import { SubForeignKey } from "common/types/foreign-key";

type NodeData = SubForeignKey<string> & { [key: string]: any };

export interface Node {
  id: string;
  label: string;
  parentId?: string;
  isGroup?: boolean;
  level?: number;
  children?: Node[];
  data?: NodeData;
}

export type NodeMap = {
  [id: string]: Node;
};

export type ChildrenMap = {
  [parentId: string]: Node[];
};

export interface NodeLinkProps {
  node: Node;
  isSelected: boolean;
  isExpanded?: boolean;
  isDisabled?: boolean;
  textToHighlight?: string;
  onClick?: () => any;
  onExpand?: () => any;
  withNodeIcon?: boolean;
}

export const UNGROUPED = "ungrouped";
