import { Component } from "react";
import { getUnexpectedErrorMessage } from "common/api/error";
import { recordsApi } from "common/api/records";
import { searchApi } from "common/api/search";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { AlertWarning } from "common/widgets/alert";
import { LoadingButton } from "common/widgets/loading-button";
import { CancelButtonLarge } from "common/ui/buttons";
import { StockFormComponent } from "./form";
import { formToApi, getAdjustment, hasValue } from "./functions";
import { AdjustmentFormValue, defaultAdjustmentValue } from "./types";

interface PropTypes {
  context: Context;
  entity: Entity;
  recordId: string;
  onCancel: () => any;
  onAdjusted: () => any;
  onHand: number;
  hasBatchSelection: boolean;
}

interface StateType {
  error?: boolean;
  errorMessage?: string;
  loading?: boolean;
  value?: AdjustmentFormValue;
}

export class StockController extends Component<PropTypes, StateType> {
  static readonly displayName = "StockController";

  constructor(props: PropTypes) {
    super(props);

    this.state = {
      error: false,
      errorMessage: undefined,
      loading: false,
      value: defaultAdjustmentValue(props.onHand),
    };
  }

  componentDidUpdate(prevProps: PropTypes) {
    if (
      prevProps.onHand !== this.props.onHand ||
      prevProps.recordId !== this.props.recordId
    ) {
      // TODO: review if this is necessary. playing with the UI don't seem to trigger it
      this.setState({ value: defaultAdjustmentValue(this.props.onHand) });
    }
  }

  adjust = () => {
    const { context, entity, recordId, onAdjusted } = this.props;
    const { value } = this.state;

    this.setState({ loading: true, error: false, errorMessage: undefined });
    recordsApi(context.apiCall)
      .adjust(entity.name, recordId, formToApi(value))
      .then(() => {
        this.setState({ loading: false });
        onAdjusted();
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: true,
          errorMessage: e.data.error,
        });
      });
  };

  onChangeFormValue = (value: AdjustmentFormValue) => this.setState({ value });

  render() {
    const { context, entity, onCancel, recordId, onHand, hasBatchSelection } =
      this.props;
    const { value, loading, error, errorMessage } = this.state;
    const adjustment = getAdjustment(context.entities, entity);
    if (!adjustment) {
      return (
        <AlertWarning
          message={_("Missing permission for Adjustment Entity.")}
        />
      );
    }

    return (
      <div className="x-stock-adjustment-wrapper">
        <div className="x-form-content">
          {error ? (
            <AlertWarning
              message={errorMessage || getUnexpectedErrorMessage()}
            />
          ) : undefined}
          <StockFormComponent
            context={context}
            entity={entity}
            runQuery={searchApi(context.apiCall).runQuery}
            originalOnHand={onHand}
            recordId={recordId}
            hasBatchSelection={hasBatchSelection}
            value={value}
            onChange={this.onChangeFormValue}
          />
        </div>
        <div className="x-form-footer">
          <div className="x-form-footer-content x-flex">
            <CancelButtonLarge onClick={onCancel} />
            <LoadingButton
              loadingText={_("Adjusting")}
              idleText={_("Adjust")}
              loading={loading}
              onClick={this.adjust}
              disabled={
                loading ||
                !hasValue(adjustment, onHand, hasBatchSelection, value)
              }
              type="action"
              size="large"
            />
          </div>
        </div>
      </div>
    );
  }
}
