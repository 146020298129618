import * as R from "ramda";
import { combineItemLabels } from "common/functions/labels";
import { Culture, EN_US } from "common/culture/supported-cultures";
import { Labels } from "common/culture/types";
import { Entities } from "common/entities/types";
import { isQueryValid } from "common/query-builder/validation";
import { isEmptyFilter } from "common/query/filter";
import { Query } from "common/query/types";
import { ColorRule } from "common/types/color-rule";
import { Context } from "common/types/context";

export const getRuleByIndex = (rules: ColorRule[], index: number) =>
  R.find((r) => r.priorityIndex === index, rules || []);

export const isSameRule = (ruleA: ColorRule, ruleB: ColorRule) =>
  (ruleA.id !== undefined && ruleA.id === ruleB.id) ||
  (ruleA.tempId !== undefined && ruleA.tempId === ruleB.tempId);

export const getRuleFromColorCode = (colorRule: ColorRule[], rule: ColorRule) =>
  colorRule?.find((item) => item.id === rule.id && item.tempId === rule.tempId);

export const getRuleLabel = (context: Context, rule: ColorRule) => {
  const { culture } = context.uiFormat;
  return rule.labels[culture]?.name || rule.labels[EN_US]?.name || "";
};

export const displayRule = (context: Context, rule: ColorRule) => {
  const { culture } = context.uiFormat;
  return (
    rule.labels[culture]?.name ||
    _("Color Rule {NUMBER}").replace("{NUMBER}", rule.priorityIndex.toString())
  );
};

export const haveUniqueLabels = (ruleA: ColorRule, ruleB: ColorRule) =>
  R.isEmpty(
    R.pickBy(
      (value, key) =>
        !isSameRule(ruleA, ruleB) && ruleB.labels[key]?.name === value.name,
      ruleA.labels,
    ),
  );

export const hasLabelProvided = (activeCulture: Culture, rule: ColorRule) =>
  rule.labels[activeCulture]?.name !== undefined;

export const hasUniqueLabels = (rule: ColorRule, rules: ColorRule[]) =>
  rules.every((r) => haveUniqueLabels(rule, r));

export const areColorRulesLabelsValid = (
  colorRules: ColorRule[],
  activeCulture: Culture,
) =>
  (colorRules || []).every(
    (rule) =>
      hasLabelProvided(activeCulture, rule) &&
      hasUniqueLabels(rule, colorRules),
  );

export const isQueryEmpty = (query: Query) =>
  isEmptyFilter(query?.having) && isEmptyFilter(query?.filter);

export const areColorRulesQueriesValid = (
  colorRules: ColorRule[],
  entityName: string,
  entities: Entities,
) =>
  (colorRules || []).every(
    (r) =>
      r.query &&
      !isQueryEmpty(r.query) &&
      isQueryValid(entities, { entity: entityName, query: r.query }),
  );

export const areColorRulesValid = (
  colorRules: ColorRule[],
  entityName: string,
  entities: Entities,
  activeCulture: Culture,
) =>
  areColorRulesLabelsValid(colorRules, activeCulture) &&
  areColorRulesQueriesValid(colorRules, entityName, entities);

export const areTranslationsValid = (
  colorRules: ColorRule[],
  cultures: Culture[],
) => {
  return colorRules?.every((rule) =>
    cultures.every((culture) => rule?.labels[culture]?.name),
  );
};

export const recalculatePriority = (rules: ColorRule[]) =>
  rules.map((rule, index) => ({ ...rule, priorityIndex: index + 1 }));

const getDefaultTemplateRuleName = (rule: ColorRule) =>
  _("Color Rule {NUMBER}").replace("{NUMBER}", rule.priorityIndex.toString());

export const translateKey =
  (colorRules: ColorRule[], culture: Culture) => (key: string) => {
    const colorRule = colorRules.find((item) => {
      const itemId = key.replace(/^item\./, "");
      return item.tempId === itemId || item.id?.toString() === itemId;
    });

    return (
      colorRule?.labels?.[culture]?.name ??
      getDefaultTemplateRuleName(colorRule)
    );
  };

export const getTranslationKeys = (colorRules: ColorRule[]): string[] =>
  colorRules
    ? [...(colorRules ?? []).map((item) => `item.${item.id || item.tempId}`)]
    : [];

export const combineColorRuleLabels = (colorRules: ColorRule[]) => ({
  items: [...colorRules],
  labels: combineItemLabels(colorRules),
});

export const resetNameTranslation = <T extends { labels: Labels }>(
  culture: Culture,
  name: string,
  element: T,
): T => {
  if (!element.labels) return element;
  const labels = Object.keys(element.labels).reduce(
    (acc, locale) => ({
      ...acc,
      [locale]: { name: culture === locale ? name : undefined },
    }),
    {},
  );
  return { ...element, labels };
};
