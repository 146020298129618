import { ReactElement, ReactNode } from "react";
import { arrayToString } from "common/utils/array";
import { genericSortByField } from "common/functions/generics";
import { ReactAsyncCreatableSelect } from "common/vendor-wrappers/react-select/async-creatable";
import { SelectorOption } from "common/widgets/selector/types";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import {
  FormatMeta,
  LabelledOption,
  LabelledOptionOrGroup,
} from "common/vendor-wrappers/react-select/types";
import { ReactAsyncSelect } from "common/vendor-wrappers/react-select/async";
import { CancellablePromise } from "common/types/promises";
import { ValueComponent, ValueProps } from "common/with-value-for";

interface PropTypes<T> {
  fetch: (
    term: string,
    limit: number,
  ) => CancellablePromise<SelectorOption<T>[]>;
  mapOptions: (options: SelectorOption<T>[]) => LabelledOptionOrGroup<T>[];
  sortOptions?: (
    options: LabelledOptionOrGroup<T>[],
  ) => LabelledOptionOrGroup<T>[];
  getOptionValue?: (option: LabelledOptionOrGroup<T>) => string;
  formatOptionLabel: (
    item: LabelledOptionOrGroup<T>,
    meta: FormatMeta<T>,
  ) => ReactNode;
  formatCreateLabel?: (inputValue: string) => ReactElement;
  defaultOptions?: SelectorOption<T>[];
  optionsLimit: number;
  isLoading?: boolean;
  disabled?: boolean;
  disableSorting?: boolean;
  className?: string;
  label?: string;
  placeholder?: string;
  allowClear?: boolean;
  shouldAddNew?: (typed: string) => boolean;
  addNew?: (typed: string) => void;
  menuIsOpen?: boolean;
}

interface StateType {
  menuIsOpen: boolean;
}

export class AsyncSelector<T> extends ValueComponent<
  T,
  PropTypes<T>,
  StateType
> {
  static readonly displayName = "AsyncSelector";
  state: StateType = {
    menuIsOpen: undefined,
  };

  componentDidUpdate(prevProps: Readonly<PropTypes<T> & ValueProps<T>>) {
    const { menuIsOpen } = this.props;

    if (prevProps.menuIsOpen !== menuIsOpen) {
      this.setState({ menuIsOpen: menuIsOpen || undefined });
    }
  }

  openMenu = () => {
    this.setState({ menuIsOpen: true });
  };

  closeMenu = () => {
    this.setState({ menuIsOpen: undefined });
  };

  onChange = (option: LabelledOptionOrGroup<T>) => {
    const { value } = this.props;
    if (!isGroupedOption(option) && option?.value !== value) {
      this.setValue(option?.value);
    }
  };

  loadOptions = (searchTerm: string, limit: number) => {
    const { fetch, mapOptions } = this.props;
    return fetch(searchTerm, limit).then((response) =>
      this.sortOptions(mapOptions(response)),
    );
  };

  sortOptions = (options: LabelledOptionOrGroup<T>[]) => {
    const { disableSorting, sortOptions } = this.props;

    if (disableSorting) return options;

    const sortingFn =
      sortOptions ?? genericSortByField<LabelledOptionOrGroup<T>>("label");

    return sortingFn(
      options.map((o) =>
        isGroupedOption(o)
          ? { ...o, options: sortingFn(o.options) as LabelledOption<T>[] }
          : o,
      ),
    );
  };

  render() {
    const {
      isLoading,
      defaultOptions,
      optionsLimit,
      getOptionValue,
      mapOptions,
      formatOptionLabel,
      className,
      label,
      placeholder,
      disabled,
      allowClear,
      shouldAddNew,
      formatCreateLabel,
      addNew,
      value,
    } = this.props;
    const { menuIsOpen } = this.state;

    const currentValue = value ? mapOptions([value])[0] : undefined;
    const selectClassName = arrayToString([
      "x-selector qa-selector",
      className,
    ]);

    return addNew ? (
      <ReactAsyncCreatableSelect
        className={selectClassName}
        isLoading={isLoading}
        defaultOptions={
          defaultOptions
            ? this.sortOptions(mapOptions(defaultOptions))
            : undefined
        }
        optionsLimit={optionsLimit}
        loadOptions={this.loadOptions}
        getOptionValue={getOptionValue}
        formatOptionLabel={formatOptionLabel}
        formatCreateLabel={formatCreateLabel}
        placeholder={placeholder}
        label={label}
        isDisabled={disabled}
        isClearable={allowClear}
        menuIsOpen={menuIsOpen}
        onMenuClose={this.closeMenu}
        isValidNewOption={shouldAddNew}
        onCreateOption={addNew}
        value={currentValue ?? null}
        onChange={this.onChange}
      />
    ) : (
      <ReactAsyncSelect
        className={selectClassName}
        isLoading={isLoading}
        defaultOptions={
          defaultOptions
            ? this.sortOptions(mapOptions(defaultOptions))
            : undefined
        }
        optionsLimit={optionsLimit}
        loadOptions={this.loadOptions}
        getOptionValue={getOptionValue}
        formatOptionLabel={formatOptionLabel}
        menuIsOpen={menuIsOpen}
        onMenuClose={this.closeMenu}
        placeholder={placeholder}
        label={label}
        isDisabled={disabled}
        isClearable={allowClear}
        value={currentValue ?? null}
        onChange={this.onChange}
      />
    );
  }
}
