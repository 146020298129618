import * as R from "ramda";
import { getLocalizedName } from "common";
import { behaveAs, filterByBehavior } from "common/entities";
import { Entity } from "common/entities/types";
import { RelatedEntity, StaticEntries } from "common/form/types";
import { getStaticEntries, payloadToRecord } from "common/record/utils";
import { Context } from "common/types/context";
import {
  Record,
  RecordPayload,
  RelatedPayload,
  RelatedRecords,
} from "common/types/records";
import { Site } from "common/types/sites";
import { SidebarElement } from "./types";

export const filterEntries =
  (entries: StaticEntries = [], toOmit: string[] = []) =>
  (entry: string) =>
    R.includes(entry, entries) && !R.includes(entry, toOmit);

export const getSidebarElements = (
  entity: Entity,
  context: Context,
  staticEntries: StaticEntries,
  toOmit: string[],
): SidebarElement[] =>
  getStaticEntries(entity, context, filterEntries(staticEntries, toOmit)).map(
    (entry) => ({ label: entry.value, entity: entity.name }),
  );

export const isOwnedBy =
  (owner: Entity) =>
  (child: Entity): boolean =>
    R.any(
      (j) => j.owner && j.outbound && j.entityName === owner.name,
      child.joins,
    );
export const canShowRelated = (
  site: Site,
  owner: Entity,
): ((child: Entity) => boolean) =>
  site.isGroup
    ? owner?.recordScope === "SharedAllSites"
      ? // for Shared:All Site entities, in a group site, only show Shared:All Site children
        (child) => child?.recordScope === "SharedAllSites"
      : owner?.recordScope === "SharedMultipleSites"
        ? // for Shared:Multiple Site entities, in a group site, only show Shared:Multiple Site children
          (child) => child?.recordScope === "SharedMultipleSites"
        : (_) => false // hide related for other combination in a group site
    : (_) => true; // Show all related if it's not a group site

export const isSelected = (
  selected: RelatedEntity[],
): ((child: Entity) => boolean) =>
  selected?.length
    ? ({ name }) => R.any((re) => re.name === name, selected)
    : (_) => true;

export const getAssetMeterReadingEntity = (
  entity: Entity,
  context: Context,
): Entity => {
  const { entities } = context;
  if (!behaveAs("Asset", entity)) return undefined;

  const assetMeters = R.values(filterByBehavior("AssetMeter", entities)).filter(
    (e) => e.arguments.assetEntity === entity.name,
  );

  if (!assetMeters.length) return undefined;

  const assetMeterReadings = R.values(
    filterByBehavior("AssetMeterReading", entities),
  ).filter((e) => e.arguments.assetMeterEntity === assetMeters[0].name);

  if (!assetMeterReadings.length) return undefined;

  return entities[assetMeterReadings[0].name];
};

const getEntitySortFn =
  (entitiesOrder: string[]) =>
  (a: Entity, b: Entity): number =>
    entitiesOrder.indexOf(a.name) - entitiesOrder.indexOf(b.name);

export const getRelated = (
  context: Context,
  entity: Entity,
  selectedEntities: RelatedEntity[],
  related: RelatedRecords,
): SidebarElement[] => {
  const assetMeterReading = getAssetMeterReadingEntity(entity, context);

  const entitiesOrder = (selectedEntities ?? []).map((e) => e.name);
  const relatedEntities = R.values(context.entities)
    .filter(isOwnedBy(entity))
    .filter(canShowRelated(context.site, entity))
    .filter(isSelected(selectedEntities))
    .sort(getEntitySortFn(entitiesOrder))
    .map((e) => ({
      label: getLocalizedName(e),
      count: (related?.[e.name] ?? []).length,
      entity: e.name,
    }));

  if (!assetMeterReading) return relatedEntities;

  return relatedEntities.reduce(
    (acc, e) =>
      e.entity !== assetMeterReading.arguments.assetMeterEntity
        ? acc.concat([e])
        : acc.concat([
            e,
            {
              label: getLocalizedName(assetMeterReading),
              entity: assetMeterReading.name,
              isChild: true,
              isGrandChild: true,
            },
          ]),
    [],
  );
};

interface Related<T> {
  [index: string]: T[];
}

const mapRelated = <T>(
  uiRelated: RelatedPayload,
  filter: (rp: RecordPayload) => boolean,
  map: (rp: RecordPayload) => T,
): Related<T> =>
  R.mapObjIndexed<RecordPayload[], T[]>(
    (records) => records.filter(filter).map(map),
    uiRelated,
  );

export const getRelatedChanges = (
  uiRelated: RelatedPayload,
  related: RelatedRecords,
): RelatedRecords => {
  const newRelated = mapRelated(
    uiRelated,
    (uiRecord) => uiRecord.properties.tempId,
    payloadToRecord,
  );

  if (!related) return newRelated;

  const deletedRelated = mapRelated(
    uiRelated,
    (uiRecord) => uiRecord.properties.id && uiRecord.properties.isDeleted,
    (r) => r.properties.id,
  );

  return R.mapObjIndexed<Record[], Record[]>(
    (records, entityName) =>
      records
        .filter(
          (r) =>
            !deletedRelated[entityName] ||
            !R.includes(r.properties.id, deletedRelated[entityName]),
        )
        .concat(newRelated[entityName] || []),
    related,
  );
};
