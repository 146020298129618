import { Component } from "react";
import { Entity } from "common/entities/types";
import { Condition } from "common/query-builder/condition";
import { Error, Errors } from "common/query-builder/errors";
import { getFields, sanitize } from "common/query-builder/functions";
import { Joins } from "common/query-builder/joins";
import { Order } from "common/query-builder/order";
import { SelectWithEntity } from "common/query-builder/select-with-entity";
import { Select } from "common/query-builder/select";
import { getGroupErrors } from "common/query-builder/validation";
import { ConditionTypes } from "common/types/condition-type";
import { Context } from "common/types/context";
import { Row } from "common/widgets/row";
import { ValueProps } from "common/with-value-for";
import { getPathMap } from "../joins";
import {
  Filter,
  JoinItem,
  OrderItem,
  Query,
  QueryForEntity,
  SelectItem,
} from "../types";
import { addSelectForOrder, updateSelect } from "./functions";

export interface PropTypes {
  context: Context;
  entity: Entity;
  isReport: boolean;
  conditionTypes?: ConditionTypes;
}

type Props = PropTypes & ValueProps<Query>;

export class Config extends Component<Props> {
  static readonly displayName = "Config";

  changeSelect = (selectItem: SelectItem[]) => {
    const { value, onChange } = this.props;
    const select = selectItem || [];

    onChange(updateSelect(value, select, value.order));
  };

  onTableSelectChange = (newQuery: Query) => {
    const { value, onChange } = this.props;
    const updatedQuery = updateSelect(value, newQuery.select, newQuery.order);

    onChange(updatedQuery);
  };

  onChangeFilter = (filter: Filter) => {
    const { value } = this.props;
    this.props.onChange({ ...value, filter });
  };

  changeJoin = (joins: JoinItem[]) => {
    const { context, entity, value, onChange } = this.props;
    const newQuery = sanitize(context.entities, {
      entity: entity.name,
      query: { ...value, joins },
    }).query;
    onChange(newQuery);
  };

  changeOrder = (orders: OrderItem[]) => {
    const { value, onChange } = this.props;
    onChange(addSelectForOrder(value, value.select, orders));
  };

  getFields = () => {
    const { context, entity, value } = this.props;
    return getFields(context.entities, { entity: entity.name, query: value });
  };

  render() {
    const { context, entity, isReport, conditionTypes, value } = this.props;
    const fields = this.getFields();
    const query: QueryForEntity = {
      entity: entity.name,
      query: value,
    };
    const groupErrors: Error[] = getGroupErrors(fields, query).map((e) => ({
      message: e.message,
    }));
    const pathMap = getPathMap(context.entities, query);

    return (
      <div className="x-query-builder qa-query-builder">
        <Row
          title={_("Joins")}
          className="x-padding-bottom-20 x-joins qa-joins"
        >
          <Joins
            entities={context.entities}
            entityName={entity.name}
            value={value.joins}
            onChange={this.changeJoin}
          />
        </Row>
        <Row
          title={isReport ? _("Columns") : _("Grid")}
          className="x-padding-bottom-20 x-select qa-select"
        >
          {isReport ? (
            <Select
              context={context}
              allowAggregates={false}
              forceFkAggregates={true}
              allowLong={false}
              entities={context.entities}
              fields={fields}
              withExpressions={true}
              value={value.select}
              onChange={this.changeSelect}
            />
          ) : (
            <SelectWithEntity
              context={context}
              entities={context.entities}
              entity={entity}
              fields={fields}
              value={value}
              onChange={this.onTableSelectChange}
            />
          )}
        </Row>
        <Row
          title={_("Filter By")}
          className="x-padding-bottom-20 x-filter-by qa-filter-by"
        >
          <Condition
            pathMap={pathMap}
            allowAggregates={false}
            fields={fields}
            context={context}
            entityName={entity.name}
            conditionTypes={conditionTypes}
            withExpressions={true}
            value={value.filter}
            onChange={this.onChangeFilter}
          />
        </Row>
        <Row
          title={_("Order By")}
          className="x-padding-bottom-20 x-order-by qa-order-by"
        >
          <Order
            context={context}
            fields={fields}
            allowAggregates={false}
            value={value.order}
            onChange={this.changeOrder}
          />
        </Row>
        {groupErrors.length ? <Errors errors={groupErrors} /> : undefined}
      </div>
    );
  }
}
