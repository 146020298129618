import * as R from "ramda";
import { dateoffset } from "common/validate";
import { ValueProps } from "common/with-value-for";
import { Selector } from "../selector";
import { displayDateOffset } from "./functions";

interface PropTypes extends ValueProps<string> {
  disabled?: boolean;
  className?: string;
}

export const DateOffset = ({
  value,
  disabled,
  className,
  onChange,
}: PropTypes) => {
  // T{hours to add}:{minutes to add}:{seconds to add}
  const dateSuffix = "T00:00:00Z";
  const options = R.times((index: number) => {
    return "R" + index + dateSuffix;
  }, 15);

  const errorClass = dateoffset(value) ? "" : "x-has-error";
  return (
    <div className={errorClass}>
      <Selector
        className={className}
        getOptionLabel={displayDateOffset}
        options={options}
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
