import { Entity } from "common/entities/types";
import { Record } from "common/types/records";
import { WorkOrderSettings } from "common/types/work-order-settings";
import { Role } from "common/types/roles";

export const isWorkOrderClosed = (record: Record) =>
  record?.properties?.status === "H";

export const getDisableToEditWorkOrderReason = (entity: Entity) =>
  _(
    "Editing of Closed {ENTITY} records is disabled. They can only be previewed.",
  ).replace("{ENTITY}", entity.localizedName ?? entity.name);

export const getEditRecordPermission = (
  workOrderEntity: Entity,
  disableEdit: boolean,
  role: Role,
  record: Record,
  workOrderSettings: WorkOrderSettings,
) => {
  const workOrderPerEntitySettings =
    workOrderSettings.workOrderPerEntitySettings?.find(
      (s) => s.entityName === workOrderEntity.name,
    );
  const enableEditingClosedWorkOrder =
    !!workOrderPerEntitySettings?.enableEditingClosedWorkOrder;
  const roleIds = workOrderPerEntitySettings?.roleIdsAllowedForEditing;
  const canRoleEdit =
    !roleIds?.length || roleIds.some((roleId) => roleId === role.id);
  const canEditClosedWO =
    !isWorkOrderClosed(record) || (enableEditingClosedWorkOrder && canRoleEdit);
  return !disableEdit && canEditClosedWO;
};
