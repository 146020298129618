import { Entity, Entities } from "common/entities/types";
import { behaveAs, getColumn, getEntityByBehavior } from "common/entities";
import { EntityColumn } from "common/entities/entity-column/types";
import { getPartChargeRelatedEntities } from "common/record/form/content/related/part-charge/functions";
import { toCamelCase } from "common";
import { Context } from "common/types/context";

export const getNestedPayloadEntity = (
  context: Context,
  entity: Entity,
  columnName: string,
) =>
  behaveAs("ScheduledEvent", entity) && columnName === "workOrderForm"
    ? context?.entities[entity?.arguments?.workOrderEntity]
    : undefined;

const getBehaviorFakeColumn = (
  entities: Entities,
  entity: Entity,
  columnName: string,
): EntityColumn => {
  if (behaveAs("PartCharge", entity)) {
    const { stockEntity, batchEntity, batchChargeEntity } =
      getPartChargeRelatedEntities(entities, entity);

    switch (columnName) {
      case "quantity":
        return getColumn(batchChargeEntity, "quantity");
      case "partId":
        return getColumn(stockEntity, "partId");
      case "stockId":
        return getColumn(batchEntity, "stockId");
    }
  }

  if (behaveAs("PartsToBeCounted", entity)) {
    if (columnName === "adjustmentType") {
      const adjustmentEntity = getEntityByBehavior("Adjustment", entities);
      return getColumn(adjustmentEntity, columnName);
    }
  }

  return undefined;
};

export const getPayloadColumn = (
  entities: Entities,
  entity: Entity,
  columnName: string,
) =>
  getColumn(entity, toCamelCase(columnName)) ||
  getBehaviorFakeColumn(entities, entity, columnName);
