import * as React from "react";
import { PropsWithChildren } from "react";
import { BadgeType } from "common/types/badges";

// @TODO We need to revise the implementation/usage of this component and its props
interface TabProps {
  // eslint-disable-next-line react/no-unused-prop-types
  value: string;
  // eslint-disable-next-line react/no-unused-prop-types
  label: string;
  // eslint-disable-next-line react/no-unused-prop-types
  warningIcon?: boolean;
  className?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  badge?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  badgeType?: BadgeType;
}

export type TabElement = React.ReactElement<TabProps>;

export const Tab = ({
  className,
  children,
  value,
}: PropsWithChildren<TabProps>) => (
  <div key={value} className={className}>
    {children}
  </div>
);
Tab.displayName = "Tab";

export const isTabElement = (t: TabElement): t is TabElement =>
  !!t?.props?.value && !!t?.props?.label;
