import * as R from "ramda";
import { Context } from "common/types/context";
import { defaultFor } from "common";
import {
  isSharedMultipleSitesEntity,
  isSingleSiteEntity,
} from "common/entities";
import { getVirtualColumns } from "common/entities/entity-column/functions";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entities, Entity } from "common/entities/types";
import { systemColumnsToOmit } from "common/form/functions/entity";
import { merge1, merge2, mergeChain } from "common/merge";
import { QueryForEntity } from "common/query/types";
import { StandardUiValue } from "common/record/types";
import {
  Properties,
  Record,
  RecordPayload,
  RelatedPayload,
  RelatedRecords,
} from "common/types/records";
import { uniqueArray } from "common/utils/array";

export const getColumnType = (name: string, cols: EntityColumn[]): string => {
  const col = R.find((c) => c.name && c.name === name, cols);
  return col ? col.dataType : "";
};

export const replaceUndefined = (properties: any) =>
  R.mapObjIndexed(
    (value: any) => (value === undefined ? null : value),
    properties,
  );

const getPropertiesChanges = (oldProperties: any, newProperties: any) => {
  const changes = replaceUndefined(
    R.pickBy((value: any, key: string) => {
      if (oldProperties?.[key] === null && value === undefined) return false;
      return !R.equals(oldProperties?.[key], value);
    }, newProperties),
  );

  return oldProperties?.id
    ? R.mergeRight(changes, { id: oldProperties?.id })
    : changes;
};

const getRelatedChanges = (
  oldRelated: RelatedRecords,
  newRelated: RelatedRecords,
) => {
  if (!newRelated) return undefined;
  if (!oldRelated) return newRelated;

  return R.mapObjIndexed(
    (value: Record[], entity: string) =>
      value
        .map((r) => ({
          oldRecord: R.find(
            (o) => o.properties.id === r.properties.id,
            oldRelated[entity],
          ),
          newRecord: r,
        }))
        .filter(({ oldRecord, newRecord }) => newRecord !== oldRecord)
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        .map((r) => getRecordChanges(r.oldRecord, r.newRecord))
        .map((record) =>
          mergeChain(record).setWith("properties", replaceUndefined).output(),
        ),
    newRelated,
  );
};

export const getRecordChanges = (
  oldRecord: Record,
  newRecord: Record,
): Record => {
  if (!oldRecord) return newRecord;

  const propertiesChanges = getPropertiesChanges(
    oldRecord.properties,
    newRecord.properties,
  );

  const relatedChanges = getRelatedChanges(
    oldRecord.related,
    newRecord.related,
  );

  return {
    properties: propertiesChanges,
    related: relatedChanges,
    actions: newRecord.actions,
  };
};

export const recordToPayload = (record: Record): RecordPayload => ({
  properties: record.properties,
  deleted: record.deleted,
  isNew: record.isNew,
  related: R.mapObjIndexed(
    (records) => R.map(recordToPayload, records),
    record.related,
  ),
});

const materializeTemporaryIdsInProperties = (
  properties: Properties,
): Properties => ({
  ...R.omit(["$id"], properties),
  ...(properties?.$id && !properties?.id ? { id: properties.$id } : undefined),
});

export const materializeTemporaryIds = <T extends Record = Record>(
  record: T,
): T => ({
  ...record,
  properties: materializeTemporaryIdsInProperties(record?.properties),
  ...(record?.related
    ? {
        related: R.mapObjIndexed(
          (relatedRecords) => relatedRecords.map(materializeTemporaryIds),
          record?.related,
        ),
      }
    : undefined),
});

export const payloadToRecord = (payload: RecordPayload): Record => ({
  ...payload,
  actions: [],
  related: R.mapObjIndexed(
    (payloads) => R.map(payloadToRecord, payloads),
    payload.related,
  ),
});

export const recordsById = (records: Record[] = []) =>
  R.indexBy((r) => r?.properties?.id, records) as {
    [index: string]: Record;
  };

export const findRecordFromProperties = (
  properties: Properties,
  records: Record[] | RecordPayload[],
): Record | RecordPayload => {
  if ((!properties.id && !properties.tempId) || !records) {
    return undefined;
  }
  return properties.id
    ? records.find((r) => r.properties.id === properties.id)
    : records.find((r) => r.properties.tempId === properties.tempId);
};

export const getRelatedRecords = (
  entityName: string,
  record: Record = defaultFor<Record>(),
  uiRelated: RelatedPayload = defaultFor<RelatedPayload>(),
): Record[] => {
  const { related = {} } = record;
  const cEntityName = entityName;
  const relatedChanges = (uiRelated[cEntityName] || []).map(payloadToRecord);
  const [newItems, changedItems] = R.partition(
    (record) => R.has("tempId", record.properties),
    relatedChanges,
  );
  const changedItemsById = recordsById(changedItems);
  return (related[cEntityName] || [])
    .map((r) => changedItemsById[r.properties.id] || r)
    .concat(newItems);
};

export const applyChangesToRecord = (
  record: Record,
  ui: StandardUiValue,
): Record => {
  // formId comes from different places whether you
  // are creating wo normally, from wo task or requestor
  const formId = ui?.detail?.form?.formId || record?.properties?.formId;
  const props = ui?.detail?.form || record?.properties;
  const properties = formId ? merge1("formId", formId, props) : props;

  const newRelated: RelatedRecords = R.mapObjIndexed(
    (_, k) => getRelatedRecords(k, record, ui?.related?.form),
    ui?.related?.form,
  );

  const related = R.mergeRight(record?.related, newRelated);

  return R.mergeRight(record, { properties, related });
};

export interface MappedRelated {
  entityName: string;
  id: any;
  deleted: boolean;
  isNew: boolean;
  record: Record;
}

export const mapRelated = (related: {
  [index: string]: Record[];
}): MappedRelated[] => {
  const relatedPairs = R.toPairs(related);
  return R.flatten(
    relatedPairs.map(([entityName, records]) =>
      records.map(
        (r: Record): MappedRelated => ({
          entityName,
          id: r.properties.id,
          deleted: r.properties.isDeleted,
          isNew: r.isNew,
          record: r,
        }),
      ),
    ),
  );
};

export const hasProtectedColumns = (entity: Entity) =>
  entity.columns.some(
    (column: EntityColumn) => column.requireExplicitAuthentication,
  );

export const getProtectedColumnNames = (entity: Entity): string[] =>
  entity.columns.reduce((acc: string[], column: EntityColumn) => {
    return column.requireExplicitAuthentication ? acc.concat(column.name) : acc;
  }, []);

export const requiresExplicitAuthentication = (
  entities: Entities,
  entity: Entity,
  oldRecord: Record,
  newRecord: Record,
): boolean => {
  const explicitAuthCols = getProtectedColumnNames(entity);

  if (!oldRecord && explicitAuthCols.length) return true; // create protected

  if (!newRecord && oldRecord) {
    if (explicitAuthCols.length) return true; // delete protected

    const related = mapRelated(oldRecord.related);

    return R.any(
      ({ entityName, record }) =>
        requiresExplicitAuthentication(
          entities,
          entities[entityName],
          record,
          undefined,
        ),
      related,
    ); // deleted protected parent
  }

  // update protected or create/update protected parent
  const { properties, related } = getRecordChanges(oldRecord, newRecord);

  if (explicitAuthCols.length) {
    const aColRequiresAuth = R.any(
      (c) => R.includes(c, explicitAuthCols),
      R.keys(properties),
    );

    if (aColRequiresAuth) return true; // update protected
  }

  if (!related) return false; // create / update unprotected

  const mappedRelated = mapRelated(related);

  return R.any(
    ({ entityName, record, id, deleted }) =>
      requiresExplicitAuthentication(
        entities,
        entities[entityName],
        oldRecord?.related &&
          R.find(
            (rel) => rel.properties.id === id,
            oldRecord.related[entityName] || [],
          ),
        deleted ? undefined : record,
      ),
    mappedRelated,
  );
};

export const AUDIT_TRAIL = "Audit Trail";
export const COMMENTS = "Comments";
export const GENERAL = "General Information";
export const MAP = "Map";
export const MEASUREMENTS = "Fluke Connect Measurements";
export const MEDIA_FILES = "Documents";
export const OTHER = "Other";
export const OVERVIEW = "Overview";
export const RELATED = "Related Entities";
export const REPORTS = "Reports";
export const SIGNATURE = "Signature";

interface BehaviorMapping {
  [index: string]: string;
}

const recordBehaviors: BehaviorMapping = {
  Attachment: MEDIA_FILES,
  GeoLocation: MAP,
  Signature: SIGNATURE,
  Asset: MEASUREMENTS,
  Comments: COMMENTS,
};

export const getLabelFor = (entry: string): string => {
  switch (entry) {
    case AUDIT_TRAIL:
      return _("Audit Trail");
    case COMMENTS:
      return _("Comments");
    case GENERAL:
      return _("General Information");
    case MAP:
      return _("Map");
    case MEASUREMENTS:
      return _("Fluke Connect Measurements");
    case MEDIA_FILES:
      return _("Documents");
    case OTHER:
      return _("Other");
    case OVERVIEW:
      return _("Overview");
    case RELATED:
      return _("Related Entities");
    case REPORTS:
      return _("Reports");
    case SIGNATURE:
      return _("Signature");
  }
  return entry;
};

export const isOverview = (label: string) => {
  return [OVERVIEW, GENERAL].includes(label);
};

const getBehaviorEntries = (entity: Entity) =>
  entity?.behaviors
    ? entity.behaviors.map((b) => recordBehaviors[b.name]).filter((b) => !!b)
    : [];

export const hasUploadableFields = (entity: Entity) =>
  entity?.columns.some(
    (c) =>
      c.dataType === "document" ||
      c.dataType === "image" ||
      c.dataType === "media",
  );

const getEntityEntries = (entity: Entity) =>
  entity && hasUploadableFields(entity) ? [MEDIA_FILES] : [];

export const removeSystemColumns = (record: Record) =>
  merge1("properties", R.omit(systemColumnsToOmit, record.properties), record);

type EntryFilter = (e: string) => boolean;

export interface StaticEntry {
  value: string;
  label: string;
}

export const getStaticEntries = (
  entity: Entity,
  context: Context,
  filter?: EntryFilter,
): StaticEntry[] => {
  const entries = uniqueArray(
    getBehaviorEntries(entity).concat(getEntityEntries(entity)),
  );
  return entries
    .filter(filter || (() => true))
    .map((entry) => ({ value: entry, label: getLabelFor(entry) }))
    .filter(
      (i) => i.value !== "Readings" || context.isEnabledFeature("integrations"),
    );
};

// ------- Generic controller dependencies -----------------------------------//
export const addTempIdToRecord = (record: Record, tempId: string) =>
  merge2("properties", "tempId", tempId, record);

// ------- List --------------------------------------------------------------//

export const queryWithPage = (query: QueryForEntity, page: number) =>
  merge2("query", "page", page, query);

export const queryWithPageSize = (query: QueryForEntity, pageSize: number) =>
  merge2("query", "pageSize", pageSize, query);

export const isNotDeletedRecord = (record: Record) =>
  !!record && !record?.properties?.isDeleted;

/**
 * Remove virtual columns from the provided records
 * @param {Record[]} records
 * @param {Entity} entity
 * @returns {Record[]}
 */
export const omitVirtualPropsFromRecords = (
  records: Record[],
  entity: Entity,
): Record[] => {
  const virtualColumnsNames = getVirtualColumns(entity).map(
    (column) => column.name,
  );

  return records.map((record) => {
    const properties = R.omit(virtualColumnsNames, record.properties);
    return { ...record, properties };
  });
};

export const getRecordSites = (
  context: Context,
  entity: Entity,
  properties: Properties,
) => {
  if (isSharedMultipleSitesEntity(entity)) {
    return properties?.sites || [];
  }
  if (isSingleSiteEntity(entity)) {
    return [properties?.site ?? context.site.name];
  }
  return [];
};

export const flattenValues = (p: Properties) =>
  p &&
  R.omit(
    ["isDeleted"],
    R.mapObjIndexed((v) => v?.id || v?.value || v, p) as Properties,
  );
