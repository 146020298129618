import { requestorUserType, UserType } from "common/functions/users";
import { ShortUnitOfTime } from "common/date-time/types";
import type { SsoLoginMethod } from "./sso-login-method";

export interface CookiesPolicy {
  sessionTimeout: SessionTimeout;
}

interface CookiesPolicyPayload {
  sessionTimeout: string;
}

export interface SessionTimeout {
  value: number;
  unit: ShortUnitOfTime;
}

export interface LoginAccountSettings {
  autoDisplayAccountByEmail: boolean;
}

export interface AuditTrailSettings {
  includeInRecordPdfExport: boolean;
  askUserBeforeExporting: boolean;
}

export const sessionUnits: ShortUnitOfTime[] = ["minutes", "hours", "days"];

export interface SecuritySettings {
  passwordPolicy: PasswordPolicy;
  cookiesPolicy: CookiesPolicy;
  ssoPolicy: SsoPolicy;
  loginAccountSettings: LoginAccountSettings;
  privacyPolicy: PrivacyPolicy;
  auditTrailSettings: AuditTrailSettings;
}

export interface SsoPolicy {
  defaultUserType: UserType[];
  defaultRoleId: number;
  site: string;
  loginMethod: SsoLoginMethod;
}

export interface SecuritySettingsPayload {
  passwordPolicy: PasswordPolicy;
  cookiesPolicy: CookiesPolicyPayload;
  ssoPolicy: SsoPolicy;
  loginAccountSettings: LoginAccountSettings;
  privacyPolicy: PrivacyPolicy;
  auditTrailSettings: AuditTrailSettings;
}

export interface AdminUserSecuritySettings {
  passwordPolicy: PasswordPolicy;
}

export interface PasswordPolicy {
  minLength?: number;
  maxLength?: number;
  expirationDays?: number;
  passwordHistoryLength?: number;
  mustContainUpper: boolean;
  mustContainNumber: boolean;
  mustContainOther: boolean;
}

export interface PrivacyPolicy {
  showGeneratedByInPdf: boolean;
}

export const defaultPasswordPolicy: PasswordPolicy = {
  mustContainUpper: false,
  mustContainNumber: false,
  mustContainOther: false,
};

export const defaultCookiesPolicy: CookiesPolicy = {
  sessionTimeout: {
    value: 1,
    unit: "hours",
  },
};

export const SITE_ALL = "all";
export const defaultSsoPolicy: SsoPolicy = {
  defaultUserType: requestorUserType,
  defaultRoleId: 101,
  site: SITE_ALL,
  loginMethod: "notAllowed",
  // ** TECH DEBT **
  // This (101) is the default roleId for the requestor role.
  // This requestor roleId could be deleted or different across tenants.
  // We don't have a way of knowing which roleId corresponds to requestor.
};

export const defaultLoginAccountSettings: LoginAccountSettings = {
  autoDisplayAccountByEmail: false,
};

export const defaultPrivacyPolicy: PrivacyPolicy = {
  showGeneratedByInPdf: false,
};

export const defaultAuditTrailSettings: AuditTrailSettings = {
  includeInRecordPdfExport: false,
  askUserBeforeExporting: false,
};

export const defaultSecuritySettings: SecuritySettings = {
  passwordPolicy: defaultPasswordPolicy,
  cookiesPolicy: defaultCookiesPolicy,
  ssoPolicy: defaultSsoPolicy,
  loginAccountSettings: defaultLoginAccountSettings,
  privacyPolicy: defaultPrivacyPolicy,
  auditTrailSettings: defaultAuditTrailSettings,
};
