import { Children, cloneElement } from "react";
import { defaultFor } from "common";
import { Component } from "common/component";
import { OnChange } from "common/with-value-for";

export interface PropTypes<TValue> {
  defaultValue?: TValue;
}

interface StateType<TValue> {
  value?: TValue;
}

export interface Injected<TValue> {
  value: TValue;
  onChange: OnChange<TValue>;
}

export function createInjected<TValue>() {
  return defaultFor<Injected<TValue>>();
}

export class ValueController<TValue> extends Component<
  PropTypes<TValue>,
  StateType<TValue>
> {
  constructor(props: PropTypes<TValue>) {
    super(props);
    this.state = { value: props.defaultValue };
  }
  render() {
    const { children } = this.props;
    const { value } = this.state;

    const injected: Injected<TValue> = {
      value,
      onChange: this.onChangeMergeState("value"),
    };

    return (
      <>
        {Children.toArray(children).map((c) =>
          cloneElement(c as any, injected),
        )}
      </>
    );
  }
}
