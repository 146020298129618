import { Context } from "common/types/context";
import { EmptyDetails } from "common/record/form/content/audit-trail/event-detail/events/empty";
import { defaultFor } from "common";
import { PartCountProperties } from "common/types/cycle-count";
import { LabelWidget } from "common/form/widget/label-widget";
import { Entity } from "common/entities/types";
import { getLocalizedColumnName } from "common/entities/entity-column/functions";
import { findColumn } from "common/entities";
import {
  PartCountCancelledDetails,
  PartCountCancelledEvent,
} from "common/types/events";
import { Row } from "../row";

interface PropTypes {
  context: Context;
  entity: Entity;
  event: PartCountCancelledEvent;
  hideLinks: boolean;
}

export const PartCountCancelled = ({
  context,
  entity,
  event,
  hideLinks,
}: PropTypes) => {
  const details = event?.details ?? defaultFor<PartCountCancelledDetails>();
  const { partId, partLocationId } =
    details.properties ?? defaultFor<PartCountProperties>();

  const partsColumn = findColumn(entity?.columns, "partId");
  const partLocationColumn = findColumn(entity?.columns, "partLocationId");

  return (
    <>
      <EmptyDetails event={event} uiFormat={context.uiFormat} />
      <hr />
      <legend>{_("Details")}</legend>
      <Row label={getLocalizedColumnName(entity, "partId")}>
        <LabelWidget
          context={context}
          entityName={entity?.name}
          column={partsColumn}
          withLinks={!hideLinks}
          value={partId}
        />
      </Row>
      <Row label={getLocalizedColumnName(entity, "partLocationId")}>
        <LabelWidget
          context={context}
          entityName={entity?.name}
          column={partLocationColumn}
          withLinks={!hideLinks}
          value={partLocationId}
        />
      </Row>
    </>
  );
};
