import * as R from "ramda";
import { useState, useEffect } from "react";
import { getAllDescendants, getSitesDictionary } from "common/functions/sites";
import { Modal } from "common/widgets/modal";
import { InputWithSearch } from "common/widgets/input-with-search";
import { Tree } from "common/widgets/tree";
import { Site } from "common/types/sites";
import { isGroupSite } from "x/account-settings/sites/functions";
import { Context } from "common/types/context";
import {
  removeSiteIfParentAlreadySelected,
  getSelectedSites,
  getAllChildSiteForGroupSites,
  getParentSiteIfAllSiblingsSelected,
  filterNodesBySearchTerm,
  removeGroupSites,
} from "./functions";

interface SiteSelectorTreeModalProps {
  context: Context;
  isTreeSelectorOpen: boolean;
  isExpandable: boolean;
  includeGroupSites: boolean;
  allowMultipleSelection: boolean;
  onClose: () => void;
  isSiteDisabled: (site: Site) => boolean;
  value: string[];
  onChange: (value: string[]) => void;
}

export const SiteSelectorTreeModal = ({
  context,
  isTreeSelectorOpen,
  includeGroupSites,
  isSiteDisabled,
  isExpandable,
  allowMultipleSelection,
  onClose,
  value,
  onChange,
}: SiteSelectorTreeModalProps) => {
  const { sites } = context;
  const [treeSearchTerm, setTreeSearchTerm] = useState<string>("");
  const [selectedSiteList, setSelectedSiteList] = useState<string[]>(
    value || [],
  );
  const sitesDictionary = getSitesDictionary(sites);
  const sitesForTree = filterNodesBySearchTerm(sites, treeSearchTerm);

  useEffect(() => {
    if (allowMultipleSelection) {
      const allChildSiteForGroupSites = getAllChildSiteForGroupSites(
        selectedSiteList,
        sites,
      );

      const newSelectedSiteList = R.uniq([
        ...selectedSiteList,
        ...allChildSiteForGroupSites,
      ]);
      setSelectedSiteList(newSelectedSiteList);
    }
  }, []);

  const onApply = () => {
    const newValue = allowMultipleSelection
      ? includeGroupSites
        ? removeSiteIfParentAlreadySelected(selectedSiteList, sites)
        : removeGroupSites(selectedSiteList, sitesDictionary)
      : selectedSiteList;

    onChange(newValue);
    onClose();
  };

  const removeSelectedSite = (site: Site) => {
    const allChildSites = getAllDescendants(site, sites);

    // when a group site is deselected, remove all its children
    const sitesWithoutChild = selectedSiteList.filter(
      (siteName) =>
        siteName !== site?.name && !allChildSites.includes(siteName),
    );

    // when a site is deselected,i.e, all the siblings are not selected
    // remove the parent if there
    const parentSitesWithAllChildSelected = getParentSiteIfAllSiblingsSelected(
      site,
      sites,
      sitesWithoutChild,
    );

    const newSelectedSiteList = sitesWithoutChild.filter(
      (siteName) => !parentSitesWithAllChildSelected.includes(siteName),
    );

    setSelectedSiteList(R.uniq(newSelectedSiteList));
  };

  const addSelectedSite = (site: Site) => {
    // when a group site is selected, add all its children
    const allChildSites = getAllDescendants(site, sites);

    const sitesWithAllChildWhenGroupSiteSelected = R.uniq([
      ...selectedSiteList,
      ...allChildSites,
      site?.name,
    ]);

    setSelectedSiteList(sitesWithAllChildWhenGroupSiteSelected);
  };

  const onSiteSelect = (site: Site) => {
    if (allowMultipleSelection) {
      selectedSiteList.includes(site?.name)
        ? removeSelectedSite(site)
        : addSelectedSite(site);
    }
  };

  const onTreeSelectorChange = (site: Site) => {
    if (isGroupSite(site) && !includeGroupSites) return;

    onChange([site?.name]);
    setSelectedSiteList([site?.name]);
    onClose();
  };

  return (
    <>
      {isTreeSelectorOpen ? (
        <Modal
          title={_("Select Site")}
          content={
            <>
              <InputWithSearch
                value={treeSearchTerm}
                onChange={setTreeSearchTerm}
              />
              {sitesForTree.length > 0 ? (
                <Tree
                  nodes={sitesForTree}
                  selected={getSelectedSites(sitesDictionary, selectedSiteList)}
                  allowMultipleSelection={allowMultipleSelection}
                  textToHighlight={treeSearchTerm}
                  isDisabled={isSiteDisabled}
                  isExpandable={isExpandable}
                  onClick={
                    allowMultipleSelection ? onSiteSelect : onTreeSelectorChange
                  }
                />
              ) : (
                <span className="x-no-results qa-no-results">
                  {_("No results")}
                </span>
              )}
            </>
          }
          className="x-tree-selector-content qa-tree-selector-modal"
          hideFooter={!allowMultipleSelection}
          noPadding={true}
          okLabel={_("Apply")}
          onOk={allowMultipleSelection && onApply}
          onCancel={onClose}
        />
      ) : undefined}
    </>
  );
};
