import { Component } from "react";
import { recordsApi } from "common/api/records";
import { Entity } from "common/entities/types";
// eslint-disable-next-line import/no-cycle
import { Groups } from "common/form";
import { Group, WidgetsOverwriteMap } from "common/form/types";
import { DismissAction } from "common/record/actions/types";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { ForeignKey } from "common/types/foreign-key";
import { Properties, Record } from "common/types/records";
import { ApiError } from "common/ui/api-error";
import { Modal } from "common/widgets/modal";
import { validateForm } from "./functions";

interface PropTypes {
  title: string;
  context: Context;
  parentRecord: ForeignKey;
  relatedEntity: Entity;
  groups: Group[];
  onSave: (value: any) => void;
  dismiss: DismissAction;
  defaultValue?: Properties;
  widgetsMap?: WidgetsOverwriteMap;
}

interface StateTypes {
  value: Properties;
  error: ApiErrorResponse;
}

export class AddRelatedEntityModal extends Component<PropTypes, StateTypes> {
  static readonly displayName = "AddRelatedEntityModal";
  constructor(props: PropTypes) {
    super(props);
    this.state = {
      value: props.defaultValue,
      error: undefined,
    };
  }

  onCancel = () => {
    const { dismiss } = this.props;
    dismiss(false);
  };

  onSubmit = () => {
    const { parentRecord, context, onSave, relatedEntity } = this.props;
    const { value } = this.state;

    const record: Record = {
      actions: [],
      properties: {
        id: parentRecord.id,
      },
      related: {
        [relatedEntity.name]: [
          {
            actions: [],
            properties: {
              ...value,
            },
          },
        ],
      },
    };

    recordsApi(context.apiCall)
      .update(parentRecord.entity, record)
      .then(() => {
        onSave(value);
        this.onCancel();
      })
      .catch((error) => this.setState({ error }));
  };

  onChange = (newValue: Properties) => {
    this.setState({
      value: newValue,
    });
  };

  render() {
    const { context, relatedEntity, title, groups, widgetsMap } = this.props;
    const { error, value } = this.state;
    return (
      <Modal
        className="x-add-related-entity"
        allowFullscreen={false}
        title={title}
        okLabel={_("Save")}
        content={
          <>
            {error ? <ApiError error={error} /> : null}
            <Groups
              context={context}
              entity={relatedEntity}
              groups={groups}
              withLinks={false}
              widgetsMap={widgetsMap}
              formValidation={undefined}
              onFormValidationChange={undefined}
              value={value}
              onChange={this.onChange}
            />
          </>
        }
        onOk={
          validateForm(groups, relatedEntity, value) ? this.onSubmit : undefined
        }
        onCancel={this.onCancel}
      />
    );
  }
}
