import { ApiCall } from "common/types/api";
import { getTimestamp } from "common/date-time/common";
import { FileType } from "common/types/media";

export const avatarUrl = "api/ui/avatar";

export const avatarApi = (apiCall: ApiCall) => ({
  upload: (file: FileType) =>
    apiCall("post", `/${avatarUrl}`, file, { upload: true }).then(
      () => `/${avatarUrl}?ts=${getTimestamp()}`,
    ),
  remove: () => apiCall("delete", `/${avatarUrl}`),
});
