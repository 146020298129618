import { DependenciesComp } from "common/with-dependencies";
// eslint-disable-next-line import/no-cycle
import { WorkOrderRecordAction } from "./action";
import {
  getRecordsForActionQuery,
  getCheckProceduresCall,
  getActionSignedRecords,
} from "./functions";
import { WorkOrderActionDependencies, WorkOrderActionPropTypes } from "./types";

export const GenericWorkOrderAction = (props: WorkOrderActionPropTypes) => {
  const { context, entity, actionName, records = [] } = props;

  const checkProcedures = getCheckProceduresCall(
    context,
    entity,
    actionName,
    records,
  );

  const properties = getRecordsForActionQuery(
    context,
    entity,
    actionName,
    records,
  );

  const signatures = getActionSignedRecords(
    context,
    entity,
    actionName,
    records,
  );

  return (
    <DependenciesComp<WorkOrderActionDependencies>
      dependencies={{
        checkProcedures,
        properties,
        signatures,
      }}
      child={<WorkOrderRecordAction {...props} />}
    />
  );
};

GenericWorkOrderAction.displayName = "GenericWorkOrderAction";
