import { SupportedCulture } from "common/culture/supported-cultures";

interface PropTypes {
  culture: SupportedCulture;
  keys: string[];
}

export const SourceLang = ({ culture, keys }: PropTypes) => (
  <div>
    <label className="qa-label-source">{_("Source")}</label>
    <dl className="x-language x-language-source">
      <dt className="x-title qa-title">{culture?.language}</dt>
      {keys?.map((c) => (
        <dd className="x-entry" key={c}>
          {c}
        </dd>
      ))}
    </dl>
  </div>
);
