import { Component } from "react";
import { Context } from "common/types/context";
import { Record, defaultRecord, Properties } from "common/types/records";
import { Entity } from "common/entities/types";
import { createDefaultLayout } from "common/form/functions/layout";
import { isFormValid } from "common/form/functions/validation";
import { getLocalizedName } from "common";
import { Modal } from "common/widgets/modal";
import { ValueProps } from "common/with-value-for";
// eslint-disable-next-line import/no-cycle
import { LazyOverviewForm } from "./lazy-overview";

interface PropTypes extends ValueProps<Record> {
  context: Context;
  parentEntity?: Entity;
  entity: Entity;
  field: string;
  onSave: () => void;
}

interface StateType {
  visible: boolean;
}

export class ReferenceModal extends Component<PropTypes> {
  static readonly displayName = "ReferenceModal";

  state: StateType = {
    visible: false,
  };

  onNew = () => {
    this.setState({ visible: true });
  };

  onChangeValue = (properties: Properties) => {
    const { value, onChange } = this.props;
    onChange({ ...value, properties });
  };

  onSave = () => {
    this.props.onSave();
    this.setState({ visible: false });
  };

  onOk = () => {
    const { entity, value = defaultRecord } = this.props;

    return isFormValid(entity, undefined, undefined, value.properties)
      ? this.onSave
      : undefined;
  };

  onCancel = () => {
    this.props.onChange(undefined);
    this.setState({ visible: false });
  };

  render() {
    const {
      context,
      parentEntity,
      entity,
      field,
      value = defaultRecord,
    } = this.props;
    const { visible } = this.state;
    const layout = createDefaultLayout(
      entity,
      context,
      value.properties,
      false,
    );

    return (
      <div className="x-new-record">
        <button
          className={`x-new qa-new-${field}`}
          title={_("New")}
          onClick={this.onNew}
        >
          <i className="fa fa-plus-circle" />
        </button>
        {visible ? (
          <Modal
            content={
              <LazyOverviewForm
                context={context}
                layout={layout}
                parentEntity={parentEntity}
                entity={entity}
                value={value.properties}
                onChange={this.onChangeValue}
              />
            }
            okLabel={_("Save")}
            title={`${_("New")} ${getLocalizedName(entity)}`}
            allowFullscreen={false}
            onOk={this.onOk()}
            onCancel={this.onCancel}
          />
        ) : undefined}
      </div>
    );
  }
}
