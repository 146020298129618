import { isType } from "common";
import { isRelatedEvent } from "common/functions/scheduler";
import type { Context } from "common/types/context";
import type { Crumb } from "x/layout/ribbon/breadcrumb";
import { createUrl } from "x/scheduler2/ribbon/url";
import type { EventModel, SelectedWorkOrder } from "x/scheduler2/types";

type ActiveEvent = EventModel | SelectedWorkOrder;

const isEventModel = isType<ActiveEvent, EventModel>(["eventData"]);
const isWorkOrder = isType<ActiveEvent, SelectedWorkOrder>(["record"]);

export const getEventNumber = (event: ActiveEvent) =>
  isEventModel(event) && isRelatedEvent(event?.eventData)
    ? event?.eventData?.number
    : isWorkOrder(event)
      ? event?.record?.properties?.number
      : undefined;

export const getCrumbs = (
  context: Context,
  hidePanels: boolean,
  onPreviewClose: () => void,
  eventNumber: number,
): Crumb[] => {
  if (!eventNumber) return [{ name: _("Scheduler") }];
  return [
    {
      name: _("Scheduler"),
      url: createUrl(context.site.name, hidePanels),
      onClick: onPreviewClose,
    },
    { name: eventNumber ? String(eventNumber) : `${_("Loading")}...` },
  ];
};
