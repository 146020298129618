import "common/i18n";

import { renderWithRouter } from "common/app/router";

import "bootstrap/dist/js/bootstrap.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./x-fluke.scss";
import { LayoutWithDirty as Main } from "./main-controller";

renderWithRouter(Main);
