import { Children, PropsWithChildren } from "react";

interface PropTypes {
  label: string;
  className?: string;
}

export const ListItemCell = ({
  label,
  className = "",
  children,
}: PropsWithChildren<PropTypes>) => (
  <dl className={`x-list-grid-item-cell ${className}`}>
    <dt>{label}</dt>
    {Children.toArray(children).map((c, i) => (
      <dd key={i}>{c}</dd>
    ))}
  </dl>
);

ListItemCell.displayName = "ListItemCell";
