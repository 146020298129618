import $ from "jquery";
import { identity, is, isEmpty } from "ramda";
import { setLocale } from "common/date-time/common";
import { CancellablePromise } from "common/types/promises";

interface Translations {
  [key: string]: string;
}

(window as any)._ = identity;

export const translationFactory = (data: Translations = {}) =>
  data && is(Object, data) && !isEmpty(data)
    ? (key: string) => (key in data ? data[key] : key)
    : identity;

export const isLanguageValid = (language: string) =>
  /^[a-z]+(-[a-z]+)?$/i.test(language);

const getTranslationRequestSettings = (
  language: string,
): JQueryAjaxSettings => ({
  beforeSend: (xhr: JQuery.jqXHR) => {
    if (xhr.overrideMimeType) {
      xhr.overrideMimeType("application/json");
    }
  },
  method: "get",
  dataType: "json",
  url: `/culture/${language}/locale.json`,
  xhrFields: { withCredentials: true },
});

export const setupLocale = (language: string): CancellablePromise<void> => {
  if (!isLanguageValid(language)) {
    return CancellablePromise.reject(`Unsupported language: ${language}`);
  }

  const ajaxCall = $.ajax(getTranslationRequestSettings(language));

  const promise = new Promise<void>((resolve, reject) => {
    ajaxCall.then(
      (data: Translations) => {
        (window as any)._ = translationFactory(data);
        resolve();
      },
      () => {
        // eslint-disable-next-line
        console.warn(
          `There seems to be a problem with a translation file for "${language}"`,
        );
        reject();
      },
    );
  }).then(() => setLocale(language));

  return new CancellablePromise(promise, () => ajaxCall.abort());
};
