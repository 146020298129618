import { withValue, WithValue } from "common/with-value";
import { getOperators } from "common/entities/data-types";
import { SelectField } from "common/query/types";
import { ColumnDefinition } from "common/query/advanced-types";
import { Operators as OperatorsComponent } from "../operators";

interface PropTypes {
  columnDefinition: ColumnDefinition;
  disabled?: boolean;
}

type Props = PropTypes & WithValue<string>;

const OperatorsComp = ({
  columnDefinition,
  disabled,
  value,
  onChangeSetValue,
}: Props) => {
  const { dataType, required, localizedName } = columnDefinition.column;

  if (dataType === "bool") return <span />;

  const dataTypeForJoins = (columnDefinition.item as SelectField).fn
    ? "float"
    : dataType;

  return (
    <OperatorsComponent
      title={`${localizedName} ${_("filter")}`}
      operators={getOperators(dataTypeForJoins, required)}
      disabled={disabled}
      onChange={onChangeSetValue}
      value={value}
    />
  );
};

export const Operators = withValue<string, PropTypes>(
  OperatorsComp,
  "Operators",
);
