import { JSX } from "react";
import { ITooltip } from "react-tooltip";
import {
  ButtonSize,
  ButtonType,
  getButtonComp,
  HtmlButtonType,
} from "common/ui/buttons";

interface PropTypes {
  loading: boolean;
  idleText: any;
  loadingText: any;
  onClick?: () => any;
  disabled?: boolean;
  type?: ButtonType;
  size?: ButtonSize;
  className?: string;
  title?: string;
  htmlButtonType?: HtmlButtonType;
  tooltip?: string | JSX.Element;
  tooltipVariant?: ITooltip["variant"];
}

export const LoadingButton = ({
  loading,
  idleText,
  loadingText,
  onClick,
  disabled,
  type,
  size,
  className,
  title,
  htmlButtonType,
  tooltip,
  tooltipVariant,
}: PropTypes) => {
  const Comp = getButtonComp(type, size);
  return loading ? (
    <Comp
      className={className}
      disabled={true}
      title={title}
      type={htmlButtonType}
      tooltip={tooltip}
      tooltipVariant={tooltipVariant}
    >
      {loadingText} <i className="fa fa-spin fa-spinner" />
    </Comp>
  ) : (
    <Comp
      className={className}
      disabled={disabled}
      onClick={onClick}
      title={title}
      type={htmlButtonType}
      tooltip={tooltip}
      tooltipVariant={tooltipVariant}
    >
      {idleText}
    </Comp>
  );
};

LoadingButton.displayName = "LoadingButton";
