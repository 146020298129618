import { ValueComponent } from "common/with-value-for";
import { Properties } from "common/types/records";
import { recordsApi } from "common/api/records";
import { Reload } from "common/record/types";
import { canDo } from "common/entities";
import { StandardRelated } from "../standard-related";
import { PropTypes as TablePropTypes } from "../table-with-form/types";
import { RelatedValue } from "../types";
import { SetRecordAs } from "../common/set-record-as";
import { noDuplicateContactId, getRelatedRecordIds } from "../common/functions";
import { updateEntity, updateDefaultForm } from "./functions";

interface PropTypes extends TablePropTypes {
  reload: Reload;
}

export class Approver extends ValueComponent<RelatedValue, PropTypes> {
  static readonly displayName = "Approver";

  displaySetGroupOwner = (properties: Properties, close: () => void) => {
    const { reload } = this.props;
    return (
      <SetRecordAs
        message={_("Set as the group owner?")}
        recordId={properties.id}
        onCancel={close}
        onSet={this.onSetGroupOwner}
        onComplete={reload}
      />
    );
  };

  isDefaultGroupOwner = (properties: Properties) => !!properties.groupOwner;

  onSetGroupOwner = (id: string) => {
    const { context, entity } = this.props;
    return recordsApi(context.apiCall).setGroupOwner(entity.name, id);
  };

  render() {
    const { context, entity, value, defaultForm } = this.props;

    const ids = getRelatedRecordIds(entity.name, "contactId", value);

    return (
      <StandardRelated
        {...this.props}
        context={noDuplicateContactId(context, entity, ids)}
        entity={updateEntity(entity)}
        defaultForm={updateDefaultForm(defaultForm, value, entity)}
        actionsWithContent={
          canDo(entity, "SetAsGroupOwner")
            ? [
                {
                  name: "SetDefault",
                  fn: this.displaySetGroupOwner,
                  isDisabled: this.isDefaultGroupOwner,
                },
              ]
            : undefined
        }
      />
    );
  }
}
