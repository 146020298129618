import * as R from "ramda";
import { defaultFor } from "common";
import { commentsApi } from "common/api/comments";
import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";
import { merge1 } from "common/merge";
import {
  getTemporaryIdPropsIfNeeded,
  mergePropertiesWithFormValue,
} from "common/record/edit/value";
import {
  FormValue,
  Reload,
  StandardUiValue,
  StandardValue,
} from "common/record/types";
import { applyChangesToRecord } from "common/record/utils";
import { RequestOptions } from "common/types/api";
import { Context } from "common/types/context";
import { Form } from "common/types/forms";
import { CancellablePromise } from "common/types/promises";
import { Properties, Record } from "common/types/records";
import { getWindowLocation } from "common/utils/window-location";
import { ValueProps } from "common/with-value-for";

export const applyFormToRecord = (newForm: Form, record: Record): Record => {
  const { properties } = record;
  const { defaults = {} } = newForm.settings;

  const propsWithDefault = R.mapObjIndexed(
    (val, key) =>
      val != null ? val : defaults[key] != null ? defaults[key] : null,
    properties,
  );
  const propsWithFormId = merge1("formId", newForm.id, propsWithDefault);
  return merge1("properties", propsWithFormId, record);
};

export const hasPendingChanges = (
  ui: StandardUiValue = defaultFor<StandardUiValue>(),
): boolean =>
  ui &&
  !!ui.detail &&
  ((!!ui.detail && !!ui.detail.form && ui.detail.isDirty) ||
    (!!ui.related && ui.related.isDirty));

export const isDelete = (record: Record): boolean =>
  record.related &&
  R.any((r) => r.properties.isDeleted, R.flatten(R.values(record.related)));

export const getFormTemporaryIdsMapper =
  (entity: Entity, defaultForm: Properties) =>
  (dependencies: { defaultValue: FormValue }) => ({
    ...dependencies,
    defaultValue: mergePropertiesWithFormValue(
      dependencies?.defaultValue,
      getTemporaryIdPropsIfNeeded(entity, defaultForm),
    ),
  });

const defaultValue = defaultFor<StandardValue>();
const defaultUiValue = defaultFor<StandardUiValue>();
const defaultRecord = defaultFor<Record>();

interface FormSaveProps extends ValueProps<StandardValue> {
  onHasChanged?: (isDirty: boolean) => any;
  reload: Reload;
  save: (
    record: Record,
    confirmDelete: boolean,
    requestOptions?: RequestOptions,
  ) => CancellablePromise<any>;
}

export const onFormSave = ({
  value = defaultValue,
  save,
  onHasChanged,
  reload,
  onChange,
}: FormSaveProps) => {
  const { record = defaultRecord, ui = defaultUiValue } = value;
  const newRecord = applyChangesToRecord(record, ui);

  save(newRecord, isDelete(newRecord)).then(() => {
    onChange({
      record: newRecord,
      ui: {
        ...value.ui,
        detail: { ...value.ui?.detail, form: undefined, isDirty: false },
        related: { ...value.ui?.related, form: undefined, isDirty: false },
      },
    });
    if (onHasChanged) {
      onHasChanged(false); // reset dirty flag
    }
    reload();
  });
};

interface RetrieveCommentsCountProps {
  updateCommentsCount: (count: number) => void;
  entity: Entity;
  context: Context;
  value: StandardValue;
}

export const retrieveCommentsCount = ({
  context,
  entity,
  value,
  updateCommentsCount,
}: RetrieveCommentsCountProps) =>
  behaveAs("Comments", entity) && value.record?.properties?.id
    ? commentsApi(context.apiCall)
        .get(entity.name, value.record.properties.id, 0, 1)
        .then((pagedComments) => {
          updateCommentsCount(pagedComments.count);
        })
        .catch(() => false)
    : CancellablePromise.resolve();

export const getDefaultFormTab = () => {
  const { href } = getWindowLocation();

  const queryString = href.includes("?") ? href.split("?")[1] : undefined;
  const searchParams = new URLSearchParams(queryString);
  return searchParams.get("tab");
};
