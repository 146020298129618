import * as R from "ramda";
import { Properties } from "common/types/records";
import { merge2 } from "common/merge";

interface WithProperties {
  properties: Properties;
}

const getId = (properties: Properties): string =>
  R.includes("tempId", R.keys(properties))
    ? `temp${properties.tempId}`
    : `num${properties.number}`;

const makePair = (properties: Properties, index: number): [string, number] => [
  getId(properties),
  index + 1,
];

export const getOrder = ({ order }: Properties) => order;

export const assignOrder = <T extends WithProperties>(records: T[] = []) => {
  const notDeleted = records
    .map((r) => r.properties)
    .filter((p) => !p.isDeleted);
  const idIndexPairs = R.sortBy(getOrder, notDeleted).map(makePair);
  const newOrders = R.fromPairs(idIndexPairs);

  return records.map((record) => {
    if (record.properties.isDeleted) return record;

    const newOrder = newOrders[getId(record.properties)];

    return merge2("properties", "order", newOrder, record);
  });
};
