import { useState } from "react";
import { lazyComponent } from "common/lazy-loading";
import { classNames } from "common/utils/jsx";
import { ValueProps } from "common/with-value-for";

import "./index.scss";

const Tinymce = lazyComponent(() =>
  import("common/vendor-wrappers/text-editor").then((m) => ({
    default: m.Tinymce,
  })),
);

interface PropTypes extends ValueProps<string> {
  // if you add props here, make sure to add the corresponding if in the
  // componentDidUpdate and change in tinymce ONLY the stuff changed
  readOnly?: boolean;
  height?: string;
}

export const LazyTextEditor = ({
  readOnly,
  height,
  value,
  onChange,
}: PropTypes) => {
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  return (
    <div
      className={classNames([
        "x-text-editor",
        readOnly ? "x-read-only" : undefined,
        isFocused ? "x-editor-focused" : undefined,
      ])}
    >
      <Tinymce
        value={value}
        readOnly={readOnly}
        height={height}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
      />
    </div>
  );
};
