import * as R from "ramda";
import { GroupedOption } from "common/vendor-wrappers/react-select/types";
import { SelectorOption } from "common/widgets/selector/types";
import { displayEntity } from "common/api/entities";
import { getFullForeignKeyLabel } from "common/functions/foreign-key";
import { getLocalizedName } from "common";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entities, Entity } from "common/entities/types";
import { Field } from "common/query-builder/types";
import { EmailForeignKey } from "common/types/address-book";
import { email } from "common/validate";
import {
  EmailField,
  TemplateField,
} from "common/widgets/email-chicklets-selector/types";
import { Context } from "common/types/context";
import { WorkflowTemplate } from "common/types/workflows";

export const getMaxLengthString = (maxLength: number) => {
  return _("You can only select a max of MAXSIZE item(s)").replace(
    "MAXSIZE",
    maxLength.toString(),
  );
};

const isFieldPlaceholder = (value: string) => /^{\/.*}$/.test(value);

export const isValidTemplateChicklet =
  (templateFields: TemplateField[]) => (chicklet: number) =>
    templateFields?.some((s) => {
      return s.value === chicklet;
    });

export const isValidChicklet = (chicklet: string, value: string[]) =>
  value.some((s) => s === chicklet) &&
  (email(chicklet) || isFieldPlaceholder(chicklet));

export const getFieldPlaceholder = (field: Field) =>
  field && field.path && `{${field.path}}`;

const formatEmailChicklet = (value: string, fields: EmailField[]) => {
  const field = R.find((f) => getFieldPlaceholder(f) === value, fields || []);
  return field ? getLocalizedName(field.parentColumn || field.column) : value;
};

export const formatChicklet = (fields: Field[]) => (chicklet: string) =>
  isFieldPlaceholder(chicklet)
    ? formatEmailChicklet(chicklet, fields)
    : chicklet;

export const formatField = (field: EmailField) => {
  const { parentColumn, column, minPath } = field;
  const columnName = getLocalizedName(column);

  return parentColumn
    ? `${getLocalizedName(parentColumn)} (${minPath}.${columnName})`
    : columnName;
};

export const getTemplateField = (field: TemplateField) => field.label;

export const formatTemplateChicklet =
  (templateFields: TemplateField[]) => (chicklet: number) => {
    if (!templateFields) return "";

    const foundField = templateFields.find((field) => field.value === chicklet);

    return foundField?.label;
  };

export const omitSelectedFields = (
  fields: EmailField[],
  value: string[],
): EmailField[] =>
  fields
    ? R.filter((f) => !R.includes(getFieldPlaceholder(f), value || []), fields)
    : [];

export const omitSelectedTemplates = (
  fields: TemplateField[],
  values: number[],
): TemplateField[] =>
  fields ? R.filter((f) => !values.includes(f.value), fields) : [];

const concatEmailField = (
  fields: EmailField[],
  entityName: string,
  column: EntityColumn,
  parentPath: string = "",
  parentColumn?: EntityColumn,
) =>
  column.dataType === "email"
    ? fields.concat({
        path: `/${parentPath ? `${parentPath}.` : ""}${column.name}`,
        minPath: entityName,
        entityName: undefined,
        column,
        ...(parentColumn && { parentColumn }),
      })
    : fields;

const getRelatedEntityName = (relatedEntity: Entity, entity: Entity) =>
  relatedEntity.name === entity.name
    ? _("Parent {ENTITY}").replace("{ENTITY}", entity.name)
    : relatedEntity.name;

const concatRelatedEmailFields = (
  entities: Entities,
  entity: Entity,
  fields: EmailField[],
  column: EntityColumn,
) => {
  const relatedEntity = entities?.[column.relatedEntity];

  if (!relatedEntity) return fields;

  const relatedColumns = relatedEntity.columns.reduce(
    (relatedAcc: Field[], relatedColumn: EntityColumn) =>
      concatEmailField(
        relatedAcc,
        getRelatedEntityName(relatedEntity, entity),
        relatedColumn,
        column.name,
        column,
      ),
    [],
  );
  return fields.concat(relatedColumns);
};

export const getEmailFields = (entities: Entities, entity: Entity) =>
  R.reduce(
    (acc: EmailField[], column: EntityColumn) =>
      column.isForeignKey
        ? concatRelatedEmailFields(entities, entity, acc, column)
        : concatEmailField(acc, entity.name, column, ""),
    [],
    (entity && entity.columns) || [],
  );

const getTemplateLabel = (label: string) => {
  switch (label) {
    case "Work Order Assignees":
      return _("Work Order Assignees");
    case "Commenters":
      return _("Commenters");
    case "Requisition Approvers":
      return _("Requisition Approvers");
    default:
      return label;
  }
};

export const getTemplateFields = (
  templates: WorkflowTemplate[],
): TemplateField[] =>
  templates?.map((workflowQuery) => {
    return {
      label: getTemplateLabel(workflowQuery.name),
      value: workflowQuery.id,
    };
  });

const getEntityRecords = (
  context: Context,
  entity: Entity,
  records: EmailForeignKey[],
  value: string[],
) => {
  return records
    .filter((fk) => email(fk.email) && !R.includes(fk.email, value || []))
    .map((fk) => {
      const foreignKeyLabel = getFullForeignKeyLabel(context, fk, entity);
      const labelWithEmail =
        foreignKeyLabel.indexOf(fk.email) !== -1
          ? foreignKeyLabel
          : `${foreignKeyLabel} - ${fk.email}`;
      return {
        value: fk,
        label: entity ? labelWithEmail : fk.title,
      };
    });
};

const getEntityTitle = (entity: Entity, entityName: string) => {
  if (entityName === "userdata") return _("Users");
  return entity ? displayEntity(entity) : entityName;
};

export const getAddressBookProcessingFn =
  (context: Context, value: string[]) =>
  (results: SelectorOption<EmailForeignKey>[]) =>
    (results ?? []).reduce((acc, option: GroupedOption<EmailForeignKey>) => {
      const entity = R.find(
        (e) => e.name.toLocaleLowerCase() === option.label,
        R.values(context.entities),
      );
      const entityRecords = getEntityRecords(
        context,
        entity,
        option.options,
        value,
      );

      return entityRecords.length
        ? acc.concat({
            label: getEntityTitle(entity, option.label),
            options: entityRecords,
          })
        : acc;
    }, []);

export const categorizeEmailsAndFields = (emailsList: string[]) =>
  emailsList.reduce(
    (acc, item) => {
      return email(item)
        ? { ...acc, emails: [...acc.emails, item] }
        : { ...acc, fields: [...acc.fields, item] };
    },
    {
      emails: [],
      fields: [],
    },
  );
