import { ValueComponent, ValueProps } from "common/with-value-for";
import { ActionItemButton } from "common/widgets/action-item";

type Value<T> = Array<T>;

interface PropTypes<T> {
  allItems: Array<T>; // used when you check all
  defaultItems: Array<T> | undefined; // used when you uncheck all
}

export type Props<T> = PropTypes<T> & ValueProps<Value<T>>;

export class CheckAllButton<T> extends ValueComponent<Value<T>, PropTypes<T>> {
  static readonly displayName = "CheckAllButton";

  checkAll = () => {
    this.setValue(this.props.allItems);
  };

  uncheckAll = () => {
    this.setValue(this.props.defaultItems, true);
  };

  render() {
    const { allItems = [], defaultItems = [], value = [] } = this.props;
    if (!allItems.length || allItems.length === defaultItems.length)
      return null;

    return (
      <div className="x-list-toggle">
        {value.length === allItems.length ? (
          <ActionItemButton
            label={_("Uncheck all")}
            clickHandler={this.uncheckAll}
          />
        ) : (
          <ActionItemButton
            label={_("Check all")}
            icon="fa fa-check-circle-o"
            clickHandler={this.checkAll}
          />
        )}
      </div>
    );
  }
}
