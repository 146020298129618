import * as R from "ramda";
import { getColumn } from "common/entities";
import { Entities, Entity } from "common/entities/types";
import { mergeChain } from "common/merge";
import { PathMap } from "common/query/advanced-types";
import { getGroupForSelect } from "common/query/select";
import { JoinItem, Query, QueryForEntity } from "common/query/types";

interface MappedJoin {
  entity: Entity;
  path: string;
}

export const mergeJoin = (
  newJoin: JoinItem,
  joins: JoinItem[] = [],
): JoinItem[] => {
  if (!newJoin) return joins;

  const { column, entity } = newJoin;
  const join = R.find((j) => j.column === column && j.entity === entity, joins);

  if (!join) return joins.concat([newJoin]);
  const mergedJoin: JoinItem = R.mergeRight(join, {
    joins: R.reduce(R.flip(mergeJoin), join.joins || [], newJoin.joins || []),
  });
  return joins.map((j) => (j === join ? mergedJoin : j));
};

export const mergeJoins = (from: JoinItem[], to: JoinItem[]) =>
  (from || []).reduce((acc, j) => mergeJoin(j, acc), to || []);

export const mergeQueryJoins = (from: QueryForEntity, to: QueryForEntity) => {
  if (!from?.query?.joins) return to;

  const newJoins = mergeJoins(from?.query?.joins, to?.query?.joins);
  const group = getGroupForSelect(to?.query?.select);

  return mergeChain(to)
    .prop("query")
    .set("joins", newJoins)
    .set("group", group)
    .output();
};

export const getJoinEntityName = (entity: Entity, join: JoinItem): string => {
  if (!join || !entity) return undefined;

  const joinEntity = join?.entity;
  if (joinEntity) return joinEntity;

  const { columns } = entity;
  const col = R.find((c) => c.name === join.column, columns);
  return col?.relatedEntity;
};

export const getJoins = (query: QueryForEntity): JoinItem[] =>
  query?.query?.joins || [];

export const getJoinPath = (parentPath: string = "", join: JoinItem) => {
  if (!join) return parentPath + "/";

  const { entity, column } = join;
  return parentPath + "/" + (entity ? entity + "." + column : column);
};

// TODO: refactor to avoid dup code here and in the compose in getPathMap
const mapJoin =
  (entities: Entities, parentEntity: Entity, parentPath: string) =>
  (join: JoinItem): MappedJoin[] => {
    const path = getJoinPath(parentPath, join);
    const name = getJoinEntityName(parentEntity, join);
    const entity = entities && entities[name];
    if (!entity) return [];

    const { joins = [] } = join;

    const mappedJoins = R.flatten(joins.map(mapJoin(entities, entity, path)));

    const currentJoinPath: MappedJoin = { entity, path };

    return [currentJoinPath, ...mappedJoins];
  };

export const getPathMap = (
  entities: Entities,
  query: QueryForEntity,
): PathMap => {
  const rootEntity = entities && entities[query && query.entity];
  if (!rootEntity) return {};
  return R.compose(
    (pairs: Array<[string, Entity]>) => R.fromPairs(pairs),
    R.map((i: MappedJoin) => [i.path, i.entity]),
    R.append<MappedJoin>({ entity: rootEntity, path: "" }),
    R.flatten,
    R.map<JoinItem, MappedJoin[]>(mapJoin(entities, rootEntity, "")),
    getJoins,
  )(query);
};

export const addJoinToQuery = (join: JoinItem, query: Query): Query => ({
  ...query,
  joins: mergeJoin(join, query.joins),
});

export const canAccessJoinEntities = (
  entities: Entities,
  joins: JoinItem[],
  entityName: string,
): boolean =>
  !!joins &&
  joins.reduce((acc: boolean, j) => {
    const joinEntity = (j && j.entity) || entityName;
    if (!entities || !entities[joinEntity]) return false;

    const column = getColumn(
      joinEntity && entities[joinEntity],
      j.column || "",
    );

    const subJoins =
      !j.joins ||
      canAccessJoinEntities(
        entities,
        j.joins,
        j.entity || column?.relatedEntity,
      );
    return acc && !!joinEntity && subJoins && !!column;
  }, true);
