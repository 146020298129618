import * as React from "react";
import { EntityColumn } from "common/entities/entity-column/types";
import { classNames } from "common/utils/jsx";
import { isRecordSelfReference } from "common/validate";
import { AlertErrorTip } from "./alert";
import { Hint } from "./hint";

interface PropTypes {
  column: EntityColumn;
  entityName: string;
  recordId: string;
  value: any;
}

export const SelfReference = ({
  column,
  entityName,
  recordId,
  value,
  children,
}: React.PropsWithChildren<PropTypes>) => {
  const isSelfReference = isRecordSelfReference(
    entityName,
    recordId,
    column,
    value,
  );
  return (
    <div
      className={classNames([
        "qa-self-reference",
        isSelfReference ? " x-has-error" : undefined,
      ])}
    >
      {children}
      {isSelfReference ? (
        <AlertErrorTip message={_("A record cannot be its own parent")} />
      ) : (
        <Hint message={_("The main record is not present in the selection")} />
      )}
    </div>
  );
};
