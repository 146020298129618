import * as React from "react";
import { toKebabCase } from "common";
import { classNames } from "common/utils/jsx";
import { ReactHighlightWords } from "common/vendor-wrappers/react-highlight-words";
import { NodeLinkProps, UNGROUPED } from "./types";

export const NodeLink = ({
  node,
  isSelected,
  textToHighlight,
  isDisabled,
  withNodeIcon,
  isExpanded,
  onExpand,
  onClick,
}: NodeLinkProps) => {
  if (!node) return null;

  const className = classNames([
    "x-tree-node-link",
    isSelected ? "selected" : undefined,
    isDisabled ? "x-disabled" : undefined,
    `qa-${toKebabCase(node.label)}`,
  ]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.code === "Enter") onClick?.();
  };

  const onLinkClick = () => {
    onClick?.();
  };

  const expandClassName = classNames([
    "fa",
    isExpanded ? "fa-angle-down" : "fa-angle-right",
  ]);
  const nodeIconClassName = classNames([
    "fa",
    node.id === UNGROUPED ? "fa-files-o" : "fa-folder-o",
  ]);

  const nodeIcon = withNodeIcon ? (
    <i className={nodeIconClassName} />
  ) : undefined;

  const icons =
    node.isGroup && onExpand ? (
      <button className="x-icons" onClick={onExpand} aria-label="Expand Group">
        <i className={expandClassName} />
        {nodeIcon}
      </button>
    ) : withNodeIcon ? (
      nodeIcon
    ) : undefined;

  const label = isDisabled ? (
    <span className="x-node-label">
      <ReactHighlightWords text={node.label} search={textToHighlight} />
    </span>
  ) : (
    <button
      className="x-node-label"
      onKeyDown={onKeyDown}
      onClick={onLinkClick}
    >
      <ReactHighlightWords text={node.label} search={textToHighlight} />
    </button>
  );

  return (
    <div className={className}>
      {icons}
      {label}
    </div>
  );
};
