import * as R from "ramda";
import { Entity } from "common/entities/types";
import { mergeChain } from "common/merge";
import { DetailUiValue } from "common/record/types";
import { Context } from "common/types/context";
import { Properties, RecordPayload } from "common/types/records";
import { SystemIntFk } from "common/types/system-int";
import { METER_READING_PROVIDED_PROCEDURE_STATUS_ID } from "common/types/system-strings";
import { ValueProps } from "common/with-value-for";
import { RelatedValue } from "../types";
import { ProcedureMeterReading } from "./meter-reading";
import {
  getAssetMeterReadingEntity,
  getMeterReadingOrDefault,
} from "./meter-reading/functions";

interface PropTypes extends ValueProps<RelatedValue> {
  context: Context;
  entity: Entity;
  parentEntity: Entity;
  properties: Properties;
  recordDetail?: DetailUiValue;
  isDisabled?: (item: SystemIntFk) => boolean;
}

export const MeterReadingStatusContent = ({
  context,
  entity,
  parentEntity,
  properties,
  recordDetail,
  isDisabled,
  value,
  onChange,
}: PropTypes) => {
  const { assetId, id } = value.record.properties;
  const asset = properties?.assetId || recordDetail?.form?.assetId || assetId;
  const assetMeterReadingEntity = getAssetMeterReadingEntity(
    context.entities,
    parentEntity,
  );

  const procedures = value.related?.form?.[entity.name] || [];
  const index = procedures.findIndex((p) => p.properties.id === properties.id);

  const onProcedureChange = (
    payload: RecordPayload,
    setFormDirty?: boolean,
  ) => {
    const meterReadingPayload = getMeterReadingOrDefault(
      assetMeterReadingEntity.name,
      payload,
    );

    const procedureStatusId = meterReadingPayload?.properties.value
      ? METER_READING_PROVIDED_PROCEDURE_STATUS_ID
      : undefined;

    const procedureWithStatus = {
      ...payload,
      properties: {
        ...payload.properties,
        procedureStatusId,
      },
    };

    const newProcedures = R.update(index, procedureWithStatus, procedures);
    const isDirty = setFormDirty || value.related.isDirty;

    onChange(
      mergeChain(value)
        .prop("related")
        .set("isDirty", isDirty)
        .prop("form")
        .set(entity.name, newProcedures)
        .output(),
    );
  };

  return (
    <ProcedureMeterReading
      context={context}
      entity={assetMeterReadingEntity}
      parentEntity={parentEntity}
      properties={properties}
      assetId={asset}
      workOrderId={id}
      isDisabled={isDisabled}
      value={procedures[index]}
      onChange={onProcedureChange}
    />
  );
};
