import { PartsDetailsResponse, SubmitAllCount } from "common/api/cycle-count";
import {
  CANCELED_STATUS_ID,
  COMPLETED_STATUS_ID,
} from "common/record/form/content/related/parts-to-be-counted/functions";
import { SubmitAllActionTypes } from "./types";

const DEFAULT_ON_HAND = 0;

export const isCountReadyToSubmit = (record: PartsDetailsResponse): boolean =>
  record?.statusId !== COMPLETED_STATUS_ID &&
  record?.statusId !== CANCELED_STATUS_ID &&
  !!record?.finalCount;

export const requiresInput = (record: PartsDetailsResponse): boolean =>
  isCountReadyToSubmit(record) &&
  (record?.finalCount > record?.onHand || record?.manualBatchSelection);

export const mapResponse = (response: PartsDetailsResponse[] = []) =>
  response.map((record) => ({
    ...record,
    onHand: record?.onHand ?? DEFAULT_ON_HAND,
    manualBatchSelection: record?.manualBatchSelection ?? false,
  }));

export const mapToSubmitAllCount = (
  records: SubmitAllActionTypes[] = [],
): SubmitAllCount[] =>
  records.map((record) => {
    const { partsToBeCountedId, finalCount, cost, batchId } = record ?? {};
    return {
      partsToBeCountedId,
      finalCount,
      cost: cost ? +cost : undefined,
      batchId: batchId === "new" ? undefined : batchId,
    };
  });

export const isReadyToSubmit = (record: SubmitAllActionTypes) => {
  const {
    partsToBeCountedId,
    manualBatchSelection,
    finalCount,
    onHand,
    batchId,
    cost,
  } = record ?? {};

  const hasBatch = !manualBatchSelection || batchId !== undefined;
  const needsCost =
    finalCount > onHand && (!manualBatchSelection || batchId === "new");

  return (
    partsToBeCountedId &&
    !!finalCount &&
    hasBatch &&
    (!needsCost || cost !== undefined)
  );
};

export const areRecordsReadyToSubmit = (
  records: SubmitAllActionTypes[] = [],
): boolean => !!records.length && records.every(isReadyToSubmit);
