import { ChartData } from "chart.js";
import { v4 as uuid } from "uuid";
import { formatTimestamp } from "common/date-time/formatters";
import { DateTimeFormat } from "common/date-time/types";
import { Entities, Entity } from "common/entities/types";
import { getWorkOrderEntity } from "common/record/actions/functions/behaviors";
import { ActionsGroup } from "common/record/actions/types";
import { getChartDataset } from "common/record/form/content/measurements/chart/functions";
import { MeasurementsResponse } from "common/record/form/content/measurements/types";
import { BLUE, DARK_GREY, LIGHT_RED } from "common/types/colors";

export const getChartData = (
  measurementsResponse: MeasurementsResponse,
  dateFormat: DateTimeFormat,
  activeIndex: number,
): ChartData => {
  const measurements = measurementsResponse?.measurements?.ms ?? [];
  const unit = measurements?.[0]?.u;

  return {
    labels: measurements.map((m) => formatTimestamp(m.t, dateFormat)),
    datasets: [
      getChartDataset(
        measurements.map((m) => m.v),
        activeIndex,
        _("Center Point"),
        unit,
        DARK_GREY,
      ),
      getChartDataset(
        measurements.map((m) => m?.vagg?.min ?? 0),
        activeIndex,
        _("Min"),
        unit,
        BLUE,
        true,
      ),
      getChartDataset(
        measurements.map((m) => m?.vagg?.max ?? 0),
        activeIndex,
        _("Max"),
        unit,
        LIGHT_RED,
        true,
      ),
    ],
  };
};

export const getImageActions = (
  entities: Entities,
  entity: Entity,
  imagePath: string,
): ActionsGroup[] => {
  return [
    [
      "common",
      [
        {
          name: "Create",
          label: _("Create Work Order"),
          target: {
            column: "assetId",
            entity: getWorkOrderEntity(entities, entity),
          },
          extraProperties: { $id: uuid() },
          data: { imagePath },
        },
      ],
    ],
  ];
};
