import { JSX } from "react";
import { getTooltipProps } from "common/vendor-wrappers/react-tooltip";

interface PropTypes {
  label: string | JSX.Element;
  onRemove: (e: any) => any;
  blockedMessage?: string;
  className?: string;
}

export const EntryBox = ({
  label,
  onRemove,
  className,
  blockedMessage,
}: PropTypes) => (
  <div className={`x-entry-box ${className ? className : ""}`}>
    <div className="x-entry">{label}</div>
    {onRemove ? (
      <button onClick={onRemove} className="x-action-icon">
        <i className="fa fa-times-circle" />
      </button>
    ) : (
      <button
        onClick={undefined}
        className="x-action-icon"
        {...getTooltipProps(blockedMessage, "light")}
      >
        <i className="fa fa-info-circle" />
      </button>
    )}
  </div>
);

EntryBox.displayName = "EntryBox";
