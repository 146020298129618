import { Context } from "common/types/context";
import { getEntityIcon, getEntitySiteLabel } from "../functions";
import { SearchRecord } from "../types";
import { SearchListItem } from ".";

interface PropTypes {
  context: Context;
  record: SearchRecord;
  textToHighlight: string;
  onClickRecord: () => void;
}

export const EntitySearchItem = ({
  context,
  record,
  textToHighlight,
  onClickRecord,
}: PropTypes) => {
  const { site, parentEntity, entity, id } = record;
  const hrefSite = site ?? context.site.name;
  const hrefEntity = parentEntity ?? entity;
  const url = `/#/${hrefSite}/${hrefEntity}/${id}`;
  const entityLabel = getEntitySiteLabel(record, context);
  const iconClass = getEntityIcon(entity, context);

  return (
    <SearchListItem
      record={record}
      textToHighlight={textToHighlight}
      onClickRecord={onClickRecord}
      url={url}
      iconClass={iconClass}
      entityLabel={entityLabel}
    />
  );
};
