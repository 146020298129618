import { Entities, Entity } from "common/entities/types";
import { LookupQuery } from "common/form/types";
import { findEntityByArgs } from "common/functions/entity";
import { addFilterToQuery } from "common/query/filter";
import { addJoinToQuery } from "common/query/joins";
import { Filter, JoinItem, QueryForEntity } from "common/query/types";
import { Context } from "common/types/context";
import { APPROVAL_COST_CENTER } from "../consts";

export const getCostCenterQuery = (
  entities: Entities,
  entity: Entity,
  approvalGroupId: string,
  withDefaultFilter: boolean,
): QueryForEntity => {
  const approvalGroupName = entity?.arguments?.approvalGroupEntity;
  const approvalCostCentersEntity = findEntityByArgs(
    entities,
    "ApprovalCostCenter",
    "approvalGroupEntity",
    approvalGroupName,
  );

  const { query } = approvalCostCentersEntity;

  const join: JoinItem = { column: "approvalGroupId" };
  const queryWithJoin = addJoinToQuery(join, query);

  const basicFilters: Filter = {
    and: [
      { name: "isDeleted", op: "isfalse" },
      {
        name: "id",
        op: "eq",
        path: "/approvalGroupId",
        value: approvalGroupId,
      },
    ],
  };
  const queryWithIdFilter = addFilterToQuery(basicFilters, queryWithJoin);

  const defaultFilter: Filter = { name: "isDefaultCostCenter", op: "istrue" };
  const queryWithDefaultFilter = withDefaultFilter
    ? addFilterToQuery(defaultFilter, queryWithIdFilter)
    : queryWithIdFilter;

  return {
    entity: approvalCostCentersEntity.name,
    query: queryWithDefaultFilter,
  };
};

export const createCostCenterLookUpQuery = (
  context: Context,
  entity: Entity,
  approvalGroupId: string,
): LookupQuery => {
  const { entities } = context;

  const query = getCostCenterQuery(entities, entity, approvalGroupId, false);

  return {
    columnName: APPROVAL_COST_CENTER,
    entityName: query.entity,
    query: query.query,
  };
};
