import { WorkOrderPreparedEvent } from "common/types/events";
import { UiFormat } from "common/types/ui-format";
import { DateTimeLabel } from "common/widgets/date/date-time-label";
import { Row } from "../row";

interface PropTypes {
  event: WorkOrderPreparedEvent;
  uiFormat: UiFormat;
}

export const WorkOrderPrepared = ({ event, uiFormat }: PropTypes) => (
  <div>
    <legend>{_("Details")}</legend>
    <Row label={_("Prepared On")}>
      <DateTimeLabel uiFormat={uiFormat} value={event.details.occurredOn} />
    </Row>
  </div>
);

WorkOrderPrepared.displayName = "WorkOrderPrepared";
