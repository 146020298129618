import { ChangeEvent } from "react";
import { ApiCall } from "common/types/api";
import { defaultFor } from "common";
import { ValueComponent } from "common/with-value-for";
import { recordsApi } from "common/api/records";
import { RequiredField } from "common/ui/required-field";
import { hasProtectedColumns } from "common/record/utils";
import { PropTypes } from "../types";
import { Action } from "../action";

export interface RejectValue {
  comments: string;
}

const defaultValue = defaultFor<RejectValue>();

export class Reject extends ValueComponent<RejectValue, PropTypes> {
  static readonly displayName = "Reject";

  onOk = (apiCall: ApiCall, dismiss: () => void) => {
    const { records = [], value = defaultValue, entity } = this.props;
    return recordsApi(apiCall)
      .reject(entity?.name, records[0]?.properties?.id, value.comments)
      .then(dismiss);
  };

  onCommentsChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.mergeValue({ comments: e.target.value });
  };

  render() {
    const { value = defaultValue } = this.props;
    const { context, dismiss, entity, records = [] } = this.props;

    return (
      <Action
        requiresAuthentication={hasProtectedColumns(entity)}
        context={context}
        dismiss={dismiss}
        entity={entity}
        records={records}
        title={_("Reject")}
        btnLabel={_("Reject")}
        onOk={value.comments && this.onOk}
      >
        <RequiredField
          label={_("Rejection Reason")}
          value={value.comments}
          input={
            // TODO replace me by a widget
            <textarea
              className="x-reject-reason"
              value={value.comments}
              onChange={this.onCommentsChange}
            />
          }
        />
      </Action>
    );
  }
}
