import {
  add,
  addSeconds,
  differenceInSeconds,
  getDate,
  getMonth,
  getYear,
  sub,
} from "date-fns";
import { toDate } from "date-fns-tz";
import { getEndOf, getStartOf } from "common/date-time/calculators";
import {
  FORMAT_DAY_ORDINAL,
  FORMAT_FULL_DAY,
  FORMAT_FULL_MONTH,
  FORMAT_FULL_NAMED_DAY,
  FORMAT_NAMED_MONTH,
} from "common/date-time/formats";
import { formatDate } from "common/date-time/formatters";
import {
  parseToSited,
  sitedToUtcISO,
  utcToISO,
} from "common/date-time/internal";
import { DurationUnit, UTCDateTime } from "common/date-time/types";
import { isRangeValid } from "common/date-time/validators";
import { ViewType } from "x/scheduler2/types";
import { SECONDS_IN_DAY } from "../constants";

const unitOfTimeGetters = [getYear, getMonth, getDate];
const formatSettings = {
  month: [FORMAT_FULL_MONTH, FORMAT_NAMED_MONTH],
  day: [FORMAT_FULL_NAMED_DAY, FORMAT_DAY_ORDINAL],
  week: [FORMAT_FULL_DAY, FORMAT_DAY_ORDINAL],
};

const getDurationFromView = (view: ViewType): DurationUnit => {
  switch (view) {
    case "day":
      return "days";
    case "week":
      return "weeks";
    case "month":
      return "months";
    default:
      return undefined;
  }
};

export const getStartOfForDateWithViewType = (
  date: UTCDateTime,
  view: ViewType,
): UTCDateTime => getStartOf(date, view);

export const getEndOfForDateWithViewType = (
  date: UTCDateTime,
  view: ViewType,
): UTCDateTime => getEndOf(date, view);

export const getPreviousDate = (
  date: UTCDateTime,
  view: ViewType,
): UTCDateTime => {
  const sitedDate = parseToSited(date);
  const duration = getDurationFromView(view);

  return duration && sitedDate
    ? sitedToUtcISO(sub(sitedDate, { [duration]: 1 }))
    : undefined;
};

export const getNextDate = (date: UTCDateTime, view: ViewType): UTCDateTime => {
  const sitedDate = parseToSited(date);
  const duration = getDurationFromView(view);

  return duration && sitedDate
    ? sitedToUtcISO(add(sitedDate, { [duration]: 1 }))
    : undefined;
};

const removeLastUnits = (format: string, numberOfTokens: number) =>
  format.replace(new RegExp(`(\\W\\w+){${numberOfTokens}}$`), "");

const formatRangeCompactly = (
  from: UTCDateTime,
  to: UTCDateTime,
  format: string,
  maxRemovableUnits: number,
) => {
  const sitedFrom = parseToSited(from);
  const sitedTo = parseToSited(to);

  for (let i = 0; i <= maxRemovableUnits; ++i) {
    const unitGetter = unitOfTimeGetters[i];
    if (unitGetter(sitedFrom) !== unitGetter(sitedTo)) {
      const shortFormat = removeLastUnits(format, i);
      return `${formatDate(from, shortFormat)} - ${formatDate(to, format)}`;
    }
  }
  return formatDate(to, format);
};

/**
 * This function formats tries to be clever while formatting the range
 * between given `from` and `to` of a given `range`. It produces the shortest
 * description possible.
 *
 * Monthly view examples:
 *
 * - `August 2019` instead of `August 2019 - August 2019` (same month and year)
 * - `August - September 2019` instead of `August 2019 - September 2019` (different month, same year)
 * - `December 2019 - January 2020` (different month and year)
 *
 * @param from
 * @param to
 * @param viewType
 */
export const formatRange = (
  from: UTCDateTime,
  to: UTCDateTime,
  viewType: ViewType,
) => {
  if (!isRangeValid(from, to)) return "";

  const [format, baseUnit] = formatSettings[viewType] || formatSettings.week;
  const formatSplit = format.split(new RegExp(`(?:^|\\W)${baseUnit}(?:\\W|$)`));
  const maxRemovableUnits = (formatSplit[1] || "").split(/\W/).length;

  return formatRangeCompactly(from, to, format, maxRemovableUnits);
};

export const getCenterDate = (from: UTCDateTime, to: UTCDateTime) => {
  if (!isRangeValid(from, to)) return undefined;

  const sitedFrom = parseToSited(from);
  const sitedTo = parseToSited(to);

  const difference = differenceInSeconds(sitedTo, sitedFrom);
  return addSeconds(sitedFrom, difference / 2);
};

export const getCenterDateISO = (from: Date, to: Date) => {
  if (from >= to) return undefined;

  const difference = differenceInSeconds(to, from);
  return utcToISO(addSeconds(from, difference / 2));
};

export const addDateDuration = (
  date: UTCDateTime | Date,
  amount: number,
  unit: keyof Duration,
) => (amount && unit ? add(toDate(date), { [unit]: amount }) : date);

export const isWeekSelected = (from: Date, to: Date): boolean => {
  const sitedFrom = parseToSited(from);
  const sitedTo = parseToSited(to);

  return differenceInSeconds(sitedTo, sitedFrom) > SECONDS_IN_DAY;
};
