import { Component } from "react";
import { getLocalizedName, getTrue } from "common";
import { GetCached, getCached } from "common/cache";
import { deepEqual } from "common/component";
import { FieldSelector } from "common/query-builder/field-selector";
import { GroupItem, isGroupField, QueryForEntity } from "common/query/types";
import { Context } from "common/types/context";
import { ActionButtonSmall } from "common/ui/buttons";
import { createList } from "common/widgets/list";
import { OnChange, ValueProps } from "common/with-value-for";
import { Error, Errors } from "./errors";
import { Expression } from "./expression";
import { fieldsEqual, getField, handleAdd } from "./functions";
import { Item } from "./item";
import { NotFound } from "./not-found";
import { Field } from "./types";
import { getGroupErrors, GroupError } from "./validation";

interface ItemPropTypes {
  context: Context;
  fields: Field[];
}

export class GroupListItem extends Item<GroupItem, ItemPropTypes> {
  static readonly displayName = "GroupItem";

  onExpressionChange = (expression: string) => {
    this.mergeValue({ expression });
  };

  render() {
    const { context, fields, value } = this.props;
    if (isGroupField(value)) {
      const field = getField(fields, value);
      if (!field) {
        const message = value?.name || _("Group column");
        return <NotFound message={message} />;
      }

      return (
        <input
          value={`${field.minPath}.${getLocalizedName(field.column)}`}
          readOnly={true}
        />
      );
    }

    return (
      <div className="x-no-padding x-query-builder-group-expression">
        <Expression
          context={context}
          fields={fields}
          onChange={this.onExpressionChange}
          value={value.expression}
        />
      </div>
    );
  }
}

interface PropTypes extends ValueProps<GroupItem[]> {
  context: Context;
  fields: Field[];
  query: QueryForEntity;
  withExpressions?: boolean;
}

const List = createList<GroupItem>("Group");

type Fix = () => void;

export class Group extends Component<PropTypes> {
  static readonly displayName = "Group";

  fixIndex: GetCached<Fix>;

  constructor(props: PropTypes) {
    super(props);
    this.fixIndex = getCached<Fix>((i) => () => {
      const { fields, query } = this.props;
      this.fix(getGroupErrors(fields, query)[parseInt(i)]);
    });
  }

  shouldComponentUpdate(newProps: PropTypes) {
    return (
      !deepEqual(this.props, newProps, ["fields"]) ||
      !fieldsEqual(this.props.fields, newProps.fields)
    );
  }

  onAdd = (field: Field) => {
    handleAdd(this.props.onChange, this.props.value)(field);
  };

  fix = (e: GroupError) => {
    const { value = [], onChange } = this.props;
    onChange(
      value.concat({
        name: e.name,
        path: e.path,
      }),
    );
  };

  renderItem = (item: GroupItem, onChange: OnChange<GroupItem>) => {
    const { context, fields } = this.props;
    return (
      <GroupListItem
        context={context}
        fields={fields}
        value={item}
        onChange={onChange}
      />
    );
  };

  render() {
    const { fields = [], query, withExpressions, value, onChange } = this.props;

    const usedFields = (value || []).map((v) => getField(fields, v));

    const availableFields = fields.filter((f) => !usedFields.includes(f));

    const errors: Error[] = getGroupErrors(fields, query).map((e, i) => ({
      message: e.message,
      buttons: e.name
        ? [
            <ActionButtonSmall key="fix" onClick={this.fixIndex(`${i}`)}>
              {_("Fix")}
            </ActionButtonSmall>,
          ]
        : undefined,
    }));

    return (
      <div className="x-query-builder-group">
        <FieldSelector
          value={undefined}
          fields={availableFields}
          withExpressions={withExpressions}
          onChange={this.onAdd}
        />
        <Errors errors={errors} className="x-margin-top-5" />
        {value && <hr />}
        {value && (
          <List
            onDisplay={this.renderItem}
            canDelete={getTrue}
            value={value}
            onChange={onChange}
          />
        )}
      </div>
    );
  }
}
