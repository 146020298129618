export interface ThemeType {
  id: string;
  label: string;
}

export const defaultTheme = "light";

interface ThemeData {
  theme: string;
}

const themeData: ThemeData = {
  theme: undefined,
};

export const themes = (): ThemeType[] => [
  { id: "light", label: _("Light") },
  { id: "dark", label: _("Dark") },
  { id: "auto", label: _("Use System Settings") },
];

const getSystemTheme = () =>
  window.matchMedia?.("(prefers-color-scheme: dark)")?.matches
    ? "dark"
    : "light";

const setThemeAttribute = (themeSelector: string) => {
  const theme = themeSelector === "auto" ? getSystemTheme() : themeSelector;

  if (theme) {
    themeData.theme = theme;
    document.documentElement.setAttribute("data-theme", theme);
    document.documentElement.setAttribute("data-bs-theme", theme);
  }
};

const setSystemColorSchemeListener = (theme: string) => {
  if (theme !== "auto") return;

  window
    .matchMedia?.("(prefers-color-scheme: dark)")
    .addEventListener?.("change", () => {
      setThemeAttribute("auto");
    });
};

/* 
Commented out until the dark theme is properly implemented
We need to handle 3rd party libraries properly in the dark theme
   
export const applyTheme = (newTheme: string) => {
  setThemeAttribute(newTheme);
  setSystemColorSchemeListener(newTheme);

  localStorage.setItem("theme", newTheme);
};
*/

export const applyTheme = (_: string) => {};

type RestoreProps = {
  savedTheme?: string;
  defaultTheme?: string;
};

export const restoreTheme = ({
  savedTheme,
  defaultTheme = "light",
}: RestoreProps = {}) => {
  const theme = savedTheme ?? localStorage.getItem("theme") ?? defaultTheme;

  if (!theme) return;

  setThemeAttribute(theme);
  setSystemColorSchemeListener(theme);
};

export const getTheme = () => themeData.theme;
