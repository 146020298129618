import * as R from "ramda";
import { cloneElement, Component } from "react";
import { Entity } from "common/entities/types";
import {
  createDefaultLayout,
  filterLayout,
} from "common/form/functions/layout";
import { hasRolePermissionTo } from "common/functions/roles";
import { FormSwitcher } from "common/record/form/content/related/form-switcher";
import { getDefaultValueForm } from "common/record/form/content/related/functions";
import { RelatedForm } from "common/record/form/content/related/related-form";
import { updateRecords } from "common/record/form/content/related/table-with-form/functions";
import {
  getNextRecord,
  getPreviousRecord,
  getRecordIndex,
  getRecordPropertiesSetterFn,
  isOwnerFkColumn,
} from "common/record/form/content/related/table-with-form/related-form/functions";
import { RelatedFormBaseProps } from "common/record/form/content/related/table-with-form/related-form/types";
import { defaultValue } from "common/record/form/content/related/table-with-form/types";
import { RelatedForm as RelatedFormType } from "common/record/types";
import { Form } from "common/types/forms";
import { ResolvedDependencies } from "common/with-dependencies";

interface Props extends RelatedFormBaseProps {
  layoutForm: Form;
  dependencies?: ResolvedDependencies;
}

export class EditRelatedForm extends Component<Props> {
  static readonly displayName = "EditRelatedForm";

  onSave = () => {
    const {
      dependencies,
      defaultForm,
      value = defaultValue,
      onChange,
      onCancel,
    } = this.props;
    const { records = [] } = value;

    const form = getDefaultValueForm(value.form, defaultForm, dependencies);
    const recordIndex = getRecordIndex(form, records);
    const newRecords = updateRecords(
      records,
      recordIndex,
      getRecordPropertiesSetterFn(form),
    );

    onChange({
      form: undefined,
      records: newRecords.length ? newRecords : undefined,
    });
    onCancel?.();
  };

  onPrevious = () => {
    const {
      dependencies,
      defaultForm,
      value = defaultValue,
      onChange,
    } = this.props;
    const { records = [] } = value;

    const form = getDefaultValueForm(value.form, defaultForm, dependencies);
    const recordIndex = getRecordIndex(form, records);
    const newRecords = updateRecords(
      records,
      recordIndex,
      getRecordPropertiesSetterFn(form),
    );

    onChange({
      form: getPreviousRecord(records, recordIndex),
      records: newRecords.length ? newRecords : undefined,
    });
  };

  onNext = () => {
    const {
      dependencies,
      defaultForm,
      value = defaultValue,
      onChange,
    } = this.props;
    const { records = [] } = value;

    const form = getDefaultValueForm(value.form, defaultForm, dependencies);
    const recordIndex = getRecordIndex(form, records);
    const newRecords = updateRecords(
      records,
      recordIndex,
      getRecordPropertiesSetterFn(form),
    );

    onChange({
      form: getNextRecord(records, recordIndex),
      records: newRecords.length ? newRecords : undefined,
    });
  };

  onCancel = () => {
    const { value, onChange, onCancel } = this.props;
    onChange({ ...value, form: undefined });
    onCancel?.();
  };

  onChangeForm = (form: RelatedFormType) => {
    const { value, onChange } = this.props;
    onChange({ ...value, form });
  };

  render() {
    const {
      context,
      entity,
      recordId,
      children,
      layoutMapper = R.identity,
      custom,
      isValid = R.T,
      defaultForm,
      layoutForm,
      dependencies,
      displayTypes,
      value = defaultValue,
      onChange,
      widgetsMapper,
    } = this.props;

    const form = getDefaultValueForm(value.form, defaultForm, dependencies);
    const recordIndex = getRecordIndex(form, value.records);
    const allowPrevious = recordIndex > 0;
    const allowNext = recordIndex < value.records?.length - 1;

    const layout = layoutMapper(
      filterLayout(
        (col) => !isOwnerFkColumn(entity, col.columnName),
        layoutForm?.settings ??
          createDefaultLayout(entity, context, form, false),
      ),
    );

    const newEntity: Entity = {
      ...entity,
      columns: entity.columns.filter(
        (column) => !isOwnerFkColumn(entity, column.name),
      ),
    };

    const formSwitcher = hasRolePermissionTo(
      context.role,
      entity.name,
      "SwitchForm",
    ) ? (
      <FormSwitcher
        context={context}
        entity={entity}
        defaultForm={defaultForm}
        value={value}
        onChange={onChange}
      />
    ) : undefined;

    return children ? (
      cloneElement(children, {
        dependencies,
        layout,
        entity: newEntity,
        onSave: this.onSave,
        onCancel: this.onCancel,
        onPrevious: allowPrevious ? this.onPrevious : undefined,
        onNext: allowNext ? this.onNext : undefined,
        custom,
        widgetsMapper,
        value: form,
        defaultForm: defaultForm,
        footerContent: formSwitcher,
        onChange: this.onChangeForm,
      })
    ) : (
      <RelatedForm
        context={context}
        entity={newEntity}
        ownerId={recordId}
        layout={layout}
        defaultForm={defaultForm}
        isValid={isValid(form)}
        custom={custom}
        widgetsMapper={widgetsMapper}
        onCancel={this.onCancel}
        onSave={this.onSave}
        onPrevious={allowPrevious ? this.onPrevious : undefined}
        onNext={allowNext ? this.onNext : undefined}
        displayTypes={displayTypes}
        value={form}
        footerContent={formSwitcher}
        onChange={this.onChangeForm}
      />
    );
  }
}
