import { JSX } from "react";
import { Children } from "common/types/children";

interface PropTypes {
  button: JSX.Element;
  menu: Children;
  className?: string;
}

export const DropdownMenu = ({ button, menu, className = "" }: PropTypes) => (
  <div className={`x-dropdown-menu qa-dropdown-menu ${className}`}>
    <div
      className="x-dropdown-button qa-dropdown-button"
      data-bs-toggle="dropdown"
    >
      {button}
    </div>
    <div className="dropdown-menu qa-dropdown-items">{menu}</div>
  </div>
);
