import { ApiCall } from "common/types/api";
import type {
  LoginResult,
  LoginValue,
  CanUsePasswordAuthValue,
  PolicyValue,
  RequestValue,
  ResetValue,
} from "common/types/authentication";
import { CancellablePromise } from "common/types/promises";
import { PasswordPolicy } from "common/types/settings";

export const userAuthApi = (apiCall: ApiCall) => ({
  login: (value: LoginValue): CancellablePromise<LoginResult> =>
    apiCall("post", "api/session", value),
  logOut: () => apiCall("delete", "api/session"),
  requestNewPassword: (value: RequestValue) =>
    apiCall("post", "api/password/request", value),
  resetPassword: (value: ResetValue) => apiCall("put", "api/password", value),
  getPolicy: (value: PolicyValue) =>
    apiCall<PasswordPolicy>("post", "api/password/policy", value),
  getSsoConfiguration: (
    tenantName: string,
  ): CancellablePromise<{
    auth0ClientId: string;
    organizationId: string;
    allowPasswordLogin: boolean;
  }> => apiCall("get", `api/sso/configuration/${tenantName}`),
  singleSignOn: (
    authToken: string,
    tenantName: string,
  ): CancellablePromise<LoginResult> =>
    apiCall("post", `api/sso/${tenantName}`, undefined, {
      headers: { Authorization: "Bearer " + authToken },
    }),
  canUsePasswordAuth: (
    value: CanUsePasswordAuthValue,
  ): CancellablePromise<boolean> =>
    apiCall("post", `api/sso/can-use-password-auth`, value),
});

export const validatePassword = (
  policy: PasswordPolicy,
  password: string,
  userName: string,
): string => {
  if (!policy || !password) return "";

  const { minLength, maxLength } = policy;

  if (password.length < policy.minLength) {
    return _("Password must be at least MINLENGTH characters long").replace(
      "MINLENGTH",
      minLength.toString(),
    );
  }

  if (policy.maxLength && password.length > policy.maxLength) {
    return _("Password must be at most MAXLENGTH characters long").replace(
      "MAXLENGTH",
      maxLength.toString(),
    );
  }

  if (policy.mustContainUpper && !password.match("[A-Z]")) {
    return _("Password must contain at least one capital letter");
  }

  if (policy.mustContainNumber && !password.match("[0-9]")) {
    return _("Password must contain at least one number");
  }

  if (policy.mustContainOther && !password.match("[$@$!%*?&]")) {
    return _("Password must contain at least one special character");
  }
  if (password === userName) {
    return _("Email (Username) and password cannot be the same");
  }
  return "";
};
