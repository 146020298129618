import { DateTimeLabel } from "common/widgets/date/date-time-label";
import { Row } from "common/record/form/content/audit-trail/event-detail/row";
import { Context } from "common/types/context";
import { PdfGeneratedEvent } from "common/types/events";

interface PropTypes {
  event: PdfGeneratedEvent;
  context: Context;
}

export const PdfGenerated = ({ event, context }: PropTypes) => (
  <div>
    <legend>{_("Details")}</legend>
    <Row label={_("Print Number")}>{event.details.printNumber}</Row>
    <Row label={_("Print Date")}>
      <DateTimeLabel
        uiFormat={context.uiFormat}
        value={event.details.occurredOn}
      />
    </Row>
  </div>
);

PdfGenerated.displayName = "PdfGenerated";
