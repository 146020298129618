import type { ViewType } from "x/scheduler2/types";

export const getTitleFormat = (view: ViewType) => {
  switch (view) {
    case "week":
      return "Do MMMM yyyy";
    case "month":
      return "MMMM yyyy";
    case "day":
    default:
      return "dddd Do MMMM yyyy";
  }
};
