import { getAliasOrNameOrEntityName } from "common/query/common";
import { SelectItem } from "common/query/types";
import { ValueComponent } from "common/with-value-for";
import { Selector } from "common/widgets/selector";

interface PropTypes {
  select: SelectItem[];
}

export class DropdownSelector extends ValueComponent<string, PropTypes> {
  static readonly displayName = "DropdownSelector";

  render() {
    const { value, select = [] } = this.props;
    return (
      <Selector
        options={select.map(getAliasOrNameOrEntityName)}
        value={value}
        onChange={this.onChangeSetValue}
      />
    );
  }
}
