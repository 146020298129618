import { defaultFor } from "common";
import { Geolocation } from "common/types/geolocation";
import { COORDINATES_ZOOM, DEFAULT, NON_COORDINATES_ZOOM } from "./consts";

export const getCoordinates = (value?: Geolocation) => {
  const { lat, lng, zoom } = value ?? defaultFor<Geolocation>();
  return {
    lat: lat || DEFAULT,
    lng: lng || DEFAULT,
    zoom: zoom || (lat && lng && COORDINATES_ZOOM) || NON_COORDINATES_ZOOM,
  };
};
