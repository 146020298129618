import * as R from "ramda";
import { hasSelectWithName } from "common/query/select";
import { withValue, WithValue } from "common/with-value";
import { Entity, Entities } from "common/entities/types";
import { getActionLabel } from "common/entities/locales/actions";
import { SelectItem } from "common/query/types";
import { CardsConfig } from "common/data/types";
import { actionNeedsStatus } from "common/record/actions/functions";
import { createList } from "common/widgets/fancy-checkbox/list";
import { FancyCheckboxLarge } from "common/widgets/fancy-checkbox";
import { HorizontalField } from "common/form/ui";
import { DropdownSelector } from "./dropdown";

const except = [
  "Create",
  "Update",
  "Delete",
  "ImportCsv",
  "ExportToCsv",
  "Sign",
];

const isNotException = (cmd: string) => !R.includes(cmd, except);

type ActionsValue = string[];

interface ActionsPropTypes {
  entity: Entity;
  select: SelectItem[];
}

const ActionsList = createList<string>();

const ActionsComponent = ({
  select = [],
  entity,
  value = [],
  onChangeSetValue,
}: ActionsPropTypes & WithValue<ActionsValue>) => {
  const { commands = [] } = entity;

  const actions = R.sortBy((c: string) => c, commands.filter(isNotException));
  if (!actions.length) return null;

  const hasStatus = hasSelectWithName("status", select);
  const isDisabledFn = hasStatus ? R.always(false) : actionNeedsStatus;
  const showMessage = !!actions.filter(isDisabledFn).length;

  return (
    <div className="x-cards-actions qa-cards-actions">
      <div className="x-margin-bottom-5">{`${_("Actions")}:`}</div>
      <ActionsList
        isDisabled={isDisabledFn}
        display={getActionLabel(true)}
        items={actions}
        value={value}
        onChange={onChangeSetValue}
      />
      {showMessage ? (
        <div>
          {_(
            '*Some actions are disabled, include the "Status" column in the report select to enable them.',
          )}
        </div>
      ) : undefined}
    </div>
  );
};

const Actions = withValue<ActionsValue, ActionsPropTypes>(
  ActionsComponent,
  "ActionsComponent",
);

interface PropTypes {
  entities: Entities;
  entity: string;
  select: SelectItem[];
  showMergeOption: boolean;
}

const OutputConfigCardsComp = ({
  entities,
  entity,
  select,
  showMergeOption,
  value,
  onChangeMergeValue,
}: PropTypes & WithValue<CardsConfig>) => (
  <div>
    <HorizontalField label={_("Title")}>
      <DropdownSelector
        select={select}
        value={value.title}
        onChange={onChangeMergeValue("title")}
      />
    </HorizontalField>
    <hr />
    {showMergeOption ? (
      <FancyCheckboxLarge
        label={_("Group sites")}
        onChange={onChangeMergeValue("mergeSites")}
        value={value.mergeSites}
      />
    ) : undefined}
    <Actions
      select={select}
      entity={entities[entity]}
      onChange={onChangeMergeValue("actions")}
      value={value.actions}
    />
  </div>
);

export const OutputConfigCards = withValue<CardsConfig, PropTypes>(
  OutputConfigCardsComp,
  "OutputConfigCards",
);
