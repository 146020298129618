import { hasActionsCheck } from "common/query/table/functions";
import { ColumnDefinition } from "common/query/advanced-types";
import { TableConfig } from "common/query/table/types";
import { defaultFor } from "common/index";

type Props = {
  config: TableConfig;
  isLoading: boolean;
  columnDefinitions: ColumnDefinition[];
};

const getHeaderSize = (
  config: TableConfig = defaultFor<TableConfig>(),
  columnDefinitions: ColumnDefinition[] = [],
) =>
  columnDefinitions.length +
  (config.allowActions ? 1 : 0) +
  (config.allowSelect ? 1 : 0) +
  (config.allowMultipleSelect ? 1 : 0) +
  (config.allowStar ? 1 : 0) +
  (hasActionsCheck(config) ? 1 : 0);

export const EmptyBody = ({
  config = defaultFor(),
  isLoading,
  columnDefinitions,
}: Props) => (
  <tr className="one-row">
    <td
      className="text-center"
      colSpan={getHeaderSize(config, columnDefinitions)}
    >
      {isLoading ? undefined : _("No results")}
    </td>
  </tr>
);
