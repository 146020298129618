import { Component } from "react";
import * as R from "ramda";
import { RecordSitesProps } from "common/form/types";
import { Entity } from "common/entities/types";
import { QueryForEntity } from "common/query/types";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { ValueProps } from "common/with-value-for";
import { ForeignKeySelector } from "common/widgets/selector/foreign-key-selector";
import { AdvancedSearchCtrl } from "../record-selector/advanced-search";

interface PropTypes extends ValueProps<ForeignKey>, RecordSitesProps {
  options: ForeignKey[];
  context: Context;
  entity: Entity;
  relatedEntity: Entity;
  query: QueryForEntity;
  disabled: boolean;
  placeholder?: string;
  allowClear?: boolean;
  withLinks?: boolean;
  onRecordSelectorChange?: (records: ForeignKey[]) => void;
}

export class ForeignKeyPlusSelector extends Component<PropTypes> {
  static readonly displayName = "ForeignKeyPlusSelector";

  render() {
    const {
      context,
      entity,
      placeholder,
      allowClear,
      withLinks,
      value,
      options,
      disabled,
      relatedEntity,
      query,
      recordSites,
      onChange,
      onRecordSelectorChange,
    } = this.props;

    const uniqueOptions = R.uniqBy((o) => o.id, options);
    return (
      <>
        <div className="x-record-selector-wrapper">
          <ForeignKeySelector
            placeholder={placeholder}
            context={context}
            entity={entity}
            options={uniqueOptions}
            allowClear={allowClear}
            disabled={!uniqueOptions.length}
            value={value}
            onChange={onChange}
          />
          <AdvancedSearchCtrl
            mergeQueryWithDefault={true}
            content={[{ queryForEntity: query }]}
            context={context}
            entity={relatedEntity}
            withLinks={withLinks}
            allowAdvancedAdding={true}
            recordSites={recordSites}
            value={[value]}
            onChange={onRecordSelectorChange}
            disabled={disabled}
          />
        </div>
      </>
    );
  }
}
