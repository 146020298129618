import { Primitive } from "common/types/primitives";

interface Cache<T> {
  [key: string]: T;
}

const cacheFor = <T>(): Cache<T> => ({});

export type GetCached<T, Key = string> = (key: Key) => T;

export const getCached = <T, Key = string>(
  ctor: (key: Key) => T,
): GetCached<T, Key> => {
  const cache = cacheFor<T>();
  return (key) => cache[`${key}`] || (cache[`${key}`] = ctor(key));
};

type GetCachedPrimitive<T, Key = Primitive> = (key: Key) => T;

export const getCachedPrimitive = <T, Key = Primitive>(
  ctor: (key: Key) => T,
): GetCachedPrimitive<T, Key> => {
  const cache = cacheFor<T>();
  return (key) => {
    const keyString = `${JSON.stringify(key)}`;
    return cache[keyString] || (cache[keyString] = ctor(key));
  };
};

type GetCachedStringPrimitive<T, Key = string, Key2 = Primitive> = (
  key: Key,
  key2: Key2,
) => T;

export const getCachedStringPrimitive = <T, Key = string, Key2 = Primitive>(
  ctor: (key: Key, key2: Key2) => T,
): GetCachedStringPrimitive<T, Key, Key2> => {
  const cache = cacheFor<T>();
  return (key, key2) => {
    const keyString = `${key}-${JSON.stringify(key2)}`;
    return cache[keyString] || (cache[keyString] = ctor(key, key2));
  };
};
