import { defaultFor } from "common";
import { Entity } from "common/entities/types";
import { Properties } from "common/types/records";
import { setReadOnly } from "common/entities/entity-column/functions";
import { Context } from "common/types/context";
import {
  replaceFormsLookupQueries,
  createNoDuplicateIdLookUpQuery,
} from "../common/functions";

export const updateContext = (
  context: Context,
  entity: Entity = defaultFor<Entity>(),
  ids: string[],
) =>
  replaceFormsLookupQueries(
    context,
    entity.name,
    "supplierId",
    createNoDuplicateIdLookUpQuery(
      context,
      entity,
      ids,
      "supplierId",
      "supplierEntity",
    ),
  );

export const updateEntity = (entity: Entity = defaultFor<Entity>()) => {
  const { columns = [] } = entity;
  const updatedColumns = columns.map(setReadOnly(["isDefault"]));
  return { ...entity, columns: updatedColumns };
};

export const updateDefaultForm = (
  context: Context,
  defaultForm: Properties = defaultFor<Properties>(),
  isDefault: boolean,
) => {
  const currency = context?.currency?.id || context?.masterCurrency?.id;

  return { ...defaultForm, isDefault, currency };
};
