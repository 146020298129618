import { Context } from "common/types/context";
import { RunQuery } from "common/query/types";
import { Output } from "common/data/types";
import { ReportLayout } from "common/form/types";
import { Report as ReportWidget } from "common/reporting/report";
import { toggleable, InjectedProps } from "common/widgets/toggleable";

interface PropTypes {
  context: Context;
  runQuery: RunQuery;
  reports: ReportLayout[];
  entityName: string;
}

const ReadOnlyReportsComp = ({
  context,
  runQuery,
  reports,
  toggleFn,
  toggleOpened,
  entityName,
}: PropTypes & InjectedProps) => (
  <div className="x-reports">
    <div onClick={toggleFn} className="x-record-title x-no-select x-pointer">
      <span className={`fa fa-chevron-${toggleOpened ? "down" : "right"}`} />
      {_("Stats")}
    </div>
    <div className={`widget-body${toggleOpened ? "" : " x-hide"}`}>
      {reports.map((r, i) => {
        const boxOutput: Output = {
          type: "Box",
          config: {
            label: r.label,
            isCurrency: r.showAsCurrency,
            value: undefined,
            mergeSites: false,
            showMergeOption: false,
          },
        };

        return (
          <ReportWidget
            key={i}
            reload={undefined}
            idFilter={true}
            context={context}
            runQuery={runQuery}
            reportLayout={r}
            output={boxOutput}
            entityName={entityName}
          />
        );
      })}
    </div>
  </div>
);

export const ReadOnlyReports = toggleable({ localStorageKey: "record-stats" })(
  ReadOnlyReportsComp,
);
