import * as R from "ramda";
import { filterObject } from "common";
import { Culture } from "common/culture/supported-cultures";
import { Entities } from "common/entities/types";
import { merge2 } from "common/merge";
import { PathMap } from "common/query/advanced-types";
import { getPathMap } from "common/query/joins";
import {
  Expression,
  Field,
  Filter,
  GroupFilter,
  isAnd,
  isExpressionRule,
  isField,
  isOr,
  isSubQuery,
  isSummaryField,
  Query,
  Select,
  SelectExpression,
  SelectField,
} from "common/query/types";

const translateField = <T extends Field>(
  pathMap: PathMap,
  cultureSuffix: string,
  field: T,
): T => {
  const entity = pathMap[field.path || ""];
  const name = `${field.name}_${cultureSuffix}`;
  const columns = (entity && entity.columns) || [];

  return R.any((c) => c.name === name, columns) ? { ...field, name } : field;
};

const translateFields =
  (pathMap: PathMap, cultureSuffix: string) =>
  <F extends Field, E extends Expression>(fields: Array<F | E>): Array<F | E> =>
    fields
      ? fields.map((f) =>
          isField(f) ? translateField(pathMap, cultureSuffix, f) : f,
        )
      : fields;

export const translateFilter =
  (pathMap: PathMap, cultureSuffix: string) =>
  (filter: Filter): Filter => {
    if (!filter) return filter;

    if (isAnd(filter))
      return { and: filter.and.map(translateFilter(pathMap, cultureSuffix)) };

    if (isOr(filter))
      return { or: filter.or.map(translateFilter(pathMap, cultureSuffix)) };

    if (isSubQuery(filter)) {
      const subQueryFilter = translateFilter(
        pathMap,
        cultureSuffix,
      )(filter.queryValue?.filter) as GroupFilter;

      return merge2("queryValue", "filter", subQueryFilter, filter);
    }

    if (isExpressionRule(filter)) return filter;

    return translateField(pathMap, cultureSuffix, filter);
  };

export const translate = (
  entities: Entities,
  culture: Culture,
  entity: string,
  query: Query,
): Query => {
  const pathMap = getPathMap(entities, { entity, query });

  const sharedQueryEntities = R.values(pathMap).filter(
    (e) => e.recordScope !== "SingleSite",
  );

  if (!sharedQueryEntities.length) return query;

  const cultureSuffix = culture.replace("-", "");
  const txField = translateFields(pathMap, cultureSuffix);
  const txFilter = translateFilter(pathMap, cultureSuffix);

  const select = query.select.filter((s) => !isSummaryField(s)) as Array<
    SelectField | SelectExpression
  >;

  return {
    ...query,
    ...filterObject((_, v) => !!v, {
      select: txField(select) as Select,
      group: txField(query.group),
      order: txField(query.order),
      filter: txFilter(query.filter),
      having: txFilter(query.having),
    }),
  };
};
