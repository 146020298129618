import { ChangeEvent } from "react";
import { Context } from "common/types/context";
import { Header } from "common/form/ui";

interface PropTypes {
  context: Context;
  onChange: (type: string) => void;
}

export const TypeSelector = ({ context, onChange }: PropTypes) => {
  const onTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className="x-event-type x-padding-20">
      <Header title={_("Event type")} />
      <div className="x-event-type-wrapp">
        {context.isEnabledFeature("preventiveMaintenance") ? (
          <label className="x-se-event-type qa-preventive">
            {/* TODO replace me by a widget */}
            <input type="radio" name="type" value="P" onChange={onTypeChange} />
            <h3 className="x-se-event-type-title">
              {_("Preventive Maintenance")}
            </h3>
            {/* <p className="x-se-event-type-description"></p> */}
          </label>
        ) : undefined}
        {context.isEnabledFeature("inspectionRound") ? (
          <label className="x-se-event-type qa-inspection">
            {/* TODO replace me by a widget */}
            <input type="radio" name="type" value="I" onChange={onTypeChange} />
            <h3 className="x-se-event-type-title">{_("Inspection Round")}</h3>
            {/* <p className="x-se-event-type-description"></p> */}
          </label>
        ) : undefined}
        {context.isEnabledFeature("externalTrigger") ? (
          <label className="x-se-event-type qa-external">
            {/* TODO replace me by a widget */}
            <input type="radio" name="type" value="E" onChange={onTypeChange} />
            <h3 className="x-se-event-type-title">{_("External Trigger")}</h3>
            {/* <p className="x-se-event-type-description"></p> */}
          </label>
        ) : undefined}
      </div>
    </div>
  );
};
