import { useState, JSX, useEffect } from "react";
import { defaultFor } from "common";
import { LoadingIcon } from "common/widgets/loading-icon";
import { CancellablePromise } from "common/types/promises";
import { ValueProps } from "common/with-value-for";
import { TableConfig, TableValue } from "common/query/table/types";
import { getActionTitle } from "common/record/actions/functions/title-and-icon";
import { Modal } from "common/widgets/modal";

type Props = {
  config: TableConfig;
} & ValueProps<TableValue>;

export const ActionsModal = (props: Props) => {
  const [content, setContent] = useState<JSX.Element>();

  const { config = defaultFor(), value = defaultFor(), onChange } = props;
  const { actionsWithContent } = config;
  const { expandedAction, data } = value;

  const expandedRecordId = expandedAction?.recordId;
  const actionName = expandedAction?.actionName;
  const actionTitle = getActionTitle(actionName);
  const actionWithContent = actionsWithContent.find(
    (action) => action.name === actionName,
  );

  const defaultCloseAction = () => {
    onChange({ ...value, expandedAction: undefined });
  };
  const closeAction = actionWithContent?.onClose ?? defaultCloseAction;

  const record = data.find(
    (d) => d.id === expandedRecordId || d.tempId === expandedRecordId,
  );

  useEffect(() => {
    CancellablePromise.resolve(
      actionWithContent.fn(record, closeAction, data),
    ).then((actionContent) => {
      if (!actionContent) return closeAction();

      setContent(
        <Modal
          key={`${expandedRecordId}-modal-action`}
          className="x-related-record-modal-action"
          content={actionContent}
          size="large"
          title={actionTitle}
          hideFooter={true}
          onOk={undefined}
          onCancel={closeAction}
        />,
      );
    });
  }, [config]);

  return content ?? <LoadingIcon />;
};
