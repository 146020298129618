import { ApiCall } from "common/types/api";
import { Role } from "common/types/roles";
import { messages, withNotifications } from "common/api/with-notifications";

const getMessages = messages(() => ({
  created: _("The role was successfully created"),
  updated: _("The role was successfully updated"),
  removed: _("The role was successfully deleted"),
}));

export const rolesApi = withNotifications(getMessages, (apiCall: ApiCall) => ({
  get: (id: number) => apiCall<Role>("get", `/api/admin/roles/${id}`),

  /**
   * Returns a list of roles. When effective `true`, gets the list of roles with its included roles' permissions.
   * @param managedOnly When `true` returns only the roles which can be accessed (managed) by the logged in user.
   * @returns
   */
  list: (managedOnly: boolean = false) =>
    apiCall<Role[]>(
      "get",
      `/api/admin/roles?effective=true&managedOnly=${managedOnly}`,
    ),
  create: (role: Role) =>
    apiCall<{ id: number }>("post", "/api/admin/roles", role),
  update: (role: Role) => apiCall("put", `/api/admin/roles/${role.id}`, role),
  remove: (id: number) => apiCall("delete", `/api/admin/roles/${id}`),
}));
