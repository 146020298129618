import { WorkingMode } from "devexpress-dashboard";
import { ActionButtonSmall } from "common/ui/buttons";

export interface PropTypes {
  workingMode: WorkingMode;
  dashboardCanBeEdited: boolean;
  toggleMode: () => void;
}

export const ModeSwitcher = ({
  workingMode,
  dashboardCanBeEdited,
  toggleMode,
}: PropTypes) =>
  workingMode !== "ViewerOnly" ? (
    <ActionButtonSmall onClick={toggleMode}>
      {workingMode === "Designer"
        ? _("Switch to viewer")
        : dashboardCanBeEdited
          ? _("Edit in designer")
          : _("Create in designer")}
    </ActionButtonSmall>
  ) : null;
