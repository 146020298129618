import { Context } from "common/types/context";
import { Entity } from "common/entities/types";
import { ScheduledEventValue } from "common/record/types";
import { YesNo } from "common/widgets/radio-button-list/yes-no";
import { ValueProps } from "common/with-value-for";
import { merge3 } from "common/merge";
import { ScheduledEventRecord } from "common/types/scheduled-event";
import { VerticalField } from "common/ui/field";
import { defaultFor } from "common/index";
import { AssetListWithLabel } from "../asset-list/with-label";

type PropTypes = {
  context: Context;
  entity: Entity;
  assetEntity: Entity;
} & ValueProps<ScheduledEventValue>;

export const TriggersForm = ({
  context,
  entity,
  assetEntity,
  value,
  onChange,
}: PropTypes) => {
  const onEnabledToggleChange = (enabled: boolean) =>
    onChange(merge3("record", "properties", "enabled", enabled, value));

  const onRecordChange = (record: ScheduledEventRecord) =>
    onChange({ ...value, record });

  const { record } = value ?? defaultFor<ScheduledEventValue>();
  return (
    <div className="row">
      <div className="col-md-3 x-sidebar-records x-external-records">
        <VerticalField
          className="x-margin-bottom-10"
          label={_("Enabled")}
          input={
            <YesNo
              name="enabled"
              value={record?.properties?.enabled}
              onChange={onEnabledToggleChange}
            />
          }
        />
        <div className="x-margin-bottom-10">{_("Assets")}</div>
        <AssetListWithLabel
          context={context}
          entity={entity}
          assetEntity={assetEntity}
          value={record}
          onChange={onRecordChange}
        />
      </div>
      <div className="col-md-9 x-external-details">
        <div className="x-se-external-blurb">
          {
            <p>
              {_(
                "In this area you will be able to create WO templates for Work Orders that will be triggered by external Interfaces (e.g. Fluke Tools, SCHAD, Teletrac Navman, etc)",
              )}
            </p>
          }
          {
            <p>
              {_(
                "External Trigger WOs will be generated by alarms that were setup for each sensor in the Fluke Connect app. To tie a sensor with a specific WO Template in X5, you will need to use the format below when writing the description:",
              )}
            </p>
          }
          {
            <p>
              {_(
                "Temperature - [F] Description (e.g. [F] Internal Temperature is too high)",
              )}
            </p>
          }
          {<p>{_("Current - [AAC] Description")}</p>}
          {<p>{_("Voltage - [VAC] Description")}</p>}
        </div>
      </div>
    </div>
  );
};
