import * as R from "ramda";
import { toCamelCase } from "common";
import { hexToRgbString } from "common/functions/colors";
import { ChartData } from "common/vendor-wrappers/chartjs/types";
import { ChartConfig, CustomChartType, Data, DataItem, Series } from "./types";

const colors = [
  "#4D4D4D",
  "#5DA5DA",
  "#FAA43A",
  "#60BD68",
  "#F17CB0",
  "#B2912F",
  "#B276B2",
  "#DECF3F",
  "#F15854",
  "#F7464A",
  "#46BFBD",
  "#FDB45C",
  "#949FB1",
  "#4D5360",
];

const mapSeries =
  (config: ChartConfig) =>
  (data: Data, i: number): Series => {
    const color = config.mainColor || colors[i % colors.length];
    return {
      strokeColor: hexToRgbString(color, 0.8),
      highlightFill: hexToRgbString(color, 0.75),
      highlightStroke: hexToRgbString(color, 1),
      backgroundColor: hexToRgbString(color, 0.7),
      pointBackgroundColor: config.colors,
      pointBorderColor: config.pointBorderColor,
      pointHoverBackgroundColor: config.pointHoverBackgroundColor,
      data,
    };
  };

export const isArrayOfNumbers = (c: ChartConfig, a: Data) =>
  R.all((r) => typeof r[c.values] === "number", a);

export const oneDimensionChart = (
  data: Data,
  config: ChartConfig,
): ChartData => {
  const chartData = data.reduce((acc, d, i) => {
    const label = d[config.labels] || d[toCamelCase(config.labels)];
    return R.mergeRight(acc, {
      labels: R.append(label, acc.labels),
      data: R.append(d[config.values], acc.data),
      backgroundColor: R.append(colors[i % colors.length], acc.backgroundColor),
    });
  }, {});

  return {
    labels: chartData.labels,
    datasets: [
      {
        data: chartData.data,
        backgroundColor: chartData.backgroundColor,
      },
    ],
  };
};

export const twoDimensionChart = (
  data: Data,
  config: ChartConfig,
): ChartData => ({
  labels: data.map((d) => d[config.labels] as string),
  datasets: [
    mapSeries(config)(
      data.map((d) => d[config.values]),
      0,
    ),
  ],
});

export const twoDimensionChartMultiseries = (
  data: Data[],
  config: ChartConfig,
): ChartData => {
  // dataAndConfig :: [ [values,cfg] ]
  const dataAndConfig = R.zip(data, config.series);

  // dataItems :: [ [{label,value}] ]
  const dataItems: DataItem[][] = R.map(
    ([values, cfg]: [Data, ChartConfig]) =>
      R.map(
        (v: any): DataItem => ({
          label: v[cfg.labels],
          value: v[cfg.values],
          v,
          cfg,
        }),
      )(values),
    dataAndConfig,
  );

  const labels: string[] = R.compose(
    (l: string[]) => R.uniq(l),
    R.map((i: DataItem) => i.label),
    R.flatten, // [{label,value}]
  )(dataItems);

  const valueForLabel = (values: DataItem[], label: string): DataItem =>
    (R.find((v) => v.label === label, values) || { value: 0 }).value;

  // series :: [ [1,2,3,4,0] ] with 0 if the label is not present
  const series = dataItems.map((values) =>
    labels.map((label) => valueForLabel(values, label)),
  );

  return {
    labels,
    datasets: series.map(mapSeries(config)),
  };
};

export const MULTI_SERIES: CustomChartType[] = ["Line", "Bar"];

export const CHART_OPTIONS: CustomChartType[] = [
  "Line",
  "Bar",
  "Doughnut",
  "PolarArea",
  "Radar",
  "Pie",
];

export const CHART_DIMENSION: { [index: string]: number } = {
  Pie: 1,
  Doughnut: 1,
  PolarArea: 1,
  Bar: 2,
  Line: 2,
  Radar: 2,
};
