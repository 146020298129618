import { defaultFor } from "common";
import { FetchReport, injected } from "common/reporting/fetch";
import { Context } from "common/types/context";
import { ReportConfig } from "common/types/dashboards";
import { GoFn } from "common/types/url";
import { Report } from "./report";

interface PropTypes {
  context: Context;
  widgetLabel: string;
  reportConfig: ReportConfig;
  reload: () => void;
  goTo: GoFn;
  notOlderThan: number;
}

export const ReportController = ({
  context,
  widgetLabel,
  reportConfig,
  reload,
  goTo,
  notOlderThan,
}: PropTypes) => {
  const { output, reportId } = reportConfig || defaultFor<ReportConfig>();
  return (
    <FetchReport
      context={context}
      id={reportId}
      output={undefined}
      reportLayout={undefined}
    >
      <Report
        {...injected}
        context={context}
        widgetLabel={widgetLabel}
        output={output}
        reload={reload}
        goTo={goTo}
        notOlderThan={notOlderThan}
      />
    </FetchReport>
  );
};

ReportController.displayName = "ReportController";
