import { toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./_notification.scss";

const defaultOptions: ToastOptions = {
  autoClose: 4000,
  closeButton: false,
  hideProgressBar: true,
  position: "bottom-center",
};

export const showWarningNotification = (
  content: string,
  options: ToastOptions = {},
) => {
  toast.warning(content, { ...defaultOptions, ...options });
};

export const showInfoNotification = (
  content: string,
  options: ToastOptions = {},
) => {
  toast.success(content, { ...defaultOptions, ...options });
};

export const showErrorNotification = (
  content: string,
  options: ToastOptions = {},
) => {
  toast.error(content, { ...defaultOptions, ...options });
};
