import * as R from "ramda";
import { Context } from "common/types/context";
import { Entity } from "common/entities/types";
import { getLocalizedName } from "common";
import { EntityColumn } from "common/entities/entity-column/types";
import { Row } from "common/record/form/content/audit-trail/event-detail/row";
import { getRowContent } from "common/record/form/content/audit-trail/event-detail/events/payload/row/functions";

interface PayloadRowPropTypes {
  context: Context;
  entity: Entity;
  column: EntityColumn;
  showEmpty: boolean;
  columnName: string;
  value?: any;
  hideLinks?: boolean;
}

export const PayloadRow = ({
  context,
  entity,
  columnName,
  column,
  showEmpty,
  value,
  hideLinks,
}: PayloadRowPropTypes) => {
  const isForm = columnName === "formId" || columnName === "workOrderFormId";
  if ((!column && !isForm) || R.isNil(value) || R.isEmpty(value)) return null;

  const rowData = getRowContent(
    isForm,
    context,
    entity,
    showEmpty,
    column,
    value,
    hideLinks,
  );

  return rowData ? (
    <Row key={columnName} label={isForm ? _("Form") : getLocalizedName(column)}>
      {rowData}
    </Row>
  ) : null;
};
PayloadRow.displayName = "PayloadRow";
