import { Component } from "react";
import { Context } from "common/types/context";
import { sandboxApi } from "common/api/sandbox";
import { ActionButton } from "common/ui/buttons";
import { setLocationHref } from "common/utils/window-location";
import { CheckboxWidget } from "common/widgets/checkbox";
import { LoadingIcon } from "common/widgets/loading-icon";
import { Modal } from "common/widgets/modal";
import { AlertInfo } from "common/widgets/alert";

interface PropTypes {
  context: Context;
}

interface StateTypes {
  isModalOpened: boolean;
  isUserAgreed: boolean;
  loading: boolean;
}

export class SandboxResetForm extends Component<PropTypes, StateTypes> {
  static readonly displayName = "SanboxResetForm";
  state: StateTypes = {
    isModalOpened: false,
    isUserAgreed: false,
    loading: false,
  };

  onOpenModal = () => {
    this.setState({ isModalOpened: true });
  };

  onCloseModal = () => {
    this.setState({ isModalOpened: false });
  };

  onChangeUserAgreement = (isUserAgreed: boolean) => {
    this.setState({ isUserAgreed });
  };

  onOk = () => {
    const {
      context: {
        apiCall,
        tenant: { name },
      },
    } = this.props;

    this.setState({ loading: true });

    sandboxApi(apiCall)
      .reset(name)
      .then(() => {
        this.setState({
          isModalOpened: false,
          isUserAgreed: false,
          loading: false,
        });
        setLocationHref("/login");
      });
  };

  getContent = () => (
    <div className="x-sandbox-reset-content">
      <p>
        {_(
          "This option will overwrite all existing data within this sandbox account.",
        )}{" "}
        <b>{_("Any modification to the sandbox will be lost.")}</b>
      </p>
      <p>
        {_(
          "During this process the account will be locked, users will receive a maintenance message if currently logged in.",
        )}{" "}
        {_(
          "This process can take up to 5 minutes dependent on configuration and quality of data.",
        )}
      </p>
      <AlertInfo
        message={_(
          "Updating/Resetting this Sandbox will disable all Scheduled Work Orders and Future status Work Orders will be removed. If needed they can be enabled manually or with a mass change",
        )}
      />
      <div className="x-sandbox-reset-agreement">
        <CheckboxWidget
          value={this.state.isUserAgreed}
          className="qa-user-agreement"
          onChange={this.onChangeUserAgreement}
          label={_(
            "I understand that any configuration or data entry within this sandbox will be lost",
          )}
        />
      </div>

      {this.state.loading ? <LoadingIcon /> : undefined}
    </div>
  );

  render() {
    const { isModalOpened, isUserAgreed } = this.state;
    const onOk = isUserAgreed ? this.onOk : undefined;

    return (
      <>
        <ActionButton
          className="qa-button-sandbox-reset"
          title={_("Reset Sandbox Account")}
          onClick={this.onOpenModal}
        >
          {_("Reset Sandbox Account")}
        </ActionButton>

        {isModalOpened ? (
          <Modal
            content={this.getContent()}
            className="x-sandbox-reset-modal"
            size="large"
            title={_("Sandbox Reset")}
            okLabel={_("Reset")}
            allowFullscreen={false}
            onOk={onOk}
            onCancel={this.onCloseModal}
          />
        ) : undefined}
      </>
    );
  }
}
