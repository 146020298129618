import { FC } from "react";
import { LinkButtonLarge } from "common/ui/buttons";

import "./index.scss";

interface PropTypes {
  url: string;
}

export const NotFound: FC<PropTypes> = (props) => {
  const description = _(
    "This is a bit embarrassing, but we cannot find the page you are looking for. We hope you can forgive us, and maybe you can try to select one of the options on the menu to find your way back.",
  );
  return (
    <div className="x-error-page-content">
      <div className="x-error-status">{_("Error 404")}</div>
      <div className="x-message">
        {`${_("WHOOPS! It looks like we have broken something")}...`}
      </div>
      <div className="x-description">{description}</div>
      <LinkButtonLarge href={props.url}>{_("Go back")}</LinkButtonLarge>
    </div>
  );
};

NotFound.displayName = "NotFound";
