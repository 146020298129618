import { ESignatureSettings } from "common/types/esignature-settings";
import { ESignature } from "common/types/reasons";

export const isPasswordAndESignatureFilled = (
  eSignatureSettings: ESignatureSettings,
  reason: ESignature,
  password: string,
  hideReason?: boolean,
) => {
  if (!password) return false;

  if (hideReason) {
    return true;
  }

  if (
    eSignatureSettings?.displayReasonText &&
    eSignatureSettings?.requireReasonText &&
    !reason?.text
  )
    return false;

  if (
    eSignatureSettings?.displayReasonCode &&
    eSignatureSettings?.requireReasonCode &&
    !reason?.code
  )
    return false;

  return true;
};
