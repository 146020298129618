import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import { SelectorOption } from "common/widgets/selector/types";
import { withValue, WithValue } from "common/with-value";
import { SelectItem } from "common/query/types";
import { Selector } from "common/widgets/selector";
import { CHART_OPTIONS } from "common/data/chart/functions";
import { ChartConfig, CustomChartType } from "common/data/chart/types";
import { RequiredField } from "common/form/ui";
import { DropdownSelector } from "./dropdown";

interface PropTypes {
  select: SelectItem[];
}

const translateChartType = (chartType: CustomChartType) => {
  switch (chartType) {
    case "Bar":
      return _("Bar");
    case "Doughnut":
      return _("Doughnut");
    case "Line":
      return _("Line");
    case "Pie":
      return _("Pie");
    case "PolarArea":
      return _("Polar Area");
    case "Radar":
      return _("Radar");
    default:
      return _("Unknown");
  }
};

export const getChartTypeOptionLabel = (
  option: SelectorOption<CustomChartType>,
) => (isGroupedOption(option) ? option.label : translateChartType(option));

const OutputConfigChartComp = ({
  select,
  value,
  onChangeMergeValue,
}: PropTypes & WithValue<ChartConfig>) => (
  <div>
    <RequiredField
      className="qa-chart-type"
      label={_("Chart type")}
      value={value.type}
    >
      <Selector<CustomChartType>
        options={CHART_OPTIONS}
        getOptionLabel={getChartTypeOptionLabel}
        value={value.type}
        onChange={onChangeMergeValue("type")}
      />
    </RequiredField>
    <RequiredField
      className="qa-chart-labels"
      label={_("Labels")}
      value={value.labels}
    >
      <DropdownSelector
        select={select}
        value={value.labels}
        onChange={onChangeMergeValue("labels")}
      />
    </RequiredField>
    <RequiredField
      className="qa-chart-values"
      label={_("Values")}
      value={value.values}
    >
      <DropdownSelector
        select={select}
        value={value.values}
        onChange={onChangeMergeValue("values")}
      />
    </RequiredField>
  </div>
);

export const OutputConfigChart = withValue<ChartConfig, PropTypes>(
  OutputConfigChartComp,
  "OutputConfigChart",
);
