import { Component } from "react";
import { checkFormValidation } from "common/form/functions/validation";
import { FormValidation } from "common/form/types";
import { purchaseOrdersApi } from "common/api/purchase-orders";
import { Action } from "common/record/actions/action";
import { PropTypes } from "common/record/actions/types";
import { FormValue } from "common/record/actions/ui/purchase-order/receive-items/types";
import { hasProtectedColumns } from "common/record/utils";
import { ApiCall } from "common/types/api";
import { ApiErrorResponse } from "common/types/error";
import { CancellablePromise } from "common/types/promises";
import { PoDetails } from "common/types/purchase-orders";
import { ApiError } from "common/ui/api-error";
import { LoadingIcon } from "common/widgets/loading-icon";
import { ReceiveItemsForm } from "./form";
import { createReceivePayload, isValidForm } from "./functions";

interface StateType {
  detailsLoading: boolean;
  detailsError: ApiErrorResponse;
  details: PoDetails;
  formValue: FormValue;
  formValidation: FormValidation;
}

export class ReceiveItems extends Component<PropTypes, StateType> {
  static readonly displayName = "ReceiveItems";

  state: StateType = {
    detailsLoading: false,
    detailsError: undefined,
    details: undefined,
    formValue: { purchaseOrderItems: [], closeAfterReceive: false },
    formValidation: undefined,
  };

  componentDidMount() {
    this.loadDetails();
  }

  loadDetails = () => {
    const { context, records = [], entity } = this.props;
    const id = records[0]?.properties?.id;
    const entityName = entity?.name;

    if (entityName && id) {
      this.setState({ detailsLoading: true });

      purchaseOrdersApi(context.apiCall)
        .getDetails(entityName, id)
        .then((details) =>
          this.setState({
            details,
            formValue: {
              purchaseOrderItems: details.purchaseOrderItems,
              closeAfterReceive: false,
            },
            detailsLoading: false,
            detailsError: undefined,
          }),
        )
        .catch((error) =>
          this.setState({
            detailsLoading: false,
            detailsError: error,
          }),
        );
    }
  };

  onFormChange = (formValue: FormValue) => {
    this.setState({ formValue });
  };

  onOk = (apiCall: ApiCall, dismiss: () => void) => {
    const { entity, records } = this.props;
    const { formValue, details } = this.state;
    const id = records[0]?.properties?.id;
    const receivePayload = createReceivePayload(formValue, details);

    return purchaseOrdersApi(apiCall)
      .receiveItems(entity?.name, id, receivePayload)
      .then(dismiss)
      .catch((e: Error) => {
        this.loadDetails();
        return CancellablePromise.reject(e);
      });
  };

  onFormValidationChange = (formValidation: FormValidation) => {
    this.setState({ formValidation });
  };

  render() {
    const { context, dismiss, entity, records } = this.props;
    const { detailsLoading, detailsError, formValue, details, formValidation } =
      this.state;

    const valid = isValidForm(formValue) && checkFormValidation(formValidation);

    return (
      <Action
        requiresAuthentication={hasProtectedColumns(entity)}
        context={context}
        dismiss={dismiss}
        entity={entity}
        records={records}
        title={_("Receive")}
        btnLabel={
          formValue.closeAfterReceive
            ? _("Receive Items and Close PO")
            : _("Receive Items")
        }
        size="large"
        hideRecordNumber={true}
        onOk={valid ? this.onOk : undefined}
      >
        {detailsLoading && <LoadingIcon />}
        {detailsError && (
          <ApiError error={detailsError} className="x-margin-bottom-20-i" />
        )}
        {details && (
          <ReceiveItemsForm
            context={context}
            entity={entity}
            details={details}
            formValidation={formValidation}
            onFormValidationChange={this.onFormValidationChange}
            value={formValue}
            onChange={this.onFormChange}
          />
        )}
      </Action>
    );
  }
}
