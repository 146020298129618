import * as R from "ramda";
import { Feature, Status, statusList } from "common/types/tenants";

export const getStatusList = (currentStatus: Status) => {
  return currentStatus === "Inactive"
    ? statusList.filter((s) => s !== "Maintenance")
    : currentStatus === "Maintenance"
      ? statusList.filter((s) => s !== "Inactive")
      : statusList;
};

export const getFeatureName = (feature: Feature): string => {
  switch (feature) {
    case "calendar":
      return _("Calendar");
    case "conditionMonitoring":
      return _("Condition Monitoring");
    case "createRecordsFromWorkflows":
      return _("Create Records from Workflows");
    case "createRequisitioningFromReorderList":
      return _("Create Requisitioning From ReorderList");
    case "customWorkflows":
      return _("User Defined Functions");
    case "dashboard":
      return _("Dashboard");
    case "dataImport":
      return _("Data Import");
    case "devExpress":
      return _("DevExpress");
    case "drilldownLookups":
      return _("Drilldown Lookups");
    case "emailDomainRules":
      return _("Email Domain Rules");
    case "emailTemplate":
      return _("Email Template");
    case "entity":
      return _("Entity");
    case "entityColorCoding":
      return _("Entity Color Coding");
    case "entityTree":
      return _("Entity Tree");
    case "externalTrigger":
      return _("External Trigger");
    case "failureAnalysis":
      return _("Failure Analysis");
    case "flukeMobile":
      return _("Fluke Mobile");
    case "flukeMobileSso":
      return _("Fluke Mobile SSO");
    case "form":
      return _("Form");
    case "inspectionRound":
      return _("Inspection Round");
    case "integrations":
      return _("Integrations");
    case "inventory":
      return _("Inventory");
    case "liveAssetEventsIntegration":
      return _("LIVE-Asset Events Integration");
    case "masterUserContacts":
      return _("Master User Contacts");
    case "mobileForms":
      return _("Mobile Forms");
    case "mobileFormsForRelatedEntities":
      return _("Mobile Forms for Related Entities");
    case "partsCycleCounting":
      return _("Parts Cycle Counting");
    case "partsReorderList":
      return _("Parts Reorder List");
    case "preventiveMaintenance":
      return _("Preventive Maintenance");
    case "printableLabels":
      return _("Printable Labels");
    case "purchaseOrder":
      return _("Purchase Order");
    case "report":
      return _("Report");
    case "reportSchedule":
      return _("Report Schedule");
    case "requisitioning":
      return _("Requisition");
    case "requestorPortal2":
      return _("Requestor Portal 2.0");
    case "rimeRanking":
      return _("RIME Ranking");
    case "rule":
      return _("Rule");
    case "scheduler":
      return _("Scheduler");
    case "scheduler2":
      return _("Scheduler 2.0");
    case "sharedMultipleSitesEntity":
      return _("Shared: Multiple Sites Entity");
    case "site":
      return _("Site");
    case "systemAuditTrail":
      return _("System Audit Trail");
    case "systemQrCode":
      return _("System QR Code");
    case "teletrac":
      return _("Teletrac Navman");
    case "userDefinedLookups":
      return _("User Defined Lookups");
    case "vendorPortal":
      return _("Vendor Portal");
    case "visualTemplates":
      return _("Visual Templates");
    case "workOrder":
      return _("Work Order");
  }
};

export const isFeature = (
  feature: string,
  features: Feature[],
): feature is Feature => R.includes(feature, features);

export const isEnabledFeature = (features: Feature[], feature: Feature) =>
  R.includes(feature, features);

export const anyFeatureEnabled =
  (features: Feature[]) => (featureKeys: Feature[]) =>
    R.intersection(features, featureKeys).length > 0;
