import { TableValue } from "common/query/table/types";
import { Query } from "common/query/types";
import { Filter } from "common/types/filters";
import { Output } from "common/types/preferences";
import { FiltersUiValue } from "x/records/list/filter/filter-menu";

export interface ContentType {
  filter: Filter;
  body: TableValue;
  output: Output;
  filtersUi: FiltersUiValue;
}

export const defaultValue: ContentType = {
  filter: undefined,
  body: undefined,
  output: "Table",
  filtersUi: {
    modalValue: undefined,
  },
};
export type SaveFilterState = "new" | "edit" | "update";
export type ClearFilterState = "clear" | "reset";

export interface MenuValue<TUi> {
  query: Query;
  ui: TUi;
}
