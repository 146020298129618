import { Component } from "common/component";
import { OnChange } from "common/with-value-for";

interface ItemPropTypes<V> {
  value: V;
  onChange: OnChange<V>;
}

type ItemProps<V, P> = ItemPropTypes<V> & P;

// TODO this is totally unnecessary and duplicated. remove this file

export class Item<V, P> extends Component<ItemProps<V, P>> {
  setValue = (value: V) => {
    this.props.onChange(value);
  };

  mergeValue = (value: any) => {
    this.setValue({ ...this.props.value, ...value });
  };

  onChangeSetValue = (value: V) => {
    this.props.onChange(value);
  };
}
