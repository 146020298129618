//                    ____
//                 _.' :  `._
//             .-.'`.  ;   .'`.-.
//    __      / : ___\ ;  /___ ; \      __
//  ,'_ ""--.:__;".-.";: :".-.":__;.--"" _`,
//  :' `.t""--.. '<@.`;_  ',@>` ..--""j.' `;
//       `:-.._J '-.-'L__ `-- ' L_..-;'     This file edit not! Generated
//         "-.__ ;  .-"  "-.  : __.-"       it was by running ./extract.sh
//             L ' /.------.\ ' J         /
//              "-.   "--"   .-"
//             __.l"-:_JL_;-";.__
//          .-j/'.;  ;""""  / .'\"-.
//        .' /:`. "-.:     .-" .';  `.
//     .-"  / ;  "-. "-..-" .-"  :    "-.
//  .+"-.  : :      "-.__.-"      ;-._   \
//  ; \  `.; ;                    : : "+. ;
//  :  ;   ; ;                    : ;  : \:
// : `."-; ;  ;                  :  ;   ,/;
//  ;    -: ;  :                ;  : .-"'  :
//  :\     \  : ;             : \.-"      :
//   ;`.    \  ; :            ;.'_..--  / ;
//   :  "-.  "-:  ;          :/."      .'  :
//     \       .-`.\        /t-""  ":-+.   :
//      `.  .-"    `l    __/ /`. :  ; ; \  ;
//        \   .-" .-"-.-"  .' .'j \  /   ;/
//         \ / .-"   /.     .'.' ;_:'    ;
//          :-""-.`./-.'     /    `.___.'
//                \ `t  ._  /
//                 "-.t-._:'

// Open season on dolphins
export const behaviors = (key: string): string => {
  switch (key) {
    case "AdjustmentEntity":
      return _("Adjustment Entity");
    case "BarcodeColumn":
      return _("Barcode Column");
    case "Description":
      return _("Description");
    case "EventAssetEntity":
      return _("Event Asset Entity");
    case "FkColumn":
      return _("Fk Column");
    case "LocationEntity":
      return _("Location Entity");
    case "MeterEntity":
      return _("Meter Entity");
    case "Name":
      return _("Name");
    case "PartChargeEntity":
      return _("Part Charge Entity");
    case "PartSupplierEntity":
      return _("Part Supplier Entity");
    case "Quantity":
      return _("Quantity");
    case "RequestEntity":
      return _("Request Entity");
    case "ScheduledEventEntity":
      return _("Scheduled Event Entity");
    case "ShippingMethodEntity":
      return _("Shipping Method Entity");
    case "TaskEntity":
      return _("Task Entity");
    case "TitleColumn":
      return _("Title Column");
    case "TransferEntity":
      return _("Transfer Entity");
    case "UnitCost":
      return _("Unit Cost");
    case "address":
      return _("Address");
    case "adjustmentDate":
      return _("Adjustment Date");
    case "adjustmentId":
      return _("Adjustment");
    case "adjustmentType":
      return _("Adjustment Type");
    case "approvalCostCenterId":
      return _("Approval Cost Center");
    case "approvalGroupId":
      return _("Approval Group");
    case "assetId":
      return _("Asset");
    case "assetMeterId":
      return _("Asset Meter");
    case "assetMeterReadingId":
      return _("Asset Meter Reading");
    case "assignmentStatusId":
      return _("Assignment Status");
    case "average":
      return _("Average");
    case "batchId":
      return _("Batch");
    case "calendarId":
      return _("Calendar");
    case "calendarIds":
      return _("Calendar Ids");
    case "closedOn":
      return _("Closed On");
    case "code":
      return _("Code");
    case "comment":
      return _("Comment");
    case "comments":
      return _("Comments");
    case "completed":
      return _("Completed");
    case "contactId":
      return _("Contact");
    case "conversionRate":
      return _("Conversion Rate");
    case "cost":
      return _("Cost");
    case "costCenterId":
      return _("Cost Center");
    case "costLimit":
      return _("Cost Limit");
    case "createdOn":
      return _("Created On");
    case "currency":
      return _("Currency");
    case "currentValue":
      return _("Current Value");
    case "date":
      return _("Date");
    case "dataSource":
      return _("Data Source");
    case "description":
      return _("Description");
    case "description_enUS":
      return _("Description_en US");
    case "details":
      return _("Details");
    case "disablePartAdjustment":
      return _("Disable Part Adjustment");
    case "email":
      return _("Email");
    case "enabled":
      return _("Enabled");
    case "externalId":
      return _("External");
    case "flukeMobileKey":
      return _("Fluke Mobile Key");
    case "followUpId":
      return _("Follow Up");
    case "formId":
      return _("Form");
    case "fromStockId":
      return _("From Stock");
    case "generateOnException":
      return _("Generate On Exception");
    case "geographical":
      return _("Geographical");
    case "geolocation":
      return _("Geolocation");
    case "globalCounter":
      return _("Global Counter");
    case "groupId":
      return _("Group");
    case "groupOwner":
      return _("Group Owner");
    case "hasProjections":
      return _("Has Projections");
    case "id":
      return _("Id");
    case "image":
      return _("Image");
    case "inReorderList":
      return _("In Reorder List");
    case "isDefault":
      return _("Is Default");
    case "isDefaultCostCenter":
      return _("Is Default Cost Center");
    case "isDeleted":
      return _("Is Deleted");
    case "itemPartId":
      return _("Item Part");
    case "lastCloseDate":
      return _("Last Close Date");
    case "lastOpenDate":
      return _("Last Open Date");
    case "liveAssetComponentId":
      return _("Live Asset Component");
    case "locationDetails":
      return _("Location Details");
    case "locationId":
      return _("Location");
    case "lowerBound":
      return _("Lower Bound");
    case "manualBatchSelection":
      return _("Manual Batch Selection");
    case "maxReadingPerDay":
      return _("Max Reading Per Day");
    case "memberId":
      return _("Member");
    case "meterId":
      return _("Meter");
    case "meterTypeId":
      return _("Meter Type");
    case "meters":
      return _("Meters");
    case "name":
      return _("Name");
    case "nextProjectionDate":
      return _("Next Projection Date");
    case "number":
      return _("Number");
    case "onHand":
      return _("On Hand");
    case "onOrderQuantity":
      return _("On Order Quantity");
    case "openedOn":
      return _("Opened On");
    case "operation":
      return _("Operation");
    case "order":
      return _("Order");
    case "orderedQty":
      return _("Ordered Qty");
    case "orgRate":
      return _("Org Rate");
    case "orgTotalCost":
      return _("Org Total Cost");
    case "orgUnitCost":
      return _("Org Unit Cost");
    case "overriddenBy":
      return _("Overridden By");
    case "ownerId":
      return _("Owner");
    case "parentId":
      return _("Parent");
    case "part":
      return _("Part");
    case "partChargeId":
      return _("Part Charge");
    case "partDescription":
      return _("Part Description");
    case "partId":
      return _("Part");
    case "partLocationId":
      return _("Part Location");
    case "partNumber":
      return _("Part Number");
    case "partSupplierId":
      return _("Part Supplier");
    case "phoneNumber":
      return _("Phone Number");
    case "pins":
      return _("Pins");
    case "pocName":
      return _("Poc Name");
    case "populateChildrenAssets":
      return _("Populate Children Assets");
    case "priorityCode":
      return _("Priority Code");
    case "procedureId":
      return _("Procedure");
    case "procedureStatusId":
      return _("Procedure Status");
    case "procedureTypeId":
      return _("Procedure Type");
    case "projectionInterval":
      return _("Projection Interval");
    case "projectionLength":
      return _("Projection Length");
    case "purchaseOrderId":
      return _("Purchase Order");
    case "purchaseOrderNumber":
      return _("Purchase Order Number");
    case "quantity":
      return _("Quantity");
    case "rangeFrom":
      return _("Range From");
    case "rangeTo":
      return _("Range To");
    case "rate":
      return _("Rate");
    case "rateTypeId":
      return _("Rate Type");
    case "reason":
      return _("Reason");
    case "receivedOn":
      return _("Received On");
    case "receivedQty":
      return _("Received Qty");
    case "rejectionReason":
      return _("Rejection Reason");
    case "reorderPoint":
      return _("Reorder Point");
    case "reorderQuantity":
      return _("Reorder Quantity");
    case "repairablePartId":
      return _("Repairable Part");
    case "requestId":
      return _("Request");
    case "requestedBy":
      return _("Requested By");
    case "requestedDeliveryDate":
      return _("Requested Delivery Date");
    case "requestedOn":
      return _("Requested On");
    case "requisitionTitle":
      return _("Requisition Title");
    case "requisitioningId":
      return _("Requisitioning");
    case "rimeRanking":
      return _("Rime Ranking");
    case "scheduledEventId":
      return _("Scheduled Event");
    case "sentOn":
      return _("Sent On");
    case "serialNumber":
      return _("Serial Number");
    case "shippingAddressId":
      return _("Shipping Address");
    case "shippingMethodId":
      return _("Shipping Method");
    case "signature":
      return _("Signature");
    case "startDate":
      return _("Start Date");
    case "status":
      return _("Status");
    case "statusId":
      return _("Status");
    case "step":
      return _("Step");
    case "stockId":
      return _("Stock");
    case "subTotal":
      return _("Sub Total");
    case "supplierId":
      return _("Supplier");
    case "suppressionDays":
      return _("Suppression Days");
    case "systemQrCodePrinted":
      return _("System Qr Code Printed");
    case "targetId":
      return _("Target");
    case "taskId":
      return _("Task");
    case "taxRate":
      return _("Tax Rate");
    case "taxRateId":
      return _("Tax Rate");
    case "taxValue":
      return _("Tax Value");
    case "templateId":
      return _("Template");
    case "toStockId":
      return _("To Stock");
    case "total":
      return _("Total");
    case "totalBeforeTax":
      return _("Total Before Tax");
    case "totalCost":
      return _("Total Cost");
    case "totalTax":
      return _("Total Tax");
    case "transactionDate":
      return _("Transaction Date");
    case "transferDate":
      return _("Transfer Date");
    case "transferId":
      return _("Transfer");
    case "trigger":
      return _("Trigger");
    case "type":
      return _("Type");
    case "unit":
      return _("Unit");
    case "unitCost":
      return _("Unit Cost");
    case "unitCostWithConversionRate":
      return _("Unit Cost With Conversion Rate");
    case "updatedOn":
      return _("Updated On");
    case "updatedSyncDate":
      return _("Updated Sync Date");
    case "upperBound":
      return _("Upper Bound");
    case "useMaxQuantityReorder":
      return _("Use Max Quantity Reorder");
    case "value":
      return _("Value");
    case "workOrderForm":
      return _("Work Order Form");
    case "workOrderFormId":
      return _("Work Order Form");
    case "workOrderId":
      return _("Work Order");
    default:
      return undefined;
  }
};
