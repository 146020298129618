import { defaultFor } from "common";
import { recordsApi } from "common/api/records";
import { searchApi } from "common/api/search";
import { QueryToValue } from "common/data/query";
import { Output } from "common/data/types";
import { merge1, merge2, mergeChain } from "common/merge";
import { QueryForEntity } from "common/query/types";
import { Site } from "common/types/sites";
import { PropTypes } from "x/dashboard/widgets/types";
import { isBoxConfig, isTableConfig } from "./functions";

interface Props extends PropTypes {
  site: Site;
}

export const getDataOutput = (output: Output, widgetLabel: string) => {
  const { config, type } = output;
  return type === "Box" && isBoxConfig(config)
    ? merge1("config", merge1("label", widgetLabel, config), output)
    : isTableConfig(config)
      ? mergeChain(output)
          .prop("config")
          .setMany({
            allowOrder: true,
            allowDrag: true,
          })
          .output()
      : output;
};

export const DataReport = ({
  context,
  widgetLabel = "",
  output,
  query,
  notOlderThan,
  reload,
  goTo,
  site,
}: Props) => {
  const { config } = output || defaultFor<Output>();
  const { sites = [], createContextForSiteWithFallback } = context;
  const { name = "", label = "" } = site || defaultFor<Site>();

  const contextWithSite = createContextForSiteWithFallback(name);
  const mergeSiteClass =
    isTableConfig(config) && config?.mergeSites ? "x-merged-view" : "";
  const dataOutput = getDataOutput(output, widgetLabel);
  const runQuery = (query: QueryForEntity) =>
    searchApi(contextWithSite.apiCall).runQueryWithContext(query, undefined);

  const runActionsQuery = (entity: string, recordIds: string[]) =>
    recordsApi(context.apiCall).getRecordsActions(entity, recordIds);

  return (
    <div key={name} className={mergeSiteClass}>
      {sites.length > 1 ? (
        <h3 className="x-dashboard-widget-site">{label}</h3>
      ) : undefined}
      <div className={`qa-widget-${widgetLabel.replace(/ /g, "")}`}>
        <QueryToValue
          runQuery={runQuery}
          runActionsQuery={runActionsQuery}
          context={contextWithSite}
          query={merge2("query", "pageSize", 10, query)}
          notOlderThan={notOlderThan}
          output={dataOutput}
          reload={reload}
          goTo={goTo}
          withLinks={true}
        />
      </div>
    </div>
  );
};

DataReport.displayName = "DataReport";
