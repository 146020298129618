import { Component } from "react";
import { defaultFor } from "common";
import type { Entity } from "common/entities/types";
import type { Reload } from "common/record/types";
import type { Context } from "common/types/context";
import type { ApiErrorResponse } from "common/types/error";
import type { Properties } from "common/types/records";
import { ApiError } from "common/ui/api-error";
import { DeleteButtonSmall } from "common/ui/buttons";
import { LoadingIcon } from "common/widgets/loading-icon";
import { RecordEditControllerIndex } from "x/records/edit-controller";
import type { EventModel, UserContact } from "x/scheduler2/types";
import {
  isMyPersonalEvent,
  mapPersonalEventLayout,
  wrapFormValue,
} from "./functions";

interface PropTypes {
  context: Context;
  personalEventEntity: Entity;
  event: EventModel;
  userContact: UserContact;
  formId: number;
  reload: Reload;
  onClose: () => void;
  onHasChanged?: (isDirty: boolean) => void;
}

interface StateType {
  loading: boolean;
  error: ApiErrorResponse;
  forceFetch: boolean;
}

export class PersonalEventView extends Component<PropTypes, StateType> {
  state: StateType = {
    loading: false,
    error: undefined,
    forceFetch: false,
  };

  closeAndReload = () => {
    const { onClose, reload } = this.props;
    onClose();
    reload();
  };

  reload = () => {
    this.setState({ forceFetch: true });
  };

  shouldFetch = () => {
    const { forceFetch } = this.state;
    if (forceFetch) this.setState({ forceFetch: false });
    return forceFetch;
  };

  render() {
    const {
      event,
      userContact,
      context,
      personalEventEntity,
      formId,
      onClose,
      onHasChanged,
    } = this.props;
    const { loading, error } = this.state;

    const eventId = event?.eventData?.id;
    const owner = eventId ? isMyPersonalEvent(context.userName, event) : true;
    const defProps = defaultFor<Properties>();
    const defaultProperties: Properties =
      !eventId && userContact
        ? { ...defProps, ownerId: userContact.id }
        : defProps;

    return (
      <div className="x-scheduler-panel-flyover">
        <div className="x-scheduler-personal-event">
          {loading ? <LoadingIcon /> : undefined}
          {error ? <ApiError error={error} /> : undefined}
          <>
            <div className="x-flex-between-start x-scheduler-panel-header">
              <div className="x-scheduler-info-title x-ellipsis">
                {eventId ? _("Personal Event") : _("New Personal Event")}
              </div>
              <DeleteButtonSmall className="x-margin-left-20" onClick={onClose}>
                <i className="fa fa-times" />
              </DeleteButtonSmall>
            </div>
            <RecordEditControllerIndex
              context={context}
              entity={personalEventEntity}
              id={eventId}
              eventId={eventId}
              auditTrailId={undefined}
              forceFetch={this.shouldFetch}
              wrap={wrapFormValue(owner)}
              isNew={!eventId}
              withLinks={false}
              defaultProperties={defaultProperties}
              hidePanels={true}
              hideRibbon={true}
              disableEdit={!owner}
              mapLayout={mapPersonalEventLayout}
              onHasChanged={onHasChanged}
              onSave={this.closeAndReload}
              onDelete={this.closeAndReload}
              onCancel={onClose}
              formId={formId}
              goTo={undefined}
            />
          </>
        </div>
      </div>
    );
  }
}
