const setProperty = <T, K extends keyof T>(key: K, v: T[K], obj: T): T => ({
  ...obj,
  [key as string]: v,
});

// ========================================================================== //
//
// DISCLAIMER: Consider using flatter data structures. Your life will be easier.
//
// ╔═╗┬ ┬┌─┐┌─┐┌─┐┌┬┐┌─┐  ╔╦╗┌─┐┬─┐┌─┐┌─┐  ╔═╗┌─┐┌─┐┌┬┐
// ╠═╣│││├┤ └─┐│ ││││├┤   ║║║├┤ ├┬┘│ ┬├┤   ╠╣ ├┤ └─┐ │
// ╩ ╩└┴┘└─┘└─┘└─┘┴ ┴└─┘  ╩ ╩└─┘┴└─└─┘└─┘  ╚  └─┘└─┘ ┴
//
// All the mergeN functions represent the immutable, type-safe version of
// R.assocPath, that is:
//
//    mergeN(k1, k2, ..., kN, value, obj);
//
// is the immutable version of:
//
//   obj[k1][k2][...][kN] = value;
//
// Note that mergeN will create empty objects for undefined intermediate keys,
// for example:
//
//
//   interface C { val: number }
//   interface B { c: C }
//   interface A { b: B }
//
//   const a:A = undefined;
//
//   merge3('b', 'c', 'val', 123 , a) // { b: { c: { val: 123 } } }
//
// ========================================================================== //

// The immutable version of:
//   obj[key] = value;
//
const mapProperty = <T, K extends keyof T>(
  key: K,
  fn: (child: T[K], key?: K) => T[K],
  obj: T,
): T => setProperty(key, fn(obj?.[key], key), obj);

/**
 * @deprecated Please use object spread. It's much more readable. Make sure you explicitly type your const.
 */
// The immutable version of:
//   obj[key] = value;
//
export const merge1 = setProperty;

// The immutable version of:
//   obj[k1][k2] = value;
//
export const merge2 = <T, K1 extends keyof T, K2 extends keyof T[K1]>(
  k1: K1,
  k2: K2,
  value: T[K1][K2],
  obj: T,
): T => mapProperty(k1, (child) => setProperty(k2, value, child), obj);

// The immutable version of:
//   obj[k1][k2][k3] = value;
//
export const merge3 = <
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
>(
  k1: K1,
  k2: K2,
  k3: K3,
  value: T[K1][K2][K3],
  obj: T,
): T => mapProperty(k1, (child) => merge2(k2, k3, value, child), obj);

// The immutable version of:
//   obj[k1][k2][k3][k4] = value;
//
export const merge4 = <
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3],
>(
  k1: K1,
  k2: K2,
  k3: K3,
  k4: K4,
  value: T[K1][K2][K3][K4],
  obj: T,
): T => mapProperty(k1, (child) => merge3(k2, k3, k4, value, child), obj);

// The immutable version of:
//   obj[k1][k2][k3][k4][k5] = value;
//
export const merge5 = <
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3],
  K5 extends keyof T[K1][K2][K3][K4],
>(
  k1: K1,
  k2: K2,
  k3: K3,
  k4: K4,
  k5: K5,
  value: T[K1][K2][K3][K4][K5],
  obj: T,
): T => mapProperty(k1, (child) => merge4(k2, k3, k4, k5, value, child), obj);

interface Chain<TRoot> {
  set: (key: any, value: any) => this;
  prop: (key: any) => Chain<TRoot>;
  output: () => TRoot;
  whereAmI: () => string;
}

class ChildChain<TSource, TRoot, TParent extends Chain<TRoot>>
  implements Chain<TRoot>
{
  protected changes: Partial<TSource> = {};
  constructor(
    protected source: TSource = {} as TSource,
    private parent: TParent,
    private parentKey: PropertyKey,
  ) {}
  prop<TKey extends keyof TSource>(
    key: TKey,
  ): ChildChain<TSource[TKey], TRoot, ChildChain<TSource, TRoot, TParent>> {
    return new ChildChain<
      TSource[TKey],
      TRoot,
      ChildChain<TSource, TRoot, TParent>
    >(this.source[key], this, key);
  }
  up(): TParent {
    const thisOutput = { ...this.source, ...this.changes };
    return this.parent.set(this.parentKey, thisOutput);
  }
  set<TKey extends keyof TSource>(key: TKey, value: TSource[TKey]) {
    this.changes[key] = value;
    return this;
  }
  setWith<TKey extends keyof TSource>(
    key: TKey,
    transform: (oldVal: TSource[TKey]) => TSource[TKey],
  ) {
    return this.set(key, transform(this.source[key]));
  }
  setMany(changes: Partial<TSource>) {
    this.changes = { ...this.changes, ...changes };
    return this;
  }
  output(): TRoot {
    return this.up().output();
  }
  whereAmI(): string {
    return this.parent.whereAmI() + "." + (this.parentKey || "").toString();
  }
}

class ParentChain<TSource> extends ChildChain<
  TSource,
  TSource,
  Chain<TSource>
> {
  constructor(source: TSource) {
    super(source, undefined, undefined);
  }
  up(): Chain<TSource> {
    throw new Error("cannot call up on root of chain");
  }
  output(): TSource {
    const thisOutput = { ...this.source, ...this.changes };
    return thisOutput;
  }
  whereAmI() {
    return "root";
  }
}

export const mergeChain = <T>(obj: T) => new ParentChain<T>(obj);
