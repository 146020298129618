import { RequiredField } from "common/ui/required-field";
import { InputSimulatePasswordWithSubmit } from "common/widgets/input-simulate-password-with-submit";

interface Props {
  focus: boolean;
  onSubmit: () => void;
  value: string;
  onChange: (value: string) => void;
}

export const PasswordRow = ({ onSubmit, focus, value, onChange }: Props) => {
  return (
    <RequiredField
      label={_("Password")}
      value={value}
      input={
        <form autoComplete="off">
          <InputSimulatePasswordWithSubmit
            focus={focus}
            value={value ?? ""}
            onSubmit={onSubmit}
            onChange={onChange}
            label={_("Password")}
          />
        </form>
      }
    />
  );
};

PasswordRow.displayName = "PasswordRow";
