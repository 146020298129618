import { CancellablePromise } from "common/types/promises";
import { ReactHighlightWords } from "common/vendor-wrappers/react-highlight-words";
import {
  FormatMeta,
  LabelledOptionOrGroup,
} from "common/vendor-wrappers/react-select/types";
import { mapValuesToOptions } from "common/widgets/selector/functions";
import { searchApi } from "common/api/search";
import { Entity } from "common/entities/types";
import { getDisplayFieldFilters } from "common/query/common";
import {
  addFilterToQuery,
  addIsDeletedFilterToQuery,
} from "common/query/filter";
import { addJoinToQuery } from "common/query/joins";
import { addToSelectQuery } from "common/query/select";
import {
  FilterAnd,
  JoinItem,
  QueryForEntity,
  SelectItem,
} from "common/query/types";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { TRENDING_METER_TYPE_ID } from "common/types/system-strings";
import { AsyncSelector } from "common/widgets/selector/async-selector";
import { formatFk } from "common/widgets/foreign-key/functions";
import { ValueComponent } from "common/with-value-for";

const MAX_SELECTOR_METERS = 100;

export interface AssetMeterSelectorValue extends ForeignKey {
  unit: string;
  assetId: string;
}

interface PropTypes {
  context: Context;
  entity: Entity;
  assetId: string;
  filter: (x: AssetMeterSelectorValue) => boolean;
  className?: string;
}

const getAssetMeterQuery = (
  entity: Entity,
  assetId: string,
  queryString: string,
): QueryForEntity => {
  const { name, query } = entity;

  const newSelects: SelectItem[] = [
    { name: "unit", path: "/meterId" },
    { name: "assetId" },
  ];
  const newJoin: JoinItem = { column: "meterId" };

  const withSelect = addToSelectQuery(newSelects, query);
  const withJoin = addJoinToQuery(newJoin, withSelect);
  const withFilterDeleted = addIsDeletedFilterToQuery(withJoin, false);

  const filter: FilterAnd = {
    and: [
      { name: "assetId", op: "eq", value: assetId },
      {
        name: "meterTypeId",
        op: "neq",
        value: TRENDING_METER_TYPE_ID,
        path: "/meterId",
      },
      { or: getDisplayFieldFilters(query.select, queryString) },
    ],
  };
  const withFilters = addFilterToQuery(filter, withFilterDeleted);

  return { entity: name, query: withFilters };
};

const formatAssetMeterOption = (
  item: LabelledOptionOrGroup<AssetMeterSelectorValue>,
  meta: FormatMeta<AssetMeterSelectorValue>,
) => <ReactHighlightWords text={item.label} search={meta?.inputValue} />;

export class AssetMeterSelector extends ValueComponent<
  AssetMeterSelectorValue,
  PropTypes
> {
  static readonly displayName = "AssetMeterSelector";

  fetch = (queryString: string, limit: number) => {
    const { context, entity, assetId } = this.props;
    const queryForEntity = getAssetMeterQuery(entity, assetId, queryString);

    return searchApi(context.apiCall).runQuery({
      ...queryForEntity,
      query: { ...queryForEntity.query, pageSize: limit },
    }) as CancellablePromise<AssetMeterSelectorValue[]>;
  };

  mapOptions = (options: AssetMeterSelectorValue[]) => {
    const { context, filter } = this.props;

    return mapValuesToOptions<AssetMeterSelectorValue>(
      filter ? options.filter(filter) : options,
      formatFk(context.entities) as unknown as (
        items: AssetMeterSelectorValue,
      ) => string,
    );
  };

  render() {
    const { className, value } = this.props;

    return (
      <AsyncSelector<AssetMeterSelectorValue>
        fetch={this.fetch}
        mapOptions={this.mapOptions}
        formatOptionLabel={formatAssetMeterOption}
        className={className}
        placeholder={_("Type to search for a meter...")}
        allowClear={false}
        optionsLimit={MAX_SELECTOR_METERS}
        value={value}
        onChange={this.onChangeSetValue}
      />
    );
  }
}
