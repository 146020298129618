import { PropsWithChildren } from "react";
import { Link as ReactLink, LinkProps } from "react-router-dom";
import { createQueryString } from "common/app/query-string";
import { QueryString } from "common/types/url";
import { cleanUrlHash } from "common/utils/go-to";

type ExposedLinkProps = Pick<
  LinkProps,
  "target" | "title" | "className" | "onClick"
>;

interface PropTypes extends ExposedLinkProps {
  href: string;
  query?: QueryString;
  state?: { [key: string]: any };
}

export const Link = (props: PropsWithChildren<PropTypes>) => {
  const { query, state } = props;
  const href = props.href && cleanUrlHash(props.href);
  const search = createQueryString(query);
  const to = href
    ? search || state
      ? { pathname: href, search, state }
      : href
    : undefined;

  // spreading all props, including any extra data-props we might have
  return to ? <ReactLink {...props} to={to} /> : <a {...props} />;
};
