import { PropsWithChildren } from "react";

import "./index.scss";

interface PropTypes {
  title: string;
  message: string;
  className?: string;
}

export const errorIcon = (
  <i className="fa fa-exclamation-circle" aria-hidden="true" />
);

export const ErrorComponent = ({
  title,
  message,
  className = "",
  children,
}: PropsWithChildren<PropTypes>) => (
  <div className={`x-error-component qa-error-component ${className}`}>
    <i className="fa fa-warning" />
    <div className="x-error-wrapper">
      <h1 className="x-error-title qa-error-title">{title}</h1>
      <p className="x-error-message">{message}</p>
      {children}
    </div>
  </div>
);

ErrorComponent.displayName = "ErrorComponent";

const withTitleMessage = (
  displayName: string,
  title: () => string,
  message: () => string,
  className: string,
) => {
  const ErrorWithTitleComp = () => (
    <ErrorComponent title={title()} message={message()} className={className} />
  );
  ErrorWithTitleComp.displayName = displayName;
  return ErrorWithTitleComp;
};

export const PermissionsError = withTitleMessage(
  "PermissionsError",
  () => _("Insufficient permissions"),
  () =>
    _(
      "You cannot access required entities or columns to view this record, please contact the system administrator.",
    ),
  "x-margin-20",
);

export const GeneralPermissionsError = withTitleMessage(
  "GeneralPermissionsError",
  () => _("Insufficient permissions"),
  () =>
    _(
      "You do not have enough permissions to perform this action, please contact the system administrator.",
    ),
  "x-margin-20",
);
