import * as R from "ramda";
import { getData } from "common/api";
import {
  AbsoluteApiCallFull,
  ApiCallFull,
  ExternalOptions,
} from "common/types/api";
import { Context } from "common/types/context";
import { FlukeMobileSsoData } from "common/types/fluke-mobile";
import { CancellablePromise } from "common/types/promises";
import { setLocationHref } from "common/utils/window-location";

const composeUrl = (domain: string, redirectionUrl: string) => {
  return R.startsWith("/", redirectionUrl)
    ? `${domain}${redirectionUrl}`
    : `${domain}/${redirectionUrl}`;
};

const getTempToken = (apiCallFull: ApiCallFull) =>
  apiCallFull<FlukeMobileSsoData>("get", "/api/flukeMobile/tempToken");

const sendToken = (
  absoluteApiCallFull: AbsoluteApiCallFull,
  endpoint: string,
  token: string,
  options: ExternalOptions,
) =>
  absoluteApiCallFull<string>("post", endpoint, options, { token }).then(
    getData,
  );

const throwError = (error: string) => {
  throw new Error("An error occurred during SSO: " + error);
};

const getFlukeMobileExternalOptions = (
  tenantName: string,
): ExternalOptions => ({
  contentType: "application/json",
  crossDomain: true,
  withCredentials: true,
  headers: { "x-tenant": tenantName },
});

interface FmApiType {
  getMobileFormEntities: () => CancellablePromise<string[]>;
  singleSignOn: () => CancellablePromise<void>;
}

export const fmApi = ({
  tenant,
  apiCallFull,
  absoluteApiCallFull,
  apiCall,
}: Context): FmApiType => ({
  singleSignOn: () =>
    getTempToken(apiCallFull)
      .catch(throwError) // i think this is an ApiErrorResponse; not a string
      .then(({ data: { domain, route, token } }) =>
        sendToken(
          absoluteApiCallFull,
          composeUrl(domain, route),
          token,
          getFlukeMobileExternalOptions(tenant.name),
        )
          .catch(throwError)
          .then((redirectionUrl) => {
            if (!redirectionUrl) {
              throwError("Invalid redirection URL");
            }
            setLocationHref(composeUrl(domain, redirectionUrl));
          }),
      ),
  getMobileFormEntities: () =>
    apiCall("get", "api/flukeMobile/mobile-form-entities"),
});
