import {
  ChangeWorkOrderStatusAction,
  WorkOrderAction,
} from "common/types/workflows";

export const isChangeStatusActionValid = (
  action: ChangeWorkOrderStatusAction,
) => !!action?.workOrderAction;

export const getWorkOrderActionsForEvent = (
  rule: string,
): WorkOrderAction[] => {
  const eventName = rule?.split("Event")[0];
  switch (eventName) {
    case "RecordCreated":
      return ["open", "complete", "close"];
    case "WorkOrderOpened":
      return ["complete", "close"];
    case "WorkOrderCompleted":
      return ["close"];
    case "WorkOrderClosed":
      return ["reopen"];
    case "RecordUpdated":
    default:
      return ["open", "reopen", "complete", "close"];
  }
};
