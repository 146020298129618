import { Selector } from "common/widgets/selector";
import { Culture, translateCulture } from "common/culture/supported-cultures";
import { formatCulture } from "common/widgets/language-selector/functions";
import { ValueProps } from "common/with-value-for";

interface PropTypes extends ValueProps<Culture> {
  cultures: Culture[];
  placeholder?: string;
  disabled?: boolean;
}

export const LanguageSelector = ({
  cultures,
  placeholder,
  disabled,
  value,
  onChange,
}: PropTypes) => (
  <div className="x-language-selector qa-language-selector">
    <Selector
      placeholder={placeholder ?? _("Select language")}
      formatOption={formatCulture}
      getOptionLabel={translateCulture}
      options={cultures}
      disabled={disabled}
      value={value}
      onChange={onChange}
    />
  </div>
);
