interface PropTypes {
  onClick?: () => void;
  className?: string;
}

import "./index.scss";

export const Backdrop = ({ onClick, className = "" }: PropTypes) => (
  <div
    className={`x-backdrop ${className}`}
    onClick={onClick}
    data-testid="backdrop"
  />
);

Backdrop.displayName = "Backdrop";
