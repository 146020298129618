import { Component } from "react";
import { stripHtml } from "common/utils/html";
import { LazyTextEditor } from "common/widgets/text-editor";

import "./index.scss";

const DEFAULT_MAX_CONTENT_LENGTH = 150;

interface PropTypes {
  value: string;
  maxContentLength?: number;
}

interface StateType {
  showEditor: boolean;
}

const createGist = (
  content: string,
  maxContentLength: number = DEFAULT_MAX_CONTENT_LENGTH,
) => {
  const shortContent = content.substr(0, maxContentLength);
  const ellipsis = content.length > maxContentLength ? "…" : "";
  return `${shortContent}${ellipsis}`;
};

export class LazyHtmlReader extends Component<PropTypes, StateType> {
  static readonly displayName = "LazyHtmlReader";

  state: StateType = {
    showEditor: false,
  };

  onClick = () => {
    this.setState({ showEditor: true });
  };

  render() {
    const { value, maxContentLength } = this.props;
    const { showEditor } = this.state;

    if (!value) return null;

    return (
      <div className="x-lazy-html-reader">
        {showEditor ? (
          <LazyTextEditor value={value} readOnly={true} onChange={undefined} />
        ) : (
          <p
            className="x-content-gist qa-content-gist"
            title={_("Click to load the original content")}
            onClick={this.onClick}
          >
            {createGist(stripHtml(value), maxContentLength)}
          </p>
        )}
      </div>
    );
  }
}
