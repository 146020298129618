import * as R from "ramda";
import { isInteractiveMapBehaviorName } from "common/api/behavior";
import { cleanUp } from "common/culture/functions";
import { Culture, EN_US } from "common/culture/supported-cultures";
import { Captions, Labels } from "common/culture/types";
import { Entities, Entity } from "common/entities/types";
import { merge2 } from "common/merge";
import { VisualTemplate } from "x/account-settings/visual-templates/types";
import { ColorLegendGroup, ColorLegendItem } from "x/records/list/functions";
import { getLocalizedName } from "common";
import { InteractiveMapProperties, InteractiveMapValue } from "./types";

export const getLabelForColorLegendGroup = (
  labels: Labels,
  culture: Culture,
  sitesCulture: Culture,
) =>
  labels[culture]?.name ??
  labels[sitesCulture]?.name ??
  labels[EN_US]?.name ??
  "";

const createColorLegendGroupByEntityName = (
  template: VisualTemplate,
  entity: Entity,
  culture: Culture,
  sitesCulture: Culture,
): ColorLegendGroup | undefined => {
  const items = template.items
    .filter((item) => item.entityName === entity.name)
    .map<ColorLegendItem>(({ color, labels }) => ({
      label: getLabelForColorLegendGroup(labels, culture, sitesCulture),
      color,
    }));

  return items.length ? { label: getLocalizedName(entity), items } : undefined;
};

export const generateColorLegendGroups = (
  entities: Entities,
  selectedTemplate: VisualTemplate,
  culture: Culture,
  sitesCulture: Culture,
): ColorLegendGroup[] => {
  const filteredEntities = Object.values(entities).filter((entity) =>
    entity.behaviors.some((b) => isInteractiveMapBehaviorName(b.name)),
  );

  return filteredEntities
    .map((entity) =>
      createColorLegendGroupByEntityName(
        selectedTemplate,
        entity,
        culture,
        sitesCulture,
      ),
    )
    .filter(Boolean);
};

const getLabelCaptions = (properties: InteractiveMapProperties): Captions => ({
  description: properties.description,
});

export const getTranslationKeys = (
  properties: InteractiveMapProperties,
): string[] => (properties ? Object.keys(getLabelCaptions(properties)) : []);

export const translateKey =
  (properties: InteractiveMapProperties) => (key: string) =>
    key === "description"
      ? properties?.description ?? _("Name")
      : key.replace(/^item\.*/, "");

export const applyLabelTranslation = (
  properties: InteractiveMapProperties,
  culture: Culture,
): InteractiveMapProperties =>
  properties
    ? {
        ...properties,
        description:
          properties?.labels?.[culture]?.description || properties.description,
      }
    : undefined;

const updateTranslations = (
  culture: Culture,
  properties: InteractiveMapProperties,
): InteractiveMapProperties =>
  properties
    ? cleanUp(getTranslationKeys)(
        merge2("labels", culture, getLabelCaptions(properties), properties),
      )
    : undefined;

export const resetDescriptionTranslation = (
  culture: Culture,
  interactiveMapValue: InteractiveMapValue,
): InteractiveMapValue => {
  const { properties } = interactiveMapValue.record;
  const updatedTranslations = updateTranslations(culture, {
    ...properties,
    labels: R.mapObjIndexed(R.omit(["description"]), properties.labels),
  });
  return merge2(
    "record",
    "properties",
    updatedTranslations,
    interactiveMapValue,
  );
};
