import { isSharedMultipleSitesEntity } from "common/entities";
import type { Entity } from "common/entities/types";
import { areSitesSubsetOfSites } from "common/functions/sites";
import type { Context } from "common/types/context";
import type { ForeignKey } from "common/types/foreign-key";

type ErrorType = {
  hasError: boolean;
  errorMessage: string | undefined;
};

const noError: ErrorType = {
  hasError: false,
  errorMessage: undefined,
};

export const getSelectorError = (
  context: Context,
  entity: Entity,
  recordSites: string[],
  value: ForeignKey,
): ErrorType => {
  if (!value) return noError;

  if (
    isSharedMultipleSitesEntity(entity) &&
    !areSitesSubsetOfSites(context.sites, value?.sites, recordSites)
  ) {
    return {
      hasError: true,
      errorMessage: _(
        "The linked record should have a broader sites scope than the current record",
      ),
    };
  }

  return noError;
};
