import { type MouseEvent } from "react";
import { recordsApi } from "common/api/records";
import { Entity } from "common/entities/types";
import { Event } from "common/types/events";
import { EventListItem } from "common/record/form/content/audit-trail/event-list/event-list-item";
import { Context } from "common/types/context";
import { ShowMore } from "common/widgets/show-more";
import { ActionButtonSmall } from "common/ui/buttons";
import {
  showErrorNotification,
  showInfoNotification,
} from "common/ui/notification";
import { DropdownMenu } from "common/widgets/dropdown-menu";
import { Link } from "common/widgets/link";

interface PropTypes {
  selectedEvent: Event;
  events: Event[];
  entityName: string;
  context: Context;
  loading: boolean;
  onShowMore: () => void;
  isLastPage: boolean;
  recordId: string;
  getUrl?: (entity: Entity, site: string) => string;
}

export const EventList = ({
  selectedEvent,
  events = [],
  entityName,
  context,
  loading,
  onShowMore,
  isLastPage,
  recordId,
  getUrl,
}: PropTypes) => {
  const list = (
    <ul className="x-list-group qa-list-group">
      {events.map((e, i) => {
        const selectedClass = selectedEvent.id === e.id ? " selected" : "";
        return (
          <li
            key={i}
            className={`x-list-group-item qa-list-group-item${selectedClass}`}
          >
            <EventListItem
              event={e}
              entityName={entityName}
              context={context}
              recordId={recordId}
              getUrl={getUrl}
            />
          </li>
        );
      })}
    </ul>
  );

  const onDownloadFullAuditTrailAsJson = (event: MouseEvent) => {
    event.preventDefault();
    recordsApi(context.apiCall)
      .downloadFullAuditTrailAsJson(entityName, recordId)
      .catch(() =>
        showErrorNotification(_("We could not complete your request")),
      );
  };

  const onDownloadFullAuditTrailAsPdf = (event: MouseEvent) => {
    event.preventDefault();
    showInfoNotification(_("Your pdf download will start shortly"));
    recordsApi(context.apiCall)
      .downloadFullAuditTrailAsPdf(entityName, recordId)
      .catch(() =>
        showErrorNotification(_("We could not complete your request")),
      );
  };

  return (
    <div className="x-event-list qa-event-list">
      <div className="x-event-list-header">
        <h3 className="x-event-list-title qa-event-list-title">
          {_("Events")}
        </h3>
        <DropdownMenu
          className="x-margin-10"
          button={
            <ActionButtonSmall>
              {_("Download Full Audit Trail")}
              <i className="fa fa-caret-down x-margin-left-5" />
            </ActionButtonSmall>
          }
          menu={
            <>
              <Link
                className="dropdown-item"
                data-testid="download-as-json"
                href="#"
                onClick={onDownloadFullAuditTrailAsJson}
              >
                {_("Download as JSON")}
              </Link>

              <Link
                className="dropdown-item x-download-as-pdf"
                data-testid="download-as-pdf"
                href="#"
                onClick={onDownloadFullAuditTrailAsPdf}
              >
                {_("Download as PDF")}
              </Link>
            </>
          }
        />
      </div>
      <div className="x-event-list-items">
        {!isLastPage ? (
          <ShowMore action={onShowMore} loading={loading}>
            {list}
          </ShowMore>
        ) : (
          list
        )}
      </div>
    </div>
  );
};

EventList.displayName = "EventList";
