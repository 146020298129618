import { returnAndNotify } from "common/api/with-notifications";
import { ApiCall } from "common/types/api";
import { CancellablePromise } from "common/types/promises";
import { SecuritySettings } from "common/types/settings";
import {
  payloadToSettings,
  settingsToPayload,
} from "x/account-settings/security/functions";

export const userSettingsApi = (apiCall: ApiCall) => ({
  get: (): CancellablePromise<SecuritySettings> =>
    apiCall("get", `/api/admin/settings`).then(payloadToSettings),
  set: (s: SecuritySettings) =>
    apiCall("put", `/api/admin/settings`, settingsToPayload(s)).then(
      returnAndNotify("The settings were successfully updated"),
    ),
});
