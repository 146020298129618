import { Component } from "react";
import ReactPlayer from "react-player/lazy";
import { arrayToString } from "common/utils/array";
import { HeaderResponse, mediaApi } from "common/api/media";
import { Context } from "common/types/context";
import { MediaPlayer } from "common/vendor-wrappers/react-player";
import { AlertWarning } from "../alert";
import { ImageLarge } from "../image";
import { WebLinkLabel } from "../web-link/label";
import { LoadingIcon } from "../loading-icon";
import {
  getFileTypeFromUrl,
  getServiceClass,
  isMixCloud,
  isSoundCloud,
  isSupportedMediaSource,
  isYouTube,
} from "./functions";

interface Props {
  context: Context;
  url: string;
  isEditMode: boolean;
  className?: string;
}

interface StateType {
  contentType: string;
  loading: boolean;
}

export class MediaWebLinkLabel extends Component<Props, StateType> {
  static readonly displayName = "MediaWebLinkLabel";

  state: StateType = {
    contentType: undefined,
    loading: false,
  };

  componentDidMount() {
    this.fetchContentType();
  }

  fetchContentType = () => {
    const { context, url } = this.props;

    if (!url) return;

    if (isSupportedMediaSource(url)) {
      this.setState({ contentType: getFileTypeFromUrl(url) });
      return;
    }

    this.setState({ loading: true });
    mediaApi(context.apiCall)
      .getContentType(url)
      .then(({ contentType }: HeaderResponse) => {
        this.setState({
          contentType: getFileTypeFromUrl(url) ?? contentType?.split("/")[0],
          loading: false,
        });
      })
      .catch(() => {
        mediaApi(context.apiCall)
          .isUrlReachable(url)
          .then(() => {
            this.setState({
              contentType: getFileTypeFromUrl(url) ?? undefined,
              loading: false,
            });
          })
          .catch(() => {
            this.setState({ contentType: undefined, loading: false });
          });
      });
  };

  getErrorContent = (message: string) => {
    const { url, isEditMode } = this.props;

    return isEditMode ? (
      <AlertWarning message={message} />
    ) : (
      <WebLinkLabel url={url} />
    );
  };

  render() {
    const { url, className = "" } = this.props;
    const { contentType, loading } = this.state;
    const mediaPlayerInput =
      isSupportedMediaSource(url) ||
      contentType === "video" ||
      contentType === "audio";
    const playerClasses = arrayToString([
      "x-media-stream",
      `x-media-${contentType}`,
      getServiceClass(url),
    ]);
    const withPreview = isSupportedMediaSource(url) || contentType === "video";
    const showError = !(isYouTube(url) || isMixCloud(url) || isSoundCloud(url));

    return (
      <div className={`x-media-stream-container ${className}`}>
        {loading ? (
          <LoadingIcon />
        ) : mediaPlayerInput ? (
          ReactPlayer.canPlay(url) ? (
            <div className={playerClasses}>
              <MediaPlayer
                url={url}
                withPreview={withPreview}
                showLoading={isSupportedMediaSource(url)}
                showError={showError}
              />
            </div>
          ) : (
            this.getErrorContent(_("The provided URL is not valid."))
          )
        ) : contentType === "image" ? (
          <div className={playerClasses}>
            <ImageLarge
              value={url}
              className="x-padding-top-10"
              allowZoom={true}
            />
          </div>
        ) : (
          this.getErrorContent(_("This media format is not supported."))
        )}
      </div>
    );
  }
}
