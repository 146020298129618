import { Component } from "react";
import { getLocalizedName } from "common";
import { searchApi } from "common/api/search";
import { getColumn } from "common/entities";
import { LabelWidget } from "common/form/widget/label-widget";
import { ForeignKey } from "common/types/foreign-key";
import { Properties } from "common/types/records";
import { VerticalField } from "common/ui/field";
import { arrayToString } from "common/utils/array";
import { Hint } from "common/widgets/hint";
// eslint-disable-next-line import/no-cycle
import { AdvancedSingleRecordSelector } from "common/widgets/record-selector";
import { isRestrictedForRole } from "common/entities/entity-column/functions";
import { getReadOnlyFieldIcon } from "common/form/widget/functions";
import { hasRestrictedColumn } from "../drilldown/functions";
import { LookupPropTypes } from "../types";
import { getFkUserDefinedFieldsQuery, getUserDefinedValues } from "./functions";

export class UserDefined extends Component<LookupPropTypes> {
  static readonly displayName = "UserDefined";

  fetchUserDefinedFields(id: string) {
    const { context, layoutColumn } = this.props;
    const { targetEntity, mappedFields } = layoutColumn.lookupConfiguration;

    return searchApi(context.apiCall)
      .runQueryFkExpansion(
        getFkUserDefinedFieldsQuery(
          context.entities[targetEntity],
          mappedFields,
          id,
        ),
      )
      .then((records: Properties[]) =>
        getUserDefinedValues(records[0], mappedFields),
      );
  }

  onRecordChange = (fk: ForeignKey) => {
    const { value, onChange, mainFkColumnName } = this.props;
    const newValue = { ...value, [mainFkColumnName]: fk };

    if (!fk) return;
    this.fetchUserDefinedFields(fk.id)
      .then((partialRecord) => onChange({ ...newValue, ...partialRecord }))
      .catch(() => onChange(newValue));
  };

  render() {
    const { context, entity, value, mainFkColumnName, layoutColumn } =
      this.props;
    const { hint, highlighted, required, lookupConfiguration } = layoutColumn;
    const { mappedFields, targetEntity } = lookupConfiguration || {};

    const fkColumn = getColumn(entity, mainFkColumnName);
    const isRequired = required || fkColumn?.required;
    const className = arrayToString([
      `qa-${fkColumn.name}`,
      highlighted ? "x-highlighted" : undefined,
    ]);
    const isAnyFieldRestricted = hasRestrictedColumn(
      context,
      entity,
      mappedFields,
      mainFkColumnName,
    );
    const readOnly = isAnyFieldRestricted || fkColumn?.userReadOnly;
    const hasError = isRequired && !value[mainFkColumnName];
    const isMainFkRestricted = isRestrictedForRole(
      fkColumn?.roleIds,
      context.role,
    );

    return (
      <VerticalField
        className={className}
        label={getLocalizedName(fkColumn)}
        isRequired={isRequired}
        error={hasError}
        input={
          readOnly ? (
            <div
              className={arrayToString([
                "x-read-only-label-wrapper",
                hasError ? "x-has-error" : undefined,
              ])}
            >
              <div className="x-read-only-label">
                <LabelWidget
                  context={context}
                  column={fkColumn}
                  value={value[mainFkColumnName]}
                />
              </div>
              {isMainFkRestricted || fkColumn?.userReadOnly ? (
                <div className="x-read-only-icon">
                  {getReadOnlyFieldIcon(fkColumn, isMainFkRestricted)}
                </div>
              ) : undefined}
            </div>
          ) : (
            <>
              <AdvancedSingleRecordSelector
                context={context}
                entity={context.entities[targetEntity]}
                withLinks={false}
                allowClear={false}
                value={value[mainFkColumnName]}
                onChange={this.onRecordChange}
              />
              {hint ? <Hint message={hint} /> : undefined}
            </>
          )
        }
      />
    );
  }
}
