import * as R from "ramda";
import { isFalse, isTrue } from "common/utils/boolean";
import { getFalse, getTrue } from "common";
import { ValueProps } from "common/with-value-for";
import { RadioButtonList } from "common/widgets/radio-button-list";

interface PropTypes extends ValueProps<boolean> {
  name: string;
  disabled?: boolean;
  className?: string;
  allowClear?: boolean;
}

export const getYesNoLabel = (value: boolean | string) => {
  if (R.isNil(value)) return undefined;

  if (isTrue(value)) return _("Yes");
  if (isFalse(value)) return _("No");

  return undefined;
};

export const YesNo = ({
  name,
  allowClear,
  disabled,
  className,
  value,
  onChange,
}: PropTypes) => (
  <RadioButtonList
    name={name}
    display={getYesNoLabel}
    items={[true, false]}
    allowClear={allowClear}
    isDisabled={disabled ? getTrue : getFalse}
    className={className}
    value={value}
    onChange={onChange}
  />
);
