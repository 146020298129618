import { Component } from "react";
import { Context } from "common/types/context";
import { userSettingsApi } from "common/api/settings/user-settings";
import { PasswordPolicy } from "common/types/settings";
import { DependenciesComp } from "common/with-dependencies";
import { createInjected } from "x/controllers/edit/with-ribbon-and-footer/form";
import { UserProfile } from ".";

interface PropTypes {
  context: Context;
  goBack: (event: string) => void;
  onImageChanged: (image: string) => void;
}
const injected = createInjected<PasswordPolicy, string>();

export class ProfileController extends Component<PropTypes> {
  static readonly displayName = "ProfileController";
  render() {
    const { context, goBack, onImageChanged } = this.props;

    //  call the api/password/policy endpoint instead of the settings
    return (
      <DependenciesComp
        dependencies={{
          passwordPolicy: userSettingsApi(context.apiCall)
            .get()
            .then((s) => s.passwordPolicy),
        }}
        child={
          <UserProfile
            {...injected}
            context={context}
            goBack={goBack}
            onImageChanged={onImageChanged}
          />
        }
      />
    );
  }
}
