import { getUtcNow } from "common/date-time/common";
import { resolveDateDynamicValue } from "common/date-time/helpers/dynamic-values";
import { behaveAs } from "common/entities";
import { DataType } from "common/entities/entity-column/data-type/types";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { Record } from "common/types/records";

export const CUSTOM_DYNAMIC_VALUE = "{customValue}";

const dateDynamicValues = [
  "{today}",
  "{yesterday}",
  "{tomorrow}",
  "{thisMonth}",
  "{lastMonth}",
  "{nextMonth}",
  "{thisYear}",
  "{lastYear}",
  "{nextYear}",
  "{lastSunday}",
  "{nextSunday}",
  "{lastMonday}",
  "{nextMonday}",
  "{lastTuesday}",
  "{nextTuesday}",
  "{lastWednesday}",
  "{nextWednesday}",
  "{lastThursday}",
  "{nextThursday}",
  "{lastFriday}",
  "{nextFriday}",
  "{lastSaturday}",
  "{nextSaturday}",
];
const dateTimeDynamicValues = ["{now}"];
const emailDynamicValues = ["{user}", "{myUserEmail}"];
const obsoleteEmailDynamicValues = ["{me}"];
const allDynamicValues = dateDynamicValues.concat(
  dateTimeDynamicValues,
  emailDynamicValues,
);

export const getDynamicValues = (
  dataType: DataType,
  relatedEntity: Entity,
  fromDefaultForm: boolean,
) => {
  switch (dataType) {
    case "email":
      return fromDefaultForm ? ["{user}"] : ["{myUserEmail}"];
    case "date":
      return dateDynamicValues;
    case "datetime": {
      return dateTimeDynamicValues.concat(dateDynamicValues);
    }
    case "fk":
      if (fromDefaultForm && relatedEntity && behaveAs("User", relatedEntity)) {
        return ["{user}"];
      }
  }
  return [];
};

export const getDateTimeOnlyDynamicValues = () => dateTimeDynamicValues;

export const resolveDynamicValue = (
  dataType: DataType,
  relatedEntity: Entity,
  value: any,
  context?: Context,
  searchResults?: Record[],
) => {
  switch (dataType) {
    case "email":
      return context.isSystem ? undefined : context.userName;
    case "date":
      return resolveDateDynamicValue(value, getUtcNow());
    case "datetime":
      return resolveDateDynamicValue(value, getUtcNow());
    case "fk":
      if (relatedEntity && behaveAs("User", relatedEntity)) {
        return searchResults ? searchResults[0] : undefined;
      }
  }
  return value;
};

export const resolveDynamicValueForFilter = (
  context: Context,
  dataType: DataType,
  relatedEntity: Entity,
  value: string,
) =>
  dataType === "fk" && relatedEntity && behaveAs("User", relatedEntity)
    ? context.userName
    : value;

const translateContextDynamicValues = (value: string) => {
  switch (value) {
    case "{me}":
      return _("Me");
    case "{myUserEmail}":
      return _("My User Email");
    case "{user}":
      return _("User");
    case "{now}":
      return _("Now");
    case "{today}":
      return _("Today");
    case "{yesterday}":
      return _("Yesterday");
    case "{tomorrow}":
      return _("Tomorrow");
    case "{thisMonth}":
      return _("First day of this Month");
    case "{lastMonth}":
      return _("First day of last Month");
    case "{nextMonth}":
      return _("First day of next Month");
    case "{thisYear}":
      return _("First day of this Year");
    case "{lastYear}":
      return _("First day of last Year");
    case "{nextYear}":
      return _("First day of next Year");
    case "{lastSunday}":
      return _("Last Sunday");
    case "{nextSunday}":
      return _("Next Sunday");
    case "{lastMonday}":
      return _("Last Monday");
    case "{nextMonday}":
      return _("Next Monday");
    case "{lastTuesday}":
      return _("Last Tuesday");
    case "{nextTuesday}":
      return _("Next Tuesday");
    case "{lastWednesday}":
      return _("Last Wednesday");
    case "{nextWednesday}":
      return _("Next Wednesday");
    case "{lastThursday}":
      return _("Last Thursday");
    case "{nextThursday}":
      return _("Next Thursday");
    case "{lastFriday}":
      return _("Last Friday");
    case "{nextFriday}":
      return _("Next Friday");
    case "{lastSaturday}":
      return _("Last Saturday");
    case "{nextSaturday}":
      return _("Next Saturday");
    case "{customValue}:email":
      return _("Custom Email");
    case "{customValue}:date":
      return _("Custom Date");
    case "{customValue}:datetime":
      return _("Custom Date and Time");
    default:
      return _("Custom Value");
  }
};

export const onDisplayDynamicValues = (dataType: DataType) => (value: string) =>
  translateContextDynamicValues(
    value === CUSTOM_DYNAMIC_VALUE
      ? CUSTOM_DYNAMIC_VALUE + ":" + dataType
      : value,
  );

export const isDynamicValue = (value: any) =>
  typeof value === "string" && allDynamicValues.includes(value);

export const isObsoleteDynamicValue = (value: any) =>
  typeof value === "string" && obsoleteEmailDynamicValues.includes(value);

export const isValidDynamicValue = (
  dataType: DataType,
  relatedEntity: Entity,
  value: any,
) => getDynamicValues(dataType, relatedEntity, true).includes(value);

export const replaceObsoleteDynamicValue = (value: any): any =>
  value === "{me}" ? "{myUserEmail}" : value;
