import * as R from "ramda";
import { ValueComponent } from "common/with-value-for";
import { Entity, Entities } from "common/entities/types";
import { Selector } from "common/widgets/selector";

interface PropTypes {
  entities: Entities;
  label?: string;
}

const formatEntity = (entity: Entity) => entity.localizedName ?? entity.name;

export class From extends ValueComponent<string, PropTypes> {
  static readonly displayName = "From";

  onChange = (entity: Entity) => {
    this.setValue(entity.name);
  };

  render() {
    const { label, entities, value } = this.props;
    const entityArray = R.values(entities);
    const selectedEntity = R.find((e) => e.name === value, entityArray);

    return (
      <span className="x-query-builder-from">
        <Selector<Entity>
          placeholder={`${_("Select an entity")}...`}
          label={label}
          getOptionLabel={formatEntity}
          value={selectedEntity}
          options={entityArray}
          onChange={this.onChange}
        />
      </span>
    );
  }
}
