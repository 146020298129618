import { Component } from "react";
import { ValueProps } from "common/with-value-for";
import { FancyCheckboxSmall } from "../fancy-checkbox";

interface PropTypes<TItem> extends ValueProps<TItem> {
  item: TItem;
  label: string;
  value: TItem;
  disabled?: boolean;
}

export class ValueToggleCheckbox<T> extends Component<PropTypes<T>> {
  onCheckboxChange = (booleanValue: boolean) => {
    const { item, onChange } = this.props;
    const newValue = booleanValue ? item : undefined;
    onChange(newValue);
  };

  render() {
    const { item, label, disabled = false, value } = this.props;

    return (
      <FancyCheckboxSmall
        label={label}
        disabled={disabled}
        value={!!value && value === item}
        onChange={this.onCheckboxChange}
      />
    );
  }
}
