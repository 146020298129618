import * as R from "ramda";
import { Component } from "react";
import { ActionsModal } from "common/query/table/actions/modal";
// eslint-disable-next-line import/no-cycle
import { Body } from "common/query/table/body";
import { EmptyBody } from "common/query/table/body/empty";
import { hasActionsCheck } from "common/query/table/functions";
import { defaultFor } from "common";
import { deepEqual } from "common/component";
import { IsRecordDisabledFn, MapWidgetPropsFn } from "common/form/types";
import { ColumnDefinition } from "common/query/advanced-types";
// eslint-disable-next-line import/no-cycle
import {
  Header,
  HeaderValue,
  Props as HeaderProps,
} from "common/query/table/header";
// eslint-disable-next-line import/no-cycle
import { HeaderDraggable } from "common/query/table/header-draggable";
import { Resize } from "common/query/table/resize";
import { TableConfig, TableValue } from "common/query/table/types";
import { Context } from "common/types/context";
import { GoFn } from "common/types/url";
import { LoadingIcon } from "common/widgets/loading-icon";
import { ValueProps } from "common/with-value-for";
import { Footer } from "./footer";

export interface PropTypes {
  context: Context;
  config: TableConfig;
  isLoading: boolean;
  withLinks: boolean;
  reload: () => any;
  starred: string[];
  toggleStar: (id: string, starred: boolean) => void;
  goTo: GoFn;
  highlighted?: string[];
  toggleOrder?: () => void;
  disableResize?: boolean;
  readOnly?: boolean;
  withTotals?: boolean;
  widgetsMapper?: MapWidgetPropsFn;
  isRecordDisabled?: IsRecordDisabledFn;
}

export interface InnerPropTypes extends PropTypes {
  columnDefinitions: ColumnDefinition[];
  ignoredColumnDefinitions?: ColumnDefinition[];
}

type Props = InnerPropTypes & ValueProps<TableValue>;

const defaultValue = defaultFor<TableValue>();
const defaultConfig = defaultFor<TableConfig>();

export class Table extends Component<Props> {
  static readonly displayName = "Table";

  onHeaderChange = (header: HeaderValue) => {
    const { value, onChange } = this.props;

    if (deepEqual(header.query, value.query)) {
      onChange({
        ...value,
        secondaryQueries: header.secondaryQueries,
        widths: header.widths,
        selected: header.selectAll ? value.data : [],
      });
    } else {
      onChange({
        ...value,
        secondaryQueries: header.secondaryQueries,
        widths: header.widths,
        query: header.query,
      });
    }
  };

  onWidthsChange = (width: number[]) => {
    const { value, onChange } = this.props;
    onChange({ ...value, widths: width });
  };

  render() {
    const {
      config = defaultConfig,
      context,
      isLoading,
      disableResize,
      toggleOrder,
      columnDefinitions,
      withTotals,
      withLinks,
      reload,
      starred,
      toggleStar,
      goTo,
      widgetsMapper,
      isRecordDisabled,
      highlighted,
      readOnly,
      ignoredColumnDefinitions,
      value = defaultValue,
      onChange,
    } = this.props;

    const { widths, data = [], secondaryQueries, expandedAction } = value;
    const isModalOpen = !!expandedAction;

    const {
      allowFilter,
      allowOrder,
      allowSelect,
      allowStar,
      allowActions,
      allowDrag,
      actions,
      allowMultipleSelect,
    } = config;

    const hasCheckActions = hasActionsCheck(config);
    const hasActions =
      allowActions || allowStar || (hasCheckActions && !allowMultipleSelect);

    const selected = value.selected || [];
    const intersect = R.intersection(data, selected);
    const selectAll = data.length > 0 && intersect.length === data.length;
    const hasSelectAction = !!actions?.some(
      (action) => action.name === "Select",
    );

    const headerProps: HeaderProps = {
      columnDefinitions,
      context,
      allowDrag,
      allowFilter,
      allowOrder,
      allowSelect: allowMultipleSelect || allowSelect,
      hasActions,
      hasSelectAction,
      toggleOrder: data.length > 1 ? toggleOrder : undefined,
      onChange: this.onHeaderChange,
      value: {
        selectAll,
        query: value.query,
        widths,
        secondaryQueries,
      },
    };

    const header = allowDrag ? (
      <HeaderDraggable key="header" {...headerProps} />
    ) : (
      <Header key="header" {...headerProps} />
    );

    const tableBody = data.length ? (
      <Body
        context={context}
        config={config}
        withLinks={withLinks}
        reload={reload}
        starred={starred}
        toggleStar={toggleStar}
        goTo={goTo}
        columnDefinitions={columnDefinitions}
        highlighted={highlighted}
        readOnly={readOnly}
        ignoredColumnDefinitions={ignoredColumnDefinitions}
        widgetsMapper={widgetsMapper}
        isRecordDisabled={isRecordDisabled}
        value={value}
        onChange={onChange}
      />
    ) : (
      <EmptyBody
        config={config}
        isLoading={isLoading}
        columnDefinitions={columnDefinitions}
      />
    );

    const footer =
      withTotals && columnDefinitions ? (
        <Footer
          key="footer"
          columnDefinitions={columnDefinitions}
          context={context}
          data={data}
          hasActions={hasActions}
          hasSelectAction={hasSelectAction}
        />
      ) : undefined;

    const table = (
      <table className="x-table-striped-bordered qa-data-table">
        {header}
        <tbody>{tableBody}</tbody>
        {footer}
      </table>
    );

    const tableWithResize = disableResize ? (
      table
    ) : (
      <Resize
        value={widths}
        onChange={this.onWidthsChange}
        allowDrag={!!allowDrag}
        allowSelect={!!allowMultipleSelect || !!allowSelect}
        allowActionsColumn={
          !!allowStar ||
          !!allowActions ||
          (hasCheckActions && !allowMultipleSelect)
        }
      >
        {table}
      </Resize>
    );

    return (
      <div className="x-table-container">
        {isLoading ? <LoadingIcon /> : undefined}
        {tableWithResize}
        {isModalOpen ? (
          <ActionsModal config={config} value={value} onChange={onChange} />
        ) : undefined}
      </div>
    );
  }
}
