import * as R from "ramda";
import { GetCached, getCached } from "common/cache";
import { Widget } from "common/types/dashboards";
import { createList } from "common/widgets/list";
import { ValueComponent, ValueProps } from "common/with-value-for";

export interface WidgetListValue {
  widgets: Widget[];
  selected: Widget;
}

type Props = ValueProps<WidgetListValue>;

type OnSelect = () => void;

const List = createList<Widget>("WidgetType");

export class WidgetList extends ValueComponent<WidgetListValue> {
  static readonly displayName = "WidgetList";
  onSelect: GetCached<OnSelect>;

  constructor(props: Props) {
    super(props);
    this.onSelect = getCached<OnSelect>(
      (key) => () =>
        this.mergeValue({
          selected: this.props.value.widgets[parseInt(key)],
        }),
    );
  }
  onDisplay = (w: Widget) => {
    const { value } = this.props;
    const { selected, widgets } = value;
    const key = R.findIndex((widget) => w === widget, widgets).toString();
    const selectedClass = R.equals(w, selected) ? " selected" : "";
    return (
      <div
        className={`widget-title qa-select-widget${selectedClass}`}
        onClick={this.onSelect(key)}
      >
        {w.label || w.name}
      </div>
    );
  };

  render() {
    const { value } = this.props;
    const { widgets, selected } = value;
    return (
      <List
        refresh={selected}
        onDisplay={this.onDisplay}
        withBorder={true}
        canDelete={R.T}
        value={widgets}
        onChange={this.onChangeMergeValue("widgets")}
      />
    );
  }
}
