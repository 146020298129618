import * as R from "ramda";
import { recordsApi } from "common/api/records";
import { returnAndNotify } from "common/api/with-notifications";
import { showInfoNotification } from "common/ui/notification";
import type { Entity } from "common/entities/types";
import type { Context } from "common/types/context";
import { CancellablePromise } from "common/types/promises";
import type { AssignmentEvent } from "common/types/scheduler";
import type { Site } from "common/types/sites";
import { getSubTreeSingleNodes } from "common/utils/tree";

const getGroupName = (event: AssignmentEvent, site: Site) => {
  const siteName = site.isGroup ? event.site : site.name;
  return `${siteName}.${event.entityName}`;
};

const getContactsQty = (assignments: AssignmentEvent[] = []) =>
  R.uniqBy((a) => a?.targetId?.id, assignments).length;

export const publishAssignmentsBySite = (
  assignments: AssignmentEvent[],
  context: Context,
) => {
  const groupedAssignments = R.groupBy(
    (event) => getGroupName(event, context.site),
    assignments,
  );

  const publishRequests = Object.keys(groupedAssignments).map((key) => {
    const [siteName, entityName] = key.split(".");
    const { apiCall } = context.createContextForSiteIfExists(siteName);
    const ids = groupedAssignments[key].map((e) => e.id);
    return recordsApi(apiCall).bulkPublish(entityName, ids);
  });

  const contactsQty = getContactsQty(assignments);
  const message =
    contactsQty === 1
      ? _("The schedule was successfully shared with 1 contact")
      : _(
          "The schedule was successfully shared with {NUMBER} contacts",
        ).replace("{NUMBER}", String(contactsQty));

  return CancellablePromise.all(publishRequests).then(returnAndNotify(message));
};

export const publishAll = (
  { site, sites, createContextForSiteIfExists }: Context,
  assignmentsEntities: Entity[],
) => {
  const subSites = getSubTreeSingleNodes(sites, site);

  const publishRequests = R.flatten(
    subSites.map((s) =>
      assignmentsEntities.map((e) =>
        // publish for every entity/sub-entity and every assignment entity
        recordsApi(createContextForSiteIfExists(s.name).apiCall).publishAll(
          e.name,
        ),
      ),
    ),
  );
  return CancellablePromise.all(publishRequests).then((publishedQty) => {
    const totalQty = publishedQty.reduce<number>((acc, qty) => acc + qty, 0);
    const message =
      totalQty === 0
        ? _("No pending assignment was found in the current site")
        : _("{NUMBER} assignments were published successfully").replace(
            "{NUMBER}",
            String(totalQty),
          );

    return showInfoNotification(message);
  });
};
