import { JSX } from "react";
import { Entity } from "common/entities/types";
import { Layout } from "common/form/types";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import {
  defaultDisplayTypes,
  RelatedFormDisplayTypes,
} from "common/types/related-form-display";
import { ResolvedDependencies } from "common/with-dependencies";
import { withValue, WithValue } from "common/with-value";
import { getDefaultValueForm } from "../functions";
import { RelatedForm } from "../related-form";
import { Custom } from "../types";
import { isValid } from "./functions";

interface PropTypes {
  context: Context;
  entity: Entity;
  layout: Layout;
  defaultForm: Properties;
  onSave: () => any;
  onPrevious?: () => void;
  onNext?: () => void;
  onCancel?: () => any;
  custom: Custom;
  dependencies?: ResolvedDependencies;
  footerContent?: JSX.Element;
  isTemplate?: boolean;
  displayTypes?: RelatedFormDisplayTypes;
}

const EventFormComp = ({
  context,
  entity,
  layout,
  defaultForm,
  displayTypes = defaultDisplayTypes,
  onSave,
  onPrevious,
  onNext,
  onCancel,
  custom,
  dependencies,
  footerContent,
  isTemplate,
  value,
  onChangeSetValue,
}: PropTypes & WithValue<Properties>) => (
  <RelatedForm
    context={context}
    layout={layout}
    defaultForm={defaultForm}
    entity={entity}
    displayTypes={displayTypes}
    onSave={onSave}
    onCancel={onCancel}
    onPrevious={onPrevious}
    onNext={onNext}
    isValid={isTemplate || isValid(value)}
    custom={custom}
    footerContent={footerContent}
    value={getDefaultValueForm(value, dependencies)}
    onChange={onChangeSetValue}
  />
);

export const EventForm = withValue<Properties, PropTypes>(
  EventFormComp,
  "EventForm",
);
