import { useState, MouseEvent } from "react";
import { userAuthApi } from "common/api/authentication/user";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { showInfoNotification } from "common/ui/notification";

export const useResetEmail = (
  context: Context,
  callBack?: (error: ApiErrorResponse) => void,
) => {
  const [resetPasswordError, setResetPasswordError] = useState();
  const [isSent, setIsSent] = useState(false);

  const onSendClick = (e: MouseEvent) => {
    e.preventDefault();
    userAuthApi(context.apiCall)
      .requestNewPassword({
        tenant: context.tenant.name,
        userName: context.userName,
      })
      .then(() => {
        setIsSent(true);
        showInfoNotification(
          _(
            "Password reset email sent. Please do not close this tab to not lose the progress.",
          ),
        );
      })
      .catch((error) => {
        callBack?.(undefined);
        setResetPasswordError(error);
      });
  };

  return {
    resetPasswordError,
    isSent,
    onSendClick,
  };
};
