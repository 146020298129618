import * as R from "ramda";
import { Component, JSX } from "react";
import { Entities, EntityJoin } from "common/entities/types";
import { JoinItem, JoinType } from "common/query/types";
import { createList } from "common/widgets/list";
import { OnChange, ValueProps } from "common/with-value-for";
import { JoinHeader } from "./header";

interface PropTypes extends ValueProps<JoinItem> {
  entities: Entities;
  baseEntityName: string;
  joinEntityName: string;
  onRemoveGroup: () => void;
  validJoinTypes?: JoinType[];
  oneToOneOnly?: boolean;
}

export const List = createList<JoinItem>();

const getEntityName = (defs: EntityJoin[], item: JoinItem) =>
  item.entity ||
  R.find((i) => i.outbound && i.column === item.column, defs).entityName;

export class Join extends Component<PropTypes> {
  static readonly displayName = "Join";

  canDelete = (item: JoinItem) => {
    return !(item && item.joins);
  };

  onRemoveGroup = (index: number) => {
    return () => {
      const { value } = this.props;
      const newValue = R.remove(index, 1, value.joins);
      this.props.onChange({ ...this.props.value, joins: newValue });
    };
  };

  onChangeJoins = (joins: JoinItem[]) => {
    this.props.onChange({ ...this.props.value, joins });
  };

  renderItem = (
    item: JoinItem,
    onChange: OnChange<JoinItem>,
    index: number,
  ) => {
    const {
      entities,
      baseEntityName,
      joinEntityName,
      validJoinTypes,
      oneToOneOnly,
    } = this.props;
    const defs = entities[joinEntityName].joins;
    return (
      <Join
        entities={entities}
        baseEntityName={baseEntityName}
        joinEntityName={getEntityName(defs, item)}
        onRemoveGroup={this.onRemoveGroup(index)}
        validJoinTypes={validJoinTypes}
        oneToOneOnly={oneToOneOnly}
        value={item}
        onChange={onChange}
      />
    );
  };

  render(): JSX.Element {
    const {
      entities,
      baseEntityName,
      joinEntityName,
      onRemoveGroup,
      validJoinTypes,
      oneToOneOnly,
      value,
      onChange,
    } = this.props;
    const isGroupHeader = value && !!value.joins;
    return (
      <div className={isGroupHeader ? "x-query-group-container" : ""}>
        <JoinHeader
          entities={entities}
          baseEntityName={baseEntityName}
          joinEntityName={joinEntityName}
          value={value}
          isGroupHeader={isGroupHeader}
          validJoinTypes={validJoinTypes}
          oneToOneOnly={oneToOneOnly}
          onRemoveGroup={onRemoveGroup}
          onChange={onChange}
        />
        {value && !!value.joins ? (
          <div className="x-query-group-border">
            <List
              onDisplay={this.renderItem}
              canDelete={this.canDelete}
              value={value.joins}
              onChange={this.onChangeJoins}
            />
          </div>
        ) : undefined}
      </div>
    );
  }
}
