import { getFalse, getTrue } from "common";
import { getBooleanLabel } from "common/widgets/checkbox/label";
import { RadioButtonList } from "common/widgets/radio-button-list";
import { ValueProps } from "common/with-value-for";

interface PropTypes extends ValueProps<boolean> {
  name: string;
  disabled?: boolean;
  className?: string;
  allowClear?: boolean;
}

export const BooleanInput = ({
  name,
  disabled,
  className,
  allowClear,
  value,
  onChange,
}: PropTypes) => (
  <RadioButtonList
    name={name}
    display={getBooleanLabel}
    items={[true, false]}
    isDisabled={disabled ? getTrue : getFalse}
    className={className}
    allowClear={allowClear}
    value={value}
    onChange={onChange}
  />
);
