import { recordsApi } from "common/api/records";
import { Component } from "common/component";
import { Context } from "common/types/context";
import { MapValue } from "common/vendor-wrappers/leaflet/types";
import { LoadingIcon } from "common/widgets/loading-icon";
import { QueryMap } from "./query-map";
import { Data } from "./types";

interface PropTypes {
  context: Context;
  data: Data;
  entity: string;
  defaultPin: string;
  selectedPin: string;
  showAll: boolean;
  mapEntity: string;
  mapId: string;
}

interface MapRecord extends MapValue {
  image: string;
}

interface StateType {
  loading?: boolean;
  error?: boolean;
  value?: MapRecord;
}

export class MapController extends Component<PropTypes, StateType> {
  state: StateType = {
    loading: true,
    error: false,
    value: undefined,
  };

  componentDidMount() {
    this.load();
  }

  load = () => {
    const { context, mapEntity, mapId } = this.props;
    if (!mapEntity || !mapId) return;

    this.setState({ loading: true, error: false, value: undefined });
    recordsApi(context.apiCall)
      .get(mapEntity, mapId)
      .then((r) =>
        this.setState({ value: r.properties as MapRecord, loading: false }),
      )
      .catch(() => this.setState({ error: true, loading: false }));
  };

  render() {
    const { context, entity, selectedPin, defaultPin, showAll, data } =
      this.props;
    const { loading, error, value } = this.state;
    if (loading) return <LoadingIcon />;
    if (error) return <span>Oops, something went wrong loading the map</span>;

    return (
      <QueryMap
        context={context}
        entity={entity}
        selectedPin={selectedPin}
        defaultPin={defaultPin}
        showAll={showAll}
        data={data}
        image={value.image}
        value={value}
        onChange={this.onChangeMergeState("value")}
      />
    );
  }
}
