import * as R from "ramda";
import { Context } from "common/types/context";
import { Captions, Labels, TranslateKey } from "common/culture/types";
import {
  showAll,
  SimplePagination,
  Page,
} from "common/widgets/simple-pagination";
import { OnChange, ValueComponent } from "common/with-value-for";
import { Language } from "./language";

const LANGS_PER_PAGE = 3;

export interface TargetLangValue {
  labels: Labels;
  page: Page;
}

interface PropTypes {
  context: Context;
  keys: string[];
  allCultures: boolean;
  translateKey: TranslateKey;
  isRequired?: boolean;
}

export class TargetLang extends ValueComponent<TargetLangValue, PropTypes> {
  captionChangeCache: { [index: string]: OnChange<Captions> } = {};

  onChangeCaptions = (culture: string) => {
    return (
      this.captionChangeCache[culture] ||
      (this.captionChangeCache[culture] = (value) =>
        this.mergeValue({
          labels: R.mergeRight(this.props.value.labels, {
            [culture]: value,
          }),
        }))
    );
  };

  render() {
    const { context, keys, isRequired, allCultures, translateKey, value } =
      this.props;
    const { cultures, supportedCultures, uiFormat } = context;

    const culturesWithoutCurrent = cultures.filter(
      (c) => c !== uiFormat.culture,
    );

    const orderedCultures = cultures
      .filter((c) => c === uiFormat.culture)
      .concat(culturesWithoutCurrent);

    const filteredCultures = allCultures
      ? orderedCultures
      : culturesWithoutCurrent;

    const sourceLabels =
      value && value.labels ? value.labels[uiFormat.culture] : undefined;

    const page = value?.page || 0;
    const pagedCultures = showAll(page)
      ? filteredCultures
      : filteredCultures.slice(
          page * LANGS_PER_PAGE,
          (page + 1) * LANGS_PER_PAGE,
        );

    return (
      <div className="row x-targets-wrapper">
        <label className="qa-target-label">{_("Target")}</label>
        <div className="row x-targets-lang-groups">
          {pagedCultures.map((culture) => {
            return (
              <Language
                key={culture}
                allCultures={allCultures}
                apiCall={context.apiCall}
                culture={R.find((c) => c.name === culture, supportedCultures)}
                translateKey={translateKey}
                sourceLabels={sourceLabels}
                keys={keys}
                uiCulture={uiFormat.culture}
                isRequired={isRequired}
                value={value?.labels && value?.labels[culture]}
                onChange={this.onChangeCaptions(culture)}
              />
            );
          })}
        </div>
        {filteredCultures.length > LANGS_PER_PAGE ? (
          <div className="x-targets-lang-pagination">
            <SimplePagination
              itemsPerPage={LANGS_PER_PAGE}
              totalItems={filteredCultures.length}
              value={page}
              onChange={this.onChangeMergeValue("page")}
            />
          </div>
        ) : undefined}
      </div>
    );
  }
}
