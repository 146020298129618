import { useEffect, useState } from "react";
import { ApiCall } from "common/types/api";
import { ESignatureSettings } from "common/types/esignature-settings";
import { eSignatureUiSettingsApi } from "common/api/e-signature-settings";
import { ESignature } from "common/types/reasons";
import { defaultFor } from "common";
import { isPasswordAndESignatureFilled } from "./functions";

export const useESignatureContext = (
  apiCall: ApiCall,
  requiresAuthentication: boolean,
  hideReason?: boolean,
) => {
  const [eSignatureSettings, setESignatureSettings] =
    useState<ESignatureSettings>(defaultFor<ESignatureSettings>());
  const [password, setPassword] = useState<string>(undefined);
  const [reason, setReason] = useState<ESignature>(undefined);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(undefined);

  useEffect(() => {
    if (!requiresAuthentication) {
      setLoading(false);
      return;
    }
    eSignatureUiSettingsApi(apiCall)
      .get()
      .then((eSignatureSettings) => {
        setESignatureSettings(eSignatureSettings);
        setLoading(false);
        setLoadingError(undefined);
      })
      .catch((error) => {
        setLoading(false);
        setLoadingError(error);
      });
  }, [apiCall, requiresAuthentication]);

  const isFilled = isPasswordAndESignatureFilled(
    eSignatureSettings,
    reason,
    password,
    hideReason,
  );

  return {
    eSignatureSettings,
    password,
    reason,
    loading,
    loadingError,
    onReasonChange: setReason,
    onPasswordChange: setPassword,
    isFilled,
  };
};
