import Linkify from "linkify-react";
import { classNames } from "common/utils/jsx";
import { Announcement } from "common/types/announcement";

export interface PropTypes {
  announcement: Announcement;
  onDismiss?: (id: number) => void;
}

export const AnnouncementView = ({ announcement, onDismiss }: PropTypes) => {
  const { id, type, title, message } = announcement;
  const isWarning = type === "Warning";
  const onDismissClick = () => onDismiss(id);

  return (
    <div
      className={classNames([
        "x-announcement",
        isWarning ? "x-warning-announcement" : "x-info-announcement",
      ])}
    >
      <i
        className={
          "x-announcement-icon fa fa-2x " +
          (isWarning ? "fa-warning" : "fa-comment-o")
        }
      />
      <div className="x-announcement-title">{title}</div>
      <div className="x-announcement-message">
        <Linkify>{message}</Linkify>
      </div>
      {onDismiss ? (
        <i
          className="x-announcement-close fa fa-2x fa-fw fa-close"
          onClick={onDismissClick}
        />
      ) : undefined}
    </div>
  );
};
