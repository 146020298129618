import * as R from "ramda";
import { Culture } from "common/culture/supported-cultures";
import { merge3 } from "common/merge";
import { Captions, HasLabels, Labels } from "./types";

export const translateName = (captions: Captions): string => captions.name;

export const updateNameLabels = <T extends HasLabels>(
  culture: Culture,
  name: string,
  value: T,
): T =>
  merge3("labels", culture, "name", name as T["labels"][string]["name"], value);

export const preserveCaptions =
  (allowedKeys: string[]) =>
  (captions: Captions): Captions =>
    R.pick(allowedKeys, captions);

export const preserveLabels = (
  allowedKeys: string[],
  labels: Labels,
): Labels =>
  labels ? R.mapObjIndexed(preserveCaptions(allowedKeys), labels) : undefined;

export const cleanUp =
  <T extends { labels: Labels }>(getKeys: (i: T) => string[]) =>
  (item: T): T => ({
    ...item,
    labels: preserveLabels(getKeys(item), item.labels),
  });

export interface KeyLabel {
  key: string;
  label: string;
}

export const keyLabel = (key: string, label: string): KeyLabel => ({
  key,
  label,
});

export const validateCaptions = (keys: string[], captions: Captions): boolean =>
  captions && R.all((k) => !!captions[k], keys);

export const getValidCultureCount = (
  cultures: Culture[],
  keys: string[],
  labels: Labels,
): number =>
  labels ? cultures.filter((c) => validateCaptions(keys, labels[c])).length : 0;

export const notTranslatedLabels = (
  labels: Labels,
  cultures: Culture[],
  keys: string[],
) =>
  labels
    ? cultures.filter((culture) => !validateCaptions(keys, labels[culture]))
    : [];

export const formatSeconds = (v: number) =>
  v === 1 ? _("1 second") : _("NUMBER seconds").replace("NUMBER", `${v}`);
export const formatMinutes = (v: number) =>
  v === 1 ? _("1 minute") : _("NUMBER minutes").replace("NUMBER", `${v}`);
export const formatHours = (v: number) =>
  v === 1 ? _("1 hour") : _("NUMBER hours").replace("NUMBER", `${v}`);
export const formatDays = (v: number) =>
  v === 1 ? _("1 day") : _("NUMBER days").replace("NUMBER", `${v}`);

export const formatRecords = (count: number) =>
  count === 1
    ? _("1 record")
    : _("NUMBER records").replace("NUMBER", `${count || 0}`);
