import * as R from "ramda";
import { daysInMonth } from "common/date-time/calculators";
import { getCurrentYear } from "common/date-time/common";
import { ExpressionField } from "common/types/cron";
import { isNumeric } from "common/utils/number";

export const optionsForSequence = (start: number, end: number) =>
  R.unfold((n) => (n <= end ? [n, n + 1] : null), start);

/**
 * The exception for February added here is deliberate and shouldn't be "optimized".
 * When setting up the cron and selecting `the last day of February` in a leap year
 * it would be stored as 29th. When executed in a normal year this cron wouldn't
 * be executed at the last day of February which would be 28th not 29th. This exception
 * makes sure cron will be executed no matter how many days there are in February
 * even though in the leap year it is executed one day ahead of time.
 *
 * @param month
 */
export const getMaxDayInMonth = (month: ExpressionField) =>
  !isNumeric(month)
    ? 31
    : month === 2
      ? 28
      : daysInMonth(getCurrentYear(), month);

export const labelFor = (label: string) => (
  <div className="x-flex-grow-1 x-align-right" key={label}>
    {label}
  </div>
);
