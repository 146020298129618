import { EN_US } from "common/culture/supported-cultures";
import { getEnvironment } from "common/monitoring/environment";
import {
  GlobalContextProperties,
  InitializationParams,
} from "common/monitoring/types";

const removeEmptyProperties = (properties: GlobalContextProperties) =>
  Object.fromEntries(Object.entries(properties).filter(([, value]) => !!value));

export const getInitialConfiguration = (params: InitializationParams = {}) => ({
  clientToken: "pubd54397f7bb841e5984558d0687a58dda",
  site: "datadoghq.com",
  service: "x5-frontend",
  version: params?.version,
  env: getEnvironment(),
});

export const getGlobalPropertiesFromParams = (params: InitializationParams) =>
  removeEmptyProperties({
    application: "X5",
    scope: params?.scope,
    language: params?.language ?? EN_US,
    tenant: params?.tenant,
    parentTenant: params?.parentTenant,
    site: params?.site,
  });
