import { useEffect, useState } from "react";
import { toast, ToastItem } from "react-toastify";
import { versionApi } from "common/api/version";
import { showWarningNotification } from "common/ui/notification";
import { eventBus } from "common/event-bus";
import { ApiCall } from "common/types/api";
import { CancellablePromise } from "common/types/promises";
import { reloadPage } from "common/utils/window-location";
import { Warning } from "common/widgets/warning";

interface UpdaterProps {
  apiCall: ApiCall;
  contextAppVersion?: string;
}

const UPDATE_WARNING_TIMEOUT_MINUTES = 10;
const UPDATE_TOAST_ID = "app-update";

export const Updater = ({ apiCall, contextAppVersion }: UpdaterProps) => {
  const [showWarning, setShowWarning] = useState(false);
  const [isDiscarded, setIsDiscarded] = useState(false);
  const [initialAppVersion, setInitialAppVersion] =
    useState<string>(contextAppVersion);
  const [appVersion, setAppVersion] = useState<string>(contextAppVersion);

  const toastUnsubscribe = toast.onChange((payload: ToastItem) => {
    if (payload.id === UPDATE_TOAST_ID && payload.status === "removed") {
      reloadPage();
    }
  });

  const getVersion = () =>
    apiCall
      ? versionApi(apiCall).version()
      : CancellablePromise.resolve(undefined);

  const getCurrentVersion = () =>
    getVersion().then((version) => {
      if (version !== appVersion) setAppVersion(version);
    });

  useEffect(() => {
    const onChunkLoadingErrorSubscription = eventBus.on(
      "ChunkLoadingError",
      () => {
        if (navigator.onLine && !isDiscarded) getCurrentVersion();
      },
    );

    return () => {
      onChunkLoadingErrorSubscription.unsubscribe();
      toastUnsubscribe();
    };
  }, [isDiscarded]);

  useEffect(() => {
    if (appVersion !== initialAppVersion) setShowWarning(true);
  }, [appVersion]);

  useEffect(() => {
    if (contextAppVersion && contextAppVersion !== appVersion) {
      setAppVersion(contextAppVersion);
    }
  }, [contextAppVersion]);

  useEffect(() => {
    getVersion().then((version) => {
      if (!initialAppVersion) setInitialAppVersion(version);
      setAppVersion(version);
    });
  }, []);

  const onConfirm = () => {
    setShowWarning(false);
    reloadPage();
  };

  const onDecline = () => {
    setIsDiscarded(true);
    setShowWarning(false);

    showWarningNotification(
      _("The application will be updated shortly. Click here to update now."),
      {
        autoClose: UPDATE_WARNING_TIMEOUT_MINUTES * 60 * 1000,
        hideProgressBar: false,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        position: "top-center",
        toastId: UPDATE_TOAST_ID,
      },
    );
  };

  return (
    <>
      {showWarning ? (
        <Warning
          title={_("Required Application Update")}
          content={_(
            "A new version of the application is available. Please save any changes before the update. Any unsaved changes will be lost.",
          )}
          isCancelModify={true}
          action1={_("Update In {MINUTES} Minutes").replace(
            "{MINUTES}",
            String(UPDATE_WARNING_TIMEOUT_MINUTES),
          )}
          action2={_("Update Now")}
          onAction1={onDecline}
          onAction2={onConfirm}
        />
      ) : null}
    </>
  );
};
