import * as R from "ramda";
import { emptyGUID, isStarCondition } from "common/query/common";
import { Filter as FilterItem, Query } from "common/query/types";
import { getAnd } from "./common";

export const isStarFilter = (query: Query, ids: string[]) =>
  !!(query && getAnd(query.filter).some((a) => isStarCondition(ids, a)));

const addStarFilter = (query: Query, ids: string[]): FilterItem[] => [
  ...getAnd(query.filter),
  {
    name: "id",
    op: "in",
    value: R.join(",", ids) || emptyGUID,
  },
];

const removeStarFilter = (query: Query, ids: string[]): FilterItem[] =>
  getAnd(query.filter).filter((a) => !isStarCondition(ids, a));

export const toggleStarFilter = (query: Query, ids: string[]): Query =>
  R.mergeRight(query, {
    filter: R.mergeRight(query.filter, {
      and: isStarFilter(query, ids)
        ? removeStarFilter(query, ids)
        : addStarFilter(query, ids),
    }),
  });
