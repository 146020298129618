import { Component } from "react";

interface PropTypes {
  layerName: string;
  active: boolean;
  count: number;
  toggle: (layer: string, show: boolean) => void;
}

export class LayerToggle extends Component<PropTypes> {
  static readonly displayName = "LayerToggle";

  onToggle = () => {
    const { layerName, active, toggle } = this.props;
    toggle(layerName, !active);
  };

  render() {
    const { layerName, active, count } = this.props;
    return (
      <button
        className={`btn btn-${active ? "primary" : "default"}`}
        onClick={this.onToggle}
      >
        {layerName}
        <div className="badge bg-secondary x-margin-left-5">{count}</div>
      </button>
    );
  }
}
