import * as R from "ramda";
import { getLocalizedName } from "common";
import { getPathMap } from "common/query/joins";
import { Context } from "common/types/context";
import { Report } from "common/types/reports";
import { Role } from "common/types/roles";
import { getRolesMissingPermissions } from "./functions";

interface PropTypes {
  context: Context;
  roles: Role[];
  report: Report;
}

export const RolesWarning = ({ context, roles, report }: PropTypes) => {
  if (!roles.length) return null;

  const { entities } = context;

  const reportEntityNames = R.values(getPathMap(entities, report)).map(
    (e) => e.name,
  );

  const rolesMissingPermissions = getRolesMissingPermissions(
    roles,
    reportEntityNames,
  );

  if (!rolesMissingPermissions.length) return null;

  return (
    <div className="x-report-roles-warning">
      <h2>{_("Invalid roles")}</h2>
      <p>
        {_("The following roles do not have access to selected entities. ")}
        {_("This will prevent some users from viewing the report.")}
      </p>
      <div className="x-report-roles-warning-list">
        {rolesMissingPermissions.map((e, i) => (
          <dl key={i}>
            <dt>
              {e.roleName} <span>{_("Cannot read")}</span>
            </dt>
            {e.missingEntities.map((entityName) => (
              <dd key={entityName}>{getLocalizedName(entities[entityName])}</dd>
            ))}
          </dl>
        ))}
      </div>
    </div>
  );
};
