import { ScheduledEventRecord } from "common/types/scheduled-event";
import { withValue, WithValue } from "common/with-value";
import { AssetsList, PropTypes } from ".";

const WithLabel = ({
  context,
  entity,
  assetEntity,
  value,
  onChangeSetValue,
  query,
}: PropTypes & WithValue<ScheduledEventRecord>) => (
  <AssetsList
    context={context}
    entity={entity}
    assetEntity={assetEntity}
    value={value}
    query={query}
    onChange={onChangeSetValue}
  />
);

export const AssetListWithLabel = withValue<ScheduledEventRecord, PropTypes>(
  WithLabel,
  "WithLabel",
);
