import * as R from "ramda";
import { Culture } from "common/culture/supported-cultures";
import { Entity } from "common/entities/types";
import { EntityColumn } from "common/entities/entity-column/types";
import { translate as txSystem } from "./system-translator";
import { Captions } from "./types";
import { cleanUp } from "./functions";

const getLocalizedColumn = (name: string) => {
  const regex = name.match(/(.*)_([a-z]{2})([A-Z]{2})$/);
  return regex
    ? `${txSystem(regex[1])} (${regex.slice(2).join("-")})`
    : txSystem(name);
};

const hasCaption = (captions: Captions) => (c: EntityColumn) =>
  !!captions[`column.${c.name}`];

const nameFromCaptions = (captions: Captions) => (c: EntityColumn) =>
  R.mergeRight(c, { localizedName: captions[`column.${c.name}`] });

export const translate =
  (culture: Culture) =>
  (entity: Entity): Entity => {
    const { columns, name, labels } = entity;

    const captions = (labels && labels[culture]) || {};
    const localizedName = (captions && captions.name) || txSystem(name);

    const columnsWithoutCaptions = R.reject(hasCaption(captions), columns);
    const missingCaptions = R.fromPairs(
      columnsWithoutCaptions.map(
        (c) =>
          [`column.${c.name}`, getLocalizedColumn(c.name)] as [string, string],
      ),
    );

    const updatedCaptions = R.mergeRight(captions, missingCaptions);

    const captionsWithName = updatedCaptions.name
      ? updatedCaptions
      : R.mergeRight(updatedCaptions, { name: localizedName });

    const updatedLabels = R.mergeRight(labels, { [culture]: captionsWithName });

    const translatedColumns = columns.map(nameFromCaptions(updatedCaptions));

    return R.mergeRight(entity, {
      localizedName,
      columns: translatedColumns,
      labels: updatedLabels,
    });
  };

export const translateKey = (key: string): string =>
  key === "name" ? _("Name") : key?.replace(/^column\./, "");

export const getKeys = (entity: Entity): string[] =>
  entity
    ? ["name"].concat((entity.columns || []).map((c) => `column.${c.name}`))
    : [];

export const cleanUpLabels = cleanUp(getKeys);
