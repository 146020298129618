import * as R from "ramda";
import { currenciesApi } from "common/api/currency-rates";
import { searchApi } from "common/api/search";
import {
  addPartSuppliersUnitCostResults,
  getNewPartSupplierIds,
  getPartSupplierQuery,
  getTotalEstimatedCost,
  getTotalEstimatedCostMessage,
} from "common/record/form/content/related/requisitioning-item/functions";
import {
  PartSuppliersUnitCostResults,
  TotalEstimatedCost,
} from "common/record/form/content/related/requisitioning-item/types";
import { CurrencyRateIndex } from "common/types/currencies";
import { ApiErrorResponse } from "common/types/error";
import { CancellablePromise } from "common/types/promises";
import { Properties, Record } from "common/types/records";
import { ApiError } from "common/ui/api-error";
import { VerticalField } from "common/ui/field";
import { AlertWarning } from "common/widgets/alert";
import { LoadingIcon } from "common/widgets/loading-icon";
import { ValueComponent } from "common/with-value-for";
import { indexRates } from "x/account-settings/currencies/functions";
import { getEntityByBehavior } from "common/entities";
import { Entity } from "common/entities/types";
import { FormSelector } from "common/form/form-selector";
import { filterFormsByEntity } from "common/functions/forms";
import { Context } from "common/types/context";
import { Form } from "common/types/forms";
import { Required } from "common/widgets/required";
// eslint-disable-next-line import/no-cycle
import { RequisitionListItem } from "./list-item";
import { CreatePoParams, EditableField } from "./types";

interface StateType {
  loading: boolean;
  error: ApiErrorResponse;
  currencyRates: CurrencyRateIndex;
  partSuppliersUnitCostResults: PartSuppliersUnitCostResults;
  purchaseOrderForms: Form[];
}

interface PropTypes {
  context: Context;
  reqItemEntity: Entity;
  editableFields: EditableField[];
  showPurchaseOrderFormSelector: boolean;
}

export class RequisitioningForm extends ValueComponent<
  CreatePoParams,
  PropTypes,
  StateType
> {
  static readonly displayName = "RequisitioningForm";

  state: StateType = {
    loading: true,
    error: undefined,
    currencyRates: undefined,
    partSuppliersUnitCostResults: {},
    purchaseOrderForms: [],
  };

  componentDidMount() {
    const { value, context } = this.props;
    const purchaseOrderEntity = getEntityByBehavior(
      "PurchaseOrder",
      context.entities,
    );
    const purchaseOrderForms = filterFormsByEntity(
      context.forms,
      purchaseOrderEntity.name,
    );
    const formId =
      purchaseOrderForms?.length === 1 ? purchaseOrderForms[0].id : undefined;
    this.setState({ purchaseOrderForms });
    this.setValue({ ...value, formId });

    CancellablePromise.all([
      this.loadCurrencyRates(),
      this.loadPartSuppliersUnitCostResults(getNewPartSupplierIds(value.items)),
    ])
      .then(([currencyRates, partSuppliersUnitCostResults]) => {
        this.setState({
          loading: false,
          currencyRates,
          partSuppliersUnitCostResults,
        });
      })
      .catch(this.onError);
  }

  loadCurrencyRates = (): CancellablePromise<CurrencyRateIndex> => {
    const { context } = this.props;
    return currenciesApi(context.apiCall).list().then(indexRates);
  };

  loadPartSuppliersUnitCostResults = (
    newPartSupplierIds: string[],
  ): CancellablePromise<PartSuppliersUnitCostResults> => {
    const { context, reqItemEntity } = this.props;
    const { apiCall } = context;
    const { partSuppliersUnitCostResults } = this.state;

    const partSupplierQuery = getPartSupplierQuery(
      newPartSupplierIds,
      reqItemEntity,
    );

    if (partSupplierQuery) {
      return searchApi(apiCall)
        .runQuery(partSupplierQuery)
        .then((partSupplierRecords: Properties[] = []) => {
          return addPartSuppliersUnitCostResults(
            partSuppliersUnitCostResults,
            partSupplierRecords,
          );
        });
    }
    return CancellablePromise.resolve(partSuppliersUnitCostResults);
  };

  onChange = (index: number) => (record: Record) => {
    const { value } = this.props;
    const newValue = R.update(index, record, value.items);
    this.setValue({ ...value, items: newValue });
    this.checkLoadPartSuppliersUnitCostResults(newValue);
  };

  onFormSelectorChange = (formId: number) => {
    const { value } = this.props;
    this.setValue({ ...value, formId });
  };

  checkLoadPartSuppliersUnitCostResults = (newValue: Record[]) => {
    const { partSuppliersUnitCostResults } = this.state;

    const newIds = getNewPartSupplierIds(
      newValue,
      partSuppliersUnitCostResults,
    );

    if (newIds.length) {
      this.setState({ loading: true, error: undefined });
      this.loadPartSuppliersUnitCostResults(newIds)
        .then((partSuppliersUnitCostResults) => {
          this.setState({
            loading: false,
            partSuppliersUnitCostResults,
          });
        })
        .catch(this.onError);
    }
  };

  onError = (error: ApiErrorResponse) => {
    this.setState({ loading: false, error });
  };

  render() {
    const {
      context,
      reqItemEntity,
      editableFields,
      showPurchaseOrderFormSelector,
      value,
    } = this.props;
    const {
      loading,
      error,
      currencyRates,
      partSuppliersUnitCostResults,
      purchaseOrderForms,
    } = this.state;

    if (loading && !currencyRates) {
      return <LoadingIcon />;
    }

    if (!value.items.length) {
      return <AlertWarning message={_("No approved items found")} />;
    }

    const { totalEstimatedCost, converted }: TotalEstimatedCost =
      getTotalEstimatedCost(
        context,
        value.items,
        partSuppliersUnitCostResults,
        currencyRates,
      );

    const totalEstimatedCostMessage = getTotalEstimatedCostMessage(
      totalEstimatedCost,
      context?.currency.symbol,
      converted,
    );

    return (
      <div className="x-requisition-form">
        {loading && <LoadingIcon />}
        {error && <ApiError error={error} />}
        {showPurchaseOrderFormSelector && purchaseOrderForms?.length ? (
          <div className="x-form-selector-container">
            <VerticalField
              className="x-form-selector qa-form-selector-label"
              isRequired={true}
              label={_("Purchase Order Form")}
              input={
                <Required value={value.formId}>
                  <FormSelector
                    className="qa-form-selector"
                    forms={purchaseOrderForms}
                    value={value.formId}
                    onChange={this.onFormSelectorChange}
                  />
                </Required>
              }
            />
          </div>
        ) : undefined}
        {totalEstimatedCostMessage && (
          <VerticalField
            label={totalEstimatedCostMessage.label}
            input={
              <div className="x-bold">{totalEstimatedCostMessage.value}</div>
            }
          />
        )}
        <table className="x-requisition-list">
          <thead>
            <tr className="x-requisition-list-header">
              <th>{_("Part")}</th>
              <th>{_("Quantity")}</th>
              <th>{_("Part Supplier")}</th>
              <th>{_("Estimated Cost")}</th>
            </tr>
          </thead>
          <tbody>
            {value.items.map((item, index) => (
              <RequisitionListItem
                key={index}
                context={context}
                reqItemEntity={reqItemEntity}
                editableFields={editableFields}
                partSuppliersUnitCostResults={partSuppliersUnitCostResults}
                index={index}
                value={item}
                onChange={this.onChange(index)}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
