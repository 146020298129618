import * as R from "ramda";
import { getLocalizedName } from "common";
import { hasBehavior } from "common/api/behavior";
import {
  numericTypes,
  queryBuilderBlackList,
  useExpressionBlackList,
} from "common/entities/entity-column/data-type/types";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entities, Entity } from "common/entities/types";
import { systemColumnsToOmit } from "common/form/functions/entity";
import { BehaviorName } from "common/types/behaviors";
import { Role } from "common/types/roles";
import { SITE_DATA_VIEW, USER_DATA_VIEW } from "common/types/view";

export const getLocalizedColumnName = (entity: Entity, columnName: string) => {
  const column = R.find((c) => c.name === columnName, entity.columns);
  return getLocalizedName(column) || columnName;
};

export const isCustomOrSystemFk = (column: EntityColumn) =>
  !!column &&
  (!!column.isForeignKey ||
    column.dataType === "systemintfk" ||
    column.dataType === "systemstringfk");

export const filterEntityColumns = (
  entity: Entity,
  filter: (e: EntityColumn) => boolean,
) => {
  if (!entity) return [];
  const { columns = [] } = entity;
  return columns.filter(filter);
};

export const isRelatedSiteDataColumn = (column: EntityColumn) =>
  column?.relatedEntity === SITE_DATA_VIEW;

export const isRelatedUserDataColumn = (column: EntityColumn) =>
  column?.relatedEntity === USER_DATA_VIEW;

export const isNumericColumn = (column: EntityColumn) =>
  R.includes(column.dataType, numericTypes);

export const isDateTimeColumn = (column: EntityColumn) =>
  column.dataType === "datetime";

export const isFkToEntityColumn =
  (entityName: string) => (column: EntityColumn) =>
    column.dataType === "fk" && column.relatedEntity === entityName;

// column is fk to an entity with given behavior
export const isFkToEntityWithBehavior =
  (behavior: BehaviorName, entities: Entities) => (column: EntityColumn) =>
    column.dataType === "fk" &&
    hasBehavior(entities[column.relatedEntity].behaviors, behavior);

export const isQuickInput = (c: EntityColumn) => c && c.quickInput;
export const isUnique = (c: EntityColumn) => c && c.required && c.unique;

export const isAllowedTableColumn = (c: EntityColumn) =>
  c.name !== "isDeleted" &&
  c.name !== "id" &&
  !R.includes(c.dataType, queryBuilderBlackList);

const looseString = (s: string) => s && s.toLowerCase().replace(/^c_/, "");
const lowerString = (s: string) => s?.toLowerCase();
const isSiteColumn = (s: string) => looseString(s) === "site";

export const looseColumnNameCheck = (s1: string, s2: string) =>
  isSiteColumn(s1)
    ? lowerString(s1) === lowerString(s2)
    : looseString(s1) === looseString(s2);

export const isSameColumnData = (c1: EntityColumn) => (c2: EntityColumn) =>
  !!(
    c1 &&
    c2 &&
    c1.name &&
    c2.name &&
    !R.includes(c1.name, systemColumnsToOmit) &&
    !R.includes(c2.name, systemColumnsToOmit) &&
    looseColumnNameCheck(c1.name, c2.name) &&
    c1.dataType &&
    c1.dataType === c2.dataType &&
    c1.dataType !== "systemintfk" &&
    c1.dataType !== "systemstringfk" &&
    (c1.dataType === "fk"
      ? c1.relatedEntity && c1.relatedEntity === c2.relatedEntity
      : true)
  );

// This function is trying to check if it is an existing column instead of
// a new one. Basing the checks on available information like dataType,
// loose name, isSystem. Nevertheless that metadata ARE not enough,
// we must rely on a UID at the moment we've not: TODO implement an UID
// in Entity Column
export const isColumnExisting = (
  column: EntityColumn,
  entityColumns: EntityColumn[],
) =>
  !!(
    column &&
    entityColumns &&
    R.any(
      (c) =>
        looseColumnNameCheck(c.name, column.name) &&
        c.dataType === column.dataType &&
        !!c.isSystem === !!column.isSystem,
      entityColumns,
    )
  );

export const canColumnUseExpression = (column: EntityColumn) =>
  !useExpressionBlackList.includes(column?.dataType);

export const setReadOnly =
  (columnNames: string[]) =>
  (c: EntityColumn): EntityColumn =>
    (columnNames ?? []).includes(c.name) ? { ...c, readOnly: true } : c;

export const updateColumn =
  (columnName: string, properties: Partial<EntityColumn>) =>
  (entity: Entity): Entity => {
    const index = entity.columns.findIndex((c) => c.name === columnName);

    if (index === -1) return entity;

    const updatedColumn: EntityColumn = {
      ...entity.columns[index],
      ...properties,
    };

    return {
      ...entity,
      columns: R.update(index, updatedColumn, entity.columns),
    };
  };

export const getVirtualColumns = (entity: Entity) => {
  return filterEntityColumns(
    entity,
    (column: EntityColumn) => column.isVirtual,
  );
};

export const isRestrictedForRole = (roleIds: number[], currentRole: Role) =>
  roleIds?.length > 0 && !roleIds.includes(currentRole.id);
