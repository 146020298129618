import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { PoItem } from "common/types/purchase-orders";
import { PoListItem } from "./list-item";

export interface PropTypes {
  context: Context;
  entity: Entity;
  items: PoItem[];
}

export const PoList = ({ context, entity, items }: PropTypes) => (
  <div className="x-po-list">
    <table>
      <thead>
        <tr className="x-po-list-header">
          <th>{_("Ordered")}</th>
          <th>{_("Received")}</th>
          <th>{_("Part")}</th>
          <th>{_("Part Location")}</th>
          <th>{_("Unit Cost")}</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <PoListItem
            key={item.id}
            context={context}
            entity={entity}
            item={item}
          />
        ))}
      </tbody>
    </table>
  </div>
);
