import * as R from "ramda";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import { SelectorOption } from "common/widgets/selector/types";
import { getUtcNow } from "common/date-time/common";
import { formatDate } from "common/date-time/formatters";
import { DateFormat, TimeFormat } from "common/date-time/types";
import {
  getWeekdayNameFromNumber,
  WeekdayName,
  weekdayNames,
  WeekdayNumber,
} from "common/date-time/weekday";
import { arrayToString } from "common/utils/array";
import { formatDays as showDays, formatHours as showHours } from "./functions";

export const translateWeekdayName = (day: WeekdayName) => {
  switch (day) {
    case "Sunday":
      return _("Sunday");
    case "Monday":
      return _("Monday");
    case "Tuesday":
      return _("Tuesday");
    case "Wednesday":
      return _("Wednesday");
    case "Thursday":
      return _("Thursday");
    case "Friday":
      return _("Friday");
    case "Saturday":
      return _("Saturday");
    default:
      return "";
  }
};

export const translateWeekdayNumber = (d: WeekdayNumber) =>
  translateWeekdayName(getWeekdayNameFromNumber(d));

export const weekdayNamesTranslations = () =>
  weekdayNames.map(translateWeekdayName);

export const ordinalSuffix = (n: number) => {
  if (!n) return "";
  const defaultSuffix = _("th");
  switch (n % 10) {
    case 1:
      return n === 11 ? defaultSuffix : _("st");
    case 2:
      return n === 12 ? defaultSuffix : _("nd");
    case 3:
      return n === 13 ? defaultSuffix : _("rd");
    default:
      return defaultSuffix;
  }
};

export const showOffsetDescription = (days: number, hours: number) =>
  arrayToString([
    days ? showDays(days) : undefined,
    hours ? showHours(hours) : undefined,
  ]);

const MONTH_NAMES = () => [
  _("January"),
  _("February"),
  _("March"),
  _("April"),
  _("May"),
  _("June"),
  _("July"),
  _("August"),
  _("September"),
  _("October"),
  _("November"),
  _("December"),
];

export const showMonth = (month: number) =>
  (month && MONTH_NAMES()[month - 1]) || "";

export const showDay = (day: number) =>
  day ? `${day}${ordinalSuffix(day)}` : "";

export const showMonthDay = (month: number, day: number) =>
  arrayToString([showMonth(month), showDay(day)]);

export const showHour = (hour: number) => (R.isNil(hour) ? "" : `${hour}h`);

export const getTimeOptionLabel = (
  option: SelectorOption<DateFormat | TimeFormat>,
) =>
  isGroupedOption(option)
    ? option.label
    : `${formatDate(getUtcNow(), option)} (${option})`;

export const decimalSeparators = [".", ","];

export const translateDecimalSeparator = (separator: string) => {
  switch (separator) {
    case ".":
      return _("Point");
    case ",":
      return _("Comma");
    default:
      return "";
  }
};

export const getDecimalSeparatorLabel = (separator: SelectorOption<string>) =>
  isGroupedOption(separator)
    ? separator.label
    : translateDecimalSeparator(separator);
