import * as R from "ramda";
import { Component } from "common/component";
import { merge2 } from "common/merge";
import { Action } from "common/record/actions/action";
import { DismissAction } from "common/record/actions/types";
import { getMaxReadingPerDay } from "common/record/form/content/related/asset-meter-reading/functions";
import { hasProtectedColumns } from "common/record/utils";
import { ApiCall } from "common/types/api";
import { ErrorComponent } from "common/widgets/error";
import { LinkRecordsWithSeparator } from "common/widgets/link-records-with-separator";
// eslint-disable-next-line import/no-cycle
import { WorkOrderRecordActionForm } from "./form";
import {
  defaultDeps,
  getActionSignature,
  getDefaultValue,
  isValid,
  isValidAssetMeterReading,
} from "./functions";
import {
  ActionPayload,
  ActionStateType,
  WorkOrderActionPropTypes,
  WorkOrderActionRecordPayload,
} from "./types";

export class WorkOrderRecordAction extends Component<
  WorkOrderActionPropTypes,
  ActionStateType
> {
  static readonly displayName = "WorkOrderRecordAction";

  constructor(props: WorkOrderActionPropTypes) {
    super(props);
    const { context, entity, records, dependencies, actionName } = props;

    this.state = {
      formValue: getDefaultValue(
        context,
        entity,
        actionName,
        records,
        dependencies,
      ),
    };
  }

  onOk = (apiCall: ApiCall, dismiss: DismissAction) => {
    const { date, signature, actionRecords } = this.state.formValue;
    const { records, onOk } = this.props;

    const updatedRecords: WorkOrderActionRecordPayload[] = records.map((r) => {
      const id = r.properties.id;
      const actionRecord = R.find((c) => c.id === id, actionRecords);
      const recordWithId = merge2("properties", "id", id, actionRecord);

      const { assetMeterReading, assetMeters, meters } = recordWithId;
      const assetMeterId = assetMeterReading?.assetMeterId;
      const maxReadingPerDay = getMaxReadingPerDay(assetMeterId, assetMeters);

      if (
        assetMeterReading &&
        !isValidAssetMeterReading(
          assetMeters,
          meters,
          assetMeterReading,
          maxReadingPerDay,
        )
      ) {
        return { ...recordWithId, assetMeterReading: undefined };
      }

      return recordWithId;
    });

    const actionPayload: ActionPayload = {
      date,
      signature,
      records: updatedRecords,
    };

    return onOk(apiCall, dismiss, actionPayload);
  };

  render() {
    const {
      context,
      dismiss,
      entity,
      actionName,
      label,
      records = [],
      dependencies = defaultDeps,
    } = this.props;
    const { formValue } = this.state;

    const { checkProcedures = [], signatures = [] } = dependencies;
    const badRecords = records.filter((r) =>
      R.includes(r.properties.id, checkProcedures),
    );

    const existingSignatureIds = signatures.map((p) => p.id);
    const signatureSetting = getActionSignature(
      context,
      entity,
      actionName,
      records,
      existingSignatureIds,
    );

    return (
      <Action
        requiresAuthentication={hasProtectedColumns(entity)}
        context={context}
        dismiss={dismiss}
        entity={entity}
        records={records}
        title={label}
        btnLabel={label}
        onOk={
          !badRecords.length &&
          isValid(context, entity, actionName, signatureSetting, formValue)
            ? this.onOk
            : undefined
        }
        size="large"
      >
        {badRecords.length ? (
          <ErrorComponent
            title={_("Incomplete procedures")}
            message={_(
              "Complete task procedures before closing work order records: ",
            )}
            className="x-margin-20"
          >
            {/* TODO single usage widget. move it to this folder */}
            <LinkRecordsWithSeparator
              records={badRecords}
              entity={entity}
              site={context.site.name}
            />
          </ErrorComponent>
        ) : (
          <WorkOrderRecordActionForm
            context={context}
            entity={entity}
            actionName={actionName}
            totalRecords={records.length}
            signatureSetting={signatureSetting}
            value={formValue}
            onChange={this.onChangeMergeState("formValue")}
          />
        )}
      </Action>
    );
  }
}
