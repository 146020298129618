import * as R from "ramda";
import { isHiddenUiBehaviorName } from "common/api/behavior";
import { getBehaviorNameTranslation } from "common/api/behavior/translations";
import { Entity, RecordScope } from "common/entities/types";
import { isSelectField, SelectField } from "common/query/types";
import { MiniEntity } from "./types";

export const translateRecordScope = (recordScope: RecordScope) => {
  switch (recordScope) {
    case "SingleSite":
      return _("Single Site");
    case "SharedMultipleSites":
      return _("Shared: Multiple Sites");
    case "SharedAllSites":
      return _("Shared: All Sites");
    default:
      return recordScope;
  }
};

export const convertEntitiesToMiniEntities = (
  entities: Entity[],
): MiniEntity[] => {
  return entities.map((entity: Entity) => {
    const { name, type, behaviors, recordScope, localizedName } = entity;

    const entityType =
      type === "Reference"
        ? _("Reference")
        : type === "SubEntity"
          ? _("Related Entity")
          : _("Entity");

    const behaviorTranslations = behaviors
      .filter((b) => !isHiddenUiBehaviorName(b.name))
      .map((b) => getBehaviorNameTranslation(b.name));

    return {
      name,
      localizedName,
      type: entityType,
      scope: translateRecordScope(recordScope),
      behaviors: behaviorTranslations,
    };
  });
};

export const validateColumnsInQuery = (entity: Entity) => {
  const { query, columns } = entity;

  const selectFieldNames = query.select
    .filter(isSelectField)
    .map((selectField) => selectField.name);

  const { joins = [] } = query;
  const joinColumnNames = joins.map((j) => j.column);
  const columnNames = selectFieldNames.concat(joinColumnNames);

  return columns.filter((c) => R.includes(c.name, columnNames));
};

export const getTitleFieldForEntity = (entity: Entity) => {
  const select = entity?.query?.select;
  const titleField = select?.find(
    (s) => isSelectField(s) && s?.alias === "title",
  ) as SelectField | undefined;

  return titleField?.name || "number";
};
