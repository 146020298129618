import { Component } from "react";
import { HorizontalField } from "common/form/ui";
import { frequencyOptions } from "common/scheduled-event/functions";
import {
  IntervalOption,
  ProjectionFrequencyProps,
} from "common/types/scheduled-event";
import { AlertErrorTip } from "common/widgets/alert";
import { Selector } from "common/widgets/selector";
import { Hint } from "common/widgets/hint";
import { Uint } from "common/widgets/number";
import { Required } from "common/widgets/required";
import { ValueProps } from "common/with-value-for";
import { displayInterval } from "../pm/frequency";
import { getProjectionLengthError } from "./functions";

type PropTypes = ValueProps<ProjectionFrequencyProps>;

export class ProjectionFrequency extends Component<PropTypes> {
  static readonly displayName = "ProjectionFrequency";

  onChangeProjectionLength = (projectionLength: number) => {
    this.props.onChange({ ...this.props.value, projectionLength });
  };

  render() {
    const { value } = this.props;
    const { projectionLength, projectionInterval } = value;

    const options = frequencyOptions();
    const selectedInterval = options.find(
      (option) => option.value === projectionInterval,
    );

    const hintMessage = _(
      "Future Work Orders will be generated based on Projection length defined. You can generate up to 24 months of projections.",
    );

    const errorMessage = getProjectionLengthError(projectionLength);

    return (
      <HorizontalField label={_("Projection Length")}>
        <div className="x-flex qa-projection-frequency">
          <Required value={errorMessage ? undefined : projectionLength}>
            <Uint
              className="qa-projection-length"
              value={projectionLength}
              onChange={this.onChangeProjectionLength}
            />
          </Required>
          <Selector<IntervalOption>
            className="x-margin-left-10 qa-projection-interval"
            getOptionLabel={displayInterval}
            options={options}
            disabled={true}
            value={selectedInterval}
            onChange={undefined}
          />
        </div>
        <Hint message={hintMessage} />
        {errorMessage && <AlertErrorTip message={errorMessage} />}
      </HorizontalField>
    );
  }
}
