import * as R from "ramda";
import { isFalse, isTrue } from "common/utils/boolean";
import { HighlightProps, Label } from "common/widgets/label";

interface PropTypes extends HighlightProps {
  value: boolean | string;
  getLabel?: (value: boolean | string) => string;
}

export const getBooleanLabel = (value: boolean | string) => {
  if (R.isNil(value)) return undefined;

  if (isTrue(value)) return _("True");
  if (isFalse(value)) return _("False");

  return undefined;
};

export const getBooleanFromString = (value: string) =>
  getBooleanLabel(value ? JSON.parse(value) : undefined);

export const CheckboxLabel = ({
  value,
  getLabel,
  textToHighlight,
}: PropTypes) => {
  const getValueLabel = getLabel ?? getBooleanLabel;
  return (
    <Label value={getValueLabel(value)} textToHighlight={textToHighlight} />
  );
};
CheckboxLabel.displayName = "CheckboxLabel";
