import * as R from "ramda";
import escapeRegExp from "lodash-es/escapeRegExp";
import { highlight } from "common/widgets/highlight";
import { applyValueOrArray } from "common/utils";

export const isMatch = (filter: string) => (s: string | string[]) =>
  filter && s
    ? R.includes(filter.toLowerCase(), s.toString().toLowerCase())
    : false;

export const getHighlighter = (filter: string) => {
  if (!filter) return applyValueOrArray((s: string) => s);

  const escaped = escapeRegExp(filter.toLowerCase());
  const regex = new RegExp(`(${escaped})`, "ig");

  const replace = (s: string = "") =>
    s.toString().replace(regex, highlight("$1"));

  return applyValueOrArray(replace);
};
