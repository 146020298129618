import { JSX } from "react";
import { AlertWarning } from "common/widgets/alert";

export interface Error {
  message: string;
  buttons?: JSX.Element[];
}

interface PropTypes {
  errors: Error[];
  className?: string;
}

export const Errors = ({ errors = [], className = "" }: PropTypes) =>
  errors.length ? (
    <div className={`x-has-error ${className}`}>
      {errors.map((e, i) => (
        <AlertWarning
          key={i}
          className="qa-error"
          message={e.message}
          buttons={e.buttons}
        />
      ))}
    </div>
  ) : null;

Errors.displayName = "Errors";
