import * as R from "ramda";
import { ReactNode } from "react";
import { genericSortByField } from "common/functions/generics";
import { ReactHighlightWords } from "common/vendor-wrappers/react-highlight-words";
import { ReactSelect } from "common/vendor-wrappers/react-select";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import {
  FormatMeta,
  LabelledOption,
  LabelledOptionOrGroup,
} from "common/vendor-wrappers/react-select/types";
import {
  findNestedOption,
  mapValuesToOptions,
  stringifyOption,
} from "common/widgets/selector/functions";
import { SelectorOption } from "common/widgets/selector/types";
import { ValueComponent, ValueProps } from "common/with-value-for";

import "./_index.scss";

interface StateType {
  menuIsOpen: boolean;
}

interface PropTypes<T> {
  options: SelectorOption<T>[];
  getOptionLabel?: (option: SelectorOption<T>) => string;
  getOptionValue?: (option: LabelledOption<T>) => string;
  isOptionSelected?: (
    option: LabelledOptionOrGroup<T>,
    selectValue: LabelledOptionOrGroup<T>[],
  ) => boolean;
  formatOption?: (
    data: LabelledOptionOrGroup<T>,
    meta: FormatMeta<T>,
  ) => ReactNode;
  formatSelection?: (data: LabelledOption<T>) => ReactNode;
  sortOptions?: (
    options: LabelledOptionOrGroup<T>[],
  ) => LabelledOptionOrGroup<T>[];
  optionsLimit?: number;
  disableSorting?: boolean;
  allowClear?: boolean;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  menuIsOpen?: boolean;
  label?: string;
  isLoading?: boolean;
}

export class Selector<T = string> extends ValueComponent<
  T,
  PropTypes<T>,
  StateType
> {
  static readonly displayName = "Selector";
  state: StateType = {
    menuIsOpen: undefined,
  };

  componentDidUpdate(prevProps: Readonly<PropTypes<T> & ValueProps<T>>) {
    const { menuIsOpen } = this.props;

    if (prevProps.menuIsOpen !== menuIsOpen) {
      this.setState({ menuIsOpen: menuIsOpen || undefined });
    }
  }

  openMenu = () => {
    this.setState({ menuIsOpen: true });
  };

  closeMenu = () => {
    this.setState({ menuIsOpen: undefined });
  };

  onChange = (option: LabelledOptionOrGroup<T>) => {
    const { value } = this.props;
    if (!isGroupedOption(option) && option?.value !== value) {
      this.setValue(option?.value);
    }
  };

  render() {
    const {
      options = [],
      disableSorting,
      getOptionLabel,
      sortOptions,
      disabled,
      allowClear,
      placeholder,
      formatOption,
      formatSelection,
      className,
      label,
      isLoading,
      isOptionSelected,
      getOptionValue,
      optionsLimit,
      value,
    } = this.props;
    const { menuIsOpen } = this.state;

    const getLabelFn = getOptionLabel || stringifyOption;

    const sortFn = disableSorting
      ? R.identity
      : sortOptions || genericSortByField<LabelledOptionOrGroup<T>>("label");

    const hasNoOptions = !options || options.length === 0;
    const hasSingleOption =
      options && options.length === 1 && !isGroupedOption(options[0]);
    const hasBlockedSingleOption =
      hasSingleOption && !R.isNil(value) && !allowClear;

    const formatOptionLabel = (
      option: LabelledOptionOrGroup<T>,
      meta: FormatMeta<T>,
    ) =>
      meta.context === "value" && formatSelection ? (
        formatSelection(option as LabelledOption<T>)
      ) : formatOption ? (
        formatOption(option, meta)
      ) : (
        <ReactHighlightWords text={option.label} search={meta?.inputValue} />
      );

    const data = mapValuesToOptions(options, getLabelFn, sortFn);
    const valueFromOptions = findNestedOption(value, data, getOptionValue);
    const currentValue =
      valueFromOptions ??
      (value ? mapValuesToOptions([value], getLabelFn)?.[0] : undefined);

    return (
      <ReactSelect<T>
        className={className}
        label={label}
        placeholder={placeholder}
        options={data}
        isDisabled={disabled || hasNoOptions || hasBlockedSingleOption}
        isClearable={allowClear}
        isOptionSelected={isOptionSelected}
        getOptionValue={getOptionValue}
        formatOptionLabel={formatOptionLabel}
        menuIsOpen={menuIsOpen}
        onMenuClose={this.closeMenu}
        isLoading={isLoading}
        optionsLimit={optionsLimit}
        value={currentValue ?? null}
        onChange={this.onChange}
      />
    );
  }
}
