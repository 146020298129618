import { ChangeEvent } from "react";
import { ValueComponent } from "common/with-value-for";

interface PropTypes {
  disabled?: boolean;
  className?: string;
  inputId?: string;
}

export class Checkbox extends ValueComponent<boolean, PropTypes> {
  static readonly displayName = "Checkbox";

  onChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setValue(!!e.target.checked);
  };

  render() {
    const { disabled, className, value, inputId } = this.props;
    return (
      <input
        type="checkbox"
        checked={!!value}
        disabled={disabled}
        className={className}
        id={inputId}
        onChange={this.onChange}
      />
    );
  }
}
