import { defaultFor } from "common";
import { Output } from "common/data/types";
import { canAccessQueryEntities } from "common/query/common";
import { Children } from "common/types/children";
import { Site } from "common/types/sites";
import { PermissionsError } from "common/widgets/error";
import { PropTypes } from "x/dashboard/widgets/types";
import { DataReport } from "./dataReport";
import { isTableConfig } from "./functions";

const getBoxWidget = (children: Children) => (
  <div className="x-dashboard-widgets-column x-dashboard-box-widget">
    {children}
  </div>
);

const getWidget = (children: Children, widgetLabel: string) => (
  <div className="x-dashboard-widgets-column x-dashboard-widget">
    <header className="x-padding-10">
      <h2>{widgetLabel || ""}</h2>
    </header>
    <div className="x-dashboard-widget-content">{children}</div>
    <div className="clear" />
  </div>
);

export const Report = ({
  context,
  widgetLabel,
  output,
  query,
  notOlderThan,
  reload,
  goTo,
}: PropTypes) => {
  const { config, type } = output || defaultFor<Output>();
  const { sites = [], site, entities } = context;

  const content =
    isTableConfig(config) && config?.mergeSites ? (
      <DataReport
        context={context}
        widgetLabel={widgetLabel}
        output={output}
        query={query}
        notOlderThan={notOlderThan}
        reload={reload}
        goTo={goTo}
        site={site}
        key={"report"}
      />
    ) : (
      sites.length &&
      sites.map((site: Site, i: number) => (
        <DataReport
          context={context}
          widgetLabel={widgetLabel}
          output={output}
          query={query}
          notOlderThan={notOlderThan}
          reload={reload}
          goTo={goTo}
          site={site}
          key={i}
        />
      ))
    );

  if (!canAccessQueryEntities(entities, query)) {
    return <PermissionsError />;
  }

  return type === "Box"
    ? getBoxWidget(content)
    : getWidget(content, widgetLabel);
};

Report.displayName = "Report";
