import { Entities, Entity } from "common/entities/types";
import { getSubFKTitle } from "common/functions/foreign-key";
import { mergeChain } from "common/merge";
import { ScheduledEventValue } from "common/record/types";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { ValueComponent } from "common/with-value-for";
import { Form } from "./form";

interface PropTypes {
  context: Context;
  entities: Entities;
  entity: Entity;
}

export class TaskForm extends ValueComponent<ScheduledEventValue, PropTypes> {
  static readonly displayName = "TaskForm";

  onChange = (taskId: ForeignKey) => {
    const { value } = this.props;
    const { record } = value;

    const newRecord = mergeChain(record)
      .prop("properties")
      .set("taskId", taskId)
      .set("description", getSubFKTitle(taskId?.title))
      .output();

    this.mergeValue({ record: newRecord });
  };

  render() {
    const { context, entities, entity, value } = this.props;
    return (
      <Form
        context={context}
        entity={entity}
        entities={entities}
        value={value?.record?.properties?.taskId}
        onChange={this.onChange}
      />
    );
  }
}
