import { HorizontalField } from "common/form/ui";
import { defaultCron } from "common/types/scheduled-event";
import { Cron } from "common/widgets/cron";
import { createList } from "common/widgets/list";
import { Required } from "common/widgets/required";
import { OnChange, ValueComponent } from "common/with-value-for";

const List = createList<string>();

export class InspectionSchedule extends ValueComponent<string[]> {
  static readonly displayName = "PmSchedule";

  onAdd = () => {
    const { value = [] } = this.props;
    this.setValue(value.concat(defaultCron));
  };

  deleteTest = () => {
    const { value = [] } = this.props;
    return value.length > 1;
  };

  onDisplay = (value: string, onChange: OnChange<string>) => {
    return (
      <div className="x-pm-triggers-column">
        <Required value={value}>
          <Cron onChange={onChange} value={value} />
        </Required>
      </div>
    );
  };

  render() {
    const { value } = this.props;

    return (
      <div className="x-pm-trigger-schedules x-pm-section qa-pm-section">
        <HorizontalField label={_("Schedules")}>
          <div className="x-pm-trigger-control-button">
            <button className="btn btn-info" onClick={this.onAdd}>
              {_("Add Schedule")}
            </button>
          </div>
          <div className="x-pm-triggers-group x-margin-top-10">
            {value && value.length ? (
              <List
                onDisplay={this.onDisplay}
                canDelete={this.deleteTest}
                withBorder={true}
                value={value}
                onChange={this.onChangeSetValue}
              />
            ) : (
              <p className="x-pm-triggers-group-empty">
                {`${_("Please add at least one schedule date")}.`}
              </p>
            )}
          </div>
        </HorizontalField>
      </div>
    );
  }
}
