import { toKebabCase } from "common";
import { getLabelWithHighlight, Highlight } from "common/widgets/highlight";
import { SearchRecord } from "../types";

interface PropTypes {
  record: SearchRecord;
  textToHighlight: string;
  onClickRecord: () => void;
  url: string;
  iconClass: string;
  entityLabel: string;
}

export const SearchListItem = ({
  record,
  textToHighlight,
  onClickRecord,
  url,
  iconClass,
  entityLabel,
}: PropTypes) => {
  const { title } = record;
  const onClick = () => onClickRecord();
  return (
    <a
      className={`x-search-item qa-item-${toKebabCase(title)}`}
      href={url}
      onClick={onClick}
    >
      <i className={`fa fa-lg fa-fw ${iconClass} qa-item-icon`} />
      <div className="x-search-item-title qa-item-title">
        {textToHighlight ? (
          <Highlight
            text={getLabelWithHighlight(String(title), textToHighlight)}
          />
        ) : (
          title
        )}
      </div>
      <div className="x-search-item-entity-label qa-item-entity-label">
        {entityLabel}
      </div>
    </a>
  );
};
