import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { Group as FormGroup } from "common/form/types";
import { ReadOnlySectionGroup } from "./section-group";

interface PropTypes {
  entityName: string;
  withLinks: boolean;
  record: Properties;
  groups: FormGroup[];
  style: string;
  context: Context;
}

export const GroupColumn = ({
  entityName,
  withLinks,
  record,
  groups,
  style,
  context,
}: PropTypes) => (
  <div className={`x-groups ${style}`}>
    {groups.map((group) => (
      <ReadOnlySectionGroup
        key={group.name}
        entityName={entityName}
        withLinks={withLinks}
        record={record}
        group={group}
        context={context}
      />
    ))}
  </div>
);
GroupColumn.displayName = "GroupColumn";
