import { displayEntity } from "common/api/entities";
import {
  getDurationAsDays,
  getDurationAsHours,
  getDurationAsMinutes,
} from "common/date-time/calculators";
import { getFormattedDurationAmount } from "common/date-time/common";
import {
  DurationObject,
  ShortUnitOfTime,
  TimeSpan,
} from "common/date-time/types";
import { getEntityByBehavior, getUrl } from "common/entities";
import { replacePlaceholders } from "common/functions/replace-placeholders";
import { Context } from "common/types/context";
import {
  CookiesPolicy,
  defaultAuditTrailSettings,
  defaultLoginAccountSettings,
  defaultPasswordPolicy,
  defaultPrivacyPolicy,
  defaultSsoPolicy,
  PasswordPolicy,
  SecuritySettings,
  SecuritySettingsPayload,
  SessionTimeout,
  SsoPolicy,
} from "common/types/settings";
import { Link } from "common/widgets/link";

export const MAX_PASSWORD_LENGTH = 128;

export const MAX_PASSWORD_HISTORY_LENGTH = 30;

export const TEN_YEARS_IN_DAYS = 3650;

export const TEN_YEARS_IN_HOURS = 87600;

export const TEN_YEARS_IN_MINUTES = 5256000;

export const wrap = (
  _: SecuritySettings,
  v: SecuritySettings,
): SecuritySettings => v;

export const getMinLengthLowerLimit = (
  mustContainOther?: boolean,
  mustContainNumber?: boolean,
  mustContainUpper?: boolean,
) =>
  [mustContainNumber, mustContainOther, mustContainUpper].filter(
    (a) => a === true,
  ).length || 1;

export const isCookiesPolicyValid = (cookiesPolicy: CookiesPolicy) => {
  const { unit, value } = cookiesPolicy?.sessionTimeout || {};
  return (
    value > 0 &&
    unit !== undefined &&
    ((unit === "days" && value <= TEN_YEARS_IN_DAYS) ||
      (unit === "hours" && value <= TEN_YEARS_IN_HOURS) ||
      (unit === "minutes" && value <= TEN_YEARS_IN_MINUTES))
  );
};

export const isMinLengthValid = (
  passwordPolicy: PasswordPolicy,
  minValueMinLength: number,
) => {
  const { maxLength, minLength } = passwordPolicy || {};
  return (
    minLength === undefined ||
    (minLength > 0 &&
      minLength >= minValueMinLength &&
      minLength <= MAX_PASSWORD_LENGTH &&
      (!maxLength || minLength <= maxLength))
  );
};

export const isMaxLengthValid = (
  passwordPolicy: PasswordPolicy,
  minValueMinLength: number,
) => {
  const { maxLength, minLength } = passwordPolicy || {};
  return (
    maxLength === undefined ||
    (maxLength > 0 &&
      maxLength <= MAX_PASSWORD_LENGTH &&
      maxLength >= minValueMinLength &&
      (!minLength || maxLength >= minLength))
  );
};

export const isPasswordHistoryLengthValid = (passwordHistoryLength?: number) =>
  passwordHistoryLength === undefined ||
  (passwordHistoryLength > 0 &&
    passwordHistoryLength <= MAX_PASSWORD_HISTORY_LENGTH);

export const isExpirationDaysValid = (expirationDays?: number) =>
  expirationDays === undefined ||
  (expirationDays > 0 && expirationDays <= TEN_YEARS_IN_DAYS);

export const isPasswordPolicyValid = (passwordPolicy: PasswordPolicy) => {
  const minValueMinLength = getMinLengthLowerLimit(
    passwordPolicy?.mustContainOther,
    passwordPolicy?.mustContainNumber,
    passwordPolicy?.mustContainUpper,
  );
  return (
    isMinLengthValid(passwordPolicy, minValueMinLength) &&
    isMaxLengthValid(passwordPolicy, minValueMinLength) &&
    isPasswordHistoryLengthValid(passwordPolicy?.passwordHistoryLength) &&
    isExpirationDaysValid(passwordPolicy?.expirationDays)
  );
};

export const isSsoPolicyValid = (context: Context, ssoPolicy: SsoPolicy) => {
  if (!context?.tenant?.sso) return true;

  const { defaultUserType, defaultRoleId } = ssoPolicy;
  return !!(defaultUserType && defaultRoleId);
};

export const isValid = (context: Context) => (value: SecuritySettings) => {
  return (
    isPasswordPolicyValid(value?.passwordPolicy) &&
    isCookiesPolicyValid(value?.cookiesPolicy) &&
    isSsoPolicyValid(context, value?.ssoPolicy)
  );
};

export const getUnit = (duration: DurationObject): ShortUnitOfTime => {
  const { days, hours, minutes } = duration;
  return minutes ? "minutes" : hours ? "hours" : days ? "days" : "minutes";
};

export const getDurationObject = (timeSpan: TimeSpan): DurationObject => {
  const timeMatches = timeSpan?.match(/(?:(\d+).)?(\d+):(\d+):(\d+)/);
  return {
    days: +timeMatches?.[1] || 0,
    hours: +timeMatches?.[2] || 0,
    minutes: +timeMatches?.[3] || 0,
  };
};

export const timeSpanToSessionTimeout = (
  timeSpan: TimeSpan,
): SessionTimeout => {
  const duration = getDurationObject(timeSpan);
  const unit = getUnit(duration);
  const value =
    unit === "days"
      ? getDurationAsDays(duration)
      : unit === "hours"
        ? getDurationAsHours(duration)
        : getDurationAsMinutes(duration);

  return { value, unit };
};

const defaultTimeSpan: TimeSpan = "00.01:00:00";

export const payloadToSettings = (
  s: SecuritySettingsPayload,
): SecuritySettings => {
  const passwordPolicy = s?.passwordPolicy || defaultPasswordPolicy;
  const timeSpan = s?.cookiesPolicy?.sessionTimeout || defaultTimeSpan;
  const ssoPolicy = s?.ssoPolicy || defaultSsoPolicy;
  const privacyPolicy = s?.privacyPolicy || defaultPrivacyPolicy;
  const loginAccountSettings =
    s?.loginAccountSettings || defaultLoginAccountSettings;
  const auditTrailSettings = s?.auditTrailSettings || defaultAuditTrailSettings;

  return {
    passwordPolicy,
    cookiesPolicy: {
      sessionTimeout: timeSpanToSessionTimeout(timeSpan),
    },
    ssoPolicy,
    privacyPolicy,
    loginAccountSettings,
    auditTrailSettings,
  };
};

export const settingsToPayload = (
  s: SecuritySettings,
): SecuritySettingsPayload => {
  const value = s?.cookiesPolicy?.sessionTimeout?.value;
  const unit = s?.cookiesPolicy?.sessionTimeout?.unit;
  return {
    passwordPolicy: s?.passwordPolicy,
    cookiesPolicy: {
      sessionTimeout: getFormattedDurationAmount(value, unit),
    },
    ssoPolicy: s?.ssoPolicy,
    privacyPolicy: s?.privacyPolicy,
    loginAccountSettings: s?.loginAccountSettings,
    auditTrailSettings: s?.auditTrailSettings,
  };
};

export const getUnitsTranslation = (units: ShortUnitOfTime) => {
  switch (units) {
    case "days":
      return _("Days");
    case "hours":
      return _("Hours");
    default:
      return _("Minutes");
  }
};

export const getDefaultSiteHintMessage = (context: Context) => {
  const domainRulesEntity = getEntityByBehavior(
    "EmailDomainRules",
    context.entities,
  );

  const message = _(
    "Default Site can be overwritten by more complex email domain rules defined in {ENTITY}",
  );

  return replacePlaceholders(message, {
    "{ENTITY}": () => (
      <Link href={getUrl(domainRulesEntity, context.site.name)}>
        {displayEntity(domainRulesEntity)}
      </Link>
    ),
  });
};
