import * as R from "ramda";
import { Geolocation } from "common/types/geolocation";
import { HighlightProps, Label } from "../label";

interface PropTypes extends HighlightProps {
  value: string;
}

export const GeolocationLabel = ({ value, textToHighlight }: PropTypes) => {
  const geoData: Geolocation = !value
    ? {}
    : R.is(Object, value)
      ? value
      : JSON.parse(value);
  return <Label value={geoData?.address} textToHighlight={textToHighlight} />;
};

GeolocationLabel.displayName = "GeolocationLabel";
