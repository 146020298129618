import { ApiCall } from "common/types/api";
import { recordsApi } from "common/api/records";
import { behaveAs } from "common/entities";
import { hasProtectedColumns } from "common/record/utils";
import { getLocalizedName } from "common/index";
import { Action } from "common/record/actions/action";
import { DismissAction, PropTypes } from "common/record/actions/types";
import { AlertInfo } from "common/widgets/alert";

export const Open = (props: PropTypes) => {
  const { records = [], entity } = props;

  const openRecord = (apiCall: ApiCall) =>
    recordsApi(apiCall).open(entity.name, records[0].properties.id);

  const openRecordList = (apiCall: ApiCall) =>
    recordsApi(apiCall).bulkOpen(
      entity.name,
      records.map((record) => record.properties.id),
    );

  const onOpen = (apiCall: ApiCall, dismiss: DismissAction) => {
    const promise =
      records.length > 1 ? openRecordList(apiCall) : openRecord(apiCall);

    return promise.then(() => dismiss(true));
  };

  const getContent = () => {
    const woEntityName = getLocalizedName(entity);

    return (
      <div className="x-open-wo-content">
        {records.length > 1
          ? _(
              "Clicking 'Open' will open these {WORK_ORDER_ENTITY} records, update the 'Last Open Date' and revise the Preventive Maintenance schedule.",
            ).replace("{WORK_ORDER_ENTITY}", woEntityName)
          : _(
              "Clicking 'Open' will open this {WORK_ORDER_ENTITY} record, update the 'Last Open Date' and revise the Preventive Maintenance schedule.",
            ).replace("{WORK_ORDER_ENTITY}", woEntityName)}
        <div className="x-padding-top-10">
          <AlertInfo
            message={_(
              "What happens after {WORK_ORDER_ENTITY} record is opened?",
            ).replace("{WORK_ORDER_ENTITY}", woEntityName)}
          >
            <div>
              <div>
                <b>
                  {_("Fixed frequency PM {WORK_ORDER_ENTITY}").replace(
                    "{WORK_ORDER_ENTITY}",
                    woEntityName,
                  )}
                </b>
                <p>
                  {_(
                    "The 'Last Open Date' changes to {WORK_ORDER_ENTITY} record's scheduled date, and all the future {WORK_ORDER_ENTITY} records scheduled before this record will be deleted.",
                  ).replace(/{WORK_ORDER_ENTITY}/g, woEntityName)}
                </p>
              </div>
              <div>
                <b>
                  {_(
                    "After previous work order is closed PM {WORK_ORDER_ENTITY}",
                  ).replace("{WORK_ORDER_ENTITY}", woEntityName)}
                </b>
                <p>
                  {_("The 'Last Open Date' will be updated to today's date.")}
                </p>
              </div>
              <div>
                <b>{_("If Work Order was rescheduled")}</b>
                <p>
                  {_(
                    "Generates new future {WORK_ORDER_ENTITY} records based on the updated timeline.",
                  ).replace("{WORK_ORDER_ENTITY}", woEntityName)}
                </p>
              </div>
            </div>
          </AlertInfo>
        </div>
      </div>
    );
  };

  return (
    <Action
      {...props}
      requiresAuthentication={hasProtectedColumns(entity)}
      size={"large"}
      title={_("Open")}
      btnLabel={records.length > 1 ? _("Open records") : _("Open")}
      onOk={onOpen}
    >
      {behaveAs("WorkOrder", entity) ? getContent() : undefined}
    </Action>
  );
};
