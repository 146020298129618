import { Context } from "common/types/context";
import { MenuEntrySetting, Preferences } from "common/types/preferences";
import { ValueProps } from "common/with-value-for";
import { NavigationConfigController } from "x/layout/navigation/config";

interface PropTypes extends ValueProps<Preferences> {
  context: Context;
}

export const PreferencesForm = ({ context, value, onChange }: PropTypes) => {
  const onChangeMenu = (menu: MenuEntrySetting[]) => {
    onChange({ ...value, menu });
  };

  /*
  const onChangeTheme = (theme: string) => {
     onChange({ ...value, theme });
  };
   */

  return (
    <div className="x-user-profile-preferences form-horizontal x-section">
      <div className="x-user-profile-preferences-menu">
        <fieldset className="x-section-group">
          <legend className="x-legend">{_("Left menu items")}</legend>
          <NavigationConfigController
            context={context}
            value={value?.menu}
            onChange={onChangeMenu}
          />
        </fieldset>
      </div>

      {/* 
      Commented out until the dark theme is properly implemented
      We need to handle 3rd party libraries properly in the dark theme
          
      After uncommenting this please uncomment also applyTheme function 
      in common/utils/themes.ts
          
      <div className="x-user-profile-preferences-themes">
        <legend className="x-legend">{_("Themes")}</legend>
        <Themes value={value?.theme} onChange={onChangeTheme} />
      </div>
      */}
    </div>
  );
};
PreferencesForm.displayName = "PreferencesForm";
