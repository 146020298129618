import { Entity } from "common/entities/types";
import { BehaviorName } from "common/types/behaviors";

export const systemColumnsToOmit = [
  "site",
  "id",
  "number",
  "isDeleted",
  "createdOn",
  "updatedOn",
  "pmId",
  "requestId",
  "updatedSyncDate",
];

const hiddenSystemColumnsToOmit = ["id", "isDeleted", "number"];

const relatedFromOverviewToOmit: BehaviorName[] = [
  // by behaviors
  "AssetMeter",
];

interface BehaviorColumn {
  [index: string]: string[];
}

const behaviorColumns: BehaviorColumn = {
  Attachment: ["image"],
  GeoLocation: ["geolocation"],
  Signature: ["signature"],
};

export const omitFromOverview = ({ behaviors }: Entity): boolean =>
  !!behaviors.find((b) => relatedFromOverviewToOmit.includes(b.name));

export const getRestrictedBehaviorColumns = (entity: Entity): string[] =>
  (entity?.behaviors || []).flatMap((b) => behaviorColumns[b.name] || []);

export const isRestrictedColumn = (entity: Entity, column: string): boolean =>
  systemColumnsToOmit
    .concat(getRestrictedBehaviorColumns(entity))
    .includes(column);

export const isRestrictedBehaviorColumn = (
  entity: Entity,
  column: string,
): boolean => getRestrictedBehaviorColumns(entity).includes(column);

export const notHiddenSystemColumn = (entity: Entity, name: string) =>
  name !== entity.ownerFkColumn &&
  hiddenSystemColumnsToOmit.indexOf(name) === -1;
