import { Component } from "react";
import * as R from "ramda";
import { RecordSitesProps } from "common/form/types";
import { searchApi } from "common/api/search";
import { Entity } from "common/entities/types";
import { getFkId } from "common/functions/foreign-key";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { Properties, Record } from "common/types/records";
import { ForeignKeyPlusSelector } from "common/widgets/selector/foreign-key-plus-selector";
import { Hint } from "common/widgets/hint";
import { ValueProps } from "common/with-value-for";
import { getQuery } from "./procedure-type/functions";

interface PropTypes extends ValueProps<ForeignKey>, RecordSitesProps {
  context: Context;
  entity: Entity;
  workOrderAssetEntity: Entity;
  assets: ForeignKey[];
  isNew: boolean;
  onRelatedRecordAdd: (properties: Properties) => void;
  placeholder?: string;
  isTemplate?: boolean;
}

interface StateType {
  currentOptions: ForeignKey[];
}

export class MultipleWorkOrderAssetsDropdown extends Component<
  PropTypes,
  StateType
> {
  static readonly displayName = "MultipleWorkOrderAssetsDropdown";

  state: StateType = {
    currentOptions: this.props.assets,
  };

  componentDidMount() {
    const { context, entity, isTemplate, assets } = this.props;

    if (!isTemplate || !assets.length) return;

    const woEntity = context.entities[entity.arguments?.ownerEntity];
    const assetEntity = context.entities[woEntity.arguments.assetEntity];

    searchApi(context.apiCall)
      .runQueryFkExpansion(getQuery(assetEntity, assets, "in"))
      .then((records: ForeignKey[]) => {
        this.setState({ currentOptions: records });
      });
  }

  componentDidUpdate(prevProps: PropTypes) {
    if (!R.equals(prevProps.assets, this.props.assets)) {
      this.setState({
        currentOptions: this.props.assets,
      });
    }
  }

  onRecordSelectorChange = (records: ForeignKey[]) => {
    const { entity, onRelatedRecordAdd, onChange } = this.props;

    const newWorkOrderAsset: Record = {
      properties: {
        assetId: records[0],
        status: "P",
      },
      actions: entity.commands,
    };

    onRelatedRecordAdd(newWorkOrderAsset);
    onChange(records[0]);

    if (!records[0]) return;
    this.setState((state) => ({
      currentOptions: [...state.currentOptions, records[0]],
    }));
  };

  render() {
    const {
      context,
      entity,
      workOrderAssetEntity,
      placeholder,
      isNew,
      assets,
      recordSites,
      value,
      onChange,
    } = this.props;

    const { currentOptions } = this.state;

    const entities = context.entities;
    const woEntity = entities[entity.arguments?.ownerEntity];
    const assetEntity = entities[woEntity.arguments.assetEntity];

    const assetId = getFkId(value);
    const containsAssetId = assets.some((a) => getFkId(a) === assetId);
    const newValue = isNew && !containsAssetId ? undefined : value;
    const showAssetRemovedHint = !isNew && !containsAssetId && newValue;

    const options =
      isNew || containsAssetId
        ? currentOptions
        : currentOptions.concat([value]).filter((a) => a);

    return (
      <>
        <ForeignKeyPlusSelector
          context={context}
          entity={entity}
          relatedEntity={assetEntity}
          options={options}
          placeholder={placeholder}
          disabled={!options.length || !workOrderAssetEntity}
          withLinks={true}
          allowClear={true}
          recordSites={recordSites}
          value={newValue}
          onChange={onChange}
          onRecordSelectorChange={this.onRecordSelectorChange}
          query={getQuery(assetEntity, options, "notin")}
        />
        {showAssetRemovedHint ? (
          <Hint
            key="assetRemovedInfo"
            message={_(
              "The asset used in this procedure was removed from the work order record",
            )}
          />
        ) : undefined}
      </>
    );
  }
}
