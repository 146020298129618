import { Query } from "common/query/types";
import { CancellablePromise } from "common/types/promises";
import { Properties } from "common/types/records";
import { SystemIntFk } from "common/types/system-int";
import { DataType } from "./data-type/types";

export const enum ColumnTypes {
  System = 1,
  Custom,
  Standard,
  Recommended,
}

export const enum BooleanTypes {
  TrueFalse,
  YesNo,
}

export interface BooleanOptions {
  trueTitle?: string;
  falseTitle?: string;
  twoCheckboxes?: boolean;
}

export interface EntityColumn {
  name: string;
  dataType: DataType;
  columnType: ColumnTypes;
  localizedName?: string;
  relatedEntity?: string;
  relatedColumn?: string;
  relatedSystem?: string;
  relatedIsOneToOne?: boolean;
  isSystem?: boolean;
  isForeignKey?: boolean;
  isSerialized?: boolean;
  readOnly?: boolean;
  required?: boolean;
  unique?: boolean;
  maxLength?: number;
  minLength?: number;
  maxValue?: number;
  minValue?: number;
  mask?: string;
  decimalScale?: number;
  canAutoPopulationOverwrite?: boolean;
  requireExplicitAuthentication?: boolean;
  availableForList?: boolean; // read 'showByDefaultInList'
  renderWithTotals?: boolean;
  userReadOnly?: boolean;
  displayValue?: (value: any) => string | number;
  quickInput?: boolean;
  quickInputOptions?: BooleanOptions;
  isDisabled?: (row: Properties, item: SystemIntFk) => boolean;
  getDependencies?: (row: Properties) => Properties;
  approveChange?: () => CancellablePromise<void>;
  query?: Query;
  hint?: string;
  disableQuickAddRelated?: boolean;
  isVirtual?: boolean;
  roleIds?: number[];
  booleanType?: BooleanTypes;
}

export const CALENDAR_ID = "calendarId";
export const SYSTEM_FIELDS = [
  "id",
  "number",
  "createdOn",
  "updatedOn",
  "isDeleted",
  "site",
  "updatedSyncDate",
  "createdBy",
  "updatedBy",
  "dataSource",
];
