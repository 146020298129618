import * as R from "ramda";
import { Group, GroupColumn } from "common/form/types";

const getNameIndex = (x: { name: string }): number =>
  parseInt(R.last(x.name.split(".")));

const getNextIndex = (xs: Array<{ name: string }>): number =>
  xs && xs.length
    ? xs.map(getNameIndex).reduce((a, b) => (a > b ? a : b), 0) + 1
    : 0;

export const getNewGroupName = (groups: Group[]): string =>
  `group.${getNextIndex(groups)}`;

export const mapColumnsFromGroups = (
  mapFn: (col: GroupColumn) => GroupColumn,
  groups: Group[] = [],
): Group[] =>
  groups.map((group) =>
    R.mergeRight(group, {
      columns: (group.columns || []).map(mapFn).filter((c) => !!c),
    }),
  );

export const createGroup = (name: string): Group => ({
  name,
  label: _("new group"),
  position: "left",
  columns: [],
});

export const createGroups = (): Group[] => [createGroup(getNewGroupName([]))];
