import * as R from "ramda";
import { eventsApi } from "common/api/events";
import { Component } from "common/component";
import { merge1, merge2 } from "common/merge";
import { ApiCall } from "common/types/api";
import { Context } from "common/types/context";
import { Event } from "common/types/events";
import { Unsubscribe } from "common/types/preferences";
import { CancellablePromise } from "common/types/promises";
import { StarredItems } from "common/types/starred";
import { LoadingIcon } from "common/widgets/loading-icon";
import { WallItem } from "./wall-item";

interface PropTypes {
  context: Context;
}

interface CachedEvents extends StarredItems {
  events: Event[];
}

interface StateType {
  cache: CachedEvents[];
  loading: boolean;
}

const findInList = <T extends StarredItems>(list: T[], s: StarredItems) =>
  R.find(
    (i) => i.site === s.site && i.entity === s.entity && i.id === s.id,
    list,
  );
const isInList =
  <T extends StarredItems>(list: T[]) =>
  (s: StarredItems) =>
    !!findInList(list, s);
const notInList =
  <T extends StarredItems>(list: T[]) =>
  (s: StarredItems) =>
    !findInList(list, s);

const getEventsFromCache = (cache: CachedEvents[]): Event[] =>
  R.reverse(
    R.sortBy(
      (e) => e.details.occurredOn,
      R.flatten(cache.map((s) => s.events)),
    ),
  );

const getEvents = (
  apiCall: ApiCall,
  added: StarredItems[],
): CancellablePromise<CachedEvents[]> => {
  const missing: Array<PromiseLike<CachedEvents>> = added.map((s) =>
    eventsApi(apiCall)
      .getForSite(s.site, s.entity, s.id)
      .then((events) =>
        R.mergeRight(s, {
          events: events.map((event) =>
            merge2("details", "site", s.site, event),
          ),
        }),
      ),
  );
  return CancellablePromise.all<CachedEvents>(missing as any);
};

export class WallController extends Component<PropTypes, StateType> {
  static readonly displayName = "WallController";
  unsubscribe: Unsubscribe = undefined;
  state: StateType = {
    cache: [],
    loading: false,
  };

  componentDidMount() {
    const { context } = this.props;
    const unsubsStarred = context.starred.subscribe(this.loadEvents);
    const unsubsEvent = context.pushNotifications.onEvent(this.addToCache);
    this.unsubscribe = () => {
      unsubsStarred();
      unsubsEvent();
    };
    this.loadEvents();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
    this.unsubscribe = undefined;
  }

  addToCache = (newEvent: Event) => {
    const { cache } = this.state;
    const { context } = this.props;
    const event = merge2("details", "site", context.site.name, newEvent);

    const { site, entityName, recordId } = event.details;
    const item = { site, entity: entityName, id: recordId, events: [event] };
    const itemCache = findInList(cache, item);

    const newCache = itemCache
      ? cache.map((c) =>
          c === itemCache
            ? merge1("events", itemCache.events.concat([event]), itemCache)
            : c,
        )
      : cache.concat([item]);

    this.setState({ cache: newCache });
  };

  loadEvents = () => {
    const { context } = this.props;
    const { cache } = this.state;
    const starred = context.starred.get();
    const added = starred.filter(notInList(cache));
    const removed = cache.filter(notInList(starred));

    added.forEach((r) =>
      context.pushNotifications.subscribe(r.site, r.entity, r.id),
    );
    removed.forEach((r) =>
      context.pushNotifications.unsubscribe(r.site, r.entity, r.id),
    );

    this.setState({ loading: true });
    getEvents(context.apiCall, added).then((eventLists: CachedEvents[]) =>
      this.setState({
        cache: cache.concat(eventLists).filter(isInList(starred)),
        loading: false,
      }),
    );
  };

  render() {
    const { context } = this.props;
    const { cache, loading } = this.state;
    const events = getEventsFromCache(cache);
    return (
      <div className="x-wall qa-wall">
        {loading ? (
          <LoadingIcon />
        ) : events.length ? (
          events.map((event: Event, index: number) => (
            <WallItem
              key={index}
              event={event}
              entities={context.entities}
              sites={context.sites}
              uiFormat={context.uiFormat}
            />
          ))
        ) : (
          <div className="x-padding-10 qa-wall-no-events">{_("No Events")}</div>
        )}
      </div>
    );
  }
}
