interface Props {
  itemsName: string;
}

export const NoResults = ({ itemsName }: Props) => (
  <div className="x-list-grid-no-results">
    <h2>
      {_("No matching {ITEMS_NAME} were found for the search filter").replace(
        "{ITEMS_NAME}",
        itemsName,
      )}
    </h2>
  </div>
);
