import * as R from "ramda";
import { Culture } from "common/culture/supported-cultures";
import { Calendar } from "common/types/calendars";
import { translateName } from "./functions";

export const translateKey =
  (cal: Calendar) =>
  (key: string): string =>
    key === "name" ? cal.label : key;

export const translate = (culture: Culture, calendar: Calendar): Calendar => {
  if (!calendar) return undefined;

  const captions = calendar.labels ? calendar.labels[culture] : {};

  return R.mergeRight(calendar, {
    label: R.isEmpty(captions || {})
      ? (calendar.id && `${_("Calendar")} ${calendar.id}`) || ""
      : translateName(captions),
  });
};

// --- Extract -------------------------------------------------------------- //
export const getKeys = (calendar: Calendar): string[] =>
  calendar ? ["name"] : [];
