import * as R from "ramda";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import {
  LabelledOption,
  LabelledOptionOrGroup,
} from "common/vendor-wrappers/react-select/types";
import { SelectorOption } from "common/widgets/selector/types";

const simpleStringify = <T>(option: T) => option.toString();

export const mapValuesToOptions = <T>(
  options: SelectorOption<T>[],
  getOptionLabel: (option: SelectorOption<T>) => string = simpleStringify,
  sortFn: (
    option: LabelledOptionOrGroup<T>[],
  ) => LabelledOptionOrGroup<T>[] = R.identity,
): LabelledOptionOrGroup<T>[] =>
  sortFn(
    options.map((option) =>
      isGroupedOption(option)
        ? {
            label: option.label ?? getOptionLabel(option),
            options: sortFn(
              option.options.map((subOption) => ({
                label: getOptionLabel(subOption),
                value: subOption,
              })),
            ) as LabelledOption<T>[],
          }
        : {
            label: getOptionLabel(option),
            value: option,
          },
    ),
  );

export const stringifyOption = <T>(option: T) =>
  typeof option === "string" ? option : option?.toString() ?? "";

const equalsOption = <T>(
  value: SelectorOption<T>,
  option: LabelledOption<T>,
  getOptionValue?: (option: LabelledOptionOrGroup<T>) => string,
) =>
  getOptionValue
    ? getOptionValue(option) ===
      getOptionValue({ label: undefined, value: value as T })
    : R.equals(option.value, value);

export const findNestedOption = <T>(
  value: SelectorOption<T>,
  options: LabelledOptionOrGroup<T>[],
  getOptionValue?: (option: LabelledOptionOrGroup<T>) => string,
): LabelledOption<T> => {
  for (let i = 0; i < options.length; ++i) {
    const option = options[i];

    if (isGroupedOption(option)) {
      const childMatch = (option.options ?? []).find((o) =>
        equalsOption(value, o, getOptionValue),
      );
      if (childMatch) return childMatch;
    } else {
      if (equalsOption(value, option, getOptionValue)) return option;
    }
  }
  return undefined;
};
