import { isType } from "common/index";
import { SystemIntFk, SystemIntFkValue } from "common/types/system-int";

export const isSystemIntFk = isType<SystemIntFkValue, SystemIntFk>([
  "id",
  "title",
]);

export const getIntFkId = (value: SystemIntFkValue) =>
  isSystemIntFk(value) ? value.id : value;
