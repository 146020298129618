import { withValue, WithValue } from "common/with-value";
import { SelectItem } from "common/query/types";
import { BoxConfig } from "common/data/types";
import { FancyCheckboxLarge } from "common/widgets/fancy-checkbox";
import { RequiredField } from "common/form/ui";
import { DropdownSelector } from "./dropdown";

interface PropTypes {
  select: SelectItem[];
  showMergeOption: boolean;
  showLabel: boolean;
}

const OutputConfigBoxComp = ({
  select,
  showMergeOption,
  showLabel,
  value,
  onChangeMergeValue,
}: PropTypes & WithValue<BoxConfig>) => (
  <div>
    <RequiredField label={_("Values")} value={value.value}>
      <DropdownSelector
        select={select}
        value={value.value}
        onChange={onChangeMergeValue("value")}
      />
    </RequiredField>
    {showLabel ? (
      <RequiredField label={_("Label")} value={value.label}>
        <DropdownSelector
          select={select}
          value={value.label}
          onChange={onChangeMergeValue("label")}
        />
      </RequiredField>
    ) : undefined}
    <hr />
    {showMergeOption ? (
      <FancyCheckboxLarge
        label={_("Group sites")}
        onChange={onChangeMergeValue("mergeSites")}
        value={value.mergeSites}
      />
    ) : undefined}
    <FancyCheckboxLarge
      label={_("Show as Currency")}
      onChange={onChangeMergeValue("isCurrency")}
      value={value.isCurrency}
    />
  </div>
);

export const OutputConfigBox = withValue<BoxConfig, PropTypes>(
  OutputConfigBoxComp,
  "OutputConfigBox",
);
