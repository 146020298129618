import * as R from "ramda";
import { genericSortByField } from "common/functions/generics";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import { SelectorOption } from "common/widgets/selector/types";
import {
  GroupedOption,
  LabelledOption,
  LabelledOptionOrGroup,
} from "common/vendor-wrappers/react-select/types";
import { getLocalizedName, isType } from "common";
import { deepEqual } from "common/component";
import { Selector } from "common/widgets/selector";
import { ValueComponent, ValueProps } from "common/with-value-for";
import { fieldsEqual } from "./functions";
import { Field } from "./types";

interface PropTypes {
  className?: string;
  fields: Field[];
  withExpressions?: boolean;
  disabled?: boolean;
  showPath?: boolean;
  allowClear?: boolean;
  label?: string;
}

const trimToLower = R.compose(R.toLower, R.trim);

interface Expression {
  label: string;
  expression: true;
}

type FieldOrExpression = Field | Expression;
type Props = PropTypes & ValueProps<FieldOrExpression>;

const isField = isType<FieldOrExpression, Field>(["minPath", "column"]);
const isExpression = isType<FieldOrExpression, Expression>(["expression"]);

const optionsForFields = (fields: Field[]): GroupedOption<Field>[] =>
  R.groupWith(
    (a, b) => a.minPath === b.minPath,
    R.sortBy((f) => f.minPath, fields),
  ).map((fields) => ({
    label: fields[0].minPath,
    options: R.sortBy((f) => trimToLower(getLocalizedName(f.column)), fields),
  }));

const getOptions = (
  fields: Field[],
  withExpressions: boolean,
): SelectorOption<FieldOrExpression>[] => {
  const fieldOptions = optionsForFields(fields);

  return withExpressions
    ? [...fieldOptions, { label: _("Custom expression"), expression: true }]
    : fieldOptions;
};

const sortOptions = (
  options: LabelledOptionOrGroup<FieldOrExpression>[],
): LabelledOptionOrGroup<FieldOrExpression>[] => {
  const expression = options.find(
    (o) => !isGroupedOption(o) && isExpression(o.value),
  );

  if (!expression) {
    return genericSortByField<LabelledOptionOrGroup<FieldOrExpression>>(
      "label",
    )(options);
  }

  const optionsWithoutExpression = options.filter(
    (o) => isGroupedOption(o) || isField(o.value),
  );
  const sortedOptions = genericSortByField<
    LabelledOptionOrGroup<FieldOrExpression>
  >("label")(optionsWithoutExpression);

  return [...sortedOptions, expression];
};

export class FieldSelector extends ValueComponent<
  FieldOrExpression,
  PropTypes
> {
  static readonly displayName = "FieldSelector";

  shouldComponentUpdate(newProps: Props) {
    const { props } = this;
    return (
      !deepEqual(props, newProps, ["fields"]) ||
      !fieldsEqual(props.fields, newProps.fields)
    );
  }

  onChange = (value: FieldOrExpression) => {
    if (!value && !this.props.allowClear) return;
    this.setValue(value && isExpression(value) ? undefined : value);
  };

  getOptionLabel = (value: SelectorOption<FieldOrExpression>) => {
    return !isGroupedOption(value) && isField(value)
      ? getLocalizedName(value.column)
      : value.label;
  };

  formatSelection = (option: LabelledOption<FieldOrExpression>) => {
    const { value } = option;
    return this.props.showPath && isField(value)
      ? value.minPath + "." + getLocalizedName(value.column)
      : this.getOptionLabel(value);
  };

  render() {
    const {
      className,
      label,
      fields,
      withExpressions,
      value,
      allowClear,
      disabled = false,
    } = this.props;

    return (
      <Selector<FieldOrExpression>
        className={className}
        label={label}
        getOptionLabel={this.getOptionLabel}
        formatSelection={this.formatSelection}
        disabled={disabled}
        allowClear={allowClear}
        placeholder={_("Select a field")}
        options={getOptions(fields, withExpressions)}
        sortOptions={sortOptions}
        onChange={this.onChange}
        value={value}
      />
    );
  }
}
