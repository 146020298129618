import {
  createInjected as ctrlInjected,
  createListController,
  PropTypes as CtrlPropTypes,
} from "common/ui/controllers/list";
import { RibbonWrapper, RibbonWrapperPropTypes } from "./ribbon-wrapper";

export function listCtrlWithRibbon<TValue, TRecord>() {
  const ListController = createListController<TValue, TRecord>();
  interface PropTypes
    extends CtrlPropTypes<TValue, TRecord>,
      RibbonWrapperPropTypes {
    children?: any;
  }

  const ListWithRibbon = (props: PropTypes) => (
    <ListController {...props}>
      <RibbonWrapper<TValue, TRecord>
        {...ctrlInjected<TValue, TRecord>()}
        {...props}
      >
        {props.children}
      </RibbonWrapper>
    </ListController>
  );
  return ListWithRibbon;
}
