import { ReactNode, Component } from "react";
import { Selector } from "common/widgets/selector";
import { ValueProps } from "common/with-value-for";

interface PropTypes {
  options: string[];
  defaultValue: string;
  getOptionLabel?: (value: string) => string;
  child: ReactNode;
}

type Props = PropTypes & ValueProps<string>;

export class WithDefaultSelector extends Component<Props> {
  static readonly displayName = "WithDefaultSelector";

  onChange = (value: string) => {
    const { defaultValue, onChange } = this.props;
    onChange(value === defaultValue ? undefined : value);
  };

  render() {
    const {
      options,
      defaultValue,
      child,
      getOptionLabel,
      value = "",
    } = this.props;
    const currentValue = options.includes(value) ? value : defaultValue;

    return (
      <div className="x-flex">
        {options.length ? (
          <Selector
            getOptionLabel={getOptionLabel}
            className="x-flex-grow-1 qa-with-default-dropdown"
            options={[defaultValue, ...options]}
            value={currentValue}
            onChange={this.onChange}
          />
        ) : undefined}
        {currentValue === defaultValue ? (
          <div
            className={`x-flex-grow-1 ${
              options.length ? "x-margin-left-10" : ""
            }`}
          >
            {child}
          </div>
        ) : undefined}
      </div>
    );
  }
}
