import { Component } from "react";
import { getLocalizedName } from "common";
import { getLocalizedColumnName } from "common/entities/entity-column/functions";
import { Entities, Entity } from "common/entities/types";
import { JoinItem, JoinType } from "common/query/types";
import { DeleteButton } from "common/ui/buttons";
import { Selector } from "common/widgets/selector";
import { Required } from "common/widgets/required";
import { ValueProps } from "common/with-value-for";
import {
  allJoinTypes,
  defaultJoinType,
  displayLocalizedJoinType,
} from "./functions";
import { JoinSelector } from "./selector";

interface PropTypes extends ValueProps<JoinItem> {
  entities: Entities;
  baseEntityName: string;
  joinEntityName: string;
  validJoinTypes?: JoinType[];
  oneToOneOnly?: boolean;
  isGroupHeader?: boolean;
  onRemoveGroup: () => void;
}

export const getLabel = (
  entities: Entities,
  baseEntity: Entity,
  item: JoinItem,
) => {
  if (item.entity) {
    const entity = entities[item.entity];
    const columnName = getLocalizedColumnName(entity, item.column);
    return `${getLocalizedName(entity)}.${columnName}`;
  }
  return getLocalizedColumnName(baseEntity, item.column);
};

export class JoinHeader extends Component<PropTypes> {
  static readonly displayName = "JoinHeader";

  onTypeChange = (type: JoinType) => {
    this.props.onChange({ ...this.props.value, type });
  };

  render() {
    const {
      entities,
      baseEntityName,
      joinEntityName,
      onRemoveGroup,
      isGroupHeader,
      validJoinTypes,
      oneToOneOnly,
      value,
      onChange,
    } = this.props;

    const baseEntity = entities[baseEntityName];
    const joinEntity = entities[joinEntityName];

    const availableJoinTypes = validJoinTypes ?? allJoinTypes;
    const selectedJoinType = value.type ?? defaultJoinType;
    const availableSelectedJoinType = availableJoinTypes.find(
      (joinType) => joinType === selectedJoinType,
    );

    return (
      <div
        className={
          "x-flex x-query-group-header" +
          (isGroupHeader ? " x-query-group-header-padding" : "")
        }
      >
        {value.column ? (
          <>
            <Required value={availableSelectedJoinType}>
              <Selector<JoinType>
                className="x-flex-grow-1 x-margin-right-10 qa-join-type"
                getOptionLabel={displayLocalizedJoinType}
                options={availableJoinTypes}
                value={availableSelectedJoinType}
                onChange={this.onTypeChange}
              />
            </Required>
            <div className="x-flex-grow-2 x-margin-right-10 qa-join-label">
              {`${_("Join")} ${getLocalizedName(joinEntity)} (${getLabel(
                entities,
                baseEntity,
                value,
              )})`}
            </div>
          </>
        ) : undefined}
        <JoinSelector
          className="x-flex-grow-2"
          entities={entities}
          entity={joinEntityName}
          validJoinTypes={validJoinTypes}
          oneToOneOnly={oneToOneOnly}
          value={value}
          onChange={onChange}
        />
        {isGroupHeader ? (
          <DeleteButton
            className="x-query-group-delete x-margin-left-10 qa-join-remove-group"
            onClick={onRemoveGroup}
            title={_("Remove Group")}
          >
            <i className="fa fa-close" />
          </DeleteButton>
        ) : undefined}
      </div>
    );
  }
}
