import * as R from "ramda";
import { behaveAs, getByBehaviorArgument } from "common/entities";
import { Entities, Entity } from "common/entities/types";
import { addFilterToQuery } from "common/query/filter";
import { mergeJoin } from "common/query/joins";
import {
  Filter,
  FilterRule,
  isSelectExpression,
  isSummaryField,
  Query,
  QueryForEntity,
  SelectField,
} from "common/query/types";
import { Record } from "common/types/records";

// --- Metadata ------------------------------------------------------------- //

export const getPartChargeRelatedEntities = (
  entities: Entities,
  partCharge: Entity,
) => {
  const batchChargeEntity = R.find(
    (e) =>
      behaveAs("BatchCharge", e) &&
      e.arguments.partChargeEntity === partCharge.name,
    R.values(entities),
  );
  const batchEntity = entities[batchChargeEntity.arguments.batchEntity];
  const stockEntity = entities[batchEntity.arguments.stockEntity];
  const partEntity = entities[stockEntity.arguments.partEntity];

  return {
    batchChargeEntity,
    batchEntity,
    partEntity,
    stockEntity,
  };
};

// --- Query ---------------------------------------------------------------- //

export const getQueryFor = (
  entities: Entities,
  partCharge: Entity,
  query: Query,
  records: Record[],
): Query => {
  // Note: this is a fake query that matches the payload returned
  // by the backend but cannot be run as is (is missing aggregates and
  // major group by).

  const batchChargePermission = getByBehaviorArgument(
    entities,
    "BatchCharge",
    "partChargeEntity",
    partCharge.name,
  );

  if (!batchChargePermission) return query;

  const { batchChargeEntity } = getPartChargeRelatedEntities(
    entities,
    partCharge,
  );

  const batchChargePath = `/${batchChargeEntity.name}.partChargeId`;
  const batchPath = `${batchChargePath}/batchId`;
  const stockPath = `${batchPath}/stockId`;

  const isManual =
    records && R.any((r) => r.properties.partId.manualBatchSelection, records);

  const select = query.select
    .filter((f) => (f as SelectField).name !== "updatedOn")
    .concat([
      { name: "partId", path: stockPath },
      { name: "stockId", path: batchPath },
    ])
    .concat(isManual ? [{ name: "batchId", path: batchChargePath }] : [])
    .concat([
      { name: "quantity", path: batchChargePath },
      { name: "totalCost", path: batchChargePath },
      { name: "orgRate", path: batchChargePath },
      { name: "orgTotalCost", path: batchChargePath },
    ]);

  const newJoin = {
    entity: batchChargeEntity.name,
    column: "partChargeId",
    joins: [
      {
        column: "batchId",
        joins: [
          {
            column: "stockId",
          },
        ],
      },
    ],
  };

  return R.mergeRight(query, {
    select,
    joins: mergeJoin(newJoin, query.joins),
  });
};

// --- Queries -------------------------------------------------------------- //

export const getStockQuery = (
  stockEntity: Entity,
  batchEntity: Entity,
  partId: string,
): QueryForEntity => {
  const query = stockEntity.query;

  const filter: Filter = {
    and: [
      { name: "partId", op: "eq", value: partId },
      { name: "isDeleted", op: "isfalse" },
    ],
  };

  const select = query.select
    .filter((s) => !isSummaryField(s))
    .concat([{ name: "id" }]);
  const selectWithOnHand = select.concat([
    {
      expression: `CONCAT('${_("on hand").replace(
        "'",
        "''",
      )} ', CONVERT(VARCHAR(MAX), ISNULL(SUM({/${
        batchEntity.name
      }.stockId/quantity}), 0)))`,
      alias: "customtitle",
    },
    {
      expression: `ISNULL(SUM({/${batchEntity.name}.stockId/quantity}), 0)`,
      alias: "onHand",
    },
  ]);

  const group = select
    .filter((s) => !isSummaryField(s) && !isSelectExpression(s))
    .map((s: SelectField) => ({ ...R.pick(["name", "path"], s) }));

  const joins = (query.joins || []).concat([
    {
      entity: batchEntity.name,
      column: "stockId",
      type: "LEFT",
    },
  ]);

  return {
    entity: stockEntity.name,
    query: R.mergeRight(query, {
      select: selectWithOnHand,
      group,
      joins,
      filter: addFilterToQuery(filter, query).filter,
    }),
  };
};

export const getBatchQuery = (
  batchEntity: Entity,
  stockId: string,
): QueryForEntity => {
  const query = batchEntity.query;
  const idFilter: FilterRule = { name: "stockId", op: "eq", value: stockId };

  return {
    entity: batchEntity.name,
    query: R.mergeRight(query, {
      select: query.select.concat([
        { name: "id" },
        { name: "quantity" },
        { name: "cost" },
      ]),
      filter: {
        and: query.filter ? [idFilter, query.filter] : [idFilter],
      },
    }),
  };
};
