import { useState, useEffect } from "react";
import { Context } from "common/types/context";
import { adpApi } from "common/api/adp";
import { Entity } from "common/entities/types";
import { Record } from "common/types/records";
import { MeasurementsChart } from "common/record/form/content/measurements/chart";
import { ImagesCarousel } from "common/record/form/content/measurements/images-carousel";
import { MeasurementsResponse } from "common/record/form/content/measurements/types";
import { getChartOptions } from "common/record/form/content/measurements/chart/functions";
import {
  getChartData,
  getImageActions,
} from "common/record/form/content/measurements/views/temperature/functions";
import { Action } from "common/record/actions/types";
import { ActionsSelector } from "common/record/actions/components/selector";
import { ActionsHandler } from "common/record/actions/components/handler";
import { getWorkOrderEntity } from "common/record/actions/functions/behaviors";

interface PropTypes {
  context: Context;
  entity: Entity;
  measurements: MeasurementsResponse;
  dateFormat: string;
  record: Record;
}

export const TemperatureView = ({
  context,
  entity,
  record,
  measurements,
  dateFormat,
}: PropTypes) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [selectedAction, setAction] = useState<Action>();

  useEffect(() => {
    const measurementsCount = measurements?.measurements?.count;
    const activeIndex = measurementsCount > 0 ? measurementsCount - 1 : 0;
    setActiveIndex(activeIndex);
  }, [measurements?.measurements?.bn, measurements?.measurements?.count]);

  const onActionSelect = (action: Action) => setAction(action);

  const onActionDismiss = () => setAction(undefined);

  const onCreateActionPerform = (action: Action) => {
    const workOrderEntity = getWorkOrderEntity(context.entities, entity);
    adpApi(context.apiCall).attachImage(
      workOrderEntity.name,
      action.extraProperties.$id,
      action.data.imagePath,
      measurements.token,
    );
  };

  const onActionPerform = (action: Action) => {
    if (action.name === "Create") {
      onCreateActionPerform(action);
    }
  };

  const createImageActions = (imagePath: string) => {
    return (
      <ActionsSelector
        displayType="icon"
        actionGroups={getImageActions(context.entities, entity, imagePath)}
        onActionSelect={onActionSelect}
      />
    );
  };

  return (
    <>
      <MeasurementsChart
        options={getChartOptions(measurements, _("Temperature"))}
        data={getChartData(measurements, dateFormat, activeIndex)}
        onActiveIndexChange={setActiveIndex}
      />
      <ImagesCarousel
        measurementsResponse={measurements}
        dateFormat={dateFormat}
        activeIndex={activeIndex}
        onActiveIndexChange={setActiveIndex}
        createImageActions={createImageActions}
      />
      <ActionsHandler
        context={context}
        entity={entity}
        records={[record]}
        action={selectedAction}
        onActionPerform={onActionPerform}
        onActionDismiss={onActionDismiss}
      />
    </>
  );
};
TemperatureView.displayName = "TemperatureView";
