import * as React from "react";
import { ComponentPropsWithRef, ComponentType, lazy, Suspense } from "react";
import { LazyLoadingBoundary } from "common/lazy-loading/error-boundary";
import { LoadingIcon } from "common/widgets/loading-icon";

export function lazyComponent<
  TComponent extends ComponentType<any>,
  TProps extends ComponentPropsWithRef<TComponent>,
>(
  factory: () => Promise<{ default: TComponent }>,
): React.ComponentType<TProps> {
  const LazyComponent = lazy(factory);

  return (props: TProps) => (
    <LazyLoadingBoundary>
      <Suspense fallback={<LoadingIcon />}>
        <LazyComponent {...props} />
      </Suspense>
    </LazyLoadingBoundary>
  );
}
