import { arrayToString } from "common/utils/array";
import { AsyncSelector } from "common/widgets/selector/async-selector";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import {
  FormatMeta,
  LabelledOptionOrGroup,
} from "common/vendor-wrappers/react-select/types";
import { Entity } from "common/entities/types";
import {
  getFullForeignKeyLabel,
  isForeignKey,
} from "common/functions/foreign-key";
import { merge1 } from "common/merge";
import { sameResultSet } from "common/query/common";
import { QueryForEntity, RunQuery, Select } from "common/query/types";
import { Context } from "common/types/context";
import { FkValue, ForeignKey } from "common/types/foreign-key";
import { CancellablePromise } from "common/types/promises";
import { formatFk } from "common/widgets/foreign-key/functions";
import { ValueComponent, ValueProps } from "common/with-value-for";
import {
  formatItem,
  getFetchRecordQuery,
  MAX_SELECTOR_RECORDS,
} from "./functions";

interface PropTypes {
  context: Context;
  entity: Entity;
  query?: QueryForEntity;
  runQuery: RunQuery;
  runResolveQuery?: RunQuery;
  placeholder?: string;
  showDeleted?: boolean;
  disabled?: boolean;
  type?: "lookup" | "dropdown"; // lookup by default
  className?: string;
  label?: string;
  allowClear?: boolean;
  dontKeepValue?: boolean;
  approveChange?: () => CancellablePromise<void>;
}

type Props = PropTypes & ValueProps<FkValue>;

interface StateType {
  fallbackValue: ForeignKey;
}

const getValueToDisplay = (value: FkValue, fallback: ForeignKey) =>
  value && !isForeignKey(value) && value === fallback?.id ? fallback : value;

/**
 * A record selector with no extra stuff. Type is lookup by default.
 * If you want to display the dropdown arrow, use dropdown type.
 */
export class RecordSelector extends ValueComponent<
  FkValue,
  PropTypes,
  StateType
> {
  static readonly displayName = "RecordSelector";

  state: StateType = {
    fallbackValue: undefined,
  };

  componentDidMount() {
    this.resolveRecord(this.props);
  }

  componentDidUpdate(prevProps: Props) {
    const newProps = this.props;

    if (
      newProps.value !== prevProps.value ||
      newProps.entity !== prevProps.entity ||
      (prevProps.query && !newProps.query) ||
      (!prevProps.query && newProps.query) ||
      !sameResultSet(prevProps.query, newProps.query)
    ) {
      this.resolveRecord(newProps);
    }
  }

  onChange = (record: ForeignKey) => {
    const { approveChange, entity, value } = this.props;
    const newValue =
      record && isForeignKey(record)
        ? { ...record, entity: entity.name }
        : record;

    if (approveChange) {
      return approveChange()
        .then(() => {
          this.setValue(newValue);
        })
        .catch(() => {
          this.setValue(value);
        });
    }

    return this.setValue(newValue);
  };

  formatFk = (item: any) => {
    const { context } = this.props;
    return item && formatFk(context.entities)(item);
  };

  resolveRecord = (props: Props) => {
    const {
      value,
      entity,
      query,
      runQuery,
      runResolveQuery,
      showDeleted,
      context,
    } = props;
    const { entities } = context;
    const runResolveRecordQuery = runResolveQuery ?? runQuery;

    if (!value || isForeignKey(value)) return;

    const { select, joins } = getFetchRecordQuery(
      runResolveRecordQuery,
      entities,
      entity,
      query,
      showDeleted,
    );

    runResolveRecordQuery({
      entity: entity.name,
      query: { select, joins, filter: { name: "id", op: "eq", value } },
    }).then((fks: ForeignKey[] = []) => {
      this.setState({ fallbackValue: merge1("id", value, fks[0]) });
    });
  };

  getFormatItemFn =
    (select: Select, entity?: Entity, context?: Context) =>
    (item: LabelledOptionOrGroup<ForeignKey>, meta: FormatMeta<ForeignKey>) => {
      return isGroupedOption(item)
        ? item.label
        : formatItem(select, entity, context)(item.value, meta?.inputValue);
    };

  fetchRecords = (term: string, limit: number) => {
    const { entity, runQuery, query, showDeleted, context } = this.props;
    const { entities } = context;
    const { fetch } = getFetchRecordQuery(
      runQuery,
      entities,
      entity,
      query,
      showDeleted,
      limit,
    );

    return fetch(term);
  };

  mapOptions = (records: ForeignKey[]) => {
    const { context, entity } = this.props;

    return records.map((record) => ({
      value: record,
      label: getFullForeignKeyLabel(context, record, entity),
    }));
  };

  render() {
    const {
      entity,
      runQuery,
      query,
      placeholder,
      showDeleted,
      allowClear,
      disabled,
      type = "lookup",
      className = "",
      label,
      context,
      value,
      dontKeepValue,
    } = this.props;
    const { fallbackValue } = this.state;
    const { entities } = context;
    const { select } = getFetchRecordQuery(
      runQuery,
      entities,
      entity,
      query,
      showDeleted,
    );

    const classFromType = type === "lookup" ? "x-lookup qa-lookup" : undefined;
    const classes = arrayToString([
      className,
      classFromType,
      !value ? "x-selector-empty" : undefined,
    ]);

    const valueToDisplay = getValueToDisplay(
      value,
      fallbackValue,
    ) as ForeignKey;

    return (
      <AsyncSelector
        className={classes}
        fetch={this.fetchRecords}
        mapOptions={this.mapOptions}
        formatOptionLabel={this.getFormatItemFn(select, entity, context)}
        placeholder={placeholder}
        label={label}
        disabled={disabled}
        allowClear={allowClear}
        optionsLimit={MAX_SELECTOR_RECORDS}
        value={!dontKeepValue ? valueToDisplay : undefined}
        onChange={this.onChange}
      />
    );
  }
}
