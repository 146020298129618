import { parseValue } from "common";
import { getCached, getCachedPrimitive } from "common/cache";
import { Component } from "common/component";
import { merge1, merge2, merge3, merge4 } from "common/merge";
import { Primitive } from "common/types/primitives";

export type OnChange<T> = (v: T) => void;

export interface ValueProps<T> {
  value: T;
  onChange: OnChange<T>;
}

type OnChangeMergeValue = (e: any) => void;
type OnChangeMergeValue1<V> = <K1 extends keyof V>(k1: K1) => OnChange<V[K1]>;

type CachedDefaultValue = () => void;
type SetDefaultValue<V> = (p: V & Primitive) => CachedDefaultValue;

export type ValueComponentType<V, P = unknown, S = unknown> = new (
  props: P & ValueProps<V>,
  ctx: any,
) => ValueComponent<V, P, S>;

/**
 * @deprecated Since we removed value events and started passing pure values on callbacks, `ValueComponent`
 * has lost its appeal. We recommend to not use it on new features and slowly replace it by function components or `React.Component`.
 * Make sure you test with react's browser plugin (highlight updates) too see if the whole UI is not re-rendering.
 * Be extra careful with repeated components in the UI e.g. table rows etc. In this case you can use `DeepCheckComponent`.
 */
export class ValueComponent<V, P = unknown, S = unknown> extends Component<
  P & ValueProps<V>,
  S
> {
  /**
   * @deprecated Can be replaced by creating function that calls `props.onChange` merging value with ...spread
   */
  onChangeMergeValue: OnChangeMergeValue1<V>;

  /**
   * @deprecated Can be replaced by creating function that calls `props.onChange` merging value with ...spread
   */
  onChangeMergeValueNoParse: OnChangeMergeValue1<V>;

  onChangeMergeValue2: <K1 extends keyof V, K2 extends keyof V[K1]>(
    k1: K1,
    k2: K2,
  ) => OnChange<V[K1][K2]>;

  onChangeMergeValue3: <
    K1 extends keyof V,
    K2 extends keyof V[K1],
    K3 extends keyof V[K1][K2],
  >(
    k1: K1,
    k2: K2,
    k3: K3,
  ) => OnChange<V[K1][K2][K3]>;

  onChangeMergeValue4: <
    K1 extends keyof V,
    K2 extends keyof V[K1],
    K3 extends keyof V[K1][K2],
    K4 extends keyof V[K1][K2][K3],
  >(
    k1: K1,
    k2: K2,
    k3: K3,
    k4: K4,
  ) => OnChange<V[K1][K2][K3][K4]>;

  /**
   * @deprecated Can be replaced by calling `props.onChange` with your default value. No rocket science.
   */
  onChangeSetDefaultValue: SetDefaultValue<V>;

  constructor(props: any) {
    super(props);

    this.onChangeMergeValueNoParse = getCached<
      OnChangeMergeValue,
      keyof OnChangeMergeValue
    >((key) => (v) => this.mergeValue1(key as any, v, true));
    this.onChangeMergeValue = getCached<
      OnChangeMergeValue,
      keyof OnChangeMergeValue
    >((key) => (v) => this.mergeValue1(key as any, v, false));
    this.onChangeMergeValue2 = <K1 extends keyof V, K2 extends keyof V[K1]>(
      k1: K1,
      k2: K2,
    ) =>
      getCached<OnChangeMergeValue>(() => (v) => this.mergeValue2(k1, k2, v))(
        [k1, k2].join("."),
      );

    this.onChangeMergeValue3 = <
      K1 extends keyof V,
      K2 extends keyof V[K1],
      K3 extends keyof V[K1][K2],
    >(
      k1: K1,
      k2: K2,
      k3: K3,
    ) =>
      getCached<OnChangeMergeValue>(
        () => (v) => this.mergeValue3(k1, k2, k3, v),
      )([k1, k2, k3].join("."));

    this.onChangeMergeValue4 = <
      K1 extends keyof V,
      K2 extends keyof V[K1],
      K3 extends keyof V[K1][K2],
      K4 extends keyof V[K1][K2][K3],
    >(
      k1: K1,
      k2: K2,
      k3: K3,
      k4: K4,
    ) =>
      getCached<OnChangeMergeValue>(
        () => (v) => this.mergeValue4(k1, k2, k3, k4, v),
      )([k1, k2, k3, k4].join("."));

    this.onChangeSetDefaultValue = getCachedPrimitive<CachedDefaultValue>(
      (newValue) => () => this.setValue(newValue as any),
    );
  }

  /**
   * @deprecated Consider removing `ValueComponent` altogether on your file and replacing by `React.Component` calling `props.onChange(yourValue)`
   */
  setValue = (v: V, noParse?: boolean) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(noParse ? v : parseValue(v));
    }
  };

  /**
   * @deprecated Consider removing `ValueComponent` altogether on your file and replacing by `React.FC` or `React.Component` just passing down `props.onChange`
   */
  onChangeSetValue = (v: V) => {
    this.setValue(v);
  };

  /**
   * @deprecated Consider removing `ValueComponent` altogether on your file and replacing by `React.FC` or `React.Component` just passing down `props.onChange`
   */
  onChangeSetValueNoParse = (v: V) => {
    this.setValue(v, true);
  };

  /**
   * @deprecated Can be replaced by calling `props.onChange` merging value with ...spread
   */
  mergeValue = (v: Partial<V>) => {
    this.setValue({ ...this.props.value, ...v });
  };

  /**
   * @deprecated Can be replaced by calling `props.onChange` merging value with ...spread
   */
  mergeValue1<K1 extends keyof V>(k1: K1, value: V[K1], noParse?: boolean) {
    return this.setValue(
      merge1(k1, noParse ? value : parseValue(value), this.props.value),
    );
  }

  mergeValue2 = <K1 extends keyof V, K2 extends keyof V[K1]>(
    k1: K1,
    k2: K2,
    value: V[K1][K2],
    noParse?: boolean,
  ) => {
    return this.setValue(
      merge2(k1, k2, noParse ? value : parseValue(value), this.props.value),
    );
  };

  mergeValue3<
    K1 extends keyof V,
    K2 extends keyof V[K1],
    K3 extends keyof V[K1][K2],
  >(k1: K1, k2: K2, k3: K3, value: V[K1][K2][K3], noParse?: boolean) {
    return this.setValue(
      merge3(k1, k2, k3, noParse ? value : parseValue(value), this.props.value),
    );
  }

  mergeValue4<
    K1 extends keyof V,
    K2 extends keyof V[K1],
    K3 extends keyof V[K1][K2],
    K4 extends keyof V[K1][K2][K3],
  >(
    k1: K1,
    k2: K2,
    k3: K3,
    k4: K4,
    value: V[K1][K2][K3][K4],
    noParse?: boolean,
  ) {
    return this.setValue(
      merge4(
        k1,
        k2,
        k3,
        k4,
        noParse ? value : parseValue(value),
        this.props.value,
      ),
    );
  }
}
