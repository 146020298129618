import { getColumn, getColumnLabel } from "common/entities";
import { Entity } from "common/entities/types";
import { LabelWidget } from "common/form/widget/label-widget";
import { Context } from "common/types/context";
import { Id } from "../utils";

interface PropTypes {
  context: Context;
  entity: Entity;
  layoutId: string;
  id: Id;
  isNew: boolean;
}

/** Ignore name. Read as sub subtitle line */
export const IdInfo = ({ context, entity, layoutId, id, isNew }: PropTypes) => (
  <div className={`x-info-id qa-${entity.name}-${layoutId}`}>
    <span className="x-id-label qa-id-label">{`${getColumnLabel(
      entity,
      id.label,
    )}:`}</span>
    <div className="x-id-value qa-id-value">
      {isNew ? (
        _("New")
      ) : (
        <LabelWidget
          context={context}
          column={getColumn(entity, id.label)}
          entityName={entity.name}
          value={id.value}
        />
      )}
    </div>
  </div>
);
IdInfo.displayName = "IdInfo";
