import * as R from "ramda";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import { SelectorOption } from "common/widgets/selector/types";
import { Context } from "common/types/context";
import { ValueComponent } from "common/with-value-for";
import { defaultFor, getLocalizedName } from "common";
import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";
import { RunQuery } from "common/query/types";
import { Date } from "common/widgets/date/date";
import { Uint } from "common/widgets/number";
import { Required } from "common/widgets/required";
import { Section, RequiredField } from "common/form/ui";
import { getFormatBatchFn } from "common/record/form/content/related/stock/functions";
import { getBatchQuery } from "common/record/form/content/related/part-charge/functions";
import { QuerySelector } from "common/widgets/selector/query-selector";
import { Selector } from "common/widgets/selector";
import { T1000 } from "common/widgets/text";
import { type BatchFk, TransferFormValue as Value } from "./types";

interface Destination {
  id: string;
  title: string;
}

interface PropTypes {
  context: Context;
  entity: Entity;
  runQuery: RunQuery;
  onHand: number;
  recordId: string;
  fromLocation: string;
  hasBatchSelection: boolean;
  destinations: Destination[];
}

function withinBound(max: number, min: number, input: number) {
  return input > max ? max : input < min ? min : input;
}

const defaultValue = defaultFor<Value>();

const formatDestinationOption = (option: SelectorOption<Destination>) =>
  isGroupedOption(option) ? option.label : option.title;

export class TransferStockFormComponent extends ValueComponent<
  Value,
  PropTypes
> {
  static readonly displayName = "TransferStockFormComponent";

  onChangeDestination = (destination: { id: string; title: string }) => {
    const { fromLocation } = this.props;
    this.mergeValue({
      destination,
      comment: _("Transfer from {FROM_LOCATION} to {TO_LOCATION}")
        .replace("{FROM_LOCATION}", fromLocation)
        .replace("{TO_LOCATION}", destination.title),
    });
  };

  changeQuantity = (quantity: number) => {
    const { onHand, value = defaultValue } = this.props;
    const { batch } = value;
    const max = batch ? batch.quantity : onHand;
    this.mergeValue({ quantity: withinBound(max, 0, quantity) });
  };

  onSubtract = () => {
    const { value = defaultValue } = this.props;
    const { quantity } = value;
    this.changeQuantity(quantity - 1);
  };

  onAdd = () => {
    const { value = defaultValue } = this.props;
    const { quantity } = value;
    this.changeQuantity(quantity + 1);
  };

  onChangeBatch = (batch: any) => {
    const { value = defaultValue } = this.props;
    this.mergeValue({
      quantity: withinBound(batch.quantity, 0, value.quantity),
      batch,
    });
  };

  onChangeCount = (count: string) =>
    this.changeQuantity(parseInt(count, 10) || 0);

  onCommentChange = (comment: string) => this.mergeValue({ comment });

  render() {
    const {
      context,
      entity,
      runQuery,
      onHand,
      destinations,
      recordId,
      hasBatchSelection,
      value = defaultValue,
    } = this.props;

    const { quantity, date, destination, comment, batch } = value;

    const batchEntity = R.values(context.entities).filter(
      (e) => behaveAs("Batch", e) && e.arguments.stockEntity === entity.name,
    )[0];

    const batchSelector = hasBatchSelection ? (
      <RequiredField
        label={getLocalizedName(batchEntity)}
        className="x-stock-transfer-batch qa-adjustment-batch"
        value={batch}
      >
        <QuerySelector<BatchFk>
          context={context}
          query={getBatchQuery(batchEntity, recordId)}
          runQuery={runQuery}
          value={batch}
          formatOption={getFormatBatchFn(context)}
          onChange={this.onChangeBatch}
        />
      </RequiredField>
    ) : undefined;

    return (
      <div className="x-stock-form-wrapper">
        <div
          className={
            "form-inline x-stock-form-transfer-stock" +
            (hasBatchSelection ? " x-stock-form-transfer-batch" : "")
          }
        >
          {batchSelector}
          <label
            className="col-md-2 x-stock-form-transfer-amount"
            htmlFor="quantity"
          >
            {`${_("Amount to transfer")}: `}
          </label>
          <div className="form-control x-stock-form-current-controls">
            <div
              className="x-stock-subtract-count qa-stock-transfer-subtract-count"
              onClick={this.onSubtract}
            >
              <i className="fa fa-minus-circle" />
            </div>
            <Required
              className="x-transfer-input"
              value={(quantity > 0 && quantity <= onHand) || undefined}
            >
              <Uint value={quantity} onChange={this.onChangeCount} />
            </Required>
            <div
              className="x-stock-add-count qa-stock-transfer-add-count"
              onClick={this.onAdd}
            >
              <i className="fa fa-plus-circle" />
            </div>
          </div>
        </div>
        <Section>
          <RequiredField
            label={_("Destination")}
            value={destination}
            className="qa-stock-destination"
          >
            <Selector<Destination>
              allowClear={false}
              options={destinations}
              getOptionLabel={formatDestinationOption}
              value={destination}
              onChange={this.onChangeDestination}
            />
          </RequiredField>
          <RequiredField
            label={_("Transaction date")}
            className="qa-stock-date"
            value={date}
          >
            <Date
              uiFormat={context.uiFormat}
              value={date}
              onChange={this.onChangeMergeValue("date")}
            />
          </RequiredField>
          <RequiredField
            label={`${_("Packing Slip")} / ${_("Comment")}`}
            className="qa-stock-comment"
            value={comment}
          >
            <T1000
              maxLength={256}
              value={comment}
              onChange={this.onCommentChange}
            />
          </RequiredField>
        </Section>
      </div>
    );
  }
}
