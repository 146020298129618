import * as R from "ramda";
import { Properties } from "common/types/records";
import { ApiCall } from "common/types/api";
import { getUtcNow } from "common/date-time/common";
import { Entities } from "common/entities/types";
import { getSubFKTitle } from "common/functions/foreign-key";
import { LocalStorage } from "common/types/local-storage";
import {
  RecentlyViewed,
  RecentlyViewedService,
} from "common/types/recently-viewed";
import { getFkOrDefault } from "./foreign-key";

export const recentlyViewedService = (
  apiCall: ApiCall,
  _: string,
  localStorage: LocalStorage,
  entities: Entities,
  notify: () => void,
): RecentlyViewedService => {
  const get = (): RecentlyViewed[] =>
    R.uniqBy(
      ({ site, entity, id }) => `${site}/${entity}/${id}`,
      localStorage.get<RecentlyViewed[]>("recently-viewed", []),
    );

  const set = (items: RecentlyViewed[]) => {
    localStorage.set("recently-viewed", items);
    notify();
  };

  const update = (fn: (items: RecentlyViewed[]) => RecentlyViewed[]) =>
    set(fn(get()));

  const remove = (site: string, entityName: string, id: string) =>
    update((items) =>
      items.filter(
        (r) => r.site !== site || r.entity !== entityName || r.id !== id,
      ),
    );

  const add = (site: string, entityName: string, record: Properties) => {
    const entity = entities[entityName];

    getFkOrDefault(apiCall, entity, record).then((r) => {
      const item: RecentlyViewed = {
        site,
        entity: entity.name,
        id: record.id,
        number: record.number,
        label: getSubFKTitle(r.title),
        update: getUtcNow(),
      };
      update((items) => [item].concat(items));
    });
  };

  return {
    get,
    add,
    remove,
  };
};
