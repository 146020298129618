import { Entity } from "common/entities/types";
import { getIntFkId } from "common/functions/system-int";
import { mergeChain } from "common/merge";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { ProcedureTypes } from "common/types/procedure-type";
import { SystemIntFk } from "common/types/system-int";
import { COMPLETED_PROCEDURE_STATUS_ID } from "common/types/system-strings";
import {
  FancyCheckbox,
  FancyCheckboxSmall,
} from "common/widgets/fancy-checkbox";
import { ValueProps } from "common/with-value-for";
import { getPartialForm } from "../functions";
import { RelatedValue } from "../types";
import { getProcedureStatus } from "./functions";

interface PropTypes extends ValueProps<RelatedValue> {
  context: Context;
  entity: Entity;
  isQuickInput: boolean;
  properties: Properties;
  onChangeForm: (properties: Properties) => void;
  isDisabled?: (item: SystemIntFk) => boolean;
}

export const CompletableStatusContent = ({
  context,
  entity,
  isQuickInput,
  properties,
  onChangeForm,
  isDisabled,
  value,
  onChange,
}: PropTypes) => {
  const onCompletableStatusQuickInputChange =
    (procedureId: string) => (procedureStatus: boolean) => {
      const procedureStatusId = procedureStatus
        ? COMPLETED_PROCEDURE_STATUS_ID
        : undefined;

      const oldProcedures =
        value.related.form?.[entity.name] ||
        value.record.related?.[entity.name];

      const updatedProcedures = oldProcedures.map((p) =>
        p.properties.id === procedureId || p.properties.tempId === procedureId
          ? { ...p, properties: { ...p.properties, procedureStatusId } }
          : p,
      );

      const newValue = mergeChain(value)
        .prop("related")
        .set("isDirty", true)
        .prop("form")
        .set(entity.name, updatedProcedures)
        .output();

      onChange(newValue);
    };

  const onCompletableStatusChange = (procedureStatus: boolean) => {
    const partialForm = getPartialForm(value, entity.name);
    const newPartialForm = {
      ...partialForm,
      procedureStatusId: procedureStatus
        ? COMPLETED_PROCEDURE_STATUS_ID
        : undefined,
    };
    onChangeForm(newPartialForm);
  };

  const { id, tempId, followUpId } = properties;
  const { systemTables } = context;

  const procedureStatusId = getIntFkId(properties.procedureStatusId);
  const allProcedureStatus = systemTables?.ProcedureStatus || [];
  const currentStatusOption = allProcedureStatus.find(
    (o) => o.id === procedureStatusId,
  );

  const completableOptions = getProcedureStatus(allProcedureStatus, {
    procedureTypeId: ProcedureTypes.Completable,
  });

  const CheckboxComponent = isQuickInput ? FancyCheckboxSmall : FancyCheckbox;
  const onChangeValue = isQuickInput
    ? onCompletableStatusQuickInputChange(id || tempId)
    : onCompletableStatusChange;

  return (
    <CheckboxComponent
      label={completableOptions.translate(currentStatusOption)}
      disabled={
        isDisabled?.(properties.procedureTypeId) ||
        (isQuickInput && !!followUpId)
      }
      value={currentStatusOption?.id === COMPLETED_PROCEDURE_STATUS_ID}
      onChange={onChangeValue}
    />
  );
};
