interface Props {
  itemsName: string;
}

export const EmptyList = ({ itemsName }: Props) => (
  <div className="x-list-grid-no-results">
    <h2>
      {_("You have not created any {ITEMS_NAME}").replace(
        "{ITEMS_NAME}",
        itemsName,
      )}
    </h2>
    <p>
      {_("To create a new one just click the")}
      <strong> {_("New")} </strong>
      {`${_("button at the top right corner")}.`}
    </p>
  </div>
);
