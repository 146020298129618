import { toAlphanumericWithDashes } from "common";
import { FileType } from "common/types/media";
import { pseudoCancellable } from "common/types/promises";

export const blobsPathPart = "/blobs/";
const fileNamePathPart = "fileName:";

export const getFileName = (url: string = "") => {
  if (url.indexOf(fileNamePathPart) === 0) {
    const [, fileName, extension] =
      url.match(/^fileName:(.+)\.([a-z0-9]+),data:/i) || [];

    if (!fileName) return "";

    const normalizedFileName =
      toAlphanumericWithDashes(fileName.replace(/--+/g, "-")) || "file";

    return `${normalizedFileName}.${extension}`;
  }
  return url.split(blobsPathPart)[1];
};

export const isPendingUpload = (url: string) =>
  url?.indexOf(fileNamePathPart) === 0;

export const getFileDetails = (url: string): FileType =>
  url ? { type: "file", name: getFileName(url), url } : undefined;

export const getUrlFromFile = (file: FileType) => {
  return pseudoCancellable(
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(fileNamePathPart + file.name + "," + fileReader.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsDataURL(file as any);
    }),
  );
};
