import { Component } from "react";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import { SelectorOption } from "common/widgets/selector/types";
import { getLocalizedName } from "common";
import { findColumn } from "common/entities";
import { Selector } from "common/widgets/selector";
import { EntityColumn } from "common/entities/entity-column/types";
import { ValueProps } from "common/with-value-for";

type Value = EntityColumn | string;

interface PropTypes extends ValueProps<Value> {
  columns: EntityColumn[];
  className?: string;
  disabled?: boolean;
  allowClear?: boolean;
  placeholder?: string;
  label?: string;
}

export const getColumnOptionLabel = (option: SelectorOption<EntityColumn>) =>
  isGroupedOption(option) ? option.label : getLocalizedName(option);

export class EntityColumnSelector extends Component<PropTypes> {
  static readonly displayName = "EntityColumnSelector";

  render() {
    const {
      columns,
      className,
      label,
      disabled,
      placeholder,
      allowClear = true,
      value,
      onChange,
    } = this.props;

    const currentValue =
      typeof value === "string" ? findColumn(columns, value) : value;

    return (
      <Selector<EntityColumn>
        allowClear={allowClear}
        placeholder={placeholder || _("Select a field")}
        className={className}
        label={label}
        options={columns}
        getOptionLabel={getColumnOptionLabel}
        disabled={disabled}
        value={currentValue}
        onChange={onChange}
      />
    );
  }
}
