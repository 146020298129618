import { Query } from "common/query/types";
import { BodyFilter } from "./body-filter";
import { BodyOutput } from "./body-output";
import { ListBodyPropTypes } from "./types";

export const ListBody = (props: ListBodyPropTypes) => {
  const { output, context, entity, value } = props;

  const onFilterChange = (query: Query) => {
    props.onChange({ ...props.value, query: { ...props.value.query, query } });
  };

  const body = (
    <>
      <BodyFilter
        context={context}
        entity={entity}
        output={output}
        value={value.query.query}
        onChange={onFilterChange}
      />
      <BodyOutput {...props} onChange={props.onChange} />
    </>
  );

  return output === "FunctionalLocation" ? (
    <div className="x-list-body-with-flex">{body}</div>
  ) : (
    body
  );
};
