import * as R from "ramda";
import { Culture } from "common/culture/supported-cultures";
import { GroupColumn } from "common/form/types";

export const getColumnName = (culture: Culture) =>
  "description_" + culture.replace("-", "");

/**
 * Puts the user's own culture field as first
 * @param columns
 * @param ownCulture
 * @param ownColumnPropsOverwrite Optional GroupColumn props to merge into the own culture column
 */
export const keepOwnCultureFirst = (
  columns: GroupColumn[],
  ownCulture: Culture,
  ownColumnPropsOverwrite?: Partial<GroupColumn>,
): GroupColumn[] => {
  const ownColumnName = getColumnName(ownCulture);

  const [matched, otherColumns] = R.partition(
    (c) => c.columnName === ownColumnName,
    columns,
  );
  if (matched.length !== 1) return columns;

  return [{ ...matched[0], ...ownColumnPropsOverwrite }, ...otherColumns];
};
