import * as R from "ramda";
import { reportsApi } from "common/api/reports";
import { rolesApi } from "common/api/roles";
import { hasPermissionToRead } from "common/functions/roles";
import { Context } from "common/types/context";
import { CancellablePromise } from "common/types/promises";
import { Report } from "common/types/reports";
import {
  createEditCtrlWithPermission,
  ExternalPropTypes,
} from "common/ui/controllers/edit";
import { createInjected } from "common/ui/controllers/edit/base";
import {
  DependenciesComp,
  dependenciesInjected,
} from "common/with-dependencies";
import { ReportFormType } from "x/reports/edit/types";
import { EditReportForm } from "./edit";

interface PropTypes extends ExternalPropTypes<number, Report> {
  context: Context;
}

const EditController = createEditCtrlWithPermission<
  ReportFormType,
  number,
  Report
>();
const injected = createInjected<ReportFormType, number, Report>();

const unwrap = (form: ReportFormType): Report => form.item;
const wrap = (form: ReportFormType, report: Report) =>
  R.mergeRight(form, { item: report });

export const ReportEditController = (props: PropTypes) => {
  const { context } = props;
  const { role, apiCall, uiFormat, entities, userTypes } = context;
  const canAccessRoles = hasPermissionToRead(userTypes, role, "Roles");

  return (
    <DependenciesComp
      dependencies={{
        roles: canAccessRoles
          ? rolesApi(apiCall).list()
          : CancellablePromise.resolve([]),
      }}
      child={
        <EditController
          {...dependenciesInjected()}
          {...props}
          api={reportsApi(apiCall, uiFormat.culture, entities)}
          permissionCategory="Reports"
          wrapRecord={wrap}
          unwrapRecord={unwrap}
          defaultValue={{
            item: {
              name: undefined,
              entity: R.values(entities)[0].name,
              query: {
                select: [],
              },
              roleIds: canAccessRoles ? [] : [role.id],
              labels: {},
              isGlobal: canAccessRoles,
              sites: [],
            },
            page: undefined,
          }}
          confirmationTitle={_("The report will be deleted")}
          confirmationLabel={_("Yes, delete the report")}
        >
          <EditReportForm {...injected} context={context} />
        </EditController>
      }
    />
  );
};
