import { isTypeWithoutValues } from "common";
import { Value, UniqueValue } from "./types";

const isUniqueObject = isTypeWithoutValues<Value, UniqueValue>([
  "value",
  "pending",
  "duplicated",
]);

export const getValueFromUniqueValue = (v: any) =>
  isUniqueObject(v) ? v.value : v;
