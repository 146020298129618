import { Component } from "react";
import { omitDataTypes } from "common/entities/entity-column/data-type/functions";
import {
  filterBlackList,
  queryBuilderBlackList,
} from "common/entities/entity-column/data-type/types";
import { Condition } from "common/query-builder/condition";
import { getFields } from "common/query-builder/functions";
import { Select } from "common/query-builder/select";
import { getPathMap } from "common/query/joins";
import type {
  Filter,
  Query,
  QueryForEntity,
  SelectItem,
} from "common/query/types";
import type { ConditionTypes } from "common/types/condition-type";
import type { Context } from "common/types/context";
import { VerticalField } from "common/ui/field";
import type { ValueProps } from "common/with-value-for";

interface PropTypes extends ValueProps<QueryForEntity> {
  context: Context;
  conditionTypes?: ConditionTypes;
}

const defaultQuery: Query = { select: undefined };
const MAX_SELECT_FIELDS = 5;

export class ConfigurationForm extends Component<PropTypes> {
  onChangeSelect = (select: SelectItem[]) => {
    const { value } = this.props;
    if (!select || select.length <= MAX_SELECT_FIELDS) {
      this.props.onChange({ ...value, query: { ...value.query, select } });
    }
  };

  onChangeFilter = (filter: Filter) => {
    const { value } = this.props;
    this.props.onChange({ ...value, query: { ...value.query, filter } });
  };

  render() {
    const { value, context, conditionTypes } = this.props;
    const { entities } = context;
    const fields = getFields(entities, value);
    const filteredFields = omitDataTypes(fields, filterBlackList);
    const queryBuilderFilteredFields = omitDataTypes(
      fields,
      queryBuilderBlackList,
    );
    const query = value.query || defaultQuery;
    const pathMap = getPathMap(context.entities, value);

    const selectField = (
      <Select
        context={context}
        allowAggregates={false}
        forceFkAggregates={false}
        allowLong={true}
        entities={context.entities}
        fields={queryBuilderFilteredFields}
        maxFields={MAX_SELECT_FIELDS}
        noDuplicates={true}
        value={query.select}
        onChange={this.onChangeSelect}
      />
    );
    const filterField = (
      <Condition
        pathMap={pathMap}
        allowAggregates={false}
        fields={filteredFields}
        context={context}
        conditionTypes={conditionTypes}
        entityName={value.entity}
        withExpressions={true}
        value={query.filter}
        onChange={this.onChangeFilter}
      />
    );

    return (
      <div className="x-query-builder qa-query-builder">
        <VerticalField
          label={_("Filter By")}
          className={"x-filter-by qa-filter-by"}
          input={filterField}
        />
        <VerticalField
          label={_("Tile Display Fields")}
          className={"x-select qa-select"}
          input={selectField}
        />
      </div>
    );
  }
}
