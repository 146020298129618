import * as R from "ramda";
import { searchApi } from "common/api/search";
import { getUrl } from "common/entities";
import { Entity } from "common/entities/types";
import { jsxToString } from "common/utils/jsx";
import { Pin } from "common/vendor-wrappers/leaflet/types";
import { getFieldAndFormat, getSubFKTitle } from "common/functions/foreign-key";
import { isSummaryField, SelectField } from "common/query/types";
import { ForeignKey } from "common/types/foreign-key";
import {
  addIconDom,
  addSubtitleDom,
} from "common/widgets/record-selector/functions";
import { Context } from "common/types/context";

export const formatPinDetails =
  (
    hasImage: boolean,
    hasSubtitle: boolean,
    hasSubsubtitle: boolean,
    context: Context,
    entity: Entity,
    url: string,
  ) =>
  (item: ForeignKey) => {
    if (!item) return null;
    const title = getSubFKTitle(item.title);
    const subtitle = getSubFKTitle(item.subtitle);
    const subsubtitle = getSubFKTitle(item.subsubtitle);

    const formattedTitle = entity
      ? getFieldAndFormat(entity, context, "title", title)
      : title;
    const formattedSubtitle = entity
      ? getFieldAndFormat(entity, context, "subtitle", subtitle)
      : subtitle;
    const formattedSubsubtitle = entity
      ? getFieldAndFormat(entity, context, "subsubtitle", subsubtitle)
      : subsubtitle;

    return (
      <div className="x-select-item">
        {hasImage ? addIconDom(item.image) : undefined}
        <div className="x-data">
          <div className="x-title qa-title">
            {item.number}
            {item.number && formattedTitle ? " - " : undefined}
            <a href={url}>{formattedTitle}</a>
          </div>
          {hasSubtitle ? addSubtitleDom(formattedSubtitle) : undefined}
          {hasSubsubtitle ? addSubtitleDom(formattedSubsubtitle) : undefined}
        </div>
      </div>
    );
  };

const formatItem = (context: Context, entity: Entity, url: string) => {
  const findInSelect = (name: string) =>
    !!entity.query.select.find(
      (selectItem) =>
        !isSummaryField(selectItem) &&
        (selectItem.alias ?? (selectItem as SelectField).name) === name,
    );
  return formatPinDetails(
    findInSelect("image"),
    findInSelect("subtitle"),
    findInSelect("subsubtitle"),
    context,
    entity,
    url,
  );
};

// TODO another self resolving foreign key implementation. see if we can reuse LinkFk
export const getInfo = (context: Context) => (pos: Pin) => {
  const { entities, apiCall, site } = context;
  const entityName = pos.layer;
  const recordId = pos.id;
  const entity = entities[entityName];
  const url = `${getUrl(entity, site.name)}/${recordId}`;

  const query = {
    entity: entityName,
    query: R.mergeRight(entity.query, {
      filter: { name: "id", op: "eq", value: recordId },
    }),
  };

  const format = (rs: any[]) =>
    jsxToString(formatItem(context, entity, url)(rs[0]));

  return searchApi(apiCall).runQuery(query).then(format);
};
