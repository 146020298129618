import { withAuthAndReason } from "common/api";
import { ApiCall } from "common/types/api";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { ESignature, ESignatureReason as Reason } from "common/types/reasons";
import { Modal } from "common/widgets/modal";
import { ESignatureContext } from "./esignature-context";
import { useESignatureContext } from "./use-esignature-context";

interface Props {
  context: Context;
  authAction: (apiCall: ApiCall) => any;
  cancel: () => any;
  error: ApiErrorResponse;
  onErrorChange?: (error: ApiErrorResponse) => void;
}

export const ExplicitAuth = ({
  context,
  authAction,
  cancel,
  error,
  onErrorChange,
}: Props) => {
  const {
    isFilled,
    password,
    reason,
    eSignatureSettings,
    loading,
    loadingError,
    onReasonChange,
    onPasswordChange,
  } = useESignatureContext(context.apiCall, true);
  const executeAction = () => {
    const eSignature: ESignature = { text: reason?.text, code: reason?.code };
    const eSignatureReason: Reason = { eSignature };

    authAction(
      withAuthAndReason(context.apiCall, password, eSignatureReason) as ApiCall,
    );
  };

  const submit = isFilled ? executeAction : undefined;

  return (
    <Modal
      content={
        <ESignatureContext
          context={context}
          submit={submit}
          error={error}
          loading={loading}
          loadingError={loadingError}
          eSignatureSettings={eSignatureSettings}
          onReasonChange={onReasonChange}
          onPasswordChange={onPasswordChange}
          password={password}
          reason={reason}
          onErrorChange={onErrorChange}
        />
      }
      title={_("Authorization required")}
      okLabel={_("Continue")}
      onOk={submit}
      onCancel={cancel}
    />
  );
};

ExplicitAuth.displayName = "ExplicitAuth";
