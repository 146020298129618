import { defaultFor } from "common";
import { Culture, EN_US } from "common/culture/supported-cultures";
import { Entity } from "common/entities/types";
import { EmailTemplate } from "common/types/email-templates";
import { Site } from "common/types/sites";
import { getAccessibleNodes } from "common/utils/tree";

export const getFieldsForCulture = (
  template: EmailTemplate,
  culture: Culture,
) => template?.fields?.[culture] ?? defaultFor();

export const getFieldsForDefaultCulture = (template: EmailTemplate) =>
  getFieldsForCulture(template, EN_US);

export const getTemplatesAvailableInSites = (
  emailTemplates: EmailTemplate[],
  site: Site,
  sites: Site[] = [],
) => {
  const accessibleSites = getAccessibleNodes([], site, sites);
  return emailTemplates.filter((emailTemplate) => {
    const templateSites = emailTemplate.sites;
    return (
      !templateSites?.length ||
      accessibleSites.some((s) => templateSites.includes(s.name))
    );
  });
};
export const getTemplatesAvailableInEntity = (
  emailTemplates: EmailTemplate[],
  entity: Entity,
) => emailTemplates?.filter((t) => t.entityName === entity?.name);
