import * as R from "ramda";
import { Context } from "common/types/context";
import { behaveAs, filterByBehavior } from "common/entities";
import { Entities, Entity } from "common/entities/types";
import { merge1, merge4, mergeChain } from "common/merge";
import { StandardValue } from "common/record/types";
import { getDependencies } from "./related/procedure-type/functions";

export const getEntityWithBehaviors = (entity: Entity) => {
  if (behaveAs("Order", entity)) {
    return R.mergeRight(entity, {
      reorderable: true,
      columns: entity.columns.map((c) => {
        switch (c.name) {
          case "procedureStatusId":
            return mergeChain(c)
              .setMany({
                quickInput: true,
                getDependencies,
              })
              .output();
          case "order":
            return R.mergeRight(c, { readOnly: true });
          default:
            return c;
        }
      }),
    });
  }

  if (behaveAs("WorkOrderAsset", entity)) {
    return R.mergeRight(entity, {
      columns: entity.columns.map((c) => {
        switch (c.name) {
          case "status":
            return merge1("quickInput", true, c);
          default:
            return c;
        }
      }),
    });
  }

  return entity;
};

export const getContextWithBehaviors = (
  context: Context,
  entityName: string,
) => {
  const entity = context.entities[entityName];

  return context.setEntity(context, entityName, getEntityWithBehaviors(entity));
};

export const updateRelatedEntities = (
  entities: Entities,
  parentEntity: Entity,
  value: StandardValue,
  relatedEntity?: Entity,
): StandardValue => {
  if (relatedEntity && !behaveAs("WorkOrderAsset", relatedEntity)) return value;

  const workOrderAssetEntity = R.values(
    filterByBehavior("WorkOrderAsset", entities),
  );

  if (!workOrderAssetEntity.length) return value;

  const workOrderAsset = relatedEntity || workOrderAssetEntity[0];

  const partChargeEntities = R.values(
    filterByBehavior("PartCharge", entities),
  ).filter((e) => e.arguments.workOrderEntity === parentEntity.name);

  if (!partChargeEntities.length || !value.ui || !value.ui.related)
    return value;

  const { form } = value.ui.related;
  const { detail } = value.ui;
  const partChargeEntity = partChargeEntities[0];

  if (!form || !form[partChargeEntity.name]) return value;

  const newMainAsset =
    detail && detail.form && detail.form.assetId.id
      ? [detail.form.assetId.id]
      : [];

  const workOrderAssetIds = (form[workOrderAsset.name] || [])
    .map((r) => r.properties.assetId.id)
    .concat(newMainAsset);

  const partCharges = form[partChargeEntity.name].filter(
    (r) =>
      r.properties.id || R.includes(r.properties.assetId.id, workOrderAssetIds),
  );

  return merge4(
    "ui",
    "related",
    "form",
    partChargeEntity.name,
    partCharges,
    value,
  );
};
