import {
  BORDER_COLOR_FACTOR,
  COLOR_BLACK,
  COLOR_WHITE,
  HexColorString,
  RGBColor,
  RGBColorString,
} from "common/types/colors";
import { formatDecimal, parseDecimal } from "common/utils/decimal";

export const sanitizeColor = (color: RGBColor): RGBColor => {
  if (!color) return undefined;

  const { r = 0, g = 0, b = 0, a = 1 } = color;
  return { r, g, b, a };
};

export const hexToRgbString = (
  hex: HexColorString = "",
  opacity: number = 1,
): RGBColorString => {
  const hexNumbers = hex.replace("#", "");
  if (hexNumbers.length !== 6) return undefined;

  const r = parseInt(hexNumbers.substring(0, 2), 16);
  const g = parseInt(hexNumbers.substring(2, 4), 16);
  const b = parseInt(hexNumbers.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity})`;
};

const getRgbHex = (color: number) => color.toString(16).padStart(6, "0");

const getAlphaHex = (color: number) => color.toString(16).padStart(2, "0");

export const rgbToHexString = (color: RGBColor): HexColorString => {
  if (!color) return undefined;

  const { r, g, b, a } = sanitizeColor(color);

  const rgbHex = getRgbHex((r << 16) + (g << 8) + b);
  const alphaHex = getAlphaHex(Math.round(a * 255));

  return `#${rgbHex}${alphaHex}`;
};

const toIntPart = (color: number) => color?.toString(10) ?? 0;

const toFloatPart = (color: number) => formatDecimal(color, 2) ?? 1;

const hasAlphaChannel = (color: RGBColor) =>
  color.a !== undefined && color.a !== 1;

export const stringifyRgb = (color: RGBColor): RGBColorString => {
  if (!color) return undefined;

  const red = toIntPart(color.r);
  const green = toIntPart(color.g);
  const blue = toIntPart(color.b);

  if (hasAlphaChannel(color)) {
    const alpha = toFloatPart(color.a);
    return `rgba(${red},${green},${blue},${alpha})`;
  } else {
    return `rgb(${red},${green},${blue})`;
  }
};

const getIntPart = (color: string, defaultValue: number = 0): number => {
  const colorNumber = parseInt(color, 10);
  return isNaN(colorNumber) ? defaultValue : colorNumber;
};

const getFloatPart = (color: string, defaultValue: number = 0): number => {
  const colorNumber = parseDecimal(color);
  return isNaN(colorNumber) ? defaultValue : colorNumber;
};

export const objectifyRgb = (color: RGBColorString): RGBColor => {
  const match = color.match(
    /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+)\))?/,
  );
  if (!match) return undefined;

  const red = getIntPart(match[1], 0);
  const green = getIntPart(match[2], 0);
  const blue = getIntPart(match[3], 0);
  const alpha = getFloatPart(match[4], 1);

  return sanitizeColor({ r: red, g: green, b: blue, a: alpha });
};

/**
 * Blends two colors together using alpha channel of the foregroundColor
 * @param foregroundColor
 * @param backgroundColor
 */
export const blendColors = (
  foregroundColor: RGBColor,
  backgroundColor: RGBColor,
) => {
  const { r: r1, g: g1, b: b1, a } = sanitizeColor(foregroundColor);
  const { r: r2, g: g2, b: b2 } = sanitizeColor(backgroundColor);
  const p = 1 - a;
  return {
    r: Math.round(r1 * a + r2 * p),
    g: Math.round(g1 * a + g2 * p),
    b: Math.round(b1 * a + b2 * p),
  };
};

/**
 * Returns BLACK color for light background and WHITE color for dark background
 * Function based on {@link https://www.w3.org/TR/WCAG20/#relativeluminancedef}
 * @param backgroundColor
 */
export const getContrastyTextColor = (backgroundColor: RGBColor) => {
  const { r, g, b } = sanitizeColor(backgroundColor) || COLOR_WHITE;

  const colorPercents = [r / 255, g / 255, b / 255];
  const colorParts = colorPercents.map((c) =>
    c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4),
  );
  const relativeLuminance =
    0.2126 * colorParts[0] + 0.7152 * colorParts[1] + 0.0722 * colorParts[2];

  return relativeLuminance > 0.179 ? COLOR_BLACK : COLOR_WHITE;
};

/**
 * Get color shade based on logarithmic scale without changing alpha.
 * @param color RGBColor object
 * @param factor Negative value (e.g. -0.3) to get darker color and positive
 * (e.g. 0.3) for lighter color.
 * @returns darker or lighter color, based on the value of the percent.
 */
export const getColorShade = (color: RGBColor, factor: number): RGBColor => {
  if (factor < -1) return COLOR_BLACK;
  if (factor > 1) return COLOR_WHITE;

  const t = factor > 0 ? factor * 255 ** 2 : 0;
  const p = factor > 0 ? 1 - factor : 1 + factor;
  const getChannel = (c: number) => Math.round((p * c ** 2 + t) ** 0.5);

  const { r, g, b, a } = sanitizeColor(color) || COLOR_WHITE;

  return { r: getChannel(r), g: getChannel(g), b: getChannel(b), a: a };
};

/** Get darker color for border */
export const getBorderColor = (color: RGBColor) =>
  getColorShade(color, BORDER_COLOR_FACTOR);
