import { TranslateKey, Captions } from "common/culture/types";

export const translateDefaultCulture = (
  allCultures: boolean,
  captions: Captions,
  tx: TranslateKey,
  key: string,
) => (!allCultures && captions?.[key]) || tx?.(key) || key;

export const getUpdatedKeys = (
  keys: string[],
  allCultures: boolean,
  captions: Captions,
  translateKey: TranslateKey,
) =>
  keys?.map((key) =>
    translateDefaultCulture(allCultures, captions, translateKey, key),
  );
