import { EntityColumn } from "common/entities/entity-column/types";
import { getFunctions, AggregateType } from "common/entities/data-types";
import { Selector } from "common/widgets/selector";

interface Props {
  className?: string;
  column: EntityColumn;
  allowEmpty: boolean;
  value: string;
  onChange: (value: string) => void;
}

export const FunctionSelector = ({
  className,
  column,
  allowEmpty,
  value,
  onChange,
}: Props) => {
  const optionsList = getFunctions(column.dataType);
  const display = (option: AggregateType) => option.label;
  const aggregateType = optionsList.find((option) => option.name === value);
  const onValueChange = (option: AggregateType) => onChange(option.name);

  return (
    <Selector<AggregateType>
      className={`${className} qa-query-builder-function-dropdown`}
      options={optionsList}
      getOptionLabel={display}
      allowClear={allowEmpty}
      value={aggregateType}
      onChange={onValueChange}
    />
  );
};

FunctionSelector.displayName = "FunctionSelector";
