import { Component } from "react";
import { getLocalizedName } from "common";
import { searchApi } from "common/api/search";
import { GroupColumn } from "common/form/types";
import { LabelWidget } from "common/form/widget/label-widget";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { Properties } from "common/types/records";
import { VerticalField } from "common/ui/field";
import { arrayToString } from "common/utils/array";
import { Hint } from "common/widgets/hint";
import { ForeignKeySelector } from "common/widgets/selector/foreign-key-selector";
import { ValueProps } from "common/with-value-for";
import { EntityColumn } from "common/entities/entity-column/types";
import { isRestrictedForRole } from "common/entities/entity-column/functions";
import { getReadOnlyFieldIcon } from "common/form/widget/functions";
import { getMainFkQuery } from "./functions";

interface PropTypes extends ValueProps<Properties> {
  context: Context;
  layoutColumn: GroupColumn;
  mainFkColumnName: string;
  column: EntityColumn;
  readOnly?: boolean;
}

interface StateType {
  options: ForeignKey[];
  menuIsOpen: boolean;
  isLoading: boolean;
}

export class MainForeignKey extends Component<PropTypes, StateType> {
  state: StateType = {
    options: undefined,
    menuIsOpen: false,
    isLoading: false,
  };

  componentDidMount() {
    const { value, mainFkColumnName } = this.props;
    if (!!value[mainFkColumnName] || !!value[this.getLastLevelName()]) {
      this.fetchOptions();
    }
  }
  componentDidUpdate(prevProps: PropTypes) {
    const { value } = this.props;

    const lastLevelName = this.getLastLevelName();
    const lastLevelChanged =
      value[lastLevelName] !== prevProps.value[lastLevelName];

    if (!lastLevelChanged) return;

    // last level has value
    if (value[lastLevelName]) {
      this.fetchOptions().then((options) => {
        // only 1 possible result, sets it.
        if (options.length === 1) {
          this.onRecordChange(options[0]);
        } else if (options.length > 1) {
          this.setState({ menuIsOpen: true });
        }
      });
    } else {
      this.setState({ options: undefined });
    }
  }

  getLastLevelName = () => {
    const {
      layoutColumn: {
        lookupConfiguration: { mappedFields },
      },
    } = this.props;
    return mappedFields[mappedFields.length - 1].columnName;
  };

  fetchOptions = () => {
    const {
      context,
      value,
      layoutColumn: {
        lookupConfiguration: { mappedFields, targetEntity },
      },
    } = this.props;
    const query = getMainFkQuery(
      context.entities[targetEntity],
      mappedFields,
      value,
    );

    this.setState({ isLoading: true });
    return searchApi(context.apiCall)
      .runQueryFkExpansion(query)
      .then((options: ForeignKey[]) => {
        this.setState({ options: options, isLoading: false });
        return options;
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        throw error;
      });
  };

  onRecordChange = (fk: ForeignKey) => {
    const { value, onChange, mainFkColumnName } = this.props;
    onChange({ ...value, [mainFkColumnName]: fk });
  };

  getOptions = () => {
    const { value, mainFkColumnName } = this.props;
    const { options } = this.state;
    const mainFkValue: ForeignKey = value[mainFkColumnName];

    const selected = mainFkValue
      ? options?.find((option) => option.id === mainFkValue.id)
      : undefined;

    return mainFkValue && !selected
      ? { selected: mainFkValue, options: (options ?? []).concat(mainFkValue) }
      : { selected, options };
  };

  render() {
    const { context, layoutColumn, column, mainFkColumnName, readOnly, value } =
      this.props;
    const { menuIsOpen, isLoading } = this.state;
    const {
      hint,
      highlighted,
      required,
      lookupConfiguration: { targetEntity },
    } = layoutColumn;
    const isRequired = required || column?.required;
    const isRestricted = isRestrictedForRole(column?.roleIds, context.role);
    const hasError = isRequired && !value[mainFkColumnName];
    const className = arrayToString([
      `qa-${column?.name}`,
      highlighted ? "x-highlighted" : undefined,
    ]);

    const { selected, options } = this.getOptions();
    const disabled = !options?.length;
    return (
      <div className="x-drilldown-main-fk">
        <VerticalField
          className={className}
          label={getLocalizedName(column)}
          isRequired={isRequired}
          error={hasError}
          input={
            readOnly ? (
              <div
                className={arrayToString([
                  "x-read-only-label-wrapper",
                  hasError ? "x-has-error" : undefined,
                ])}
              >
                <div className="x-read-only-label">
                  <LabelWidget
                    context={context}
                    column={column}
                    value={value[mainFkColumnName]}
                  />
                </div>
                {isRestricted || column?.userReadOnly ? (
                  <div className="x-read-only-icon">
                    {getReadOnlyFieldIcon(column, isRestricted)}
                  </div>
                ) : undefined}
              </div>
            ) : (
              <>
                <ForeignKeySelector
                  context={context}
                  className="x-lookup"
                  entity={context.entities[targetEntity]}
                  options={options}
                  allowClear={true}
                  isLoading={isLoading}
                  placeholder={
                    disabled
                      ? _("Select the above drilldown levels...")
                      : _("Select...")
                  }
                  disabled={disabled}
                  menuIsOpen={menuIsOpen}
                  value={selected}
                  onChange={this.onRecordChange}
                />
                {hint ? <Hint message={hint} /> : undefined}
              </>
            )
          }
        />
      </div>
    );
  }
}
