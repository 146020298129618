import { Action } from "common/record/actions/types";
import { Record } from "common/types/records";
import { Entity } from "common/entities/types";
// eslint-disable-next-line import/no-cycle
import { RecordActions } from "common/record/actions";
import { Context } from "common/types/context";
import { GoFn } from "common/types/url";

interface PropTypes {
  goTo: GoFn;
  context: Context;
  reload: () => any;
  entity: Entity;
  record: Record;
  predefinedActions?: Action[];
}

export const TableActions = ({
  goTo,
  context,
  reload,
  entity,
  record,
  predefinedActions,
}: PropTypes) => (
  <div className="x-action x-table-actions">
    <RecordActions
      goTo={goTo}
      query={undefined}
      context={context}
      reload={reload}
      entity={entity}
      records={[record]}
      onTheRight={false}
      predefinedActions={predefinedActions}
      includeSwitchForm={false}
      displayType="icon"
    />
  </div>
);

TableActions.displayName = "TableActions";
