import { behaveAs, getUniqueColumn } from "common/entities";
import { Entity } from "common/entities/types";
import { Header } from "common/form/types";

export const getDefaultHeader = (entity: Entity): Header => {
  if (!entity) {
    return {
      id: undefined,
      title: undefined,
      datePrimary: "createdOn",
      dateSecondary: "updatedOn",
    };
  }

  const titleColumn = entity.columns.find(
    (c) => c.name === "description" || c.name === "description_enUS",
  );

  return {
    id: getUniqueColumn(entity)?.name,
    title: titleColumn?.name,
    datePrimary: "createdOn",
    dateSecondary: "updatedOn",
    image: behaveAs("Attachment", entity) ? "image" : "",
    subtitle:
      behaveAs("WorkOrder", entity) || behaveAs("Request", entity)
        ? "status"
        : "",
  };
};
