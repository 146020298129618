import { returnAndNotify } from "common/api/with-notifications";
import { ApiCall, CrudWithRestore } from "common/types/api";
import { FileType } from "common/types/media";
import { PrintableLabelOption } from "common/types/printable-label";
import { CancellablePromise } from "common/types/promises";
import { PrintableLabel } from "x/account-settings/printable-labels/form/types";

interface PrintableLabelsApi extends CrudWithRestore<string, PrintableLabel> {
  uploadImage: (id: string, file: FileType) => CancellablePromise<string>;
  removeImage: (id: string, fileName: string) => CancellablePromise<void>;
  listImages: (id: string) => CancellablePromise<FileType[]>;
}

export const printableLabelsApi = (apiCall: ApiCall): PrintableLabelsApi => ({
  get: (id: string) => apiCall("get", `api/ui/printable-labels/${id}`),
  list: (includeDeleted?: boolean) =>
    apiCall<PrintableLabel[]>(
      "get",
      `api/ui/printable-labels${includeDeleted ? "?includeDisabled=true" : ""}`,
    ),
  create: (label: PrintableLabel) =>
    apiCall("post", "api/ui/printable-labels", label),
  update: (label: PrintableLabel) =>
    apiCall("put", `api/ui/printable-labels/${label.id}`, label),
  remove: (id: string) => apiCall("delete", `api/ui/printable-labels/${id}`),
  restore: (id: string) =>
    apiCall("put", `api/ui/printable-labels/${id}/restore`).then(
      returnAndNotify(_("The label was successfully restored")),
    ),
  uploadImage: (id: string, file: FileType) =>
    apiCall("post", `api/ui/printable-labels/${id}/blobs`, file, {
      upload: true,
    })
      .then((response: string) =>
        CancellablePromise.resolve(JSON.parse(response)),
      )
      .then(({ name }: FileType) => {
        return `api/ui/printable-labels/${id}/blobs/${name}`;
      }),
  listImages: (id: string) =>
    apiCall<FileType[]>("get", `api/ui/printable-labels/${id}/blobs`),
  removeImage: (id: string, fileName: string) =>
    apiCall("delete", `api/ui/printable-labels/${id}/blobs/${fileName}`).then(
      returnAndNotify(_("The image was successfully deleted")),
    ),
});

export const printableLabelsListApi = (apiCall: ApiCall) => ({
  list: (entityName: string) =>
    apiCall<PrintableLabelOption[]>(
      "get",
      `api/entities/:site/${entityName}/printable-labels`,
    ),
});
