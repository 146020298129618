import * as React from "react";
import { MenuEntry } from "common/types/preferences";
import { Link } from "common/widgets/link";
import { track } from "./functions";

interface PropTypes {
  entries: MenuEntry[];
  location: string;
  explorerEntity: string;
  explorerOpen: boolean;
}

export const Menu = ({
  entries,
  explorerEntity,
  explorerOpen,
  location,
  children,
}: React.PropsWithChildren<PropTypes>) => {
  const lowerCaseLocation = location.toLocaleLowerCase();

  const selectedEntry = entries
    .filter(({ link }) => {
      const noHashLink = link.toLocaleLowerCase().replace(/^#?/, "");

      return (
        noHashLink === lowerCaseLocation.slice(0, noHashLink.length) &&
        (lowerCaseLocation.length === noHashLink.length ||
          lowerCaseLocation[noHashLink.length] === "/")
      );
    })
    // choose the longest/most specific link
    .sort(({ link: linkA }, { link: linkB }) => linkB.length - linkA.length)[0];

  return (
    <>
      {children}
      {entries.map(
        ({
          link,
          label,
          icon,
          lastViewedLink,
          canExplore,
          entityName,
          itemClass,
        }: MenuEntry) => {
          const locationActive = link === selectedEntry?.link;
          const iconCLass = canExplore && explorerOpen ? " x-explore-icon" : "";
          const explorerActive =
            locationActive &&
            explorerEntity !== undefined &&
            explorerEntity === entityName;

          return (
            <div
              key={link}
              className={
                "x-menu-item" +
                (locationActive ? " x-active" : "") +
                (explorerActive ? " x-explorer-active" : "") +
                (itemClass ? ` ${itemClass}` : "")
              }
            >
              <Link className="x-menu-item-link" href={link} title={label}>
                <i className={`fa fa-lg fa-fw ${icon}${iconCLass}`} />
                <div className="x-menu-item-label">{label}</div>
              </Link>
              {lastViewedLink ? (
                <Link
                  onClick={track(entityName)}
                  className="x-menu-item-last-link"
                  href={lastViewedLink}
                  title={_("See Last Viewed")}
                >
                  <i className="fa fa-eye" />
                </Link>
              ) : undefined}
            </div>
          );
        },
      )}
    </>
  );
};
