import { ChartData, ChartOptions } from "chart.js";
import { LazyChartjs } from "common/vendor-wrappers/chartjs";
import { ChartElement } from "common/vendor-wrappers/chartjs/types";
import { Component } from "common/component";

interface PropTypes {
  options: ChartOptions;
  data: ChartData;
  onActiveIndexChange: (index: number) => void;
}

export class MeasurementsChart extends Component<PropTypes> {
  static readonly displayName = "MeasurementsChart";

  onElementsClick = (elements: ChartElement[]) => {
    const { onActiveIndexChange } = this.props;
    if (elements && elements.length) {
      onActiveIndexChange(elements[0]._index);
    }
  };

  render() {
    const { data, options } = this.props;

    return (
      <LazyChartjs
        type="line"
        className="x-measurements-chart"
        data={data}
        options={options}
        onElementsClick={this.onElementsClick}
      />
    );
  }
}
