import * as R from "ramda";
import { getRelatedEntities } from "common/entities";
import { Entities, Entity } from "common/entities/types";
import { StandardValue } from "common/record/types";
import { getRelatedRecords, isNotDeletedRecord } from "common/record/utils";
import { Record } from "common/types/records";

const getRelatedEntityByRelation = (
  entity: Entity,
  entities: Entities,
  hasRelationFn: (mainEntity: Entity, relatedEntity: Entity) => boolean,
): Entity =>
  R.find(
    (relatedEntity) => hasRelationFn(entity, relatedEntity),
    getRelatedEntities(entity, entities),
  );

export const getRelatedRecordsByRelation = (
  entity: Entity,
  entities: Entities,
  value: StandardValue,
  hasRelationFn: (mainEntity: Entity, relatedEntity: Entity) => boolean,
): Record[] => {
  const relatedEntity = getRelatedEntityByRelation(
    entity,
    entities,
    hasRelationFn,
  );
  if (!relatedEntity) return [];

  const { ui, record } = value;
  const uiRelated = ui?.related?.form;
  return getRelatedRecords(relatedEntity.name, record, uiRelated).filter(
    isNotDeletedRecord,
  );
};
