import { returnAndNotify } from "common/api/with-notifications";
import { ApiCall, CrudWithRestore } from "common/types/api";
import { Pin } from "common/vendor-wrappers/leaflet/types";
import { VisualTemplate } from "x/account-settings/visual-templates/types";

export const visualTemplatesApi = (
  apiCall: ApiCall,
): CrudWithRestore<number, VisualTemplate> => ({
  get: (id: number) => apiCall("get", `api/admin/visual-templates/${id}`),
  list: (includeDeleted?: boolean) =>
    apiCall<VisualTemplate[]>(
      "get",
      `api/admin/visual-templates${
        includeDeleted ? "?includeDisabled=true" : ""
      }`,
    ),
  create: (label: VisualTemplate) =>
    apiCall("post", "api/admin/visual-templates", label),
  update: (label: VisualTemplate) =>
    apiCall("put", `api/admin/visual-templates/${label.id}`, label),
  remove: (id: number) => apiCall("delete", `api/admin/visual-templates/${id}`),
  restore: (id: number) =>
    apiCall("put", `api/admin/visual-templates/${id}/restore`).then(
      returnAndNotify(_("The visual template was successfully restored")),
    ),
});

export const visualTemplatesUIApi = (apiCall: ApiCall) => ({
  list: () => apiCall<VisualTemplate[]>("get", "api/ui/visual-templates"),
  previewPins: (pins: Pin[], templateId: number, entityName: string) =>
    apiCall<{ pins: Pin[]; templateId: number }>(
      "post",
      `/api/entities/:site/${entityName}/preview-pins`,
      {
        pins,
        templateId,
      },
    ),
});
