import { returnAndNotify } from "common/api/with-notifications";
import { DocumentsScope } from "common/record/form/content/media/documents-scope";
import { ApiCall } from "common/types/api";
import { FileType } from "common/types/media";
import { CancellablePromise } from "common/types/promises";

export const getMediaUrl = (
  site: string,
  entityName: string,
  recordId: string,
  fileName: string,
  versionId?: string,
) =>
  ["/api/entities", site, entityName, recordId, "blobs", fileName]
    .join("/")
    .concat(versionId ? `?versionId=${versionId}` : "");

export interface HeaderResponse {
  contentType: string;
  status: number;
}

export const urlHeaderJsonpCall = (url: string) => {
  const ajaxCall = new XMLHttpRequest();

  const promise = new Promise<void>((resolve) => {
    ajaxCall.onreadystatechange = function () {
      if (this.readyState !== 4) return;

      if (this.status === 200) {
        resolve();
      } else {
        resolve();
      }
    };

    ajaxCall.open("HEAD", url);
    ajaxCall.setRequestHeader("dataType", "jsonp");
    ajaxCall.send();
  }).catch((e) => {
    throw e;
  });

  return new CancellablePromise<void>(promise, () => ajaxCall.abort());
};

// This call responds the content type of external urls for media fields
export const urlHeaderCall = (url: string) => {
  const ajaxCall = new XMLHttpRequest();

  const promise = new Promise<HeaderResponse>((resolve, reject) => {
    ajaxCall.onreadystatechange = function () {
      if (this.readyState !== 4) return;

      if (this.status === 200) {
        resolve({
          contentType: this.getResponseHeader("Content-Type"),
          status: this.status,
        });
      } else {
        reject();
      }
    };

    ajaxCall.open("HEAD", url);
    ajaxCall.setRequestHeader("mode", "no-cors");
    ajaxCall.send();
  }).catch((e) => {
    throw e;
  });

  return new CancellablePromise<HeaderResponse>(promise, () =>
    ajaxCall.abort(),
  );
};

export const mediaApi = (apiCall: ApiCall) => {
  const list = (entity: string, recordId: string, scope?: DocumentsScope) =>
    apiCall<FileType[]>(
      "get",
      `api/entities/:site/${entity}/${recordId}/blobs${scope ? `?scope=${scope}` : ""}`,
    );

  const upload = (entity: string, recordId: string, file: FileType) =>
    apiCall("post", `api/entities/:site/${entity}/${recordId}/blobs`, file, {
      upload: true,
    }).then((response: string) =>
      CancellablePromise.resolve(JSON.parse(response)),
    );

  const setAsDefault = (entity: string, recordId: string, image: string) =>
    apiCall("put", `api/entities/:site/${entity}/${recordId}`, {
      properties: { image },
    });

  const uploadAndGetUrl = (
    site: string,
    entity: string,
    recordId: string,
    file: FileType,
  ) =>
    upload(entity, recordId, file).then((returnData: FileType) => {
      const { name } = returnData;
      return getMediaUrl(site, entity, recordId, name);
    });

  const uploadAndSetAsDefault = (
    site: string,
    entity: string,
    recordId: string,
    file: FileType,
  ) =>
    uploadAndGetUrl(site, entity, recordId, file).then((imageUrl: string) => {
      return setAsDefault(entity, recordId, imageUrl).then(() => imageUrl);
    });

  const uploadCsv = (entity: string, file: FileType) =>
    apiCall("post", `api/entities/:site/${entity}/blobs/uploadcsv`, file, {
      upload: true,
    });

  const remove = (entity: string, recordId: string, fileName: string) =>
    apiCall(
      "delete",
      `api/entities/:site/${entity}/${recordId}/blobs/${fileName}`,
    ).then(returnAndNotify(_("The file was successfully deleted")));

  const isDocumentUsed = (entity: string, id: string, attachment: string) =>
    apiCall<{ isUsed: boolean; message: string }>(
      "post",
      `api/entities/:site/${entity}/${id}/blobs/is-used`,
      attachment,
    );

  const getContentType = (url: string) => urlHeaderCall(url);

  const isUrlReachable = (url: string) => urlHeaderJsonpCall(url);

  return {
    list,
    upload,
    uploadAndGetUrl,
    setAsDefault,
    uploadAndSetAsDefault,
    uploadCsv,
    remove,
    isUrlReachable,
    getContentType,
    isDocumentUsed,
  };
};
