import * as R from "ramda";
import { getLocalizedName } from "common";
import { filterEntities } from "common/entities";
import { getOperators } from "common/entities/data-types";
import {
  isDateTimeColumn,
  isFkToEntityColumn,
} from "common/entities/entity-column/functions";
import { EntityColumn } from "common/entities/entity-column/types";
import {
  isDateTimeOperator,
  Operator as OperatorMetadata,
  SubQueryOperator,
} from "common/entities/operators";
import { Entities, Entity } from "common/entities/types";
import {
  Filter,
  FilterAnd,
  FilterOr,
  FilterRule,
  FilterSubQueryRule,
  GroupFilter,
  isAnd,
  isExpressionRule,
  SubQueryScope,
} from "common/query/types";
import { getAvailableJoins, isOneToOne } from "../joins/functions";
import { Field, Operator } from "../types";

export const VALUE_FROM_COLUMN_ALIAS: string = "T";

export type SubQuery = {
  scopeFilter: FilterRule;
  subFilter: GroupFilter;
};

export const createRuleFor = (field: Field, op: string): FilterRule => ({
  path: field?.path || undefined,
  name: field?.column.name,
  op,
  expression: field ? undefined : "",
});

/**
 * Returns valid operators for a given field's type, ex. `contains` for string
 * data type, `dayeq` for date data type, and so on.
 * @param field
 */
export const getColumnOperators = (field: Field) =>
  getOperators(field?.column?.dataType, field?.column?.required);

/**
 * Returns valid operators for a rule when a function is selected, ex. COUNT.
 * At the moment all available aggregation functions return numeric values
 * thus all the operators have to be numeric based.
 */
export const getAggregateFnOperators = () => getOperators("int", true);

export const getSiteOperators = () => getOperators("fk", true);

export const getExpressionOperators = () => getOperators("string", true);

export const getOperatorByName = <T extends OperatorMetadata>(
  operators: T[],
  operatorName: string,
) => R.find((o) => o.name === operatorName, operators || []);

export const getRuleOperators = (field: Field, value: FilterRule) => {
  if (field?.column?.name === "site") return getSiteOperators();

  // The field check is occasioned by the FieldSelector component
  // As we are returning 'undefined' if Custom Expression is selected
  if (!field || isExpressionRule(value)) return getExpressionOperators();
  return value.fn ? getAggregateFnOperators() : getColumnOperators(field);
};

export const getValidOperator = (field: Field, value: FilterRule) => {
  const operators = getRuleOperators(field, value);
  return getOperatorByName(operators, value.op) || operators[0];
};

export const operators: Operator[] = ["and", "or"];

export const displayOperator = (o: Operator) =>
  o === "and" ? _("AND") : _("OR");

export const defaultAnd: FilterAnd = { and: [] };

export const getOp = (value: GroupFilter = defaultAnd): Operator =>
  isAnd(value) ? "and" : "or";

export const getAndOr = (value: GroupFilter) =>
  (isAnd(value) ? value.and : value.or) || [];

export const getFirstField = (fields: Field[]): Field =>
  R.sortBy(
    (f: Field) =>
      `${f.minPath}/${R.toLower(R.trim(getLocalizedName(f.column)))}`,
    fields,
  )[0];

export const setGroupFor =
  (setValue: (v: GroupFilter) => any) => (op: Operator, filters: Filter[]) =>
    setValue({ [op]: filters } as unknown as FilterAnd | FilterOr);

export const displayComparisonOperator = (o: SubQueryOperator) => o.label;

const getFkBetween = (entity: Entity, parentEntityName: string) =>
  entity?.columns.find((c) => isFkToEntityColumn(parentEntityName)(c));

export const buildScopeSubQueryCondition = (
  entity: Entity,
  parentEntityName: string,
  scope: SubQueryScope,
): FilterRule => {
  switch (scope) {
    case "anySite":
      return undefined;
    case "currentSite":
      return {
        name: "site",
        valueFromColumn: { name: "site", alias: VALUE_FROM_COLUMN_ALIAS },
        op: "eq",
      };
    case "related":
    default: {
      const foreignKey = getFkBetween(entity, parentEntityName);
      return foreignKey
        ? {
            name: foreignKey.name,
            valueFromColumn: { name: "id", alias: VALUE_FROM_COLUMN_ALIAS },
            op: "eq",
          }
        : undefined;
    }
  }
};

export const getEntitiesForSubQueryFilter = (
  entities: Entities,
  entityName: string,
  scope: SubQueryScope,
): Entities => {
  switch (scope) {
    case "anySite":
      return entities;
    case "currentSite": {
      const isSingleSiteEntity = (entity: Entity) =>
        entity.recordScope === "SingleSite";
      return filterEntities(entities, isSingleSiteEntity);
    }
    case "related":
    default: {
      const parentEntity = entities[entityName];
      const availableJoins = getAvailableJoins(
        entities,
        entityName,
        parentEntity.joins,
      );

      const oneToManyEntities: Entities = availableJoins.reduce((acc, join) => {
        if (isOneToOne(join)) return acc;

        const { entity } = join;
        return { ...acc, [entity.name]: entity };
      }, {});

      return oneToManyEntities;
    }
  }
};

export const defaultSubQueryScope: SubQueryScope = "related";
export const allSubQueryScopes: SubQueryScope[] = [
  "related",
  "currentSite",
  "anySite",
];

export const displaySubQueryFilterScope = (scope: SubQueryScope) => {
  switch (scope) {
    case "anySite":
      return _("Records at Any Site");
    case "currentSite":
      return _("Records at Current Site");
    case "related":
    default:
      return _("Related Records Only");
  }
};

export const parseSubQuery = (filterRule: FilterSubQueryRule): SubQuery => {
  const { scope = defaultSubQueryScope, queryValue } = filterRule;
  const { filter } = queryValue;

  if (!filter) return { scopeFilter: undefined, subFilter: defaultAnd };

  switch (scope) {
    case "related":
    case "currentSite": {
      const conditions = isAnd(filter) ? filter.and : filter.or;
      const [scopeFilter, subFilter, _] = conditions;
      return {
        scopeFilter: scopeFilter as FilterRule,
        subFilter: subFilter as GroupFilter,
      };
    }
    case "anySite":
    default:
      return { scopeFilter: undefined, subFilter: filter };
  }
};

const getMainEntityAlias = (entityName: string) =>
  `${entityName} (${_("MAIN")})`;

export const mapToMainEntityField = (field: Field) => ({
  ...field,
  minPath: getMainEntityAlias(field.minPath),
  path: "main",
});

export const getColumnDataType = (column: EntityColumn, op: string) =>
  isDateTimeColumn(column) && !isDateTimeOperator(op)
    ? "date"
    : column.dataType;
