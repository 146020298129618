import { cycleCountApi } from "common/api/cycle-count";
import { mergeChain } from "common/merge";
import { getActionDisabledMessageWhenEditing } from "common/query/table/functions";
import { ActionWithContent } from "common/query/table/types";
import { SubmitActionContent } from "common/record/form/content/related/parts-to-be-counted/submit-action-content";
import { StandardRelated } from "common/record/form/content/related/standard-related";
import { PropTypes } from "common/record/form/content/related/table-with-form/types";
import { RelatedValue } from "common/record/form/content/related/types";
import { Reload } from "common/record/types";
import { Properties } from "common/types/records";
import { ValueProps } from "common/with-value-for";
import {
  CANCELED_STATUS_ID,
  COMPLETED_STATUS_ID,
  isRecordDisabled,
  searchDefaultLocation,
  showDisabledRecordsWarning,
  updatePartsToBeAddedContext,
} from "common/record/form/content/related/parts-to-be-counted/functions";
import { AlertWarning } from "common/widgets/alert";
import { hasPermissionTo } from "common/functions/roles";

type Props = PropTypes & {
  reload: Reload;
} & ValueProps<RelatedValue>;

export const PartsToBeCounted = (props: Props) => {
  const { context, entity, reload, recordDetail, value, onChange } = props;
  const { entities, userTypes, role } = context;
  const updatedContext = updatePartsToBeAddedContext(context, value, entity);
  const isFormDirty = recordDetail?.isDirty || value?.related?.isDirty;

  const getPartsToBeCountedFormValue = (value: RelatedValue) =>
    value?.related?.partialForm?.[entity.name];

  const hasPartChanged = (oldProps: Properties, newProps: Properties) => {
    const newPartId: string = newProps?.partId?.id;
    return (
      oldProps?.id === newProps?.id &&
      newPartId &&
      oldProps?.partId?.id !== newPartId
    );
  };

  const setPartialForm = (eventValue: RelatedValue, props: Properties) => {
    const mergedProps = mergeChain(eventValue)
      .prop("related")
      .prop("partialForm")
      .set(entity.name, props)
      .output();

    onChange(mergedProps);
  };

  const updatePartProperties = (
    newProps: Properties,
    eventValue: RelatedValue,
  ) => {
    const newPartId: string = newProps?.partId?.id;
    return searchDefaultLocation(context, entity, value, newPartId).then(
      (defaultPartLocation) => {
        const props: Properties = {
          ...newProps,
          partLocationId: defaultPartLocation,
        };

        return setPartialForm(eventValue, props);
      },
    );
  };

  const onChangeValue = (eventValue: RelatedValue) => {
    const oldProps = getPartsToBeCountedFormValue(value);
    const newProps = getPartsToBeCountedFormValue(eventValue);

    if (hasPartChanged(oldProps, newProps)) {
      return updatePartProperties(
        {
          ...newProps,
          ...(oldProps?.partId // part "really" changed. partLocation then is reset
            ? { partLocationId: undefined }
            : {}),
        },
        eventValue,
      );
    }

    return onChange(eventValue);
  };

  const runSubmitAction = (line: Properties, close: () => void) => {
    return cycleCountApi(context.apiCall)
      .getPartsDetails(entity.arguments.cycleCountEntity, line.cycleCountId)
      .then((partsDetails = []) => {
        const partDetails = partsDetails.find(
          (pd) => pd.partsToBeCountedId === line.id,
        );
        const onHand = partDetails?.onHand ?? 0;
        const manualBatchSelection = partDetails?.manualBatchSelection ?? false;
        const isInputRequired =
          manualBatchSelection || line.finalCount > onHand;

        return isInputRequired ? (
          <SubmitActionContent
            context={context}
            onSubmit={reload}
            onCancel={close}
            entity={entity}
            recordId={line.id}
            properties={line}
            onHand={onHand}
            lastCost={partDetails?.lastCost}
            manualBatchSelection={manualBatchSelection}
          />
        ) : (
          cycleCountApi(context.apiCall)
            .submit(entity.name, line.id, { finalCount: line?.finalCount })
            .then(reload)
            .catch(() => {
              // Error is handled by the API call function
              return undefined;
            })
        );
      });
  };

  const runCancelAction = (line: Properties) => {
    return cycleCountApi(context.apiCall)
      .cancel(entity.name, line.id)
      .then(reload)
      .catch(() => {
        // Error is handled by the API call function
        return undefined;
      });
  };

  const hasPermissionToSubmit = hasPermissionTo(
    userTypes,
    role,
    entity.name,
    "SubmitPartCount",
  );

  const hasPermissionToCancel = hasPermissionTo(
    userTypes,
    role,
    entity.name,
    "CancelPartCount",
  );

  const actionsWithContent: ActionWithContent[] = [
    ...(hasPermissionToSubmit
      ? [
          {
            name: "SubmitPartCount",
            fn: runSubmitAction,
            isDisabled: (line: Properties) =>
              isFormDirty || line.finalCount == null,
            isHidden: (line: Properties) =>
              line.statusId?.id === CANCELED_STATUS_ID ||
              line.statusId?.id === COMPLETED_STATUS_ID,
            disabledMessage: isFormDirty
              ? getActionDisabledMessageWhenEditing()
              : _("Please provide the final count before submitting"),
          },
        ]
      : []),
    ...(hasPermissionToCancel
      ? [
          {
            name: "CancelPartCount",
            fn: runCancelAction,
            isDisabled: () => isFormDirty,
            isHidden: (line: Properties) =>
              line.statusId?.id === COMPLETED_STATUS_ID ||
              line.statusId?.id === CANCELED_STATUS_ID,
            disabledMessage: getActionDisabledMessageWhenEditing(),
          },
        ]
      : []),
  ];

  const disableRecordsWarning = showDisabledRecordsWarning(value, entity) ? (
    <AlertWarning
      message={_("Records that are submitted cannot be edited or archived.")}
    />
  ) : undefined;

  return (
    <StandardRelated
      {...props}
      actionsWithContent={actionsWithContent}
      isRecordDisabled={isRecordDisabled}
      warning={disableRecordsWarning}
      context={updatedContext}
      entity={entities[entity.name]}
      onChange={onChangeValue}
    />
  );
};
