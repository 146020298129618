import { defaultFor } from "common";
import { Labels } from "common/culture/types";
import { Context } from "common/types/context";
import { Dashboard, Widget } from "common/types/dashboards";
import { GoFn } from "common/types/url";
import { LinkWidget } from "../widgets/link";
import { ReportController } from "../widgets/report-controller";
import { getLabel, isLinkConfig, isReportConfig } from "./functions";

interface DashboardWidgetProps {
  context: Context;
  widget: Widget;
  reload: () => any;
  goTo: GoFn;
  notOlderThan: number;
  labels: Labels;
  index: number;
}

export const DashboardWidget = ({
  context,
  reload,
  goTo,
  notOlderThan,
  labels,
  widget,
  index,
}: DashboardWidgetProps) => {
  const { label, name, type, config } = widget || defaultFor<Widget>();
  const { uiFormat } = context;
  const labelKey = `widget.${index}`;
  const widgetLabel = getLabel(labels, label, labelKey, uiFormat.culture);

  if (type === "Report" && isReportConfig(config)) {
    return (
      <ReportController
        context={context}
        key={index}
        widgetLabel={widgetLabel}
        reportConfig={config}
        reload={reload}
        goTo={goTo}
        notOlderThan={notOlderThan}
      />
    );
  }
  if (type === "Link" && isLinkConfig(config)) {
    const { label, url } = config;
    return (
      <LinkWidget
        key={index}
        widgetLabel={widgetLabel || name}
        linkLabel={label}
        linkUrl={url}
      />
    );
  }
  return (
    <div key={index} className="x-dashboard-widget x-padding-10">
      <div className="bg-danger x-padding-10 text-center">
        {_("Widget not found")}
      </div>
    </div>
  );
};
DashboardWidget.displayName = "DashboardWidget";

interface DashboardViewProps {
  context: Context;
  dashboard: Dashboard;
  goTo: GoFn;
  reload: () => any;
  notOlderThan: number;
}

export const DashboardView = ({
  context,
  dashboard,
  goTo,
  reload,
  notOlderThan,
}: DashboardViewProps) => {
  const { sites = [] } = context;
  const { widgets = [], labels } = dashboard || defaultFor<Dashboard>();
  const siteClass = sites.length > 1 ? "qa-group-site" : "qa-single-site";

  return (
    <div className={`x-dashboard-content ${siteClass}`}>
      {widgets.length &&
        widgets.map((w, i) => (
          <DashboardWidget
            context={context}
            goTo={goTo}
            reload={reload}
            notOlderThan={notOlderThan}
            labels={labels}
            widget={w}
            index={i}
            key={i}
          />
        ))}
    </div>
  );
};

DashboardView.displayName = "DashboardView";
