import { Record } from "common/types/records";
import { Tabs } from "common/widgets/tabs/tabs";
import { Tab } from "common/widgets/tabs/tab";
import { SignatureImage } from "common/widgets/signature/image";
import { SIGNATURE } from "common/record/utils";

interface PropTypes {
  value: Record;
}

export const RecordSignature = ({ value }: PropTypes) => (
  <Tabs key="signature-tabs">
    <Tab value={SIGNATURE} label={_("Signature")}>
      <SignatureImage value={value.properties.signature} />
    </Tab>
  </Tabs>
);
