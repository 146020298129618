import { Component } from "react";
import {
  getHourFromDate,
  getMiddayIndicator,
  setHour,
} from "common/date-time/common";
import { UTCDateTime } from "common/date-time/types";
import { ValueProps } from "common/with-value-for";

export class AmPmButton extends Component<ValueProps<UTCDateTime>> {
  static readonly displayName = "AmPmButton";

  toggleAmOrPm = () => {
    const { value, onChange } = this.props;
    const hourValue = getHourFromDate(value);
    if (hourValue) {
      const newTime = hourValue + (hourValue < 12 ? 12 : -12);
      onChange(setHour(value, newTime));
    }
  };

  render() {
    return (
      <button
        type="button"
        className="x-am-pm x-no-select am-pm"
        onClick={this.toggleAmOrPm}
      >
        {getMiddayIndicator(this.props.value)}
      </button>
    );
  }
}
