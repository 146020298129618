import { getLocalizedName } from "common";
import { Entity } from "common/entities/types";
import { RelatedEntityInOverview } from "common/form/types/overview-related";
import { shouldDisplayForm } from "common/functions/related-form-display";
import { RelatedValue } from "common/record/form/content/related/types";
import { Reload } from "common/record/types";
import { RelatedFormDisplayTypes } from "common/types/related-form-display";
import { ValueProps } from "common/with-value-for";
import { GoFn } from "common/types/url";
import { RelatedRecordsForm } from "../related";
import { Section } from "./view/section-group";

interface PropTypes extends ValueProps<RelatedValue> {
  entity: Entity;
  relatedEntitiesInOverview: RelatedEntityInOverview[];
  isTemplate: boolean;
  reload: Reload;
  displayTypes?: RelatedFormDisplayTypes;
  goTo?: GoFn;
}

export const OverviewRelatedRecords = ({
  entity,
  reload,
  isTemplate,
  relatedEntitiesInOverview,
  displayTypes,
  value,
  goTo,
  onChange,
}: PropTypes) => (
  <>
    {relatedEntitiesInOverview?.map((relatedEntity) => {
      const relatedRecordsForm = (
        <RelatedRecordsForm
          key={relatedEntity.name}
          isTemplate={isTemplate}
          context={relatedEntity.context}
          displayTypes={displayTypes}
          entity={relatedEntity.entity}
          entityConfig={relatedEntity}
          parentEntity={entity}
          recordDetail={undefined}
          recordId={value?.record?.properties.id}
          reload={reload}
          goTo={goTo}
          value={value}
          onChange={onChange}
        />
      );

      return shouldDisplayForm(displayTypes) ? (
        relatedRecordsForm
      ) : (
        <Section
          key={relatedEntity.name}
          title={getLocalizedName(relatedEntity.entity)}
        >
          {relatedRecordsForm}
        </Section>
      );
    })}
  </>
);

OverviewRelatedRecords.displayName = "OverviewRelatedRecords";
