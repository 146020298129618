import { PropsWithChildren, JSX } from "react";

import "./index.scss";

interface PropTypes {
  message: string | JSX.Element;
  className?: string;
  hideLabel?: boolean;
}

export const Hint = ({
  message,
  className = "",
  hideLabel = false,
}: PropsWithChildren<PropTypes>) => (
  <div className={`x-hint x-flex-start-start ${className}`}>
    <p className="x-margin-0">
      {hideLabel ? undefined : `${_("Hint")}: `}
      {message}
    </p>
  </div>
);

Hint.displayName = "Hint";
