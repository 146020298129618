import { noOp } from "common";
import type { Entity } from "common/entities/types";
import type { Context } from "common/types/context";
import type { ApiErrorResponse } from "common/types/error";
import type { AssignmentEvent } from "common/types/scheduler";
import { ActionButton } from "common/ui/buttons";
import { DropdownMenu } from "common/widgets/dropdown-menu";
import { Warning } from "common/widgets/warning";
import { useConfirmation } from "common/widgets/warning/use-confirmation";
import {
  publishAll,
  publishAssignmentsBySite,
} from "x/scheduler2/planner/header/functions";
import type { ViewType } from "x/scheduler2/types";

interface PropTypes {
  context: Context;
  view: ViewType;
  assignmentsEntities: Entity[];
  unpublishedEvents: AssignmentEvent[];
  reload: () => void;
  onError: (error: ApiErrorResponse) => void;
}

export const PublishButton = ({
  context,
  assignmentsEntities,
  view,
  unpublishedEvents,
  reload,
  onError,
}: PropTypes) => {
  const { onClose, onConfirm, isOpen, onOpen } = useConfirmation();

  const confirmation = isOpen ? (
    <Warning
      title={_("Publish all pending events in the current site")}
      content={_("Are you sure?")}
      action1={_("Cancel")}
      action2={_("Yes")}
      onAction1={onClose}
      onAction2={onConfirm}
    />
  ) : null;

  const openAndPublishAllWhenResolved = () =>
    onOpen()
      .then(() =>
        publishAll(context, assignmentsEntities).catch(onError).then(reload),
      )
      .catch(noOp);

  if (!unpublishedEvents.length)
    return (
      <>
        {confirmation}
        <ActionButton
          className="qa-publish-all"
          title={_("Publish all")}
          onClick={openAndPublishAllWhenResolved}
        >
          {_("Publish all")}
        </ActionButton>
      </>
    );

  const publishView = () =>
    publishAssignmentsBySite(unpublishedEvents, context)
      .catch(onError)
      .then(reload);

  return (
    <>
      {confirmation}
      <DropdownMenu
        className="qa-publish-selector"
        button={
          <ActionButton>
            {_("Publish")} <i className="fa fa-caret-down" />
          </ActionButton>
        }
        menu={
          <>
            <a className="dropdown-item qa-publish-view" onClick={publishView}>
              {view === "day"
                ? _("Publish day")
                : view === "week"
                  ? _("Publish week")
                  : _("Publish month")}
            </a>

            <a
              className="dropdown-item qa-publish-all"
              onClick={openAndPublishAllWhenResolved}
            >
              {_("Publish all")}
            </a>
          </>
        }
      />
    </>
  );
};
