import * as R from "ramda";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { Properties } from "common/types/records";
import { isRangeValid } from "common/date-time/validators";
import { behaveAs } from "common/entities";
import { updateColumn } from "common/entities/entity-column/functions";
import { Entities, Entity } from "common/entities/types";
import { mapLayout } from "common/form/functions/layout";
import { GroupColumn } from "common/form/types";
import { merge2 } from "common/merge";
import { QueryForEntity } from "common/query/types";
import { updateContextForms } from "../common/functions";

export const getRateEntity = (entities: Entities, chargeEntity: Entity) => {
  const { targetEntity, rateTypeEntity } = chargeEntity.arguments;
  const entityList = Object.values(entities);

  return entityList.find(
    (entity) =>
      behaveAs("Rate", entity) &&
      entity.arguments.targetEntity === targetEntity &&
      entity.arguments.rateTypeEntity === rateTypeEntity,
  );
};

/**
 * Checks if the quantity field should be recalculated.
 * @param oldForm
 * @param newForm
 */
export const shouldComputeQty = (
  oldForm: Properties,
  newForm: Properties,
): boolean =>
  !!newForm &&
  (!oldForm ||
    oldForm.rangeFrom !== newForm.rangeFrom ||
    oldForm.rangeTo !== newForm.rangeTo) &&
  isRangeValid(newForm.rangeFrom, newForm.rangeTo);

/**
 * Checks if the rangeTo field should be recalculated.
 * @param oldForm
 * @param newForm
 */
export const shouldComputeRangeTo = (
  oldForm: Properties,
  newForm: Properties,
): boolean =>
  !!newForm?.rangeFrom &&
  !!newForm?.quantity &&
  (!oldForm ||
    oldForm.quantity !== newForm.quantity ||
    oldForm.rangeFrom !== newForm.rangeFrom);

export const getFetchRateValueQuery = (
  rateEntityName: string,
  targetId: string,
  targetEmail: string,
  rateTypeId: string,
): QueryForEntity => {
  const targetFilter = targetId
    ? { path: "/targetId", name: "id", op: "eq", value: targetId }
    : { path: "/targetId", name: "email", op: "eq", value: targetEmail };

  return {
    entity: rateEntityName,
    query: {
      select: [{ name: "value" }],
      joins: [{ column: "targetId" }],
      order: [{ name: "createdOn", desc: true }],
      filter: {
        and: [
          { name: "isDeleted", op: "isfalse" },
          targetFilter,
          { name: "rateTypeId", op: "eq", value: rateTypeId },
        ],
      },
    },
  };
};

const idFilter = (rateTypeIds: string[]) => (fk: ForeignKey) =>
  rateTypeIds.includes(fk.id);

const updateRateTypeColumn = (ids: string[]) => (col: GroupColumn) => {
  return col.columnName === "rateTypeId"
    ? {
        ...col,
        referenceFilter:
          ids?.length > 0
            ? idFilter(ids) // keeps only the rateTypes the user has
            : R.F, // omits all rateTypes
      }
    : col;
};

export const updateLaborChargeContext = (
  context: Context,
  entity: Entity,
  rateTypeIds: string[],
) => {
  const newEntity = updateColumn("rateTypeId", {
    disableQuickAddRelated: true,
    ...(rateTypeIds?.length === 0
      ? {
          hint: _(
            "The selected contact does not have labor rates registered. Please contact your administrator",
          ),
        }
      : {}),
  })(entity);

  const newContext = merge2("entities", entity.name, newEntity, context);

  return updateContextForms(newContext, entity.name, (f) => ({
    ...f,
    settings: mapLayout(updateRateTypeColumn(rateTypeIds), f.settings),
  }));
};
