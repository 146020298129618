import { Context } from "common/types/context";
import { LoadingIcon } from "common/widgets/loading-icon";
import { LabelWidget } from "common/form/widget/label-widget";
import { getColumn } from "common/entities";

interface PropTypes {
  data: any[];
  value: string;
  label: string;
  isCurrency: boolean;
  context: Context;
  entityName: string;
}

export const Box = ({
  data,
  isCurrency,
  context,
  label,
  value,
  entityName,
}: PropTypes) => {
  const { currency } = context;

  const entity = context?.entities?.[entityName];
  const column = getColumn(entity, value);
  const isCurrencyColumn = column?.dataType === "currency";

  if (!data) return <LoadingIcon />;
  if (!value)
    return (
      <div className="bg-danger x-padding-10">
        {_("Select a column in Values")}
      </div>
    );

  const results = (data.length && data[0][value]) || "";

  return (
    <div className="x-data-box">
      {results ? (
        <h3 className="x-results">
          {isCurrency && !isCurrencyColumn ? (
            <span>{currency.symbol}&nbsp;</span>
          ) : (
            ""
          )}
          {
            <LabelWidget
              context={context}
              column={column}
              withLinks={false}
              value={results}
            />
          }{" "}
        </h3>
      ) : label ? (
        <h3 className="x-results">-</h3>
      ) : (
        <h3 className="x-no-results">{_("No results")}</h3>
      )}
      {label ? <p className="x-label text-primary">{label}</p> : undefined}
    </div>
  );
};
Box.displayName = "Box";
