import { ApiCall } from "common/types/api";
import {
  LiveAssetComponent,
  LiveAsset,
} from "common/record/actions/ui/live-asset-settings/types";

export type AssetDetailsWithComponents = {
  id: string;
  name: string;
  components: LiveAssetComponent[];
  description?: string;
};

export const liveAssetPortalApi = (apiCall: ApiCall) => ({
  getAssets: (searchKey: string, limit?: number) => {
    const params = [
      `searchKey=${searchKey}`,
      limit ? `limit=${limit}` : undefined,
    ]
      .filter((p) => !!p)
      .join("&");

    return apiCall<LiveAsset[]>(
      "get",
      `api/live-asset-portal/assets?${params}`,
    );
  },
  getAssetWithComponents: (assetId: string) =>
    apiCall<AssetDetailsWithComponents>(
      "get",
      `api/live-asset-portal/assets/${assetId}`,
    ),
  getComponent: (assetId: string, componentId: string) =>
    apiCall<LiveAssetComponent>(
      "get",
      `api/live-asset-portal/assets/${assetId}/components/${componentId}`,
    ),
});
