import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { Event } from "common/types/events";
import { EventDetail } from "./event-detail";
import { EventList } from "./event-list";

interface PropTypes {
  selectedEvent: Event;
  events: Event[];
  entity: Entity;
  context: Context;
  loading: boolean;
  onShowMore: () => void;
  isLastPage: boolean;
  recordId: string;
  hideLinks?: boolean;
  getUrl?: (entity: Entity, site: string) => string;
}

export const AuditTrail = ({
  selectedEvent,
  events = [],
  entity,
  context,
  loading,
  onShowMore,
  isLastPage,
  recordId,
  getUrl,
  hideLinks,
}: PropTypes) => {
  const entityName = selectedEvent?.details?.entityName;
  const eventDetailEntity = entityName ? context.entities[entityName] : entity;

  return (
    <div className="x-audit-trail">
      <EventList
        context={context}
        entityName={entity.name}
        events={events}
        loading={loading}
        onShowMore={onShowMore}
        isLastPage={isLastPage}
        recordId={recordId}
        selectedEvent={selectedEvent}
        getUrl={getUrl}
      />
      <EventDetail
        event={selectedEvent}
        entity={eventDetailEntity}
        context={context}
        hideLinks={hideLinks}
      />
    </div>
  );
};

AuditTrail.displayName = "AuditTrail";
