import { getColumn } from "common/entities";
import { Entity } from "common/entities/types";
import { LabelWidget } from "common/form/widget/label-widget";
import { Context } from "common/types/context";
import { PoDetails } from "common/types/purchase-orders";
import { VerticalField } from "common/ui/field";

export interface PropTypes {
  context: Context;
  entity: Entity;
  details: PoDetails;
}

export const Header = ({ context, entity, details }: PropTypes) => (
  <div className="x-flex-around-center">
    <VerticalField
      label={_("Purchase Order Number")}
      input={
        <LabelWidget
          context={context}
          withLinks={true}
          column={getColumn(entity, "purchaseOrderNumber")}
          value={details.purchaseOrderNumber}
        />
      }
    />
    <VerticalField
      label={_("Supplier")}
      input={
        <LabelWidget
          context={context}
          withLinks={true}
          column={getColumn(entity, "supplierId")}
          value={details.supplierId}
        />
      }
    />
    <VerticalField
      label={_("Created")}
      input={
        <LabelWidget
          context={context}
          withLinks={true}
          column={getColumn(entity, "createdOn")}
          value={details.createdOn}
        />
      }
    />
  </div>
);
