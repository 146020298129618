import * as R from "ramda";
import { defaultFor } from "common";
import { searchApi } from "common/api/search";
import { ColumnTypes, EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { LookupQuery } from "common/form/types";
import { shouldDisplayForm } from "common/functions/related-form-display";
import { getIntFkId } from "common/functions/system-int";
import { roundWithPrecision } from "common/math";
import { merge1, merge3, mergeChain } from "common/merge";
import {
  Filter,
  Query,
  QueryForEntity,
  isSelectField,
} from "common/query/types";
import { RelatedUiValue } from "common/record/types";
import { getRelatedRecords, isNotDeletedRecord } from "common/record/utils";
import { Context } from "common/types/context";
import { CurrencyRateIndex } from "common/types/currencies";
import { CancellablePromise } from "common/types/promises";
import { Properties, Record } from "common/types/records";
import { RelatedFormDisplayTypes } from "common/types/related-form-display";
import { SystemIntFk } from "common/types/system-int";
import { UnitCostResult } from "common/utils/currency";
import { rateIndex } from "x/account-settings/currencies/functions";
import {
  APPROVER,
  AWAITING_APPROVAL,
  OPEN,
  REVIEWER,
  UNDER_REVIEW,
} from "x/records/edit/behavior-form/requisitioning/consts";
import { getApproverQuery } from "x/records/edit/behavior-form/requisitioning/functions/approver-query";
import {
  getStatus,
  includesPermission,
} from "x/records/edit/behavior-form/requisitioning/functions/common";
import { PermissionType } from "x/records/edit/behavior-form/requisitioning/types";
import { replaceLookupQueries, updateContextForms } from "../common/functions";
import { createPartLocationLookupQuery } from "../part-location/functions";
import { RelatedValue } from "../types";
import {
  APPROVED_ITEM,
  AWAITING_APPROVAL_ITEM,
  CREATED_ON,
  CURRENCY,
  ESTIMATED_COST,
  PART,
  PART_LOCATION,
  PART_SUPPLIER,
  QUANTITY,
  REJECTED_ITEM,
  REQUISITIONING_ITEM_STATUS,
  UPDATED_ON,
} from "./consts";
import {
  EstimatedCost,
  PartSuppliersUnitCostResults,
  RequisitionUpdateItem,
  TotalEstimatedCost,
} from "./types";

export const getPartLocationId = (r: Record): string =>
  r?.properties?.[PART_LOCATION]?.id;

export const getPartSupplierId = (r: Record): string =>
  r?.properties?.[PART_SUPPLIER]?.id;

export const getQuantity = (r: Record): number => r?.properties?.[QUANTITY];

export const getItemStatus = (r: Record): number =>
  r?.properties?.[REQUISITIONING_ITEM_STATUS]?.id || AWAITING_APPROVAL_ITEM;

export const hiddenFieldsByStatus = (status: number = OPEN) => {
  switch (status) {
    case OPEN:
      return [
        REQUISITIONING_ITEM_STATUS,
        PART_SUPPLIER,
        ESTIMATED_COST,
        CURRENCY,
      ];
    case UNDER_REVIEW:
      return [REQUISITIONING_ITEM_STATUS, CREATED_ON, UPDATED_ON];
    default:
      return [CREATED_ON, UPDATED_ON];
  }
};

export const hasPartChanged = (oldProps: Properties, newProps: Properties) => {
  const newPartId: string = newProps?.partId?.id;
  return (
    oldProps?.id === newProps?.id &&
    !!newPartId &&
    oldProps?.partId?.id !== newPartId
  );
};

export const quickInputFieldsByStatus = (
  status: number = OPEN,
  permissionTypes: PermissionType[],
): string[] =>
  (status === UNDER_REVIEW && includesPermission(permissionTypes, REVIEWER)) ||
  (status === AWAITING_APPROVAL &&
    includesPermission(permissionTypes, APPROVER))
    ? [REQUISITIONING_ITEM_STATUS, PART_SUPPLIER, QUANTITY]
    : [];

export const requiredInputFieldsByStatus = (
  status: number = OPEN,
  permissionTypes: PermissionType[],
): string[] =>
  (status === UNDER_REVIEW && includesPermission(permissionTypes, REVIEWER)) ||
  (status === AWAITING_APPROVAL &&
    includesPermission(permissionTypes, APPROVER))
    ? [PART_SUPPLIER]
    : [];

export const isDisabled = (
  properties: Properties,
  item: SystemIntFk,
): boolean => !properties.partSupplierId && item.id === AWAITING_APPROVAL;

export const getDependencies = (properties: Properties): Properties => ({
  partSupplierId: properties.partSupplierId,
});

const includeAdditionalPropertiesForStatusColumn = (
  column: EntityColumn,
  quickInputs: string[],
) =>
  column.name === REQUISITIONING_ITEM_STATUS &&
  R.includes(column.name, quickInputs)
    ? { quickInput: true, isDisabled, getDependencies }
    : undefined;

const updateColumn = (
  column: EntityColumn,
  requiredInputs: string[],
  quickInputs: string[],
): EntityColumn => ({
  ...column,
  ...(R.includes(column.name, quickInputs) ? { quickInput: true } : undefined),
  ...includeAdditionalPropertiesForStatusColumn(column, quickInputs),
  ...(R.includes(column.name, requiredInputs) ? { required: true } : undefined),
  ...(column.name === QUANTITY ? { minValue: 1 } : undefined),
});

export const estimatedCostColumn = (): EntityColumn => ({
  name: ESTIMATED_COST,
  dataType: "currency",
  availableForList: true,
  localizedName: _("Estimated Cost"),
  isVirtual: true,
  columnType: ColumnTypes.System,
});

export const currencyColumn = (): EntityColumn => ({
  name: CURRENCY,
  dataType: "string",
  availableForList: true,
  localizedName: _("Currency"),
  isVirtual: true,
  columnType: ColumnTypes.System,
});

export const updateEntity = (
  entity: Entity,
  value: RelatedValue,
  permissionTypes: PermissionType[],
) => {
  const status = getStatus(value);
  const hidden = hiddenFieldsByStatus(status);
  const quickInputs = quickInputFieldsByStatus(status, permissionTypes);
  const requiredInputs = requiredInputFieldsByStatus(status, permissionTypes);

  const newColumns = entity.columns
    .map((c) => updateColumn(c, requiredInputs, quickInputs))
    .concat(estimatedCostColumn(), currencyColumn())
    .filter((c) => !R.includes(c.name, hidden));

  return { ...entity, columns: newColumns };
};

export const createPartSupplierLookUpQuery = (
  context: Context,
  entity: Entity,
): LookupQuery => {
  const partSupplierEntityName = entity?.arguments?.partSupplierEntity;
  const partSupplierEntity = context.entities[partSupplierEntityName];

  if (!partSupplierEntity) return undefined;

  return {
    columnName: PART_SUPPLIER,
    entityName: partSupplierEntity.name,
    filterByColumn: PART,
    query: partSupplierEntity.query,
  };
};

export const updateContext = (
  context: Context,
  entity: Entity,
  value: RelatedValue,
  permissionTypes: PermissionType[],
) => {
  const { entities } = context;
  const newEntities = R.mapObjIndexed(
    (e) =>
      e.name === entity.name ? updateEntity(e, value, permissionTypes) : e,
    entities,
  );

  const updatedContext = merge1("entities", newEntities, context);

  const partSupplierLookUpQuery = createPartSupplierLookUpQuery(
    updatedContext,
    entity,
  );
  const partLocationLookupQuery = createPartLocationLookupQuery(
    updatedContext,
    entity,
    value,
  );

  const updateSettings = R.pipe(
    replaceLookupQueries(PART_SUPPLIER, partSupplierLookUpQuery),
    replaceLookupQueries(PART_LOCATION, partLocationLookupQuery),
  );

  return updateContextForms(updatedContext, entity.name, (form) => ({
    ...form,
    settings: updateSettings(form.settings),
  }));
};

export const updateDisplayTypes = (
  displayTypes: RelatedFormDisplayTypes,
  status: number,
): RelatedFormDisplayTypes =>
  shouldDisplayForm(displayTypes) && status !== OPEN
    ? ["table", "tab"]
    : displayTypes;

export const updateTableQuery = (
  query: Query = defaultFor<Query>(),
  status: number,
) => {
  const { select = [] } = query;

  const hidden = hiddenFieldsByStatus(status);

  const newSelects = select
    .concat({ name: ESTIMATED_COST })
    .filter((s) => !(isSelectField(s) && R.includes(s.name, hidden)));

  return merge1("select", newSelects, query);
};

export const getNewPartSupplierIds = (
  records: Record[] = [],
  partSuppliersWithUnitCost: PartSuppliersUnitCostResults = {},
) => {
  return records.reduce((acc: string[], record) => {
    const partSupplierId = getPartSupplierId(record);
    if (
      partSupplierId &&
      isNotDeletedRecord(record) &&
      !R.includes(partSupplierId, R.keys(partSuppliersWithUnitCost))
    ) {
      return acc.concat(partSupplierId);
    }
    return acc;
  }, []);
};

export const getPartSupplierQuery = (
  newPartSupplierIds: string[] = [],
  entity: Entity,
): QueryForEntity => {
  const partSupplierName = entity?.arguments?.partSupplierEntity;
  if (!partSupplierName || !newPartSupplierIds.length) return undefined;

  const idFilters: Filter[] = newPartSupplierIds.map((supplierId) => ({
    and: [{ name: "id", op: "eq", value: supplierId }],
  }));

  return {
    entity: partSupplierName,
    query: {
      select: [{ name: "id" }, { name: "currency" }, { name: "unitCost" }],
      filter: {
        and: [{ name: "isDeleted", op: "isfalse" }, { or: idFilters }],
      },
    },
  };
};

export const getEstimatedCost = (
  record: Record,
  partSuppliersUnitCostResults: PartSuppliersUnitCostResults,
): EstimatedCost => {
  const partSupplierId = getPartSupplierId(record);
  if (!partSupplierId) return defaultFor<EstimatedCost>();

  const quantity = getQuantity(record);
  const partSupplierWithUnitCost =
    partSuppliersUnitCostResults[partSupplierId] ||
    defaultFor<UnitCostResult>();
  const { currency, unitCost } = partSupplierWithUnitCost;
  const estimatedCost =
    unitCost !== undefined && quantity !== undefined
      ? unitCost * quantity
      : undefined;

  return { estimatedCost, currency };
};

export const updateValue = (
  value: RelatedValue,
  entity: Entity,
  partSupplierWithUnitCost: PartSuppliersUnitCostResults,
) => {
  const status = getStatus(value);
  if (status === OPEN) return value;

  const { record, related = defaultFor<RelatedUiValue>() } = value;
  const requisitionItems = getRelatedRecords(entity.name, record, related.form);
  if (!requisitionItems.length) return value;

  const updatedRecords = requisitionItems.map((record) => {
    const { estimatedCost, currency } = getEstimatedCost(
      record,
      partSupplierWithUnitCost,
    );

    return mergeChain(record)
      .prop("properties")
      .setMany({
        estimatedCost: roundWithPrecision(estimatedCost, 2),
        currency,
      })
      .output();
  });

  return merge3("related", "form", entity.name, updatedRecords, value);
};

export const removeUpdatedValue = (value: RelatedValue, entityName: string) => {
  const reqItemsRecords = value?.related?.form?.[entityName];
  if (!reqItemsRecords) return value;

  const updatedRecords = reqItemsRecords.map((r) => {
    const newProperties = R.omit([CURRENCY, ESTIMATED_COST], r.properties);
    return merge1("properties", newProperties, r);
  });
  return merge3("related", "form", entityName, updatedRecords, value);
};

export const addPartSuppliersUnitCostResults = (
  partSupplierDetails: PartSuppliersUnitCostResults = {},
  properties: Properties[] = [],
) =>
  properties.reduce((acc, { id, currency, unitCost }) => {
    return merge1(id, { currency, unitCost }, acc);
  }, partSupplierDetails);

export const recordsToUpdateItems = (
  records: Record[] = [],
): RequisitionUpdateItem[] => {
  return records.map((record) => {
    const { id, statusId } = record.properties;
    return {
      recordId: id,
      statusId: getIntFkId(statusId),
      partSupplierId: getPartSupplierId(record) || null,
    };
  });
};

export const isApproved = (r: Record) => getItemStatus(r) === APPROVED_ITEM;
export const isRejected = (r: Record) => getItemStatus(r) === REJECTED_ITEM;
export const isApprovedOrRejected = (r: Record) =>
  isApproved(r) || isRejected(r);

const defaultTotalEstimatedCost: TotalEstimatedCost = {
  totalEstimatedCost: 0,
  converted: false,
};

export const getTotalEstimatedCost = (
  context: Context,
  records: Record[],
  partSuppliersUnitCostResults: PartSuppliersUnitCostResults,
  currencyRates: CurrencyRateIndex,
): TotalEstimatedCost => {
  const siteCurrency = context?.currency?.id;
  return records.reduce((acc, record) => {
    const { converted } = acc;
    const { estimatedCost, currency } = getEstimatedCost(
      record,
      partSuppliersUnitCostResults,
    );
    const isApprovedRecord = isApproved(record);
    if (!estimatedCost || !currency || !isApprovedRecord) {
      return acc;
    }

    if (siteCurrency === currency) {
      return {
        totalEstimatedCost: acc.totalEstimatedCost + estimatedCost,
        converted,
      };
    }

    const currencyRate = currencyRates[rateIndex(currency, siteCurrency)];
    const rate = currencyRate ? currencyRate.rate : 1;
    return {
      totalEstimatedCost: acc.totalEstimatedCost + estimatedCost * rate,
      converted: true,
    };
  }, defaultTotalEstimatedCost);
};

export const getTotalEstimatedCostMessage = (
  totalEstimatedCost: number,
  symbol: string,
  converted: boolean,
) => {
  if (!totalEstimatedCost) return undefined;

  const label = converted
    ? _("Estimated approved total with exchange rate applied:")
    : _("Estimated approved total:");
  const value = `${symbol}${roundWithPrecision(totalEstimatedCost, 2)}`;
  return { label, value };
};

export const getApproverCostLimit = (
  context: Context,
  partRequisitionEntity: Entity,
  approvalGroupId: string,
  contactId: string,
): CancellablePromise<number> => {
  const { entities, apiCall } = context;
  const approverQuery = getApproverQuery(
    entities,
    partRequisitionEntity,
    approvalGroupId,
    contactId,
  );
  return approverQuery
    ? searchApi(apiCall)
        .runQuery(approverQuery)
        .then((approvers: Properties) => approvers[0]?.costLimit)
    : CancellablePromise.resolve(undefined);
};

export const getCostLimitWarningMessage = (
  currencySymbol: string,
  approverCostLimit: number,
) =>
  _("Cost limit exceeded, your value is over: {LIMIT}").replace(
    "{LIMIT}",
    `${currencySymbol}${roundWithPrecision(approverCostLimit, 2)}`,
  );
