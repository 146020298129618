import * as React from "react";
import * as R from "ramda";
import $ from "jquery";
/**
 * jQuery UI listens to mouse events: mouseover, mousemove
 * and mouseout, not touch events: touchstart, touchmove and touchend.
 * jQuery UI Touch Punch is a small hack that enables the use of touch
 * events on sites using the jQuery UI user interface library.
 *
 * Note: order of import is important!
 */
import "jquery-ui/ui/widgets/resizable";
import "jquery-ui-touch-punch";
import { ValueProps } from "common/with-value-for";

interface PropTypes extends ValueProps<number[]> {
  allowDrag: boolean;
  allowSelect: boolean;
  allowActionsColumn: boolean;
  children?: any;
}

// USES: window.getComputedStyle
export class Resize extends React.Component<PropTypes> {
  tableContainerRef: React.RefObject<HTMLDivElement> = React.createRef();

  componentDidMount() {
    this.activation();
  }

  componentDidUpdate(prevProps: PropTypes) {
    if (this.props.allowDrag !== prevProps.allowDrag && this.props.allowDrag)
      this.activation();
    if (this.props.value !== prevProps.value && this.props.value)
      this.setWidths();
  }

  getTable = () => {
    return $(this.tableContainerRef.current).find("table").first();
  };

  selector = () => {
    const { allowSelect, allowActionsColumn } = this.props;
    const optionsActived = R.reduce((acc, conf) => (conf ? acc + 1 : acc), 0, [
      allowSelect,
      allowActionsColumn,
    ]);
    return `thead th:not(:nth-child(-n+${optionsActived}))`;
  };

  activation = () => {
    const table = this.getTable();

    (table.find(this.selector()) as any).resizable({
      handles: "e",
      minWidth: 10,
      alsoResize: table,
      resize: (_: any, ui: any) => {
        const { element } = ui;
        const str = getComputedStyle(element[0]).width;
        const minWidth = parseInt(R.slice(0, str.length - 2, str));
        const currentWidth = ui.size.width;

        if (currentWidth < minWidth) {
          element.resizable("option", "minWidth", currentWidth);
        }
      },
      stop: () => {
        const columns = table.find("thead th").toArray();
        const widths = R.map((td) => td.clientWidth, columns);
        this.props.onChange(widths);
      },
    });
    this.setWidths();
  };

  setWidths = () => {
    const widths = this.props.value;
    if (!widths) return;
    const newTableWidth = R.sum(widths);
    if (newTableWidth === 0) {
      return;
    }

    const table = this.getTable();
    table.width(!R.includes(null, widths) ? newTableWidth : "");
    table.height("");
    table.find("thead th").each((i: number, c) => {
      if (!widths[i]) {
        return;
      }
      $(c).outerWidth(widths[i]);
    });
  };

  render() {
    return <div ref={this.tableContainerRef}>{this.props.children}</div>;
  }
}
