import { ApiCall } from "common/types/api";
import { Comment, PagedComments } from "common/types/comments";

export const commentsApi = (apiCall: ApiCall) => ({
  get: (
    entity: string,
    recordId: string,
    offset: number = 0,
    limit: number = 10,
    dateFrom?: string,
  ) =>
    apiCall<PagedComments>(
      "get",
      `api/entities/:site/${entity}/${recordId}/comments?offset=${offset}&limit=${limit}${
        dateFrom ? `&dateFrom=${dateFrom}` : ""
      }`,
    ),
  post: (entity: string, recordId: string, comment: Comment) =>
    apiCall(
      "post",
      `api/entities/:site/${entity}/${recordId}/comments`,
      comment,
    ),
  delete: (entity: string, id: string) =>
    apiCall("delete", `api/entities/:site/${entity}/comments/${id}`),
});
