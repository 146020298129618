import { Entities } from "common/entities/types";
import { addFilter } from "common/query/filter";
import { mergeQueryJoins } from "common/query/joins";
import { getAdvancedSearchListQuery } from "common/query/list";
import { QueryForEntity, Query } from "common/query/types";

export const mergeWithDefaultListQuery = (
  entities: Entities,
  entityName: string,
  query: Query,
  queryFromPref: Query,
): QueryForEntity => {
  const queryForEntity = { entity: entityName, query };
  const defaultQuery = {
    entity: entityName,
    query: getAdvancedSearchListQuery(
      entities,
      entities?.[entityName],
      queryFromPref,
    ),
  };

  const queriesMerged = mergeQueryJoins(queryForEntity, defaultQuery);

  const filter = queryForEntity?.query?.filter;
  return filter ? addFilter(filter, queriesMerged) : queriesMerged;
};
