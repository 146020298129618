import { StandardValue } from "common/record/types";
import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { getRelatedRecordsByRelation } from "../functions";

export const isGroupMemberEntity = (
  mainEntity: Entity,
  relatedEntity: Entity,
) =>
  behaveAs("Group", mainEntity) &&
  behaveAs("GroupMember", relatedEntity) &&
  relatedEntity.arguments.groupEntity === mainEntity.name;

export const validateGroupMemberLength = (
  context: Context,
  entity: Entity,
  value: StandardValue,
) => {
  const groupMemberRecords = getRelatedRecordsByRelation(
    entity,
    context.entities,
    value,
    isGroupMemberEntity,
  );
  return !!groupMemberRecords.length;
};
