import { DateFormat, TimeFormat } from "common/date-time/types";

export const dateFormats: DateFormat[] = [
  "yyyy-M-d",
  "yyyy-MM-dd",
  "d-M-yyyy",
  "dd-MM-yyyy",
  "M-d-yyyy",
  "MM-dd-yyyy",

  "yyyy.M.d",
  "yyyy.MM.dd",
  "d.M.yyyy",
  "dd.MM.yy",

  "d/M/yyyy",
  "M/d/yyyy",
  "dd/MM/yyyy",

  "MMMM dd, yyyy",
  "d MMMM yyyy",
];

export const timeFormats: TimeFormat[] = [
  "HH:mm:ss",
  "H:mm:ss",
  "HH:mm",
  "hh:mm:ss a",
  "h:mm:ss a",
  "hh:mm a",
  "h:mm a",
];

export const FORMAT_ISO = "yyyy-MM-dd'T'HH:mm:ssXXX";

export const FORMAT_DAY_ORDINAL = "do"; // 13th
export const FORMAT_DATE = "yyyy-MM-dd HH:mm"; // 2021-07-13 13:14
export const FORMAT_DATE_FULL = "PPP pp"; // July 13th, 2021 01:14:00 PM
// export const FORMAT_DATE_FULL = "MMMM do yyyy, h:mm:ss a";
export const FORMAT_DATE_MONTH = "yyyy-MM"; // 2021-07
export const FORMAT_DATE_MONTH_NAME = "yyyy MMMM"; // 2021 July
export const FORMAT_MIDDAY_INDICATOR = "a"; // PM
export const FORMAT_TIME_SHORT = "hh:mma"; // 01:14PM
export const FORMAT_WEEKDAY_NAME = "cccc"; // Tuesday

export const FORMAT_FULL_NAMED_DAY = "cccc do MMMM yyyy"; // Tuesday 13th July 2021
export const FORMAT_FULL_DAY = "do MMMM yyyy"; // 13th July 2021
export const FORMAT_FULL_MONTH = "MMMM yyyy"; // July 2021
export const FORMAT_NAMED_MONTH = "MMMM"; // July
