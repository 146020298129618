import { AcceptType } from "./types";

const imageExtensions: string[] = ["bmp", "gif", "jpeg", "jpg", "png"];

const csvExtensions: string[] = ["csv"];

// The videoExtensions & audioExtensions are the ones which we support
// exclusively for Media fields having ability of inline play in browsers
const videoExtensions: string[] = ["mp4", "mov", "f4v", "mkv", "webm", "m4v"];

const audioExtensions: string[] = ["mp3", "wav"];

const otherExtensions: string[] = [
  "doc",
  "docm",
  "docx",
  "dot",
  "dotm",
  "dotx",
  "dwg",
  "dxf",
  "eml",
  "emlx",
  "epub",
  "kmz",
  "mpp",
  "mpt",
  "msg",
  "odp",
  "ods",
  "odt",
  "ott",
  "pdf",
  "pps",
  "ppsx",
  "ppt",
  "pptx",
  "rtf",
  "txt",
  "vdw",
  "vdx",
  "vsd",
  "vsdx",
  "vss",
  "vst",
  "vsx",
  "vtx",
  "xls",
  "xlsb",
  "xlsm",
  "xlsx",
  "xml",
  "xps",
  "zip",
  "7z",
  "ogg",
  "wmv",
  "avi",
  "avchd",
  "flv",
  "swf",
  "mpeg-2",
  "mpg",
  "mp2",
  "mpeg",
  "mpe",
  "mpv",
  "qt",
  "m4p",
  "tiff",
  "tif",
];

export const getFileExtension = (fileName: string) => {
  const dotIndex = fileName?.lastIndexOf(".") ?? -1;
  return dotIndex === -1 ? undefined : fileName.substr(dotIndex + 1);
};

const getAllowedExtensions = (acceptType: AcceptType) => {
  switch (acceptType) {
    case "image":
      return imageExtensions;
    case "csv":
      return csvExtensions;
    case "media":
      return [...imageExtensions, ...videoExtensions, ...audioExtensions];
    case "all":
    default:
      return [
        ...imageExtensions,
        ...csvExtensions,
        ...videoExtensions,
        ...audioExtensions,
        ...otherExtensions,
      ];
  }
};

export const isFileTypeAllowed = (fileName: string, acceptType: AcceptType) => {
  return getAllowedExtensions(acceptType).includes(
    getFileExtension(fileName)?.toLowerCase(),
  );
};

export const getAllowedExtensionsAsString = (acceptType: AcceptType) =>
  `.${getAllowedExtensions(acceptType).join(",.")}`;
