import { Context } from "common/types/context";
import { toKebabCase } from "common";
import { ActionButton } from "common/ui/buttons";
import { DropdownMenu } from "common/widgets/dropdown-menu";
import { MiniReport } from "x/reports/list/types";
import { exportCsv, exportPdf } from "../export-utils";

interface PropTypes {
  context: Context;
  report: MiniReport;
  className: string;
}

interface ExportTypes {
  title: string;
  onClick: () => void;
}

export const ExportReport = ({ context, report, className }: PropTypes) => {
  const { apiCall } = context;
  const exportList: ExportTypes[] = [
    {
      title: _("Export to CSV"),
      onClick: exportCsv(apiCall, report),
    },
    {
      title: _("Export to PDF"),
      onClick: exportPdf(apiCall, report),
    },
  ];

  return (
    <DropdownMenu
      className={className}
      button={
        <ActionButton title={_("Export")}>
          {_("Export")} <i className="fa fa-caret-down" />
        </ActionButton>
      }
      menu={exportList.map((item) => (
        <a
          key={item.title}
          className={`dropdown-item qa-${toKebabCase(item.title)}`}
          onClick={item.onClick}
        >
          {item.title}
        </a>
      ))}
    />
  );
};
