import { defaultFor } from "common";
import { currenciesApi } from "common/api/currency-rates";
import { recordsApi } from "common/api/records";
import { searchApi } from "common/api/search";
import { shouldDisplayForm } from "common/functions/related-form-display";
import { mergeChain } from "common/merge";
import { RelatedUiValue } from "common/record/types";
import { getRelatedRecords } from "common/record/utils";
import { CurrencyRateIndex } from "common/types/currencies";
import { ApiErrorResponse } from "common/types/error";
import { CancellablePromise } from "common/types/promises";
import { Properties } from "common/types/records";
import { ApiError } from "common/ui/api-error";
import { AlertWarning } from "common/widgets/alert";
import { LoadingIcon } from "common/widgets/loading-icon";
import { ValueComponent } from "common/with-value-for";
import { indexRates } from "x/account-settings/currencies/functions";
import {
  APPROVAL_GROUP,
  OPEN,
} from "x/records/edit/behavior-form/requisitioning/consts";
import {
  getPermissionTypes,
  getStatus,
  loadUserContact,
} from "x/records/edit/behavior-form/requisitioning/functions/common";
import { PermissionType } from "x/records/edit/behavior-form/requisitioning/types";
import { searchDefaultLocation } from "../purchase-order-item/functions";
import { StandardRelated } from "../standard-related";
import { PropTypes } from "../table-with-form/types";
import { RelatedValue } from "../types";
import {
  addPartSuppliersUnitCostResults as addPartSuppliersWithUnitCost,
  getApproverCostLimit,
  getCostLimitWarningMessage,
  getNewPartSupplierIds,
  getPartSupplierQuery,
  getTotalEstimatedCost,
  getTotalEstimatedCostMessage,
  hasPartChanged,
  removeUpdatedValue,
  updateContext,
  updateDisplayTypes,
  updateEntity,
  updateTableQuery,
  updateValue,
} from "./functions";
import { PartSuppliersUnitCostResults, TotalEstimatedCost } from "./types";

interface StateType {
  loading: boolean;
  error: ApiErrorResponse;
  currencyRates: CurrencyRateIndex;
  partSuppliersUnitCostResults: PartSuppliersUnitCostResults;
  permissionTypes: PermissionType[];
  approverCostLimit: number;
}

export class RequisitioningItem extends ValueComponent<
  RelatedValue,
  PropTypes,
  StateType
> {
  static readonly displayName = "RequisitioningItem";

  state: StateType = {
    loading: false,
    error: undefined,
    currencyRates: undefined,
    partSuppliersUnitCostResults: {},
    permissionTypes: undefined,
    approverCostLimit: undefined,
  };

  componentDidMount() {
    const { context, parentEntity, value } = this.props;
    const status = getStatus(value);
    const approvalGroupId = value?.record?.properties?.[APPROVAL_GROUP]?.id;

    if (status !== OPEN) {
      this.setState({ loading: true, error: undefined });
      CancellablePromise.all([
        loadUserContact(context, parentEntity),
        this.loadCurrencyRates(),
        this.loadPartSuppliersUnitCostResults(
          this.getNewPartSupplierIds(value),
        ),
      ])
        .then(([userContact, currencyRates, partSuppliersUnitCostResults]) => {
          CancellablePromise.all([
            getPermissionTypes(context, parentEntity, value, userContact?.id),
            getApproverCostLimit(
              context,
              parentEntity,
              approvalGroupId,
              userContact?.id,
            ),
          ]).then(([permissionTypes, constLimit]) => {
            this.setState({
              loading: false,
              currencyRates,
              partSuppliersUnitCostResults,
              permissionTypes: permissionTypes,
              approverCostLimit: constLimit,
            });
          });
        })
        .catch(this.onError);
    }
  }

  getRequisitioningItemValue = (v: RelatedValue) => {
    const { entity } = this.props;
    return v?.related?.partialForm?.[entity.name];
  };

  updatePartProperties = (newProps: Properties, eventValue: RelatedValue) => {
    const { context, value, entity } = this.props;
    const newPartId: string = newProps?.partId?.id;
    this.setState({ loading: true, error: undefined });

    return CancellablePromise.all([
      recordsApi(context.apiCall).get(newProps?.partId?.entity, newPartId),
      searchDefaultLocation(context, entity, value, newPartId),
    ])
      .then(([part, defaultPartLocation]) => {
        const props = {
          ...newProps,
          partNumber: part.properties.partNumber,
          partLocationId: defaultPartLocation,
        };

        const updatedEventValue = mergeChain(eventValue)
          .prop("related")
          .prop("partialForm")
          .set(entity.name, props)
          .output();

        this.setState({ loading: false });
        this.setValue(updatedEventValue);
      })
      .catch(this.onError);
  };

  loadCurrencyRates = (): CancellablePromise<CurrencyRateIndex> => {
    const { context } = this.props;
    return currenciesApi(context.apiCall).list().then(indexRates);
  };

  loadPartSuppliersUnitCostResults = (
    newPartSupplierIds: string[],
  ): CancellablePromise<PartSuppliersUnitCostResults> => {
    const { context, entity } = this.props;
    const { apiCall } = context;
    const { partSuppliersUnitCostResults } = this.state;
    const partSupplierQuery = getPartSupplierQuery(newPartSupplierIds, entity);

    if (partSupplierQuery) {
      return searchApi(apiCall)
        .runQuery(partSupplierQuery)
        .then((partSupplierRecords: Properties[] = []) => {
          return addPartSuppliersWithUnitCost(
            partSuppliersUnitCostResults,
            partSupplierRecords,
          );
        });
    }
    return CancellablePromise.resolve(partSuppliersUnitCostResults);
  };

  getNewPartSupplierIds = (newValue: RelatedValue) => {
    const { entity } = this.props;
    const { partSuppliersUnitCostResults } = this.state;
    const { record, related = defaultFor<RelatedUiValue>() } = newValue;

    const reqItemsRecords = getRelatedRecords(
      entity.name,
      record,
      related.form,
    );
    return getNewPartSupplierIds(reqItemsRecords, partSuppliersUnitCostResults);
  };

  onChange = (newValue: RelatedValue) => {
    const { entity, value } = this.props;
    const status = getStatus(value);

    if (status === OPEN) {
      const oldRequisitioningItemValue = this.getRequisitioningItemValue(value);
      const newRequisitioningItemValue =
        this.getRequisitioningItemValue(newValue);

      const isPartChanged = hasPartChanged(
        oldRequisitioningItemValue,
        newRequisitioningItemValue,
      );

      if (!isPartChanged) {
        return this.setValue(newValue);
      }

      const partLocationId = oldRequisitioningItemValue?.partId
        ? undefined
        : newRequisitioningItemValue?.partLocationId;

      return this.updatePartProperties(
        { ...newRequisitioningItemValue, partLocationId },
        newValue,
      );
    }

    this.setValue(removeUpdatedValue(newValue, entity.name));
    this.checkLoadPartSuppliersUnitCostResults(newValue);
  };

  checkLoadPartSuppliersUnitCostResults = (newValue: RelatedValue) => {
    const newPartSupplierIds = this.getNewPartSupplierIds(newValue);

    if (newPartSupplierIds.length) {
      this.setState({ loading: true, error: undefined });
      this.loadPartSuppliersUnitCostResults(newPartSupplierIds)
        .then((partSuppliersUnitCostResults) => {
          this.setState({
            loading: false,
            partSuppliersUnitCostResults,
          });
        })
        .catch(this.onError);
    }
  };

  onError = (error: ApiErrorResponse) => {
    this.setState({ loading: false, error });
  };

  render() {
    const { context, entity, value, displayTypes, query } = this.props;
    const {
      loading,
      error,
      currencyRates,
      partSuppliersUnitCostResults,
      permissionTypes,
      approverCostLimit,
    } = this.state;
    const { record, related = defaultFor<RelatedUiValue>() } = value;
    const status = getStatus(value);

    const initialLoading = status !== OPEN && loading && !currencyRates;
    if (initialLoading) {
      return <LoadingIcon />;
    }

    const reqItemsRecords = getRelatedRecords(
      entity.name,
      record,
      related.form,
    );

    const { totalEstimatedCost, converted }: TotalEstimatedCost =
      getTotalEstimatedCost(
        context,
        reqItemsRecords,
        partSuppliersUnitCostResults,
        currencyRates,
      );

    const totalEstimatedCostMessage =
      shouldDisplayForm(displayTypes) && status !== OPEN
        ? getTotalEstimatedCostMessage(
            totalEstimatedCost,
            context?.currency?.symbol,
            converted,
          )
        : undefined;

    const exceedCostLimit = totalEstimatedCost > approverCostLimit;

    return (
      <>
        {loading && <LoadingIcon />}
        {error && <ApiError error={error} />}
        {totalEstimatedCostMessage && (
          <div className="qa-total-label x-padding-bottom-10">
            {totalEstimatedCostMessage.label +
              " " +
              totalEstimatedCostMessage.value}
          </div>
        )}
        <StandardRelated
          {...this.props}
          context={updateContext(context, entity, value, permissionTypes)}
          entity={updateEntity(entity, value, permissionTypes)}
          displayTypes={updateDisplayTypes(displayTypes, status)}
          query={updateTableQuery(query, status)}
          value={updateValue(value, entity, partSuppliersUnitCostResults)}
          warning={
            exceedCostLimit ? (
              <AlertWarning
                message={getCostLimitWarningMessage(
                  context?.currency?.symbol,
                  approverCostLimit,
                )}
                className="x-margin-bottom-10-i"
              />
            ) : undefined
          }
          onChange={this.onChange}
        />
      </>
    );
  }
}
