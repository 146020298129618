import { ESignatureReason, Reason } from "common/types/reasons";
import { Row } from "common/record/form/content/audit-trail/event-detail/row";
import { getReason } from "common/scheduled-event/form/projections/reason";
import { Context } from "common/types/context";
import { FormattedFk } from "common/widgets/foreign-key";
import { LinkFk } from "common/widgets/foreign-key/link-fk";

interface PropTypes {
  context: Context;
  reason: Reason;
  hideLinks?: boolean;
}

export const ReasonDetails = ({ context, reason, hideLinks }: PropTypes) => {
  if (!reason || !Object.keys(reason).length) return <span />;

  const { text, exception, calendar } = getReason(reason);
  const reschedule = exception || calendar;
  const { eSignature } = reason as ESignatureReason;

  return (
    <div className="qa-reason-details">
      {eSignature ? (
        <div className="qa-esignature-reason">
          <legend className="x-event qa-event">{_("E-Signature")}</legend>
          {eSignature.code ? (
            <Row key="code" label={_("Reason Code")}>
              {hideLinks ? (
                <FormattedFk value={eSignature.code} context={context} />
              ) : (
                <LinkFk value={eSignature.code} context={context} />
              )}
            </Row>
          ) : undefined}
          {eSignature.text ? (
            <Row key="text" label={_("Reason Text")}>
              {eSignature.text}
            </Row>
          ) : undefined}
        </div>
      ) : undefined}
      {"origin" in reason ? (
        <div className="qa-reason">
          <legend className="x-event qa-event">{_("Reason")}</legend>
          <ul>
            {text ? (
              <li className="qa-audit-trail-reason">{text}</li>
            ) : undefined}
            {reschedule ? <li>{reschedule}</li> : undefined}
            {!text && !reschedule ? <li>{_("Unknown reason")}</li> : undefined}
          </ul>
        </div>
      ) : undefined}
    </div>
  );
};

ReasonDetails.displayName = "ReasonDetails";
