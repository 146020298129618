import { browserLogsLogger } from "common/monitoring/browser-logs";
import { getGlobalPropertiesFromParams } from "common/monitoring/configuration";
import { isLocalEnvironment } from "common/monitoring/environment";
import { rumLogger } from "common/monitoring/rum";
import { InitializationParams, User } from "common/monitoring/types";
import { ApiErrorResponse } from "common/types/error";

const setGlobalContextProperties = (params: InitializationParams) => {
  const globalContextParams = getGlobalPropertiesFromParams(params);

  browserLogsLogger.setGlobalContextProperties(globalContextParams);
  rumLogger.setGlobalContextProperties(globalContextParams);
};

const setUser = (user: User) => {
  if (!user || isLocalEnvironment()) return;

  browserLogsLogger.setUser(user);
  rumLogger.setUser(user);
};

const initialize = (params: InitializationParams = {}) => {
  if (isLocalEnvironment()) return;

  browserLogsLogger.initialize(params);
  rumLogger.initialize(params);

  setGlobalContextProperties(params);

  setUser(params?.user);
};

const logCustomError = (error: ApiErrorResponse | Error) => {
  if (isLocalEnvironment()) return;

  const errorMessage = JSON.stringify(error);

  browserLogsLogger.logCustomError(errorMessage);
  rumLogger.logCustomError(errorMessage);
};

export const dataDog = {
  initialize,
  setUser,
  logCustomError,
};
