import { createFilter } from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";

export const MAX_SELECTOR_OPTIONS = 100;

export const getCommonProps = (
  props: StateManagerProps,
): StateManagerProps => ({
  ...props,
  classNamePrefix: "x-selector",
  filterOption: createFilter({ ignoreAccents: false }),
  menuPlacement: "auto",
  menuPortalTarget: document.getElementById("react"),
  placeholder: props.placeholder ?? _("Select..."),
  styles: {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (base) => ({
      ...base,
      minWidth: "150px",
    }),
    groupHeading: () => ({}),
  },
});
