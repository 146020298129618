import { ApiErrorResponse } from "common/types/error";
import { ApiError } from "common/ui/api-error";
import { CancelButtonSmall } from "common/ui/buttons";

interface PropsType {
  error: ApiErrorResponse;
  onReset: () => void;
}

export const QueryError = ({ error, onReset }: PropsType) => {
  const mainMessage =
    error?.status === 500
      ? _(
          "There was a problem running your query. Please check your filters and try again.",
        )
      : undefined;

  const errorButtons = [
    <CancelButtonSmall
      key="reset-filter"
      title={_("Reset filter")}
      onClick={onReset}
    >
      {_("Reset filter")}
    </CancelButtonSmall>,
  ];

  return (
    <ApiError error={error} mainMessage={mainMessage} buttons={errorButtons} />
  );
};
