import { behaveAs } from "common/entities";
import {
  CustomConfig,
  CustomConfigSettings,
  RelatedValue,
} from "common/record/form/content/related/types";
import { TaskProcedures } from "../task-procedures";
import { orderRef } from "./order-ref";

const defaultCustomConfig: CustomConfigSettings<RelatedValue> = {
  defaultForm: undefined,
  layoutMapper: (l) => l,
  custom: () => undefined,
  customOnChange: undefined,
};

export const procedureRef: CustomConfig<RelatedValue> = (
  props,
): CustomConfigSettings<RelatedValue> => {
  const {
    relatedEntity,
    parentEntity,
    recordDetail,
    context,
    value,
    onChange,
  } = props;
  const { partialForm } = value.related;

  const taskEntity = context.entities[parentEntity.arguments.taskEntity];
  const assetsEntity = context.entities[parentEntity.arguments.assetEntity];

  const orderRefComp = behaveAs("Order", relatedEntity)
    ? orderRef(props)
    : defaultCustomConfig;

  const editingProcedure = partialForm?.[relatedEntity.name];
  const isEditingProcedureNew =
    !editingProcedure?.id && !editingProcedure?.tempId;

  return {
    ...orderRefComp,
    custom: () =>
      isEditingProcedureNew && taskEntity ? (
        <TaskProcedures
          context={context}
          entity={relatedEntity}
          assetsEntity={assetsEntity}
          taskEntity={taskEntity}
          recordDetail={recordDetail}
          value={value}
          onChange={onChange}
        />
      ) : undefined,
  };
};
