import { createEditCtrlWithPermission } from "common/ui/controllers/edit";
import { PropTypes } from "./types";
import { FormComponent } from "./form";

export function createController<TValue, TId, TRecord>() {
  const EditController = createEditCtrlWithPermission<TValue, TId, TRecord>();

  const EditWithRibbonAndFooter = ({
    api,
    context,
    id,
    isNew,
    permissionCategory,
    unwrapRecord,
    wrapRecord,
    canDelete,
    canRestore,
    defaultValue,
    dependencies,
    dontLoad,
    entity,
    forceFetch,
    onCancel,
    onDelete,
    onHasChanged,
    onNotFound,
    onPreSave,
    onRecordChanged,
    onRestore,
    onSave,
    restoring,
    needSaveConfirmation,
    saveConfirmationMessage,
    skipDeleteConfirmation,
    crumbs,
    isValid,
    cancelLabel,
    children,
    confirmationLabel,
    confirmationTitle,
    deleteLabels,
    deleteWarningMessage,
    getTitle,
    hideFooter,
    hideRibbon,
    noDelete,
  }: PropTypes<TValue, TId, TRecord>) => {
    return (
      <EditController
        confirmationTitle={confirmationTitle || _("The record will be deleted")}
        confirmationLabel={confirmationLabel || _("Yes, delete the record")}
        // pass down props
        api={api}
        context={context}
        id={id}
        isNew={isNew}
        permissionCategory={permissionCategory}
        unwrapRecord={unwrapRecord}
        wrapRecord={wrapRecord}
        canDelete={canDelete}
        canRestore={canRestore}
        defaultValue={defaultValue}
        dependencies={dependencies}
        dontLoad={dontLoad}
        entity={entity}
        forceFetch={forceFetch}
        onCancel={onCancel}
        onDelete={onDelete}
        onHasChanged={onHasChanged}
        onNotFound={onNotFound}
        onPreSave={onPreSave}
        onRecordChanged={onRecordChanged}
        onRestore={onRestore}
        onSave={onSave}
        restoring={restoring}
        needSaveConfirmation={needSaveConfirmation}
        saveConfirmationMessage={saveConfirmationMessage}
        skipDeleteConfirmation={skipDeleteConfirmation}
        deleteWarningMessage={deleteWarningMessage}
      >
        <FormComponent<TValue, TId>
          // injected by EditController ... base
          initialValue={undefined}
          value={undefined}
          onSave={undefined}
          onDelete={undefined}
          onRestore={undefined}
          error={undefined}
          loading={undefined}
          saving={undefined}
          reload={undefined}
          restoring={undefined}
          deleting={undefined}
          dependencies={undefined}
          id={undefined}
          onCancel={undefined}
          onChange={undefined}
          deleteWarningMessage={undefined}
          // pass down props
          context={context}
          crumbs={crumbs}
          isNew={isNew}
          isValid={isValid}
          cancelLabel={cancelLabel}
          deleteLabels={deleteLabels}
          getTitle={getTitle}
          hideFooter={hideFooter}
          hideRibbon={hideRibbon}
          noDelete={noDelete}
        >
          {children}
        </FormComponent>
      </EditController>
    );
  };
  return EditWithRibbonAndFooter;
}
