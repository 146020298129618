import * as R from "ramda";
import { Properties } from "common/types/records";
import { ResolvedDependencies } from "common/with-dependencies";
import { RelatedValue } from "common/record/form/content/related/types";
import { getRelatedRecords, recordToPayload } from "common/record/utils";
import { merge2, merge3 } from "common/merge";

export const getDefaultValueForm = (
  value: Properties = {},
  defaultValue: Properties = {},
  dependencies: ResolvedDependencies = {},
): Properties => {
  const formValue = R.keys(value).length
    ? { ...defaultValue, ...value }
    : defaultValue;

  const dependenciesValue = dependencies?.defaultValue?.ui?.detail?.form;

  return formValue.id || !dependenciesValue
    ? formValue
    : R.mergeRight(dependenciesValue, formValue);
};

export const isEdited = (form: Properties = {}) => !!(form.id || form.tempId);

export const getPartialForm = (v: RelatedValue, relatedEntityName: string) => {
  if (!relatedEntityName) return undefined;
  return v?.related?.partialForm?.[relatedEntityName];
};

/**
 * Unwraps value to parent making sure at least one record is default
 * @param value
 * @param entityName
 */
export const unwrapWithDefault = (
  value: RelatedValue,
  entityName: string,
): RelatedValue => {
  const { record, related } = value;

  const nonDeletedRecords = getRelatedRecords(
    entityName,
    record,
    related.form,
  ).filter(({ properties }) => !properties.isDeleted);

  if (
    !nonDeletedRecords.length ||
    nonDeletedRecords.find(({ properties }) => properties.isDefault)
  )
    return value;

  const entityForm = related.form?.[entityName] || [];
  const defaultRecord = recordToPayload(
    merge2("properties", "isDefault", true, nonDeletedRecords[0]),
  );

  const indexInForm = entityForm.findIndex(
    ({ properties: { id, tempId } }) =>
      (id && id === defaultRecord.properties.id) ||
      (tempId && tempId === defaultRecord.properties.tempId),
  );

  const newForm =
    indexInForm >= 0
      ? R.update(indexInForm, defaultRecord, entityForm)
      : entityForm.concat(defaultRecord);

  return merge3("related", "form", entityName, newForm, value);
};

/**
 * Unwraps value to parent making sure at no more than one record is default
 * @param value
 * @param entityName
 * @param id
 */
export const resetDefault = (
  value: RelatedValue,
  entityName: string,
  id: string,
): RelatedValue => {
  const { record, related } = value;

  const nonDeletedRecords = getRelatedRecords(
    entityName,
    record,
    related.form,
  ).filter(({ properties }) => !properties.isDeleted);

  const entityForm = related.form?.[entityName];
  const resetDefaultRecord = recordToPayload(
    merge2("properties", "isDefault", false, nonDeletedRecords[0]),
  );

  const indexInForm = entityForm?.findIndex(
    ({ properties: { id } }) => id && id === resetDefaultRecord.properties.id,
  );

  const newForm =
    indexInForm >= 0
      ? R.update(indexInForm, resetDefaultRecord, entityForm)
      : entityForm.concat(resetDefaultRecord);

  return id !== resetDefaultRecord.properties.id
    ? merge3("related", "form", entityName, newForm, value)
    : value;
};
