import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { Entity } from "common/entities/types";
import { getColumn } from "common/entities";
import { getLocalizedName } from "common";
import { Row } from "common/record/form/content/detail/view/section-group/row";

interface PropTypes {
  context: Context;
  entity: Entity;
  properties?: Properties;
}

type CostColumnName = "totalBeforeTax" | "totalTax" | "totalCost";
const costColumns: CostColumnName[] = [
  "totalBeforeTax",
  "totalTax",
  "totalCost",
];

export const CostSummary = ({ entity, properties, context }: PropTypes) => {
  const costSummary = costColumns.map((name) => {
    const column = getColumn(entity, name);
    if (!column) return null;

    return (
      <Row
        key={column.name}
        withLinks={false}
        column={column}
        label={getLocalizedName(column)}
        context={context}
        entityName={entity.name}
        value={properties[column.name]}
      />
    );
  });

  return (
    <div className="x-flex-end-center x-margin-top-10 x-margin-right-50">
      <div>
        <table className="x-cost-summary-table table">
          <tbody>{costSummary}</tbody>
        </table>
      </div>
    </div>
  );
};
CostSummary.displayName = "CostSummary";
