import { getUtcNow } from "common/date-time/common";
import { ForeignKey } from "common/types/foreign-key";

export interface AdjustmentFormValue {
  onHand: number;
  adjustmentType: ForeignKey;
  date: string;
  cost: number | string;
  comment: string;
  adjustBy: string;
  batch: ForeignKey;
  [index: string]: any;
}

export interface TransferFormValue {
  quantity: number;
  destination: { id: string; title: string };
  date: string;
  comment: string;
  [index: string]: any;
}

export const defaultAdjustmentValue = (
  onHand: number,
): AdjustmentFormValue => ({
  onHand,
  adjustmentType: undefined,
  date: getUtcNow(),
  cost: undefined,
  comment: undefined,
  adjustBy: undefined,
  batch: undefined,
});

export const defaultTransferValue = (quantity: number): TransferFormValue => ({
  quantity,
  date: getUtcNow(),
  comment: undefined,
  destination: undefined,
});

export interface BatchFk extends ForeignKey {
  quantity: string;
  cost: string;
}
