import * as R from "ramda";
import { recordsApi } from "common/api/records";
import { Component } from "common/component";
import { findEntityByArgs } from "common/functions/entity";
import { Action } from "common/record/actions/action";
import { DismissAction, PropTypes } from "common/record/actions/types";
import { isApproved } from "common/record/form/content/related/requisitioning-item/functions";
import { hasProtectedColumns } from "common/record/utils";
import { ApiCall } from "common/types/api";
import { Record } from "common/types/records";
// eslint-disable-next-line import/no-cycle
import { RequisitioningForm } from "./form";
import {
  getAllItems,
  isValid,
  recordToRequisitioningPayload,
} from "./functions";
import { CreatePoParams } from "./types";

interface StateType {
  initialItems: Record[];
  createPoParams: CreatePoParams;
  isLoading?: boolean;
}

//TODO: Remove ApproveRequisition code and its logic as its redundant and no longer in use
export class ApproveRequisition extends Component<PropTypes, StateType> {
  static readonly displayName = "ApproveRequisition";

  constructor(props: PropTypes) {
    super(props);
    const { context, entity, records = [] } = props;
    const items = getAllItems(context.entities, entity, records[0]);
    this.state = {
      initialItems: items.filter(isApproved),
      createPoParams: { formId: undefined, items: [] },
    };
  }

  onOk = (apiCall: ApiCall, dismiss: DismissAction) => {
    const { entity, records = [] } = this.props;
    const { initialItems, createPoParams } = this.state;

    const id = records[0]?.properties?.id;
    const updatedRecords = R.difference(createPoParams.items, initialItems);
    return recordsApi(apiCall)
      .approveRequisitioning(
        entity.name,
        id,
        updatedRecords.map(recordToRequisitioningPayload),
      )
      .then(dismiss);
  };

  render() {
    const { context, dismiss, entity, records = [] } = this.props;
    const { entities } = context;
    const { createPoParams } = this.state;

    const reqItemEntity = findEntityByArgs(
      entities,
      "RequisitioningItem",
      "requisitioningEntity",
      entity.name,
    );

    return (
      <Action
        requiresAuthentication={hasProtectedColumns(entity)}
        context={context}
        dismiss={dismiss}
        entity={entity}
        records={records}
        title={_("Approve")}
        btnLabel={_("Approve Request")}
        onOk={isValid(createPoParams.items) ? this.onOk : undefined}
        size="large"
      >
        <RequisitioningForm
          context={context}
          reqItemEntity={reqItemEntity}
          editableFields={["partSupplierId"]}
          showPurchaseOrderFormSelector={false}
          value={createPoParams}
          onChange={this.onChangeMergeState("createPoParams")}
        />
      </Action>
    );
  }
}
