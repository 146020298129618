import { getUtcNow } from "common/date-time/common";
import { formatDate } from "common/date-time/formatters";
import { resolveDateDynamicValue } from "common/date-time/helpers/dynamic-values";
import {
  DateFormat,
  SitedDateTime,
  TimeFormat,
  UTCDateTime,
} from "common/date-time/types";
import { arrayToString } from "common/utils/array";

export const getDateTimeFormatter =
  (dateFormat: DateFormat, timeFormat: TimeFormat) =>
  (dateTime: UTCDateTime): SitedDateTime => {
    const dateISO = resolveDateDynamicValue(dateTime, getUtcNow()) || dateTime;

    return formatDate(dateISO, arrayToString([dateFormat, timeFormat]));
  };
