import { searchApi } from "common/api/search";
import { addFilter, modifyPathInFilter } from "common/query/filter";
import { getAdvancedSearchListQuery } from "common/query/list";
import { QueryForEntity } from "common/query/types";
import { ForeignKey } from "common/types/foreign-key";
import { Properties } from "common/types/records";
import { AdvancedSearch } from "common/widgets/record-selector/advanced-search/advanced-search";
import { ValueComponent } from "common/with-value-for";
import {
  getQueryFromPreferences,
  mapEntityColumnsToSelect,
  mergeQueries,
} from "../functions";
import { AdvancedSearchBaseProps, TabContentProps } from "../types";

export class MultiEntitySearchCtrl extends ValueComponent<
  ForeignKey[],
  AdvancedSearchBaseProps
> {
  static readonly displayName = "MultiEntitySearchCtrl";

  getTabsContent = (): TabContentProps[] => {
    const { context, content = [], entity } = this.props;
    const { entities } = context;

    return content.map((tabContent) => {
      const { entity: entityName } = tabContent.queryForEntity || {};
      const queryFromPreferences = getQueryFromPreferences(context, entityName);

      const defaultQuery = getAdvancedSearchListQuery(
        entities,
        entities[entityName],
        queryFromPreferences,
      );

      const path = entity.joins.find(
        (j) => j.entityName === entityName,
      )?.column;

      const columnsToInclude = mapEntityColumnsToSelect(entity, path);

      const mergedQuery = mergeQueries(
        tabContent.queryForEntity,
        {
          entity: entityName,
          query: defaultQuery,
        },
        columnsToInclude,
      );

      const filter = tabContent.queryForEntity?.query?.filter;
      const newFilter = path
        ? modifyPathInFilter(filter, columnsToInclude, path)
        : filter;

      const queryForEntity = newFilter
        ? addFilter(newFilter, mergedQuery)
        : mergedQuery;

      return { ...tabContent, queryForEntity };
    });
  };

  selectProperty = (newSelected: Properties[]) => {
    const { onSelect } = this.props;
    onSelect(newSelected[0]);
  };

  runQuery = (query: QueryForEntity) => {
    const { context } = this.props;
    return searchApi(context.apiCall).runQueryFkExpansion(query);
  };

  render() {
    const content = this.getTabsContent();

    return (
      <AdvancedSearch
        {...this.props}
        runQuery={this.runQuery}
        content={content}
        selectProperty={this.selectProperty}
      />
    );
  }
}
