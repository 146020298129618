import * as R from "ramda";
import {
  MeterForeignKey,
  MeterState,
  ScheduledEventAssetRecord,
} from "common/types/scheduled-event";
import { AlertWarning } from "common/widgets/alert";
import { Ufloat } from "common/widgets/number";
import { Required } from "common/widgets/required";
import { ValueComponent } from "common/with-value-for";
import { defaultFor } from "common/index";

interface PropTypes {
  meterValue: MeterState;
  unit: string;
}

export class EventAssetMeter extends ValueComponent<
  ScheduledEventAssetRecord,
  PropTypes
> {
  static readonly displayName = "EventAssetMeter";

  onRemove = () => {
    const { meterValue, value } = this.props;
    const newMeters =
      value.properties.meters.filter(
        (m) => m.assetMeterId !== meterValue.assetMeterId,
      ) || [];

    this.mergeValue2("properties", "meters", newMeters, true);
  };

  onChangeLast = (lastTriggered: number) => {
    const { meterValue, value } = this.props;
    const newMeters = value.properties.meters.map((m) =>
      m.assetMeterId !== meterValue.assetMeterId
        ? m
        : R.mergeRight(m, { lastTriggered }),
    );

    this.mergeValue2("properties", "meters", newMeters);
  };

  onChangeThreshold = (threshold: number) => {
    const { meterValue, value } = this.props;
    const newMeters = value.properties.meters.map((m) =>
      m.assetMeterId !== meterValue.assetMeterId
        ? m
        : R.mergeRight(m, { threshold }),
    );
    this.mergeValue2("properties", "meters", newMeters);
  };

  render() {
    const { meterValue } = this.props;
    const { threshold, lastTriggered } = meterValue ?? defaultFor<MeterState>();
    const { unit, isDeleted, title } =
      meterValue.assetMeterId ?? defaultFor<MeterForeignKey>();
    const message =
      Number(threshold) === 0
        ? _("Threshold value must be greater than 0")
        : undefined;
    return (
      <div className="x-se-pm-asset-meter">
        <label className="x-se-pm-asset-label">
          {isDeleted ? `${title} (${_("Archived")})` : title}
          <button
            className="x-se-pm-asset-meter-remove"
            onClick={this.onRemove}
          >
            <i className="fa fa-times" />
          </button>
        </label>
        <div className="x-se-pm-asset-meter-input">
          <label>{_("Last WO Reading")}:</label>
          <span>
            <Ufloat
              className="ix-pm-triggers-column-input qa-wo-reading"
              placeholder={_("Amount")}
              value={lastTriggered || ""}
              onChange={this.onChangeLast}
            />
          </span>
        </div>
        <div className="pull-right x-se-pm-asset-meter-input">
          <label>{_("Every")}:</label>
          <Required value={threshold > 0 ? threshold : undefined}>
            <Ufloat
              className="x-pm-triggers-column-input qa-threshold"
              placeholder={_("Threshold")}
              value={threshold || ""} // threshold
              onChange={this.onChangeThreshold}
            />
          </Required>
          <i>{unit || _("Units")}</i>
        </div>
        {message && (
          <AlertWarning message={message} className={"x-threshold-warning"} />
        )}
      </div>
    );
  }
}
