import * as R from "ramda";
import { Record } from "common/types/records";
import {
  isApproved,
  isRejected,
  isApprovedOrRejected,
} from "common/record/form/content/related/requisitioning-item/functions";

export const hasApprovedRequisitionItems = (
  requisitionItems: Record[] = [],
): boolean => {
  if (requisitionItems.length === 0) return false;
  return (
    R.any(isApproved, requisitionItems) &&
    R.all(isApprovedOrRejected, requisitionItems)
  );
};

export const hasAllRejectedRequisitionItems = (
  requisitionItems: Record[] = [],
): boolean => {
  if (requisitionItems.length === 0) return false;
  return R.all(isRejected, requisitionItems);
};
