import { Projection } from "common/types/scheduled-event";
import { ProjectionComponent } from "./projection";

interface RelatedRecordMap {
  [index: string]: string;
}

interface PropTypes {
  day: string;
  projections: Projection[];
  relatedRecords: RelatedRecordMap;
}

export const Day = ({ day, projections, relatedRecords }: PropTypes) => (
  <div>
    <strong className="x-projection-day-date">{day}</strong>
    {projections.map((projection, i) => (
      <ProjectionComponent
        key={i}
        assetName={relatedRecords[projection.assetId]}
        projection={projection}
      />
    ))}
  </div>
);
