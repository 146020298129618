import { DataType } from "common/entities/entity-column/data-type/types";
import { EntityColumn } from "common/entities/entity-column/types";

export type BehaviorType = "optional" | "primary" | "system" | "exclusive";

export type BehaviorName =
  | "Adjustment"
  | "AnalysisTypes"
  | "ApprovalCostCenter"
  | "ApprovalGroup"
  | "Approver"
  | "Asset"
  | "AssetMeter"
  | "AssetMeterReading"
  | "AssetPart"
  | "Assignment"
  | "Attachment"
  | "AttachmentColumn"
  | "Barcode"
  | "Batch"
  | "BatchCharge"
  | "Calendar"
  | "ConditionMonitoring"
  | "Comments"
  | "CurrencyTranslation"
  | "CycleCount"
  | "Description"
  | "Email"
  | "EmailDomainRules"
  | "Event"
  | "EventAsset"
  | "ExternalId"
  | "FailureAnalysis"
  | "FlukeMobile"
  | "FollowUp"
  | "FunctionalLocation"
  | "GeoLocation"
  | "GlobalCounter"
  | "Group"
  | "GroupMember"
  | "Journal"
  | "KitItem"
  | "LaborCharge"
  | "LiveAssetIntegration"
  | "LiveAssetAlarmEvent"
  | "LiveAssetComponentStatusChangeEvent"
  | "LiveAssetSystemEvent"
  | "LocationDefinition"
  | "Map"
  | "Meter"
  | "Order"
  | "Part"
  | "PartCharge"
  | "PartsToBeCounted"
  | "PartsReorder"
  | "PartSupplier"
  | "Procedure"
  | "ProcedureType"
  | "MeterType"
  | "Procurement"
  | "PurchaseOrder"
  | "PurchaseOrderItem"
  | "Pure"
  | "Rate"
  | "Reason"
  | "Record"
  | "Reference"
  | "RelatedCalendar"
  | "RepairablePart"
  | "RepairablePartCharge"
  | "Request"
  | "Requirement"
  | "Requisitioner"
  | "RequisitionerGroup"
  | "Requisitioning"
  | "RequisitioningItem"
  | "ScheduledEvent"
  | "Signature"
  | "Stock"
  | "SubEntity"
  | "Supplier"
  | "SystemQrCode"
  | "Task"
  | "TaskItem"
  | "TaxRate"
  | "ToDo"
  | "Transfer"
  | "Tree"
  | "User"
  | "WorkOrder"
  | "WorkOrderAsset"
  | "WorkOrderAssetRef"
  | "WorkOrderSubEntity";

export const allBehaviorNames: BehaviorName[] = [
  "Adjustment",
  "AnalysisTypes",
  "ApprovalCostCenter",
  "ApprovalGroup",
  "Approver",
  "Asset",
  "AssetMeter",
  "AssetMeterReading",
  "AssetPart",
  "Assignment",
  "Attachment",
  "AttachmentColumn",
  "Barcode",
  "Batch",
  "BatchCharge",
  "Calendar",
  "ConditionMonitoring",
  "Comments",
  "CurrencyTranslation",
  "CycleCount",
  "Description",
  "Email",
  "EmailDomainRules",
  "Event",
  "EventAsset",
  "ExternalId",
  "FailureAnalysis",
  "FlukeMobile",
  "FollowUp",
  "FunctionalLocation",
  "GeoLocation",
  "GlobalCounter",
  "Group",
  "GroupMember",
  "Journal",
  "KitItem",
  "LaborCharge",
  "LiveAssetIntegration",
  "LiveAssetAlarmEvent",
  "LiveAssetComponentStatusChangeEvent",
  "LiveAssetSystemEvent",
  "LocationDefinition",
  "Map",
  "Meter",
  "Order",
  "Part",
  "PartCharge",
  "PartsToBeCounted",
  "PartsReorder",
  "PartSupplier",
  "Procedure",
  "ProcedureType",
  "MeterType",
  "Procurement",
  "PurchaseOrder",
  "PurchaseOrderItem",
  "Pure",
  "Rate",
  "Reason",
  "Record",
  "Reference",
  "RelatedCalendar",
  "RepairablePart",
  "RepairablePartCharge",
  "Request",
  "Requirement",
  "Requisitioner",
  "RequisitionerGroup",
  "Requisitioning",
  "RequisitioningItem",
  "ScheduledEvent",
  "Signature",
  "Stock",
  "SubEntity",
  "Supplier",
  "SystemQrCode",
  "Task",
  "TaskItem",
  "TaxRate",
  "ToDo",
  "Transfer",
  "Tree",
  "User",
  "WorkOrder",
  "WorkOrderAsset",
  "WorkOrderAssetRef",
  "WorkOrderSubEntity",
];

export const hiddenUiBehaviorNames: BehaviorName[] = [
  "Pure",
  "Record",
  "Reference",
];

export const chargeBehaviorNames: BehaviorName[] = [
  "BatchCharge",
  "LaborCharge",
  "PartCharge",
  "CurrencyTranslation",
];

export const scheduledBehaviorNames: BehaviorName[] = [
  "EventAsset",
  "ScheduledEvent",
];

export const interactiveMapBehaviorNames: BehaviorName[] = ["Asset", "Part"];

export interface Behavior {
  name: BehaviorName;
  arguments: string[];
}

// Blueprints
export type BlueprintArgumentName =
  | "AdjustmentEntity"
  | "AdjustmentTypeEntity"
  | "ApprovalCostCenterEntity"
  | "ApprovalGroupEntity"
  | "AssetEntity"
  | "AssetMeterEntity"
  | "AssetPartEntity"
  | "BarcodeColumn"
  | "BatchEntity"
  | "ColumnName"
  | "ContactEntity"
  | "CostCenterReference"
  | "EventAssetEntity"
  | "FkColumn"
  | "GroupEntity"
  | "LocationEntity"
  | "MeterEntity"
  | "OwnerColumn"
  | "OwnerEntity"
  | "PartChargeEntity"
  | "PartEntity"
  | "PartSupplierEntity"
  | "ProcedureEntity"
  | "PurchaseOrderEntity"
  | "QrCodeRequestor"
  | "Quantity"
  | "RateTypeEntity"
  | "RateTypeEntityColumn"
  | "RepairablePartEntity"
  | "RequestEntity"
  | "RequisitioningEntity"
  | "ScheduledEventEntity"
  | "ShippingAddress"
  | "ShippingAddressEntity"
  | "ShippingMethodEntity"
  | "StockEntity"
  | "SupplierEntity"
  | "TargetColumn"
  | "TargetEntity"
  | "TaskEntity"
  | "TaxRateEntity"
  | "TitleColumn"
  | "TransferEntity"
  | "UnitCost"
  | "WorkOrderEntity";

export type BlueprintArgumentType =
  | "columnOfPreviousArg"
  | "column"
  | "subEntity"
  | "reference"
  | "entity"
  | "customOption";
// undefined - default type is entity

export interface BehaviorConstraint {
  behaviorName: BehaviorName;
  argumentName: string;
}

export interface CustomOption {
  id: string;
  name: string;
}

export interface BlueprintArgument {
  name: BlueprintArgumentName;
  type?: BlueprintArgumentType;
  behaveLike?: BehaviorName;
  relationFor?: BehaviorConstraint;
  defaultValue?: string;
  columnType?: DataType;
  customOptions?: CustomOption[];
  properties?: BlueprintArgumentProperty[];
}

export interface BehaviorBlueprint {
  type?: BehaviorType;
  name: BehaviorName;
  arguments: BlueprintArgument[];
  includedBehaviors?: string;
  columns?: EntityColumn[];
}

export type BlueprintArgumentProperty =
  | "optional"
  | "required"
  | "immutable"
  | "mutable";
