import { Context } from "common/types/context";
import { defaultFilter as defaultValue, Filter } from "common/types/filters";
import { DeleteButton } from "common/ui/buttons";
import { VerticalField } from "common/ui/field";
import { Checkbox } from "common/widgets/checkbox/checkbox";
import { InputWithSubmit } from "common/widgets/input-with-submit";
import { Modal } from "common/widgets/modal";
import { ValueProps } from "common/with-value-for";
import { getFiltersPermissions } from "x/records/list/functions";
import { SaveFilterState } from "x/records/list/types";

interface PropTypes extends ValueProps<Filter> {
  saveFilterState: SaveFilterState;
  lastFilterName: string;
  filters: Filter[];
  context: Context;
  onSave: () => void;
  onCancel: () => void;
  onDelete: () => void;
}

const isDuplicateFilterName = (
  isNewFilter: boolean,
  lastFilterName: string,
  filters: Filter[] = [],
  name: string = "",
) =>
  filters.some((f) => f.name.toLowerCase() === name.toLowerCase()) &&
  (isNewFilter || lastFilterName !== name);

const isValidFilter = (
  isNewFilter: boolean,
  lastFilterName: string,
  filters: Filter[],
  filter: Filter,
) =>
  !!filter?.name &&
  !isDuplicateFilterName(isNewFilter, lastFilterName, filters, filter.name);

const isOkLabel = (saveFilterState: SaveFilterState) => {
  switch (saveFilterState) {
    case "new":
    case "edit":
      return _("Save");
    case "update":
      return _("Update");
  }
};

const getTitle = (saveFilterState: SaveFilterState) => {
  switch (saveFilterState) {
    case "new":
      return _("Save new view");
    case "edit":
      return _("Edit current view");
    case "update":
      return _("Update current view");
  }
};

export const SaveFilterModal = ({
  saveFilterState,
  lastFilterName,
  filters,
  context,
  onSave,
  onCancel,
  onDelete,
  value = defaultValue,
  onChange,
}: PropTypes) => {
  const { name, shared } = value;

  const onNameChange = (name: string) => {
    onChange({ ...value, name });
  };

  const onSharedChange = (shared: boolean) => {
    onChange({ ...value, shared });
  };

  const duplicatedName = isDuplicateFilterName(
    saveFilterState === "new",
    lastFilterName,
    filters,
    name,
  );

  const nameInput = (
    <VerticalField
      key="name-input"
      label={_("View name")}
      error={!name || duplicatedName}
      input={
        <InputWithSubmit
          inputId="name-input"
          className="x-input-text qa-filter-name-input"
          hasError={duplicatedName}
          errorMessage={
            duplicatedName
              ? _("A View with this name already exists.")
              : undefined
          }
          value={name || ""}
          onSubmit={onSave}
          onChange={onNameChange}
        />
      }
    />
  );

  const sharedInput = (
    <label key="shared-input" className="qa-label-checkbox x-margin-top-5">
      <Checkbox
        className="qa-filter-shared-input"
        value={shared}
        onChange={onSharedChange}
      />
      <span className="x-margin-left-5">
        {_("Shared (anyone can see this view)")}
      </span>
    </label>
  );

  const { canCreateShared, canDelete } = getFiltersPermissions(context, [
    "CreateShared",
    "Delete",
  ]);

  const footerButtons =
    saveFilterState === "edit" && canDelete ? (
      <DeleteButton className="x-float-left qa-btn-delete" onClick={onDelete} />
    ) : undefined;

  return (
    <Modal
      content={canCreateShared ? [nameInput, sharedInput] : [nameInput]}
      title={getTitle(saveFilterState)}
      okLabel={isOkLabel(saveFilterState)}
      onOk={
        isValidFilter(saveFilterState === "new", lastFilterName, filters, value)
          ? onSave
          : undefined
      }
      onCancel={onCancel}
      footerButtons={footerButtons}
    />
  );
};
