import { defaultFor } from "common";
import { Calendar, CalendarSettings } from "common/types/calendars";
import { ListItemCell } from "common/widgets/list-with-search/cell";
import { ListItem } from "common/widgets/list-with-search/item";

interface PropTypes {
  calendar: Calendar;
  editPath: string;
}

const defaultCalendar = defaultFor<Calendar>();
const defaultSettings = defaultFor<CalendarSettings>();

export const CalendarView = ({
  calendar = defaultCalendar,
  editPath,
}: PropTypes) => {
  const { settings = defaultSettings, label, id } = calendar;
  return (
    <ListItem editPath={editPath} label={label}>
      <ListItemCell label={_("ID")}>{id}</ListItemCell>
      <ListItemCell label={_("Default")}>
        {(settings.isDefault || false).toString()}
      </ListItemCell>
    </ListItem>
  );
};
CalendarView.displayName = "CalendarView";
