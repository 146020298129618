import { getByBehaviorArguments } from "common/entities";
import type { Entities, Arguments } from "common/entities/types";

export const getPartsEntity = (workOrderEntity: string, entities: Entities) => {
  const partChargeEntity = getByBehaviorArguments(entities, "PartCharge", {
    workOrderEntity,
  });

  if (!partChargeEntity) return undefined;

  return getByBehaviorArguments(entities, "Requirement", {
    ownerEntity: workOrderEntity,
    targetEntity: partChargeEntity.name,
  });
};

export const getProceduresEntity = (
  workOrderEntity: string,
  entities: Entities,
) =>
  getByBehaviorArguments(entities, "Procedure", {
    ownerEntity: workOrderEntity,
  });

export const getAssignmentEntity = (
  workOrderEntity: string,
  entities: Entities,
) => {
  const woArgument: Arguments = {
    workOrderEntity,
  };
  return getByBehaviorArguments(entities, "Assignment", woArgument);
};
