import { Component } from "react";
import { getSubFKTitle } from "common/functions/foreign-key";
import { Selector } from "common/widgets/selector";
import { AsyncSelector } from "common/widgets/selector/async-selector";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import {
  FormatMeta,
  LabelledOptionOrGroup,
} from "common/vendor-wrappers/react-select/types";
import { liveAssetPortalApi } from "common/api/live-asset-portal";
import { recordsApi } from "common/api/records";
import { showInfoNotification } from "common/ui/notification";
import { Action } from "common/record/actions/action";
import { DismissAction, PropTypes } from "common/record/actions/types";
import {
  LiveAsset,
  LiveAssetApiError,
  LiveAssetComponent,
} from "common/record/actions/ui/live-asset-settings/types";
import { ApiCall } from "common/types/api";
import { ApiError } from "common/ui/api-error";
import { RequiredField } from "common/ui/required-field";
import { AlertWarning } from "common/widgets/alert";
import { LoadingIcon } from "common/widgets/loading-icon";
import { ValueProps } from "common/with-value-for";
import { ReactHighlightWords } from "common/vendor-wrappers/react-highlight-words";
import { Record } from "common/types/records";
import { CheckboxWidget } from "common/widgets/checkbox";
import { ForeignKey } from "common/types/foreign-key";
import { displaySite } from "common/functions/sites";
import {
  mapAssetOptions,
  getComponentLabel,
  getComponentById,
  getComponentsList,
  getAssetLocation,
  requiresExplicitAuthentication,
} from "./functions";

const MAX_LAP_ASSETS_LIMIT = 100;

export interface LiveAssetSettingsValue {
  asset: LiveAsset;
  component: LiveAssetComponent;
}

interface StateType {
  isOverrideAgreed: boolean;
  linkedX5Asset: ForeignKey;
  componentsList: LiveAssetComponent[];
  isLoading: boolean;
  error: LiveAssetApiError;
}

type Props = PropTypes & ValueProps<LiveAssetSettingsValue>;

export class LiveAssetSettingsModal extends Component<Props> {
  static readonly displayName = "LiveAssetSettingsModal";
  state: StateType = {
    isOverrideAgreed: false,
    linkedX5Asset: undefined,
    componentsList: [],
    isLoading: false,
    error: undefined,
  };

  componentDidMount() {
    const { liveAssetId, liveAssetComponentId } =
      this.props.records[0].properties;
    if (liveAssetId && liveAssetComponentId) {
      this.fetchLinkedAssetAndComponent(liveAssetId, liveAssetComponentId);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.value?.asset?.id !== this.props.value?.asset?.id &&
      !!this.props.value?.asset?.id
    ) {
      this.fetchComponentList();
    }
  }

  onCheckboxChange = (isOverrideAgreed: boolean) =>
    this.setState({ isOverrideAgreed });

  fetchLinkedAssetAndComponent = (assetId: string, componentId: string) => {
    const { context, value, onChange } = this.props;

    this.setState({ isLoading: true });
    getComponentsList(context, assetId)
      .then(({ componentsList = [], asset }) => {
        this.setState({ componentsList, isLoading: false });
        getComponentById(context, assetId, componentId)
          .then((component) => {
            this.setState({ isLoading: false, linkedX5Asset: undefined });
            onChange({ ...value, asset, component });
          })
          .catch((errorDetails) => {
            onChange({ ...value, asset });
            this.setState({
              error: {
                type: "componentError",
                errorDetails,
              },
              isLoading: false,
            });
          });
      })
      .catch((errorDetails) =>
        this.setState({
          error: {
            type: "assetError",
            errorDetails,
          },
          isLoading: false,
        }),
      );
  };

  fetchAssets = (searchKey: string, limit: number) =>
    liveAssetPortalApi(this.props.context.apiCall)
      .getAssets(searchKey, limit)
      .catch((errorDetails) => {
        this.setState({
          error: {
            type: "assetError",
            errorDetails,
          },
        });
        return [];
      });

  fetchComponentList = () => {
    const { context, value } = this.props;

    this.setState({ isLoading: true, linkedX5Asset: undefined });
    getComponentsList(context, value?.asset?.id)
      .then(({ componentsList = [] }) => {
        this.setState({ componentsList, isLoading: false });
      })
      .catch((errorDetails) =>
        this.setState({
          error: {
            type: "assetError",
            errorDetails,
          },
          isLoading: false,
        }),
      );
  };

  formatOptionLabel = (
    item: LabelledOptionOrGroup<LiveAsset>,
    meta: FormatMeta<LiveAsset>,
  ) => {
    if (isGroupedOption(item)) return item.label;

    return (
      <div className="x-select2-item clearfix">
        <div className="x-data ">
          <div className="x-title qa-title x-bold">
            <ReactHighlightWords
              text={item.value.name}
              search={meta?.inputValue}
            />
          </div>
          <div className="x-customTitle">{getAssetLocation(item.value)}</div>
        </div>
      </div>
    );
  };

  onChangeAsset = (asset: LiveAsset) => {
    const { onChange, value } = this.props;
    this.setState({ error: undefined });
    onChange({ ...value, asset, component: undefined });
  };

  onChangeComponent = (component: LiveAssetComponent) => {
    const { liveAssetComponentId } = this.props.records[0].properties;
    const { onChange, value } = this.props;

    this.setState({
      error: undefined,
      linkedX5Asset:
        liveAssetComponentId !== component?.id
          ? component?.linkedX5Asset
          : undefined,
    });
    onChange({ ...value, component });
  };

  onOk = (apiCall: ApiCall, dismiss: DismissAction) => {
    const { entity, records, value } = this.props;
    const { component, asset } = value;
    const { id } = records[0].properties;

    const componentId = component ? component.id : undefined;
    const assetId = asset ? asset.id : undefined;

    const { liveAssetId, liveAssetComponentId } = records[0].properties;

    const record: Record = {
      actions: [],
      properties: !asset
        ? { id, liveAssetId: null, liveAssetComponentId: null }
        : liveAssetId !== assetId
          ? { id, liveAssetId: assetId, liveAssetComponentId: componentId }
          : liveAssetComponentId !== componentId
            ? { id, liveAssetComponentId: componentId }
            : { id },
    };

    return recordsApi(apiCall, { hideNotifications: true })
      .update(entity.name, record)
      .then(() => {
        dismiss(false);
        showInfoNotification(
          _("Component {COMPONENT_NAME} was connected successfully").replace(
            "{COMPONENT_NAME}",
            component.name,
          ),
        );
      });
  };

  render() {
    const { context, dismiss, records, entity, value } = this.props;
    const {
      componentsList,
      isLoading,
      error,
      linkedX5Asset,
      isOverrideAgreed,
    } = this.state;

    const linkedX5AssetName = getSubFKTitle(linkedX5Asset?.title);
    const linkedX5SiteName = displaySite(context.sites)(linkedX5Asset?.site);
    const { liveAssetId, liveAssetComponentId } =
      this.props.records[0].properties;

    const recordHasLinkedAssetAndComponent = !!(
      liveAssetId && liveAssetComponentId
    );

    const isValid =
      !!(
        value?.asset?.id &&
        value?.component?.id &&
        (!linkedX5Asset || isOverrideAgreed)
      ) ||
      (recordHasLinkedAssetAndComponent && !value?.asset?.id);

    return (
      <Action
        requiresAuthentication={requiresExplicitAuthentication(entity)}
        context={context}
        dismiss={dismiss}
        entity={entity}
        records={records}
        hideRecordNumber={true}
        title={_("LIVE-Asset Settings")}
        hideEntityInTitle={true}
        btnLabel={_("Save")}
        onOk={isValid ? this.onOk : undefined}
      >
        {isLoading ? <LoadingIcon /> : undefined}
        {error ? <ApiError error={error.errorDetails} /> : undefined}
        <RequiredField
          label={_("LIVE-Asset - Asset")}
          className="qa-lap-asset"
          value={value?.asset?.name}
          input={
            <AsyncSelector<LiveAsset>
              allowClear={true}
              className="x-lookup"
              disabled={isLoading}
              placeholder={_("Select an asset")}
              fetch={this.fetchAssets}
              mapOptions={mapAssetOptions}
              formatOptionLabel={this.formatOptionLabel}
              optionsLimit={MAX_LAP_ASSETS_LIMIT}
              value={value?.asset}
              onChange={this.onChangeAsset}
            />
          }
        />
        {!(error?.type === "assetError") ? (
          value?.asset?.id && !isLoading ? (
            componentsList.length ? (
              <RequiredField
                label={_("LIVE-Asset - Components of {ASSET_NAME}").replace(
                  "{ASSET_NAME}",
                  value?.asset?.name,
                )}
                className="qa-lap-component"
                value={value?.component}
                input={
                  <Selector
                    placeholder={_("Select a component")}
                    getOptionLabel={getComponentLabel}
                    options={componentsList}
                    value={value?.component}
                    onChange={this.onChangeComponent}
                  />
                }
              />
            ) : (
              <AlertWarning
                message={_(
                  "No Component exists for this asset. Please create a Component first in LIVE-Asset or link to another asset.",
                )}
              />
            )
          ) : undefined
        ) : undefined}

        {linkedX5Asset ? (
          <>
            <AlertWarning
              message={_(
                "The selected LIVE-Asset component is already linked.",
              )}
            />
            <div className="x-lap-force-link-agreement">
              <CheckboxWidget
                label={_(
                  "I understand that the LIVE-Asset component {COMPONENT_NAME} will be unlinked from {LINKED_X5_ASSET_NAME} ({LINKED_X5_SITE_NAME}) and get linked to the current asset",
                )
                  .replace("{COMPONENT_NAME}", value?.component?.name || "")
                  .replace("{LINKED_X5_ASSET_NAME}", linkedX5AssetName || "")
                  .replace("{LINKED_X5_SITE_NAME}", linkedX5SiteName || "")}
                value={isOverrideAgreed}
                onChange={this.onCheckboxChange}
              />
            </div>
          </>
        ) : undefined}
      </Action>
    );
  }
}
