import * as R from "ramda";
import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";
import { TableConfig } from "common/query/table/types";
import {
  Filter as FilterItem,
  isAnd,
  isSelectField,
  isSummaryField,
  QueryForEntity,
  SelectExpression,
  SelectField,
} from "common/query/types";

export const getAnd = (filter: FilterItem): FilterItem[] =>
  filter ? (isAnd(filter) ? filter.and : [filter]) : [];

export const hasDescription = (query: QueryForEntity) =>
  R.any(
    (c: SelectField | SelectExpression) =>
      c.alias === "description" ||
      (!c.alias && isSelectField(c) && !c.path && c.name === "description"),
    query.query.select.filter((s) => !isSummaryField(s)),
  );

export const getConfig = (entity: Entity, isReport: boolean): TableConfig => {
  return {
    allowFilter: true,
    allowOrder: true,
    allowDrag: true,
    ignore: [],
    allowStar: !isReport,
    allowSelect: !isReport,
    allowActions: !isReport && !behaveAs("Map", entity),
  };
};
