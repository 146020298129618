export type License = "Team" | "Professional" | "Enterprise" | "Internal";

export type UserMode = "Concurrent" | "Named";

export type Status =
  | "Active"
  | "Deployed"
  | "Inactive"
  | "Maintenance"
  | "Pending";

export type Feature =
  | "calendar"
  | "conditionMonitoring"
  | "createRecordsFromWorkflows"
  | "createRequisitioningFromReorderList"
  | "customWorkflows"
  | "dashboard"
  | "dataImport"
  | "devExpress"
  | "drilldownLookups"
  | "emailDomainRules"
  | "emailTemplate"
  | "entity"
  | "entityColorCoding"
  | "entityTree"
  | "externalTrigger"
  | "failureAnalysis"
  | "flukeMobile"
  | "flukeMobileSso"
  | "form"
  | "inspectionRound"
  | "integrations"
  | "inventory"
  | "liveAssetEventsIntegration"
  | "masterUserContacts"
  | "mobileForms"
  | "mobileFormsForRelatedEntities"
  | "partsCycleCounting"
  | "partsReorderList"
  | "preventiveMaintenance"
  | "printableLabels"
  | "purchaseOrder"
  | "report"
  | "reportSchedule"
  | "requisitioning"
  | "requestorPortal2"
  | "rimeRanking"
  | "rule"
  | "scheduler"
  | "scheduler2"
  | "sharedMultipleSitesEntity"
  | "site"
  | "systemAuditTrail"
  | "systemQrCode"
  | "teletrac"
  | "userDefinedLookups"
  | "visualTemplates"
  | "workOrder"
  | "vendorPortal";

export type isEnabledFeatureType = (featureKey: Feature) => boolean;

export interface Template {
  name: string;
  isCsvTemplate?: boolean;
}

export interface Tenant {
  name: string;
  companyName: string;
  template: string;
  isCsvTemplate?: boolean;
  status: Status;
  license: License;
  createdOn: string;
  isTrial: boolean;
  trialFrom: string;
  trialTo: string;
  userMode: UserMode;
  maxUsers: number;
  maxRequestUsers: number;
  maxVendorUsers: number;
  salesForceUrl: string;
  features: Feature[];
  announcementId: number;
  slaAccepted: boolean;
  allowXTokenLogin: boolean;
  sso: boolean;
  auth0ClientId: string;
  litmosUserValidDue: string;
  maxFlukeMobileUsers: number;
  flukeMobileIntegrationUserId: string;
  enableOutboundEmails: boolean;
  parentTenantName: string;
  auth0MobileClientId: string;
  orgId: string;
}

export const licenses: License[] = [
  "Team",
  "Professional",
  "Enterprise",
  "Internal",
];

export const userModes: UserMode[] = ["Named", "Concurrent"];

export const statusList: Status[] = ["Active", "Inactive", "Maintenance"];
