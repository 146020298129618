import { withValue, WithValue } from "common/with-value";
import { StringInput } from "common/widgets/input-with-submit/string";

const defaultValue = "";

type WebLinkProps = {
  label?: string;
} & WithValue<string>;

const WebLinkComp = ({
  label,
  value = defaultValue,
  onChangeSetValue,
}: WebLinkProps) => (
  <StringInput
    label={label}
    placeholder={_("e.g. http://myweb.com")}
    value={value}
    onChange={onChangeSetValue}
  />
);

export const WebLink = withValue<string>(WebLinkComp, "WebLink");
