import type { Entities } from "common/entities/types";
import type { Header } from "common/form/types";
import { RecordInfo } from "common/record/header/record";
import type { Context } from "common/types/context";
import { DeleteButtonSmall } from "common/ui/buttons";
import type { SelectedWorkOrder } from "x/scheduler2/types";

interface PropTypes {
  context: Context;
  entities: Entities;
  selectedWorkOrder: SelectedWorkOrder;
  layout: Header;
  onClose: () => void;
}

export const HeaderSection = (props: PropTypes) => {
  const { context, entities, selectedWorkOrder, layout, onClose } = props;
  const { record, entityName } = selectedWorkOrder;
  const entity = entities[entityName];

  return (
    <div className="x-flex-between-start x-scheduler-panel-header">
      <RecordInfo
        context={context}
        entity={entity}
        layout={layout}
        withLinks={true}
        withTitleLink={true}
        value={(record && record.properties) || {}}
        onChange={undefined}
      />
      <DeleteButtonSmall className="x-margin-left-20" onClick={onClose}>
        <i className="fa fa-times" />
      </DeleteButtonSmall>
    </div>
  );
};

HeaderSection.displayName = "HeaderSection";
