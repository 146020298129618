import { Component, JSX, ReactNode } from "react";
import { ActionButtonSmall } from "common/ui/buttons";
import { InjectedProps, toggleable } from "common/widgets/toggleable";

import "./index.scss";

interface BasePropTypes {
  message: string | JSX.Element;
  className?: string;
  children?: ReactNode;
  buttons?: JSX.Element[];
}

interface Props extends BasePropTypes, InjectedProps {
  icon?: JSX.Element;
}

export class Alert extends Component<Props> {
  static readonly displayName = "Alert";

  render() {
    const {
      className,
      icon,
      message,
      toggleOpened,
      children,
      buttons,
      toggleFn,
    } = this.props;

    return (
      <div className={`x-alert ${className}`}>
        <div className="x-alert-content" data-testid="api-error">
          {icon}
          <div
            className="qa-alert-message x-alert-message"
            data-testid="alert-message"
          >
            {message}
          </div>
        </div>
        {children || buttons?.length > 0 ? (
          <div className="qa-alert-actions x-alert-actions x-flex x-flex-item-end">
            {buttons}
            {children ? (
              <ActionButtonSmall
                className="qa-toggle-details"
                onClick={toggleFn}
              >
                {toggleOpened ? _("Hide details") : _("Show details")}
              </ActionButtonSmall>
            ) : undefined}
          </div>
        ) : undefined}
        {toggleOpened ? (
          <div className="qa-alert-details x-alert-details">{children}</div>
        ) : undefined}
      </div>
    );
  }
}

export const ToggleableAlert = toggleable({ defaultOpened: false })(Alert);

const create = (displayName: string, style: string, icon?: JSX.Element) => {
  const AlertComp = ({
    className = "",
    message,
    isOpen,
    children,
    buttons,
  }: BasePropTypes & { isOpen?: boolean }) => (
    <ToggleableAlert
      className={`${style} ${className}`}
      icon={icon}
      isOpened={isOpen}
      message={message}
      children={children}
      buttons={buttons}
    />
  );
  AlertComp.displayName = displayName;
  return AlertComp;
};

export const AlertWarning = create(
  "AlertWarning",
  "x-alert-warning",
  <i className="fa fa-warning" />,
);

export const AlertError = create(
  "AlertError",
  "x-alert-error",
  <i className="fa fa-warning" />,
);

export const AlertErrorTip = create(
  "AlertErrorTip",
  "x-alert-error-tip",
  <i className="fa fa-warning" />,
);

export const AlertSuccess = create(
  "AlertSuccess",
  "x-alert-success",
  <i className="fa fa-check" />,
);

export const AlertLoading = create(
  "AlertLoading",
  "x-alert-loading",
  <i className="fa fa-spinner fa-spin" />,
);

export const AlertInfo = create(
  "AlertInfo",
  "x-alert-info",
  <i className="fa fa-info" />,
);
