import * as R from "ramda";
import { cloneElement, Component } from "react";
import { reportsApi } from "common/api/reports";
import { Entity } from "common/entities/types";
import { MapLayoutFn } from "common/form/types";
import { FormValue } from "common/record/types";
import { Context } from "common/types/context";
import { Report } from "common/types/reports";
import {
  getLayout,
  getLayoutReportIds,
  updateLayoutReportTitles,
} from "x/records/edit/functions";

interface PropTypes {
  context: Context;
  entity: Entity;
  loading: boolean;
  value: FormValue;
  mapLayout?: MapLayoutFn;
  children?: any;
}

interface StateType {
  reports: Report[];
  loadingReports: boolean;
}

export class FormWithLayout extends Component<PropTypes, StateType> {
  static readonly displayName = "FormWithLayout";

  state: StateType = {
    reports: undefined,
    loadingReports: false,
  };

  componentDidMount() {
    this.loadReports();
  }

  componentDidUpdate(prevProps: PropTypes) {
    if (prevProps.loading && !this.props.loading) this.loadReports();
  }

  loadReports = () => {
    const { value, context, entity } = this.props;
    const { apiCall, uiFormat, entities } = context;
    const reportIds = getLayoutReportIds(getLayout(context, entity, value));

    if (!reportIds.length) return;

    this.setState({ loadingReports: true });
    reportsApi(apiCall, uiFormat.culture, entities)
      .list()
      .then((reports) => this.setState({ reports, loadingReports: false }))
      .catch(() => this.setState({ loadingReports: false }));
  };

  getMappedLayout = () => {
    const { loadingReports, reports } = this.state;
    const {
      loading,
      context,
      entity,
      mapLayout = R.identity,
      value,
    } = this.props;

    const layout = getLayout(context, entity, value);

    if (loading || loadingReports) return layout;

    const reportIds = getLayoutReportIds(layout);

    const layoutWithTranslatedReportTitles =
      reportIds.length && reports
        ? updateLayoutReportTitles(
            layout,
            reports.filter((r) => R.includes(r.id, reportIds)),
          )
        : layout;

    return mapLayout(layoutWithTranslatedReportTitles);
  };

  render() {
    const { loadingReports } = this.state;
    const { loading, children } = this.props;

    return cloneElement(children, {
      layout: this.getMappedLayout(),
      loading: loading || loadingReports,
    });
  }
}
