import * as R from "ramda";
import { getLocalizedName } from "common";
import { MapConfig } from "common/data/types";
import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";
import { HorizontalField } from "common/form/ui";
import { Context } from "common/types/context";
import { colors } from "common/vendor-wrappers/leaflet/types";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import { FancyCheckboxLarge } from "common/widgets/fancy-checkbox";
import { AdvancedSingleRecordSelector } from "common/widgets/record-selector";
import { Selector } from "common/widgets/selector";
import { SelectorOption } from "common/widgets/selector/types";
import { ValueComponent } from "common/with-value-for";

interface PropTypes {
  context: Context;
}

export const getEntityOptionLabel = (option: SelectorOption<Entity>) =>
  isGroupedOption(option) ? option.label : getLocalizedName(option);

export class OutputConfigMap extends ValueComponent<MapConfig, PropTypes> {
  static readonly displayName = "OutputConfigMap";

  onChange = (entity: Entity) => this.mergeValue({ mapEntity: entity?.name });

  render() {
    const { value, context } = this.props;

    const recordSelector = value.mapEntity ? (
      <HorizontalField label={_("Map")}>
        <AdvancedSingleRecordSelector
          context={context}
          entity={context.entities[value.mapEntity]}
          withLinks={true}
          value={value.mapId}
          onChange={this.onChangeMergeValue("mapId")}
        />
      </HorizontalField>
    ) : undefined;

    const colorSelector = value.showAll ? (
      <div>
        <HorizontalField label={_("Color")}>
          <Selector
            options={colors}
            value={value.selectedPin}
            onChange={this.onChangeMergeValue("selectedPin")}
          />
        </HorizontalField>
        <HorizontalField label={_("Default Color")}>
          <Selector
            options={colors}
            value={value.defaultPin}
            onChange={this.onChangeMergeValue("defaultPin")}
          />
        </HorizontalField>
      </div>
    ) : undefined;

    const mapEntities = R.values(context.entities).filter((e) =>
      behaveAs("Map", e),
    );

    return (
      <div className="x-map-output">
        <HorizontalField label={_("Map entity")}>
          <Selector<Entity>
            options={mapEntities}
            value={context.entities[value.mapEntity]}
            getOptionLabel={getEntityOptionLabel}
            onChange={this.onChange}
          />
        </HorizontalField>
        {recordSelector}
        <FancyCheckboxLarge
          label={_("Show the full map (paint selected records)")}
          value={value.showAll}
          onChange={this.onChangeMergeValue("showAll")}
        />
        {colorSelector}
      </div>
    );
  }
}
