import { Context } from "common/types/context";
import { Component } from "common/component";
import { Ribbon } from "x/layout/ribbon";
import { AnnouncementView } from "./announcement";
import { setSeenAnnouncements } from "./announcements-controller";

interface PropTypes {
  context: Context;
}

export class AnnouncementsList extends Component<PropTypes> {
  static readonly displayName = "AnnouncementsList";
  componentDidMount() {
    const { context } = this.props;
    setSeenAnnouncements(
      context,
      context.announcements.map((a) => a.id),
    );
  }
  render() {
    const { context } = this.props;
    return (
      <div className="x-container-with-ribbon">
        <Ribbon
          onRefresh={undefined}
          scope={context.scope}
          crumbs={[{ name: _("Announcements") }]}
        />
        <div className="x-content-with-ribbon">
          <div className="x-announcements-list">
            {context.announcements.length ? (
              context.announcements.map((a) => (
                <AnnouncementView key={a.id} announcement={a} />
              ))
            ) : (
              <h2>{_("You have no Announcements")}</h2>
            )}
          </div>
        </div>
      </div>
    );
  }
}
