import { Component } from "react";
import { getError } from "common/api/error";
import {
  showErrorNotification,
  showInfoNotification,
} from "common/ui/notification";
import { ApiErrorResponse } from "common/types/error";
import { FileType } from "common/types/media";
import { CancellablePromise } from "common/types/promises";
import { FileSelector } from "common/widgets/file-selector";
import { isFileTypeAllowed } from "./functions";
import { AcceptType } from "./types";

interface PropTypes {
  uploadFile: (file: FileType) => CancellablePromise<any>;
  buttonAsIcon: boolean;
  acceptType: AcceptType;
  isNewFile: boolean;
  iconAddNewClass?: string;
  buttonText?: string;
  className?: string;
}

interface StateType {
  error?: boolean;
  uploading?: boolean;
}

export class Uploader extends Component<PropTypes, StateType> {
  state: StateType = {
    error: false,
    uploading: false,
  };

  upload = (file: FileType) => {
    const { uploadFile, acceptType } = this.props;
    if (!file) return;

    if (!isFileTypeAllowed(file.name, acceptType)) {
      this.setState({ uploading: false, error: true });
      showErrorNotification(_("Unsupported file type."));
      return;
    }

    this.setState({ uploading: true });
    uploadFile(file)
      .then(() => {
        this.setState({ uploading: false, error: false });
        showInfoNotification(_("The file was successfully uploaded."));
      })
      .catch((error: ApiErrorResponse) => {
        this.setState({ uploading: false, error: true });
        showErrorNotification(getError(error));
      });
  };

  render() {
    const {
      buttonAsIcon,
      acceptType,
      isNewFile,
      iconAddNewClass,
      buttonText,
      className = "",
    } = this.props;

    const { error, uploading } = this.state;

    const fileSelectorClassName = className + (error ? " upload-error" : "");

    const iconClass = uploading
      ? "fa-spinner fa-spin"
      : error
        ? "fa-warning"
        : isNewFile && iconAddNewClass
          ? iconAddNewClass
          : isNewFile
            ? "fa-plus-circle"
            : buttonAsIcon
              ? "fa-pencil"
              : "";

    return (
      <FileSelector
        buttonAsIcon={buttonAsIcon}
        onFileSelect={this.upload}
        buttonText={uploading ? `${_("Uploading")}...` : buttonText || ""}
        acceptType={acceptType}
        className={fileSelectorClassName}
        iconClass={iconClass}
      />
    );
  }
}
