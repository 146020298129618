import { replacePlaceholders } from "common/functions/replace-placeholders";
import type { Context } from "common/types/context";
import { removeHighlight } from "common/widgets/highlight";
import { Link } from "common/widgets/link";

export const getEditPath = (id: number | string, editPath: string) =>
  editPath?.replace(":id", removeHighlight(id.toString()));

export const parseMarkUp = (markUp: string) =>
  markUp
    .replace(/(<([^>]+)>)/gi, "") // remove markup tags
    .replace(/&[a-z]+;/gi, "") // remove HTML entities
    .replace(/\s+/g, " "); // replace multiple spaces for one

export const getSecuritySettingsHint = (context: Context, message: string) => {
  return context?.tenant?.sso
    ? replacePlaceholders(message, {
        "{SECURITY_SETTINGS}": () => (
          <Link
            href={`/#/${context.site.name}/admin/Security`}
            state={{ defaultTab: "sso" }}
          >
            {_("Security Settings")}
          </Link>
        ),
      })
    : message.replace("{SECURITY_SETTINGS}", _("Security Settings"));
};
