import { ChangeEvent } from "react";
import { RequiredField } from "common/form/ui";
import { ScheduledEventValue } from "common/record/types";
import { ValueComponent } from "common/with-value-for";

export class DescriptionForm extends ValueComponent<ScheduledEventValue> {
  static readonly displayName = "DescriptionForm";

  onChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.mergeValue3("record", "properties", "description", e.target.value);
  };

  render() {
    const value = this.props.value.record.properties.description;

    return (
      <div className="x-pm-section qa-pm-section qa-pm-description">
        <RequiredField label={_("Description")} value={value}>
          {/* TODO replace me by a widget */}
          <input type="text" value={value || ""} onChange={this.onChange} />
        </RequiredField>
      </div>
    );
  }
}
