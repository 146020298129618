import { Context } from "common/types/context";
import { createList } from "common/widgets/list-with-search";
import { MiniReport } from "x/reports/list/types";
import { ReportItem } from "./report-item";

interface PropTypes {
  editPath: string;
  previewPath: string;
  context: Context;
}

export const List = createList<MiniReport, PropTypes>(
  () => _("Reports"),
  ["label", "entityLabel"],
  ({ editPath, context, previewPath }: PropTypes, report: MiniReport) => (
    <ReportItem
      editPath={editPath.replace(":id", `${report.id}`)}
      previewPath={previewPath.replace(":id", `${report.id}`)}
      report={report}
      context={context}
    />
  ),
);
