import * as R from "ramda";
import { Node } from "./types";

export const flattenNodesFrom = (node: Node): Node[] => {
  const { children } = node;

  if (!children || !children.length) return [node];

  const childrenIds = children.reduce(
    (acc, child) => [...acc, ...flattenNodesFrom(child)],
    [],
  );

  return [node, ...childrenIds];
};

const getNode = (name: string, nodes: Node[] = []): Node =>
  nodes.find((node) => node.name === name);

const getAllParentHierarchy = (node: Node, nodes: Node[]): Node[] => {
  const parentNode = node?.parentName
    ? getNode(node.parentName, nodes)
    : undefined;
  return parentNode
    ? getAllParentHierarchy(parentNode, nodes).concat(parentNode)
    : [];
};

const getParentForSelectedNodes = (
  selectedNodes: Node[],
  nodes: Node[],
): Node[] =>
  R.uniq(
    selectedNodes.flatMap((node) =>
      getAllParentHierarchy(node, nodes).filter(
        (parentNode) => !selectedNodes.includes(parentNode),
      ),
    ),
  );

export const hasSomeChildNodeSelected = (
  parentNode: Node,
  selectedNodes: Node[] = [],
  nodes: Node[] = [],
): boolean =>
  getParentForSelectedNodes(selectedNodes, nodes).some(
    (node) => node === parentNode,
  );
