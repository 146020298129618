import { PoItem } from "common/types/purchase-orders";

export interface PropTypes {
  item: PoItem;
}

export const OrderedOverwritten = ({
  item: { orderedQty, receivedQty = 0, receivingQty = 0 },
}: PropTypes) => (
  <>
    <span className="x-previous-ordered-qty x-disabled-color">
      {orderedQty}
    </span>
    <i className="fa fa-long-arrow-right x-padding-left-5 x-padding-right-5" />
    <span>{receivedQty + receivingQty}</span>
  </>
);
