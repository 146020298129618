import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";
import { StandardValue } from "common/record/types";
import { BehaviorName } from "common/types/behaviors";
import { Context } from "common/types/context";
import { getRelatedRecordsByRelation } from "../functions";

const getSubEntityFn =
  (relatedBehaviorName: BehaviorName) => (main: Entity, related: Entity) =>
    behaveAs("ApprovalGroup", main) &&
    behaveAs(relatedBehaviorName, related) &&
    related.arguments.approvalGroupEntity === main.name;

const isApprovalCostCenterEntity = getSubEntityFn("ApprovalCostCenter");
const isApproverEntity = getSubEntityFn("Approver");
const isRequisitionerEntity = getSubEntityFn("Requisitioner");
const isRequisitionerGroupEntity = getSubEntityFn("RequisitionerGroup");

export const validateRelatedEntities = (
  context: Context,
  entity: Entity,
  value: StandardValue,
) => {
  const { entities } = context;

  const costCenterRecords = getRelatedRecordsByRelation(
    entity,
    entities,
    value,
    isApprovalCostCenterEntity,
  );
  const approverRecords = getRelatedRecordsByRelation(
    entity,
    entities,
    value,
    isApproverEntity,
  );
  const requisitionerRecords = getRelatedRecordsByRelation(
    entity,
    entities,
    value,
    isRequisitionerEntity,
  );
  const requisitionerGroupRecords = getRelatedRecordsByRelation(
    entity,
    entities,
    value,
    isRequisitionerGroupEntity,
  );

  return !!(
    costCenterRecords.length &&
    approverRecords.length &&
    (requisitionerRecords.length || requisitionerGroupRecords.length)
  );
};
