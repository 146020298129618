import { Entities, Entity } from "common/entities/types";
import { findEntityByArgs } from "common/functions/entity";
import { addFilterToQuery } from "common/query/filter";
import { addJoinToQuery } from "common/query/joins";
import { addToSelectQuery } from "common/query/select";
import { Filter, QueryForEntity } from "common/query/types";

export const getApproverQuery = (
  entities: Entities,
  entity: Entity,
  approvalGroupId: string,
  contactId: string,
): QueryForEntity => {
  const approvalGroupName = entity?.arguments?.approvalGroupEntity;
  const approverEntity = findEntityByArgs(
    entities,
    "Approver",
    "approvalGroupEntity",
    approvalGroupName,
  );

  if (!approverEntity) return undefined;

  const { query } = approverEntity;

  const queryWithApprovalGroupJoin = addJoinToQuery(
    { column: "approvalGroupId" },
    query,
  );
  const queryWithContactJoin = addJoinToQuery(
    { column: "contactId" },
    queryWithApprovalGroupJoin,
  );
  const queryWithCostLimitSelect = addToSelectQuery(
    [{ name: "costLimit" }],
    queryWithContactJoin,
  );

  const filters: Filter = {
    and: [
      { name: "isDeleted", op: "isfalse" },
      {
        name: "id",
        op: "eq",
        path: "/approvalGroupId",
        value: approvalGroupId,
      },
      {
        name: "id",
        op: "eq",
        path: "/contactId",
        value: contactId,
      },
    ],
  };

  return {
    entity: approverEntity.name,
    query: addFilterToQuery(filters, queryWithCostLimitSelect),
  };
};
