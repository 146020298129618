import { useState } from "react";
import { CallbackButton } from "common/widgets/callback-button";
import { ReadOnlyText } from "common/widgets/text";

interface PropTypes {
  id: string;
  text: string;
  onDelete: (commentId: string, index?: number) => void;
  canDelete?: boolean;
  isNew?: boolean;
}

export const CommentBubble = ({
  id,
  text,
  canDelete,
  onDelete,
  isNew,
}: PropTypes) => {
  const [isHovering, setIsHovering] = useState(false);

  const onMouseEnter = () => setIsHovering(true);
  const onMouseLeave = () => setIsHovering(false);

  return (
    <div
      className={`x-bubble-container ${text ? "" : "x-margin-top-5"}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {text ? (
        <div className="x-bubble">
          <ReadOnlyText value={text} />
          {isNew && <span className="x-new-comment" />}
        </div>
      ) : undefined}
      {canDelete && isHovering && (
        <CallbackButton
          className="x-delete"
          data={id}
          title={_("Delete")}
          callback={onDelete}
        >
          <i className="fa fa-trash-o" />
        </CallbackButton>
      )}
    </div>
  );
};

CommentBubble.displayName = "CommentBubble";
