import * as R from "ramda";
import { defaultFor, update } from "common";
import {
  extract,
  getKeys,
  translateKey,
} from "common/culture/dashboard-translator";
import { Context } from "common/types/context";
import { Dashboard, Widget } from "common/types/dashboards";
import { Role } from "common/types/roles";
import { ActionButtonLarge } from "common/ui/buttons";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import { AlertWarning } from "common/widgets/alert";
import { FancyCheckboxLarge } from "common/widgets/fancy-checkbox";
import { StringInput } from "common/widgets/input-with-submit/string";
import { Required } from "common/widgets/required";
import { Selector } from "common/widgets/selector";
import { SelectorOption } from "common/widgets/selector/types";
import { ValueProps } from "common/with-value-for";
import { LabelsComponent, LabelsTable } from "x/culture/labels";
import { DashboardFormType } from "x/dashboard/form/types";
import {
  defaultWidgetConfig,
  getName,
  validateDashboardName,
} from "./functions";
import { WidgetForm } from "./widget";
import { WidgetList, WidgetListValue } from "./widget-list";

interface PropTypes {
  context: Context;
  dependencies: {
    roles: Role[];
  };
}

interface StateType {
  disableGlobalCheckbox: boolean;
}

type Props = PropTypes & ValueProps<DashboardFormType>;

const defaultValue = defaultFor<DashboardFormType>();
const defaultDashboard = defaultFor<Dashboard>();

export const getRoleOptionLabel = (option: SelectorOption<Role>) =>
  isGroupedOption(option) ? option.label : option.name;

export class DashboardForm extends LabelsComponent<
  Dashboard,
  DashboardFormType,
  PropTypes,
  StateType
> {
  constructor(props: Props) {
    super(props, (d) => extract(this.props.context.uiFormat.culture, d));
    this.state = {
      disableGlobalCheckbox: !!props.value?.item?.isGlobal,
    };
  }

  updateDashboard = (v: Partial<DashboardFormType>) => {
    const { context } = this.props;
    const { item } = v;
    this.setValue({
      selected: undefined,
      page: undefined,
      ...v,
      item: extract(context.uiFormat.culture, item),
    });
  };

  onAddWidget = () => {
    const { context, value = defaultValue } = this.props;
    const { site } = context;
    const { item } = value;
    const newWidgetLabel = getName(item.widgets);
    const newWidget = R.mergeRight(defaultWidgetConfig(newWidgetLabel), {
      site: site.name,
    });

    return this.updateDashboard({
      selected: newWidgetLabel,
      item: R.mergeRight(item, {
        widgets: [newWidget].concat(item.widgets),
      }),
    });
  };

  onChangeWidgets = (newListValue: WidgetListValue) => {
    const { value = defaultValue } = this.props;
    const { item } = value;
    const { widgets = [], selected } = newListValue;

    this.updateDashboard({
      item: R.mergeRight(item, { widgets }),
      selected: R.includes(selected, widgets)
        ? selected.name
        : widgets[0] && widgets[0].name,
    });
  };

  onChangeLabel = (label: string) => {
    const { value = defaultValue } = this.props;
    const { item } = value;
    this.updateDashboard({
      item: R.mergeRight(item, { label }),
    });
  };

  onChangeSelectedWidget = (selected: Widget) => {
    const { value = defaultValue } = this.props;
    const { item } = value;

    const widgets = update(
      R.find((w) => w.name === selected.name, item.widgets),
      selected,
      item.widgets,
    );
    this.updateDashboard({
      selected: value.selected,
      item: { ...item, widgets },
    });
  };

  onChangeRole = (newValue: Role) => {
    const { value = defaultValue } = this.props;
    const { item } = value;

    const roleId = newValue?.id;

    this.updateDashboard({
      item: R.mergeRight(item, { roleId, isGlobal: false }),
    });
  };

  onGlobalChange = (isGlobal: boolean) => {
    const { value = defaultValue } = this.props;
    const { item } = value;

    this.updateDashboard({
      item: R.mergeRight(item, { roleId: undefined, isGlobal }),
    });
  };

  render() {
    const { disableGlobalCheckbox } = this.state;
    const { context, value = defaultValue, dependencies } = this.props;
    const { dashboards } = context;
    const { roles = [] } = dependencies;
    const { selected } = value;

    const dashboard = value.item || defaultDashboard;
    const isGlobal = dashboard?.isGlobal;
    const { widgets = [], label, roleId } = dashboard;
    const selectedName =
      selected || (widgets.length && widgets[0] && widgets[0].name);
    const selectedWidget = R.find((w) => w.name === selectedName, widgets);
    const isDashboardNameValid = validateDashboardName(dashboard, dashboards);

    return (
      <div className="x-dashboard-edit">
        <div
          className={`x-section-header ${
            label && !isDashboardNameValid ? "x-margin-bottom-0-i" : ""
          }`}
        >
          <Required
            value={isDashboardNameValid || undefined}
            className="x-dashboard-name"
          >
            <StringInput
              className="qa-dashboard-name"
              placeholder={_("e.g. Dashboard Name")}
              value={label || ""}
              onChange={this.onChangeLabel}
            />
          </Required>
          {roles.length ? (
            <div className="x-dashboard-role qa-role">
              <Selector<Role>
                allowClear={true}
                disabled={isGlobal}
                placeholder={`${_("Select a role")}...`}
                getOptionLabel={getRoleOptionLabel}
                options={roles}
                onChange={this.onChangeRole}
                value={
                  dashboard && roleId && R.find((r) => r.id === roleId, roles)
                }
              />
              <FancyCheckboxLarge
                disabled={disableGlobalCheckbox}
                label={_("Global")}
                onChange={this.onGlobalChange}
                value={isGlobal}
              />
            </div>
          ) : undefined}
          <ActionButtonLarge
            className="x-add-widget qa-add-widget"
            onClick={this.onAddWidget}
          >
            {_("Add Widget")}
          </ActionButtonLarge>
        </div>
        {label && !isDashboardNameValid ? (
          <AlertWarning
            className="x-margin-bottom-20-i"
            message="A dashboard with this name already exists"
          />
        ) : undefined}
        <div className="x-dashboard-edit-content">
          <div className="x-dashboard-edit-widget-list-wrap qa-widget-list">
            <h3 className="x-dashboard-edit-widget-list-title">
              {_("Widgets")}
            </h3>
            <div className="x-dashboard-edit-widget-list">
              {selectedWidget ? (
                <WidgetList
                  value={{ widgets, selected: selectedWidget }}
                  onChange={this.onChangeWidgets}
                />
              ) : null}
            </div>
          </div>
          <div className="x-dashboard-edit-widget-content">
            {selectedWidget ? (
              <WidgetForm
                context={context}
                value={selectedWidget}
                onChange={this.onChangeSelectedWidget}
              />
            ) : (
              <p>{_("Select one widget from the list or add a new one")}.</p>
            )}
          </div>
        </div>
        <LabelsTable<Dashboard, DashboardFormType>
          allCultures={false}
          context={context}
          keys={getKeys(dashboard)}
          translateKey={translateKey(dashboard)}
          value={value}
          onChange={this.onChangeSetValue}
        />
      </div>
    );
  }
}
