import { JSX } from "react";
import { defaultFor } from "common";
import { CancelButtonLarge } from "common/ui/buttons";
import { LoadingButton } from "common/widgets/loading-button";

export interface LoadingButtonLabels {
  idle: string;
  loading: string;
}

export interface PropTypes {
  isNew: boolean;
  isValid: boolean;

  canCancel: boolean;
  onCancel?: () => void;
  cancelLabel?: string;
  disableCancel?: boolean;

  canSave: boolean;
  onSave?: () => void;
  saveLabels?: LoadingButtonLabels;
  isSaving?: boolean;

  canDelete: boolean;
  onDelete?: () => void;
  isDeleting?: boolean;
  deleteLabels?: LoadingButtonLabels;

  canRestore: boolean;
  onRestore?: () => void;
  isRestoring?: boolean;
  restoreLabels?: LoadingButtonLabels;

  extraActionButton?: JSX.Element;

  deleteWarningMessage?: string;

  footerContent?: JSX.Element;

  noOverlay?: boolean;
  children?: any;
}

const qaClass = (s: string) => (s ? "qa-btn-add" : "qa-btn-save");

export const FormFooter = ({
  isNew,
  isValid,
  canCancel,
  onCancel,
  disableCancel,
  cancelLabel,
  canSave,
  onSave,
  isSaving,
  saveLabels = defaultFor(),
  canDelete,
  onDelete,
  isDeleting,
  deleteLabels = defaultFor(),
  canRestore,
  onRestore,
  isRestoring,
  restoreLabels = defaultFor(),
  deleteWarningMessage,
  footerContent,
  noOverlay,
  children,
  extraActionButton,
}: PropTypes) => (
  <div className={`x-form-footer${noOverlay ? "" : " x-footer-overlay"}`}>
    <div className="x-form-footer-content x-flex">
      {!isNew && canDelete ? (
        <LoadingButton
          className="x-margin-right-10 qa-btn-delete"
          tooltip={deleteWarningMessage}
          tooltipVariant="error"
          loadingText={deleteLabels.loading || _("Deleting")}
          idleText={deleteLabels.idle || _("Delete")}
          loading={isDeleting}
          disabled={!!deleteWarningMessage}
          onClick={deleteWarningMessage ? undefined : onDelete}
          type="delete"
          size="large"
        />
      ) : undefined}
      {!isNew && canRestore ? (
        <LoadingButton
          className="x-margin-right-10 qa-btn-restore"
          loadingText={restoreLabels.loading || _("Restoring")}
          idleText={restoreLabels.idle || _("Restore")}
          loading={isRestoring}
          onClick={onRestore}
          type="action"
          size="large"
        />
      ) : undefined}
      <div className="x-flex-grow-1">
        {footerContent}
        {children}
      </div>
      {canCancel ? (
        <CancelButtonLarge
          onClick={onCancel}
          className="x-margin-left-10 qa-btn-cancel"
          disabled={disableCancel}
        >
          {cancelLabel || _("Cancel")}
        </CancelButtonLarge>
      ) : undefined}
      {canSave ? (
        <LoadingButton
          className={qaClass(saveLabels.idle)}
          loadingText={saveLabels.loading || _("Saving")}
          idleText={saveLabels.idle || _("Save")}
          disabled={!isValid}
          loading={isSaving}
          onClick={onSave}
          type="modify"
          size="large"
        />
      ) : undefined}
      {extraActionButton}
    </div>
  </div>
);

FormFooter.displayName = "FormFooter";
