import { getWindowLocation } from "common/utils/window-location";

const isQAEnvironment = (host: string) =>
  host.indexOf(".addqa1.x5beta.") >= 0 ||
  host.indexOf(".addqa2.x5beta.") >= 0 ||
  host.indexOf(".qa.x5beta.") >= 0;

export const getEnvironment = () => {
  const windowLocation = getWindowLocation();
  const host = windowLocation?.host?.toLowerCase();

  return !host
    ? undefined
    : isQAEnvironment(host)
      ? "x5-qa"
      : host.indexOf(".ci.x5beta.") >= 0
        ? "x5-ci"
        : host.indexOf(".dev.x5beta.") >= 0
          ? "x5-dev"
          : host.indexOf(".x5beta.") >= 0
            ? "x5-beta"
            : host.indexOf("localhost") >= 0
              ? "x5-localhost"
              : "x5-prod";
};

export const isLocalEnvironment = () => getEnvironment() === "x5-localhost";
