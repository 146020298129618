import { Children, cloneElement, Component } from "react";
import { defaultFor } from "common";
import { ApiErrorResponse } from "common/types/error";
import { CancellablePromise } from "common/types/promises";
import { ApiError } from "common/ui/api-error";

export interface PropTypes<TValue, TRecord> {
  fetch: (value?: TValue) => CancellablePromise<TRecord[]>;
  value?: TValue;
  onChange?: (value: TValue) => void;
  defaultRecords?: TRecord[];
  dontFetch?: boolean;
  notOlderThan?: number;
  children?: any;
  passError?: (error: any) => boolean; // check if used
}

interface StateType<TRecord> {
  loading?: boolean;
  error?: any;
  records?: TRecord[];
}

export interface Injected<TValue, TRecord> {
  loading: boolean;
  error: ApiErrorResponse;
  records: TRecord[];
  value: TValue;
  onChange: (value: TValue) => void;
  reload: () => any;
}

export function createInjected<TValue, TRecord>() {
  return defaultFor<Injected<TValue, TRecord>>();
}

export class FetchRecords<TValue, TRecord> extends Component<
  PropTypes<TValue, TRecord>,
  StateType<TRecord>
> {
  fetchRecordsRequest: CancellablePromise<unknown>;

  constructor(props: PropTypes<TValue, TRecord>) {
    super(props);
    this.state = {
      records: props.defaultRecords || [],
    };
  }

  componentDidMount() {
    if (!this.props.dontFetch) this.load();
  }

  componentWillUnmount() {
    this.fetchRecordsRequest?.cancel();
  }

  componentDidUpdate(prevProps: PropTypes<TValue, TRecord>) {
    const { notOlderThan } = prevProps;
    if (
      this.props.notOlderThan &&
      (!notOlderThan || this.props.notOlderThan > notOlderThan)
    )
      this.load();
  }

  load = () => {
    const { fetch, value = defaultFor<TValue>() } = this.props;
    this.setState({ loading: true, error: undefined, records: undefined });

    this.fetchRecordsRequest = fetch(value)
      .then((records) =>
        this.setState({ loading: false, error: undefined, records }),
      )
      .catch((error) =>
        this.setState({ loading: false, error, records: undefined }),
      );
  };

  render() {
    const { dontFetch, children, value, onChange } = this.props;
    const { loading, error, records } = this.state;

    const passError =
      error && this.props.passError && this.props.passError(error);

    if (error && !passError) {
      return (
        <div className="x-list-fetch-records">
          <ApiError error={error} />
        </div>
      );
    }

    const injected: Injected<TValue, TRecord> = {
      loading,
      error: passError ? error : undefined,
      records,
      value,
      onChange,
      reload: dontFetch ? undefined : this.load,
    };

    return (
      <div className="x-list-fetch-records">
        {Children.toArray(children).map((c) =>
          cloneElement(c as any, injected),
        )}
      </div>
    );
  }
}
