import { Fragment, ReactNode } from "react";

const replacePlaceholder = (
  str: string,
  placeholder: string,
  replaceFn: () => ReactNode,
) =>
  str
    .split(placeholder)
    .flatMap((subChunk, index, allSubChunks) =>
      index < allSubChunks.length - 1
        ? [
            subChunk,
            <Fragment key={`${placeholder}${index}`}>{replaceFn()}</Fragment>,
          ]
        : subChunk,
    );

export const replacePlaceholders = (
  str: string,
  replacements: { [placeholder: string]: () => ReactNode },
) => {
  const withReplaced = Object.keys(replacements)
    .reduce(
      (acc, placeholder) =>
        acc.flatMap((chunk) =>
          typeof chunk === "string"
            ? replacePlaceholder(chunk, placeholder, replacements[placeholder])
            : chunk,
        ),
      [str],
    )
    .filter((p) => p);
  return <>{withReplaced}</>;
};
