import type { User } from "common/functions/users";
import { ApiCallFull } from "common/types/api";
import { RawSettings, SettingsBase } from "common/types/context";
import { CancellablePromise } from "common/types/promises";
import { RawSystemTables } from "common/types/system-table";
import { getData } from ".";

const baseTableNames = [
  "AssignmentStatus",
  "PurchaseOrderStatus",
  "PurchaseOrderItemStatus",
  "PartRequisitioningStatus",
  "PartRequisitioningItemStatus",
  "EntityColumnType",
  "ProcedureType",
  "ProcedureStatus",
  "MeterType",
  "SecurityEventTypes",
  "VisualTemplates",
  "DomainLoginType",
];

const getTableNamesForFeature = (feature: string) => {
  if (feature === "partsCycleCounting") {
    return ["CycleCountStatus"];
  }

  return [];
};

const getSystemTableNames = (ui: SettingsBase) => {
  const featureTableNames =
    ui?.tenant?.features?.flatMap(getTableNamesForFeature) ?? [];
  return baseTableNames.concat(featureTableNames);
};

export const uiApi = (apiCallFull: ApiCallFull) => ({
  load: (): CancellablePromise<RawSettings> => {
    const settingsRequest = apiCallFull<SettingsBase>("get", "api/ui").then(
      getData,
    );

    const contextRequest = settingsRequest.then((baseSettings) => {
      return apiCallFull<RawSystemTables>("post", "api/ui/systemTables", {
        tableNames: getSystemTableNames(baseSettings),
      })
        .then(getData)
        .then((systemTables) => ({
          ...baseSettings,
          systemTables,
        }));
    });

    const userRequest = apiCallFull<User>("get", "api/ui/user").then(getData);

    return CancellablePromise.all([contextRequest, userRequest]).then(
      ([context, user]) => {
        return {
          ...context,
          userContactInfo: user.userContactInfo,
        };
      },
    );
  },

  loadForQrCode: (siteName: string, entity: string, assetId: string) =>
    apiCallFull<RawSettings>(
      "get",
      `api/ui/qrcode/${siteName}/${entity}/${assetId}`,
    ).then(getData),
});
