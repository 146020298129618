import { Properties } from "common/types/records";
import { injected } from "common/record/form/content/related/table-with-form/functions";
import { ValueProps } from "common/with-value-for";
import { StandardRelated } from "../standard-related";
import { PropTypes } from "../table-with-form/types";
import { RelatedValue } from "../types";
import { EventForm } from "./form";

export const Event = (props: PropTypes & ValueProps<RelatedValue>) => (
  <StandardRelated {...props} onChange={props.onChange}>
    <EventForm
      {...injected<Properties>()}
      context={props.context}
      entity={props.entity}
      defaultForm={props.defaultForm}
      isTemplate={props.isTemplate}
      value={props.value.related.form}
      displayTypes={props.displayTypes}
      onCancel={props.onCancel}
    />
  </StandardRelated>
);

Event.displayName = "Event";
