import { JSX } from "react";
import { Component } from "common/component";
import { getUrl } from "common/entities";
import { Entity } from "common/entities/types";
import { hasPermissionToCreate } from "common/functions/roles";
import { Context } from "common/types/context";
import { Form } from "common/types/forms";
import { ModifyButtonSmall, ModifyLinkButtonSmall } from "common/ui/buttons";
import { eventLabel, trackEvent } from "common/utils/mixpanel";
import { DropdownMenu } from "common/widgets/dropdown-menu";
import { Link } from "common/widgets/link";

interface PropTypes {
  context: Context;
  entity: Entity;
  site: string;
  forms: Form[];
  title?: string;
  disabled?: boolean;
  onClick?: (form: Form) => () => void;
  menuItem?: (form: Form) => JSX.Element;
  newPath?: string;
  newButtonLabel?: string;
}

const defaultForm: Form = {
  entityName: undefined,
  settings: undefined,
  labels: undefined,
  sites: undefined,
  id: 0,
  label: "Default",
};

export class NewRecordMenu extends Component<PropTypes> {
  static readonly displayName = "NewRecordMenu";
  onTrack = (form: Form = defaultForm) => {
    const { entity } = this.props;
    return () =>
      trackEvent(eventLabel.createRecord, {
        entity: entity.name,
        formId: form.id,
        formLabel: form.label,
      });
  };
  getMenu = (forms: Form[], newUrl: string) => {
    const { menuItem } = this.props;
    return forms.map((form, i) => (
      <div key={i} onClick={this.onTrack(form)}>
        {menuItem ? (
          <a className="dropdown-item">{menuItem(form)}</a>
        ) : (
          <Link href={`${newUrl}/${form.id}`} className="dropdown-item">
            {form.label}
          </Link>
        )}
      </div>
    ));
  };
  render() {
    const {
      context,
      entity,
      site,
      forms = [],
      onClick,
      title,
      disabled,
      newPath,
      newButtonLabel: newButtonLabel,
    } = this.props;
    const { role, userTypes } = context;

    if (!entity || !hasPermissionToCreate(userTypes, role, entity.name))
      return null;

    const newUrl = newPath ? newPath : `${getUrl(entity, site)}/new`;

    if (forms.length <= 1) {
      const url = !forms.length ? newUrl : newUrl + "/" + forms[0].id;

      const buttonContent = (
        <div
          className="qa-btn-content"
          onClick={this.onTrack(!forms.length ? undefined : forms[0])}
        >
          {newButtonLabel ? newButtonLabel : _("New")}
        </div>
      );

      return onClick ? (
        <ModifyButtonSmall
          disabled={disabled}
          className="qa-btn-new-form"
          onClick={onClick && onClick(forms[0])}
          title={title}
        >
          {buttonContent}
        </ModifyButtonSmall>
      ) : (
        <ModifyLinkButtonSmall
          disabled={disabled}
          className="qa-btn-new-form"
          href={url}
          title={title}
        >
          {buttonContent}
        </ModifyLinkButtonSmall>
      );
    }

    return (
      <DropdownMenu
        button={
          <ModifyButtonSmall className="qa-btn-new-form" disabled={disabled}>
            {_("New")} <i className="fa fa-caret-down" />
          </ModifyButtonSmall>
        }
        menu={this.getMenu(forms, newUrl)}
      />
    );
  }
}
