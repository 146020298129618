import * as R from "ramda";
import { JSX } from "react";
import { EntryBox } from "common/widgets/entry-box";
import { withList, WithList } from "common/with-list";
import { ValueComponent, ValueProps } from "common/with-value-for";

type Value<T> = T[];

export interface BlockInfo {
  itemIndex: number;
  message?: string;
}

interface PropTypes<T> {
  display: (x: T) => string | JSX.Element;
  itemClass?: (x: T) => string;
  className?: string;
  withDefaultValue?: boolean;
  blockRemoving?: BlockInfo[];
}

export type Props<T> = PropTypes<T> & ValueProps<Value<T>>;

export class EntryBoxList<T> extends ValueComponent<Value<T>, PropTypes<T>> {
  static readonly displayName = "EntryBoxList";
  entryList: WithList<boolean>;

  constructor(props: Props<T>) {
    super(props);
    this.entryList = withList<boolean>({
      update: undefined,
      remove: this.onRemoveEntry,
    });
  }

  onRemoveEntry = (index: number) => {
    const { value = [], withDefaultValue } = this.props;
    this.setValue(R.remove(index, 1, value), withDefaultValue);
  };

  findBlockedByIndex = (index: number) => {
    const { blockRemoving = [] } = this.props;
    return R.find((blocked) => blocked.itemIndex === index, blockRemoving);
  };

  render() {
    const { display, itemClass, className = "", value = [] } = this.props;
    return (
      <ul className="row unstyled x-entry-box-list">
        {value
          .map((item, index) => {
            const thisClass = itemClass ? itemClass(item) : "";
            const itemLabel = display(item);
            const blockedItem = this.findBlockedByIndex(index);
            return itemLabel ? (
              <li
                key={index}
                className={`col-sm-6 col-md-4 col-lg-3 ${className} ${thisClass}`}
              >
                <EntryBox
                  className={`qa-entry-${index}`}
                  label={itemLabel}
                  blockedMessage={blockedItem && blockedItem.message}
                  onRemove={
                    blockedItem ? undefined : this.entryList.remove(index)
                  }
                />
              </li>
            ) : undefined;
          })
          .filter((l) => !!l)}
      </ul>
    );
  }
}
