import { Component } from "react";
import { Entity } from "common/entities/types";
import { FormValidationProps } from "common/form/types";
import { InspectionFormValue } from "common/scheduled-event/form/inspection/types";
import { getEventAssetsIds } from "common/scheduled-event/functions";
import { Context } from "common/types/context";
import { Inspection } from "common/types/scheduled-event";
import { ValueProps } from "common/with-value-for";
import { CustomFieldsForm } from "../custom-fields";
import { DescriptionForm } from "../description";
import { TaskForm } from "../tasks";
import { WithProjections } from "../with-projections";

interface PropTypes
  extends ValueProps<InspectionFormValue>,
    FormValidationProps {
  context: Context;
  entity: Entity;
  woEntity: Entity;
}

export class DetailsForm extends Component<PropTypes> {
  static readonly displayName = "DetailsForm";

  onInspectionChange = (inspection: Inspection) => {
    const { value, onChange } = this.props;
    onChange({
      ...value,
      record: {
        ...value.record,
        properties: inspection,
      },
    });
  };

  render() {
    const {
      context,
      entity,
      woEntity,
      formValidation,
      onFormValidationChange,
      value,
      onChange,
    } = this.props;
    const { properties } = value.record;
    const assetsIds = getEventAssetsIds(
      value.record,
      entity.arguments.eventAssetEntity,
    );

    return (
      <div className="x-pm-trigger form-horizontal">
        {entity.arguments.taskEntity ? (
          <TaskForm
            context={context}
            entities={context.entities}
            entity={entity}
            value={value}
            onChange={onChange}
          />
        ) : undefined}
        <DescriptionForm value={value} onChange={onChange} />
        <WithProjections
          isShadow={false}
          context={context}
          entity={entity}
          assetsIds={assetsIds}
          value={value.record.properties}
          onChange={this.onInspectionChange}
        />
        <CustomFieldsForm
          context={context}
          woEntity={woEntity}
          entity={entity}
          formValidation={formValidation}
          onFormValidationChange={onFormValidationChange}
          value={properties}
          onChange={this.onInspectionChange}
        />
      </div>
    );
  }
}
