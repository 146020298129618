import {
  BehaviorName,
  Behavior,
  BlueprintArgumentName,
} from "common/types/behaviors";

export const getBehaviorNameTranslation = (
  behaviorName: BehaviorName,
): string => {
  switch (behaviorName) {
    case "Adjustment":
      return _("Adjustment");
    case "AnalysisTypes":
      return _("Analysis Types");
    case "ApprovalCostCenter":
      return _("Approval Cost Center");
    case "ApprovalGroup":
      return _("Approval Group");
    case "Approver":
      return _("Approver");
    case "Asset":
      return _("Asset");
    case "AssetPart":
      return _("Asset Part");
    case "AssetMeter":
      return _("Asset Meter");
    case "AssetMeterReading":
      return _("Asset Meter Reading");
    case "Assignment":
      return _("Assignment");
    case "Barcode":
      return _("Barcode");
    case "Batch":
      return _("Batch");
    case "BatchCharge":
      return _("Batch Charge");
    case "Calendar":
      return _("Calendar");
    case "Comments":
      return _("Comments");
    case "CurrencyTranslation":
      return _("Currency Translation");
    case "CycleCount":
      return _("Cycle Count");
    case "Description":
      return _("Description");
    case "Email":
      return _("Email Action");
    case "EmailDomainRules":
      return _("Email Domain Rules");
    case "Event":
      return _("Event");
    case "EventAsset":
      return _("Event Asset");
    case "ExternalId":
      return _("Fluke Connect Sync");
    case "FailureAnalysis":
      return _("Failure Analysis");
    case "FlukeMobile":
      return _("Fluke Mobile");
    case "FunctionalLocation":
      return _("Functional Location");
    case "FollowUp":
      return _("Follow Up");
    case "GeoLocation":
      return _("Geo Location");
    case "GlobalCounter":
      return _("Global Counter");
    case "Group":
      return _("Group");
    case "GroupMember":
      return _("Group Member");
    case "Attachment":
      return _("Attachment");
    case "AttachmentColumn":
      return _("Attachment Column");
    case "Journal":
      return _("Journal");
    case "KitItem":
      return _("Kit Item");
    case "LaborCharge":
      return _("Labor Charge");
    case "LiveAssetIntegration":
      return _("LIVE-Asset Integration");
    case "LiveAssetAlarmEvent":
      return _("LIVE-Asset Alarm Event");
    case "LiveAssetComponentStatusChangeEvent":
      return _("LIVE-Asset Component Status Change Event");
    case "LiveAssetSystemEvent":
      return _("LIVE-Asset System Event");
    case "LocationDefinition":
      return _("Location Definition");
    case "Map":
      return _("Interactive Map");
    case "Meter":
      return _("Meter");
    case "Order":
      return _("Order");
    case "Part":
      return _("Part");
    case "PartCharge":
      return _("Part Charge");
    case "PartsReorder":
      return _("Parts Reorder List");
    case "PartSupplier":
      return _("Part Supplier");
    case "PartsToBeCounted":
      return _("Parts to be Counted");
    case "Procedure":
      return _("Procedure");
    case "ProcedureType":
      return _("Procedure Type");
    case "MeterType":
      return _("Meter Type");
    case "PurchaseOrder":
      return _("Purchase Order");
    case "PurchaseOrderItem":
      return _("Purchase Order Item");
    case "Pure":
      return _("Pure");
    case "Procurement":
      return _("Procurement");
    case "Rate":
      return _("Rate");
    case "Reason":
      return _("Reason");
    case "Record":
      return _("Record");
    case "Reference":
      return _("Reference");
    case "RelatedCalendar":
      return _("Related Calendar");
    case "RepairablePart":
      return _("Repairable Part");
    case "RepairablePartCharge":
      return _("Repairable Part Charge");
    case "Request":
      return _("Request");
    case "Requisitioner":
      return _("Requisitioner");
    case "RequisitionerGroup":
      return _("Requisitioner Group");
    case "Requisitioning":
      return _("Requisition");
    case "RequisitioningItem":
      return _("Requisition Item");
    case "Requirement":
      return _("Requirement");
    case "ScheduledEvent":
      return _("Scheduled Event");
    case "Signature":
      return _("Signature");
    case "Stock":
      return _("Stock");
    case "SubEntity":
      return _("Sub-Entity");
    case "Supplier":
      return _("Supplier");
    case "SystemQrCode":
      return _("System QR Code");
    case "Task":
      return _("Task");
    case "TaskItem":
      return _("Task Item");
    case "TaxRate":
      return _("Tax Rate");
    case "ToDo":
      return _("To Do");
    case "Transfer":
      return _("Transfer");
    case "Tree":
      return _("Tree");
    case "ConditionMonitoring":
      return _("Condition Monitoring");
    case "User":
      return _("User");
    case "WorkOrder":
      return _("WorkOrder");
    case "WorkOrderAsset":
      return _("Work Order Asset");
    case "WorkOrderAssetRef":
      return _("Work Order Asset Reference");
    case "WorkOrderSubEntity":
      return _("Work Order Sub-Entity");
    default:
      return "";
  }
};

export const getBehaviorTranslation = (b: Behavior): string =>
  (b && b.name && getBehaviorNameTranslation(b.name)) || "";

export const getBlueprintArgumentName = (
  blueprintArgumentName: BlueprintArgumentName,
): string => {
  switch (blueprintArgumentName) {
    case "AdjustmentEntity":
      return _("Adjustment Entity");
    case "AdjustmentTypeEntity":
      return _("Adjustment Type Entity");
    case "ApprovalCostCenterEntity":
      return _("Approval Cost Center Entity");
    case "ApprovalGroupEntity":
      return _("Approval Group Entity");
    case "AssetEntity":
      return _("Asset Entity");
    case "AssetMeterEntity":
      return _("Asset Meter Entity");
    case "AssetPartEntity":
      return _("Asset Part Entity");
    case "BarcodeColumn":
      return _("Barcode Column");
    case "BatchEntity":
      return _("Batch Entity");
    case "ColumnName":
      return _("Column Name");
    case "ContactEntity":
      return _("Contact Entity");
    case "CostCenterReference":
      return _("Cost Center Reference");
    case "EventAssetEntity":
      return _("Event Asset Entity");
    case "FkColumn":
      return _("Foreign Key Column");
    case "GroupEntity":
      return _("Group Entity");
    case "LocationEntity":
      return _("Location Entity");
    case "MeterEntity":
      return _("Meter Entity");
    case "OwnerColumn":
      return _("Owner Column");
    case "OwnerEntity":
      return _("Owner Entity");
    case "PartChargeEntity":
      return _("Part Charge Entity");
    case "PartEntity":
      return _("Part Entity");
    case "PartSupplierEntity":
      return _("Part Supplier Entity");
    case "ProcedureEntity":
      return _("Procedure Entity");
    case "PurchaseOrderEntity":
      return _("Purchase Order Entity");
    case "Quantity":
      return _("Quantity");
    case "RateTypeEntity":
      return _("Rate Type Entity");
    case "RateTypeEntityColumn":
      return _("Rate Type Entity Column");
    case "RepairablePartEntity":
      return _("Repairable Part Entity");
    case "RequestEntity":
      return _("Request Entity");
    case "RequisitioningEntity":
      return _("Requisition Entity");
    case "ScheduledEventEntity":
      return _("Scheduled Event Entity");
    case "ShippingMethodEntity":
      return _("Shipping Method Entity");
    case "ShippingAddressEntity":
      return _("Shipping Address Entity");
    case "StockEntity":
      return _("Stock Entity");
    case "SupplierEntity":
      return _("Supplier Entity");
    case "TargetColumn":
      return _("Target Column");
    case "TargetEntity":
      return _("Target Entity");
    case "TaskEntity":
      return _("Task Entity");
    case "TaxRateEntity":
      return _("Tax Rate Entity");
    case "TitleColumn":
      return _("Title Column");
    case "TransferEntity":
      return _("Transfer Entity");
    case "UnitCost":
      return _("Unit Cost");
    case "WorkOrderEntity":
      return _("Work Order Entity");
    default:
      return "";
  }
};
