import {
  add,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  eachWeekOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  getDate,
  intervalToDuration,
  setDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  sub,
} from "date-fns";
import { getUserLocale } from "common/date-time/global";
import {
  getSitedNowDate,
  parseToSited,
  sitedToISO,
  sitedToUtcISO,
} from "common/date-time/internal";
import {
  DurationObject,
  GenericDateTime,
  SitedDateTime,
  TimeUnit,
  UTCDateRange,
  UTCDateTime,
} from "common/date-time/types";
import { Weekdays } from "common/date-time/weekday";
import { roundWithPrecision } from "common/math";
import { DAYS_IN_MONTH } from "./constants";

export const defaultDuration: Duration = {
  years: 0,
  months: 0,
  weeks: 0,
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

export const daysInMonth = (year: number, month: number) =>
  year && month ? getDate(new Date(year, month, 0)) : undefined;

export const getDuration = (from: GenericDateTime, to: GenericDateTime) => {
  const sitedFrom = parseToSited(from);
  const sitedTo = parseToSited(to);

  if (!sitedFrom || !sitedTo) return defaultDuration;

  return intervalToDuration({ start: sitedFrom, end: sitedTo });
};

export const calculateTotalDuration = (durations: Duration[] = []): Duration =>
  durations.reduce((acc, currentDuration) => {
    const {
      years = 0,
      months = 0,
      weeks = 0,
      days = 0,
      hours = 0,
      minutes = 0,
      seconds = 0,
    } = acc;
    const totalDuration: Duration = {
      years: years + (currentDuration.years || 0),
      months: months + (currentDuration.months || 0),
      weeks: weeks + (currentDuration.weeks || 0),
      days: days + (currentDuration.days || 0),
      hours: hours + (currentDuration.hours || 0),
      minutes: minutes + (currentDuration.minutes || 0),
      seconds: seconds + (currentDuration.seconds || 0),
    };

    if (totalDuration.seconds && totalDuration.seconds >= 60) {
      const additionalMinutes = (totalDuration.seconds / 60) | 0;
      totalDuration.minutes += additionalMinutes;
      totalDuration.seconds %= 60;
    }

    if (totalDuration.minutes && totalDuration.minutes >= 60) {
      const additionalHours = (totalDuration.minutes / 60) | 0;
      totalDuration.hours += additionalHours;
      totalDuration.minutes %= 60;
    }

    if (totalDuration.hours && totalDuration.hours >= 24) {
      const additionalDays = (totalDuration.hours / 24) | 0;
      totalDuration.days += additionalDays;
      totalDuration.hours %= 24;
    }

    if (totalDuration.days && totalDuration.days >= 7) {
      const additionalWeeks = (totalDuration.days / 7) | 0;
      totalDuration.weeks += additionalWeeks;
      totalDuration.days %= 7;
    }

    if (totalDuration.months && totalDuration.months >= 12) {
      const additionalYears = (totalDuration.months / 12) | 0;
      totalDuration.years += additionalYears;
      totalDuration.months %= 12;
    }

    return totalDuration;
  }, defaultDuration);

export const addDateDuration = (
  date: UTCDateTime,
  amount: number,
  unit: keyof Duration,
) => {
  const sitedDate = parseToSited(date);

  return sitedDate
    ? sitedToUtcISO(
        amount && unit ? add(sitedDate, { [unit]: amount }) : sitedDate,
      )
    : undefined;
};

export const subtractDateDuration = (
  date: UTCDateTime,
  amount: number,
  unit: keyof Duration,
) => {
  const sitedDate = parseToSited(date);

  return sitedDate
    ? sitedToUtcISO(
        amount && unit ? sub(sitedDate, { [unit]: amount }) : sitedDate,
      )
    : undefined;
};

export const getMonthRangeForDate = (date: UTCDateTime): UTCDateRange => {
  const sitedDate = parseToSited(date);

  if (!sitedDate) return undefined;

  const from = startOfDay(setDay(sitedDate, 0));
  const to = endOfDay(setDay(sitedDate, DAYS_IN_MONTH));

  return { from: sitedToUtcISO(from), to: sitedToUtcISO(to) };
};

export const getWorkweekRangeForDate = (date: UTCDateTime): UTCDateRange => {
  const sitedDate = parseToSited(date);

  if (!sitedDate) return undefined;

  const from = startOfDay(
    setDay(sitedDate, Weekdays.Monday, { locale: getUserLocale() }),
  );
  const to = endOfDay(
    setDay(sitedDate, Weekdays.Friday, { locale: getUserLocale() }),
  );

  return { from: sitedToUtcISO(from), to: sitedToUtcISO(to) };
};

export const getDifferenceInDays = (
  from: GenericDateTime,
  to: GenericDateTime,
) =>
  roundWithPrecision(
    differenceInHours(parseToSited(to), parseToSited(from)) / 24,
    1,
  ) || 0;

export const getDifferenceInHours = (
  from: GenericDateTime,
  to: GenericDateTime,
) =>
  roundWithPrecision(
    differenceInMinutes(parseToSited(to), parseToSited(from)) / 60,
    1,
  ) || 0;

export const getDifferenceInMinutes = (
  from: GenericDateTime,
  to: GenericDateTime,
) => differenceInMinutes(parseToSited(to), parseToSited(from)) || 0;

export const getDifferenceInSeconds = (
  date1: GenericDateTime,
  date2: GenericDateTime,
) => differenceInSeconds(parseToSited(date2), parseToSited(date1)) || 0;

export const getStartOfForDate = (date: Date, timeUnit: TimeUnit) => {
  switch (timeUnit) {
    case "day":
      return startOfDay(date);
    case "week":
      return startOfWeek(date, { locale: getUserLocale() });
    case "month":
      return startOfMonth(date);
    case "year":
      return startOfYear(date);
    default:
      return undefined;
  }
};

/**
 * Calculates the beginning of a given duration unit for a given date
 * in the Site's Time Zone
 *
 * @param date An ISO 8601 string date, Date or number of milliseconds since 1970
 * @param timeUnit
 * @returns string An ISO 8601 UTC string
 */
export const getStartOf = (
  date: GenericDateTime | Date | number,
  timeUnit: TimeUnit,
): UTCDateTime => {
  const sitedDate = parseToSited(date);

  return date && timeUnit && sitedDate
    ? sitedToUtcISO(getStartOfForDate(sitedDate, timeUnit))
    : undefined;
};

const getEndOfForDate = (date: Date, timeUnit: TimeUnit) => {
  switch (timeUnit) {
    case "day":
      return endOfDay(date);
    case "week":
      return endOfWeek(date, { locale: getUserLocale() });
    case "month":
      return endOfMonth(date);
    case "year":
      return endOfYear(date);
    default:
      return undefined;
  }
};

/**
 * Calculates the end of a given duration unit for a given date
 * in the Site's Time Zone
 *
 * @param date An ISO 8601 string date
 * @param timeUnit
 * @returns string An ISO 8601 UTC string
 */
export const getEndOf = (
  date: GenericDateTime,
  timeUnit: TimeUnit,
): UTCDateTime => {
  const sitedDate = parseToSited(date);

  return date && timeUnit && sitedDate
    ? sitedToUtcISO(getEndOfForDate(sitedDate, timeUnit))
    : undefined;
};

export const getStartOfToday = (): UTCDateTime => getStartOf(Date.now(), "day");

export const getStartOfDayForDate = (date: GenericDateTime): UTCDateTime =>
  getStartOf(date, "day");

export const getEndOfDayForDate = (date: GenericDateTime): UTCDateTime =>
  getEndOf(date, "day");

export const getStartOfDayForDateInSited = (
  date: GenericDateTime,
): SitedDateTime => {
  const sitedDate = parseToSited(date);

  return date && sitedDate
    ? sitedToISO(getStartOfForDate(sitedDate, "day"))
    : undefined;
};

export const getEndOfDayForDateInSited = (
  date: GenericDateTime,
): SitedDateTime => {
  const sitedDate = parseToSited(date);

  return date && sitedDate
    ? sitedToISO(getEndOfForDate(sitedDate, "day"))
    : undefined;
};

export const getDurationAsDays = (duration: DurationObject): number => {
  const date1 = getSitedNowDate();
  const date2 = duration ? add(date1, duration) : date1;
  return differenceInDays(date2, date1);
};

export const getDurationAsHours = (duration: DurationObject): number => {
  const date1 = getSitedNowDate();
  const date2 = duration ? add(date1, duration) : date1;
  return differenceInHours(date2, date1);
};

export const getDurationAsMinutes = (duration: DurationObject): number => {
  const date1 = getSitedNowDate();
  const date2 = duration ? add(date1, duration) : date1;
  return differenceInMinutes(date2, date1);
};

export const getEachWeekInterval = (
  start: UTCDateTime,
  end: UTCDateTime,
): UTCDateTime[] => {
  const sitedStart = parseToSited(start);
  const sitedEnd = parseToSited(end);

  return eachWeekOfInterval({ start: sitedStart, end: sitedEnd }).map(
    (sitedDate) => sitedToUtcISO(sitedDate),
  );
};
