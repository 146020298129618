import { ApiErrorResponse } from "common/types/error";
import { stripHtml } from "common/utils/html";

interface PropTypes {
  error: ApiErrorResponse | string;
}

const getApiErrorMessage = (error: ApiErrorResponse | string) =>
  typeof error === "string"
    ? stripHtml(error)
    : typeof error.data === "string"
      ? stripHtml(error.data)
      : error.data?.message ?? error.data?.Message ?? error.data?.error;

export const ApiErrorDetails = ({ error }: PropTypes) => {
  return (
    <div className="x-error-details-content">
      {typeof error !== "string" ? (
        <div className="qa-error-code row" data-testid="error-code">
          <div className="col-md-2">
            <strong>{_("Error Code:")}</strong>
          </div>
          <div className="col-md-10">{error?.status}</div>
        </div>
      ) : undefined}
      <div className="qa-error-message row" data-testid="error-message">
        <div className="col-md-2">
          <strong>{_("Error Message:")}</strong>
        </div>
        <div className="col-md-10">{getApiErrorMessage(error)}</div>
      </div>
    </div>
  );
};
