import * as R from "ramda";
import { cloneElement, Component } from "react";
import { reportsApi } from "common/api/reports";
import { BoxConfig, Output } from "common/data/types";
import { ReportLayout } from "common/form/types";
import { getAliasOrNameOrEntityName } from "common/query/common";
import {
  isSelectField,
  isSummaryField,
  Query,
  QueryForEntity,
} from "common/query/types";
import { Context } from "common/types/context";
import { Report } from "common/types/reports";
import { ApiError } from "common/ui/api-error";
import { LoadingIcon } from "common/widgets/loading-icon";

interface PropTypes {
  context: Context;
  id: number;
  output: Output;
  reportLayout: ReportLayout;
  children?: any;
}

interface Injected {
  label: string;
  output: Output;
  query: QueryForEntity;
}

interface StateType {
  report?: Report;
  error?: any;
  loading?: boolean;
}

export const injected: Injected = {
  label: undefined,
  output: undefined,
  query: undefined,
};

export const applyReportLayout = (
  query: Query,
  layout: ReportLayout,
): Query => {
  if (!layout || !layout.columns.length) return query;

  const { select } = query;
  const { columns } = layout;

  return R.mergeRight(query, {
    select: select.filter((s) =>
      R.any((column) => getAliasOrNameOrEntityName(s) === column, columns),
    ),
  });
};

export class FetchReport extends Component<PropTypes, StateType> {
  state: StateType = {
    report: undefined,
    error: false,
    loading: false,
  };

  componentDidMount() {
    this.findReport();
  }

  componentDidUpdate(prevProps: PropTypes) {
    if (prevProps.id === this.props.id) return;

    this.findReport();
  }

  findReport = () => {
    const { id } = this.props;
    const { apiCall, uiFormat, entities } = this.props.context;
    this.setState({ loading: true });

    reportsApi(apiCall, uiFormat.culture, entities)
      .get(id)
      .then((report) => this.setState({ loading: false, report }))
      .catch((error) => this.setState({ loading: false, error }));
  };

  render() {
    const { loading, report, error } = this.state;

    if (loading) return <LoadingIcon />;

    if (error) {
      if (error.status === 404) return null;
      return (
        <div className="x-dashboard-widgets-column x-with-error">
          <ApiError error={error} />
        </div>
      );
    }

    if (!report)
      return <div className="x-dashboard-widgets-column">{_("No data")}</div>;

    const { entity, query, label } = report;
    const { output, reportLayout } = this.props;

    const q: QueryForEntity = {
      entity,
      query: applyReportLayout(query, reportLayout),
    };

    if (!output) {
      return cloneElement(this.props.children, { label, query: q });
    }

    const selectField = query.select[0];

    const value = isSelectField(selectField)
      ? selectField.alias || selectField.name
      : isSummaryField(selectField)
        ? selectField.entityName
        : selectField.alias;

    const conf =
      output.type === "Box" && !(output.config as BoxConfig).value
        ? R.mergeRight(output, {
            config: R.mergeRight(output.config, { value, label }),
          })
        : output;

    const injected: Injected = {
      label,
      output: conf,
      query: q,
    };

    return cloneElement(this.props.children, injected);
  }
}
