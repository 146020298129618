import {
  PmTrigger,
  ScheduledEventAssetRecord,
} from "common/types/scheduled-event";
import { OnDisplay } from "common/widgets/list/types";
import { VirtualList } from "common/widgets/virtual-list";
import { ValueComponent } from "common/with-value-for";

interface PropTypes {
  pmTrigger: PmTrigger;
  onDisplay: OnDisplay<ScheduledEventAssetRecord>;
}

const METER_ITEM_HEIGHT = 105;
const FREQUENCY_ITEM_HEIGHT = 221;
const METER_HEIGHT = 97;
const LIST_HEIGHT_FACTOR = 3.2;

export class VirtualAssetList extends ValueComponent<
  ScheduledEventAssetRecord[],
  PropTypes
> {
  static readonly displayName = "VirtualAssetList";

  getDefaultItemSize = () => {
    const hasFrequency = !!this.props?.pmTrigger?.frequency;
    return hasFrequency ? FREQUENCY_ITEM_HEIGHT : METER_ITEM_HEIGHT;
  };

  getPmItemSize = (item: ScheduledEventAssetRecord) => {
    const meters = item?.properties?.meters || [];
    const additionalHeight = meters.length * METER_HEIGHT;
    return this.getDefaultItemSize() + additionalHeight;
  };

  render() {
    const { value, onDisplay, pmTrigger } = this.props;
    const defaultItemSize = this.getDefaultItemSize();
    const defaultHeight = Math.floor(LIST_HEIGHT_FACTOR * defaultItemSize);

    return (
      <VirtualList<ScheduledEventAssetRecord>
        key={JSON.stringify(pmTrigger)}
        onDisplay={onDisplay}
        defaultItemSize={defaultItemSize}
        getItemSize={this.getPmItemSize}
        defaultHeight={defaultHeight}
        canDelete={true}
        value={value}
        onChange={this.onChangeSetValue}
      />
    );
  }
}
