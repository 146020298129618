import { Properties } from "common/types/records";
import { Action } from "common/record/actions/types";
import { QuickSelect } from "common/query/table/actions/quick-actions/quick-select";
import { QuickUpdate } from "common/query/table/actions/quick-actions/quick-update";

export const QUICK_ACTIONS = ["Select", "Update"];

type Props = {
  actions: Action[];
  properties: Properties;
  index: number;
};

export const QuickActions = (props: Props) => {
  const { actions, properties, index } = props;

  const quickActions = actions?.filter((action) =>
    QUICK_ACTIONS.includes(action.name),
  );

  if (!quickActions?.length) return null;

  return (
    <>
      {quickActions.map((action) => {
        switch (action.name) {
          case "Select":
            return (
              <QuickSelect
                key={action.name}
                action={action}
                properties={properties}
                index={index}
              />
            );
          case "Update":
            return (
              <QuickUpdate
                key={action.name}
                action={action}
                properties={properties}
                index={index}
              />
            );
          default:
            return null;
        }
      })}
    </>
  );
};
