import * as R from "ramda";
import { getNewEventAsset } from "common/api/scheduled-event";
import { Entity } from "common/entities/types";
import { QueryForEntity } from "common/query/types";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { ScheduledEventRecord } from "common/types/scheduled-event";
import { MultiRecordSelector } from "common/widgets/record-selector/multiple-record";
import { Required } from "common/widgets/required";
import { ValueComponent } from "common/with-value-for";
import {
  getEventAssets,
  setEventAssetDeleted,
  setEventAssets,
} from "../../functions";

interface PropTypes {
  context: Context;
  entity: Entity;
  assetEntity: Entity;
  query: QueryForEntity;
}

export class AssetSelector extends ValueComponent<
  ScheduledEventRecord,
  PropTypes
> {
  static readonly displayName = "AssetSelector";

  addEventAsset = (assetFks: ForeignKey[]) => {
    if (!assetFks || !assetFks.length) return;

    const { context, entity, value } = this.props;

    const eventAssets = getEventAssets(context.entities, entity, value);
    const assetFkIds = assetFks.map((afk) => afk.id);

    const [duplicatedEventAssets, existingEventAssets] = R.partition(
      (eventAsset) => R.includes(eventAsset.properties.assetId.id, assetFkIds),
      eventAssets,
    );

    const addedAssetFks = assetFks.filter((assetFk) =>
      R.all(
        (duplicatedEventAsset) =>
          duplicatedEventAsset.properties.assetId.id !== assetFk.id,
        duplicatedEventAssets,
      ),
    );

    const newEventAssets = duplicatedEventAssets
      .map((eventAsset) => setEventAssetDeleted(false, eventAsset))
      .concat(addedAssetFks.map(getNewEventAsset))
      .concat(existingEventAssets);

    const newValue = setEventAssets(
      context.entities,
      entity,
      newEventAssets,
      value,
    );

    this.setValue(newValue);
  };

  render() {
    const { context, entity, assetEntity, value, query } = this.props;
    const eventAssets = getEventAssets(context.entities, entity, value);
    const isValid =
      value.properties?.type === "E" || eventAssets.length || undefined;

    return (
      <Required value={isValid}>
        <MultiRecordSelector
          context={context}
          entity={assetEntity}
          withLinks={true}
          dontKeepValue={true}
          value={undefined}
          query={query}
          onChange={this.addEventAsset}
        />
      </Required>
    );
  }
}
