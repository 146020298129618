import * as R from "ramda";
import { Component } from "react";
import { defaultFor } from "common";
import { omitDataTypes } from "common/entities/entity-column/data-type/functions";
import { filterBlackList } from "common/entities/entity-column/data-type/types";
import { getSubQueryOperators } from "common/entities/operators";
import { RenderGroupItem } from "common/query-builder/condition/types";
import {
  Filter,
  FilterSubQuery,
  GroupFilter,
  isAnd,
  isOr,
} from "common/query/types";
import { inputCondition } from "common/types/condition-type";
import { ActionButtonLarge, DeleteButton } from "common/ui/buttons";
import { createList } from "common/widgets/list";
import { Selector } from "common/widgets/selector";
import { ValueProps } from "common/with-value-for";
import { Field, GroupItem, Operator } from "../types";
import {
  createRuleFor,
  defaultAnd,
  defaultSubQueryScope,
  displayOperator,
  getAndOr,
  getColumnOperators,
  getFirstField,
  getOp,
  operators,
  setGroupFor,
} from "./functions";

interface PropTypes extends ValueProps<GroupFilter> {
  fields: Field[];
  onRemoveGroup: () => void;
  renderGroupItem: RenderGroupItem;
  disableNestedGroups?: boolean;
  disableSubQueries?: boolean;
}

const List = createList<GroupItem>();

export class Group extends Component<PropTypes> {
  static readonly displayName = "Group";

  deleteTest = (item: GroupItem) => {
    return item && item.filter && !isAnd(item.filter) && !isOr(item.filter);
  };

  onSetGroup = (group: Operator) => {
    const { value = defaultAnd, onChange } = this.props;
    const values: Filter[] = getAndOr(value);
    const setGroup = setGroupFor(onChange);
    setGroup(group, values);
  };

  onCreateRule = () => {
    const { fields, value = defaultAnd, onChange } = this.props;

    const op = getOp(value);
    const values: Filter[] = getAndOr(value);
    const setGroup = setGroupFor(onChange);
    const filteredFields = omitDataTypes(fields, filterBlackList);
    const firstField = getFirstField(filteredFields);
    const ruleOp = getColumnOperators(firstField)[0].name;
    const rule = createRuleFor(firstField, ruleOp);

    setGroup(op, R.append(rule, values));
  };

  onCreateSubQuery = () => {
    const { value = defaultAnd, onChange } = this.props;

    const op = getOp(value);
    const values: Filter[] = getAndOr(value);
    const setGroup = setGroupFor(onChange);
    const ruleOp = getSubQueryOperators()[0].name;
    const rule = {
      op: ruleOp,
      queryValue: defaultFor<FilterSubQuery>(),
      scope: defaultSubQueryScope,
    };

    setGroup(op, R.append(rule, values));
  };

  onCreateGroup = () => {
    const { value = defaultAnd, onChange } = this.props;

    const op = getOp(value);
    const values: Filter[] = getAndOr(value);
    const setGroup = setGroupFor(onChange);

    setGroup(op, R.append({ and: [] }, values));
  };

  onRemoveGroup = (index: number) => {
    return () => {
      const { value = defaultAnd } = this.props;
      const filter = isAnd(value) ? value.and : value.or;
      const newValue = R.remove(index, 1, filter);

      this.props.onChange({
        ...this.props.value,
        [isAnd(value) ? "and" : "or"]: newValue,
      });
    };
  };

  onChangeItemValues = (groupItems: GroupItem[]) => {
    const { value = defaultAnd } = this.props;
    const op = getOp(value);

    this.props.onChange({
      ...this.props.value,
      [op]: (groupItems || []).map((groupItem) => groupItem.filter),
    });
  };

  render() {
    const {
      onRemoveGroup,
      value = defaultAnd,
      renderGroupItem,
      disableNestedGroups,
      disableSubQueries = false,
    } = this.props;
    const op = getOp(value);
    const values: Filter[] = getAndOr(value);

    const itemValues: GroupItem[] = values.map((value) => ({
      filter: value,
      operator: op,
      valueSelector: inputCondition,
    }));

    return (
      <div className="x-query-group-container x-flex-grow-1">
        <div
          className={
            "x-query-group-header x-query-group-header-padding x-flex-between-start"
          }
        >
          <Selector<Operator>
            className="x-condition-builder-select qa-input-op"
            getOptionLabel={displayOperator}
            options={operators}
            value={op}
            onChange={this.onSetGroup}
          />
          <DeleteButton
            className="x-query-group-delete qa-condition-remove-group"
            onClick={onRemoveGroup}
            title={_("Remove Group")}
          >
            <i className="fa fa-close" />
          </DeleteButton>
        </div>
        <List
          canDelete={this.deleteTest}
          onDisplay={renderGroupItem(this.onRemoveGroup)}
          value={itemValues}
          onChange={this.onChangeItemValues}
        />
        <div className="x-condition-builder-buttons">
          <ActionButtonLarge
            className="qa-btn-create-rule"
            onClick={this.onCreateRule}
          >
            {_("Create Rule")}
          </ActionButtonLarge>
          {!disableSubQueries ? (
            <ActionButtonLarge
              className="qa-btn-create-sub-query"
              onClick={this.onCreateSubQuery}
            >
              {_("Create Sub Query")}
            </ActionButtonLarge>
          ) : undefined}
          {!disableNestedGroups ? (
            <ActionButtonLarge
              className="qa-btn-create-group"
              onClick={this.onCreateGroup}
            >
              {_("Create Group")}
            </ActionButtonLarge>
          ) : undefined}
        </div>
      </div>
    );
  }
}
