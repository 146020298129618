import { Component } from "react";
import { Filter } from "common/query/types";
import { GroupItem } from "common/query-builder/types";
import { inputCondition } from "common/types/condition-type";
import { ValueProps } from "common/with-value-for";
import {
  InnerCondition,
  PropTypes,
} from "common/query-builder/condition/inner";

type Props = PropTypes &
  ValueProps<Filter> & {
    disableSubQueries?: boolean;
  };

export class Condition extends Component<Props> {
  static readonly displayName = "Condition";

  onChange = (groupItem: GroupItem) => {
    this.props.onChange(groupItem.filter);
  };

  render() {
    return (
      <InnerCondition
        {...this.props}
        className="x-query-group-base"
        value={{
          filter: this.props.value,
          operator: undefined,
          valueSelector: inputCondition,
        }}
        onChange={this.onChange}
      />
    );
  }
}
