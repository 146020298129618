import { v4 as uuid } from "uuid";
import { getByBehaviorArgument } from "common/entities";
import { Entities, Entity } from "common/entities/types";
import { getDefaultQuery } from "common/query/for-table";
import { QueryForEntity } from "common/query/types";
import { Properties, RecordPayload } from "common/types/records";
import { getFkId } from "common/functions/foreign-key";

export const getAssetMetersEntity = (entities: Entities, entity: Entity) =>
  getByBehaviorArgument(entities, "AssetMeter", "assetEntity", entity.name);

export const getAssetMeterReadingEntity = (
  entities: Entities,
  entity: Entity,
) =>
  getByBehaviorArgument(
    entities,
    "AssetMeterReading",
    "workOrderEntity",
    entity.name,
  );

export const expandMeterReadingQuery = (
  entity: string,
  id: string,
): QueryForEntity => {
  return {
    entity,
    query: {
      select: [{ name: "assetMeterId" }, { name: "date" }, { name: "value" }],
      fkExpansion: true,
      filter: {
        and: [{ name: "id", op: "eq", value: id }],
      },
    },
  };
};

export const getAssetMetersQuery = (
  entity: Entity,
  assetId: string,
  meterId: string,
): QueryForEntity => {
  const query = getDefaultQuery(entity);
  return {
    entity: entity.name,
    query: {
      ...query,
      select: [...query.select, { name: "meterTypeId", path: "/meterId" }],
      filter: {
        and: [
          { name: "assetId", op: "eq", value: assetId },
          { name: "meterId", op: "eq", value: meterId },
        ],
      },
      joins: [{ column: "meterId", type: "LEFT" }],
    },
  };
};

export const isEditMode = (meterReading: RecordPayload) =>
  meterReading?.properties.id || meterReading?.properties.$id;

export const getMeterType = (meterId: string, meters: Properties[]) =>
  meterId && meters.find((p) => p.id === meterId)?.meterTypeId;

export const mapToProperties = (
  properties: Properties,
  procedureId?: string,
  workOrderId?: string,
): Properties => {
  const { id, $id, assetMeterId, date, value } = properties || {};
  const baseProperties = {
    date,
    value,
    assetMeterId: getFkId(assetMeterId),
    procedureId,
    workOrderId,
  };

  return id
    ? { ...baseProperties, id }
    : { ...baseProperties, $id: $id || uuid() };
};

export const getMeterReadingOrDefault = (
  entityName: string,
  payload: RecordPayload,
) => {
  const relatedRecords = payload?.related?.[entityName] || [];
  return relatedRecords?.[0];
};
