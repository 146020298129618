import { Component, JSX } from "react";
import { deepEqual } from "common/component";
import { getTimestamp } from "common/date-time/common";
import { Properties } from "common/types/records";
import { ApiError } from "common/ui/api-error";
import { ShowMore } from "common/widgets/show-more";
import { getRecordsForPages, runQueryWithPage, sameResultSet } from "./common";
import {
  ActionsByRecordId,
  Page,
  QueryForEntity,
  RunActionsQuery,
  RunQuery,
} from "./types";

export interface Injected {
  records: Properties[];
  actionsByRecordId?: ActionsByRecordId;
  running: boolean;
}

interface PropTypes<T> {
  onDisplay: (injected: Injected) => JSX.Element;
  runQuery: RunQuery;
  runActionsQuery?: RunActionsQuery;
  query: QueryForEntity;
  // notOlderThan prop is super important, it is attached here to trigger
  // a refresh when the user changes the results via asynchronous action
  // e.g. if the user is in the dashboard, viewing cards, triggers an action
  // like 'close' for a Work Order -> this triggers the reload, which changes
  // this prop, and the re-run the query.
  // eslint-disable-next-line react/no-unused-prop-types
  notOlderThan: number;
  withPaging: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  refresh?: T;
}

interface StateType {
  running?: boolean;
  error?: any;
  timestamp?: any;
  pages?: Page[];
  actionsByRecordId?: ActionsByRecordId;
  page?: number;
  isLastPage?: boolean;
}

export class QueryRunner<T> extends Component<PropTypes<T>, StateType> {
  state: StateType = {
    running: false,
    error: false,
    pages: [],
    page: 0,
    isLastPage: true,
    actionsByRecordId: {},
  };

  componentDidMount() {
    this.runQuery(0);
  }

  componentDidUpdate(prevProps: PropTypes<T>) {
    const newQuery = this.props.query;
    const oldQuery = prevProps.query;

    const areEqual =
      deepEqual(prevProps, this.props, ["query", "children", "onDisplay"]) &&
      sameResultSet(oldQuery, newQuery);

    const { running } = this.state;

    if (!running && !areEqual) this.runQuery(0);
  }

  runQuery = (page: number) => {
    const { runQuery, query } = this.props;
    const { pages } = this.state;
    this.setState({ running: true, page });

    runQueryWithPage(runQuery, pages, query, page)
      .then(({ pages, isLastPage }) => {
        this.setState({
          running: false,
          pages,
          timestamp: getTimestamp(),
          error: undefined,
          isLastPage,
        });

        return pages;
      })
      .then((pages) => this.loadActions(getRecordsForPages(pages)))
      .catch((error) =>
        this.setState({
          running: false,
          error,
        }),
      );
  };

  loadActions = (records: Properties[]) => {
    const { runActionsQuery, query } = this.props;
    const recordIds = records?.reduce((acc, record) => {
      const id = record?.id;
      return id ? acc.concat(id) : acc;
    }, []);

    if (!runActionsQuery || !recordIds?.length) return;

    runActionsQuery(query.entity, recordIds).then((actionsByRecordId) => {
      this.setState({ ...this.state, actionsByRecordId });
    });
  };

  onShowMore = () => this.runQuery(this.state.page + 1);

  render() {
    const { withPaging, onDisplay } = this.props;
    const { pages, running, isLastPage, actionsByRecordId, error } = this.state;
    const records = getRecordsForPages(pages);
    const child = (
      <>
        {error ? <ApiError error={error} /> : undefined}
        {onDisplay({ records, actionsByRecordId, running })}
      </>
    );

    if (!withPaging || isLastPage) return child;

    return (
      <ShowMore action={this.onShowMore} loading={running}>
        {child}
      </ShowMore>
    );
  }
}
