import { Component } from "react";
import { isEntity, isReport, isStatic } from "common/functions/preferences";
import { MenuEntrySetting } from "common/types/preferences";
import { Culture } from "common/culture/supported-cultures";
import { Entities } from "common/entities/types";
import { IconList } from "common/widgets/selector/icon-list";
import { ValueProps } from "common/with-value-for";
import { getReportLabelBySettings, staticEntries } from "../functions";

interface LabelProps {
  entities: Entities;
  culture: Culture;
  menuSetting: MenuEntrySetting;
}

const EntryLabel = ({ entities, culture, menuSetting }: LabelProps) => {
  if (isReport(menuSetting)) {
    return (
      <span className="qa-user-profile-report">
        <span>{getReportLabelBySettings(culture, menuSetting)}</span>
        {/* TODO find a better class name */}
        <span className="x-user-profile-badge">{_("report")}</span>
      </span>
    );
  }

  if (isEntity(menuSetting)) {
    return (
      <span className="qa-user-profile-entity">
        {entities[menuSetting.entity]?.localizedName ?? menuSetting.entity}
      </span>
    );
  }

  if (isStatic(menuSetting)) {
    const { name } = menuSetting;
    return (
      <span className="qa-user-profile-static">
        {staticEntries().find((s) => s.name === name)?.label ??
          menuSetting.name}
      </span>
    );
  }

  return undefined;
};

interface PropTypes extends ValueProps<MenuEntrySetting> {
  entities: Entities;
  culture: Culture;
}

export class MenuEntrySettingForm extends Component<PropTypes> {
  onIconChange = (icon: string) => {
    const { value, onChange } = this.props;
    onChange({ ...value, icon });
  };

  render() {
    const { entities, culture, value } = this.props;

    return (
      <div className="x-flex">
        <div className="x-flex-grow-1 x-widget-list-row-name">
          <EntryLabel
            entities={entities}
            culture={culture}
            menuSetting={value}
          />
        </div>
        <div className="x-flex-grow-1">
          <IconList value={value.icon} onChange={this.onIconChange} />
        </div>
      </div>
    );
  }
}
