import { LayoutActionName } from "common/form/types";
import { ApiCall } from "common/types/api";
import { withValue } from "common/with-value";
import { PropTypes, ActionHandler } from "./types";
// eslint-disable-next-line import/no-cycle
import { GenericWorkOrderAction } from "./ui/work-order-action";
import { ActionPayload } from "./ui/work-order-action/types";

export const simpleGenericWorkOrderAction = <V extends unknown>(
  label: string,
  actionLabel: string,
  actionName: LayoutActionName,
  fn: (apiCall: ApiCall, entity: string, payload: ActionPayload) => any,
): ActionHandler<V, PropTypes> => {
  return {
    label: actionLabel,
    Ui: withValue<V, PropTypes>((props) => {
      const onOk = (
        apiCall: ApiCall,
        dismiss: (x: boolean) => any,
        payload: ActionPayload,
      ) => fn(apiCall, props.entity.name, payload).then(dismiss);

      return (
        <GenericWorkOrderAction
          {...props}
          actionName={actionName}
          label={label}
          onOk={onOk}
        />
      );
    }),
  };
};
