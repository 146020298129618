import * as R from "ramda";
import { defaultFor } from "common";
import { getColumn } from "common/entities";
// eslint-disable-next-line import/no-cycle
import { InputWidget } from "common/form/widget/input-widget";
import { roundWithPrecision } from "common/math";
import { applyLookupQueryToColumn } from "common/query/entities";
import {
  PART,
  PART_SUPPLIER,
  QUANTITY,
} from "common/record/form/content/related/requisitioning-item/consts";
import {
  createPartSupplierLookUpQuery,
  getEstimatedCost,
} from "common/record/form/content/related/requisitioning-item/functions";
import { Properties } from "common/types/records";
import { DefaultCurrencyLabel } from "common/widgets/currency/label-with-default";
import { FormattedFk } from "common/widgets/foreign-key";
import { LinkFk } from "common/widgets/foreign-key/link-fk";
import { Int } from "common/widgets/number";
import { IntLabel } from "common/widgets/number/label";
import { withValue } from "common/with-value";
import { ItemPropTypes, ItemValue } from "./types";

export const RequisitionListItem = withValue<ItemValue, ItemPropTypes>(
  ({
    context,
    editableFields,
    index,
    reqItemEntity,
    partSuppliersUnitCostResults,
    value = defaultFor<ItemValue>(),
    onChangeMergeValue2,
  }) => {
    const { entities } = context;
    const { properties } = value || defaultFor<Properties>();
    const { partId, quantity, partSupplierId } = properties;

    const lookupQuery = createPartSupplierLookUpQuery(context, reqItemEntity);
    const updatedPartSupplierColumn = applyLookupQueryToColumn(
      entities,
      reqItemEntity.name,
      [lookupQuery],
      properties,
      getColumn(reqItemEntity, PART_SUPPLIER),
    );

    const { estimatedCost, currency } = getEstimatedCost(
      value,
      partSuppliersUnitCostResults,
    );

    return (
      <tr key={index} className="x-requisition-list-item">
        {/* Part */}
        <td>
          <LinkFk
            context={context}
            column={getColumn(reqItemEntity, PART)}
            value={partId}
          />
        </td>
        {/* Quantity */}
        <td className={!quantity ? "x-has-error" : undefined}>
          {R.includes(QUANTITY, editableFields) ? (
            <Int
              value={quantity}
              onChange={onChangeMergeValue2("properties", QUANTITY)}
            />
          ) : (
            <IntLabel value={quantity} />
          )}
        </td>
        {/* Part Supplier */}
        <td className={!partSupplierId ? "x-has-error" : undefined}>
          {R.includes(PART_SUPPLIER, editableFields) ? (
            <InputWidget
              context={context}
              column={updatedPartSupplierColumn}
              disabled={false}
              validate={true}
              buffer={false}
              formValidation={undefined}
              onFormValidationChange={undefined}
              value={partSupplierId}
              onChange={onChangeMergeValue2("properties", PART_SUPPLIER)}
            />
          ) : (
            <FormattedFk
              context={context}
              column={updatedPartSupplierColumn}
              value={partSupplierId}
            />
          )}
        </td>
        {/* Estimated Cost */}
        <td>
          <DefaultCurrencyLabel
            context={context}
            column={undefined}
            currencyId={currency}
            value={estimatedCost && `${roundWithPrecision(estimatedCost, 2)}`}
          />
        </td>
      </tr>
    );
  },
  "RequisitionListItem",
);
