import { CancellablePromise } from "common/types/promises";
import { RGBColor } from "common/types/colors";

export const colors = ["blue", "green", "red", "yellow"];

interface Position {
  lat: number;
  lng: number;
}

export interface Pin extends Position {
  layer: string;
  id?: string;
  label?: string;
  color?: RGBColor;
}

export interface ImgSize {
  width: number;
  height: number;
}

export type GetInfo = (pin: Pin) => CancellablePromise<string>;

export interface MapValue {
  pins: Pin[];
  selected: Pin;
  imgSize: ImgSize;
}
export const defaultMapValue: MapValue = {
  pins: [],
  selected: undefined,
  imgSize: undefined,
};
