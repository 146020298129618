import * as R from "ramda";
import { isMatch } from "common/widgets/list-with-search/search-lib";
import { PropOrPathOf } from "common/widgets/list-with-search/types";

export const sortRecords = <TRecord>(
  records: TRecord[],
  orderBy: PropOrPathOf<TRecord>,
): TRecord[] =>
  (orderBy &&
    R.sortBy(
      (r) => ((R.path(orderBy.split("."), r) as string) || "").toLowerCase(),
      records,
    )) ||
  records;

export const filterRecords = <TRecord>(
  records: TRecord[],
  searchTerm: string,
  filters: Array<PropOrPathOf<TRecord>>,
): TRecord[] => {
  const recordMatches = isMatch(searchTerm);

  return searchTerm && filters.length > 0
    ? records.filter((record) =>
        R.any(
          (filter) =>
            // using Path here as the number of filters is exponential
            // so we cannot use any of the getPropertyN
            // We need to create a recursive getProperty
            recordMatches(R.path(filter.split("."), record)),
          filters,
        ),
      )
    : records;
};

export const mapRecord = <TRecord>(
  mapFn: (s: string) => string,
  properties: Array<PropOrPathOf<TRecord>>,
  record: TRecord,
): TRecord =>
  mapFn && properties.length > 0
    ? properties.reduce((record, key) => {
        const keyLens = R.lensPath(key.split("."));
        return R.over(keyLens, mapFn, record);
      }, record)
    : record;
