interface PropTypes {
  message: string;
  isInfoWarning?: boolean;
}

export const ProjectionWarning = ({ message, isInfoWarning }: PropTypes) => {
  const className = isInfoWarning
    ? "x-pm-projections-info-warning"
    : "x-pm-projections-warning";

  return (
    <div className={className}>
      <i className="fa fa-exclamation-triangle" />
      {message}
    </div>
  );
};

ProjectionWarning.displayName = "ProjectionWarning";
