import { UTCDateTime } from "common/date-time/types";
import { UiFormat } from "common/types/ui-format";
import { HighlightProps } from "common/widgets/label";
import { DateBaseLabel } from "./date-base-label";

interface PropTypes extends HighlightProps {
  uiFormat: UiFormat;
  timezone?: string;
  value: UTCDateTime;
}

export const getDateTimeFormat = (uiFormat: UiFormat) =>
  `${uiFormat?.dateFormat} ${uiFormat?.timeFormat}${
    uiFormat.displayTimezone ? " zzz" : ""
  }`;

export const DateTimeLabel = ({
  uiFormat,
  textToHighlight,
  timezone,
  value,
}: PropTypes) => {
  return (
    <DateBaseLabel
      title={`${_("Unformatted Date-Time")}: ${value}`}
      format={getDateTimeFormat(uiFormat)}
      textToHighlight={textToHighlight}
      timezone={timezone}
      value={value}
    />
  );
};
