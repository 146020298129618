import { filterByBehaviorArguments } from "common/entities";
import type { Context } from "common/types/context";
import { PlannerArea } from "x/scheduler2/planner";
import { isPlanner } from "x/scheduler2/shared";
import { TechnicianArea } from "x/scheduler2/technician";
import type { SchedulerApiData } from "x/scheduler2/types";

interface PropTypes {
  context: Context;
  dependencies?: SchedulerApiData;
  hidePanels?: boolean;
}

export const SchedulerAreaSwitch = ({
  context,
  dependencies,
  hidePanels,
}: PropTypes) => {
  const { schedulerSettings, userContact } = dependencies.schedulerApiData;
  const { userTypes, role, entities } = context;

  const assignmentsEntities = filterByBehaviorArguments(
    entities,
    "Assignment",
    { targetEntity: schedulerSettings.entityName },
  );

  return isPlanner(userTypes, role, assignmentsEntities) ? (
    <PlannerArea
      context={context}
      userContact={userContact}
      schedulerSettings={schedulerSettings}
      assignmentEntities={assignmentsEntities}
      hidePanels={hidePanels}
    />
  ) : (
    <TechnicianArea
      context={context}
      userContact={userContact}
      schedulerSettings={schedulerSettings}
    />
  );
};
