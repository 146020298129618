import * as R from "ramda";

export const genericSort = R.sortBy(R.identity);

export const genericSortByField = <T>(field: keyof T) => {
  const getFieldValue = (field: keyof T) => (obj: T) => {
    const stringValue = (obj?.[field] || "").toString();
    return R.toLower(stringValue);
  };
  return R.sort<T>(R.ascend(getFieldValue(field)));
};
