import { ApiCall, Crud } from "common/types/api";
import { Dashboard } from "common/types/dashboards";
import { CancellablePromise } from "common/types/promises";
import { messages, withNotifications } from "common/api/with-notifications";

const getMessages = messages(() => ({
  created: _("The dashboard was successfully created"),
  updated: _("The dashboard was successfully updated"),
  removed: _("The dashboard was successfully deleted"),
}));

export const dashboardsApi = withNotifications(
  getMessages,
  (apiCall: ApiCall): Crud<number, Dashboard> => ({
    get: (id: number) => apiCall("get", `api/ui/dashboards/${id}`),
    list: (): CancellablePromise<Dashboard[]> =>
      apiCall("get", "api/ui/dashboards"),
    create: (dashboard: Dashboard) =>
      apiCall("post", "api/ui/dashboards", dashboard),
    remove: (id: number) => apiCall("delete", `api/ui/dashboards/${id}`),
    update: (dashboard: Dashboard) =>
      apiCall("put", `api/ui/dashboards/${dashboard.id}`, dashboard),
  }),
);
