import { Component } from "react";
import { recordsApi } from "common/api/records";
import { ApiCall } from "common/types/api";
import { requiresExplicitAuthentication } from "common/record/utils";
import { Action } from "common/record/actions/action";
import { getSortedRecordNumbers } from "common/record/actions/functions/record";
import { DismissAction, PropTypes } from "common/record/actions/types";

export class Delete extends Component<PropTypes> {
  static readonly displayName = "Delete";

  deleteRecord = (apiCall: ApiCall) => {
    const { entity, records } = this.props;
    return recordsApi(apiCall).remove(entity.name, records[0].properties.id);
  };

  deleteRecordList = (apiCall: ApiCall) => {
    const { entity, records } = this.props;
    return recordsApi(apiCall).bulkDelete(
      entity.name,
      records.map((r) => r.properties.id),
    );
  };

  onDelete = (apiCall: ApiCall, dismiss: DismissAction) => {
    const { context, entity, records, goTo } = this.props;

    const deleteProm =
      records.length > 1
        ? this.deleteRecordList(apiCall)
        : this.deleteRecord(apiCall);

    return deleteProm.then(() => {
      dismiss(true);
      if (goTo)
        goTo(
          `${context.site.name}/${entity.displayAsSystem ? "admin/" : ""}${
            entity.name
          }`,
        );
    });
  };

  render() {
    const { records, context, dismiss, entity } = this.props;
    const recordNumbers = getSortedRecordNumbers(records);
    const authenticationRequired = records.some((record) =>
      requiresExplicitAuthentication(
        context.entities,
        entity,
        record,
        undefined,
      ),
    );

    return (
      <Action
        context={context}
        dismiss={dismiss}
        entity={entity}
        records={records}
        title={_("Archive")}
        btnLabel={`Archive the ${records.length > 1 ? "records" : "record"}`}
        size={"large"}
        onOk={this.onDelete}
        hideRecordNumber={true}
        requiresAuthentication={authenticationRequired}
      >
        <div className="x-delete-text">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {_("Record(s):")}
          <div className="x-record-numbers">{recordNumbers.join(", ")}</div>
          {_("will be archived. Are you sure?")}
        </div>
      </Action>
    );
  }
}
