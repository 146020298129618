import { isType } from "common/index";
import {
  EntitySetting,
  MenuEntrySetting,
  ReportSetting,
  StaticSetting,
} from "common/types/preferences";

export const isStatic = isType<MenuEntrySetting, StaticSetting>(["name"]);

export const isEntity = isType<MenuEntrySetting, EntitySetting>(["entity"]);

export const isReport = isType<MenuEntrySetting, ReportSetting>(["reportId"]);
