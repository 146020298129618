import * as R from "ramda";
import { Component } from "react";
import { Form } from "common/types/forms";
import { Selector } from "common/widgets/selector";
import { ValueProps } from "common/with-value-for";

interface PropTypes extends ValueProps<number> {
  forms: Form[];
  className?: string;
  disabled?: boolean;
  preselectFirstByDefault?: boolean;
  placeholder?: string;
  label?: string;
}

const displayForm = (form: Form) => form?.label;

export class FormSelector extends Component<PropTypes> {
  static readonly displayName = "FormSelector";

  onChange = (form: Form) => {
    this.props.onChange(form.id);
  };

  findSelected = () => {
    const { forms = [], value, preselectFirstByDefault } = this.props;
    return value
      ? R.find((o) => o.id === value, forms)
      : preselectFirstByDefault
        ? forms[0]
        : undefined;
  };

  render() {
    const { className, disabled, forms = [], placeholder, label } = this.props;

    const selected = this.findSelected();

    return forms.length ? (
      <div className={className}>
        <Selector<Form>
          className="qa-dropdown"
          label={label}
          getOptionLabel={displayForm}
          options={forms}
          disabled={disabled}
          placeholder={placeholder ?? _("Select a form")}
          value={selected}
          onChange={this.onChange}
        />
      </div>
    ) : null;
  }
}
