import { Component } from "react";
import { Context } from "common/types/context";
import { AnnouncementView } from "./announcement";

export interface PropTypes {
  context: Context;
}

const getSeenAnnouncements = (context: Context): number[] => {
  return context.localStorage.get<number[]>("seen-announcements") || [];
};

export const setSeenAnnouncements = (context: Context, value: number[]) => {
  const seenIds = getSeenAnnouncements(context);
  const newIds = value.filter((id) => seenIds.indexOf(id) === -1);
  context.localStorage.set("seen-announcements", seenIds.concat(newIds));
};

export class AnnouncementsController extends Component<PropTypes> {
  static readonly displayName = "AnnouncementsController";
  updateSeenAnnouncements = (id: number) => {
    setSeenAnnouncements(this.props.context, [id]);
    this.forceUpdate();
  };
  render() {
    const { context } = this.props;
    const seen = getSeenAnnouncements(context);
    const filtered = context.announcements.filter((a) => {
      return seen.indexOf(a.id) === -1;
    });

    return filtered.length ? (
      <div className="x-announcements">
        {filtered.map((a) => (
          <AnnouncementView
            key={a.id}
            announcement={a}
            onDismiss={this.updateSeenAnnouncements}
          />
        ))}
      </div>
    ) : null;
  }
}
