import { isFromFluke } from "common/functions/reasons";
import { defaultRecord, Properties } from "common/types/records";
import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";
import { Header } from "common/form/types";
import { Context } from "common/types/context";
import { withValue, WithValue } from "common/with-value";
import { Address } from "./address";
import { DatesInfo } from "./dates-info";
import { WoReason } from "./reason";
import { RecordInfo } from "./record";

interface PropTypes {
  entity: Entity;
  context: Context;
  layout: Header;
  withLinks: boolean;
  disableEdit?: boolean;
  disableReason?: string;
}

const RecordHeaderComp = ({
  entity,
  context,
  layout,
  withLinks,
  disableEdit,
  disableReason,
  value = defaultRecord.properties,
  onChangeSetValue,
}: PropTypes & WithValue<Properties>) => {
  return (
    <>
      <div className="x-record-header x-flex">
        <RecordInfo
          context={context}
          entity={entity}
          layout={layout}
          withLinks={withLinks}
          disableEdit={disableEdit}
          value={value}
          onChange={onChangeSetValue}
        />
        {behaveAs("GeoLocation", entity) && (
          <Address address={value.geolocation && value.geolocation.address} />
        )}
        <DatesInfo
          entity={entity}
          properties={value}
          context={context}
          headerConfig={layout}
          withLinks={withLinks}
        />
      </div>
      {behaveAs("WorkOrder", entity) &&
      value.reason &&
      isFromFluke(value.reason) ? (
        <WoReason reason={value.reason} />
      ) : undefined}
      {disableEdit ? (
        <div className="x-record-warning">
          <i className="fa fa-exclamation-triangle" />
          {disableReason}
        </div>
      ) : undefined}
    </>
  );
};

export const RecordHeader = withValue<Properties, PropTypes>(
  RecordHeaderComp,
  "RecordHeader",
);
