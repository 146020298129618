import { Component } from "react";
import { FormValidationProps } from "common/form/types";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { PoItem } from "common/types/purchase-orders";
import { ValueProps } from "common/with-value-for";
import { PoListItem } from "./list-item";

interface PropTypes extends ValueProps<PoItem[]>, FormValidationProps {
  context: Context;
  entity: Entity;
  closeAfterReceive: boolean;
}

export class PoList extends Component<PropTypes> {
  onItemChange = (newItem: PoItem) => {
    const { value, onChange } = this.props;
    const newValue = value.map((current) =>
      newItem.id === current.id ? newItem : current,
    );
    onChange(newValue);
  };

  render() {
    const {
      context,
      entity,
      closeAfterReceive,
      formValidation,
      onFormValidationChange,
      value,
    } = this.props;
    const hasReceived = value.some((item) => item.receivedQty > 0);

    return (
      <div className={`x-po-list${hasReceived ? " x-has-received" : ""}`}>
        <table>
          <thead>
            <tr className="x-po-list-header">
              <th>{_("Ordered")}</th>
              {hasReceived && <th>{_("Received")}</th>}
              <th>{_("Receiving")}</th>
              <th>{_("Part")}</th>
              <th>{_("Part Location")}</th>
              <th>{_("Unit Cost")}</th>
            </tr>
          </thead>
          <tbody>
            {value.map((item) => (
              <PoListItem
                key={item.id}
                context={context}
                entity={entity}
                hasReceived={hasReceived}
                closeAfterReceive={closeAfterReceive}
                items={value}
                formValidation={formValidation}
                onFormValidationChange={onFormValidationChange}
                value={item}
                onChange={this.onItemChange}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
