import { ApiCall } from "common/types/api";
import { withValue } from "common/with-value";
import { hasProtectedColumns } from "common/record/utils";
import { PropTypes, ActionHandler } from "./types";
import { Action } from "./action";

export const simpleAction = <V extends unknown>(
  title: string,
  actionLabel: string,
  fn: (apiCall: ApiCall, entityName: string, recordIds: string[]) => any,
  btnLabel?: string,
): ActionHandler<V, PropTypes> => {
  return {
    label: actionLabel,
    Ui: withValue<V, PropTypes>((props) => {
      const onOk = (apiCall: ApiCall, dismiss: (x: boolean) => any) =>
        fn(
          apiCall,
          props.entity.name,
          props.records.map((r) => r.properties.id),
        ).then(dismiss);

      return (
        <Action
          requiresAuthentication={hasProtectedColumns(props.entity)}
          context={props.context}
          dismiss={props.dismiss}
          entity={props.entity}
          records={props.records}
          title={title}
          btnLabel={btnLabel ?? actionLabel}
          onOk={onOk}
        />
      );
    }),
  };
};
