import { Component } from "react";
import { devExpressApi } from "common/api/devexpress";
import { rolesApi } from "common/api/roles";
import { Context } from "common/types/context";
import { DevExpressDashboard } from "common/types/dashboards";
import { hasRolePermissionTo } from "common/functions/roles";
import { Role } from "common/types/roles";
import { ActionButtonSmall } from "common/ui/buttons";
import { ButtonModal } from "common/widgets/modal/button-modal";
import { DashboardSettingsValue, defaultValue, SettingsForm } from "./form";

interface PropTypes {
  context: Context;
  dashboard: DevExpressDashboard;
  onDashboardUpdated: () => void;
}

interface StateType {
  value: DashboardSettingsValue;
  roles: Role[];
}

export class Settings extends Component<PropTypes, StateType> {
  state: StateType = { value: defaultValue, roles: [] };

  componentDidMount() {
    const { context } = this.props;
    const { apiCall } = context;

    rolesApi(apiCall)
      .list()
      .then((r) => {
        const roles = r.filter((role) =>
          hasRolePermissionTo(role, "Dashboard", "Read"),
        );
        this.setState({ roles });
      });
  }

  onOpen = () => {
    const { dashboard } = this.props;
    const { isShared, roleIds, sites } = dashboard;

    this.setState({
      value: {
        isShared: !!isShared,
        roleIds,
        sites: sites ? sites : undefined,
      },
    });
  };

  onChange = (value: DashboardSettingsValue) => {
    this.setState({ value });
  };

  onSave = () => {
    const { context, dashboard, onDashboardUpdated } = this.props;
    const { value } = this.state;

    return devExpressApi(context.apiCall)
      .update({ ...dashboard, ...value, sites: value?.sites })
      .then(onDashboardUpdated);
  };

  render() {
    const { value, roles } = this.state;
    const { context } = this.props;
    const { isShared, roleIds } = value;

    return (
      <ButtonModal
        className="qa-settings"
        button={
          <ActionButtonSmall title={_("Dashboard settings")}>
            <i className="fa fa-cog" /> {_("Settings")}
          </ActionButtonSmall>
        }
        title={_("Settings")}
        size="medium"
        okLabel={_("Save")}
        onOpen={this.onOpen}
        onOk={!isShared || roleIds.length ? this.onSave : null}
        allowFullscreen={false}
        content={
          <SettingsForm
            context={context}
            roles={roles}
            value={value}
            onChange={this.onChange}
          />
        }
      />
    );
  }
}
