import * as R from "ramda";
import { Component } from "react";
import type { RecordSitesProps } from "common/form/types";
import { ApiCall } from "common/types/api";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { TRENDING_METER_TYPE_ID } from "common/types/system-strings";
import { behaveAs, isSharedMultipleSitesEntity } from "common/entities";
import { recordsApi } from "common/api/records";
import { Record } from "common/types/records";
import { CALENDAR_ID, EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { ValueProps } from "common/with-value-for";
// eslint-disable-next-line import/no-cycle
import { ReferenceModal } from "./modal";

export const COLUMNS_TO_OMIT = [CALENDAR_ID];

interface PropTypes extends ValueProps<ForeignKey>, RecordSitesProps {
  context: Context;
  parentEntity?: Entity;
  entity: Entity;
  column: EntityColumn;
}

interface StateType {
  value: Record;
}

const saveRecord = (apiCall: ApiCall, entity: string, record: Record) =>
  record.properties.id
    ? recordsApi(apiCall).update(entity, record)
    : recordsApi(apiCall).create(entity, record);

const getRecordWithSites = (
  entity: Entity,
  record: Record,
  recordSites: string[],
): Record =>
  isSharedMultipleSitesEntity(entity)
    ? {
        ...record,
        properties: { ...record?.properties, sites: recordSites ?? [] },
      }
    : record;

export const getDefaultValueForEntity = (
  entity: Entity,
  parentEntity: Entity,
  recordSites?: string[],
): Record => {
  const baseRecord: Record =
    behaveAs("Meter", entity) && behaveAs("ConditionMonitoring", parentEntity)
      ? { actions: [], properties: { meterTypeId: TRENDING_METER_TYPE_ID } }
      : undefined;
  return getRecordWithSites(entity, baseRecord, recordSites);
};

export class ReferenceAddOn extends Component<PropTypes, StateType> {
  static readonly displayName = "ReferenceAddOn";

  constructor(props: PropTypes) {
    super(props);

    this.state = {
      value: getDefaultValueForEntity(
        props.entity,
        props.parentEntity,
        props.recordSites,
      ),
    };
  }

  componentDidUpdate(prevProps: Readonly<PropTypes>) {
    if (prevProps.recordSites !== this.props.recordSites) {
      this.setState((prevState) => ({
        value: getRecordWithSites(
          this.props.entity,
          prevState.value,
          this.props.recordSites,
        ),
      }));
    }
  }

  onRecordSaved = () => {
    const { value } = this.state;
    if (!value) return;

    const { entity, parentEntity, recordSites, context, onChange } = this.props;
    const { culture } = context.uiFormat;

    const stringField = R.find((c) => c.dataType === "string", entity.columns);

    saveRecord(context.apiCall, entity.name, value).then(({ id }) => {
      const { properties } = value;
      const newRecord: ForeignKey = {
        id,
        number: undefined,
        title:
          properties[`description_${culture.replace("-", "")}`] ||
          properties[stringField.name],
      };
      const newValue = isSharedMultipleSitesEntity(entity)
        ? { ...newRecord, sites: properties.sites }
        : newRecord;
      onChange(newValue);
      this.setState({
        value: getDefaultValueForEntity(entity, parentEntity, recordSites),
      });
    });
  };

  onNewRecordChange = (newRecord: Record) => {
    const { entity, parentEntity, recordSites } = this.props;
    this.setState({
      value: newRecord
        ? getRecordWithSites(entity, newRecord, recordSites)
        : getDefaultValueForEntity(entity, parentEntity, recordSites),
    });
  };

  render() {
    const { context, parentEntity, entity, column } = this.props;

    const canCreate =
      context.entities && R.includes("Create", entity.commands || []);

    if (!canCreate) return <span />;

    return (
      <ReferenceModal
        context={context}
        value={this.state.value}
        field={column.name}
        onChange={this.onNewRecordChange}
        onSave={this.onRecordSaved}
        parentEntity={parentEntity}
        entity={entity}
      />
    );
  }
}
