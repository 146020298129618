import { datadogRum } from "@datadog/browser-rum";
import { getInitialConfiguration } from "common/monitoring/configuration";
import {
  GlobalContextProperties,
  InitializationParams,
  User,
} from "common/monitoring/types";

export const rumLogger = {
  initialize: (params: InitializationParams = {}) => {
    const initConfiguration = getInitialConfiguration(params);

    datadogRum.init({
      ...initConfiguration,
      applicationId: "b80790b5-4e08-4134-97ec-408c9ea0a15c",
      sessionSampleRate: 100,
      trackUserInteractions: true,
      trackLongTasks: true,
      trackResources: true,
      allowedTracingUrls: [
        /https:\/\/.*\.x5beta\.com/,
        /https:\/\/.*\.accelix\.com/,
      ],
    });
  },

  setUser: (user: User) => {
    datadogRum.setUser(user);
  },

  setGlobalContextProperties: (properties: GlobalContextProperties = {}) => {
    Object.entries(properties).forEach(([key, value]) => {
      datadogRum.setGlobalContextProperty(key, value);
    });
  },

  logCustomError: (error: string) => {
    datadogRum.addError(new Error(error));
  },
};
