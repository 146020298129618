import * as R from "ramda";
import { resolveDynamicValue } from "common/form/dynamic-values";
import { defaultFor } from "common";
import { recordsApi } from "common/api/records";
import {
  defaultEventProps,
  getAvailableTypes,
} from "common/api/scheduled-event";
import { searchApi } from "common/api/search";
import { behaveAs, getColumn } from "common/entities";
import { Entities, Entity } from "common/entities/types";
import {
  getFormWithResolvedDefaults,
  getDynamicDefaultsToResolve,
  getNonDynamicDefaults,
} from "common/form/defaults";
import { systemColumnsToOmit } from "common/form/functions/entity";
import { isForeignKey } from "common/functions/foreign-key";
import {
  filterFormsByEntity,
  getFormById,
  getFormByIdOrEntity,
} from "common/functions/forms";
import { merge1, mergeChain } from "common/merge";
import { getSelectWithId } from "common/query/select";
import {
  addDefaultCalendarId,
  getExtraPropsPartialForValue,
  getRelatedAndCustomCommonKeys,
  getWorkOrderTaskEntityKey,
  resolveForeignKeys,
} from "common/record/edit/functions";
import {
  defaultFormValue,
  getPropertiesWithTemporaryIds,
} from "common/record/edit/value";
import { FormValue, type StandardValue } from "common/record/types";
import { addTempIdToRecord, removeSystemColumns } from "common/record/utils";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { CancellablePromise } from "common/types/promises";
import {
  KeysOf,
  Properties,
  Record,
  RelatedRecords,
} from "common/types/records";
import { Form } from "common/types/forms";

const populateRecordByResolvedRelated = (
  entities: Entities,
  entity: Entity,
  properties: Properties,
  resolvedProps: Properties,
): Properties =>
  (R.keys(properties) as KeysOf<Properties>).reduce((acc, column) => {
    if (!resolvedProps[column] || R.isEmpty(resolvedProps[column])) return acc;

    const extraProps =
      column === "parentId"
        ? // if we omit some columns (ie follow-up), getExtraPropsPartialForValue puts everything back again
          // this hack is to avoid this. RTS
          { parentId: resolvedProps[column] }
        : getExtraPropsPartialForValue(
            entities,
            entity,
            properties,
            column,
            resolvedProps[column],
          );

    return R.mergeRight(acc, extraProps) as Properties;
  }, properties);

export const fetchAssetForeignKeys = (
  context: Context,
  entity: Entity,
  assetIds: string[] = [],
): CancellablePromise<ForeignKey[]> => {
  if (!assetIds.length) return CancellablePromise.resolve([]);

  const { entities, apiCall } = context;
  const { eventAssetEntity } = entity.arguments;
  const assetId = getColumn(entities[eventAssetEntity], "assetId");
  const assetEntity = assetId.relatedEntity;
  const originalQuery = entities[assetEntity].query;

  // see createUniqueAssetsQuery doing the same but `notin`
  const assetQuery = {
    entity: assetEntity,
    query: R.mergeRight(originalQuery, {
      select: getSelectWithId(originalQuery.select),
      filter: {
        and: [
          { name: "isDeleted", op: "isfalse" },
          { name: "id", op: "in", value: assetIds.join(",") },
        ],
      },
    }),
  };

  return searchApi(apiCall).runQuery(assetQuery) as CancellablePromise<
    ForeignKey[]
  >;
};

export const getScheduledWODependencies = (
  context: Context,
  entity: Entity,
  defaultProperties: Properties = defaultFor<Properties>(),
  scheduledAssetIds: string[] = [],
  isNew?: boolean,
): CancellablePromise<FormValue> => {
  const { workOrderEntity } = entity.arguments;
  const woForms = filterFormsByEntity(context.forms, workOrderEntity);

  // If only one form is available, preselect it
  const formId = woForms?.length === 1 ? woForms[0]?.id : undefined;

  const availableTypes = getAvailableTypes(context);

  const scheduledWOProperties = R.omit(["related", "id"], defaultProperties);

  const defaults = R.keys(scheduledWOProperties).length
    ? scheduledWOProperties
    : { workOrderForm: { properties: { formId } } };

  const typeProperties = {
    ...(availableTypes.length > 1
      ? undefined
      : defaultEventProps(context, availableTypes[0])),
    ...defaults,
  };
  const properties = getPropertiesWithTemporaryIds(entity, typeProperties);

  const eventAssetEntity = entity.arguments.eventAssetEntity;

  return fetchAssetForeignKeys(context, entity, scheduledAssetIds).then(
    (assetsFks = []) => {
      const assets = assetsFks.map((assetId: ForeignKey) => ({
        properties: {
          assetId,
          meters: [],
        },
      }));

      const related = assetsFks.length
        ? { [eventAssetEntity]: assets }
        : defaultProperties?.related;

      return {
        record: { properties, isNew, related },
        ui: { detail: {} },
      } as unknown as FormValue;
    },
  );
};

export const mapWorkOrderTaskRelatedRecords = (
  entities: Entity[],
  record: Record,
) =>
  entities.reduce((acc, entity) => {
    const {
      arguments: { targetEntity },
      name,
    } = entity;
    const origName = name;
    const newName = targetEntity;
    const records = record.related[origName].map((r, i) => {
      r.properties = R.keys(r.properties).reduce((acc, key) => {
        const newKey = key === "c_step" ? "step" : key;
        return R.mergeRight(acc, { [newKey]: r.properties[key] });
      }, {});
      return addTempIdToRecord(removeSystemColumns(r), `${i + 1}`);
    });
    return merge1(newName, records, acc);
  }, {} as RelatedRecords);

const mapRecordProperties = (entity: Entity, record: Record) => {
  const workOrderTaskEntity = entity.columns
    .filter((c) => !R.includes(c.name, systemColumnsToOmit))
    .map((c) => c.name);

  return R.pick(workOrderTaskEntity, record.properties);
};

const mergeDefaultsAndTaskProps = (
  defaultValue: StandardValue,
  relatedEntitiesMapped: RelatedRecords,
  mappedProperties: Properties,
  nonDynamicDefaults: Properties,
  resolvedFormDefaults: Properties = {},
): FormValue => {
  // The order is important here
  // 1. nonDynamicDefaults
  // 2. resolvedFormDefaults
  // 3. mappedProperties : Overwrites form defaults
  const propertiesMappedWithWoDefaults = {
    ...nonDynamicDefaults,
    ...resolvedFormDefaults,
    ...mappedProperties,
  };

  return mergeChain(defaultValue)
    .prop("ui")
    .prop("detail")
    .set("form", propertiesMappedWithWoDefaults)
    .up()
    .prop("related")
    .set("form", relatedEntitiesMapped)
    .output();
};

export const getWoTaskDependencies = (
  context: Context,
  entity: Entity,
  defaultProperties: any,
  woTaskEntityKey: string,
  defaultValue: StandardValue,
  form: Form,
): CancellablePromise<FormValue> => {
  const [, woTaskEntity] = woTaskEntityKey.split("_");
  const defaults = form?.settings?.defaults ?? {};
  const entities = R.values(context.entities);
  const taskItemEntities = R.filter(
    (e) => behaveAs("TaskItem", e) && e.arguments.ownerEntity === woTaskEntity,
    entities,
  );

  if (!taskItemEntities.length) return CancellablePromise.resolve(defaultValue);

  return recordsApi(context.apiCall)
    .get(woTaskEntity, defaultProperties[woTaskEntityKey], true)
    .then((workOrderTaskRecord: Record) => {
      const relatedEntitiesMapped = mapWorkOrderTaskRelatedRecords(
        taskItemEntities,
        workOrderTaskRecord,
      );
      const workOrderTaskEntity = context.entities[woTaskEntity];
      const mappedProperties = mapRecordProperties(
        workOrderTaskEntity,
        workOrderTaskRecord,
      );
      const { defaultsWithDynamicValues, promiseArray } =
        getDynamicDefaultsToResolve(context, entity, defaults);
      const nonDynamicDefaults = getNonDynamicDefaults(
        defaults,
        defaultsWithDynamicValues,
      );

      if (!promiseArray.length) {
        return mergeDefaultsAndTaskProps(
          defaultValue,
          relatedEntitiesMapped,
          mappedProperties,
          nonDynamicDefaults,
        );
      }

      return CancellablePromise.all(promiseArray)
        .then((data: Record[][]) => {
          const resolvedFormDefaultsWithDynamicValues =
            defaultsWithDynamicValues.reduce((acc, object, index: number) => {
              const { key, dataType, value, entity } = object;

              const newValue = resolveDynamicValue(
                dataType,
                entity,
                value,
                context,
                data[index],
              );

              return R.mergeRight(acc, { [key]: newValue });
            }, {});

          return mergeDefaultsAndTaskProps(
            defaultValue,
            relatedEntitiesMapped,
            mappedProperties,
            nonDynamicDefaults,
            resolvedFormDefaultsWithDynamicValues,
          );
        })
        .catch(() => {
          return mergeDefaultsAndTaskProps(
            defaultValue,
            relatedEntitiesMapped,
            mappedProperties,
            nonDynamicDefaults,
          );
        });
    });
};

export const getRecordDependencies = (
  context: Context,
  entity: Entity,
  isNew: boolean,
  defaultProps: Properties,
  formId: number,
  scheduledAssetIds?: string[],
  resolveDynamicValues: boolean = true,
  selectFormById: boolean = false,
) => {
  const defaultProperties = addDefaultCalendarId(context, entity, defaultProps);

  // if selectFormById is true, the form matching formId is used and not
  // the first entity form
  const form = selectFormById
    ? getFormById(context.forms, formId)
    : getFormByIdOrEntity(context.forms, entity?.name, formId);
  return resolveForeignKeys(context, entity, form, defaultProperties).then(
    (resolvedProperties) => {
      const props = populateRecordByResolvedRelated(
        context.entities,
        entity,
        defaultProperties,
        resolvedProperties,
      );

      // Add the current site to SharedMultipleSites Entity and References
      const properties =
        entity?.recordScope === "SharedMultipleSites" &&
        entity?.type !== "SubEntity"
          ? { ...props, sites: [context.site.name] }
          : props;

      const defaultForm = defaultFormValue(isNew, properties, form);

      if (!isNew) return CancellablePromise.resolve(defaultForm);

      if (behaveAs("ScheduledEvent", entity)) {
        return getScheduledWODependencies(
          context,
          entity,
          properties,
          scheduledAssetIds,
          isNew,
        );
      }

      const woTaskEntityKey = getWorkOrderTaskEntityKey(properties);
      if (woTaskEntityKey) {
        return getWoTaskDependencies(
          context,
          entity,
          properties,
          woTaskEntityKey,
          defaultForm as StandardValue,
          form,
        );
      }

      const requestId = properties?.requestId;
      if (requestId && isForeignKey(requestId)) {
        const requestCustomKeys = getRelatedAndCustomCommonKeys(
          R.keys(requestId) as KeysOf<Properties>,
          R.keys(properties) as KeysOf<Properties>,
        );
        return getFormWithResolvedDefaults(
          context,
          entity,
          form,
          defaultForm,
          properties,
          requestCustomKeys,
          resolveDynamicValues,
        );
      }

      return getFormWithResolvedDefaults(
        context,
        entity,
        form,
        defaultForm,
        properties,
        undefined,
        resolveDynamicValues,
      );
    },
  );
};
