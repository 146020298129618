import * as R from "ramda";
import { SubForeignKey } from "common/types/foreign-key";
import { isType } from "common";
import { SummaryRecord, SummaryRecordTitle } from "common/types/records";

interface StringifiedSummaryRecord {
  title: string;
  subtitle: string;
  subsubtitle: string;
}

const toString = (val: string | number) => (R.isNil(val) ? "" : val.toString());

export const getDisplayText = (item: SummaryRecordTitle) => {
  const isSubFk = isType<SummaryRecordTitle, SubForeignKey<string>>(["title"]);
  return toString(isSubFk(item) ? item.title : item);
};

export const stringifySummaryRecords = (
  items: SummaryRecord[],
): StringifiedSummaryRecord[] =>
  items.map((item) => {
    const { title, subtitle, subsubtitle, number } = item;

    return {
      title: getDisplayText(title),
      subtitle: getDisplayText(subtitle),
      subsubtitle: getDisplayText(subsubtitle ?? number),
    };
  });

export const getMatchingProp = (
  items: StringifiedSummaryRecord[],
  prop: keyof StringifiedSummaryRecord,
  filter: string,
) => items?.find((i) => i?.[prop].toLowerCase().includes(filter))?.[prop];
