import { defaultFor } from "common";
import { RequiredField } from "common/form/ui";
import { LinkConfig } from "common/types/dashboards";
import { StringInput } from "common/widgets/input-with-submit/string";
import { withValue, WithValue } from "common/with-value";

const defaultValue = defaultFor<LinkConfig>();

const LinkConfigFormComp = ({
  value = defaultValue,
  onChangeMergeValue,
}: WithValue<LinkConfig>) => (
  <>
    <RequiredField label={_("Url")} value={value.url}>
      <StringInput
        className="qa-widget-link-url"
        placeholder={_("e.g. http://myweb.com")}
        value={value.url}
        onChange={onChangeMergeValue("url")}
      />
    </RequiredField>
    <RequiredField label={_("Label")} value={value.label}>
      <StringInput
        className="qa-widget-link-label"
        value={value.label}
        onChange={onChangeMergeValue("label")}
      />
    </RequiredField>
  </>
);

export const LinkConfigForm = withValue<LinkConfig>(
  LinkConfigFormComp,
  "LinkConfigForm",
);
