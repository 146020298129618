import { Context } from "common/types/context";
import { behaveAs, getColumn } from "common/entities";
import { Entity } from "common/entities/types";
import { LabelWidget } from "common/form/widget/label-widget";
import { Subtitle } from "../utils";

interface PropTypes {
  context: Context;
  entity: Entity;
  columnName: string;
  value: Subtitle;
  isNew: boolean;
}

/** Subtitle line */
export const SubtitleInfo = ({
  context,
  value,
  entity,
  columnName,
  isNew,
}: PropTypes) => {
  const isWo = behaveAs("WorkOrder", entity);
  const stringValue = isNew && isWo && columnName === "status" ? "O" : "";
  const subtitleLabel = value.label ? value.label + ": " : "";
  const subtitleValue = value.value || stringValue;
  const color = isWo ? "badge" : "txt-color-blueLight";

  return (
    <div className={`${color} x-info-subtitle qa-${entity.name}-${columnName}`}>
      <span className="x-subtitle-label qa-subtitle-label">
        {subtitleLabel}
      </span>
      <div className="x-subtitle-value qa-subtitle-value">
        <LabelWidget
          context={context}
          column={getColumn(entity, columnName)}
          entityName={entity.name}
          value={subtitleValue}
        />
      </div>
    </div>
  );
};
