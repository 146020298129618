import { Children, ReactElement } from "react";
import { components, MenuListProps } from "react-select";

export const LimitedOptionsMessage = () => (
  <div className="x-selector__limited-options">
    {_("Too many results. Please use search to narrow them down.")}
  </div>
);

export const createLimitedMenuList = (limit: number) => {
  const LimitedMenuList = (props: MenuListProps) => {
    const childrenArray = Children.toArray(props.children) ?? [];
    const childrenWithLimitMessage = childrenArray.map(
      (child: ReactElement) => {
        const nestedChildren = child?.props?.children;
        const isLimited =
          Array.isArray(nestedChildren) && nestedChildren.length >= limit;
        return isLimited ? (
          <>
            {child}
            <LimitedOptionsMessage />
          </>
        ) : (
          child
        );
      },
    );

    return (
      <components.MenuList {...props}>
        {childrenWithLimitMessage}
        {childrenWithLimitMessage.length >= limit ? (
          <LimitedOptionsMessage />
        ) : undefined}
      </components.MenuList>
    );
  };

  return LimitedMenuList;
};
