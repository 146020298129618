interface PropTypes {
  message: string;
}

export const NotFound = ({ message }: PropTypes) => (
  <div>
    {_("{MESSAGE} not found. Please remove it.").replace("{MESSAGE}", message)}
  </div>
);

NotFound.displayName = "NotFound";
