import { Form } from "common/types/forms";

export const filterFormsByEntity = (forms: Form[] = [], entityName: string) =>
  forms.filter((f) => f.entityName === entityName);

/**
 * Tries to get a form first by ID (if supplied) or the first form matching the entityName.
 * @param forms
 * @param entityName
 * @param formId
 */
export const getFormByIdOrEntity = (
  forms: Form[],
  entityName: string,
  formId?: number,
): Form => {
  if (!forms) return undefined;
  return formId
    ? forms.find((f) => f.id === formId)
    : forms.find((f) => f.entityName === entityName);
};

export const getFormById = (forms: Form[], formId: number): Form =>
  formId && forms ? forms.find((f) => f.id === formId) : undefined;
