import { ComponentType } from "react";
import { Context } from "common/types/context";
import { Component } from "common/component";

interface WithWalkMe {
  context: Context;
}

type WithWalkMeOutput<PropTypes> = PropTypes & WithWalkMe;

const getWalkMeLanguage = (culture: string) => {
  switch (culture) {
    case "es-AR":
      return "ESP";
    case "pt-BR":
      return "POR";
    case "zh-CN":
      return "chinese";
    case "fr-FR":
      return "French";
    case "ru-RU":
      return "Russian";
    case "de-DE":
      return "German";
    case "ja-JP":
      return "ja-JP";
    case "nl-NL":
      return "nl-NL";
    case "hu-HU":
      return "hu-HU";
    case "it-IT":
      return "it";
    case "pl-PL":
      return "PL";
    case "sk":
      return "sk-SK";
    // for 'en-US' or unsupported to default english
    default:
      return "";
  }
};

const walkMeChangeLanguage = (newCulture: string) => {
  const walkMeAPI = (window as any).WalkMeAPI;
  if (walkMeAPI) {
    walkMeAPI.changeLanguage(getWalkMeLanguage(newCulture));
  }
};

export function withWalkMe<PropTypes>(
  YourComponent: ComponentType<WithWalkMeOutput<PropTypes>>,
) {
  return class WithWalkMeComp extends Component<WithWalkMeOutput<PropTypes>> {
    static readonly displayName = "WithWalkMeComp";

    componentDidMount() {
      const { context } = this.props;

      (window as any)._walkmeConfig = { smartLoad: true };

      (window as any)._walkmeVariables = {
        userId: context.id,
        userUserName: context.userName,
        userName: context.name,
        userRole: context.role.name,
        userCulture: context.uiFormat.culture,
      };

      (window as any).walkme_event = (eventData: any) => {
        if (eventData.Type === "LauncherIsShown") {
          walkMeChangeLanguage(context.uiFormat.culture);
          (window as any).walkme_event = undefined;
        }
      };
    }

    componentDidUpdate(prevProps: Readonly<WithWalkMeOutput<PropTypes>>) {
      const prevCulture = prevProps.context.uiFormat.culture;
      const newCulture = this.props.context.uiFormat.culture;
      if (prevCulture !== newCulture) {
        walkMeChangeLanguage(newCulture);
      }
    }

    render() {
      return <YourComponent {...this.props} />;
    }
  };
}
