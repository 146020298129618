interface PropTypes {
  widgetLabel: string;
  linkLabel: string;
  linkUrl: string;
}

export const LinkWidget = (props: PropTypes) => {
  const { widgetLabel, linkLabel, linkUrl } = props;

  return (
    <div className="x-dashboard-widgets-column x-dashboard-link-widget">
      <header className="x-padding-10">
        <h2>{widgetLabel}</h2>
      </header>
      <div className="x-dashboard-link-content">
        <div className="x-dashboard-link-label qa-dashboard-link-label">
          <label>{_("Label")}</label>
          <div className="x-link-label">{linkLabel}</div>
        </div>
        <div className="x-dashboard-link-url qa-dashboard-link-url">
          <label>{_("Url")}</label>
          <div className="x-link-url">
            <a href={linkUrl}>{linkUrl}</a>
          </div>
        </div>
      </div>
      <div className="clear" />
    </div>
  );
};
LinkWidget.displayName = "LinkWidget";
