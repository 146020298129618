import { defaultFor } from "common";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import {
  replaceFormsLookupQueries,
  createNoDuplicateIdLookUpQuery,
} from "../common/functions";

export const updateContext = (
  context: Context,
  entity: Entity = defaultFor<Entity>(),
  ids: string[],
) =>
  replaceFormsLookupQueries(
    context,
    entity.name,
    "groupId",
    createNoDuplicateIdLookUpQuery(
      context,
      entity,
      ids,
      "groupId",
      "groupEntity",
    ),
  );
