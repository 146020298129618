import { useEffect, useState } from "react";
import { cycleCountApi, PartsDetailsResponse } from "common/api/cycle-count";
import { behaveAs, getRelatedEntities } from "common/entities";
import { Action } from "common/record/actions/action";
import { DismissAction, PropTypes } from "common/record/actions/types";
import {
  areRecordsReadyToSubmit,
  isCountReadyToSubmit,
  mapResponse,
  mapToSubmitAllCount,
  requiresInput,
} from "common/record/actions/ui/submit-parts-count/functions";
import { hasProtectedColumns } from "common/record/utils";
import { ApiCall } from "common/types/api";
import { ApiErrorResponse } from "common/types/error";
import { Properties } from "common/types/records";
import { ApiError } from "common/ui/api-error";
import { LoadingIcon } from "common/widgets/loading-icon";
import { defaultFor } from "common/index";
import { SubmitAllActionTypes } from "common/record/actions/ui/submit-parts-count/types";
import { SubmitWithoutInput } from "common/record/actions/ui/submit-parts-count/submit-without-input";
// eslint-disable-next-line import/no-cycle
import { SubmitWithInput } from "common/record/actions/ui/submit-parts-count/submit-with-input";
import { ActionButtonSmall } from "common/ui/buttons";

export const SubmitPartsCount = (props: PropTypes) => {
  const { context, entity, records, dismiss } = props;
  const { entities, apiCall } = context;

  const cycleCountRecord = records[0]?.properties ?? defaultFor<Properties>();
  const partsToBeCountedEntity = getRelatedEntities(entity, entities).find(
    (entity) => behaveAs("PartsToBeCounted", entity),
  );
  const partLocationEntityName = partsToBeCountedEntity?.arguments?.stockEntity;

  const [totalPartsToBeCounted, setTotalPartsToBeCounted] = useState<number>(0);
  const [partsToBeCounted, setPartsToBeCounted] = useState<
    SubmitAllActionTypes[]
  >([]);
  const [isLoadingPartsDetails, setIsLoadingPartsDetails] =
    useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<ApiErrorResponse>(undefined);

  const partsWithInputRequired = partsToBeCounted.filter(requiresInput);

  useEffect(() => {
    setIsLoadingPartsDetails(true);

    cycleCountApi(apiCall)
      .getPartsDetails(entity.name, cycleCountRecord.id)
      .then((response: PartsDetailsResponse[] = []) => {
        const partDetails = mapResponse(response);

        setTotalPartsToBeCounted(partDetails.length);
        setPartsToBeCounted(partDetails.filter(isCountReadyToSubmit));

        setIsLoadingPartsDetails(false);
      })
      .catch(() => {
        // Error is handled by the API call function
        setIsLoadingPartsDetails(false);
      });
  }, []);

  const onSubmit = (apiCall: ApiCall, dismiss: DismissAction) => {
    setIsSubmitting(true);

    return cycleCountApi(apiCall)
      .submitAll(
        entity.name,
        cycleCountRecord.id,
        totalPartsToBeCounted,
        mapToSubmitAllCount(partsToBeCounted),
      )
      .then(() => {
        dismiss(true);
        setIsSubmitting(false);
      })
      .catch((error) => {
        // Error is handled by the API call function
        setError(error);
        dismiss(true);
      });
  };

  const onContentChange = (value: SubmitAllActionTypes) => {
    const newPartsToBeCounted = partsToBeCounted.map((record) =>
      record?.partsToBeCountedId === value.partsToBeCountedId
        ? { ...record, ...value }
        : record,
    );
    setPartsToBeCounted(newPartsToBeCounted);
  };

  const onApplyLastCost = () => {
    const newPartsToBeCounted = partsToBeCounted.map((record) =>
      record.lastCost && !record.cost
        ? { ...record, cost: record.lastCost }
        : record,
    );
    setPartsToBeCounted(newPartsToBeCounted);
  };

  if (isLoadingPartsDetails) return <LoadingIcon />;
  if (!partsToBeCounted.length) return null;
  if (!isLoadingPartsDetails && partsWithInputRequired.length === 0) {
    return (
      <SubmitWithoutInput
        context={context}
        entity={entity}
        dismiss={dismiss}
        partsToBeCounted={partsToBeCounted}
        cycleCountId={cycleCountRecord?.id}
      />
    );
  }

  const canLoadLastCost = partsToBeCounted.some(
    (record) => record.lastCost && !record.cost,
  );

  return (
    <Action
      {...props}
      requiresAuthentication={hasProtectedColumns(entity)}
      title={_("Submit")}
      btnLabel={_("Submit")}
      size="large"
      onOk={areRecordsReadyToSubmit(partsToBeCounted) ? onSubmit : undefined}
    >
      {isSubmitting ? <LoadingIcon /> : undefined}
      {error ? <ApiError error={error} /> : undefined}
      <div>
        <ActionButtonSmall
          onClick={onApplyLastCost}
          disabled={!canLoadLastCost}
        >
          {_("Apply Last Cost")}
        </ActionButtonSmall>
        {partsWithInputRequired.map((record) => (
          <SubmitWithInput
            key={record.partsToBeCountedId}
            context={context}
            partLocationEntityName={partLocationEntityName}
            partsToBeCountedEntity={partsToBeCountedEntity}
            value={record}
            onChange={onContentChange}
          />
        ))}
      </div>
    </Action>
  );
};
