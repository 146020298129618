import { Entity } from "common/entities/types";
import { HorizontalField } from "common/form/ui";
import { getSubFKTitle } from "common/functions/foreign-key";
import { addFilter } from "common/query/filter";
import { addJoinToQuery } from "common/query/joins";
import { FilterAnd, QueryForEntity } from "common/query/types";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { EntryBoxList } from "common/widgets/entry-box/list";
import { Hint } from "common/widgets/hint";
import { AdvancedSingleRecordSelector } from "common/widgets/record-selector";
import { ValueComponent } from "common/with-value-for";

const getQueryWithRecordFilter = (
  entity: Entity,
  recordId: string,
  assetsIds: string[] = [],
  selected: ForeignKey[] = [],
): QueryForEntity => {
  const queryFilter: FilterAnd = {
    and: [
      { name: "isDeleted", op: "isfalse" },
      ...(recordId ? [{ name: "id", op: "neq", value: recordId }] : []),
      ...(selected.length
        ? [
            {
              name: "id",
              op: "notin",
              value: selected.map((s) => s.id).join(","),
            },
          ]
        : []),
      ...(assetsIds.length
        ? [
            {
              name: "assetId",
              op: "in",
              path: `/${entity.arguments.eventAssetEntity}.scheduledEventId`,
              value: assetsIds.join(","),
              excludeFromFkExpansion: true,
            },
          ]
        : []),
    ],
  };

  return addFilter(queryFilter, {
    entity: entity.name,
    query: assetsIds.length
      ? addJoinToQuery(
          {
            column: "scheduledEventId",
            entity: entity.arguments.eventAssetEntity,
          },
          entity.query,
        )
      : entity.query,
  });
};

interface PropTypes {
  entity: Entity;
  context: Context;
  recordId: string;
  assetsIds?: string[];
}

export class PmOverrides extends ValueComponent<ForeignKey[], PropTypes> {
  static readonly displayName = "RelatedRecords";

  onRecordSelected = (newRecord: ForeignKey) => {
    const value = this.props.value || [];
    this.setValue(value.concat([newRecord]));
  };

  onDisplay = (r: ForeignKey) => getSubFKTitle(r.title);

  onChange = (records: ForeignKey[]) => {
    this.setValue(records || [], true);
  };

  render() {
    const { context, entity, recordId, assetsIds, value } = this.props;
    const message = _(
      "Selected PMs will have priority over this PM if they are triggered at the same time",
    );

    return (
      <div className="x-pm-trigger-overrides x-pm-section qa-pm-section">
        <HorizontalField label={_("PM Nesting")} disabled={!assetsIds?.length}>
          <AdvancedSingleRecordSelector
            context={context}
            entity={entity}
            query={getQueryWithRecordFilter(entity, recordId, assetsIds, value)}
            withLinks={true}
            dontKeepValue={true}
            value={undefined}
            onChange={this.onRecordSelected}
          />
          <Hint message={message} />
          {value?.length ? (
            <div className="x-pm-added-assets">
              <EntryBoxList<ForeignKey>
                display={this.onDisplay}
                value={value}
                onChange={this.onChange}
              />
            </div>
          ) : undefined}
        </HorizontalField>
      </div>
    );
  }
}
