import {
  getActionIcon,
  getActionTitle,
} from "common/record/actions/functions/title-and-icon";
import { Action } from "common/record/actions/types";
import { Properties } from "common/types/records";
import { CallbackButton } from "common/widgets/callback-button";

type QuickSelectProps = {
  action: Action;
  properties: Properties;
  index: number;
};

export const QuickSelect = ({
  action,
  properties,
  index,
}: QuickSelectProps) => (
  <CallbackButton
    key={action.name}
    className="x-action qa-related-select"
    data={properties}
    index={index}
    title={getActionTitle(action.name)}
    callback={action.fn}
    disabled={action.isDisabled}
  >
    <i className={`fa ${getActionIcon(action.name)}`} />
  </CallbackButton>
);
