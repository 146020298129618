import * as R from "ramda";
import { roundWithPrecision } from "common/math";
import {
  Currency,
  CurrencyRate,
  CurrencyRateIndex,
} from "common/types/currencies";
import { IndexedRates } from "x/account-settings/currencies/types";

export const rateIndex = (source: string, target: string) =>
  source && target ? `${source}X${target}` : undefined;

export const indexRates = (rates: CurrencyRate[]): CurrencyRateIndex =>
  R.indexBy((r) => rateIndex(r.source, r.target), rates);

export function getRate(
  source: Currency,
  target: Currency,
  indexedRates: IndexedRates,
) {
  if (!source || !target || !indexedRates) return undefined;
  const currencyRate = indexedRates[rateIndex(source.id, target.id)];
  return currencyRate || { source: source.id, target: target.id, rate: 0 };
}

// take a list of currencies, a primary currency and a partial list of rates
// (should include every rate from primary currency to every other currency)
// Returns a complete list of rates from every currency to every currency
export function expandRates(
  currencies: Currency[],
  primaryCurrency: Currency,
  rates: CurrencyRate[],
): CurrencyRate[] {
  const initialRates = indexRates(rates);

  const currencyNames = currencies.map(R.prop("id"));
  const currencyPairs = R.xprod(currencyNames, currencyNames);
  const uniqueCurrencyPairs = R.reject(
    ([source, target]) => source === target,
    currencyPairs,
  );

  return uniqueCurrencyPairs.map(([source, target]) => {
    const rate = initialRates[rateIndex(source, target)];
    if (rate) return rate;
    const sourceRate = initialRates[rateIndex(primaryCurrency.id, source)];
    const targetRate = initialRates[rateIndex(primaryCurrency.id, target)];

    if (!sourceRate || !targetRate) {
      throw new Error(
        `Insufficient initial rates provided,
        missing rate for ${source} to ${target}`,
      );
    }
    return {
      source,
      target,
      rate: roundWithPrecision(targetRate.rate / sourceRate.rate, 4),
    };
  });
}
