import * as R from "ramda";
import { defaultFor } from "common";
import { getRecordDependencies } from "common/record/dependencies";
import { getFormOrDefault } from "common/record/edit/functions";
import { RelatedFormBaseProps } from "common/record/form/content/related/table-with-form/related-form/types";
import { getFormTemporaryIdsMapper } from "common/record/form/functions";
import {
  DependenciesComp,
  dependenciesInjected,
} from "common/with-dependencies";
import { ValueComponent } from "common/with-value-for";
import { TableWithFormValue } from "../types";
import { EditRelatedForm } from "./form";

export class CreateRelatedFormWithDependencies extends ValueComponent<
  TableWithFormValue,
  RelatedFormBaseProps
> {
  static readonly displayName = "CreateRelatedFormWithDependencies";

  render() {
    const {
      context,
      entity,
      defaultForm,
      value = defaultFor<TableWithFormValue>(),
    } = this.props;

    const form = R.keys(value.form).length ? value.form : defaultForm;

    const layoutForm = getFormOrDefault(
      context,
      entity.name,
      form && form.formId,
      form,
    );
    const formId = (form && form.formId) || (layoutForm && layoutForm.id);
    const isNew = R.isNil(form) || (R.isNil(form.id) && R.isNil(form.tempId));
    const defaultValueDep = getRecordDependencies(
      context,
      entity,
      isNew,
      form,
      formId,
    );

    return (
      <DependenciesComp
        dependencies={{ defaultValue: defaultValueDep }}
        mapDependencies={
          isNew ? getFormTemporaryIdsMapper(entity, form) : undefined
        }
        child={
          <EditRelatedForm
            {...this.props}
            {...dependenciesInjected()}
            layoutForm={layoutForm}
          />
        }
      />
    );
  }
}
