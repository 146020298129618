import { EntityColumn } from "common/entities/entity-column/types";
import { isForeignKey } from "common/functions/foreign-key";
import { Context } from "common/types/context";
import { FkValue } from "common/types/foreign-key";
import { formatFk } from "common/widgets/foreign-key/functions";
import { HighlightProps, Label } from "common/widgets/label";
import { FkFormat } from "./types";

interface PropTypes extends HighlightProps {
  context: Context;
  value: FkValue; // can be expanded or not
  format: FkFormat;
  column?: EntityColumn;
}

/**
 * Internal (do not reuse). Use the generic index.
 * Gets a foreign key and formats it. Shows original id if not expanded.
 */
export const InternalFormatted = ({
  context,
  column,
  value,
  format,
  textToHighlight,
}: PropTypes) => (
  <Label
    value={
      isForeignKey(value)
        ? formatFk(context.entities)(
            value,
            format === "title [subtitle]",
            context.entities[column?.relatedEntity ?? value.entity],
            context,
          )
        : value
    }
    textToHighlight={textToHighlight}
  />
);
