import { ValueComponent, ValueProps } from "common/with-value-for";
import { getBooleanLabel } from "common/widgets/checkbox/label";
import { AlertErrorTip } from "../alert";

export interface PropTypes {
  label?: string;
  disabled?: boolean;
  children?: any;
  className?: string;
  message?: string;
  inputId?: string;
  hint?: string;
}

interface InternalPropTypes extends PropTypes {
  style?: string;
}

type Props = InternalPropTypes & ValueProps<boolean>;

class FancyCheckboxComp extends ValueComponent<boolean, InternalPropTypes> {
  static readonly displayName = "FancyCheckboxComp";
  render() {
    const {
      disabled,
      style,
      label = "",
      children,
      className = "",
      message,
      value = false,
      inputId,
      hint,
    } = this.props;

    const qa = label ? " qa-" + label.toLowerCase().replace(/ /g, "-") : "";
    const isChecked = value ? " checked" : "";
    const isDisabled = disabled ? " x-disabled" : "";

    const checkboxClass = value ? "fa fa-check-square-o" : "fa fa-square-o";

    return (
      <div className={`${style} qa-checkbox`} title={hint}>
        <label className={`${className}${qa}${isChecked}${isDisabled}`}>
          <i className={checkboxClass} />
          <input
            type="checkbox"
            disabled={disabled}
            id={inputId}
            checked={value}
            onChange={this.onChangeSetDefaultValue(!value)}
          />
          {label || getBooleanLabel(value)}
          {children}
        </label>
        {message && <AlertErrorTip message={message} />}
      </div>
    );
  }
}

const create = (displayName: string, style: string) => {
  const CheckboxComp = (props: Props) => (
    <FancyCheckboxComp
      {...props}
      style={style}
      value={props.value}
      onChange={props.onChange}
    />
  );
  CheckboxComp.displayName = displayName;
  return CheckboxComp;
};

export const FancyCheckboxSmall = create(
  "FancyCheckboxSmall",
  "x-fancy-checkbox-small",
);
export const FancyCheckbox = create("FancyCheckbox", "x-fancy-checkbox");
export const FancyCheckboxLarge = create(
  "FancyCheckboxLarge",
  "x-fancy-checkbox-large",
);
