import { translate } from "common/culture/report-translator";
import { Culture } from "common/culture/supported-cultures";
import { Entities } from "common/entities/types";
import { canAccessReportEntities } from "common/query/common";
import { ApiCall, Crud } from "common/types/api";
import { CancellablePromise } from "common/types/promises";
import { Report } from "common/types/reports";
import { messages, withNotifications } from "common/api/with-notifications";

interface ReportsApiType extends Crud<number, Report> {
  search: (entity?: string, name?: string) => CancellablePromise<Report[]>;
  export: (id: number) => CancellablePromise<any>;
}

const tx =
  (culture: Culture) =>
  (report: Report): Report =>
    culture ? translate(culture, report) : report;

const getParams = (entity: string, name: string) => {
  const entityParam = entity ? `entity=${entity}` : undefined;
  const nameParam = name ? `name=${name}` : undefined;
  const allParams = [entityParam, nameParam].filter((p) => !!p).join("&");

  return allParams ? `?${allParams}` : allParams;
};

const parseReports =
  (culture: Culture, entities: Entities) => (reports: Report[]) =>
    reports
      .filter((report) => canAccessReportEntities(entities, report))
      .map(tx(culture));

const isAllOrUndefined = (site: string) => {
  return !site || site.toLocaleLowerCase() === "all";
};

const apiSpec =
  (culture: Culture, entities: Entities, site?: string) =>
  (apiCall: ApiCall): ReportsApiType => ({
    get: (id: number) => apiCall("get", `api/reports/${id}`).then(tx(culture)),
    list: () => {
      const url = isAllOrUndefined(site)
        ? "api/reports"
        : `api/reports?site=${site}`;
      return apiCall("get", url).then(parseReports(culture, entities));
    },
    search: (entity?: string, name?: string) =>
      apiCall("get", `api/reports${getParams(entity, name)}`).then(
        parseReports(culture, entities),
      ),
    create: (report: Report) => apiCall("post", "api/reports", report),
    update: (report: Report) =>
      apiCall("put", `api/reports/${report.id}`, report),
    remove: (id: number) => apiCall("delete", `api/reports/${id}`),
    export: (id: number) => apiCall("post", `api/reports/${id}/export`),
  });

const getMessages = messages(() => ({
  created: _("The report was successfully created"),
  updated: _("The report was successfully updated"),
  removed: _("The report was successfully deleted"),
}));

export const reportsApi = (
  apiCall: ApiCall,
  culture: Culture,
  entities: Entities,
  site?: string,
): ReportsApiType =>
  withNotifications(getMessages, apiSpec(culture, entities, site))(apiCall);

export const apiWithoutTranslations = (apiCall: ApiCall, entities: Entities) =>
  reportsApi(apiCall, undefined, entities);
