import * as R from "ramda";
import { isDecimal } from "common/validate";
import { NumberValue as Value, ValidationFn } from "./types";

const getVal = (value: Value) => (R.isNil(value) ? "" : value.toString());

export const fromDisplay = (value: Value, sep: string) =>
  getVal(value)
    .replace(sep || ".", ".")
    .replace(/^\./, "0.")
    .replace(/^-\./, "-0.");

export const replaceSeparator = (value: Value, sep: string) =>
  getVal(value).replace(".", sep || ".");

export const checkRange = (min: number, max: number, value: Value) =>
  R.isNil(value) ||
  R.isEmpty(value) ||
  ((!min || +value >= min) && (!max || +value <= max));

export const valueLength = (value: Value) =>
  R.isNil(value) ? 0 : ("" + value).length;
export const isShorter = (oldValue: Value, newValue: Value) =>
  valueLength(newValue) < valueLength(oldValue);

export const getValidationWarning = (
  value: Value,
  isValid: ValidationFn = R.T,
) => (!isValid(value) ? _("The given value is not a valid number") : undefined);

export const getDecimalScaleWarning = (value: Value, decimalScale?: number) =>
  decimalScale && !isDecimal(decimalScale, value)
    ? _(
        "Number of decimal places should be lower or equal {DECIMAL_SCALE}",
      ).replace("{DECIMAL_SCALE}", decimalScale.toString())
    : undefined;

export const getRangeWarning = (
  value: Value,
  minValue: number,
  maxValue: number,
) => {
  if (checkRange(minValue, maxValue, value)) return undefined;

  if (minValue && !maxValue)
    return _("The given number should be higher or equal {MIN_VALUE}").replace(
      "{MIN_VALUE}",
      minValue.toString(),
    );
  if (!minValue && maxValue)
    return _("The given number should be lower or equal {MAX_VALUE}").replace(
      "{MAX_VALUE}",
      maxValue.toString(),
    );

  return _("The given number should be between {MIN_VALUE} and {MAX_VALUE}")
    .replace("{MIN_VALUE}", minValue.toString())
    .replace("{MAX_VALUE}", maxValue.toString());
};
