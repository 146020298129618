import * as R from "ramda";
import {
  behaveAs,
  filterByBehavior,
  getByBehaviorArgument,
  isRelatedTo,
} from "common/entities";
import { Entities, Entity } from "common/entities/types";

export const getScheduledEvent = (entities: Entities, entity: Entity) => {
  if (!behaveAs("Asset", entity)) return undefined;

  const assetEntities = R.values(filterByBehavior("EventAsset", entities));
  const eventAsset = R.find(isRelatedTo(entity.name), assetEntities);

  return (
    eventAsset &&
    getByBehaviorArgument(
      entities,
      "ScheduledEvent",
      "eventAssetEntity",
      eventAsset.name,
    )
  );
};

export const getWorkOrderEntity = (entities: Entities, assetEntity: Entity) => {
  if (!behaveAs("Asset", assetEntity)) return undefined;

  return getByBehaviorArgument(
    entities,
    "WorkOrder",
    "assetEntity",
    assetEntity.name,
  );
};
