/* eslint-disable @typescript-eslint/no-use-before-define */
import * as R from "ramda";
import { Context } from "common/types/context";
import { getLocalizedName } from "common";
import { Entity } from "common/entities/types";
import { EntityColumn } from "common/entities/entity-column/types";
import { Properties, KeysOf, Record } from "common/types/records";
import { Row } from "common/record/form/content/audit-trail/event-detail/row";
import { PayloadRow } from "common/record/form/content/audit-trail/event-detail/events/payload/row";
import {
  getNestedPayloadEntity,
  getPayloadColumn,
} from "common/record/form/content/audit-trail/event-detail/events/payload/properties/functions";

interface PropTypes {
  entity: Entity;
  showEmpty: boolean;
  properties: Properties;
  context: Context;
  title?: string;
  className?: string;
  hideLinks?: boolean;
}

const toOmit: ReadonlyArray<string> = [
  "EntityName",
  "Id",
  "IsDeleted",
  "Number",
  "OccurredOn",
  "RecordId",
  "UserId",
  "CreatedOn",
  "UpdatedOn",
];

const getNestedPayload = (
  context: Context,
  entity: Entity,
  column: EntityColumn,
  record: Record,
) => {
  if (!entity || !R.keys(record?.properties).length) return undefined;

  return (
    <Row key={column.name} label={getLocalizedName(column)}>
      <div className="x-inner-object">
        <PropertiesPayload
          entity={entity}
          context={context}
          showEmpty={false}
          properties={record.properties}
        />
      </div>
    </Row>
  );
};

export const PropertiesPayload = ({
  entity,
  showEmpty,
  title,
  properties,
  context,
  className,
  hideLinks,
}: PropTypes) => {
  if (!properties) return null;

  const columnsToOmit = entity.ownerFkColumn
    ? toOmit.concat(entity.ownerFkColumn)
    : toOmit;

  const columnsToShow = R.difference(
    R.keys(properties) as KeysOf<Properties>,
    columnsToOmit,
  );

  if (columnsToShow.length === 0) return null;

  const orderedColumnsToShow = R.sort<string>(
    R.ascend(R.identity),
    columnsToShow,
  );
  return (
    <div className="qa-record-payload-view">
      {title ? <legend className={className}>{title}</legend> : undefined}
      {orderedColumnsToShow
        .map((columnName) => {
          const column = getPayloadColumn(context.entities, entity, columnName);
          const nestedEntity = getNestedPayloadEntity(
            context,
            entity,
            columnName,
          );

          return nestedEntity ? (
            getNestedPayload(
              context,
              nestedEntity,
              column,
              properties[columnName],
            )
          ) : (
            <PayloadRow
              key={columnName}
              context={context}
              entity={entity}
              columnName={columnName}
              showEmpty={showEmpty}
              value={properties[columnName]}
              column={column}
              hideLinks={hideLinks}
            />
          );
        })
        .filter((r) => !!r)}
    </div>
  );
};
PropertiesPayload.displayName = "PropertiesPayload";
