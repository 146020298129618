import * as R from "ramda";
import { mergeChain } from "common/merge";
import {
  isSummaryField,
  QueryForEntity,
  SelectField,
} from "common/query/types";
import { ColumnDefinition } from "common/query/advanced-types";

export const moveColumn = (
  query: QueryForEntity,
  columnDefinitions: ColumnDefinition[],
  fromIndex: number,
  toIndex: number,
): QueryForEntity => {
  const select = query.query.select;

  const sourceColumn = columnDefinitions[fromIndex];
  const targetColumn = columnDefinitions[toIndex];

  const findSelectIndex = (valueKey: string) =>
    R.findIndex(
      (sf) =>
        isSummaryField(sf)
          ? valueKey === sf.entityName
          : valueKey === (sf.alias || (sf as SelectField).name),
      select,
    );

  const sourceIndex = findSelectIndex(sourceColumn.valueKey);
  const targetIndex = targetColumn
    ? findSelectIndex(targetColumn.valueKey)
    : select.length - 1;

  const source = select[sourceIndex];

  // Pluck the element out of the list (this moves all elements greater than
  // source one position to the left).
  const selectWithoutSource = R.remove(sourceIndex, 1, select);

  const newSelect = R.insert(targetIndex, source, selectWithoutSource);

  return mergeChain(query).prop("query").set("select", newSelect).output();
};

export const moveArrayItems = <T>(
  array: T[],
  offset: number,
  fromIndex: number,
  toIndex: number,
) =>
  R.insert(
    toIndex + offset,
    array[fromIndex + offset],
    R.remove(fromIndex + offset, 1, array),
  );
