import { addFilterToQuery } from "common/query/filter";
import { addJoinToQuery } from "common/query/joins";
import { QueryForEntity, Filter } from "common/query/types";
import { Entity } from "common/entities/types";

export const getReviewerQuery = (
  procurementEntity: Entity,
  approvalGroupId: string,
  contactId: string,
): QueryForEntity => {
  if (!procurementEntity) return undefined;

  const { query } = procurementEntity;

  const queryWithApprovalGroupJoin = addJoinToQuery(
    { column: "approvalGroupId" },
    query,
  );
  const queryWithContactJoin = addJoinToQuery(
    { column: "contactId" },
    queryWithApprovalGroupJoin,
  );

  const filters: Filter = {
    and: [
      { name: "isDeleted", op: "isfalse" },
      {
        name: "id",
        op: "eq",
        path: "/approvalGroupId",
        value: approvalGroupId,
      },
      {
        name: "id",
        op: "eq",
        path: "/contactId",
        value: contactId,
      },
    ],
  };

  return {
    entity: procurementEntity.name,
    query: addFilterToQuery(filters, queryWithContactJoin),
  };
};
