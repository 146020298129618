import { Component } from "react";
import { FormValidationProps } from "common/form/types";
import { getColumn } from "common/entities";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { PoItem } from "common/types/purchase-orders";
import { DefaultCurrencyInput } from "common/widgets/currency/input-with-default";
import { DefaultCurrencyLabel } from "common/widgets/currency/label-with-default";
import { formatFk } from "common/widgets/foreign-key/functions";
import { LinkFk } from "common/widgets/foreign-key/link-fk";
import { Int } from "common/widgets/number";
import { IntLabel } from "common/widgets/number/label";
import { ValueProps } from "common/with-value-for";
import { OrderedOverwritten } from "../ordered-overwritten";
import { isValidReceiving, isValidUnitCost, outstandingQty } from "./functions";

interface PropTypes extends ValueProps<PoItem>, FormValidationProps {
  context: Context;
  entity: Entity;
  items: PoItem[];
  closeAfterReceive: boolean;
  hasReceived: boolean;
}

export class PoListItem extends Component<PropTypes> {
  onReceivingChange = (newReceivingQty: string) => {
    const receivingQty =
      newReceivingQty === "0" ? 0 : parseInt(newReceivingQty) || undefined;
    this.props.onChange({ ...this.props.value, receivingQty });
  };

  onPartLocationChange = (partLocationId: ForeignKey) =>
    this.props.onChange({ ...this.props.value, partLocationId });

  onUnitCostChange = (unitCost: string) =>
    this.props.onChange({ ...this.props.value, unitCost });

  render() {
    const {
      context,
      entity,
      value,
      closeAfterReceive,
      hasReceived,
      items,
      formValidation,
      onFormValidationChange,
    } = this.props;
    const { entities } = context;
    const {
      orderedQty,
      receivedQty = 0,
      receivingQty,
      partId,
      unitCost,
      currency,
      partLocationId,
    } = value;

    const readOnly = outstandingQty(value) === 0;
    const currencyColumn = getColumn(entity, "unitCost");
    const showOrderedChange =
      closeAfterReceive &&
      receivingQty !== undefined &&
      orderedQty !== receivedQty + (receivingQty || 0);

    return (
      <tr className="x-po-list-item">
        {/* Ordered */}
        <td>
          {showOrderedChange ? (
            <OrderedOverwritten item={value} />
          ) : (
            <IntLabel value={orderedQty} />
          )}
        </td>
        {/* Received */}
        {hasReceived && (
          <td>
            <IntLabel value={receivedQty} />
          </td>
        )}
        {/* Receiving */}
        <td
          className={
            readOnly || isValidReceiving(value, items)
              ? undefined
              : "x-has-error"
          }
        >
          {readOnly ? (
            "-"
          ) : (
            <Int value={receivingQty} onChange={this.onReceivingChange} />
          )}
        </td>
        {/* Part */}
        <td>
          <LinkFk
            context={context}
            column={getColumn(entity, "partId")}
            value={partId}
          />
        </td>
        {/* Part Location */}
        <td>{formatFk(entities)(partLocationId, false)}</td>
        {/* Unit Cost */}
        <td
          className={
            readOnly || isValidUnitCost(unitCost) ? undefined : "x-has-error"
          }
        >
          {readOnly ? (
            <DefaultCurrencyLabel
              context={context}
              column={currencyColumn}
              currencyId={currency?.id}
              value={unitCost}
            />
          ) : (
            <DefaultCurrencyInput
              context={context}
              column={currencyColumn}
              currencyId={currency?.id}
              formValidation={formValidation}
              onFormValidationChange={onFormValidationChange}
              value={unitCost}
              onChange={this.onUnitCostChange}
            />
          )}
        </td>
      </tr>
    );
  }
}
