import {
  defaultEventProps,
  getAvailableTypes,
} from "common/api/scheduled-event";
import { Entity } from "common/entities/types";
import { FormFooter } from "common/form/footer";
import { FormValidation } from "common/form/types";
import {
  isAnyEntityUploadable,
  getTemporaryIdProps,
} from "common/record/edit/value";
import { ScheduledEventValue } from "common/record/types";
import { Context } from "common/types/context";
import { CancellablePromise } from "common/types/promises";
import { Record } from "common/types/records";
import { EventType } from "common/types/scheduled-event";
import { PermissionsError } from "common/widgets/error";
import { ValueComponent } from "common/with-value-for";
import { isValid } from "../validations";
import { TypeSelector } from "./event-type-selector";
import { EventScheduleForm } from "./form";

interface PropTypes {
  context: Context;
  entity: Entity;
  save: (record: Record, confirmDelete: boolean) => CancellablePromise<any>;
  saving: boolean;
  onDelete: () => any;
  deleting: boolean;
  onCancel: () => any;
  onHasChanged?: (isDirty: boolean) => any;
  isNew: boolean;
  auditTrailId: string;
}

interface StateType {
  formValidation: FormValidation;
}

export class ScheduledEventForm extends ValueComponent<
  ScheduledEventValue,
  PropTypes,
  StateType
> {
  static readonly displayName = "ScheduledEventForm";
  state: StateType = {
    formValidation: undefined,
  };

  onFormValidationChange = (formValidation: FormValidation) => {
    this.setState({ formValidation });
  };

  onChangeEventType = (eventType: EventType) => {
    const { context, entity, value } = this.props;
    const { workOrderForm } = value.record.properties;
    const { workOrderEntity } = entity.arguments;

    const temporaryIdProps = isAnyEntityUploadable([
      entity,
      context.entities[workOrderEntity],
    ])
      ? getTemporaryIdProps(value.record.properties)
      : undefined;

    const properties = {
      ...defaultEventProps(context, eventType),
      ...temporaryIdProps,
      workOrderForm,
    };
    this.mergeValue2("record", "properties", properties);
  };

  onSave = () => {
    const { save, onHasChanged, value } = this.props;
    save(value.record, false).then(() => onHasChanged(false));
  };

  render() {
    const {
      context,
      entity,
      saving,
      deleting,
      onCancel,
      onDelete,
      value,
      isNew,
      auditTrailId,
    } = this.props;
    const { formValidation } = this.state;
    const { entities, forms } = context;

    const { workOrderEntity, eventAssetEntity, meterEntity, taskEntity } =
      entity.arguments;

    const permissions =
      entities[workOrderEntity] &&
      entities[eventAssetEntity] &&
      (!meterEntity ||
        (entities[meterEntity] && !taskEntity) ||
        entities[taskEntity]);

    const availableTypes = getAvailableTypes(context);

    if (!permissions || !availableTypes.length) return <PermissionsError />;

    const record = value?.record;
    const { id, type, isDeleted } = record?.properties ?? {};

    const woFormId = record?.properties?.workOrderForm?.properties?.formId;
    const form = woFormId ? forms.find((f) => f.id === woFormId) : undefined;
    const layout = form?.settings;

    return !type && availableTypes.length > 1 ? (
      <TypeSelector context={context} onChange={this.onChangeEventType} />
    ) : (
      <div className="x-padding-20">
        <EventScheduleForm
          context={context}
          entity={entity}
          isNew={isNew}
          auditTrailId={auditTrailId}
          formValidation={formValidation}
          onFormValidationChange={this.onFormValidationChange}
          value={value}
          onChange={this.onChangeSetValue}
        />
        <FormFooter
          canDelete={!!id && !isDeleted}
          onDelete={onDelete}
          isDeleting={deleting}
          canRestore={false}
          onRestore={undefined}
          isRestoring={false}
          canSave={!isDeleted}
          onSave={this.onSave}
          isSaving={saving}
          canCancel={true}
          onCancel={onCancel}
          isValid={isValid(context, entity, record, layout, formValidation)}
          isNew={false}
        />
      </div>
    );
  }
}
