import { Component } from "react";
import { defaultFor } from "common";
import { Record } from "common/types/records";
import { searchApi } from "common/api/search";
import { getAllItems } from "common/record/actions/ui/requisitioning/functions";
import { RecordContentForm } from "common/record/form/form";
import { StandardUiValue, StandardValue as Value } from "common/record/types";
import { applyChangesToRecord } from "common/record/utils";
import { ApiErrorResponse } from "common/types/error";
import { ForeignKey } from "common/types/foreign-key";
import { ApiError } from "common/ui/api-error";
import { LoadingIcon } from "common/widgets/loading-icon";
import { ValueProps } from "common/with-value-for";
import {
  AWAITING_APPROVAL,
  OPEN,
  UNDER_REVIEW,
} from "x/records/edit/behavior-form/requisitioning/consts";
import { CreatePurchaseOrderButton } from "x/records/edit/behavior-form/requisitioning/create-purchase-order-button";
import {
  getApprovalGroupId,
  getPermissionTypes,
  getStatus,
  hasPartSupplierIds,
  loadUserContact,
  setApprovalCostCenter,
  updateDisableEdit,
  updateEntity,
  updateLayout,
  updateValue,
} from "x/records/edit/behavior-form/requisitioning/functions/common";
import { getCostCenterQuery } from "x/records/edit/behavior-form/requisitioning/functions/cost-center-query";
import { RejectButton } from "x/records/edit/behavior-form/requisitioning/reject-button";
import { SubmitButton } from "x/records/edit/behavior-form/requisitioning/submit-button";
import { PropTypes } from "x/records/edit/types";
import { hasPermissionsTo } from "common/functions/roles";
import {
  hasAllRejectedRequisitionItems,
  hasApprovedRequisitionItems,
} from "./functions/requisition-items";
import { PermissionType } from "./types";

interface StateType {
  actionExecuting: boolean;
  error: ApiErrorResponse;
  userContact: ForeignKey;
  permissionTypes: PermissionType[];
}

type Props = PropTypes & ValueProps<Value>;

export class Requisitioning extends Component<Props, StateType> {
  static readonly displayName = "Requisitioning";
  state: StateType = {
    actionExecuting: false,
    error: undefined,
    userContact: undefined,
    permissionTypes: undefined,
  };

  componentDidMount() {
    this.loadUserContactWithPermissions();
  }

  componentDidUpdate() {
    this.loadUserContactWithPermissions();
  }

  loadUserContactWithPermissions = () => {
    const { context, entity, value } = this.props;
    const { permissionTypes, error } = this.state;

    if (!value?.record || permissionTypes || error) return;

    loadUserContact(context, entity)
      .then((userContact) =>
        getPermissionTypes(context, entity, value, userContact?.id).then(
          (permissionTypes: PermissionType[]) => {
            this.setState({
              userContact,
              permissionTypes,
            });
          },
        ),
      )
      .catch(this.onError);
  };

  onChange = (newValue: Value) => {
    const { value, onChange } = this.props;
    const prevApprovalGroupId = getApprovalGroupId(value);
    const newApprovalGroupId = getApprovalGroupId(newValue);

    if (newApprovalGroupId && prevApprovalGroupId !== newApprovalGroupId) {
      this.loadDefaultCostCenter(newValue, newApprovalGroupId);
    } else {
      onChange(newValue);
    }
  };

  loadDefaultCostCenter = (newValue: Value, approvalGroupId: string) => {
    const { context, entity, onChange } = this.props;
    const { entities } = context;

    this.setState({ error: undefined });

    searchApi(context.apiCall)
      .runQuery(getCostCenterQuery(entities, entity, approvalGroupId, true))
      .then((fks: ForeignKey[] = []) => {
        onChange(setApprovalCostCenter(newValue, fks[0]));
      })
      .catch(this.onError);
  };

  onError = (error: ApiErrorResponse) => {
    this.setState({ error });
  };

  onActionExecuting = (isExecuting: boolean) => {
    this.setState({ actionExecuting: isExecuting });
  };

  onActionPerformed = (recordId: string) => {
    const { value, onHasChanged, reload, onChange } = this.props;
    const {
      record = defaultFor<Record>(),
      ui = defaultFor<StandardUiValue>(),
    } = value;
    const recordToSave = applyChangesToRecord(record, ui);

    onChange({
      record: recordToSave,
      ui: {
        ...value.ui,
        detail: { ...value.ui?.detail, form: undefined, isDirty: false },
        related: {
          ...value.ui?.related,
          form: undefined,
          isDirty: false,
        },
      },
    });
    if (onHasChanged) onHasChanged(false);
    reload(false, recordId);
  };

  createActionButton = (isValid: boolean) => {
    const { context, entity, value, save } = this.props;
    const status = getStatus(value);
    if (!status) return undefined;

    const {
      record = defaultFor<Record>(),
      ui = defaultFor<StandardUiValue>(),
    } = value;
    const newRecord = applyChangesToRecord(record, ui);
    const hasPermissionsToSubmit = hasPermissionsTo(
      context.userTypes,
      context.role,
      entity.name,
      ["SubmitRequisition"],
    );
    const requisitionItems = getAllItems(context.entities, entity, newRecord);
    switch (status) {
      case OPEN:
      case UNDER_REVIEW:
        return (
          <SubmitButton
            context={context}
            entity={entity}
            record={newRecord}
            isValid={hasPermissionsToSubmit && isValid}
            save={save}
            onSubmitted={this.onActionPerformed}
            onError={this.onError}
            onActionExecuting={this.onActionExecuting}
          />
        );
      case AWAITING_APPROVAL:
        return hasAllRejectedRequisitionItems(requisitionItems) ? (
          <RejectButton
            context={context}
            entity={entity}
            record={newRecord}
            isValid={true}
            save={save}
            onRejected={this.onActionPerformed}
            onError={this.onError}
            onActionExecuting={this.onActionExecuting}
          />
        ) : (
          <CreatePurchaseOrderButton
            context={context}
            entity={entity}
            record={newRecord}
            isValid={hasApprovedRequisitionItems(requisitionItems)}
            save={save}
            onCreated={this.onActionPerformed}
            onError={this.onError}
            onActionExecuting={this.onActionExecuting}
          />
        );
      default:
        return undefined;
    }
  };

  render() {
    const { context, entity, layout, value, disableEdit, saving, loading } =
      this.props;
    const { actionExecuting, error, permissionTypes, userContact } = this.state;
    const status = getStatus(value);

    return (
      <>
        {loading && <LoadingIcon />}
        {error && <ApiError error={error} />}
        <RecordContentForm
          {...this.props}
          saving={saving && !actionExecuting}
          entity={updateEntity(entity, status, permissionTypes)}
          layout={updateLayout(value, context, entity, layout)}
          disableEdit={updateDisableEdit(disableEdit, status)}
          extraValidation={hasPartSupplierIds}
          createExtraActionButton={this.createActionButton}
          value={updateValue(value, userContact)}
          onChange={this.onChange}
        />
      </>
    );
  }
}
