import { Component } from "react";
import { Entity } from "common/entities/types";
import { FormValidationProps, Layout } from "common/form/types";
import { mergeChain } from "common/merge";
import { getRecordDependencies } from "common/record/dependencies";
import { ScheduledEventValue, StandardValue } from "common/record/types";
import { Context } from "common/types/context";
import { Record } from "common/types/records";
import { DependenciesComp } from "common/with-dependencies";
import { ValueProps } from "common/with-value-for";
import { PmEntityForm } from "./form";
import {
  getEntityWithColumnsMadeOptional,
  getLayoutWithColumnsMadeOptional,
} from "./functions";

interface PropTypes
  extends ValueProps<ScheduledEventValue>,
    FormValidationProps {
  context: Context;
  entity: Entity;
  record: Record;
  woEntity: Entity;
  layout: Layout;
  isNew: boolean;
}

export class WorkOrderTemplate extends Component<PropTypes> {
  static readonly displayName = "WorkOrderTemplate";

  /**
   * Extracts entityForm from the event and make it as our typical record value.
   * Uses PM id as the "record id" to allow saving documents for this template as it was a regular record
   */
  unwrap = (): StandardValue => {
    const { value } = this.props;
    const properties = value?.record?.properties;

    const workOrderProperties = {
      ...properties?.workOrderForm?.properties,
      id: properties?.id ?? properties?.$id,
    };

    return {
      record: {
        ...properties?.workOrderForm,
        properties: workOrderProperties,
        related: value?.record?.related,
      },
      ui: value?.ui?.entityForm,
    };
  };

  /**
   * Wraps the value from the WO template and emits the change in ScheduledEventValue format
   */
  onChange = (newValue: StandardValue) => {
    const { value, onChange } = this.props;
    const { record, ui } = newValue;

    onChange(
      mergeChain(value)
        .prop("ui")
        .set("entityForm", ui)
        .up()
        .prop("record")
        .prop("properties")
        .set("workOrderForm", record)
        .output(),
    );
  };

  render() {
    const {
      context,
      entity,
      woEntity,
      layout,
      isNew,
      record,
      formValidation,
      onFormValidationChange,
    } = this.props;

    const value = this.unwrap();

    const properties = value?.record?.properties;
    const formId = properties?.formId;
    const defaultValue = getRecordDependencies(
      context,
      woEntity,
      isNew,
      properties,
      formId,
      undefined,
      false,
      true,
    );

    const newWoEntity = getEntityWithColumnsMadeOptional(woEntity);
    const newLayout = getLayoutWithColumnsMadeOptional(layout);

    return (
      <DependenciesComp
        dependencies={{ defaultValue }}
        child={
          <PmEntityForm
            context={context}
            entity={entity}
            woEntity={newWoEntity}
            record={record}
            layout={newLayout}
            formValidation={formValidation}
            onFormValidationChange={onFormValidationChange}
            value={value}
            onChange={this.onChange}
          />
        }
      />
    );
  }
}
