import * as React from "react";
import { getLogoUrls, type LogoMode } from "common/functions/branding";

import "./logo.scss";

interface PropTypes {
  logoMode?: LogoMode;
  tenantName?: string;
}

export const Logo: React.FunctionComponent<PropTypes> = ({
  tenantName,
  logoMode,
}) => {
  const { desktopLogo, mobileLogo } = getLogoUrls(tenantName, logoMode);

  return (
    <div className="x-logo">
      <img
        className="x-logo-large x-desktop-logo"
        src={desktopLogo}
        alt="desktop logo"
      />
      <img
        className="x-logo-small x-mobile-logo"
        src={mobileLogo}
        alt="mobile logo"
      />
    </div>
  );
};

Logo.displayName = "Logo";
