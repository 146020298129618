// eslint-disable-next-line import/no-unused-modules
export const NOT_COMPLETED_PROCEDURE_STATUS_ID = 0;
export const COMPLETED_PROCEDURE_STATUS_ID = 1;
export const PASS_PROCEDURE_STATUS_ID = 2;
export const FAIL_PROCEDURE_STATUS_ID = 3;
export const URGENT_REPAIR_PROCEDURE_STATUS_ID = 4;
export const YES_PROCEDURE_STATUS_ID = 5;
export const NO_PROCEDURE_STATUS_ID = 6;
export const NOT_APPLICABLE_PROCEDURE_STATUS_ID = 7;
export const METER_READING_PROVIDED_PROCEDURE_STATUS_ID = 8;

export const TOTAL_METER_TYPE_ID = 1;
export const RUNNING_METER_TYPE_ID = 2;
export const TRENDING_METER_TYPE_ID = 3;

export const ASSIGNED_ASSIGNMENT_STATUS_ID = 1;

export interface SystemStringFk {
  id: string;
  title: string;
}

export type SystemStringFkValue = SystemStringFk | string;
