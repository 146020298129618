import { JSX } from "react";
import {
  ConfirmationType,
  useConfirmation,
} from "common/widgets/warning/use-confirmation";

interface Props<T> {
  children: (confirmation: ConfirmationType<T>) => JSX.Element;
}

export function WithConfirmation<T>({ children }: Props<T>) {
  return children(useConfirmation<T>());
}
