import * as R from "ramda";
import { defaultFor } from "common";
import { searchApi } from "common/api/search";
import { getByBehaviorArgument } from "common/entities";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { filterLayout, mapLayout } from "common/form/functions/layout";
import { Layout } from "common/form/types";
import { shouldDisplayForm } from "common/functions/related-form-display";
import { merge1, merge2 } from "common/merge";
import { QueryForEntity } from "common/query/types";
import { Context } from "common/types/context";
import { Properties, Record } from "common/types/records";
import { RelatedFormDisplayTypes } from "common/types/related-form-display";
import { UnitCostResult } from "common/utils/currency";
import { parseDecimal } from "common/utils/decimal";
import { replaceLookupQueries, updateContextForms } from "../common/functions";
import { getPartialForm } from "../functions";
import {
  createDefaultPartLocationQuery,
  createPartLocationLookupQuery,
} from "../part-location/functions";
import {
  createPartsLookupQueryForSupplier,
  getSelectedLocationIdsForPart,
} from "../part/functions";
import { RelatedValue } from "../types";

const RECEIVED_FIELDS: string[] = ["receivedOn", "receivedQty"];

export const removeReceivedFields = (layout: Layout) =>
  filterLayout((c) => !R.includes(c.columnName, RECEIVED_FIELDS), layout);

export const addPartHint = (layout: Layout) =>
  mapLayout((c) => {
    const hintText = _("Parts are filtered by the selected Supplier");
    return c.columnName === "partId" ? merge1("hint", hintText, c) : c;
  }, layout);

export const replaceZeroValue = (value: any) =>
  parseDecimal(value) === 0 ? "-" : value;

export const setDisplayValue = (
  columnNames: string[] = [],
  c: EntityColumn,
) => {
  return R.includes(c.name, columnNames)
    ? { ...c, displayValue: replaceZeroValue }
    : c;
};

export const addValidation = (columns: EntityColumn[]) =>
  columns.map((c) => (c.name === "orderedQty" ? { ...c, minValue: 1 } : c));

export const updateEntity = (entity: Entity = defaultFor<Entity>()) => {
  const { columns = [] } = entity;
  if (!columns.length) return entity;

  const updatedColumns = columns.map((c) =>
    setDisplayValue(["taxRate", "taxValue"], c),
  );
  return { ...entity, columns: addValidation(updatedColumns) };
};

export const getPurchaseOrderItemRelatedEntities = (
  context: Context,
  entity: Entity = defaultFor<Entity>(),
) => {
  const partEntity = context.entities[entity.arguments.partEntity];
  const partLocationsEntity = context.entities[entity.arguments.stockEntity];
  const purchaseOrderEntity =
    context.entities[entity.arguments.purchaseOrderEntity];

  const partSupplierEntity = getByBehaviorArgument(
    context.entities,
    "PartSupplier",
    "partEntity",
    partEntity?.name,
  );

  return {
    partEntity,
    partSupplierEntity,
    partLocationsEntity,
    purchaseOrderEntity,
  };
};

const getLocationHint = (context: Context, entity: Entity) => {
  const { partLocationsEntity, purchaseOrderEntity } =
    getPurchaseOrderItemRelatedEntities(context, entity);

  return _(
    "Please add a {STOCK_ENTITY} record to be able to add this part to a {PO_ENTITY}",
  )
    .replace("{STOCK_ENTITY}", partLocationsEntity.localizedName)
    .replace("{PO_ENTITY}", purchaseOrderEntity.localizedName);
};

export const addLocationHint = (
  context: Context,
  entity: Entity,
  value: RelatedValue,
) => {
  const partialForm = getPartialForm(value, entity.name);
  const partId = partialForm?.partId?.id;
  const locationId = partialForm?.partLocationId;
  const hasSelectedLocationsForPart =
    getSelectedLocationIdsForPart(entity.name, value, partId)?.length > 0;

  const hintRequired = partId && !locationId && !hasSelectedLocationsForPart;
  return (layout: Layout) =>
    mapLayout(
      (c) =>
        hintRequired && c.columnName === "partLocationId"
          ? { ...c, hint: getLocationHint(context, entity) }
          : c,
      layout,
    );
};

export const updatePOIContext = (
  context: Context,
  entity: Entity = defaultFor<Entity>(),
  value: RelatedValue,
) => {
  const { entities } = context;
  const updatedContext = merge2(
    "entities",
    entity.name,
    updateEntity(entities[entity.name]),
    context,
  );

  const supplierId = value?.record?.properties?.supplierId?.id;
  const partIdLookupQuery = createPartsLookupQueryForSupplier(
    updatedContext,
    entity,
    supplierId,
  );

  const partLocationLookupQuery = createPartLocationLookupQuery(
    updatedContext,
    entity,
    value,
  );

  const updateSettings = R.pipe(
    removeReceivedFields,
    addPartHint,
    addLocationHint(context, entity, value),
    replaceLookupQueries("partId", partIdLookupQuery),
    replaceLookupQueries("partLocationId", partLocationLookupQuery),
  );

  return updateContextForms(updatedContext, entity.name, (f) => ({
    ...f,
    settings: updateSettings(f.settings),
  }));
};

export const createUnitCostQuery = (
  context: Context,
  entity: Entity = defaultFor<Entity>(),
  value: RelatedValue,
  partId: string,
): QueryForEntity => {
  const supplierId: string = value?.record?.properties?.supplierId?.id;
  const { partSupplierEntity } = getPurchaseOrderItemRelatedEntities(
    context,
    entity,
  );

  return {
    entity: partSupplierEntity.name,
    query: {
      select: [{ name: "currency" }, { name: "unitCost" }],
      joins: [{ column: "partId" }, { column: "supplierId" }],
      filter: {
        and: [
          {
            name: "id",
            value: partId,
            op: "contains",
            path: "/partId",
          },
          {
            name: "isDeleted",
            op: "isfalse",
            path: "/partId",
          },
          {
            name: "id",
            value: supplierId,
            op: "contains",
            path: "/supplierId",
          },
          {
            name: "isDeleted",
            op: "isfalse",
            path: "/supplierId",
          },
        ],
      },
    },
  };
};

export const searchUnitCost = (
  context: Context,
  entity: Entity = defaultFor<Entity>(),
  value: RelatedValue,
  partId: string,
) => {
  const query = createUnitCostQuery(context, entity, value, partId);
  return searchApi(context.apiCall)
    .runQuery(query)
    .then((rs: UnitCostResult[] = []) => (rs.length ? rs[0] : undefined));
};

export const searchDefaultLocation = (
  context: Context,
  entity: Entity,
  value: RelatedValue,
  partId: string,
) =>
  searchApi(context.apiCall)
    .runQuery(createDefaultPartLocationQuery(context, entity, value, partId))
    .then((rs: Properties[] = []) => (rs.length ? rs[0] : undefined));

export const updateDisplayTypes = (
  value: RelatedValue,
  displayTypes: RelatedFormDisplayTypes,
): RelatedFormDisplayTypes => {
  const props = value?.record?.properties;
  const poStatusId = props?.statusId?.id;
  const supplierId = props?.supplierId;
  // StatusId 1 === "Open"
  return shouldDisplayForm(displayTypes) && (poStatusId !== 1 || !supplierId)
    ? ["table", "tab"]
    : displayTypes;
};

export const getPOIIgnoredColumns = (relatedRecords: Record[]): string[] => {
  const defaultIgnoredColumns = ["currency", "taxRateId"];
  const haveNoneTaxApplied = relatedRecords.every((r) =>
    R.isNil(r.properties?.taxRateId),
  );

  return haveNoneTaxApplied
    ? [...defaultIgnoredColumns, "taxRate", "taxValue", "total"]
    : defaultIgnoredColumns;
};
