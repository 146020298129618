import * as R from "ramda";
import {
  ChartOptions,
  ChartData,
  ChartTooltipItem,
  ChartDataSets,
} from "chart.js";
import { MeasurementsResponse } from "common/record/form/content/measurements/types";
import { formatMeasurementValue } from "common/record/form/content/measurements/functions/value";
import { HexColorString, FLUKE_YELLOW, DARK_GREY } from "common/types/colors";

export const getChartDataset = (
  data: number[],
  activeIndex: number = data?.length - 1,
  label: string = _("Dataset"),
  unit: string = undefined,
  color: HexColorString = DARK_GREY,
  hidden: boolean = false,
): ChartDataSets => {
  const pointRadius =
    data && !R.isEmpty(data)
      ? R.update(activeIndex, 6, R.repeat(4, data.length))
      : [];
  const pointBackgroundColor =
    data && !R.isEmpty(data)
      ? R.update(activeIndex, FLUKE_YELLOW, R.repeat(undefined, data.length))
      : [];

  return {
    label,
    yAxisID: unit,
    data: data || [],
    fill: false,

    backgroundColor: color,
    borderWidth: 1,
    borderColor: color,
    hidden,
    lineTension: 0.1,
    spanGaps: true,

    pointBackgroundColor,
    pointRadius,
    pointHoverRadius: 6,
  };
};

export const displayLabel = (
  tooltipItem: ChartTooltipItem,
  data: ChartData,
) => {
  const dataset = data?.datasets?.[tooltipItem?.datasetIndex];
  const unit = dataset?.yAxisID;
  const value = +tooltipItem?.value;
  const label = dataset?.label ?? _("Point");

  return `${label}: ${formatMeasurementValue(value, unit)}`;
};

export const getChartOptions = (
  measurementsResponse: MeasurementsResponse,
  label: string,
): ChartOptions => {
  const measurements = measurementsResponse?.measurements?.ms;
  const unit = measurements?.[0]?.u;

  return {
    scales: {
      yAxes: [
        {
          id: unit,
          scaleLabel: {
            display: true,
            labelString: label,
          },
          ticks: {
            callback: (v) => formatMeasurementValue(v, unit),
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: displayLabel,
      },
    },
    maintainAspectRatio: false,
  };
};
