import { getUtcNow } from "common/date-time/common";
import { UTCDateTime } from "common/date-time/types";
import { ForeignKey } from "common/types/foreign-key";
import { Reason } from "common/types/reasons";
import { Record } from "common/types/records";

export type EventType = "P" | "I" | "C" | "E";
export const defaultCron = "0 9 1 * *";

export interface ScheduledEventBase {
  type: EventType;
  description: string;
  enabled: boolean;
  workOrderForm: Record;
  taskId: ForeignKey;
  calendarIds: number[];
  suppressionDays: number;
  overriddenBy: ForeignKey[];
  populateChildrenAssets: boolean;
  copyCustomFieldsToWo: boolean;
  site?: string;
  sites?: string[];
  id?: string;
  $id?: string;
  number?: number;
  formId?: number;
  hasProjections?: boolean;
  projectionLength?: number;
  projectionInterval?: FrequencyInterval;
  isDeleted?: boolean;

  [customField: string]: unknown;
}

export type ScheduledEvent = Pm | Inspection | External;
export type ScheduledEventRecord = PmRecord | InspectionRecord | ExternalRecord;

export interface MeterState {
  assetMeterId: MeterForeignKey;
  lastTriggered: number;
  threshold: number;
}

interface EventAsset {
  id?: string;
  assetId: ForeignKey;
  meters: MeterState[];
  lastOpenDate: string;
  lastCloseDate: string;
  isDeleted: boolean;
}

export interface ScheduledEventAssetRecord extends Record {
  properties: EventAsset;
}

export type FrequencyInterval = "d" | "w" | "m";

export interface PmFrequency {
  value: number;
  interval: FrequencyInterval;
  shadow: boolean;
}

export interface MeterForeignKey extends ForeignKey {
  unit: string;
  assetId: string;
  isDeleted?: boolean;
}

export interface PmTrigger {
  frequency: PmFrequency; // Optional
}

export interface Pm extends ScheduledEventBase {
  type: "P";
  trigger: PmTrigger;
}

export const defaultPm: Pm = {
  description: undefined,
  enabled: true,
  workOrderForm: undefined,
  populateChildrenAssets: true,
  copyCustomFieldsToWo: false,
  taskId: undefined,
  overriddenBy: [],
  calendarIds: [],
  suppressionDays: 1,
  type: "P",
  trigger: {
    frequency: undefined,
  },
};

export interface IntervalOption {
  value: FrequencyInterval;
  label: string;
}

export interface PmRecord extends Record {
  properties: Pm;
}

export interface InspectionTrigger {
  crons: string[];
  startDate: UTCDateTime;
}

export interface Inspection extends ScheduledEventBase {
  type: "I";
  trigger: InspectionTrigger;
}

export interface InspectionRecord extends Record {
  properties: Inspection;
}

export const getDefaultInspectionProps = (): Inspection => ({
  description: undefined,
  enabled: true,
  workOrderForm: undefined,
  taskId: undefined,
  populateChildrenAssets: true,
  copyCustomFieldsToWo: false,
  overriddenBy: [],
  calendarIds: [],
  suppressionDays: 1,
  type: "I",
  trigger: {
    crons: [defaultCron],
    startDate: getUtcNow(),
  },
});

export interface External extends ScheduledEventBase {
  type: "E";
  trigger: unknown;
}

export interface ExternalRecord extends Record {
  properties: External;
}

export const defaultExternal: External = {
  description: undefined,
  workOrderForm: undefined,
  enabled: true,
  taskId: undefined,
  overriddenBy: [],
  calendarIds: [],
  populateChildrenAssets: true,
  copyCustomFieldsToWo: false,
  suppressionDays: 0,
  type: "E",
  trigger: {},
};

export interface Projection {
  assetId: string;
  date: string;
  type: string;
  reason: Reason;
}

export interface ProjectionFrequencyProps {
  projectionLength: number;
  projectionInterval: FrequencyInterval;
}

export const DEFAULT_PROJECTION_FREQUENCY: ProjectionFrequencyProps = {
  projectionLength: 30,
  projectionInterval: "d",
};
