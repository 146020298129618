import { classNames } from "common/utils/jsx";

interface PropTypes {
  value: boolean;
  onChange: () => void;
  isDisabled?: boolean;
  className?: string;
  hasSomeChildSelected?: boolean;
  label?: string;
}

export const TriStateCheckbox = ({
  isDisabled = false,
  hasSomeChildSelected = false,
  className = "",
  label,
  value,
  onChange,
}: PropTypes) => {
  const handleToggle = () => {
    if (!isDisabled) onChange();
  };

  const checkboxIconClass = classNames([
    "x-tri-state-checkbox",
    hasSomeChildSelected
      ? "fa fa-minus-square-o"
      : value
        ? "fa fa-check-square-o"
        : "fa fa-square-o",
  ]);

  const checkboxClass = classNames([
    "x-tri-state-checkbox",
    className,
    isDisabled ? "x-disabled" : undefined,
  ]);
  const ariaChecked = hasSomeChildSelected ? "mixed" : value ? "true" : "false";

  return (
    <div
      data-testid="tri-state-checkbox"
      role="checkbox"
      aria-checked={ariaChecked}
      aria-label={label}
      onClick={handleToggle}
      className={checkboxClass}
    >
      <i className={checkboxIconClass} />
    </div>
  );
};
