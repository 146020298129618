import debounce from "lodash-es/debounce";
import { createRef, Component, JSX } from "react";
import { Cancelable } from "common/types/debounce";

export interface Sizes {
  loading: boolean;
  height: number;
  width: number;
}

interface PropTypes {
  className?: string;
  render: (s: Sizes) => JSX.Element | JSX.Element[];
}

interface StateType extends Sizes {
  debounceResize: (() => void) & Cancelable;
}

export class Sizer extends Component<PropTypes, StateType> {
  static readonly displayName = "Sizer";
  sizeRef = createRef<HTMLDivElement>();
  resizeObserver = new ResizeObserver(() => {
    this.resize();
  });

  constructor(props: PropTypes, ctx: any) {
    super(props, ctx);
    this.state = {
      loading: true,
      width: undefined,
      height: undefined,
      debounceResize: debounce(this.setSizes, 150),
    };
  }

  componentDidMount() {
    this.setSizes();
    this.resizeObserver.observe(this.sizeRef.current);
    window.addEventListener("resize", this.resize);
  }

  resize = () => {
    const { debounceResize } = this.state;
    if (debounceResize) debounceResize();
  };

  componentWillUnmount() {
    const { debounceResize } = this.state;
    if (debounceResize) debounceResize.cancel();
    this.resizeObserver.unobserve(this.sizeRef.current);
    window.removeEventListener("resize", this.resize);
  }

  setSizes = () => {
    this.setState({
      loading: false,
      width: this.sizeRef.current.offsetWidth,
      height: this.sizeRef.current.offsetHeight,
    });
  };

  render() {
    const { render, className } = this.props;

    return (
      <div className={`x-sizer ${className ?? ""}`} ref={this.sizeRef}>
        {render(this.state)}
      </div>
    );
  }
}
