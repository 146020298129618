import { TimeRangeName } from "common/record/form/content/measurements/time-range/types";

export const timeRanges: TimeRangeName[] = [
  "ONE_HOUR",
  "TWELVE_HOUR",
  "ONE_DAY",
  "ONE_WEEK",
  "ONE_MONTH",
  "ALL",
];
