import { Entity } from "common/entities/types";
import { Group, Layout, StaticEntries } from "common/form/types";

const staticEntriesToOmit = ["signature", "comments"];

export const getEntityWithColumnsMadeOptional = (woEntity: Entity): Entity => {
  if (!woEntity) return undefined;

  const newColumns = (woEntity.columns || []).map((c) =>
    c.required ? { ...c, required: false } : c,
  );
  return { ...woEntity, columns: newColumns };
};

export const getGroupWithColumnsMadeOptional = (group: Group): Group => {
  if (!group) return undefined;

  const newColumns = (group.columns || []).map((c) =>
    c.required ? { ...c, required: false } : c,
  );
  return { ...group, columns: newColumns };
};

export const getLayoutWithColumnsMadeOptional = (layout: Layout): Layout => {
  if (!layout) return undefined;

  const newGroups = (layout.groups || []).map(getGroupWithColumnsMadeOptional);
  return { ...layout, groups: newGroups };
};

export const getWoTemplateStaticEntries = (
  staticEntries: StaticEntries,
): StaticEntries =>
  staticEntries?.filter((s) => !staticEntriesToOmit.includes(s.toLowerCase()));
