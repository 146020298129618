import * as React from "react";
import { Component } from "common/component";
import {
  ActionButton,
  ActionButtonSmall,
  DeleteButton,
  DeleteButtonSmall,
  ModifyButton,
  ModifyButtonSmall,
} from "common/ui/buttons";
import { arrayToString } from "common/utils/array";
import { OnDisplay } from "./types";

export interface PropTypes<TItem> {
  item: TItem;
  index: number;
  onDisplay: OnDisplay<TItem>;
  getName?: (item: TItem) => string;
  canDelete?: (item: TItem) => boolean;
  dragTest?: (item: TItem) => boolean;
  allowReorder?: boolean;
  hoverBefore?: boolean;
  hoverAfter?: boolean;
  small?: boolean;
  hideOverflow?: boolean;
  hideArrows?: boolean;
  isLast: boolean;
  onDelete: (index: number) => void;
  onMove: (fromIndex: number, toIndex: number) => void;
  onUpdate: (item: TItem, index: number) => void;
  onEdit?: (item: TItem) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  refresh?: any;
  getItemStyle?: (item: TItem) => React.CSSProperties;
  getItemClass?: (item: TItem) => string;
}

export class Item<TItem> extends Component<PropTypes<TItem>> {
  static readonly displayName = "Item";

  onMoveUp = () => {
    const { index, onMove } = this.props;
    if (index > 0) onMove(index, index - 1);
  };

  onMoveDown = () => {
    const { index, onMove, isLast } = this.props;
    if (!isLast) onMove(index, index + 1);
  };

  onDelete = () => {
    const { index, onDelete } = this.props;
    onDelete(index);
  };

  onEdit = () => {
    const { onEdit, item } = this.props;
    onEdit(item);
  };

  onUpdate = (item: TItem) => {
    const { index, onUpdate } = this.props;
    onUpdate(item, index);
  };

  render() {
    const {
      onDisplay,
      canDelete,
      dragTest,
      allowReorder,
      index,
      item,
      hoverBefore,
      hoverAfter,
      isLast,
      getName,
      small,
      onEdit,
      hideOverflow,
      hideArrows,
      getItemStyle,
      getItemClass,
    } = this.props;

    const displayItem = onDisplay(item, this.onUpdate, index);
    if (!displayItem) return null;

    const itemName = (item && getName && getName(item)) || "item";
    const classNames = arrayToString([
      "x-list-item",
      hoverBefore ? "x-hover-before" : undefined,
      hoverAfter ? "x-hover-after" : undefined,
      getItemClass?.(item),
      `qa-list-widget-${itemName}`,
    ]);

    const canReorder = allowReorder && (dragTest ? dragTest(item) : true);

    const ActionButtonComp = small ? ActionButtonSmall : ActionButton;
    const DeleteButtonComp = small ? DeleteButtonSmall : DeleteButton;
    const ModifyButtonComp = small ? ModifyButtonSmall : ModifyButton;

    const style = item && getItemStyle ? getItemStyle(item) : undefined;

    return (
      <div className={classNames} style={style}>
        {canReorder ? (
          <div className="x-list-handle qa-list-handle">
            <i className="fa fa-sort" />
          </div>
        ) : undefined}
        <div
          className={
            "x-list-item-content" + (hideOverflow ? " x-hide-overflow " : "")
          }
        >
          {displayItem}
        </div>
        {canReorder && !hideArrows
          ? [
              <ActionButtonComp
                key="moveUp"
                className="x-move-up-btn x-margin-left-10 qa-list-item-move-up"
                onClick={this.onMoveUp}
                disabled={index === 0}
                title={_("Move up")}
              >
                <i className="fa fa-arrow-up" />
              </ActionButtonComp>,
              <ActionButtonComp
                key="moveDown"
                className="x-move-down-btn x-margin-left-10 qa-list-item-move-down"
                onClick={this.onMoveDown}
                disabled={isLast}
                title={_("Move down")}
              >
                <i className="fa fa-arrow-down" />
              </ActionButtonComp>,
            ]
          : undefined}
        {onEdit ? (
          <ModifyButtonComp
            className="x-margin-left-10 qa-list-item-edit"
            onClick={this.onEdit}
            disabled={false}
            title={_("Edit")}
          >
            <i className="fa fa-cog" />
          </ModifyButtonComp>
        ) : undefined}
        {canDelete?.(item) ? (
          <DeleteButtonComp
            className="x-delete-btn x-margin-left-10 qa-list-item-delete"
            onClick={this.onDelete}
            title={_("Remove")}
          >
            <i className="fa fa-close" />
          </DeleteButtonComp>
        ) : undefined}
      </div>
    );
  }
}
