import { Properties } from "common/types/records";
import { Entity } from "common/entities/types";
import { MappedField } from "common/form/types";
import { QueryForEntity } from "common/query/types";

/**
 * Returns a query to find the extra fields in the foreign record required for the user-defined lookup.
 */
export const getFkUserDefinedFieldsQuery = (
  targetEntity: Entity,
  mappedFields: MappedField[],
  id: string,
): QueryForEntity => {
  return {
    entity: targetEntity.name,
    query: {
      ...targetEntity.query,
      select: mappedFields.map((field) => ({ name: field.targetColumnName })),
      filter: {
        and: [
          {
            name: "id",
            op: "eq",
            value: id,
          },
          { name: "isDeleted", op: "isfalse" },
        ],
      },
    },
  };
};

export const getUserDefinedValues = (
  fkRecord: Properties,
  mappedFields: MappedField[],
) =>
  mappedFields.reduce<Properties>(
    (acc, field) => ({
      ...acc,
      [field.columnName]: fkRecord[field.targetColumnName],
    }),
    {},
  );
