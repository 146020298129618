import { Component, ComponentType } from "react";
import { Location } from "history";
import { KeepWorkingOrDiscard } from "common/widgets/warning/keep-working-or-discard";
import { goTo } from "common/utils/go-to";
import { RouteProps } from "./router";

export interface WithDirty {
  isDirty: boolean;
  setDirty: (dirty: boolean) => void;
}

interface StateType {
  isDirty?: boolean;
  nextUrl?: string;
}

// The props that get passed to withDirty(YourComponent)
export type DirtyInput<PropTypes> = PropTypes & RouteProps;

// The props that YourComponent expects
type DirtyOutput<PropTypes> = PropTypes & WithDirty;

export function withDirty<PropTypes>(
  YourComponent: ComponentType<DirtyOutput<PropTypes>>,
) {
  return class extends Component<DirtyInput<PropTypes>, StateType> {
    static readonly displayName = "DirtyController";
    state: StateType = {
      isDirty: false,
    };

    componentDidMount() {
      this.props.history.block(this.routerWillLeave);
    }

    routerWillLeave = (nextLocation: Location): false | void => {
      const { isDirty, nextUrl } = this.state;

      const newUrl = nextLocation.search
        ? nextLocation.pathname + nextLocation.search
        : nextLocation.pathname;

      if (isDirty && nextUrl !== newUrl) {
        this.setState({ nextUrl: newUrl });
        return false;
      }
    };

    setDirty = (isDirty: boolean) => {
      this.setState({ isDirty, nextUrl: undefined });
    };

    onDiscard = () => {
      const { history } = this.props;
      const { nextUrl } = this.state;

      this.setState({ isDirty: false, nextUrl: undefined });

      goTo(history)(nextUrl);
    };

    onKeepWorking = () => {
      this.setState({ nextUrl: undefined });
    };

    render() {
      const { nextUrl, isDirty } = this.state;

      return (
        <div className="x-with-dirty">
          <YourComponent
            {...this.props}
            isDirty={isDirty}
            setDirty={this.setDirty}
          />
          {nextUrl ? (
            <KeepWorkingOrDiscard
              onKeepWorking={this.onKeepWorking}
              onDiscard={this.onDiscard}
            />
          ) : undefined}
        </div>
      );
    }
  };
}
