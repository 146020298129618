import { useEffect, useState } from "react";
import { VerticalField } from "common/ui/field";
import { Selector } from "common/widgets/selector";

export type DocumentsScope = "all" | "owned" | "related";

const options: DocumentsScope[] = ["all", "owned", "related"];

interface PropTypes {
  loadDocuments: (scope?: DocumentsScope) => void;
}

const getOptionLabel = (option: DocumentsScope) => {
  switch (option) {
    case "owned":
      return _("Documents for this record");
    case "related":
      return _("Documents for related entities");
    case "all":
    default:
      return _("All Documents");
  }
};

export const DocumentsScopeSelector = ({ loadDocuments }: PropTypes) => {
  const [value, setValue] = useState<DocumentsScope>("all");
  useEffect(() => {
    loadDocuments(value);
  }, [value, loadDocuments]);

  return (
    <VerticalField
      className="x-select-view"
      label={_("Select View")}
      input={
        <Selector<DocumentsScope>
          getOptionLabel={getOptionLabel}
          options={options}
          value={value}
          onChange={setValue}
        />
      }
    />
  );
};
