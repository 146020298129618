import { defaultFor } from "common";
import { Properties } from "common/types/records";
import {
  getUiFormProperties,
  propertiesToEventProperties,
} from "common/record/form/content/related/event/functions";
import { CheckAvailability } from "common/record/form/content/related/event/check-availability";
import {
  RelatedValue,
  CustomConfig,
  CustomConfigSettings,
} from "common/record/form/content/related/types";
import { behaveAs, getColumn } from "common/entities";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";

const getRelatedCalendarDetails = (
  context: Context,
  relatedEntity: Entity,
  formProperties: Properties,
) => {
  const { ownerFkColumn } = relatedEntity;

  const ownerEntityName = getColumn(relatedEntity, ownerFkColumn).relatedEntity;
  const ownerEntity = context.entities[ownerEntityName];
  if (!behaveAs("RelatedCalendar", ownerEntity)) return undefined;

  const columnName = ownerEntity.arguments.columnName;
  const assetFk = formProperties?.[columnName];
  return { [columnName]: assetFk?.id };
};

export const eventRef: CustomConfig<RelatedValue> = ({
  context,
  relatedEntity,
  isTemplate,
  value: { record, related },
  recordDetail,
}): CustomConfigSettings<RelatedValue> => {
  const { ownerFkColumn } = relatedEntity;
  const defaultForm: Properties = ownerFkColumn
    ? { [ownerFkColumn]: record.properties.id }
    : undefined;

  const relatedEntityName = relatedEntity.name.toLowerCase();
  return {
    defaultForm,
    layoutMapper: undefined,
    custom: (properties = defaultFor<Properties>()) =>
      isTemplate ? undefined : (
        <CheckAvailability
          context={context}
          entity={relatedEntity}
          eventProps={propertiesToEventProperties(properties)}
          uiFormProperties={getUiFormProperties(related, relatedEntityName)}
          relatedCalendarArgValue={getRelatedCalendarDetails(
            context,
            relatedEntity,
            recordDetail?.form,
          )}
        />
      ),
    customOnChange: undefined,
  };
};
