import * as R from "ramda";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { ValueComponent } from "common/with-value-for";
import { Entity } from "common/entities/types";
import { defaultFor } from "common";
import { Record } from "common/types/records";
import { Selector } from "common/widgets/selector";
import { mapRecordToFk } from "../functions";

interface PropTypes {
  context: Context;
  entity: Entity;
  assetMeters: Record[];
  disabled?: boolean;
}

const defaultValue = defaultFor<ForeignKey>();

const displayMeter = (am: Record) => am.properties.description;

export class AssetMeter extends ValueComponent<ForeignKey, PropTypes> {
  static readonly displayName = "AssetMeter";

  onChange = (record: Record) => {
    const { context, entity, assetMeters } = this.props;
    const assetMeterId = record.properties.id;

    if (!assetMeterId) return this.setValue(undefined);

    const assetMeter = context.entities[entity.arguments.assetMeterEntity];
    const am = R.find((am) => am.properties.id === assetMeterId, assetMeters);

    this.setValue(mapRecordToFk(assetMeter, am));
  };

  render() {
    const { assetMeters, disabled, value = defaultValue } = this.props;

    const selectedMeter = value?.id
      ? assetMeters?.find((option) => option.properties.id === value.id)
      : undefined;

    return (
      <Selector<Record>
        getOptionLabel={displayMeter}
        options={assetMeters}
        disabled={disabled}
        value={selectedMeter}
        onChange={this.onChange}
      />
    );
  }
}
