import { returnAndNotify } from "common/api/with-notifications";
import { ApiCall } from "common/types/api";
import { ESignatureSettings } from "common/types/esignature-settings";
import { CancellablePromise } from "common/types/promises";

export const eSignatureSettingsApi = (apiCall: ApiCall) => ({
  get: (): CancellablePromise<ESignatureSettings> =>
    apiCall("get", "/api/admin/settings/e-signature"),
  update: (settings: ESignatureSettings) =>
    apiCall("put", "/api/admin/settings/e-signature", settings).then(
      returnAndNotify(_("The e-signature settings were successfully updated")),
    ),
});

export const eSignatureUiSettingsApi = (apiCall: ApiCall) => ({
  get: (): CancellablePromise<ESignatureSettings> =>
    apiCall("get", "/api/ui/settings/e-signature"),
});
