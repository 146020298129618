import * as R from "ramda";
import { getActionTitle } from "common/record/actions/functions/title-and-icon";
import { Properties, Record } from "common/types/records";
import { IsRecordDisabledFn, MapWidgetPropsFn } from "common/form/types";
import { defaultFor } from "common/index";
import { ColumnDefinition } from "common/query/advanced-types";
import {
  Action,
  ActionWithContent,
  RowValue,
  TableConfig,
  TableValue,
} from "common/query/table/types";
import { GoFn } from "common/types/url";
import { ValueProps } from "common/with-value-for";
import { hasActionsCheck, rowIsSelected } from "common/query/table/functions";
// eslint-disable-next-line import/no-cycle
import { TableRow } from "common/query/table/row";
import { Context } from "common/types/context";

type Props = {
  context: Context;
  config: TableConfig;
  withLinks: boolean;
  reload: () => any;
  starred: string[];
  toggleStar: (id: string, starred: boolean) => void;
  goTo: GoFn;
  highlighted?: string[];
  readOnly?: boolean;
  widgetsMapper?: MapWidgetPropsFn;
  isRecordDisabled?: IsRecordDisabledFn;
  columnDefinitions: ColumnDefinition[];
  ignoredColumnDefinitions?: ColumnDefinition[];
} & ValueProps<TableValue>;

export const Body = (props: Props) => {
  const {
    config = defaultFor(),
    reload,
    context,
    toggleStar,
    starred = [],
    withLinks,
    readOnly,
    columnDefinitions,
    ignoredColumnDefinitions,
    goTo,
    highlighted = [],
    widgetsMapper,
    isRecordDisabled,
    value = defaultFor(),
    onChange,
  } = props;
  const { allowSelect, allowStar, allowActions, allowMultipleSelect } = config;
  const { data = [], secondaryQueries, actionsByRecordId = {} } = value;

  const expandedRecordId = value?.expandedAction?.recordId;

  const hasCheckActions = hasActionsCheck(config);
  const hasActions =
    allowActions || allowStar || (hasCheckActions && !allowMultipleSelect);

  const contextCache = R.fromPairs(
    R.uniq(data.map((r) => r.site))
      .map((siteName: string) =>
        siteName && context.site.name !== siteName
          ? context.createContextForSiteWithFallback(siteName)
          : context,
      )
      .map((ctx: Context) => [ctx.site.name, ctx] as [string, Context]),
  );

  const toggleExpandAction = (action: ActionWithContent): Action => ({
    ...action,
    fn: (record: Properties) => {
      onChange({
        ...value,
        expandedAction: { actionName: action.name, recordId: record.id },
      });
      action?.onActionRun?.(record);
    },
  });

  const getActions = (config: TableConfig, properties: Properties) => {
    const actions = [
      ...(config.actions ?? []),
      ...(config.actionsWithContent?.map(toggleExpandAction) ?? []),
    ]
      .filter((action) => !action.isHidden?.(properties))
      .map((action) => ({
        ...action,
        isDisabled: action?.isDisabled?.(properties) || false,
        label: getActionTitle(action.name),
      }));
    return actions;
  };

  const getRecord = (properties: Properties): Record =>
    properties
      ? {
          properties,
          actions: actionsByRecordId[properties.id] || [],
        }
      : undefined;

  const onRowChange = (row: RowValue) => {
    const { isSelected, record } = row;
    const { properties = {} } = record;
    const { id: recordId } = properties;
    const { data = [], selected } = value;

    const newData = data.map((r) => {
      return r.id === recordId ? properties : r;
    });

    const newSelected =
      isSelected === rowIsSelected(properties, value)
        ? selected
        : isSelected
          ? (selected || []).concat([properties])
          : (selected || []).filter((v) => v.id !== recordId);

    onChange({ ...value, data: newData, selected: newSelected });
  };

  return (
    <>
      {data.map((properties, index) => {
        const isSelected = rowIsSelected(properties, value);

        // you could be in a group site and links would be broken.
        // this manipulates context.site to be the record site.
        const ctx = properties?.site ? contextCache[properties.site] : context;
        const id = properties?.id || properties?.tempId;
        const record = getRecord(properties);
        return (
          <TableRow
            key={index}
            columnDefinitions={columnDefinitions}
            ignoredColumnDefinitions={ignoredColumnDefinitions}
            context={ctx}
            hasActions={hasActions}
            allowStar={allowStar}
            allowSelect={allowMultipleSelect || allowSelect}
            allowActions={allowActions}
            allowImage={true}
            actions={getActions(config, properties)}
            entity={value?.query?.entity}
            reload={reload}
            starred={R.includes(id, starred)}
            isHighlighted={R.includes(id, highlighted)}
            isEditing={expandedRecordId === id}
            toggleStar={toggleStar}
            withLinks={withLinks}
            readOnly={readOnly}
            secondaryQueries={secondaryQueries}
            goTo={goTo}
            widgetsMapper={widgetsMapper}
            isRecordDisabled={isRecordDisabled?.(record)}
            value={{ isSelected, record, index }}
            onChange={onRowChange}
          />
        );
      })}
    </>
  );
};
