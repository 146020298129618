import { defaultFor } from "common";
import { Record } from "common/types/records";
import { Entity } from "common/entities/types";
import { addFilterToQuery } from "common/query/filter";
import { Filter, QueryForEntity } from "common/query/types";
import { RelatedUiValue } from "common/record/types";
import { getRelatedRecords } from "common/record/utils";

/**
 * It creates query obj for filtering already selected assets
 *
 * @param {Entity} assetsEntity
 * @param {Entity} relatedEntity
 * @param {Record} record
 * @param {RelatedUiValue} related
 *
 * @returns {QueryForEntity}
 */
export const createUniqueAssetsQuery = (
  assetsEntity: Entity = defaultFor<Entity>(),
  relatedEntity: Entity,
  record: Record,
  related: RelatedUiValue,
): QueryForEntity => {
  const selectedAssetIds =
    getRelatedRecords(relatedEntity?.name, record, related?.form)
      ?.filter((r) => !r?.properties?.isDeleted && r?.properties?.assetId?.id)
      .map((r) => r?.properties?.assetId?.id) || [];

  if (!assetsEntity || !selectedAssetIds?.length) return undefined;

  const assetFilter: Filter = {
    name: "id",
    op: "notin",
    value: selectedAssetIds?.join(","),
  };

  return {
    entity: assetsEntity.name,
    query: addFilterToQuery(assetFilter, assetsEntity?.query),
  };
};
