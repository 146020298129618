import { defaultFor } from "common";
import { isForm } from "common/api/forms";
import { merge3 } from "common/merge";
import { getFormWithResolvedDefaults } from "common/form/defaults";
import { applyFormToRecord } from "common/record/form/functions";
import { generalInfo } from "common/record/sidebar";
import { FormValue, StandardValue } from "common/record/types";
import { Record } from "common/types/records";
import { ValueComponent } from "common/with-value-for";
import { Crumb } from "x/layout/ribbon/breadcrumb";
import { getRecord } from "x/records/edit/functions";
import { PropTypes as FormPropTypes } from "x/records/edit/types";
import { EditRibbon } from "x/records/ribbon";
import { RecordForm } from "./record-form";

export interface PropTypes extends FormPropTypes {
  hidePanels: boolean;
  starred: boolean;
  toggleStar: () => void;
  crumbs?: Crumb[];
}

const defaultValue = defaultFor<FormValue>();
const defaultRecord = defaultFor<Record>();

export class FormWithRibbon extends ValueComponent<FormValue, PropTypes> {
  static readonly displayName = "FormWithRibbon";

  reload = (newForm?: any) => {
    if (!newForm || !isForm(newForm)) return this.props.reload();

    const { context, entity, value = defaultValue } = this.props;
    const { ui, record = defaultRecord } = value as StandardValue;
    const { detail, related, other } = ui;
    const newProperties = applyFormToRecord(newForm, record).properties;

    const newValue: StandardValue = {
      record,
      ui: {
        related,
        detail: { ...detail, form: newProperties },
        sidebar: generalInfo,
        other: other,
      },
    };

    getFormWithResolvedDefaults(
      context,
      entity,
      newForm,
      newValue,
      newProperties,
    ).then((formValue: StandardValue) => {
      const valueWithDefaults = formValue?.ui?.detail?.form
        ? merge3("ui", "detail", "form", formValue?.ui?.detail?.form, newValue)
        : newValue;
      this.mergeValue(valueWithDefaults);
    });
  };

  render() {
    const {
      context,
      goTo,
      entity,
      starred,
      toggleStar,
      loading,
      hidePanels,
      runQuery,
      withLinks,
      reload,
      eventId,
      auditTrailId,
      saving,
      deleting,
      deleteLabels,
      layout,
      isNew,
      disableEdit,
      crumbs,
      save,
      onDelete,
      onCancel,
      onHasChanged,
      value = defaultValue,
      getUrl,
    } = this.props;

    return (
      <div className="x-container-with-ribbon">
        <EditRibbon
          context={context}
          goTo={goTo}
          entity={entity}
          record={!loading ? getRecord(value) : undefined}
          starred={starred}
          crumbs={crumbs}
          toggleStar={toggleStar}
          hidePanels={hidePanels}
          reload={this.reload}
        />
        <div className="x-content-with-ribbon">
          <div className="x-record-layout">
            <RecordForm
              context={context}
              entity={entity}
              runQuery={runQuery}
              save={save}
              saving={saving}
              onDelete={onDelete}
              deleting={deleting}
              onCancel={onCancel}
              onHasChanged={onHasChanged}
              isNew={isNew}
              goTo={goTo}
              withLinks={withLinks}
              loading={loading}
              reload={reload}
              eventId={eventId}
              auditTrailId={auditTrailId}
              deleteLabels={deleteLabels}
              layout={layout}
              disableEdit={disableEdit}
              value={value}
              onChange={this.onChangeSetValue}
              getUrl={getUrl}
            />
          </div>
        </div>
      </div>
    );
  }
}
