import { ApiCall } from "../types/api";
import { FileType } from "../types/media";
import { CancellablePromise } from "../types/promises";

export const workflowsMediaApi = (apiCall: ApiCall) => {
  const getMediaUrl = (recordId: string, fileName: string) =>
    ["/api/workflows", recordId, "blobs", fileName].join("/");

  const upload = (recordId: string, file: FileType) =>
    apiCall("post", `api/workflows/${recordId}/blobs`, file, {
      upload: true,
    }).then((response: string) =>
      CancellablePromise.resolve(JSON.parse(response)),
    );

  const uploadAndGetUrl = (recordId: string, file: FileType) =>
    upload(recordId, file).then((returnData: FileType) => {
      const { name } = returnData;
      return getMediaUrl(recordId, name);
    });

  return {
    uploadAndGetUrl,
  };
};
