import { Comment } from "common/types/comments";
import { Context } from "common/types/context";
import { arrayToString } from "common/utils/array";
import { getFormattedDate } from "common/widgets/date/date-base-label";
import { getDateTimeFormat } from "common/widgets/date/date-time-label";
import { UserAvatar } from "common/widgets/user-avatar";
import { Preview } from "../../attachments/preview";
import { CommentBubble } from "./bubble";
import { CommentHeader } from "./header";

interface PropTypes {
  context: Context;
  comment: Comment;
  onDelete: (commentId: string, index?: number) => void;
}

export const CommentListItem = ({ context, comment, onDelete }: PropTypes) => {
  const { id, createdOn, createdBy, userName, text, isNew, attachments } =
    comment;

  const isMine = context.id === createdBy;
  const mainClasses = arrayToString(["x-comment", isMine ? "x-mine" : ""]);
  const formattedDate = getFormattedDate(
    createdOn,
    getDateTimeFormat(context.uiFormat),
  );

  return (
    <div className={mainClasses}>
      <UserAvatar value={undefined} onChange={undefined} />
      <div className="x-comment-container">
        <CommentHeader userName={userName} createdOn={formattedDate} />
        <CommentBubble
          id={id}
          text={text}
          canDelete={false}
          onDelete={onDelete}
          isNew={isNew}
        />
        <Preview allowClear={false} attachments={attachments} />
      </div>
    </div>
  );
};

CommentListItem.displayName = "CommentListItem";
