import { Calendar } from "common/types/calendars";
import { createList } from "common/widgets/list-with-search";
import { getEditPath } from "x/account-settings/functions";
import { CalendarView } from "./calendar";

interface PropTypes {
  editPath: string;
}
export const List = createList<Calendar, PropTypes>(
  () => _("Calendars"),
  "label",
  ({ editPath }, calendar) => (
    <CalendarView
      editPath={getEditPath(calendar.id, editPath)}
      calendar={calendar}
    />
  ),
);
