import { defaultFor } from "common";
import { merge1 } from "common/merge";
import { Entity } from "common/entities/types";
import { Properties } from "common/types/records";
import { getRelatedRecords } from "common/record/utils";
import { setReadOnly } from "common/entities/entity-column/functions";
import { RelatedValue } from "../types";

export const updateEntity = (entity: Entity = defaultFor<Entity>()) => {
  const { columns = [] } = entity;
  const updatedColumns = columns.map(setReadOnly(["groupOwner"]));
  return merge1("columns", updatedColumns, entity);
};

export const updateDefaultForm = (
  defaultForm = defaultFor<Properties>(),
  value: RelatedValue,
  entity: Entity,
) => {
  const { record, related } = value;
  const records = getRelatedRecords(entity.name, record, related.form).filter(
    (r) => !r.properties.isDeleted && r.properties.groupOwner,
  );
  return merge1("groupOwner", !records.length, defaultForm);
};
