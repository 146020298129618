import { defaultFor } from "common";
import { ValueProps } from "common/with-value-for";
import { Properties, Record } from "common/types/records";
import { RelatedValue } from "common/record/form/content/related/types";
import { areChargesReadOnly } from "common/record/disabled-related-entity";
import { PropTypes } from "common/record/form/content/related/table-with-form/types";
import { StandardRelated } from "common/record/form/content/related/standard-related";
import { AlertWarning } from "common/widgets/alert";
import { getByBehaviorArgument } from "common/entities";

type Props = PropTypes & ValueProps<RelatedValue>;

export const PartCharge = (props: Props) => {
  const { value, entity, context, displayTypes } = props;
  const { record = defaultFor<Record>() } = value;
  const { properties = defaultFor<Properties>() } = record;

  const readOnlyEntity = areChargesReadOnly(
    context.entities[entity.arguments.workOrderEntity],
    entity,
    properties,
    displayTypes,
  );

  const batchChargeEntity = getByBehaviorArgument(
    context.entities,
    "BatchCharge",
    "partChargeEntity",
    entity.name,
  );

  if (!batchChargeEntity) {
    return (
      <AlertWarning
        message={_(
          "Missing permission for an entity with {BEHAVIOR_NAME} behavior.",
        ).replace("{BEHAVIOR_NAME}", "Batch Charges")}
      />
    );
  }
  return (
    <StandardRelated
      {...props}
      displayTypes={readOnlyEntity ? ["table"] : undefined}
    />
  );
};
PartCharge.displayName = "PartCharge";
