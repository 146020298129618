import {
  SiteTreeSelector,
  SiteSelectorValue,
} from "common/widgets/site-selector/tree-selector";
import { defaultFor } from "common";
import { getSitesDictionary } from "common/functions/sites";
import { Site } from "common/types/sites";
import { Selector } from "common/widgets/selector";
import { ValueComponent } from "common/with-value-for";
import { getDisplaySiteFn } from "./functions";

export interface SiteTreeSelectorWithSearchValue {
  site: string;
  siteOpen: boolean;
}

interface PropTypes {
  sites: Site[];
}

export const defaultValue = defaultFor<SiteTreeSelectorWithSearchValue>();

export class SiteTreeSelectorWithSearch extends ValueComponent<
  SiteTreeSelectorWithSearchValue,
  PropTypes
> {
  static readonly displayName = "SiteTreeSelectorWithSearch";

  onChangeSite = (siteValue: SiteSelectorValue) => {
    const { site, open } = siteValue;
    this.mergeValue({
      site: site?.name,
      siteOpen: open,
    });
  };

  onSearchSite = (site: Site) => {
    this.mergeValue({
      site: site?.name,
    });
  };

  render() {
    const { sites, value = defaultValue } = this.props;
    const { site, siteOpen } = value;
    const sitesDictionary = getSitesDictionary(sites);
    return (
      <div className="x-float-left" title={_("Change site")}>
        <SiteTreeSelector
          placeholder={_("Select...")}
          autoClose={true}
          icon="fa fa-map-marker"
          sites={sites}
          value={{
            site: sitesDictionary[site],
            open: siteOpen,
          }}
          onChange={this.onChangeSite}
        >
          <div className="x-tab-style-header">{_("Sites")}</div>
          <Selector<Site>
            className="qa-site-search x-margin-10 x-margin-bottom-15"
            placeholder={`${_("Type to search")}...`}
            options={sites}
            getOptionLabel={getDisplaySiteFn(sitesDictionary)}
            value={undefined}
            onChange={this.onSearchSite}
          />
        </SiteTreeSelector>
      </div>
    );
  }
}
