import * as R from "ramda";
import { Component } from "common/component";
import { withList, WithList } from "common/with-list";
import { FancyCheckbox, FancyCheckboxLarge, FancyCheckboxSmall } from ".";

type CheckboxSize = "small" | "large";

export interface PropTypesBase<TItem> {
  items: TItem[];
  isDisabled?: (item: TItem) => boolean;
  display: (item: TItem) => string;
  displayExtra?: (item: TItem) => any;
  displayHint?: (item: TItem) => string;
  noBottomMargins?: boolean;
  size?: CheckboxSize;
  inputId?: string;
  className?: string;
}

export interface PropTypes<TItem> extends PropTypesBase<TItem> {
  isChecked: (item: TItem) => boolean;
  onToggle: (item: TItem, isChecked: boolean) => void;
}

const getComp = (size: CheckboxSize) => {
  switch (size) {
    case "small":
      return FancyCheckboxSmall;
    case "large":
      return FancyCheckboxLarge;
    default:
      return FancyCheckbox;
  }
};

export class CheckboxList<TItem> extends Component<PropTypes<TItem>> {
  static readonly displayName = "CheckboxList";

  checkboxList: WithList<boolean>;

  constructor(props: PropTypes<TItem>) {
    super(props);
    this.checkboxList = withList<boolean>({
      update: this.onToggle,
      remove: undefined,
    });
  }

  onToggle = (index: number, checked: boolean) => {
    const { items, onToggle } = this.props;
    onToggle(items[index], checked);
  };

  render() {
    const {
      items = [],
      display,
      displayExtra,
      displayHint,
      isChecked,
      noBottomMargins,
      size,
      isDisabled = R.always(false),
      inputId,
      className = "",
    } = this.props;
    const CheckboxComp = getComp(size);
    const classSuffix = noBottomMargins ? "-no-bottom-margins" : "";

    return (
      <div
        className={`x-fancy-checkbox-list${classSuffix}${
          className && " " + className
        }`}
      >
        {items.map((item, index) => (
          <CheckboxComp
            key={index}
            disabled={isDisabled(item)}
            label={display(item)}
            inputId={inputId && inputId + index}
            className="x-margin-bottom-2"
            hint={displayHint?.(item)}
            value={isChecked(item)}
            onChange={this.checkboxList.update(index)}
          >
            {displayExtra ? displayExtra(item) : undefined}
          </CheckboxComp>
        ))}
      </div>
    );
  }
}
