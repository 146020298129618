import * as R from "ramda";
import { isSystemIntFk } from "common/functions/system-int";
import { Calendar } from "common/types/calendars";
import { SystemIntFkValue } from "common/types/system-int";
import { ValueComponent } from "common/with-value-for";
import { Selector } from "../selector";

interface PropTypes {
  calendars: Calendar[];
  placeholder?: string;
  allowClear?: boolean;
  disabled?: boolean;
  label?: string;
}

const formatCalendar = (calendar: Calendar) => calendar?.label;

export class CalendarSelector extends ValueComponent<
  SystemIntFkValue,
  PropTypes
> {
  static readonly displayName = "CalendarSelector";

  onChange = (calendar: Calendar) => {
    this.setValue(calendar?.id);
  };

  render() {
    const { calendars, placeholder, label, allowClear, disabled, value } =
      this.props;
    const calendarId = isSystemIntFk(value) ? value.id : value;
    const calendarValue = R.find((c) => c.id === calendarId, calendars);

    return (
      <Selector<Calendar>
        placeholder={placeholder ?? `${_("Select calendar")}...`}
        className="qa-calendar-dropdown"
        label={label}
        allowClear={allowClear}
        disabled={disabled}
        options={calendars}
        getOptionLabel={formatCalendar}
        value={calendarValue}
        onChange={this.onChange}
      />
    );
  }
}
