import * as R from "ramda";
import { isSystemStringFk } from "common/functions/system-strings";
import { Currency } from "common/types/currencies";
import { SystemStringFkValue } from "common/types/system-strings";
import { Label } from "../label";

interface PropTypes {
  currencies: Currency[];
  value: SystemStringFkValue;
}

export const getCurrencySelectorLabel = (
  currencies: Currency[] = [],
  value: SystemStringFkValue,
) => {
  if (isSystemStringFk(value)) {
    return value.title;
  }
  const currency = R.find((c) => c.id === value, currencies);
  return currency ? currency.label : value || "";
};

export const CurrencySelectorLabel = ({ currencies, value }: PropTypes) => {
  const label = getCurrencySelectorLabel(currencies, value);
  return <Label value={label} />;
};
CurrencySelectorLabel.displayName = "CurrencySelectorLabel";
