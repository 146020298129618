import * as R from "ramda";
import { behaveAs } from "common/entities";
import {
  EntityColumn,
  SYSTEM_FIELDS,
} from "common/entities/entity-column/types";
import { Entities, Entity } from "common/entities/types";
import { KeysOf, Properties } from "common/types/records";

interface ColumNameDictionary {
  [columName: string]: string;
}

const getColumnDictionary = (columns: EntityColumn[]) =>
  columns.reduce<ColumNameDictionary>(
    (acc, { name }) => ({
      ...acc,
      [name.toLowerCase()]: name,
      [`c_${name.toLowerCase()}`]: name,
    }),
    {},
  );

const getNonSystemColumns = (entity: Entity) =>
  entity.columns.filter(
    (c) => !R.includes(c.name, ["tempId", ...SYSTEM_FIELDS]),
  );

const getColumnsDictionary = R.pipe(getNonSystemColumns, getColumnDictionary);

const getBehaviorColumns = (entity: Entity): ColumNameDictionary => {
  if (behaveAs("PartCharge", entity))
    return {
      c_quantity: "quantity",
      quantity: "quantity",
      partId: "partId",
      c_partId: "partId",
      partid: "partId",
      c_partid: "partId",
    };

  return {};
};

const newRecordProperties = (
  commonAndBehaviorColumns: string[],
  behaviorColumns: ColumNameDictionary,
  requirementColumnNames: ColumNameDictionary,
  targetColumnNames: ColumNameDictionary,
  properties: Properties,
) =>
  commonAndBehaviorColumns.reduce<Properties>((acc, columnName) => {
    const behaviorColumn = behaviorColumns[columnName];

    const requirementColumnName =
      requirementColumnNames[columnName] || behaviorColumn;
    const targetColumnName = targetColumnNames[columnName] || behaviorColumn;

    const value = properties[requirementColumnName];

    return R.isNil(acc[targetColumnName])
      ? { ...acc, [targetColumnName]: value }
      : acc;
  }, {});

export const mapCommonColumns = (
  requirementEntity: Entity,
  targetEntity: Entity,
  properties: Properties,
) => {
  const requirementColumnNames = getColumnsDictionary(requirementEntity);
  const targetColumnNames = getColumnsDictionary(targetEntity);

  const commonColumns = R.intersection(
    R.keys(requirementColumnNames),
    R.keys(targetColumnNames),
  ) as KeysOf<ColumNameDictionary>;

  const behaviorColumns = getBehaviorColumns(targetEntity);
  const commonAndBehaviorColumns = [
    ...commonColumns,
    ...(R.keys(behaviorColumns) as KeysOf<ColumNameDictionary>),
  ];

  return newRecordProperties(
    commonAndBehaviorColumns,
    behaviorColumns,
    requirementColumnNames,
    targetColumnNames,
    properties,
  );
};

export const getPartRequirementRelatedEntities = (
  entities: Entities,
  partRequirementEntity: Entity,
) => {
  const partChargeEntityName = partRequirementEntity.arguments.targetEntity;
  const partChargeEntity = entities[partChargeEntityName];

  const batchChargeEntity = R.find(
    (e) =>
      behaveAs("BatchCharge", e) &&
      e.arguments.partChargeEntity === partChargeEntity.name,
    R.values(entities),
  );
  const batchEntity = entities[batchChargeEntity.arguments.batchEntity];
  const stockEntity = entities[batchEntity.arguments.stockEntity];
  const partEntity = entities[stockEntity.arguments.partEntity];

  return { batchEntity, partEntity, stockEntity };
};

export const isPartRequirementEntity = (entities: Entities, entity: Entity) => {
  const partChargeEntity = R.values(entities).find(
    (e) =>
      e.name === entity.arguments.targetEntity && behaveAs("PartCharge", e),
  );
  return !!partChargeEntity && behaveAs("Requirement", entity);
};
