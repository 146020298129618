import * as R from "ramda";
import { Form } from "common/types/forms";
import { Site } from "common/types/sites";
import { getSubTreeSingleNodes } from "common/utils/tree";
import { Group, GroupColumn } from "common/form/types";

export const getLayoutGroupColumns = (groups: Group[] = []): GroupColumn[] =>
  groups.flatMap((g) => g.columns || []);

export const getLayoutGroupColumn = (
  layoutColumns: GroupColumn[],
  columnName: string,
) => layoutColumns.find((gc) => gc.columnName === columnName);

export const filterFormsPerSite =
  (sites: Site[], site: Site) =>
  (form: Form): boolean => {
    const siteName = site?.name;
    const isGroup = site?.isGroup;
    const formSites = form?.sites;

    if (!siteName || !formSites || !formSites.length) {
      return true;
    }

    if (isGroup) {
      const children = getSubTreeSingleNodes(sites, site).map((s) => s.name);
      return R.intersection(children, formSites).length > 0;
    }

    return formSites.includes(siteName);
  };
