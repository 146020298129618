import { Component } from "react";
import * as R from "ramda";
import { Node } from "common/widgets/tree/types";
import { Entity } from "common/entities/types";
import {
  displayNodeWithLink,
  displayExpandableNode,
} from "x/records/list/tree/functions";
import { TreeGrouping } from "./entity-tree-group";

interface PropTypes {
  entity: Entity;
  trees: Node[];
  onClick: (node: Node) => any;
  showTitle: boolean;
  link?: (node: Node) => string;
  className?: string;
  selected?: Node;
  showUngroup?: boolean;
  isExpandable?: boolean;
  expandedByDefault?: boolean;
  withNodeIcons?: boolean;
}

export class EntityTree extends Component<PropTypes> {
  static readonly displayName = "EntityTree";

  render() {
    const {
      trees,
      entity,
      selected,
      onClick,
      showUngroup,
      showTitle,
      link,
      className = "",
      isExpandable,
      expandedByDefault,
      withNodeIcons,
    } = this.props;
    const [groupedTree, ungroupedTree] = R.partition((t) => t.isGroup, trees);

    const titleForGroupedTree = showTitle
      ? _("Grouped {ENTITY}").replace("{ENTITY}", entity.localizedName)
      : undefined;

    const titleForUngroupedTree = showTitle
      ? _("Ungrouped {ENTITY}").replace("{ENTITY}", entity.localizedName)
      : undefined;

    const displayNode = link
      ? displayNodeWithLink(link)
      : displayExpandableNode(isExpandable, withNodeIcons);

    return (
      <div className={`x-tree-container ${className}`}>
        {!!trees && !!trees.length ? (
          <div className="x-tree-groups-wrap">
            {groupedTree && groupedTree.length ? (
              <TreeGrouping
                title={titleForGroupedTree}
                tree={groupedTree}
                displayNode={displayNode}
                sort={true}
                expandedByDefault={expandedByDefault}
                selected={selected}
                onClick={onClick}
              />
            ) : undefined}
            {showUngroup && ungroupedTree && ungroupedTree.length ? (
              <TreeGrouping
                title={titleForUngroupedTree}
                tree={ungroupedTree}
                displayNode={displayNode}
                expandedByDefault={expandedByDefault}
                selected={selected}
                onClick={onClick}
              />
            ) : undefined}
          </div>
        ) : (
          _("No results")
        )}
      </div>
    );
  }
}
