import { JSX } from "react";
import { displayEntity } from "common/api/entities";
import { Entities } from "common/entities/types";
import { LinkRecord } from "common/widgets/link-record";
import { parse } from "./functions";
import { Event } from "./types";

interface EventPropTypes {
  entities: Entities;
  site: string;
  event: Event;
}

export const EventTitle = ({ entities, site, event }: EventPropTypes) => {
  const e = parse(entities, event);
  const { entityName, related } = e;

  const getSingularEntity = (name: string) => displayEntity(entities[name]);

  let description: JSX.Element | string;

  switch (e.type) {
    case "Event": {
      const links = related.map((r, i) => {
        const link = (
          <LinkRecord
            entity={entities[r.entityName]}
            id={r.id}
            site={site}
            title={`${getSingularEntity(r.entityName)}: ${r.label}`}
            target="_blank"
          />
        );
        const split = i ? <span> | </span> : undefined;
        return (
          <span key={i}>
            {split}
            {link}
          </span>
        );
      });

      description = (
        <span>
          {getSingularEntity(entityName)}
          {links.length ? `: ${links}` : ""}
        </span>
      );
      break;
    }
    case "NonWorking":
    case "Holiday": {
      description = (
        <span title={_("Unavailable for maintenance")}>
          {_("GETSINGULARENTITY unavailable").replace(
            "GETSINGULARENTITY",
            getSingularEntity(entityName),
          )}
        </span>
      );
      break;
    }
    case "Offseason": {
      description = (
        <span title={_("Off-Season")}>
          {_("GETSINGULARENTITY unavailable").replace(
            "GETSINGULARENTITY",
            getSingularEntity(entityName),
          )}
        </span>
      );
      break;
    }
    default:
      description = e.type;
  }

  return <div className="x-description">{description}</div>;
};

EventTitle.displayName = "EventTitle";
