import { Crumb } from "x/layout/ribbon/breadcrumb";
import { RecordRibbon } from "./record-ribbon";
import { Buttons, PropTypes as ButtonPropTypes } from "./buttons";

interface PropTypes extends ButtonPropTypes {
  crumbs?: Crumb[];
}
export const EditRibbon = (props: PropTypes) => {
  const { context, entity, record, reload, crumbs } = props;
  const { site, scope } = context;
  return (
    <RecordRibbon
      reload={reload}
      site={site.name}
      scope={scope}
      entity={entity}
      record={record}
      crumbs={crumbs}
    >
      <Buttons {...props} />
    </RecordRibbon>
  );
};

EditRibbon.displayName = "EditRibbon";
