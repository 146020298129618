import { DocumentAddedEvent } from "common/types/events";
import { getText } from "common/scheduled-event/form/projections/reason";
import { Row } from "../row";
import { getVersionedDocumentUrl } from "./functions/document";

interface PropTypes {
  siteName: string;
  event: DocumentAddedEvent;
  hideLinks?: boolean;
}

export const DocumentAdded = ({ siteName, event, hideLinks }: PropTypes) => {
  const text = getText(event?.details?.reason);
  const url = getVersionedDocumentUrl(siteName, event?.details);
  return (
    <div className="x-document-added qa-document-added">
      <legend>{_("Details")}</legend>
      <Row label={_("File Name")}>
        {url && !hideLinks ? (
          <a
            href={url}
            className="x-word-break"
            target="_blank"
            rel="noopener noreferrer"
          >
            {text}
          </a>
        ) : (
          text
        )}
      </Row>
    </div>
  );
};

DocumentAdded.displayName = "DocumentAdded";
