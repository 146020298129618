import * as R from "ramda";

export const parseValue = (value: any) => {
  if (value === false) return false;
  if (value === 0) return 0;
  if (value && value.length === 0) return undefined;
  return value || undefined;
};

export const update = <T1>(oldVal: T1, newVal: T1, list: T1[]): T1[] =>
  R.update(R.findIndex(R.identical(oldVal), list), newVal, list);

export const toLettersOnly = (s: string = "") => s.replace(/[^a-zA-Z]/g, "");

export const toCamelCase = (s: string = "") =>
  s.charAt(0).toLocaleLowerCase() + s.slice(1);

export const toPascalCase = (s: string = "") =>
  s.charAt(0).toLocaleUpperCase() + s.slice(1);

export const toLowerNoSpace = (s: string = ""): string =>
  s.toLocaleLowerCase().replace(/ /g, "");

export const toDashed = (s: string = ""): string =>
  s.charAt(0).toLocaleLowerCase() +
  s.slice(1).replace(/[A-Z]/g, (char) => "-" + char.toLocaleLowerCase());

export const kebabCaseToTitle = (s: string = ""): string =>
  s.charAt(0).toLocaleUpperCase() +
  s.slice(1).replace(/[A-Z]/g, (char) => " " + char);

export const toDashedNoSpace = (s: string = ""): string =>
  s.replace(/\s+(-\s+)?/g, "-");

export const toFullCamelCase = (s: string): string =>
  (s || "")
    .replace(/[^-a-zA-Z0-9\s]/g, "")
    .replace(/\s/g, "-")
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/--+/, "-")
    .replace(/^-$/, "")
    .toLocaleLowerCase()
    .replace(/-[a-zA-Z0-9]/g, (match) => match[1].toLocaleUpperCase());

export const toKebabCase = (s: string = ""): string =>
  toDashedNoSpace(
    s
      .toLowerCase()
      .replace(/[^-a-z0-9\s]/g, "")
      .replace(/--+/, "-"),
  );

export const toAlphanumericWithDashes = (s: string) =>
  s.replace(/[^a-z0-9-]/gi, "");

export const toStrictName = (name: string) => toPascalCase(toLettersOnly(name));

export const truncate = (s: string = "", msgLength: number) =>
  `${s.substring(0, msgLength)}${msgLength < s.length ? "..." : ""}`;

export function updateEqual<T>(oldItem: T, newItem: T, list: T[]) {
  return R.update<T>(R.findIndex<T>(R.equals(oldItem), list), newItem, list);
}

export function filterObject<T, U extends { [index: string]: T }>(
  filterFn: (key: string, value: T) => boolean,
  o: U,
): U {
  return R.pickBy(R.flip(filterFn), o);
}

export function defaultFor<T>(): T {
  return {} as unknown as T;
}

// TODO review this. form probably was not updating and the solution
// someone found was to force new props every render...
let count = 7;
export function getUniqueId() {
  return `x-id-${count++}`;
}

interface InterfaceWithLocalizedName {
  localizedName?: string;
}
export const getLocalizedName = (o: InterfaceWithLocalizedName): string =>
  (o && o.localizedName) || "";

const isTypeBase = (checkValue: boolean) =>
  function isTypeBase<A, B extends A>(
    includes: Array<keyof B>,
    excludes?: PropertyKey[],
  ) {
    return (a: A): a is B => {
      const keys = Object.keys(a ?? {}) as Array<keyof A>;
      const keysWithValue = checkValue
        ? keys.filter((k) => !R.isNil(a[k]))
        : keys;

      if (R.intersection(keysWithValue, excludes || []).length) return false;

      return R.all((i) => R.includes(i, keysWithValue), includes);
    };
  };

export const isType = isTypeBase(true);
export const isTypeWithoutValues = isTypeBase(false);

export function isArray<A, B extends A>() {
  return (a: A): a is B => Array.isArray(a);
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noOp = () => {};

export const getTrue = () => true;
export const getFalse = () => false;
