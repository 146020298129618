import * as R from "ramda";
import { defaultFor } from "common";
import { searchApi } from "common/api/search";
import { Entity } from "common/entities/types";
import { addFilter } from "common/query/filter";
import { addToSelectQuery } from "common/query/select";
import { isField, SelectField } from "common/query/types";
import { ApiCall } from "common/types/api";
import { ForeignKey } from "common/types/foreign-key";
import { CancellablePromise } from "common/types/promises";
import { Properties } from "common/types/records";

// This is a "best effort" function. Will work find as long as all the fields
// defined in the query are NOT expressions.
export const getFkFromProperties = (
  entity: Entity,
  properties: Properties,
): ForeignKey => {
  if (!entity || !entity.query || !entity.query.select || !properties) {
    return undefined;
  }

  const getColumn = (aliasOrName: string): SelectField =>
    R.find(
      (c) => isField(c) && (c.alias || c.name) === aliasOrName,
      entity.query.select,
    ) as SelectField;

  const title = getColumn("title");
  const subtitle = getColumn("subtitle");
  const subsubtitle = getColumn("subsubtitle");
  const image = getColumn("image");

  return {
    id: properties.id,
    title: title && properties[title.name],
    number: properties.number,
    image: image && properties[image.name],
    subtitle: subtitle && properties[subtitle.name],
    subsubtitle: subsubtitle && properties[subsubtitle.name],
    entity: entity.name,
  };
};

export const getFk = (
  apiCall: ApiCall,
  entity: Entity,
  id: string,
  addToSelect: SelectField[] = [],
): CancellablePromise<ForeignKey[]> => {
  const { name, query } = entity || defaultFor<Entity>();

  const selects: SelectField[] = [{ name: "id" }].concat(addToSelect);
  const queryToRun = addFilter(
    {
      and: [
        { name: "id", op: "in", value: id },
        {
          or: [
            { name: "isDeleted", op: "istrue" },
            { name: "isDeleted", op: "isfalse" },
          ],
        },
      ],
    },
    {
      entity: name,
      query: addToSelectQuery(selects, query),
    },
  );
  return searchApi(apiCall)
    .runQuery(queryToRun)
    .then((fks: ForeignKey[] = []) =>
      fks.map((fk) => ({ ...fk, entity: entity.name })),
    );
};

export const getFkOrDefault = (
  apiCall: ApiCall,
  entity: Entity,
  properties: Properties,
  addToSelect?: SelectField[],
): CancellablePromise<ForeignKey> =>
  getFk(apiCall, entity, properties && properties.id, addToSelect)
    .then((fks) => (fks.length ? fks[0] : undefined))
    .catch(() => getFkFromProperties(entity, properties));

export const getFksOrDefault = (
  apiCall: ApiCall,
  entity: Entity,
  properties: Properties[] = [],
  addToSelect?: SelectField[],
): CancellablePromise<ForeignKey[]> => {
  const ids = properties.map((p) => p.id).join(",");

  return getFk(apiCall, entity, ids, addToSelect).catch(() =>
    properties
      .map((props) => getFkFromProperties(entity, props))
      .filter(R.identity),
  );
};
