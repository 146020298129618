import { returnAndNotify } from "common/api/with-notifications";
import { ApiCall } from "common/types/api";
import { CancellablePromise } from "common/types/promises";

export const sandboxApi = (apiCall: ApiCall) => ({
  create:
    (parentTenantName: string) =>
    (name: string): CancellablePromise<void> =>
      apiCall("post", `api/admin/tenants/sandbox`, {
        parentTenantName,
        name,
      }).then(
        returnAndNotify(
          _(
            "The sandbox was successfully created. Configuration might take some time...",
          ),
        ),
      ),
  reset: (sandboxName: string): CancellablePromise<void> =>
    apiCall("post", "api/admin/tenants/reset-sandbox", {
      sandboxName,
    }),
});
