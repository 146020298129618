import { Component } from "react";
import { getColumn } from "common/entities";
import { Entity } from "common/entities/types";
import { PoItemValue } from "common/record/actions/ui/purchase-order/return-items/types";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { DefaultCurrencyLabel } from "common/widgets/currency/label-with-default";
import { formatFk } from "common/widgets/foreign-key/functions";
import { LinkFk } from "common/widgets/foreign-key/link-fk";
import { Int } from "common/widgets/number";
import { IntLabel } from "common/widgets/number/label";
import { ValueProps } from "common/with-value-for";
import { isValidReturning } from "./functions";

interface PropTypes extends ValueProps<PoItemValue> {
  context: Context;
  entity: Entity;
  items: PoItemValue[];
}

export class PoListItem extends Component<PropTypes> {
  onReturningChange = (newReturningQty: string) => {
    const updatedReturningQty =
      newReturningQty === "0" ? 0 : parseInt(newReturningQty) || undefined;
    this.props.onChange({
      ...this.props.value,
      returningQty: updatedReturningQty,
    });
  };

  onPartLocationChange = (partLocationId: ForeignKey) =>
    this.props.onChange({ ...this.props.value, partLocationId });

  render() {
    const { context, entity, value, items } = this.props;
    const { entities } = context;
    const {
      orderedQty,
      receivedQty,
      returningQty,
      partId,
      unitCost,
      currency,
      partLocationId,
    } = value;

    const currencyColumn = getColumn(entity, "unitCost");

    return (
      <tr className="x-po-list-item">
        {/* Ordered */}
        <td>{<IntLabel value={orderedQty} />}</td>
        {/* Received */}
        <td>
          <IntLabel value={receivedQty || "-"} />
        </td>
        {/* Returning */}
        <td
          className={isValidReturning(value, items) ? undefined : "x-has-error"}
        >
          {receivedQty ? (
            <Int value={returningQty} onChange={this.onReturningChange} />
          ) : (
            <IntLabel value="-" />
          )}
        </td>
        {/* Part */}
        <td>
          <LinkFk
            context={context}
            column={getColumn(entity, "partId")}
            value={partId}
          />
        </td>
        {/* Part Location */}
        <td>{formatFk(entities)(partLocationId, false)}</td>
        {/* Unit Cost */}
        <td>
          <DefaultCurrencyLabel
            context={context}
            column={currencyColumn}
            currencyId={currency?.id}
            value={unitCost}
          />
        </td>
      </tr>
    );
  }
}
