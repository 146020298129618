import { defaultFor } from "common";
import { formsApi } from "common/api/forms";
import { reportsApi } from "common/api/reports";
import {
  extract,
  getKeys,
  translateKey,
} from "common/culture/report-translator";
import { FormFooter } from "common/form/footer";
import { hasRolePermissionTo } from "common/functions/roles";
import { canAccessReportEntities } from "common/query/common";
import { Select } from "common/query/types";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { Form } from "common/types/forms";
import { CancellablePromise } from "common/types/promises";
import { Report } from "common/types/reports";
import { Role } from "common/types/roles";
import { ApiError } from "common/ui/api-error";
import { ActionButtonSmall, LinkButtonSmall } from "common/ui/buttons";
import { ChildPropTypes } from "common/ui/controllers/edit/types";
import { PermissionsError } from "common/widgets/error";
import { LoadingIcon } from "common/widgets/loading-icon";
import { ValueProps } from "common/with-value-for";
import { LabelsComponent, LabelsTable } from "x/culture/labels";
import { Ribbon, RibbonButtons } from "x/layout/ribbon";
import { ReportFormType } from "x/reports/edit/types";
import { exportCsv, exportPdf } from "../export-utils";
import {
  deleteReportFromForms,
  getSelectChanges,
  getUpdatedForms,
  isValid,
} from "./functions";
import { ReportForm } from "./report";

interface PropTypes extends ChildPropTypes<ReportFormType> {
  context: Context;
}

interface StateType {
  reports?: Report[];
  error?: any;
}

const defaultValue = defaultFor<ReportFormType>();
export const defaultReport = defaultFor<Report>();

type Props = PropTypes & ValueProps<ReportFormType>;

export const onReportSave =
  (
    context: Context,
    initialSelect: Select,
    currentSelect: Select,
    id: number,
    forms: Form[],
    fn: () => void,
  ) =>
  () => {
    if (initialSelect) {
      const changes = getSelectChanges(initialSelect, currentSelect);
      const updatedForms = getUpdatedForms(id, forms, changes);
      if (updatedForms.length)
        return formsApi(context.apiCall).bulkUpdate(updatedForms).then(fn);
    }
    return CancellablePromise.resolve(fn());
  };

export const onReportDelete =
  (context: Context, id: number, forms: Form[], fn: () => void) => () => {
    const updatedForms = deleteReportFromForms(id, forms);
    if (updatedForms.length)
      return formsApi(context.apiCall).bulkUpdate(updatedForms).then(fn);
    return CancellablePromise.resolve(fn());
  };

export class EditReportForm extends LabelsComponent<
  Report,
  ReportFormType,
  PropTypes,
  StateType
> {
  state: StateType = {
    reports: undefined,
    error: undefined,
  };

  constructor(props: Props) {
    super(props, (r) => extract(this.props.context.uiFormat.culture, r));
  }

  componentDidMount() {
    this.loadReports();
  }

  loadReports = () => {
    const { context } = this.props;
    const { apiCall, entities, uiFormat } = context;

    reportsApi(apiCall, uiFormat.culture, entities)
      .list()
      .then((reports: Report[]) => {
        this.setState({ reports });
      })
      .catch(this.onError);
  };

  onError = (error: ApiErrorResponse) => {
    this.setState({ error });
  };

  onReportChange = (report: Report) => {
    this.setValue(this.mergeItem(report));
  };

  render() {
    const {
      context,
      onCancel,
      onSave,
      onDelete,
      dependencies,
      loading,
      initialValue,
      value = defaultValue,
    } = this.props;
    const { forms, site, scope, apiCall, entities } = context;
    const { culture } = context.uiFormat;
    const { reports = [], error } = this.state;

    if (loading) return <LoadingIcon />;
    if (error) return <ApiError error={error} />;

    const report = value.item || defaultReport;

    if (!canAccessReportEntities(entities, report)) return <PermissionsError />;

    const roles: Role[] = dependencies?.roles || [];
    const filteredRoles = roles.filter((r) =>
      hasRolePermissionTo(r, "Reports", "Read"),
    );

    const initialSelect =
      initialValue && (initialValue.item.query.select || []);

    const currentSelect = report.query.select;

    const deleteReportWarningMessage = report.storedQueriesId
      ? _("Cannot delete report as it is in use with a Scheduled Report")
      : undefined;

    return (
      <div className="x-container-with-ribbon">
        <Ribbon
          crumbs={[
            { name: _("Reports"), url: `#/${site.name}/Reports` },
            { name: (report.id && report.id.toString()) || _("New") },
          ]}
          onRefresh={undefined}
          scope={scope}
        >
          {report.id ? (
            <RibbonButtons>
              <ActionButtonSmall
                className="x-margin-right-5"
                onClick={exportCsv(apiCall, report)}
              >
                {_("Export to CSV")}
              </ActionButtonSmall>
              <ActionButtonSmall
                className="x-margin-right-5"
                onClick={exportPdf(apiCall, report)}
              >
                {_("Export to PDF")}
              </ActionButtonSmall>
              <LinkButtonSmall
                href={`#/${site.name}/Reports/${report.id}/preview?hidePanels=true`}
                target="_blank"
                title={_("Preview in a new tab")}
              >
                <i className="fa fa-share-square-o" />
              </LinkButtonSmall>
            </RibbonButtons>
          ) : undefined}
        </Ribbon>
        <div className="x-content-with-ribbon">
          <div className="x-padding-20">
            <ReportForm
              roles={filteredRoles}
              context={context}
              reports={reports}
              value={report}
              onChange={this.onReportChange}
            />
            <LabelsTable<Report, ReportFormType>
              allCultures={false}
              context={context}
              keys={getKeys(value.item)}
              value={value}
              translateKey={translateKey(value.item)}
              onChange={this.onChangeSetValueNoParse}
            />
            <FormFooter
              isNew={!report || !report.id}
              isValid={isValid(context.entities, culture, value, reports)}
              canCancel={true}
              onCancel={onCancel}
              canSave={!!onSave}
              onSave={onReportSave(
                context,
                initialSelect,
                currentSelect,
                report.id,
                forms,
                onSave,
              )}
              isSaving={!onSave}
              canDelete={!!onDelete}
              onDelete={onReportDelete(context, report.id, forms, onDelete)}
              isDeleting={!onDelete}
              canRestore={false}
              isRestoring={false}
              onRestore={undefined}
              deleteWarningMessage={deleteReportWarningMessage}
            />
          </div>
        </div>
      </div>
    );
  }
}
