import { Component } from "react";
import { Record } from "common/types/records";
import { Entity } from "common/entities/types";
import { getExpandedQuery } from "common/query/context";
import { QueryForEntity } from "common/query/types";
// eslint-disable-next-line import/no-cycle
import { ActionsHandler } from "common/record/actions/components/handler";
import { ActionsSelector } from "common/record/actions/components/selector";
import { Context } from "common/types/context";
import { GoFn } from "common/types/url";
// eslint-disable-next-line import/no-cycle
import { getActions, hasActions } from "./functions";
import { Action } from "./types";

interface PropTypes {
  context: Context;
  entity: Entity;
  records: Record[];
  reload: (value: any) => any;
  onTheRight: boolean;
  query?: QueryForEntity;
  includeSwitchForm: boolean;
  goTo: GoFn;
  displayType: "label" | "icon";
  label?: string;
  icon?: string;
  includeCopy?: boolean;
  predefinedActions?: Action[];
}

interface StateType {
  action?: Action;
}

export class RecordActions extends Component<PropTypes, StateType> {
  static readonly displayName = "RecordActions";
  state: StateType = {};

  setAction = (action: Action) => {
    const { records } = this.props;
    if (action?.fn) action.fn?.(records[0].properties);

    this.setState({ action });
  };

  unsetAction = () => this.setState({ action: undefined });

  render() {
    const {
      context,
      entity,
      records = [],
      query,
      displayType,
      goTo,
      reload,
      label,
      icon,
      onTheRight,
      includeSwitchForm,
      includeCopy,
      predefinedActions,
    } = this.props;
    const { action } = this.state;

    const actions = getActions(
      context,
      entity,
      records,
      includeSwitchForm,
      includeCopy,
      query,
      predefinedActions,
    );

    if (!hasActions(actions)) return <span />;

    const expandedQuery = query ? getExpandedQuery(query, context) : undefined;

    return (
      <>
        <ActionsSelector
          displayType={displayType}
          icon={icon}
          label={label}
          onTheRight={onTheRight}
          actionGroups={actions}
          onActionSelect={this.setAction}
        />
        {!action?.fn ? (
          <ActionsHandler
            context={context}
            entity={entity}
            records={records}
            query={expandedQuery}
            goTo={goTo}
            reload={reload}
            action={action}
            onActionDismiss={this.unsetAction}
            onActionPerform={this.unsetAction}
          />
        ) : undefined}
      </>
    );
  }
}
