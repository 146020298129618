import * as React from "react";
import {
  KeyboardEventHandler,
  ChangeEvent,
  SyntheticEvent,
  ClipboardEvent,
} from "react";
import { AlertErrorTip } from "common/widgets/alert";
import { arrayToString } from "common/utils/array";
import { ValueProps } from "common/with-value-for";

type OnSubmit = () => void;

interface PropTypes extends ValueProps<string> {
  onSubmit: OnSubmit;
  className?: string;
  hasError?: boolean;
  focus?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  label?: string;
}

interface StateType {
  start: number;
  end: number;
}

const onEnterPressed =
  (action: () => void): KeyboardEventHandler =>
  (e) =>
    (e.which === 13 || e.which === 10) && action();

const disableStandardBehavior = (e: SyntheticEvent<HTMLInputElement>) =>
  e.preventDefault();

const keyboardKey = {
  backspace: "Backspace",
  del: "Delete",
};

export const dotUnicode = "\u2022";

export class InputSimulatePasswordWithSubmit extends React.Component<
  PropTypes,
  StateType
> {
  static readonly displayName = "InputSimulatePasswordWithSubmit";
  input = React.createRef<HTMLInputElement>();

  constructor(props: PropTypes) {
    super(props);

    const position = this.props.value?.length ?? 0;
    this.state = {
      end: position,
      start: position,
    };
  }

  componentDidMount() {
    if (this.props.focus) {
      this.input.current.focus();
    }
  }

  setCursorPosition = (focusPosition: number) => {
    this.setState({}, () => {
      this.input.current.setSelectionRange(focusPosition, focusPosition);
    });
  };

  onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parsedValue = e.target.value.replace(new RegExp(dotUnicode, "g"), "");
    const { value, onChange } = this.props;
    const { start, end } = this.state;

    onChange(value.slice(0, start) + parsedValue + value.slice(end));
    this.setCursorPosition(
      start === end ? end + 1 : start + parsedValue.length,
    );
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const { backspace, del } = keyboardKey;
    const { end, start } = this.state;
    const { value, onChange } = this.props;

    if (key !== backspace && key !== del) return;
    e.preventDefault();

    if (
      !value?.length ||
      (start === 0 && end === 0 && key === backspace) // backspacing from first position
    )
      return;

    // single char delete
    if (key === del && start === end) {
      if (end === value.length) return; // deleting from last position

      const cursorPosition = start;
      onChange(
        value.slice(0, cursorPosition) + value.slice(cursorPosition + 1),
      );
      this.setCursorPosition(cursorPosition);
      return;
    }

    const cursorPosition = start === end ? start - 1 : start;
    onChange(value.slice(0, cursorPosition) + value.slice(end));
    this.setCursorPosition(cursorPosition);
  };

  onSelect = (e: React.SyntheticEvent<HTMLInputElement, Event>) => {
    const { selectionStart, selectionEnd } = e.currentTarget;
    this.setState({
      start: selectionStart,
      end: selectionEnd,
    });
  };

  onPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const { start, end } = this.state;
    const { value, onChange } = this.props;
    const pastedText = e.clipboardData.getData("Text");

    onChange(
      value.length
        ? value.slice(0, start) + pastedText + value.slice(end)
        : pastedText,
    );
    this.setCursorPosition(start + pastedText.length);
  };

  render() {
    const {
      onSubmit,
      className = "",
      hasError,
      value,
      errorMessage,
      disabled,
      label,
    } = this.props;

    const inputClassName = arrayToString([
      hasError ? "x-input-error" : undefined,
      className,
    ]);

    return (
      <>
        <input
          ref={this.input}
          type="text"
          className={inputClassName}
          disabled={disabled}
          onCopy={disableStandardBehavior}
          onCut={disableStandardBehavior}
          onKeyDown={this.onKeyDown}
          onPaste={this.onPaste}
          onSelect={this.onSelect}
          onKeyPress={onSubmit ? onEnterPressed(onSubmit) : undefined}
          value={value?.length ? dotUnicode.repeat(value.length) : ""}
          onChange={this.onInputChange}
          aria-label={label}
        />
        {hasError && errorMessage && <AlertErrorTip message={errorMessage} />}
      </>
    );
  }
}
