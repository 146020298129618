import * as R from "ramda";
import { Context } from "common/types/context";
import { defaultFor } from "common";
import { Secondaries } from "common/query/types";
import { getFilterKey } from "common/query/filter";
import { InputWithSubmit } from "common/widgets/input-with-submit";
import { ValueComponent, ValueProps } from "common/with-value-for";
import { queryForInput } from "common/widgets/record-selector/functions";

interface PropTypes {
  context: Context;
  entityName: string;
}

interface StateType {
  key: string;
}

type Props = PropTypes & ValueProps<Secondaries>;

const defaultValue = defaultFor<Secondaries>();

export class RelatedGridFilter extends ValueComponent<
  Secondaries,
  PropTypes,
  StateType
> {
  static readonly displayName = "RelatedGridFilter";

  state: StateType = { key: undefined };

  componentDidMount() {
    this.updateInternalState(null, this.props);
  }

  componentDidUpdate(prevProps: Props) {
    this.updateInternalState(prevProps, this.props);
  }

  updateInternalState = (prevProps: Props, nextProps: Props) => {
    if (!prevProps || !R.equals(prevProps.value, nextProps.value)) {
      const { value, entityName } = nextProps;
      this.setState({ key: value && getFilterKey(value[entityName]) });
    }
  };

  getSecondaryQuery = () => {
    const { context, entityName } = this.props;
    const { key } = this.state;

    return queryForInput(
      context.entities[entityName],
      context.entities,
      {
        // Query for the related entity
        entity: entityName,
        query: context.entities[entityName].query,
      },
      false,
    )(key).query;
  };

  onSubmit = () => {
    const { entityName, value = defaultValue } = this.props;
    const { key } = this.state;

    this.setValue(
      key
        ? R.mergeRight(value, {
            [entityName]: this.getSecondaryQuery(),
          })
        : R.omit([entityName], value),
    );
  };

  render() {
    const { key } = this.state;
    return (
      <div className="x-filter x-related-filter">
        <InputWithSubmit
          className="x-filter"
          value={key}
          submitOnBlur={true}
          onChange={this.onChangeMergeState("key")}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}
