import { DependenciesComp } from "common/with-dependencies";
import type { Context } from "common/types/context";
import {
  dependenciesInjected,
  getSchedulerApiData,
} from "x/scheduler2/dependency";
import { SchedulerAreaSwitch } from "x/scheduler2/switch";

interface PropTypes {
  context: Context;
  hidePanels: boolean;
}

export const Scheduler2Controller = ({ context, hidePanels }: PropTypes) => (
  <DependenciesComp
    className="x-scheduler2-controller-container"
    dependencies={{ schedulerApiData: getSchedulerApiData(context) }}
    child={
      <SchedulerAreaSwitch
        {...dependenciesInjected}
        context={context}
        hidePanels={hidePanels}
      />
    }
  />
);
