import { reportsApi } from "common/api/reports";
import { Context } from "common/types/context";
import { MenuEntrySetting } from "common/types/preferences";
import { Report } from "common/types/reports";
import {
  createInjected,
  FetchRecords,
} from "common/ui/controllers/list/fetch-records";
import { ValueProps } from "common/with-value-for";
import { MenuConfig } from "./form";

interface PropTypes extends ValueProps<MenuEntrySetting[]> {
  context: Context;
}

const injected = createInjected<MenuEntrySetting[], Report>();

export const NavigationConfigController = (props: PropTypes) => {
  const { context } = props;

  const api = reportsApi(
    context.apiCall,
    context.uiFormat.culture,
    context.entities,
  );

  return (
    <FetchRecords<MenuEntrySetting[], Report>
      fetch={api.list}
      passError={(apiError) => apiError?.status === 403}
      value={props.value}
      onChange={props.onChange}
    >
      <MenuConfig {...injected} context={context} />
    </FetchRecords>
  );
};
