import { JSX } from "react";
import { Component } from "common/component";
import { TableMap, TableValue } from "common/query/table/types";
import { RunQuery } from "common/query/types";
import { Context } from "common/types/context";
import { CancellablePromise } from "common/types/promises";
import { Properties } from "common/types/records";
import { FullPageModal } from "common/widgets/full-page-modal";
// eslint-disable-next-line import/no-cycle
import { LazyAdvancedSearchContent } from "common/widgets/record-selector/advanced-search/modal";
import { Tab } from "common/widgets/tabs/tab";
import { Tabs } from "common/widgets/tabs/tabs";
import { TabContentProps } from "../types";

interface PropTypes {
  selectProperty: (newSelected: Properties[]) => void;
  context: Context;
  runQuery: RunQuery;
  content: TabContentProps[];
  withLinks: boolean;
  disabled?: boolean;
  allowMultipleSelect?: boolean;
  allowAdvancedAdding?: boolean;
  approveChange?: () => CancellablePromise<void>;
}

interface State {
  showModal?: boolean;
  tables: TableMap;
}

export class AdvancedSearch extends Component<PropTypes, State> {
  static readonly displayName = "AdvancedSearch";
  state: State = {
    showModal: false,
    tables: undefined,
  };

  selectProperties = (properties: Properties[]) => {
    const { selectProperty } = this.props;
    selectProperty(properties);
    this.setState({ showModal: false });
  };

  onActionSelect = (newSelected: Properties) => {
    const { approveChange } = this.props;

    if (approveChange) {
      approveChange()
        .then(() => this.selectProperties([newSelected]))
        .catch(() => {
          /* ignore */
        });
    } else {
      this.selectProperties([newSelected]);
    }
  };

  onModifyButtonClick = () => {
    const { tables = [] } = this.state;
    this.selectProperties(tables[0]?.selected);
  };

  onAdvancedSearchClick = () => {
    this.setState({ showModal: true, tables: undefined });
  };

  onTabContentChange =
    (index: number = 0) =>
    (tableValue: TableValue) => {
      const { tables = [] } = this.state;

      const newTables = { ...tables, [index]: tableValue };
      this.setState({ tables: newTables });
    };

  render(): JSX.Element {
    const {
      runQuery,
      content,
      disabled,
      allowMultipleSelect,
      context,
      withLinks,
      allowAdvancedAdding,
    } = this.props;
    const { showModal, tables = [] } = this.state;
    const newQuery = tables[0]?.query || content[0].queryForEntity;

    const iconClass = newQuery
      ? allowAdvancedAdding
        ? "fa-plus-circle"
        : "fa-search"
      : "fa-spinner fa-spin";

    return (
      <div className="x-advanced-search">
        <button
          className="btn qa-advanced-search-btn"
          title={_("Advanced search")}
          disabled={!newQuery || disabled}
          onClick={this.onAdvancedSearchClick}
        >
          <i className={`fa ${iconClass}`} />
        </button>
        {newQuery && showModal && (
          <FullPageModal
            title={_("Advanced search")}
            closeButtonClick={this.onChangeMergeDefaultState(
              "showModal",
              false,
            )}
            modifyButtonClick={allowMultipleSelect && this.onModifyButtonClick}
          >
            {content.length > 1 ? (
              <Tabs key="advanced-search-tabs">
                {content.map((tabContent, index) => {
                  const { label, queryForEntity, value } = tabContent;
                  const table = tables[index];
                  return (
                    <Tab key={value} value={value} label={label}>
                      <LazyAdvancedSearchContent
                        runQuery={runQuery}
                        query={table?.query || queryForEntity}
                        table={table}
                        context={context}
                        withLinks={withLinks}
                        allowMultipleSelect={allowMultipleSelect}
                        onChange={this.onTabContentChange(index)}
                        onSelect={this.onActionSelect}
                      />
                    </Tab>
                  );
                })}
              </Tabs>
            ) : (
              <LazyAdvancedSearchContent
                runQuery={runQuery}
                query={newQuery}
                table={tables[0]}
                context={context}
                withLinks={withLinks}
                allowMultipleSelect={allowMultipleSelect}
                onChange={this.onTabContentChange()}
                onSelect={this.onActionSelect}
              />
            )}
          </FullPageModal>
        )}
      </div>
    );
  }
}
