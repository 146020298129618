import { recordsApi } from "common/api/records";
import { ValueComponent } from "common/with-value-for";
import { Properties } from "common/types/records";
import { Reload } from "common/record/types";
import { canDo } from "common/entities";
import { getRelatedRecordIds } from "../common/functions";
import { SetRecordAs } from "../common/set-record-as";
import { PropTypes as TablePropTypes } from "../table-with-form/types";
import { StandardRelated } from "../standard-related";
import { RelatedValue } from "../types";
import { updateContext, updateEntity, updateDefaultForm } from "./functions";

interface PropTypes extends TablePropTypes {
  reload: Reload;
}

export class ApprovalCostCenter extends ValueComponent<
  RelatedValue,
  PropTypes
> {
  static readonly displayName = "ApprovalCostCenter";

  displaySetDefaultCostCenter = (properties: Properties, close: () => void) => {
    const { reload } = this.props;
    const recordTitle = properties?.costCenterId?.title;
    return (
      <SetRecordAs
        message={_("Set as the default cost center number?")}
        recordId={properties.id}
        onCancel={close}
        onSet={this.onSetDefaultCostCenter(recordTitle)}
        onComplete={reload}
      />
    );
  };

  isDefaultCostCenter = (properties: Properties) =>
    !!properties.isDefaultCostCenter;

  onSetDefaultCostCenter = (recordTitle: string) => (id: string) => {
    const { context, entity } = this.props;
    return recordsApi(context.apiCall).setDefaultCostCenter(
      entity.name,
      id,
      recordTitle,
    );
  };

  render() {
    const { context, entity, value, defaultForm } = this.props;

    const ids = getRelatedRecordIds(entity.name, "costCenterId", value);

    return (
      <StandardRelated
        {...this.props}
        context={updateContext(context, entity, ids)}
        entity={updateEntity(entity)}
        defaultForm={updateDefaultForm(defaultForm, value, entity)}
        actionsWithContent={
          canDo(entity, "SetAsDefault")
            ? [
                {
                  name: "SetDefault",
                  fn: this.displaySetDefaultCostCenter,
                  isDisabled: this.isDefaultCostCenter,
                },
              ]
            : undefined
        }
      />
    );
  }
}
