import * as R from "ramda";
import { defaultFor } from "common";
import { queryBuilderBlackList } from "common/entities/entity-column/data-type/types";
import { merge1 } from "common/merge";
import { ColumnDefinition } from "common/query/advanced-types";
import { getColumnDefinitions } from "common/query/common";
// eslint-disable-next-line import/no-cycle
import { ReorderTable } from "common/query/table/reorder-table";
// eslint-disable-next-line import/no-cycle
import { PropTypes, Table } from "common/query/table/table";
import { TableConfig, TableValue } from "common/query/table/types";
import { getOrder } from "common/record/form/content/procedures/functions";
import { ValueComponent } from "common/with-value-for";

interface State {
  reorder: boolean;
}
const defaultValue = defaultFor<TableValue>();
const defaultConfig = defaultFor<TableConfig>();

/** A table that can be toggled between two modes:
 * - a regular table
 * - a table that allows reordering rows (used for Tasks)
 */
export class TableWithReorder extends ValueComponent<
  TableValue,
  PropTypes,
  State
> {
  static readonly displayName = "TableWithReorder";
  state = { reorder: false };

  getColumnDefinitions = (): [ColumnDefinition[], ColumnDefinition[]] => {
    const {
      value = defaultValue,
      context,
      config = defaultConfig,
    } = this.props;
    const ignore = R.concat(
      queryBuilderBlackList as string[],
      config.ignore || [],
    );

    const columnDefinitions = getColumnDefinitions(
      context.entities,
      value.query,
    );
    return R.partition<ColumnDefinition>(
      (c) => !c.column || !R.includes(c.column.name, ignore),
      columnDefinitions,
    );
  };

  render() {
    const {
      context,
      config = defaultConfig,
      widgetsMapper,
      isRecordDisabled,
      value = defaultValue,
    } = this.props;
    const { reorder } = this.state;
    const { query, data = [] } = value;
    const entity = context.entities[query && query.entity];

    const [columnDefinitions, ignoredColumns] = this.getColumnDefinitions();
    const isReorderable = entity?.reorderable;

    const tableValue = R.mergeRight(value, { columnDefinitions });
    const orderedValue = merge1("data", R.sortBy(getOrder, data), tableValue);

    return !isReorderable || !reorder ? (
      <Table
        {...this.props}
        config={
          isReorderable ? R.mergeRight(config, { allowOrder: false }) : config
        }
        toggleOrder={
          isReorderable
            ? this.onChangeMergeDefaultState("reorder", !reorder)
            : undefined
        }
        columnDefinitions={columnDefinitions}
        ignoredColumnDefinitions={ignoredColumns}
        widgetsMapper={widgetsMapper}
        isRecordDisabled={isRecordDisabled}
        value={isReorderable ? orderedValue : tableValue}
      />
    ) : (
      <ReorderTable
        {...this.props}
        widgetsMapper={widgetsMapper}
        toggleOrder={this.onChangeMergeDefaultState("reorder", !reorder)}
        columnDefinitions={columnDefinitions}
        value={orderedValue}
      />
    );
  }
}
