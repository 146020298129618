import { ComponentType } from "react";
import { ApiCall } from "common/types/api";
import { Component } from "common/component";
import { getApiCallResolvingData } from "common/api";
import { apiCallFullWithLoginRedirect } from "common/api/impl";

const apiCallResolvingData = getApiCallResolvingData(
  apiCallFullWithLoginRedirect,
);

interface WithApiCall {
  apiCall: ApiCall;
}

type WithApiCallOutput<PropTypes> = PropTypes & WithApiCall;

export function withApiCall<PropTypes>(
  YourComponent: ComponentType<WithApiCallOutput<PropTypes>>,
) {
  return class WithApiCallComp extends Component<PropTypes> {
    static readonly displayName = "WithApiCallComp";
    render() {
      return <YourComponent {...this.props} apiCall={apiCallResolvingData} />;
    }
  };
}
