import { ModifyLinkButtonSmall, ModifyButtonSmall } from "common/ui/buttons";
import { DropdownMenu } from "common/widgets/dropdown-menu";
import { Link } from "common/widgets/link";

interface PropTypes {
  newPath: string;
  permissionCategory: string;
}

const entitiesOptions: string[] = ["entity", "reference"];

const csvOptions: string[] = ["create", "update"];

const translateOption = (option: string) => {
  switch (option) {
    case "entity":
      return _("Entity");
    case "reference":
      return _("Lookup Table (Reference)");
    case "create":
      return _("Create");
    case "update":
      return _("Update");
    default:
      return "";
  }
};

const buttonNew = (newPath: string, options: string[]) => (
  <DropdownMenu
    button={
      <ModifyButtonSmall className="qa-btn-new-form">
        {_("New")} <i className="fa fa-caret-down" />
      </ModifyButtonSmall>
    }
    menu={options.map((option, i) => (
      <Link
        key={i}
        className={`dropdown-item qa-btn-new-${option}`}
        href={`/${newPath}?${option}=true`}
      >
        {translateOption(option)}
      </Link>
    ))}
  />
);

export const NewButton = ({ newPath, permissionCategory }: PropTypes) => {
  switch (permissionCategory) {
    case "Entities":
      return buttonNew(newPath, entitiesOptions);
    case "Import":
      return buttonNew(newPath, csvOptions);
    default:
      return (
        <ModifyLinkButtonSmall className="qa-btn-new-form" href={newPath}>
          {_("New")}
        </ModifyLinkButtonSmall>
      );
  }
};
