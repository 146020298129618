import { Component } from "react";
import { partReorderApi } from "common/api/part-reorder";
import { getTimestamp } from "common/date-time/common";
import { FormSelector } from "common/form/form-selector";
import { Action } from "common/record/actions/action";
import { DismissAction } from "common/record/actions/types";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { getEntityByBehavior } from "common/entities";
import { Entity } from "common/entities/types";
import { Record, Properties } from "common/types/records";
import { Form } from "common/types/forms";
import { PartReorderRecord } from "common/types/part-reorder";
import { ApiError } from "common/ui/api-error";
import { ActionButtonLarge, ModifyButtonLarge } from "common/ui/buttons";
import { VerticalField } from "common/ui/field";
import { arrayToString } from "common/utils/array";
import { hasProtectedColumns } from "common/record/utils";
import { setLocationHref } from "common/utils/window-location";
import { AlertInfo } from "common/widgets/alert";
import { LoadingIcon } from "common/widgets/loading-icon";
import { filterFormsByEntity } from "common/functions/forms";
import { Required } from "common/widgets/required";
import { toPreviewPayload, toPurchaseOrderPayload } from "../functions";
// eslint-disable-next-line import/no-cycle
import { PurchaseOrders } from "./form";
import { PurchaseOrderItem } from "./types";

interface PurchaseOrderPropTypes {
  context: Context;
  reorderRecords: Properties[];
  dismiss: DismissAction;
  entity: Entity;
  records: Record[];
}

interface StateTypes {
  purchaseOrderItems: PurchaseOrderItem[];
  isPreviewScreen: boolean;
  loading: boolean;
  error: ApiErrorResponse;
  purchaseOrderForms: Form[];
  formId: number;
}

export class CreatePurchaseOrderFromReorderList extends Component<
  PurchaseOrderPropTypes,
  StateTypes
> {
  static readonly displayName = "PurchaseOrderController";
  private seed = getTimestamp();
  private poIdMap: { [supplierId: string]: string } = {};

  constructor(props: PurchaseOrderPropTypes) {
    super(props);
    this.state = {
      purchaseOrderItems: props.reorderRecords.map(this.toPoItem),
      formId: undefined,
      purchaseOrderForms: [],
      isPreviewScreen: false,
      loading: false,
      error: undefined,
    };
  }

  componentDidMount() {
    const { context } = this.props;

    const purchaseOrderForms = filterFormsByEntity(
      context.forms,
      getEntityByBehavior("PurchaseOrder", context.entities).name,
    );
    const formId =
      purchaseOrderForms?.length === 1 ? purchaseOrderForms[0].id : undefined;
    this.setState({ purchaseOrderForms, formId });
  }

  getPoId = (supplierId: string) =>
    this.poIdMap[supplierId] || (this.poIdMap[supplierId] = `PO${++this.seed}`);

  toPoItem = (record: PartReorderRecord): PurchaseOrderItem => ({
    ...record,
    supplierId: record.supplierId,
    purchaseOrderNumber: this.getPoId(record.supplierId?.id),
  });

  onPoItemsChange = (poItems: PurchaseOrderItem[]) => {
    this.setState({
      purchaseOrderItems: poItems.map((item) =>
        item.purchaseOrderNumber
          ? item
          : { ...item, purchaseOrderNumber: this.getPoId(item.supplierId.id) },
      ),
    });
  };

  onFormSelectorChange = (formId: number) => this.setState({ formId });

  formIsValid = () => {
    const { purchaseOrderForms, formId, purchaseOrderItems } = this.state;
    const isPurchaseOrderFormSelectionValid =
      !purchaseOrderForms?.length || !!formId;
    return (
      isPurchaseOrderFormSelectionValid &&
      purchaseOrderItems.every(
        (item) =>
          item.reorderQuantity > 0 && item.unitCost > 0 && item.supplierId,
      )
    );
  };

  goToPurchaseOrders = (purchaseOrdersIds: string[]) => {
    const {
      context: { site, entities },
    } = this.props;

    return setLocationHref(
      arrayToString(
        [
          `#/${site.name}/${getEntityByBehavior("PurchaseOrder", entities).name}`,
          purchaseOrdersIds.length === 1 ? `/${purchaseOrdersIds[0]}` : "",
        ],
        "",
      ),
    );
  };

  onCancel = () => this.props.dismiss(true);

  onGoBack = () => this.setState({ isPreviewScreen: false });

  createPo = () => {
    const { context, dismiss } = this.props;
    const { purchaseOrderItems, formId } = this.state;
    const payload = toPurchaseOrderPayload(purchaseOrderItems, formId);

    this.setState({ loading: true, error: undefined });

    partReorderApi(context)
      .createPurchaseOrders(payload)
      .then((purchaseOrdersIds) => {
        dismiss(false);
        this.goToPurchaseOrders(purchaseOrdersIds);
      })
      .catch((error) => this.setState({ error, loading: false }));
  };

  preview = () => {
    const { purchaseOrderItems } = this.state;
    const { context } = this.props;
    const previewPayload = toPreviewPayload(purchaseOrderItems);

    this.setState({ loading: true, error: undefined });

    partReorderApi(context)
      .preview(previewPayload)
      .then((previews) => {
        const updatedPurchaseOrderItems: PurchaseOrderItem[] =
          purchaseOrderItems.map((poItem) => {
            const { totalCost, conversionRate } = previews.find(
              (preview) =>
                preview.partId === poItem.partId.id &&
                preview.partLocationId === poItem.locationId.id,
            );
            return { ...poItem, totalCost, conversionRate };
          });

        this.setState({
          purchaseOrderItems: updatedPurchaseOrderItems,
          loading: false,
          isPreviewScreen: true,
        });
      })
      .catch((error) => this.setState({ error, loading: false }));
  };

  render() {
    const { context, dismiss, entity, records = [] } = this.props;
    const {
      purchaseOrderItems,
      isPreviewScreen,
      error,
      loading,
      purchaseOrderForms,
      formId,
    } = this.state;

    return (
      <Action
        requiresAuthentication={hasProtectedColumns(entity)}
        context={context}
        dismiss={dismiss}
        entity={entity}
        records={records}
        title={_("Create Purchase Order")}
        btnLabel={_("Create Purchase Order")}
        hideEntityInTitle={true}
        hideRecordNumber={true}
        hideFooter={true}
        onOk={undefined}
        noPadding={true}
        size="large"
      >
        <div className="x-po-controller x-record-layout">
          <div className="x-po-controller-content">
            {loading ? <LoadingIcon /> : undefined}
            {error ? (
              <ApiError className="qa-create-po-error" error={error} />
            ) : undefined}
            {purchaseOrderForms?.length ? (
              <div className="x-form-selector-container">
                <VerticalField
                  className={"x-form-selector qa-form-selector-label"}
                  label={_("Purchase Order Form")}
                  input={
                    <Required value={formId}>
                      <FormSelector
                        className="qa-form-selector"
                        preselectFirstByDefault={false}
                        disabled={isPreviewScreen}
                        forms={purchaseOrderForms}
                        value={formId}
                        onChange={this.onFormSelectorChange}
                      />
                    </Required>
                  }
                />
              </div>
            ) : undefined}
            <PurchaseOrders
              context={context}
              isPreviewScreen={isPreviewScreen}
              value={purchaseOrderItems}
              onChange={this.onPoItemsChange}
            />
            {!isPreviewScreen ? (
              <AlertInfo
                className="x-margin-bottom-20-i qa-total-cost-info"
                message={_("Total cost will be calculated on preview screen")}
              />
            ) : undefined}
          </div>

          <div className="x-po-controller-footer">
            <div className="x-po-controller-footer-content x-flex x-flex-end-center">
              <ActionButtonLarge
                className="qa-cancel-button"
                onClick={this.onCancel}
              >
                {_("Cancel")}
              </ActionButtonLarge>

              {isPreviewScreen ? (
                <>
                  <ActionButtonLarge
                    className="qa-back-button"
                    onClick={this.onGoBack}
                  >
                    {_("Back")}
                  </ActionButtonLarge>

                  <ModifyButtonLarge
                    className="qa-create-po"
                    onClick={this.createPo}
                  >
                    {_("Create Purchase Order")}
                  </ModifyButtonLarge>
                </>
              ) : (
                <ModifyButtonLarge
                  className="qa-preview"
                  disabled={!this.formIsValid()}
                  onClick={this.preview}
                >
                  {_("Continue")}
                </ModifyButtonLarge>
              )}
            </div>
          </div>
        </div>
      </Action>
    );
  }
}
