import * as R from "ramda";
import { JSX } from "react";
import { ChartConfig } from "common/data/chart/types";
import {
  BoxConfig,
  CardsConfig,
  Config,
  MapConfig,
  Output,
  TableConfigWithMerge,
} from "common/data/types";
import { SelectItem } from "common/query/types";
import { Context } from "common/types/context";
import { withValue, WithValue } from "common/with-value";
import { OutputConfigBox } from "./box";
import { OutputConfigCards } from "./cards";
import { OutputConfigChart } from "./chart";
import { OutputConfigMap } from "./map";
import { OutputConfigTable } from "./table";

interface PropTypes {
  context: Context;
  entity: string;
  select: SelectItem[];
  showMergeOption: boolean;
}

type Props = PropTypes & WithValue<Output>;
type ConfigCtor = (props: Props) => JSX.Element;

interface ConfigMap {
  [index: string]: ConfigCtor;
}

interface DefaultMap {
  [index: string]: Config;
}

export const defaultMap: DefaultMap = {
  Box: {
    mergeSites: false,
    showMergeOption: false,
    label: undefined,
    value: undefined,
    isCurrency: false,
  },
  Map: {
    mergeSites: false,
    showMergeOption: false,
    defaultPin: undefined,
    selectedPin: undefined,
    showAll: false,
    mapEntity: undefined,
    mapId: undefined,
  },
  Cards: {
    mergeSites: false,
    showMergeOption: false,
    title: undefined,
    actions: [],
  },
  Chart: {
    type: "Pie",
    values: undefined,
    labels: undefined,
  },
  Table: {
    mergeSites: false,
    showMergeOption: false,
    ignore: [],
  },
};

const configMap: ConfigMap = {
  Box: ({ select, value, onChangeMergeValue, showMergeOption }) => (
    <OutputConfigBox
      select={select}
      showMergeOption={showMergeOption}
      value={value.config as BoxConfig}
      onChange={onChangeMergeValue("config")}
      showLabel={true}
    />
  ),
  Table: ({ value, onChangeMergeValue, showMergeOption }) => (
    <OutputConfigTable
      showMergeOption={showMergeOption}
      value={value.config as TableConfigWithMerge}
      onChange={onChangeMergeValue("config")}
    />
  ),
  Cards: ({
    context,
    entity,
    select,
    value,
    onChangeMergeValue,
    showMergeOption,
  }) => (
    <OutputConfigCards
      entities={context.entities}
      entity={entity}
      select={select}
      showMergeOption={showMergeOption}
      value={value.config as CardsConfig}
      onChange={onChangeMergeValue("config")}
    />
  ),
  Map: ({ context, value, onChangeMergeValue }) => (
    <OutputConfigMap
      context={context}
      value={value.config as MapConfig}
      onChange={onChangeMergeValue("config")}
    />
  ),
  Chart: ({ select, value, onChangeMergeValue }) => (
    <OutputConfigChart
      select={select}
      value={value.config as ChartConfig}
      onChange={onChangeMergeValue("config")}
    />
  ),
};
export const outputTypes = R.keys(configMap);
const invalid = () => <span />;

const OutputDynamicComp = (props: PropTypes & WithValue<Output>) =>
  (configMap[props.value && props.value.type] || invalid)(props);

export const OutputDynamic = withValue<Output, PropTypes>(
  OutputDynamicComp,
  "OutputDynamic",
);
