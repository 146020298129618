import { EntityColumn } from "common/entities/entity-column/types";
import { useExpandedRecord } from "common/record/foreign-key-expansion";
import { Context } from "common/types/context";
import { FkValue } from "common/types/foreign-key";
import { InternalFormatted } from "common/widgets/foreign-key/internal/formatted";
import { HighlightProps } from "common/widgets/label";
import { getFkRelatedEntityName } from "../functions";

interface PropTypes extends HighlightProps {
  context: Context;
  value: FkValue;
  column?: EntityColumn;
}

/**
 * Internal (do not reuse). Use the generic index.
 *
 * Fetches the references and tries to self-expand.
 * - if it's already expanded, use value because it comes translated from backend
 * otherwise:
 * - fetch and try to expand. during loading or error, show value as is (id)
 */
export const SelfExpandingReferenceFk = ({
  context,
  value,
  column,
  textToHighlight,
}: PropTypes) => {
  const expandedRecord = useExpandedRecord({
    context,
    entityName: getFkRelatedEntityName(column, value),
    record: value,
  });

  return (
    <InternalFormatted
      context={context}
      column={column}
      format="title"
      value={expandedRecord ?? value}
      textToHighlight={textToHighlight}
    />
  );
};
