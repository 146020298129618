import * as R from "ramda";
import { isSystemIntFk } from "common/functions/system-int";
import {
  getMeterType,
  getPartRequisitioningItemStatus,
  getPartRequisitioningStatus,
  getPurchaseOrderItemStatus,
  getPurchaseOrderStatus,
  translateCycleCountStatus,
  translateDomainLoginType,
  translateEntityColumnType,
  translateProcedureStatus,
  translateProcedureType,
} from "common/functions/system-string-options";
import { SystemIntFkValue } from "common/types/system-int";
import { SystemTables } from "common/types/system-table";
import { HighlightProps, Label } from "../label";

interface PropTypes extends HighlightProps {
  systemTables: SystemTables;
  tableName: string;
  value: SystemIntFkValue;
}

const findTitleInSystemTable = (
  systemTables: SystemTables,
  tableName: string,
  value: SystemIntFkValue,
) => {
  const fks = systemTables?.[tableName] || [];
  const fk = R.find((f) => f.id === value, fks);
  return fk ? fk.title : value ? `${value}` : "";
};

export const getSystemIntFkLabel = (
  systemTables: SystemTables,
  tableName: string,
  value: SystemIntFkValue,
) => {
  if (isSystemIntFk(value)) {
    // TODO remove this block after system strings come translated from api
    if (tableName === "CycleCountStatus") {
      return translateCycleCountStatus(value.title);
    }

    if (tableName === "PurchaseOrderItemStatus") {
      return getPurchaseOrderItemStatus(value.title);
    }

    if (tableName === "PurchaseOrderStatus") {
      return getPurchaseOrderStatus(value.title);
    }

    if (tableName === "PartRequisitioningStatus") {
      return getPartRequisitioningStatus(value.title);
    }

    if (tableName === "PartRequisitioningItemStatus") {
      return getPartRequisitioningItemStatus(value.title);
    }

    if (tableName === "ProcedureType") {
      return translateProcedureType(value.title);
    }
    if (tableName === "MeterType") {
      return getMeterType(value.title);
    }

    if (tableName === "ProcedureStatus") {
      return translateProcedureStatus(value.title);
    }

    if (tableName === "DomainLoginType") {
      return translateDomainLoginType(value.title);
    }

    return value.title;
  }

  const title = findTitleInSystemTable(systemTables, tableName, value);

  if (tableName === "EntityColumnType") {
    return translateEntityColumnType(title);
  }

  return title;
};

export const SystemIntFkLabel = ({
  systemTables,
  tableName,
  value,
  textToHighlight,
}: PropTypes) => {
  const label = getSystemIntFkLabel(systemTables, tableName, value);
  return <Label value={label} textToHighlight={textToHighlight} />;
};
SystemIntFkLabel.displayName = "SystemIntFkLabel";
