import { Group, LookupConfiguration, LookupType } from "common/form/types";
import { Context } from "common/types/context";
import { getLayoutGroupColumns } from "common/form/functions/common";
import { isDrilldownValid } from "./drilldown/functions";
import { LookupTypeWithDefault } from "./types";
import { isUserDefinedValid } from "./user-defined/functions";

export const getLookupType = (
  context: Context,
  lookupConfiguration: LookupConfiguration,
): LookupType => {
  const type = lookupConfiguration?.type;
  const typeIsEnabled =
    (type === "drilldown" && context.isEnabledFeature("drilldownLookups")) ||
    (type === "userDefined" && context.isEnabledFeature("userDefinedLookups"));

  return typeIsEnabled ? type : undefined;
};

export const isFormValid = (
  type: LookupTypeWithDefault,
  lookupConfiguration: LookupConfiguration,
): boolean =>
  type === "foreignKey" ||
  isDrilldownValid(lookupConfiguration) ||
  isUserDefinedValid(lookupConfiguration);

export const getColumnsWithLookupConfig = (groups: Group[]): string[] =>
  getLayoutGroupColumns(groups).reduce<string[]>(
    (acc, lc) => (lc.lookupConfiguration ? [...acc, lc.columnName] : acc),
    [],
  );
