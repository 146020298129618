import { includes, pickBy } from "ramda";
import { isFkToEntityWithBehavior } from "common/entities/entity-column/functions";
import { Entities, Entity } from "common/entities/types";
import { redirect } from "common/record/actions/functions/url";
import { DismissAction, PropTypes } from "common/record/actions/types";
import { Create } from "common/record/actions/ui/create";
import { ApiCall } from "common/types/api";
import { CancellablePromise } from "common/types/promises";
import { Properties } from "common/types/records";
import { getFkFromProperties } from "common/utils/foreign-key";
import { withValue, WithValue } from "common/with-value";

const filterOutUserFks = (
  properties: Properties,
  entity: Entity,
  entities: Entities,
): Properties => {
  // users dont want pre-populated contacts in follow ups
  const userFkCols = entity.columns.reduce(
    (acc: string[], c) =>
      isFkToEntityWithBehavior("User", entities)(c) ? [...acc, c.name] : acc,
    [],
  );

  return pickBy(
    (_: Properties, key: string) => !includes(key, userFkCols),
    properties,
  );
};

export interface FollowUpValue {
  formId: number;
}

type Props = PropTypes & WithValue<FollowUpValue>;

export const CreateFollowUp = withValue<FollowUpValue, PropTypes>(
  (props: Props) => {
    const {
      entity,
      records = [],
      context,
      value,
      extraProperties,
      onPerform,
      onChangeSetValue,
      goTo,
    } = props;
    const properties = records[0]?.properties;

    const onOk = (_: ApiCall, dismiss: DismissAction) => {
      onPerform?.();
      redirect(
        context,
        filterOutUserFks(properties, entity, context.entities),
        value?.formId,
        entity,
        {
          parentId: getFkFromProperties(entity, properties),
          ...extraProperties,
        },
        goTo,
      );
      return CancellablePromise.resolve(dismiss);
    };

    const actionProps = {
      hideRecordNumber: true,
      title: _("Create Follow-up"),
      onOk,
    };

    return (
      <Create
        {...props}
        actionProps={actionProps}
        value={value}
        onChange={onChangeSetValue}
      />
    );
  },
  "CreateFollowUp",
);
