import { MouseEvent } from "react";
import { getTooltipProps } from "common/vendor-wrappers/react-tooltip";

import "./index.scss";

interface Props {
  isSent: boolean;
  onSendClick: (e: MouseEvent) => void;
}

export const RecoverLink = ({ onSendClick, isSent }: Props) => {
  return (
    <div className="x-forgot-password x-password-wrapper">
      {isSent ? (
        <>
          <span>{_("Email successfully sent")}</span>
          <i className={`fa fa-check-circle x-icon`} />
        </>
      ) : (
        <a
          href="#"
          onClick={onSendClick}
          className="qa-forgot-password"
          {...getTooltipProps(
            _(
              "An email with password reset link will be sent to your registered email",
            ),
            "info",
            { place: "bottom-start" },
          )}
        >
          {_("Forgot password?")}
        </a>
      )}
    </div>
  );
};
