import * as R from "ramda";
import { ChangeEvent } from "react";
import { ApiCall } from "common/types/api";
import { defaultFor } from "common";
import { cultureApi } from "common/api/cultures";
import { validateCaptions } from "common/culture/functions";
import { Culture, SupportedCulture } from "common/culture/supported-cultures";
import { Captions, TranslateKey } from "common/culture/types";
import { ActionButtonSmall } from "common/ui/buttons";
import { classNames } from "common/utils/jsx";
import { ValueComponent } from "common/with-value-for";
import { getUpdatedKeys } from "./functions";

interface StateType {
  translating?: boolean;
  error?: any;
}

interface PropTypes {
  apiCall: ApiCall;
  culture: SupportedCulture;
  allCultures: boolean;
  sourceLabels: Captions;
  uiCulture: Culture;
  keys: string[];
  translateKey: TranslateKey;
  isRequired?: boolean;
}

export class Language extends ValueComponent<Captions, PropTypes, StateType> {
  static readonly displayName = "Language";
  state: StateType = {};

  onChangeCache: {
    [index: string]: (e: ChangeEvent<HTMLInputElement>) => void;
  } = {};

  onChange = (captionKey: string) => {
    const { value } = this.props;
    return (this.onChangeCache[captionKey] = (e) =>
      e?.target?.value
        ? this.mergeValue({ [captionKey]: e?.target?.value })
        : this.setValue(R.omit([captionKey], value)));
  };

  onTranslate = () => {
    const {
      apiCall,
      culture,
      sourceLabels,
      uiCulture,
      keys,
      translateKey,
      allCultures,
      value,
    } = this.props;

    const keysToTranslate = R.difference(
      keys,
      R.keys(value).filter((key) => !value[key]) as Culture[],
    );

    const updatedKeys = getUpdatedKeys(
      keysToTranslate,
      allCultures,
      sourceLabels,
      translateKey,
    );

    const labelsToTranslate = R.zipObj(keysToTranslate, updatedKeys);

    if (R.keys(labelsToTranslate).length) {
      this.setState({ translating: true });
      cultureApi(apiCall)
        .translateLanguage(labelsToTranslate, uiCulture, culture.name)
        .then((translations) => {
          this.setValue(R.mergeRight(value, translations));
          this.setState({ translating: false });
        })
        .catch((error) => this.setState({ error, translating: false }));
    }
  };

  render() {
    const {
      culture,
      keys,
      uiCulture,
      value = defaultFor<Captions>(),
      isRequired,
    } = this.props;
    const { translating } = this.state;

    const allTranslated = validateCaptions(keys, value);

    const languageInputs = keys.map((captionKey, i) => (
      <dd
        key={i}
        className={classNames([
          "x-entry x-labels-value",
          isRequired && !value[captionKey] ? "x-has-error" : undefined,
          `qa-lang-${culture.name.toLowerCase()}`,
        ])}
      >
        <input
          value={value[captionKey] || ""}
          disabled={translating}
          data-caption={captionKey}
          onChange={this.onChange(captionKey)}
        />
      </dd>
    ));

    return (
      <dl className="col-sm-4 x-language x-language-target">
        <dt className="x-title qa-title">
          <div className="x-ellipsis qa-language-name">{culture.language}</div>
          {culture.name !== uiCulture && !allTranslated ? (
            <ActionButtonSmall
              className="qa-translate-culture x-float-right"
              disabled={translating}
              onClick={this.onTranslate}
            >
              {translating ? (
                <div className="x-loading-icon-wrap">
                  <i className="fa fa-spinner fa-spin x-loading-icon" />
                  <span>{_("Translating")}</span>
                </div>
              ) : (
                <div className="x-loading-icon-wrap">
                  <i className="fa fa-language" />
                  <span>{_("Translate")}</span>
                </div>
              )}
            </ActionButtonSmall>
          ) : undefined}
        </dt>
        {languageInputs}
      </dl>
    );
  }
}
