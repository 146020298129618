import { add, setISODay, sub } from "date-fns";
import * as R from "ramda";
import { getStartOf } from "common/date-time/calculators";
import { parseToSited, sitedToUtcISO } from "common/date-time/internal";
import { DurationUnit, TimeUnit, UTCDateTime } from "common/date-time/types";
import { WeekdayNumber, Weekdays } from "common/date-time/weekday";

interface TimeUnitToDuration {
  [timeUnit: string]: DurationUnit;
}

interface DynamicValues {
  [name: string]: (now: UTCDateTime) => UTCDateTime;
}

const timeUnitToDuration: TimeUnitToDuration = {
  day: "days",
  month: "months",
  year: "years",
};

const same = (date: Date) => date;

const getSitedStartOf = (now: UTCDateTime, timeUnit: TimeUnit) => {
  const startOfTimeUnit = getStartOf(now, timeUnit);
  return startOfTimeUnit ? parseToSited(startOfTimeUnit) : undefined;
};

const getStartOfUnit =
  (which: "last" | "this" | "next", timeUnit: TimeUnit) =>
  (now: UTCDateTime) => {
    const whichFn = which === "last" ? sub : which === "next" ? add : same;
    const startOf = getSitedStartOf(now, timeUnit);
    return startOf
      ? sitedToUtcISO(whichFn(startOf, { [timeUnitToDuration[timeUnit]]: 1 }))
      : undefined;
  };

const getLastDayOfWeekFromToday = (today: Date, dayOfWeek: WeekdayNumber) => {
  const day = setISODay(today, dayOfWeek);
  return day >= today ? sub(day, { days: 7 }) : day;
};

const getNextDayOfWeekFromToday = (today: Date, dayOfWeek: WeekdayNumber) => {
  const day = setISODay(today, dayOfWeek);
  return day >= today ? day : add(day, { days: 7 });
};

const getLastWeekday = (dayOfWeek: WeekdayNumber) => (now: UTCDateTime) => {
  const today = getSitedStartOf(now, "day");
  return today
    ? sitedToUtcISO(getLastDayOfWeekFromToday(today, dayOfWeek))
    : undefined;
};

const getNextWeekday = (dayOfWeek: WeekdayNumber) => (now: UTCDateTime) => {
  const today = getSitedStartOf(now, "day");
  return today
    ? sitedToUtcISO(getNextDayOfWeekFromToday(today, dayOfWeek))
    : undefined;
};

const dynamicValues: DynamicValues = {
  "{now}": R.identity,
  "{lastSunday}": getLastWeekday(Weekdays.Sunday),
  "{nextSunday}": getNextWeekday(Weekdays.Sunday),
  "{lastMonday}": getLastWeekday(Weekdays.Monday),
  "{nextMonday}": getNextWeekday(Weekdays.Monday),
  "{lastTuesday}": getLastWeekday(Weekdays.Tuesday),
  "{nextTuesday}": getNextWeekday(Weekdays.Tuesday),
  "{lastWednesday}": getLastWeekday(Weekdays.Wednesday),
  "{nextWednesday}": getNextWeekday(Weekdays.Wednesday),
  "{lastThursday}": getLastWeekday(Weekdays.Thursday),
  "{nextThursday}": getNextWeekday(Weekdays.Thursday),
  "{lastFriday}": getLastWeekday(Weekdays.Friday),
  "{nextFriday}": getNextWeekday(Weekdays.Friday),
  "{lastSaturday}": getLastWeekday(Weekdays.Saturday),
  "{nextSaturday}": getNextWeekday(Weekdays.Saturday),
  "{yesterday}": getStartOfUnit("last", "day"),
  "{today}": getStartOfUnit("this", "day"),
  "{tomorrow}": getStartOfUnit("next", "day"),
  "{lastMonth}": getStartOfUnit("last", "month"),
  "{thisMonth}": getStartOfUnit("this", "month"),
  "{nextMonth}": getStartOfUnit("next", "month"),
  "{lastYear}": getStartOfUnit("last", "year"),
  "{thisYear}": getStartOfUnit("this", "year"),
  "{nextYear}": getStartOfUnit("next", "year"),
};

export const resolveDateDynamicValue = (name: string, now: UTCDateTime) =>
  dynamicValues[name]?.(now);
