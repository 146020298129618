import { defaultFor } from "common";
import { Entity } from "common/entities/types";
import { FormValidationProps, Layout } from "common/form/types";
import { hasPermissionToRead } from "common/functions/roles";
import { createUniqueAssetsQuery } from "common/query/behaviors/assets";
import { AuditTrailController } from "common/record/form/content/audit-trail";
import { PmFormValue } from "common/scheduled-event/form/pm/types";
import { Context } from "common/types/context";
import { Tab } from "common/widgets/tabs/tab";
import { Tabs } from "common/widgets/tabs/tabs";
import { withValue, WithValue } from "common/with-value";
import {
  pmDetailIsValid,
  pmTriggerIsValid,
  validateWorkOrderTemplate,
} from "../../validations";
import { WorkOrderTemplate } from "../entity-form";
import { Details } from "./details";
import { TriggersForm } from "./triggers";

interface PropTypes extends FormValidationProps {
  context: Context;
  entity: Entity;
  assetEntity: Entity;
  woEntity: Entity;
  layout: Layout;
  isNew: boolean;
  meterEntity: Entity;
  auditTrailId: string;
}

const defaultValue = defaultFor<PmFormValue>();

type Props = PropTypes & WithValue<PmFormValue>;

export const PmForm = withValue<PmFormValue, PropTypes>(
  ({
    context,
    entity,
    assetEntity,
    woEntity,
    layout,
    isNew,
    meterEntity,
    auditTrailId,
    formValidation,
    onFormValidationChange,
    value = defaultValue,
    onChangeSetValue,
    onChangeMergeValue,
  }: Props) => {
    const { entities, role, userTypes } = context;
    const { record } = value;
    const defaultTab = auditTrailId && !isNew ? "AuditTrail" : undefined;

    const scheduledWOAssetEntity =
      context.entities[entity.arguments.eventAssetEntity];
    const assetsQuery = createUniqueAssetsQuery(
      assetEntity,
      scheduledWOAssetEntity,
      record,
      undefined,
    );

    return (
      <Tabs
        key="pm-tabs"
        className="x-pm-trigger form-horizontal"
        defaultValue={defaultTab}
      >
        <Tab
          className="qa-pm-tab-triggers"
          value="Triggers"
          label={_("Triggers")}
          warningIcon={!pmTriggerIsValid(entities, entity, record)}
        >
          <TriggersForm
            context={context}
            entity={entity}
            assetEntity={assetEntity}
            meterEntity={meterEntity}
            value={record}
            query={assetsQuery}
            onChange={onChangeMergeValue("record")}
          />
        </Tab>

        <Tab
          className="qa-pm-tab-details"
          value="Details"
          label={_("Details")}
          warningIcon={!pmDetailIsValid(entity, value)}
        >
          <Details
            context={context}
            entity={entity}
            woEntity={woEntity}
            formValidation={formValidation}
            onFormValidationChange={onFormValidationChange}
            value={value}
            onChange={onChangeSetValue}
          />
        </Tab>

        <Tab
          className="qa-pm-tab-template"
          value="WorkOrderTemplate"
          label={_("Work Order Template")}
          warningIcon={
            !validateWorkOrderTemplate(context, entity, record, layout)
          }
        >
          <WorkOrderTemplate
            context={context}
            entity={entity}
            record={record}
            woEntity={woEntity}
            layout={layout}
            isNew={isNew}
            formValidation={formValidation}
            onFormValidationChange={onFormValidationChange}
            value={value}
            onChange={onChangeSetValue}
          />
        </Tab>

        {!isNew && hasPermissionToRead(userTypes, role, "AuditTrail") && (
          <Tab
            className="qa-pm-tab-audit-trail"
            value="AuditTrail"
            label={_("Audit Trail")}
          >
            <AuditTrailController
              context={context}
              entity={entity}
              record={record}
              auditTrailId={auditTrailId}
            />
          </Tab>
        )}
      </Tabs>
    );
  },
  "PmForm",
);
