import * as R from "ramda";
import { JSX } from "react";
import { Node, NodeLinkPropTypes } from "common/widgets/tree/types";
import { Tree } from "common/widgets/tree";

interface PropTypes {
  title: string;
  tree: Node[];
  displayNode: (props: NodeLinkPropTypes) => JSX.Element;
  onClick: (node: Node) => any;
  selected?: Node;
  sort?: boolean;
  expandedByDefault?: boolean;
}

export const TreeGrouping = ({
  title,
  tree,
  displayNode,
  sort,
  selected,
  expandedByDefault,
  onClick,
}: PropTypes) => {
  return (
    <div className="x-tree-group">
      {title && <h2>{title}</h2>}
      {tree.map((root, index) => {
        const newRoot =
          sort && root.children
            ? R.mergeRight(root, {
                children: R.sortBy((c) => !c.isGroup, root.children),
              })
            : root;
        return (
          <Tree
            nodes={[newRoot]}
            onDisplay={displayNode}
            key={index}
            selected={selected ? [selected] : undefined}
            expandedByDefault={expandedByDefault}
            onClick={onClick}
          />
        );
      })}
    </div>
  );
};

TreeGrouping.displayName = "TreeGrouping";
