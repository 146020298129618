import {
  imageExtensions,
  inlineAudioExtensions,
  inlineVideoExtensions,
  hasMatchingExtension,
} from "common/record/form/content/media/functions";

const regex = {
  internalUploadedFile: /^\/api\/(?:entities|workflows)\/(.*)\/blobs\/(.*)/i,
  youTube: /^(http(s)?:\/\/)?((www|m).)?youtu(be|.be)(\.com)?\/.+/,
  vimeo: /^(http(s)?:\/\/)?(www\.)?(player\.)?(vimeo.com)\/.+/,
  soundCloud:
    /^http(s)?:\/\/?(?:(?:www|m)\.)?(soundcloud\.com|snd\.sc)\/(.*)$/i,
  mixCloud: /^http(s)?:\/\/(www\.)?mixcloud.com(.*)/i,
};

const webLinkVideoExtensions = [...inlineVideoExtensions, ".mpd", ".m3u8"];

export const isInternalSourceMedia = (url: string) =>
  regex.internalUploadedFile.test(url);

export const isYouTube = (url: string) => regex.youTube.test(url);
export const isMixCloud = (url: string) => regex.mixCloud.test(url);
export const isSoundCloud = (url: string) => regex.soundCloud.test(url);
const isVimeo = (url: string) => regex.vimeo.test(url);

export const isSupportedMediaSource = (value: string) =>
  isYouTube(value) ||
  isVimeo(value) ||
  isMixCloud(value) ||
  isSoundCloud(value);

export const getServiceClass = (url: string) => {
  if (isYouTube(url)) return "x-youtube";
  if (isVimeo(url)) return "x-vimeo";
  if (isMixCloud(url)) return "x-mixcloud";
  if (isSoundCloud(url)) return "x-soundcloud";

  return undefined;
};

export const getFileTypeFromUrl = (url: string) => {
  if (hasMatchingExtension(url, imageExtensions)) return "image";
  if (hasMatchingExtension(url, webLinkVideoExtensions)) return "video";
  if (hasMatchingExtension(url, inlineAudioExtensions)) return "audio";

  if (isYouTube(url) || isVimeo(url)) return "video";
  if (isMixCloud(url) || isSoundCloud(url)) return "audio";

  return undefined;
};
