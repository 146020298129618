import * as R from "ramda";
import { Record } from "common/types/records";
import { behaveAs, getColumn } from "common/entities";
import {
  isAllowedTableColumn,
  isQuickInput,
  isUnique,
} from "common/entities/entity-column/functions";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entities, Entity } from "common/entities/types";
import { RelatedEntity, RelatedEntityColumn } from "common/form/types";
import { getQueryFor } from "common/record/form/content/related/part-charge/functions";
import { isSelectField, JoinItem, Query, SelectField } from "./types";

interface SelectAccumulator {
  quickActions: EntityColumn[];
  numberColumn: EntityColumn;
  unique: EntityColumn[];
  allowed: EntityColumn[];
}

export const getDefaultQuery = (entity: Entity): Query => {
  const columns = entity?.columns;
  if (!columns) return undefined;

  const { quickActions, numberColumn, unique, allowed } =
    columns.reduce<SelectAccumulator>(
      (acc, col) => {
        if (isQuickInput(col)) {
          return { ...acc, quickActions: acc.quickActions.concat(col) };
        }

        if (col.name === "number") {
          return { ...acc, numberColumn: col };
        }

        if (!col.availableForList) return acc;

        // to avoid order by unique foreign key with links
        if (isUnique(col) && !col?.isForeignKey) {
          return { ...acc, unique: acc.unique.concat(col) };
        }

        if (isAllowedTableColumn(col)) {
          return { ...acc, allowed: acc.allowed.concat(col) };
        }

        return acc;
      },
      {
        quickActions: [],
        numberColumn: undefined,
        unique: [],
        allowed: [],
      },
    );

  const uniqueWithDefault = [
    ...unique,
    ...(numberColumn && (!unique.length || numberColumn.availableForList)
      ? [numberColumn]
      : []),
  ];

  const select = [...quickActions, ...uniqueWithDefault, ...allowed].map(
    ({ name }) => ({ name }),
  );

  return {
    select,
    order: uniqueWithDefault.length
      ? [{ name: uniqueWithDefault[0].name, desc: true }]
      : undefined,
    pageSize: 25,
    page: 0,
  };
};

const applyBehaviorToQuery = (
  entities: Entities,
  entity: Entity,
  records: Record[] = [],
  query: Query,
): Query => {
  if (behaveAs("PartCharge", entity)) {
    return getQueryFor(entities, entity, query, records);
  }

  if (behaveAs("Order", entity)) {
    return {
      ...query,
      select: query.select.filter(isSelectField),
    };
  }

  return query;
};

const queryWithRelated = (
  query: Query,
  entity: Entity,
  relatedEntity: RelatedEntity,
  includeAllJoins: boolean,
): Query => {
  const selected = relatedEntity.columns.map(
    (c: RelatedEntityColumn): SelectField => {
      if (c.columnName.indexOf(".") !== -1) {
        const [path, name] = c.columnName.split(".");
        return {
          path,
          name,
          alias: c.columnName.substring(1),
        };
      }
      return { name: c.columnName };
    },
  );
  const uniqueColumns = relatedEntity.columns.filter((c) =>
    isUnique(getColumn(entity, c.columnName)),
  );
  const numberColumn = R.find(
    (c) => c.columnName === "number",
    relatedEntity.columns,
  );
  const orderBy = (
    numberColumn ? [...uniqueColumns, numberColumn] : uniqueColumns
  )[0]?.columnName;

  const selectEntityNames = selected
    .filter((s) => s.path)
    .map((s) => s.path.substring(1));

  const filteredJoins: JoinItem[] = R.uniq(
    entity.joins
      .filter(
        (join) =>
          !join.owner &&
          join.outbound &&
          (includeAllJoins || R.includes(join.column, selectEntityNames)),
      )
      .map((j) => ({ column: j.column, type: "LEFT" })),
  );

  const addSelectToQuery: Query = {
    ...query,
    select: selected,
    order: orderBy && [{ name: orderBy, desc: true }],
  };

  return filteredJoins.length
    ? { ...addSelectToQuery, joins: filteredJoins }
    : addSelectToQuery;
};

export const getRelatedQuery = (
  entity: Entity,
  entities: Entities,
  relatedEntity: RelatedEntity,
  records: Record[],
  includeAllJoins: boolean,
  applyBehavior: boolean = true,
): Query => {
  const defaultQP = getDefaultQuery(entity);

  const query = relatedEntity
    ? queryWithRelated(defaultQP, entity, relatedEntity, includeAllJoins)
    : defaultQP;

  return applyBehavior
    ? applyBehaviorToQuery(entities, entity, records, query)
    : query;
};
