import { Component } from "react";
import type { UTCDateTime } from "common/date-time/types";
import type { Entity } from "common/entities/types";
import type { Context } from "common/types/context";
import type { ApiErrorResponse } from "common/types/error";
import type { AssignmentEvent } from "common/types/scheduler";
import type { SchedulerSettingsType } from "common/types/scheduler-settings";
import type { ValueProps } from "common/with-value-for";
import { SchedulerFilter } from "x/scheduler2/planner/header/filter";
import { DateSelector } from "x/scheduler2/shared/date-selector";
import { ViewSelector } from "x/scheduler2/shared/view-selector";
import type { SchedulerData, ViewType } from "x/scheduler2/types";
import { PublishButton } from "./publish";

interface PropTypes extends ValueProps<SchedulerData> {
  context: Context;
  assignmentsEntities: Entity[];
  schedulerSettings: SchedulerSettingsType;
  unpublishedEvents: AssignmentEvent[];
  reload: () => void;
  onError: (error: ApiErrorResponse) => void;
}

export class SchedulerHeader extends Component<PropTypes> {
  onViewChange = (view: ViewType) => {
    this.props.onChange({ ...this.props.value, view });
  };

  onHideWeekendsChange = (hideWeekends: boolean) => {
    this.props.onChange({ ...this.props.value, hideWeekends });
  };

  onDateChanged = (date: UTCDateTime) => {
    this.props.onChange({ ...this.props.value, selectedDate: date });
  };

  render() {
    const {
      value,
      context,
      onChange,
      assignmentsEntities,
      onError,
      reload,
      schedulerSettings,
      unpublishedEvents = [],
    } = this.props;
    const { selectedDate, view, hideWeekends } = value;

    return (
      <div className="x-scheduler-header x-flex">
        <SchedulerFilter
          context={context}
          schedulerSettings={schedulerSettings}
          value={value}
          onChange={onChange}
        />

        <div className="x-scheduler-header-datetime">
          <DateSelector
            context={context}
            view={view}
            date={selectedDate}
            hideWeekends={hideWeekends}
            onDateChanged={this.onDateChanged}
          />
        </div>

        <div className="x-scheduler-header-view">
          <PublishButton
            context={context}
            view={view}
            assignmentsEntities={assignmentsEntities}
            unpublishedEvents={unpublishedEvents}
            onError={onError}
            reload={reload}
          />

          <ViewSelector
            view={view}
            hideWeekends={hideWeekends}
            onViewChange={this.onViewChange}
            onHideWeekendsChange={this.onHideWeekendsChange}
          />
        </div>
      </div>
    );
  }
}
