import { ApiCall, Cud } from "common/types/api";
import { CancellablePromise } from "common/types/promises";
import {
  showErrorNotification,
  showInfoNotification,
} from "common/ui/notification";

interface NotificationMessages {
  created: string;
  updated: string;
  removed: string;
}

export const messages = (getMessages: () => NotificationMessages) =>
  getMessages;

export const returnAndNotify = (content: string) => (result: any) => {
  showInfoNotification(content);
  return result;
};

export const rethrowAndNotify = (content: string) => (error: any) => {
  showErrorNotification(content);
  throw error;
};

export const withNotifications =
  <TId, TRecord, TApi extends Cud<TId, TRecord>>(
    getMessages: () => NotificationMessages,
    apiFactory: (apiCall: ApiCall) => TApi,
  ) =>
  (apiCall: ApiCall): TApi => {
    const api = apiFactory(apiCall);
    const messages = getMessages();
    return {
      ...api,
      create: (record: TRecord): CancellablePromise<any> =>
        api.create(record).then(returnAndNotify(messages.created)),
      update: (record: TRecord): CancellablePromise<any> =>
        api.update(record).then(returnAndNotify(messages.updated)),
      remove: (id: TId): CancellablePromise<any> =>
        api.remove(id).then(returnAndNotify(messages.removed)),
    };
  };
