import { Entity } from "common/entities/types";
import {
  getFullForeignKeyLabel,
  isForeignKey,
} from "common/functions/foreign-key";
import { useExpandedRecord } from "common/record/foreign-key-expansion";
import { Context } from "common/types/context";
import { FkValue, ForeignKey } from "common/types/foreign-key";
import { arrayToString } from "common/utils/array";
import { ReactHighlightWords } from "common/vendor-wrappers/react-highlight-words";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import {
  FormatMeta,
  LabelledOptionOrGroup,
} from "common/vendor-wrappers/react-select/types";
import { formatFkItemForDropdown } from "common/widgets/record-selector/functions";
import { Selector } from "common/widgets/selector/index";
import { ValueProps } from "common/with-value-for";

interface PropTypes extends ValueProps<FkValue> {
  options: ForeignKey[];
  context?: Context;
  entity?: Entity;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  allowClear?: boolean;
  menuIsOpen?: boolean;
  isLoading?: boolean;
}

export const ForeignKeySelector = ({
  context,
  entity,
  options = [],
  placeholder,
  disabled,
  className = "",
  allowClear,
  menuIsOpen,
  isLoading,
  value,
  onChange,
}: PropTypes) => {
  const expandedRecord = useExpandedRecord({
    context,
    entityName: entity?.name,
    record: value,
  });

  const formatOption = (
    option: LabelledOptionOrGroup<ForeignKey>,
    meta: FormatMeta<ForeignKey>,
  ) => {
    if (isGroupedOption(option) || typeof option.value === "string")
      return (
        <ReactHighlightWords text={option.label} search={meta?.inputValue} />
      );

    const { value } = option;

    return formatFkItemForDropdown(
      "image" in value,
      !!value.subtitle,
      !!value.subsubtitle,
      !!value.customtitle,
      entity,
      context,
    )(value, meta?.inputValue);
  };

  const getLabel = (value: ForeignKey) =>
    getFullForeignKeyLabel(context, value, entity);

  const currentValue = expandedRecord ?? value;
  const optionsWithExpandedRecord = expandedRecord
    ? options.map((option) =>
        !isForeignKey(option) && option === expandedRecord.id
          ? expandedRecord
          : option,
      )
    : options;

  return (
    <Selector
      className={arrayToString(["x-lookup", className])}
      disabled={disabled}
      allowClear={allowClear}
      placeholder={placeholder}
      getOptionLabel={getLabel}
      isLoading={isLoading}
      formatOption={formatOption}
      options={optionsWithExpandedRecord}
      menuIsOpen={menuIsOpen}
      value={currentValue}
      onChange={onChange}
    />
  );
};
