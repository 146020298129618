import * as R from "ramda";
import { getColumn } from "common/entities";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entities, Entity } from "common/entities/types";
import { Layout, LookupQuery } from "common/form/types";
import { merge1 } from "common/merge";
import { addFilterToQuery } from "common/query/filter";
import { FilterRule } from "common/query/types";
import { Properties } from "common/types/records";

const getQueryFilterByColumn = (
  entities: Entities,
  entityName: string,
  column: EntityColumn,
  filterByColumn: string,
  value: any,
): EntityColumn => {
  const entity = entities[entityName];
  const columnInfo = getColumn(entity, column.name);
  const filterByColumnInfo = getColumn(entity, filterByColumn);

  const filterByEntity = entities[filterByColumnInfo.relatedEntity];

  const join = R.find(
    (j) => j.entityName === columnInfo.relatedEntity && !j.outbound,
    filterByEntity.joins,
  );

  const filter: FilterRule = {
    name: join.column,
    op: "eq",
    value,
    excludeFromFkExpansion: true,
  };

  const query = column.query || entities[columnInfo.relatedEntity].query;

  return { ...column, query: addFilterToQuery(filter, query) };
};

export const applyLookupQueryToColumn = (
  entities: Entities,
  entityName: string,
  lookupQueries: LookupQuery[],
  properties: Properties,
  column: EntityColumn,
): EntityColumn => {
  const lookupQuery =
    !!column && lookupQueries?.find((q) => q.columnName === column.name);

  if (!lookupQuery) return column;
  const { query, filterByColumn } = lookupQuery;

  const newColumn = query ? merge1("query", query, column) : column;

  const value = properties?.[filterByColumn];

  return filterByColumn && value
    ? getQueryFilterByColumn(
        entities,
        entityName,
        newColumn,
        filterByColumn,
        value,
      )
    : newColumn;
};

export const applyLookupQuery = (
  entities: Entities,
  entity: Entity,
  layout: Pick<Layout, "lookupQueries">,
  properties: Properties = {},
): Entity => {
  if (!layout?.lookupQueries?.length) return entity;
  const { lookupQueries } = layout;

  const columns = entity.columns.map((column) =>
    applyLookupQueryToColumn(
      entities,
      entity.name,
      lookupQueries,
      properties,
      column,
    ),
  );
  return merge1("columns", columns, entity);
};
