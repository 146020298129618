import { Entities } from "common/entities/types";
import { UiFormat } from "common/types/ui-format";
import { DateTimeLabel } from "common/widgets/date/date-time-label";
import { EventTitle } from "./event-title";
import { Event } from "./types";

interface EventPropTypes {
  entities: Entities;
  site: string;
  event: Event;
  uiFormat: UiFormat;
}

export const EventInfo = ({
  entities,
  site,
  event,
  uiFormat,
}: EventPropTypes) => {
  return (
    <div className="x-calendar-event">
      <DateTimeLabel uiFormat={uiFormat} value={event.rangeFrom} />
      <div className="x-separator"> - </div>
      <DateTimeLabel uiFormat={uiFormat} value={event.rangeTo} />
      <EventTitle entities={entities} site={site} event={event} />
    </div>
  );
};

EventInfo.displayName = "EventInfo";
