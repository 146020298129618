import * as R from "ramda";
import { ApiCall } from "common/types/api";
import {
  allBehaviorNames,
  Behavior,
  BehaviorBlueprint,
  interactiveMapBehaviorNames,
  BehaviorName,
  chargeBehaviorNames,
  hiddenUiBehaviorNames,
  scheduledBehaviorNames,
} from "common/types/behaviors";
import { CancellablePromise } from "common/types/promises";
import { findByName } from "common/utils/array";

export const behaviorsApi = (apiCall: ApiCall) => ({
  get: (): CancellablePromise<BehaviorBlueprint[]> =>
    apiCall("get", "api/behaviors"),
});

export const isBehaviorName = (n: BehaviorName): boolean =>
  allBehaviorNames.includes(n);

export const isHiddenUiBehaviorName = (n: BehaviorName): boolean =>
  hiddenUiBehaviorNames.includes(n);

export const isChargeBehaviorName = (n: BehaviorName): boolean =>
  chargeBehaviorNames.includes(n);

export const isScheduledBehaviorName = (n: BehaviorName): boolean =>
  scheduledBehaviorNames.includes(n);

export const isInteractiveMapBehaviorName = (n: BehaviorName): boolean =>
  interactiveMapBehaviorNames.includes(n);

export const findBehavior = (
  behaviors: Behavior[],
  behaviorName: BehaviorName,
): Behavior => findByName(behaviors, behaviorName);

export const hasBehavior = (
  behaviors: Behavior[],
  behaviorName: BehaviorName,
): boolean => !!findBehavior(behaviors, behaviorName);

const matchBehaviors = /[^,<]+(<.*?>)?/g;
const matchLettersOnly = /[a-zA-Z_]+/g;

export const stringToBehaviors = (behaviors: string): Behavior[] => {
  if (!behaviors) return [];
  const noSpace = behaviors.split(" ").join("");
  return R.match(matchBehaviors, noSpace)
    .map((b: string) => {
      const bParts = R.match(matchLettersOnly, b);
      const name = R.head(bParts) as BehaviorName;
      const args = R.tail(bParts);
      return { name, arguments: args };
    })
    .filter((b) => isBehaviorName(b.name));
};

export const behaviorsToString = (behaviors: Behavior[]): string => {
  if (!behaviors || !behaviors.length) return "";
  return behaviors
    .map((b) => {
      const args = b.arguments.map((a) => a || "null");
      return args.length ? `${b.name}<${args.join(",")}>` : b.name;
    })
    .join(",");
};
