interface PropTypes {
  title: string;
  className?: string;
  children?: any;
}

export const Row = ({ title, className = "", children }: PropTypes) => (
  <div className={className}>
    <legend>{title}</legend>
    {children}
  </div>
);

Row.displayName = "Row";
