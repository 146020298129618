import { Component } from "react";
import { Context } from "common/types/context";
import { MapValue } from "common/vendor-wrappers/leaflet/types";
import { MapWithLayers } from "common/widgets/map";
import { ValueProps } from "common/with-value-for";
import { getInfo } from "./utils";

interface PropTypes extends ValueProps<MapValue> {
  context: Context;
  image: string;
  isReadOnly: boolean;
}

export class EntityMap extends Component<PropTypes> {
  static readonly displayName = "EntityMap";

  render() {
    const { context, image, isReadOnly, value, onChange } = this.props;

    return image ? (
      <MapWithLayers
        getInfo={getInfo(context)}
        imageUrl={image}
        isReadOnly={isReadOnly}
        value={value}
        onChange={onChange}
      />
    ) : null;
  }
}
