import "mixpanel-browser";
import { ComponentType } from "react";
import { Component } from "common/component";
import { Location } from "common/types/url";
import { eventLabel, trackEvent } from "common/utils/mixpanel";

interface WithMixPanel {
  location: Location;
}

type WithMixPanelOutput<PropTypes> = PropTypes & WithMixPanel;

const trackPath = (path: string) => {
  if (
    /^\/\w+\/\w+$/g.test(path) ||
    /^\/\w+\/admin\/\w+$/g.test(path) ||
    /^\/\w+\/admin\/References\/\w+$/g.test(path)
  ) {
    const pathElements = path.split("/");
    trackEvent(eventLabel.pathAccessed, {
      path: pathElements[pathElements.length - 1],
    });
  }
};

export function withMixPanel<PropTypes>(
  YourComponent: ComponentType<WithMixPanelOutput<PropTypes>>,
) {
  return class WithMixPanelComp extends Component<
    WithMixPanelOutput<PropTypes>
  > {
    static readonly displayName = "WithMixPanelComp";

    componentDidMount() {
      trackPath(this.props.location.pathname);
    }

    componentDidUpdate(prevProps: Readonly<WithMixPanelOutput<PropTypes>>) {
      const newPath = this.props.location.pathname;
      const prevPath = prevProps.location.pathname;

      if (prevPath === newPath) return;

      trackPath(newPath);
    }

    render() {
      return <YourComponent {...this.props} />;
    }
  };
}
