import { Output } from "common/data/types";
import { QueryBuilder } from "common/query-builder";
import { allConditionTypes } from "common/types/condition-type";
import { Context } from "common/types/context";
import { Report } from "common/types/reports";
import { VerticalField } from "common/ui/field";
import { withValue, WithValue } from "common/with-value";
import { OutputPreview } from "../preview-with-button";

interface PropTypes {
  context: Context;
}

export const tableOutput: Output = {
  type: "Table",
  config: {
    ignore: [],
    mergeSites: true,
    showMergeOption: false,
  },
};

const ReportBuilderComp = ({
  context,
  value,
  onChangeSetValue,
}: PropTypes & WithValue<Report>) => {
  const outputFields = (
    <OutputPreview
      context={context}
      output={tableOutput}
      query={value}
      withLinks={true}
    />
  );

  return (
    <div className="x-query-builder-with-output">
      <QueryBuilder
        context={context}
        conditionTypes={allConditionTypes}
        withPaging={false}
        allowFiltersOnUnboundQueries={true}
        value={value}
        onChange={onChangeSetValue}
      />
      <div className="x-query-builder-output">
        <VerticalField label={_("Output")} input={outputFields} />
      </div>
    </div>
  );
};

export const ReportBuilder = withValue<Report, PropTypes>(
  ReportBuilderComp,
  "ReportBuilder",
);
