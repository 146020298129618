import * as R from "ramda";
import { cloneElement } from "react";
import { Component } from "common/component";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { Unsubscribe } from "common/types/preferences";
import { getStarredFor } from "common/utils/starred";

type Injected<TPrototypes> = TPrototypes & {
  starred: boolean;
  toggleStar: () => any;
};

export type PropTypes<TPrototypes> = TPrototypes & {
  context: Context;
  entity: Entity;
  id: string;
  children?: any;
};

export function withStar<TPrototypes>() {
  type Props = PropTypes<TPrototypes>;

  class WithStar extends Component<Props> {
    unsubscribe: Unsubscribe = undefined;

    componentDidMount() {
      this.unsubscribe = this.props.context.starred.subscribe(() =>
        this.forceUpdate(),
      );
    }

    componentWillUnmount() {
      if (this.unsubscribe) this.unsubscribe();
      this.unsubscribe = undefined;
    }

    getStarred = () => {
      const { entity, context } = this.props;
      return getStarredFor(
        context.starred.get(),
        context.site.name,
        entity.name,
      );
    };

    isStarred = () => {
      const { id } = this.props;
      return R.any((i) => i === id, this.getStarred());
    };

    toggleStar = () => {
      const { entity, id, context } = this.props;

      const promise = this.isStarred()
        ? context.starred.unstar(context.site.name, entity.name, id)
        : context.starred.star(context.site.name, entity.name, id);

      promise.then(() => this.forceUpdate());
    };

    render() {
      const { children } = this.props;
      const injected: Injected<TPrototypes> = {
        ...this.props,
        toggleStar: this.toggleStar,
        starred: this.isStarred(),
      };
      return cloneElement(children, injected);
    }
  }

  return WithStar;
}
