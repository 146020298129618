import { CancelButton } from "common/ui/buttons";
import { trackEvent } from "common/utils/mixpanel";
import { DropdownMenu } from "common/widgets/dropdown-menu";
import { DropdownOption } from "x/scheduler2/shared/view-selector/dropdown-option";
import { translateOption } from "x/scheduler2/shared/view-selector/functions";
import type { ViewType } from "x/scheduler2/types";

interface PropTypes {
  view: ViewType;
  hideWeekends: boolean;
  onViewChange: (view: ViewType) => void;
  onHideWeekendsChange: (hideWeekends: boolean) => void;
}

export const ViewSelector = ({
  view,
  hideWeekends,
  onViewChange,
  onHideWeekendsChange,
}: PropTypes) => {
  const changeView = (newView: ViewType) => () => {
    trackEvent("Scheduler View Changed", { view: newView });
    onViewChange(newView);
  };

  const toggleWeekends = () => {
    onHideWeekendsChange(!hideWeekends);
  };

  return (
    <DropdownMenu
      className="qa-scheduler-view-selector"
      button={
        <CancelButton className="x-margin-left-10">
          {translateOption(view)} <i className="fa fa-caret-down" />
        </CancelButton>
      }
      menu={
        <>
          <DropdownOption
            option="day"
            onSelect={changeView("day")}
            isSelected={view === "day"}
          />
          <DropdownOption
            option="week"
            onSelect={changeView("week")}
            isSelected={view === "week"}
          />
          <DropdownOption
            option="month"
            onSelect={changeView("month")}
            isSelected={view === "month"}
          />
          {view === "week" ? (
            <>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <DropdownOption
                option={hideWeekends ? "showWeekends" : "hideWeekends"}
                onSelect={toggleWeekends}
                isSelected={false}
              />
            </>
          ) : undefined}
        </>
      }
    />
  );
};
