import { getSitesDictionary } from "common/functions/sites";
import { Context } from "common/types/context";
import { Label } from "common/widgets/label";
import { getDisplaySiteFn } from "common/widgets/site-tree-selector-with-search/functions";

type SitesLabelProps = {
  context: Context;
  value: string[];
  textToHighlight: string;
};

export const SitesLabel = ({
  context,
  value,
  textToHighlight,
}: SitesLabelProps) => {
  const availableSites = context?.sites;
  const sitesDictionary = getSitesDictionary(availableSites);
  const displaySite = getDisplaySiteFn(sitesDictionary);
  const selectedSites = value
    ?.map((site) => availableSites.find((s) => s.name === site))
    ?.filter((site) => !!site)
    ?.map(displaySite)
    .join(", ");

  return <Label textToHighlight={textToHighlight} value={selectedSites} />;
};
