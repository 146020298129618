const TRUE_VALUES = ["true", "1", "yes"];
const FALSE_VALUES = ["false", "0", "no"];

export const isTrue = (value: boolean | string) =>
  (typeof value === "boolean" && value === true) ||
  (typeof value === "string" && TRUE_VALUES.includes(value.toLowerCase()));

export const isFalse = (value: boolean | string) =>
  (typeof value === "boolean" && value === false) ||
  (typeof value === "string" && FALSE_VALUES.includes(value.toLowerCase()));
