import { UTCDateTime } from "common/date-time/types";
import { UiFormat } from "common/types/ui-format";
import { HighlightProps } from "common/widgets/label";
import { DateBaseLabel } from "./date-base-label";

interface PropTypes extends HighlightProps {
  uiFormat: UiFormat;
  value: UTCDateTime;
  timezone?: string;
}

export const DateLabel = ({
  uiFormat,
  value,
  textToHighlight,
  timezone,
}: PropTypes) => (
  <DateBaseLabel
    title={`${_("Unformatted Date")}: ${value}`}
    format={uiFormat.dateFormat}
    textToHighlight={textToHighlight}
    timezone={timezone}
    value={value}
  />
);
