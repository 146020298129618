import * as R from "ramda";
import { apiSearchFull, searchApi } from "common/api/search";
import type { UTCDateRange } from "common/date-time/types";
import { findColumn } from "common/entities";
import { isCustomOrSystemFk } from "common/entities/entity-column/functions";
import type { EntityColumn } from "common/entities/entity-column/types";
import type { Entity } from "common/entities/types";
import { workOrderStatusDictionary } from "common/functions/system-string-options";
import { getSelectFieldByTitle } from "common/query/select";
import type {
  FilterAnd,
  FilterOr,
  FilterRule,
  JoinItem,
  PaginationValue,
  QueryForEntity,
  SelectField,
} from "common/query/types";
import type { ApiResponse } from "common/types/api";
import type { Context } from "common/types/context";
import { isNumeric } from "common/utils/number";
import { isOrgColumn } from "common/widgets/currency/functions";
import {
  defaultWOSelect,
  getAssignmentEntityFromWorkOrderEntity,
} from "x/scheduler2/planner/unassigned-work/functions";
import type { WorkOrder } from "x/scheduler2/planner/unassigned-work/types";

const WORK_ORDERS_PER_PAGE = 50;

export const getSchedulerQuery = (
  context: Context,
  workOrderEntity: Entity,
  dateRange: UTCDateRange,
  customQuery?: QueryForEntity,
  searchQuery?: QueryForEntity,
  getCountOnly?: boolean,
): QueryForEntity => {
  const rangeFilter = [
    ...(dateRange?.from
      ? [{ name: "startDate", op: "gte", value: dateRange.from }]
      : []),
    { name: "startDate", op: "lte", value: dateRange.to },
  ];
  const assignmentEntity = getAssignmentEntityFromWorkOrderEntity(
    context,
    workOrderEntity,
  );
  const assignedFilter = {
    name: "number",
    path: `/${assignmentEntity.name}.workOrderId`,
    op: "isnull",
  };
  const customFilter = customQuery?.query?.filter;
  const searchFilter = searchQuery?.query?.filter;

  const queryFilter = {
    and: [
      { name: "isDeleted", op: "isfalse" },
      { name: "status", op: "neq", value: "H" },
      ...rangeFilter,
      assignedFilter,
      ...(customFilter && !getCountOnly ? [customFilter] : []),
      ...(searchFilter && !getCountOnly ? [searchFilter] : []),
    ],
  };

  const querySelect = getCountOnly
    ? [{ name: "number", fn: "COUNT", alias: "count" }]
    : [
        { name: "id", alias: "SYSTEM_id" },
        { name: "number", alias: "SYSTEM_number" },
        { name: "startDate", alias: "SYSTEM_startDate" },
        { name: "description", alias: "SYSTEM_description" },
        {
          expression: `'${workOrderEntity.name}'`,
          label: "entityName",
          alias: "SYSTEM_entityName",
        },
        ...(customQuery?.query?.select || defaultWOSelect),
      ];

  const searchJoins = searchQuery?.query?.joins || [];
  const queryJoins: JoinItem[] = [
    {
      column: "workOrderId",
      entity: assignmentEntity.name,
      type: "LEFT",
      additionalOriginJoinEquates: "false",
      additionalOriginJoinField: "isdeleted",
    },
    ...(getCountOnly ? [] : searchJoins),
  ];

  const queryOrder = getCountOnly ? [] : [{ name: "number" }];

  return {
    entity: workOrderEntity.name,
    query: {
      select: querySelect,
      joins: queryJoins,
      filter: queryFilter,
      order: queryOrder,
      fkExpansion: true,
      includeColorCoding: !getCountOnly,
    },
  };
};

/**
 * Falsy condition prevents returning any results for a given field
 * @param name Column name
 */
export const getFalseCondition = (name: string) => ({
  and: [
    { name, op: "isnull" },
    { name, op: "isnotnull" },
  ],
});

export const getStatusCondition = (value: string): FilterOr | FilterAnd => {
  const statusDictionary = R.invertObj(workOrderStatusDictionary());
  const statusLabels = Object.keys(statusDictionary);

  /**
   * Creates an OR filter with all matching statuses. It does the matching on
   * statuses translated in the FE and returns values stored in the DB
   * ex. "ed" is matched in "Closed" and "Completed" and gives "H" OR "C"
   **/
  const filters: FilterRule[] = statusLabels.reduce(
    (acc, statusLabel) =>
      R.includes(value, statusLabel.toLocaleLowerCase())
        ? [
            ...acc,
            {
              name: "status",
              op: "eq",
              value: statusDictionary[statusLabel],
            },
          ]
        : acc,
    [],
  );

  return filters.length ? { or: filters } : getFalseCondition("status");
};

export const getFkCondition = (
  context: Context,
  column: EntityColumn,
  value: string,
): FilterRule => {
  // getting the field set as a title in related entity configuration
  // that the foreign key column is related to
  const relatedEntity = context.entities[column.relatedEntity];
  const relatedField = getSelectFieldByTitle(relatedEntity.query.select);

  return {
    name: relatedField.name,
    op: "contains",
    value,
    path: `/${column.name}`,
    excludeFromFkExpansion: isCustomOrSystemFk(column),
  };
};

export const getBoolCondition = (value: string, name: string): FilterAnd => {
  const operator = R.includes(value, "true")
    ? "istrue"
    : R.includes(value, "false")
      ? "isfalse"
      : undefined;

  return operator
    ? {
        and: [
          { name, op: operator },
          { name, op: "isnotnull" },
        ],
      }
    : getFalseCondition(name);
};

export const getFloatCondition = (
  context: Context,
  column: EntityColumn,
  value: string,
): FilterOr => {
  const { uiFormat } = context;

  // Normalize separator character
  const sanitizedValue = value.replace(uiFormat.decimalSeparator, ".");

  return {
    or: [
      ...(isNumeric(sanitizedValue)
        ? [
            {
              name: column.name,
              op: "eq",
              value: sanitizedValue,
              excludeFromFkExpansion: isCustomOrSystemFk(column),
            },
          ]
        : []),
      {
        name: column.name,
        op: "contains",
        value: sanitizedValue,
        excludeFromFkExpansion: isCustomOrSystemFk(column),
      },
    ],
  };
};

export const getCurrencyCondition = (
  context: Context,
  column: EntityColumn,
  value: string,
): FilterRule | FilterOr => {
  const { currency, masterCurrency } = context;
  const currencySymbol =
    column && isOrgColumn(column) ? masterCurrency.symbol : currency.symbol;

  if (value === currencySymbol) {
    return {
      name: column.name,
      op: "isnotnull",
    };
  }

  // Skip the currency symbol
  const sanitizedValue = value.replace(currencySymbol, "").trim();

  return getFloatCondition(context, column, sanitizedValue);
};

const removeDeletedFields = (fields: SelectField[], columns: EntityColumn[]) =>
  fields.filter((field) =>
    columns.some((column) => column.name === field.name),
  );

export const getSearchQuery = (
  context: Context,
  entity: Entity,
  fields: SelectField[],
  value: string,
): QueryForEntity => {
  const { columns } = entity;
  const sanitizedValue = value.trim().toLocaleLowerCase();

  // Filter out the deleted fields
  const filteredFields = removeDeletedFields(fields, columns);

  const conditions = filteredFields
    .map((f) => {
      if (f.name === "status") {
        return getStatusCondition(sanitizedValue);
      }

      const column = findColumn(columns, f.name);
      if (column.dataType === "fk") {
        return getFkCondition(context, column, sanitizedValue);
      }
      if (column.dataType === "bool") {
        return getBoolCondition(sanitizedValue, f.name);
      }
      if (column.dataType === "currency") {
        return getCurrencyCondition(context, column, sanitizedValue);
      }
      if (column.dataType === "float" || column.dataType === "ufloat") {
        return getFloatCondition(context, column, sanitizedValue);
      }

      return {
        name: f.name,
        op: "contains",
        value: sanitizedValue,
        excludeFromFkExpansion: isCustomOrSystemFk(column),
      };
    })
    .filter((condition) => !!condition);

  const joins: JoinItem[] = filteredFields.reduce(
    (acc: JoinItem[], field: SelectField) => {
      const column = findColumn(columns, field.name);
      if (column.dataType === "fk") {
        return acc.concat([{ column: field.name }]);
      }
      return acc;
    },
    [],
  );

  return {
    entity: entity.name,
    query: {
      select: filteredFields,
      joins,
      filter: { or: conditions },
      order: [{ name: "number" }],
      fkExpansion: true,
    },
  };
};

export const getWorkOrders = (
  context: Context,
  workOrderEntity: Entity,
  dateRange: UTCDateRange,
  customQuery: QueryForEntity,
  searchQuery: QueryForEntity,
  page: number,
  perPage: number = WORK_ORDERS_PER_PAGE,
) =>
  apiSearchFull(context.apiCallFull)
    .runQueryWithPagination(
      getSchedulerQuery(
        context,
        workOrderEntity,
        dateRange,
        customQuery,
        searchQuery,
      ),
      context,
      page,
      perPage,
    )
    .then((r: ApiResponse<PaginationValue<WorkOrder>>) => r.data);

export const getTotalCount = (
  context: Context,
  workOrderEntity: Entity,
  dateRange: UTCDateRange,
  customQuery: QueryForEntity,
) =>
  searchApi(context.apiCall)
    .runQuery(
      getSchedulerQuery(
        context,
        workOrderEntity,
        dateRange,
        customQuery,
        undefined,
        true,
      ),
    )
    .then((r: WorkOrder[] = []) => r[0]?.count);
