import { Entities } from "common/entities/types";
import { SelectField, SelectExpression, SelectItem } from "common/query/types";
import { SelectError } from "common/query-builder/validation";

export const isFieldFromOneToManyJoin = (
  fieldPath: string,
  entities: Entities,
): boolean => {
  const pathParts = fieldPath?.split("/") ?? [];
  const inboundJoins = pathParts.filter((part) => part.includes("."));

  return inboundJoins.some((join) => {
    const [entity, column] = join.split(".");
    const en = entities[entity];
    const joins = en?.joins;

    return joins?.some((j) => j.column === column && !j.oneToOne);
  });
};

export const filterErrorFieldItems = (
  errors: SelectError[],
  item: SelectField,
) =>
  errors.filter(
    (e) =>
      e.alias === item.alias &&
      item.name &&
      e.name === item.name &&
      item.path === e.path,
  );

export const filterErrorExpressions = (
  errors: SelectError[],
  item: SelectExpression,
) => errors.filter((e) => e.expression === item.expression);

export const noError: { message: string } = {
  message: undefined,
};

export const getSelectedFullPaths = (fields: SelectItem[]) =>
  fields.map((field: SelectField) => `${field.path || ""}.${field.name}`);
