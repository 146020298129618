import { getTheme } from "common/utils/themes";

export type LogoMode = "light" | "standard" | "theme-based";

export const getLogoUrls = (
  tenantName?: string,
  logoMode: LogoMode = "theme-based",
) => {
  const query = tenantName ? `?tenantName=${tenantName}` : "";
  const isLight =
    logoMode === "light" ||
    (logoMode === "theme-based" && getTheme() === "dark");
  const mode = isLight ? "/light" : "";

  return {
    desktopLogo: `/api/branding/desktop${mode}${query}`,
    mobileLogo: `/api/branding/mobile${mode}${query}`,
  };
};
