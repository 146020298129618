import { Context } from "common/types/context";
import { EntityColumn } from "common/entities/entity-column/types";
import { LabelWidget } from "common/form/widget/label-widget";
import { AdditionalProps } from "common/record/form/content/detail/functions";

interface PropTypes {
  column: EntityColumn;
  withLinks: boolean;
  label: string;
  context: Context;
  entityName: string;
  value: any;
  additionalProps?: AdditionalProps;
}

export const Row = ({
  label,
  column,
  withLinks,
  context,
  entityName,
  value,
  additionalProps,
}: PropTypes) => (
  <tr className={`x-section-group-row qa-${label.replace(" ", "-")}`}>
    <td className="x-read-only-label txt-color-blueLight">{label}</td>
    <td className={`x-read-only-value qa-${column.dataType}`}>
      <LabelWidget
        {...additionalProps}
        column={column}
        context={context}
        withLinks={withLinks}
        entityName={entityName}
        value={value}
      />
    </td>
  </tr>
);
