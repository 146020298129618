import { withValue, WithValue } from "common/with-value";
import { UiFormat } from "common/types/ui-format";
import { DateTime } from "common/widgets/date/date-time";
import { RequiredField } from "common/form/ui";
import { Hint } from "common/widgets/hint";

interface PropTypes {
  uiFormat: UiFormat;
}

const StartDateComp = ({
  uiFormat,
  value,
  onChangeSetValue,
}: PropTypes & WithValue<string>) => (
  <div className="x-pm-start-date x-pm-section qa-pm-section">
    <RequiredField label={_("Starting on")} value={value}>
      <DateTime uiFormat={uiFormat} value={value} onChange={onChangeSetValue} />
      <Hint
        message={_("Projections will be generated after the selected date")}
      />
    </RequiredField>
  </div>
);

export const StartDate = withValue<string, PropTypes>(
  StartDateComp,
  "StartDate",
);
