import { Component } from "react";
import {
  BooleanOptions,
  EntityColumn,
} from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { WidgetsOverwriteMap } from "common/form/types";
// eslint-disable-next-line import/no-cycle
import { Widget } from "common/form/widget";
import { getIntFkId } from "common/functions/system-int";
import {
  getMappedSystemIntFkOptions,
  getSystemString,
} from "common/functions/system-string-options";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { SystemIntFk } from "common/types/system-int";
import { RadioButtonList } from "common/widgets/radio-button-list";
import { ValueProps } from "common/with-value-for";
import { BooleanQuickInput } from "./boolean";
import { ValueToggleCheckbox } from "./value-toggle-check-box";

interface PropTypes extends ValueProps<any> {
  context: Context;
  column: EntityColumn;
  entity: Entity;
  options?: BooleanOptions;
  dependencies?: Properties;
  widgetsMap?: WidgetsOverwriteMap;
  isDisabled?: (item: SystemIntFk) => boolean;
}

export class QuickInput extends Component<PropTypes> {
  static readonly displayName = "QuickInput";

  render() {
    const {
      context,
      column,
      options,
      entity,
      dependencies,
      isDisabled,
      widgetsMap,
      value,
      onChange,
    } = this.props;

    const quickInputNode = widgetsMap?.[column.name]?.quickInputNode?.({
      isDisabled,
    });
    if (quickInputNode) return quickInputNode;

    const { dataType, required, name, relatedSystem } = column;

    const systemString = getSystemString(entity, name);
    if (systemString) {
      return (
        <RadioButtonList
          className="x-no-bottom-margins"
          size="small"
          name={name}
          display={systemString.translate}
          items={systemString.options}
          value={value}
          onChange={onChange}
        />
      );
    }

    const availableOptions = context.systemTables[relatedSystem];
    const mappedSystemIntFk = getMappedSystemIntFkOptions(
      availableOptions,
      entity,
      name,
      dependencies,
    );
    if (mappedSystemIntFk) {
      if (mappedSystemIntFk.options.length === 1) {
        const item = mappedSystemIntFk.options[0];
        const label = mappedSystemIntFk.translate(item);
        return (
          <ValueToggleCheckbox
            item={item}
            label={label}
            disabled={isDisabled?.(item) ?? false}
            value={item.id === getIntFkId(value) ? item : undefined}
            onChange={onChange}
          />
        );
      }

      return (
        <RadioButtonList
          className="x-no-bottom-margins"
          size="small"
          name={name}
          isDisabled={isDisabled}
          display={mappedSystemIntFk.translate}
          items={mappedSystemIntFk.options}
          value={availableOptions.find((o) => o.id === getIntFkId(value))}
          onChange={onChange}
        />
      );
    }

    switch (dataType) {
      case "bool":
        return (
          <BooleanQuickInput
            options={options}
            value={value}
            onChange={onChange}
          />
        );
      default:
        return (
          <Widget
            context={context}
            required={required}
            disabled={false}
            buffer={false}
            withLinks={true}
            readOnly={false}
            recordId={undefined}
            entityName={entity.name}
            column={column}
            formValidation={undefined}
            onFormValidationChange={undefined}
            value={value}
            onChange={onChange}
          />
        );
    }
  }
}
