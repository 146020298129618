import { Component } from "react";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { ForeignKey } from "common/types/foreign-key";
import { Properties, RecordPayload } from "common/types/records";
import { SystemIntFk } from "common/types/system-int";
import { ActionButtonSmall, InactiveButtonSmall } from "common/ui/buttons";
import { Label } from "common/widgets/label";
import { merge2 } from "common/merge";
import { searchApi } from "common/api/search";
import {
  getAssetMetersEntity,
  getAssetMetersQuery,
  getMeterReadingOrDefault,
  isEditMode,
  mapToProperties,
} from "./functions";
import { AssetMeterReadingModal } from "./modal";

interface Props {
  context: Context;
  entity: Entity;
  parentEntity: Entity;
  properties: Properties;
  assetId: ForeignKey;
  workOrderId: string;
  isDisabled?: (item: SystemIntFk) => boolean;
  value: RecordPayload;
  onChange: (payload: RecordPayload, setFormDirty?: boolean) => void;
}

interface StateType {
  assetMeters: Properties[];
  loading: boolean;
  showModal: boolean;
  error: ApiErrorResponse;
}

export class ProcedureMeterReading extends Component<Props, StateType> {
  static readonly displayName = "ProcedureMeterReading";

  state: StateType = {
    assetMeters: undefined,
    error: undefined,
    loading: false,
    showModal: false,
  };

  componentDidUpdate(_: Readonly<Props>, prevState: Readonly<StateType>) {
    const { showModal, assetMeters } = this.state;
    if (showModal && !prevState.showModal && !assetMeters)
      this.loadAssetMeters();
  }

  loadAssetMeters = () => {
    const { context, assetId, parentEntity, value } = this.props;

    const assetsEntity = context.entities[parentEntity.arguments?.assetEntity];
    const assetMetersEntity = getAssetMetersEntity(
      context.entities,
      assetsEntity,
    );

    const query = getAssetMetersQuery(
      assetMetersEntity,
      assetId.id,
      value.properties?.meterId.id,
    );

    this.setState({ loading: true });
    searchApi(context.apiCall)
      .runQuery(query)
      .then((assetMeters: Properties[]) => {
        this.setState({ assetMeters, loading: false });
      })
      .catch((error: ApiErrorResponse) => {
        this.setState({ loading: false, error });
      });
  };

  showModal = () => this.setState({ showModal: true });
  onModalCancel = () => {
    const { entity, value, onChange } = this.props;
    const meterReading = getMeterReadingOrDefault(entity.name, value);

    if (!isEditMode(meterReading)) onChange({ ...value, related: {} });
    this.setState({ showModal: false });
  };

  onOk = () => {
    const { entity, workOrderId, value } = this.props;
    const { id } = value.properties;

    const meterReading = getMeterReadingOrDefault(entity.name, value);
    const recordProperties = mapToProperties(
      meterReading?.properties,
      id,
      workOrderId,
    );

    this.onMeterReadingChange(true)(recordProperties);
    this.setState({ showModal: false });
  };

  onMeterReadingChange =
    (setDirty: boolean = false) =>
    (properties: Properties) => {
      const { entity, value, onChange } = this.props;
      const record: RecordPayload[] = [{ properties, isNew: !properties.id }];

      const newValue = merge2("related", entity.name, record, value);
      onChange(newValue, setDirty);
    };

  getActionContent = (meterReading: RecordPayload) => {
    const { properties, isDisabled } = this.props;
    const label = meterReading?.properties.value;
    const ButtonElement = label ? ActionButtonSmall : InactiveButtonSmall;
    const className = `${label ? "x-margin-right-10" : ""}`;
    const buttonTitle = isEditMode(meterReading)
      ? _("Meter Reading")
      : _("Enter Meter Reading");

    return (
      <div className="x-flex x-asset-meter-reading qa-asset-meter-reading">
        <ButtonElement
          className={className}
          onClick={this.showModal}
          disabled={isDisabled?.(properties.procedureTypeId)}
        >
          {buttonTitle}
        </ButtonElement>
        {label ? <Label value={`(${label})`} /> : undefined}
      </div>
    );
  };

  render() {
    const { context, entity, assetId, value } = this.props;
    const { showModal, loading, error, assetMeters } = this.state;

    const meterReading = getMeterReadingOrDefault(entity.name, value);

    return (
      <>
        {this.getActionContent(meterReading)}
        {showModal ? (
          <AssetMeterReadingModal
            context={context}
            entity={entity}
            assetMeters={assetMeters}
            assetId={assetId}
            error={error}
            loading={loading}
            value={meterReading?.properties}
            onChange={this.onMeterReadingChange()}
            onOk={this.onOk}
            onCancel={this.onModalCancel}
          />
        ) : undefined}
      </>
    );
  }
}
