import { adminAuthApi } from "common/api/authentication/admin";
import { hasMoreThanOneSite } from "common/functions/sites";
import { Context } from "common/types/context";
import { GoFn } from "common/types/url";
import { DeleteButtonLarge } from "common/ui/buttons";
import { Logo } from "common/widgets/logo";
import {
  SiteTreeSelectorWithSearch,
  SiteTreeSelectorWithSearchValue,
} from "common/widgets/site-tree-selector-with-search";
import { ValueComponent } from "common/with-value-for";
import { UserPopover } from "./user-popover";
import { GlobalSearchComponent } from "./global-search";

export interface HeaderValue {
  avatar: string;
  site: string;
  menuOpen: boolean;
  disableAutoMenuToggle: boolean;
  siteOpen: boolean;
  historyOpen: boolean;
  explorerOpen: boolean;
}

interface PropTypes {
  context: Context;
  goTo: GoFn;
  logout: () => any;
  isAdmin: boolean;
  announcementsActive: boolean;
}

export class Header extends ValueComponent<HeaderValue, PropTypes> {
  static readonly displayName = "Header";

  onToggleMenu = () => {
    const { value } = this.props;
    const { menuOpen } = value;
    this.mergeValue({ menuOpen: !menuOpen, disableAutoMenuToggle: true });
  };

  onToggleExplorer = () => {
    const { value } = this.props;
    const { explorerOpen } = value;
    this.mergeValue({ explorerOpen: !explorerOpen });
  };

  onStopImpersonating = () => {
    const { context } = this.props;
    adminAuthApi(context.apiCall).stopImpersonating(context.tenant.name);
  };

  onChangeSite = (value: SiteTreeSelectorWithSearchValue) => {
    const { site, siteOpen } = value;
    this.mergeValue({
      siteOpen,
      site,
      historyOpen: false,
    });
  };

  onToggleHistory = () => {
    const { value } = this.props;
    const { historyOpen } = value;
    this.mergeValue({
      siteOpen: false,
      historyOpen: !historyOpen,
    });
  };

  onToggleAnnouncements = () => {
    const { context, announcementsActive, value, goTo } = this.props;
    const { localStorage, lastVisited } = context;
    const { site } = value;
    if (announcementsActive) {
      const backTo = localStorage.get("backFromAnnouncements");
      localStorage.remove("backFromAnnouncements");
      goTo(backTo ? `${backTo}` : `/${site}/dashboard`);
    } else {
      localStorage.set(
        "backFromAnnouncements",
        lastVisited.get() || `/${site}/dashboard`,
      );
      goTo(`/${site}/Announcements`);
    }
  };

  render() {
    const { logout, context, isAdmin, announcementsActive, value, goTo } =
      this.props;
    const { sites, announcements, tenant } = context;
    const { avatar, site, menuOpen, siteOpen, historyOpen, explorerOpen } =
      value;

    return (
      <div className="x-header">
        <div className="x-header-container">
          <div className="x-logo-wrapper x-float-left">
            <Logo tenantName={tenant.name} />
          </div>
          <button
            className={
              "x-header-button x-float-left" + (menuOpen ? " x-active" : "")
            }
            onClick={this.onToggleMenu}
            title={_("Toggle Menu")}
          >
            <i className="fa fa-bars" />
          </button>
          {!isAdmin ? (
            <button
              className={
                "x-header-button x-float-left" +
                (explorerOpen ? " x-active" : "")
              }
              onClick={this.onToggleExplorer}
              title={_("Toggle Explorer")}
            >
              <i className="fa fa-sitemap fa-rotate-270 x-explorer-icon" />
            </button>
          ) : undefined}
        </div>

        {context.isImpersonated ? (
          <DeleteButtonLarge
            className="x-header-button x-impersonation qa-impersonation"
            onClick={this.onStopImpersonating}
          >
            {_("Stop impersonating")}
          </DeleteButtonLarge>
        ) : undefined}

        <div className="x-flex">
          {announcements.length ? (
            <button
              className={
                "x-header-button x-float-left" +
                (announcementsActive ? " x-active" : "")
              }
              onClick={this.onToggleAnnouncements}
              title={_("View Announcements")}
            >
              <i className="fa fa-bullhorn" />
            </button>
          ) : undefined}

          <GlobalSearchComponent context={context} goTo={goTo} />

          {hasMoreThanOneSite(sites) ? (
            <SiteTreeSelectorWithSearch
              sites={sites}
              value={{ site, siteOpen }}
              onChange={this.onChangeSite}
            />
          ) : undefined}
          <button
            className={
              "x-header-button x-float-left" + (historyOpen ? " x-active" : "")
            }
            onClick={this.onToggleHistory}
            title={_("Toggle History Panel")}
          >
            <i className="fa fa-history" />
          </button>

          <div className="x-float-left">
            <UserPopover
              context={context}
              logout={logout}
              site={site}
              value={avatar}
              onChange={this.onChangeMergeValue("avatar")}
            />
          </div>
        </div>
      </div>
    );
  }
}
