import { Component } from "react";
import { getError } from "common/api/error";
import { recordsApi } from "common/api/records";
import { userSettingsApi } from "common/api/settings/user-settings";
import { Action } from "common/record/actions/action";
import { DismissAction, PropTypes } from "common/record/actions/types";
import { ApiCall } from "common/types/api";
import {
  AuditTrailSettings,
  defaultAuditTrailSettings,
} from "common/types/settings";
import {
  showErrorNotification,
  showInfoNotification,
} from "common/ui/notification";
import { ToggleWithLabel } from "common/widgets/toggle/with-label";
import { ValueProps } from "common/with-value-for";
import { LoadingIcon } from "common/widgets/loading-icon";
import { genericCatch, getTitle } from "../function-handlers";

export interface ExportToPdfValue {
  includeAuditTrailInPdfExport: boolean;
}

interface StateType {
  auditTrailSettings: AuditTrailSettings;
  isLoading: boolean;
}

type Props = PropTypes & ValueProps<ExportToPdfValue>;

export class ExportToPdfModal extends Component<Props, StateType> {
  static readonly displayName = "ExportToPdfModal";
  state: StateType = {
    auditTrailSettings: defaultAuditTrailSettings,
    isLoading: true,
  };

  componentDidMount() {
    const { context, dismiss, value, onChange } = this.props;

    this.setState({ isLoading: true });
    userSettingsApi(context.apiCall)
      .get()
      .then((settings) => {
        const auditTrailSettings = settings.auditTrailSettings;

        if (!auditTrailSettings.askUserBeforeExporting) {
          this.onExport(context.apiCall, () => dismiss(true));
          this.setState({ isLoading: false });
          return;
        }

        onChange({
          ...value,
          includeAuditTrailInPdfExport:
            auditTrailSettings.includeInRecordPdfExport,
        });
        this.setState({
          auditTrailSettings: {
            includeInRecordPdfExport:
              auditTrailSettings.includeInRecordPdfExport,
            askUserBeforeExporting: auditTrailSettings.askUserBeforeExporting,
          },
          isLoading: false,
        });
      })
      .catch((error) => {
        showErrorNotification(getError(error));
      });
  }

  onExport = (apiCall: ApiCall, dismiss: DismissAction) => {
    const { entity, records, value } = this.props;
    const title = getTitle(entity, records[0]);
    const auditTrailSettings = this.state.auditTrailSettings;

    showInfoNotification(_("Your pdf download will start shortly"));
    dismiss(false);

    return recordsApi(apiCall)
      .downloadPdf(
        entity,
        title,
        records[0].properties.id,
        auditTrailSettings.askUserBeforeExporting &&
          value?.includeAuditTrailInPdfExport,
      )
      .catch(genericCatch);
  };

  onChangeToggle = () => {
    const { onChange, value } = this.props;

    onChange({
      ...value,
      includeAuditTrailInPdfExport: !value.includeAuditTrailInPdfExport,
    });
  };

  render() {
    const {
      context,
      dismiss,
      entity,
      records,
      allowFullscreen,
      actionProps,
      value,
    } = this.props;
    const { isLoading, auditTrailSettings } = this.state;
    const askUserBeforeExporting = auditTrailSettings?.askUserBeforeExporting;

    return (
      <>
        {askUserBeforeExporting ? (
          <Action
            requiresAuthentication={false}
            allowFullscreen={allowFullscreen}
            context={context}
            dismiss={dismiss}
            entity={entity}
            records={records}
            title={_("Export")}
            btnLabel={_("Export")}
            onOk={this.onExport}
            {...actionProps}
          >
            <ToggleWithLabel
              label={_("Include Audit Trail in the PDF")}
              value={value?.includeAuditTrailInPdfExport}
              onChange={this.onChangeToggle}
            />
          </Action>
        ) : null}
        {isLoading ? <LoadingIcon /> : undefined}
      </>
    );
  }
}
