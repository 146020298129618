import * as R from "ramda";
import { defaultFor, getLocalizedName } from "common";
import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";
import { createDefaultLayout } from "common/form/functions/layout";
import { SectionGroup } from "common/form/group";
import { HorizontalField, RequiredField, Section } from "common/form/ui";
import { InputWidget } from "common/form/widget/input-widget";
import { RunQuery } from "common/query/types";
import { getBatchQuery } from "common/record/form/content/related/part-charge/functions";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { Date } from "common/widgets/date/date";
import { ReferenceSelector } from "common/widgets/record-selector/references";
import { QuerySelector } from "common/widgets/selector/query-selector";
import { Int, Uint } from "common/widgets/number";
import { Required } from "common/widgets/required";
import { ValueComponent } from "common/with-value-for";
import { T1000 } from "common/widgets/text";
import { ModifyButton } from "common/ui/buttons";
import {
  getAdjustment,
  getAdjustmentEntity,
  getFormatBatchFn,
} from "./functions";
import { AdjustmentFormValue as Value } from "./types";

interface PropTypes {
  context: Context;
  entity: Entity;
  runQuery: RunQuery;
  originalOnHand: number;
  recordId: string;
  hasBatchSelection: boolean;
}

const defaultValue = defaultFor<Value>();

export class StockFormComponent extends ValueComponent<Value, PropTypes> {
  static readonly displayName = "StockFormComponent";

  changeOnHand = (newOnHand: number, newAdjustBy?: string) => {
    const { originalOnHand, value = defaultValue } = this.props;
    const { adjustBy, batch } = value;
    this.mergeValue({
      onHand: newOnHand,
      adjustBy: newAdjustBy !== undefined ? newAdjustBy : adjustBy,
      batch:
        batch && batch.id === "new" && newOnHand < originalOnHand
          ? undefined
          : batch,
    });
  };

  onSubtract = () => {
    const { value = defaultValue } = this.props;
    const { onHand } = value;
    this.changeOnHand(onHand > 0 ? onHand - 1 : 0);
  };

  onAdd = () => {
    const { value = defaultValue } = this.props;
    const { onHand } = value;
    this.changeOnHand(onHand > 0 ? onHand + 1 : 1);
  };

  onChangeCount = (onHand: string) => {
    this.changeOnHand(parseInt(onHand, 10) || 0);
  };

  onAdjust = () => {
    const { value = defaultValue } = this.props;
    const { onHand, adjustBy } = value;
    const newValue = onHand + parseInt(adjustBy);
    const newOnHand = newValue > 0 ? newValue : 0;
    this.changeOnHand(newOnHand, "");
  };

  onCommentChange = (comment: string) => this.mergeValue({ comment });

  render() {
    const {
      context,
      entity,
      runQuery,
      originalOnHand,
      recordId,
      hasBatchSelection,
      value = defaultValue,
    } = this.props;
    const { onHand, adjustmentType, date, cost, comment, adjustBy, batch } =
      value;

    const onHandDelta = onHand - originalOnHand;

    const batchEntity = R.values(context.entities).filter(
      (e) => behaveAs("Batch", e) && e.arguments.stockEntity === entity.name,
    )[0];

    const batchSelectorDefaults: ForeignKey[] = [
      { id: "new", number: 0, title: _("New") },
    ];

    const batchSelector = hasBatchSelection ? (
      <RequiredField
        label={getLocalizedName(batchEntity)}
        className="qa-adjustment-batch"
        value={batch}
      >
        <QuerySelector
          context={context}
          query={recordId ? getBatchQuery(batchEntity, recordId) : undefined}
          runQuery={runQuery}
          defaults={onHandDelta >= 0 ? batchSelectorDefaults : undefined}
          formatOption={getFormatBatchFn(context)}
          value={batch}
          onChange={this.onChangeMergeValue("batch")}
        />
      </RequiredField>
    ) : undefined;

    const showCost =
      (!hasBatchSelection || (batch && batch.id === "new")) && onHandDelta >= 0;

    const adjustmentEntity = getAdjustment(context.entities, entity);
    const adjustmentLayout = createDefaultLayout(
      adjustmentEntity,
      context,
      value,
      true,
    );

    const fullAdjustmentEntity = getAdjustmentEntity(context.entities, entity);
    const unitCostColumn = R.find(
      (c) => c.name === "cost",
      fullAdjustmentEntity.columns,
    );

    const costInput = showCost ? (
      <HorizontalField
        className="qa-stock-cost"
        isRequired={true}
        label={_("Unit cost")}
      >
        <Required value={cost}>
          <InputWidget
            context={context}
            column={unitCostColumn}
            disabled={false}
            validate={true}
            buffer={false}
            formValidation={undefined}
            onFormValidationChange={undefined}
            value={cost !== undefined ? `${cost}` : undefined}
            onChange={this.onChangeMergeValue("cost")}
          />
        </Required>
      </HorizontalField>
    ) : undefined;

    return (
      <div className="x-stock-form-wrapper">
        <div className="form-inline x-stock-form-current-stock">
          <label
            className="col-md-2 x-stock-form-current-amount"
            htmlFor="onHand"
          >
            {`${_("Current on hand")}: `}
          </label>
          <div className="form-control x-stock-form-current-controls">
            <div
              className="x-stock-subtract-count qa-stock-subtract-count"
              onClick={this.onSubtract}
            >
              <i className="fa fa-minus-circle" />
            </div>
            <Required
              className="x-on-hand-input"
              value={onHandDelta !== 0 || undefined}
            >
              <Uint value={onHand} onChange={this.onChangeCount} />
            </Required>
            <div
              className="x-stock-add-count qa-stock-add-count"
              onClick={this.onAdd}
            >
              <i className="fa fa-plus-circle" />
            </div>
          </div>
          <label
            className="txt-color-blueLight x-stock-form-current-hint"
            htmlFor="updateAmountBy"
          >
            {`${_("or adjust amount by")}: (+/-)`}
          </label>
          <div className="form-control x-stock-current-hint qa-stock-adjustBy">
            <Int
              className="x-adjust-by-input"
              placeholder={`${_("e.g.")} -50`}
              value={adjustBy}
              onChange={this.onChangeMergeValue("adjustBy")}
            />

            <ModifyButton
              disabled={!adjustBy || adjustBy === "-"}
              className="qa-stock-update-amount-by"
              onClick={this.onAdjust}
            >
              {_("Ok")}
            </ModifyButton>
          </div>
        </div>
        <Section>
          <RequiredField
            label={_("Adjustment type")}
            value={adjustmentType}
            className="qa-stock-adjustment-type"
          >
            <ReferenceSelector
              context={context}
              allowClear={false}
              entity={adjustmentEntity.arguments.adjustmentTypeEntity}
              value={adjustmentType}
              onChange={this.onChangeMergeValue("adjustmentType")}
            />
          </RequiredField>
          <RequiredField
            label={_("Transaction date")}
            className="qa-stock-date"
            value={date}
          >
            <Date
              uiFormat={context.uiFormat}
              value={date}
              onChange={this.onChangeMergeValue("date")}
            />
          </RequiredField>
          {batchSelector}
          {costInput}
          <HorizontalField
            label={`${_("Packing Slip")} / ${_("Comment")}`}
            className="qa-stock-comment"
          >
            <T1000
              maxLength={256}
              value={comment}
              onChange={this.onCommentChange}
            />
          </HorizontalField>
        </Section>
        {adjustmentLayout.groups.length ? (
          <SectionGroup
            context={context}
            entity={adjustmentEntity}
            groups={adjustmentLayout.groups}
            group={adjustmentLayout.groups[0]}
            withLinks={true}
            formValidation={undefined}
            onFormValidationChange={undefined}
            value={value}
            onChange={this.onChangeSetValue}
          />
        ) : undefined}
      </div>
    );
  }
}
