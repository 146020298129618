import { History } from "history";
import { Component, ComponentType } from "react";
import { connect } from "react-redux";
import { apiCallFullWithLoginRedirect } from "common/api/impl";
import { uiApi } from "common/api/ui";
import { resetExpandedRecords } from "common/app/redux/expanded-records";
import { resetReferences } from "common/app/redux/references";
import { Culture } from "common/culture/supported-cultures";
import { setSiteTimeZone } from "common/date-time/global";
import { findSite, hasMoreThanOneSite } from "common/functions/sites";
import { setupLocale } from "common/i18n";
import { merge2 } from "common/merge";
import { ApiCallFull } from "common/types/api";
import {
  type ApplicationScope,
  Context,
  RawSettings,
} from "common/types/context";
import { CancellablePromise } from "common/types/promises";
import { Site } from "common/types/sites";
import { Location } from "common/types/url";
import { ApiError } from "common/ui/api-error";
import { getContext } from "common/utils/context";
import { goTo } from "common/utils/go-to";
import { lastVisitedService } from "common/utils/last-visited";
import { localStorageService } from "common/utils/local-storage";
import { withAuth0Provider } from "common/vendor-wrappers/auth0/with-auth0-provider";
import { LoadingIcon } from "common/widgets/loading-icon";
import { AppDispatch } from "./redux";
import { splitPath } from "./router";

export interface ApiAndPathProps {
  location: Location;
  history: History;
  apiCallFull?: ApiCallFull;
  uiApiCall?: () => CancellablePromise<RawSettings>;
}

interface DispatchProps {
  resetExpandedRecords: () => void;
  resetReferences: () => void;
}

export interface WithContext {
  context: Context;
  reloadUi: () => CancellablePromise<void>;
  changeCulture: (culture: Culture) => CancellablePromise<void>;
}

interface StateType {
  rawSettings?: RawSettings;
  context?: Context;
  error?: any;
}

const getSiteFromPathname = (path: string) => splitPath(path)[0];

const getSiteFromLocation = (location: Location, sites: Site[]) => {
  const site = findSite(getSiteFromPathname(location.pathname), sites);
  if (site) return site;

  const siteFromQuery = findSite(location?.queryString?.siteName, sites);
  return siteFromQuery && !siteFromQuery.isGroup ? siteFromQuery : undefined;
};

const getCulture = (settings: RawSettings) => settings.uiFormat.culture;

// The props that get passed to withContext(YourComponent)
export type Props<PropTypes> = PropTypes & ApiAndPathProps & DispatchProps;

// The props that YourComponent expects
type PropsWithContext<PropTypes> = PropTypes & WithContext & ApiAndPathProps;

export function withContext<PropTypes extends { [name: string]: any }>(
  YourComponent: ComponentType<PropsWithContext<PropTypes>>,
  scope: ApplicationScope,
) {
  class WithContext extends Component<Props<PropTypes>, StateType> {
    static readonly displayName = "WithContext";

    static getDerivedStateFromProps(
      newProps: Props<PropTypes>,
      prevState: StateType,
    ) {
      const { context, rawSettings } = prevState;
      const { location, apiCallFull = apiCallFullWithLoginRedirect } = newProps;

      if (!context || !rawSettings) return null;

      const globalSite = getSiteFromLocation(
        newProps.location,
        rawSettings.sites,
      );

      if (
        globalSite &&
        globalSite.name !== context.site.name &&
        hasMoreThanOneSite(rawSettings.sites)
      ) {
        const context = getContext(
          rawSettings,
          globalSite.name,
          apiCallFull,
          location?.queryString?.xtoken,
          undefined,
          scope,
        );

        setSiteTimeZone(context.site.timezone);

        newProps.resetReferences();
        newProps.resetExpandedRecords();

        return { context, rawSettings };
      }

      return null;
    }

    state: StateType = {};

    componentDidMount() {
      this.reloadUi();
    }

    componentDidUpdate(prevProps: Readonly<Props<PropTypes>>) {
      const { context } = this.state;
      const { location } = this.props;

      if (prevProps.location.pathname !== location.pathname) {
        context?.lastVisited.set(location.pathname);
      }
    }

    updateHistory = (settings: RawSettings) => {
      const { history } = this.props;
      const { userName, tenant, sites } = settings;
      const localStorage = localStorageService(userName, tenant.name);
      const lastVisitedPath = lastVisitedService(localStorage).get();
      const firstAvailableSite = sites.find((s) => !s.isGroup) || sites[0];
      const url =
        hasMoreThanOneSite(sites) &&
        lastVisitedPath &&
        !!findSite(getSiteFromPathname(lastVisitedPath), sites)
          ? lastVisitedPath
          : firstAvailableSite.name;

      goTo(history)(url);

      return getSiteFromPathname(url);
    };

    reloadUi = () => {
      const {
        location,
        apiCallFull = apiCallFullWithLoginRedirect,
        uiApiCall,
      } = this.props;

      const loadApi = uiApiCall ?? uiApi(apiCallFull).load;
      return loadApi()
        .then((rawSettings) => {
          const newCulture = getCulture(rawSettings);
          const oldContext = this.state.context;
          const { sites } = rawSettings;

          const globalSite = getSiteFromLocation(location, rawSettings.sites);

          const newSite =
            globalSite && (!globalSite.isGroup || hasMoreThanOneSite(sites))
              ? globalSite.name
              : // we either don't have site in the url or we are in an invalid one,
                // so we will try to get a default site from last visited or first valid in the list
                this.updateHistory(rawSettings);

          if (!oldContext || oldContext.uiFormat.culture !== newCulture) {
            setupLocale(newCulture).then(() =>
              this.createAndStoreContextForSite(rawSettings, newSite),
            );
          } else {
            this.createAndStoreContextForSite(rawSettings, newSite);
          }
        })
        .catch((error) => {
          this.setState({ error });
        });
    };

    changeCulture = (newCulture: Culture) => {
      const { rawSettings, context } = this.state;

      if (!context.cultures.includes(newCulture)) return undefined;

      const rawSettingsForCulture = merge2(
        "uiFormat",
        "culture",
        newCulture,
        rawSettings,
      );

      return setupLocale(newCulture).then(() => {
        this.createAndStoreContextForSite(
          rawSettingsForCulture,
          context.site.name,
        );
      });
    };

    createAndStoreContextForSite = (
      rawSettings: RawSettings,
      globalSiteName: string,
    ) => {
      const {
        location,
        apiCallFull = apiCallFullWithLoginRedirect,
        resetReferences,
        resetExpandedRecords,
      } = this.props;

      const xtoken = location?.queryString?.xtoken;
      const context = getContext(
        rawSettings,
        globalSiteName,
        apiCallFull,
        xtoken,
        this.reloadUi,
        scope,
      );

      setSiteTimeZone(context.site.timezone);

      resetReferences();
      resetExpandedRecords();

      this.setState({ context, rawSettings });

      return context;
    };

    render() {
      const { context, error } = this.state;
      if (error) return <ApiError error={error} />;
      if (!context) return <LoadingIcon message={`${_("Loading")}...`} />;

      const { tenant } = context;
      const { auth0ClientId, orgId } = tenant;
      return withAuth0Provider(
        <YourComponent
          {...this.props}
          context={context}
          reloadUi={this.reloadUi}
          changeCulture={this.changeCulture}
        />,
        auth0ClientId,
        orgId,
      );
    }
  }

  const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
    resetExpandedRecords: () => dispatch(resetExpandedRecords()),
    resetReferences: () => dispatch(resetReferences()),
  });

  return connect(undefined, mapDispatchToProps)(WithContext);
}
