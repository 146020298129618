import { returnAndNotify } from "common/api/with-notifications";
import { ApiCall } from "common/types/api";
import { CancellablePromise } from "common/types/promises";
import { SchedulerSettingsType } from "common/types/scheduler-settings";

export const schedulerSettingsApi = (apiCall: ApiCall) => ({
  get: (): CancellablePromise<SchedulerSettingsType> =>
    apiCall("get", "/api/admin/settings/scheduler"),
  set: (s: SchedulerSettingsType) =>
    apiCall("post", "/api/admin/settings/scheduler", s).then(
      returnAndNotify(_("The scheduler settings were successfully updated")),
    ),
});
