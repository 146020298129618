import {
  isCalendar,
  isFromFluke,
  isManual,
  isRunning,
  isShadow,
  isTotals,
  isTotalsEstimation,
} from "common/functions/reasons";
import { Reason } from "common/types/reasons";
import { FlukeReasonComp } from "./fluke";

interface PropTypes {
  reason: Reason;
}

// TODO: Create components for all the reasons

const getContent = (reason: Reason) => {
  if (isManual(reason)) return <span />;
  if (isCalendar(reason)) return <span />;
  if (isTotals(reason)) return <span />;
  if (isTotalsEstimation(reason)) return <span />;
  if (isRunning(reason)) return <span />;
  if (isFromFluke(reason)) return <FlukeReasonComp reason={reason} />;
  if (isShadow(reason)) return <span />;
  return undefined;
};

export const WoReason = ({ reason }: PropTypes) => (
  <div className="x-record-reason-wrapper">
    <div className="x-record-reason">{getContent(reason)}</div>
  </div>
);

WoReason.displayName = "WoReason";
