import * as R from "ramda";
import { Context } from "common/types/context";
import { Output } from "common/types/preferences";
import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";

const isTree = (context: Context, entity: Entity) =>
  context.isEnabledFeature("entityTree") &&
  behaveAs("Tree", entity) &&
  !context.site.isGroup;

const isFunctionalLocation = (context: Context, entity: Entity) =>
  !!entity.columns.filter(
    (column) =>
      column.isForeignKey &&
      behaveAs("FunctionalLocation", context.entities[column.relatedEntity]) &&
      entity.name !== column.relatedEntity,
  ).length;

type OutputFilterFn = (context: Context, entity: Entity) => boolean;

const outputFilters: Array<[Output, OutputFilterFn]> = [
  ["Tree", isTree],
  ["FunctionalLocation", isFunctionalLocation],
  ["Table", R.always(true)],
  ["Cards", R.always(true)],
];

export const getOutputFilter =
  (context: Context, entity: Entity) => (output: Output) =>
    R.fromPairs(outputFilters)[output](context, entity);
