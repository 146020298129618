import { getColumn } from "common/entities";
import { InputWidget } from "common/form/widget/input-widget";
import type { Context } from "common/types/context";
import type { SchedulerSettingsType } from "common/types/scheduler-settings";
import type { ValueProps } from "common/with-value-for";
import { getCalendarsAvailableInSites } from "x/account-settings/calendars/list-controller";
import type { FilterValue, SchedulerData } from "x/scheduler2/types";

interface PropTypes extends ValueProps<SchedulerData> {
  context: Context;
  schedulerSettings: SchedulerSettingsType;
}

export const SchedulerFilter = (props: PropTypes) => {
  const { value, context, schedulerSettings, onChange } = props;
  const { filterValue } = value;
  const { entityName, filterBy } = schedulerSettings;

  if (!filterBy) return null;

  const { entities, calendars, site, sites } = context;
  const entity = entities[entityName];
  const column = { ...getColumn(entity, filterBy), required: false };

  const updatedContext =
    filterBy === "calendarId"
      ? {
          ...context,
          calendars: getCalendarsAvailableInSites(calendars, site, sites),
        }
      : context;

  const onFilterChange = (newFilterValue: FilterValue) => {
    onChange({ ...value, filterValue: newFilterValue });
  };

  return (
    <div className="x-scheduler-header-filter">
      <InputWidget
        context={updatedContext}
        column={column}
        disabled={false}
        buffer={false}
        allowClear={true}
        validate={false}
        formValidation={undefined}
        onFormValidationChange={undefined}
        value={filterValue}
        onChange={onFilterChange}
      />
    </div>
  );
};
