import { Component } from "react";
import { defaultFor } from "common";
import { getUtcNow } from "common/date-time/common";
import { UTCDateTime } from "common/date-time/types";
import { Entity } from "common/entities/types";
import {
  getMaxReadingPerDay,
  getPreviousReading,
  isValid,
  getReadingsValueWarning,
} from "common/record/form/content/related/asset-meter-reading/functions";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { ForeignKey } from "common/types/foreign-key";
import { Properties } from "common/types/records";
import {
  RUNNING_METER_TYPE_ID,
  TOTAL_METER_TYPE_ID,
} from "common/types/system-strings";
import { ApiError } from "common/ui/api-error";
import { VerticalField } from "common/ui/field";
import { AlertWarning } from "common/widgets/alert";
import { DateTime } from "common/widgets/date/date-time";
import { ForeignKeySelector } from "common/widgets/selector/foreign-key-selector";
import { LoadingIcon } from "common/widgets/loading-icon";
import { Modal } from "common/widgets/modal";
import { Required } from "common/widgets/required";
import { ValueProps } from "common/with-value-for";
import { getFkId } from "common/functions/foreign-key";
import { AssetMeter } from "../../asset-meter-reading/form/asset-meter";
import { Reading } from "../../asset-meter-reading/form/reading";
import { getMeterType, mapToProperties } from "./functions";

interface PropTypes {
  context: Context;
  entity: Entity;
  assetId: ForeignKey;
  assetMeters: Properties[];
  loading?: boolean;
  error?: ApiErrorResponse;
  onOk: () => void;
  onCancel: () => void;
}

type Props = PropTypes & ValueProps<Properties>;

interface StateType {
  initialValue: Properties;
}

export class AssetMeterReadingModal extends Component<Props, StateType> {
  static readonly displayName = "AssetMeterReadingModal";

  constructor(props: Props) {
    super(props);

    const date = props.value?.date;
    const initialDate = date || getUtcNow();
    const initialValue = { ...props.value, date: initialDate };

    this.state = { initialValue };
    if (!date) this.onChangeDate(initialDate);
  }

  onCancel = () => {
    const { onChange, onCancel } = this.props;
    const { initialValue } = this.state;

    onChange(initialValue);
    onCancel();
  };

  onChangeDate = (date: UTCDateTime) => {
    const { value, onChange } = this.props;
    onChange({ ...value, date });
  };

  onMeterValueChange = (meterReading: Properties) => {
    const { value, onChange } = this.props;
    onChange({ ...value, value: meterReading?.value });
  };

  onAssetMeterChange = (assetMeterId: ForeignKey) => {
    const { value, onChange } = this.props;
    onChange({ ...value, assetMeterId });
  };

  render() {
    const {
      context,
      entity,
      assetId,
      assetMeters = [],
      loading,
      error,
      value = defaultFor<Properties>(),
      onOk,
    } = this.props;

    const { assetMeterId, value: meterReadingValue, date } = value;
    const meterTypeId = getMeterType(getFkId(assetMeterId), assetMeters);
    const totalReadings = assetMeters.reduce(
      (sum, meter) => sum + meter?.value,
      0,
    );

    const assetMeterRecords = assetMeters.map((meter) => ({
      actions: [],
      properties: meter,
    }));

    const maxReadingPerDay = getMaxReadingPerDay(
      assetMeterId,
      assetMeterRecords,
    );

    const total =
      parseFloat(meterReadingValue || 0) +
      ((meterTypeId === TOTAL_METER_TYPE_ID && totalReadings) || 0);

    const validTotal = !maxReadingPerDay || total <= maxReadingPerDay;
    const totalExceeded = !validTotal && meterTypeId !== RUNNING_METER_TYPE_ID;
    const assetMeterReading = mapToProperties(value);
    const previousReading = getPreviousReading(
      assetMeterReading,
      assetMeterRecords,
    );
    const isAssetMeterReadingValid = isValid(
      assetMeterReading,
      previousReading,
      meterTypeId,
      maxReadingPerDay,
    );

    const canSave =
      !totalExceeded &&
      isAssetMeterReadingValid &&
      !!meterReadingValue &&
      !loading;

    const isEditMode = !!(value.id || value.$id);
    const okLabel = isEditMode ? _("Change") : _("Create");
    const title = isEditMode
      ? _("Change Asset Meter Reading")
      : _("Add Asset Meter Reading");

    return (
      <Modal
        content={
          <div>
            {loading && <LoadingIcon />}
            {error && <ApiError error={error} />}
            <VerticalField
              label={_("Asset")}
              input={
                <ForeignKeySelector
                  context={context}
                  options={[assetId]}
                  value={assetId}
                  disabled={true}
                  onChange={undefined}
                />
              }
            />
            <Required value={assetMeterId}>
              <VerticalField
                label={_("Asset Meters")}
                input={
                  <AssetMeter
                    entity={entity}
                    context={context}
                    assetMeters={assetMeterRecords}
                    value={assetMeterId}
                    onChange={this.onAssetMeterChange}
                  />
                }
              />
            </Required>
            <VerticalField
              label={_("Date")}
              input={
                <DateTime
                  uiFormat={context.uiFormat}
                  value={date}
                  onChange={this.onChangeDate}
                  allowClear={false}
                />
              }
            />
            <Required value={meterReadingValue}>
              <Reading
                context={context}
                entity={entity}
                previousReading={previousReading}
                meterTypeId={meterTypeId}
                isValid={isAssetMeterReadingValid}
                maxReadingPerDay={maxReadingPerDay}
                value={value}
                onChange={this.onMeterValueChange}
              />
            </Required>
            {totalExceeded && (
              <AlertWarning
                message={getReadingsValueWarning(total, maxReadingPerDay)}
              />
            )}
          </div>
        }
        title={title}
        okLabel={okLabel}
        onOk={canSave ? onOk : undefined}
        onCancel={this.onCancel}
      />
    );
  }
}
