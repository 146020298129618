import { ReactHighlightWords } from "common/vendor-wrappers/react-highlight-words";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import {
  FormatMeta,
  LabelledOptionOrGroup,
} from "common/vendor-wrappers/react-select/types";
import { icons } from "common/entities";
import { withValue, WithValue } from "common/with-value";
import { Selector } from "..";

interface PropTypes {
  disabled?: boolean;
  label?: string;
}

export const formatIconOption = (
  option: LabelledOptionOrGroup<string>,
  meta: FormatMeta<string>,
) => {
  if (isGroupedOption(option)) return option.label;

  const icon = option.value;
  const text = icon.slice(3);
  const replaceStartAndEnding =
    text.slice(-2) === "-o" ? text.slice(0, -1) + _("(Inverted)") : text;
  const title = replaceStartAndEnding.split("-").join(" ");
  return (
    <div className="x-select-item">
      <div className="x-icon">
        <i className={`fa ${icon}`} />
      </div>
      <div className="x-data">
        <div className="x-title">
          <ReactHighlightWords text={title} search={meta?.inputValue} />
        </div>
      </div>
    </div>
  );
};

const IconListComp = ({
  disabled,
  label,
  value,
  onChangeSetValue,
}: PropTypes & WithValue<string>) => (
  <Selector
    label={label}
    value={value}
    className="qa-icon-dropdown"
    placeholder={`${_("Select icon")}...`}
    allowClear={true}
    options={icons}
    disabled={disabled}
    formatOption={formatIconOption}
    onChange={onChangeSetValue}
  />
);

export const IconList = withValue<string, PropTypes>(IconListComp, "IconList");
IconList.displayName = "IconList";
