import * as R from "ramda";
import { getLocalizedName } from "common";
import { Component } from "common/component";
import { merge4, mergeChain } from "common/merge";
import { FormValue, ScheduledEventValue } from "common/record/types";
import {
  materializeTemporaryIds,
  payloadToRecord,
  replaceUndefined,
} from "common/record/utils";
import { ApiErrorResponse } from "common/types/error";
import { Record } from "common/types/records";
import { ApiError } from "common/ui/api-error";
import { LoadingIcon } from "common/widgets/loading-icon";
import { PropTypes } from "x/records/edit-controller/types";
import { RecordEditController } from "../edit-controller";
import {
  defaultUnwrap,
  defaultWrap,
} from "../generic-record-detail-controller";

interface StateType {
  loading: boolean;
  error: ApiErrorResponse;
  type: string;
}

export class ScheduledEventEditController extends Component<
  PropTypes,
  StateType
> {
  static readonly displayName = "ScheduledEventEditController";
  state: StateType = {
    loading: false,
    error: undefined,
    type: undefined,
  };

  wrapRecord = (form: FormValue, record: Record) => {
    const { wrap = defaultWrap } = this.props;

    const type = record.properties.type;
    this.setState({ type });

    const id = record.properties.id;
    const properties = record.properties.workOrderForm.properties;
    const newProperties =
      id && !properties.id ? { ...properties, id } : properties;

    const newRecord = mergeChain(record)
      .prop("properties")
      .prop("workOrderForm")
      .setMany({
        properties: newProperties,
        related: undefined,
      })
      .output();

    const { related } = record.properties.workOrderForm;
    const formWithRelated = merge4(
      "ui",
      "entityForm",
      "related",
      "form",
      related,
      form as ScheduledEventValue,
    );

    return wrap(formWithRelated, newRecord);
  };

  unwrapRecord = (form: FormValue) => {
    const { unwrap = defaultUnwrap } = this.props;

    const { record, ui } = form as ScheduledEventValue;

    const uiForm = ui?.entityForm?.related?.form || {};
    const newRelated = R.mapObjIndexed((r) => r.map(payloadToRecord), uiForm);

    const workOrderForm = record.properties.workOrderForm;

    const newWorkOrderForm = {
      ...workOrderForm,
      properties: replaceUndefined(workOrderForm?.properties),
      related: newRelated,
    };

    const newRecord = materializeTemporaryIds(
      mergeChain(record)
        .prop("properties")
        .set("workOrderForm", materializeTemporaryIds(newWorkOrderForm))
        .output(),
    );

    return unwrap(R.mergeRight(form, { record: newRecord }));
  };
  render() {
    const { loading, error, type } = this.state;
    const { entity, isNew } = this.props;

    return (
      <>
        {loading && <LoadingIcon />}
        {error && <ApiError error={error} className="x-margin-top-40-i" />}
        <RecordEditController
          {...this.props}
          wrap={this.wrapRecord}
          unwrap={this.unwrapRecord}
          needSaveConfirmation={!isNew && type !== "E"}
          saveConfirmationMessage={_(
            "Updating {ENTITY_NAME} record will delete previously generated work order projections. If generate projections option is turned on, the previously generated work order projections will be re-generated with the updated information from the {ENTITY_NAME} record.",
          ).replace(/{ENTITY_NAME}/g, getLocalizedName(entity) || entity.name)}
        />
      </>
    );
  }
}
