import { WIDTH } from "common/vendor-wrappers/signature/consts";
import { LoadingIcon } from "../loading-icon";
import { Sizer, Sizes } from "../sizer";

interface SignatureImageProps {
  value: string;
  width?: number;
}

export const SignatureImage = ({
  value,
  width = WIDTH,
}: SignatureImageProps) =>
  value ? (
    <img className="x-signature-image" src={value} width={width} />
  ) : null;
SignatureImage.displayName = "SignatureImage";

interface SignatureImageWithSizerProps {
  value: string;
}

export const SignatureImageWithSizer = ({
  value,
}: SignatureImageWithSizerProps) => {
  const renderSignature = ({ loading, width }: Sizes) => {
    return loading ? (
      <LoadingIcon />
    ) : (
      <SignatureImage value={value} width={width} />
    );
  };
  return <Sizer render={renderSignature} />;
};
SignatureImageWithSizer.displayName = "SignatureImageWithSizer";
