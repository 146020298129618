import { GeolocationWrapper, Props } from "common/vendor-wrappers/geolocation";

import "./index.scss";

export const GeolocationInput = ({ readonly, value, onChange }: Props) => {
  return (
    <div className="x-geolocation">
      <GeolocationWrapper
        readonly={readonly}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
