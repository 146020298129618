import { v4 as uuid } from "uuid";
import { Comment } from "common/types/comments";

export const PAGE_SIZE = 10;

export const markNewComments = (
  comments: Comment[],
  userId: string,
): Comment[] =>
  comments.map((comment) => ({
    ...comment,
    isNew: comment.createdBy !== userId,
  }));

export const getComment = (
  entityName: string,
  recordId: string,
  text?: string,
): Comment => {
  return {
    id: uuid(),
    text,
    entityName,
    recordId,
    attachments: [],
  };
};
