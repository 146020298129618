import * as R from "ramda";
import { Component } from "react";
import { defaultFor } from "common";
import {
  filterRolesByIds,
  getFullPermissionRoleIds,
  isRoleWithFullPermissions,
} from "common/functions/roles";
import { Role } from "common/types/roles";
import { VerticalField } from "common/ui/field";
import { createList } from "common/widgets/fancy-checkbox/list";
import { YesNo } from "common/widgets/radio-button-list/yes-no";
import { ValueProps } from "common/with-value-for";

export interface RolesSettings {
  isGlobal: boolean;
  roleIds: number[];
}

interface PropTypes extends ValueProps<RolesSettings> {
  roles: Role[];
}

const List = createList<Role>();
const defaultValue = defaultFor<RolesSettings>();

export class Roles extends Component<PropTypes> {
  static readonly displayName = "Roles";

  onChange = (value: Role[]) => {
    const newRoles = (value || []).map((r) => r.id);
    this.props.onChange({ ...this.props.value, roleIds: newRoles });
  };

  onToggle = (isRestricted: boolean) => {
    const { roles, value, onChange } = this.props;
    const isGlobal = !isRestricted;
    onChange({
      ...value,
      isGlobal,
      roleIds: isRestricted ? getFullPermissionRoleIds(roles) : undefined,
    });
  };

  render() {
    const { roles = [], value = defaultValue } = this.props;
    const { isGlobal, roleIds = [] } = value;

    const roleInput = (
      <List
        display={R.prop("name")}
        isDisabled={isRoleWithFullPermissions}
        items={roles}
        value={filterRolesByIds(roles, roleIds)}
        onChange={this.onChange}
      />
    );

    return (
      <div className="qa-report-roles">
        <VerticalField
          label={_("Restrict report to specific roles?")}
          input={
            <YesNo name="isGlobal" onChange={this.onToggle} value={!isGlobal} />
          }
        />
        {!isGlobal ? (
          <VerticalField
            label={_("Select one or more roles")}
            input={roleInput}
          />
        ) : undefined}
      </div>
    );
  }
}
