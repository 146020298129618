import { Component } from "react";
import { FormValidationProps } from "common/form/types";
import { Context } from "common/types/context";
import { ModifyButtonLarge } from "common/ui/buttons";
import type { UsageArea } from "common/widgets/types";
import { ValueProps } from "common/with-value-for";
import { DocumentWithUploadController } from "../document/controller";
import { FancyCheckboxLarge } from "../fancy-checkbox";
import { StringInput } from "../input-with-submit/string";
import { isInternalSourceMedia } from "./functions";
import { MediaWebLinkLabel } from "./web-link-label";

interface Props extends ValueProps<string>, FormValidationProps {
  context: Context;
  entityName: string;
  columnName: string;
  recordId: string;
  allowClear: boolean;
  usageArea?: UsageArea;
}

interface StateType {
  isWebLinkMode: boolean;
  isWebLinkSet: boolean;
  webLinkInput: string;
}

export class MediaInputController extends Component<Props, StateType> {
  static readonly displayName = "MediaInputController";

  constructor(props: Props) {
    super(props);

    const { value } = props;
    this.state = {
      isWebLinkMode: value ? !isInternalSourceMedia(value) : false,
      isWebLinkSet: !!value,
      webLinkInput: value,
    };
  }

  onWebLinkChange = (checked: boolean) => {
    this.setState({
      isWebLinkMode: checked,
      isWebLinkSet: false,
      webLinkInput: undefined,
    });
    this.props.onChange(undefined);
  };

  onUploadWebLink = (webLinkInput: string) => this.setState({ webLinkInput });

  onApplyWebLink = () => {
    const { isWebLinkSet, webLinkInput } = this.state;
    this.setState({ isWebLinkSet: !isWebLinkSet });
    this.props.onChange(webLinkInput);
  };

  render() {
    const {
      context,
      entityName,
      recordId,
      allowClear,
      formValidation,
      columnName,
      onFormValidationChange,
      usageArea,
      value,
      onChange,
    } = this.props;
    const { isWebLinkMode, isWebLinkSet, webLinkInput } = this.state;

    return (
      <>
        <div className="x-media-input-controller">
          {!isWebLinkMode ? (
            <DocumentWithUploadController
              context={context}
              entityName={entityName}
              columnName={columnName}
              recordId={recordId}
              allowClear={allowClear}
              acceptType={"media"}
              uploaderLabel={_("Upload media")}
              formValidation={formValidation}
              onFormValidationChange={onFormValidationChange}
              usageArea={usageArea}
              value={value}
              onChange={onChange}
            />
          ) : (
            <>
              <StringInput
                placeholder={_("e.g. https://youtu.be/zrmlNkO50zE")}
                disabled={isWebLinkSet}
                value={webLinkInput}
                onChange={this.onUploadWebLink}
              />

              <ModifyButtonLarge
                className="x-margin-left-10"
                disabled={!isWebLinkSet && !webLinkInput}
                onClick={this.onApplyWebLink}
              >
                {isWebLinkSet ? _("Edit") : _("Apply")}
              </ModifyButtonLarge>
            </>
          )}

          {!value || isWebLinkMode ? (
            <div className="x-padding-left-10">
              <FancyCheckboxLarge
                label={_("Media weblink")}
                value={isWebLinkMode}
                onChange={this.onWebLinkChange}
              />
            </div>
          ) : undefined}
        </div>
        {isWebLinkMode && isWebLinkSet ? (
          <MediaWebLinkLabel
            context={context}
            className="x-margin-top-10"
            url={value}
            isEditMode={true}
          />
        ) : undefined}
      </>
    );
  }
}
