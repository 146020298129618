import * as R from "ramda";
import { Component } from "react";
import { displayEntity } from "common/api/entities";
import { searchApi } from "common/api/search";
import { validateGroupColumns } from "common/form/functions/validation";
// eslint-disable-next-line import/no-cycle
import { GroupField } from "common/form/group/field";
import { findEntityByArgs } from "common/functions/entity";
import { mergeChain } from "common/merge";
import {
  getAssetMeterQuery,
  getMaxReadingPerDay,
  getMeters,
  getMeterType,
  getPreviousReading,
  isValid,
} from "common/record/form/content/related/asset-meter-reading/functions";
import { ApiErrorResponse } from "common/types/error";
import { Properties, Record } from "common/types/records";
import { ApiError } from "common/ui/api-error";
import { LoadingIcon } from "common/widgets/loading-icon";
import { Tab } from "common/widgets/tabs/tab";
import { Tabs } from "common/widgets/tabs/tabs";
import {
  getAssetMeterReadingEntity,
  getWarning,
} from "x/account-settings/forms/form/work-order-action/functions";
import { AssetMeterReading } from "./asset-meter-reading";
import { getActionLayout } from "./functions";
import { FormPropTypes } from "./types";

interface StateType {
  error: ApiErrorResponse;
  loading: boolean;
}

export class WorkOrderActionFormTabs extends Component<
  FormPropTypes,
  StateType
> {
  static readonly displayName = "WorkOrderActionFormTabs";

  state: StateType = {
    error: undefined,
    loading: false,
  };

  componentDidMount() {
    const { actionRecords, page } = this.props.value;
    const actionRecord = actionRecords.length && actionRecords[page];
    const hasAmr = !!actionRecord?.assetMeterReading;
    if (hasAmr) {
      this.fetchAssetWithMeters();
    }
  }

  componentDidUpdate(prevProps: FormPropTypes) {
    const { page, actionRecords } = this.props.value;
    const { assetMeters, meters } = actionRecords.length && actionRecords[page];
    if (
      prevProps.value.page !== page &&
      (!assetMeters.length || !meters.length)
    ) {
      this.fetchAssetWithMeters();
    }
  }

  fetchAssetWithMeters = () => {
    const { context, entity, value, onChange } = this.props;
    const { actionRecords, page } = value;

    const assetId = actionRecords[page].assetId;

    const assetMeterReadingEntity = getAssetMeterReadingEntity(
      entity,
      context.entities,
    );

    const assetEntityName = entity?.arguments?.assetEntity;
    const assetMeterEntity = findEntityByArgs(
      context.entities,
      "AssetMeter",
      "assetEntity",
      assetEntityName,
    );
    const query = getAssetMeterQuery(assetMeterEntity, assetId);

    this.setState({ loading: true });
    searchApi(context.apiCall)
      .runQueryFkExpansion(query)
      .then((assetMeterProperties: Properties[] = []) => {
        const assetMeters: Record[] = assetMeterProperties.map(
          (properties) => ({
            properties,
            actions: [],
          }),
        );
        return getMeters(context, assetMeterReadingEntity, assetMeters).then(
          (meters = []) => {
            const { actionRecords, page } = this.props.value;
            const newRecord = mergeChain(actionRecords[page])
              .setMany({
                meters,
                assetMeters,
              })
              .output();
            const newRecords = R.update(page, newRecord, actionRecords);
            onChange({ ...value, actionRecords: newRecords });
            this.setState({ loading: false });
          },
        );
      })
      .catch((error) => this.setState({ loading: false, error }));
  };

  onChange =
    (key: "properties" | "assetMeterReading") => (properties: Properties) => {
      const { value, onChange } = this.props;
      const { actionRecords, page } = value;
      const newRecord = { ...actionRecords[page], [key]: properties };
      const newRecords = R.update(page, newRecord, actionRecords);

      onChange({ ...value, actionRecords: newRecords });
    };

  render() {
    const { context, entity, actionName, value } = this.props;
    const { error, loading } = this.state;
    const { actionRecords = [], page } = value;

    if (error) return <ApiError error={error} />;

    const {
      properties,
      assetMeterReading,
      assetId,
      assetMeters = [],
      meters = [],
    } = actionRecords.length && actionRecords[page];

    const actionForm = getActionLayout(context, entity, actionName, properties);
    const columns = actionForm?.columns || [];

    const amrEntity = getAssetMeterReadingEntity(entity, context.entities);
    const previousReading = getPreviousReading(assetMeterReading, assetMeters);
    const meterType = getMeterType(assetMeterReading, assetMeters, meters);
    const maxReadingPerDay = getMaxReadingPerDay(
      assetMeterReading?.assetMeterId,
      assetMeters,
    );
    const isAmrValid = isValid(
      assetMeterReading,
      previousReading,
      meterType?.id,
      maxReadingPerDay,
    );

    return (
      <div className="x-wo-action-tabs-container">
        {loading && <LoadingIcon />}
        <Tabs key={page} className="x-wo-action-tabs">
          {properties && (
            <Tab
              key="general"
              value="general"
              label={_("General Information")}
              warningIcon={
                !validateGroupColumns(entity.columns, properties, columns)
              }
            >
              {getWarning(columns, entity.columns)}
              <div className="x-padding-bottom-20 x-wo-action">
                {columns.map((layoutColumn, colIndex) => (
                  <GroupField
                    key={colIndex}
                    context={context}
                    entity={entity}
                    groups={undefined}
                    layoutColumn={layoutColumn}
                    withLinks={true}
                    formValidation={undefined}
                    onFormValidationChange={undefined}
                    value={properties}
                    onChange={this.onChange("properties")}
                  />
                ))}
              </div>
            </Tab>
          )}

          {assetMeterReading && (
            <Tab
              key="assetMeterReading"
              value="assetMeterReading"
              label={displayEntity(amrEntity)}
            >
              <div>{_("* Creating an Asset Meter Reading is optional")}</div>
              <AssetMeterReading
                context={context}
                assetMeterReadingEntity={amrEntity}
                assetId={assetId}
                assetMeters={assetMeters}
                meters={meters}
                meterTypeId={meterType?.id}
                previousReading={previousReading}
                isValid={isAmrValid}
                value={assetMeterReading}
                maxReadingPerDay={maxReadingPerDay}
                onChange={this.onChange("assetMeterReading")}
              />
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }
}
