import { Component } from "react";
import { Context } from "common/types/context";
import { defaultFor } from "common";
import { getRelatedEntities } from "common/entities";
import { Entities, Entity } from "common/entities/types";
import { addToSelectQuery } from "common/query/select";
import {
  isSummaryField,
  Query,
  Select as QuerySelect,
} from "common/query/types";
import { EntitySelector } from "common/widgets/selector/entity-selector";
import { ValueProps } from "common/with-value-for";
import { Select } from "./select";
import { Field } from "./types";

interface PropTypes extends ValueProps<Query> {
  context: Context;
  fields: Field[];
  entities: Entities;
  entity: Entity;
}

export class SelectWithEntity extends Component<PropTypes> {
  static readonly displayName = "SelectWithEntity";

  onAddSummary = (entity: Entity) => {
    const { value, onChange } = this.props;
    onChange(addToSelectQuery([{ entityName: entity.name }], value));
  };

  onChangeSelect = (select: QuerySelect) => {
    const { value, onChange } = this.props;
    onChange({ ...value, select });
  };

  render() {
    const {
      context,
      entity,
      entities,
      value = defaultFor(),
      fields,
    } = this.props;
    const { select } = value;

    const relatedEntities = getRelatedEntities(entity, entities).filter(
      (entity) =>
        !select.find((s) => isSummaryField(s) && s.entityName === entity.name),
    );

    const entitySelector = (
      <div className="x-select-related-config">
        <div className="x-related-config-controls">
          <EntitySelector
            className="x-select-entity"
            entities={relatedEntities}
            required={false}
            value={undefined}
            onChange={this.onAddSummary}
          />
        </div>
      </div>
    );

    return (
      <Select
        context={context}
        allowAggregates={false}
        forceFkAggregates={true}
        allowLong={false}
        entities={entities}
        fields={fields}
        entitySelector={entitySelector}
        withExpressions={true}
        value={select}
        onChange={this.onChangeSelect}
      />
    );
  }
}
