import { Component } from "react";
import { ConditionalRequired } from "common/widgets/conditional-required";
import { LabelWidget } from "common/form/widget/label-widget";
import { searchApi } from "common/api/search";
import { workOrderSettingsApi } from "common/api/work-order-settings";
import { findColumn, getByBehaviorArgument, getColumn } from "common/entities";
import {
  findGroupColumn,
  isReadOnlyColumn,
} from "common/form/functions/layout";
import {
  GroupColumn,
  MapWidgetPropsFn,
  QuickInputNodeProps,
} from "common/form/types";
import { Widget } from "common/form/widget";
import { getFkId } from "common/functions/foreign-key";
import { getIntFkId } from "common/functions/system-int";
import { merge2, merge3 } from "common/merge";
import {
  ActionHandlerValue,
  getActionHandlersByBehaviors,
} from "common/record/actions/handlers";
import { getFormOrDefault } from "common/record/edit/functions";
import { StandardRelated } from "common/record/form/content/related/standard-related";
import { PropTypes } from "common/record/form/content/related/table-with-form/types";
import { RelatedValue } from "common/record/form/content/related/types";
import { ForeignKey } from "common/types/foreign-key";
import { ProcedureTypes } from "common/types/procedure-type";
import { CancellablePromise } from "common/types/promises";
import { Properties, Record, RecordPayload } from "common/types/records";
import { SystemIntFk } from "common/types/system-int";
import { WorkOrderSettings } from "common/types/work-order-settings";
import { Hint } from "common/widgets/hint";
import { ValueProps } from "common/with-value-for";
import { getPartialForm } from "../functions";
import { MultipleWorkOrderAssetsDropdown } from "../multiple-work-order-assets-dropdown";
import { normalizeIds } from "../table-with-form/functions";
import {
  getProcedureTypeHint,
  getQuery,
  getRecordWithUrgentRepairDescription,
  injectMeterTypeIntoLayout,
  isProcedureMeterReadingType,
  isProcedureValid,
  METER_TYPE_COLUMN_NAME,
  PROCEDURE_TYPE_COLUMN_NAME,
} from "./functions";
import { ProcedureTypeContent } from "./procedure-type-content";

type Props = PropTypes & ValueProps<RelatedValue>;

interface StateType {
  urgentRepairProcedureId: string;
  modalValue: ActionHandlerValue;
  workOrderSettings: WorkOrderSettings;
}

const PROCEDURE_STATUS_COLUMN_NAME = "procedureStatusId";
const ASSET_COLUMN_NAME = "assetId";

export class ProcedureType extends Component<Props, StateType> {
  static readonly displayName = "ProcedureType";

  state: StateType = {
    urgentRepairProcedureId: undefined,
    modalValue: undefined,
    workOrderSettings: undefined,
  };

  componentDidMount() {
    const { context, onChange } = this.props;
    CancellablePromise.all([
      workOrderSettingsApi(context.apiCall).get(),
      this.loadProcedures(),
    ])
      .then(([settings, formValue]) => {
        this.setState({ workOrderSettings: settings });

        onChange(formValue);
      })
      .catch(() => undefined);
  }

  loadProcedures = () => {
    const { context, entity, parentEntity, value, isTemplate } = this.props;

    const assetsEntity = context.entities[parentEntity?.arguments.assetEntity];
    const procedures = value.related.form?.[entity?.name];
    const assets = procedures?.map((a) => a.properties?.assetId);
    if (!assets || !isTemplate) return CancellablePromise.resolve(value);

    return searchApi(context.apiCall)
      .runQueryFkExpansion(getQuery(assetsEntity, assets, "in"))
      .then((records: ForeignKey[]) => {
        const newProcedures: RecordPayload[] = procedures.map((procedure) => {
          const procedureAssetId = getFkId(procedure.properties?.assetId);
          return {
            ...procedure,
            properties: {
              ...procedure.properties,
              assetId: records.find((r) => r.id === procedureAssetId),
            },
          };
        });

        const newValue = merge3(
          "related",
          "form",
          entity.name,
          newProcedures,
          value,
        );

        return newValue;
      });
  };

  onChangeForm = (properties: Properties) => {
    const { entity, value, onChange } = this.props;

    const newValue = merge3(
      "related",
      "partialForm",
      entity.name,
      properties,
      value,
    );

    onChange(newValue);
  };

  onProcedureAssetChange = (assetId: ForeignKey) => {
    const { entity, value } = this.props;
    const partialForm = getPartialForm(value, entity.name);

    const newPartialForm: Properties = {
      ...partialForm,
      assetId,
    };

    this.onChangeForm(newPartialForm);
  };

  onMeterTypeChange = (meterId: Properties) => {
    const { entity, value } = this.props;
    const partialForm = getPartialForm(value, entity.name);

    const newPartialForm: Properties = {
      ...partialForm,
      meterId,
    };

    this.onChangeForm(newPartialForm);
  };

  onRelatedRecordAdd = (record: Record) => {
    const { value, context, parentEntity, onChange } = this.props;

    const workOrderAssetEntity = getByBehaviorArgument(
      context.entities,
      "WorkOrderAsset",
      "workOrderEntity",
      parentEntity.name,
    );

    const previousRecords: Record[] =
      value.related?.form?.[workOrderAssetEntity.name]?.map((a) => {
        return {
          properties: a.properties,
          actions: workOrderAssetEntity.commands,
        };
      }) || [];

    const updatedValue = merge2("related", "isDirty", true, value);

    const newValue = merge3(
      "related",
      "form",
      workOrderAssetEntity.name,
      normalizeIds([...previousRecords, record]),
      updatedValue,
    );

    onChange(newValue);
  };

  onProcedureTypeChange = (procedureType: SystemIntFk) => {
    const { entity, value } = this.props;
    const partialForm = getPartialForm(value, entity.name);

    const newPartialForm: Properties = {
      ...partialForm,
      procedureTypeId: procedureType,
      procedureStatusId: undefined,
    };
    this.onChangeForm(newPartialForm);
  };

  onChangeUrgentRepairModal = (modalValue: ActionHandlerValue) => {
    this.setState({ modalValue });
  };

  onDismissUrgentRepair = () => {
    this.setState({
      modalValue: undefined,
      urgentRepairProcedureId: undefined,
    });

    const { entity, value } = this.props;

    const partialForm = getPartialForm(value, entity.name);

    const newPartialForm: Properties = {
      ...partialForm,
      procedureStatusId: undefined,
    };
    this.onChangeForm(newPartialForm);
  };

  onPerformUrgentRepair = () => {
    this.setState({
      modalValue: undefined,
      urgentRepairProcedureId: undefined,
    });
  };

  getFollowUpActionUi = (procedureId: string) => {
    const { context, entity, parentEntity, value, goTo } = this.props;
    const { record } = value;

    const actionHandlers = getActionHandlersByBehaviors(parentEntity.behaviors);
    const FollowUpActionUi = actionHandlers?.CreateFollowUp?.Ui;

    const recordWithUrgentRepairDescription =
      getRecordWithUrgentRepairDescription(record, entity.name, procedureId);

    return FollowUpActionUi ? (
      <FollowUpActionUi
        goTo={goTo}
        query={undefined}
        context={context}
        entity={parentEntity}
        records={[recordWithUrgentRepairDescription]}
        extraProperties={{ urgentRepairProcedureId: procedureId }}
        allowFullscreen={false}
        value={this.state.modalValue}
        dismiss={this.onDismissUrgentRepair}
        onChange={this.onChangeUrgentRepairModal}
        onPerform={this.onPerformUrgentRepair}
      />
    ) : null;
  };

  getMeterTypeContent = (
    properties: Properties,
    meterTypeColumn: GroupColumn,
  ) => {
    const { context, entity } = this.props;
    const { required, disabled, hint } = meterTypeColumn;

    const meterEntityColumn = getColumn(entity, METER_TYPE_COLUMN_NAME);
    const readOnlyColumn = isReadOnlyColumn(
      entity,
      meterTypeColumn,
      context.role,
    );

    return (
      <>
        <Widget
          key="meterId"
          buffer={false}
          entityName={entity.name}
          recordId={undefined}
          context={context}
          column={meterEntityColumn}
          withLinks={true}
          required={required}
          disabled={disabled}
          readOnly={readOnlyColumn}
          formValidation={undefined}
          onFormValidationChange={undefined}
          value={properties.meterId}
          onChange={this.onMeterTypeChange}
        />
        {hint ? <Hint key={hint} message={hint} /> : undefined}
      </>
    );
  };

  widgetsMapper: MapWidgetPropsFn = (properties, layout, isNew) => {
    const {
      context,
      entity,
      parentEntity,
      isTemplate,
      value,
      recordDetail,
      onChange,
    } = this.props;

    const { form } = properties;
    const { entities } = context;
    const { urgentRepairProcedureId, workOrderSettings } = this.state;

    const layoutSetting =
      layout ??
      getFormOrDefault(context, entity.name, form?.formId, form)?.settings;

    const procedureTypeLayoutColumn = layoutSetting
      ? findGroupColumn(PROCEDURE_TYPE_COLUMN_NAME, layoutSetting)
      : undefined;
    const procedureTypeColumn = getColumn(entity, PROCEDURE_TYPE_COLUMN_NAME);

    const { hint } = procedureTypeLayoutColumn || {};

    const procedureStatusColumn = layoutSetting
      ? findGroupColumn(PROCEDURE_STATUS_COLUMN_NAME, layoutSetting)
      : undefined;

    const assetColumn = layoutSetting
      ? findGroupColumn(ASSET_COLUMN_NAME, layoutSetting)
      : undefined;

    const procedureId = properties.id || properties.tempId;
    const procedureTypeId = getIntFkId(properties.procedureTypeId);

    const { workOrderPerEntitySettings = [] } = workOrderSettings || {};
    const shouldDisableUrgentRepair = workOrderPerEntitySettings.find(
      (s) => s.entityName === parentEntity.name,
    )?.shouldDisableUrgentRepairProcedure;

    const workOrderAssetEntity = getByBehaviorArgument(
      entities,
      "WorkOrderAsset",
      "workOrderEntity",
      parentEntity.name,
    );

    const existingWorkOrderAssets =
      (workOrderAssetEntity &&
        value.record.related?.[workOrderAssetEntity.name]) ||
      [];

    const workOrderAssets =
      (workOrderAssetEntity &&
        value.related?.form?.[workOrderAssetEntity.name]) ||
      [];

    const currentWorkOrderAssets = workOrderAssets
      .concat(existingWorkOrderAssets)
      .filter((a) => !a.properties.isDeleted)
      .map((a) => a.properties.assetId);

    const eventAssetEntity = getByBehaviorArgument(
      entities,
      "EventAsset",
      "scheduledEventEntity",
      parentEntity.arguments?.scheduledEventEntity,
    );

    const templateAssets =
      (eventAssetEntity && value.record.related?.[eventAssetEntity.name]) || [];
    const filteredTemplateAssets = isTemplate
      ? templateAssets.map((r) =>
          r.properties.isDeleted ? undefined : r.properties.assetId,
        )
      : [];

    const mainAsset =
      recordDetail?.form?.assetId || value.record.properties.assetId;

    const assets = currentWorkOrderAssets
      .concat(isTemplate ? filteredTemplateAssets : [mainAsset])
      .filter((a) => a);

    const isAssetColumnReadOnly = isReadOnlyColumn(
      entity,
      assetColumn,
      context.role,
    );

    const isMeterReadingType = procedureTypeId === ProcedureTypes.MeterReading;
    const hasAssetMeterReadingId = !!properties?.assetMeterReadingId?.id;

    const meterTypeGroupColumn = layoutSetting
      ? findGroupColumn(METER_TYPE_COLUMN_NAME, layoutSetting)
      : undefined;

    const meterTypeColumn: GroupColumn = {
      ...meterTypeGroupColumn,
      columnName: "meterId",
      required: true,
      disabled: hasAssetMeterReadingId,
    };

    const onUrgentRepairProcedureIdChange = (procedureId: string) =>
      this.setState({ urgentRepairProcedureId: procedureId });

    const shouldDisplayFollowUpActionUi =
      urgentRepairProcedureId &&
      procedureId &&
      urgentRepairProcedureId === procedureId;

    const getStatusQuickInput = (props: QuickInputNodeProps) => (
      <>
        <ProcedureTypeContent
          context={context}
          entity={entity}
          isQuickInput={true}
          parentEntity={parentEntity}
          properties={properties}
          shouldDisableUrgentRepair={shouldDisableUrgentRepair}
          onChangeForm={this.onChangeForm}
          onUrgentRepairProcedureIdChange={onUrgentRepairProcedureIdChange}
          recordDetail={recordDetail}
          isDisabled={props.isDisabled}
          value={value}
          onChange={onChange}
        />
        {shouldDisplayFollowUpActionUi
          ? this.getFollowUpActionUi(procedureId)
          : undefined}
      </>
    );

    const isProcedureTypeColumnReadOnly = isReadOnlyColumn(
      entity,
      procedureTypeLayoutColumn,
      context.role,
    );

    const isProcedureStatusColumnReadOnly = isReadOnlyColumn(
      entity,
      procedureStatusColumn,
      context.role,
    );
    const procedureStatusInputNode = procedureStatusColumn ? (
      isProcedureStatusColumnReadOnly ? (
        <div className="x-read-only-label-wrapper">
          <LabelWidget
            context={context}
            column={findColumn(entity.columns, PROCEDURE_STATUS_COLUMN_NAME)}
            value={properties.procedureStatusId}
          />
        </div>
      ) : (
        <>
          <ProcedureTypeContent
            context={context}
            entity={entity}
            isQuickInput={false}
            parentEntity={parentEntity}
            properties={properties}
            shouldDisableUrgentRepair={shouldDisableUrgentRepair}
            onChangeForm={this.onChangeForm}
            onUrgentRepairProcedureIdChange={onUrgentRepairProcedureIdChange}
            recordDetail={recordDetail}
            isDisabled={undefined}
            value={value}
            onChange={onChange}
          />
          {procedureStatusColumn.hint ? (
            <Hint
              key={procedureStatusColumn.hint}
              message={procedureStatusColumn.hint}
            />
          ) : undefined}
        </>
      )
    ) : undefined;

    return {
      ...(procedureTypeLayoutColumn
        ? {
            [PROCEDURE_TYPE_COLUMN_NAME]: {
              inputNode: (
                <>
                  <Widget
                    key="procedureTypeId"
                    buffer={false}
                    entityName={entity.name}
                    recordId={undefined}
                    context={context}
                    column={procedureTypeColumn}
                    withLinks={true}
                    required={true}
                    disabled={hasAssetMeterReadingId}
                    readOnly={isProcedureTypeColumnReadOnly}
                    formValidation={undefined}
                    onFormValidationChange={undefined}
                    value={properties.procedureTypeId}
                    onChange={this.onProcedureTypeChange}
                  />
                  {!isProcedureTypeColumnReadOnly ? (
                    <>
                      {hint ? <Hint key={hint} message={hint} /> : undefined}
                      <Hint
                        key="procedureDesc"
                        hideLabel={!!hint}
                        message={getProcedureTypeHint(procedureTypeId)}
                      />
                    </>
                  ) : undefined}
                </>
              ),
            },
          }
        : undefined),
      [METER_TYPE_COLUMN_NAME]: {
        ...{
          inputNode: this.getMeterTypeContent(properties, meterTypeColumn),
          omitColumn: !isMeterReadingType,
          disableQuickAddRelated: hasAssetMeterReadingId,
        },
      },
      [ASSET_COLUMN_NAME]: {
        ...(assetColumn
          ? {
              inputNode: (
                <ConditionalRequired
                  value={properties.assetId}
                  isRequired={assetColumn.required}
                  className="qa-label-name"
                >
                  {isAssetColumnReadOnly ? (
                    <div className="x-read-only-label-wrapper">
                      <LabelWidget
                        context={context}
                        column={findColumn(entity.columns, ASSET_COLUMN_NAME)}
                        value={properties.assetId}
                      />
                    </div>
                  ) : (
                    <>
                      <MultipleWorkOrderAssetsDropdown
                        context={context}
                        entity={entity}
                        workOrderAssetEntity={workOrderAssetEntity}
                        assets={assets}
                        placeholder={_("Type to search...")}
                        value={properties.assetId}
                        isNew={isNew}
                        isTemplate={isTemplate}
                        onChange={this.onProcedureAssetChange}
                        onRelatedRecordAdd={this.onRelatedRecordAdd}
                      />
                      {assetColumn.hint ? (
                        <Hint
                          key={assetColumn.hint}
                          message={assetColumn.hint}
                        />
                      ) : undefined}
                    </>
                  )}
                </ConditionalRequired>
              ),
            }
          : undefined),
      },
      [PROCEDURE_STATUS_COLUMN_NAME]: {
        ...(procedureStatusColumn
          ? {
              inputNode: procedureStatusInputNode,
              omitColumn: isMeterReadingType && !properties.id,
            }
          : undefined),
        quickInputNode: getStatusQuickInput,
        forceUpdate: true,
      },
      ...(properties.followUpId
        ? {
            tableActionsToOmit: { actions: ["update"] },
          }
        : undefined),
    };
  };

  render() {
    const { value, entity } = this.props;

    const layoutMapper = isProcedureMeterReadingType(value, entity.name)
      ? injectMeterTypeIntoLayout
      : undefined;

    return (
      <StandardRelated
        {...this.props}
        layoutMapper={layoutMapper}
        isValid={isProcedureValid}
        widgetsMapper={this.widgetsMapper}
      />
    );
  }
}
