import * as RealCancellablePromise from "real-cancellable-promise";

export type Resolve<T> = (value?: T | PromiseLike<T>) => void;
export type Reject = (reason?: any) => void;

/**
 * Cancellable Promise types override that allows us to separate from the
 * original library and make any further refactors/library changes easier.
 */
export class CancellablePromise<
  T,
> extends RealCancellablePromise.CancellablePromise<T> {}
export class Cancellation extends RealCancellablePromise.Cancellation {}
export const pseudoCancellable = RealCancellablePromise.pseudoCancellable;
