import { translateOption } from "x/scheduler2/shared/view-selector/functions";

interface PropTypes {
  option: string;
  onSelect: () => void;
  isSelected: boolean;
}

export const DropdownOption = ({ option, onSelect, isSelected }: PropTypes) => {
  const selectedClass = isSelected ? "x-btn-selected" : "";

  return (
    <li>
      <a
        key={option}
        className={`dropdown-item x-btn-${option} ${selectedClass}`}
        onClick={onSelect}
      >
        {translateOption(option)}
      </a>
    </li>
  );
};
