import { Projection } from "common/types/scheduled-event";
import { getLabel, getRescheduleText } from "../reason";

export interface RelatedRecordMap {
  [index: string]: string;
}
interface PropTypes {
  assetName: string;
  projection: Projection;
}

export const ProjectionComponent = ({ assetName, projection }: PropTypes) => {
  const reschedule = getRescheduleText(projection.reason);
  return (
    <div className="x-projection-asset">
      <h2>{assetName}</h2>
      <span className="x-projection-type">{getLabel(projection.reason)}</span>
      {reschedule ? (
        <div className="x-projection-alert">
          <p className="x-projection-alert-message">
            <i className="fa fa-exclamation-circle" />
            {reschedule}
          </p>
        </div>
      ) : undefined}
    </div>
  );
};
