import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import { SelectorOption } from "common/widgets/selector/types";
import { defaultFor } from "common";
import { HorizontalField, RequiredField, Section } from "common/form/ui";
import { Context } from "common/types/context";
import {
  LinkConfig,
  ReportConfig,
  Widget,
  WidgetType,
} from "common/types/dashboards";
import { hasPermissionToRead } from "common/functions/roles";
import { Selector } from "common/widgets/selector";
import { StringInput } from "common/widgets/input-with-submit/string";
import { ValueComponent } from "common/with-value-for";
import { LinkConfigForm } from "./link";
import { ReportConfigForm } from "./report";

interface PropTypes {
  context: Context;
}

const defaultValue = defaultFor<Widget>();

const translateWidgetType = (widgetType: WidgetType) => {
  switch (widgetType) {
    case "Link":
      return _("Link");
    case "Report":
      return _("Report");
    default:
      return _("Unknown");
  }
};

export const getWidgetTypeOptionLabel = (option: SelectorOption<WidgetType>) =>
  isGroupedOption(option) ? option.label : translateWidgetType(option);

export class WidgetForm extends ValueComponent<Widget, PropTypes> {
  static readonly displayName = "WidgetForm";

  onTypeChange = (type: WidgetType) => {
    this.mergeValue({
      type,
      config: undefined,
    });
  };

  render() {
    const { context, value = defaultValue } = this.props;
    const { userTypes, role, sites } = context;

    const canViewReports = hasPermissionToRead(userTypes, role, "Reports");
    const typeOptions: WidgetType[] = canViewReports
      ? ["Report", "Link"]
      : ["Link"];

    return (
      <Section>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <RequiredField
              label={_("Label")}
              labelBreakpoints="col-md-3"
              value={value.label}
            >
              <StringInput
                className="qa-widget-name"
                value={value.label}
                onChange={this.onChangeMergeValue("label")}
              />
            </RequiredField>
          </div>
          <div className="col-xs-12 col-md-6">
            <HorizontalField label={_("Type")} labelBreakpoints="col-md-3">
              <Selector<WidgetType>
                className="qa-widget-report"
                getOptionLabel={getWidgetTypeOptionLabel}
                options={typeOptions}
                value={value.type}
                onChange={this.onTypeChange}
              />
            </HorizontalField>
          </div>
        </div>
        <div className="x-edit-widget-config">
          <div className="col-xs-12 x-edit-widget-config-entry">
            {value.type === "Report" ? (
              <ReportConfigForm
                context={context}
                showMergeOption={sites.length > 1}
                value={value.config as ReportConfig}
                onChange={this.onChangeMergeValue("config")}
              />
            ) : value.type === "Link" ? (
              <LinkConfigForm
                value={value.config as LinkConfig}
                onChange={this.onChangeMergeValue("config")}
              />
            ) : undefined}
          </div>
        </div>
      </Section>
    );
  }
}
