import { Component } from "react";
import { FileType } from "common/types/media";
import { Uploader } from "common/widgets/file-selector/uploader";
import { mediaApi } from "common/api/media";
import { Context } from "common/types/context";
import { ValueProps } from "common/with-value-for";
import { CancellablePromise } from "common/types/promises";
import { getFileDetails } from "common/widgets/document/functions";
import { Preview } from "./preview";

interface PropTypes {
  context: Context;
  entityName: string;
  id: string;
}

type Props = PropTypes & ValueProps<string[]>;

export class AttachmentsWithUploadController extends Component<Props> {
  static readonly displayName = "AttachmentsWithUploadController";

  uploadAndGetUrlRequest: CancellablePromise<unknown>;

  componentWillUnmount() {
    this.uploadAndGetUrlRequest?.cancel();
  }

  uploadAttachment = (file: FileType) => {
    const { context, entityName, id, value, onChange } = this.props;

    this.uploadAndGetUrlRequest = mediaApi(context.apiCall)
      .uploadAndGetUrl(context.site.name, entityName, id, file)
      .then((url) => {
        const newAttachments = value.concat(url);
        onChange(newAttachments);
      });

    return this.uploadAndGetUrlRequest;
  };

  removeAttachment = (url: string) => {
    const { context, entityName, id, value, onChange } = this.props;
    const { name } = getFileDetails(url);

    mediaApi(context.apiCall).remove(entityName, id, name);

    const remainingFiles = value.filter((fileUrl) => fileUrl !== url);
    onChange(remainingFiles);
  };

  render() {
    const { value } = this.props;
    return (
      <>
        <Uploader
          className="x-attachment-uploader"
          uploadFile={this.uploadAttachment}
          buttonAsIcon={false}
          isNewFile={true}
          iconAddNewClass="fa-paperclip"
          acceptType="all"
          buttonText={_("Upload file")}
        />
        <Preview
          allowClear={true}
          onRemove={this.removeAttachment}
          attachments={value}
        />
      </>
    );
  }
}
