import { UserType } from "common/functions/users";
import { ApiCall } from "common/types/api";
import { LoginResult, LoginValue } from "common/types/authentication";
import { CancellablePromise } from "common/types/promises";
import { isRequestorUserType, isVendorUserType } from "common/types/users";
import { setLocationHref } from "common/utils/window-location";

export const requestorRoute = "requestor";
export const vendorRoute = "vendor";

export const adminAuthApi = (apiCall: ApiCall) => ({
  login: (value: LoginValue): CancellablePromise<LoginResult> =>
    apiCall("post", "api/admin/session", value),
  verifyCode: (value: LoginValue): CancellablePromise<LoginResult> =>
    apiCall("post", "api/admin/verify-code", value),
  stopImpersonating: (tenantName: string) =>
    apiCall("delete", "api/session/impersonate").then(() =>
      setLocationHref(`/admin#/accounts/${tenantName}`),
    ),
});

const getUrl = (userTypes: UserType[]) => {
  if (isRequestorUserType(userTypes)) {
    return requestorRoute;
  }
  if (isVendorUserType(userTypes)) {
    return vendorRoute;
  }
  return undefined;
};

export const logAsUser = (
  tenant: string = "",
  userId: string = "",
  userTypes: UserType[],
) => {
  const url = getUrl(userTypes);
  return `/api/session/impersonate?tenant=${tenant}&userId=${userId}${
    url ? `&redirect=${url}` : ""
  }`;
};
