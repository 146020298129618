import { getTrue } from "common";
import { Entity } from "common/entities/types";
import { getIntFkId } from "common/functions/system-int";
import { mergeChain } from "common/merge";
import { Context } from "common/types/context";
import { ProcedureTypes } from "common/types/procedure-type";
import { Properties } from "common/types/records";
import { SystemIntFk } from "common/types/system-int";
import { URGENT_REPAIR_PROCEDURE_STATUS_ID } from "common/types/system-strings";
import { RadioButtonList } from "common/widgets/radio-button-list";
import { ValueProps } from "common/with-value-for";
import { getPartialForm } from "../functions";
import { RelatedValue } from "../types";
import { getProcedureStatus, isUrgentRepairOption } from "./functions";

interface PropTypes extends ValueProps<RelatedValue> {
  context: Context;
  entity: Entity;
  isQuickInput: boolean;
  properties: Properties;
  shouldDisableUrgentRepair: boolean;
  isDisabled?: (item: SystemIntFk) => boolean;
  onChangeForm: (properties: Properties) => void;
  onUrgentRepairProcedureIdChange: (procedureId: string) => void;
}

export const OptionStatusContent = ({
  context,
  entity,
  isQuickInput,
  properties,
  shouldDisableUrgentRepair,
  isDisabled,
  onChangeForm,
  onUrgentRepairProcedureIdChange,
  value,
  onChange,
}: PropTypes) => {
  // PassFailUrgentRepair and YesNoNotApplicable procedures status change
  const onRadioButtonsStatusQuickInputChange =
    (procedureId: string) => (procedureStatus: SystemIntFk) => {
      const isUrgentRepair =
        procedureStatus?.id === URGENT_REPAIR_PROCEDURE_STATUS_ID;

      if (isUrgentRepair) {
        return onUrgentRepairProcedureIdChange(procedureId);
      }

      const oldProcedures =
        value.related.form?.[entity.name] ||
        value.record.related?.[entity.name];

      const updatedProcedures = oldProcedures.map((p) =>
        p.properties.id === procedureId || p.properties.tempId === procedureId
          ? {
              ...p,
              properties: {
                ...p.properties,
                procedureStatusId: procedureStatus,
              },
            }
          : p,
      );
      const newValue = mergeChain(value)
        .prop("related")
        .set("isDirty", true)
        .prop("form")
        .set(entity.name, updatedProcedures)
        .output();

      onChange(newValue);
    };

  // PassFailUrgentRepair and YesNoNotApplicable procedures status change
  const onRadioButtonsStatusChange =
    (procedureId: string) => (procedureStatus: SystemIntFk) => {
      const statusOptions = context.systemTables?.ProcedureStatus || [];
      const statusValue = statusOptions.find(
        (o) => o.id === procedureStatus?.id,
      );
      const isUrgentRepair =
        statusValue?.id === URGENT_REPAIR_PROCEDURE_STATUS_ID;

      if (isUrgentRepair) {
        return onUrgentRepairProcedureIdChange(procedureId);
      }

      const partialForm = getPartialForm(value, entity.name);
      const newPartialForm = {
        ...partialForm,
        procedureStatusId: statusValue?.id,
      };
      onChangeForm(newPartialForm);
    };

  const { id, tempId, followUpId } = properties;
  const { systemTables } = context;

  const procedureStatusId = getIntFkId(properties.procedureStatusId);
  const allProcedureStatus = systemTables?.ProcedureStatus || [];

  const currentStatusOption = allProcedureStatus.find(
    (o) => o.id === procedureStatusId,
  );

  const mappedPassFailUrgentOptions = getProcedureStatus(allProcedureStatus, {
    procedureTypeId: ProcedureTypes.PassFailUrgentRepair,
  });

  const mappedYesNoNotApplicableOptions = getProcedureStatus(
    allProcedureStatus,
    {
      procedureTypeId: ProcedureTypes.YesNoNotApplicable,
    },
  );

  const passFailUrgentOptions =
    id && (followUpId || !shouldDisableUrgentRepair)
      ? mappedPassFailUrgentOptions.options
      : mappedPassFailUrgentOptions.options.filter(
          (o) => o.id !== URGENT_REPAIR_PROCEDURE_STATUS_ID,
        );

  const procedureTypeId = getIntFkId(properties.procedureTypeId);
  const isPassFailUrgentRepairType =
    procedureTypeId === ProcedureTypes.PassFailUrgentRepair;

  const items = isPassFailUrgentRepairType
    ? passFailUrgentOptions
    : mappedYesNoNotApplicableOptions.options;

  const onChangeValue = isQuickInput
    ? onRadioButtonsStatusQuickInputChange
    : onRadioButtonsStatusChange;

  const className = isQuickInput ? "x-no-bottom-margins" : undefined;
  const size = isQuickInput ? "small" : undefined;

  const isListDisabled = isDisabled?.(properties.procedureTypeId);

  return (
    <RadioButtonList
      name="procedureStatus"
      className={className}
      size={size}
      allowClear={!isListDisabled}
      isDisabled={
        isListDisabled || (isQuickInput && followUpId) ? getTrue : undefined
      }
      hasError={isUrgentRepairOption}
      display={
        (isPassFailUrgentRepairType
          ? mappedPassFailUrgentOptions
          : mappedYesNoNotApplicableOptions
        ).translate
      }
      items={items}
      value={items.find((o) => o.id === currentStatusOption?.id)}
      onChange={onChangeValue(id || tempId)}
    />
  );
};
