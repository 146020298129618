import * as React from "react";
import { Required } from "./required";

interface PropTypes {
  value: any;
  isRequired: boolean;
  className?: string;
}

export const ConditionalRequired = ({
  isRequired,
  className,
  value,
  children,
}: React.PropsWithChildren<PropTypes>) =>
  isRequired ? (
    <Required value={value} className={className}>
      {children}
    </Required>
  ) : (
    <>{children}</>
  );
