import * as R from "ramda";

export const DEFAULT_CURRENCY_DECIMAL_SCALE = 2;
export const MAXIMUM_DECIMAL_SCALE = 7;

export const parseDecimal = (value: string | number) => parseFloat(value + "");

export const decimalPlaceholder = (separator: string, scale: number) =>
  scale ? "0" + separator + R.repeat("0", scale).join("") : undefined;

export const getScaleAndPrecision = (num: string | number) => {
  const decimal = parseDecimal(num);
  const parsed = isNaN(decimal) ? "" : decimal.toString();
  const point = parsed.indexOf(".");
  return point === -1
    ? { precision: parsed.length, scale: 0 }
    : { precision: parsed.length - 1, scale: parsed.length - point - 1 };
};

export const formatDecimal = (value: string | number, scale: number) => {
  const decimal = parseDecimal(value);

  return isNaN(decimal) || !scale ? value : decimal.toFixed(scale);
};
