import * as R from "ramda";
import { Site, Sites } from "common/types/sites";

export const getDisplaySiteFn = (sitesDictionary: Sites) => {
  const sites = R.values(sitesDictionary);
  const hashCount = sites.reduce<{ [key: string]: number }>(
    (acc, { label }) => {
      const lowKey = label.toLowerCase();
      return { ...acc, [lowKey]: (acc[lowKey] || 0) + 1 };
    },
    {},
  );
  return ({ label, parentName }: Site) => {
    return parentName && hashCount[label.toLowerCase()] > 1
      ? `${label} (${sitesDictionary[parentName].label})`
      : label;
  };
};
