import * as R from "ramda";
import { returnAndNotify } from "common/api/with-notifications";
import { Culture } from "common/culture/supported-cultures";
import { Captions } from "common/culture/types";
import { ApiCall } from "common/types/api";
import { CancellablePromise } from "common/types/promises";
import { TranslationItems, Translations } from "common/types/translations";

const translateKeys = (
  apiCall: ApiCall,
  keyValues: Captions,
  from: string,
  to: string[],
) => {
  const keys = R.keys(keyValues);
  if (!keys.length) return CancellablePromise.resolve({});

  const values = keys.map((key) => keyValues[key]);

  const fromE = encodeURIComponent(from);
  const toE = encodeURIComponent(JSON.stringify(to));
  const textsE = encodeURIComponent(JSON.stringify(values));
  const query = `?texts=${textsE}&to=${toE}&from=${fromE}`;

  return apiCall("get", `/api/ui/translate${query}`).then(
    (translations: Translations) => {
      return to.reduce((acc: Translations, toKey: string) => {
        const translation = translations[toKey];

        const replaceKeys = keys.reduce(
          (acc: TranslationItems, key: string, index: number) =>
            R.mergeRight(acc, { [key]: translation[index] }),
          {},
        );

        return R.mergeRight(acc, { [toKey]: replaceKeys });
      }, {});
    },
  );
};

export const cultureApi = (apiCall: ApiCall) => ({
  list: () => apiCall("get", "api/admin/cultures"),
  create: (cultures: Culture[]) =>
    apiCall("post", "/api/admin/cultures", cultures).then(
      returnAndNotify(_("The cultures were successfully updated")),
    ),

  translateLanguage: (
    source: TranslationItems,
    from: string,
    to: string,
  ): CancellablePromise<TranslationItems> =>
    translateKeys(apiCall, source, from, [to]).then(
      (translation: Translations) => translation[to],
    ),

  translateLanguages: (
    source: TranslationItems,
    from: string,
    to: string[],
  ): CancellablePromise<Translations> =>
    translateKeys(apiCall, source, from, to),
});
