import * as R from "ramda";
import { defaultFor } from "common";
import { dashboardsApi } from "common/api/dashboards";
import { rolesApi } from "common/api/roles";
import { merge1 } from "common/merge";
import { Dashboard } from "common/types/dashboards";
import { Role } from "common/types/roles";
import { isAdminUserType } from "common/types/users";
import { ExternalPropTypes } from "common/ui/controllers/edit";
import {
  DependenciesComp,
  dependenciesInjected,
} from "common/with-dependencies";
import { createController } from "x/controllers/edit/with-ribbon-and-footer";
import { createInjected } from "x/controllers/edit/with-ribbon-and-footer/form";
import { DashboardFormType } from "x/dashboard/form/types";
import { DashboardForm } from "./form";
import { isValid } from "./form/functions";

type PropTypes = ExternalPropTypes<number, Dashboard>;

const EditController = createController<DashboardFormType, number, Dashboard>();
const injected = createInjected<DashboardFormType, number>();

interface EditControllerDependencies {
  roles: Role[];
}

const unwrap = (form: DashboardFormType): Dashboard => form.item;
const wrap = (form: DashboardFormType, db: Dashboard): DashboardFormType =>
  merge1("item", db, form);

const newDashboard = (userId: string): Dashboard => ({
  id: undefined,
  label: undefined,
  widgets: [],
  userId,
  labels: undefined,
  isGlobal: false,
});

const defaultValue = (
  isNew: boolean,
  id: number,
  dbs: Dashboard[],
  userId: string,
): DashboardFormType => {
  if (isNew) return wrap(defaultFor<DashboardFormType>(), newDashboard(userId));
  const db = R.find((c) => c.id === id, dbs);
  return db ? wrap(defaultFor<DashboardFormType>(), db) : undefined;
};

const getTitle = (value: DashboardFormType) => value?.item?.label;

export const DashboardEditController = (props: PropTypes) => {
  const { context, id, isNew } = props;
  const { dashboards, apiCall, site, userTypes } = context;

  const isAdmin = isAdminUserType(userTypes);
  const isYours =
    !isAdmin &&
    (isNew ||
      R.find((d) => d.id === id && d.userId === context.id, dashboards));

  return (
    <DependenciesComp<EditControllerDependencies>
      dependencies={isAdmin ? { roles: rolesApi(apiCall).list() } : undefined}
      child={
        <EditController
          {...dependenciesInjected<EditControllerDependencies>()}
          {...props}
          api={dashboardsApi(apiCall)}
          permissionCategory={isYours ? "any" : ""}
          wrapRecord={wrap}
          unwrapRecord={unwrap}
          crumbs={[{ name: _("Dashboards"), url: `#/${site.name}/dashboard` }]}
          getTitle={getTitle}
          isValid={isValid(dashboards)}
          dontLoad={true}
          defaultValue={defaultValue(isNew, id, dashboards, context.id)}
          confirmationTitle={_("The record will be deleted")}
          confirmationLabel={_("Yes, delete the record")}
        >
          <DashboardForm {...injected} context={context} />
        </EditController>
      }
    />
  );
};
