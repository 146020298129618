import * as R from "ramda";
import { genericSort } from "common/functions/generics";
import { pageSizes } from "common/types/pagination";
import { Selector } from "common/widgets/selector";
import { Pagination } from "common/widgets/pagination";

import "./footer.scss";

interface PropTypes {
  page: number;
  onChangePage: (page: number) => void;
  pageSize: number;
  onChangePageSize: (size: number) => void;
  totalRecords: number;
  loadingRecords: boolean;
}

export const PaginationFooter = ({
  page,
  onChangePage,
  pageSize,
  onChangePageSize,
  totalRecords,
  loadingRecords,
}: PropTypes) => {
  const minPageSizes = pageSizes.reduce(R.min);
  if (
    R.isNil(page) ||
    R.isNil(pageSize) ||
    R.isNil(totalRecords) ||
    totalRecords <= minPageSizes
  ) {
    return null;
  }

  const previousPageTotal = page * pageSize;
  const from = previousPageTotal + 1;
  const toMax = previousPageTotal + pageSize;
  const to = toMax > totalRecords ? totalRecords : toMax;

  return (
    <div className="x-pagination-footer x-flex-between-center">
      <Selector<number>
        className="x-selector-small"
        options={pageSizes}
        value={pageSize}
        onChange={onChangePageSize}
        sortOptions={genericSort}
      />
      {totalRecords > pageSize && (
        <Pagination
          itemsPerPage={pageSize}
          totalItems={totalRecords}
          loading={loadingRecords}
          value={page}
          onChange={onChangePage}
        />
      )}
      <div className="x-results-info x-padding-right-5">
        {_("Displaying {RANGE} of {TOTAL} results")
          .replace("{RANGE}", from === to ? `${from}` : `${from}-${to}`)
          .replace("{TOTAL}", `${totalRecords}`)}
      </div>
    </div>
  );
};
PaginationFooter.displayName = "PaginationFooter";
