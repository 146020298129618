import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { Entity } from "common/entities/types";
import { CheckAvailability } from "common/record/form/content/related/event/check-availability";
import { propertiesToEventProperties } from "common/record/form/content/related/event/functions";

interface PropTypes {
  context: Context;
  entity: Entity;
  properties: Properties;
}

export const DetailBehavior = ({ context, entity, properties }: PropTypes) => (
  <CheckAvailability
    context={context}
    entity={entity}
    eventProps={propertiesToEventProperties(properties)}
  />
);

DetailBehavior.displayName = "DetailBehavior";
