import { HighlightProps, Label } from "common/widgets/label";

interface PropTypes extends HighlightProps {
  url: string;
}

export const WebLinkLabel = ({ url, textToHighlight }: PropTypes) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    <Label value={url} textToHighlight={textToHighlight} />
  </a>
);
WebLinkLabel.displayName = "WebLinkLabel";
