import { Component } from "react";
import { ModifyButtonLarge } from "common/ui/buttons";
import { T1000 } from "common/widgets/text";
import { Context } from "common/types/context";
import { Comment } from "common/types/comments";
import { ValueProps } from "common/with-value-for";
import { AttachmentsWithUploadController } from "./attachments";

interface PropTypes {
  context: Context;
  canAddComments?: boolean;
  onReload: () => void;
  onSave: () => void;
}

type Props = PropTypes & ValueProps<Comment>;

const isValidComment = (comment: Comment) =>
  comment.attachments?.length > 0 || comment.text?.trim();

export class CommentsInput extends Component<Props> {
  static readonly displayName = "CommentsInput";

  onChange = (newText: string) => {
    const { value, onChange } = this.props;
    onChange({ ...value, text: newText });
  };

  onAddAttachment = (attachments: string[]) => {
    const { value, onChange } = this.props;
    onChange({ ...value, attachments });
  };

  render() {
    const { context, value, canAddComments, onReload, onSave } = this.props;
    const { text, attachments, entityName, id } = value;

    return (
      <div className="x-comments-input-container">
        <div
          className="x-reload-button qa-reload-button"
          title={_("Reload comments")}
          onClick={onReload}
        >
          <i className="fa fa-refresh" />
        </div>
        {canAddComments ? (
          <>
            <div className="x-comment-input">
              <T1000
                placeholder={_("Type your message...")}
                autoHeight={true}
                withHint={false}
                value={text}
                onChange={this.onChange}
              />
              <AttachmentsWithUploadController
                context={context}
                entityName={entityName}
                id={id}
                value={attachments}
                onChange={this.onAddAttachment}
              />
            </div>
            <ModifyButtonLarge
              disabled={!isValidComment(value)}
              title={_("Save")}
              onClick={onSave}
            />
          </>
        ) : undefined}
      </div>
    );
  }
}
