import { Entity } from "common/entities/types";
import { QueryForEntity } from "common/query/types";
import { Pin } from "common/vendor-wrappers/leaflet/types";

export const getRecordQueryWithFilter = (
  entity: Entity,
  pinsToFilter: Pin[],
): QueryForEntity => {
  const query = entity.query;
  const queryFilter = pinsToFilter?.length
    ? {
        ...query.filter,
        and: [
          {
            name: "id",
            op: "notin",
            value: pinsToFilter.map((p) => p.id).join(","),
          },
        ],
      }
    : query.filter;

  return {
    entity: entity.name,
    query: {
      ...query,
      filter: queryFilter,
      includeColorCoding: true,
      fkExpansion: true,
      relatedSummary: {},
      page: 0,
      pageSize: 50,
    },
  };
};
