import { displayEntity } from "common/api/entities";
import { Event } from "common/types/events";
import { formatDate } from "common/date-time/formatters";
import { Entities } from "common/entities/types";
import { getEventLocalizedName } from "common/record/form/content/audit-trail/utils";
import { Site } from "common/types/sites";
import { UiFormat } from "common/types/ui-format";
import { getDateTimeFormat } from "common/widgets/date/date-time-label";
import { LinkRecord } from "common/widgets/link-record";
import { getSiteLabelByName } from "x/account-settings/sites/functions";

interface PropTypes {
  event: Event;
  entities: Entities;
  sites: Site[];
  uiFormat: UiFormat;
}

export const WallItem = ({ event, entities, sites, uiFormat }: PropTypes) => {
  if (!event) return null;
  const { name, userName, details, id } = event;
  const { entityName, site, occurredOn, recordId } = details;
  const entity = entities[entityName];

  return (
    <LinkRecord site={site} entity={entity} id={recordId} auditTrailId={id}>
      <div className="x-wall-item qa-wall-item">
        <div className="x-history-panel-title qa-history-panel-title">
          {displayEntity(entity) + " - " + getSiteLabelByName(sites, site)}
        </div>
        <div className="x-history-panel-content qa-history-panel-content">
          {getEventLocalizedName(name, userName)}
        </div>
        <div className="x-history-panel-footer qa-history-panel-footer-1">
          {_("by {USERNAME}").replace("{USERNAME}", userName)}
        </div>
        <div className="x-history-panel-footer qa-history-panel-footer-2">
          {_("on DATE").replace(
            "DATE",
            formatDate(occurredOn, getDateTimeFormat(uiFormat)),
          )}
        </div>
      </div>
    </LinkRecord>
  );
};
