import { withValue, WithValue } from "common/with-value";
import { FormValue } from "common/record/types";
import { MapLayoutFn } from "common/form/types";
import { Crumb } from "x/layout/ribbon/breadcrumb";
import { FormWithLayout } from "x/records/edit/form-with-layout";
import { RecordForm } from "x/records/edit/record-form";
import {
  FormWithRibbon,
  PropTypes as FormWithRibbonProps,
} from "./form-with-ribbon";

export interface PropTypes extends FormWithRibbonProps {
  mapLayout?: MapLayoutFn;
  hideRibbon?: boolean;
  crumbs?: Crumb[];
}

export const EditRecordForm = withValue<FormValue, PropTypes>(
  ({
    context,
    goTo,
    entity,
    starred,
    toggleStar,
    loading,
    hidePanels,
    runQuery,
    withLinks,
    reload,
    eventId,
    auditTrailId,
    saving,
    deleting,
    deleteLabels,
    isNew,
    disableEdit,
    save,
    onDelete,
    onCancel,
    onHasChanged,
    hideRibbon,
    crumbs,
    value,
    onChangeSetValue,
    mapLayout,
    getUrl,
  }: PropTypes & WithValue<FormValue>) => (
    <FormWithLayout
      context={context}
      entity={entity}
      loading={loading}
      value={value}
      mapLayout={mapLayout}
    >
      {hideRibbon ? (
        <RecordForm
          layout={undefined} // injected by FormWithLayout
          context={context}
          entity={entity}
          runQuery={runQuery}
          save={save}
          saving={saving}
          onDelete={onDelete}
          deleting={deleting}
          onCancel={onCancel}
          onHasChanged={onHasChanged}
          isNew={isNew}
          goTo={goTo}
          withLinks={withLinks}
          loading={loading}
          reload={reload}
          eventId={eventId}
          auditTrailId={auditTrailId}
          deleteLabels={deleteLabels}
          disableEdit={disableEdit}
          value={value}
          onChange={onChangeSetValue}
          getUrl={getUrl}
        />
      ) : (
        // this guy is just another RecordForm + EditRibbon. we can refactor and put this logic here
        <FormWithRibbon
          layout={undefined} // injected by FormWithLayout
          context={context}
          entity={entity}
          runQuery={runQuery}
          save={save}
          saving={saving}
          onDelete={onDelete}
          deleting={deleting}
          onCancel={onCancel}
          onHasChanged={onHasChanged}
          isNew={isNew}
          goTo={goTo}
          withLinks={withLinks}
          loading={loading}
          reload={reload}
          eventId={eventId}
          auditTrailId={auditTrailId}
          deleteLabels={deleteLabels}
          disableEdit={disableEdit}
          hidePanels={hidePanels}
          crumbs={crumbs}
          starred={starred}
          toggleStar={toggleStar}
          value={value}
          onChange={onChangeSetValue}
          getUrl={getUrl}
        />
      )}
    </FormWithLayout>
  ),
  "EditRecordForm",
);
