import {
  rethrowAndNotify,
  returnAndNotify,
} from "common/api/with-notifications";
import { Event } from "common/types/events";
import { ApiCall } from "common/types/api";

type SubmitCount = {
  finalCount: number;
  cost?: number;
  batchId?: string;
};

export type SubmitAllCount = {
  partsToBeCountedId: string;
  finalCount: number;
  cost?: number;
  batchId?: string;
};

export interface PartsDetailsResponse {
  partsToBeCountedId: string;
  partId: string;
  partLocationId: string;
  finalCount: number;
  onHand: number;
  lastCost: number;
  statusId: number;
  manualBatchSelection: boolean;
}

export const cycleCountApi = (apiCall: ApiCall) => ({
  submit: (entity: string, id: string, payload: SubmitCount) =>
    apiCall<Event[]>(
      "put",
      `api/entities/:site/${entity}/${id}/submit`,
      payload,
    )
      .then(returnAndNotify(_("The cycle count was successfully submitted")))
      .catch(rethrowAndNotify(_("Failed to submit the cycle count"))),
  cancel: (entity: string, id: string) => {
    return apiCall<Event[]>(
      "put",
      `api/entities/:site/${entity}/${id}/cancel-count`,
    )
      .then(returnAndNotify(_("The cycle count was successfully canceled")))
      .catch(rethrowAndNotify(_("Failed to cancel the cycle count")));
  },
  submitAll: (
    cycleCountEntity: string,
    id: string,
    totalPartsToBeCountedRecords: number,
    payloads: SubmitAllCount[] = [],
  ) =>
    apiCall<Event[]>(
      "put",
      `api/entities/:site/${cycleCountEntity}/${id}/submit-all`,
      payloads,
    )
      .then(
        returnAndNotify(
          _(
            "{SUBMITTED_NUMBER} / {TOTAL_NUMBER} records were successfully submitted",
          )
            .replace("{SUBMITTED_NUMBER}", payloads.length?.toString())
            .replace(
              "{TOTAL_NUMBER}",
              totalPartsToBeCountedRecords?.toString(),
            ),
        ),
      )
      .catch(rethrowAndNotify(_("Failed to submit the cycle count"))),
  getPartsDetails: (cycleCountEntity: string, id: string) =>
    apiCall<PartsDetailsResponse[]>(
      "get",
      `api/entities/:site/${cycleCountEntity}/${id}/parts-details`,
    ).catch(rethrowAndNotify(_("Failed to submit the cycle count"))),
});
