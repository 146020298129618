import { Component } from "react";
import { Entity } from "common/entities/types";
import { FormValidationProps } from "common/form/types";
import { ExternalFormValue } from "common/scheduled-event/form/external/types";
import { Context } from "common/types/context";
import { External } from "common/types/scheduled-event";
import { ValueProps } from "common/with-value-for";
import { CustomFieldsForm } from "../custom-fields";
import { DescriptionForm } from "../description";
import { TaskForm } from "../tasks";

interface PropTypes extends ValueProps<ExternalFormValue>, FormValidationProps {
  context: Context;
  entity: Entity;
  woEntity: Entity;
}

export class DetailsForm extends Component<PropTypes> {
  static readonly displayName = "DetailsForm";

  onExternalChange = (external: External) => {
    const { value, onChange } = this.props;
    onChange({
      ...value,
      record: {
        ...value.record,
        properties: external,
      },
    });
  };

  render() {
    const {
      context,
      entity,
      woEntity,
      formValidation,
      onFormValidationChange,
      value,
      onChange,
    } = this.props;
    const { properties } = value.record;

    return (
      <div className="x-pm-trigger form-horizontal">
        {entity.arguments.taskEntity ? (
          <TaskForm
            context={context}
            entities={context.entities}
            entity={entity}
            value={value}
            onChange={onChange}
          />
        ) : undefined}
        <DescriptionForm value={value} onChange={onChange} />
        <CustomFieldsForm
          context={context}
          woEntity={woEntity}
          entity={entity}
          formValidation={formValidation}
          onFormValidationChange={onFormValidationChange}
          value={properties}
          onChange={this.onExternalChange}
        />
      </div>
    );
  }
}
