import * as R from "ramda";
import { CancellablePromise } from "common/types/promises";
import { SelectorOption } from "common/widgets/selector/types";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import {
  FormatMeta,
  LabelledOptionOrGroup,
} from "common/vendor-wrappers/react-select/types";
import { addressBookApi } from "common/api/address-book";
import { Field } from "common/query-builder/types";
import { EmailForeignKey } from "common/types/address-book";
import { email } from "common/validate";
import { Chicklets } from "common/widgets/chicklets";
import { Selector } from "common/widgets/selector";
import { AsyncSelector } from "common/widgets/selector/async-selector";
import {
  formatChicklet,
  formatField,
  getAddressBookProcessingFn,
  getEmailFields,
  getFieldPlaceholder,
  getMaxLengthString,
  isValidChicklet,
  omitSelectedFields,
} from "common/widgets/email-chicklets-selector/functions";
import { PropTypes } from "common/widgets/email-chicklets-selector/types";
import { formatFkItemForDropdown } from "common/widgets/record-selector/functions";
import { ValueComponent } from "common/with-value-for";

import "./index.scss";

const MAX_SELECTOR_EMAILS_PER_ENTITY = 50;

// TODO ReceiverSelectors use the same logic, but converted to functional
//  use it as example to convert EmailChickletsSelector to functional
//  and then replace some part in ReceiverSelectors to EmailChickletsSelector
export class EmailChickletsSelector extends ValueComponent<
  string[],
  PropTypes
> {
  static readonly displayName = "EmailChickletsSelector";

  onEmailSelected = (emailFk: EmailForeignKey) => {
    this.onNewEmailAdded(emailFk.email);
  };

  onEmailFieldSelected = (emailField: Field) => {
    const { value = [] } = this.props;
    this.setValue(value.concat(getFieldPlaceholder(emailField)));
  };

  onNewEmailAdded = (newEmail: string) => {
    const { value = [] } = this.props;
    this.setValue(value.concat(newEmail));
  };

  formatOptionLabel = (
    item: LabelledOptionOrGroup<EmailForeignKey>,
    meta: FormatMeta<EmailForeignKey>,
  ) => {
    const { context, entity } = this.props;

    if (isGroupedOption(item) || typeof item.value === "string") {
      return item.label;
    }

    const { value } = item;

    return formatFkItemForDropdown(
      "image" in value,
      !!value.subtitle,
      !!value.subsubtitle,
      !!value.customtitle,
      entity,
      context,
    )(value, meta?.inputValue);
  };

  formatCreateLabel = (inputValue: string) => {
    return (
      <>{_('Add new "{SEARCH_VALUE}"').replace("{SEARCH_VALUE}", inputValue)}</>
    );
  };

  shouldAddNew = (inputValue: string) => {
    return inputValue && email(inputValue);
  };

  fetchEmails = (term: string, limit: number) => {
    const { context } = this.props;
    const { apiCall, site } = context;

    return addressBookApi(apiCall)
      .get(site.name)(term, limit)
      .then((results) => {
        return R.toPairs(results).map((emailsForEntity) => ({
          label: emailsForEntity[0],
          options: emailsForEntity[1],
        }));
      }) as CancellablePromise<SelectorOption<EmailForeignKey>[]>;
  };

  mapOptions = (options: SelectorOption<EmailForeignKey>[]) => {
    const { context, value } = this.props;
    return getAddressBookProcessingFn(context, value)(options);
  };

  render() {
    const {
      context,
      maxLength,
      value = [],
      hasFieldSelector,
      entity,
    } = this.props;

    const maxLengthDisabled = maxLength ? value.length >= maxLength : false;
    const placeholder = maxLengthDisabled
      ? getMaxLengthString(maxLength)
      : undefined;
    const emailFields = getEmailFields(context.entities, entity);

    return (
      <>
        <div className="x-email-recipient-selector">
          <AsyncSelector<EmailForeignKey>
            fetch={this.fetchEmails}
            mapOptions={this.mapOptions}
            formatOptionLabel={this.formatOptionLabel}
            formatCreateLabel={this.formatCreateLabel}
            placeholder={placeholder}
            disabled={maxLengthDisabled}
            shouldAddNew={this.shouldAddNew}
            addNew={this.onNewEmailAdded}
            optionsLimit={MAX_SELECTOR_EMAILS_PER_ENTITY}
            value={undefined}
            onChange={this.onEmailSelected}
          />
          {hasFieldSelector && (
            <div className="x-email-column-selector">
              <label className="x-field-label">{_("Field")}</label>
              <Selector
                placeholder={_("Select a field")}
                options={omitSelectedFields(emailFields, value)}
                getOptionLabel={formatField}
                value={undefined}
                onChange={this.onEmailFieldSelected}
              />
            </div>
          )}
        </div>
        {value.length ? (
          <Chicklets
            className="x-padding-top-5"
            isValidChicklet={isValidChicklet}
            display={formatChicklet(emailFields)}
            value={value}
            onChange={this.onChangeSetValue}
          />
        ) : undefined}
      </>
    );
  }
}
