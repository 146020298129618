import { ForeignKey } from "common/types/foreign-key";
import { withValue, WithValue } from "common/with-value";
import { AdvancedRecordSelectorBase } from "./base";
import { RecordSelectorPropTypes } from "./types";
import { AdvancedSearchCtrl } from "./advanced-search";

const MultiRecordSelectorComp = (
  props: RecordSelectorPropTypes & WithValue<ForeignKey[]>,
) => (
  <AdvancedRecordSelectorBase
    {...props}
    content={undefined}
    allowMultipleSelect={true}
    onChange={props.onChangeSetValue}
    dontKeepValue={true}
    SearchCtrlComp={AdvancedSearchCtrl}
  />
);

export const MultiRecordSelector = withValue<
  ForeignKey[],
  RecordSelectorPropTypes
>(MultiRecordSelectorComp, "MultiRecordSelector");
