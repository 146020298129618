import { PropsWithChildren } from "react";
import { getLocalizedName } from "common";
import { getUniqueColumn, getUrl } from "common/entities";
import { Entity } from "common/entities/types";
import { Reload } from "common/record/types";
import type { ApplicationScope } from "common/types/context";
import { Record } from "common/types/records";
import { Ribbon } from "x/layout/ribbon";
import { getAccountSettingsCrumb, Crumb } from "x/layout/ribbon/breadcrumb";

interface PropTypes {
  site: string;
  entity: Entity;
  record: Record;
  reload: Reload;
  scope: ApplicationScope;
  crumbs?: Crumb[];
}

const getRecordCrumb = (
  site: string,
  entity: Entity,
  uniqueColumn: string,
  record: Record,
  crumbs?: Crumb[],
): Crumb => {
  const property = record.properties[uniqueColumn];
  const isDeleted = record.properties.isDeleted;
  const crumbsRelativeUrl = crumbs?.[crumbs.length - 1]?.url;
  return record.properties.id
    ? {
        name:
          ((property && property.value) || property || "") +
          ((isDeleted && ` ${_("Archived")}`) || ""),
        url: crumbsRelativeUrl
          ? `${crumbsRelativeUrl}/${record.properties.id}`
          : `${getUrl(entity, site)}/${record.properties.id}`,
      }
    : {
        name: _("New"),
      };
};

export const RecordRibbon = ({
  site,
  entity,
  record,
  reload,
  scope,
  crumbs,
  children,
}: PropsWithChildren<PropTypes>) => {
  const referencesCrumbs: Crumb[] =
    entity && entity.type === "Reference"
      ? [
          getAccountSettingsCrumb(),
          { name: "References", url: `#/${site}/admin/References` },
        ]
      : [];

  const entityCrumb: Crumb = {
    name: getLocalizedName(entity),
    url: `${getUrl(entity, site)}`,
  };
  const recordCrumb: Crumb = record
    ? getRecordCrumb(site, entity, getUniqueColumn(entity).name, record, crumbs)
    : { name: `${_("Loading")}...` };

  const allCrumbs = crumbs
    ? [...crumbs, recordCrumb].filter((c) => !!c)
    : [...referencesCrumbs, entityCrumb, recordCrumb].filter((c) => !!c);

  return (
    <Ribbon
      onRefresh={reload}
      scope={scope}
      crumbs={allCrumbs}
      className="qa-record-detail-ribbon"
    >
      {children}
    </Ribbon>
  );
};

RecordRibbon.displayName = "RecordRibbon";
