import { VariantType } from "react-tooltip";
import { getTooltipProps } from "common/vendor-wrappers/react-tooltip";

const getTooltip = (message: string, variant: VariantType = "info") =>
  getTooltipProps(message, variant);

export const getRequiredIcon = (): JSX.Element => (
  <i
    className="fa fa-asterisk x-required-icon"
    {...getTooltip("This field is required", "error")}
  />
);
