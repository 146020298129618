import { Component } from "react";
import { Context } from "common/types/context";
import { Entity } from "common/entities/types";
import { getRelated } from "common/record/sidebar/functions";
import { hasPermissionTo } from "common/functions/roles";
import { Action } from "common/types/roles";
import {
  EditControllerBase,
  ExternalPropTypes as ExternalPropTypesBase,
  PropTypesBase,
} from "./base";

// This is the same as base, except that we make canDo from context

export const hasPermissionToEntity = (
  context: Context,
  entity: Entity,
  action: Action,
) => {
  if (hasPermissionTo(context.userTypes, context.role, entity.name, action))
    return true;

  const relatedEntities = getRelated(context, entity, undefined, undefined);

  return relatedEntities.some((r) =>
    hasPermissionTo(context.userTypes, context.role, r.entity, action),
  );
};

export interface ExternalPropTypes<TId, TRecord>
  extends ExternalPropTypesBase<TId, TRecord> {
  context: Context;
}

export interface PropTypes<TValue, TId, TRecord>
  extends PropTypesBase<TValue, TId, TRecord> {
  context: Context;
  restoring?: boolean;
  permissionCategory: string;
  confirmationTitle?: string;
  confirmationLabel?: string;
  deleteWarningMessage?: string;
  saveConfirmationMessage?: string;
}

export function createEditCtrlWithPermission<TValue, TId, TRecord>() {
  type Props = PropTypes<TValue, TId, TRecord>;

  class EditController extends Component<Props> {
    can = (action: Action): boolean => {
      const { context, entity, permissionCategory } = this.props;
      const { userTypes, role } = context;
      return entity
        ? hasPermissionToEntity(context, entity, action)
        : hasPermissionTo(userTypes, role, permissionCategory, action);
    };

    render() {
      const {
        entity,
        id,
        api,
        isNew,
        onHasChanged,
        confirmationTitle,
        canDelete,
        canRestore,
        forceFetch,
        onDelete,
        onNotFound,
        onRecordChanged,
        needSaveConfirmation,
        saveConfirmationMessage,
        skipDeleteConfirmation,
        onRestore,
        onSave,
        onPreSave,
        restoring,
        wrapRecord,
        unwrapRecord,
        confirmationLabel,
        defaultValue,
        dependencies,
        dontLoad,
        onCancel,
        deleteWarningMessage,
        children,
        // used only for the "can" check:
        // context,
        // permissionCategory
      } = this.props;

      return (
        <EditControllerBase<TValue, TId, TRecord>
          // review. seem unused
          passError={undefined}
          // only logic this does
          canDo={this.can}
          // pass down things
          children={children}
          deleteWarningMessage={deleteWarningMessage}
          entity={entity}
          id={id}
          isNew={isNew}
          api={api}
          wrapRecord={wrapRecord}
          unwrapRecord={unwrapRecord}
          onHasChanged={onHasChanged}
          needSaveConfirmation={needSaveConfirmation}
          saveConfirmationMessage={saveConfirmationMessage}
          skipDeleteConfirmation={skipDeleteConfirmation}
          onPreSave={onPreSave}
          confirmationTitle={confirmationTitle}
          canDelete={canDelete}
          canRestore={canRestore}
          forceFetch={forceFetch}
          onDelete={onDelete}
          onNotFound={onNotFound}
          onRecordChanged={onRecordChanged}
          onRestore={onRestore}
          onSave={onSave}
          restoring={restoring}
          confirmationLabel={confirmationLabel}
          defaultValue={defaultValue}
          dependencies={dependencies}
          dontLoad={dontLoad}
          onCancel={onCancel}
        />
      );
    }
  }

  return EditController;
}
