import { Children, cloneElement, ReactNode } from "react";
import { getRequiredIcon } from "common/ui/field-label-icon";
import { classNames } from "common/utils/jsx";

interface PropTypes {
  className?: string;
  label?: string;
  error?: boolean;
  input: ReactNode;
  addOn?: ReactNode;
  isRequired?: boolean;
}

export const VerticalField = ({
  label,
  className = "",
  error,
  input,
  addOn,
  isRequired,
}: PropTypes) => {
  if (!input) return null;

  const inputWithLabel = Children.toArray(input).map((c: any) =>
    cloneElement(c, { label: c.props?.label ?? label }),
  );
  const fieldClassName = classNames([
    "x-field",
    className,
    error ? "x-has-error" : undefined,
    isRequired ? "x-required-field" : undefined,
  ]);

  return (
    <fieldset className={fieldClassName}>
      <label className="x-field-label">
        {label}
        {label && isRequired ? getRequiredIcon() : undefined}
      </label>
      {addOn ? (
        <div className="x-field-input x-flex x-flex-between-start">
          <div className="x-field-input-item">{inputWithLabel}</div>
          <div className="x-field-addon">{addOn}</div>
        </div>
      ) : (
        <div className="x-field-input">
          <div className="x-field-input-item">{inputWithLabel}</div>
        </div>
      )}
    </fieldset>
  );
};

VerticalField.displayName = "VerticalField";
