import { Component } from "react";
import { Context } from "common/types/context";
import { Entities } from "common/entities/types";
import { JoinItem } from "common/query/types";
import { DeleteButton } from "common/ui/buttons";
import { Modal } from "common/widgets/modal";
import { ValueProps } from "common/with-value-for";
import { Config } from "x/layout/explorer-panel/explorer/types";
import { ConfigComponent } from "./config";

interface PropTypes extends ValueProps<Config> {
  context: Context;
  entities: Entities;
  entity: string;
  joins: JoinItem[];
  onApply: () => any;
  onReset: () => any;
  onCancel: () => any;
}

export class ExplorerModal extends Component<PropTypes> {
  onChangeConfig = (value: Config) => {
    this.props.onChange(value);
  };

  render() {
    const {
      context,
      entities,
      entity,
      joins,
      onApply,
      onReset,
      onCancel,
      value,
    } = this.props;
    const content = (
      <ConfigComponent
        context={context}
        value={value}
        entities={entities}
        entity={entity}
        joins={joins}
        onChange={this.onChangeConfig}
      />
    );
    const deleteButton = (
      <DeleteButton role="button" onClick={onReset}>
        {_("Reset")}
      </DeleteButton>
    );
    return (
      <Modal
        content={content}
        size="large"
        title={_("Modify Table Filter")}
        okLabel={_("Apply")}
        onOk={onApply}
        onCancel={onCancel}
        footerButtons={deleteButton}
      />
    );
  }
}
