import { Query, Secondaries } from "common/query/types";

export interface Filter {
  id: number;
  name: string;
  entity: string;
  shared: boolean;
  owned: boolean;
  query: Query;
  createdBy?: string;
  secondaryQueries?: Secondaries;
}

export const defaultFilter: Filter = {
  id: undefined,
  name: undefined,
  entity: undefined,
  shared: undefined,
  owned: undefined,
  query: undefined,
};
