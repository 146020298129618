import * as R from "ramda";
import { frequencyOptions } from "common/scheduled-event/functions";
import {
  IntervalOption,
  PmFrequency as Value,
} from "common/types/scheduled-event";
import { ActionButtonLarge, DeleteButton } from "common/ui/buttons";
import { Selector } from "common/widgets/selector";
import { Uint } from "common/widgets/number";
import { Required } from "common/widgets/required";
import { ValueComponent } from "common/with-value-for";

export const defaultFrequency: Value = {
  value: undefined,
  interval: "m",
  shadow: false,
};

export const displayInterval = (i: IntervalOption) => i && i.label;

export class Frequency extends ValueComponent<Value> {
  static readonly displayName = "Frequency";

  onAddFrequency = () => {
    this.setValue(defaultFrequency);
  };

  onChangeFrequency = (frequency: string) => {
    const newValue = parseInt(frequency, 10) || undefined;
    this.mergeValue({ value: newValue });
  };

  onChangeInterval = (interval: IntervalOption) => {
    this.mergeValue({ interval: interval.value });
  };

  render() {
    const { value } = this.props;
    const options = frequencyOptions();
    const selectedInterval =
      value && value.interval
        ? R.find((option) => option.value === value.interval, options)
        : options[0];
    return value ? (
      <div className="x-flex">
        <div className="flex-grow-1 x-flex x-se-frequency qa-frequency">
          <fieldset className="x-value qa-value">
            <Required value={value.value}>
              <Uint
                placeholder={_("Every")}
                className="qa-every"
                value={value.value}
                onChange={this.onChangeFrequency}
              />
            </Required>
          </fieldset>
          <fieldset className="x-interval qa-interval">
            <Selector<IntervalOption>
              getOptionLabel={displayInterval}
              options={options}
              value={selectedInterval}
              onChange={this.onChangeInterval}
            />
          </fieldset>
        </div>
        <div className="flex-grow-0 qa-remove-frequency">
          <DeleteButton
            className="x-float-right x-margin-left-10"
            onClick={this.onChangeSetDefaultValue(undefined)}
            title={_("Remove")}
          >
            <i className="fa fa-times" />
          </DeleteButton>
        </div>
      </div>
    ) : (
      <ActionButtonLarge
        className="x-se-frequency-add qa-se-frequency-add"
        title={_("Add frequency")}
        onClick={this.onAddFrequency}
      >
        {_("Add frequency")}
      </ActionButtonLarge>
    );
  }
}
