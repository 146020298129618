import * as R from "ramda";
import { Component } from "react";
import { LinkFk } from "common/widgets/foreign-key/link-fk";
import { UTCDateTime } from "common/date-time/types";
import { Entity } from "common/entities/types";
import { getAssetMeterEntities } from "common/scheduled-event/functions";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import {
  MeterState,
  PmTrigger,
  ScheduledEventAssetRecord,
} from "common/types/scheduled-event";
import { VerticalField } from "common/ui/field";
import { classNames } from "common/utils/jsx";
import { ConditionalRequired } from "common/widgets/conditional-required";
import { Date } from "common/widgets/date/date";
import { Required } from "common/widgets/required";
import { ValueProps } from "common/with-value-for";
import {
  AssetMeterSelector,
  AssetMeterSelectorValue,
} from "./asset-meter-selector";
import { EventAssetMeter } from "./meter";

interface PropTypes extends ValueProps<ScheduledEventAssetRecord> {
  context: Context;
  pmTrigger: PmTrigger;
  assetEntity: Entity;
  meterEntity: Entity;
}

interface ForeignKeyWithAsset extends ForeignKey {
  assetId: string;
}

export class EventAsset extends Component<PropTypes> {
  static readonly displayName = "EventAsset";

  onChangeLastDate = (lastDate: UTCDateTime) => {
    const { pmTrigger, value, onChange } = this.props;
    const lastDateType = pmTrigger.frequency.shadow
      ? "lastCloseDate"
      : "lastOpenDate";

    onChange({
      ...value,
      properties: {
        ...value.properties,
        [lastDateType]: lastDate,
      },
    });
  };

  onAddMeter = (assetMeterId: AssetMeterSelectorValue) => {
    const { value, onChange } = this.props;
    const { properties } = value;
    const newMeter: MeterState = {
      assetMeterId,
      lastTriggered: undefined,
      threshold: undefined,
    };
    onChange({
      ...value,
      properties: {
        ...properties,
        meters: (properties.meters || []).concat(newMeter),
      },
    });
  };

  assetMeterFilter = (m: ForeignKeyWithAsset) => {
    const { value } = this.props;

    if (m.assetId !== value.properties.assetId.id) return false;

    return !R.find(
      (i) => i.assetMeterId && i.assetMeterId.id === m.id,
      value.properties.meters || [],
    );
  };

  render() {
    const { pmTrigger, context, assetEntity, meterEntity, value, onChange } =
      this.props;
    const { frequency } = pmTrigger;

    const assetMeterEntity = getAssetMeterEntities(
      context.entities,
      assetEntity,
      meterEntity,
    )[0];

    const { meters = [] } = value.properties;

    const metersValue = frequency || meters.length ? meters : undefined;

    return (
      <div className="x-se-pm-asset qa-se-pm-asset">
        <div className="x-se-pm-asset-wrapper x-flex">
          <div className="x-se-pm-asset-title">
            <LinkFk context={context} value={value.properties.assetId} />
          </div>
          <div className="x-se-pm-asset-triggers">
            {frequency ? (
              <div className="x-se-pm-asset-frequency">
                <ConditionalRequired
                  value={value.properties.lastOpenDate}
                  isRequired={!pmTrigger.frequency.shadow}
                >
                  <VerticalField
                    label={_("Last Open Date")}
                    className={classNames([
                      "x-se-pm-asset-label qa-last-open-date",
                      !pmTrigger.frequency.shadow ? "x-highlighted" : undefined,
                    ])}
                    input={
                      <Date
                        uiFormat={context.uiFormat}
                        allowClear={false}
                        disabled={pmTrigger.frequency.shadow}
                        value={value.properties.lastOpenDate}
                        onChange={this.onChangeLastDate}
                      />
                    }
                  />
                </ConditionalRequired>
                <ConditionalRequired
                  value={value.properties.lastCloseDate}
                  isRequired={pmTrigger.frequency.shadow}
                >
                  <VerticalField
                    label={_("Last Close Date")}
                    className={classNames([
                      "x-se-pm-asset-label qa-last-close-date",
                      pmTrigger.frequency.shadow ? "x-highlighted" : undefined,
                    ])}
                    input={
                      <Date
                        uiFormat={context.uiFormat}
                        allowClear={false}
                        disabled={!pmTrigger.frequency.shadow}
                        value={value.properties.lastCloseDate}
                        onChange={this.onChangeLastDate}
                      />
                    }
                  />
                </ConditionalRequired>
              </div>
            ) : undefined}
            {meterEntity ? (
              <div className="qa-se-asset-meter-selector">
                <Required value={metersValue}>
                  <AssetMeterSelector
                    context={context}
                    entity={assetMeterEntity}
                    assetId={value.properties.assetId.id}
                    filter={this.assetMeterFilter}
                    value={undefined}
                    onChange={this.onAddMeter}
                  />
                </Required>
              </div>
            ) : undefined}
            {(value.properties.meters || []).map((meter, i) => {
              return (
                <EventAssetMeter
                  key={i}
                  meterValue={meter}
                  unit={undefined}
                  value={value}
                  onChange={onChange}
                />
              );
            })}

            {!frequency && !(value.properties.meters || []).length ? (
              <div className="x-se-empty-trigger">
                {meterEntity
                  ? _("Please configure a frequency or meter")
                  : _("Please configure a frequency")}
              </div>
            ) : undefined}
          </div>
        </div>
      </div>
    );
  }
}
