import { PopOut } from "common/widgets/pop-out";
import { getUrl } from "common/entities";
import { Entity } from "common/entities/types";

interface PropTypes {
  entity: Entity;
  site: string;
  id: string | number;
  title?: string;
}

export const RecordPopOut = ({ entity, site, id, title }: PropTypes) =>
  id ? (
    <PopOut
      url={`${getUrl(entity, site)}/${id}?hidePanels=true`}
      title={title}
    />
  ) : (
    <span />
  );

RecordPopOut.displayName = "RecordPopOut";
