import { isType } from "common";
import { getFkId, isForeignKey } from "common/functions/foreign-key";
import { isNumber, isString } from "common/functions/primitives";
import type { FkValue, ForeignKey } from "common/types/foreign-key";
import type { Omit } from "common/types/omit";
import type { FilterValue } from "x/scheduler2/types";

type SystemFk = Omit<ForeignKey, "id">;

const SYSTEM_INT_FK = "systemIntFk";
const SYSTEM_STRING_FK = "systemStringFk";
const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

const isSystemIntFk = (value: any): value is SystemFk =>
  value &&
  (isType<any, SystemFk>(["number", "title"], ["id"])(value) ||
    (isForeignKey(value) && value.title === SYSTEM_INT_FK));

const createSystemIntFk = (numberId: number): FkValue => ({
  id: EMPTY_GUID,
  number: numberId,
  title: SYSTEM_INT_FK,
});

const createSystemStringFk = (guid: string): FkValue => ({
  id: guid,
  number: 0,
  title: SYSTEM_STRING_FK,
});

export const filterValueToFk = (value: FilterValue) =>
  isNumber(value)
    ? createSystemIntFk(value)
    : isString(value)
      ? createSystemStringFk(value)
      : isForeignKey(value)
        ? value
        : undefined;

export const filterValueToId = (value: FilterValue) =>
  isNumber(value) ? value.toString() : getFkId(value);

export const fkToFilterValue = (value: FkValue): FilterValue =>
  value ? (isSystemIntFk(value) ? value.number : value) : undefined;
