import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { expandedRecordsReducer } from "common/app/redux/expanded-records";
import { referencesReducer } from "common/app/redux/references";

export const reducer = {
  expandedRecords: expandedRecordsReducer,
  references: referencesReducer,
};

export const store = configureStore({ reducer });

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: <TSelected>(
  selector: (state: AppState) => TSelected,
) => TSelected = useSelector;
