import * as R from "ramda";
import { SelectorOption } from "common/widgets/selector/types";
import { isSystemStringFk } from "common/functions/system-strings";
import { Context } from "common/types/context";
import { Currency } from "common/types/currencies";
import { SystemStringFkValue } from "common/types/system-strings";
import { ValueComponent } from "common/with-value-for";
import { Selector } from "../selector";

interface PropTypes {
  context: Context;
  placeholder?: string;
  allowClear?: boolean;
  disabled?: boolean;
  label?: string;
}

const formatCurrencyOption = (option: SelectorOption<Currency>) =>
  option?.label;

export class CurrencySelector extends ValueComponent<
  SystemStringFkValue,
  PropTypes
> {
  static readonly displayName = "CurrencySelector";

  onChange = (currency: Currency) => {
    this.setValue(currency?.id);
  };

  render() {
    const { placeholder, label, allowClear, disabled, value, context } =
      this.props;
    const { currencies = [] } = context;
    const currencyId = isSystemStringFk(value) ? value.id : value;
    const currencyValue = R.find((c) => c.id === currencyId, currencies);

    return (
      <Selector<Currency>
        placeholder={placeholder || `${_("Select currency")}...`}
        allowClear={allowClear}
        disabled={disabled}
        options={currencies}
        getOptionLabel={formatCurrencyOption}
        label={label}
        value={currencyValue}
        onChange={this.onChange}
      />
    );
  }
}
