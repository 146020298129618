import { Context } from "common/types/context";
import { isAdminUserType, isVendorUserType } from "common/types/users";
import { CancelButton, LinkButton } from "common/ui/buttons";
import { Link } from "common/widgets/link";
import { UserAvatar } from "common/widgets/user-avatar";
import { ValueProps } from "common/with-value-for";
import { FlukeMobileSettings } from "x/layout/header/fluke-mobile-settings";
import { SandboxResetForm } from "./sandbox-reset-form";

interface PropTypes extends ValueProps<string> {
  context: Context;
  site: string;
  logout: () => any;
}

export const UserPopover = ({
  context,
  logout,
  site,
  value,
  onChange,
}: PropTypes) => {
  const { name, role, userName, isEnabledFeature, userTypes, tenant } = context;
  const isVendor = isVendorUserType(userTypes);

  return (
    <div className="x-popover dropdown">
      <div className="x-logout-name x-flex hidden-xs" title={name}>
        <div className="x-user-name" data-testid="user-name">
          {name}
        </div>
        <div className="x-user-role" data-testid="user-role">
          {role?.name}
        </div>
      </div>
      <a
        data-bs-toggle="dropdown"
        className="x-header-button x-menu-user qa-menu-user"
        title={`${_("Account Settings")} / ${_("Log out")}`}
        tabIndex={0}
        role="button"
      >
        <div className="x-user-avatar-wrapper">
          <UserAvatar value={value} onChange={onChange} />
        </div>
      </a>
      <div className="x-dropdown-form dropdown-menu">
        <div className="x-content">
          <div className="x-image-wrapper">
            <UserAvatar iconClass="fa-4x" value={value} onChange={onChange} />
          </div>
          <div className="x-account">
            <span>{name}</span>
            <span>{userName}</span>
            {!isVendor && (
              <Link href={`/${site}/profile`} className="x-button-cancel-small">
                {_("Profile Settings")}
              </Link>
            )}
          </div>
        </div>
        <footer>
          {isVendor ? (
            <LinkButton
              href={`/${site}/profile`}
              className="qa-button-account-settings"
            >
              {_("Profile Settings")}
            </LinkButton>
          ) : (
            <LinkButton
              href={`/${site}/admin`}
              className="qa-button-account-settings"
            >
              {_("Account Settings")}
            </LinkButton>
          )}
          {isAdminUserType(userTypes) && tenant?.parentTenantName && (
            <SandboxResetForm context={context} />
          )}
          {isEnabledFeature("flukeMobile") && isAdminUserType(userTypes) && (
            <FlukeMobileSettings context={context} />
          )}
          <CancelButton className="qa-btn-logout" onClick={logout}>
            <i className="fa fa-sign-out" />
            {_("Log out")}
          </CancelButton>
        </footer>
      </div>
    </div>
  );
};
