import { EntityColumn } from "common/entities/entity-column/types";
import { getFkId, isForeignKey } from "common/functions/foreign-key";
import { Context } from "common/types/context";
import { FkValue } from "common/types/foreign-key";
import { FormattedFk } from "common/widgets/foreign-key";
import { HighlightProps } from "common/widgets/label";
import { LinkRecord } from "../link-record";
import { getFkRelatedEntityName } from "./functions";
import { FkFormat } from "./internal/types";

interface PropTypes extends HighlightProps {
  context: Context;
  value: FkValue; // can be expanded or not
  column?: EntityColumn;
  format?: FkFormat;
}

export const LinkFk = ({
  value,
  column,
  context,
  format,
  textToHighlight,
}: PropTypes) => (
  <LinkRecord
    entity={context.entities[getFkRelatedEntityName(column, value)]}
    site={context.site.name}
    id={getFkId(value)}
    isArchived={isForeignKey(value) ? value.isDeleted : undefined}
  >
    <FormattedFk
      context={context}
      column={column}
      format={format}
      value={value}
      textToHighlight={textToHighlight}
    />
  </LinkRecord>
);
