import { Component } from "react";
import { Context } from "common/types/context";
import { defaultFor } from "common";
import { recordsApi } from "common/api/records";
import { ApiCall } from "common/types/api";
import { ApiErrorResponse } from "common/types/error";
import { CancellablePromise } from "common/types/promises";
import { Properties, Record } from "common/types/records";
import { getUtcNow } from "common/date-time/common";
import { Entity } from "common/entities/types";
import { FormFooter } from "common/form/footer";
import { isFormDirty } from "common/form/functions/validation";
import { getDefaultValueForm } from "common/record/form/content/related/functions";
import { InjectedPropTypes } from "common/record/form/content/related/table-with-form/types";
import { ApiError } from "common/ui/api-error";
import { ResolvedDependencies } from "common/with-dependencies";
import { ValueProps } from "common/with-value-for";
import {
  ExecuteAction,
  DelayedApiCall,
} from "x/records/edit-controller/with-explicit-auth";
import {
  isExplicitAuthError,
  getMaxReadingPerDay,
  getMeterType,
  getPreviousReading,
  isValid,
} from "../functions";
import { AssetMeterData as Content } from "./content";

interface PropTypes extends InjectedPropTypes {
  context: Context;
  entity: Entity;
  assetMeters: Record[];
  meters: Properties[];
  assetId: string;
  dependencies?: ResolvedDependencies;
  onSavePerformed?: () => void;
  explicitAuth: DelayedApiCall;
  executeAction?: ExecuteAction;
}

type Props = PropTypes & ValueProps<Properties>;

interface StateType {
  saving?: boolean;
  error?: ApiErrorResponse;
}

const defaultValue = defaultFor<Properties>();

export class AssetMeterReadingForm extends Component<Props, StateType> {
  static readonly displayName = "AssetMeterReadingForm";
  state: StateType = {};

  componentDidUpdate(prevProps: Readonly<PropTypes>): void {
    if (prevProps.explicitAuth && !this.props.explicitAuth) {
      this.setState({ saving: false });
    }
  }

  doSave = (apiCall: ApiCall) => {
    const {
      entity,
      onSave,
      onSavePerformed,
      value = defaultValue,
      onChange,
    } = this.props;

    const record: Record = { properties: value, actions: [] };

    const promise = value.id
      ? recordsApi(apiCall).update(entity.name, record)
      : recordsApi(apiCall).create(entity.name, record);

    return promise
      .then(() => {
        onSave();
        if (onSavePerformed) onSavePerformed();
        onChange(undefined);
      })
      .catch((error) => {
        this.setState({ error });
        return CancellablePromise.reject<any>(error);
      });
  };

  onCancel = () => {
    const { onChange, onCancel } = this.props;
    onChange(undefined);
    onCancel?.();
  };

  onSave = () => {
    this.setState({ saving: true, error: undefined });
    const { value = defaultValue } = this.props;

    const record: Record = { properties: value, actions: [] };

    this.props.executeAction("save", record, undefined, this.doSave);
  };

  render() {
    const {
      context,
      entity,
      assetMeters,
      meters,
      assetId,
      dependencies,
      value = defaultValue,
      onChange,
    } = this.props;
    const { saving, error } = this.state;

    const previousReading = getPreviousReading(value, assetMeters);
    const meterType = getMeterType(value, assetMeters, meters);
    const isNew = !value?.id && !value?.tempId;
    const maxReadingPerDay = getMaxReadingPerDay(
      value.assetMeterId,
      assetMeters,
    );
    const valid = isValid(
      value,
      previousReading,
      meterType?.id,
      maxReadingPerDay,
    );

    return (
      <div className="x-related-component x-asset-meter-reading-form">
        <div className="x-form">
          <div className="x-form-content">
            {error && !isExplicitAuthError(error) ? (
              <ApiError error={error} />
            ) : undefined}
            <Content
              entity={entity}
              context={context}
              assetId={assetId}
              assetMeters={assetMeters}
              previousReading={previousReading}
              isValid={valid}
              maxReadingPerDay={maxReadingPerDay}
              meterTypeId={meterType?.id}
              value={{
                ...getDefaultValueForm(value, dependencies),
                date: value.date || getUtcNow(),
              }}
              onChange={onChange}
            />
          </div>
          <FormFooter
            isNew={undefined}
            noOverlay={true}
            isValid={valid}
            canDelete={false}
            onDelete={undefined}
            isDeleting={false}
            canRestore={false}
            onRestore={undefined}
            isRestoring={false}
            canSave={true}
            onSave={this.onSave}
            isSaving={saving}
            canCancel={true}
            cancelLabel={isNew ? _("Clear") : _("Cancel")}
            disableCancel={isNew && !isFormDirty(undefined, undefined, value)}
            onCancel={this.onCancel}
          />
        </div>
      </div>
    );
  }
}
