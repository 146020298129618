import { returnAndNotify } from "common/api/with-notifications";
import { ApiCall } from "common/types/api";
import { CancellablePromise } from "common/types/promises";
import { WorkOrderSettings } from "common/types/work-order-settings";

export const workOrderSettingsApi = (apiCall: ApiCall) => ({
  get: (): CancellablePromise<WorkOrderSettings> => {
    return apiCall("get", "/api/admin/settings/work-order");
  },
  update: (workOrderSettings: WorkOrderSettings) =>
    apiCall("put", "/api/admin/settings/work-order", workOrderSettings).then(
      returnAndNotify(_("The work order settings were successfully updated")),
    ),
});
