import * as R from "ramda";
import { Culture } from "common/culture/supported-cultures";
import { Labels } from "common/culture/types";

type Item = { id?: number; tempId?: string; labels: Labels };

export const getName = <T extends { labels: Labels }>(
  culture: Culture,
  element: T,
) => element?.labels?.[culture]?.name;

const getItemId = (item: Item) => item.id || item.tempId;

const getItemLabelKey = (item: Item) => `item.${getItemId(item)}`;

export const combineItemLabels = <TItem extends Item>(
  items: TItem[],
): Labels => {
  return items.reduce((acc, item) => {
    return R.mergeDeepLeft(
      acc,
      Object.keys(item.labels).reduce((acc, locale: Culture) => {
        const ruleName = getName(locale, item);

        if (ruleName !== undefined) {
          return {
            ...acc,
            [locale]: {
              [getItemLabelKey(item)]: ruleName,
            },
          };
        }

        return acc;
      }, {}),
    );
  }, {});
};

export const splitItemLabels = <TItem extends Item>(itemsWithLabels: {
  items: TItem[];
  labels: Labels;
}) => {
  const cultures = Object.keys(itemsWithLabels.labels);

  return itemsWithLabels.items.map((item) => ({
    ...item,
    labels: cultures.reduce((acc, locale) => {
      const localizedRuleName =
        itemsWithLabels.labels[locale]?.[getItemLabelKey(item)];

      return localizedRuleName
        ? { ...acc, [locale]: { name: localizedRuleName } }
        : acc;
    }, {}),
  }));
};
