import { defaultFor, getLocalizedName } from "common";
import { getCached } from "common/cache";
import { getColumn } from "common/entities";
import { dataTypesWithDynamicValues } from "common/entities/entity-column/data-type/types";
import { isRestrictedForRole } from "common/entities/entity-column/functions";
import { Entity } from "common/entities/types";
import {
  CUSTOM_DYNAMIC_VALUE,
  getDynamicValues,
  onDisplayDynamicValues,
} from "common/form/dynamic-values";
import {
  getAdditionalProps,
  getCommonEntityFields,
} from "common/form/group/functions";
import {
  FormValidationProps,
  Group,
  GroupColumn,
  WidgetOverwrite,
} from "common/form/types";
import { getExtraPropsPartialForValue } from "common/record/edit/functions";
import { getRecordSites } from "common/record/utils";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { VerticalField } from "common/ui/field";
import { Hint } from "common/widgets/hint";
// eslint-disable-next-line import/no-cycle
import {
  COLUMNS_TO_OMIT,
  ReferenceAddOn,
} from "common/widgets/reference-add-on";
import { WithDefaultSelector } from "common/widgets/selector/with-default-selector";
import { ValueComponent } from "common/with-value-for";
import { arrayToString } from "common/utils/array";
// eslint-disable-next-line import/no-cycle
import { Widget } from "../widget";

interface PropTypes extends FormValidationProps {
  context: Context;
  entity: Entity;
  groups: Group[];
  layoutColumn: GroupColumn;
  withLinks: boolean;
  widgetsProps?: WidgetOverwrite;
  allowDynamicValues?: boolean;
}

export class GroupField extends ValueComponent<Properties, PropTypes> {
  static readonly displayName = "GroupField";

  onReferenceSelectorChange = getCached(
    (key: string) => (widgetValue: unknown) => {
      const { context, value = defaultFor(), entity } = this.props;

      this.mergeValue(
        getExtraPropsPartialForValue(
          context.entities,
          entity,
          value,
          key,
          widgetValue,
        ),
      );
    },
  );

  render() {
    const {
      context,
      entity,
      layoutColumn,
      withLinks,
      value = defaultFor(),
      groups = [],
      widgetsProps,
      allowDynamicValues,
      formValidation,
      onFormValidationChange,
    } = this.props;
    const entityColumn = getColumn(entity, layoutColumn.columnName);

    // EntityColumn should not have a `hint` prop. it's a hack for labor charges. we can refactor out.
    const hint = layoutColumn.hint ?? entityColumn?.hint;

    const relatedEntity = context.entities[entityColumn.relatedEntity];
    const isRestrictedField = isRestrictedForRole(
      entityColumn.roleIds,
      context.role,
    );

    const isReadOnly = !!(
      entityColumn.readOnly ||
      entityColumn.userReadOnly ||
      isRestrictedField ||
      layoutColumn.readOnly
    );

    const isRequired =
      (entityColumn.required || layoutColumn.required) &&
      !(entityColumn.isSystem && entityColumn.readOnly);

    const classes = arrayToString([
      `qa-${entityColumn.name}`,
      layoutColumn.highlighted ? "x-highlighted" : undefined,
      isRestrictedField ? "x-restricted-field" : undefined,
      entityColumn.readOnly ||
      entityColumn.userReadOnly ||
      layoutColumn.readOnly
        ? "x-read-only-field"
        : undefined,
    ]);

    const { inputNode, disableQuickAddRelated, omitColumn, ...rest } =
      widgetsProps ?? {};

    const recordSites = getRecordSites(context, entity, value);

    const widget = inputNode || (
      <Widget
        key={`${layoutColumn.columnName}-widget`}
        buffer={false}
        entityName={entity.name}
        recordId={value.$id ?? value.id}
        context={context}
        column={entityColumn}
        withLinks={withLinks}
        required={entityColumn.required || layoutColumn.required}
        isRestricted={isRestrictedField}
        readOnly={isReadOnly}
        disabled={layoutColumn.disabled}
        referenceFilter={layoutColumn.referenceFilter}
        addToSelect={
          // @TODO refactor this to be consistent with what we do in form/group/lookup/user-defined
          // this is used to find matching column names from entity and related entity -- and later on
          // auto-fill record fields on FK change.
          getCommonEntityFields(
            value,
            entityColumn,
            groups,
            entity,
            relatedEntity,
          )
        }
        formValidation={formValidation}
        onFormValidationChange={onFormValidationChange}
        recordSites={recordSites}
        value={value[entityColumn.name]}
        onChange={this.onReferenceSelectorChange(entityColumn.name)}
        {...rest}
        // @TODO refactor this out and use widgetsProps
        {...getAdditionalProps(entity, entityColumn, value, context)}
      />
    );

    const addOnRecord =
      entityColumn.isForeignKey &&
      !entityColumn.readOnly &&
      !entityColumn.userReadOnly &&
      !isRestrictedForRole(entityColumn.roleIds, context.role) &&
      !layoutColumn.readOnly &&
      !(
        layoutColumn.disableQuickAddRelated ||
        entityColumn.disableQuickAddRelated ||
        disableQuickAddRelated
      ) &&
      !COLUMNS_TO_OMIT.includes(entityColumn.name) &&
      relatedEntity.type === "Reference" ? (
        <div className="x-buttons" key={entityColumn.name}>
          <ReferenceAddOn
            context={context}
            parentEntity={entity}
            entity={relatedEntity}
            column={entityColumn}
            recordSites={recordSites}
            value={undefined}
            onChange={this.onChangeMergeValue(entityColumn.name)}
          />
        </div>
      ) : undefined;

    const inputWidget =
      allowDynamicValues &&
      dataTypesWithDynamicValues.includes(entityColumn.dataType) ? (
        <WithDefaultSelector
          key={`${layoutColumn.columnName}-variable-selector`}
          options={getDynamicValues(
            entityColumn.dataType,
            context.entities[entityColumn.relatedEntity],
            true,
          )}
          defaultValue={CUSTOM_DYNAMIC_VALUE}
          getOptionLabel={onDisplayDynamicValues(entityColumn.dataType)}
          value={value[entityColumn.name]}
          onChange={this.onChangeMergeValue(entityColumn.name)}
          child={widget}
        />
      ) : (
        widget
      );

    return !omitColumn ? (
      <VerticalField
        className={`${classes} x-group-field-container`}
        isRequired={isRequired}
        label={getLocalizedName(entityColumn)}
        input={
          hint && !inputNode
            ? [
                inputWidget,
                <Hint key={`${entityColumn.name}-hint`} message={hint} />,
              ]
            : [inputWidget]
        }
        addOn={addOnRecord}
      />
    ) : null;
  }
}
