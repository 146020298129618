import { hasBehavior } from "common/api/behavior";
import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";
import { isForeignKey } from "common/functions/foreign-key";
import { getSelectField } from "common/query/select";
import { Filter, FilterRule, QueryForEntity } from "common/query/types";
import { Context } from "common/types/context";
import {
  getChildrenMap,
  getNodesMap,
} from "common/widgets/lazy-tree/functions";
import { Node, UNGROUPED } from "common/widgets/lazy-tree/types";

export const getFkToTree = (entity: Entity, context: Context) =>
  entity.columns.filter(
    (c) =>
      c.isForeignKey &&
      behaveAs("FunctionalLocation", context.entities[c.relatedEntity]) &&
      c.relatedEntity !== entity.name,
  )[0];

export const getFilterWithNodesIds = (
  nodes: Node[],
  name: string,
): FilterRule[] =>
  nodes
    .map(
      (node): FilterRule =>
        isForeignKey(node.data)
          ? {
              excludeFromFkExpansion: true,
              name,
              op: "eq",
              value: node.data,
            }
          : undefined,
    )
    .filter((f) => f);

export function getAllDescendantNodes(
  selectedNode: Node,
  nodes: Node[],
): Node[] {
  const nodeMap = getNodesMap(nodes);
  const childrenMap = getChildrenMap(nodeMap);

  const descendantNodes: Node[] = [];

  function addDescendants(node: Node) {
    descendantNodes.push(node);

    const children = childrenMap[node.id];
    if (children) {
      for (const child of children) {
        addDescendants(child);
      }
    }
  }

  addDescendants(selectedNode);

  return descendantNodes;
}

export const getInFilter = (
  entity: Entity,
  context: Context,
  node: Node,
  nodes: Node[],
): Filter => {
  const descendantNodes = getAllDescendantNodes(node, nodes);
  const fkToTreeEntity = getFkToTree(entity, context);

  const functionalLocationFilter =
    node.id === UNGROUPED
      ? {
          excludeFromFkExpansion: true,
          name: fkToTreeEntity.name,
          op: "isnull",
        }
      : {
          or: getFilterWithNodesIds(descendantNodes, fkToTreeEntity.name),
        };

  return {
    and: [{ name: "isDeleted", op: "isfalse" }, functionalLocationFilter],
  };
};

export const getRecordsQuery = (
  functionalLocationEntity: Entity,
): QueryForEntity => {
  if (!hasBehavior(functionalLocationEntity?.behaviors, "FunctionalLocation"))
    return undefined;

  const parentColumn = functionalLocationEntity.arguments.fkColumn;
  const titleColumnName =
    getSelectField("title", functionalLocationEntity.query.select)?.name ??
    "description";

  return {
    entity: functionalLocationEntity.name,
    query: {
      select: [
        { name: "id" },
        { name: "number" },
        { name: titleColumnName, alias: "title" },
        { name: parentColumn, alias: "parentId" },
        {
          name: "id",
          fn: "COUNT",
          alias: "childrenCount",
          path: `/${functionalLocationEntity.name}.${parentColumn}`,
        },
      ],
      joins: [
        {
          entity: functionalLocationEntity.name,
          column: parentColumn,
          type: "LEFT",
        },
      ],
      group: [
        { name: "id" },
        { name: "number" },
        { name: titleColumnName },
        { name: parentColumn },
      ],
      fkExpansion: false,
    },
  };
};
