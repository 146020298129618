import { Component } from "react";
import { ForeignKey } from "common/types/foreign-key";
import { ValueProps } from "common/with-value-for";
import { RecordSelectorPropTypes } from "./types";
import { AdvancedRecordSelectorBase } from "./base";
import { MultiEntitySearchCtrl } from "./advanced-search/multi-entity-search";

interface PropTypes extends RecordSelectorPropTypes, ValueProps<ForeignKey> {}

export class MultiEntityRecordSelector extends Component<PropTypes> {
  static readonly displayName = "MultiEntityRecordSelector";

  onRecordSelectorChange = (records: ForeignKey[]) => {
    const { onChange } = this.props;
    onChange(records[0]);
  };

  render() {
    return (
      <AdvancedRecordSelectorBase
        {...this.props}
        allowMultipleSelect={false}
        value={[this.props.value]}
        onChange={this.onRecordSelectorChange}
        SearchCtrlComp={MultiEntitySearchCtrl}
      />
    );
  }
}
