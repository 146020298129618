import { FormValidationProps } from "common/form/types";
import { Context } from "common/types/context";
import { OnChange } from "common/with-value-for";
import { CurrencyInput } from "common/widgets/currency/input";
import { EntityColumn } from "common/entities/entity-column/types";
import { getCurrencySymbol } from "common/widgets/currency/functions";
import { DEFAULT_CURRENCY_DECIMAL_SCALE } from "common/utils/decimal";
import { OnSubmit } from "../input-with-submit";

interface PropTypes extends FormValidationProps {
  column: EntityColumn;
  context: Context;
  value: string;
  onChange: OnChange<string>;
  className?: string;
  currencyId?: string;
  disabled?: boolean;
  hideSymbol?: boolean;
  onSubmit?: OnSubmit;
  label?: string;
}

export const DefaultCurrencyInput = (props: PropTypes) => {
  const {
    context,
    column,
    currencyId,
    disabled,
    className,
    label,
    hideSymbol,
    formValidation,
    onFormValidationChange,
    value,
    onChange,
    onSubmit,
  } = props;
  const { uiFormat } = context;

  return (
    <CurrencyInput
      column={column}
      className={className}
      label={label}
      disabled={disabled}
      decimalSeparator={uiFormat.decimalSeparator}
      decimalScale={column?.decimalScale || DEFAULT_CURRENCY_DECIMAL_SCALE}
      currencySymbol={getCurrencySymbol(currencyId, column, context)}
      hideSymbol={hideSymbol}
      formValidation={formValidation}
      onFormValidationChange={onFormValidationChange}
      value={value}
      onChange={onChange}
      onSubmit={onSubmit}
    />
  );
};

DefaultCurrencyInput.displayName = "DefaultCurrencyInput";
