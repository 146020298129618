import { Component } from "react";
import { Entity } from "common/entities/types";
import { PoItemValue } from "common/record/actions/ui/purchase-order/return-items/types";
import { Context } from "common/types/context";
import { ValueProps } from "common/with-value-for";
import { PoListItem } from "./list-item";

export interface PropTypes extends ValueProps<PoItemValue[]> {
  context: Context;
  entity: Entity;
}

export class PoList extends Component<PropTypes> {
  onItemChange = (newItem: PoItemValue) => {
    const { value, onChange } = this.props;
    const newValue = value.map((current) =>
      newItem.id === current.id ? newItem : current,
    );
    onChange(newValue);
  };

  render() {
    const { context, entity, value } = this.props;
    const hasReceived = value.some((item) => item.receivedQty);

    return (
      <div className={`x-po-list${hasReceived ? " x-has-received" : ""}`}>
        <table>
          <thead>
            <tr className="x-po-list-header">
              <th>{_("Ordered")}</th>
              {hasReceived && <th>{_("Received")}</th>}
              <th>{_("Returning")}</th>
              <th>{_("Part")}</th>
              <th>{_("Part Location")}</th>
              <th>{_("Unit Cost")}</th>
            </tr>
          </thead>
          <tbody>
            {value.map((item) => (
              <PoListItem
                key={item.id}
                context={context}
                entity={entity}
                items={value}
                value={item}
                onChange={this.onItemChange}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
