import { HorizontalField } from "common/form/ui";
import { FancyCheckboxLarge } from "common/widgets/fancy-checkbox";
import { ValueProps } from "common/with-value-for";

interface PropTypes extends ValueProps<boolean> {
  message: string;
  isShadow: boolean;
}

export const HasProjections = ({
  message,
  isShadow,
  value,
  onChange,
}: PropTypes) => (
  <div className="x-trigger-projections x-pm-section qa-pm-section">
    <HorizontalField omitElementId={true} label={_("Projections")}>
      <FancyCheckboxLarge
        label={
          isShadow
            ? _("Generate the next available projection")
            : _("Generate projections")
        }
        message={message}
        value={value}
        onChange={onChange}
      />
    </HorizontalField>
  </div>
);

HasProjections.displayName = "HasProjections";
