import * as R from "ramda";
import { getStartOf } from "common/date-time/calculators";
import { Projection } from "common/types/scheduled-event";
import { Month } from "./month";

interface RelatedRecordMap {
  [index: string]: string;
}

interface PropTypes {
  projections: Projection[];
  relatedRecords: RelatedRecordMap;
}

const getProjectionsByMonth = (projections: Projection[]) =>
  R.toPairs(R.groupBy((e) => getStartOf(e.date, "month"), projections)).map(
    ([month, monthProjections]: [string, Projection[]]) => ({
      month,
      monthProjections,
    }),
  );

export const Grid = ({ projections, relatedRecords }: PropTypes) =>
  projections.length > 0 ? (
    <div className="x-pm-projections-grid">
      {getProjectionsByMonth(projections).map(
        ({ month, monthProjections }, i) => (
          <Month
            key={i}
            month={month}
            projections={monthProjections}
            relatedRecords={relatedRecords}
          />
        ),
      )}
    </div>
  ) : (
    <p className="x-pm-projections-not-available">
      {_("No projections available")}
    </p>
  );
