import { Component, ComponentType } from "react";
import { Record } from "common/types/records";
import { ApiCall } from "common/types/api";
import { CancellablePromise, pseudoCancellable } from "common/types/promises";
import { ApiErrorResponse } from "common/types/error";
import { requiresExplicitAuthentication } from "common/record/utils";
import { ExplicitAuth } from "common/explicit-auth";
import { ESignatureReason } from "common/types/reasons";
import { Context } from "common/types/context";
import { Entity } from "common/entities/types";
import { DeleteWarning } from "common/widgets/warning/delete-warning";

export interface DelayedApiCall {
  onAccept: (apiCall: ApiCall) => void;
  onCancel: () => void;
}

interface State {
  explicitAuth: DelayedApiCall;
  confirmDelete: DelayedApiCall;
  error: ApiErrorResponse;
  reason: ESignatureReason;
}

type Action = "save" | "remove";

export type ExecuteAction = <T>(
  action: Action,
  record: Record,
  newRecord: Record,
  fn: (apiCall: ApiCall) => CancellablePromise<T>,
) => CancellablePromise<T>;

interface WithExplicitAuth {
  context: Context;
  entity: Entity;
  explicitAuth?: DelayedApiCall;
  executeAction?: ExecuteAction;
}

type WithExplicitAuthOutput<PropTypes> = PropTypes & WithExplicitAuth;

export function withExplicitAuth<PropTypes extends WithExplicitAuth>(
  WrappedComponent: ComponentType<WithExplicitAuthOutput<PropTypes>>,
) {
  return class WithExplicitAuth extends Component<PropTypes, State> {
    constructor(props: PropTypes) {
      super(props);
      this.state = {
        explicitAuth: undefined,
        confirmDelete: undefined,
        error: undefined,
        reason: undefined,
      };
    }

    executeAction = <T,>(
      action: Action,
      oldRecord: Record,
      newRecord: Record,
      fn: (apiCall: ApiCall) => CancellablePromise<T>,
    ): CancellablePromise<T> => {
      const { context, entity } = this.props;
      const promptAuth = requiresExplicitAuthentication(
        context.entities,
        entity,
        oldRecord,
        action === "remove" ? undefined : newRecord,
      );

      const promptDelete = action === "remove";
      const promptPromise = (stateKey: "confirmDelete" | "explicitAuth") =>
        pseudoCancellable(
          new Promise((resolve, reject) =>
            this.setState((prevState) => ({
              ...prevState,
              [stateKey]: { onAccept: resolve, onCancel: reject },
            })),
          ).catch((error) => {
            if (error) {
              // eslint-disable-next-line
              console.error("error setting state", error);
              throw error;
            }

            this.setState({
              confirmDelete: undefined,
              explicitAuth: undefined,
              error: undefined,
            });
            // TODO: RTS
            throw { cancel: true };
          }),
        );

      const confirmAction = () =>
        !promptDelete
          ? CancellablePromise.resolve()
          : promptPromise("confirmDelete").then(() =>
              this.setState({ confirmDelete: undefined }),
            );

      const confirmAuth = () => {
        if (!promptAuth) {
          return fn(context.apiCall);
        }

        const passwordPromise = (): CancellablePromise<T> =>
          promptPromise("explicitAuth")
            .then((apiCall: ApiCall) => fn(apiCall))
            .then((data) => {
              this.setState({ explicitAuth: undefined, error: undefined });
              if (context.isPasswordExpired) {
                context.reloadUi();
              }
              return data;
            })
            .catch((error) => {
              if (error.status === 401 || error.status === 400) {
                this.setState({ error });
                return passwordPromise();
              } else {
                this.setState({
                  explicitAuth: undefined,
                  error: undefined,
                });
                throw error;
              }
            });
        return passwordPromise();
      };

      return confirmAction().then(confirmAuth);
    };

    onAcceptExplicitAuth = (apiCall: ApiCall) => {
      const { explicitAuth } = this.state;
      explicitAuth.onAccept(apiCall);
    };

    onCancelExplicitAuth = () => {
      const { explicitAuth } = this.state;
      explicitAuth.onCancel();
    };

    onConfirmDelete = () => {
      const { context } = this.props;
      const { confirmDelete } = this.state;
      confirmDelete.onAccept(context.apiCall);
    };

    onCancelDelete = () => {
      const { confirmDelete } = this.state;
      confirmDelete.onCancel();
    };

    onErrorChange = (error: ApiErrorResponse) => {
      this.setState({ error });
    };

    render() {
      const { context } = this.props;
      const { explicitAuth, error, confirmDelete } = this.state;

      return (
        <>
          <WrappedComponent
            {...this.props}
            executeAction={this.executeAction}
            explicitAuth={explicitAuth}
          />
          {explicitAuth ? (
            <ExplicitAuth
              context={context}
              authAction={this.onAcceptExplicitAuth}
              cancel={this.onCancelExplicitAuth}
              error={error}
              onErrorChange={this.onErrorChange}
            />
          ) : null}
          {confirmDelete ? (
            <DeleteWarning
              onCancel={this.onCancelDelete}
              onDelete={this.onConfirmDelete}
            />
          ) : undefined}
        </>
      );
    }
  };
}
