import { vendorRoute } from "common/api/authentication/admin";
import { searchApi } from "common/api/search";
import { Context } from "common/types/context";
import { Location } from "common/types/url";
import { setLocationHref } from "common/utils/window-location";
import { splitLocation } from "x/functions";

const workOrderStatusQuery = (entityName: string, recordId: string) => ({
  entity: entityName,
  query: {
    select: [{ name: "status" }],
    filter: { name: "id", op: "eq", value: recordId },
    pageSize: 1,
    page: 0,
  },
});

const parseWorkOrderStatus = (status: string) => {
  switch (status) {
    case "O":
      return "open";
    case "C":
      return "completed";
    case "H":
      return "closed";
    default:
      return "";
  }
};

export const redirectToVendorPortal = (
  context: Context,
  location: Location,
) => {
  const { site, page: entity, id } = splitLocation(location.pathname);
  const getUrl = (urlCore: string) =>
    `/${vendorRoute}/#${urlCore}${location.search}`;

  searchApi(context.apiCall)
    .runQuery(workOrderStatusQuery(entity, id))
    .then((result: Array<{ status: string }>) => {
      const status = parseWorkOrderStatus(result[0]?.status);
      const prefix = `/${site}/${entity}`;
      const urlCore = status ? `${prefix}/${status}/${id}` : prefix;
      const redirectUrl = getUrl(urlCore);

      setLocationHref(redirectUrl);
    })
    .catch((_) => setLocationHref(getUrl(location.pathname)));
};
