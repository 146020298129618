import { ApiErrorResponse } from "common/types/error";

export const isChunkLoadingError = (error: any) =>
  !!(error && error.name === "ChunkLoadError");

export const isApiError = (error: any): error is ApiErrorResponse =>
  !!(error && "status" in error && "data" in error);

export const isJSError = (error: any): error is Error =>
  !!(error && "message" in error);
