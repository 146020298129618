import * as R from "ramda";
import { behaveAs, getColumn, getRelatedEntities } from "common/entities";
import { Entities, Entity } from "common/entities/types";
import {
  FrequencyInterval,
  IntervalOption,
  PmRecord,
  ScheduledEventAssetRecord,
  ScheduledEventRecord,
} from "common/types/scheduled-event";
import { MAX_INTERVAL_MONTHS } from "./defaults";

export const getWorkOrderEntity = (entities: Entities, entity: Entity) =>
  entities[entity.arguments.workOrderEntity];

export const getAssetIdCol = (woEntity: Entity) =>
  getColumn(woEntity, "assetId");

export const getEventAssetEntity = (entities: Entities, entity: Entity) =>
  entities[entity.arguments.eventAssetEntity];

export const getMeterEntity = (entities: Entities, entity: Entity) =>
  entities[entity.arguments.meterEntity];

export const getEventAssets = (
  entities: Entities,
  entity: Entity,
  record: ScheduledEventRecord,
): ScheduledEventAssetRecord[] => {
  if (!record?.related) return [];
  const eventAssetEntity = getEventAssetEntity(entities, entity);
  return (record.related[eventAssetEntity.name] ||
    []) as ScheduledEventAssetRecord[];
};

export const getEventAssetsIds = (
  record: ScheduledEventRecord,
  eventAssetEntity: string,
): string[] =>
  (record?.related?.[eventAssetEntity] ?? []).reduce(
    (acc, cur) =>
      cur.properties.isDeleted ? acc : acc.concat(cur.properties.assetId.id),
    [],
  );

export const setEventAssets = <T extends ScheduledEventRecord>(
  entities: Entities,
  entity: Entity,
  records: ScheduledEventAssetRecord[],
  record: T,
): T => {
  const assetEntity = getEventAssetEntity(entities, entity);
  return {
    ...record,
    related: { ...record.related, [assetEntity.name]: records },
  };
};

export const getAssetMeterEntities = (
  entities: Entities,
  assetEntity: Entity,
  meterEntity: Entity,
) =>
  getRelatedEntities(
    assetEntity,
    entities,
    (e) =>
      behaveAs("AssetMeter", e) &&
      getColumn(e, "meterId").relatedEntity === meterEntity?.name,
  );

export const setEventAssetDeleted = (
  isDeleted: boolean,
  a: ScheduledEventAssetRecord,
): ScheduledEventAssetRecord =>
  R.mergeRight(a, { properties: R.mergeRight(a.properties, { isDeleted }) });

export const frequencyOptions = (): IntervalOption[] => [
  {
    value: "m",
    label: _("Months"),
  },
  {
    value: "w",
    label: _("Weeks"),
  },
  {
    value: "d",
    label: _("Days"),
  },
];

export const getIntervalInMonths = (
  value: number,
  interval: FrequencyInterval,
) => {
  const amount = Math.max(value ?? 0, 0);

  switch (interval) {
    case "m":
      return amount;
    case "w":
      return Math.ceil(amount / 4);
    case "d":
      return Math.ceil(amount / 30);
    default:
      return 0;
  }
};

export const frequencyInRange = (record: ScheduledEventRecord) => {
  if (record.properties.type !== "P") return true;

  const frequency = (record as PmRecord).properties.trigger?.frequency;

  if (!frequency) return false;

  return frequency.value
    ? getIntervalInMonths(frequency.value, frequency.interval) <=
        MAX_INTERVAL_MONTHS
    : true;
};
