import { TimeFormat } from "common/date-time/types";

const isBetween = (value: number, min: number, max: number) =>
  value >= min && value <= max;

export const to12HourFormat = (hourValue: number) => {
  if (!isBetween(hourValue, 0, 24)) return undefined;
  return hourValue >= 12 ? hourValue - 12 : hourValue;
};

export const to24HourFormat = (newValue: number, initialValue: number) => {
  if (!isBetween(newValue, 0, 12)) return undefined;
  return initialValue >= 12 && newValue !== undefined
    ? newValue + 12
    : newValue;
};

export const is12HourFormat = (timeFormat: TimeFormat) =>
  !!timeFormat && /hh?/.test(timeFormat);

export const formatTimeValue = (timeInt: number) => {
  if (timeInt === undefined || isNaN(timeInt)) return "";
  return `${timeInt < 10 ? "0" : ""}${timeInt}`;
};
