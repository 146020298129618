import { UTCDateTime } from "common/date-time/types";
import { isAfter, isBefore } from "common/date-time/validators";
import { dateoffset } from "common/validate";

export const displayDateOffset = (value: string) => {
  if (!value || !dateoffset(value)) return "";
  const numOfDays = value.substring(1, value.indexOf("T"));
  if (numOfDays === "0") return _("Same Day");
  return `+${numOfDays} ${numOfDays === "1" ? _("day") : _("days")}`;
};

export const isBeforeMinDate = (minDate: UTCDateTime, value: UTCDateTime) =>
  !!(value && minDate && isBefore(value, minDate));

export const isAfterMaxDate = (maxDate: UTCDateTime, value: UTCDateTime) =>
  !!(value && maxDate && isAfter(value, maxDate));

export const hasDateChanged = (oldValue: UTCDateTime, newValue: UTCDateTime) =>
  !!newValue && newValue !== oldValue;
