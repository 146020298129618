import * as R from "ramda";
import { defaultFor } from "common";
import { recordsApi } from "common/api/records";
import { searchApi } from "common/api/search";
import { getUtcNow } from "common/date-time/common";
import { behaveAs, getColumn } from "common/entities";
import { Entity } from "common/entities/types";
import { validateGroupColumns } from "common/form/functions/validation";
import {
  ActionLayout,
  ActionSignature,
  LayoutActionName,
  WorkOrderActionLayout,
} from "common/form/types";
import { findEntityByArgs } from "common/functions/entity";
import { filterFormsByEntity } from "common/functions/forms";
import { QueryForEntity } from "common/query/types";
import { getFormOrDefault } from "common/record/edit/functions";
import {
  getMaxReadingPerDay,
  getMeterType,
  getPreviousReading,
  isValid as validateAssetMeterReading,
} from "common/record/form/content/related/asset-meter-reading/functions";
import { Context } from "common/types/context";
import { Form } from "common/types/forms";
import { CancellablePromise } from "common/types/promises";
import { Properties, Record } from "common/types/records";
import {
  getAssetMeterReadingEntity,
  hasSystemColumnName,
} from "x/account-settings/forms/form/work-order-action/functions";
import {
  FormValue,
  WorkOrderActionDependencies,
  WorkOrderActionRecord,
} from "./types";

export const DEFAULT_WORK_ORDER_ACTION_COLUMNS = [
  "id",
  "number",
  "formId",
  "assetId",
];
export const defaultDeps = defaultFor<WorkOrderActionDependencies>();
const defaultAmrProperties = defaultFor<Properties>();

const defaultValue: FormValue = {
  date: undefined,
  signature: undefined,
  page: 0,
  actionRecords: [],
};

const canShowWorkOrderAction = (actionLayout: WorkOrderActionLayout) => {
  const { columns = [], showAssetMeterReadings } = actionLayout;
  return !!columns.length || showAssetMeterReadings;
};

const canCheckWorkOrderActionProcedures = (
  actionLayout: WorkOrderActionLayout,
) => !!actionLayout?.completeProceduresEnabled;

export const getActionLayoutWithExistingColumns = <T extends ActionLayout>(
  entity: Entity,
  actionLayout: T,
): T => {
  if (!actionLayout) return undefined;

  const existingColumns = (actionLayout.columns || []).filter((c) =>
    getColumn(entity, c.columnName),
  );
  return { ...actionLayout, columns: existingColumns };
};

export const getActionLayout = (
  context: Context,
  entity: Entity,
  actionName: LayoutActionName,
  properties: Properties,
): WorkOrderActionLayout => {
  const formId = properties?.formId;
  const entityName = entity?.name;
  const form = getFormOrDefault(context, entityName, formId);

  return getActionLayoutWithExistingColumns(
    entity,
    form?.settings?.actions?.[actionName],
  );
};

const getActionRecords = (
  context: Context,
  entity: Entity,
  actionName: LayoutActionName,
  records: Record[] = [],
  filter: (c: WorkOrderActionLayout) => boolean,
) => {
  return records.filter((r) => {
    const actionLayout = getActionLayout(
      context,
      entity,
      actionName,
      r.properties,
    );
    return actionLayout && filter(actionLayout);
  });
};

export const getCheckProceduresCall = (
  context: Context,
  entity: Entity,
  actionName: LayoutActionName,
  records: Record[],
) => {
  const proceduresEntity = findEntityByArgs(
    context.entities,
    "Procedure",
    "ownerEntity",
    entity.name,
  );

  const actionRecords = getActionRecords(
    context,
    entity,
    actionName,
    records,
    canCheckWorkOrderActionProcedures,
  );

  if (!proceduresEntity || !actionRecords.length) return undefined;

  return recordsApi(context.apiCall).bulkCheckProcedures(
    actionName,
    proceduresEntity.name,
    actionRecords,
  );
};

export const getActionSignedRecords = (
  context: Context,
  entity: Entity,
  actionName: LayoutActionName,
  records: Record[],
) => {
  if (!behaveAs("Signature", entity)) return undefined;

  const signatureRecordIds = records.reduce((acc, r) => {
    const actionLayout = getActionLayout(
      context,
      entity,
      actionName,
      r?.properties,
    );
    const needsSignature = R.includes(actionLayout?.signature, [
      "required",
      "optional",
    ]);
    const id = r?.properties?.id;
    return id && needsSignature ? acc.concat(id) : acc;
  }, []);

  if (!signatureRecordIds.length) return undefined;

  const query: QueryForEntity = {
    entity: entity.name,
    query: {
      select: [{ name: "id" }],
      filter: {
        and: [
          {
            name: "id",
            op: "in",
            value: signatureRecordIds.join(","),
          },
          {
            name: "signature",
            op: "isnotnull",
          },
        ],
      },
    },
  };
  return searchApi(context.apiCall).runQuery(query) as CancellablePromise<
    Properties[]
  >;
};

export const getActionColumnNames = (
  entity: Entity,
  forms: Form[],
  actionName: LayoutActionName,
): string[] => {
  const entityName = entity?.name;
  const entityForms = filterFormsByEntity(forms, entityName);
  return entityForms.reduce((acc: string[], form) => {
    const cols = form?.settings?.actions?.[actionName]?.columns || [];
    const newCols = R.difference(
      cols.map((c) => c.columnName),
      acc,
    );
    return acc.concat(newCols);
  }, []);
};

export const getActionQuery = (
  entity: Entity,
  columnsNames: string[] = [],
  recordsIds: string[] = [],
): QueryForEntity => {
  const select = columnsNames
    .concat(DEFAULT_WORK_ORDER_ACTION_COLUMNS)
    .map((colName) => ({ name: colName }));

  return {
    entity: entity?.name,
    query: {
      select,
      filter: {
        name: "id",
        op: "in",
        value: recordsIds.join(","),
      },
    },
  };
};

export const getRecordsForActionQuery = (
  context: Context,
  entity: Entity,
  actionName: LayoutActionName,
  records: Record[],
) => {
  const columnsNames = getActionColumnNames(entity, context.forms, actionName);
  const removeDeletedNames = columnsNames.filter((n) => getColumn(entity, n));

  const actionRecords = getActionRecords(
    context,
    entity,
    actionName,
    records,
    canShowWorkOrderAction,
  );
  if (!actionRecords.length) return undefined;

  const actionRecordsIds = actionRecords.map((r) => r?.properties?.id);
  const query = getActionQuery(entity, removeDeletedNames, actionRecordsIds);
  return searchApi(context.apiCall).runQueryFkExpansion(
    query,
  ) as CancellablePromise<Properties[]>;
};

const getAmrDefault = (context: Context, entity: Entity, dateNow: string) => {
  const { entities } = context;

  const assetMeteReadingEntity = getAssetMeterReadingEntity(entity, entities);
  if (!assetMeteReadingEntity) return undefined;

  const form = getFormOrDefault(context, assetMeteReadingEntity.name);
  const amrFormDefault = form?.settings?.defaults || defaultAmrProperties;

  return !amrFormDefault.date
    ? { ...amrFormDefault, date: dateNow }
    : amrFormDefault;
};

export const getActionProperties = (
  dependencies: WorkOrderActionDependencies = defaultDeps,
  records: Record[] = [],
) => {
  const { properties = [] } = dependencies;
  return records.map((r) =>
    R.find((p) => p.id === r.properties.id, properties),
  );
};

export const getDefaultValue = (
  context: Context,
  entity: Entity,
  actionName: LayoutActionName,
  records: Record[] = [],
  deps: WorkOrderActionDependencies = defaultDeps,
) => {
  const date = getUtcNow();
  const formValue = { ...defaultValue, date };

  const hasNoFormIds = R.none((r) => !!r?.properties?.formId, records);
  if (hasNoFormIds) {
    return formValue;
  }

  const amrDefaults = getAmrDefault(context, entity, date);

  const actionRecords = getActionProperties(deps, records).reduce(
    (acc: WorkOrderActionRecord[], p) => {
      if (!p) return acc;

      const actionLayout = getActionLayout(context, entity, actionName, p);
      const { columns = [], showAssetMeterReadings } = actionLayout;

      const newRecord: WorkOrderActionRecord = {
        id: p.id,
        recordNumber: p.number,
        assetId: p?.assetId?.id,
        properties: columns.length ? p : undefined,
        assetMeterReading: showAssetMeterReadings ? amrDefaults : undefined,
        assetMeters: [],
        meters: [],
      };

      return acc.concat(newRecord);
    },
    [],
  );
  return { ...formValue, actionRecords };
};

export const getActionSignature = (
  context: Context,
  entity: Entity,
  actionName: LayoutActionName,
  records: Record[] = [],
  existingSignatureIds: string[] = [],
): ActionSignature => {
  const signatureSettings = records.reduce((acc: ActionSignature[], r) => {
    if (R.includes(r.properties.id, existingSignatureIds)) return acc;
    const actionLayout = getActionLayout(
      context,
      entity,
      actionName,
      r.properties,
    );
    return acc.concat(actionLayout?.signature);
  }, []);

  if (R.includes("required", signatureSettings)) {
    return "required";
  }
  if (R.includes("optional", signatureSettings)) {
    return "optional";
  }
  return "disabled";
};

export const isValidAssetMeterReading = (
  assetMeters: Record[] = [],
  meters: Properties[] = [],
  amrProperties: Properties,
  maxReadingPerDay?: number,
) => {
  if (!assetMeters.length || !meters.length) return false;

  const previousReading = getPreviousReading(amrProperties, assetMeters);
  const meterType = getMeterType(amrProperties, assetMeters, meters);
  return validateAssetMeterReading(
    amrProperties,
    previousReading,
    meterType?.id,
    maxReadingPerDay,
  );
};

const isValidProperties = (
  context: Context,
  entity: Entity,
  actionName: LayoutActionName,
  properties: Properties,
) => {
  const actionLayout = getActionLayout(context, entity, actionName, properties);
  const columns = actionLayout?.columns;
  return validateGroupColumns(entity.columns, properties, columns);
};

export const areAllAssetMeterReadingsValid = (
  actionRecords: WorkOrderActionRecord[],
) =>
  R.all((cr) => {
    const { assetMeters, assetMeterReading, meters } = cr;
    const assetMeterId = assetMeterReading?.assetMeterId;
    const value = assetMeterReading?.value;
    const maxReadingPerDay = getMaxReadingPerDay(assetMeterId, assetMeters);
    return (
      !(assetMeterId || value) ||
      isValidAssetMeterReading(
        assetMeters,
        meters,
        assetMeterReading,
        maxReadingPerDay,
      )
    );
  }, actionRecords);

export const isValidRecord = (
  context: Context,
  entity: Entity,
  actionName: LayoutActionName,
  record: WorkOrderActionRecord,
) => {
  const { id, properties } = record;
  if (!id) return false;

  if (!properties) return true;

  const keysWithoutDefaults = Object.keys(
    R.omit(DEFAULT_WORK_ORDER_ACTION_COLUMNS, properties),
  );
  const hasSystemColumns = hasSystemColumnName(
    keysWithoutDefaults,
    entity.columns,
  );
  if (hasSystemColumns) return false;

  const isValidProps = isValidProperties(
    context,
    entity,
    actionName,
    properties,
  );

  return !!isValidProps;
};

export const isValid = (
  context: Context,
  entity: Entity,
  actionName: LayoutActionName,
  signatureSetting: ActionSignature,
  value: FormValue,
) => {
  const { date, signature, actionRecords = [] } = value || defaultValue;
  if (signatureSetting === "required" && !signature) {
    return false;
  }
  if (!date) {
    return false;
  }
  if (!areAllAssetMeterReadingsValid(actionRecords)) {
    return false;
  }
  if (actionRecords.length) {
    return actionRecords?.every((record) =>
      isValidRecord(context, entity, actionName, record),
    );
  }
  return true;
};
