import * as R from "ramda";
import { schedulerApi } from "common/api/scheduler";
import type { UTCDateTime } from "common/date-time/types";
import { CALENDAR_ID } from "common/entities/entity-column/types";
import { isNotWorkingEvent } from "common/functions/scheduler";
import { roundWithPrecision } from "common/math";
import type { Calendar } from "common/types/calendars";
import type { Context } from "common/types/context";
import { CancellablePromise } from "common/types/promises";
import type {
  ContactEventProperties,
  ContactEvents,
  EventsResponse,
} from "common/types/scheduler";
import { filterValueToId } from "x/scheduler2/planner/header/filter/filter-value";
import {
  getWorkingDateRange,
  translateViewForPreferences,
} from "x/scheduler2/shared";
import { mapEvent } from "x/scheduler2/shared/events";
import type {
  PartialUpdate,
  Resource,
  SchedulerData,
} from "x/scheduler2/types";

type GetGroupByFn = (
  properties: ContactEventProperties,
  groupBy: string,
  context: Context,
) => string | number;

export interface LabelMap {
  [key: number]: string;
}

export const getCalendarLabels = (calendars: Calendar[]): LabelMap =>
  (calendars || []).reduce(
    (acc: LabelMap, calendar: Calendar) => ({
      ...acc,
      [calendar.id]: calendar.label,
    }),
    {},
  );

export const translateCalendarId = (
  properties: ContactEventProperties,
  groupBy: string,
  context: Context,
) => {
  const labelsMap = getCalendarLabels(context.calendars);
  const propertyValue = properties[groupBy];

  return labelsMap[propertyValue] || propertyValue;
};

export const translateReferenceFk = (
  properties: ContactEventProperties,
  groupBy: string,
  _: Context,
) => properties[groupBy] || properties[`${groupBy}_enUS`];

export const getResourceCls = (properties: {
  assignedHours: number;
  availableHours: number;
}) =>
  properties.assignedHours > properties.availableHours
    ? "x-resource-overloaded"
    : undefined;

const mapResource = (
  context: Context,
  groupBy: string,
  getGroupBy: GetGroupByFn,
) => {
  return ({ properties }: ContactEvents): Resource => {
    const resource: Resource = {
      id: properties.id,
      name: properties.title,
      imageUrl: properties.image,
      image: properties.image ? undefined : false,
      assignedHours: properties.assignedHours,
      availableHours: properties.availableHours,
      cls: getResourceCls(properties),
      matchingColumn: properties.matchingColumn,
    };

    return groupBy
      ? { ...resource, [groupBy]: getGroupBy(properties, groupBy, context) }
      : resource;
  };
};

export const getResources = (
  context: Context,
  records: ReadonlyArray<ContactEvents>,
  groupBy: string,
): Resource[] => {
  const getGroupByFn = groupBy
    ? groupBy === CALENDAR_ID
      ? translateCalendarId
      : translateReferenceFk
    : undefined;

  return records.map(mapResource(context, groupBy, getGroupByFn));
};

export const getResourceMeta = (resource: Resource) => {
  const { assignedHours, availableHours } = resource;

  const assigned = roundWithPrecision(assignedHours, 1);
  const available = roundWithPrecision(availableHours, 1);

  return `${assigned} / ${available} ${_("hours allocated")}`;
};

const getResourceEvents = (
  context: Context,
  value: SchedulerData,
  resourceId: string | number,
  from: UTCDateTime,
  to: UTCDateTime,
) =>
  resourceId
    ? schedulerApi(context.apiCall).getEvents(
        from,
        to,
        translateViewForPreferences(value.view),
        true,
        filterValueToId(value.filterValue),
        resourceId,
      )
    : undefined;

export const getUpdatedResourceEvents = (
  context: Context,
  value: SchedulerData,
  resourceIds: Array<string | number>,
) => {
  const { from, to } = getWorkingDateRange(value);

  const uniqueResources = R.uniq(resourceIds.filter(R.identity));
  const queries = uniqueResources.map((resourceId) =>
    getResourceEvents(context, value, resourceId, from, to),
  );

  return CancellablePromise.all(queries).then((responses: EventsResponse[]) =>
    responses.reduce(
      (acc: PartialUpdate, response: EventsResponse) => {
        const resourceUpdate = response.availableHours[0];
        const resourceId = resourceUpdate.contactId;

        return {
          ...acc,
          resourceUpdate: {
            ...acc.resourceUpdate,
            [resourceId]: {
              availableHours: resourceUpdate.availableHours,
              assignedHours: resourceUpdate.assignedHours,
            },
          },
          newEvents: acc.newEvents.concat(
            response.events
              .filter((event) => !isNotWorkingEvent(event))
              .map((event) =>
                mapEvent(context, resourceUpdate.contactId, event),
              ),
          ),
        };
      },
      { resourceUpdate: {}, newEvents: [] },
    ),
  );
};
