import { defaultFor } from "common";
import { getByBehaviorArgument } from "common/entities";
import {
  isFkToEntityWithBehavior,
  looseColumnNameCheck,
} from "common/entities/entity-column/functions";
import { Entities, Entity } from "common/entities/types";
import { LookupQuery } from "common/form/types";
import { addFilterToQuery } from "common/query/filter";
import { addJoinToQuery } from "common/query/joins";
import {
  Filter,
  JoinItem,
  QueryForEntity,
  SelectField,
} from "common/query/types";
import { getRelatedRecords } from "common/record/utils";
import { Context } from "common/types/context";
import { TabContentProps } from "common/widgets/record-selector/types";
import { getPartialForm } from "../functions";
import { RelatedValue } from "../types";

const getPartRelatedEntities = (context: Context, entity: Entity) => {
  const partEntity = context.entities[entity.arguments.partEntity];
  const partSupplierEntity = getByBehaviorArgument(
    context.entities,
    "PartSupplier",
    "partEntity",
    partEntity?.name,
  );
  return { partEntity, partSupplierEntity };
};

export const createPartsLookupQueryForSupplier = (
  context: Context,
  entity: Entity = defaultFor<Entity>(),
  supplierId: string,
): LookupQuery => {
  const { partEntity, partSupplierEntity } = getPartRelatedEntities(
    context,
    entity,
  );

  const supplierFilter: Filter = {
    and: [
      {
        path: `/${partSupplierEntity.name}.partId/supplierId`,
        name: "id",
        op: "eq",
        value: supplierId,
      },
      {
        name: "isDeleted",
        op: "isfalse",
        path: `/${partSupplierEntity.name}.partId`,
      },
      {
        name: "isDeleted",
        op: "isfalse",
        path: `/${partSupplierEntity.name}.partId/supplierId`,
      },
    ],
  };

  const partSupplierAndSupplierJoin: JoinItem = {
    column: "partId",
    entity: partSupplierEntity.name,
    joins: [{ column: "supplierId" }],
  };

  const query = addFilterToQuery(
    supplierFilter,
    addJoinToQuery(partSupplierAndSupplierJoin, partEntity.query),
  );

  return {
    columnName: "partId",
    entityName: partEntity.name,
    query,
  };
};

export const getSelectedLocationIdsForPart = (
  entityName: string,
  value: RelatedValue,
  partId: number,
): string[] => {
  const { record, related } = value;
  const relatedRecords = getRelatedRecords(entityName, record, related?.form);

  const partialRecord = getPartialForm(value, entityName);
  const currentId = partialRecord?.tempId ?? partialRecord?.id;

  return relatedRecords.reduce<string[]>((acc, r) => {
    const id = r?.properties?.tempId ?? r?.properties?.id;

    return r?.properties?.isDeleted || // consider only non-deleted
      r?.properties?.partId?.id !== partId || // only related with same part
      id === currentId // only the related that are NOT the one being edited
      ? acc
      : [...acc, r?.properties?.partLocationId?.id];
  }, []);
};

const getPartsDefaultColumns = (batchEntity: string): SelectField[] => {
  return [
    {
      name: "quantity",
      alias: "onHand",
      path: `/${batchEntity}.stockId`,
      label: _("On Hand"),
      fn: "SUM",
    },
    {
      name: "number",
      alias: "Part Number",
      path: "/partId",
      label: _("Part Number"),
    },
    {
      name: "description",
      alias: "Part Description",
      path: "/partId",
      label: _("Part Description"),
    },
  ];
};

const getSitePartsLookupQuery = (
  siteName: string,
  batchEntity: Entity,
  stockEntity: Entity,
): QueryForEntity => {
  const { name, query } = stockEntity;
  const { name: batchEntityName } = batchEntity;
  const customColumns = getPartsDefaultColumns(batchEntityName);

  const joins = (query.joins || []).concat([
    {
      entity: batchEntityName,
      column: "stockId",
      type: "LEFT",
    },
    { column: "partId", type: "RIGHT" },
  ]);

  return {
    entity: name,
    query: {
      ...query,
      select: customColumns,
      joins,
      filter: {
        and: [
          { name: "site", op: "eq", value: siteName },
          { name: "isDeleted", op: "eq", value: false, path: "/partId" },
        ],
      },
    },
  };
};

export const getSitePartsLookupContent = (
  siteName: string,
  batchEntity: Entity,
  partEntity: Entity,
  stockEntity: Entity,
): TabContentProps[] => {
  const sitePartsQuery = getSitePartsLookupQuery(
    siteName,
    batchEntity,
    stockEntity,
  );
  const allPartsQuery: QueryForEntity = {
    entity: partEntity.name,
    query: undefined,
  };
  return [
    {
      queryForEntity: sitePartsQuery,
      label: _("Site Parts"),
      value: "site-parts",
    },
    {
      queryForEntity: allPartsQuery,
      label: _("All Parts"),
      value: "all-parts",
    },
  ];
};

export const getPartIdColumn = (entities: Entities, entity: Entity) =>
  entity.columns.find(
    (c) =>
      looseColumnNameCheck(c.name, "partId") &&
      isFkToEntityWithBehavior("Part", entities)(c),
  );
