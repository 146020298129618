import { ValueComponent } from "common/with-value-for";
import { PropTypes, FancyCheckboxLarge } from ".";

export class InvertedFancyCheckbox extends ValueComponent<boolean, PropTypes> {
  static readonly displayName = "InvertedFancyCheckbox";
  render() {
    const { value, children } = this.props;
    return (
      <FancyCheckboxLarge
        {...this.props}
        value={!value}
        onChange={this.onChangeSetDefaultValue(!value)}
      >
        {children}
      </FancyCheckboxLarge>
    );
  }
}
