import { defaultFor } from "common";
import { UTCDateTime } from "common/date-time/types";
import { Entity } from "common/entities/types";
import { QueryForEntity } from "common/query/types";
import { Context } from "common/types/context";
import {
  Inspection,
  InspectionRecord,
  InspectionTrigger,
} from "common/types/scheduled-event";
import { ValueComponent } from "common/with-value-for";
import { YesNo } from "common/widgets/radio-button-list/yes-no";
import { HorizontalField } from "common/form/ui";
import { AssetListWithLabel } from "../asset-list/with-label";
import { StartDate } from "../start-date";
import { InspectionSchedule } from "./schedules";

interface PropTypes {
  context: Context;
  entity: Entity;
  assetEntity: Entity;
  query?: QueryForEntity;
}

export type TriggerProps = PropTypes;

const defaultInspection = defaultFor<Inspection>();
const defaultInspectionTrigger = defaultFor<InspectionTrigger>();

export class TriggersForm extends ValueComponent<InspectionRecord, PropTypes> {
  static readonly displayName = "TriggersForm";

  onChangeStartDate = (startDate: UTCDateTime) => {
    this.mergeValue3("properties", "trigger", "startDate", startDate);
  };

  onChangeSchedule = (crons: string[]) => {
    this.mergeValue3("properties", "trigger", "crons", crons);
  };

  onEnabledToggleChange = (enabled: boolean) =>
    this.mergeValue2("properties", "enabled", enabled);

  render() {
    const { context, entity, assetEntity, value, query } = this.props;
    const { properties = defaultInspection } =
      value ?? defaultFor<InspectionRecord>();
    const { trigger = defaultInspectionTrigger, enabled } = properties;
    return (
      <div className="row">
        <div className="col-md-3 x-sidebar-records x-inspection-records">
          <div className="x-margin-bottom-5">{_("Assets")}</div>
          <AssetListWithLabel
            context={context}
            entity={entity}
            assetEntity={assetEntity}
            value={value}
            query={query}
            onChange={this.onChangeSetValue}
          />
        </div>
        <div className="col-md-9 x-inspection-details">
          <HorizontalField label={_("Enabled")} disabled={false}>
            <YesNo
              name="enabled"
              value={enabled}
              onChange={this.onEnabledToggleChange}
            />
          </HorizontalField>
          <StartDate
            uiFormat={context.uiFormat}
            value={trigger.startDate}
            onChange={this.onChangeStartDate}
          />
          <InspectionSchedule
            value={trigger.crons}
            onChange={this.onChangeSchedule}
          />
        </div>
      </div>
    );
  }
}
