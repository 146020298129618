import { Component } from "react";
import { Context } from "common/types/context";
import { GoFn } from "common/types/url";
import { DebounceInputWithSearch } from "common/widgets/input-with-search";
import { SearchRecordsWithTabs } from "./search-records-with-tabs";

interface PropTypes {
  context: Context;
  goTo: GoFn;
}

interface StateType {
  searchValue: string;
  openSearchBar: boolean;
}

export class GlobalSearchComponent extends Component<PropTypes, StateType> {
  static readonly displayName = "GlobalSearchComponent";
  state: StateType = {
    searchValue: undefined,
    openSearchBar: false,
  };

  onSearchChange = (value: string) => {
    this.setState({
      searchValue: value,
      openSearchBar: this.state.openSearchBar || !!this.state.searchValue,
    });
  };

  onClear = () => {
    this.setState({ searchValue: undefined });
  };

  onClose = () => {
    this.setState({ openSearchBar: false, searchValue: undefined });
  };

  openSearchBar = () => {
    this.setState({ openSearchBar: true });
  };

  render() {
    const { context, goTo } = this.props;
    const { searchValue, openSearchBar } = this.state;

    return (
      <div className={"x-global-search x-float-left"}>
        {!openSearchBar ? (
          <button
            className="x-header-button x-search-icon qa-header-search-icon"
            onClick={this.openSearchBar}
          >
            <i className="fa fa-search" />
          </button>
        ) : (
          <>
            <div className="modal-backdrop fade" onClick={this.onClose} />
            <div className="x-header-button x-global-search-bar">
              <DebounceInputWithSearch
                value={searchValue}
                onClear={this.onClear}
                onChange={this.onSearchChange}
                focus={true}
              />
              {searchValue ? (
                <SearchRecordsWithTabs
                  context={context}
                  searchValue={searchValue}
                  goTo={goTo}
                  onClickRecord={this.onClose}
                />
              ) : undefined}
            </div>
          </>
        )}
      </div>
    );
  }
}
