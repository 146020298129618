// @todo Fix non working notifications! Temporary commenting to prevent errors.

// import * as $ from 'jquery';
// import 'signalr';
// import { PushNotificationService, EventHandler, Unsubscribe } from './types';
// import Proxy = SignalR.Hub.Proxy;
//
// let promise:Promise<any>;
//
// const eventHandlers:EventHandler[] = [];
//
// interface ProxiedSignalR extends SignalR {
//   WallHub: Proxy
// }
//
// export const load = () =>
//   promise
//   ? promise
//   : promise = new Promise((resolve, reject) => {
//       const conn = $.connection as ProxiedSignalR;
//       if (!conn) {
//         console.error('SignalR not loaded');
//         reject();
//         return;
//       }
//
//       $.getScript('/signalr/hubs').then(() => {
//         try {
//           conn.hub.url = '/signalr';
//           conn.hub
//             .start()
//             .done(() => {
//               console.log('SignalR connected');
//               conn.WallHub.on('onEvent', handleEvent);
//               resolve(conn.WallHub);
//             })
//             .fail((e:any) => {
//               console.error('Could not connect to signalR server', e);
//               reject();
//             });
//         } catch (e) {
//           console.error('Could not connect to signalR server', e);
//           reject();
//         }
//       });
//     });
//
// const getProxy = (fn:(p:any) => any) => load().then(fn);
//
// const handleEvent = (e:string) => {
//   const value = JSON.parse(e);
//   eventHandlers.forEach(fn => fn(value));
// };
//
// const onEvent = (fn:EventHandler):Unsubscribe => {
//   eventHandlers.push(fn);
//   return () => eventHandlers.splice(eventHandlers.indexOf(fn), 1);
// };
// const subscribe = (site:string, entity:string, id:string) =>
//   getProxy(p => p.server.subscribe(site, entity, id));
// const unsubscribe = (site:string, entity:string, id:string) =>
//   getProxy(p => p.server.unsubscribe(site, entity, id));
//
// const service:PushNotificationService = {
//   onEvent,
//   subscribe,
//   unsubscribe
// };
//
// export default () => service;

import * as R from "ramda";

export const pushNotificationsService = () => ({
  onEvent: R.always(() => undefined as any),
  subscribe: R.always(undefined),
  unsubscribe: R.always(undefined),
});

export const load = R.always(undefined);
