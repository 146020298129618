import * as R from "ramda";
import { Props } from "react-select";
import {
  GroupedOption,
  LabelledOptionOrGroup,
} from "common/vendor-wrappers/react-select/types";

export const isGroupedOption = <T>(option: any): option is GroupedOption<T> =>
  R.has("options", option);

export const matchLabel = (label: string | number, searchTerm: string) =>
  !searchTerm ||
  (!!label &&
    label
      .toString()
      .toLocaleLowerCase()
      .includes(searchTerm.toLocaleLowerCase()));

export const filterOptions = <T>(
  options: ReadonlyArray<LabelledOptionOrGroup<T>>,
  searchValue: string,
): Props["options"] => {
  if (!options || !searchValue) return options;

  const result: LabelledOptionOrGroup<T>[] = [];

  options.forEach((option) => {
    if (!isGroupedOption(option)) {
      if (matchLabel(option.label, searchValue)) {
        result.push(option);
      }
    } else {
      const groupedOption = option.options.filter((subOption) =>
        matchLabel(subOption.label, searchValue),
      );

      if (groupedOption.length > 0) {
        result.push({ ...option, options: groupedOption });
      }
    }
  });

  return result;
};

export const sliceOptions = <T>(
  options: ReadonlyArray<LabelledOptionOrGroup<T>>,
  limit: number,
): Props["options"] =>
  options && limit
    ? options
        .slice(0, limit)
        .map((o) =>
          isGroupedOption(o) ? { ...o, options: o.options.slice(0, limit) } : o,
        )
    : options;

export const getNoOptionsMessage = ({ inputValue }: { inputValue: string }) =>
  !inputValue ? _("Type to search") : _("No options");
