import {
  Node,
  NodeMap,
  ChildrenMap,
  UNGROUPED,
} from "common/widgets/lazy-tree/types";

/**
 * Adds the 'ungrouped' node to the list of nodes.
 */
export function addUngroupedNode(nodes: Node[]): Node[] {
  return nodes.concat({
    id: UNGROUPED,
    label: _("Ungrouped"),
  });
}

/**
 * Builds a map of node ids to node objects.
 */
export function getNodesMap(nodes: Node[]): NodeMap {
  const nodesMap: NodeMap = {};

  for (const node of nodes) {
    nodesMap[node.id] = node;
  }

  return nodesMap;
}

/**
 * Builds a map of parent node ids to their child nodes.
 */
export function getChildrenMap(nodeMap: NodeMap): ChildrenMap {
  const childrenMap: ChildrenMap = {};

  for (const nodeId in nodeMap) {
    const node = nodeMap[nodeId];

    if (!node.parentId) continue;

    if (!childrenMap[node.parentId]) {
      childrenMap[node.parentId] = [];
    }
    childrenMap[node.parentId].push(node);
  }
  return childrenMap;
}

/**
 * Builds the tree data structures: nodeMap, childrenMap, and roots.
 */
export function buildTreeData(nodes: Node[]): {
  nodeMap: NodeMap;
  childrenMap: ChildrenMap;
  roots: Node[];
} {
  const nodeMap = getNodesMap(nodes);
  const childrenMap = getChildrenMap(nodeMap);
  const roots = nodes.filter((node) => !node.parentId);

  return { nodeMap, childrenMap, roots };
}

/**
 * Filters nodes based on a search term. Includes matching nodes, their ancestors, and descendants.
 */
export function filterNodesBySearchTerm(
  nodeMap: NodeMap,
  childrenMap: ChildrenMap,
  searchTerm: string,
): Node[] {
  const includedNodeIds = new Set<string>();
  const searchTermLower = searchTerm.toLowerCase();

  function addDescendants(nodeId: string) {
    const children = childrenMap[nodeId];

    if (!children) return;

    for (const child of children) {
      if (!includedNodeIds.has(child.id)) {
        includedNodeIds.add(child.id);
        addDescendants(child.id);
      }
    }
  }

  for (const nodeId in nodeMap) {
    const node = nodeMap[nodeId];

    if (node.label.toLowerCase().includes(searchTermLower)) {
      includedNodeIds.add(node.id);

      let currentNode = node;
      while (currentNode.parentId) {
        if (includedNodeIds.has(currentNode.parentId)) break;

        includedNodeIds.add(currentNode.parentId);
        currentNode = nodeMap[currentNode.parentId];
      }

      addDescendants(node.id);
    }
  }

  const filteredNodes = Array.from(includedNodeIds).map((id) => nodeMap[id]);

  return filteredNodes;
}

/**
 * Filters nodes based on expanded nodes. Collapses all nodes by default, expanding specified nodes.
 */
export function filterNodesByExpanded(
  childrenMap: ChildrenMap,
  roots: Node[],
  expandedNodes: string[],
): Node[] {
  const expandedSet = new Set(expandedNodes);
  const resultNodes: Node[] = [];

  function traverse(node: Node) {
    resultNodes.push(node);

    if (expandedSet.has(node.id)) {
      const children = childrenMap[node.id] || [];
      children.sort((a, b) => a.label.localeCompare(b.label));

      for (const child of children) {
        traverse(child);
      }
    }
  }

  roots.sort((a, b) => a.label.localeCompare(b.label));

  for (const root of roots) {
    traverse(root);
  }

  return resultNodes;
}

/**
 * Enriches nodes with their level in the tree, starting from level 0 at the root.
 */
export function getNodesWithLevels(
  nodes: Node[],
  childrenMap: ChildrenMap,
  roots: Node[],
): Node[] {
  for (const node of nodes) {
    node.level = -1;
  }

  function dfs(node: Node, level: number) {
    node.level = level;
    const children = childrenMap[node.id];

    if (!children) return;

    for (const child of children) {
      dfs(child, level + 1);
    }
  }

  for (const root of roots) {
    dfs(root, 0);
  }

  return nodes;
}

/**
 * Sorts nodes so that parents come before their children, with 'ungrouped' nodes at the end.
 */
export function sortNodes(
  nodes: Node[],
  childrenMap: ChildrenMap,
  roots: Node[],
): Node[] {
  const nodeSet = new Set(nodes.map((node) => node.id));
  const sortedNodes: Node[] = [];

  function compareNodes(a: Node, b: Node): number {
    if (a.id === UNGROUPED) return 1;
    if (b.id === UNGROUPED) return -1;
    return a.label.localeCompare(b.label);
  }

  const filteredRoots = roots.filter((root) => nodeSet.has(root.id));

  let ungroupedNode: Node | undefined;
  const otherRoots: Node[] = [];

  for (const root of filteredRoots) {
    if (root.id === UNGROUPED) {
      ungroupedNode = root;
    } else {
      otherRoots.push(root);
    }
  }

  otherRoots.sort(compareNodes);

  function traverse(node: Node) {
    if (!nodeSet.has(node.id)) return;

    sortedNodes.push(node);

    const children = childrenMap[node.id] || [];
    children.sort(compareNodes);

    for (const child of children) {
      traverse(child);
    }
  }

  for (const root of otherRoots) {
    traverse(root);
  }

  if (ungroupedNode) {
    traverse(ungroupedNode);
  }

  return sortedNodes;
}

/**
 * Main function to get nodes prepared for tree display, including filtering and sorting.
 */
export function getNodesForTree(
  nodes: Node[],
  searchTerm?: string,
  expandedNodes?: string[],
): Node[] {
  const nodesWithUngrouped = addUngroupedNode(nodes);

  const { nodeMap, childrenMap, roots } = buildTreeData(nodesWithUngrouped);

  const filteredNodes = searchTerm
    ? filterNodesBySearchTerm(nodeMap, childrenMap, searchTerm)
    : filterNodesByExpanded(childrenMap, roots, expandedNodes ?? []);

  const { childrenMap: filteredChildrenMap, roots: filteredRoots } =
    buildTreeData(filteredNodes);

  const nodesWithLevels = getNodesWithLevels(
    filteredNodes,
    filteredChildrenMap,
    filteredRoots,
  );

  const sortedNodes = sortNodes(
    nodesWithLevels,
    filteredChildrenMap,
    filteredRoots,
  );

  return sortedNodes;
}
