import { Context } from "common/types/context";
import { WorkOrderRescheduledEvent } from "common/types/events";
import { DateTimeLabel } from "common/widgets/date/date-time-label";
import { Row } from "../row";
import { ReasonDetails } from "./reason";

interface PropTypes {
  event: WorkOrderRescheduledEvent;
  context: Context;
  hideLinks?: boolean;
}

export const WorkOrderRescheduled = ({
  event,
  context,
  hideLinks,
}: PropTypes) => {
  const { uiFormat } = context;
  return (
    <div>
      <legend>{_("Details")}</legend>
      <Row label={_("From date")}>
        <DateTimeLabel uiFormat={uiFormat} value={event.details.from} />
      </Row>
      <Row label={_("To date")}>
        <DateTimeLabel uiFormat={uiFormat} value={event.details.to} />
      </Row>
      <Row label={_("Username")}>{event.details.userName}</Row>
      <ReasonDetails
        reason={event.details.reason}
        context={context}
        hideLinks={hideLinks}
      />
    </div>
  );
};

WorkOrderRescheduled.displayName = "WorkOrderRescheduled";
