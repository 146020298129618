import { noOp } from "common";
import type { ApplicationScope } from "common/types/context";
import { LoadingIcon } from "common/widgets/loading-icon";
import { Ribbon } from "x/layout/ribbon";

interface PropTypes {
  scope: ApplicationScope;
}

export const ContentLoading = ({ scope }: PropTypes) => (
  <div className="x-container-with-ribbon">
    <Ribbon
      onRefresh={noOp}
      scope={scope}
      crumbs={[{ name: `${_("Loading")}...` }]}
    />
    <div className="x-content-with-ribbon">
      <div className="x-records-list widget-body x-padding-bottom-0">
        <LoadingIcon />
      </div>
    </div>
  </div>
);

ContentLoading.displayName = "ContentLoading";
