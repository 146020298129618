import { kebabCaseToTitle } from "common/index";

const eventNames = () => ({
  RecordCreated: _("Created"),
  RecordUpdated: _("Updated"),
  RecordDeleted: _("Archived"),
  RecordRestored: _("Restored"),
  WorkOrderOpened: _("Opened"),
  WorkOrderClosed: _("Closed"),
  WorkOrderCompleted: _("Completed"),
  WorkOrderPrepared: _("Prepared"),
  WorkOrderReopened: _("Reopened"),
  WorkOrderRescheduled: _("Rescheduled"),
  RequisitioningSubmitted: _("Requisitioning Submitted"),
  RequisitioningRejected: _("Requisitioning Rejected"),
  RequisitioningItemRejected: _("Requisitioning Item Rejected"),
  RequisitioningItemApproved: _("Requisitioning Item Approved"),
  RequisitioningApproved: _("Requisitioning Approved"),
  RequisitioningReadyForPo: _("Requisitioning Ready For Po"),
  RequestRejected: _("Rejected"),
  RequestApproved: _("Approved"),
  PMTriggered: _("Triggered"),
  StockUpdated: _("Stock Updated"),
  PurchaseOrderSent: _("Purchase Order Sent"),
  PurchaseOrderReceive: _("Purchase Order Received"),
  PurchaseOrderReturn: _("Purchase Order Returned"),
  PurchaseOrderAdjustment: _("Purchase Order Adjustment"),
  ApproverSetOwner: _("Approver Set as Owner"),
  SetDefaultCostCenter: _("Set Default Cost Center"),
  ReorderPart: _("Reorder Part"),
  PartsCheckedIn: _("Parts Checked In"),
  PartsCheckedOut: _("Parts Checked Out"),
  PartSupplierSetDefault: _("Set As Default"),
  PdfGenerated: _("PDF Generated"),
  StockSetDefault: _("Set As Default"),
  DocumentAdded: _("Document Added"),
  DocumentRemoved: _("Document Removed"),
  AssignmentPublished: _("Assignment Published"),
  PurchaseOrderClosed: _("Purchase Order Closed"),
  PurchaseOrderCancel: _("Purchase Order Cancelled"),
  RimeRankingUpdated: _("Rime Ranking Updated"),
  PartCountSubmitted: _("Part Count Submitted"),
  PartCountCancelled: _("Part Count Cancelled"),
});

const getEventName = (name: string) => name.split("Event.")[0];

export const getDetailsEventName = (name: string, userName: string) => {
  const isWorkflow = userName?.toLowerCase() === "workflow engine";
  return isWorkflow ? "RecordUpdated" : getEventName(name);
};

export const getEventEntityName = (name: string) => name.split(".")[1];

export const getEventLocalizedName = (name: string, userName: string) => {
  if (!name) return _("Unknown event");

  const isWorkflow = userName.toLowerCase() === "workflow engine";
  const eventName = getEventName(name);

  const localizedName =
    (eventNames() as any)[eventName] || kebabCaseToTitle(eventName);

  return isWorkflow
    ? _("Workflow engine response to: EVENT").replace("EVENT", localizedName)
    : localizedName;
};
