//                    ____
//                 _.' :  `._
//             .-.'`.  ;   .'`.-.
//    __      / : ___\ ;  /___ ; \      __
//  ,'_ ""--.:__;".-.";: :".-.":__;.--"" _`,
//  :' `.t""--.. '<@.`;_  ',@>` ..--""j.' `;
//       `:-.._J '-.-'L__ `-- ' L_..-;'     This file edit not! Generated
//         "-.__ ;  .-"  "-.  : __.-"       it was by running ./extract.sh
//             L ' /.------.\ ' J         /
//              "-.   "--"   .-"
//             __.l"-:_JL_;-";.__
//          .-j/'.;  ;""""  / .'\"-.
//        .' /:`. "-.:     .-" .';  `.
//     .-"  / ;  "-. "-..-" .-"  :    "-.
//  .+"-.  : :      "-.__.-"      ;-._   \
//  ; \  `.; ;                    : : "+. ;
//  :  ;   ; ;                    : ;  : \:
// : `."-; ;  ;                  :  ;   ,/;
//  ;    -: ;  :                ;  : .-"'  :
//  :\     \  : ;             : \.-"      :
//   ;`.    \  ; :            ;.'_..--  / ;
//   :  "-.  "-:  ;          :/."      .'  :
//     \       .-`.\        /t-""  ":-+.   :
//      `.  .-"    `l    __/ /`. :  ; ; \  ;
//        \   .-" .-"-.-"  .' .'j \  /   ;/
//         \ / .-"   /.     .'.' ;_:'    ;
//          :-""-.`./-.'     /    `.___.'
//                \ `t  ._  /
//                 "-.t-._:'

// Open season on dolphins
export const messages = (key: string): string => {
  switch (key) {
    case "AdjustPurchaseOrderCommand":
      return _("Adjust Purchase Order");
    case "AdminPasswordResetRequestEvent":
      return _("Admin Password Reset Request");
    case "ApproveRequestCommand":
      return _("Approve Request");
    case "ApproveRequisitioningItemCommand":
      return _("Approve Requisitioning Item");
    case "ApproverSetOwnerEvent":
      return _("Approver Set Owner");
    case "AssetCreatedEvent":
      return _("Asset Created");
    case "AssetDeletedEvent":
      return _("Asset Deleted");
    case "AssetMeterReadingCreatedEvent":
      return _("Asset Meter Reading Created");
    case "AssetMeterReadingUpdatedEvent":
      return _("Asset Meter Reading Updated");
    case "AssetRestoredEvent":
      return _("Asset Restored");
    case "AssetUpdatedEvent":
      return _("Asset Updated");
    case "AssignmentPublishedEvent":
      return _("Assignment Published");
    case "BlackListEmailAddressCommand":
      return _("Black List Email Address");
    case "BreakKitCommand":
      return _("Break Kit");
    case "CalendarUpdatedEvent":
      return _("Calendar Updated");
    case "CancelPurchaseOrderCommand":
      return _("Cancel Purchase Order");
    case "ClosePurchaseOrderCommand":
      return _("Close Purchase Order");
    case "CloseWorkOrderCommand":
      return _("Close Work Order");
    case "CompleteWorkOrderCommand":
      return _("Complete Work Order");
    case "CreateKitCommand":
      return _("Create Kit");
    case "CreateMultipleEntityRecordsCommand":
      return _("Create Multiple Entity Records");
    case "CreatePoFromReorderListCommand":
      return _("Create Po From Reorder List");
    case "CreatePoFromRequisitioningCommand":
      return _("Create Po From Requisitioning");
    case "CreateRecordCommand":
      return _("Create Record");
    case "CreateRecordsCommand":
      return _("Create Records");
    case "CreateWorkOrderFromRequestCommand":
      return _("Create Work Order From Request");
    case "CreateWorkflowCommand":
      return _("Create Workflow");
    case "CsvImportEvent":
      return _("Csv Import");
    case "CsvResolveForeignKeyEvent":
      return _("Csv Resolve Foreign Key");
    case "CsvUndoImportEvent":
      return _("Csv Undo Import");
    case "DeleteRecordCommand":
      return _("Delete Record");
    case "DeleteRecordsCommand":
      return _("Delete Records");
    case "DisableRimeRankingEvent":
      return _("Disable Rime Ranking");
    case "DocumentAddedEvent":
      return _("Document Added");
    case "DocumentRemovedEvent":
      return _("Document Removed");
    case "DomainEvent":
      return _("Domain");
    case "EmailTemplateCreatedEvent":
      return _("Email Template Created");
    case "EmailTemplateDeletedEvent":
      return _("Email Template Deleted");
    case "EmailTemplateUpdatedEvent":
      return _("Email Template Updated");
    case "EnableRimeRankingEvent":
      return _("Enable Rime Ranking");
    case "EventAssetUpdatedEvent":
      return _("Event Asset Updated");
    case "ExecuteRuleCommand":
      return _("Execute Rule");
    case "MultiTenantCommand":
      return _("Multi Tenant");
    case "OpenWorkOrderCommand":
      return _("Open Work Order");
    case "PartSupplierSetDefaultEvent":
      return _("Part Supplier Set Default");
    case "PartsCheckedInEvent":
      return _("Parts Checked In");
    case "PartsCheckedOutEvent":
      return _("Parts Checked Out");
    case "PublishAssignmentCommand":
      return _("Publish Assignment");
    case "PurchaseOrderAdjustmentEvent":
      return _("Purchase Order Adjustment");
    case "PurchaseOrderCancelEvent":
      return _("Purchase Order Cancel");
    case "PurchaseOrderClosedEvent":
      return _("Purchase Order Closed");
    case "PurchaseOrderReceiveEvent":
      return _("Purchase Order Receive");
    case "PurchaseOrderReturnEvent":
      return _("Purchase Order Return");
    case "PurchaseOrderSentEvent":
      return _("Purchase Order Sent");
    case "PurchaseRequisitioningCommand":
      return _("Purchase Requisitioning");
    case "QuickTimerElapsedEvent":
      return _("Quick Timer Elapsed");
    case "ReOpenWorkOrderCommand":
      return _("Re Open Work Order");
    case "ReadRecordCommand":
      return _("Read Record");
    case "ReadRecordsCommand":
      return _("Read Records");
    case "ReadRelatedRecordsCommand":
      return _("Read Related Records");
    case "ReceivePurchaseOrderCommand":
      return _("Receive Purchase Order");
    case "RecordCreatedEvent":
      return _("Record Created");
    case "RecordDeletedEvent":
      return _("Record Deleted");
    case "RecordEvent":
      return _("Record");
    case "RecordRestoredEvent":
      return _("Record Restored");
    case "RecordUpdatedEvent":
      return _("Record Updated");
    case "RejectRequestCommand":
      return _("Reject Request");
    case "RejectRequisitioningCommand":
      return _("Reject Requisitioning");
    case "RejectRequisitioningItemCommand":
      return _("Reject Requisitioning Item");
    case "ReorderPartEvent":
      return _("Reorder Part");
    case "RequestApprovedEvent":
      return _("Request Approved");
    case "RequestRejectedEvent":
      return _("Request Rejected");
    case "RequisitioningApprovedEvent":
      return _("Requisitioning Approved");
    case "RequisitioningItemApprovedEvent":
      return _("Requisitioning Item Approved");
    case "RequisitioningItemRejectedEvent":
      return _("Requisitioning Item Rejected");
    case "RequisitioningReadyForPoEvent":
      return _("Requisitioning Ready For Po");
    case "RequisitioningRejectedEvent":
      return _("Requisitioning Rejected");
    case "RequisitioningSubmittedEvent":
      return _("Requisitioning Submitted");
    case "RescheduleWorkOrderCommand":
      return _("Reschedule Work Order");
    case "RestoreRecordCommand":
      return _("Restore Record");
    case "ReturnPurchaseOrderCommand":
      return _("Return Purchase Order");
    case "SandboxCreatedEvent":
      return _("Sandbox Created");
    case "SandboxResetEvent":
      return _("Sandbox Reset");
    case "ScheduleRimeRankingMidnightEvent":
      return _("Schedule Rime Ranking Midnight");
    case "ScheduledEventCreatedEvent":
      return _("Scheduled Event Created");
    case "ScheduledEventDeletedEvent":
      return _("Scheduled Event Deleted");
    case "ScheduledEventRestoredEvent":
      return _("Scheduled Event Restored");
    case "ScheduledEventUpdatedEvent":
      return _("Scheduled Event Updated");
    case "SendPurchaseOrderCommand":
      return _("Send Purchase Order");
    case "SendRecordCommand":
      return _("Send Record");
    case "SendStoreQueryEvent":
      return _("Send Store Query");
    case "SetDefaultCostCenterCommand":
      return _("Set Default Cost Center");
    case "SetDefaultCostCenterEvent":
      return _("Set Default Cost Center");
    case "SetDefaultPartSupplierCommand":
      return _("Set Default Part Supplier");
    case "SetDefaultStockCommand":
      return _("Set Default Stock");
    case "SetGroupOwnerCommand":
      return _("Set Group Owner");
    case "SignRecordCommand":
      return _("Sign Record");
    case "SiteCreatedEvent":
      return _("Site Created");
    case "SiteDeletedEvent":
      return _("Site Deleted");
    case "StockAdjustedEvent":
      return _("Stock Adjusted");
    case "StockSetDefaultEvent":
      return _("Stock Set Default");
    case "StockTransferredEvent":
      return _("Stock Transferred");
    case "StockUpdatedEvent":
      return _("Stock Updated");
    case "StoreQueryEvent":
      return _("Store Query");
    case "StoredQueryEvent":
      return _("Stored Query");
    case "SubmitRequisitioningCommand":
      return _("Submit Requisitioning");
    case "TaskItemCreatedEvent":
      return _("Task Item Created");
    case "TaskItemDeletedEvent":
      return _("Task Item Deleted");
    case "TaskItemRestoredEvent":
      return _("Task Item Restored");
    case "TaskItemUpdatedEvent":
      return _("Task Item Updated");
    case "TaskUpdatedEvent":
      return _("Task Updated");
    case "TemporaryDocumentAddedEvent":
      return _("Temporary Document Added");
    case "TenantActivatedEvent":
      return _("Tenant Activated");
    case "TenantDeployedEvent":
      return _("Tenant Deployed");
    case "TenantMetadataChangedEvent":
      return _("Tenant Metadata Changed");
    case "TenantPasswordResetRequestEvent":
      return _("Tenant Password Reset Request");
    case "TenantSettingsChangedEvent":
      return _("Tenant Settings Changed");
    case "TenantUserLoggedInEvent":
      return _("Tenant User Logged In");
    case "TriggerExternalEvent":
      return _("Trigger External");
    case "TriggerMatchingExternalEvent":
      return _("Trigger Matching External");
    case "UnscheduleRimeRankingMidnightEvent":
      return _("Unschedule Rime Ranking Midnight");
    case "UpdateArchivedRecordCommand":
      return _("Update Archived Record");
    case "UpdateReadOnlyRecordCommand":
      return _("Update Read Only Record");
    case "UpdateRecordCommand":
      return _("Update Record");
    case "UpdateRecordsCommand":
      return _("Update Records");
    case "UpdateRequisitioningItemQuantityCommand":
      return _("Update Requisitioning Item Quantity");
    case "UpdateSupplierForItemCommand":
      return _("Update Supplier For Item");
    case "UserCreatedEvent":
      return _("User Created");
    case "WorkOrderClosedEvent":
      return _("Work Order Closed");
    case "WorkOrderCompletedEvent":
      return _("Work Order Completed");
    case "WorkOrderCreatedEvent":
      return _("Work Order Created");
    case "WorkOrderDeletedEvent":
      return _("Work Order Deleted");
    case "WorkOrderOpenedEvent":
      return _("Work Order Opened");
    case "WorkOrderPreparedEvent":
      return _("Work Order Prepared");
    case "WorkOrderReopenedEvent":
      return _("Work Order Reopened");
    case "WorkOrderRescheduledEvent":
      return _("Work Order Rescheduled");
    case "WorkOrderRestoredEvent":
      return _("Work Order Restored");
    case "WorkOrderUpdatedEvent":
      return _("Work Order Updated");
    default:
      return undefined;
  }
};
