import * as R from "ramda";
import { getSubFKTitle } from "common/functions/foreign-key";
import { Calendar } from "common/types/calendars";
import { ForeignKey } from "common/types/foreign-key";
import { ListWithSelectItem } from "./types";

export const canSelectAll = (items: ListWithSelectItem[]): boolean =>
  !!(items && items.length && R.any((item) => !item.isSelected, items));

export const canDeselectAll = (items: ListWithSelectItem[]): boolean =>
  !!(items && items.length && R.any((item) => item.isSelected, items));

export const filterById = (
  items: ListWithSelectItem[] = [],
  filterByItems: ListWithSelectItem[] = [],
): ListWithSelectItem[] =>
  items.filter((item) => R.find((i) => i.id === item.id, filterByItems));

export const calendarToOption = ({
  id,
  label,
}: Calendar): ListWithSelectItem => ({
  id: id.toString(),
  label,
  isSelected: true,
});

export const fkToOption = ({
  number: fkNumber,
  id,
  title,
}: ForeignKey): ListWithSelectItem => ({
  id,
  label: !R.isNil(title) ? getSubFKTitle(title) : fkNumber.toString(),
  isSelected: true,
});
