import { defaultFor } from "common";
import { TableWithReorder } from "common/query/table";
import { Cards } from "common/data/cards";
import { RecordTree } from "x/records/list/tree/record-tree";
import { getConfig, hasDescription } from "x/records/list/functions/common";
import { getRecordsWithActions } from "../functions";
import { ListBodyPropTypes } from "./types";

export const BodyOutput = ({
  context,
  entity,
  output,
  records,
  actionsByRecordId,
  loading,
  withLinks,
  isReport,
  starred,
  toggleStar,
  reload,
  goTo,
  onChange,
  value = defaultFor(),
  widgetsMapper,
  config,
}: ListBodyPropTypes) => {
  const { query } = value;

  switch (output) {
    case "Cards":
      return (
        <Cards
          key="display"
          context={context}
          records={getRecordsWithActions(records, actionsByRecordId)}
          title={hasDescription(query) ? "description" : undefined}
          query={query}
          withLinks={withLinks}
          widgetsMapper={widgetsMapper}
          reload={reload}
          goTo={goTo}
        />
      );
    case "Tree":
      return (
        <RecordTree
          context={context}
          entity={entity}
          hasRecords={!!records && !!records.length}
        />
      );
    default:
      return (
        <TableWithReorder
          key="display"
          context={context}
          isLoading={loading}
          config={config ?? getConfig(entity, isReport)}
          reload={reload}
          goTo={goTo}
          starred={starred}
          toggleStar={toggleStar}
          withLinks={withLinks}
          value={{ ...value, data: records, actionsByRecordId }}
          onChange={onChange}
          widgetsMapper={widgetsMapper}
        />
      );
  }
};
