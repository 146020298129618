import * as R from "ramda";
import { KeysOf } from "common/types/records";
import { Culture } from "common/culture/supported-cultures";
import { Labels } from "common/culture/types";
import { Context } from "common/types/context";
import {
  Config,
  Dashboard,
  LinkConfig,
  ReportConfig,
} from "common/types/dashboards";

export const isLinkConfig = (config: Config): config is LinkConfig => {
  const keys = R.keys(config) as KeysOf<LinkConfig>;
  return R.includes("label", keys);
};

export const isReportConfig = (config: Config): config is ReportConfig => {
  const keys = R.keys(config) as KeysOf<ReportConfig>;
  return R.includes("output", keys);
};

export const getLabel = (
  labels: Labels,
  defaultLabel: string,
  labelKey: string,
  culture: Culture,
): string => {
  if (culture === "en-US" || !labels || !labels[culture]) {
    return defaultLabel;
  }
  const label = labels[culture][labelKey];

  return label || defaultLabel;
};

export const getDashboard = (dashboards: Dashboard[] = [], id: number) =>
  R.find((d) => d.id === id, dashboards);

const hasRoleName = (context: Context, d: Dashboard) =>
  context.role.permissions["*"] && d.roleName && d.roleId && d.roleId !== 1;

export const displayDashboard = (context: Context) => (d: Dashboard) =>
  hasRoleName(context, d) ? `${d.label} - ${d.roleName}` : d.label;
