export const translateOption = (option: string) => {
  switch (option) {
    case "day":
      return _("Day View");
    case "week":
      return _("Week View");
    case "month":
      return _("Month View");
    case "showWeekends":
      return _("Show weekends");
    case "hideWeekends":
      return _("Hide weekends");
    case "separator":
      return "";
    default:
      return option;
  }
};
