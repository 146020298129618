import * as R from "ramda";
import { ValueComponent, ValueProps } from "common/with-value-for";
import { PropTypesBase, CheckboxList as List } from "./list-base";

type Value<T> = T[];

type PropTypes<T> = PropTypesBase<T>;

export type Props<T> = PropTypes<T> & ValueProps<Value<T>>;

export function createList<T>() {
  return class CheckboxList extends ValueComponent<Value<T>, PropTypes<T>> {
    static readonly displayName = "CheckboxList";

    isChecked = (item: T) => {
      const { value = [] } = this.props;
      return R.includes(item, value);
    };
    onToggle = (item: T, isChecked: boolean) => {
      const { value = [] } = this.props;
      const newValue = isChecked
        ? value.concat([item])
        : value.filter((v) => v !== item);
      this.setValue(newValue, true);
    };
    render() {
      return (
        <List<T>
          {...this.props}
          isChecked={this.isChecked}
          onToggle={this.onToggle}
        />
      );
    }
  };
}
