import * as R from "ramda";
import * as React from "react";
import { toKebabCase } from "common";
import { getColorByRuleId } from "common/api/color-rule";
import { getColumn } from "common/entities";
import type { EntityColumn } from "common/entities/entity-column/types";
import { LabelWidget } from "common/form/widget/label-widget";
import { getColorShade, stringifyRgb } from "common/functions/colors";
import {
  isSelectExpression,
  isSelectField,
  isSummaryField,
} from "common/query/types";
import type { Context } from "common/types/context";
import { arrayToString } from "common/utils/array";
import { DEFAULT_EVENT_COLOR as DEFAULT_COLOR } from "x/scheduler2/consts";
import { getRawEventData } from "x/scheduler2/planner/unassigned-work/functions/event";
import type {
  OnWorkOrderViewClickFn,
  WorkOrder,
} from "x/scheduler2/planner/unassigned-work/types";
import type { EntityQueries } from "x/scheduler2/types";
import { defaultWOSelect } from "./functions";

interface PropTypes {
  workOrder: WorkOrder;
  draggable: boolean;
  context: Context;
  customQueries: EntityQueries;
  searchValue: string;
  onClick: OnWorkOrderViewClickFn;
}

export class UnassignedWorkTile extends React.Component<PropTypes> {
  getSelectFields = () => {
    const { workOrder, customQueries } = this.props;

    return (
      customQueries?.[workOrder.SYSTEM_entityName]?.query?.select ||
      defaultWOSelect
    );
  };

  getTileFields = (context: Context) => {
    const { workOrder } = this.props;
    const { entities } = context;
    const entity = entities[workOrder.SYSTEM_entityName];

    return this.getSelectFields().map((field, index) =>
      isSelectField(field)
        ? this.getFieldWidget(
            context,
            index,
            workOrder[field.alias || field.name],
            field.alias,
            getColumn(entity, field.name),
          )
        : isSelectExpression(field)
          ? this.getFieldWidget(
              context,
              index,
              workOrder[field.alias],
              field.alias,
            )
          : isSummaryField(field)
            ? this.getFieldWidget(
                context,
                index,
                workOrder[field.entityName],
                field.entityName,
              )
            : undefined,
    );
  };

  getFieldWidget = (
    context: Context,
    index: number,
    value: any,
    label?: string,
    column?: EntityColumn,
  ) => {
    const { workOrder, searchValue } = this.props;

    if (R.isNil(value)) return undefined;

    const fieldId = toKebabCase(
      label || (column ? column.name : String(index)),
    );
    const classNames = arrayToString([
      "x-labeled-value",
      `qa-${fieldId}-field`,
    ]);

    return (
      <div className={classNames} key={index}>
        {label && <div className="x-label">{label}:</div>}
        <div className="x-value">
          <LabelWidget
            context={context}
            entityName={workOrder.SYSTEM_entityName}
            column={column}
            withLinks={true}
            textToHighlight={searchValue}
            value={value}
          />
        </div>
      </div>
    );
  };

  getDynamicStyle = (): React.CSSProperties => {
    const { context, workOrder } = this.props;
    const { entities } = context;
    const entity = entities[workOrder.SYSTEM_entityName];

    const ruleColor =
      getColorByRuleId(entity.colorRules, workOrder.entityColorRuleId) ??
      DEFAULT_COLOR;

    return {
      borderColor: stringifyRgb(ruleColor),
      // Add a CSS Custom Property for dynamic hover color based on ruleColor
      ["--hover-color" as any]: stringifyRgb(getColorShade(ruleColor, -0.15)),
    };
  };

  render() {
    const { context, workOrder, draggable, onClick } = this.props;

    if (!workOrder || !workOrder.SYSTEM_id) return null;

    const woContext = context.site.isGroup
      ? context.createContextForSiteWithFallback(workOrder.site)
      : context;

    const eventData = JSON.stringify(getRawEventData(workOrder));

    return (
      <div
        className={`x-scheduler-panel-tile qa-scheduler-panel-tile ${
          draggable ? "x-draggable" : ""
        }`}
        data-event={eventData}
        style={this.getDynamicStyle()}
      >
        <div className="x-tile-info x-padding-10 x-margin-left-5">
          {this.getTileFields(woContext)}
          {draggable && <i className="fa fa-ellipsis-v" />}
        </div>
        {onClick && (
          <div className="x-tile-clickable" onClick={onClick(workOrder)}>
            {_("view")}
          </div>
        )}
      </div>
    );
  }
}
