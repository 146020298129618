import * as R from "ramda";
import { defaultFor } from "common";
import { UTCDateTime } from "common/date-time/types";
import { behaveAs, getColumns } from "common/entities";
import {
  isSameColumnData,
  looseColumnNameCheck,
} from "common/entities/entity-column/functions";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import {
  getLayoutGroupColumn,
  getLayoutGroupColumns,
} from "common/form/functions/common";
import { Group } from "common/form/types";
import { SelectField } from "common/query/types";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";

export interface AdditionalProps {
  currencyId?: string;
  maxDate?: UTCDateTime;
  minDate?: UTCDateTime;
  placeholder?: string;
}

export const EXTRA_PREFIX = "extra_";

export const getOverwritableColumns = (entity: Entity) => {
  return getColumns(entity)
    .filter((c) => c.canAutoPopulationOverwrite)
    .map((c) => c.name);
};

export const getCommonEntityFields = (
  props: Properties,
  currentEntityColumn: EntityColumn,
  groups: Group[] = [],
  entity: Entity,
  relatedEntity: Entity,
): SelectField[] => {
  const layoutColumns = getLayoutGroupColumns(groups);
  const currentLayoutColumn = getLayoutGroupColumn(
    layoutColumns,
    currentEntityColumn?.name,
  );
  const lookupConfiguration = currentLayoutColumn?.lookupConfiguration;

  if (lookupConfiguration) {
    return lookupConfiguration.mappedFields.map((mappedField) => ({
      name: mappedField.targetColumnName,
      alias: EXTRA_PREFIX + mappedField.columnName,
    }));
  }

  const overwritableColumns = getOverwritableColumns(entity);
  const propKeysWithValue = R.keys(props).filter(
    (k) => !R.includes(k, overwritableColumns) && !R.isNil(props[k]),
  );
  const entityColumns = getColumns(entity);
  const relatedEntityColumns = getColumns(relatedEntity);
  const layoutColumnNames = layoutColumns
    .map((c) => c.columnName)
    .filter(
      (name) =>
        !R.includes(name, propKeysWithValue) &&
        name !== currentEntityColumn?.name,
    );

  return relatedEntityColumns.reduce((acc, crc) => {
    const { name } = crc;
    return entityColumns?.some(isSameColumnData(crc)) &&
      layoutColumnNames?.some((columnName) =>
        looseColumnNameCheck(name, columnName),
      )
      ? acc.concat({ name, alias: EXTRA_PREFIX + name })
      : acc;
  }, []);
};

const getAdditionalDateRangeProps = (
  entity: Entity,
  fieldName: string,
  props: Properties = defaultFor<Properties>(),
): AdditionalProps => {
  const { rangeFrom, rangeTo } = props;
  if (behaveAs("Event", entity)) {
    if (fieldName === "rangeFrom" && rangeTo) {
      return { maxDate: rangeTo };
    }
    if (fieldName === "rangeTo" && rangeFrom) {
      return { minDate: rangeFrom };
    }
  }
  return {};
};

export const getAdditionalCurrencyProps = (
  entity: Entity,
  column: EntityColumn,
  props: Properties,
  context: Context,
): AdditionalProps => {
  if (!entity || !column || !props) return {};

  if (
    behaveAs("PurchaseOrderItem", entity) &&
    R.includes(column.name, [
      "unitCostWithConversionRate",
      "subTotal",
      "taxValue",
      "total",
    ])
  ) {
    return { currencyId: context.currency.id };
  }

  if (
    behaveAs("PurchaseOrderItem", entity) ||
    behaveAs("PartSupplier", entity) ||
    behaveAs("RequisitioningItem", entity)
  ) {
    if (column.dataType === "currency" && props.currency) {
      return { currencyId: props.currency.id || props.currency };
    }
  }

  if (behaveAs("PartsReorder", entity)) {
    return {
      currencyId:
        column.name === "totalCost" ? context.site.currency : props.currencyId,
    };
  }

  return {};
};

const getPlaceholder = (
  entity: Entity,
  column: EntityColumn,
): AdditionalProps => {
  if (
    behaveAs("PurchaseOrderItem", entity) &&
    R.includes(column.name, ["taxRateId"])
  ) {
    return { placeholder: _("Tax Exempt") };
  }
  return {};
};

export const getAdditionalProps = (
  entity: Entity,
  column: EntityColumn,
  props: Properties,
  context: Context,
): AdditionalProps => {
  if (!entity || !column || !props) return {};

  return {
    ...getPlaceholder(entity, column),
    ...getAdditionalDateRangeProps(entity, column.name, props),
    ...getAdditionalCurrencyProps(entity, column, props, context),
  };
};
