import { Event } from "common/types/events";
import { ApiCall } from "common/types/api";

export const eventsApi = (apiCall: ApiCall) => ({
  get: (entity: string, id: string, offset: number = 0) =>
    apiCall<Event[]>(
      "get",
      `api/entities/:site/${entity}/${id}/events?offset=${offset}`,
    ),
  getForSite: (site: string, entity: string, id: string) =>
    apiCall<Event[]>("get", `api/entities/${site}/${entity}/${id}/events`),
});
