import { Component } from "react";
import { purchaseOrdersApi } from "common/api/purchase-orders";
import { Action } from "common/record/actions/action";
import { PropTypes } from "common/record/actions/types";
import { FormValue } from "common/record/actions/ui/purchase-order/return-items/types";
import { hasProtectedColumns } from "common/record/utils";
import { ApiCall } from "common/types/api";
import { ApiErrorResponse } from "common/types/error";
import { CancellablePromise } from "common/types/promises";
import { PoDetails } from "common/types/purchase-orders";
import { ApiError } from "common/ui/api-error";
import { LoadingIcon } from "common/widgets/loading-icon";
import { ReturnItemsForm } from "./form";
import { createReturnPayload, isValidForm } from "./functions";

interface StateType {
  detailsLoading: boolean;
  detailsError: ApiErrorResponse;
  details: PoDetails;
  formValue: FormValue;
}

export class ReturnItems extends Component<PropTypes, StateType> {
  static readonly displayName = "ReturnItems";

  state: StateType = {
    detailsLoading: false,
    detailsError: undefined,
    details: undefined,
    formValue: { purchaseOrderItems: [], reason: undefined },
  };

  componentDidMount() {
    this.loadDetails();
  }

  loadDetails = () => {
    const { context, records = [], entity } = this.props;
    const id = records[0]?.properties?.id;
    const entityName = entity && entity.name;

    if (entityName && id) {
      this.setState({ detailsLoading: true });

      purchaseOrdersApi(context.apiCall)
        .getDetails(entityName, id)
        .then((details) => {
          return this.setState({
            details,
            formValue: {
              purchaseOrderItems: details.purchaseOrderItems.map((item) => ({
                ...item,
                returningQty: 0,
              })),
              reason: undefined,
            },
            detailsLoading: false,
            detailsError: undefined,
          });
        })
        .catch((error) =>
          this.setState({
            detailsLoading: false,
            detailsError: error,
          }),
        );
    }
  };

  onFormChange = (formValue: FormValue) => {
    this.setState({ formValue });
  };

  onOk = (apiCall: ApiCall, dismiss: () => void) => {
    const { entity, records } = this.props;
    const { formValue, details } = this.state;
    const id = records[0]?.properties?.id;

    const returnPayload = createReturnPayload(
      formValue,
      details.returnAdjustmentType,
    );

    return purchaseOrdersApi(apiCall)
      .returnItems(entity?.name, id, returnPayload)
      .then(dismiss)
      .catch((e: Error) => {
        this.loadDetails();
        return CancellablePromise.reject(e);
      });
  };

  render() {
    const { context, dismiss, entity, records } = this.props;
    const { detailsLoading, detailsError, formValue, details } = this.state;

    const valid = isValidForm(formValue);

    return (
      <Action
        requiresAuthentication={hasProtectedColumns(entity)}
        context={context}
        dismiss={dismiss}
        entity={entity}
        records={records}
        title={_("Return")}
        btnLabel={_("Return Items")}
        size={"large"}
        hideRecordNumber={true}
        onOk={valid ? this.onOk : undefined}
      >
        {detailsLoading && <LoadingIcon />}
        {detailsError && (
          <ApiError error={detailsError} className="x-margin-bottom-20-i" />
        )}
        {details && (
          <ReturnItemsForm
            context={context}
            entity={entity}
            details={details}
            value={formValue}
            onChange={this.onFormChange}
          />
        )}
      </Action>
    );
  }
}
