import * as R from "ramda";
import { merge3 } from "common/merge";
import { assignOrder } from "common/record/form/content/procedures/functions";
import { recordToPayload } from "common/record/utils";
import { RelatedValue, CustomConfig, CustomConfigSettings } from "../types";

export const orderRef: CustomConfig<RelatedValue> = ({
  relatedEntity,
  value: { record, related },
}): CustomConfigSettings<RelatedValue> => {
  const relatedEntityName = relatedEntity.name;
  const existingRecords = record.related?.[relatedEntityName] || [];
  const changedRecords = related.form?.[relatedEntityName] || [];

  const [deletedRecords = [], newRecords = []] = R.partition(
    (record) => record.properties.isDeleted,
    changedRecords,
  );

  const order =
    (newRecords.length || existingRecords.length - deletedRecords.length) + 1;

  return {
    defaultForm: { order },
    layoutMapper: (l) => l,
    custom: () => undefined,
    customOnChange: (value: RelatedValue): RelatedValue => {
      const { record } = value;
      const existingRecords = record.related?.[relatedEntityName] || [];

      const changes = value.related.form?.[relatedEntityName] || [];

      const oldRecords = existingRecords.filter(
        (r) => !R.find((rc) => rc.properties.id === r.properties.id, changes),
      );

      return merge3(
        "related",
        "form",
        relatedEntityName,
        assignOrder([...oldRecords, ...changes].map(recordToPayload)),
        value,
      );
    },
  };
};
