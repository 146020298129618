import * as R from "ramda";
import { Component } from "react";
import { defaultFor } from "common";
import { schedulerApi } from "common/api/scheduler";
import type { UTCDateTime } from "common/date-time/types";
import type { Context } from "common/types/context";
import type { Form } from "common/types/forms";
import { CancellablePromise } from "common/types/promises";
import type { SchedulerSettingsType } from "common/types/scheduler-settings";
import { ApiError } from "common/ui/api-error";
import { trackEvent } from "common/utils/mixpanel";
import { Backdrop } from "common/widgets/backdrop";
import { LoadingIcon } from "common/widgets/loading-icon";
import { PersonalEventView } from "x/scheduler2/personal-event-view";
import { getPersonalEventEntity } from "x/scheduler2/personal-event-view/functions";
import { filterValueToId } from "x/scheduler2/planner/header/filter/filter-value";
import {
  getSchedulerData,
  setSchedulerPreferences,
} from "x/scheduler2/preferences";
import { SchedulerRibbon } from "x/scheduler2/ribbon";
import {
  getWorkingDateRange,
  translateViewForPreferences,
} from "x/scheduler2/shared";
import { getResourcesEvents } from "x/scheduler2/shared/events";
import type {
  MouseEvent,
  EventModel,
  SchedulerData,
  SelectedWorkOrder,
  UserContact,
  ViewType,
  TimeRange,
} from "x/scheduler2/types";
import { LazyBryntumCalendar } from "x/scheduler2/wrappers/lazy-calendar";
import { isAssignmentEvent, isRelatedEvent } from "common/functions/scheduler";
import type { Record } from "common/types/records";
import { calendarEventRenderer } from "x/scheduler2/planner/events";
import { WorkOrderView } from "../workorder-view";
import { getAssignmentsWithConflicts } from "../planner/unassigned-work/functions";
import { getTimeRanges } from "../shared/time-ranges";

interface PropTypes {
  context: Context;
  userContact: UserContact;
  schedulerSettings: SchedulerSettingsType;
  hidePanels?: boolean;
}

interface StateType {
  events: EventModel[];
  value?: SchedulerData;
  newPersonalEventFormId: number;
  selectedPersonalEvent: EventModel;
  selectedWorkOrder: SelectedWorkOrder;
  timeRanges: TimeRange[];
  isLoading: boolean;
  error: any;
}

export class TechnicianArea extends Component<PropTypes, StateType> {
  personalEventViewRef: PersonalEventView = undefined;
  workOrderViewRef: WorkOrderView = undefined;
  getContactEventsRequest: CancellablePromise<unknown>;

  constructor(props: PropTypes) {
    super(props);

    this.state = {
      events: [],
      value: getSchedulerData(props.context),
      newPersonalEventFormId: undefined,
      selectedPersonalEvent: undefined,
      selectedWorkOrder: undefined,
      timeRanges: [],
      isLoading: false,
      error: undefined,
    };
  }

  componentDidMount() {
    this.getEvents();
  }

  componentDidUpdate(prevProps: PropTypes, prevState: StateType) {
    if (
      prevProps.context.site.name !== this.props.context.site.name ||
      prevState.value !== this.state.value
    ) {
      this.getEvents();
    }
  }

  componentWillUnmount() {
    this.getContactEventsRequest?.cancel();
  }

  getEvents = () => {
    const { context } = this.props;
    const { value } = this.state;
    const { view, filterValue } = value;
    const { apiCall } = context;

    this.setState({ isLoading: true });

    const { from, to } = getWorkingDateRange(value);

    this.getContactEventsRequest = schedulerApi(apiCall)
      .getContactEvents(
        from,
        to,
        translateViewForPreferences(view),
        true,
        filterValueToId(filterValue),
      )
      .then((contactEvents = []) => {
        const timeRanges = getTimeRanges(contactEvents);
        const events = getResourcesEvents(context, contactEvents);

        this.setState({
          events,
          timeRanges,
          isLoading: false,
          error: undefined,
        });
      })
      .catch((error) =>
        this.setState({
          events: [],
          timeRanges: [],
          isLoading: false,
          error,
        }),
      );
  };

  onReload = () => {
    this.getEvents();
  };

  onModeChange = (view: ViewType) => {
    this.onChange({ ...this.state.value, view });
  };

  onSelectedDateChange = (selectedDate: UTCDateTime) => {
    this.onChange({ ...this.state.value, selectedDate });
  };

  onHideWeekendsChange = (hideWeekends: boolean) => {
    this.onChange({ ...this.state.value, hideWeekends });
  };

  onEventClick = (event: MouseEvent) => {
    const eventData = event.eventRecord.data.eventData;

    if (isRelatedEvent(eventData)) {
      this.setState({
        selectedPersonalEvent: event.eventRecord.data,
        selectedWorkOrder: undefined,
      });
    }

    if (isAssignmentEvent(eventData)) {
      const { workOrderId, site } = eventData;
      this.setSelectedWorkOrder(site, workOrderId.entity, workOrderId.id);
    }
  };

  onPreviewLoaded = (workOrder: Record) => {
    const { selectedWorkOrder } = this.state;
    if (selectedWorkOrder) {
      this.setState({
        selectedWorkOrder: { ...selectedWorkOrder, record: workOrder },
      });
    }
  };

  setPersonalEventViewRef = (ref: PersonalEventView) => {
    this.personalEventViewRef = ref;
  };

  setWorkOrderViewRef = (ref: WorkOrderView) => {
    this.workOrderViewRef = ref;
  };

  setSelectedWorkOrder = (
    site: string,
    entityName: string,
    recordId: string,
  ) => {
    const { events } = this.state;
    const conflictedEvents = getAssignmentsWithConflicts(events, recordId);
    this.setState({
      selectedWorkOrder: {
        site,
        entityName,
        id: recordId,
        assignmentsWithConflicts: conflictedEvents,
      },
      selectedPersonalEvent: undefined,
    });
  };

  onChange = (newValue: SchedulerData) => {
    const { context } = this.props;
    const { value } = this.state;

    if (R.equals(value, newValue)) return;

    this.setState({ value: newValue });
    setSchedulerPreferences(context, newValue);
  };

  onNewPersonalEvent = (form: Form) => () => {
    trackEvent("New Personal Event");
    this.setState({
      newPersonalEventFormId: form?.id,
      selectedPersonalEvent: defaultFor(),
    });
  };

  onCloseSelection = () => {
    this.setState({
      selectedPersonalEvent: undefined,
      selectedWorkOrder: undefined,
    });
  };

  render() {
    const { context, userContact, schedulerSettings, hidePanels } = this.props;
    const {
      events,
      timeRanges,
      selectedPersonalEvent,
      newPersonalEventFormId,
      selectedWorkOrder,
      value,
      isLoading,
      error,
    } = this.state;

    const userEntityName = context.entities[schedulerSettings?.entityName].name;
    const personalEventEntity = getPersonalEventEntity(
      context.entities,
      userEntityName,
      undefined,
    );

    return (
      <>
        <SchedulerRibbon
          eventNumber={undefined}
          personalEventEntity={personalEventEntity}
          context={context}
          hidePanels={hidePanels}
          readOnly={!userContact}
          reload={this.onReload}
          onPreviewClose={this.onCloseSelection}
          onCreateEvent={this.onNewPersonalEvent}
        />

        <div className="x-technician-area">
          {isLoading ? <LoadingIcon /> : undefined}
          {error ? (
            <ApiError className="x-calendar-alert" error={error} />
          ) : undefined}
          {error ? <Backdrop className="x-calendar-backdrop" /> : undefined}

          <LazyBryntumCalendar
            date={value.selectedDate}
            view={value.view}
            events={events}
            timeRanges={timeRanges}
            hideWeekends={value?.hideWeekends}
            onModeChange={this.onModeChange}
            onEventClick={this.onEventClick}
            onSelectedDateChange={this.onSelectedDateChange}
            onHideWeekendsChange={this.onHideWeekendsChange}
            eventRenderer={calendarEventRenderer}
          />

          {selectedWorkOrder ? (
            <>
              <WorkOrderView
                isPlanner={false}
                ref={this.setWorkOrderViewRef}
                selectedWorkOrder={selectedWorkOrder}
                context={context.createContextForSiteIfExists(
                  selectedWorkOrder?.site,
                )}
                reload={this.onReload}
                onClose={this.onCloseSelection}
                onLoaded={this.onPreviewLoaded}
              />
              <Backdrop onClick={this.onCloseSelection} />
            </>
          ) : undefined}

          {selectedPersonalEvent ? (
            <>
              <PersonalEventView
                ref={this.setPersonalEventViewRef}
                context={context}
                personalEventEntity={personalEventEntity}
                event={selectedPersonalEvent}
                userContact={userContact}
                formId={newPersonalEventFormId}
                reload={this.onReload}
                onClose={this.onCloseSelection}
              />
              <Backdrop onClick={this.onCloseSelection} />
            </>
          ) : undefined}
        </div>
      </>
    );
  }
}
