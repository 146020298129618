import * as R from "ramda";
import { Entity } from "common/entities/types";
import { isEdited } from "common/record/form/content/related/functions";
import { Properties, Record } from "common/types/records";

const defaultRecord: Record = {
  properties: undefined,
  actions: [],
};

export const getRecordIndex = (form: Properties, records: Record[] = []) => {
  const findBy = form?.id ? "id" : "tempId";
  return form && isEdited(form)
    ? R.findIndex((r) => r.properties[findBy] === form[findBy], records)
    : -1;
};

export const getRecordPropertiesSetterFn =
  (form: Properties) =>
  (record: Record = defaultRecord): Record => ({
    ...record,
    properties: form,
  });

export const getPreviousRecord = (records: Record[], recordIndex: number) => {
  const previousIndex = recordIndex > 0 ? recordIndex - 1 : 0;
  return records?.[previousIndex]?.properties;
};

export const getNextRecord = (records: Record[], recordIndex: number) => {
  const lastIndex = records.length - 1;
  const nextIndex = recordIndex < lastIndex ? recordIndex + 1 : lastIndex;
  return records?.[nextIndex]?.properties;
};

export const isOwnerFkColumn = (entity: Entity, columnName: string) =>
  entity?.ownerFkColumn === columnName;
