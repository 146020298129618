import { Context } from "common/types/context";
import { ForeignKey as Fk } from "common/types/foreign-key";
import { getLocalizedName } from "common";
import { EntityColumn } from "common/entities/entity-column/types";
import { LinkFk } from "common/widgets/foreign-key/link-fk";
import { FormattedFk } from "common/widgets/foreign-key";

interface PropTypes {
  context: Context;
  column: EntityColumn;
  withLinks: boolean;
  value: Fk;
  label?: string;
}

export const FkInfo = ({
  column,
  withLinks,
  value,
  context,
  label,
}: PropTypes) => (
  <div className={`qa-${column.name} x-fk-info`}>
    <span className={`qa-${column.name}-label`}>
      {`${label || getLocalizedName(column)}:`}
    </span>
    {withLinks ? (
      <LinkFk context={context} value={value} column={column} />
    ) : (
      <span className={`qa-${column.name}-value`}>
        <FormattedFk
          context={context}
          column={column}
          format="title [subtitle]"
          value={value}
        />
      </span>
    )}
  </div>
);
FkInfo.displayName = "FkInfo";
