import mixpanel from "mixpanel-browser";
import { getSitedNowDate } from "common/date-time/internal";
import { Context } from "common/types/context";
import { getWindowLocation } from "common/utils/window-location";

const mixpanelData = {
  isInitialized: false,
};

interface UserProps {
  [index: string]: any;
}

interface EventProperties {
  [key: string]: any;
}

interface EventLabel {
  [key: string]: MixpanelEvents;
}

type Callback = () => void;

type MixpanelEvents =
  | "Create Record"
  | "Last Viewed"
  | "List Filter Operator"
  | "List Filter Search"
  | "List View Configuration"
  | "Login successfully"
  | "Path accessed"
  | "Select Saved Filter"
  | "Save New List View"
  | "Scheduler View Changed"
  | "New Personal Event"
  | "Assignment Published Changed"
  | "Scheduler Filter Changed"
  | "Scheduler Assignments Changed"
  | "Scheduler Work Order Action Performed"
  | "Scheduler Unassigned Work Toggled";

export const eventLabel: EventLabel = {
  createRecord: "Create Record",
  lastViewed: "Last Viewed",
  listFilterOperator: "List Filter Operator",
  listFilterSearch: "List Filter Search",
  listViewConfiguration: "List View Configuration",
  loginSuccessfully: "Login successfully",
  pathAccessed: "Path accessed",
  selectSavedFilter: "Select Saved Filter",
  saveNewListView: "Save New List View",
  schedulerViewChanged: "Scheduler View Changed",
  schedulerNewPersonalEvent: "New Personal Event",
  assignmentPublishedChanged: "Assignment Published Changed",
  schedulerFilterChanged: "Scheduler Filter Changed",
  schedulerAssignmentsChanged: "Scheduler Assignments Changed",
  schedulerWorkOrderActionPerformed: "Scheduler Work Order Action Performed",
  scheduledUnassignedWorkToggled: "Scheduler Unassigned Work Toggled",
};

export const configureMixpanel = (userName: string, userProps: UserProps) => {
  const windowLocation = getWindowLocation();
  const host = windowLocation.host.toLowerCase();

  const isUat = host.indexOf(".uat.") >= 0 && "uat";
  const isQa = host.indexOf(".qa.") >= 0 && "qa";
  const isBeta = host.indexOf(".x5beta.") >= 0;

  if (isQa) return;

  if (isBeta || isUat) {
    mixpanel.init("563f0aa11dd9e31e9513e7a34e426883");
    mixpanel.identify(userName);
    mixpanel.people.set(userProps);

    mixpanelData.isInitialized = true;
  }
};

export const configureMixpanelFromContext = (context: Context) => {
  configureMixpanel(context.userName, {
    $email: context.userName,
    $created: getSitedNowDate(),
    tenant: context.tenant.name,
    site: context.site.name,
    culture: context.uiFormat.culture,
  });
};

export const trackEvent = (
  eventName: MixpanelEvents,
  properties?: EventProperties,
  callback?: Callback,
) => {
  if (mixpanelData.isInitialized && mixpanel.track) {
    mixpanel.track(eventName, properties, callback);
    return;
  }
  callback?.();
};

export const trackLogin = (
  userName: string,
  tenant: string,
  callback: Callback,
) => {
  configureMixpanel(userName, {
    $email: userName,
    $last_login: getSitedNowDate(),
    tenant,
  });
  trackEvent(eventLabel.loginSuccessfully, { User: userName }, callback);
};
