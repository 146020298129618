import { JSX } from "react";
import { DateTimeFormat } from "common/date-time/types";
import { MeasurementImage } from "common/record/form/content/measurements/images-carousel/image";
import { MeasurementsResponse } from "common/record/form/content/measurements/types";
import { LazyReactSlick } from "common/vendor-wrappers/react-slick";
import { AlertWarning } from "common/widgets/alert";

interface PropTypes {
  measurementsResponse: MeasurementsResponse;
  dateFormat: DateTimeFormat;
  activeIndex: number;
  onActiveIndexChange: (index: number) => void;
  createImageActions?: (imagePath: string) => JSX.Element;
}

export const ImagesCarousel = ({
  measurementsResponse,
  dateFormat,
  activeIndex,
  onActiveIndexChange,
  createImageActions,
}: PropTypes) => {
  const token = measurementsResponse?.token;
  const measurements = measurementsResponse?.measurements?.ms ?? [];

  if (measurements.length === 0)
    return <AlertWarning message={_("No images available")} />;

  const items = measurements.map((m) => (
    <MeasurementImage
      key={m.t}
      token={token}
      measurement={m}
      dateFormat={dateFormat}
      createImageActions={createImageActions}
    />
  ));

  return (
    <LazyReactSlick
      startingIndex={measurements.length - 1}
      className="x-measurements-images"
      activeIndex={activeIndex}
      items={items}
      elementWidth={240}
      onAfterChange={onActiveIndexChange}
    />
  );
};
ImagesCarousel.displayName = "ImagesCarousel";
