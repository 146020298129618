import { Fragment, type MouseEvent } from "react";
import { arrayToString } from "common/utils/array";
import { Link } from "common/widgets/link";
import { getTooltipProps } from "common/vendor-wrappers/react-tooltip";
import { Action, ActionsGroup } from "common/record/actions/types";

const onDisabledClick = (event: MouseEvent) => {
  event.preventDefault();
};

const toLink = (onActionClick: (a: Action) => void) => (action: Action) => {
  const { name, link, label, target, isDisabled, disabledMessage } = action;

  const qaClassEntity = name === "Create" && target ? target.entity.name : "";
  const classes = arrayToString([
    "x-record-action-button",
    isDisabled ? "x-disabled" : undefined,
    `qa-action-${name}${qaClassEntity}`,
    "dropdown-item",
  ]);

  const onClick = (event: MouseEvent) => {
    event.preventDefault();
    onActionClick(action);
  };

  return isDisabled ? (
    <Link
      className={classes}
      href="#"
      onClick={onDisabledClick}
      {...getTooltipProps(disabledMessage, "error")}
    >
      {label}
    </Link>
  ) : (
    <Link
      className={classes}
      data-action={action}
      href={link ? link : "#"}
      onClick={link ? undefined : onClick}
    >
      {label}
    </Link>
  );
};

export const mapActionsItem =
  (onActionSelect: (a: Action) => void) => (action: Action) => {
    return (
      <li key={action.label} className="x-record-action-item">
        {toLink(onActionSelect)(action)}
      </li>
    );
  };

export const actionGroupsWithSeparators = (
  actionGroups: ActionsGroup[],
  onActionSelect: (a: Action) => void,
) =>
  (actionGroups || []).map((group, i, groups) => {
    const withSeparator = i < groups.length - 1;

    return (
      <Fragment key={group[0]}>
        {group[1].map(mapActionsItem(onActionSelect))}
        {withSeparator && (
          <li>
            <hr className="dropdown-divider" />
          </li>
        )}
      </Fragment>
    );
  });
