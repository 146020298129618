import * as R from "ramda";
import { getLocalizedName } from "common";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entities, Entity } from "common/entities/types";
import {
  getFieldAndFormat,
  getSubFKTitle,
  isForeignKey,
  isSubForeignKey,
} from "common/functions/foreign-key";
import { merge1 } from "common/merge";
import { addFilterToQuery, addIsDeletedFilter } from "common/query/filter";
import { getSelectWithId } from "common/query/select";
import { QueryForEntity } from "common/query/types";
import { Context } from "common/types/context";
import { FkValue, ForeignKey as Fk } from "common/types/foreign-key";
import { USER_DATA_VIEW } from "common/types/view";

const getDefaultLabelIfExists = (entityName: string) =>
  entityName === USER_DATA_VIEW ? _("eMaint Support Member") : undefined;

const getFkDescription = (fk: Fk, entities: Entities) => {
  const { title, subtitle, subsubtitle, label, entity } = fk;
  return (
    getSubFKTitle(title) ||
    subtitle ||
    subsubtitle ||
    label ||
    getDefaultLabelIfExists(entity) ||
    getLocalizedName(entities[entity])
  );
};

const getAliasNameSubtitle = (value: Fk) =>
  R.has("subtitle", value)
    ? "subtitle"
    : R.has("subsubtitle", value)
      ? "subsubtitle"
      : "";

const getAliasName = (value: Fk) =>
  R.has("title", value) ? "title" : getAliasNameSubtitle(value);

const getSubtitlePart = (fk: Fk) => {
  const { customtitle, subtitle } = fk;
  return customtitle
    ? ` [${getSubFKTitle(customtitle)}]`
    : (subtitle && ` [${getSubFKTitle(subtitle)}]`) || "";
};

export const formatFk =
  (entities: Entities) =>
  (
    foreignKey: Fk,
    appendSubtitle: boolean = true, // title + subtitle by default
    entity?: Entity,
    context?: Context,
  ) => {
    if (!isForeignKey(foreignKey)) {
      return undefined;
    }

    const subtitle = appendSubtitle ? getSubtitlePart(foreignKey) : "";

    const fk = appendSubtitle
      ? merge1("subtitle", undefined, foreignKey)
      : foreignKey;

    const subFKValue = getFkDescription(fk, entities);
    const description = isSubForeignKey(subFKValue)
      ? getSubFKTitle(subFKValue)
      : subFKValue;

    const formattedDescription =
      entity && context && foreignKey
        ? getFieldAndFormat(
            entity,
            context,
            getAliasName(foreignKey),
            description,
          )
        : description;

    const formattedSubtitle =
      subtitle && entity && context && foreignKey
        ? getFieldAndFormat(
            entity,
            context,
            getAliasNameSubtitle(foreignKey),
            subtitle,
          )
        : subtitle;

    return formattedDescription + formattedSubtitle;
  };

/**
 * Get the entity's default query to retrieve records on a "display format" a.k.a. Foreign Key
 * This format is configured on the entity's configuration. Deleted records NOT included here.
 * @param entity
 */
export const getExpandFkQueryWithoutDeleted = (entity: Entity) =>
  addIsDeletedFilter(
    {
      entity: entity?.name,
      query: entity?.query,
    },
    false,
  );

/**
 * Get a query to find a record by id and return in its "display" format. NOT filtering deleted.
 * @param entity
 * @param id Id of the record you want to expand
 */
export const getExpandFkQueryForId = (
  entity: Entity,
  id: string,
): QueryForEntity => ({
  entity: entity.name,
  query: addFilterToQuery(
    { name: "id", op: "eq", value: id },
    { ...entity.query, select: getSelectWithId(entity.query.select) },
  ),
});

export const getFkRelatedEntityName = (column: EntityColumn, value: FkValue) =>
  column?.relatedEntity ?? (isForeignKey(value) && value.entity);
