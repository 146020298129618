import { behaveAs } from "common/entities";
import { PropTypes } from "x/records/edit-controller/types";
import { RecordEditController } from "./edit-controller";
import { RequisitioningEditController } from "./behavior-edit-controller/requisitioning";
import { ScheduledEventEditController } from "./behavior-edit-controller/scheduled-event";

export const RecordEditControllerBehavior = (props: PropTypes) => {
  const { entity } = props;

  if (behaveAs("ScheduledEvent", entity)) {
    return <ScheduledEventEditController {...props} />;
  }

  if (behaveAs("Requisitioning", entity)) {
    return <RequisitioningEditController {...props} />;
  }

  return <RecordEditController {...props} />;
};

RecordEditControllerBehavior.displayName = "RecordEditControllerBehavior";
