import { Component } from "react";
import { Record } from "common/types/records";
import { Entity } from "common/entities/types";
import { QueryForEntity } from "common/query/types";
import { functionHandlers } from "common/record/actions/function-handlers";
import { getHandlerRecords } from "common/record/actions/functions/record";
// eslint-disable-next-line import/no-cycle
import {
  ActionHandlerValue,
  getActionHandlersByBehaviors,
} from "common/record/actions/handlers";
import {
  Action,
  PropTypes as ActionPropTypes,
} from "common/record/actions/types";
import { Context } from "common/types/context";
import { GoFn } from "common/types/url";
import { ComponentWithValue } from "common/with-value";

interface PropTypes {
  context: Context;
  entity: Entity;
  records: Record[];
  query?: QueryForEntity;
  action: Action;
  goTo?: GoFn;
  reload?: (value: any) => any;
  onActionDismiss?: (action: Action) => void;
  onActionPerform?: (action: Action) => void;
}

interface StateType {
  prevAction: Action;
  actionValue: ActionHandlerValue;
  ActionUi: ComponentWithValue<ActionHandlerValue, ActionPropTypes>;
}

export class ActionsHandler extends Component<PropTypes> {
  static getDerivedStateFromProps(
    newProps: PropTypes,
    prevState: StateType,
  ): StateType {
    if (newProps.action && prevState.prevAction === newProps.action)
      return null;

    return {
      ActionUi: getActionHandlersByBehaviors(newProps.entity.behaviors)[
        newProps.action?.name
      ]?.Ui,
      prevAction: newProps.action,
      actionValue: undefined,
    };
  }

  state: StateType = {
    prevAction: undefined,
    actionValue: undefined,
    ActionUi: undefined,
  };

  componentDidUpdate(prevProps: Readonly<PropTypes>) {
    const { action } = this.props;

    if (action !== prevProps.action && functionHandlers()[action?.name]) {
      this.runAction();
    }
  }

  runAction = () => {
    const { context, entity, records, query, action, onActionPerform, goTo } =
      this.props;

    const handlerFn = functionHandlers()[action.name];
    handlerFn.fn(context, entity, records, goTo, query);

    onActionPerform?.(action);
  };

  onDismiss = (actionExecuted: boolean, value?: any) => {
    const { reload, onActionDismiss, action } = this.props;
    if (actionExecuted && reload) reload(value);

    this.setState({ actionValue: undefined, ActionUi: undefined });
    onActionDismiss?.(action);
  };

  onPerform = () => {
    const { action, onActionPerform } = this.props;
    onActionPerform?.(action);
  };

  onChangeActionValue = (actionValue: ActionHandlerValue) => {
    this.setState({ actionValue });
  };

  render() {
    const { action, goTo, query, context, entity, records } = this.props;
    const { ActionUi, actionValue } = this.state;

    return action && ActionUi ? (
      <ActionUi
        key={action?.name}
        goTo={goTo}
        query={query}
        context={context}
        dismiss={this.onDismiss}
        onPerform={this.onPerform}
        entity={action?.target?.entity ?? entity}
        sourceEntity={entity}
        records={getHandlerRecords(entity, records, action)}
        extraProperties={action.extraProperties}
        value={actionValue}
        onChange={this.onChangeActionValue}
      />
    ) : null;
  }
}
