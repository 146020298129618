import { Component } from "react";
import { FileType } from "common/types/media";
import { getLabelWithHighlight, Highlight } from "common/widgets/highlight";
import { ImageLarge } from "common/widgets/image";
import { MediaPlayer } from "common/vendor-wrappers/react-player";
import { DefaultImage } from "./default-image";
import {
  isViewableAudioFileType,
  isViewableFileType,
  isViewableVideoFileType,
  getFileType,
  getSizeString,
  fileIcons,
  isImageFileType,
} from "./functions";

export type SetAsDefault = (f: FileType) => any;

interface PropTypes {
  file: FileType;
  setAsDefault?: SetAsDefault;
  isDefault?: boolean;
  isInternalSource?: boolean;
  showFileInformation?: boolean;
  textToHighlight?: string;
  isClickable?: boolean;
  disableEdit?: boolean;
}

export class MediaFile extends Component<PropTypes> {
  setAsDefault = () => {
    const { file, setAsDefault } = this.props;
    setAsDefault(file);
  };

  getPreview = () => {
    const { file } = this.props;
    const { url } = file;
    const fileType = getFileType(file);
    const fileClass = `fa ${fileIcons[fileType]} fa-lg`;

    return !isViewableFileType(fileType) || !url ? (
      <i className={fileClass} />
    ) : isImageFileType(fileType) ? (
      <div className="x-media-image">
        <ImageLarge value={url} allowZoom={true} />
      </div>
    ) : isViewableAudioFileType(fileType) ? (
      <div className="x-media-audio">
        <MediaPlayer url={url} withPreview={false} />
      </div>
    ) : isViewableVideoFileType(fileType) ? (
      <div className="x-media-video">
        <MediaPlayer
          url={url}
          withPreview={true}
          showError={true}
          showLoading={false}
        />
      </div>
    ) : undefined;
  };

  getFileInformation = () => {
    const {
      file,
      textToHighlight,
      isClickable = true,
      showFileInformation = false,
    } = this.props;
    const { url, name, size } = file;
    const fileType = getFileType(file);

    const linkText = !textToHighlight ? (
      name
    ) : (
      <Highlight text={getLabelWithHighlight(String(name), textToHighlight)} />
    );

    return (
      <>
        {showFileInformation || !isViewableFileType(fileType) ? (
          <div className="x-file-name x-margin-left-10 qa-file-name">
            {isClickable ? (
              <a
                href={url}
                className="x-word-break"
                target="_blank"
                rel="noopener noreferrer"
              >
                {linkText}
              </a>
            ) : (
              linkText
            )}
          </div>
        ) : undefined}
        {showFileInformation && size ? (
          <div className="x-margin-left-10 qa-file-size">
            {getSizeString(size)}
          </div>
        ) : undefined}
      </>
    );
  };

  render() {
    const { file, isDefault, isInternalSource, setAsDefault, disableEdit } =
      this.props;
    const fileType = getFileType(file);

    return (
      <div className={`x-media-file x-media-file-${fileType}`}>
        <div className="x-media-file-content">
          {this.getPreview()}
          {this.getFileInformation()}
        </div>
        {fileType === "image" && isInternalSource ? (
          <DefaultImage
            isDefault={isDefault}
            disableEdit={disableEdit}
            setAsDefault={setAsDefault ? this.setAsDefault : undefined}
          />
        ) : undefined}
      </div>
    );
  }
}
