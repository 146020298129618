import { BehaviorName } from "common/types/behaviors";
import { Properties } from "common/types/records";
import { behaveAs } from "common/entities";
import { ActionsByRecordId } from "common/query/types";
import { RGBColor } from "common/types/colors";
import { Context } from "common/types/context";
import { Filter } from "common/types/filters";
import { hasPermissionTo } from "common/functions/roles";
import { Action } from "common/types/roles";
import { getRuleLabel } from "x/account-settings/color-coding/functions";

export interface ColorLegendItem {
  label: string;
  color: RGBColor;
}

export interface ColorLegendGroup {
  label?: string;
  items: ColorLegendItem[];
}

export const getFiltersPermissions = (
  context: Context,
  actions: Action[] = [],
) => {
  const { userTypes, role } = context;
  return actions.reduce((acc: any, action: Action) => {
    const actionKey = `can${action}`;
    return {
      ...acc,
      [actionKey]: hasPermissionTo(userTypes, role, "Filters", action),
    };
  }, {});
};

export const getFilterById = (filters: Filter[] = [], id: number) =>
  filters.find((f) => f.id === id);

export const getColorLegendGroupsByBehavior = (
  behavior: BehaviorName,
  context: Context,
): ColorLegendGroup[] => {
  const { entities, uiFormat } = context;

  return Object.values(entities).reduce((groups, entity) => {
    const { colorRules, labels } = entity;

    if (!colorRules?.length || !behaveAs(behavior, entity)) return groups;

    return [
      ...groups,
      {
        label: labels[uiFormat.culture].name,
        items: colorRules
          .map((rule) => ({
            label: getRuleLabel(context, rule),
            color: rule.color,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      },
    ];
  }, []);
};

export const getRecordsWithActions = (
  recordsProperties: Properties[],
  actionsByRecordId: ActionsByRecordId,
  allowedActions?: string[],
) =>
  recordsProperties?.map((properties: Properties) => {
    const recordActions = actionsByRecordId?.[properties.id] || [];
    return {
      actions: allowedActions
        ? recordActions.filter((a) => allowedActions.includes(a))
        : recordActions,
      properties,
    };
  });
