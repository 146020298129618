import { isReferenceEntity } from "common/api/entities";
import { EntityColumn } from "common/entities/entity-column/types";
import { Context } from "common/types/context";
import { FkValue } from "common/types/foreign-key";
import { SelfExpandingReferenceFk } from "common/widgets/foreign-key/internal/self-expanding-reference-fk";
import { HighlightProps } from "common/widgets/label";
import { getFkRelatedEntityName } from "./functions";
import { SelfExpandingFk } from "./internal/self-expanding-fk";
import { FkFormat } from "./internal/types";

export interface PropTypes extends HighlightProps {
  context: Context;
  value: FkValue; // can be expanded or not
  column?: EntityColumn;
  /**
   * Defaults to "title". For reference fks there is no subtitle
   */
  format?: FkFormat;
}

/**
 * Generic Foreign Key widget
 * It handles fks to reference entities or "regular" fks. It also
 * figures if the fk is not expanded, and does that for you.
 */
export const FormattedFk = ({
  context,
  column,
  value,
  format = "title",
  textToHighlight,
}: PropTypes) => {
  const relatedEntity = context.entities[getFkRelatedEntityName(column, value)];

  return isReferenceEntity(relatedEntity) ? (
    <SelfExpandingReferenceFk
      context={context}
      column={column}
      value={value}
      textToHighlight={textToHighlight}
    />
  ) : (
    <SelfExpandingFk
      context={context}
      column={column}
      format={format}
      value={value}
      textToHighlight={textToHighlight}
    />
  );
};
