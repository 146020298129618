import {
  MAX_INTERVAL_DAYS,
  MIN_INTERVAL_DAYS,
} from "common/scheduled-event/defaults";
import { validateProjectionLength } from "common/scheduled-event/validations";

export const getProjectionLengthError = (projectionLength: number) => {
  if (validateProjectionLength(projectionLength)) return undefined;

  return _("Projection length should be in range {MIN} - {MAX} days.")
    .replace("{MIN}", MIN_INTERVAL_DAYS.toString())
    .replace("{MAX}", MAX_INTERVAL_DAYS.toString());
};
