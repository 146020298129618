import { PropsWithChildren } from "react";
import { getLocalizedName } from "common";
import { getColumn } from "common/entities";
import { Group } from "common/form/types";
import { getAdditionalProps } from "common/record/form/content/detail/functions";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { getLookupType } from "x/account-settings/forms/form/lookups/functions";
import { ReadOnlyLookupField } from "./lookup";
import { Row } from "./row";

export interface SectionPropTypes {
  className?: string;
  title?: string;
}

interface PropTypes {
  entityName: string;
  context: Context;
  withLinks: boolean;
  record: Properties;
  group: Group;
}

// Pretty much the same as ui/Section. Consider removing this.
export const Section = ({
  className,
  title,
  children,
}: PropsWithChildren<SectionPropTypes>) => (
  <section className={`x-section-group ${className || ""}`}>
    {title ? <div className="x-record-title">{title}</div> : undefined}
    {children}
  </section>
);

export const ReadOnlySectionGroup = ({
  group,
  withLinks,
  entityName,
  record = {},
  context,
}: PropTypes) => {
  const entity = context.entities[entityName];
  const rows = group.columns.map((groupColumn) => {
    const entityColumn = getColumn(entity, groupColumn.columnName);

    const additionalProps = getAdditionalProps(
      entity,
      entityColumn,
      record,
      context,
    );

    return getLookupType(context, groupColumn.lookupConfiguration) ? (
      <ReadOnlyLookupField
        key={groupColumn.columnName}
        context={context}
        entity={entity}
        withLinks={withLinks}
        groupColumn={groupColumn}
        record={record}
      />
    ) : (
      <Row
        key={groupColumn.columnName}
        context={context}
        entityName={entityName}
        withLinks={withLinks}
        column={entityColumn}
        label={getLocalizedName(entityColumn)}
        value={record[groupColumn.columnName]}
        additionalProps={additionalProps}
      />
    );
  });

  return (
    <Section title={group.label}>
      <table className="table">
        <tbody>{rows}</tbody>
      </table>
    </Section>
  );
};
