import { useEffect, useState } from "react";
import { cycleCountApi } from "common/api/cycle-count";
import { mapToSubmitAllCount } from "common/record/actions/ui/submit-parts-count/functions";
import { LoadingIcon } from "common/widgets/loading-icon";
import { SubmitAllActionTypes } from "common/record/actions/ui/submit-parts-count/types";
import { Context } from "common/types/context";
import { Entity } from "common/entities/types";
import { DismissAction } from "common/record/actions/types";

interface PropTypes {
  context: Context;
  entity: Entity;
  cycleCountId: string;
  partsToBeCounted: SubmitAllActionTypes[];
  dismiss: DismissAction;
}

export const SubmitWithoutInput = (props: PropTypes) => {
  const { context, partsToBeCounted, cycleCountId, entity, dismiss } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    cycleCountApi(context.apiCall)
      .submitAll(
        entity.name,
        cycleCountId,
        partsToBeCounted.length,
        mapToSubmitAllCount(partsToBeCounted),
      )
      .then(() => {
        setLoading(false);
        dismiss(true);
      })
      .catch(() => {
        setLoading(false);
        // Error is handled by the API call function
      });
  }, []);

  return loading ? <LoadingIcon /> : null;
};
