const DataSource = {
  Manual: 0,
  DataImport: 1,
  Workflow: 2,
} as const;

export const dataSourceValues = Object.values(DataSource);

export const translateDataSource = (value: number | string) => {
  switch (+value) {
    case DataSource.Workflow:
      return _("Workflow");
    case DataSource.DataImport:
      return _("Data Import");
    case DataSource.Manual:
    default:
      return _("Manual");
  }
};
