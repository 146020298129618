import {
  AssignmentEvent,
  SchedulerEvent,
  RelatedEvent,
} from "common/types/scheduler";

export const isAssignmentEvent = (
  event: SchedulerEvent,
): event is AssignmentEvent => event && event.type === "Assignment";

export const isUnpublishedEvent = (
  event: SchedulerEvent,
): event is AssignmentEvent =>
  event && isAssignmentEvent(event) && event.status === "N";

export const isRelatedEvent = (event: SchedulerEvent): event is RelatedEvent =>
  event && event.type === "Event";

export const isNotWorkingEvent = (
  event: SchedulerEvent,
): event is RelatedEvent =>
  (event && event.type === "NonWorking") ||
  event.type === "Offseason" ||
  event.type === "Holiday";
