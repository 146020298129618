import * as R from "ramda";
import { apiWithoutTranslations as reportsApi } from "common/api/reports";
import { canAccessReportEntities } from "common/query/common";
import { Context } from "common/types/context";
import { Report } from "common/types/reports";
import {
  createEditCtrlWithPermission,
  ExternalPropTypes,
} from "common/ui/controllers/edit";
import { createInjected } from "common/ui/controllers/edit/base";
import { PermissionsError } from "common/widgets/error";
import { ValueProps } from "common/with-value-for";
import { ReportFormType } from "x/reports/edit/types";
import { tableOutput } from "./edit/query-builder";
import { Preview, PropTypes as PreviewPropTypes } from "./preview";

interface PropTypes extends ExternalPropTypes<number, Report> {
  context: Context;
  withLinks: boolean;
}

const EditController = createEditCtrlWithPermission<
  ReportFormType,
  number,
  Report
>();
const injected = createInjected<ReportFormType, number, Report>();

const unwrap = (form: ReportFormType): Report => form.item;
const wrap = (form: ReportFormType, report: Report) =>
  R.mergeRight(form, { item: report });

const PreviewWrapper = (
  props: PreviewPropTypes & ValueProps<ReportFormType>,
) => {
  if (!props.value) return <span>{_("Loading")}</span>;

  const { item } = props.value;
  const { entity, query } = item;

  if (!canAccessReportEntities(props.context.entities, item)) {
    return <PermissionsError />;
  }

  return (
    <Preview
      {...injected}
      {...props}
      query={{ entity, query }}
      className="x-padding-20"
    />
  );
};

export const ReportPreviewController = (props: PropTypes) => {
  return (
    <EditController
      {...props}
      api={reportsApi(props.context.apiCall, props.context.entities)}
      permissionCategory="Reports"
      wrapRecord={wrap}
      unwrapRecord={unwrap}
    >
      <PreviewWrapper
        {...injected}
        {...props}
        query={undefined}
        output={tableOutput}
      />
    </EditController>
  );
};

ReportPreviewController.displayName = "ReportPreviewController";
