import * as R from "ramda";
import { searchApi } from "common/api/search";
import { behaveAs } from "common/entities";
import { setReadOnly } from "common/entities/entity-column/functions";
import { Entities, Entity } from "common/entities/types";
import { mapLayout } from "common/form/functions/layout";
import { Layout, RelatedEntity } from "common/form/types";
import { findEntityByArgs } from "common/functions/entity";
import { disableColumns } from "common/functions/group-column";
import { isNew } from "common/functions/standard-value";
import { merge1, merge4 } from "common/merge";
import { replaceLookupQueries } from "common/record/form/content/related/common/functions";
import { RelatedValue } from "common/record/form/content/related/types";
import { StandardValue } from "common/record/types";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { CancellablePromise } from "common/types/promises";
import { Record } from "common/types/records";
import { getRelatedRecordsByRelation } from "x/records/edit/behavior-form/functions";
import { getUserContactQuery } from "x/records/edit/behavior-form/requisitioning/functions/contact-query";
import {
  APPROVAL_COST_CENTER,
  APPROVAL_GROUP,
  APPROVER,
  AWAITING_APPROVAL,
  COMMENTS,
  OPEN,
  PO_CREATED,
  REJECTED,
  REQUESTED_BY,
  REQUESTED_DELIVERY_DATE,
  REQUESTED_ON,
  REQUISITIONER,
  REQUISITIONING_STATUS,
  REVIEWER,
  UNDER_REVIEW,
} from "../consts";
import { PermissionType, RequisitionStatus } from "../types";
import { createApprovalGroupLookUpQuery } from "./approval-group-query";
import { getApproverQuery } from "./approver-query";
import { createCostCenterLookUpQuery } from "./cost-center-query";
import { getReviewerQuery } from "./reviewer-query";

export const getStatus = (value: { record: Record }): RequisitionStatus =>
  value?.record?.properties?.[REQUISITIONING_STATUS]?.id || OPEN;

export const getApprovalGroupId = (
  value: StandardValue | RelatedValue,
): string =>
  (value as StandardValue)?.ui?.detail?.form?.[APPROVAL_GROUP]?.id ||
  value?.record?.properties?.[APPROVAL_GROUP]?.id;

export const getRequestedBy = (
  value: StandardValue | RelatedValue,
): ForeignKey =>
  (value as StandardValue)?.ui?.detail?.form?.[REQUESTED_BY] ||
  value?.record?.properties?.[REQUESTED_BY];

export const setRequestedBy = (value: StandardValue, fk: ForeignKey) =>
  merge4("ui", "detail", "form", REQUESTED_BY, fk, value);

export const setApprovalCostCenter = (value: StandardValue, fk: ForeignKey) =>
  merge4("ui", "detail", "form", APPROVAL_COST_CENTER, fk, value);

export const includesPermission = (
  permissions: PermissionType[],
  permission: PermissionType,
) => R.includes(permission, permissions || []);

const isRequisitioner = (
  contactId: string,
  value: StandardValue | RelatedValue,
) => contactId && getRequestedBy(value)?.id === contactId;

const checkIsApprover = (
  context: Context,
  entity: Entity,
  approvalGroupId: string,
  contactId: string,
): CancellablePromise<boolean> => {
  const { apiCall, entities } = context;
  const approverQuery = getApproverQuery(
    entities,
    entity,
    approvalGroupId,
    contactId,
  );

  return searchApi(apiCall)
    .runQuery(approverQuery)
    .then((approvers: ForeignKey[] = []) => !!approvers.length);
};

const getProcurementEntity = (context: Context, entity: Entity) =>
  findEntityByArgs(
    context.entities,
    "Procurement",
    "approvalGroupEntity",
    entity?.arguments?.approvalGroupEntity,
  );

export const hasProcurement = (context: Context, entity: Entity) =>
  !!getProcurementEntity(context, entity);

const checkIsReviewer = (
  context: Context,
  entity: Entity,
  approvalGroupId: string,
  contactId: string,
): CancellablePromise<boolean> => {
  if (!hasProcurement(context, entity))
    return CancellablePromise.resolve(false);

  const { apiCall } = context;
  const procurementEntity = getProcurementEntity(context, entity);
  const reviewerQuery = getReviewerQuery(
    procurementEntity,
    approvalGroupId,
    contactId,
  );

  return searchApi(apiCall)
    .runQuery(reviewerQuery)
    .then((reviewers: ForeignKey[] = []) => !!reviewers.length);
};

export const loadUserContact = (
  context: Context,
  entity: Entity,
): CancellablePromise<ForeignKey> => {
  const { apiCall } = context;
  const userContactQuery = getUserContactQuery(context, entity);

  return userContactQuery
    ? searchApi(apiCall)
        .runQuery(userContactQuery)
        .then((userContacts: ForeignKey[] = []) => userContacts[0])
    : CancellablePromise.resolve(undefined);
};

export const getPermissionTypes = (
  context: Context,
  entity: Entity,
  value: StandardValue | RelatedValue,
  contactId: string,
): CancellablePromise<PermissionType[]> => {
  const basePermissions: PermissionType[] = [
    ...(isRequisitioner(contactId, value) ? [REQUISITIONER] : []),
  ];
  const approvalGroupId = getApprovalGroupId(value);

  return approvalGroupId && contactId
    ? CancellablePromise.all([
        checkIsReviewer(context, entity, approvalGroupId, contactId),
        checkIsApprover(context, entity, approvalGroupId, contactId),
      ])
        .then(([isReviewer, isApprover]) => {
          const permissions = [
            ...basePermissions,
            ...(isReviewer ? [REVIEWER] : []),
            ...(isApprover ? [APPROVER] : []),
          ];
          return CancellablePromise.resolve(permissions);
        })
        .catch(() => CancellablePromise.resolve(basePermissions))
    : CancellablePromise.resolve(basePermissions);
};

export const DEFAULT = [REQUESTED_BY];

export const ALL_WITHOUT_COST_CENTER = DEFAULT.concat([
  APPROVAL_GROUP,
  REQUESTED_DELIVERY_DATE,
  REQUESTED_ON,
  REQUISITIONING_STATUS,
  COMMENTS,
]);

export const ALL = ALL_WITHOUT_COST_CENTER.concat([APPROVAL_COST_CENTER]);

export const getReadOnlyFields = (
  status: number = OPEN,
  permissionTypes: PermissionType[],
) => {
  if (status === OPEN) {
    return DEFAULT;
  }

  if (
    status === UNDER_REVIEW &&
    includesPermission(permissionTypes, REVIEWER)
  ) {
    return DEFAULT;
  }

  if (
    status === AWAITING_APPROVAL &&
    includesPermission(permissionTypes, APPROVER)
  ) {
    return DEFAULT;
  }

  return ALL;
};

const getHiddenFields = (status: number = OPEN) =>
  status === OPEN ? [REQUISITIONING_STATUS, REQUESTED_ON] : [];

export const updateEntity = (
  entity: Entity,
  status: number,
  permissionTypes: PermissionType[],
) => {
  const readOnly = getReadOnlyFields(status, permissionTypes);
  const hidden = getHiddenFields(status);

  const newColumns = entity.columns
    .filter((c) => !R.includes(c.name, hidden))
    .map(setReadOnly(readOnly));

  return merge1("columns", newColumns, entity);
};

export const updateRelatedEntitiesOverviewPosition = (
  relatedEntities: RelatedEntity[],
  entities: Entities,
): RelatedEntity[] => {
  return relatedEntities?.map((re) => {
    const entityName = re.name;
    const entity = entities && entities[entityName];

    return {
      ...re,
      overviewPosition: behaveAs("RequisitioningItem", entity)
        ? "top"
        : "bottom",
    };
  });
};

export const updateLayoutRelatedEntities = (
  layout: Layout,
  entities: Entities,
) => {
  const relatedEntities = updateRelatedEntitiesOverviewPosition(
    layout.relatedEntities,
    entities,
  );
  return { ...layout, relatedEntities };
};

export const updateLayout = (
  value: StandardValue,
  context: Context,
  entity: Entity,
  layout: Layout,
) => {
  const { userName } = context;
  const approvalGroupId = getApprovalGroupId(value);
  const layoutWithDisabled = approvalGroupId
    ? layout
    : mapLayout(disableColumns([APPROVAL_COST_CENTER]), layout);

  const requisitionerEmail = getRequestedBy(value)?.email;
  const approvalGroupLookupQuery = createApprovalGroupLookUpQuery(
    context,
    entity,
    requisitionerEmail ? requisitionerEmail : userName,
  );
  const layoutWithApprovalGroupLookupQuery = replaceLookupQueries(
    APPROVAL_GROUP,
    approvalGroupLookupQuery,
  )(layoutWithDisabled);

  const costCenterLookupQuery = createCostCenterLookUpQuery(
    context,
    entity,
    approvalGroupId,
  );
  const layoutWithCostCenterLookupQuery = approvalGroupId
    ? replaceLookupQueries(
        APPROVAL_COST_CENTER,
        costCenterLookupQuery,
      )(layoutWithApprovalGroupLookupQuery)
    : layoutWithApprovalGroupLookupQuery;

  const layoutWithUpdatedRelatedEntities = updateLayoutRelatedEntities(
    layoutWithCostCenterLookupQuery,
    context.entities,
  );

  return layoutWithUpdatedRelatedEntities;
};

export const updateValue = (value: StandardValue, userContact: ForeignKey) => {
  return userContact && isNew(value) && !getRequestedBy(value)
    ? setRequestedBy(value, userContact)
    : value;
};

export const updateDisableEdit = (
  disableEdit: boolean,
  status: RequisitionStatus,
) => {
  return status === PO_CREATED || status === REJECTED || !!disableEdit;
};

export const isRequisitioningItemEntity = (
  mainEntity: Entity,
  relatedEntity: Entity,
) =>
  behaveAs("Requisitioning", mainEntity) &&
  behaveAs("RequisitioningItem", relatedEntity) &&
  relatedEntity.arguments.requisitioningEntity === mainEntity.name;

export const hasRequisitionItems = (
  context: Context,
  entity: Entity,
  value: StandardValue,
) => {
  const requisitioningItems = getRelatedRecordsByRelation(
    entity,
    context.entities,
    value,
    isRequisitioningItemEntity,
  );

  return !!requisitioningItems.length;
};

export const hasPartSupplierIds = (
  context: Context,
  entity: Entity,
  value: StandardValue,
) => {
  const record = value.record;
  const statusId = record?.properties?.statusId?.id;

  const requisitioningItems = getRelatedRecordsByRelation(
    entity,
    context.entities,
    value,
    isRequisitioningItemEntity,
  );

  return (
    !!requisitioningItems.length &&
    (!R.includes(statusId, [UNDER_REVIEW, AWAITING_APPROVAL, REJECTED]) ||
      R.all(
        (i) => !!i.properties.partSupplierId && !R.isNil(i.properties.quantity),
        requisitioningItems,
      ))
  );
};
