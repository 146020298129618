import { ValueComponent } from "common/with-value-for";
import { Query } from "common/query/types";
import { Uint } from "common/widgets/number";

export class Paging extends ValueComponent<Query> {
  static readonly displayName = "Paging";

  onChange = (page: number) => {
    this.mergeValue({
      pageSize: page || undefined,
      page: page ? 0 : undefined,
    });
  };

  render() {
    const { value } = this.props;
    return (
      <div className="x-query-builder-paging">
        <Uint
          placeholder={_("page size")}
          value={value && value.pageSize ? value.pageSize : undefined}
          onChange={this.onChange}
        />
      </div>
    );
  }
}
