import { PropsWithChildren } from "react";

interface PropTypes {
  label: string;
}

export const Row = ({ label, children }: PropsWithChildren<PropTypes>) => (
  <div className="row x-detail-row">
    <div className="col-md-4 x-detail-label">{label}</div>
    <div className="col-md-8 x-detail-value">{children}</div>
  </div>
);

Row.displayName = "Row";
