import { Context } from "common/types/context";
import { PurchaseOrderClosedEvent } from "common/types/events";
import { DateTimeLabel } from "common/widgets/date/date-time-label";
import { Row } from "../row";
import { ReasonDetails } from "./reason";

interface PropTypes {
  context: Context;
  event: PurchaseOrderClosedEvent;
  hideLinks?: boolean;
}

export const PurchaseOrderClosed = ({
  event,
  context,
  hideLinks,
}: PropTypes) => (
  <div>
    <legend>{_("Details")}</legend>
    <Row label={_("Closed By")}>{event.userName}</Row>
    <Row label={_("Closed On")}>
      <DateTimeLabel
        uiFormat={context.uiFormat}
        value={event.details.closedOn}
      />
    </Row>
    <ReasonDetails
      reason={event.details.reason}
      context={context}
      hideLinks={hideLinks}
    />
  </div>
);

PurchaseOrderClosed.displayName = "PurchaseOrderClosed";
