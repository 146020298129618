import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import md5 from "md5";
import { addFilter } from "common/query/filter";
import { hasCacheExpired } from "common/app/redux/functions";
import { apiSearchFull, searchApi } from "common/api/search";
import {
  getRecordWithTitleFn,
  getReferenceDisplayFieldFilters,
  getReferenceQuery,
} from "common/functions/references";
import { Query } from "common/query/types";
import { Context } from "common/types/context";
import { ForeignKey, Reference } from "common/types/foreign-key";

interface References {
  records: Reference[];
  totalPages: number;
  isLoading: boolean;
  error?: any;
  lastFetch?: number;
}

type ReferencesState = Record<string, References>;

interface ReferencesFetchArgs {
  context: Context;
  entityName: string;
  query: Query;
  sites?: string[];
  force?: boolean;
  limit?: number;
}

interface ReferencesFetchResult extends Partial<References> {
  entityName: string;
  query: Query;
  sites: string[];
}

const initialState: ReferencesState = {};
const initialReferences: References = {
  records: [],
  totalPages: 0,
  lastFetch: undefined,
  isLoading: false,
  error: undefined,
};

export const getReferenceKey = (
  entityName: string,
  sites?: string[],
  query?: Query,
) => {
  const sitesPart = sites?.length ? sites.join(",") : "";
  const queryPart = query ? JSON.stringify(query) : "";
  const hashParts = `${sitesPart}${queryPart}`;

  return `${entityName}${hashParts ? md5(hashParts) : ""}`;
};

export const fetchReferences = createAsyncThunk<
  ReferencesFetchResult,
  ReferencesFetchArgs,
  { state: { references: ReferencesState } }
>(
  "references/fetchReferences",
  (payload) => {
    const { context, entityName, query, limit, sites } = payload;
    const { apiCallFull, apiCall, entities, uiFormat, site } = context;

    const referencesQuery = query
      ? { entity: entityName, query }
      : {
          entity: entityName,
          query: getReferenceQuery(context, entities[entityName]),
        };

    const filteredQuery = addFilter(
      {
        or: getReferenceDisplayFieldFilters(
          context,
          referencesQuery.query.select,
        ),
      },
      referencesQuery,
    );

    const finalQuery = limit
      ? apiSearchFull(apiCallFull).runLookupQueryWithPagination(
          filteredQuery,
          context,
          0,
          limit,
          sites,
        )
      : searchApi(apiCall)
          .runQueryForLookup(filteredQuery, sites)
          .then((records: ForeignKey[]) => ({
            data: { data: records, totalPages: 1 },
          }));

    return finalQuery.then(({ data: { data = [], totalPages } }) => ({
      entityName,
      sites,
      query,
      records: data.map(
        getRecordWithTitleFn(entityName, uiFormat.culture, site.culture),
      ),
      totalPages,
      lastFetch: Date.now(),
    }));
  },
  {
    condition: (payload, { getState }) => {
      const { sites, entityName, query, force } = payload;
      const refKey = getReferenceKey(entityName, sites, query);
      const { references } = getState();
      const { lastFetch, isLoading } = references?.[refKey] || {};

      return force || (hasCacheExpired(lastFetch) && !isLoading);
    },
  },
);

const referencesSlice = createSlice({
  name: "references",
  initialState,
  reducers: {
    resetReferences: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReferences.fulfilled, (state, action) => {
        const {
          sites,
          entityName,
          query,
          records = [],
          totalPages,
          lastFetch,
        } = action.payload;
        const refKey = getReferenceKey(entityName, sites, query);

        return {
          ...state,
          [refKey]: {
            isLoading: false,
            error: undefined,
            records,
            totalPages,
            lastFetch,
          },
        };
      })
      .addCase(fetchReferences.pending, (state, action) => {
        const { sites, entityName, query } = action.meta.arg;
        const refKey = getReferenceKey(entityName, sites, query);
        const existingReferences = state?.[refKey] ?? initialReferences;

        return {
          ...state,
          [refKey]: {
            ...existingReferences,
            isLoading: true,
            error: undefined,
          },
        };
      })
      .addCase(fetchReferences.rejected, (state, action) => {
        const { sites, entityName, query } = action.meta.arg;
        const refKey = getReferenceKey(entityName, sites, query);
        const existingReferences = state?.[refKey] ?? initialReferences;

        return {
          ...state,
          [refKey]: {
            ...existingReferences,
            isLoading: false,
            error: action.error,
          },
        };
      });
  },
});

export const { resetReferences } = referencesSlice.actions;

export const referencesReducer = referencesSlice.reducer;
