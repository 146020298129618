import * as R from "ramda";
import { KeysOf } from "common/types/records";
import { BoxConfig, Config, TableConfigWithMerge } from "common/data/types";

export const isTableConfig = (
  config: Config,
): config is TableConfigWithMerge => {
  const keys = R.keys(config) as KeysOf<TableConfigWithMerge>;
  return R.includes("ignore", keys);
};

export const isBoxConfig = (config: Config): config is BoxConfig => {
  const keys = R.keys(config) as KeysOf<BoxConfig>;
  return R.includes("value", keys);
};
