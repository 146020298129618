import { Fragment } from "react";
import { getLocalizedName } from "common";
import { findGroupColumn } from "common/form/functions/layout";
import { MapWidgetPropsFn } from "common/form/types";
import { merge1, merge2, merge3 } from "common/merge";
import { getFormOrDefault } from "common/record/edit/functions";
import { ForeignKey } from "common/types/foreign-key";
import { Properties } from "common/types/records";
import { getFkOrDefault } from "common/utils/foreign-key";
import { ConditionalRequired } from "common/widgets/conditional-required";
import { Hint } from "common/widgets/hint";
import { MultiEntityRecordSelector } from "common/widgets/record-selector/multi-entity";
import { ValueComponent } from "common/with-value-for";
import { getPartialForm } from "../functions";
import { getPartIdColumn, getSitePartsLookupContent } from "../part/functions";
import { StandardRelated } from "../standard-related";
import { PropTypes as DefaultPropTypes } from "../table-with-form/types";
import { RelatedValue } from "../types";
import {
  getPartRequirementRelatedEntities,
  isPartRequirementEntity,
  mapCommonColumns,
} from "./functions";

export class Requirement extends ValueComponent<
  RelatedValue,
  DefaultPropTypes
> {
  static readonly displayName = "Requirement";

  onAccepted = (properties: Properties) => {
    const { value, context, entity } = this.props;
    const { entities } = context;
    const { record, related } = value;

    const targetEntity = entity.arguments.targetEntity;

    const recordProperties = mapCommonColumns(
      entities[entity.name],
      entities[targetEntity],
      properties,
    );

    const newForm = merge1(
      "assetId",
      record.properties.assetId,
      recordProperties,
    );

    this.mergeValue({
      related: merge2("partialForm", targetEntity, newForm, related),
      // moves the sidebar selection to target entity.
      // i.e. Labor Requirements to Labor Charges
      sidebar: {
        entity: targetEntity,
        label: getLocalizedName(context.entities[targetEntity]),
      },
    });
  };

  onChangeForm = (properties: Properties) => {
    const { entity, value, onChange } = this.props;
    const newValue = merge3(
      "related",
      "partialForm",
      entity.name,
      properties,
      value,
    );

    onChange(newValue);
  };

  setPartId = (partId: ForeignKey) => {
    const { context, entity, value } = this.props;
    const { entities } = context;

    const partialForm = getPartialForm(value, entity.name);
    const partIdColumnName = getPartIdColumn(entities, entity)?.name;

    const newPartialForm: Properties = {
      ...partialForm,
      [partIdColumnName]: partId,
    };

    this.onChangeForm(newPartialForm);
  };

  onRecordSelect = (properties: Properties) => {
    const { context, entity } = this.props;
    const { entities } = context;
    const { partId } = properties;

    if (!partId) {
      const { partEntity } = getPartRequirementRelatedEntities(
        entities,
        entity,
      );
      getFkOrDefault(context.apiCall, partEntity, properties).then(
        (partId: ForeignKey) => this.setPartId(partId),
      );
      return;
    }

    this.setPartId(partId);
  };

  getPartIdMapper = (): MapWidgetPropsFn => {
    const { context, entity, value } = this.props;
    const { entities, site } = context;

    const { batchEntity, partEntity, stockEntity } =
      getPartRequirementRelatedEntities(entities, entity);

    const content = getSitePartsLookupContent(
      site.name,
      batchEntity,
      partEntity,
      stockEntity,
    );

    const partIdColumn = getPartIdColumn(entities, entity);
    const partIdColumnName = partIdColumn?.name;
    const partialForm = getPartialForm(value, entity.name);
    const partIdValue = partialForm?.[partIdColumnName];

    return (properties, layout) => {
      const { form } = properties;
      const layoutSetting =
        layout ??
        getFormOrDefault(context, entity.name, form?.formId, form)?.settings;

      const layoutColumn = layoutSetting
        ? findGroupColumn(partIdColumnName, layoutSetting)
        : undefined;

      const hint = layoutColumn?.hint;

      const inputNode = (
        <Fragment key={partIdColumnName}>
          <ConditionalRequired
            value={partIdValue}
            isRequired={partIdColumn.required || layoutColumn?.required}
          >
            <MultiEntityRecordSelector
              context={context}
              entity={partEntity}
              withLinks={true}
              value={partIdValue}
              content={content}
              onChange={this.setPartId}
              onSelect={this.onRecordSelect}
            />
            {hint ? <Hint key={hint} message={hint} /> : undefined}
          </ConditionalRequired>
        </Fragment>
      );

      return {
        [partIdColumnName]: {
          inputNode,
        },
      };
    };
  };

  render() {
    const { context, entity } = this.props;
    const { entities } = context;

    const widgetMapper = isPartRequirementEntity(entities, entity)
      ? this.getPartIdMapper()
      : undefined;

    return (
      <StandardRelated
        {...this.props}
        actions={[{ name: "Accept", fn: this.onAccepted }]}
        widgetsMapper={widgetMapper}
        onChange={this.onChangeSetValue}
      />
    );
  }
}
