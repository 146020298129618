import { Field } from "common/query-builder/types";
import { DataType } from "./types";

export const translateDataType = (type: DataType): string => {
  switch (type) {
    case "array":
      return _("Array");
    case "bool":
      return _("Boolean");
    case "currency":
      return _("Currency");
    case "date":
      return _("Date");
    case "datetime":
      return _("Date and Time");
    case "document":
      return _("Document");
    case "email":
      return _("E-mail");
    case "fk":
      return _("Foreign key");
    case "float":
      return _("Float");
    case "geolocation":
      return _("Geo location");
    case "hidden":
      return _("Hidden");
    case "html":
      return _("HTML");
    case "hyperlink":
      return _("Link");
    case "image":
      return _("Image");
    case "int":
      return _("Integer");
    case "media":
      return _("Multimedia");
    case "object":
      return _("Object");
    case "signature":
      return _("Signature");
    case "string":
      return _("String");
    case "systemintfk":
      return _("System ID Key");
    case "systemstringfk":
      return _("System Name Key");
    case "text":
      return _("Text");
    case "ufloat":
      return _("UFloat");
    case "uint":
      return _("UInteger");
    case "uniqueidentifier":
      return _("Unique Identifier");
    default:
      // eslint-disable-next-line no-console
      console.error("Unknown date type", type);
      return "Unknown";
  }
};

export const omitDataTypes = (fields: Field[], dataTypesToOmit: DataType[]) =>
  fields.filter((f) => dataTypesToOmit.indexOf(f.column.dataType) === -1);

export const includeDataTypes = (
  fields: Field[],
  dataTypesToInclude: DataType[],
) => fields.filter((f) => dataTypesToInclude.includes(f.column.dataType));
