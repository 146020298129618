import { Calendar } from "common/types/calendars";
import { Site } from "common/types/sites";
import { createInjected } from "common/ui/controllers/list";
import { getAccessibleNodes } from "common/utils/tree";
import { ExternalPropTypes } from "x/controllers/list/ribbon-wrapper";
import { listCtrlWithRibbon } from "x/controllers/list/with-ribbon";
import { getAccountSettingsCrumb } from "x/layout/ribbon/breadcrumb";
import { List } from "./list";

/**
 * @param calendars An array of all calendars
 * @param site The current site from the context
 * @param sites An array of sites from the context
 * @returns {Calendar[]} Calendars accessible in sites.
 */
export const getCalendarsAvailableInSites = (
  calendars: Calendar[],
  site: Site,
  sites: Site[] = [],
) => {
  const accessibleSites = getAccessibleNodes([], site, sites);
  return calendars.filter((calendar) => {
    const calendarSites = calendar.sites;
    return (
      !calendarSites?.length ||
      accessibleSites.some((s) => calendarSites.includes(s.name))
    );
  });
};

const ListController = listCtrlWithRibbon<string, Calendar>();
const injected = createInjected<string, Calendar>();

interface PropTypes extends ExternalPropTypes {
  newPath: string;
  editPath: string;
}

export const CalendarListController = (props: PropTypes) => {
  const { editPath, context } = props;
  const { calendars, site, sites } = context;

  const availableCalendars = getCalendarsAvailableInSites(
    calendars,
    site,
    sites,
  );

  return (
    <ListController
      {...props}
      fetch={undefined}
      dontFetch={true}
      defaultRecords={availableCalendars}
      permissionCategory="Calendars"
      title={undefined}
      crumbs={[getAccountSettingsCrumb(), { name: _("Calendars") }]}
    >
      <List {...injected} editPath={editPath} />
    </ListController>
  );
};
CalendarListController.displayName = "CalendarListController";
