import * as R from "ramda";
import { JoinOption } from "common/query-builder/joins/types";
import { Entities, EntityJoin } from "common/entities/types";
import { JoinItem, JoinType } from "common/query/types";

const joinEq = (joinDef: EntityJoin, join: JoinItem): boolean =>
  join.column === joinDef.column &&
  (joinDef.outbound || (join.entity && join.entity === joinDef.entityName));

export const getAvailableJoins = (
  entities: Entities,
  entityName: string,
  joins: JoinItem[],
): JoinOption[] =>
  R.sortBy(
    (j) => j.entityName,
    entities[entityName]?.joins
      .filter((def) => !R.any((j) => joinEq(def, j), joins || []))
      .map((j) => R.mergeRight(j, { entity: entities[j.entityName] })) || [],
  );

export const hasAvailableJoins = (
  entities: Entities,
  entityName: string,
  joins: JoinItem[],
) => !R.isEmpty(getAvailableJoins(entities, entityName, joins));

export const allJoinTypes: JoinType[] = ["INNER", "LEFT", "RIGHT"];
export const defaultJoinType: JoinType = "INNER";

export const displayLocalizedJoinType = (joinType: JoinType) =>
  joinType === "INNER"
    ? _("Inner")
    : joinType === "LEFT"
      ? _("Left")
      : _("Right");

export const isOneToOne = (join: EntityJoin) => join.outbound || join.oneToOne;
