import { Component } from "common/component";
import { Injected, QueryRunner } from "common/query";
import { QueryForEntity, RunActionsQuery, RunQuery } from "common/query/types";
import { Context } from "common/types/context";
import { GoFn } from "common/types/url";
import { Dynamic } from "./dynamic";
import { Output } from "./types";

interface PropTypes {
  context: Context;
  query: QueryForEntity;
  runQuery: RunQuery;
  runActionsQuery?: RunActionsQuery;
  goTo: GoFn;
  output: Output;
  notOlderThan: number;
  withLinks: boolean;
  reload: () => any;
  hidePaging?: boolean;
}

interface StateType {
  value: QueryForEntity;
}

export class QueryToValue extends Component<PropTypes, StateType> {
  constructor(props: PropTypes) {
    super(props);

    this.state = {
      value: this.props.query,
    };
  }

  onDisplay = (injected: Injected) => {
    const { records, running, actionsByRecordId } = injected;
    const { context, output, reload, withLinks, goTo } = this.props;
    return (
      <Dynamic
        context={context}
        output={output}
        reload={reload}
        goTo={goTo}
        withLinks={withLinks}
        records={records}
        actionsByRecordId={actionsByRecordId}
        running={running}
        value={this.state.value}
        onChange={this.onChangeMergeState("value")}
      />
    );
  };

  render() {
    const { output, runQuery, runActionsQuery, notOlderThan, hidePaging } =
      this.props;

    return (
      <QueryRunner
        onDisplay={this.onDisplay}
        runQuery={runQuery}
        runActionsQuery={runActionsQuery}
        query={this.state.value}
        withPaging={
          !hidePaging && (output.type === "Table" || output.type === "Cards")
        }
        notOlderThan={notOlderThan}
      />
    );
  }
}
