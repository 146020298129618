import * as R from "ramda";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import { SelectorOption } from "common/widgets/selector/types";
import { ValueProps } from "common/with-value-for";
import { getLocalizedName } from "common";
import { Selector } from "common/widgets/selector";
import { Entity, Entities } from "common/entities/types";

interface PropTypes {
  entities: Entities | Entity[];
  required: boolean;
  filter?: (e: Entity) => boolean;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  label?: string;
}

export const getEntityOptionLabel = (option: SelectorOption<Entity>) =>
  isGroupedOption(option) ? option.label : getLocalizedName(option);

export const EntitySelector = ({
  entities,
  required,
  placeholder,
  filter,
  disabled,
  className,
  label,
  value,
  onChange,
}: PropTypes & ValueProps<Entity>) => {
  const options = Array.isArray(entities) ? entities : R.values(entities);
  const filteredOptions = filter ? options.filter(filter) : options;
  return (
    <Selector<Entity>
      className={className}
      allowClear={!required}
      placeholder={placeholder || _("Select entity")}
      options={filteredOptions}
      getOptionLabel={getEntityOptionLabel}
      disabled={disabled}
      label={label}
      value={value}
      onChange={onChange}
    />
  );
};
