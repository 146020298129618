import { filterFormsByEntity } from "common/functions/forms";
import type { Form } from "common/types/forms";
import type { Entity } from "common/entities/types";
import { NewRecordMenu } from "common/record/new-record-menu";
import { COLOR_WHITE } from "common/types/colors";
import { AlertWarning } from "common/widgets/alert";
import { ColorLegendButton } from "common/widgets/color-legend-button";
import { PopOut } from "common/widgets/pop-out";
import { Ribbon, RibbonButtons } from "x/layout/ribbon";
import {
  ColorLegendGroup,
  getColorLegendGroupsByBehavior,
} from "x/records/list/functions";
import { DEFAULT_EVENT_COLOR } from "x/scheduler2/consts";
import { getCrumbs } from "x/scheduler2/ribbon/crumbs";
import { createUrl } from "x/scheduler2/ribbon/url";
import type { Context } from "common/types/context";

interface PropTypes {
  eventNumber: number;
  context: Context;
  personalEventEntity: Entity;
  hidePanels: boolean;
  readOnly: boolean;
  reload: () => void;
  onPreviewClose: () => void;
  onCreateEvent: (form: Form) => () => void;
}

export const getSchedulerGeneralColorLegendGroup = (): ColorLegendGroup => ({
  label: _("General"),
  items: [
    { label: _("Available time slot"), color: COLOR_WHITE },
    { label: _("Unavailable time slot"), color: DEFAULT_EVENT_COLOR },
  ],
});

export const SchedulerRibbon = ({
  eventNumber,
  reload,
  context,
  personalEventEntity,
  hidePanels,
  readOnly,
  onPreviewClose,
  onCreateEvent,
}: PropTypes) => {
  const { site, scope, forms } = context;

  const menuItem = (form: Form) => (
    <div className="qa-new-personal-event" onClick={onCreateEvent(form)}>
      {(form && form.label) || _("New")}
    </div>
  );

  const showNewBtn = !readOnly && !!personalEventEntity && !site.isGroup;

  const colorLegendGroups: ColorLegendGroup[] = [
    [getSchedulerGeneralColorLegendGroup()],
    getColorLegendGroupsByBehavior("Assignment", context),
    getColorLegendGroupsByBehavior("WorkOrder", context),
  ].flat();

  return (
    <Ribbon
      onRefresh={reload}
      scope={scope}
      crumbs={getCrumbs(context, hidePanels, onPreviewClose, eventNumber)}
      className="x-scheduler-ribbon qa-scheduler-ribbon"
    >
      {site.isGroup && (
        <AlertWarning
          message={_("Drag and drop is disabled for group sites")}
        />
      )}
      <RibbonButtons>
        {showNewBtn && (
          <>
            <NewRecordMenu
              entity={personalEventEntity}
              site={site.name}
              context={context}
              forms={filterFormsByEntity(forms, personalEventEntity.name)}
              title={_("New Personal Event")}
              disabled={!!eventNumber}
              onClick={onCreateEvent}
              menuItem={menuItem}
            />
            <div className="x-vertical-divider qa-vertical-divider" />
          </>
        )}
        <ColorLegendButton colorLegendGroups={colorLegendGroups} />
        {!hidePanels && <PopOut url={createUrl(site.name, true)} />}
      </RibbonButtons>
    </Ribbon>
  );
};
