import { Component } from "react";
import { defaultFor } from "common";
import { reportsApi } from "common/api/reports";
import { Output } from "common/data/types";
import { RequiredField } from "common/form/ui";
import { ReportSelector } from "common/reporting/selector";
import { Context } from "common/types/context";
import { ReportConfig } from "common/types/dashboards";
import { Report } from "common/types/reports";
import { Selector } from "common/widgets/selector";
import { ValueProps } from "common/with-value-for";
import { defaultMap, OutputDynamic, outputTypes } from "./output";

interface PropTypes extends ValueProps<ReportConfig> {
  context: Context;
  showMergeOption: boolean;
}

interface StateType {
  report?: Report;
  loading?: boolean;
  error?: any;
}

const defaultValue = defaultFor<ReportConfig>();

export class ReportConfigForm extends Component<PropTypes, StateType> {
  static readonly displayName = "ReportConfigForm";
  state: StateType = {};

  componentDidMount() {
    const { value = defaultValue } = this.props;
    this.loadReport(value.reportId);
  }

  componentDidUpdate() {
    // when you navigate through widgets on the left list
    const { report } = this.state;
    const { value } = this.props;

    if (!report || value?.reportId !== report.id) {
      this.loadReport(value?.reportId);
    }
  }

  loadReport = (reportId: number) => {
    if (this.state.loading || !reportId) return;

    this.setState({ loading: true });
    const { apiCall, uiFormat, entities } = this.props.context;

    reportsApi(apiCall, uiFormat.culture, entities)
      .get(reportId)
      .then(
        (report) =>
          report ? this.setReport(report) : this.setState({ loading: false }), // invalid reportId
      )
      // TODO Utilize the error caught here
      // eslint-disable-next-line react/no-unused-state
      .catch((error) => this.setState({ loading: false, error }));
  };

  setReport = (report: Report) => {
    this.setState({ report, loading: false });
  };

  onChangeReport = (report: Report) => {
    if (report) this.setReport(report);
    this.props.onChange({ ...this.props.value, reportId: report?.id });
  };

  onChangeOutputConfigType = (type: string) => {
    this.props.onChange({
      ...this.props.value,
      output: { type, config: defaultMap[type] },
    });
  };

  onChangeOutput = (output: Output) => {
    this.props.onChange({ ...this.props.value, output: output });
  };

  render() {
    const { context, showMergeOption, value = defaultValue } = this.props;
    const { output } = value;
    const { report = defaultFor() } = this.state;
    const { entity, query } = report;

    const outputConfigType = output?.type;
    const hasReport = !!entity && !!query?.select;

    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-md-6 qa-record-selector">
            <RequiredField
              label={_("Report")}
              labelBreakpoints="col-md-3"
              value={report}
            >
              <ReportSelector
                context={context}
                onChange={this.onChangeReport}
                value={report}
              />
            </RequiredField>
          </div>
          <div className="col-xs-12 col-md-6 qa-output-selector">
            {hasReport ? (
              <RequiredField
                label={_("Output")}
                labelBreakpoints="col-md-3"
                value={outputConfigType}
              >
                <Selector
                  options={outputTypes}
                  value={outputConfigType}
                  onChange={this.onChangeOutputConfigType}
                />
              </RequiredField>
            ) : undefined}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 x-widget-dynamic">
            {hasReport ? (
              <div className={`qa-outputConfig-${outputConfigType}`}>
                <OutputDynamic
                  context={context}
                  entity={entity}
                  select={query?.select}
                  showMergeOption={showMergeOption}
                  value={output}
                  onChange={this.onChangeOutput}
                />
              </div>
            ) : (
              <div className="x-widget-empty">
                {_("Please select a report")}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
