import * as R from "ramda";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import {
  NotificationTemplate,
  NotificationReceivers,
} from "common/types/workflows";
import { getTemplatesAvailableInEntity } from "common/widgets/email-action/functions";

export const isEmailNotificationValid = (action: NotificationTemplate) =>
  !!action?.emailTemplateId;

export const areEmailReceiversValid = (action: NotificationReceivers) =>
  !!(
    action?.to?.emailsList ||
    action?.to?.sqlquery?.query?.select ||
    (action?.to?.templateIds && !R.isEmpty(action?.to?.templateIds))
  );

export const hasEmailTemplates = (context: Context, entity: Entity) => {
  const { emailTemplates } = context;
  const availableTemplates = getTemplatesAvailableInEntity(
    emailTemplates,
    entity,
  );
  return !!availableTemplates?.length;
};
