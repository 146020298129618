import { Context } from "common/types/context";
import { Menu } from "./menu";
import { AdminMenu } from "./admin-menu";
import { getUserMenuEntries } from "./functions";

interface PropTypes {
  context: Context;
  isAdmin: boolean;
  location: string;
  explorerEntity: string;
  lastNonAdminPath: string;
  explorerOpen: boolean;
}

export const MenuPanel = ({
  context,
  isAdmin,
  location,
  explorerEntity,
  lastNonAdminPath,
  explorerOpen,
}: PropTypes) => (
  <div className="x-menu">
    {isAdmin ? (
      <AdminMenu
        context={context}
        lastNonAdminPath={lastNonAdminPath}
        location={location}
        explorerEntity={explorerEntity}
        explorerOpen={explorerOpen}
      />
    ) : (
      <Menu
        location={location}
        entries={getUserMenuEntries(context)}
        explorerEntity={explorerEntity}
        explorerOpen={explorerOpen}
      />
    )}
  </div>
);
