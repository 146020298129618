import { Component } from "react";
import { Context } from "common/types/context";
import { ForeignKey as Fk } from "common/types/foreign-key";
import { defaultFor, getLocalizedName } from "common";
import { Properties, Record } from "common/types/records";
import { searchApi } from "common/api/search";
import { UTCDateTime } from "common/date-time/types";
import { getColumnLabel } from "common/entities";
import { Entity } from "common/entities/types";
import { RequiredField } from "common/form/ui";
import {
  RUNNING_METER_TYPE_ID,
  TOTAL_METER_TYPE_ID,
} from "common/types/system-strings";
import { AlertWarning } from "common/widgets/alert";
import { DateTime } from "common/widgets/date/date-time";
import { ValueProps } from "common/with-value-for";
import { getTotalReadings, getReadingsValueWarning } from "../functions";
import { AssetMeter } from "./asset-meter";
import { Reading } from "./reading";

interface PropTypes extends ValueProps<Properties> {
  context: Context;
  entity: Entity;
  assetMeters: Record[];
  isValid: boolean;
  previousReading: number;
  assetId: string;
  maxReadingPerDay?: number;
  meterTypeId: number;
}

interface StateType {
  totalReadings?: number;
  loading?: boolean;
  error?: any;
}

export const defaultValue = defaultFor<Properties>();

export class AssetMeterData extends Component<PropTypes, StateType> {
  static readonly displayName = "AssetMeterData";
  state: StateType = {};

  componentDidUpdate(prevProps: PropTypes) {
    const oldValue = prevProps.value;
    const newValue = this.props.value;

    const oldAssetMeterId = oldValue?.assetMeterId?.id;
    const newAssetMeterId = newValue?.assetMeterId?.id;

    if (
      !newAssetMeterId ||
      !newValue?.date ||
      (oldAssetMeterId === newAssetMeterId && oldValue?.date === newValue?.date)
    )
      return;

    this.fetchTotalReadings();
  }

  fetchTotalReadings = () => {
    const { context, entity, assetId, meterTypeId, value, maxReadingPerDay } =
      this.props;
    const { assetMeterId, date, id } = value;

    if (!maxReadingPerDay) return;

    const query = getTotalReadings(
      entity,
      assetId,
      assetMeterId.id,
      id,
      date,
      meterTypeId,
    );

    this.setState({ loading: true, totalReadings: undefined });
    searchApi(context.apiCall)
      .runQuery(query)
      .then((results: Array<{ totalReadings: number }>) =>
        this.setState({
          loading: false,
          totalReadings: results[0] && Number(results[0].totalReadings),
        }),
      )
      // TODO Utilize the error caught here
      // eslint-disable-next-line react/no-unused-state
      .catch((error) => this.setState({ loading: false, error }));
  };

  onChangeAssetMeterId = (assetMeterId: Fk) =>
    this.props.onChange({
      ...this.props.value,
      assetMeterId,
      value: undefined,
    });

  onChangeDate = (date: UTCDateTime) =>
    this.props.onChange({ ...this.props.value, date });

  render() {
    const {
      context,
      entity,
      assetMeters,
      isValid,
      previousReading,
      meterTypeId,
      value = defaultValue,
      onChange,
      maxReadingPerDay,
    } = this.props;
    const { loading, totalReadings } = this.state;
    const { entities } = context;

    const total =
      parseFloat(value.value || 0) +
      ((meterTypeId === TOTAL_METER_TYPE_ID && totalReadings) || 0);
    const validTotal = !maxReadingPerDay || total <= maxReadingPerDay;
    const assetMeterEntity = entities[entity.arguments.assetMeterEntity];

    return (
      <div className="x-asset-meter-data">
        <RequiredField
          label={getLocalizedName(assetMeterEntity)}
          className="qa-asset-meter-reading-meter"
          value={value.assetMeterId}
        >
          <AssetMeter
            entity={entity}
            context={context}
            assetMeters={assetMeters}
            value={value.assetMeterId}
            onChange={this.onChangeAssetMeterId}
          />
        </RequiredField>
        <RequiredField
          label={getColumnLabel(entity, "date")}
          className="qa-asset-meter-reading-date"
          value={value.date}
        >
          <DateTime
            uiFormat={context.uiFormat}
            value={value.date}
            onChange={this.onChangeDate}
          />
        </RequiredField>
        <Reading
          context={context}
          entity={entity}
          previousReading={previousReading}
          meterTypeId={meterTypeId}
          isValid={isValid}
          maxReadingPerDay={maxReadingPerDay}
          onChange={onChange}
          value={value}
          isRequired={true}
        />
        {!validTotal && meterTypeId !== RUNNING_METER_TYPE_ID && (
          <AlertWarning
            message={getReadingsValueWarning(total, maxReadingPerDay)}
          />
        )}
        {loading ? <span>{_("Loading total...")}</span> : undefined}
      </div>
    );
  }
}
