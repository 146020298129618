import * as R from "ramda";
import { Entities } from "common/entities/types";
import { getField } from "common/query-builder/functions";
import { Field } from "common/query-builder/types";
import { getPathMap } from "common/query/joins";
import { getSelectFieldByTitle } from "common/query/select";
import {
  Field as QueryField,
  Filter,
  FilterAnd,
  FilterRule,
  GroupField,
  GroupItem,
  isAnd,
  isExpressionRule,
  isOr,
  isSubQuery,
  QueryForEntity,
} from "common/query/types";

export const isValid = ({ entity, query }: QueryForEntity) =>
  entity && query && query.group && !!query.group.length;

const getQueryFilter = (
  group: GroupItem[] = [],
  values: any[] = [],
  filter: Filter,
): FilterAnd => ({
  and: R.compose(
    (fs: Filter[]) => R.filter((f: Filter) => !!f, fs),
    R.append(filter),
    (group: GroupItem[]) =>
      group?.map((g, i) =>
        R.mergeRight(g, {
          op: values[i] == null ? "isnull" : "eq",
          value: values[i],
        }),
      ),
    R.take(values.length),
  )(group),
});

// replaces FK fields for the 'title' field of the joined table
// e.g. { name: 'assetType' } => { name: 'description', path: '/assetType' }
const generatePath = (
  entities: Entities,
  fields: Field[],
  item: QueryField,
) => {
  const field = getField(fields, item);
  const relatedEntity = field?.column?.relatedEntity;
  if (!relatedEntity) return item;

  return R.mergeRight(item, {
    name: getSelectFieldByTitle(entities[relatedEntity].query.select).name,
    path: `${item.path || ""}/${item.name}`,
  });
};

const replaceFksInFilter = (
  entities: Entities,
  fields: Field[],
  filter: Filter,
): Filter => {
  const replaceFilter = (f: Filter) => replaceFksInFilter(entities, fields, f);

  if (isAnd(filter)) return { and: filter.and.map(replaceFilter) };
  if (isOr(filter)) return { or: filter.or.map(replaceFilter) };
  if (isSubQuery(filter) || isExpressionRule(filter)) return filter;

  return generatePath(entities, fields, filter) as FilterRule;
};

export const getRecordQuery = (
  entities: Entities,
  fields: Field[],
  { entity, query }: QueryForEntity,
  values: any[] = [],
): QueryForEntity => {
  const titleField = getSelectFieldByTitle(entities[entity].query.select);
  const filter = replaceFksInFilter(entities, fields, query.filter);

  return {
    entity,
    query: R.mergeRight(query, {
      select: [{ name: titleField.name, alias: "title" }, { name: "id" }],
      filter: getQueryFilter(query.group, values, filter),
      group: [],
      order: [{ name: titleField.name, alias: "title" }],
    }),
  };
};

export const getCategoryQuery = (
  entities: Entities,
  fields: Field[],
  { entity, query }: QueryForEntity,
  values: any[] = [],
): QueryForEntity => {
  const filter = replaceFksInFilter(entities, fields, query.filter);

  return {
    entity,
    query: R.mergeRight(query, {
      select: [
        R.mergeRight(query.group[values.length], { alias: "name" }),
        { name: "id", fn: "COUNT", alias: "count" },
      ],
      filter: getQueryFilter(query.group, values, filter),
      order: [query.group[values.length]],
      group: R.take(values.length + 1, query.group),
    }),
  };
};

export const applyGroup = (
  entities: Entities,
  group: GroupItem[],
  query: QueryForEntity,
): QueryForEntity => {
  const pathMap = getPathMap(entities, query);

  const workingGroup = (group || []).filter(
    (g) => !!pathMap[(g as GroupField).path || ""],
  );

  return R.assocPath(["query", "group"], workingGroup, query);
};
