import * as R from "ramda";
import { hasBehavior, isChargeBehaviorName } from "common/api/behavior";
import { Entity } from "common/entities/types";
import { RelatedUiValue } from "common/record/types";
import { AlertWarning } from "common/widgets/alert";

interface PropTypes {
  entity: Entity;
  related: RelatedUiValue;
}

const hasCostCalculationWarning = (entity: Entity, related: RelatedUiValue) =>
  related?.isDirty &&
  (R.any((b) => isChargeBehaviorName(b.name), entity.behaviors) ||
    hasBehavior(entity.behaviors, "PurchaseOrderItem"));

export const RelatedTableWarning = ({ entity, related }: PropTypes) =>
  hasCostCalculationWarning(entity, related) ? (
    <AlertWarning
      message={_("All costs will be calculated after saving the record")}
      className="x-margin-bottom-10-i"
    />
  ) : null;
