import * as R from "ramda";
import { Component } from "react";
import { getUnexpectedErrorMessage } from "common/api/error";
import { recordsApi } from "common/api/records";
import { searchApi } from "common/api/search";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { CancelButtonLarge } from "common/ui/buttons";
import { AlertWarning } from "common/widgets/alert";
import { LoadingButton } from "common/widgets/loading-button";
import { hasTransferValue as hasValue, transferFormToApi } from "./functions";
import { TransferStockFormComponent } from "./transfer-form";
import { defaultTransferValue, TransferFormValue } from "./types";

interface PropTypes {
  context: Context;
  entity: Entity;
  fromStock: Properties;
  onCancel: () => any;
  onTransferred: () => any;
  destinations: Properties[];
  hasBatchSelection: boolean;
}

interface StateType {
  error?: boolean;
  errorMessage?: string;
  loading?: boolean;
  value?: TransferFormValue;
}

export class TransferStock extends Component<PropTypes, StateType> {
  constructor(props: PropTypes) {
    super(props);

    this.state = {
      error: false,
      errorMessage: undefined,
      loading: false,
      value: defaultTransferValue(props.fromStock.onHand),
    };
  }

  componentDidUpdate(prevProps: PropTypes) {
    if (
      prevProps.fromStock.onHand !== this.props.fromStock.onHand ||
      prevProps.fromStock.id !== this.props.fromStock.id
    ) {
      // TODO: review if this is necessary. playing with the UI don't seem to trigger it
      this.setState({
        value: defaultTransferValue(this.props.fromStock.onHand),
      });
    }
  }

  transfer = () => {
    const { context, entity, fromStock, onTransferred, destinations } =
      this.props;
    const recordId = fromStock.id;
    const { value } = this.state;

    this.setState({ loading: true, error: false, errorMessage: undefined });
    recordsApi(context.apiCall)
      .transfer(entity.name, recordId, transferFormToApi(value, destinations))
      .then(() => {
        this.setState({ loading: false });
        onTransferred();
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: true,
          errorMessage: e.data.error,
        });
      });
  };

  onChange = (value: TransferFormValue) => {
    this.setState({ value });
  };

  render() {
    const {
      context,
      entity,
      onCancel,
      fromStock,
      destinations,
      hasBatchSelection,
    } = this.props;
    const { id, onHand } = fromStock;
    const { value, loading, error, errorMessage } = this.state;
    const destinationLocations = destinations.map(
      R.prop("locationId"),
    ) as Array<{ id: string; title: string }>;
    return (
      <div className="x-stock-adjustment-wrapper">
        <div className="x-form-content">
          {error ? (
            <AlertWarning
              message={errorMessage || getUnexpectedErrorMessage()}
            />
          ) : undefined}
          <TransferStockFormComponent
            context={context}
            entity={entity}
            runQuery={searchApi(context.apiCall).runQuery}
            onHand={onHand}
            destinations={destinationLocations}
            recordId={id}
            fromLocation={fromStock.locationId.title}
            hasBatchSelection={hasBatchSelection}
            value={value}
            onChange={this.onChange}
          />
        </div>

        <div className="x-form-footer">
          <div className="x-form-footer-content x-flex">
            <CancelButtonLarge onClick={onCancel} />
            <LoadingButton
              loadingText={_("Transferring")}
              idleText={_("Transfer")}
              loading={loading}
              onClick={this.transfer}
              disabled={loading || !hasValue(onHand, hasBatchSelection, value)}
              type="action"
              size="large"
            />
          </div>
        </div>
      </div>
    );
  }
}
