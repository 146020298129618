import * as R from "ramda";
import { showDay, showHour, showMonth } from "common/culture";
import { numberToDayOfWeekNumber } from "common/date-time/common";
import { getWeekdayNameFromNumber } from "common/date-time/weekday";
import {
  CronTuple,
  ExpressionField,
  FREQUENCIES,
  LAST_DAY_OF_MONTH,
} from "common/types/cron";
import { getWithin, isNumeric } from "common/utils/number";
import { getMaxDayInMonth } from "common/widgets/cron/functions";

export const translateFrequency = (frequency: string) => {
  switch (frequency) {
    case "everyDay":
      return _("Every day");
    case "everyWeek":
      return _("Every week");
    case "everyMonth":
      return _("Every month");
    case "everyYear":
      return _("Every year");
    default:
      return "";
  }
};

const isValidDayExpression = (expression: ExpressionField) =>
  expression === LAST_DAY_OF_MONTH;

const translateDayExpression = (expression: ExpressionField) =>
  expression === LAST_DAY_OF_MONTH ? _("last day") : "";

export const FREQUENCIES_OPTIONS = Object.keys(FREQUENCIES);

export const getCronFieldValue = (
  rawValue: string,
  min: number,
  max: number,
) => {
  const value = parseInt(rawValue);
  return isNaN(value) ? value : getWithin(value, min, max);
};

/**
 * http://www.freebsd.org/cgi/man.cgi?crontab%285%29
 * cron[0] = minute (0-59)
 * cron[1] = hour(0-23)
 * cron[2] = day (1-31) || L - last day of the month
 * cron[3] = month (1-12)
 * cron[4] = week day (0-6 - 0 is Sun)
 */
export const getCron = (value: string): CronTuple => {
  if (!value) return null;

  const [rawMinute, rawHour, rawDayOfMonth, rawMonth, rawDayOfWeek] = R.split(
    " ",
    R.trim(value),
  );

  const dayOfMonth = isValidDayExpression(rawDayOfMonth)
    ? rawDayOfMonth
    : getCronFieldValue(rawDayOfMonth, 1, getMaxDayInMonth(rawMonth));

  return [
    getCronFieldValue(rawMinute, 0, 59),
    getCronFieldValue(rawHour, 0, 23),
    dayOfMonth,
    getCronFieldValue(rawMonth, 1, 12),
    getCronFieldValue(rawDayOfWeek, 0, 6),
  ];
};

export const getSelectedFrequency = (cron: string) => {
  const matchFrequency = (freqPair: [string, string]) => {
    const cronArray = cron.split(" ");
    const pattern = freqPair[1].split(" ");

    // cron[i] is NaN if and only if pattern[i] is star
    const matchComponent = (i: number) =>
      (pattern[i] === "*") === (cronArray[i] === "*");

    return R.all(matchComponent, [0, 1, 2, 3, 4]);
  };

  return cron
    ? R.compose(
        (match: [string, string]) => R.head(match || [""]),
        R.find(matchFrequency),
        R.toPairs,
      )(FREQUENCIES)
    : "";
};

export const showCronDay = (day: ExpressionField) =>
  isValidDayExpression(day)
    ? translateDayExpression(day)
    : isNumeric(day)
      ? showDay(day)
      : "";

export const showCronMonthDay = (month: number, day: ExpressionField) => {
  const dayToShow = showCronDay(day);
  const monthToShow = showMonth(month);

  if (!dayToShow || !monthToShow) return "";

  return isValidDayExpression(day)
    ? `${dayToShow} of ${monthToShow}`
    : `${monthToShow} ${dayToShow}`;
};

export const cronToString = (value: string) => {
  const cron = getCron(value);

  if (!cron) return null;

  const selectedFreq = getSelectedFrequency(value);

  const [, h, days, months, weekDays] = cron;
  const hours = typeof h === "number" ? showHour(h) : undefined;
  const weekday = weekDays ? numberToDayOfWeekNumber(weekDays) : undefined;

  switch (selectedFreq) {
    case "everyDay":
      return _("every day at HOURS").replace("HOURS", hours);
    case "everyWeek":
      return _("every week on WEEK at HOURS")
        .replace("WEEK", getWeekdayNameFromNumber(weekday))
        .replace("HOURS", hours);
    case "everyMonth": {
      const text = isValidDayExpression(days)
        ? _("every DAYSORIGINALSUFFIX of a month at HOURS")
        : _("every month on the DAYSORIGINALSUFFIX at HOURS");

      return text
        .replace("DAYSORIGINALSUFFIX", showCronDay(days))
        .replace("HOURS", hours);
    }
    case "everyYear": {
      const text = isValidDayExpression(days)
        ? _("every year on the SHOWMONTHDAY at HOURS")
        : _("every year on SHOWMONTHDAY at HOURS");

      return text
        .replace("SHOWMONTHDAY", showCronMonthDay(months, days))
        .replace("HOURS", hours);
    }
    default:
      return "";
  }
};
