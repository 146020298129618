import { ApiCall } from "common/types/api";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { CancellablePromise } from "common/types/promises";
import {
  DEFAULT_PROJECTION_FREQUENCY,
  defaultExternal,
  defaultPm,
  EventType,
  getDefaultInspectionProps,
  Pm,
  Projection,
  ScheduledEventAssetRecord,
  ScheduledEventRecord,
} from "common/types/scheduled-event";

export const eventsApi = (apiCall: ApiCall) => ({
  getProjections: (
    entityName: string,
    record: ScheduledEventRecord,
  ): CancellablePromise<Projection[]> =>
    apiCall("post", `api/entities/:site/${entityName}/new/projections`, record),
});

export const getNewEventAsset = (
  assetId: ForeignKey,
): ScheduledEventAssetRecord => ({
  properties: {
    assetId,
    meters: [],
    lastOpenDate: undefined,
    lastCloseDate: undefined,
    isDeleted: false,
  },
  actions: [],
});

const getDefaultPm = (context: Context): Pm =>
  context.isEnabledFeature("scheduler")
    ? { ...defaultPm, ...DEFAULT_PROJECTION_FREQUENCY, hasProjections: true }
    : defaultPm;

export const defaultEventProps = (context: Context, eventType: EventType) => {
  const { defaultCalendarId } = context;

  const defaultsForType =
    eventType === "P"
      ? getDefaultPm(context)
      : eventType === "I"
        ? getDefaultInspectionProps()
        : eventType === "E"
          ? defaultExternal
          : undefined;

  return {
    ...defaultsForType,
    calendarIds: defaultCalendarId ? [defaultCalendarId] : [],
  };
};

export const getAvailableTypes = (context: Context) =>
  [
    context.isEnabledFeature("preventiveMaintenance") && "P",
    context.isEnabledFeature("inspectionRound") && "I",
    context.isEnabledFeature("conditionMonitoring") && "C",
    context.isEnabledFeature("externalTrigger") && "E",
  ].filter((et) => et) as EventType[];
