import type {
  AssignmentPublishedEvent,
  DocumentAddedEvent,
  DocumentRemovedEvent,
  Event,
  PMTriggeredEvent,
  PurchaseOrderAdjustmentEvent,
  PurchaseOrderClosedEvent,
  RecordCreatedEvent,
  RecordDeletedEvent,
  RecordRestoredEvent,
  RecordUpdatedEvent,
  RequestApprovedEvent,
  RequestRejectedEvent,
  WorkOrderClosedEvent,
  WorkOrderCompletedEvent,
  WorkOrderOpenedEvent,
  WorkOrderPreparedEvent,
  WorkOrderReopenedEvent,
  WorkOrderRescheduledEvent,
  RimeRankingUpdatedEvent,
  PdfGeneratedEvent,
  RequisitioningReadyForPoEvent,
  RequisitioningRejectedEvent,
  RequisitioningSubmittedEvent,
  PartCountSubmittedEvent,
  PartCountCancelledEvent,
} from "common/types/events";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { PartCountSubmitted } from "common/record/form/content/audit-trail/event-detail/events/part-count-submitted";
import { PartCountCancelled } from "common/record/form/content/audit-trail/event-detail/events/part-count-cancelled";
import { getDetailsEventName } from "../utils";
import { AssignmentPublished } from "./events/assignment-published";
import { DocumentAdded } from "./events/document-added";
import { DocumentRemoved } from "./events/document-removed";
import { EmptyDetails } from "./events/empty";
import { InventoryUpdated } from "./events/inventory-updated";
import { PdfGenerated } from "./events/pdf-generated";
import { PmTriggered } from "./events/pm-triggered";
import { PurchaseOrderAdjustment } from "./events/purchase-order-adjustment";
import { PurchaseOrderClosed } from "./events/purchase-order-closed";
import { RecordCreated } from "./events/record-created";
import { RecordDeleted } from "./events/record-deleted";
import { RecordRestored } from "./events/record-restored";
import { RecordUpdated } from "./events/record-updated";
import { RequestApproved } from "./events/request-approved";
import { RequestRejected } from "./events/request-rejected";
import { WorkOrderClosed } from "./events/work-order-closed";
import { WorkOrderCompleted } from "./events/work-order-completed";
import { WorkOrderOpened } from "./events/work-order-opened";
import { WorkOrderPrepared } from "./events/work-order-prepared";
import { WorkOrderReopened } from "./events/work-order-reopened";
import { WorkOrderRescheduled } from "./events/work-order-rescheduled";
import { RimeRankingUpdated } from "./events/rime-ranking-updated";
import { RequisitioningReadyForPo } from "./events/requisitioning-ready-for-po";
import { RequisitioningRejected } from "./events/requisitioning-rejected";
import { RequisitioningSubmitted } from "./events/requisitioning-submitted";

interface PropTypes {
  event: Event;
  entity: Entity;
  context: Context;
  hideLinks?: boolean;
}

export const Detail = ({ event, entity, context, hideLinks }: PropTypes) => {
  switch (getDetailsEventName(event.name, event.userName)) {
    case "PdfGenerated":
      return (
        <PdfGenerated event={event as PdfGeneratedEvent} context={context} />
      );
    case "RecordCreated":
      return (
        <RecordCreated
          event={event as RecordCreatedEvent}
          entity={entity}
          context={context}
          hideLinks={hideLinks}
        />
      );
    case "RecordUpdated": {
      return (
        <RecordUpdated
          event={event as RecordUpdatedEvent}
          entity={entity}
          context={context}
          hideLinks={hideLinks}
        />
      );
    }
    case "RecordDeleted": {
      return (
        <RecordDeleted
          event={event as RecordDeletedEvent}
          context={context}
          hideLinks={hideLinks}
        />
      );
    }
    case "RecordRestored": {
      return (
        <RecordRestored
          event={event as RecordRestoredEvent}
          context={context}
          hideLinks={hideLinks}
        />
      );
    }
    case "WorkOrderOpened": {
      return (
        <WorkOrderOpened
          event={event as WorkOrderOpenedEvent}
          context={context}
          entity={entity}
          hideLinks={hideLinks}
        />
      );
    }
    case "WorkOrderClosed": {
      return (
        <WorkOrderClosed
          event={event as WorkOrderClosedEvent}
          entity={entity}
          context={context}
          hideLinks={hideLinks}
        />
      );
    }
    case "WorkOrderCompleted": {
      return (
        <WorkOrderCompleted
          event={event as WorkOrderCompletedEvent}
          entity={entity}
          context={context}
          hideLinks={hideLinks}
        />
      );
    }
    case "WorkOrderPrepared": {
      return (
        <WorkOrderPrepared
          event={event as WorkOrderPreparedEvent}
          uiFormat={context.uiFormat}
        />
      );
    }
    case "WorkOrderReopened": {
      return (
        <WorkOrderReopened
          event={event as WorkOrderReopenedEvent}
          context={context}
          hideLinks={hideLinks}
        />
      );
    }
    case "WorkOrderRescheduled": {
      return (
        <WorkOrderRescheduled
          event={event as WorkOrderRescheduledEvent}
          context={context}
          hideLinks={hideLinks}
        />
      );
    }
    case "RequestApproved": {
      return (
        <RequestApproved
          event={event as RequestApprovedEvent}
          context={context}
          hideLinks={hideLinks}
        />
      );
    }
    case "RequestRejected": {
      return (
        <RequestRejected
          event={event as RequestRejectedEvent}
          context={context}
          hideLinks={hideLinks}
        />
      );
    }
    case "PMTriggered": {
      return (
        <PmTriggered
          entities={context.entities}
          event={event as PMTriggeredEvent}
          context={context}
          hideLinks={hideLinks}
        />
      );
    }
    case "PartsCheckedOut":
    case "PartsCheckedIn":
    case "ReorderPart":
    case "StockUpdated": {
      return (
        <InventoryUpdated
          message={_("Check Inventory Journal for more information")}
        />
      );
    }
    case "DocumentAdded": {
      return (
        <DocumentAdded
          siteName={context.site.name}
          event={event as DocumentAddedEvent}
          hideLinks={hideLinks}
        />
      );
    }
    case "DocumentRemoved": {
      return (
        <DocumentRemoved
          siteName={context.site.name}
          event={event as DocumentRemovedEvent}
          hideLinks={hideLinks}
        />
      );
    }
    case "AssignmentPublished": {
      return (
        <AssignmentPublished
          event={event as AssignmentPublishedEvent}
          uiFormat={context.uiFormat}
        />
      );
    }
    case "PurchaseOrderClosed": {
      return (
        <PurchaseOrderClosed
          event={event as PurchaseOrderClosedEvent}
          context={context}
          hideLinks={hideLinks}
        />
      );
    }
    case "PurchaseOrderAdjustment": {
      return (
        <PurchaseOrderAdjustment
          event={event as PurchaseOrderAdjustmentEvent}
          context={context}
          hideLinks={hideLinks}
        />
      );
    }
    case "PartCountSubmitted": {
      return (
        <PartCountSubmitted
          context={context}
          entity={entity}
          event={event as PartCountSubmittedEvent}
          hideLinks={hideLinks}
        />
      );
    }
    case "PartCountCancelled": {
      return (
        <PartCountCancelled
          context={context}
          entity={entity}
          event={event as PartCountCancelledEvent}
          hideLinks={hideLinks}
        />
      );
    }
    case "RimeRankingUpdated": {
      return <RimeRankingUpdated event={event as RimeRankingUpdatedEvent} />;
    }
    case "RequisitioningSubmitted": {
      return (
        <RequisitioningSubmitted
          event={event as RequisitioningSubmittedEvent}
          context={context}
        />
      );
    }
    case "RequisitioningRejected": {
      return (
        <RequisitioningRejected
          event={event as RequisitioningRejectedEvent}
          context={context}
        />
      );
    }
    case "RequisitioningReadyForPo": {
      return (
        <RequisitioningReadyForPo
          event={event as RequisitioningReadyForPoEvent}
          context={context}
        />
      );
    }
  }
  return <EmptyDetails event={event} uiFormat={context.uiFormat} />;
};

Detail.displayName = "Detail";
