import { Component } from "react";
import { getTimestamp } from "common/date-time/common";
import { Context } from "common/types/context";
import { Dashboard } from "common/types/dashboards";
import { GoFn } from "common/types/url";
import { getSubTreeSingleNodes } from "common/utils/tree";
import { Selector } from "common/widgets/selector";
import { NotFound } from "common/widgets/not-found";
import { isAdminUserType } from "common/types/users";
import { DashboardView } from "./dashboard";
import { displayDashboard, getDashboard } from "./functions";

export interface PropTypes {
  context: Context;
  onEdit: (id: number) => void;
  onNew: () => void;
  id: number;
  goTo: GoFn;
}

interface StateType {
  notOlderThan: number;
}

export class Dashboards extends Component<PropTypes, StateType> {
  static readonly displayName = "Dashboards";
  state: StateType = {
    notOlderThan: undefined,
  };

  componentDidMount() {
    const { context, id, goTo } = this.props;
    const { dashboards = [], site } = context;

    if (!id && dashboards.length) {
      goTo(`#/${site.name}/dashboard/${dashboards[0].id}`);
    }
  }

  componentDidUpdate(prevProps: PropTypes) {
    if (prevProps.id !== this.props.id) {
      this.setState({ notOlderThan: undefined });
    }
  }

  onDashboardChanged = (dashboard: Dashboard) => {
    const { goTo, context } = this.props;
    goTo(`#/${context.site.name}/dashboard/${dashboard.id}`);
  };

  onEditClicked = () => {
    const { onEdit, id } = this.props;
    onEdit(id);
  };

  reload = () => {
    this.setState({ notOlderThan: getTimestamp() });
  };

  render() {
    const { context, onNew, id, goTo } = this.props;
    const { site, sites, dashboards, userTypes } = context;

    const hasDashboards = !!dashboards.length;
    const selectedDashboard = getDashboard(dashboards, id);

    if (id && !selectedDashboard)
      return <NotFound url={`#/${site.name}/dashboard`} />;

    const availableSites = getSubTreeSingleNodes(sites, site);

    const isDashboardFeatureOn = context.isEnabledFeature("dashboard");
    const userIsOwner = context.id === selectedDashboard?.userId;
    const canEditDashboard = isAdminUserType(userTypes) || userIsOwner;

    return (
      <div className="x-padding-10">
        <div className="x-dashboard-header x-padding-10 ">
          <div className="qa-select-dashboard">
            {hasDashboards ? (
              <Selector<Dashboard>
                options={dashboards}
                value={selectedDashboard}
                getOptionLabel={displayDashboard(context)}
                onChange={this.onDashboardChanged}
              />
            ) : undefined}
            <div className="x-dashboard-action-wrapper">
              {isDashboardFeatureOn && selectedDashboard && canEditDashboard ? (
                <span
                  onClick={this.onEditClicked}
                  className="x-dashboard-edit-button qa-dashboard-edit"
                  title={_("Edit dashboard")}
                >
                  <i className="fa fa-edit" />
                </span>
              ) : undefined}
              {isDashboardFeatureOn ? (
                <span
                  onClick={onNew}
                  className="x-dashboard-add-button qa-dashboard-add"
                  title={_("Add new dashboard")}
                >
                  <i className="fa fa-plus" />
                </span>
              ) : undefined}
            </div>
          </div>
        </div>
        {hasDashboards ? (
          <DashboardView
            context={{ ...context, sites: availableSites }}
            dashboard={selectedDashboard}
            goTo={goTo}
            reload={this.reload}
            notOlderThan={this.state.notOlderThan}
          />
        ) : (
          <div className="x-dashboard-empty">
            <p>
              {_(
                "Please click the PLUS sign at the top to create a new dashboard",
              ).replace("PLUS", "[ + ]")}
              .
            </p>
          </div>
        )}
      </div>
    );
  }
}
