import * as R from "ramda";
import * as React from "react";
import { ComponentClass } from "react";
import { ValueComponent, ValueProps } from "common/with-value-for";

type InwardProps<V, P> = P & WithValue<V>;

interface ValueFunctions<V> {
  setValue: (v: V, noParse?: boolean) => void;
  mergeValue: (v: Partial<V>, noParse?: boolean) => void;
  mergeValue2: <K1 extends keyof V, K2 extends keyof V[K1]>(
    k1: K1,
    k2: K2,
    v: V[K1][K2],
  ) => void;
  onChangeSetValue: (v: V) => void;
  onChangeSetValueNoParse: (v: V) => void;
  onChangeMergeValue: <K extends keyof V>(
    key: K,
    noParse?: boolean,
  ) => (v: V[K]) => void;
  onChangeMergeValueNoParse: <K extends keyof V>(
    key: K,
    noParse?: boolean,
  ) => (v: V[K]) => void;
  onChangeMergeValue2: <K1 extends keyof V, K2 extends keyof V[K1]>(
    k1: K1,
    k2: K2,
  ) => (v: V[K1][K2]) => void;
}

export interface WithValue<V> extends ValueFunctions<V> {
  value: V;
}

interface FunctionComponent<T> extends React.FC<T> {
  name?: string;
}

export type ComponentWithValue<V, P> = ComponentClass<P & ValueProps<V>>;

/**
 * @deprecated We recommend to not use it on new features and slowly replace it by `React.FC` or `React.Component`
 * For more info check the `with-value-for.ts` file.
 */
export const withValue = <V, P = unknown>(
  fn: FunctionComponent<InwardProps<V, P>>,
  name?: string,
): ComponentWithValue<V, P> => {
  class Comp extends ValueComponent<V, P> {
    static readonly displayName = name || fn.name;

    render() {
      const {
        setValue,
        mergeValue,
        onChangeSetValue,
        onChangeSetValueNoParse,
        onChangeMergeValue,
        onChangeMergeValue2,
        onChangeMergeValueNoParse,
      } = this;
      return fn(
        R.mergeRight(this.props, {
          setValue,
          mergeValue,
          onChangeSetValue,
          onChangeSetValueNoParse,
          onChangeMergeValue,
          onChangeMergeValue2,
          onChangeMergeValueNoParse,
        }) as any,
      );
    }
  }
  (Comp as any).propTypes = fn.propTypes as any;
  return Comp;
};
