import { Culture, EN_US } from "common/culture/supported-cultures";
import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";
import { getColumnName } from "common/form/reference-group/functions";
import { addFilterToQuery } from "common/query/filter";
import { hasSelectWithName } from "common/query/select";
import {
  Filter,
  FilterAnd,
  FilterField,
  isFieldRule,
  Query,
  QueryForEntity,
  SelectItem,
} from "common/query/types";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { uniqueArray } from "common/utils/array";

export const getRecordWithTitleFn =
  (entity: string, userCulture: Culture, siteCulture: Culture) =>
  (reference: any): ForeignKey => {
    const title =
      reference.title ??
      reference[getColumnName(userCulture)] ??
      reference[getColumnName(siteCulture)] ??
      reference[getColumnName(EN_US)];

    return {
      ...reference,
      entity,
      title,
    };
  };

const getQueryForMeter = (query: Query) => ({
  ...query,
  select: [...query.select, { name: "meterTypeId", alias: "subtitle" }],
  fkExpansion: true,
});

export const getReferenceQuery = (context: Context, entity: Entity): Query => {
  const { uiFormat, site } = context;
  const cultures: Culture[] = uniqueArray(
    [uiFormat.culture, site.culture, EN_US].filter((c) => c),
  ) as Culture[];

  const columnNames = cultures ? cultures.map((c) => getColumnName(c)) : [];
  const defaultColumnName = getColumnName(EN_US);

  const translatedColumns = cultures
    ? columnNames.map((name) => ({ name }))
    : [{ name: defaultColumnName, alias: "title" }];

  const defaultQuery = {
    select: [{ name: "id" }, { name: "number" }, ...translatedColumns],
    filter: { and: [{ name: "isDeleted", op: "isfalse" }] },
    order: [{ name: defaultColumnName }],
  };

  return entity && behaveAs("Meter", entity)
    ? getQueryForMeter(defaultQuery)
    : defaultQuery;
};

/**
 * Get a query to find a record by id and return in its "display" format. NOT filtering deleted.
 * @param context
 * @param entity
 * @param id Id of the record you want to expand
 */
export const getExpandReferenceFkQueryForId = (
  context: Context,
  entity: Entity,
  id: string,
): QueryForEntity => ({
  entity: entity.name,
  query: addFilterToQuery(
    { name: "id", op: "eq", value: id },
    getReferenceQuery(context, entity),
  ),
});

export const getReferenceDisplayFieldFilters = (
  context: Context,
  select: SelectItem[],
  searchTerm?: string,
) => {
  const { uiFormat, site } = context;
  const cultures = uniqueArray(
    [uiFormat.culture, site.culture, EN_US].filter((c) => c),
  ) as Culture[];
  const columnNames = cultures
    .map((c) => getColumnName(c))
    .filter((c) => hasSelectWithName(c, select));

  if (!searchTerm) {
    return [{ or: columnNames.map((f) => ({ name: f, op: "isnotnull" })) }];
  }

  return columnNames.reduce((acc: Filter[], column: string) => {
    return acc.length === 0
      ? acc.concat({
          name: column,
          op: "contains",
          value: searchTerm,
        })
      : acc.concat({
          and: [
            {
              name: column,
              op: "contains",
              value: searchTerm,
            },
            ...acc.map((f) => ({
              name: isFieldRule(f)
                ? f.name
                : ((f as FilterAnd).and[0] as FilterField).name,
              op: "isnull",
            })),
          ],
        });
  }, []);
};
