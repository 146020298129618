import { BooleanTypes } from "common/entities/entity-column/types";
import { isForeignKey } from "common/functions/foreign-key";
import { getSystemStringTranslate } from "common/functions/system-string-options";
import { DEFAULT_CURRENCY_DECIMAL_SCALE } from "common/utils/decimal";
import { getCalendarLabel } from "common/widgets/calendar-selector/label";
import { getBooleanLabel } from "common/widgets/checkbox/label";
import { getCurrencySelectorLabel } from "common/widgets/currency-selector/label";
import { getCurrencySymbol } from "common/widgets/currency/functions";
import { getCurrencyLabel } from "common/widgets/currency/label";
import { getFormattedDate } from "common/widgets/date/date-base-label";
import { getDateTimeFormat } from "common/widgets/date/date-time-label";
import { displayDateOffset } from "common/widgets/date/functions";
import { formatFk } from "common/widgets/foreign-key/functions";
import { getImageSource } from "common/widgets/image/functions";
import { replaceSeparator } from "common/widgets/number/functions";
import { getYesNoLabel } from "common/widgets/radio-button-list/yes-no";
import { getSystemIntFkLabel } from "common/widgets/system-int-foreign-key/label";
import { defaultColumn, getSiteValue } from "./functions";

import { LabelProps as PropTypes } from "./types";

// This function takes the same parameters as LabelWidget and should return
// the string values only. The differences being:
//   - No Links
//   - No Images
//   - No complex dataTypes e.g. html

export const labelFunction = (props: PropTypes): string => {
  const { context, column = defaultColumn, entityName, currencyId } = props;
  const { uiFormat, entities, calendars, currencies } = context;
  const { dataType, relatedSystem, booleanType } = column;

  const value = getSiteValue(context, column, props.value);
  const entity = entities[entityName];

  switch (dataType) {
    case "date":
      return getFormattedDate(value, uiFormat.dateFormat);
    case "datetime":
      return getFormattedDate(value, getDateTimeFormat(uiFormat));
    case "dateoffset":
      return displayDateOffset(value);
    case "currency": {
      const { decimalSeparator } = uiFormat;
      const symbol = getCurrencySymbol(currencyId, column, context);
      const decimalScale =
        column.decimalScale || DEFAULT_CURRENCY_DECIMAL_SCALE;
      return getCurrencyLabel(value, symbol, decimalSeparator, decimalScale);
    }
    case "float":
    case "ufloat":
      return replaceSeparator(value, uiFormat.decimalSeparator);
    case "bool":
      return booleanType === BooleanTypes.YesNo
        ? getYesNoLabel(value)
        : getBooleanLabel(value);
    case "image":
      return getImageSource(value);
    case "geolocation":
      return value.address;
    case "fk":
      if (!value || isForeignKey(value)) {
        return formatFk(entities)(value, false);
      }
    // fall through
    case "systemintfk":
      if (relatedSystem === "Calendars") {
        return getCalendarLabel(calendars, value);
      }
      return getSystemIntFkLabel(context.systemTables, relatedSystem, value);

    case "systemstringfk":
      if (relatedSystem === "Currencies") {
        return getCurrencySelectorLabel(currencies, value);
      }
    // fall through
    case "int":
    case "uint":
    case "hyperlink":
    case "html":
    case "signature":
    case "text":
    default:
      return getSystemStringTranslate(entity, column.name)(value);
  }
};
