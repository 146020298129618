export type RelatedFormDisplayType =
  | "actions"
  | "form"
  | "tab"
  | "table"
  | "clearable";

export type RelatedFormDisplayTypes = RelatedFormDisplayType[];

export const defaultDisplayTypes: RelatedFormDisplayTypes = [
  "actions",
  "clearable",
  "form",
  "tab",
  "table",
];
