import { ValueProps } from "common/with-value-for";

import "./index.scss";

interface PropTypes extends ValueProps<string> {
  iconClass?: string;
}

export const UserAvatar = ({ iconClass, value, onChange }: PropTypes) => {
  const handleOnError = () => onChange(undefined);
  return (
    <div className="x-user-avatar">
      {value ? (
        <img src={value} onError={handleOnError} alt={_("User Avatar")} />
      ) : (
        <i className={`fa fa-user ${iconClass}`} />
      )}
    </div>
  );
};
