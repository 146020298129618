import { Component } from "react";
import { Entities } from "common/entities/types";
import { JoinItem, JoinType } from "common/query/types";
import { ValueProps } from "common/with-value-for";

import { Join } from "./join";

interface PropTypes extends ValueProps<JoinItem[]> {
  entities: Entities;
  entityName: string;
  validJoinTypes?: JoinType[];
  oneToOneOnly?: boolean;
}

export class Joins extends Component<PropTypes> {
  static readonly displayName = "Joins";

  onChange = (joinItem: JoinItem) => {
    this.props.onChange(joinItem.joins);
  };

  render() {
    const { entities, entityName, validJoinTypes, oneToOneOnly, value } =
      this.props;
    return (
      <div className="x-query-group-base">
        <Join
          entities={entities}
          baseEntityName={entityName}
          joinEntityName={entityName}
          onRemoveGroup={undefined}
          validJoinTypes={validJoinTypes}
          oneToOneOnly={oneToOneOnly}
          value={{ column: undefined, joins: value }}
          onChange={this.onChange}
        />
      </div>
    );
  }
}
