import * as R from "ramda";
import { addDateDuration } from "common/date-time/helpers/scheduler-bryntum";
import { toSited } from "common/date-time/common";
import { searchApi } from "common/api/search";
import { isDateValid } from "common/date-time/validators";
import { isForeignKey } from "common/functions/foreign-key";
import type { QueryForEntity } from "common/query/types";
import type { Context } from "common/types/context";
import type { FkValue } from "common/types/foreign-key";
import { CancellablePromise } from "common/types/promises";
import type { Properties } from "common/types/records";
import type {
  SchedulerSettingsType,
  WorkOrderSettingsType,
} from "common/types/scheduler-settings";
import type { WorkOrderEvent } from "x/scheduler2/planner/unassigned-work/types";
import type { Resource } from "x/scheduler2/types";

export const DEFAULT_ASSIGNMENT_DURATION = 1;

export const hasFullSetup = (workOrderSettings: WorkOrderSettingsType) =>
  !!(
    workOrderSettings &&
    workOrderSettings.requirementEntityName &&
    workOrderSettings.matchingColumnName &&
    workOrderSettings.laborHoursColumnName
  );

const getLaborRequirementQuery = (
  settings: WorkOrderSettingsType,
  workOrderId: string,
  matchingValue: FkValue,
): QueryForEntity => {
  const { requirementEntityName, matchingColumnName, laborHoursColumnName } =
    settings;

  return {
    entity: requirementEntityName,
    query: {
      select: [{ name: laborHoursColumnName }],
      filter: {
        and: [
          { name: "isDeleted", op: "isfalse" },
          {
            name: "ownerId",
            op: "eq",
            value: workOrderId,
          },
          {
            name: matchingColumnName,
            op: "eq",
            value: isForeignKey(matchingValue)
              ? matchingValue.id
              : matchingValue,
          },
        ],
      },
      order: [{ name: laborHoursColumnName, desc: true }],
    },
  };
};

/**
 * Quantity comes as hours and can be both integers and floats. date-fns's `add`
 * function uses `Math.floor` for positive decimals and `Math.ceil` for negative
 * decimals. That causes values like `0.5`, that is a half an hour, to be rounded
 * to `0`, which is wrong. In order to mitigate this issue we convert hours to
 * minutes here and round up to 1 minute.
 *
 * @param event
 * @param hours
 */
export const getEventWithDuration = (event: WorkOrderEvent, hours: number) => {
  const minutes = hours * 60;
  const startDate = event.startDate;

  const endDate = addDateDuration(
    event.startDate,
    minutes > 1 ? minutes : 1,
    "minutes",
  );

  return { ...event, startDate, endDate };
};

export const getEventDuration = (
  context: Context,
  resources: ReadonlyArray<Resource>,
  settings: SchedulerSettingsType,
  event: WorkOrderEvent,
): CancellablePromise<number> => {
  const contact = R.find(
    (resource) => resource.id === event.resourceId,
    resources,
  );
  const { apiCall } = context;
  const woSettings = settings?.workOrders?.[event.workOrderEntityName];
  const userEntityName = settings?.entityName;

  if (!isDateValid(toSited(event?.startDate)))
    return CancellablePromise.reject(
      _("Invalid start date of the given event"),
    );

  if (!userEntityName || !hasFullSetup(woSettings))
    return CancellablePromise.resolve(DEFAULT_ASSIGNMENT_DURATION);

  const { laborHoursColumnName } = woSettings;

  return searchApi(apiCall)
    .runExactQuery(
      getLaborRequirementQuery(
        woSettings,
        event.workOrderId,
        contact.matchingColumn,
      ),
    )
    .then(
      (data: Properties[]) =>
        data?.[0]?.[laborHoursColumnName] ?? DEFAULT_ASSIGNMENT_DURATION,
    )
    .catch(() => DEFAULT_ASSIGNMENT_DURATION);
};
