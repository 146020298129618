import * as R from "ramda";
import * as React from "react";
import {
  formatLongDate,
  getHumanizedDifferenceFromNow,
} from "common/date-time/formatters";

interface PropTypes {
  value: string;
  className?: string;
}

declare const NO_TIMER: string;

type TimeAgoComp = React.Component<PropTypes>;

const items: TimeAgoComp[] = [];

const subscribe = (comp: TimeAgoComp) => items.push(comp);
const unsubscribe = (comp: TimeAgoComp) => {
  const i = items.indexOf(comp);
  if (i !== -1) items.splice(i, 1);
};
const notify = () => R.forEach((c) => c.forceUpdate(), items);

if (typeof NO_TIMER === "undefined") {
  setInterval(notify, 1000 * 60); // every minute
}

export class TimeAgo extends React.Component<PropTypes> {
  static readonly displayName = "TimeAgo";
  componentDidMount() {
    subscribe(this);
  }
  componentWillUnmount() {
    unsubscribe(this);
  }
  render() {
    const { value, className = "" } = this.props;
    if (!value) return null;

    const time = formatLongDate(value);
    const timeAgo = getHumanizedDifferenceFromNow(value);

    return (
      <div className={className} title={time}>
        {timeAgo}
      </div>
    );
  }
}
