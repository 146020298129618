import * as R from "ramda";
import { DataType } from "common/entities/entity-column/data-type/types";

type OperatorType =
  | "string"
  | "order"
  | "boolean"
  | "optional"
  | "date"
  | "datetime"
  | "uniqueidentifier";

type OperatorName =
  | "eq"
  | "neq"
  | "gt"
  | "gte"
  | "lt"
  | "lte"
  | "exists"
  | "notexists"
  | "contains"
  | "doesnotcontain"
  | "startswith"
  | "doesnotstartwith"
  | "endswith"
  | "doesnotendwith"
  | "isempty"
  | "any"
  | "all"
  | "isnull"
  | "isnotnull"
  | "istrue"
  | "isfalse"
  | "in"
  | "notin"
  | "dayeq"
  | "dayneq"
  | "daygt"
  | "daygte"
  | "daylt"
  | "daylte"
  | "daytimeeq"
  | "daytimeneq"
  | "daytimegt"
  | "daytimegte"
  | "daytimelt"
  | "daytimelte";

export type SubQueryOperatorName = "exists" | "notexists";

export interface Operator {
  name: OperatorName;
  type: OperatorType;
  label: string;
  icon: string;
  unary?: boolean;
}

export interface SubQueryOperator extends Omit<Operator, "name"> {
  name: SubQueryOperatorName;
}

export interface OperatorDef extends Operator {
  include?: DataType[];
  exclude?: DataType[];
}

const idType: DataType[] = ["uniqueidentifier"];
const stringTypes: DataType[] = [
  "string",
  "email",
  "hyperlink",
  "text",
  "document",
];
const boolTypes: DataType[] = ["bool"];
const dateTypes: DataType[] = ["date"];
const dateTimeTypes: DataType[] = ["datetime"];
const orderedTypes: DataType[] = stringTypes.concat([
  "int",
  "uint",
  "float",
  "ufloat",
  "currency",
]);

export const operators = (): OperatorDef[] => [
  {
    name: "eq",
    label: _("Is equal to"),
    icon: "=",
    type: "order",
    exclude: boolTypes.concat(dateTypes, dateTimeTypes),
  },
  {
    name: "neq",
    label: _("Is not equal to"),
    icon: "!=",
    type: "order",
    exclude: boolTypes.concat(dateTypes, dateTimeTypes),
  },
  {
    name: "gt",
    label: _("Is greater than"),
    icon: ">",
    type: "order",
    include: orderedTypes,
  },
  {
    name: "gte",
    label: _("Is greater than or equal to"),
    icon: ">=",
    type: "order",
    include: orderedTypes,
  },
  {
    name: "lt",
    label: _("Is less than"),
    icon: "<",
    type: "order",
    include: orderedTypes,
  },
  {
    name: "lte",
    label: _("Is less than or equal to"),
    icon: "<=",
    type: "order",
    include: orderedTypes,
  },
  {
    name: "contains",
    label: _("Contains"),
    icon: "∋",
    type: "string",
    include: stringTypes.concat(idType),
  },
  {
    name: "doesnotcontain",
    label: _("Does not contain"),
    icon: "∌",
    type: "string",
    include: stringTypes.concat(idType),
  },
  {
    name: "startswith",
    label: _("Starts with"),
    icon: "^",
    type: "string",
    include: stringTypes.concat(idType),
  },
  {
    name: "doesnotstartwith",
    label: _("Does not start with"),
    icon: "!^",
    type: "string",
    include: stringTypes.concat(idType),
  },
  {
    name: "endswith",
    label: _("Ends with"),
    icon: "$",
    type: "string",
    include: stringTypes.concat(idType),
  },
  {
    name: "doesnotendwith",
    label: _("Does not end with"),
    icon: "!$",
    type: "string",
    include: stringTypes.concat(idType),
  },
  {
    name: "isempty",
    label: _("Is empty"),
    icon: "∅",
    unary: true,
    type: "string",
    include: stringTypes,
  },
  {
    name: "any",
    label: _("Contains any word"),
    icon: "∃",
    type: "string",
    include: stringTypes,
  },
  {
    name: "all",
    label: _("Contains all words"),
    icon: "∀",
    type: "string",
    include: stringTypes,
  },
  {
    name: "isnull",
    label: _("Is null"),
    icon: "N",
    type: "optional",
    unary: true,
  },
  {
    name: "isnotnull",
    label: _("Is not null"),
    icon: "!N",
    type: "optional",
    unary: true,
  },
  {
    name: "istrue",
    label: _("Is true"),
    icon: "T",
    include: boolTypes,
    type: "boolean",
    unary: true,
  },
  {
    name: "isfalse",
    label: _("Is false"),
    icon: "F",
    include: boolTypes,
    type: "boolean",
    unary: true,
  },
  {
    name: "dayeq",
    label: _("Date equals"),
    icon: "D=",
    type: "date",
    include: dateTypes.concat(dateTimeTypes),
  },
  {
    name: "dayneq",
    label: _("Date not equals"),
    icon: "D!=",
    type: "date",
    include: dateTypes.concat(dateTimeTypes),
  },
  {
    name: "daygt",
    label: _("After date"),
    icon: "D>",
    type: "date",
    include: dateTypes.concat(dateTimeTypes),
  },
  {
    name: "daygte",
    label: _("Same date or after"),
    icon: "D>=",
    type: "date",
    include: dateTypes.concat(dateTimeTypes),
  },
  {
    name: "daylt",
    label: _("Before date"),
    icon: "D<",
    type: "date",
    include: dateTypes.concat(dateTimeTypes),
  },
  {
    name: "daylte",
    label: _("Same date or before"),
    icon: "D<=",
    type: "date",
    include: dateTypes.concat(dateTimeTypes),
  },
  {
    name: "daytimeeq",
    label: _("Date and time equals"),
    icon: "DT=",
    type: "datetime",
    include: dateTimeTypes,
  },
  {
    name: "daytimeneq",
    label: _("Date and time not equals"),
    icon: "DT!=",
    type: "datetime",
    include: dateTimeTypes,
  },
  {
    name: "daytimegt",
    label: _("After date and time"),
    icon: "DT>",
    type: "datetime",
    include: dateTimeTypes,
  },
  {
    name: "daytimegte",
    label: _("Same date and time or after"),
    icon: "DT>=",
    type: "datetime",
    include: dateTimeTypes,
  },
  {
    name: "daytimelt",
    label: _("Before date and time"),
    icon: "DT<",
    type: "datetime",
    include: dateTimeTypes,
  },
  {
    name: "daytimelte",
    label: _("Same date and time or before"),
    icon: "DT<=",
    type: "datetime",
    include: dateTimeTypes,
  },
  {
    name: "in",
    label: _("In"),
    icon: "In",
    type: "uniqueidentifier",
    include: idType,
  },
  {
    name: "notin",
    label: _("Not in"),
    icon: "NotIn",
    type: "uniqueidentifier",
    include: idType,
  },
];

export const getSubQueryOperators = (): SubQueryOperator[] => [
  {
    name: "exists",
    label: _("Exists"),
    icon: "∃",
    type: "boolean",
  },
  {
    name: "notexists",
    label: _("Does not exist"),
    icon: "∄",
    type: "boolean",
  },
];

export const isUnaryOperator = (name: string): boolean => {
  if (!name) return false;

  const op = R.find((o) => o.name === name, operators());
  if (!op) return false;
  return !!op.unary;
};

export const isDateOperator = (name: string): boolean =>
  operators().some((op) => op.type === "date" && op.name === name);

export const isDateTimeOperator = (name: string): boolean =>
  operators().some((op) => op.type === "datetime" && op.name === name);
