import { makeUrlString, StringObject } from "common/app/query-string";
import { Context } from "common/types/context";

export const getImageSource = (url: string, srcParameters?: StringObject) => {
  if (url.indexOf("fileName:") === 0)
    return url.substring(url.indexOf(",") + 1);
  if (url.indexOf("http") === 0) return url;
  return url + makeUrlString(srcParameters);
};

export const getSrcParameters = (context: Context) => {
  const xtoken = context?.xtoken;
  return xtoken ? { "X-Token": xtoken } : undefined;
};
