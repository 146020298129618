import * as R from "ramda";
import { defaultFor } from "common";
import { cultureApi } from "common/api/cultures";
import {
  getValidCultureCount,
  validateCaptions,
} from "common/culture/functions";
import { HasLabels, Labels, TranslateKey } from "common/culture/types";
import { merge2 } from "common/merge";
import { Context } from "common/types/context";
import { ActionButton } from "common/ui/buttons";
import { Page } from "common/widgets/simple-pagination";
import { ValueComponent, ValueProps } from "common/with-value-for";
import { getUpdatedKeys } from "./functions";
import { SourceLang } from "./source-lang";
import { TargetLang, TargetLangValue } from "./target-lang";

export interface LabelsFor<THasLabels extends HasLabels> {
  item: THasLabels;
  page: Page;
}

interface PropTypes {
  context: Context;
  allCultures: boolean;
  keys: string[];
  translateKey: TranslateKey;
  isRequired?: boolean;
  displayMissingTranslationsIcon?: boolean;
}

interface StateType {
  translating?: boolean;
  error?: any;
}

export class LabelsTable<
  THasLabels extends HasLabels,
  TValue extends LabelsFor<THasLabels>,
> extends ValueComponent<TValue, PropTypes, StateType> {
  static readonly displayName = "LabelsTable";
  state: StateType = { translating: false };

  onTranslateAll = () => {
    const { context, allCultures, keys, translateKey, value } = this.props;
    const { apiCall, cultures, uiFormat } = context;
    const { item } = value;

    const labels = item.labels || ({} as Labels);
    const captions = labels[uiFormat.culture];

    const updatedKeys = getUpdatedKeys(
      keys,
      allCultures,
      captions,
      translateKey,
    );

    const sourceLabels = R.zipObj(keys, updatedKeys);

    const culturesToTranslate = cultures.filter(
      (culture) => !validateCaptions(keys, labels[culture]),
    );

    if (culturesToTranslate.length) {
      this.setState({ translating: true });
      cultureApi(apiCall)
        .translateLanguages(sourceLabels, uiFormat.culture, culturesToTranslate)
        .then((translations) => {
          const newLabels = R.mergeDeepLeft(labels, translations);
          this.setValue(merge2("item", "labels", newLabels, value));
          this.setState({ translating: false });
        })
        .catch((error) => this.setState({ error, translating: false }));
    }
  };
  onChangeLabels = (targetLangValue: TargetLangValue) => {
    const { value } = this.props;

    this.mergeValue({
      item: { ...value?.item, labels: targetLangValue.labels },
      page: targetLangValue.page,
    } as Partial<TValue>);
  };
  render() {
    const {
      context,
      allCultures,
      keys,
      translateKey,
      value = defaultFor<TValue>(),
      isRequired,
      displayMissingTranslationsIcon = true,
    } = this.props;
    const { cultures, uiFormat, supportedCultures } = context;
    const { item = defaultFor<THasLabels>() } = value;
    const { page } = value;
    const { translating } = this.state;

    if (!cultures.length) return <span />;

    const captions = (item.labels || {})[uiFormat.culture];

    const updatedKeys = getUpdatedKeys(
      keys,
      allCultures,
      captions,
      translateKey,
    );

    const totalCultures = allCultures
      ? cultures
      : cultures.filter((c) => c !== uiFormat.culture);

    const validCultures = getValidCultureCount(
      totalCultures,
      keys,
      item.labels,
    );

    const areTranslationsComplete = validCultures === totalCultures.length;
    const exclamationIcon =
      !areTranslationsComplete && displayMissingTranslationsIcon ? (
        isRequired ? (
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
        ) : (
          <i
            className="fa fa-exclamation-triangle"
            title={_("Translations are incomplete")}
            aria-hidden="true"
          />
        )
      ) : undefined;

    return (
      <div className="x-translations">
        <h3 className="x-translations-header">
          {_("Translations")}
          {exclamationIcon}
          <div className="x-translations-stats">
            <span
              className={`x-translations-pending${
                areTranslationsComplete
                  ? " complete"
                  : isRequired
                    ? " error"
                    : " "
              }`}
            >
              {_("Completed")}: <strong>{validCultures}</strong> /{" "}
              {totalCultures.length}
            </span>
          </div>
        </h3>
        <div className="row x-translations-content">
          {validCultures < totalCultures.length ? (
            <div className="col-xs-12 x-translate-all-wrapper">
              <ActionButton
                className="qa-translate-all-cultures x-float-right"
                disabled={translating}
                onClick={this.onTranslateAll}
              >
                {translating ? (
                  <div className="x-loading-icon-wrap">
                    <i className="fa fa-spinner fa-spin x-loading-icon" />
                    {_("Translating")}
                  </div>
                ) : (
                  <div className="x-loading-icon-wrap">
                    <i className="fa fa-language" />
                    {_("Translate all")}
                  </div>
                )}
              </ActionButton>
            </div>
          ) : undefined}
          <div className="col-md-3 x-source-panel">
            <SourceLang
              culture={R.find(
                (c) => c.name === uiFormat.culture,
                supportedCultures,
              )}
              keys={updatedKeys}
            />
          </div>
          <div className="col-md-9 x-target-panel">
            <TargetLang
              context={context}
              translateKey={translateKey}
              keys={keys}
              isRequired={isRequired}
              allCultures={allCultures}
              value={{
                labels: item.labels || {},
                page,
              }}
              onChange={this.onChangeLabels}
            />
          </div>
        </div>
      </div>
    );
  }
}

export abstract class LabelsComponent<
  THasLabels extends HasLabels,
  TValue extends LabelsFor<THasLabels>,
  TPropTypes,
  TStateType = unknown,
> extends ValueComponent<TValue, TPropTypes, TStateType> {
  mergeLabels: (labels: Labels) => TValue;
  mergeItem: (item: THasLabels) => TValue;
  onChangeItem: (value: THasLabels) => any;

  constructor(
    props: TPropTypes & ValueProps<TValue>,
    updateLabels: (v: THasLabels) => THasLabels,
  ) {
    super(props);

    this.mergeLabels = (labels: Labels): TValue => ({
      ...this.props.value,
      item: { ...this.props.value.item, labels },
    });

    this.mergeItem = (item: THasLabels): TValue => ({
      ...this.props.value,
      item: updateLabels(item),
    });

    this.onChangeItem = (value: THasLabels) =>
      this.mergeValue({
        item: updateLabels(value),
      } as Partial<TValue>);

    this.onChangeSetValue = (value: TValue) =>
      this.setValue({
        ...value,
        item: updateLabels(value.item),
      });
  }
}
