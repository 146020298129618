import { EntityColumn } from "common/entities/entity-column/types";
import { useExpandedRecord } from "common/record/foreign-key-expansion";
import { Context } from "common/types/context";
import { FkValue } from "common/types/foreign-key";
import { HighlightProps } from "common/widgets/label";
import { InternalFormatted } from "./formatted";
import { FkFormat } from "./types";

export interface PropTypes extends HighlightProps {
  context: Context;
  column: EntityColumn;
  value: FkValue; // can be expanded or not
  format: FkFormat;
}

/**
 * Internal (do not reuse). Use the generic index.
 *
 * This component gets a foreign key and expand it if necessary.
 */
export const SelfExpandingFk = ({
  context,
  column,
  value,
  format,
  textToHighlight,
}: PropTypes) => {
  const expandedRecord = useExpandedRecord({
    context,
    entityName: column?.relatedEntity,
    record: value,
  });

  return (
    <InternalFormatted
      context={context}
      column={column}
      value={expandedRecord ?? value}
      format={format}
      textToHighlight={textToHighlight}
    />
  );
};
