import * as R from "ramda";
import { getLocalizedName } from "common";
import { Entity } from "common/entities/types";
import { Header } from "common/form/types";
import { Properties } from "common/types/records";
import { getColumnType } from "../utils";

export interface Title {
  link?: string;
  value: any;
}

export interface Subtitle {
  label?: string;
  value: any;
}

export interface Id {
  type: string;
  label?: string;
  value: any;
}

interface HeaderInfo {
  title: Title;
  subtitle: Subtitle;
  id: Id;
  image: string;
}

export const getHeaderInfo = (
  properties: Properties,
  entity: Entity,
  layout: Header,
): HeaderInfo => {
  const { title, subtitle, image, id } = layout;
  const subtitleColumn = R.find((c) => c.name === subtitle, entity.columns);

  return {
    title: {
      value: properties && properties[title],
    },
    subtitle: subtitleColumn && {
      label: getLocalizedName(subtitleColumn),
      value: properties && properties[subtitle],
    },
    id: {
      type: getColumnType(id, entity.columns),
      label: id,
      value: properties && properties[id],
    },
    image: properties && properties[image],
  };
};
