import { defaultFor } from "common";
import { recordsApi } from "common/api/records";
import { PropTypes } from "common/record/actions/types";
import {
  ApproveDependencies,
  ApproveValue,
} from "common/record/actions/ui/approve/types";
import { DependenciesComp } from "common/with-dependencies";
import { withValue, WithValue } from "common/with-value";
import { ApproveAction } from "./action";

const defaultValue = defaultFor<ApproveValue>();

type Props = PropTypes & WithValue<ApproveValue>;

export const Approve = withValue<ApproveValue, PropTypes>((props: Props) => {
  const { context, entity, records = [], value = defaultValue } = props;
  const id = records[0]?.properties?.id;
  const fullRecord = recordsApi(context.apiCall).get(entity.name, id, false);

  return (
    <DependenciesComp<ApproveDependencies>
      dependencies={{ fullRecord }}
      child={
        <ApproveAction
          {...props}
          value={value}
          onChange={props.onChangeSetValue}
        />
      }
    />
  );
}, "Approve");
