import { Interval, isFuture, isPast, isValid, parseISO } from "date-fns";
import * as R from "ramda";
import { GenericDateTime, UTCDateTime } from "common/date-time/types";
import { WeekdayNumber, Weekdays } from "common/date-time/weekday";

export const isAfter = (baseDate: UTCDateTime, dateToCompare: UTCDateTime) =>
  !!(baseDate && dateToCompare && parseISO(baseDate) > parseISO(dateToCompare));

export const isSameOrAfter = (
  baseDate: UTCDateTime,
  dateToCompare: UTCDateTime,
) =>
  !!(
    baseDate &&
    dateToCompare &&
    parseISO(baseDate) >= parseISO(dateToCompare)
  );

export const isBefore = (baseDate: UTCDateTime, dateToCompare: UTCDateTime) =>
  !!(baseDate && dateToCompare && parseISO(baseDate) < parseISO(dateToCompare));

export const isSameOrBefore = (
  baseDate: UTCDateTime,
  dateToCompare: UTCDateTime,
) =>
  !!(
    baseDate &&
    dateToCompare &&
    parseISO(baseDate) <= parseISO(dateToCompare)
  );

export const isBeforeNow = (dateToCompare: UTCDateTime) =>
  !!(dateToCompare && isPast(parseISO(dateToCompare)));

export const isAfterNow = (dateToCompare: UTCDateTime) =>
  !!(dateToCompare && isFuture(parseISO(dateToCompare)));

export const isDateValid = (date: GenericDateTime) =>
  !!date && isValid(parseISO(date));

export const isRangeValid = (start: GenericDateTime, end: GenericDateTime) =>
  !!(start && end && isBefore(start, end));

export const isIntervalValid = (interval: Interval) =>
  isValid(interval.start) &&
  isValid(interval.end) &&
  interval.start < interval.end;

export const isDayOfWeekNumber = (n: number): n is WeekdayNumber =>
  R.includes(n, R.values(Weekdays));
