import { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getLocalizedName, toKebabCase } from "common";
import { Context } from "common/types/context";
import { Tabs } from "common/widgets/tabs/tabs";
import { Tab } from "common/widgets/tabs/tab";
import { LoadingIcon } from "common/widgets/loading-icon";
import { ApiError } from "common/ui/api-error";
import { ActionButtonSmall } from "common/ui/buttons";
import { GoFn } from "common/types/url";
import { globalSearchApi } from "common/api/global-search";
import { SearchRecord, SearchSummary } from "./types";
import { getScrollHeight, getTabLabel } from "./functions";
import { EntitySearchItem } from "./searched-list-item/entity-item";
import { DocumentSearchItem } from "./searched-list-item/document-item";

const ALL_TAB = "all";

interface PropTypes {
  context: Context;
  searchValue: string;
  onClickRecord: () => void;
  goTo: GoFn;
}

interface StateType {
  searchedRecords: SearchRecord[];
  summary: SearchSummary[];
  selectedTab: string;
  loading?: boolean;
  error?: any;
  nextPage?: number;
}

export class SearchRecordsWithTabs extends Component<PropTypes> {
  static readonly displayName = "SearchRecordsWithTabs";
  state: StateType = {
    searchedRecords: [],
    summary: [],
    selectedTab: ALL_TAB,
    loading: true,
    nextPage: undefined,
    error: undefined,
  };

  componentDidMount() {
    this.loadSummary();
    this.loadResultsForEntity(ALL_TAB);
  }

  componentDidUpdate(prevProps: PropTypes) {
    if (prevProps.searchValue !== this.props.searchValue) {
      this.loadSummary();
      this.loadResultsForEntity(this.state.selectedTab);
    }
  }

  loadSummary = () => {
    const { context, searchValue } = this.props;

    this.setState({ loading: true });
    globalSearchApi(context.apiCall)
      .searchSummary(searchValue)
      .then((response: SearchSummary[] = []) =>
        this.setState({ loading: false, summary: response, error: undefined }),
      )
      .catch((error) => this.setState({ loading: false, error }));
  };

  loadResultsForEntity = (entity: string) => {
    const { context, searchValue } = this.props;

    this.setState({ loading: true, searchedRecords: [] });
    globalSearchApi(context.apiCall)
      .searchListByEntity(searchValue, entity, 0)
      .then((paginationValue) => {
        this.state.selectedTab === ALL_TAB || paginationValue.data.length
          ? this.setState({
              loading: false,
              searchedRecords: paginationValue.data ?? [],
              nextPage: paginationValue.next ?? undefined,
              error: undefined,
            })
          : this.onChangeTab(ALL_TAB);
      })
      .catch((error) => this.setState({ loading: false, error }));
  };

  loadNextResultsForEntity = () => {
    const { context, searchValue } = this.props;
    const { searchedRecords, selectedTab, nextPage } = this.state;

    if (nextPage === undefined) return;

    this.setState({ loading: true });
    globalSearchApi(context.apiCall)
      .searchListByEntity(searchValue, selectedTab, nextPage)
      .then((paginationValue) =>
        this.setState({
          loading: false,
          searchedRecords: searchedRecords.concat(paginationValue.data ?? []),
          nextPage: paginationValue.next ?? undefined,
          error: undefined,
        }),
      )
      .catch((error) => this.setState({ loading: false, error }));
  };

  onChangeTab = (tab: string) => {
    this.setState({ selectedTab: tab });
    this.loadResultsForEntity(tab);
  };

  onClickGoToAll = () => {
    const { context, goTo, onClickRecord } = this.props;
    const { selectedTab } = this.state;

    goTo(`/#/${context.site.name}/${selectedTab}`);
    onClickRecord();
  };

  hasMore = () => {
    return this.state.nextPage !== undefined;
  };

  renderListItem = (record: SearchRecord) => {
    const { context, searchValue, onClickRecord } = this.props;
    const props = {
      key: `${record.id}-${toKebabCase(record.title)}`,
      context,
      record,
      textToHighlight: searchValue,
      onClickRecord,
    };

    switch (record.entity) {
      case "Documents":
        return <DocumentSearchItem {...props} />;
      default:
        return <EntitySearchItem {...props} />;
    }
  };

  render() {
    const { context } = this.props;
    const { loading, error, searchedRecords, summary, selectedTab } =
      this.state;

    const displayGoToAll = (entity: string) =>
      selectedTab !== ALL_TAB && !context.entities[entity]?.isSystem;

    return (
      <>
        {loading ? <LoadingIcon /> : undefined}
        <div
          className={`x-global-search-results ${
            loading ? "x-loading-margin" : ""
          }`}
        >
          {error ? <ApiError error={error} /> : undefined}
          {summary.length ? (
            <Tabs
              key="x-search-results-tabs"
              className="x-padding-10"
              defaultValue={ALL_TAB}
              parentExpectedTab={selectedTab}
              onChange={this.onChangeTab}
            >
              {summary.map((s) => (
                <Tab
                  key={s.entity}
                  value={s.entity}
                  label={getTabLabel(context, s.entity)}
                  badge={s.count.toLocaleString()}
                >
                  <InfiniteScroll
                    next={this.loadNextResultsForEntity}
                    hasMore={this.hasMore()}
                    loader={undefined}
                    dataLength={searchedRecords.length}
                    height={getScrollHeight(s.count)}
                  >
                    {searchedRecords.map((record) =>
                      this.renderListItem(record),
                    )}
                  </InfiniteScroll>
                  {displayGoToAll(s.entity) ? (
                    <div className="x-show-all qa-show-all">
                      <hr />
                      <ActionButtonSmall onClick={this.onClickGoToAll}>
                        {_("Go to all {ENTITY_NAME}").replace(
                          "{ENTITY_NAME}",
                          getLocalizedName(context.entities[selectedTab]),
                        )}
                      </ActionButtonSmall>
                    </div>
                  ) : undefined}
                </Tab>
              ))}
            </Tabs>
          ) : (
            !loading && (
              <div className="x-no-results x-padding-10 qa-no-results">
                <i className="fa fa-times-circle-o" aria-hidden={true} />
                {_("We could not find anything matching your search.")}
              </div>
            )
          )}
        </div>
      </>
    );
  }
}
