import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { SITE_DATA_VIEW } from "common/types/view";
import { Modal } from "common/widgets/modal";
// eslint-disable-next-line import/no-cycle
import { LazyRecordFilterContent } from "common/widgets/record-filter/modal";
import { ColumnForeignKey } from "common/widgets/record-filter/types";
import { ValueComponent } from "common/with-value-for";

interface PropTypes {
  context: Context;
  entity: Entity;
  disabled?: boolean;
}

interface StateType {
  showFilters?: boolean;
  filters?: ColumnForeignKey[];
}

export class RecordFilter extends ValueComponent<
  ColumnForeignKey[],
  PropTypes,
  StateType
> {
  static readonly displayName = "RecordFilter";
  state: StateType = {
    showFilters: false,
    filters: undefined,
  };

  onClickFilter = () => {
    this.setState({ showFilters: true, filters: this.props.value });
  };

  onCancelClick = () => {
    this.setState({ showFilters: false, filters: this.props.value });
  };

  onOkClick = () => {
    this.setState({ showFilters: false });
    this.setValue(this.state.filters);
  };

  render() {
    const { entity, context, disabled, value } = this.props;
    const { showFilters, filters } = this.state;
    const { joins } = entity;

    const outboundJoins = joins.filter(
      (j) =>
        j.outbound &&
        j.entityName !== entity.localizedName &&
        j.entityName !== SITE_DATA_VIEW,
    );

    if (!outboundJoins.length) return null;

    const cn = value ? "x-with-filter" : "";

    return (
      <div className="x-record-filter">
        <button
          className={`btn x-record-filter-button ${cn}`}
          title={_("Filter")}
          disabled={disabled}
          onClick={this.onClickFilter}
        >
          <i className="fa fa-filter" />
        </button>
        {showFilters ? (
          <Modal
            content={
              <LazyRecordFilterContent
                context={context}
                entity={entity}
                outboundJoins={outboundJoins}
                value={filters}
                onChange={this.onChangeMergeState("filters")}
              />
            }
            onOk={this.onOkClick}
            onCancel={this.onCancelClick}
          />
        ) : undefined}
      </div>
    );
  }
}
