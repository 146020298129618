import { RimeRankingUpdatedEvent } from "common/types/events";
import { Row } from "common/record/form/content/audit-trail/event-detail/row";

interface PropTypes {
  event: RimeRankingUpdatedEvent;
}

export const RimeRankingUpdated = ({ event }: PropTypes) => (
  <>
    <Row key="updated" label={_("Updated Rime Ranking")}>
      {event.details?.newValue}
    </Row>
    <hr />
    <Row key="previous" label={_("Previous Rime Ranking")}>
      {event.details?.oldValue}
    </Row>
  </>
);

RimeRankingUpdated.displayName = "RimeRankingUpdated";
