import * as R from "ramda";
import { defaultFor } from "common";
import { KeysOf, Properties, Record } from "common/types/records";
import { createQueryString } from "common/app/query-string";
import { getUrl } from "common/entities";
import { looseColumnNameCheck } from "common/entities/entity-column/functions";
import { Entity } from "common/entities/types";
import { filterFormsByEntity } from "common/functions/forms";
import { merge1 } from "common/merge";
import { Context } from "common/types/context";
import { GoFn } from "common/types/url";

export const getMatchingProperty = (
  properties: Properties,
  columnName: string,
): Properties => {
  const propertyName = R.find(
    (name) => looseColumnNameCheck(name, columnName),
    R.keys(properties) as KeysOf<Properties>,
  );
  return properties?.[propertyName];
};

export const getTransferableProperties = (
  entity: Entity,
  properties: Properties,
): Properties => {
  if (!entity || !properties) return undefined;

  const { columns } = entity;

  const recordProperties = columns.reduce((acc, col) => {
    const value = getMatchingProperty(properties, col.name);
    return R.isNil(value) || col.readOnly || col.unique
      ? acc
      : { ...acc, [col.name]: value };
  }, defaultFor<Properties>());

  const withTaskProperties = (R.keys(properties) as KeysOf<Properties>).reduce(
    (acc, propertyName) => {
      const value = properties[propertyName];
      return R.isNil(value) || propertyName.indexOf("$entity_") !== 0
        ? acc
        : { ...acc, [propertyName]: value };
    },
    recordProperties,
  );

  return withTaskProperties;
};

export const getScheduledWorkOrderAssetIds = (
  scheduledWorkOrderEntity: Entity,
  record: Record,
): string[] => {
  const eventAssetEntity =
    scheduledWorkOrderEntity?.arguments?.eventAssetEntity;
  const relatedEntity = record?.related?.[eventAssetEntity] || [];
  return relatedEntity.map((r) => r.properties.assetId.id);
};

export const getUrlFormId = (
  context: Context,
  entityName: string,
  formId: number,
): string => {
  const { site, forms, entities } = context;
  const entity = entities[entityName];
  const url = getUrl(entity, site && site.name);

  const entityForms = filterFormsByEntity(forms, entity && entity.name);

  const form =
    formId || entityForms.length ? `/${formId || entityForms[0].id}` : "";

  return `${url}/new${form}`;
};

export const redirect = (
  context: Context,
  properties: Properties,
  formId: number,
  entity: Entity,
  extraProperties: Properties = {},
  goTo: GoFn,
) => {
  if (!properties || !entity?.columns) return;

  const urlFormId = getUrlFormId(context, entity?.name, formId);
  const queryString = createQueryString({
    defaultProperties: {
      ...getTransferableProperties(entity, properties),
      ...extraProperties,
    },
  });

  goTo(`${urlFormId}${queryString}`);
};

export const getRedirectUrl = (
  context: Context,
  properties: Properties,
  formId: number,
  entity: Entity,
) => {
  const id = properties?.id;
  const transferableProperties = getTransferableProperties(entity, properties);
  const defaultProperties = merge1("requestId", id, transferableProperties);
  const qs = createQueryString({ defaultProperties });
  return id ? `${getUrlFormId(context, entity.name, formId)}${qs}` : "/";
};
