import * as R from "ramda";
import { isSystemIntFk } from "common/functions/system-int";
import { Calendar } from "common/types/calendars";
import { SystemIntFkValue } from "common/types/system-int";
import { HighlightProps, Label } from "../label";
import { Link } from "../link";

interface PropTypes extends HighlightProps {
  calendars: Calendar[];
  withLinks: boolean;
  site: string;
  value: SystemIntFkValue;
}

export const getCalendarLabel = (
  calendars: Calendar[],
  value: SystemIntFkValue,
) => {
  if (isSystemIntFk(value)) {
    return value.title;
  }
  const calendar = R.find((c) => c.id === value, calendars);
  return calendar ? calendar.label : value ? `${value}` : "";
};

export const CalendarLabel = ({
  calendars,
  site,
  withLinks,
  value,
  textToHighlight,
}: PropTypes) => {
  const id = isSystemIntFk(value) ? value.id : value;
  const calendarLabel = getCalendarLabel(calendars, value);

  const label = (
    <Label value={calendarLabel} textToHighlight={textToHighlight} />
  );

  return withLinks ? (
    <Link className="x-link-calendar" href={`/#/${site}/admin/Calendars/${id}`}>
      {label}
    </Link>
  ) : (
    label
  );
};
CalendarLabel.displayName = "CalendarLabel";
