import * as R from "ramda";
import { UserType } from "common/functions/users";
import { Action, Permissions, Role } from "common/types/roles";
import { isAdminUserType } from "common/types/users";

const ANY = "any";
const ALL = "*";
const ADMIN_ROLE_ID = 1;

export const getSystemAdministratorRole = (roles: Role[] = []) =>
  roles.find((role) => role.id === ADMIN_ROLE_ID);

export const isRoleWithFullPermissions = (role: Role) =>
  R.equals(role?.permissions?.[ALL], [ALL]);

const isPermissionIn = <T>(permission: T, allPermissions: T[]) =>
  [permission, ALL].some((p) => R.includes(p, allPermissions));

const getPermittedCategories = (permissions: Permissions) =>
  R.keys(permissions);

const hasPermissionToCategory = (permissions: Permissions, category: string) =>
  isPermissionIn(category, getPermittedCategories(permissions));

const getActionsForCategory = (category: string, permissions: Permissions) =>
  R.unnest(R.values(R.pick([category, ALL], permissions)));

const hasPermissionToAction = (
  permissions: Permissions,
  category: string,
  action: Action,
) => isPermissionIn(action, getActionsForCategory(category, permissions));

/**
 * Checks if the role has permission to action for category.
 * It's used for cases when iterating all roles to check their permissions.
 * @param {Role} role
 * @param {string} category Can be entity or All (*) or Any
 * @param {Action} action
 */
export const hasRolePermissionTo = (
  role: Role,
  category: string,
  action: Action,
) => {
  if (!role) return false;

  if (category === ANY) return true;

  const permissions = role?.permissions || {};

  return action === "Read"
    ? hasPermissionToCategory(permissions, category)
    : hasPermissionToAction(permissions, category, action);
};

/**
 * Checks if logged in user with userTypes and role has permission to action for category.
 * @param {UserType[]} userTypes
 * @param {Role} role
 * @param {string} category Can be entity or All (*) or Any
 * @param {Action} action
 */
export const hasPermissionTo = (
  userTypes: UserType[],
  role: Role,
  category: string,
  action: Action,
) => isAdminUserType(userTypes) || hasRolePermissionTo(role, category, action);

/**
 * Wrapper function to check if logged in user with userTypes and role has Read permission for category.
 * @param {UserType[]} userTypes
 * @param {Role} role
 * @param {string} category Can be entity or All (*) or Any
 */
export const hasPermissionToRead = (
  userTypes: UserType[],
  role: Role,
  category: string,
) => hasPermissionTo(userTypes, role, category, "Read");

/**
 * Wrapper function to check if logged in user with userTypes and role has Create permission for category.
 * @param {UserType[]} userTypes
 * @param {Role} role
 * @param {string} category Can be entity or All (*) or Any
 */
export const hasPermissionToCreate = (
  userTypes: UserType[],
  role: Role,
  category: string,
) => hasPermissionTo(userTypes, role, category, "Create");

/**
 * Wrapper function to check if logged in user with userTypes and role has Update permission for category.
 * @param {UserType[]} userTypes
 * @param {Role} role
 * @param {string} category Can be entity or All (*) or Any
 */
export const hasPermissionToUpdate = (
  userTypes: UserType[],
  role: Role,
  category: string,
) => hasPermissionTo(userTypes, role, category, "Update");

/**
 * Wrapper function to check if logged in user with userTypes and role has Delete permission for category.
 * @param {UserType[]} userTypes
 * @param {Role} role
 * @param {string} category Can be entity or All (*) or Any
 */
export const hasPermissionToDelete = (
  userTypes: UserType[],
  role: Role,
  category: string,
) => hasPermissionTo(userTypes, role, category, "Delete");

/**
 * Wrapper function to check if logged in user with userTypes and role has actions permissions for category.
 * @param {UserType[]} userTypes
 * @param {Role} role
 * @param {string} category Can be entity or All (*) or Any
 * @param {Action[]} actions
 */
export const hasPermissionsTo = (
  userTypes: UserType[],
  role: Role,
  category: string,
  actions: Action[] = [],
) =>
  actions.every((action) => hasPermissionTo(userTypes, role, category, action));

export const getFullPermissionRoleIds = (roles: Role[] = []) =>
  roles.filter(isRoleWithFullPermissions).map((r) => r.id);

export const filterRolesByIds = (roles: Role[] = [], ids: number[] = []) =>
  roles.filter((r) => R.includes(r.id, ids));
