import * as R from "ramda";
import { MapLayoutFn } from "common/form/types";
import { RelatedForm } from "common/record/types";
import { Properties, Record } from "common/types/records";
import type { Injected, RecordType } from "./types";

export function injected<T extends RelatedForm>(): Injected<T> {
  return {
    layout: undefined,
    custom: undefined,
    onSave: undefined,
    value: undefined,
    onChange: undefined,
  };
}

export const withMapper = (a: MapLayoutFn, b: MapLayoutFn): MapLayoutFn =>
  R.compose(a || R.identity(a), b || R.identity(b));

export const withForm = (a: Properties, b: Properties): Properties =>
  R.mergeRight(a, b) as Properties;

export const normalizeIds = <T extends RecordType>(records: T[] = []): T[] =>
  records.map((r, i) =>
    !r.properties.id ? R.assocPath(["properties", "tempId"], i + 1, r) : r,
  );

export const updateRecords = (
  records: Record[],
  index: number,
  updateRecordFn: (record: Record) => Record,
): Record[] => {
  const updatedRecords =
    index >= 0
      ? R.update(index, updateRecordFn(records[index]), records)
      : R.append(updateRecordFn(undefined), records);

  return normalizeIds(updatedRecords);
};

export const deleteTempRecord = (
  records: Record[],
  tempId: string,
): Record[] => {
  const index = R.findIndex((r) => r.properties.tempId === tempId, records);

  return R.remove<Record>(index, 1, records);
};
