import * as R from "ramda";
import { MediaFile } from "common/record/form/content/media/file";
import {
  getFileDetails,
  isPendingUpload,
} from "common/widgets/document/functions";
import { arrayToString } from "common/utils/array";
import { AttachmentType, getAttachmentType } from "./functions";

interface Props {
  attachments: string[];
  className?: string;
  allowClear?: boolean;
  onRemove?: (url: string) => void;
}

export const Preview = ({
  className,
  allowClear = false,
  onRemove,
  attachments = [],
}: Props) => {
  const groupedAttachments = R.groupBy(getAttachmentType, attachments);
  const types = Object.keys(groupedAttachments);

  return (
    <>
      {types.map((type: AttachmentType) => {
        const values = groupedAttachments[type];
        const classes = arrayToString([`x-${type}-attachments`, className]);

        const showFileInformation = type !== "file";
        return (
          <div key={type} className={classes}>
            {values.map((url) => {
              const file = getFileDetails(url);

              return (
                <div key={url}>
                  <div className="x-attachment x-image-with-upload">
                    <MediaFile
                      file={file}
                      isClickable={!isPendingUpload(url)}
                      showFileInformation={showFileInformation}
                    />
                    {file && allowClear && onRemove ? (
                      <div
                        className="x-btn-delete-wrapper"
                        onClick={() => onRemove(url)}
                      >
                        <i className="fa fa-trash" />
                      </div>
                    ) : undefined}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

Preview.displayName = "Preview";
