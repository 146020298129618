import { WorkingMode } from "devexpress-dashboard";
import { Context } from "common/types/context";
import { DevExpressDashboard } from "common/types/dashboards";
import { LinkButtonSmall } from "common/ui/buttons";
import { DeleteDashboard } from "./delete";
import { ModeSwitcher } from "./mode-switcher";
import { Settings } from "./settings";

interface PropTypes {
  context: Context;
  dashboard: DevExpressDashboard;
  workingMode: WorkingMode;
  dashboardCanBeEdited: boolean;
  toggleMode: () => void;
  onDashboardUpdated: () => void;
  onDashboardDeleted: () => void;
}

export const Ribbon = ({
  context,
  dashboard,
  workingMode,
  dashboardCanBeEdited,
  toggleMode,
  onDashboardUpdated,
  onDashboardDeleted,
}: PropTypes) => {
  return (
    <div className="x-devexpress-ribbon x-flex-end-center x-padding-10 x-margin-right-10">
      <ModeSwitcher
        dashboardCanBeEdited={dashboardCanBeEdited}
        workingMode={workingMode}
        toggleMode={toggleMode}
      />
      {dashboard && workingMode === "Designer" && dashboardCanBeEdited ? (
        <>
          <Settings
            context={context}
            dashboard={dashboard}
            onDashboardUpdated={onDashboardUpdated}
          />
          <DeleteDashboard
            context={context}
            dashboard={dashboard}
            onDashboardDeleted={onDashboardDeleted}
          />
        </>
      ) : null}

      {dashboard && (
        <LinkButtonSmall
          className="qa-popout-dashboard"
          href={`#/${context.site.name}/dashboard/${dashboard.id}?tab=devexpress&hidePanels=true`}
          target="_blank"
          title={_("Preview dashboard in a new tab")}
        >
          <i className="fa fa-share-square-o" />
        </LinkButtonSmall>
      )}
    </div>
  );
};
