import { Component } from "common/component";

interface PropTypes {
  data: any;
  callback: (data: any, index?: number) => void;
  className?: string;
  title?: string;
  index?: number;
  children?: any;
  disabled?: boolean;
}

export class CallbackButton extends Component<PropTypes> {
  static readonly displayName = "CallbackButton";

  onClick = () => {
    const { data, callback, index } = this.props;
    callback(data, index);
  };

  render() {
    const { className = "", title, children, disabled } = this.props;
    const disabledClass = disabled ? "x-disabled-callback" : "";

    return (
      <div
        className={`qa-callback-button ${className} ${disabledClass}`}
        onClick={!disabled ? this.onClick : undefined}
        title={title}
      >
        {children}
      </div>
    );
  }
}
