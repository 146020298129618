import * as R from "ramda";
import { getLocalizedName } from "common";
import { withDownload } from "common/api";
import { returnAndNotify } from "common/api/with-notifications";
import { getUtcNow } from "common/date-time/common";
import { Entity } from "common/entities/types";
import { LayoutActionName } from "common/form/types";
import { ActionsByRecordId, Query } from "common/query/types";
import {
  CreatePoPayload,
  RequisitioningPayload,
} from "common/record/actions/ui/requisitioning/types";
import {
  ActionPayload,
  WorkOrderActionProceduresPayload,
} from "common/record/actions/ui/work-order-action/types";
import { Event } from "common/record/form/content/related/event/check-availability/types";
import { RequisitionUpdateItem } from "common/record/form/content/related/requisitioning-item/types";
import { BulkTriggerPayload } from "common/record/actions/ui/trigger/types";
import { flattenValues, replaceUndefined } from "common/record/utils";
import { ApiCall, Crud, RequestOptions } from "common/types/api";
import { CancellablePromise } from "common/types/promises";
import {
  Adjustment,
  Email,
  FkEventValues,
  Properties,
  Record,
  RecordPayload,
  RelatedPayload,
  RelatedRecords,
  SiteRecordIds,
  Transfer,
} from "common/types/records";

export const isPropertiesObject = (value: any): value is Properties =>
  R.is(Object, value);

const formatRecord = (r: Record): RecordPayload => ({
  properties: flattenValues(r.properties),
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  related: formatRelated(r.related),
  isNew: r.isNew,
  deleted: !!r.properties.isDeleted,
  reason: r.reason,
  byPassCheckExplicitAuthentication:
    r.byPassCheckExplicitAuthentication ?? false,
});

const formatRelated = (related: RelatedRecords): RelatedPayload =>
  R.mapObjIndexed((records: Record[]) => records.map(formatRecord), related);

const getRecordPdf =
  (apiCall: ApiCall) =>
  (entity: string, recordId: string, includeAuditTrail?: boolean) =>
    apiCall(
      "get",
      `api/entities/:site/${entity}/${recordId}/pdf${
        includeAuditTrail ? "?includeAuditTrail=true" : ""
      }`,
    );

const getBulkRecordsPdf =
  (apiCall: ApiCall) => (entity: string, recordIds: string[]) =>
    apiCall("put", `api/entities/:site/${entity}/bulk/pdf`, { recordIds });

const getRecordBarcode =
  (apiCall: ApiCall) => (entity: string, recordId: string) =>
    apiCall("get", `api/entities/:site/${entity}/${recordId}/barcode`);

const getRecordBarcodes =
  (apiCall: ApiCall) => (entity: string, recordIds: string[]) =>
    apiCall("put", `api/entities/:site/${entity}/bulk/barcode`, { recordIds });

const getRecordSystemQrCodes = (
  apiCall: ApiCall,
  entityName: string,
  siteRecordIds: SiteRecordIds[],
) =>
  apiCall("put", `api/entities/:site/${entityName}/system-qrcode`, {
    siteRecordIds,
  });

const getPrintableLabel =
  (apiCall: ApiCall) => (entity: string, recordId: string, labelId: string) =>
    apiCall(
      "get",
      `api/entities/:site/${entity}/${recordId}/printable-label/${labelId}`,
    );

const getPrintableLabels =
  (apiCall: ApiCall) =>
  (entity: string, recordIds: string[], labelId: string) =>
    apiCall("put", `api/entities/:site/${entity}/bulk/printable-labels`, {
      recordIds,
      labelId,
    });

const getRecordSystemQrCodesFilename = (
  entity: Entity,
  payload: SiteRecordIds[],
  title?: string,
) => {
  const fileName =
    payload.length === 1 && payload[0].ids.length === 1
      ? _("{NAME} - {TITLE} QR Code.pdf")
      : _("{NAME} - QR Codes.pdf");
  return fileName
    .replace("{NAME}", getLocalizedName(entity))
    .replace("{TITLE}", title);
};

const getTemplateCsv = (apiCall: ApiCall) => (entity: string) =>
  apiCall("get", `api/entities/:site/${entity}/reference`);

const getAllEvents = (apiCall: ApiCall) => (entity: string, recordId: string) =>
  apiCall("get", `api/entities/:site/${entity}/${recordId}/all-events`);

const getAllEventsAsPdf =
  (apiCall: ApiCall) => (entity: string, recordId: string) =>
    apiCall("get", `api/entities/:site/${entity}/${recordId}/all-events/pdf`);

const create =
  (apiCall: ApiCall, requestOptions?: RequestOptions) =>
  (
    entity: string,
    record: Record,
    message: string = _("The record was successfully created"),
  ): CancellablePromise<{ id: string }> =>
    apiCall("post", `api/entities/:site/${entity}`, formatRecord(record)).then(
      !requestOptions?.hideNotifications && returnAndNotify(message),
    );

const update =
  (apiCall: ApiCall, requestOptions?: RequestOptions) =>
  (entity: string, record: Record) =>
    apiCall(
      "put",
      `api/entities/:site/${entity}/${record.properties.id}`,
      formatRecord(record),
    ).then(
      !requestOptions?.hideNotifications &&
        returnAndNotify(_("The record was successfully updated")),
    );

const crudApi = (apiCall: ApiCall, requestOptions?: RequestOptions) => ({
  get: (
    entity: string,
    id: string,
    includeRelated?: boolean,
  ): CancellablePromise<Record> =>
    apiCall(
      "get",
      `api/entities/:site/${entity}/${id}?withLabels=true${
        includeRelated ? "&includeRelated=true" : ""
      }`,
    ),
  create: create(apiCall, requestOptions),
  update: update(apiCall, requestOptions),
  createOrUpdate: (entity: string, record: Record) =>
    record.properties.id
      ? update(apiCall)(entity, record)
      : create(apiCall)(entity, record),
  remove: (entity: string, id: string) =>
    apiCall("delete", `api/entities/:site/${entity}/${id}`).then(
      returnAndNotify(_("The record was successfully archived")),
    ),
  restore: (entity: string, id: string) =>
    apiCall("put", `api/entities/:site/${entity}/${id}/restore`, {}).then(
      returnAndNotify(_("The record was successfully restored")),
    ),
});

const customApi = (apiCall: ApiCall, requestOptions?: RequestOptions) => ({
  checkAvailability: (
    entity: string,
    recordId: string,
    rangeFrom: string,
    rangeTo: string,
    resources: FkEventValues,
  ) =>
    apiCall<Event[]>(
      "post",
      `api/entities/:site/${entity}/${recordId ?? "new"}/calendars/events`,
      R.mergeRight(resources, { rangeFrom, rangeTo }),
    ),
  bulkClose: (entity: string, payload: ActionPayload) => {
    const { records = [] } = payload;
    const message =
      records.length === 1
        ? _("The record was successfully closed")
        : _("The records were successfully closed");

    const updatedPayload: ActionPayload = {
      ...payload,
      records: records.map(({ properties, assetMeterReading }) => ({
        properties: replaceUndefined(flattenValues(properties)),
        assetMeterReading: flattenValues(assetMeterReading),
      })),
    };
    return apiCall(
      "put",
      `api/entities/:site/${entity}/bulk/close`,
      updatedPayload,
    ).then(returnAndNotify(message));
  },
  bulkComplete: (entity: string, payload: ActionPayload) => {
    const { records = [] } = payload;
    const message =
      records.length === 1
        ? _("The record was successfully completed")
        : _("The records were successfully completed");

    const updatedPayload: ActionPayload = {
      signature: payload.signature,
      records: records.map(({ properties, assetMeterReading }) => ({
        properties: replaceUndefined(flattenValues(properties)),
        assetMeterReading: flattenValues(assetMeterReading),
      })),
    };
    return apiCall(
      "put",
      `api/entities/:site/${entity}/bulk/complete`,
      updatedPayload,
    ).then(returnAndNotify(message));
  },
  bulkCheckProcedures: (
    workOrderActionName: LayoutActionName,
    proceduresEntity: string,
    workOrderRecords: Record[],
  ): CancellablePromise<string[]> => {
    const proceduresPayload: WorkOrderActionProceduresPayload[] =
      workOrderRecords.map((r) => ({
        workOrderId: r?.properties?.id,
        formId: r?.properties?.formId,
      }));
    return apiCall(
      "post",
      `api/entities/:site/${proceduresEntity}/uncompleted/${workOrderActionName}`,
      proceduresPayload,
    );
  },
  bulkSubmitRequisition: (entity: string, recordIds: string[] = []) => {
    const message =
      recordIds.length > 1
        ? _("The records were successfully submitted")
        : _("The record was successfully submitted");
    return apiCall(
      "put",
      `api/entities/:site/${entity}/bulk/submit-requisition`,
      { recordIds },
    ).then(!requestOptions?.hideNotifications && returnAndNotify(message));
  },
  approveRequisitioning: (
    entity: string,
    recordId: string,
    reqPayload: RequisitioningPayload[] = [],
  ) =>
    apiCall(
      "put",
      `api/entities/:site/${entity}/${recordId}/approve-requisitioning`,
      reqPayload,
    ).then(returnAndNotify(_("The record was successfully approved"))),
  rejectRequisition: (entity: string, recordId: string) =>
    apiCall(
      "put",
      `api/entities/:site/${entity}/${recordId}/reject-requisitioning`,
    ).then(returnAndNotify(_("The record was successfully rejected"))),
  createPurchaseOrder: (
    entity: string,
    recordId: string,
    createPoPayload: CreatePoPayload,
  ) =>
    apiCall(
      "put",
      `api/entities/:site/${entity}/${recordId}/create-po`,
      createPoPayload,
    ).then(returnAndNotify(_("The purchase order was successfully created"))),
  getRecordsActions: (entity: string, recordIds: string[]) =>
    apiCall<ActionsByRecordId>(
      "post",
      `api/entities/:site/${entity}/actions`,
      recordIds,
    ),
  sign: (entity: string, recordId: string, image: string) =>
    apiCall("put", `api/entities/:site/${entity}/${recordId}/sign`, {
      image,
    }).then(returnAndNotify(_("The record was successfully signed"))),
  open: (entity: string, recordId: string) =>
    apiCall("put", `api/entities/:site/${entity}/${recordId}/open`, {}).then(
      returnAndNotify(_("The record was successfully opened")),
    ),
  send: (entity: string, recordId: string) =>
    apiCall("put", `api/entities/:site/${entity}/${recordId}/send`, {}).then(
      returnAndNotify(_("The PO record was successfully submitted")),
    ),
  bulkPublish: (entity: string, recordIds: string[]) =>
    apiCall("put", `api/entities/:site/${entity}/bulk/publish`, {
      recordIds,
    }),
  publishAll: (entity: string): CancellablePromise<number> =>
    apiCall("put", `api/entities/:site/${entity}/publish/all`),
  bulkOpen: (entity: string, recordIds: string[]) =>
    apiCall("put", `api/entities/:site/${entity}/bulk/open`, {
      date: getUtcNow(),
      recordIds,
    }).then(returnAndNotify(_("The records were successfully opened"))),
  bulkDelete: (entity: string, recordIds: string[]) =>
    apiCall("delete", `api/entities/:site/${entity}/bulk`, { recordIds }).then(
      returnAndNotify(_("The records were successfully archived")),
    ),
  bulkRestore: (entity: string, recordIds: string[]) =>
    apiCall("put", `api/entities/:site/${entity}/bulk/restore`, {
      recordIds,
    }).then(returnAndNotify(_("The records were successfully restored"))),
  bulkReopen: (entity: string, recordIds: string[]) =>
    apiCall("put", `api/entities/:site/${entity}/bulk/reopen`, {
      date: getUtcNow(),
      recordIds,
    }).then(returnAndNotify(_("The records were successfully re-opened"))),
  reopen: (entity: string, recordId: string) =>
    apiCall("put", `api/entities/:site/${entity}/${recordId}/reopen`, {}).then(
      returnAndNotify(_("The record was successfully re-opened")),
    ),
  reschedule: (
    entity: string,
    recordId: string,
    date: string,
    shiftAll: boolean = false,
  ) =>
    apiCall("put", `api/entities/:site/${entity}/${recordId}/reschedule`, {
      date,
      shiftAll,
    }).then(returnAndNotify(_("The record was successfully re-scheduled"))),
  trigger: (entity: string, recordId: string, updateLastOpenDate: boolean) =>
    apiCall("put", `api/entities/:site/${entity}/${recordId}/trigger`, {
      updateLastOpenDate,
    }).then(returnAndNotify(_("The record was successfully triggered"))),
  bulkTrigger: (entity: string, bulkTriggerPayload: BulkTriggerPayload[]) =>
    apiCall(
      "put",
      `api/entities/:site/${entity}/bulk/trigger`,
      bulkTriggerPayload,
    ).then(returnAndNotify(_("The records were successfully triggered"))),
  setDefaultSupplier: (entity: string, recordId: string) =>
    apiCall("put", `api/entities/:site/${entity}/${recordId}/set-default`).then(
      returnAndNotify(_("The record was successfully set as default supplier")),
    ),
  setDefaultLocation: (entity: string, recordId: string) =>
    apiCall(
      "put",
      `api/entities/:site/${entity}/${recordId}/set-default-location`,
    ).then(
      returnAndNotify(_("The record was successfully set as default location")),
    ),
  setGroupOwner: (entity: string, recordId: string) =>
    apiCall(
      "put",
      `api/entities/:site/${entity}/${recordId}/set-group-owner`,
    ).then(
      returnAndNotify(_("The record was successfully set as the group owner")),
    ),
  setDefaultCostCenter: (
    entity: string,
    recordId: string,
    recordTitle: string,
  ) =>
    apiCall(
      "put",
      `api/entities/:site/${entity}/${recordId}/set-default-center`,
    ).then(
      returnAndNotify(
        _("{TITLE} was successfully set as default cost center number").replace(
          "{TITLE}",
          recordTitle || _("The Record"),
        ),
      ),
    ),
  reject: (entity: string, recordId: string, comments: string) =>
    apiCall("put", `api/entities/:site/${entity}/${recordId}/reject`, {
      comments,
    }).then(returnAndNotify(_("The record was successfully rejected"))),

  cancel: (entity: string, recordId: string) =>
    apiCall("put", `api/entities/:site/${entity}/${recordId}/cancel`).then(
      returnAndNotify(_("The record was successfully canceled")),
    ),
  bulkUpdateItemsStatus: (
    entity: string,
    reqUpdateItems: RequisitionUpdateItem[],
  ) =>
    apiCall(
      "put",
      `api/entities/:site/${entity}/bulk/update-items-status`,
      reqUpdateItems,
    ),
  /*
  createKit: (entity:string, recordId:string, kit:CreateKit) =>
    apiCall('PUT', `api/entities/:site/${entity}/${recordId}/create-kit`, kit),
  breakKit: (entity:string, recordId:string, kit:BreakKit) =>
    apiCall('PUT', `api/entities/:site/${entity}/${recordId}/break-kit`, kit),
  */
  adjust: (entity: string, recordId: string, adj: Adjustment) =>
    apiCall("put", `api/entities/:site/${entity}/${recordId}/adjust`, adj).then(
      returnAndNotify(_("The record was successfully adjusted")),
    ),
  transfer: (entity: string, recordId: string, data: Transfer) =>
    apiCall(
      "put",
      `api/entities/:site/${entity}/${recordId}/transfer`,
      data,
    ).then(returnAndNotify(_("The record was successfully transferred"))),
  email: (entity: string, recordId: string, emailRequest: Email) =>
    apiCall(
      "post",
      `api/entities/:site/${entity}/${recordId}/email`,
      emailRequest,
    ).then(returnAndNotify(_("The record was successfully sent by email"))),
  getAsCsv: (entity: string, query: Query) =>
    apiCall("post", `api/entities/:site/${entity}`, query),
  downloadPdf: (
    entity: Entity,
    title: string,
    recordId: string,
    includeAuditTrail?: boolean,
  ) =>
    getRecordPdf(
      withDownload(
        apiCall,
        "application/pdf",
        `${getLocalizedName(entity)} - ${title}.pdf`,
      ),
    )(entity.name, recordId, includeAuditTrail),

  bulkDownloadPdf: (entity: Entity, recordIds: string[]) =>
    getBulkRecordsPdf(
      withDownload(
        apiCall,
        "application/pdf",
        `${getLocalizedName(entity)}.pdf`,
      ),
    )(entity.name, recordIds),

  getSystemQrCodes: (
    entity: Entity,
    siteRecordIds: SiteRecordIds[],
    title?: string,
  ) =>
    getRecordSystemQrCodes(
      withDownload(
        apiCall,
        "application/pdf",
        getRecordSystemQrCodesFilename(entity, siteRecordIds, title),
      ),
      entity.name,
      siteRecordIds,
    ),

  getBarcode: (entity: Entity, num: number, recordId: string) =>
    getRecordBarcode(
      withDownload(
        apiCall,
        "application/pdf",
        `${getLocalizedName(entity)} - ${num} barcode.pdf`,
      ),
    )(entity.name, recordId),

  getBarcodes: (entity: Entity, amount: number, recordIds: string[]) =>
    getRecordBarcodes(
      withDownload(
        apiCall,
        "application/pdf",
        `${getLocalizedName(entity)} - ${amount} barcodes.pdf`,
      ),
    )(entity.name, recordIds),

  downloadCsvTemplate: (entity: Entity) =>
    getTemplateCsv(
      withDownload(
        apiCall,
        "text/csv",
        `${getLocalizedName(entity)}-template.csv`,
      ),
    )(entity.name),

  downloadFullAuditTrailAsJson: (entityName: string, recordId: string) =>
    getAllEvents(
      withDownload(
        apiCall,
        "text/plain",
        `${entityName}-Audit Trail ${recordId}.txt`,
      ),
    )(entityName, recordId),

  downloadFullAuditTrailAsPdf: (entityName: string, recordId: string) =>
    getAllEventsAsPdf(
      withDownload(
        apiCall,
        "application/pdf",
        `${entityName}-Audit Trail ${recordId}.pdf`,
      ),
    )(entityName, recordId),

  exportPrintableLabel: (
    entity: Entity,
    num: number,
    recordId: string,
    labelId: string,
    labelName: string,
  ) =>
    getPrintableLabel(
      withDownload(
        apiCall,
        "application/pdf",
        `${labelName} - ${getLocalizedName(entity)}-${num}.pdf`,
      ),
    )(entity.name, recordId, labelId),

  exportPrintableLabels: (
    entity: Entity,
    amount: number,
    recordIds: string[],
    labelId: string,
    labelName: string,
  ) =>
    getPrintableLabels(
      withDownload(
        apiCall,
        "application/pdf",
        `${labelName} - ${getLocalizedName(entity)}-${amount}.pdf`,
      ),
    )(entity.name, recordIds, labelId),
});

export const recordsApi = (apiCall: ApiCall, requestOptions?: RequestOptions) =>
  R.mergeRight(
    crudApi(apiCall, requestOptions),
    customApi(apiCall, requestOptions),
  );

export const forEntity = (
  apiCall: ApiCall,
  entityName: string,
  includeRelated?: boolean,
): Crud<string, Record> => {
  const api = recordsApi(apiCall);
  return {
    list: () => undefined,
    get: (id: string) => api.get(entityName, id, includeRelated),
    create: (record: Record) => api.create(entityName, record),
    update: (record: Record) => api.update(entityName, record),
    remove: (id: string) => api.remove(entityName, id),
  };
};
