import { Selector } from "common/widgets/selector";
import { ReactHighlightWords } from "common/vendor-wrappers/react-highlight-words";
import { reportsApi } from "common/api/reports";
import { Context } from "common/types/context";
import { Report } from "common/types/reports";
import {
  FormatMeta,
  LabelledOptionOrGroup,
} from "common/vendor-wrappers/react-select/types";
import { ValueComponent } from "common/with-value-for";

interface PropTypes {
  context: Context;
}

interface StateType {
  reports: Report[];
}

export const getReportLabel = (culture: string) => (report: Report) =>
  report?.labels?.[culture]?.name || report.label || report.name;

export class ReportSelector extends ValueComponent<
  Report,
  PropTypes,
  StateType
> {
  state: StateType = {
    reports: [],
  };

  componentDidMount() {
    this.fetchReports().then((reports) => this.setState({ reports }));
  }

  fetchReports = () => {
    const { apiCall, uiFormat, entities } = this.props.context;
    const { culture } = uiFormat;

    return reportsApi(apiCall, culture, entities).list();
  };

  formatOptionLabel = (
    option: LabelledOptionOrGroup<Report>,
    meta: FormatMeta<Report>,
  ) => <ReactHighlightWords text={option.label} search={meta?.inputValue} />;

  render() {
    const { context, value, onChange } = this.props;
    const { culture } = context.uiFormat;
    const { reports } = this.state;

    return (
      <Selector
        formatOption={this.formatOptionLabel}
        getOptionLabel={getReportLabel(culture)}
        options={reports}
        value={value}
        onChange={onChange}
      />
    );
  }
}
