import * as React from "react";
import { KeyboardEventHandler, ChangeEvent } from "react";
import { ValueComponent } from "common/with-value-for";
import { AlertErrorTip } from "common/widgets/alert";

export type OnSubmit = () => void;

type onClipboard = (e: React.SyntheticEvent<HTMLInputElement>) => void;

export interface PropTypes {
  onSubmit: OnSubmit;
  type?: string;
  submitOnBlur?: boolean;
  className?: string;
  placeholder?: string;
  hasError?: boolean;
  disabled?: boolean;
  maxLength?: number;
  focus?: boolean;
  inputId?: string;
  autoComplete?: string;
  errorMessage?: string;
  onCopy?: onClipboard;
  onCut?: onClipboard;
  spellCheck?: boolean;
  label?: string;
}

const onEnterPressed =
  (action: () => void): KeyboardEventHandler =>
  (e) =>
    (e.which === 13 || e.which === 10) && action();

export class InputWithSubmit extends ValueComponent<string, PropTypes> {
  static readonly displayName = "InputWithSubmit";
  input: HTMLInputElement = null;

  componentDidMount() {
    if (this.props.focus) {
      this.input.focus();
    }
  }

  setRef = (input: HTMLInputElement) => {
    this.input = input;
  };

  onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setValue(e.target.value);
  };

  render() {
    const {
      onSubmit,
      type,
      className = "",
      placeholder,
      hasError,
      disabled,
      maxLength,
      value,
      submitOnBlur,
      inputId,
      label,
      autoComplete,
      errorMessage,
      onCopy,
      onCut,
      spellCheck,
    } = this.props;
    return (
      <>
        <input
          id={inputId}
          className={`${hasError ? "x-input-error" : ""} ${className}`}
          ref={this.setRef}
          type={type || "text"}
          placeholder={placeholder}
          spellCheck={spellCheck}
          maxLength={maxLength}
          disabled={disabled}
          autoComplete={autoComplete}
          aria-label={label}
          value={value || ""}
          onCopy={onCopy}
          onCut={onCut}
          onKeyPress={onSubmit ? onEnterPressed(onSubmit) : undefined}
          onBlur={onSubmit && submitOnBlur ? onSubmit : null}
          onChange={this.onInputChange}
        />
        {hasError && errorMessage && <AlertErrorTip message={errorMessage} />}
      </>
    );
  }
}
