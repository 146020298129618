import * as R from "ramda";
import { Component } from "react";
import { omitDataTypes } from "common/entities/entity-column/data-type/functions";
import {
  filterBlackList,
  noValidationFilters,
} from "common/entities/entity-column/data-type/types";
import { EntityColumn } from "common/entities/entity-column/types";
import { isDateTimeOperator, Operator } from "common/entities/operators";
import { getFkId } from "common/functions/foreign-key";
import {
  CUSTOM_DYNAMIC_VALUE,
  getDateTimeOnlyDynamicValues,
  getDynamicValues,
  isObsoleteDynamicValue,
  onDisplayDynamicValues,
  replaceObsoleteDynamicValue,
} from "common/form/dynamic-values";
import { InputWidget } from "common/form/widget/input-widget";
import { translateConditionType } from "common/functions/condition-type";
import { FieldSelector } from "common/query-builder/field-selector";
import { FunctionSelector } from "common/query-builder/function-selector";
import { PathMap } from "common/query/advanced-types";
import { shouldExcludeFromFkExpansion } from "common/query/expansion";
import { getAdjustedDateTimeFilterValue } from "common/query/filter";
import {
  FilterField,
  FilterRule,
  isExpressionRule,
  isFieldRule,
  QueryField,
} from "common/query/types";
import {
  ConditionType,
  ConditionTypes,
  defaultConditionTypes,
} from "common/types/condition-type";
import { Context } from "common/types/context";
import { arrayToString } from "common/utils/array";
import { StringInput } from "common/widgets/input-with-submit/string";
import { Int } from "common/widgets/number";
import { Selector } from "common/widgets/selector";
import { WithDefaultSelector } from "common/widgets/selector/with-default-selector";
import { ValueProps } from "common/with-value-for";
import { Expression } from "../expression";
import { getField } from "../functions";
import { NotFound } from "../not-found";
import { Field } from "../types";
import { FilterError } from "../validation";
import {
  createRuleFor,
  displayComparisonOperator,
  getColumnDataType,
  getOperatorByName,
  getRuleOperators,
  getValidOperator,
  VALUE_FROM_COLUMN_ALIAS,
} from "./functions";

interface PropTypes extends ValueProps<FilterRule> {
  context: Context;
  fields: Field[];
  allowAggregates: boolean;
  entityName: string;
  pathMap: PathMap;
  conditionTypes?: ConditionTypes;
  withExpressions?: boolean;
  errors?: FilterError[];
  mainEntityFields?: Field[];
}

const isFkToEntity = (context: Context, column: EntityColumn) => {
  if (column.dataType !== "fk") return false;
  return context.entities[column.relatedEntity].type === "Entity";
};

export class Rule extends Component<PropTypes> {
  static readonly displayName = "Rule";

  componentDidMount() {
    const { value, onChange } = this.props;
    if (isObsoleteDynamicValue(value?.value)) {
      onChange({
        ...value,
        value: replaceObsoleteDynamicValue(value?.value),
      });
    }
  }

  onOperatorChanged = (newOp: Operator) => {
    const { value, onChange } = this.props;
    const newValue = getAdjustedDateTimeFilterValue(value, newOp.name);
    onChange({
      ...value,
      op: newOp.name,
      value: newOp.unary ? undefined : newValue,
    });
  };

  onFieldChanged = (field: Field) => {
    const { value, onChange } = this.props;
    onChange({
      ...createRuleFor(field, getValidOperator(field, value)?.name),
      value: undefined,
    });
  };

  onChange = (v: any) => {
    const { value, onChange } = this.props;
    const newValue = shouldExcludeFromFkExpansion(v)
      ? { ...value, value: getFkId(v), excludeFromFkExpansion: true }
      : { ...value, value: v };

    onChange(newValue);
  };

  onConditionTypeChanged = (conditionType: ConditionType) => {
    const { value, onChange } = this.props;
    const valueFromColumn: QueryField =
      conditionType === "input" ? undefined : { name: undefined };

    onChange({ ...value, valueFromColumn });
  };

  onValueFromColumnChanged = (field: Field) => {
    const { mainEntityFields, value, onChange } = this.props;
    const { path, column } = field;
    const { name } = column;

    const valueFromColumn: QueryField = mainEntityFields?.includes(field)
      ? { name, path, alias: VALUE_FROM_COLUMN_ALIAS }
      : { name, path };

    onChange({ ...value, valueFromColumn });
  };

  onFunctionChanged = (fn: string) => {
    const { fields, value, onChange } = this.props;
    const newValue: FilterRule = {
      ...value,
      fn,
      value: undefined,
      valueFromColumn: undefined,
    };

    onChange({
      ...newValue,
      op: getValidOperator(getField(fields, value), newValue)?.name,
    });
  };

  onExpressionChanged = (expression: string) => {
    const { value, onChange } = this.props;
    onChange({ ...value, expression });
  };

  getColumnOptions = (column: EntityColumn) => {
    const { context, value } = this.props;
    const { dataType } = column;

    const entity = isFkToEntity(context, column)
      ? context.entities[column.relatedEntity]
      : undefined;

    return isDateTimeOperator(value.op)
      ? getDateTimeOnlyDynamicValues()
      : getDynamicValues(dataType, entity, false);
  };

  getColumnValueSelector = (value: FilterField) => {
    const { entityName, fields, context, pathMap } = this.props;

    const field = getField(fields, value);
    const columnEntityName = field.path ? pathMap[field.path].name : entityName;

    const dataType = getColumnDataType(field.column, value.op);
    const fieldColumn: EntityColumn = {
      ...field.column,
      dataType,
    };

    const options = value.fn ? [] : this.getColumnOptions(fieldColumn);

    return (
      <WithDefaultSelector
        options={options}
        defaultValue={CUSTOM_DYNAMIC_VALUE}
        getOptionLabel={onDisplayDynamicValues(dataType)}
        value={value.value}
        onChange={this.onChange}
        child={
          value.fn ? (
            <Int value={value.value} onChange={this.onChange} />
          ) : R.includes(dataType, noValidationFilters) ? (
            <StringInput
              className="qa-condition-rule-basic-string"
              value={value.value}
              onChange={this.onChange}
            />
          ) : (
            <InputWidget
              entityName={columnEntityName}
              disabled={false}
              validate={true}
              buffer={false}
              context={context}
              column={fieldColumn}
              formValidation={undefined}
              onFormValidationChange={undefined}
              value={value.value}
              onChange={this.onChange}
            />
          )
        }
      />
    );
  };

  getExpressionValueSelector = () => (
    <StringInput
      className="x-flex-grow-1 qa-condition-rule-expression-string"
      value={this.props.value.value}
      onChange={this.onChange}
    />
  );

  getColumnSelector = () => {
    const { fields, mainEntityFields = [], value } = this.props;
    const { valueFromColumn } = value;
    const selectorFields = fields.concat(mainEntityFields);

    return (
      <FieldSelector
        className="x-flex-grow-1 x-margin-left-10 qa-rule-column-selector"
        fields={omitDataTypes(selectorFields, filterBlackList)}
        value={getField(selectorFields, valueFromColumn)}
        onChange={this.onValueFromColumnChanged}
      />
    );
  };

  getValueSelector = () => {
    const { fields, value } = this.props;
    const field = getField(fields, value);

    if (getValidOperator(field, value)?.unary) return undefined;
    if (value.valueFromColumn) return this.getColumnSelector();

    return isFieldRule(value)
      ? this.getColumnValueSelector(value)
      : this.getExpressionValueSelector();
  };

  render() {
    const {
      allowAggregates,
      context,
      fields,
      value,
      conditionTypes = defaultConditionTypes,
      withExpressions,
      errors,
    } = this.props;

    const field = getField(fields, value);
    if (!isExpressionRule(value) && !field) {
      return <NotFound message={value?.name || _("Filter column")} />;
    }

    const operators = getRuleOperators(field, value);
    const operator = getOperatorByName(operators, value.op);
    const selectedConditionType = value.valueFromColumn ? "column" : "input";
    const expressionError = errors?.find(
      (e) => isExpressionRule(value) && e.expression === value.expression,
    )?.message;

    const expressionClasses = arrayToString([
      "x-flex-grow-1",
      expressionError ? "x-has-error" : undefined,
    ]);

    return (
      <div className="x-flex x-flex-grow-1 x-rule-field qa-rule-field">
        {isExpressionRule(value) ? (
          <Expression
            className={expressionClasses}
            context={context}
            fields={fields}
            placeholder={expressionError}
            value={value.expression}
            onChange={this.onExpressionChanged}
          />
        ) : (
          <>
            <FieldSelector
              className="x-flex-grow-1 qa-rule-field-selector"
              fields={omitDataTypes(fields, filterBlackList)}
              withExpressions={withExpressions}
              value={field}
              onChange={this.onFieldChanged}
            />
            {allowAggregates ? (
              <FunctionSelector
                className="x-flex-grow-1 x-margin-left-10"
                column={field.column}
                allowEmpty={true}
                value={value.fn}
                onChange={this.onFunctionChanged}
              />
            ) : undefined}
          </>
        )}
        <Selector<Operator>
          className="x-flex-grow-1 x-margin-left-10 qa-rule-op"
          getOptionLabel={displayComparisonOperator}
          options={operators}
          value={operator}
          onChange={this.onOperatorChanged}
        />
        {!operator?.unary && conditionTypes.length > 1 && (
          <Selector<ConditionType>
            className="x-flex-grow-1 x-margin-left-10 qa-rule-condition-type"
            getOptionLabel={translateConditionType}
            options={conditionTypes}
            value={selectedConditionType}
            onChange={this.onConditionTypeChanged}
          />
        )}
        <div className={`x-flex-grow-2 x-margin-left-10 qa-rule-value`}>
          {this.getValueSelector()}
        </div>
      </div>
    );
  }
}
