import { Component } from "react";
import { Context } from "common/types/context";
import { getUrl } from "common/entities";
import { Entity } from "common/entities/types";
import { Node } from "common/widgets/tree/types";
import { EntityTreeController } from "./entity-tree-controller";

interface PropTypes {
  context: Context;
  entity: Entity;
  hasRecords: boolean;
}

export class RecordTree extends Component<PropTypes> {
  static readonly displayName = "RecordTree";

  treeLink = (node: Node) => {
    const { context, entity } = this.props;

    return `${getUrl(entity, context.site.name)}/${node.name}`;
  };

  render() {
    const { context, entity, hasRecords } = this.props;

    return (
      <EntityTreeController
        className="x-records-tree"
        context={context}
        entity={entity}
        hasRecords={hasRecords}
        link={this.treeLink}
        showUngroup={true}
        onChange={undefined}
        showTitle={true}
        value={undefined}
      />
    );
  }
}
