import * as R from "ramda";
import { ValueComponent } from "common/with-value-for";
import { Uint } from "common/widgets/number";
import { RequiredField } from "common/form/ui";

export class PmThreshold extends ValueComponent<number> {
  static readonly displayName = "PmOverrides";

  render() {
    const { value } = this.props;
    const days = value === 1 ? _("day") : _("days");

    return (
      <div className="x-pm-trigger-threshold x-pm-section qa-pm-section">
        <RequiredField label={_("Suppression")} value={value}>
          <div className="x-pm-overrides-threshold">
            <div>
              {_("If the time difference between two projections is less than")}
            </div>
            <Uint
              value={!R.isNil(value) ? value : ""}
              onChange={this.onChangeSetValue}
              placeholder={"1"}
            />
            <div>{`${days}, ${_("keep only one projection")}.`}</div>
          </div>
        </RequiredField>
      </div>
    );
  }
}
