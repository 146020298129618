import * as R from "ramda";
import { EntityColumn } from "common/entities/entity-column/types";
import { getFkId } from "common/functions/foreign-key";
import { Row } from "common/record/form/content/audit-trail/event-detail/row";
import { Context } from "common/types/context";
import { MeterState } from "common/types/scheduled-event";
import { LinkRecord } from "common/widgets/link-record";
import { FloatLabel } from "common/widgets/number/label";

const eventAsset = (context: Context, values: MeterState[]) => {
  if (!values?.length) return undefined;
  const { uiFormat, entities, site } = context;

  return (
    <div className="x-inner-object">
      {values.map((value, i) => {
        return (
          <div key={i} className="x-object-separator qa-object-separator">
            {R.keys(value).map((key) => {
              switch (key) {
                case "assetMeterId": {
                  const id = getFkId(value.assetMeterId);
                  return (
                    value.assetMeterId && (
                      <Row key={key} label={_("Asset Meter")}>
                        <LinkRecord
                          site={site.name}
                          entity={entities.AssetMeters}
                          id={id}
                        >
                          {id}
                        </LinkRecord>
                      </Row>
                    )
                  );
                }
                case "lastTriggered":
                  return (
                    !R.isNil(value.lastTriggered) && (
                      <Row key={key} label={_("Last WO Reading")}>
                        <FloatLabel
                          value={value.lastTriggered}
                          decimalSeparator={uiFormat.decimalSeparator}
                        />
                      </Row>
                    )
                  );
                case "threshold":
                  return (
                    !R.isNil(value.threshold) && (
                      <Row key={key} label={_("Every")}>
                        <FloatLabel
                          value={value.threshold}
                          decimalSeparator={uiFormat.decimalSeparator}
                        />
                      </Row>
                    )
                  );
              }
              return undefined;
            })}
          </div>
        );
      })}
    </div>
  );
};

export const getEventAssetContent = (
  context: Context,
  column: EntityColumn,
  value: any,
) => {
  switch (column?.name) {
    case "meters":
      return eventAsset(context, value);

    default:
      return undefined;
  }
};
