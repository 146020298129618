import { getLocalizedName } from "common";
import type { RelatedEntity } from "common/form/types";
import { Section } from "common/record/form/content/detail/view/section-group";
import { RelatedRecordsForm } from "common/record/form/content/related";
import { getProceduresEntity } from "x/scheduler2/workorder-view/functions";
import type { SectionProps } from "x/scheduler2/workorder-view/section/types";

export const ProceduresSection = (props: SectionProps) => {
  const { context, workOrderEntityName, relatedValue, workOrderId } = props;
  const proceduresEntity = getProceduresEntity(
    workOrderEntityName,
    context.entities,
  );
  if (!proceduresEntity) {
    return null;
  }

  const entityConfig: RelatedEntity = {
    name: "properties",
    columns: [{ columnName: "order" }, { columnName: "step" }],
  };

  return (
    <Section
      className="qa-WorkOrderView-procedures"
      title={getLocalizedName(proceduresEntity)}
    >
      <RelatedRecordsForm
        context={context}
        parentEntity={context.entities[workOrderEntityName]}
        entity={proceduresEntity}
        recordId={workOrderId}
        entityConfig={entityConfig}
        value={relatedValue}
        orderBy={{ name: "order", desc: false }}
        isTemplate={false}
        displayTypes={["table"]}
        recordDetail={undefined}
        reload={undefined}
        onChange={undefined}
      />
    </Section>
  );
};

ProceduresSection.displayName = "ProceduresSection";
