import { findDOMNode } from "react-dom";
import { DragSource, DropTarget } from "react-dnd-cjs";
import { ValueComponent, ValueProps } from "common/with-value-for";
import { Column, Props as ColumnProps } from "common/query/table/column";
import { ColumnDefinition } from "common/query/advanced-types";
import { QueryForEntity } from "common/query/types";
import { moveArrayItems, moveColumn } from "./functions";

export interface ColumnDraggableValue {
  query: QueryForEntity;
  widths: number[];
}

const defaultValue: ColumnDraggableValue = {
  query: undefined,
  widths: [],
};

interface PropTypes {
  column: ColumnProps;
  index: number;
  offset: number;
  columnDefinitions: ColumnDefinition[];
  dragIndex?: number;
  isMovingLeft?: boolean;
  connectDragSource?: (x: any) => void;
  connectDropTarget?: (x: any) => void;
  isOver?: boolean;
}

type Props = PropTypes & ValueProps<ColumnDraggableValue>;

class ColumnDraggableComp extends ValueComponent<
  ColumnDraggableValue,
  PropTypes
> {
  moveColumn = (dragIndex: number, dropIndex: number) => {
    const { value = defaultValue, offset, columnDefinitions } = this.props;
    const { widths = [], query } = value;

    const newWidths = widths.length
      ? moveArrayItems(widths, offset, dragIndex, dropIndex)
      : undefined;

    const newQuery = moveColumn(query, columnDefinitions, dragIndex, dropIndex);

    this.setValue({
      query: newQuery,
      widths: newWidths,
    });
  };

  attachDrag = (instance: any) => {
    const { connectDropTarget, connectDragSource } = this.props;
    // TODO ---> update the lib to use dnd in a simpler way
    // eslint-disable-next-line react/no-find-dom-node
    const comp = findDOMNode(instance);
    connectDragSource(comp);
    connectDropTarget(comp);
  };

  render() {
    const {
      isMovingLeft,
      isOver,
      index,
      dragIndex,
      column,
      value = defaultValue,
    } = this.props;

    const hoverClass =
      isOver && index !== dragIndex
        ? ` x-drag-hover-${isMovingLeft ? "left" : "right"}`
        : "";

    return (
      <Column
        {...column}
        value={value.query}
        onChange={this.onChangeMergeValue("query")}
        styles={"x-draggable" + hoverClass}
        ref={this.attachDrag}
      />
    );
  }
}

const target = DropTarget<Props>(
  "header",
  {
    drop(props, monitor, component) {
      const dragIndex = (monitor.getItem() as any).index;
      const hoverIndex = props.index;
      if (dragIndex === hoverIndex) return;
      (component as ColumnDraggableComp).moveColumn(dragIndex, hoverIndex);
    },
  },
  (connect, monitor) => {
    const position = monitor.getDifferenceFromInitialOffset();
    const item = (monitor.getItem() || {}) as any;
    return {
      dragIndex: item.index,
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver(),
      isMovingLeft: position && position.x < 0,
    };
  },
);

const source = DragSource<Props>(
  "header",
  {
    beginDrag(props) {
      return {
        index: props.index,
      };
    },
  },
  (connect) => ({ connectDragSource: connect.dragSource() }),
);

export const ColumnDraggable = source(target(ColumnDraggableComp));
