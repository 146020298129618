import { v4 as uuid } from "uuid";
import { merge2, merge3 } from "common/merge";
import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";
import { FormValue, isStandardUiValue } from "common/record/types";
import { hasUploadableFields } from "common/record/utils";
import { Form } from "common/types/forms";
import { Properties } from "common/types/records";

const isEntityUploadable = (entity: Entity) =>
  behaveAs("Attachment", entity) || hasUploadableFields(entity);

export const isAnyEntityUploadable = (entities: Entity[]) =>
  (entities ?? []).some(isEntityUploadable);

export const getTemporaryIdProps = (properties: Properties) => ({
  $id: properties?.$id ?? uuid(),
});

/**
 * Adds $id with generated UUID to new records from entities with Attachment behavior
 * or if entity has any column of type document
 * This is converted to a record's id while saving the record by an edit controller
 * It's used to attach documents to records before saving them
 */
export const getTemporaryIdPropsIfNeeded = (
  entity: Entity,
  properties: Properties,
) => (isEntityUploadable(entity) ? getTemporaryIdProps(properties) : undefined);

export const getPropertiesWithTemporaryIds = (
  entity: Entity,
  properties: Properties,
) => ({ ...properties, ...getTemporaryIdPropsIfNeeded(entity, properties) });

const getDefaultsForNewRecord = (
  defaultProperties: Properties,
  form: Form,
) => ({
  ...form?.settings?.defaults,
  ...(defaultProperties ?? {}),
});

export const defaultFormValue = (
  isNew: boolean,
  defaultProperties: Properties,
  form: Form,
): FormValue => {
  const defaults = isNew
    ? getDefaultsForNewRecord(defaultProperties ?? {}, form)
    : undefined;

  return {
    ui: {
      detail: { form: defaults },
    },
    record: {
      properties: { ...defaults, formId: form?.id },
      isNew: isNew,
      actions: [],
    },
  } as FormValue;
};

export const mergePropertiesWithFormValue = (
  value: FormValue,
  properties: Properties = {},
) => {
  const recordProperties = { ...value?.record?.properties, ...properties };
  const valueWithProperties = merge2(
    "record",
    "properties",
    recordProperties,
    value,
  );

  if (!isStandardUiValue(valueWithProperties)) return valueWithProperties;

  const uiFormProperties = {
    ...valueWithProperties?.ui?.detail?.form,
    ...properties,
  };

  return merge3("ui", "detail", "form", uiFormProperties, valueWithProperties);
};
