import { WorkingMode } from "devexpress-dashboard";
import { Component } from "react";
import { devExpressApi } from "common/api/devexpress";
import { Context } from "common/types/context";
import { DevExpressDashboard } from "common/types/dashboards";
import { hasPermissionTo } from "common/functions/roles";
import { isAdminUserType } from "common/types/users";
import { LazyDevExpress } from "common/vendor-wrappers/devexpress";
import { DevExpressDomApi } from "common/vendor-wrappers/devexpress/types";
import { Ribbon } from "./ribbon";

interface PropTypes {
  context: Context;
  hidePanels: boolean;
  urlId?: number;
}

interface StateType {
  dashboardId: number;
  dashboards: DevExpressDashboard[];
  devExpressDomApi: DevExpressDomApi;
  currentMode: WorkingMode;
}

export class DevExpressController extends Component<PropTypes, StateType> {
  getInitialWorkingMode = (): WorkingMode => {
    const { hidePanels, context } = this.props;
    return !hidePanels &&
      hasPermissionTo(context.userTypes, context.role, "Dashboard", "Design")
      ? "Viewer"
      : "ViewerOnly"; // if can't design, it stays stuck in this mode
  };

  state: StateType = {
    dashboardId: this.props.urlId,
    dashboards: [],
    devExpressDomApi: undefined,
    currentMode: this.getInitialWorkingMode(),
  };

  componentDidMount() {
    this.fetchDashboards();
  }

  componentDidUpdate(prevProps: PropTypes) {
    const { site } = this.props.context;
    if (prevProps.context.site.name !== site.name) this.fetchDashboards();
  }

  findDashboard = (id: number) =>
    this.state.dashboards.find((d) => d.id === id);

  fetchDashboards = () =>
    devExpressApi(this.props.context.apiCall)
      .list()
      .then((dashboards) => {
        this.setState({ dashboards });
      });

  onDashboardInit = (id: number) => {
    if (!this.findDashboard(id)) {
      // re-fetch if not found - ie new dashboards
      this.fetchDashboards();
    }

    this.setState({ dashboardId: id });
  };

  onDashboardDeleted = () => {
    const { devExpressDomApi } = this.state;

    this.setState({ dashboardId: undefined }, () => {
      this.fetchDashboards();
      devExpressDomApi.refreshDashboardsList();

      // delete called, we were on designer mode.
      // we go to viewer and unload
      this.toggleMode();
    });
  };

  setDomApi = (devExpressDomApi: DevExpressDomApi) => {
    this.setState({ devExpressDomApi });
  };

  canDashboardBeEdited = () => {
    const { context } = this.props;
    const { dashboardId } = this.state;

    const userIsOwner = context.id === this.findDashboard(dashboardId)?.userId;
    const adminCanEdit =
      isAdminUserType(context.userTypes) &&
      this.findDashboard(dashboardId)?.roleIds.length > 0;

    return userIsOwner || adminCanEdit;
  };

  toggleMode = () => {
    const { devExpressDomApi, dashboardId } = this.state;
    const mode = devExpressDomApi.getMode();

    if (!dashboardId || (mode === "Viewer" && !this.canDashboardBeEdited())) {
      // we are going to design mode but user can't edit current dashboard
      // or dashboard was deleted
      devExpressDomApi.unloadDashboard();
    } else if (mode === "Designer") {
      // ensure everything is saved before switching modes (in case user is editing).
      // if it was unloaded before and nothing new was created, just loads the previous.
      devExpressDomApi.ensureSaveAndLoad(dashboardId);
    }

    this.setState({ currentMode: mode === "Designer" ? "Viewer" : "Designer" });
    devExpressDomApi.toggleMode();
  };

  render() {
    const { context, hidePanels, urlId } = this.props;
    const { devExpressDomApi, dashboardId, currentMode } = this.state;

    return (
      <>
        {!hidePanels && devExpressDomApi ? (
          <Ribbon
            context={context}
            dashboard={this.findDashboard(dashboardId)}
            workingMode={currentMode}
            dashboardCanBeEdited={this.canDashboardBeEdited()}
            toggleMode={this.toggleMode}
            onDashboardUpdated={this.fetchDashboards}
            onDashboardDeleted={this.onDashboardDeleted}
          />
        ) : null}
        <LazyDevExpress
          url={`api/devexpress/${context.site.name}`}
          initialWorkingMode={this.getInitialWorkingMode()}
          initialId={urlId}
          hideLeftMenu={hidePanels}
          onDashboardInit={this.onDashboardInit}
          onApiReady={this.setDomApi}
        />
      </>
    );
  }
}
