import { Component } from "react";
import * as R from "ramda";
import { Context } from "common/types/context";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { EntityColumnSelector } from "common/widgets/selector/entity-column-selector";
import { createList } from "common/widgets/list";
import { OnChange, ValueProps } from "common/with-value-for";
// eslint-disable-next-line import/no-cycle
import { getAllowedMassChangeColumns } from "./functions";
// eslint-disable-next-line import/no-cycle
import { FieldRow, Row } from "./row";

interface PropTypes extends ValueProps<Row[]> {
  context: Context;
  entity: Entity;
}

const List = createList<Row>();

export class UpdateListForm extends Component<PropTypes> {
  static readonly displayName = "UpdateListForm";

  onAddColumn = (column: EntityColumn) => {
    const { value, onChange } = this.props;

    onChange(
      value.concat({
        name: column.name,
        localizedName: column.localizedName,
        value: undefined,
      }),
    );
  };

  getUnselected = () => {
    const { entity, value, context } = this.props;
    const selected = value.map((f) => f.name);

    return getAllowedMassChangeColumns(context, entity).filter(
      (c) => !selected.includes(c.name),
    );
  };

  renderRow = (item: Row, onChange: OnChange<Row>) => {
    const { context, entity } = this.props;

    return (
      <FieldRow
        key={item.name}
        context={context}
        entity={entity}
        value={item}
        onChange={onChange}
      />
    );
  };

  render() {
    const { value, onChange } = this.props;
    const unselected = this.getUnselected();

    return (
      <>
        <EntityColumnSelector
          columns={unselected}
          disabled={!unselected.length}
          value={undefined}
          onChange={this.onAddColumn}
        />
        <List
          className="x-padding-top-10 x-padding-bottom-10"
          canDelete={R.T}
          noReorder={true}
          onDisplay={this.renderRow}
          value={value}
          onChange={onChange}
        />
      </>
    );
  }
}
