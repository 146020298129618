import { Location } from "../types/url";
import { splitPath } from "../app/router";

export const getBrowserLanguage = () => window.navigator.language;
export const getWindowLocation = () => window.location;
export const getLocationOrigin = () => window.location.origin;
export const setLocationHref = (url: string): void => {
  window.location.href = url;
};
export const openUrlInNewTab = (url: string): void => {
  window.open(url, "_blank");
};

export const getUserAgent = () => window.navigator.userAgent;
export const reloadPage = () => window.location.reload();

export const goTo = (location: Location, siteName: string, entity?: string) => {
  const [, entityName, page = "open"] = splitPath(location.pathname);
  const url =
    "#/" + [siteName, entity || entityName, page].filter((s) => !!s).join("/");
  setLocationHref(url);
};
