import { defaultFor } from "common";
import { omitColumn } from "common/entities";
import { Entity } from "common/entities/types";
import {
  createDefaultLayout,
  omitLayoutColumn,
} from "common/form/functions/layout";
import { FormValidationProps } from "common/form/types";
import { ScheduledEventValue } from "common/record/types";
import { ExternalFormValue } from "common/scheduled-event/form/external/types";
import { InspectionFormValue } from "common/scheduled-event/form/inspection/types";
import { PmFormValue } from "common/scheduled-event/form/pm/types";
import { Context } from "common/types/context";
import { ScheduledEventRecord } from "common/types/scheduled-event";
import { ValueComponent } from "common/with-value-for";
import {
  getAssetIdCol,
  getMeterEntity,
  getWorkOrderEntity,
} from "../functions";
import { ExternalForm } from "./external";
import { InspectionForm } from "./inspection";
import { PmForm } from "./pm";
import { DataHandler } from "./projections";

interface PropTypes extends FormValidationProps {
  context: Context;
  entity: Entity;
  isNew: boolean;
  auditTrailId: string;
}

const defaultValue = defaultFor<ScheduledEventValue>();
const defaultRecord = defaultFor<ScheduledEventRecord>();

export class EventScheduleForm extends ValueComponent<
  ScheduledEventValue,
  PropTypes
> {
  static readonly displayName = "EventScheduleForm";

  render() {
    const {
      context,
      entity,
      value = defaultValue,
      isNew,
      auditTrailId,
      formValidation,
      onFormValidationChange,
    } = this.props;
    const { entities, forms } = context;
    const { record = defaultRecord } = value;

    const workOrderEntityForTriggers = getWorkOrderEntity(entities, entity);
    const assetIdCol = getAssetIdCol(workOrderEntityForTriggers);
    const assetEntity = entities[assetIdCol.relatedEntity];
    const meterEntity = getMeterEntity(entities, entity);

    // TODO: rts
    const { workOrderForm } = record.properties;
    const formId = workOrderForm?.properties?.formId;
    const formLayout = formId ? forms.find((f) => f.id === formId) : undefined;

    const workOrderEntityForForms = omitColumn(
      getWorkOrderEntity(entities, entity),
      assetIdCol.name,
    );

    const layout = formLayout
      ? formLayout.settings
      : createDefaultLayout(workOrderEntityForForms, context, undefined, true);
    const layoutWithoutAssetId = omitLayoutColumn(layout, assetIdCol.name);

    return (
      <div className="x-scheduled-event-form">
        {record.properties.type === "P" || record.properties.type === "I" ? (
          <DataHandler
            apiCall={context.apiCall}
            entities={context.entities}
            entity={entity}
            scheduledEvent={record}
          />
        ) : (
          <span />
        )}

        {record.properties.type === "P" ? (
          <PmForm
            context={context}
            entity={entity}
            assetEntity={assetEntity}
            woEntity={workOrderEntityForTriggers}
            meterEntity={meterEntity}
            layout={layoutWithoutAssetId}
            isNew={isNew}
            auditTrailId={auditTrailId}
            formValidation={formValidation}
            onFormValidationChange={onFormValidationChange}
            value={value as PmFormValue}
            onChange={this.onChangeSetValue}
          />
        ) : undefined}

        {record.properties.type === "I" ? (
          <InspectionForm
            context={context}
            entity={entity}
            assetEntity={assetEntity}
            woEntity={workOrderEntityForTriggers}
            layout={layoutWithoutAssetId}
            isNew={isNew}
            auditTrailId={auditTrailId}
            formValidation={formValidation}
            onFormValidationChange={onFormValidationChange}
            value={value as InspectionFormValue}
            onChange={this.onChangeSetValue}
          />
        ) : undefined}

        {record.properties.type === "E" ? (
          <ExternalForm
            context={context}
            entity={entity}
            assetEntity={assetEntity}
            woEntity={workOrderEntityForTriggers}
            layout={layoutWithoutAssetId}
            isNew={isNew}
            auditTrailId={auditTrailId}
            formValidation={formValidation}
            onFormValidationChange={onFormValidationChange}
            value={value as ExternalFormValue}
            onChange={this.onChangeSetValue}
          />
        ) : undefined}
      </div>
    );
  }
}
