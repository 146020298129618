import * as R from "ramda";
import { isRangeValid } from "common/date-time/validators";
import {
  isRelatedSiteDataColumn,
  isRelatedUserDataColumn,
} from "common/entities/entity-column/functions";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { getFkId } from "common/functions/foreign-key";
import { RelatedUiValue } from "common/record/types";
import { FkEventValues, Properties } from "common/types/records";
import { EventProperties } from "./types";

export const getForeignKeyValues = (
  entity: Entity,
  props: EventProperties,
): FkEventValues => {
  if (!props || !entity) return undefined;

  const fkColumns = entity.columns.filter(
    (c) =>
      !isRelatedSiteDataColumn(c) &&
      !!c.relatedEntity &&
      c.required &&
      !isRelatedUserDataColumn(c),
  );

  const fkValues = fkColumns.reduce((acc: FkEventValues, c: EntityColumn) => {
    const value = props[c.name];
    return value ? { ...acc, [c.name]: getFkId(value) } : acc;
  }, {});

  return fkColumns.length === R.keys(fkValues).length ? fkValues : undefined;
};

export const getUiFormProperties = (
  related: RelatedUiValue,
  entityName: string,
) => {
  const assignments = related?.form?.[entityName] || [];
  return assignments.map((a) => a.properties);
};

export const propertiesToEventProperties = (
  properties: Properties,
): EventProperties =>
  ({
    ...properties,
    rangeFrom: properties.rangeFrom || "",
    rangeTo: properties.rangeTo || "",
  }) as EventProperties;

export const isValid = (value: Properties) => {
  if (!value) return false;
  const { rangeFrom, rangeTo } = value;

  return isRangeValid(rangeFrom, rangeTo);
};
