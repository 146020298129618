import * as R from "ramda";

export const Weekdays = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
} as const;

type WeekdaysMap = typeof Weekdays;
export type WeekdayName = keyof WeekdaysMap;
export type WeekdayNumber = WeekdaysMap[keyof WeekdaysMap];

export const weekdayNumbers = [
  Weekdays.Sunday,
  Weekdays.Monday,
  Weekdays.Tuesday,
  Weekdays.Wednesday,
  Weekdays.Thursday,
  Weekdays.Friday,
  Weekdays.Saturday,
];

export const getWeekdayNameFromNumber = (weekdayNumber: WeekdayNumber) =>
  R.invertObj(Weekdays)[weekdayNumber] as WeekdayName;

export const weekdayNames = weekdayNumbers.map(getWeekdayNameFromNumber);
