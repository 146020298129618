import { displayEntity } from "common/api/entities";
import { Context } from "common/types/context";
import { SearchRecord } from "../types";
import { SearchListItem } from ".";

interface PropTypes {
  context: Context;
  record: SearchRecord;
  textToHighlight: string;
  onClickRecord: () => void;
}

export const DocumentSearchItem = ({
  context,
  record,
  textToHighlight,
  onClickRecord,
}: PropTypes) => {
  const { site, id, documentEntityName } = record;
  const hrefSite = site ?? context.site.name;
  const url = `/#/${hrefSite}/${documentEntityName}/${id}?tab=documents`;
  const entity = context.entities[documentEntityName];
  const entityLabel = `${_("Documents")} - ${displayEntity(entity)}`;

  return (
    <SearchListItem
      record={record}
      textToHighlight={textToHighlight}
      onClickRecord={onClickRecord}
      url={url}
      iconClass="fa-file"
      entityLabel={entityLabel}
    />
  );
};
