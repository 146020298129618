export const EN_US = "en-US";

// Language codes from https://en.wikipedia.org/wiki/IETF_language_tag
const CULTURES = {
  "bg-BG": "Bulgarian",
  "cs-CZ": "Czech",
  "de-DE": "German (Germany)",
  [EN_US]: "English (US)",
  "es-AR": "Spanish (Argentina)",
  et: "Estonian",
  "fr-FR": "French (France)",
  "hu-HU": "Hungarian",
  "id-ID": "Indonesian",
  "it-IT": "Italian",
  "ja-JP": "Japanese",
  "ka-GE": "Georgian",
  "kk-KZ": "Kazakh",
  "ko-KR": "Korean",
  "lv-LV": "Latvian",
  "ms-MY": "Malay (Malaysia)",
  "nl-NL": "Dutch (Netherlands)",
  nb: "Norwegian",
  "pl-PL": "Polish",
  "pt-BR": "Portuguese (Brazil)",
  "ro-RO": "Romanian",
  "ru-RU": "Russian",
  sl: "Slovenian",
  sr: "Serbian(Latin)",
  "sv-SE": "Swedish (Sweden)",
  "th-TH": "Thai",
  "tr-TR": "Turkish",
  "uk-UA": "Ukrainian",
  "vi-VN": "Vietnamese",
  "zh-CN": "Chinese (Simplified)",
  // 'ar': 'Arabic', // right to left
  // 'ca': 'Catalan',
  // 'cy': 'Welsh',
  da: "Danish",
  // 'el': 'Greek',
  // 'en-AU': 'English (Australia)',
  // 'en-CA': 'English (Canada)',
  // 'en-GB': 'English (United Kingdom)',
  // 'en-IE': 'English (Ireland)',
  // 'en-NZ': 'English (New Zealand)',
  // 'en-ZA': 'English (South Africa)',
  // 'es-BO': 'Spanish (Bolivia)',
  // 'es-CL': 'Spanish (Chile)',
  // 'es-CO': 'Spanish (Colombia)',
  // 'es-CR': 'Spanish (Costa Rica)',
  // 'es-DO': 'Spanish (Dominican Republic)',
  // 'es-EC': 'Spanish (Ecuador)',
  // 'es-ES': 'Spanish (Spain)',
  // 'es-GT': 'Spanish (Guatemala)',
  // 'es-HN': 'Spanish (Honduras)',
  // 'es-MX': 'Spanish (Mexico)',
  // 'es-NI': 'Spanish (Nicaragua)',
  // 'es-PA': 'Spanish (Panama)',
  // 'es-PE': 'Spanish (Peru)',
  // 'es-PR': 'Spanish (Puerto Rico)',
  // 'es-PY': 'Spanish (Paraguay)',
  // 'es-SV': 'Spanish (El Salvador)',
  // 'es-UY': 'Spanish (Uruguay)',
  // 'es-VE': 'Spanish (Venezuela)',
  // 'et': 'Estonian',
  // 'fi': 'Finnish',
  // 'fr-CA': 'French (Canada)',
  // he: "Hebrew", // right to left
  "he-IL": "Hebrew (Israel) (Beta)",
  // 'hi': 'Hindi',
  // 'hr-HR': 'Croatian (Croatia)',
  // 'nl-BE': 'Dutch (Belgium)',
  // 'pt-PT': 'Portuguese (PT)',
  sk: "Slovak",
  // 'sv-FI': 'Swedish (Finland)',
  // 'zh-HK': 'Chinese (Hong Kong)',
  // 'zh-MO': 'Chinese (Macau)',
  // 'zh-SG': 'Chinese (Singapore)',
  "zh-TW": "Chinese (Traditional)",
} as const;

export type Culture = keyof typeof CULTURES;

export interface SupportedCulture {
  name: Culture;
  language: string;
}

export const SUPPORTED_CULTURES: SupportedCulture[] = Object.keys(CULTURES).map(
  (name: Culture) => ({ name, language: CULTURES[name] }),
);

export const translateCulture = (culture: Culture) => CULTURES[culture];
