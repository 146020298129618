import * as R from "ramda";
import { Entities } from "common/entities/types";
import { UiFormat } from "common/types/ui-format";
import { EventInfo } from "./event-info";
import { Event } from "./types";

interface PropTypes {
  entities: Entities;
  site: string;
  events: Event[];
  uiFormat: UiFormat;
}

export const EventConflicts = ({
  entities,
  site,
  events,
  uiFormat,
}: PropTypes) => {
  return (
    <div className="x-event-conflicts">
      <div>{`${_("Resource availability issues")}:`}</div>
      {R.sortBy((event) => event && event.rangeFrom, events).map(
        (event: Event, i: number) =>
          event && (
            <EventInfo
              key={i}
              entities={entities}
              site={site}
              event={event}
              uiFormat={uiFormat}
            />
          ),
      )}
    </div>
  );
};

EventConflicts.displayName = "EventConflicts";
