import { Component } from "react";
import { Props } from "./types";
import { createItems } from "./items";

import "./index.scss";

// If you want the component to be reorderable/dragable the dragId is required

export function createList<TItem>(dragId?: string) {
  const Items = createItems<TItem>(dragId);

  return class List extends Component<Props<TItem>> {
    render() {
      return <Items {...this.props} />;
    }
  };
}
