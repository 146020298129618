import * as R from "ramda";
import { Component } from "common/component";
import {
  ChartElement,
  ChartOptions,
  ChartValidationStatus,
} from "common/vendor-wrappers/chartjs/types";
import { LazyChartjs } from "common/vendor-wrappers/chartjs";
import { toCamelCase } from "common";
import { ChartConfig, GetData } from "./types";
import {
  isArrayOfNumbers,
  oneDimensionChart,
  MULTI_SERIES,
  CHART_DIMENSION,
  twoDimensionChart,
  twoDimensionChartMultiseries,
} from "./functions";

interface PropTypes {
  data: any[];
  config: ChartConfig;
  onElementsClick?: (o: ChartElement[]) => void;
}

export class Chart extends Component<PropTypes> {
  static readonly displayName = "Chart";

  getData = () => {
    const { data, config } = this.props;
    const oneDimensional = CHART_DIMENSION[config.type] === 1;

    const fn: GetData = oneDimensional
      ? oneDimensionChart
      : this.isMulti()
        ? twoDimensionChartMultiseries
        : twoDimensionChart;

    return fn(data, config);
  };
  validate = (): ChartValidationStatus => {
    const { data, config } = this.props;

    if (!data) return "invalid";
    if (!data.length) return "empty";
    if (!this.isMulti())
      return isArrayOfNumbers(config, data) ? "ok" : "invalid";
    if (!config.series) return "ok";
    if (config.series.length !== data.length) return "invalid";
    if (R.all((d) => d.length === 0, data)) return "empty";

    const configAndData = R.zip(config.series, data);
    return R.all(([c, d]) => isArrayOfNumbers(c, d), configAndData)
      ? "ok"
      : "invalid";
  };
  isMulti = () => {
    const { type, series } = this.props.config;
    return series && R.includes(type, MULTI_SERIES);
  };
  getOptions = (): ChartOptions => {
    const { type, hideTooltipTitle } = this.props.config;
    const display = R.includes(type, ["Pie", "Doughnut", "PolarArea"]);
    return {
      legend: { display },
      // it needs be false to be able set the height of charts in group site
      maintainAspectRatio: false,
      tooltips: {
        titleFontSize: hideTooltipTitle ? 0 : 12,
        custom: (tooltip: any) => {
          const { body = [] }: { body: any[] } = tooltip;
          const [firstBody = {}] = body;
          const { lines = [] }: { lines: any[] } = firstBody;
          const [firstLine] = lines;
          if (firstLine) {
            lines[0] = firstLine.replace(/^: /, "");
          }
          return tooltip;
        },
      },
      responsive: true,
      legendCallback: () =>
        '<ul class="<%=name.toLowerCase()%>-legend list-unstyled">' +
        "<% for (var i=0; i<segments.length; i++){%>" +
        "<%if(segments[i].value > 0){%>" +
        '<li><span style="background-color:<%=segments[i].fillColor%>" ' +
        'class="chart-legend-item">&nbsp;&nbsp;&nbsp;</span>' +
        "&nbsp;" +
        "<%if(segments[i].label)" +
        "{%><%=segments[i].label%><%}%>" +
        "</li>" +
        "<%}%>" +
        "<%}%>" +
        "</ul>",
    };
  };

  render() {
    const { config, onElementsClick } = this.props;

    const chartProps = {
      data: this.getData(),
      onElementsClick,
      options: config.options
        ? (R.mergeDeepLeft(this.getOptions(), config.options) as ChartOptions)
        : this.getOptions(),
    };

    const status = this.validate();

    if (status === "invalid") {
      return (
        <div className="bg-danger text-center x-padding-10">
          {_("Invalid data")}
        </div>
      );
    }
    if (status === "empty") {
      return (
        <div className="bg-danger text-center x-padding-10">{_("No data")}</div>
      );
    }

    // hack to not break old reports
    const camelType: any = toCamelCase(config.type);

    return <LazyChartjs type={camelType} {...chartProps} />;
  }
}
