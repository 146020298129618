import { Component } from "react";
import { commentsApi } from "common/api/comments";
import { Entity } from "common/entities/types";
import { Comment } from "common/types/comments";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { ApiError } from "common/ui/api-error";
import { LoadingIcon } from "common/widgets/loading-icon";
import { ValueProps } from "common/with-value-for";
import { hasRolePermissionTo } from "common/functions/roles";
import { CommentsList } from "./comment-list";
import { CommentsInput } from "./comments-input";
import { getComment, markNewComments, PAGE_SIZE } from "./functions";

interface PropTypes extends ValueProps<Comment> {
  context: Context;
  entity: Entity;
  recordId: string;
  onCountChange: (count: number) => void;
}

interface StateType {
  comments: Comment[];
  error: ApiErrorResponse;
  isLastPage: boolean;
  isLoading: boolean;
  offset: number;
}

const ADD_COMMENTS = "AddComments";

export class CommentsController extends Component<PropTypes, StateType> {
  static readonly displayName = "CommentsController";

  state: StateType = {
    comments: [],
    error: undefined,
    isLastPage: false,
    isLoading: false,
    offset: 0,
  };

  componentDidMount() {
    this.loadComments();
  }

  loadComments = (offset: number = 0, isReload: boolean = false) => {
    const { context, entity, recordId, onCountChange } = this.props;

    this.setState({ isLoading: true, offset });
    commentsApi(context.apiCall)
      .get(entity.name, recordId, offset)
      .then((pagedComments) => {
        const { comments, count } = pagedComments;
        this.setState((state) => ({
          comments: isReload ? comments : state.comments.concat(comments),
          isLoading: false,
          isLastPage: comments.length < PAGE_SIZE,
        }));
        onCountChange(count);
      })
      .catch((error: ApiErrorResponse) =>
        this.setState({ isLoading: false, error }),
      );
  };

  reloadComments = () => this.loadComments(0, true);

  saveComment = () => {
    const { context, recordId, entity, value } = this.props;

    this.setState({ isLoading: true });
    commentsApi(context.apiCall)
      .post(entity.name, recordId, value)
      .then(this.fetchComments)
      .catch((error) => this.setState({ isLoading: false, error }));
  };

  fetchComments = () => {
    const { context, recordId, entity, onChange, onCountChange } = this.props;
    const { comments } = this.state;
    const createdOn = comments[0]?.createdOn;
    const lastCommentDate = createdOn
      ? encodeURIComponent(createdOn)
      : undefined;

    commentsApi(context.apiCall)
      .get(entity.name, recordId, 0, 10, lastCommentDate)
      .then((pagedComments) => {
        const { count = 0, comments } = pagedComments;
        this.setState((state) => {
          const newComments = markNewComments(comments, context.id).concat(
            state.comments.map((oldComment) => {
              const { isNew, ...rest } = oldComment;
              return rest;
            }),
          );

          return {
            comments: newComments,
            isLoading: false,
            error: undefined,
          };
        });

        onCountChange(count);
      })
      .catch((error) => this.setState({ isLoading: false, error }));

    onChange(getComment(entity.name, recordId));
  };

  delete = (commentId: string) => {
    const { context, entity } = this.props;

    this.setState({ isLoading: true });
    commentsApi(context.apiCall)
      .delete(entity.name, commentId)
      .then(() =>
        this.setState((state) => ({
          comments: state.comments.filter((c) => c.id !== commentId),
          error: undefined,
          isLoading: false,
        })),
      )
      .catch((error) => this.setState({ isLoading: false, error }));
  };

  onShowMore = () => this.loadComments(this.state.offset + PAGE_SIZE);

  render() {
    const { context, entity, value, onChange } = this.props;
    const { role } = context;
    const { comments, isLastPage, error, isLoading } = this.state;

    const canAddComments = hasRolePermissionTo(role, entity.name, ADD_COMMENTS);

    return (
      <div className="x-comments-container">
        {error ? <ApiError error={error} /> : undefined}
        {isLoading ? <LoadingIcon /> : undefined}
        <CommentsInput
          context={context}
          canAddComments={canAddComments}
          onSave={this.saveComment}
          onReload={this.reloadComments}
          value={value}
          onChange={onChange}
        />
        <CommentsList
          context={context}
          comments={comments}
          isLastPage={isLastPage}
          onDelete={this.delete}
          onShowMore={this.onShowMore}
        />
      </div>
    );
  }
}
