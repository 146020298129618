import { Component } from "react";
import { Context } from "common/types/context";
import { defaultFor } from "common";
import { deepEqual } from "common/component";
import { OnRemoveCreator } from "common/query-builder/condition/types";
import { ValueProps, OnChange } from "common/with-value-for";
import {
  FilterAnd,
  FilterOr,
  isSubQuery,
  isRule,
  QueryForEntity,
  GroupFilter,
  ConditionFilter,
} from "common/query/types";
import { ConditionTypes } from "common/types/condition-type";
import { PathMap } from "common/query/advanced-types";
import { filterBlackList } from "common/entities/entity-column/data-type/types";
import { omitDataTypes } from "common/entities/entity-column/data-type/functions";
import { getPathMap } from "common/query/joins";
import { Field, GroupItem } from "../types";
import { fieldsEqual, getFields } from "../functions";
import { getFilterErrors } from "../validation";
import { mapToMainEntityField } from "./functions";
import { Rule } from "./rule";
import { Group } from "./group";
import { Exists } from "./exists";

export interface PropTypes {
  className?: string;
  fields: Field[];
  allowAggregates: boolean;
  disableNestedGroups?: boolean;
  context: Context;
  onRemoveGroup?: () => void;
  entityName: string;
  pathMap: PathMap;
  conditionTypes?: ConditionTypes;
  disableSubQueries?: boolean;
  allowFiltersOnUnboundQueries?: boolean;
  withExpressions?: boolean;
  mainEntityFields?: Field[];
}

export type InnerProps = PropTypes & ValueProps<GroupItem>;

export class InnerCondition extends Component<InnerProps> {
  static readonly displayName = "InnerCondition";

  shouldComponentUpdate = (newProps: InnerProps) => {
    const { props } = this;
    return (
      !deepEqual(props, newProps, ["fields"]) ||
      !fieldsEqual(props.fields, newProps.fields)
    );
  };

  onGroupChanged = (group: GroupFilter) => {
    this.props.onChange({
      ...this.props.value,
      filter:
        group && ((group as FilterAnd).and || (group as FilterOr).or)
          ? group
          : undefined,
    });
  };

  onRuleChanged = (filter: ConditionFilter) => {
    this.props.onChange({ ...this.props.value, filter });
  };

  getConditionProps = (item: GroupItem) => {
    const {
      context,
      fields,
      pathMap,
      disableSubQueries,
      entityName,
      value,
      mainEntityFields = [],
    } = this.props;
    const { entities } = context;
    const { filter } = item;

    if (!isSubQuery(filter)) {
      const entity =
        disableSubQueries && isSubQuery(value.filter)
          ? value.filter.queryValue.entity
          : entityName;

      return {
        disableSubQueries,
        entity,
        fields,
        mainEntityFields,
        pathMap,
      };
    }

    const query: QueryForEntity = {
      entity: filter.queryValue?.entity,
      query: { joins: filter.queryValue?.joins || [], select: [] },
    };

    const newFields = getFields(entities, query);
    const newPathMap = getPathMap(entities, query);
    const filteredFields = omitDataTypes(newFields, filterBlackList);
    const newMainEntityFields = mainEntityFields.length
      ? mainEntityFields
      : fields
          .filter((field) => field.entityName === entityName)
          .map(mapToMainEntityField);

    return {
      disableSubQueries: true,
      entity: entityName,
      fields: filteredFields,
      mainEntityFields: newMainEntityFields,
      pathMap: newPathMap,
    };
  };

  // TODO Declare this component outside parent component "InnerCondition" or memoize it.
  renderGroupItem =
    (onRemove: OnRemoveCreator) =>
    // eslint-disable-next-line react/no-unstable-nested-components
    (item: GroupItem, onChange: OnChange<GroupItem>, index: number) => {
      const {
        context,
        allowAggregates,
        disableNestedGroups,
        conditionTypes,
        allowFiltersOnUnboundQueries,
        withExpressions,
      } = this.props;

      const { fields, mainEntityFields, pathMap, disableSubQueries, entity } =
        this.getConditionProps(item);

      return (
        <InnerCondition
          pathMap={pathMap}
          context={context}
          fields={fields}
          mainEntityFields={mainEntityFields}
          allowAggregates={allowAggregates}
          disableNestedGroups={disableNestedGroups}
          onRemoveGroup={onRemove(index)}
          entityName={entity}
          conditionTypes={conditionTypes}
          disableSubQueries={disableSubQueries}
          allowFiltersOnUnboundQueries={allowFiltersOnUnboundQueries}
          withExpressions={withExpressions}
          value={item}
          onChange={onChange}
        />
      );
    };

  renderFilterCondition = () => {
    const {
      fields,
      mainEntityFields,
      allowAggregates,
      disableNestedGroups,
      context,
      onRemoveGroup,
      value = defaultFor<GroupItem>(),
      entityName,
      conditionTypes,
      pathMap,
      disableSubQueries,
      allowFiltersOnUnboundQueries,
      withExpressions,
    } = this.props;
    const { filter } = value;

    if (isRule(filter)) {
      return (
        <Rule
          pathMap={pathMap}
          fields={fields}
          context={context}
          allowAggregates={allowAggregates}
          entityName={entityName}
          conditionTypes={conditionTypes}
          withExpressions={withExpressions}
          errors={getFilterErrors(filter)}
          mainEntityFields={mainEntityFields}
          value={filter}
          onChange={this.onRuleChanged}
        />
      );
    }

    if (isSubQuery(filter)) {
      return (
        <Exists
          context={context}
          fields={fields}
          parentEntity={entityName}
          disableNestedGroups={disableNestedGroups}
          renderGroupItem={this.renderGroupItem}
          onRemoveGroup={onRemoveGroup}
          allowUnboundQueries={allowFiltersOnUnboundQueries}
          value={filter}
          onChange={this.onRuleChanged}
        />
      );
    }

    return (
      <Group
        fields={fields}
        onRemoveGroup={onRemoveGroup}
        disableNestedGroups={disableNestedGroups}
        disableSubQueries={disableSubQueries}
        renderGroupItem={this.renderGroupItem}
        value={filter}
        onChange={this.onGroupChanged}
      />
    );
  };

  render() {
    const { className, value = defaultFor<GroupItem>() } = this.props;
    const { operator } = value;

    return (
      <div className={`x-flex ${className || ""}`}>
        <div className="x-condition-operator x-padding-right-10">
          {operator === "and" ? _("AND") : operator === "or" ? _("OR") : ""}
        </div>
        {this.renderFilterCondition()}
      </div>
    );
  }
}
