import * as React from "react";
import { ReactHighlightWords } from "common/vendor-wrappers/react-highlight-words";
import { arrayToString } from "common/utils/array";
import { TriStateCheckbox } from "common/widgets/checkbox/tri-state";
import { NodeLinkPropTypes } from "./types";

export const NodeLink = ({
  node,
  textToHighlight,
  isDisabled,
  isSelected,
  allowMultipleSelection,
  hasSomeChildSelected,
  onClick,
}: NodeLinkPropTypes) => {
  if (!node) return null;

  const className = arrayToString([
    "x-tree-node-link",
    !allowMultipleSelection && isSelected ? "selected" : undefined,
    isDisabled ? "x-disabled" : undefined,
    `qa-${node.name}`,
  ]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.code === "Enter") {
      onClick?.();
    }
  };

  const onLinkClick = () => {
    onClick?.();
  };

  return node ? (
    <>
      {allowMultipleSelection ? (
        <TriStateCheckbox
          isDisabled={isDisabled}
          hasSomeChildSelected={hasSomeChildSelected}
          label={node.label}
          value={isSelected}
          onChange={onClick}
        />
      ) : undefined}
      {isDisabled ? (
        <span className={className}>
          <ReactHighlightWords text={node.label} search={textToHighlight} />
        </span>
      ) : (
        <button
          onKeyDown={onKeyDown}
          onClick={onLinkClick}
          className={className}
        >
          <ReactHighlightWords text={node.label} search={textToHighlight} />
        </button>
      )}
    </>
  ) : null;
};
