import { defaultFor } from "common";
import { Labels } from "common/culture/types";
import { Record } from "common/types/records";
import { ForeignKey } from "common/types/foreign-key";
import { ImgSize, Pin } from "common/vendor-wrappers/leaflet/types";
import { SystemIntFkValue } from "common/types/system-int";

export interface InteractiveMapProperties {
  id: string;
  image: string;
  description: string;
  geographical: boolean;
  pins: Pin[];
  templateId: SystemIntFkValue;
  labels: Labels;
}

export interface InteractiveMapRecord extends Record {
  properties: InteractiveMapProperties;
}

export interface InteractiveMapValue {
  record: InteractiveMapRecord;
  pinEntity: string;
  pinRecord: ForeignKey;
  selectedPin: Pin;
  imgSize: ImgSize;
}

export const defaultRecord = defaultFor<InteractiveMapRecord>();
export const defaultProperties = defaultFor<InteractiveMapProperties>();
export const defaultValue = defaultFor<InteractiveMapValue>();

export const getNewPin = (
  value: InteractiveMapValue,
  width: number,
  height: number,
): Pin => ({
  lat: height ? height / 2 : 200,
  lng: width ? width / 2 : 200,
  layer: value.pinEntity,
  id: value.pinRecord.id,
});
