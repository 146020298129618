import { ValueComponent } from "common/with-value-for";
import { LazySignatureInput } from "common/vendor-wrappers/signature";
import { LoadingIcon } from "../loading-icon";
import { Sizes, Sizer } from "../sizer";
import { SignatureImage } from "./image";

interface PropTypes {
  isSigned: boolean;
}

export class Signature extends ValueComponent<string, PropTypes> {
  static readonly displayName = "Signature";

  renderSignature = ({ loading, width }: Sizes) => {
    const { value, isSigned } = this.props;
    return loading ? (
      <LoadingIcon />
    ) : isSigned ? (
      <SignatureImage value={this.props.value} width={width} />
    ) : (
      <LazySignatureInput
        width={width}
        value={value}
        onChange={this.onChangeSetValue}
      />
    );
  };

  render() {
    return <Sizer render={this.renderSignature} />;
  }
}
