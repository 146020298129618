export type DataType =
  | "uniqueidentifier"
  | "int"
  | "uint"
  | "date"
  | "datetime"
  | "dateoffset"
  | "document"
  | "currency"
  | "float"
  | "ufloat"
  | "bool"
  | "string"
  | "email"
  | "image"
  | "text"
  | "geolocation"
  | "fk"
  | "systemintfk"
  | "systemstringfk"
  | "hidden"
  | "hyperlink"
  | "html"
  | "signature"
  | "media"
  | "object"
  | "array";

export const types: DataType[] = [
  "array",
  "bool",
  "currency",
  "date",
  "datetime",
  "document",
  "email",
  "fk",
  "float",
  "geolocation",
  "hidden",
  "html",
  "hyperlink",
  "image",
  "int",
  "media",
  "object",
  "signature",
  "string",
  "systemintfk",
  "systemstringfk",
  "text",
  "ufloat",
  "uint",
  "uniqueidentifier",
];

export const customBlackList: DataType[] = [
  "array",
  "geolocation",
  "hidden",
  "signature",
  "systemintfk",
  "systemstringfk",
  "uniqueidentifier",
  "ufloat",
  "uint",
  "object",
];

export const dataTypesWithDynamicValues: DataType[] = [
  "date",
  "datetime",
  "email",
  "fk",
];

export const formBlacklist: DataType[] = [
  "array",
  "geolocation",
  "signature",
  "object",
];

export const headerFormBlackList: DataType[] = [
  "array",
  "image",
  "text",
  "geolocation",
  "hidden",
  "html",
  "signature",
  "object",
];

export const filterBlackList: DataType[] = [
  "array",
  "geolocation",
  "hidden",
  "hyperlink",
  "html",
  "signature",
  "image",
  "media",
  "object",
];

export const selectorFilterTypes: DataType[] = [
  "string",
  "text",
  "email",
  "int",
  "uint",
  "uniqueidentifier",
];

export const noValidationFilters: DataType[] = [
  "text",
  "email",
  "hyperlink",
  "document",
];

export const queryBuilderBlackList: DataType[] = ["object", "array"];

export const displayFieldsBlackList: DataType[] = [
  "array",
  "text",
  "geolocation",
  "signature",
  "html",
  "hidden",
  "hyperlink",
  "image",
  "document",
  "object",
];

export const workflowReplaceBlacklist: DataType[] = [
  "array",
  "geolocation",
  "hidden",
  "object",
  "signature",
  "uniqueidentifier",
];

export const useExpressionBlackList: DataType[] = [
  "array",
  "document",
  "geolocation",
  "hidden",
  "image",
  "media",
  "object",
  "signature",
  "uniqueidentifier",
];

export const canBeUniqueList: DataType[] = ["string", "email"];

export const numericTypes: DataType[] = [
  "int",
  "uint",
  "float",
  "ufloat",
  "currency",
];

export const decimalTypes: DataType[] = ["float", "ufloat", "currency"];

export const dataTypesWithEllipsis: DataType[] = [
  "currency",
  "date",
  "datetime",
  "email",
  "float",
  "geolocation",
  "hyperlink",
  "int",
  "systemintfk",
  "systemstringfk",
  "text",
  "ufloat",
  "uint",
];

export const emailNotificationWhiteList: DataType[] = ["email"];
