import { isAssignmentEvent } from "common/functions/scheduler";
import type { SchedulerEvent } from "common/types/scheduler";
import { arrayToString } from "common/utils/array";
import type {
  CalendarEventRendererDetail,
  SchedulerEventRendererDetail,
} from "x/scheduler2/types";

const getConflictIcon = (event: SchedulerEvent) =>
  event?.hasConflicts
    ? `<i class="fa fa-warning" title="${_("Assignment conflict")}"></i>`
    : undefined;

const getMultipleAssignmentIcon = (event: SchedulerEvent) =>
  isAssignmentEvent(event) && event.isMultiple
    ? `<i class="fa fa-users" title="${_("Multiple assignments")}"></i>`
    : undefined;

const getEventRenderer = (eventData: SchedulerEvent, eventName: string) => {
  if (!eventData) return eventName ?? "";

  const conflictIcon = getConflictIcon(eventData);
  const multipleAssignmentIcon = getMultipleAssignmentIcon(eventData);

  return arrayToString([conflictIcon, multipleAssignmentIcon, eventName]);
};

export const schedulerEventRenderer = ({
  eventRecord,
}: SchedulerEventRendererDetail) =>
  getEventRenderer(eventRecord.eventData, eventRecord.name);

export const calendarEventRenderer = ({
  eventRecord,
}: CalendarEventRendererDetail) =>
  getEventRenderer(eventRecord.eventData, eventRecord.name);
