import { Component } from "react";
import { defaultFor } from "common";
import { canAccessQueryEntities } from "common/query/common";
import { Context } from "common/types/context";
import { hasPermissionToUpdate } from "common/functions/roles";
import { LinkButton } from "common/ui/buttons";
import { Highlight } from "common/widgets/highlight";
import { ListItemCell } from "common/widgets/list-with-search/cell";
import { ListItem } from "common/widgets/list-with-search/item";
import { MiniReport } from "x/reports/list/types";
import { EmailReport } from "./email-report";
import { ExportReport } from "./export-report";

interface PropTypes {
  report: MiniReport;
  editPath: string;
  previewPath: string;
  context: Context;
}
export class ReportItem extends Component<PropTypes> {
  render() {
    const {
      context,
      report = defaultFor<MiniReport>(),
      editPath,
      previewPath,
    } = this.props;
    const { entities, role, tenant, userTypes } = context;

    const hasEntityPermission = canAccessQueryEntities(entities, report);
    const canEdit = hasPermissionToUpdate(userTypes, role, "Reports");

    const actions = [
      <ExportReport
        key={"exportDropdown"}
        className="x-inline-block x-margin-left-10 qa-export-dropdown"
        context={context}
        report={report}
      />,

      tenant.enableOutboundEmails ? (
        <EmailReport
          key={"emailDropdown"}
          className="x-inline-block x-margin-left-10 qa-email-dropdown"
          context={context}
          report={report}
        />
      ) : undefined,

      <LinkButton
        key={"preview"}
        className="x-margin-left-10 qa-preview-new-tab"
        href={`${previewPath}?hidePanels=true`}
        target="_blank"
        title={_("Preview in a new tab")}
      >
        <i className="fa fa-share-square-o x-margin-right-5" />
        {_("Preview")}
      </LinkButton>,
    ];

    return (
      <ListItem
        className="x-report-item"
        editPath={
          context.isEnabledFeature("report") && hasEntityPermission
            ? editPath
            : undefined
        }
        label={report.label}
        actions={hasEntityPermission ? actions : undefined}
        linkLabel={canEdit ? undefined : _("View")}
      >
        {hasEntityPermission ? (
          <ListItemCell label={_("Entity")}>
            <Highlight text={report.entityLabel} />
          </ListItemCell>
        ) : (
          <ListItemCell label={_("Insufficient permissions")}>
            <span className="x-error">
              {_(
                "You cannot access required entities or columns to view this record, please contact the system administrator.",
              )}
            </span>
          </ListItemCell>
        )}
      </ListItem>
    );
  }
}
