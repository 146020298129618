import { Context } from "common/types/context";
import { Comment } from "common/types/comments";
import { arrayToString } from "common/utils/array";
import { ShowMore } from "common/widgets/show-more";
import { PAGE_SIZE } from "../functions";
import { CommentListItem } from "./comment-list-item";

interface PropTypes {
  className?: string;
  context: Context;
  comments: Comment[];
  isLastPage: boolean;
  onDelete: (commentId: string) => void;
  onShowMore: () => void;
}

export const CommentsList = ({
  context,
  comments,
  className,
  isLastPage,
  onDelete,
  onShowMore,
}: PropTypes) => {
  const classNames = arrayToString(["x-comments-list", className]);

  const commentsList = comments.map((comment) => {
    const { id } = comment;
    return (
      <CommentListItem
        context={context}
        key={id}
        comment={comment}
        onDelete={onDelete}
      />
    );
  });

  return (
    <div className={classNames}>
      {!isLastPage && comments.length >= PAGE_SIZE ? (
        <ShowMore action={onShowMore}>{commentsList}</ShowMore>
      ) : (
        commentsList
      )}
    </div>
  );
};

CommentsList.displayName = "CommentsList";
