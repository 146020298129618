import { returnAndNotify } from "common/api/with-notifications";
import { ApiCall } from "common/types/api";
import { DevExpressDashboard } from "common/types/dashboards";
import { showErrorNotification } from "common/ui/notification";
import { getError } from "./error";

export const devExpressApi = (apiCall: ApiCall) => ({
  list: () => apiCall<DevExpressDashboard[]>("get", "api/ui/devexpress/:site"),
  update: (dashboard: DevExpressDashboard) =>
    apiCall<void>("put", `api/ui/devexpress/${dashboard.id}`, dashboard)
      .then(returnAndNotify(_("The dashboard was successfully updated")))
      .catch((e) => {
        showErrorNotification(getError(e));
      }),
  remove: (id: number) =>
    apiCall<void>("delete", `api/ui/devexpress/${id}`)
      .then(returnAndNotify(_("The dashboard was successfully deleted")))
      .catch((e) => {
        showErrorNotification(getError(e));
      }),
});
