import { useEffect, useMemo, useRef, useState } from "react";
import Select, { Props } from "react-select";
import { arrayToString } from "common/utils/array";
import { createLimitedMenuList } from "common/vendor-wrappers/react-select/limited-menu-list";
import {
  filterOptions,
  sliceOptions,
} from "common/vendor-wrappers/react-select/functions";
import { LabelledOptionOrGroup } from "common/vendor-wrappers/react-select/types";
import {
  getCommonProps,
  MAX_SELECTOR_OPTIONS,
} from "common/vendor-wrappers/react-select/consts";

type PropTypes<T> = { optionsLimit?: number; label?: string } & Props<
  LabelledOptionOrGroup<T>
>;

export const ReactSelect = <T extends unknown>(props: PropTypes<T>) => {
  const [inputValue, setInputValue] = useState("");

  const selectorRef = useRef<HTMLDivElement>();

  const optionsLimit = props.optionsLimit ?? MAX_SELECTOR_OPTIONS;
  const { menuIsOpen } = props;

  useEffect(() => {
    if (menuIsOpen) {
      selectorRef.current.focus();
    }
  }, [menuIsOpen]);

  const filteredOptions = useMemo(() => {
    return filterOptions<T>(
      props.options as ReadonlyArray<LabelledOptionOrGroup<T>>,
      inputValue,
    );
  }, [inputValue, props.options]);

  const slicedOptions = useMemo(
    () =>
      sliceOptions<T>(
        filteredOptions as ReadonlyArray<LabelledOptionOrGroup<T>>,
        optionsLimit,
      ),
    [filteredOptions],
  );

  const isOptionDisabled = (
    option: LabelledOptionOrGroup<T>,
    options: LabelledOptionOrGroup<T>[],
  ) => {
    return (
      props.isOptionDisabled?.(option, options) ||
      (option as unknown as { isDisabled: boolean }).isDisabled
    );
  };

  return (
    <Select
      ref={selectorRef as any}
      {...getCommonProps(props)}
      aria-label={props.label}
      className={arrayToString(["x-selector", props.className])}
      options={slicedOptions}
      filterOption={() => true}
      isOptionDisabled={isOptionDisabled}
      components={{ MenuList: createLimitedMenuList(optionsLimit) }}
      value={props.value || null}
      onInputChange={setInputValue}
    />
  );
};
