type UpdateFn<T> = (item: T) => any;

type RemoveFn = () => any;

export interface WithList<T> {
  update: (index: number) => UpdateFn<T>;
  remove: (index: number) => RemoveFn;
}

export function withList<T>({
  update,
  remove,
}: {
  update: (index: number, item: T) => any;
  remove: (index: number) => any;
}) {
  const updateCache: { [index: number]: UpdateFn<T> } = {};
  const removeCache: { [index: number]: RemoveFn } = {};

  const withList: WithList<T> = {
    update: (i) =>
      updateCache[i] || (updateCache[i] = (item: T) => update(i, item)),
    remove: (i) => removeCache[i] || (removeCache[i] = () => remove(i)),
  };

  return withList;
}
