import * as R from "ramda";
import { isAssignmentEvent, isRelatedEvent } from "common/functions/scheduler";
import { ApiCall } from "common/types/api";
import { CancellablePromise } from "common/types/promises";
import {
  ContactAvailability,
  ContactEvents,
  EventsResponse,
  SchedulerFk,
  ViewType,
} from "common/types/scheduler";

const getStaff =
  (apiCall: ApiCall) =>
  (filterValueId?: string): CancellablePromise<SchedulerFk[]> => {
    const filterValue = filterValueId ? `?filterValue=${filterValueId}` : "";
    return apiCall("get", `api/scheduler/:site/staff${filterValue}`);
  };

const getEvents =
  (apiCall: ApiCall) =>
  (
    rangeFrom: string,
    rangeTo: string,
    view: ViewType,
    includeColorCoding: boolean,
    filterValueId: string,
    resourceId?: string | number,
  ): CancellablePromise<EventsResponse> =>
    apiCall("post", "api/scheduler/:site", {
      rangeFrom,
      rangeTo,
      view,
      includeColorCoding,
      filterValue: filterValueId,
      resourceId,
    });

const createAvailableHoursDictionary = (
  availableHours: ReadonlyArray<ContactAvailability>,
): { [contactId: string]: ContactAvailability } =>
  availableHours.reduce(
    (acc: any, availability: ContactAvailability) => ({
      ...acc,
      [availability.contactId]: availability,
    }),
    {},
  );

export const groupEventsByContactIds = ([staff, eventsResponse]: [
  SchedulerFk[],
  EventsResponse,
]): ContactEvents[] => {
  const eventsGroupByContactId = R.groupBy((event) => {
    if (isAssignmentEvent(event)) return event.targetId.id;
    if (isRelatedEvent(event)) return event.ownerId.id;
    return event.id;
  }, eventsResponse.events);

  const allAvailableHours = createAvailableHoursDictionary(
    eventsResponse.availableHours,
  );

  return staff.map((subForeignKey) => {
    const { assignedHours = 0, availableHours = 0 } =
      allAvailableHours[subForeignKey.id] || {};
    return {
      properties: {
        ...subForeignKey,
        assignedHours,
        availableHours,
      },
      events: eventsGroupByContactId[subForeignKey.id] || [],
    };
  });
};

export const schedulerApi = (apiCall: ApiCall) => ({
  getContactEvents: (
    rangeFrom: string,
    rangeTo: string,
    view: ViewType,
    includeColorCoding: boolean,
    filterValueId: string,
    filterResourceId?: string,
  ) =>
    CancellablePromise.all([
      getStaff(apiCall)(filterValueId),
      getEvents(apiCall)(
        rangeFrom,
        rangeTo,
        view,
        includeColorCoding,
        filterValueId,
        filterResourceId,
      ),
    ]).then(groupEventsByContactIds),
  getStaff: getStaff(apiCall),
  getEvents: getEvents(apiCall),
});
