import { returnAndNotify } from "common/api/with-notifications";
import { ApiCall } from "common/types/api";
import { CancellablePromise } from "common/types/promises";
import {
  PoDetails,
  ReceivePayload,
  ReturnPayload,
} from "common/types/purchase-orders";

export const purchaseOrdersApi = (apiCall: ApiCall) => ({
  getDetails: (
    entityName: string,
    recordId: string,
  ): CancellablePromise<PoDetails> =>
    apiCall("get", `api/entities/:site/${entityName}/${recordId}/details`),

  receiveItems: (
    entityName: string,
    recordId: string,
    value: ReceivePayload,
  ): CancellablePromise<void> =>
    apiCall(
      "put",
      `api/entities/:site/${entityName}/${recordId}/receive`,
      value,
    ).then(returnAndNotify(_("The purchase order was successfully received"))),

  returnItems: (
    entityName: string,
    recordId: string,
    value: ReturnPayload,
  ): CancellablePromise<void> =>
    apiCall(
      "put",
      `api/entities/:site/${entityName}/${recordId}/return`,
      value,
    ).then(returnAndNotify(_("The purchase order was successfully returned"))),

  closePo: (entityName: string, recordId: string): CancellablePromise<void> =>
    apiCall("put", `api/entities/:site/${entityName}/${recordId}/close`).then(
      returnAndNotify(_("The purchase order was successfully closed")),
    ),
});
