import * as R from "ramda";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import { SelectorOption } from "common/widgets/selector/types";
import { isSystemIntFk } from "common/functions/system-int";
import { SystemIntFk, SystemIntFkValue } from "common/types/system-int";
import { SystemTables } from "common/types/system-table";
import { ValueComponent } from "common/with-value-for";
import { Selector } from "../selector";
import { getSystemIntFkLabel } from "./label";

interface PropTypes {
  systemTables: SystemTables;
  tableName: string;
  placeholder?: string;
  allowClear?: boolean;
  disabled?: boolean;
  label?: string;
}

const getFkOptionLabel = (option: SelectorOption<SystemIntFk>) =>
  isGroupedOption(option) ? option.label : option.title;

export class SystemIntFkSelector extends ValueComponent<
  SystemIntFkValue,
  PropTypes
> {
  static readonly displayName = "SystemIntFkSelector";

  onChange = (fk: SystemIntFk) => {
    this.setValue(fk?.id);
  };

  render() {
    const {
      systemTables,
      tableName,
      allowClear,
      disabled,
      placeholder,
      label,
      value,
    } = this.props;
    const id = isSystemIntFk(value) ? value.id : value;
    const options: SystemIntFk[] =
      systemTables?.[tableName].map((value) => ({
        id: value.id,
        title: getSystemIntFkLabel(systemTables, tableName, value),
      })) || [];

    return (
      <Selector<SystemIntFk>
        allowClear={allowClear}
        disabled={disabled}
        options={options}
        getOptionLabel={getFkOptionLabel}
        placeholder={placeholder}
        label={label}
        value={R.find((o) => o.id === id, options)}
        onChange={this.onChange}
      />
    );
  }
}
