import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { Entity } from "common/entities/types";
import { PropertiesPayload } from "./payload/properties";

interface PropTypes {
  context: Context;
  entity: Entity;
  recordDetails: Properties;
  hideLinks?: boolean;
}

export const RecordDetails = ({
  context,
  entity,
  recordDetails,
  hideLinks,
}: PropTypes) => {
  if (!recordDetails) return <span />;
  return (
    <PropertiesPayload
      context={context}
      entity={entity}
      showEmpty={false}
      title={_("Details")}
      properties={recordDetails}
      hideLinks={hideLinks}
    />
  );
};
