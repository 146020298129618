import Switch from "react-switch";
import { arrayToString } from "common/utils/array";
import { ValueProps } from "common/with-value-for";

import "./index.scss";

interface PropTypes extends ValueProps<boolean> {
  disabled?: boolean;
  className?: string;
}

export const Toggle = ({ disabled, className, value, onChange }: PropTypes) => {
  const onSwitchChange = (value: boolean) => onChange(value);

  return (
    <Switch
      className={arrayToString([
        className,
        value ? "x-toggle-checked" : "x-toggle-unchecked",
      ])}
      height={22}
      width={44}
      disabled={!!disabled}
      onChange={onSwitchChange}
      checked={!!value}
    />
  );
};
