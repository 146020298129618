import * as R from "ramda";
import { ChartConfig } from "common/data/chart/types";
import { BoxConfig, Output } from "common/data/types";
import {
  Dashboard,
  LinkConfig,
  ReportConfig,
  Widget,
} from "common/types/dashboards";
import { DashboardFormType } from "x/dashboard/form/types";

export const defaultWidgetConfig = (name: string): Widget => ({
  label: undefined,
  name,
  site: undefined,
  type: "Link",
  config: {
    url: undefined,
    label: undefined,
  },
});

export const getName = (widgets: Widget[] = []): string =>
  widgets.length
    ? `widget.${
        widgets
          .map((w) => parseInt(w.name.replace("widget.", "")))
          .reduce((a, b) => (a > b ? a : b), 0) + 1
      }`
    : "widget.0";

const validateBoxConfig = (bc: BoxConfig) => bc && !!bc.label && !!bc.value;

const validateChartConfig = (cc: ChartConfig) =>
  cc && !!cc.type && !!cc.labels && !!cc.values;

const validateReportOutput = (o: Output) =>
  o &&
  !!o.type &&
  ((o.type !== "Box" && o.type !== "Chart") ||
    validateBoxConfig(o.config as BoxConfig) ||
    validateChartConfig(o.config as ChartConfig));

export const validateWidget = (w: Widget) =>
  w &&
  !!w.label &&
  ((w.type === "Report" &&
    !!w.config &&
    !!(w.config as ReportConfig).reportId &&
    validateReportOutput((w.config as ReportConfig).output)) ||
    (w.type === "Link" &&
      !!w.config &&
      !!(w.config as LinkConfig).url &&
      !!(w.config as LinkConfig).label));

export const validateDashboardName = (
  dashboard: Dashboard,
  dashboards: Dashboard[],
) => {
  const { id, label, roleId } = dashboard;
  if (!label) return false;

  return !R.any(
    (d) =>
      d.label.toLowerCase() === label.toLowerCase() &&
      d.roleId === roleId &&
      d.id !== id,
    dashboards,
  );
};

export const isValid =
  (dashboards: Dashboard[]) =>
  (value: DashboardFormType): boolean => {
    if (!value || !value.item) return false;

    const { item } = value;
    const { labels, widgets = [] } = item;

    return (
      !!labels &&
      validateDashboardName(item, dashboards) &&
      !!widgets.length &&
      R.all((w) => validateWidget(w), widgets)
    );
  };
