import { searchApi } from "common/api/search";
import { setColumnIsValid } from "common/form/functions/validation";
import { merge1 } from "common/merge";
import { setFkExpansion } from "common/query/expansion";
import { addFilter } from "common/query/filter";
import { addToSelectQuery } from "common/query/select";
import type { QueryForEntity, Filter, FilterRule } from "common/query/types";
import type { ForeignKey } from "common/types/foreign-key";
import { AlertErrorTip } from "common/widgets/alert";
// eslint-disable-next-line import/no-cycle
import { RecordFilter } from "common/widgets/record-filter";
import type { ColumnForeignKey } from "common/widgets/record-filter/types";
import { getSelectorError } from "common/widgets/record-selector/errors";
import { ValueComponent, type ValueProps } from "common/with-value-for";
import { RecordSelector } from "./selector";
import type { BasePropTypes, TabContentProps } from "./types";
import { addFilterToContentQueries } from "./functions";

interface StateType {
  customFilters?: ColumnForeignKey[];
}

const getFilter = (fks: ColumnForeignKey[]): Filter => {
  const filters = fks.map(
    (fk): FilterRule => ({
      name: fk.columnName,
      op: "eq",
      value: fk.foreignKey.title,
    }),
  );

  return { and: filters };
};

interface ReturnQueries {
  selectorQuery: QueryForEntity;
  advancedSearchTabs: TabContentProps[];
}

/**
 * A record selector with advanced search and filter. Don't use me directly.
 */
export class AdvancedRecordSelectorBase extends ValueComponent<
  ForeignKey[],
  BasePropTypes,
  StateType
> {
  static readonly displayName = "AdvancedRecordSelectorBase";
  state: StateType = {};

  componentDidUpdate(prevProps: BasePropTypes & ValueProps<ForeignKey[]>) {
    const { value, column, entity, context } = this.props;

    if (
      prevProps.value !== value ||
      prevProps.column?.name !== column?.name ||
      prevProps.entity?.name !== entity?.name ||
      prevProps.context?.site?.name !== context?.site?.name
    ) {
      const {
        context,
        entity,
        column,
        recordSites,
        value,
        formValidation,
        onFormValidationChange,
      } = this.props;
      const { hasError } = getSelectorError(
        context,
        entity,
        recordSites,
        value?.[0],
      );

      onFormValidationChange?.(
        setColumnIsValid(formValidation, column?.name, !hasError),
      );
    }
  }

  getQueries = (): ReturnQueries => {
    const {
      entity,
      query,
      extraFilter,
      content = [],
      addToSelect = [],
    } = this.props;
    const { customFilters = [] } = this.state;

    const defaultQuery: QueryForEntity = {
      entity: entity.name,
      query: entity.query,
    };

    const baseQuery = query || defaultQuery;

    const filter = customFilters.length ? getFilter(customFilters) : undefined;
    const queryWithFilter = filter
      ? setFkExpansion(addFilter(filter, baseQuery))
      : query;

    const queryWithExtraFilter = extraFilter
      ? addFilter(extraFilter, queryWithFilter || baseQuery)
      : queryWithFilter;

    const queryToAddSelectTo = queryWithExtraFilter || defaultQuery;

    const selectorQuery = addToSelect.length
      ? merge1(
          "query",
          addToSelectQuery(addToSelect, queryToAddSelectTo.query),
          queryToAddSelectTo,
        )
      : queryWithExtraFilter;

    const contentQueries: TabContentProps[] = content.length
      ? content
      : [{ queryForEntity: baseQuery }];

    const contentQueriesWithFilter = addFilterToContentQueries(
      filter,
      contentQueries,
    );

    const advancedSearchTabs = addFilterToContentQueries(
      extraFilter,
      contentQueriesWithFilter,
    );

    return { selectorQuery, advancedSearchTabs };
  };

  onRecordSelectorChange = (fk: ForeignKey) => {
    this.setValue([fk]);
  };

  runQuery = (query: QueryForEntity) => {
    const { recordSites, context } = this.props;
    return searchApi(context.apiCall).runQueryForLookup(query, recordSites);
  };

  render() {
    const {
      context,
      entity,
      type,
      disabled,
      withLinks,
      placeholder,
      className,
      label,
      allowClear,
      value,
      addToSelect = [],
      allowMultipleSelect,
      dontKeepValue,
      approveChange,
      onSelect,
      recordSites,
      allowAdvancedAdding,
      SearchCtrlComp,
    } = this.props;
    const { customFilters } = this.state;

    const { selectorQuery, advancedSearchTabs } = this.getQueries();
    const { hasError, errorMessage } = getSelectorError(
      context,
      entity,
      recordSites,
      value?.[0],
    );

    return (
      <div className={hasError ? "x-has-error" : undefined}>
        <div className="x-record-selector-wrapper">
          <RecordSelector
            context={context}
            entity={entity}
            query={selectorQuery}
            runQuery={this.runQuery}
            runResolveQuery={searchApi(context.apiCall).runQueryFkExpansion}
            type={type}
            disabled={disabled}
            allowClear={allowClear}
            placeholder={placeholder}
            className={className}
            label={label}
            dontKeepValue={dontKeepValue}
            value={value?.length ? value[0] : undefined}
            onChange={this.onRecordSelectorChange}
            approveChange={approveChange}
          />
          <SearchCtrlComp
            mergeQueryWithDefault={true}
            content={advancedSearchTabs}
            context={context}
            entity={entity}
            disabled={disabled}
            withLinks={withLinks}
            addToSelect={addToSelect}
            allowMultipleSelect={allowMultipleSelect}
            allowAdvancedAdding={allowAdvancedAdding}
            recordSites={recordSites}
            value={value}
            onChange={this.onChangeSetValue}
            onSelect={onSelect}
            approveChange={approveChange}
          />
          {!allowAdvancedAdding ? (
            <RecordFilter
              context={context}
              entity={entity}
              value={customFilters}
              disabled={disabled}
              onChange={this.onChangeMergeState("customFilters")}
            />
          ) : undefined}
        </div>
        {hasError ? <AlertErrorTip message={errorMessage} /> : undefined}
      </div>
    );
  }
}
