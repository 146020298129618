import { AttachPdfAction } from "common/types/workflows";

const FILE_NAME_REGEX = /^[a-zA-Z0-9 _-]+$/;

export const isFileNameValid = (fileName: string) => {
  return !!fileName && FILE_NAME_REGEX.test(fileName);
};

export const isAttachPdfActionValid = (action: AttachPdfAction) =>
  !!(action && isFileNameValid(action.fileName));
