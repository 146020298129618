import { RecordContentForm } from "common/record/form/form";
import { StandardValue as Value } from "common/record/types";
import { ValueProps } from "common/with-value-for";
import { PropTypes } from "x/records/edit/types";
import { validateRelatedEntities } from "./functions";

type Props = PropTypes & ValueProps<Value>;

export const ApprovalGroupBehaviorForm = (props: Props) => (
  <RecordContentForm {...props} extraValidation={validateRelatedEntities} />
);
ApprovalGroupBehaviorForm.displayName = "ApprovalGroupBehaviorForm";
