import { Context } from "common/types/context";
import { defaultFor } from "common";
import { DEFAULT_CURRENCY_DECIMAL_SCALE } from "common/utils/decimal";
import { CurrencyLabel } from "common/widgets/currency/label";
import { EntityColumn } from "common/entities/entity-column/types";
import { getCurrencySymbol } from "common/widgets/currency/functions";
import { HighlightProps } from "common/widgets/label";

interface PropTypes extends HighlightProps {
  value: string;
  context: Context;
  column: EntityColumn;
  currencyId?: string;
}

const defaultColumn = defaultFor<EntityColumn>();

export const DefaultCurrencyLabel = (props: PropTypes) => {
  const {
    currencyId,
    context,
    column = defaultColumn,
    value,
    textToHighlight,
  } = props;
  const { uiFormat } = context;

  return (
    <CurrencyLabel
      value={value}
      decimalSeparator={uiFormat.decimalSeparator}
      currencySymbol={getCurrencySymbol(currencyId, column, context)}
      decimalScale={column.decimalScale || DEFAULT_CURRENCY_DECIMAL_SCALE}
      displayValue={column.displayValue}
      textToHighlight={textToHighlight}
    />
  );
};

DefaultCurrencyLabel.displayName = "DefaultCurrencyLabel";
