import { useEffect, useState } from "react";
import { Context } from "common/types/context";
import { workflowsApi } from "common/api/workflows";
import {
  Suggestion,
  Suggestions,
} from "common/vendor-wrappers/react-mentions/suggestions";
import { withDebounce } from "common/with-debounce";
import { ValueProps } from "common/with-value-for";
import { Field } from "./types";

interface PropTypes {
  context: Context;
  fields: Field[];
  className?: string;
  placeholder?: string;
}

const getSuggestions = (fields: Field[]): Suggestion[] =>
  fields.map((f) => {
    const id = f.path + "/" + f.column.name;
    return { id, display: id };
  });

export const Expression = ({
  context,
  fields,
  className,
  placeholder,
  value,
  onChange,
}: PropTypes & ValueProps<string>) => {
  const [suggestions, setSuggestions] = useState<Suggestion[]>(
    getSuggestions(fields),
  );

  useEffect(() => {
    workflowsApi(context.apiCall)
      .getValidExpressions()
      .then((result) => {
        const allSuggestions = suggestions.concat(
          result.map((r) => {
            return { id: r, display: r };
          }),
        );

        setSuggestions(allSuggestions);
      });
  }, []);

  return (
    <Suggestions
      className={className}
      placeholder={placeholder}
      multipleLines={true}
      onChange={onChange}
      value={value}
      suggestions={suggestions}
    />
  );
};

export const DebounceExpression = withDebounce(Expression);
