import { Component } from "react";
import { CancelButton } from "common/ui/buttons";
import { arrayToString } from "common/utils/array";
import { RadioButton } from "common/widgets/radio-button-list/radio";
import { ValueProps } from "common/with-value-for";

import "./index.scss";

interface PropTypes<TItem> extends ValueProps<TItem> {
  name: string;
  items: TItem[];
  isDisabled?: (item: TItem) => boolean;
  hasError?: (item: TItem) => boolean;
  display: (item: TItem) => string;
  size?: "small" | "medium" | "large";
  allowClear?: boolean;
  className?: string;
}

export class RadioButtonList<T> extends Component<PropTypes<T>> {
  static readonly displayName = "RadioButtonList";

  onRadioChange = (item: T) => () => {
    this.props.onChange(item);
  };

  onRadioClear = () => {
    this.props.onChange(undefined);
  };

  render() {
    const {
      name,
      items,
      display,
      className,
      size,
      allowClear,
      isDisabled,
      hasError,
      value,
    } = this.props;

    const radioListClasses = arrayToString(["x-radio-button-list", className]);
    const showClearButton = allowClear && value != null;

    return (
      <div className={radioListClasses}>
        {items.map((item) => {
          const label = display(item);
          return (
            <RadioButton
              key={label}
              name={name}
              label={label}
              size={size}
              disabled={isDisabled?.(item) ?? false}
              isChecked={item === value}
              hasError={hasError?.(item) ?? false}
              onCheck={this.onRadioChange(item)}
            />
          );
        })}
        {showClearButton ? (
          <CancelButton
            className="x-clear-button"
            title={_("Clear selection")}
            onClick={this.onRadioClear}
          >
            <i className="fa fa-close" />
          </CancelButton>
        ) : undefined}
      </div>
    );
  }
}
