import { datadogLogs } from "@datadog/browser-logs";
import { getInitialConfiguration } from "common/monitoring/configuration";
import {
  GlobalContextProperties,
  InitializationParams,
  User,
} from "common/monitoring/types";

export const browserLogsLogger = {
  initialize: (params: InitializationParams = {}) => {
    datadogLogs.init(getInitialConfiguration(params));
  },

  setUser: (user: User) => {
    datadogLogs.setUser(user);
  },

  setGlobalContextProperties: (properties: GlobalContextProperties = {}) => {
    Object.entries(properties).forEach(([key, value]) => {
      datadogLogs.setGlobalContextProperty(key, value);
    });
  },

  logCustomError: (error: string) => {
    datadogLogs.logger.error(error);
  },
};
