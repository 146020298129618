import * as R from "ramda";
import { JSX } from "react";
import { getLocalizedName } from "common";
import { getOperators } from "common/entities/data-types";
import { isDateTimeColumn } from "common/entities/entity-column/functions";
import { EntityColumn } from "common/entities/entity-column/types";
import {
  getSubQueryOperators,
  isDateOperator,
} from "common/entities/operators";
import { LabelWidget } from "common/form/widget/label-widget";
import { isForeignKey } from "common/functions/foreign-key";
import { isStarCondition } from "common/query/common";
import {
  ConditionFilter,
  Filter,
  FilterExpression,
  FilterField,
  FilterRule,
  FilterSubQueryRule,
  isAnd,
  isExpressionRule,
  isFieldRule,
  isOr,
  isSubQuery,
  QueryForEntity,
  Secondaries,
  SelectField,
} from "common/query/types";
import { Context } from "common/types/context";
import { Label } from "common/widgets/label";
import { isArchivedReference } from "x/records/list/functions/is-deleted";
import { getOperatorByName, parseSubQuery } from "./condition/functions";
import { getField, getFields } from "./functions";

const operators = getOperators("all", false);

/**
 * Joins an array of elements by the supplied separator. Ignores nullish entries.
 * @param elements
 * @param separator
 */
const joinJSX = (elements: JSX.Element[], separator: JSX.Element) =>
  elements.reduce<JSX.Element[]>((acc, element) => {
    if (!element) return acc;
    return acc === null ? [element] : [...acc, separator, element];
  }, null);

/**
 * Joins descriptions by the logical operator and wraps it with parenthesis if necessary.
 * @param descriptions Array of rule descriptions
 * @param operator The operator like `"OR"`, `"AND"` **without** empty spaces
 * @param wrapInParenthesis
 */
const joinByOperator = (
  descriptions: JSX.Element[],
  operator: string,
  wrapInParenthesis?: boolean,
): JSX.Element => {
  const elements = joinJSX(descriptions, <> {operator} </>);

  if (!elements) return null;
  if (elements.length === 1) return elements[0];

  const withKeys = elements.map((e, i) => ({ ...e, key: i }));

  return wrapInParenthesis ? <>({withKeys})</> : <>{withKeys}</>;
};

/**
 * Maps a list of filters to a JSX description. If it's more than 1, join them by
 * the supplied operator and wraps it with `()`.
 * @param ruleDescriber The mapping function used to transform a rule into string
 * @param filters A list of filters
 * @param operator The operator like `"OR"`, `"AND"` **without** empty spaces
 */
const mapFiltersToDescription = (
  ruleDescriber: (rule: FilterRule) => JSX.Element,
  filters: Filter[],
  operator: string,
): JSX.Element => {
  const descriptions = filters.map((f) => {
    if (isAnd(f)) {
      return mapFiltersToDescription(ruleDescriber, f.and, _("AND"));
    }
    if (isOr(f)) {
      return mapFiltersToDescription(ruleDescriber, f.or, _("OR"));
    }

    // filter is a rule
    return ruleDescriber(f as FilterRule);
  });

  return joinByOperator(descriptions, operator, true);
};

/**
 * Removes the default `isDeleted` rule from the 1st level `and` of a filter.
 * The intention is to make a less noisy description and only show the `isDeleted` rule if
 * it's on a deeper level or the 1st level was changed to an `OR`.
 * @param filter
 */
const omitDefaultFilter = (filter: Filter): Filter =>
  isAnd(filter)
    ? {
        ...filter,
        and: filter.and.filter((f: Filter) =>
          isFieldRule(f)
            ? !(
                f?.name === "isDeleted" &&
                (f?.op === "istrue" || f?.op === "isfalse")
              )
            : !isArchivedReference(f),
        ),
      }
    : filter;

/**
 * Transforms a filter rule into a JSX description
 * @param context
 * @param query
 * @param rule
 */
const describeFieldRule = (
  context: Context,
  query: QueryForEntity,
  rule: FilterField,
): JSX.Element => {
  const fields = getFields(context.entities, query);
  if (!fields?.length) return null;

  const field = getField(fields, rule);
  if (!field) return null;

  const getPrefix = (): string => {
    // try to find a possible alias for this rule defined in select
    const selectForRule = R.find(
      (s) => s.name === rule.name && s.fn === rule.fn && s.path === rule.path,
      (query.query.select ?? []) as SelectField[],
    );
    const alias = selectForRule?.alias;

    const prefix = alias
      ? `[${alias}]`
      : field.minPath
        ? `[${field.minPath}].[${getLocalizedName(field.column)}]`
        : `[${rule.name}]`;

    return rule.fn ? `${rule.fn}(${prefix})` : prefix;
  };

  const getOperator = (): string =>
    field.column.dataType === "fk" &&
    isForeignKey(rule.value) &&
    !rule.value.title
      ? ""
      : R.find((o) => o.name === rule.op, operators).label;

  const getValue = (): JSX.Element => {
    if (!rule.value || rule.value === "") return null;

    // modifies dataType to hide time part
    const column: EntityColumn =
      isDateTimeColumn(field.column) && isDateOperator(rule.op)
        ? {
            ...field.column,
            dataType: "date",
          }
        : field.column;

    return (
      <>
        {" "}
        <span className="x-bold">
          <LabelWidget
            entityName={field.entityName}
            withLinks={true}
            context={context}
            column={column}
            value={rule.value}
          />
        </span>
      </>
    );
  };

  return (
    <>
      {getPrefix() + " " + getOperator()}
      {getValue()}
    </>
  );
};

const describeExpressionRule = (rule: FilterExpression) => {
  if (!rule) return null;

  const prefix = `[${_("Expression")}]`;
  const operator = R.find((o) => o.name === rule.op, operators).label;

  return (
    <>
      {`${prefix} ${operator} `}
      <Label value={rule.value} />
    </>
  );
};

const describeRule = (
  context: Context,
  query: QueryForEntity,
  rule: FilterRule,
) =>
  isFieldRule(rule)
    ? describeFieldRule(context, query, rule)
    : describeExpressionRule(rule);

/**
 * Transforms a filter sub query into a JSX description
 * @param context
 * @param query
 * @param rule
 */
const describeSubQuery = (
  context: Context,
  query: QueryForEntity,
  rule: FilterSubQueryRule,
): JSX.Element => {
  const fields = getFields(context.entities, query);
  if (!fields?.length) return null;

  const { entity, joins } = rule.queryValue;
  const operators = getSubQueryOperators();
  const operator = getOperatorByName(operators, rule.op);
  const { subFilter } = parseSubQuery(rule);

  const ruleDescriber = (rule: FilterRule) => {
    const newQuery: QueryForEntity = { entity, query: { joins, select: [] } };
    return describeRule(context, newQuery, rule);
  };

  const subQueryDescription = mapFiltersToDescription(
    ruleDescriber,
    [subFilter],
    _("AND"),
  );

  const separator = subQueryDescription ? _("Where") : undefined;
  return (
    <>
      {"("}
      {`[${entity}] ${operator.label}`}
      {separator ? ` ${separator} ` : ""}
      {subQueryDescription || <></>}
      {")"}
    </>
  );
};

/**
 * Transforms the `filter` and `having` of a query into a JSX description
 * @param context
 * @param query
 * @param starred
 */
const describeQuery = (
  context: Context,
  query: QueryForEntity,
  starred: string[] = [],
): JSX.Element => {
  if (!query?.query) return null;
  const { filter, having } = query.query;

  const ruleDescriber = (rule: ConditionFilter): JSX.Element => {
    if (isFieldRule(rule) && (!rule.name || !rule.op)) return null;
    if (isExpressionRule(rule) && (!rule.expression || !rule.op)) return null;
    if (isSubQuery(rule)) return describeSubQuery(context, query, rule);

    // dealing with corner cases outside
    if (isStarCondition(starred, rule)) return <>{_("Starred Items")}</>;

    return describeRule(context, query, rule);
  };

  const allFilters = [
    ...(filter ? [filter] : []),
    ...(having ? [having] : []),
  ].map(omitDefaultFilter);

  return mapFiltersToDescription(
    ruleDescriber,
    allFilters,
    _("AND"), // filter AND having
  );
};

/**
 * Maps the secondary queries, creating descriptions for them, prefixing them by their
 * entity name and joining with an `AND`.
 * @param context
 * @param secondaryQueries
 */
const getSecondaryQueriesDescription = (
  context: Context,
  secondaryQueries: Secondaries,
): JSX.Element => {
  if (!secondaryQueries) return null;

  const descriptions = Object.keys(secondaryQueries).map((entityName) => {
    const description = describeQuery(context, {
      entity: entityName,
      query: secondaryQueries[entityName],
    });
    return description ? (
      <>
        [{entityName}].{description}
      </>
    ) : null;
  });

  return joinByOperator(descriptions, _("AND"));
};

/**
 * Returns true if any rule passes the predicate
 * @param predicate A callback function which rules will be recursively checked against
 * @param filters Array of filters
 */
export const someRule = (
  predicate: (rule: FilterRule) => boolean,
  filters: Filter[],
): boolean =>
  filters.some((f) => {
    if (isAnd(f)) {
      return someRule(predicate, f.and);
    }
    if (isOr(f)) {
      return someRule(predicate, f.or);
    }
    if (isSubQuery(f)) return false;

    return predicate(f);
  });

/**
 * Transforms a query and secondary queries into a JSX description.
 * @param context
 * @param query
 * @param secondaryQueries
 * @param starred
 */
export const get = (
  context: Context,
  query: QueryForEntity,
  secondaryQueries: Secondaries = undefined,
  starred: string[] = [],
): JSX.Element => {
  if (!query?.query) return null;

  const includingArchivedDesc = _("including archived");
  const { filter, having } = query.query;
  const allMainFilters = [
    ...(filter ? [filter] : []),
    ...(having ? [having] : []),
  ];

  const mainDescription = describeQuery(context, query, starred);
  const secondaryDescription = getSecondaryQueriesDescription(
    context,
    secondaryQueries,
  );

  const mainQueryContainsIsDeleted = allMainFilters.some((filter) =>
    isArchivedReference(filter),
  );

  if (!mainDescription && !secondaryDescription && mainQueryContainsIsDeleted)
    return (
      <>{_("Show all (DELETED)").replace("DELETED", includingArchivedDesc)}</>
    );

  // for the sake of simplicity we only show `including archived` on
  // the TOTAL absence of `isDeleted` in the whole query
  const archivedSuffix = !someRule(
    (r) => isFieldRule(r) && r.name === "isDeleted" && r.op === "istrue",
    allMainFilters,
  ) ? null : (
    <>{includingArchivedDesc}</>
  );

  return joinByOperator(
    [mainDescription, secondaryDescription, archivedSuffix],
    _("AND"),
  );
};
