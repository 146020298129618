import { Component } from "react";
import { cultureApi } from "common/api/cultures";
import { Culture } from "common/culture/supported-cultures";
import { Properties } from "common/types/records";
import { Translations } from "common/types/translations";
import { LoadingButton } from "common/widgets/loading-button";
import { ValueProps } from "common/with-value-for";
// eslint-disable-next-line import/no-cycle
import { PropTypes, SectionGroup } from "../group";
import { getColumnName, keepOwnCultureFirst } from "./functions";

interface StateType {
  translating?: boolean;
}

type Props = PropTypes & ValueProps<Properties>;

export class ReferenceGroup extends Component<Props, StateType> {
  state: StateType = {};

  getOwnCultureColumnName = () =>
    getColumnName(this.props.context.uiFormat.culture);

  /** Returns a list of cultures that can be auto-translated */
  getCulturesForTranslation = () => {
    const {
      context: { cultures, uiFormat },
      value = {},
    } = this.props;
    return cultures.filter(
      (culture) =>
        culture !== uiFormat.culture && !value[getColumnName(culture)],
    );
  };

  onTranslate = () => {
    const { context, value = {}, onChange } = this.props;
    const uiCulture = context.uiFormat.culture;
    const description = value[this.getOwnCultureColumnName()];
    this.setState({ translating: true });

    cultureApi(context.apiCall)
      .translateLanguages(
        { description },
        uiCulture,
        this.getCulturesForTranslation(),
      )
      .then((translations: Translations) => {
        this.setState({ translating: false });

        const newValue = Object.keys(translations).reduce<Properties>(
          (acc, culture: Culture) => ({
            ...acc,
            [getColumnName(culture)]: translations[culture].description,
          }),
          value,
        );
        onChange(newValue);
      });
  };

  render() {
    const {
      context,
      entity,
      groups,
      group,
      withLinks,
      allowDynamicValues,
      formValidation,
      onFormValidationChange,
      value = {},
      onChange,
    } = this.props;
    const { translating } = this.state;

    if (!group.columns.length) return null;

    const showAutoTranslate =
      !!value[this.getOwnCultureColumnName()] &&
      this.getCulturesForTranslation().length > 0;

    return (
      <>
        {showAutoTranslate ? (
          <LoadingButton
            className="qa-translate-culture"
            type="action"
            loading={translating}
            idleText={_("Translate")}
            loadingText={_("Translating")}
            onClick={this.onTranslate}
          />
        ) : undefined}

        <SectionGroup
          context={context}
          groups={groups}
          group={{
            ...group,
            columns: keepOwnCultureFirst(
              group.columns,
              context.uiFormat.culture,
            ),
          }}
          withLinks={withLinks}
          entity={entity}
          allowDynamicValues={allowDynamicValues}
          formValidation={formValidation}
          onFormValidationChange={onFormValidationChange}
          value={value}
          onChange={onChange}
        />
      </>
    );
  }
}
