import { isType } from "common";
import { ApiCall, Crud } from "common/types/api";
import { Form } from "common/types/forms";
import { CancellablePromise } from "common/types/promises";
import { messages, withNotifications } from "common/api/with-notifications";

export const isForm = isType<any, Form>(["entityName", "settings", "labels"]);

const getMessages = messages(() => ({
  created: _("The form was successfully created"),
  updated: _("The form was successfully updated"),
  removed: _("The form was successfully deleted"),
}));

interface FormApiType extends Crud<number, Form> {
  bulkUpdate: (forms: Form[]) => CancellablePromise<any>;
  isUsed: (id: number) => CancellablePromise<boolean>;
}

export const formsApi = withNotifications(
  getMessages,
  (apiCall: ApiCall): FormApiType => ({
    get: (id: number) => apiCall("get", `api/ui/forms/${id}`),
    list: () => apiCall("get", "api/ui/forms"),
    create: (form: Form) => apiCall("post", "api/ui/forms", form),
    update: (form: Form) => apiCall("put", `api/ui/forms/${form.id}`, form),
    bulkUpdate: (forms: Form[]) => apiCall("put", `api/ui/forms/bulk`, forms),
    remove: (id: number) => apiCall("delete", `api/ui/forms/${id}`),
    isUsed: (id: number) => apiCall("get", `api/ui/forms/${id}/is-used`),
  }),
);
