import { Link } from "common/widgets/link";

export interface Crumb {
  name: string;
  url?: string;
  onClick?: () => void;
}

interface PropTypes {
  crumbs: Crumb[];
}

export const getAccountSettingsCrumb = (): Crumb => ({
  name: _("Account Settings"),
});

export const Breadcrumb = ({ crumbs }: PropTypes) => (
  <nav aria-label="breadcrumb">
    <ol className="breadcrumb">
      {crumbs
        .filter((c) => !!c.name)
        .map((c, i) => (
          <li className="breadcrumb-item" key={i} onClick={c.onClick}>
            {c.url ? <Link href={c.url}>{c.name}</Link> : c.name}
          </li>
        ))}
    </ol>
  </nav>
);
