import { ChangeEvent, PropsWithChildren } from "react";
import { toFullCamelCase } from "common";
import { arrayToString } from "common/utils/array";
import { getBooleanLabel } from "common/widgets/checkbox/label";
import { OnChange } from "common/with-value-for";

import "./radio.scss";

interface PropTypes {
  name: string;
  label: string;
  isChecked: boolean;
  hasError: boolean;
  onCheck: OnChange<boolean>;
  disabled?: boolean;
  className?: string;
  size?: "small" | "medium" | "large";
}

export const RadioButton = ({
  name,
  label,
  disabled = false,
  className,
  size = "medium",
  isChecked,
  hasError,
  onCheck,
  children,
}: PropsWithChildren<PropTypes>) => {
  const onRadioChange = (_: ChangeEvent<HTMLInputElement>) => {
    onCheck(!isChecked);
  };

  const radioIcon = isChecked ? "fa-check-circle-o" : "fa-circle-o";
  const displayLabel = label || getBooleanLabel(isChecked);
  const normalizedValue = toFullCamelCase(displayLabel);

  const labelClasses = arrayToString([
    className,
    label ? `qa-${toFullCamelCase(name)}-${normalizedValue}` : undefined,
    isChecked ? "checked" : undefined,
    hasError ? "x-error" : undefined,
    disabled ? "x-disabled" : undefined,
  ]);

  return (
    <div className={`x-radio-button x-radio-button-${size} qa-radio`}>
      <label className={labelClasses}>
        <i className={`fa ${radioIcon}`} />
        <input
          type="radio"
          name={name}
          value={normalizedValue}
          disabled={disabled}
          checked={isChecked}
          onChange={onRadioChange}
        />
        {displayLabel}
        {children}
      </label>
    </div>
  );
};
