import { Component } from "react";
import { defaultFor } from "common";
import { behaveAs, canDo } from "common/entities";
import { Entity } from "common/entities/types";
import {
  appendColumnsToLastGroup,
  findMissingColumns,
} from "common/form/functions/layout";
import { FormValidationProps, Layout as FormLayout } from "common/form/types";
import { mergeChain } from "common/merge";
import { RelatedValue } from "common/record/form/content/related/types";
import { DetailUiValue, Reload, StandardUiValue } from "common/record/types";
import { Context } from "common/types/context";
import { Record } from "common/types/records";
import { GoFn } from "common/types/url";
import { ModifyButtonSmall } from "common/ui/buttons";
import { ValueProps } from "common/with-value-for";
import { DetailBehavior } from "./behavior";
import { EditRecord } from "./edit";
import { ViewRecord } from "./view/view-record";

export interface RecordDetailValue extends StandardUiValue {
  record: Record;
}

const defaultValue = defaultFor<RecordDetailValue>();
const defaultUi = defaultFor<DetailUiValue>();
const defaultRecord = defaultFor<Record>();

interface PropTypes extends ValueProps<RecordDetailValue>, FormValidationProps {
  context: Context;
  entity: Entity;
  layout: FormLayout;
  withLinks: boolean;
  reload: Reload;
  isTemplate: boolean;
  /**
   * if false, buffer changes and only update the record when the user presses "Save"
   */
  updateRecordOnChange: boolean;
  allowDynamicValues?: boolean;
  disableEdit?: boolean;
  goTo?: GoFn;
}

export class RecordDetail extends Component<PropTypes> {
  static readonly displayName = "RecordDetail";

  onChangeEdit = (editValue: RelatedValue) => {
    const { updateRecordOnChange, value = defaultValue, onChange } = this.props;
    const { record, related } = editValue;
    const { properties } = record;

    const chain = mergeChain(value).set("related", related);

    onChange(
      updateRecordOnChange
        ? chain.prop("record").set("properties", properties).output()
        : chain
            .prop("detail")
            .set("form", properties)
            .set("isDirty", true)
            .output(),
    );
  };

  onEdit = () => {
    const { value = defaultValue, onChange } = this.props;
    onChange(
      mergeChain(value)
        .prop("detail")
        .set("form", value.record.properties)
        .output(),
    );
  };

  render() {
    const {
      context,
      entity,
      layout,
      withLinks,
      updateRecordOnChange,
      isTemplate,
      reload,
      goTo,
      disableEdit,
      allowDynamicValues,
      formValidation,
      onFormValidationChange,
      value = defaultValue,
    } = this.props;
    const { detail = defaultUi, record = defaultRecord } = value;
    const { form } = detail;
    const properties = updateRecordOnChange ? record.properties : form;

    const canEdit =
      !disableEdit &&
      canDo(entity, "Update") &&
      !value.record?.properties?.isDeleted;

    const missingColumns = findMissingColumns(layout, entity);
    const layoutWithMissingColumns = appendColumnsToLastGroup(
      missingColumns,
      layout,
    );

    return (
      <>
        {behaveAs("Event", entity) ? (
          <DetailBehavior
            context={context}
            entity={entity}
            properties={form || record.properties}
          />
        ) : undefined}
        {!disableEdit && (properties || updateRecordOnChange) ? (
          <EditRecord
            isTemplate={isTemplate}
            goTo={goTo}
            context={context}
            entity={entity}
            layout={layoutWithMissingColumns}
            withLinks={withLinks}
            reload={reload}
            allowDynamicValues={allowDynamicValues}
            formValidation={formValidation}
            onFormValidationChange={onFormValidationChange}
            value={mergeChain(value)
              .prop("record")
              .set("properties", properties)
              .output()}
            onChange={this.onChangeEdit}
          />
        ) : (
          <div className="x-record-detail-view">
            {canEdit ? (
              <div className="x-align-right x-margin-bottom-10">
                <ModifyButtonSmall
                  className="x-form-edit-action qa-edit"
                  onClick={this.onEdit}
                  title={_("Edit")}
                >
                  {_("Edit")}
                </ModifyButtonSmall>
              </div>
            ) : undefined}

            <ViewRecord
              withLinks={withLinks}
              context={context}
              entity={entity}
              layout={layoutWithMissingColumns}
              reload={reload}
              isTemplate={isTemplate}
              groups={layoutWithMissingColumns.groups}
              value={value}
            />
          </div>
        )}
      </>
    );
  }
}
