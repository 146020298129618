import { ComponentType } from "react";
import { DndProvider } from "react-dnd-cjs";
import HTML5Backend from "react-dnd-html5-backend-cjs";

export function withDnd<PropTypes>(YourComponent: ComponentType<PropTypes>) {
  return function WithDndProvider(props: PropTypes) {
    return (
      <DndProvider backend={HTML5Backend}>
        <YourComponent {...props} />
      </DndProvider>
    );
  };
}
