import { defaultFor } from "common";
import {
  FetchRecords,
  PropTypes as PropTypesFetch,
  Injected as InjectedRecord,
} from "./fetch-records";
import {
  ValueController,
  PropTypes as PropTypesValue,
  Injected as InjectedValue,
  createInjected as createInjectedValue,
} from "./value-controller";

export interface PropTypes<TValue, TRecord>
  extends PropTypesValue<TValue>,
    PropTypesFetch<TValue, TRecord> {}

export interface Injected<TValue, TRecord>
  extends InjectedValue<TValue>,
    InjectedRecord<TValue, TRecord> {}

export function createInjected<TValue, TRecord>() {
  return defaultFor<Injected<TValue, TRecord>>();
}

export function createListController<TValue, TRecord>() {
  const injected = createInjectedValue<TValue>();

  const ListWithFetching = (props: PropTypes<TValue, TRecord>) => (
    <ValueController<TValue> defaultValue={props.defaultValue}>
      <FetchRecords<TValue, TRecord>
        {...injected}
        fetch={props.fetch}
        defaultRecords={props.defaultRecords}
        dontFetch={props.dontFetch}
        notOlderThan={props.notOlderThan}
      >
        {props.children}
      </FetchRecords>
    </ValueController>
  );

  return ListWithFetching;
}
