import { Component } from "react";
import { FunctionSelector } from "common/query-builder/function-selector";
import { Context } from "common/types/context";
import { getLocalizedName, getTrue } from "common";
import { deepEqual } from "common/component";
import { isOrderExpression, OrderItem } from "common/query/types";
import { FancyCheckboxLarge } from "common/widgets/fancy-checkbox";
import { createList } from "common/widgets/list";
import { OnChange, ValueProps } from "common/with-value-for";
import { FieldSelector } from "common/query-builder/field-selector";
import { Expression } from "./expression";
import { fieldsEqual, getField, handleAdd } from "./functions";
import { Item } from "./item";
import { NotFound } from "./not-found";
import { Field } from "./types";

interface ItemPropTypes {
  context: Context;
  fields: Field[];
  allowAggregates: boolean;
}

export class OrderListItem extends Item<OrderItem, ItemPropTypes> {
  static readonly displayName = "OrderItem";

  onDirectionChange = (isDesc: boolean) => {
    this.mergeValue({ desc: isDesc });
  };

  onExpressionChange = (expression: string) => {
    this.mergeValue({ expression });
  };

  onFunctionChange = (fn: string) => {
    this.mergeValue({ fn });
  };

  render() {
    const { context, fields, allowAggregates, value } = this.props;
    const checkBox = (
      <FancyCheckboxLarge
        className="x-margin-left-10 x-margin-right-10"
        label={_("DESC")}
        value={value.desc}
        onChange={this.onDirectionChange}
      />
    );

    if (isOrderExpression(value)) {
      return (
        <div className="x-flex qa-query-builder-order-expression">
          <Expression
            context={context}
            className="x-flex-grow-1"
            fields={fields}
            onChange={this.onExpressionChange}
            value={value.expression}
          />
          {checkBox}
        </div>
      );
    }

    const field = getField(fields, value);
    if (!field) {
      const message = value?.name || _("Order column");
      return <NotFound message={message} />;
    }
    const fieldLabel = `${field.minPath}.${getLocalizedName(field.column)}`;

    if (allowAggregates) {
      return (
        <div className="x-flex qa-query-builder-order-item-with-dropdown">
          <input
            className="x-margin-right-10 x-flex-grow-2"
            type="text"
            value={fieldLabel}
            readOnly={true}
          />
          <FunctionSelector
            className="x-flex-grow-1"
            column={field.column}
            allowEmpty={true}
            value={value.fn}
            onChange={this.onFunctionChange}
          />
          {checkBox}
        </div>
      );
    }

    return (
      <div className="x-flex qa-query-builder-order-item">
        <input type="text" value={fieldLabel} readOnly={true} />
        {checkBox}
      </div>
    );
  }
}

interface PropTypes extends ValueProps<OrderItem[]> {
  context: Context;
  fields: Field[];
  allowAggregates: boolean;
}

export const List = createList<OrderItem>("Order");

export class Order extends Component<PropTypes> {
  static readonly displayName = "Order";

  onChangeValue = (value: OrderItem[]) => {
    this.props.onChange(value);
  };

  onAdd = (field: Field) => {
    handleAdd(this.onChangeValue, this.props.value)(field);
  };

  shouldComponentUpdate = (newProps: PropTypes) => {
    const { props } = this;
    return (
      !deepEqual(props, newProps, ["fields"]) ||
      !fieldsEqual(props.fields, newProps.fields)
    );
  };

  renderItem = (item: OrderItem, onChange: OnChange<OrderItem>) => {
    const { context, fields, allowAggregates } = this.props;
    return (
      <OrderListItem
        context={context}
        fields={fields}
        allowAggregates={allowAggregates}
        value={item}
        onChange={onChange}
      />
    );
  };

  render() {
    const { fields, value } = this.props;
    return (
      <div className="x-query-builder-order">
        <FieldSelector
          value={undefined}
          fields={fields}
          withExpressions={true}
          onChange={this.onAdd}
        />
        <List
          className="x-padding-top-10 x-padding-bottom-10"
          value={value}
          canDelete={getTrue}
          onChange={this.onChangeValue}
          onDisplay={this.renderItem}
        />
      </div>
    );
  }
}
