import { getLocalizedName } from "common";
import { Entities } from "common/entities/types";
import { Context } from "common/types/context";
import { PMTriggeredEvent } from "common/types/events";
import { Row } from "../row";
import { ReasonDetails } from "./reason";

interface PropTypes {
  context: Context;
  entities: Entities;
  event: PMTriggeredEvent;
  hideLinks?: boolean;
}

export const PmTriggered = ({
  context,
  entities,
  event,
  hideLinks,
}: PropTypes) => (
  <div>
    <legend>{_("Details")}</legend>
    {entities[event.details.workOrderEntity] ? (
      <Row label={_("Type of work order created")}>
        {getLocalizedName(entities[event.details.workOrderEntity])}
      </Row>
    ) : undefined}
    <Row label={_("Work orders created")}>
      {event.details.workOrderIds.length}
    </Row>
    <ReasonDetails
      reason={event.details.reason}
      context={context}
      hideLinks={hideLinks}
    />
  </div>
);

PmTriggered.displayName = "PmTriggered";
