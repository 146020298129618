import * as R from "ramda";
import { JSX } from "react";
import { defaultFor } from "common";
import { Link } from "common/widgets/link";
import { Node, NodeLinkPropTypes } from "common/widgets/tree/types";

export const displayNodeWithLink =
  (link?: (node: Node) => string) =>
  (nodeLinkProps: NodeLinkPropTypes): JSX.Element => {
    const { node = defaultFor<Node>(), onClick, selected } = nodeLinkProps;
    const className = `x-tree-node-wrapper ${
      R.equals(node, selected?.[0]) ? "selected" : ""
    }`;

    return (
      <div className={className}>
        {node?.data?.image && <img src={node.data.image} />}
        <div className="x-tree-node-content">
          {link ? (
            <Link href={link(node)}>{node.label}</Link>
          ) : (
            <p onClick={onClick}>{node.label}</p>
          )}
          <div className="x-tree-node-subinfo">{node?.data?.subtitle}</div>
          <div className="x-tree-node-subinfo">{node?.data?.subsubtitle}</div>
        </div>
      </div>
    );
  };

export const displayExpandableNode =
  (isExpandable: boolean, withNodeIcon?: boolean) =>
  (nodeLinkProps: NodeLinkPropTypes): JSX.Element => {
    const { node, onClick, selected, onExpand, isExpanded } = nodeLinkProps;
    const className = `x-tree-node-wrapper ${
      R.equals(node, selected?.[0]) ? "selected" : ""
    }`;

    const expandClassName = `fa fa-angle-${isExpanded ? "up" : "down"}`;
    const nodeIconClassName = `fa fa-folder-${
      isExpanded && node.isGroup ? "open-" : ""
    }o`;

    const icons =
      node.isGroup && isExpandable ? (
        withNodeIcon ? (
          <div className="x-icons" onClick={onExpand}>
            <i className={expandClassName} />
            <i className={nodeIconClassName} />
          </div>
        ) : (
          <i className={expandClassName} onClick={onExpand} />
        )
      ) : withNodeIcon ? (
        <i className={nodeIconClassName} />
      ) : undefined;

    return (
      <div className={className}>
        {node?.data?.image && <img src={node.data.image} />}
        <div className="x-tree-node-content">
          {icons}
          <div className="x-node-label" onClick={onClick}>
            {node.label}
          </div>
          <div className="x-tree-node-subinfo">{node?.data?.subtitle}</div>
          <div className="x-tree-node-subinfo">{node?.data?.subsubtitle}</div>
        </div>
      </div>
    );
  };
