import * as R from "ramda";
import { Entity } from "common/entities/types";
import { merge2 } from "common/merge";
import { Form } from "common/types/forms";
import { filterLayout } from "./layout";

const cleanDefaults = (columnNames: string[]) => (form: Form) => {
  if (!columnNames || !form.settings.defaults) return form;
  const filteredDefaults = R.pick(columnNames, form.settings.defaults);
  return merge2("settings", "defaults", filteredDefaults, form);
};

const cleanDeletedColumns = (columnNames: string[]) => (form: Form) => {
  const newSettings = filterLayout(
    (groupColumn) => columnNames.includes(groupColumn.columnName),
    form.settings,
  );
  return { ...form, settings: newSettings };
};

export const cleanUpForm = (form: Form, entity: Entity): Form => {
  if (!form || !entity) return form;

  const columnNames = entity.columns.map((c) => c.name);
  const defaults = cleanDefaults(columnNames);
  const groups = cleanDeletedColumns(columnNames);

  return groups(defaults(form));
};
