import { PropsWithChildren } from "react";
import { ActionButton, ModifyButton } from "common/ui/buttons";

import "./index.scss";

interface PropTypes {
  title?: string;
  message?: string;
  closeButtonClick?: () => any;
  modifyButtonClick?: () => any;
  backdropClose?: () => any;
  closeButtonTitle?: string;
  modifyButtonTitle?: string;
}

export const FullPageModal = ({
  title,
  message,
  modifyButtonClick,
  closeButtonClick,
  modifyButtonTitle,
  closeButtonTitle,
  backdropClose,
  children,
}: PropsWithChildren<PropTypes>) => {
  const addButton = modifyButtonClick && (
    <ModifyButton className={"qa-fpm-button-add"} onClick={modifyButtonClick}>
      {modifyButtonTitle || _("Add Selected")}
    </ModifyButton>
  );

  const marginClass = modifyButtonClick ? "x-margin-left-10" : "";

  const closeButton = closeButtonClick && (
    <ActionButton
      className={`qa-fpm-button ${marginClass}`}
      onClick={closeButtonClick}
    >
      {closeButtonTitle || _("Close")}
    </ActionButton>
  );

  return (
    <div className="x-full-page-modal" onClick={backdropClose}>
      <div className="x-fpm-header">
        {title || message ? (
          <div className="x-fpm-header-info">
            {title && <h2 className="x-fpm-title">{title}</h2>}
            {message && <div className="x-fpm-message">{message}</div>}
          </div>
        ) : undefined}
        <div>
          {addButton}
          {closeButton}
        </div>
      </div>
      <div className="x-fpm-content">{children}</div>
    </div>
  );
};

FullPageModal.displayName = "FullPageModal";
