import { Component } from "react";
import { Entity } from "common/entities/types";
import {
  Group,
  Layout as FormLayout,
  OverviewPosition,
  RelatedEntity,
} from "common/form/types";
import { RelatedValue } from "common/record/form/content/related/types";
import { Reload } from "common/record/types";
import { Context } from "common/types/context";
import {
  filterEnabledRelatedEntities,
  getRelatedEntitiesShowInOverview,
} from "../functions";
import { OverviewRelatedRecords } from "../overview-related-records";
import { ViewForm } from ".";

interface PropTypes {
  withLinks: boolean;
  context: Context;
  entity: Entity;
  reload: Reload;
  isTemplate: boolean;
  layout: FormLayout;
  groups: Group[];
  value: RelatedValue;
}

export class ViewRecord extends Component<PropTypes> {
  static readonly displayName = "ViewRecord";

  getOverviewRelatedRecordsByPosition = (
    relatedEntities: RelatedEntity[],
    position: OverviewPosition,
  ) => {
    const { context, entity, reload, isTemplate, value } = this.props;

    const relatedEntitiesForOverview = getRelatedEntitiesShowInOverview(
      relatedEntities,
      context,
      position,
    );

    return relatedEntitiesForOverview?.length > 0 ? (
      <OverviewRelatedRecords
        entity={entity}
        relatedEntitiesInOverview={relatedEntitiesForOverview}
        isTemplate={isTemplate}
        reload={reload}
        value={value}
        onChange={undefined}
        displayTypes={["table"]}
      />
    ) : undefined;
  };

  render() {
    const {
      context,
      entity,
      layout,
      withLinks,
      groups = [],
      value,
    } = this.props;
    const { record } = value;
    const { relatedEntities } = layout;

    const enableRelatedEntities = filterEnabledRelatedEntities(
      context,
      entity,
      record,
      relatedEntities,
    );

    const relatedEntitiesForOverviewTop =
      this.getOverviewRelatedRecordsByPosition(enableRelatedEntities, "top");

    const relatedEntitiesForOverviewBottom =
      this.getOverviewRelatedRecordsByPosition(enableRelatedEntities, "bottom");

    return (
      <ViewForm
        context={context}
        entityName={entity.name}
        record={record.properties}
        withLinks={withLinks}
        groups={groups}
        relatedEntitiesForOverviewTop={relatedEntitiesForOverviewTop}
        relatedEntitiesForOverviewBottom={relatedEntitiesForOverviewBottom}
      />
    );
  }
}
