import { Context } from "common/types/context";
import { getColumn } from "common/entities";
import { Entity } from "common/entities/types";
import { LabelWidget } from "common/form/widget/label-widget";
import { Config } from "./types";

interface PropTypes {
  context: Context;
  entity: Entity;
  config: Config;
  values: string[];
}

export const ExplorerWidget = ({
  context,
  entity,
  config,
  values,
}: PropTypes) => {
  const columnName = config[values.length - 1].name;
  const column = getColumn(entity, columnName);
  const name = values[values.length - 1];

  if (name === null || name === "") {
    return <span>{_("(Empty)")}</span>;
  }
  return (
    <LabelWidget
      column={column}
      withLinks={false}
      context={context}
      value={name}
    />
  );
};
