import * as R from "ramda";
import {
  Query,
  Filter as FilterItem,
  Filter,
  isAnd,
  isOr,
  FilterOr,
  isFieldRule,
} from "common/query/types";
import { merge1 } from "common/merge";
import { getAnd } from "./common";

const defaultCondition = (): FilterOr => ({
  or: [
    { name: "isDeleted", op: "istrue" },
    { name: "isDeleted", op: "isfalse" },
  ],
});

export const isHideDeletedRule = (f: FilterItem, op: string): boolean =>
  isFieldRule(f) && f.name === "isDeleted" && f.op === op && !f.path;

export const isArchivedReference = (filter: Filter): boolean =>
  isAnd(filter)
    ? R.any(isArchivedReference, filter?.and)
    : isOr(filter) &&
      filter.or.some((f) => isHideDeletedRule(f, "istrue")) &&
      filter.or.some((f) => isHideDeletedRule(f, "isfalse"));

export const toggleIsDeleted = (query: Query, showDeleted: boolean): Query => {
  const and = getAnd(query.filter);
  const defaultFilter = defaultCondition();
  const newAnd = showDeleted
    ? and.concat(defaultFilter)
    : and.filter((f) => !isArchivedReference(f));

  return merge1("filter", { and: newAnd }, query);
};
