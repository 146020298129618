import { Context } from "common/types/context";
import { ValueComponent, ValueProps } from "common/with-value-for";
import { defaultFor } from "common";
import { RunQuery, QueryForEntity } from "common/query/types";
import { getFields } from "common/query-builder/functions";
import { Item, TreeItem } from "./item";
import { Config } from "./types";
import { applyGroup } from "./functions";

export interface ExplorerValue {
  children?: TreeItem[];
}

interface PropTypes {
  context: Context;
  runQuery: RunQuery;
  query: QueryForEntity;
  config: Config;
}

type Props = PropTypes & ValueProps<ExplorerValue>;

export class Explorer extends ValueComponent<ExplorerValue, PropTypes> {
  static readonly displayName = "Explorer";

  componentDidUpdate(prevProps: Props) {
    const prevEntity = prevProps?.query?.entity;
    const nextEntity = this.props?.query?.entity;
    if (prevEntity !== nextEntity) {
      this.setValue({ children: undefined });
    }
  }

  onChange = (value: TreeItem) => {
    this.setValue({ children: value.children });
  };

  render() {
    const {
      context,
      runQuery,
      query,
      config,
      value = defaultFor<ExplorerValue>(),
    } = this.props;

    return (
      <div className="x-explorer">
        <Item
          context={context}
          fields={getFields(context.entities, query)}
          runQuery={runQuery}
          query={applyGroup(context.entities, config, query)}
          config={config}
          values={[]}
          value={{ shown: true, children: value.children }}
          onChange={this.onChange}
        />
      </div>
    );
  }
}
