import { Event } from "common/types/events";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { Detail } from "./detail";

interface PropTypes {
  event: Event;
  entity: Entity;
  context: Context;
  hideLinks?: boolean;
}

export const EventDetail = ({
  event,
  entity,
  context,
  hideLinks,
}: PropTypes) => (
  <div className="x-event-details">
    <div className="x-event-content">
      <Detail
        event={event}
        entity={entity}
        context={context}
        hideLinks={hideLinks}
      />
    </div>
  </div>
);

EventDetail.displayName = "EventDetail";
