import * as R from "ramda";
import { Component } from "react";
import { entityTreeApi } from "common/api/entity-tree";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { TreeData } from "common/types/entity-tree";
import { LoadingIcon } from "common/widgets/loading-icon";
import { Node } from "common/widgets/tree/types";
import { ValueProps } from "common/with-value-for";
import { EntityTree } from "x/records/list/tree/entity-tree";

interface PropTypes extends ValueProps<Node> {
  context: Context;
  entity: Entity;
  link: (node: Node) => string;
  hasRecords: boolean;
  showTitle: boolean;
  mapNodes?: (node: Node[]) => Node[];
  showUngroup?: boolean;
  isExpandable?: boolean;
  expandedByDefault?: boolean;
  withNodeIcons?: boolean;
  className?: string;
}

interface StateType {
  loading: boolean;
  trees: Node[];
}

const normalize = (td: TreeData): Node => {
  const { title } = td.properties;
  return {
    name: td.id,
    label: title,
    isGroup: td.items.length > 0,
    children: td.items.map(normalize),
    data: td.properties,
  };
};

export class EntityTreeController extends Component<PropTypes, StateType> {
  constructor(props: PropTypes) {
    super(props);
    this.state = { loading: !!props.hasRecords, trees: [] };
  }

  componentDidMount() {
    const { hasRecords, context, mapNodes, entity } = this.props;

    if (hasRecords) {
      const mapNodesFn = mapNodes || R.identity;

      entityTreeApi(context.apiCall)
        .list(context.site.name, entity.name)
        .then((data) => data.trees.map(normalize))
        .catch(() => []) // TODO in task X5-1667
        .then((trees) =>
          this.setState({ loading: false, trees: mapNodesFn(trees) }),
        );
    }
  }

  onClick = (node: Node) => {
    this.props.onChange(node);
  };

  render() {
    if (this.state.loading) {
      return <LoadingIcon />;
    }

    const { trees } = this.state;
    const { showTitle, value } = this.props;

    return (
      <EntityTree
        trees={trees}
        selected={value}
        onClick={this.onClick}
        showTitle={showTitle}
        {...this.props}
      />
    );
  }
}
