import * as R from "ramda";
import { Context } from "common/types/context";
import { findCurrency } from "common/utils/currency";
import { EntityColumn } from "common/entities/entity-column/types";
import { currency, isDecimal } from "common/validate";

export const isOrgColumn = (column: EntityColumn) =>
  column.name &&
  column.name.substring(0, 3) === "org" &&
  column.dataType === "currency";

export const getCurrencySymbol = (
  currencyId: string,
  column: EntityColumn,
  context: Context,
) => {
  const { currency, masterCurrency, currencies } = context;
  const passedCurrency = currencyId && findCurrency(currencyId, currencies);
  const propsCurrencySymbol = passedCurrency && passedCurrency.symbol;
  const defaultCurrencySymbol =
    column && isOrgColumn(column) ? masterCurrency.symbol : currency.symbol;

  return propsCurrencySymbol || defaultCurrencySymbol;
};

export const isValueValid = (decimalScale: number) => (value: string) =>
  currency(value) && isDecimal(decimalScale, value);

export const displayCurrency = (sep: string, value: string | number) =>
  (R.isNil(value) ? "" : value.toString())
    .replace(/^\./, "0.")
    .replace(".", sep || ".");
