import { Component } from "react";
import * as R from "ramda";
import { getLocalizedName } from "common";
import { searchApi } from "common/api/search";
import { workOrderSettingsApi } from "common/api/work-order-settings";
import { behaveAs, getColumn } from "common/entities";
import { Entity } from "common/entities/types";
import { filterLayout } from "common/form/functions/layout";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { KeyOfType } from "common/types/key-of-type";
import { Properties } from "common/types/records";
import { WorkOrderSettings } from "common/types/work-order-settings";
import { VerticalField } from "common/ui/field";
import { RequiredField } from "common/ui/required-field";
import { getFkOrDefault } from "common/utils/foreign-key";
import { AlertWarning } from "common/widgets/alert";
import { QuerySelector } from "common/widgets/selector/query-selector";
import { FancyCheckbox } from "common/widgets/fancy-checkbox";
import { Ufloat } from "common/widgets/number";
import { MultiEntityRecordSelector } from "common/widgets/record-selector/multi-entity";
import { ValueProps } from "common/with-value-for";
import { ForeignKeySelector } from "common/widgets/selector/foreign-key-selector";
import { getRelatedRecords } from "common/record/utils";
import { getSitePartsLookupContent } from "../part/functions";
import {
  getBatchQuery,
  getPartChargeRelatedEntities,
  getStockQuery,
} from "../part-charge/functions";
import { CustomConfig, CustomConfigSettings, RelatedValue } from "../types";

interface PropTypes extends ValueProps<Properties> {
  context: Context;
  entity: Entity;
  preUpdateProperties: Properties;
  assets: ForeignKey[];
  shouldCreateAssetPart?: boolean;
}

interface StateType {
  shouldCreateAssetPart: boolean;
}

export class PartCharge extends Component<PropTypes, StateType> {
  static readonly displayName = "PartCharge";

  state: StateType = {
    shouldCreateAssetPart: false,
  };

  componentDidMount() {
    this.loadShouldCreateAssetPartSettings();
  }

  componentDidUpdate() {
    const { value, onChange } = this.props;

    if (!value || value.shouldCreateAssetPart !== undefined) return;

    onChange({
      ...value,
      shouldCreateAssetPart: this.state.shouldCreateAssetPart,
    });
  }

  loadShouldCreateAssetPartSettings = () => {
    const { context } = this.props;

    workOrderSettingsApi(context.apiCall)
      .get()
      .then((workOrderSettings: WorkOrderSettings) => {
        this.onChangeShouldCreateAssetPart(
          workOrderSettings.shouldCreateAssetPartsByDefault,
        );
        this.setState({
          shouldCreateAssetPart:
            workOrderSettings.shouldCreateAssetPartsByDefault,
        });
      })
      .catch(() => undefined);
  };

  onChangePart = (partId: ForeignKey) => {
    const { value, onChange } = this.props;

    onChange({
      ...value,
      partId,
      stockId: undefined,
      batchId: undefined,
    });
  };

  onRecordSelect = (record: Properties) => {
    const { context, entity, value, onChange } = this.props;
    const { entities } = context;
    const { id, locationId, partId, onHand = 0 } = record || {};

    if (!partId) {
      const { stockEntity } = getPartChargeRelatedEntities(entities, entity);
      const partEntity = entities[stockEntity.arguments.partEntity];

      getFkOrDefault(context.apiCall, partEntity, record).then(
        (partId: ForeignKey) =>
          onChange({ ...value, partId, stockId: undefined }),
      );

      return;
    }

    const { title, number } = locationId || {};
    const stockId = locationId
      ? {
          id,
          number,
          title,
          onHand,
          customtitle: _("on Hand {NUMBER}").replace("{NUMBER}", onHand),
        }
      : undefined;

    onChange({
      ...value,
      partId,
      stockId,
      batchId: undefined,
    });
  };

  onChangeStock = (stockId: ForeignKey) => {
    const { value, onChange } = this.props;

    onChange({
      ...value,
      stockId,
      batchId: undefined,
    });
  };

  onChangeDropdown =
    (key: KeyOfType<Properties, ForeignKey>) => (id: ForeignKey) => {
      const { value, onChange } = this.props;

      onChange({
        ...value,
        shouldCreateAssetPart: this.state.shouldCreateAssetPart,
        [key]: id,
      });
    };

  onChangeQuantity = (quantity: number) => {
    const { value, onChange } = this.props;

    onChange({
      ...value,
      quantity,
    });
  };

  onChangeShouldCreateAssetPart = (shouldCreateAssetPart: boolean) => {
    const { value, onChange } = this.props;
    onChange({
      ...value,
      shouldCreateAssetPart,
    });
    this.setState({ shouldCreateAssetPart });
  };

  render() {
    const {
      context,
      entity,
      assets,
      preUpdateProperties,
      value = {},
    } = this.props;
    const { shouldCreateAssetPart = false } = this.state;
    const { entities, site } = context;
    const { batchChargeEntity, batchEntity, partEntity, stockEntity } =
      getPartChargeRelatedEntities(entities, entity);

    const isNew = !value.id;

    const runQuery = searchApi(context.apiCall).runQuery;
    const qtyColumn = getColumn(batchChargeEntity, "quantity");

    const { stockId } = value;
    const onHand = stockId && stockId.onHand;
    const quantity = value.quantity && parseFloat(value.quantity);
    const initialQuantity = preUpdateProperties?.quantity
      ? parseFloat(preUpdateProperties.quantity)
      : 0;

    const quantityDelta = quantity - initialQuantity;
    const stockError =
      quantity === 0
        ? _("Quantity has to be greater than 0.")
        : quantityDelta > onHand
          ? _("Insufficient stock.")
          : "";

    const content = getSitePartsLookupContent(
      site.name,
      batchEntity,
      partEntity,
      stockEntity,
    );

    return (
      <div className="x-custom-form x-part-charges-custom">
        <RequiredField
          label={getLocalizedName(partEntity)}
          className="qa-part-charge-part"
          value={value.partId}
          input={
            <MultiEntityRecordSelector
              context={context}
              entity={partEntity}
              disabled={!isNew}
              withLinks={true}
              value={value.partId}
              content={content}
              onChange={this.onChangePart}
              onSelect={this.onRecordSelect}
            />
          }
        />
        <RequiredField
          label={getLocalizedName(stockEntity)}
          className="qa-part-charge-stock"
          value={value.stockId}
          input={
            <QuerySelector
              query={
                value.partId
                  ? getStockQuery(stockEntity, batchEntity, value.partId.id)
                  : undefined
              }
              disabled={!isNew || !value.partId}
              runQuery={runQuery}
              context={context}
              entity={stockEntity}
              value={value.stockId}
              onChange={this.onChangeStock}
            />
          }
        />
        {value.partId?.manualBatchSelection ? (
          <RequiredField
            label={getLocalizedName(batchEntity)}
            className="qa-part-charge-batch"
            value={value.batchId}
            input={
              <QuerySelector
                context={context}
                query={
                  value.stockId
                    ? getBatchQuery(batchEntity, value.stockId.id)
                    : undefined
                }
                disabled={!isNew || !value.stockId}
                runQuery={runQuery}
                value={value.batchId}
                onChange={this.onChangeDropdown("batchId")}
              />
            }
          />
        ) : undefined}
        {assets.length > 1 ? (
          <RequiredField
            label={_("Asset")}
            value={value.assetId}
            input={
              <ForeignKeySelector
                context={context}
                entity={entity}
                options={assets}
                value={value.assetId}
                onChange={this.onChangeDropdown("assetId")}
              />
            }
          />
        ) : undefined}
        <RequiredField
          label={getLocalizedName(qtyColumn)}
          className={`${
            stockError.length > 0 ? "x-has-error " : ""
          }qa-part-charge-quantity`}
          value={value.quantity}
          input={
            <div>
              <Ufloat value={value.quantity} onChange={this.onChangeQuantity} />
              {stockError.length > 0 && (
                <AlertWarning
                  className="x-margin-top-10-i"
                  message={stockError}
                />
              )}
            </div>
          }
        />
        <VerticalField
          input={
            <FancyCheckbox
              label={_("Create Asset Parts Record")}
              value={shouldCreateAssetPart}
              onChange={this.onChangeShouldCreateAssetPart}
            />
          }
        />
      </div>
    );
  }
}

export const partChargeRef: CustomConfig<RelatedValue> = ({
  context,
  value: { record, related },
  parentEntity,
  relatedEntity,
  recordDetail,
}): CustomConfigSettings<RelatedValue> => {
  const workOrderAssetEntity = R.values(context.entities).filter(
    (entity) =>
      behaveAs("WorkOrderAsset", entity) &&
      entity.arguments.workOrderEntity === parentEntity.name,
  )[0];

  const relatedAssets = (
    (workOrderAssetEntity &&
      record.related &&
      record.related[workOrderAssetEntity.name]) ||
    []
  ).map((a) => a.properties.assetId);

  const uiAssets = (
    (workOrderAssetEntity &&
      related &&
      related.form &&
      related.form[workOrderAssetEntity.name]) ||
    []
  ).map((a) => a.properties.assetId);

  const mainAsset =
    (recordDetail && recordDetail.form && recordDetail.form.assetId) ||
    record.properties.assetId;

  const assets = relatedAssets
    .concat(uiAssets)
    .concat([mainAsset])
    .filter((a) => a);

  const partCharges = getRelatedRecords(relatedEntity.name, record);
  const updatedPartCharge = related.partialForm?.[relatedEntity.name];
  const originalRecord = partCharges?.find(
    (p) => p.properties?.id === updatedPartCharge?.id,
  );

  return {
    defaultForm: {
      assetId: mainAsset,
    },
    layoutMapper: (l) =>
      filterLayout(
        (c) => c.columnName !== "assetId" && c.columnName !== "stockId",
        l,
      ),
    custom: (value, onChange) => (
      <PartCharge
        entity={relatedEntity}
        context={context}
        assets={assets}
        preUpdateProperties={originalRecord?.properties}
        value={value}
        onChange={onChange}
      />
    ),
    isValid: (properties) => {
      const quantity = properties.quantity
        ? parseFloat(properties.quantity)
        : 0;

      const initialQuantity = parseFloat(
        originalRecord?.properties?.quantity || 0,
      );

      const quantityDelta = quantity - initialQuantity;
      return !!(
        properties.assetId &&
        properties.partId &&
        properties.stockId &&
        quantity &&
        (!properties.partId.manualBatchSelection || properties.batchId) &&
        properties.stockId.onHand !== undefined &&
        quantityDelta <= properties.stockId.onHand
      );
    },
    customOnChange: undefined,
  };
};
