import { Component } from "react";
import { defaultFor } from "common";
import { FormSelector } from "common/form/form-selector";
import { filterFormsByEntity } from "common/functions/forms";
import { CancellablePromise } from "common/types/promises";
import { VerticalField } from "common/ui/field";
import { AlertInfo } from "common/widgets/alert";
import { ValueProps } from "common/with-value-for";
import { Action } from "../action";
import { PropTypes as ActionPropTypes } from "../types";

export interface SwitchFormValue {
  formId: number;
}

type PropTypes = ActionPropTypes & ValueProps<SwitchFormValue>;

export class SwitchForm extends Component<PropTypes> {
  static readonly displayName = "SwitchForm";

  getAvailableForms = () => {
    const { context, entity, records = [] } = this.props;

    return filterFormsByEntity(context.forms, entity.name).filter(
      (f) => f.id !== records[0]?.properties?.formId,
    );
  };

  onFormIdChange = (formId: number) => {
    const { value, onChange } = this.props;
    onChange({ ...value, formId });
  };

  onOk = () => {
    const { dismiss, value = defaultFor<SwitchFormValue>() } = this.props;
    const forms = this.getAvailableForms();

    const form = value.formId
      ? forms.find((f) => f.id === value.formId)
      : forms?.[0];

    return CancellablePromise.resolve().then(() => dismiss(true, form));
  };

  render() {
    const {
      context,
      dismiss,
      entity,
      records = [],
      value = defaultFor<SwitchFormValue>(),
    } = this.props;

    const availableForms = this.getAvailableForms();

    return (
      <Action
        requiresAuthentication={false}
        context={context}
        dismiss={dismiss}
        entity={entity}
        records={records}
        title={_("Switch Form")}
        btnLabel={_("Switch")}
        onOk={this.onOk}
      >
        <AlertInfo
          message={_(
            "Switching the form could change the layout, add default values or it may require input data",
          )}
        />
        <VerticalField
          label={_("Form")}
          isRequired={true}
          input={
            <FormSelector
              forms={availableForms}
              preselectFirstByDefault={true}
              value={value.formId}
              onChange={this.onFormIdChange}
            />
          }
        />
      </Action>
    );
  }
}
