import * as R from "ramda";
import { ValueComponent } from "common/with-value-for";
import { Operator } from "common/entities/operators";
import { ButtonModal } from "common/widgets/modal/button-modal";

interface PropTypes {
  title: string;
  operators: Operator[];
  disabled?: boolean;
}

export class Operators extends ValueComponent<string, PropTypes> {
  static readonly displayName = "Operators";
  render() {
    const { title, operators, disabled, value } = this.props;

    const selected = value && R.find((op) => op.name === value, operators);

    const button = (
      <div
        className={`x-filter-operators-button ${disabled ? "x-disabled" : ""}`}
      >
        {selected ? (
          <span className="x-filter-op-icon">{selected.icon}</span>
        ) : (
          <i className="fa fa-filter" />
        )}
      </div>
    );

    const menu = (
      <div className="x-filter-options">
        <div
          className={`x-filter-option ${!selected ? "x-selected" : ""}`}
          onClick={this.onChangeSetDefaultValue(undefined)}
        >
          <div className="x-filter-option-label">{_("No filter")}</div>
        </div>
        {operators.map((op, index) => (
          <div
            key={index}
            className={`x-filter-option ${
              op === selected ? " x-selected" : ""
            }`}
            onClick={this.onChangeSetDefaultValue(op.name)}
          >
            <div className="x-filter-option-icon">{op.icon}</div>
            <div className="x-filter-option-label">{op.label}</div>
          </div>
        ))}
      </div>
    );

    return (
      <ButtonModal
        button={button}
        content={menu}
        className="x-filter-operators"
        closeOnClick={true}
        disabled={disabled}
        // modal props
        title={title}
        size="small"
        noPadding={true}
        hideFooter={true}
        allowFullscreen={false}
      />
    );
  }
}
