import { Drilldown } from "./drilldown";
import { LookupPropTypes } from "./types";
// eslint-disable-next-line import/no-cycle
import { UserDefined } from "./user-defined";

export const LookupEditMode = (props: LookupPropTypes) => {
  const { type } = props.layoutColumn.lookupConfiguration;

  return type === "drilldown" ? (
    <Drilldown {...props} />
  ) : type === "userDefined" ? (
    <UserDefined {...props} />
  ) : undefined;
};
