import * as React from "react";
import { Component } from "common/component";

export interface InjectedProps {
  toggleFn?: () => void;
  toggleOpened?: boolean;
}

interface State {
  toggleOpened: boolean;
}

type OnToggleFn = (isOpened: boolean) => void;

export interface DefaultOptions {
  defaultOpened?: boolean;
  localStorageKey?: string;
  toggleCallback?: OnToggleFn;
}

export interface PropTypes {
  isOpened?: boolean;
  onToggle?: OnToggleFn;
}

/** Simple Higher Order Component providing a single stateful boolean
 * Options:
 * defaultOpened - to choose default initial state. Default is true
 * localStorageKey - key for persisting state. State is *not* persisted if null
 * Injected Props:
 * toggleFn - a function for toggling the boolean
 * toggleOpened - the current boolean state
 */
export const toggleable = ({
  defaultOpened = true,
  localStorageKey = "",
  toggleCallback,
}: DefaultOptions = {}) =>
  function <TOriginalProps>(
    OriginalComponent: React.ComponentType<TOriginalProps & InjectedProps>,
  ) {
    type ResultProps = TOriginalProps & PropTypes;

    return class Collapsible extends Component<ResultProps, State> {
      static readonly displayName = `Collapsible(${
        OriginalComponent.displayName || (OriginalComponent as any).name
      })`;
      private localStorageKey = localStorageKey
        ? `collapsible.${localStorageKey}`
        : "";

      constructor(props: ResultProps) {
        super(props);

        this.state = {
          toggleOpened:
            this.getSavedIsOpened() ?? props.isOpened ?? defaultOpened,
        };
      }

      componentDidUpdate(prevProps: ResultProps) {
        if (prevProps.isOpened !== this.props.isOpened) {
          this.toggleOpened(this.props.isOpened);
        }
      }

      toggle = () => {
        this.toggleOpened(!this.state.toggleOpened);
      };

      toggleOpened = (isOpened: boolean) => {
        const callback = this.props.onToggle ?? toggleCallback;
        this.saveIsOpened(isOpened);
        this.setState({ toggleOpened: isOpened });

        if (callback) callback(isOpened);
      };

      saveIsOpened = (isOpened: boolean) => {
        if (this.localStorageKey) {
          localStorage.setItem(
            this.localStorageKey,
            isOpened ? "true" : "false",
          );
        }
      };

      getSavedIsOpened = () => {
        return this.localStorageKey
          ? localStorage.getItem(this.localStorageKey) === "true"
          : undefined;
      };

      render() {
        return (
          <OriginalComponent
            {...this.props}
            {...this.state}
            toggleFn={this.toggle}
          />
        );
      }
    };
  };
