import * as R from "ramda";
import { behaveAs } from "common/entities";
import { Entity, Entities } from "common/entities/types";
import { BehaviorName } from "common/types/behaviors";

export const findEntityByArgs = (
  entities: Entities,
  behaviorName: BehaviorName,
  argumentName: string,
  argumentValue: string,
): Entity =>
  R.find(
    (e) =>
      behaveAs(behaviorName, e) &&
      e?.arguments?.[argumentName] === argumentValue,
    R.values(entities),
  );

export const isFullEntity = (e: Entity) =>
  !!(e && (e.type === "Entity" || e.type === "SubEntity"));
