// TODO: RTS
import { Component } from "react";
import * as R from "ramda";
import { recordsApi } from "common/api/records";
import { searchApi } from "common/api/search";
import { merge3 } from "common/merge";
import { PropTypes as TableWithFormProps } from "common/record/form/content/related//table-with-form/types";
import { RelatedValue } from "common/record/form/content/related//types";
import { StandardRelated } from "common/record/form/content/related/standard-related";
import { injected } from "common/record/form/content/related/table-with-form/functions";
import { Reload } from "common/record/types";
import { payloadToRecord } from "common/record/utils";
import { ApiCall } from "common/types/api";
import { CancellablePromise } from "common/types/promises";
import { ApiError } from "common/ui/api-error";
import { LoadingIcon } from "common/widgets/loading-icon";
import { ValueProps } from "common/with-value-for";
import {
  DelayedApiCall,
  ExecuteAction,
  withExplicitAuth,
} from "x/records/edit-controller/with-explicit-auth";
import { AssetMeterReadingForm } from "./form";
import {
  getAssetMeters,
  isExplicitAuthError,
  getMeters,
  getQueryFor,
} from "./functions";

interface PropTypes extends TableWithFormProps {
  reload: Reload;
  explicitAuth?: DelayedApiCall;
  executeAction?: ExecuteAction;
}

type Props = PropTypes & ValueProps<RelatedValue>;

interface StateType {
  meters?: any[];
  loading?: boolean;
  value?: RelatedValue;
  error?: any;
}

class AssetMeterReadingComp extends Component<Props, StateType> {
  static readonly displayName = "AssetMeterReading";

  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value,
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchMeters();
    this.fetchReadings();
  }

  fetchMeters = () => {
    const { context, entity, value } = this.props;
    const { record } = value;

    const assetMeters = getAssetMeters(record, entity);

    getMeters(context, entity, assetMeters)
      .then((meters) => this.setState({ loading: false, meters }))
      .catch((error) => this.setState({ loading: false, error }));
  };

  fetchReadings = () => {
    const { context, entity, recordId } = this.props;

    const query = getQueryFor(entity, recordId);

    searchApi(context.apiCall)
      .runQueryFkExpansion(query)
      .then((records: any[]) =>
        this.setState({
          loading: false,
          value: merge3(
            "record",
            "related",
            entity.name,
            records.map((properties) => ({ properties, actions: undefined })),
            this.state.value,
          ),
        }),
      )
      .catch((error) => this.setState({ loading: false, error }));
  };

  deleteRecord = (recordId: string) => (apiCall: ApiCall) => {
    const { value } = this.state;
    const { entity } = this.props;

    const entityName = entity.name;
    const meters = value.record.related[entityName];

    this.setState({ loading: true });
    return recordsApi(apiCall)
      .remove(entity.name, recordId)
      .then(() =>
        this.setState((prevState) => ({
          loading: false,
          value: R.assocPath(
            ["record", "related", entityName],
            R.filter((r) => r.properties.id !== recordId, meters),
            prevState.value,
          ),
        })),
      )
      .catch((error) => {
        this.setState({ loading: false, error });
        return CancellablePromise.reject(error);
      });
  };

  onChange = (value: RelatedValue) => {
    const { entity, executeAction } = this.props;
    const entityName = entity.name;

    const meters = value.related.form[entityName] || [];

    const deleted = R.find((r) => r.properties.isDeleted, meters);
    const added = R.find((r) => r.properties.tempId >= 0, meters);

    if (deleted) {
      executeAction(
        "remove",
        payloadToRecord(deleted),
        undefined,
        this.deleteRecord(deleted.properties.id),
      );
      return;
    }

    if (added) {
      this.setState({ loading: true });
      this.fetchReadings();
      return;
    }

    this.setState({ value });
  };

  render() {
    const { context, entity, recordId, reload, executeAction, explicitAuth } =
      this.props;
    const { value, loading, error, meters } = this.state;
    const { record } = value;

    if (loading && meters) return <LoadingIcon />;

    const { assetMeterEntity } = entity.arguments;
    const assetMeters = record.related[assetMeterEntity];
    return (
      <div>
        {error && !isExplicitAuthError(error) ? (
          <ApiError error={error} />
        ) : undefined}
        <StandardRelated
          {...this.props}
          withOwnerId={true}
          value={value}
          onChange={this.onChange}
        >
          <AssetMeterReadingForm
            {...injected<RelatedValue>()}
            assetId={recordId}
            meters={meters}
            assetMeters={assetMeters}
            context={context}
            entity={entity}
            // validation values needs to be updated and current value of related -> Asset Meters needs
            // to be refreshed. thus a full refresh
            onSavePerformed={reload}
            onChange={this.onChange}
            executeAction={executeAction}
            explicitAuth={explicitAuth}
          />
        </StandardRelated>
      </div>
    );
  }
}

export const AssetMeterReading = withExplicitAuth<Props>(AssetMeterReadingComp);
