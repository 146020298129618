import { UTCDateRange } from "common/date-time/types";
import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";
import { RelatedEntity } from "common/form/types";
import { getRelatedQuery } from "common/query/for-table";
import { OrderField } from "common/query/types";
import { LaborCharge } from "common/record/form/content/related/labor-charge";
import { PartCharge } from "common/record/form/content/related/part-charge";
import { DetailUiValue, Reload } from "common/record/types";
import { Context } from "common/types/context";
import {
  defaultDisplayTypes,
  RelatedFormDisplayTypes,
} from "common/types/related-form-display";
import { GoFn } from "common/types/url";
import { ValueComponent, ValueProps } from "common/with-value-for";
import { PartsToBeCounted } from "common/record/form/content/related/parts-to-be-counted";
import { ApprovalCostCenter } from "./approval-cost-center";
import { Approver } from "./approver";
import { AssetMeterReading } from "./asset-meter-reading";
import { eventRef } from "./behavior-refs/event-ref";
import { orderRef } from "./behavior-refs/order-ref";
import { partChargeRef } from "./behavior-refs/part-charge-ref";
import { procedureRef } from "./behavior-refs/procedure-ref";
import { workOrderAssetRef } from "./behavior-refs/work-order-asset-ref";
import { Event } from "./event";
import { PartSupplier } from "./part-supplier";
import { ProcedureType } from "./procedure-type";
import { PurchaseOrderItem } from "./purchase-order-item";
import { Requirement } from "./requirement";
import { Requisitioner } from "./requisitioner";
import { RequisitionerGroup } from "./requisitioner-group";
import { RequisitioningItem } from "./requisitioning-item";
import { StandardRelated } from "./standard-related";
import { RecordStock as Stock } from "./stock";
import { PropTypes as DefaultPropTypes } from "./table-with-form/types";
import { Task } from "./task";
import { ToDo } from "./todo";
import { CustomConfig, RelatedValue } from "./types";

interface PropTypes {
  context: Context;
  parentEntity: Entity;
  entity: Entity;
  recordId: any;
  entityConfig: RelatedEntity;
  reload: Reload;
  recordDetail: DetailUiValue;
  isTemplate: boolean;
  orderBy?: OrderField;
  defaultDates?: UTCDateRange;
  highlighted?: string[];
  onCancel?: () => void;
  displayTypes?: RelatedFormDisplayTypes;
  goTo?: GoFn;
  withLinks?: boolean;
}

const defaultConfig: CustomConfig<RelatedValue> = () => ({
  defaultForm: undefined,
  layoutMapper: (l) => l,
  custom: () => undefined,
  customOnChange: undefined,
});

const getLayoutMapperAndCustom = (
  entity: Entity,
): CustomConfig<RelatedValue> => {
  // The order is important
  if (behaveAs("Event", entity)) return eventRef;
  if (behaveAs("PartCharge", entity)) return partChargeRef;
  if (behaveAs("WorkOrderAsset", entity)) return workOrderAssetRef;
  if (behaveAs("Procedure", entity)) return procedureRef;
  if (behaveAs("Order", entity)) return orderRef;

  return defaultConfig;
};

export class RelatedRecordsForm extends ValueComponent<
  RelatedValue,
  PropTypes
> {
  static readonly displayName = "RelatedRecordsForm";

  onChange = (newValue: RelatedValue) => {
    const {
      context,
      parentEntity,
      entity,
      recordDetail,
      isTemplate,
      value,
      onChange,
      goTo,
    } = this.props;

    const { customOnChange } = getLayoutMapperAndCustom(entity)({
      context,
      parentEntity,
      relatedEntity: entity,
      value,
      recordDetail,
      isTemplate,
      onChange,
      goTo,
    });

    this.setValue(customOnChange ? customOnChange(newValue) : newValue);
  };

  getForm = () => {
    const {
      context,
      parentEntity,
      entity,
      recordId,
      entityConfig,
      reload,
      isTemplate,
      value,
      recordDetail,
      orderBy,
      defaultDates,
      highlighted,
      onCancel,
      displayTypes = defaultDisplayTypes,
      goTo,
      onChange,
      withLinks,
    } = this.props;
    const { entities } = context;

    const relatedRecords = value.record.related
      ? value.record.related[entity.name]
      : [];

    const query = getRelatedQuery(
      entity,
      entities,
      entityConfig,
      relatedRecords,
      false,
    );

    const { layoutMapper, custom, defaultForm, isValid, actions } =
      getLayoutMapperAndCustom(entity)({
        context,
        parentEntity,
        relatedEntity: entity,
        value,
        recordDetail,
        isTemplate,
        onChange,
        goTo,
      });

    const defaultProps: DefaultPropTypes & ValueProps<RelatedValue> = {
      context,
      query,
      parentEntity,
      entity,
      recordId,
      layoutMapper,
      custom,
      defaultForm,
      isTemplate,
      value,
      isValid,
      actions,
      onChange: this.onChange,
      onCancel,
      orderBy,
      highlighted,
      displayTypes,
      recordDetail,
      withLinks: withLinks,
    };

    if (behaveAs("Task", entity)) {
      return <Task {...defaultProps} valueKey={entity.name} />;
    }

    if (behaveAs("Event", entity)) {
      return <Event {...defaultProps} defaultDates={defaultDates} />;
    }

    if (behaveAs("Requirement", entity)) {
      return <Requirement {...defaultProps} />;
    }

    if (behaveAs("LaborCharge", entity)) {
      return <LaborCharge {...defaultProps} />;
    }

    if (behaveAs("PartCharge", entity)) {
      return <PartCharge {...defaultProps} />;
    }

    if (behaveAs("Stock", entity)) {
      return <Stock {...defaultProps} reload={reload} />;
    }

    if (behaveAs("AssetMeterReading", entity)) {
      return <AssetMeterReading {...defaultProps} reload={reload} />;
    }

    if (behaveAs("ToDo", entity)) {
      return <ToDo {...defaultProps} />;
    }

    if (behaveAs("PurchaseOrderItem", entity)) {
      return <PurchaseOrderItem {...defaultProps} />;
    }

    if (behaveAs("PartSupplier", entity)) {
      return <PartSupplier {...defaultProps} reload={reload} />;
    }

    if (behaveAs("Approver", entity)) {
      return <Approver {...defaultProps} reload={reload} />;
    }

    if (behaveAs("Requisitioner", entity)) {
      return <Requisitioner {...defaultProps} />;
    }

    if (behaveAs("RequisitionerGroup", entity)) {
      return <RequisitionerGroup {...defaultProps} />;
    }

    if (behaveAs("ApprovalCostCenter", entity)) {
      return <ApprovalCostCenter {...defaultProps} reload={reload} />;
    }

    if (behaveAs("RequisitioningItem", entity)) {
      return <RequisitioningItem {...defaultProps} />;
    }

    if (behaveAs("ProcedureType", entity)) {
      return <ProcedureType {...defaultProps} goTo={goTo} />;
    }

    if (behaveAs("PartsToBeCounted", entity)) {
      return <PartsToBeCounted {...defaultProps} reload={reload} />;
    }

    return <StandardRelated {...defaultProps} />;
  };

  render() {
    return <div className="x-related-records">{this.getForm()}</div>;
  }
}
