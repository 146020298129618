import { JSX } from "react";
import {
  ActionButtonLarge,
  CancelButtonLarge,
  ModifyButtonLarge,
} from "common/ui/buttons";

import "./index.scss";

interface PropTypes {
  title: string;
  content?: string | JSX.Element;
  action1: string;
  action2: string;
  isAction2Disabled?: boolean;
  isCancelModify?: boolean;
  onAction1: () => any;
  onAction2: () => any;
}

export const Warning = ({
  title,
  content,
  action1,
  action2,
  isAction2Disabled,
  isCancelModify,
  onAction1,
  onAction2,
}: PropTypes) => {
  const Action1Component = isCancelModify
    ? CancelButtonLarge
    : ActionButtonLarge;

  const Action2Component = isCancelModify
    ? ModifyButtonLarge
    : ActionButtonLarge;

  return (
    <div className="x-warning-overlay">
      <div className="x-warning-overlay-container">
        <div className="x-warning-overlay-middle">
          <i className="fa fa-exclamation-circle" />
          <div className="x-warning-overlay-content">
            <h3 className="x-warning-overlay-title">{title}</h3>
            {typeof content === "string" ? <p>{content}</p> : content}
          </div>
          <div className="x-warning-overlay-actions">
            <Action1Component className="qa-action1" onClick={onAction1}>
              {action1}
            </Action1Component>
            <Action2Component
              className="qa-action2"
              onClick={onAction2}
              disabled={isAction2Disabled}
            >
              {action2}
            </Action2Component>
          </div>
        </div>
      </div>
    </div>
  );
};
