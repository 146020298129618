import { Component, JSX } from "react";
import { defaultPageSize } from "common/types/pagination";
import {
  filterRecords,
  mapRecord,
  sortRecords,
} from "common/widgets/list-with-search/functions";
import { PropOrPathOf } from "common/widgets/list-with-search/types";
import { PaginationFooter } from "common/widgets/pagination/footer";
import { ValueProps } from "common/with-value-for";
import { InputWithSearch } from "../input-with-search";
import { EmptyList } from "./empty-list";
import { NoResults } from "./no-results";
import { getHighlighter } from "./search-lib";

interface PropTypes<TRecord, TKey = PropOrPathOf<TRecord>>
  extends ValueProps<string> {
  loading: boolean;
  records: TRecord[];
  display: (
    record: TRecord,
    originalRecord?: TRecord,
    highlight?: (s: string) => string,
  ) => JSX.Element;
  filterBy: TKey | Array<TKey>;
  label: string;
  disableSorting?: boolean;
  children?: any;
  emptyListContent?: JSX.Element;
}

interface StateType {
  page: number;
  pageSize: number;
}

export class ListWithSearch<TRecord> extends Component<
  PropTypes<TRecord>,
  StateType
> {
  state: StateType = {
    page: 0,
    pageSize: defaultPageSize,
  };

  componentDidUpdate(prevProps: PropTypes<TRecord>) {
    if (this.props.value !== prevProps.value) {
      this.setState({ page: 0 });
    }
  }

  onPageChange = (page: number) => {
    this.setState({ page });
  };

  onPageSizeChange = (pageSize: number) => {
    this.setState({ pageSize, page: 0 });
  };

  render() {
    const {
      records,
      loading,
      filterBy = [],
      disableSorting,
      display,
      label,
      children,
      value = "",
      onChange,
      emptyListContent,
    } = this.props;
    const { page, pageSize } = this.state;

    if (loading) return null;

    const highlight = getHighlighter(value);
    const filters = Array.isArray(filterBy) ? filterBy : [filterBy];

    const filteredRecords = filterRecords(records, value, filters);
    const sortedRecords = disableSorting
      ? filteredRecords
      : sortRecords(filteredRecords, filters[0]);

    const firstRecordIndex = page * pageSize;
    const recordsPage = sortedRecords.slice(
      firstRecordIndex,
      firstRecordIndex + pageSize,
    );

    const items = recordsPage.map((record, index) => (
      <li key={index} className="x-list-grid-item qa-list-grid-item">
        {display(
          filters ? mapRecord(highlight, filters, record) : record,
          record,
          highlight,
        )}
      </li>
    ));

    return (
      <div className="x-list-grid" data-testid="list-with-search">
        <div className="x-list-grid-header">
          <div className="x-padding-20">
            <InputWithSearch value={value} onChange={onChange} />
            {children}
          </div>
        </div>

        {records.length ? (
          items.length ? (
            <div className="x-list-container">
              <ul className="x-list-grid-items container-fluid">{items}</ul>
            </div>
          ) : (
            <NoResults itemsName={label} />
          )
        ) : (
          emptyListContent ?? <EmptyList itemsName={label} />
        )}

        <PaginationFooter
          page={page}
          onChangePage={this.onPageChange}
          pageSize={pageSize}
          onChangePageSize={this.onPageSizeChange}
          totalRecords={filteredRecords.length}
          loadingRecords={false}
        />
      </div>
    );
  }
}
