import { cloneElement } from "react";
import { QueryForEntity } from "common/query/types";
import { Entities } from "common/entities/types";
import { addIdFilter } from "common/query/filter";
import { Output } from "./types";

interface Injected {
  query: QueryForEntity;
  output: Output;
}

interface PropTypes {
  query: QueryForEntity;
  output: Output;
  entities: Entities;
  entityName: string;
  children?: any;
}

export const QueryWithIdFilter = ({
  query,
  output,
  entities,
  entityName,
  children,
}: PropTypes) =>
  cloneElement(children, {
    query: addIdFilter(query, entityName, entities),
    output,
  } as Injected);
