interface PropTypes {
  userName: string;
  createdOn?: string;
}

export const CommentHeader = ({ userName, createdOn }: PropTypes) => {
  return (
    <div className="x-comment-header x-flex">
      {userName}
      {createdOn ? <div className="x-created-on">{createdOn}</div> : undefined}
    </div>
  );
};

CommentHeader.displayName = "CommentHeader";
