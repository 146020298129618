import { Component, MouseEvent } from "react";
import * as R from "ramda";
import type { Entity } from "common/entities/types";
import { isQueryValid } from "common/query-builder/validation";
import type { QueryForEntity } from "common/query/types";
import { allConditionTypes } from "common/types/condition-type";
import type { Context } from "common/types/context";
import { arrayToString } from "common/utils/array";
import { Chicklet } from "common/widgets/chicklets/chicklet";
import { Modal } from "common/widgets/modal";
import type { ValueProps } from "common/with-value-for";
import { ConfigurationForm } from "x/scheduler2/planner/unassigned-work/configuration/form";
import {
  isFilterApplied,
  isFilterValid,
  isSelectValid,
  resetFilter,
} from "x/scheduler2/planner/unassigned-work/configuration/functions";
import type { EntityQueries } from "x/scheduler2/types";
import { defaultWOSelect } from "../functions";

interface PropTypes extends ValueProps<EntityQueries> {
  workOrderEntity: Entity;
  context: Context;
}

type Props = PropTypes & ValueProps<EntityQueries>;

interface StateType {
  showModal: boolean;
  query: EntityQueries;
}

export class Configuration extends Component<PropTypes, StateType> {
  state: StateType = {
    showModal: false,
    query: undefined,
  };

  componentDidMount() {
    this.setState({ query: this.props.value });
  }

  componentDidUpdate(prevProps: Props) {
    if (!R.equals(prevProps.value, this.props.value)) {
      this.setState({ query: this.props.value });
    }
  }

  onCancelPopup = () => {
    const { value } = this.props;
    this.setState({ query: value, showModal: false });
  };

  onSavePopup = () => {
    const { query } = this.state;
    this.setState({ showModal: false }, () => this.props.onChange(query));
  };

  onShowPopup = (e: MouseEvent) => {
    e.preventDefault();
    this.setState({ showModal: true });
  };

  onFilterReset = (e: MouseEvent) => {
    e.preventDefault();
    const { query } = this.state;
    if (query) {
      const newQuery = resetFilter(query);
      this.setState({ query: newQuery }, () => this.props.onChange(newQuery));
    }
  };

  onQueryChange = (entityName: string) => (value: QueryForEntity) => {
    const { query } = this.state;
    this.setState({
      query: {
        ...query,
        [entityName]: value,
      },
    });
  };

  getQueryOrDefault = (entity: Entity) => {
    const { query } = this.state;
    return (
      query?.[entity.name] || {
        entity: entity.name,
        query: { select: defaultWOSelect },
      }
    );
  };

  getQueryBuilder = (entity: Entity) => {
    const { context } = this.props;
    return (
      <ConfigurationForm
        context={context}
        conditionTypes={allConditionTypes}
        value={this.getQueryOrDefault(entity)}
        onChange={this.onQueryChange(entity.name)}
      />
    );
  };

  isConfigValid = () => {
    const { context, workOrderEntity } = this.props;
    const { columns, name } = workOrderEntity;
    const { query } = this.state;

    return (
      isFilterValid(columns)(query?.[name]?.query?.filter) &&
      isSelectValid(query, workOrderEntity) &&
      R.values(query).every((entityQuery) =>
        isQueryValid(context.entities, {
          entity: entityQuery.entity,
          query: entityQuery.query,
        }),
      )
    );
  };

  render() {
    const { value, workOrderEntity } = this.props;
    const { showModal } = this.state;
    const hasFilter = isFilterApplied(value);
    const className = arrayToString([
      "x-btn-filter-popup",
      "qa-btn-filter-popup",
      hasFilter ? "x-active" : undefined,
    ]);

    return (
      <>
        <a href="#" onClick={this.onShowPopup} className={className}>
          <i className="fa fa-cog" />
        </a>
        {hasFilter && (
          <div className="x-filter-info">
            <Chicklet
              size="small"
              isValid={this.isConfigValid()}
              invalidMessage={_(
                "The applied filter is invalid. Please go to the filter configuration to fix it.",
              )}
              content={_("Filter applied")}
              canDelete={true}
              onDelete={this.onFilterReset}
            />
          </div>
        )}
        {showModal && (
          <Modal
            content={this.getQueryBuilder(workOrderEntity)}
            size="large"
            title={_("Unassigned Work Orders Configuration")}
            okLabel={_("Save")}
            onOk={this.isConfigValid() ? this.onSavePopup : undefined}
            onCancel={this.onCancelPopup}
          />
        )}
      </>
    );
  }
}
