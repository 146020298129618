import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { searchApi } from "common/api/search";
import { hasCacheExpired } from "common/app/redux/functions";
import {
  getExpandReferenceFkQueryForId,
  getRecordWithTitleFn,
} from "common/functions/references";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { getExpandFkQueryForId } from "common/widgets/foreign-key/functions";

interface ExpandedRecord {
  record: ForeignKey;
  isLoading: boolean;
  error?: any;
  lastFetch?: number;
}

type ExpandedState = Record<string, ExpandedRecord>;

interface FetchArguments {
  context: Context;
  entityName: string;
  recordId: string;
}

interface FetchResult extends Partial<ExpandedRecord> {
  entityName: string;
  recordId: string;
}

const initialState: ExpandedState = {};
const initialExpanded: ExpandedRecord = {
  record: undefined,
  lastFetch: undefined,
  isLoading: false,
  error: undefined,
};

export const fetchExpandedRecord = createAsyncThunk<
  FetchResult,
  FetchArguments,
  { state: { expandedRecords: ExpandedState } }
>(
  "expandedRecords/expandRecord",
  (payload) => {
    const { context, entityName, recordId } = payload;
    const entity = context.entities[entityName];
    const isReference = entity.type === "Reference";

    const fetchPromise = isReference
      ? searchApi(context.apiCall)
          .runQuery(getExpandReferenceFkQueryForId(context, entity, recordId))
          .then(([record]) =>
            getRecordWithTitleFn(
              entityName,
              context.uiFormat.culture,
              context.site.culture,
            )(record),
          )
      : searchApi(context.apiCall)
          .runQuery(getExpandFkQueryForId(entity, recordId))
          .then(([record]) => record);

    return fetchPromise.then((record) => ({
      entityName,
      recordId,
      record,
      lastFetch: Date.now(),
    }));
  },
  {
    condition: (payload, { getState }) => {
      const { recordId } = payload;
      const { expandedRecords } = getState();

      const { lastFetch, isLoading } = expandedRecords?.[recordId] || {};

      return hasCacheExpired(lastFetch) && !isLoading;
    },
  },
);

const expandedRecordsSlice = createSlice({
  name: "expandedRecords",
  initialState,
  reducers: {
    resetExpandedRecords: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpandedRecord.fulfilled, (state, action) => {
        const { recordId, record, lastFetch } = action.payload;

        return {
          ...state,
          [recordId]: {
            isLoading: false,
            error: undefined,
            record,
            lastFetch,
          },
        };
      })
      .addCase(fetchExpandedRecord.pending, (state, action) => {
        const { recordId } = action.meta.arg;
        const existing = state?.[recordId] ?? initialExpanded;

        return {
          ...state,
          [recordId]: { ...existing, isLoading: true, error: undefined },
        };
      })
      .addCase(fetchExpandedRecord.rejected, (state, action) => {
        const { recordId } = action.meta.arg;
        const existing = state?.[recordId] ?? initialExpanded;

        return {
          ...state,
          [recordId]: { ...existing, isLoading: false, error: action.error },
        };
      });
  },
});

export const { resetExpandedRecords } = expandedRecordsSlice.actions;

export const expandedRecordsReducer = expandedRecordsSlice.reducer;
