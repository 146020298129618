import { useState } from "react";
import { VerticalField } from "common/ui/field";
import { StringInput } from "../input-with-submit/string";
import { Warning } from ".";

interface PropTypes {
  onCancel: () => any;
  onDelete: () => any;
  confirmationTitle?: string;
  confirmationLabel?: string;
  confirmationPhrase?: string;
  confirmationPhraseLabel?: string;
  isCancelModify?: boolean;
}

export const DeleteWarning = ({
  onCancel,
  onDelete,
  confirmationTitle,
  confirmationLabel,
  confirmationPhrase,
  confirmationPhraseLabel,
  isCancelModify,
}: PropTypes) => {
  const [confirmedPhrase, setConfirmedPhrase] = useState("");
  const [hasConfirmError, setHasConfirmError] = useState(true);

  const onConfirmPhraseChange = (confirmedPhrase: string) => {
    setConfirmedPhrase(confirmedPhrase);
    setHasConfirmError(confirmedPhrase !== confirmationPhrase);
  };

  const content = confirmationPhrase ? (
    <VerticalField
      className="x-confirm-phrase qa-confirm-phrase"
      label={confirmationPhraseLabel}
      error={hasConfirmError}
      input={
        <StringInput
          autoComplete="off"
          placeholder={confirmationPhrase}
          className={confirmedPhrase ? "" : "x-placeholder"}
          value={confirmedPhrase}
          onChange={onConfirmPhraseChange}
        />
      }
    />
  ) : (
    _("Are you sure?")
  );

  return (
    <Warning
      title={confirmationTitle || _("The record will be archived")}
      content={content}
      action1={confirmationPhrase ? _("Cancel") : _("No")}
      action2={confirmationLabel || _("Yes, archive the record")}
      onAction1={onCancel}
      onAction2={onDelete}
      isAction2Disabled={confirmationPhrase && hasConfirmError}
      isCancelModify={isCancelModify}
    />
  );
};

DeleteWarning.displayName = "DeleteWarning";
