import { defaultFor } from "common";
import { EntityColumn } from "common/entities/entity-column/types";
import { GroupColumn } from "common/form/types";

export const createColumn = (column: EntityColumn): GroupColumn => ({
  ...defaultFor<GroupColumn>(),
  columnName: column.name,
  hint: column.hint,
  disableQuickAddRelated: column.disableQuickAddRelated,
});
