import { Event } from "common/types/events";
import { Row } from "common/record/form/content/audit-trail/event-detail/row";
import { getEventLocalizedName } from "common/record/form/content/audit-trail/utils";
import { UiFormat } from "common/types/ui-format";
import { DateTimeLabel } from "common/widgets/date/date-time-label";

interface PropTypes {
  event: Event;
  uiFormat: UiFormat;
}

export const EmptyDetails = ({ event, uiFormat }: PropTypes) => (
  <div className="qa-empty-details">
    <legend>{getEventLocalizedName(event.name, event.userName)}</legend>
    <Row label={_("Username")}>{event.userName}</Row>
    {!!event.details.occurredOn && (
      <Row label={_("Occurred On")}>
        <DateTimeLabel uiFormat={uiFormat} value={event.details.occurredOn} />
      </Row>
    )}
  </div>
);

EmptyDetails.displayName = "EmptyDetails";
