import { PropsWithChildren } from "react";
import { getUrl as getLink } from "common/entities";
import { Entity } from "common/entities/types";
import { USER_DATA_VIEW } from "common/types/view";
import { classNames } from "common/utils/jsx";
import { Link } from "./link";

interface PropTypes {
  site: string;
  entity: Entity;
  id: string | number;
  title?: string;
  target?: string;
  auditTrailId?: string;
  getUrl?: (entity: Entity, site: string) => string;
  isArchived?: boolean;
}

export const LinkRecord = ({
  site,
  entity,
  id,
  children,
  title,
  target,
  auditTrailId,
  getUrl,
  isArchived,
}: PropsWithChildren<PropTypes>) => {
  return !entity || !id || entity.name === USER_DATA_VIEW ? (
    <>{children}</>
  ) : (
    <Link
      href={`${getUrl?.(entity, site) || getLink(entity, site)}/${id}`}
      className={classNames([
        "x-link-record qa-link-record",
        isArchived ? "x-link-archived" : undefined,
      ])}
      title={title}
      target={target}
      query={{ auditTrailId }}
    >
      {children}
    </Link>
  );
};
