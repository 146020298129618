import { JSX } from "react";
import { formatTimestamp } from "common/date-time/formatters";
import { DateTimeFormat } from "common/date-time/types";
import { formatMeasurementValue } from "common/record/form/content/measurements/functions/value";
import { Measurement } from "common/record/form/content/measurements/types";
import { ImageWithLoading } from "common/widgets/image-with-loading";

interface PropTypes {
  token: string;
  measurement: Measurement;
  dateFormat: DateTimeFormat;
  createImageActions?: (imagePath: string) => JSX.Element;
}

export const MeasurementImage = ({
  token,
  measurement,
  dateFormat,
  createImageActions,
}: PropTypes) => {
  if (!measurement) return null;

  const { t, vagg, v, vf, u } = measurement;
  const { min, max } = vagg || {};
  const imagePath =
    token && vf
      ? `/api/adp/measurements/measurement-image?token=${token}&path=${vf}`
      : undefined;

  return (
    <div className="x-measurement-image-container">
      <div className="x-measurement-image-header">
        <span className="x-measurement-image-min">
          {_("min: {MIN_VALUE}").replace(
            "{MIN_VALUE}",
            formatMeasurementValue(min, u),
          )}
        </span>
        {createImageActions?.(vf)}
        <span className="x-measurement-image-max">
          {_("max: {MAX_VALUE}").replace(
            "{MAX_VALUE}",
            formatMeasurementValue(max, u),
          )}
        </span>
      </div>

      <ImageWithLoading
        src={imagePath}
        className="x-measurement-image"
        stopReload={true}
      />

      <div className="x-measurement-image-footer">
        <span className="x-measurement-image-value">
          {formatMeasurementValue(v, u)}
        </span>
        <span className="x-measurement-image-date">
          {formatTimestamp(t, dateFormat)}
        </span>
      </div>
    </div>
  );
};
MeasurementImage.displayName = "MeasurementImage";
