import * as R from "ramda";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { defaultFor } from "common";
import { merge1 } from "common/merge";
import { Entity } from "common/entities/types";
import { GroupColumn } from "common/form/types";
import { Properties } from "common/types/records";
import { getRelatedRecords } from "common/record/utils";
import { mapLayout } from "common/form/functions/layout";
import { setReadOnly } from "common/entities/entity-column/functions";
import { RelatedValue } from "../types";
import { updateContextForms } from "../common/functions";

const idFilter = (ids: string[]) => (fk: ForeignKey) => !R.includes(fk.id, ids);

const updateCostCenterColumn = (ids: string[]) => (col: GroupColumn) => {
  return col.columnName === "costCenterId"
    ? merge1("referenceFilter", idFilter(ids), col)
    : col;
};

export const updateContext = (
  context: Context,
  entity: Entity,
  ids: string[],
) =>
  updateContextForms(context, entity.name, (f) =>
    merge1("settings", mapLayout(updateCostCenterColumn(ids), f.settings), f),
  );

export const updateEntity = (entity: Entity = defaultFor<Entity>()) => {
  const { columns = [] } = entity;
  const updatedColumns = columns.map(setReadOnly(["isDefaultCostCenter"]));
  return merge1("columns", updatedColumns, entity);
};

export const updateDefaultForm = (
  defaultForm = defaultFor<Properties>(),
  value: RelatedValue,
  entity: Entity,
) => {
  const { record, related } = value;
  const records = getRelatedRecords(entity.name, record, related.form).filter(
    (r) => !r.properties.isDeleted && r.properties.isDefaultCostCenter,
  );
  return merge1("isDefaultCostCenter", !records.length, defaultForm);
};
