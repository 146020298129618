import { getUnexpectedErrorMessage } from "common/api/error";
import { Component } from "common/component";
import { eventBus } from "common/event-bus";
import { ApiErrorResponse } from "common/types/error";
import { ApiError } from "common/ui/api-error";
import { isApiError, isChunkLoadingError, isJSError } from "common/utils/error";
import { AlertWarning } from "common/widgets/alert";

interface StateType {
  error: ApiErrorResponse | Error;
}

export class LazyLoadingBoundary extends Component<unknown, StateType> {
  static getDerivedStateFromError(error: ApiErrorResponse | Error) {
    return { error };
  }

  state: StateType = { error: undefined };

  render() {
    const { error } = this.state;

    if (!error) return this.props.children;

    if (isChunkLoadingError(error)) {
      eventBus.emit({ type: "ChunkLoadingError" });
      return (
        <AlertWarning
          message={_("Error loading module. Please refresh the page.")}
        />
      );
    }

    return isApiError(error) ? (
      <ApiError error={error} />
    ) : isJSError(error) ? (
      <AlertWarning message={error.message} />
    ) : (
      getUnexpectedErrorMessage()
    );
  }
}
