import type { ApplicationScope } from "common/types/context";

export const setDocumentTitle = (title: string, scope: ApplicationScope) => {
  const items = (() => {
    switch (scope) {
      case "admin":
        return [title, _("Admin Portal"), _("X5")];
      case "vendor":
        return [title, _("Vendor Portal"), _("X5")];
      case "requestor":
        return [title, _("Requestor Portal"), _("X5")];
      case "x-token":
        return [title, _("Requestor Form"), _("X5")];
      default:
        return [title, _("X5")];
    }
  })();
  document.title = items.filter(Boolean).join(" - ");
};
