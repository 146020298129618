import { slaApi } from "common/api/sla";
import { ApiCall } from "common/types/api";
import { ApiErrorResponse } from "common/types/error";
import { CancellablePromise } from "common/types/promises";
import { ApiError } from "common/ui/api-error";
import { ModifyButtonLarge } from "common/ui/buttons";
import { CheckboxWidget } from "common/widgets/checkbox";
import { LoadingIcon } from "common/widgets/loading-icon";
import { ValueComponent } from "common/with-value-for";

interface PropTypes {
  apiCall: ApiCall;
  reloadUi: () => CancellablePromise<any>;
}

export interface SlaValue {
  msa: boolean;
  msapa: boolean;
}

interface StateType {
  loading: boolean;
  error: ApiErrorResponse;
}

const defaultValue: SlaValue = {
  msa: false,
  msapa: false,
};

export class Sla extends ValueComponent<SlaValue, PropTypes, StateType> {
  state: StateType = {
    loading: false,
    error: undefined,
  };

  onAccept = () => {
    const { apiCall, reloadUi } = this.props;
    this.setState({ loading: true });
    slaApi(apiCall)
      .acceptSla()
      .then(reloadUi)
      .catch((error) => this.setState({ loading: false, error }));
  };

  render() {
    const { value = defaultValue } = this.props;
    const { loading, error } = this.state;

    return (
      <div className="x-sla-agreement">
        <div>
          {loading && <LoadingIcon />}
          {error && <ApiError error={error} className="x-margin-bottom-20-i" />}
          <div className="x-sla-content">
            <h1>{_("Welcome to eMaint CMMS")}</h1>
            <p>
              {_(
                "Thank you for selecting eMaint as your CMMS solution. Your subscription includes 24/7 access to eMaint, unlimited support and maintenance, eMaint University, and free monthly webinars.",
              )}
            </p>
            <p>
              {_(
                "To access and use eMaint, you must click to accept eMaint's Master Subscription Agreement (MSA) below.",
              )}
            </p>
            <p>
              {_(
                "If you will have users in the European Economic Area (EEA) using eMaint, the following also applies to you:",
              )}
            </p>
            <p>
              {_(
                "In order to ensure our compliance with the General Data Protection Regulation (GDPR), the data protection law applicable across the EEA, eMaint has put together a Data Processing Addendum with Standard Contractual Clauses (DPA) to the MSA. Article 28 of the GDPR requires such a written contract to be in place between you, the data controller and us, the data processors, where we process data from individuals in the European Economic Area (EEA) on your behalf. The DPA sets out our obligations to you in the context of the processing of personal data.",
              )}
            </p>
            <p>
              {_(
                "In order to provide you with the eMaint system, personal data entered into eMaint will be transferred outside the EEA. Articles 44-50 of the GDPR requires that this transfer takes place on the basis of an appropriate safeguard. The DPA also incorporates the Standard Contractual Clauses, which have been adopted by the European Commission as an appropriate safeguard and enable us to transfer personal data outside the EEA in compliance with GDPR.",
              )}
            </p>
            <p>
              {_(
                "If any of the personal data that you upload to eMaint, such as names and email addresses, relates to individuals in the EEA and you are data controller of that data, you should agree to the GDPR addendum with Standard Contractual Clauses to the MSA by checking the box below.",
              )}
            </p>
            <hr />
            <p>
              {_(
                "Whenever you need help, our product specialists are here to assist you. Live online during normal business hours:",
              )}
            </p>
            <p>
              <strong>{_("Monday - Friday")}</strong>
            </p>
            <p>
              <strong>{_("8:30am - 5:00pm Eastern Time")}</strong>
            </p>
            <hr />
            <dl>
              <dt>{_("You can contact us in a variety of ways:")}</dt>
              <dd>{_("Give us a call at (856) 810-2700, press 5")}</dd>
              <dd>
                {`${_("Email us at")} `}
                <a href="mailto:sales@emaint.com">sales@emaint.com</a>
              </dd>
            </dl>
          </div>
          <div className="x-sla-footer">
            <label>
              <CheckboxWidget
                label={_("I have read and agree to the")}
                value={value.msa}
                onChange={this.onChangeMergeValue("msa")}
              />
              <a
                href="https://www.emaint.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                {_("MASTER SUBSCRIPTION AGREEMENT")}
              </a>
              .
            </label>
            <label>
              <CheckboxWidget
                label={_(
                  "I have read and agree to the Master Subscription Agreement Data Protection Appendix with Standard Contractual Clauses",
                )}
                value={value.msapa}
                onChange={this.onChangeMergeValue("msapa")}
              />
            </label>
            <ModifyButtonLarge
              disabled={!value || !value.msa || !value.msapa}
              onClick={this.onAccept}
            >
              {_("Accept agreement")}
            </ModifyButtonLarge>
          </div>
        </div>
      </div>
    );
  }
}
