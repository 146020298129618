import * as R from "ramda";
import { Entity } from "common/entities/types";
import { LookupQuery } from "common/form/types";
import { findEntityByArgs } from "common/functions/entity";
import { merge1 } from "common/merge";
import { addFilterToQuery } from "common/query/filter";
import { addJoinToQuery } from "common/query/joins";
import { getGroupForSelect } from "common/query/select";
import { FilterAnd, FilterOr, JoinItem } from "common/query/types";
import { Context } from "common/types/context";
import { APPROVAL_GROUP } from "../consts";

const requisitionerJoin = (reqEntity: Entity): JoinItem =>
  reqEntity && {
    entity: reqEntity.name,
    column: "approvalGroupId",
    type: "LEFT",
    joins: [
      {
        type: "LEFT",
        column: "contactId",
      },
    ],
  };

const requisitionerGroupJoin = (
  reqGroupEntity: Entity,
  groupMemberName: string,
): JoinItem =>
  reqGroupEntity && {
    entity: reqGroupEntity.name,
    column: "approvalGroupId",
    type: "LEFT",
    joins: [
      {
        column: "groupId",
        type: "LEFT",
        joins: [
          {
            entity: groupMemberName,
            column: "groupId",
            type: "LEFT",
            joins: [
              {
                type: "LEFT",
                column: "memberId",
              },
            ],
          },
        ],
      },
    ],
  };

const requisitionerFilters = (
  reqEntity: Entity,
  requisitionerName: string,
): FilterAnd =>
  reqEntity && {
    and: [
      {
        name: "email",
        op: "eq",
        path: `/${reqEntity.name}.approvalGroupId/contactId`,
        value: requisitionerName,
      },
      {
        name: "isDeleted",
        op: "isfalse",
        path: `/${reqEntity.name}.approvalGroupId`,
      },
    ],
  };

const requisitionerGroupFilters = (
  reqGroupEntity: Entity,
  groupMemberName: string,
  requisitionerName: string,
): FilterAnd =>
  reqGroupEntity && {
    and: [
      {
        name: "email",
        op: "eq",
        path:
          `/${reqGroupEntity.name}.approvalGroupId/groupId` +
          `/${groupMemberName}.groupId/memberId`,
        value: requisitionerName,
      },
      {
        name: "isDeleted",
        op: "isfalse",
        path: `/${reqGroupEntity.name}.approvalGroupId`,
      },
      {
        name: "isDeleted",
        op: "isfalse",
        path: `/${reqGroupEntity.name}.approvalGroupId/groupId`,
      },
      {
        name: "isDeleted",
        op: "isfalse",
        path:
          `/${reqGroupEntity.name}.approvalGroupId/groupId/` +
          `${groupMemberName}.groupId`,
      },
    ],
  };

export const createApprovalGroupLookUpQuery = (
  context: Context,
  entity: Entity,
  requisitionerName: string,
): LookupQuery => {
  const { entities } = context;

  const approvalGroupName = entity?.arguments?.approvalGroupEntity;
  const approvalGroupEntity = entities[approvalGroupName];
  const reqEntity = findEntityByArgs(
    entities,
    "Requisitioner",
    "approvalGroupEntity",
    approvalGroupName,
  );
  const reqGroupEntity = findEntityByArgs(
    entities,
    "RequisitionerGroup",
    "approvalGroupEntity",
    approvalGroupName,
  );
  const groupName = reqGroupEntity?.arguments?.groupEntity;
  const groupEntity = entities[groupName];
  const groupMemberEntity = findEntityByArgs(
    entities,
    "GroupMember",
    "groupEntity",
    groupEntity?.name,
  );
  const groupMemberName = groupMemberEntity?.name;

  if (!approvalGroupEntity || (!reqEntity && !reqGroupEntity)) return undefined;

  const { query } = approvalGroupEntity;

  const reqJoin = requisitionerJoin(reqEntity);
  const reqGroupJoin = requisitionerGroupJoin(reqGroupEntity, groupMemberName);
  const queryWithJoin1 = addJoinToQuery(reqJoin, query);
  const queryWithJoin2 = addJoinToQuery(reqGroupJoin, queryWithJoin1);

  const filters: FilterOr = {
    or: [
      requisitionerFilters(reqEntity, requisitionerName),
      requisitionerGroupFilters(
        reqGroupEntity,
        groupMemberName,
        requisitionerName,
      ),
    ].filter(R.identity),
  };
  const queryWithFilters = addFilterToQuery(filters, queryWithJoin2);

  const group = getGroupForSelect(query.select);
  const queryWithGroup = merge1("group", group, queryWithFilters);

  return {
    columnName: APPROVAL_GROUP,
    entityName: approvalGroupEntity?.name,
    query: queryWithGroup,
  };
};
