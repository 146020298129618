import { getColumn } from "common/entities";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { PoItem } from "common/types/purchase-orders";
import { DefaultCurrencyLabel } from "common/widgets/currency/label-with-default";
import { formatFk } from "common/widgets/foreign-key/functions";
import { LinkFk } from "common/widgets/foreign-key/link-fk";
import { IntLabel } from "common/widgets/number/label";
import { OrderedOverwritten } from "../ordered-overwritten";

export interface PropTypes {
  context: Context;
  entity: Entity;
  item: PoItem;
}

export const PoListItem = ({ context, entity, item }: PropTypes) => {
  const {
    orderedQty,
    receivedQty = 0,
    partId,
    unitCost,
    currency,
    partLocationId,
  } = item;

  const showOverwritten = orderedQty !== receivedQty;

  return (
    <tr className="x-po-list-item">
      {/* Ordered */}
      <td>
        {showOverwritten ? (
          <OrderedOverwritten item={item} />
        ) : (
          <IntLabel value={orderedQty} />
        )}
      </td>
      {/* Received */}
      <td>
        <IntLabel value={receivedQty} />
      </td>

      {/* Part */}
      <td>
        <LinkFk
          context={context}
          column={getColumn(entity, "partId")}
          value={partId}
        />
      </td>

      {/* Part Location */}
      <td>{formatFk(context.entities)(partLocationId, false)}</td>

      {/* Unit Cost */}
      <td>
        <DefaultCurrencyLabel
          context={context}
          column={getColumn(entity, "unitCost")}
          currencyId={currency?.id}
          value={unitCost}
        />
      </td>
    </tr>
  );
};
