import * as R from "ramda";
import { Component } from "react";
import { Culture } from "common/culture/supported-cultures";
import { Captions } from "common/culture/types";
import { isEntity, isReport, isStatic } from "common/functions/preferences";
import { Context } from "common/types/context";
import {
  EntitySetting,
  MenuEntrySetting,
  ReportSetting,
  StaticSetting,
} from "common/types/preferences";
import { Report } from "common/types/reports";
import { createList } from "common/widgets/list";
import { Selector } from "common/widgets/selector";
import { OnChange, ValueProps } from "common/with-value-for";
import {
  equalsSetting,
  getAvailableUserMenuSettings,
  getLabel,
  getListSettings,
  getReportLabelByRecord,
  mapEntityLocalizedName,
  sortSettingsByPosition,
} from "../functions";
import { MenuEntrySettingForm } from "./setting-form";

interface PropTypes extends ValueProps<MenuEntrySetting[]> {
  context: Context;
  records: Report[];
}

export const List = createList<MenuEntrySetting>("MenuSetting");

const mapReport = (culture: Culture, reports: Report[]): ReportSetting[] =>
  reports.map((r) => {
    const keys = Object.keys(r.labels || {});

    const newLabels = keys.reduce(
      (acc: Captions, key: string) =>
        R.mergeRight(acc, { [key]: r.labels[key].name }),
      {
        [culture]: getReportLabelByRecord(culture, r),
      },
    );

    return {
      reportId: r.id,
      labels: newLabels,
      icon: "fa-table",
    };
  });

const filterMenuSettings = (
  value: MenuEntrySetting[],
  settings: MenuEntrySetting[],
) =>
  settings.filter(
    (available) => !value?.some((setting) => equalsSetting(available, setting)),
  );

export class MenuConfig extends Component<PropTypes> {
  static readonly displayName = "MenuConfig";

  displayNewSetting = (setting: MenuEntrySetting) => {
    const { context } = this.props;
    return getLabel(context.uiFormat.culture, setting);
  };

  displayItem = (
    item: MenuEntrySetting,
    onChange: OnChange<MenuEntrySetting>,
  ) => {
    const { context } = this.props;
    return (
      <MenuEntrySettingForm
        entities={context.entities}
        culture={context.uiFormat.culture}
        value={item}
        onChange={onChange}
      />
    );
  };

  addEntry = (item: EntitySetting | StaticSetting) => {
    const { value, onChange } = this.props;
    const listSettings = getListSettings(value);
    const newValue = value.map((s) =>
      (isEntity(s) && isEntity(item) && s.entity === item.entity) ||
      (isStatic(s) && isStatic(item) && s.name === item.name)
        ? { ...s, hidden: false, position: listSettings.length + 1 }
        : s,
    );

    onChange(newValue);
  };

  addReport = (report: ReportSetting) => {
    const oldValue = this.props.value;
    const listSettings = getListSettings(oldValue);
    this.props.onChange(
      oldValue.concat({ ...report, position: listSettings.length + 1 }),
    );
  };

  onChangeList = (newValue: MenuEntrySetting[]) => {
    const { value: oldValue, onChange } = this.props;
    const diff = R.difference(oldValue, newValue);

    const updatedValue: MenuEntrySetting[] = diff.reduce((acc, s) => {
      if (isReport(s)) return acc;

      const findSetting =
        (isStatic(s) &&
          ((v: MenuEntrySetting) => isStatic(v) && v.name === s.name)) ||
        (isEntity(s) &&
          ((v: MenuEntrySetting) => isEntity(v) && v.entity === s.entity)) ||
        (() => false);

      return R.find(findSetting, newValue)
        ? acc
        : [...acc, { ...s, hidden: true, position: undefined }];
    }, newValue);

    const valueWithUpdatedPosition = updatedValue.map((s, index) => ({
      ...s,
      position: s.position ? index + 1 : undefined,
    }));

    onChange(valueWithUpdatedPosition);
  };

  render() {
    const { context, records = [], value = [] } = this.props;
    const { uiFormat, entities } = context;
    const { culture } = uiFormat;

    const listSettings = sortSettingsByPosition(getListSettings(value));
    const settings = getAvailableUserMenuSettings(context);
    const remainingEntitySettings = filterMenuSettings(
      listSettings,
      settings,
    ).map(mapEntityLocalizedName(entities));

    const reportSettings = mapReport(culture, records);
    const remainingReportSettings = filterMenuSettings(value, reportSettings);

    return (
      <>
        <div className="x-flex">
          <Selector<MenuEntrySetting>
            options={remainingEntitySettings}
            getOptionLabel={this.displayNewSetting}
            disabled={!remainingEntitySettings.length}
            placeholder={_("Add entity")}
            className="x-flex-grow-1 x-margin-right-10 x-menu-entry qa-menu-entry"
            value={undefined}
            onChange={this.addEntry}
          />
          <Selector<MenuEntrySetting>
            options={remainingReportSettings}
            getOptionLabel={this.displayNewSetting}
            disabled={!remainingReportSettings.length}
            placeholder={_("Add report")}
            className="x-flex-grow-1 x-report qa-report"
            value={undefined}
            onChange={this.addReport}
          />
        </div>
        <List
          className="x-margin-top-10"
          onDisplay={this.displayItem}
          canDelete={R.always(listSettings.length > 1)}
          withBorder={true}
          value={listSettings}
          onChange={this.onChangeList}
        />
      </>
    );
  }
}
