import { Context } from "common/types/context";
import { Component } from "common/component";
import { fmApi } from "common/api/fluke-mobile";
import { showErrorNotification } from "common/ui/notification";
import { getError } from "common/api/error";
import { LoadingButton } from "common/widgets/loading-button";

interface PropTypes {
  context: Context;
}

interface StateType {
  loading: boolean;
}

export class FlukeMobileSettings extends Component<PropTypes, StateType> {
  static readonly displayName = "FlukeMobileSettings";
  state: StateType = {
    loading: false,
  };

  onClick = () => {
    const { context } = this.props;

    this.setState({ loading: true });

    fmApi(context)
      .singleSignOn()
      .then(() => this.setState({ loading: false }))
      .catch((error) => {
        this.setState({ loading: false });
        showErrorNotification(getError(error));
      });
  };

  render() {
    const { loading } = this.state;
    return (
      <LoadingButton
        idleText={_("Mobile Account Settings")}
        loadingText={_("Loading")}
        loading={loading}
        type="action"
        className="qa-btn-fm-admin"
        onClick={this.onClick}
      />
    );
  }
}
