import { Entity } from "common/entities/types";
import { LayoutActionName } from "common/form/types";
import { PropTypes } from "common/record/actions/types";
import { ApiCall } from "common/types/api";
import { Context } from "common/types/context";
import { Cancelable } from "common/types/debounce";
import { Properties, Record } from "common/types/records";
import { ValueProps } from "common/with-value-for";

interface ActionDependencies {
  properties: Properties[];
  signatures: Properties[];
}

export interface WorkOrderActionDependencies extends ActionDependencies {
  checkProcedures: string[];
}

export interface WorkOrderActionPropTypes extends PropTypes {
  actionName: LayoutActionName;
  label: string;
  onOk: (
    apiCall: ApiCall,
    dismiss: (x: boolean) => any,
    payload: ActionPayload,
  ) => any;
  dependencies?: WorkOrderActionDependencies;
}

export interface ActionStateType {
  formValue: FormValue;
}

export interface FormValue {
  date: string;
  signature: string;
  page: number;
  actionRecords: WorkOrderActionRecord[];
}

export interface FormPropTypes extends ValueProps<FormValue> {
  context: Context;
  entity: Entity;
  actionName: LayoutActionName;
}

export interface FormStateType {
  isMobile: boolean;
  debounceResize: (() => void) & Cancelable;
}

export interface WorkOrderActionRecord extends WorkOrderActionRecordPayload {
  id: string;
  recordNumber: number;
  assetId: string;
  assetMeters: Record[];
  meters: Properties[];
}

export interface ActionPayload {
  date?: string;
  signature: string;
  records: WorkOrderActionRecordPayload[];
}

export interface WorkOrderActionRecordPayload {
  properties: Properties;
  assetMeterReading: Properties;
}

export interface WorkOrderActionProceduresPayload {
  workOrderId: string;
  formId: number;
}

export const actionsWithDateApplicable = ["closeOut"];
