import { Context } from "common/types/context";
import type { RequisitioningRejectedEvent } from "common/types/events";
import { EmptyDetails } from "./empty";
import { ReasonDetails } from "./reason";

interface PropTypes {
  event: RequisitioningRejectedEvent;
  context: Context;
  hideLinks?: boolean;
}

export const RequisitioningRejected = ({
  event,
  context,
  hideLinks,
}: PropTypes) => (
  <div>
    <EmptyDetails event={event} uiFormat={context.uiFormat} />
    <ReasonDetails
      reason={event.details.reason}
      context={context}
      hideLinks={hideLinks}
    />
  </div>
);

RequisitioningRejected.displayName = "RequisitioningRejected";
