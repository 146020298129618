import { noOp } from "common";
import { devExpressApi } from "common/api/devexpress";
import { Context } from "common/types/context";
import { DevExpressDashboard } from "common/types/dashboards";
import { hasPermissionToDelete } from "common/functions/roles";
import { isAdminUserType } from "common/types/users";
import { DeleteButtonSmall } from "common/ui/buttons";
import { DeleteWarning } from "common/widgets/warning/delete-warning";
import { useConfirmation } from "common/widgets/warning/use-confirmation";

export interface PropTypes {
  context: Context;
  dashboard: DevExpressDashboard;
  onDashboardDeleted: () => void;
}

export const DeleteDashboard = ({
  context,
  dashboard,
  onDashboardDeleted,
}: PropTypes) => {
  const { onClose, onConfirm, isOpen, onOpen } = useConfirmation();
  const canDelete =
    isAdminUserType(context.userTypes) ||
    (context.id === dashboard.userId &&
      hasPermissionToDelete(context.userTypes, context.role, "Dashboard"));

  if (!canDelete) return null;

  const openAndDeleteWhenResolved = () =>
    onOpen()
      .then(() =>
        devExpressApi(context.apiCall)
          .remove(dashboard.id)
          .then(onDashboardDeleted),
      )
      .catch(noOp);

  return (
    <>
      {isOpen ? (
        <DeleteWarning
          key="delete-dashboard"
          confirmationTitle={_(
            'The dashboard "{NAME}" will be deleted',
          ).replace("{NAME}", dashboard.name)}
          confirmationLabel={_("Yes, delete the dashboard")}
          onCancel={onClose}
          onDelete={onConfirm}
        />
      ) : null}
      <DeleteButtonSmall
        className="qa-delete-dashboard"
        onClick={openAndDeleteWhenResolved}
      />
    </>
  );
};

DeleteDashboard.displayName = "DeleteDashboard";
