import { RecordContentForm } from "common/record/form/form";
import { StandardValue as Value } from "common/record/types";
import { ValueProps } from "common/with-value-for";
import { mapEntityColumns } from "common/entities";
import { PropTypes } from "x/records/edit/types";
import { setTaxRateRange } from "./functions";

type Props = PropTypes & ValueProps<Value>;

export const TaxRateBehaviorForm = (props: Props) => (
  <RecordContentForm
    {...props}
    entity={mapEntityColumns(props.entity, setTaxRateRange)}
  />
);
TaxRateBehaviorForm.displayName = "TaxRateBehaviorForm";
