import { Locale } from "date-fns";
import { Culture } from "common/culture/supported-cultures";

interface Locales {
  [name: string]: Locale;
}

interface TimeData {
  siteTimeZone: string;
  userLocale: Locale;
}

const timeData: TimeData = {
  siteTimeZone: undefined,
  userLocale: undefined,
};

/**
 * This time zone is used when parsing dates without a time zone information.
 * We assume all dates from the BE and interchanged between functions in the FE
 * to be in UTC.
 */
export const DEFAULT_TIME_ZONE = "Etc/UTC";

export const getUserLocale = () => timeData.userLocale;

export const setUserLocale = (culture: Culture) => {
  const longName = culture?.replace("-", "");
  const shortName = culture?.substring(0, 2);

  return import("date-fns/locale").then((locales) => {
    timeData.userLocale =
      (locales as Locales)[longName] ??
      (locales as Locales)[shortName] ??
      locales.enUS;
  });
};

export const getLocalTimeZone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getSiteTimeZone = () =>
  timeData.siteTimeZone ?? getLocalTimeZone();

export const setSiteTimeZone = (timeZone: string) => {
  timeData.siteTimeZone = timeZone;
};
