import {
  AssetDetailsWithComponents,
  liveAssetPortalApi,
} from "common/api/live-asset-portal";
import { isType } from "common";
import { Entity } from "common/entities/types";
import { getProtectedColumnNames } from "common/record/utils";
import { Context } from "common/types/context";
import { CancellablePromise } from "common/types/promises";
import { LiveAssetComponent, LiveAsset, LiveAssetLocation } from "./types";

const LAP_FIELDS = [
  "liveAssetId",
  "liveAssetComponentId",
  "availableInLiveAsset",
];

export const requiresExplicitAuthentication = (entity: Entity) =>
  getProtectedColumnNames(entity).some((c) => LAP_FIELDS.includes(c));

const isLiveAsset = isType<LiveAsset | string, LiveAsset>(["id"]);

export const mapAssetOptions = (assets: LiveAsset[]) =>
  assets?.map((asset) => ({
    label: isLiveAsset(asset) ? asset.name : asset,
    value: asset,
  }));

export const getComponentLabel = (component: LiveAssetComponent) =>
  component?.name || "";

const getFilteredComponents = (components: LiveAssetComponent[]) =>
  components.filter((component) => component.name);

export const getComponentById = (
  context: Context,
  assetId: string,
  componentId: string,
): CancellablePromise<LiveAssetComponent> =>
  liveAssetPortalApi(context.apiCall)
    .getComponent(assetId, componentId)
    .then((component: LiveAssetComponent) => component);

export const getComponentsList = (context: Context, assetId: string) =>
  liveAssetPortalApi(context.apiCall)
    .getAssetWithComponents(assetId)
    .then((response: AssetDetailsWithComponents) => ({
      componentsList: getFilteredComponents(response.components),
      asset: { id: response.id, name: response.name },
    }));

export const getAssetLocation = (liveAsset: LiveAsset) => {
  const { locationsPath = [], locationId } = liveAsset ?? {};
  const location = locationsPath.find(
    (path: LiveAssetLocation) => path.id === locationId,
  );
  return location?.name || "";
};
