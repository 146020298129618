import { Component, PropsWithChildren } from "react";
import { getLocalizedName } from "common";
import { deepEqual } from "common/component";
import { findColumn, getEntityByBehavior } from "common/entities";
import { EntityColumn } from "common/entities/entity-column/types";
// eslint-disable-next-line import/no-cycle
import { GroupField } from "common/form/group/field";
import { hasPermissionToCreate } from "common/functions/roles";
import { Row } from "common/record/form/content/detail/view/section-group/row";
import { Context } from "common/types/context";
import { RequiredField } from "common/ui/required-field";
import { ForeignKeySelector } from "common/widgets/selector/foreign-key-selector";
import { ValueProps } from "common/with-value-for";
import { AddRelatedEntityModal } from "./add-part-supplier/add-related-entity-modal";
import {
  getFormGroups,
  partSupplierWidgetsMapper,
} from "./add-part-supplier/functions";
import { PartSupplier, PurchaseOrderItem } from "./types";

const Table = ({ children }: PropsWithChildren<unknown>) => (
  <table className="table">
    <tbody>{children}</tbody>
  </table>
);

interface PropTypes extends ValueProps<PurchaseOrderItem> {
  context: Context;
  columns: EntityColumn[];
  isPreviewScreen: boolean;
  partSuppliers: PartSupplier[];
}

interface StateType {
  selectedPartSupplier: PartSupplier;
  showAddPartSupplier: boolean;
}

export class POItem extends Component<PropTypes, StateType> {
  static readonly displayName = "POItem";

  constructor(props: PropTypes) {
    super(props);

    const {
      value: { supplierId },
    } = props;

    this.state = {
      selectedPartSupplier: props.partSuppliers.find(
        (s) => s.supplierId.id === supplierId?.id,
      ),
      showAddPartSupplier: false,
    };
  }

  componentDidUpdate(prevProps: PropTypes) {
    const { partSuppliers, value } = this.props;
    if (!deepEqual(prevProps.partSuppliers, partSuppliers)) {
      this.setState({
        selectedPartSupplier: partSuppliers.find(
          (s) => s.supplierId.id === value.supplierId?.id,
        ),
      });
    }
  }

  onClickAddPartSupplier = () => this.setState({ showAddPartSupplier: true });

  onCancel = () => this.setState({ showAddPartSupplier: false });

  onPartSupplierChange = (selectedPartSupplier: PartSupplier) => {
    const { value, onChange } = this.props;
    this.setState({ selectedPartSupplier });

    onChange({
      ...value,
      supplierId: selectedPartSupplier.supplierId,
      unitCost: selectedPartSupplier.unitCost,
      purchaseOrderNumber: undefined,
    });
  };

  onSupplierAdded = (selectedPartSupplier: PartSupplier) => {
    this.onPartSupplierChange(selectedPartSupplier);
  };

  getReadOnlyField = (columnName: keyof PurchaseOrderItem) => {
    const { context, columns, value } = this.props;
    const column = findColumn(columns, columnName);

    return (
      <Row
        context={context}
        entityName={undefined}
        withLinks={true}
        column={column}
        label={getLocalizedName(column)}
        value={value[columnName]}
      />
    );
  };

  getGroupField = (columnName: string, className: string) => {
    const { context, value, isPreviewScreen, columns, onChange } = this.props;
    const { selectedPartSupplier } = this.state;
    return (
      <div className={`x-edit-field qa-${columnName} ${className}`}>
        <GroupField
          key={columnName}
          context={context}
          withLinks={false}
          groups={[]}
          entity={{
            name: undefined,
            type: "Entity",
            columns,
            recordScope: "SingleSite",
            commands: [],
            labels: {},
            behaviors: [],
          }}
          layoutColumn={{
            required: true,
            readOnly: false,
            disabled: isPreviewScreen,
            highlighted: false,
            columnName,
          }}
          widgetsProps={{ currencyId: selectedPartSupplier?.currency?.id }}
          formValidation={undefined}
          onFormValidationChange={undefined}
          value={value}
          onChange={onChange}
        />
      </div>
    );
  };

  render() {
    const { context, value, isPreviewScreen, partSuppliers } = this.props;
    const { userTypes, role } = context;
    const { selectedPartSupplier, showAddPartSupplier } = this.state;
    const { unitCost, reorderQuantity, partId } = value;
    return (
      <div className="row">
        <div className="col-sm-6">
          <Table>
            {this.getReadOnlyField("partId")}
            {this.getReadOnlyField("description")}
            {this.getReadOnlyField("locationId")}
            {this.getReadOnlyField("onHand")}
            {this.getReadOnlyField("onOrderQuantity")}
            {isPreviewScreen
              ? this.getReadOnlyField("conversionRate")
              : undefined}
          </Table>
        </div>

        <div className="col-sm-6">
          <RequiredField
            label={_("Supplier")}
            value={selectedPartSupplier}
            addOn={
              hasPermissionToCreate(userTypes, role, "PartSuppliers") &&
              !isPreviewScreen ? (
                <div className="x-new-record">
                  <button
                    className="x-new"
                    title={_("New")}
                    onClick={this.onClickAddPartSupplier}
                  >
                    <i className="fa fa-plus-circle" />
                  </button>
                </div>
              ) : undefined
            }
            input={
              <ForeignKeySelector
                className="qa-part-supplier-dropdown"
                context={context}
                placeholder={_("Select a part supplier")}
                allowClear={false}
                disabled={!partSuppliers.length || isPreviewScreen}
                options={partSuppliers}
                value={selectedPartSupplier}
                onChange={this.onPartSupplierChange}
              />
            }
          />
          {showAddPartSupplier ? (
            <AddRelatedEntityModal
              title={_("New Part Supplier")}
              context={context}
              parentRecord={partId}
              widgetsMap={partSupplierWidgetsMapper(
                context,
                partSuppliers,
                getEntityByBehavior("Supplier", context.entities).name,
              )}
              relatedEntity={
                context.entities[
                  getEntityByBehavior("PartSupplier", context.entities).name
                ]
              }
              groups={getFormGroups(
                context,
                context.entities[
                  getEntityByBehavior("PartSupplier", context.entities).name
                ],
              )}
              dismiss={this.onCancel}
              onSave={this.onSupplierAdded}
              defaultValue={{
                currency: context.currency.id,
                isDefault: !partSuppliers.length, // set Default the first entry
                supplierId: undefined,
                unitCost: undefined,
              }}
            />
          ) : undefined}
          {this.getGroupField("unitCost", unitCost > 0 ? "" : "x-has-error")}
          {this.getGroupField(
            "reorderQuantity",
            reorderQuantity > 0 ? "" : "x-has-error",
          )}
        </div>
      </div>
    );
  }
}
