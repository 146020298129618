import * as R from "ramda";
import { Context } from "common/types/context";
import { getLocalizedName } from "common";
import { getDefaultHeader } from "common/form/functions/header";
import { behaveAs, getColumn } from "common/entities";
import { Entity } from "common/entities/types";
import { Header } from "common/form/types";
import { LabelWidget } from "common/form/widget/label-widget";

interface PropTypes {
  entity: Entity;
  properties: any; // ??
  context: Context;
  headerConfig: Header;
  withLinks: boolean;
}

export const DatesInfo = ({
  entity,
  properties,
  context,
  headerConfig,
  withLinks,
}: PropTypes) => {
  const isWo = behaveAs("WorkOrder", entity);
  const behaviorFields = isWo ? ["startDate"] : [];
  const layout = R.mergeRight(getDefaultHeader(entity), headerConfig);

  const dateFields: string[] = [
    ...behaviorFields,
    layout.datePrimary,
    layout.dateSecondary,
  ];

  return (
    <div className="x-no-margin x-dates">
      <ul className="list-unstyled">
        {dateFields.map((field, i) => {
          const isStartDate = isWo && field === "startDate";
          const color = isStartDate ? "" : "txt-color-blueLight";
          const column = getColumn(entity, field);

          return (
            <li
              key={i}
              className={`qa-${entity.name}-${field} ${
                isStartDate ? "x-bold" : ""
              }`}
            >
              <div className={`x-label ${color}`}>
                {getLocalizedName(column)}
              </div>
              <div className="x-value">
                <LabelWidget
                  context={context}
                  withLinks={withLinks}
                  column={column}
                  value={properties[field]}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
