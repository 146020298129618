import * as R from "ramda";
import { JSX } from "react";
import { getLocalizedName } from "common";
import { getColumn } from "common/entities";
import { Entities, EntityJoin } from "common/entities/types";
import {
  defaultJoinType,
  getAvailableJoins,
  isOneToOne,
} from "common/query-builder/joins/functions";
import { JoinOption } from "common/query-builder/joins/types";
import { JoinItem, JoinType } from "common/query/types";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import { Selector } from "common/widgets/selector";
import { SelectorOption } from "common/widgets/selector/types";
import { withValue, WithValue } from "common/with-value";

interface PropTypes {
  className?: string;
  entities: Entities;
  entity: string;
  validJoinTypes?: JoinType[];
  oneToOneOnly?: boolean;
}

const handleAdd =
  (
    setValue: (x: JoinItem) => any,
    value: JoinItem,
    validJoinTypes: JoinType[],
  ) =>
  (join: EntityJoin) => {
    if (!join) return;

    const newJoin: JoinItem = join.outbound
      ? { column: join.column }
      : { column: join.column, entity: join.entityName };

    const joinToAdd =
      validJoinTypes && !validJoinTypes.includes(defaultJoinType)
        ? { ...newJoin, type: validJoinTypes[0] }
        : newJoin;

    setValue({ ...value, joins: [...(value.joins ?? []), joinToAdd] });
  };

const display =
  (entities: Entities, lowerCaseEntity: string) =>
  (j: SelectorOption<EntityJoin>) => {
    if (isGroupedOption(j)) return j.label;

    const entity = entities[j.entityName];
    const entityName = j.entityName.toLowerCase();
    const lowerCaseColumn = j.column.toLowerCase();
    const entityFromColumn = lowerCaseColumn.replace(/id$/, "") + "s";

    if (
      entityName === lowerCaseColumn ||
      entityName === entityFromColumn ||
      lowerCaseEntity === entityFromColumn
    ) {
      return getLocalizedName(entity);
    }

    const column = getColumn(entity, j.column);
    const columnDisplay = getLocalizedName(column) || j.column;

    return getLocalizedName(entity) + " (" + columnDisplay + ")";
  };

const JoinSelectorComp = ({
  className,
  entities,
  entity,
  validJoinTypes,
  oneToOneOnly,
  value,
  setValue,
}: PropTypes & WithValue<JoinItem>): JSX.Element => {
  const availableJoins = getAvailableJoins(entities, entity, value.joins);
  if (!availableJoins.length) return <span />;

  const oneToOneJoins = R.filter((j) => isOneToOne(j), availableJoins);
  const oneToManyJoins = oneToOneOnly
    ? []
    : R.filter((j) => !isOneToOne(j), availableJoins);

  const options = [
    ...(oneToOneJoins.length
      ? [{ label: _("One to one"), options: oneToOneJoins }]
      : []),
    ...(oneToManyJoins.length
      ? [{ label: _("One to many"), options: oneToManyJoins }]
      : []),
  ];

  return (
    <Selector<JoinOption>
      className={className}
      value={undefined}
      getOptionLabel={display(entities, entity.toLowerCase())}
      options={options}
      onChange={handleAdd(setValue, value, validJoinTypes)}
      placeholder={`${_("Join with")}...`}
    />
  );
};

export const JoinSelector = withValue<JoinItem, PropTypes>(
  JoinSelectorComp,
  "JoinSelector",
);
