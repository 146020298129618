import * as R from "ramda";
import { Component, JSX } from "react";
import { defaultFor } from "common";
import { Entity } from "common/entities/types";
import { get as getDesc } from "common/query-builder/description";
import { isQueryEquals } from "common/query-builder/functions";
import { Query, Secondaries } from "common/query/types";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { Filter } from "common/types/filters";
import { isAdminUserType } from "common/types/users";
import { CancelButtonSmall, ModifyButtonSmall } from "common/ui/buttons";
import { ReactHighlightWords } from "common/vendor-wrappers/react-highlight-words";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import {
  FormatMeta,
  LabelledOptionOrGroup,
} from "common/vendor-wrappers/react-select/types";
import { Selector } from "common/widgets/selector";
import { ValueProps } from "common/with-value-for";
import { ClearFilterState, SaveFilterState } from "x/records/list/types";
import { getFilterById, getFiltersPermissions } from "../functions";
import { FilterMenu, FilterMenuValue } from "./filter-menu";

type ShowSaveViewModal = (saveFilterState: SaveFilterState) => () => void;
type OnClickCallback = () => void;
type ButtonType = SaveFilterState | ClearFilterState;

interface PropTypes extends ValueProps<FilterMenuValue> {
  context: Context;
  entity: Entity;
  lastFilterId: number;
  filters: Filter[];
  secondaryQueries: Secondaries;
  starred: string[];
  error: ApiErrorResponse;
  defaultQuery: Query;
  isReport: boolean;
  showSaveViewModal: ShowSaveViewModal;
  onResetView: (toLastFilter: boolean) => () => void;
  onSelectView: (filter: Filter) => void;
}

const sanitizeSecondaries = (secondaryQueries: Secondaries) =>
  R.mapObjIndexed(
    (query: Query) => R.pickBy((v) => v !== undefined, query),
    secondaryQueries,
  );

const secondariesChanged = (filter: Filter, secondaryQueries: Secondaries) => {
  if (!secondaryQueries || !filter) return false;

  const sq1 = sanitizeSecondaries(filter.secondaryQueries);
  const sq2 = sanitizeSecondaries(secondaryQueries);

  return !R.equals(sq1, sq2);
};

const getButtonClass = (buttonType: ButtonType) => {
  return R.includes(buttonType, ["reset", "clear"])
    ? CancelButtonSmall
    : ModifyButtonSmall;
};

const getButtonLabel = (buttonType: ButtonType) => {
  const labels = {
    new: _("Save new"),
    edit: _("Edit"),
    update: _("Update"),
    reset: _("Reset"),
    clear: _("Clear"),
  };
  return labels[buttonType] || "";
};

const createButton = (buttonType: ButtonType, onClick: OnClickCallback) => {
  const ButtonClass = getButtonClass(buttonType);
  return (
    <ButtonClass
      key={buttonType}
      className={`qa-btn-${buttonType}`}
      onClick={onClick}
    >
      {getButtonLabel(buttonType)}
    </ButtonClass>
  );
};

const formatOption = (
  option: LabelledOptionOrGroup<Filter>,
  meta: FormatMeta<Filter>,
) => {
  if (isGroupedOption(option)) return option.label;

  const { shared } = option.value;
  const icon = shared ? "fa-eye" : "fa-eye-slash";
  const title = shared ? _("Shared") : _("Private");

  return (
    <div className="x-select-item">
      <div className="x-filter-icon qa-filter-icon" title={title}>
        <i className={`fa ${icon}`} />
      </div>
      <div className="x-filter-item qa-filter-item">
        <ReactHighlightWords text={option.label} search={meta?.inputValue} />
      </div>
    </div>
  );
};

const getFilterLabel = (filter: Filter) => filter.name;

export class FilterHeader extends Component<PropTypes> {
  static readonly displayName = "FilterHeader";

  getButtons = (secondaryQueriesChanged: boolean, lastFilter: Filter) => {
    const {
      context,
      entity,
      lastFilterId,
      showSaveViewModal,
      value = defaultFor<FilterMenuValue>(),
      onResetView,
    } = this.props;
    const { id, entities, userTypes } = context;

    const isLastFilter =
      value.query &&
      lastFilter?.query &&
      isQueryEquals(entities, entity, value.query, lastFilter.query);

    const { canCreate, canCreateShared, canUpdate } = getFiltersPermissions(
      context,
      ["Create", "CreateShared", "Update"],
    );

    const { createdBy, shared } = lastFilter || {};
    const canEdit =
      isAdminUserType(userTypes) ||
      (canUpdate &&
        lastFilter &&
        (!createdBy || createdBy === id) &&
        (!shared || canCreateShared));

    const buttonsFactory: { [key in ButtonType]: () => JSX.Element } = {
      edit: () => createButton("edit", showSaveViewModal("edit")),
      clear: () => createButton("clear", onResetView(false)),
      reset: () => createButton("reset", onResetView(true)),
      update: () => createButton("update", showSaveViewModal("update")),
      new: () => createButton("new", showSaveViewModal("new")),
    };

    const isChanged = !isLastFilter || secondaryQueriesChanged;
    const buttonsAvailability: { [key in ButtonType]: boolean } = {
      edit: !isChanged && canEdit,
      clear: isChanged && !lastFilterId,
      reset: isChanged && !!lastFilterId,
      update: isChanged && !!lastFilterId && canEdit,
      new: isChanged && canCreate,
    };

    return Object.keys(buttonsFactory)
      .filter((t: ButtonType) => buttonsAvailability[t])
      .map((t: ButtonType) => buttonsFactory[t]());
  };

  render() {
    const {
      context,
      entity,
      lastFilterId,
      filters,
      secondaryQueries,
      starred,
      error,
      defaultQuery,
      isReport,
      onSelectView,
      value = defaultFor<FilterMenuValue>(),
      onChange,
    } = this.props;

    if (!filters || !value.query) {
      return null;
    }

    const isDefaultQuery = isQueryEquals(
      context.entities,
      entity,
      value.query,
      defaultQuery,
    );
    const lastFilter = getFilterById(filters, lastFilterId);
    const secondaryQueriesChanged = secondariesChanged(
      lastFilter,
      secondaryQueries,
    );

    const description = getDesc(
      context,
      { entity: entity.name, query: value.query },
      secondaryQueries,
      starred,
    );

    return (
      <div className="x-records-filter x-flex">
        <FilterMenu
          context={context}
          entity={entity}
          starred={starred}
          isReport={isReport}
          value={value}
          onChange={onChange}
        />
        <Selector<Filter>
          className="qa-view-selector"
          placeholder={`${_("Saved views")}`}
          options={filters}
          allowClear={true}
          getOptionLabel={getFilterLabel}
          formatOption={formatOption}
          value={lastFilter}
          onChange={onSelectView}
        />
        {description ? (
          <span className="x-records-filter-description">
            <span className="x-records-filter-text">
              <b>{`${_("Filter")}: `}</b>
              {`${lastFilter?.name ?? ""} `}
              {description}
            </span>
          </span>
        ) : undefined}
        {!error && (!isDefaultQuery || secondaryQueriesChanged) ? (
          <span className="x-records-view-actions x-flex">
            {this.getButtons(secondaryQueriesChanged, lastFilter)}
          </span>
        ) : undefined}
      </div>
    );
  }
}
