import { Component } from "react";
import * as R from "ramda";
import { Context } from "common/types/context";
import { ValueProps, OnChange } from "common/with-value-for";
import { GetCached, getCached } from "common/cache";
import { getUrl } from "common/entities";
import { RunQuery, QueryForEntity, FilterAnd } from "common/query/types";
import { Field } from "common/query-builder/types";
import { Link } from "common/widgets/link";
import { ExplorerWidget } from "./widget";
import { Config } from "./types";
import { isValid, getRecordQuery, getCategoryQuery } from "./functions";

export interface TreeItem {
  shown: boolean;
  children?: TreeItem[];
}

interface PropTypes extends ValueProps<TreeItem> {
  context: Context;
  fields: Field[];
  runQuery: RunQuery;
  query: QueryForEntity;
  config: Config;
  values: string[];
}

interface ExplorerRecord {
  id: string;
  title: string;
  name: string;
  count: number;
}

interface StateType {
  loading?: boolean;
  failed?: boolean;
  records?: ExplorerRecord[];
}

const getListFilters = (config: Config, values: string[]): FilterAnd => {
  const conditions = values.map((value, i) => ({
    name: config[i].name,
    op: value !== null ? "eq" : "isnull",
    value,
  }));
  return { and: conditions };
};

type OnUpdateItem = OnChange<TreeItem>;
type OnToggleItem = () => void;

export class Item extends Component<PropTypes, StateType> {
  state: StateType = {
    loading: false,
    failed: false,
    records: null,
  };

  onUpdateItem: GetCached<OnUpdateItem, number>;
  onToggleItem: GetCached<OnToggleItem, number>;

  constructor(props: PropTypes) {
    super(props);

    this.onUpdateItem = getCached<OnUpdateItem, number>((i) => (value) => {
      const newChildren = R.update(i, value, this.getDisplayedChildren());
      this.props.onChange({ ...this.props.value, children: newChildren });
    });
    this.onToggleItem = getCached<OnToggleItem, number>((i) => () => {
      const childrenDisplay = this.getDisplayedChildren();
      const currentValue = childrenDisplay[i] || { shown: false };
      const newValue = R.mergeRight(currentValue, {
        shown: !currentValue.shown,
      });
      const newChildren = R.update(i, newValue, childrenDisplay);
      this.props.onChange({ ...this.props.value, children: newChildren });
    });
  }

  componentDidMount() {
    this.loadRecords();
  }

  componentDidUpdate(prevProps: PropTypes) {
    const { loading, failed, records } = this.state;

    if (
      R.equals(prevProps.query, this.props.query) &&
      R.equals(prevProps.values, this.props.values) &&
      prevProps.context.site.name === this.props.context.site.name &&
      (prevProps.value.shown ||
        !this.props.value.shown ||
        loading ||
        failed ||
        records)
    )
      return;

    this.loadRecords();
  }

  loadRecords = () => {
    const { context, fields, runQuery, query, values = [], value } = this.props;
    if (!value.shown || !isValid(query)) return;

    const explorerQuery =
      query.query.group.length === values.length
        ? getRecordQuery(context.entities, fields, query, values)
        : getCategoryQuery(context.entities, fields, query, values);

    this.setState({ records: null, loading: true, failed: false });
    runQuery(explorerQuery)
      .then((records: ExplorerRecord[]) =>
        this.setState({ records, loading: false, failed: false }),
      )
      .catch(() =>
        this.setState({ records: null, loading: false, failed: true }),
      );
  };

  getDisplayedChildren = () => {
    const { value } = this.props;
    const { children } = value;
    const { records } = this.state;
    return children || R.repeat({ shown: false }, records.length);
  };

  render() {
    const { context, fields, query, value, values, runQuery, config } =
      this.props;
    const { entities, site } = context;
    const { shown } = value;

    if (!shown || !isValid(query)) return <span />;

    const { loading, failed, records } = this.state;
    if (loading) {
      return (
        <div className="x-explorer-info">
          {_("Loading")}
          <i className="fa fa-spin fa-spinner" />
        </div>
      );
    }
    if (failed) {
      return (
        <div className="x-explorer-info x-warning">
          {_("Something went wrong fetching the records")}
        </div>
      );
    }

    if (!records || !records.length) {
      return (
        <div className="x-explorer-info">
          {_("No results for selected columns")}
        </div>
      );
    }

    const entity = entities[query.entity];

    if (values.length === query.query.group.length) {
      return (
        <div className="x-explorer-records">
          {records.map((r) => (
            <Link
              key={r.id}
              className="x-explorer-link"
              href={`${getUrl(entity, site.name)}/${r.id}`}
            >
              <div>{r.title}</div>
            </Link>
          ))}
        </div>
      );
    }

    const childrenDisplay = this.getDisplayedChildren();

    const items = records.map(({ name, count }, i) => {
      const { shown } = childrenDisplay[i];
      const arrowIcon = "fa fa-caret-" + (shown ? "down" : "right");
      const newValues = values.concat([name]);

      return (
        <div key={i} className="x-explorer-item">
          <span
            className={arrowIcon + " x-explorer-arrow"}
            onClick={this.onToggleItem(i)}
          />
          <Link
            className="x-explorer-name"
            href={`${getUrl(entity, site.name)}`}
            query={{ listFilters: getListFilters(config, newValues) }}
          >
            <ExplorerWidget
              context={context}
              entity={entity}
              config={config}
              values={newValues}
            />
            <span className="x-explorer-count">{count}</span>
          </Link>
          <Item
            context={context}
            fields={fields}
            runQuery={runQuery}
            query={query}
            config={config}
            values={newValues}
            value={childrenDisplay[i]}
            onChange={this.onUpdateItem(i)}
          />
        </div>
      );
    });

    return <div className="x-explorer-items">{items}</div>;
  }
}
