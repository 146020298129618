import { Component } from "react";
import { Selector } from "common/widgets/selector";
import {
  getSubQueryOperators,
  SubQueryOperator,
} from "common/entities/operators";
import { merge2, mergeChain } from "common/merge";
import { RenderGroupItem } from "common/query-builder/condition/types";
import {
  FilterRule,
  FilterSubQueryRule,
  GroupFilter,
  JoinItem,
  QueryForEntity,
  SubQueryScope,
} from "common/query/types";
import { Context } from "common/types/context";
import { VerticalField } from "common/ui/field";
import { Hint } from "common/widgets/hint";
import { ValueProps } from "common/with-value-for";
import { From } from "../from";
import { getFromEntities, sanitize } from "../functions";
import { Joins } from "../joins";
import { Field } from "../types";
import {
  allSubQueryScopes,
  buildScopeSubQueryCondition,
  defaultAnd,
  defaultSubQueryScope,
  displayComparisonOperator,
  displaySubQueryFilterScope,
  getEntitiesForSubQueryFilter,
  getOperatorByName,
  parseSubQuery,
} from "./functions";
import { Group } from "./group";

interface PropTypes extends ValueProps<FilterSubQueryRule> {
  context: Context;
  parentEntity: string;
  fields: Field[];
  disableNestedGroups: boolean;
  renderGroupItem: RenderGroupItem;
  onRemoveGroup: () => void;
  allowUnboundQueries?: boolean;
}

interface StateType {
  scopeFilter: FilterRule;
  subFilter: GroupFilter;
}

export class Exists extends Component<PropTypes, StateType> {
  static readonly displayName = "Exists";

  constructor(props: PropTypes) {
    super(props);

    const { value } = this.props;
    const { scopeFilter, subFilter } = parseSubQuery(value);
    this.state = {
      scopeFilter,
      subFilter,
    };
  }

  onEntityChange = (entity: string) => {
    const { context, parentEntity, value, onChange } = this.props;
    const { scope = defaultSubQueryScope } = value;
    const fullEntity = context.entities[entity];

    const scopeFilter = buildScopeSubQueryCondition(
      fullEntity,
      parentEntity,
      scope,
    );
    const filter = scopeFilter ? { and: [scopeFilter] } : defaultAnd;
    const newValue = mergeChain(value)
      .prop("queryValue")
      .set("entity", entity)
      .set("joins", [])
      .set("filter", filter)
      .output();

    this.setState({ scopeFilter, subFilter: defaultAnd });
    onChange(newValue);
  };

  onJoinChange = (joins: JoinItem[]) => {
    const { context, value, onChange } = this.props;

    const query: QueryForEntity = {
      entity: value.queryValue?.entity,
      query: { select: [], joins },
    };

    const joinItems = sanitize(context.entities, query).query.joins;
    const subQuery = merge2("queryValue", "joins", joinItems, value);
    onChange(subQuery);
  };

  onFilterChange = (subFilter: GroupFilter) => {
    const { value, onChange } = this.props;
    const { scopeFilter } = this.state;

    this.setState({ subFilter });

    const filter = scopeFilter
      ? {
          and: [scopeFilter, subFilter],
        }
      : subFilter;

    const newFilter = merge2("queryValue", "filter", filter, value);
    onChange(newFilter);
  };

  onOperatorChange = (operator: SubQueryOperator) => {
    const { onChange, value } = this.props;
    onChange({ ...value, op: operator.name });
  };

  onScopeChange = (scope: SubQueryScope) => {
    const { value, onChange } = this.props;
    onChange({
      ...value,
      scope,
      queryValue: { entity: undefined, filter: defaultAnd },
    });
  };

  render() {
    const {
      context,
      value,
      parentEntity,
      disableNestedGroups,
      fields,
      renderGroupItem,
      onRemoveGroup,
      allowUnboundQueries,
    } = this.props;

    const { subFilter } = this.state;
    const { queryValue, scope = defaultSubQueryScope } = value;
    const { entities } = context;
    const entityName = queryValue?.entity;

    const subQueryOperators = getSubQueryOperators();
    const operator = getOperatorByName(subQueryOperators, value.op);

    const entitiesForSubQueryFilter = getEntitiesForSubQueryFilter(
      getFromEntities(context),
      parentEntity,
      scope,
    );

    return (
      <div className="x-flex x-flex-grow-1 x-query-group-container x-sub-query qa-sub-query">
        <div className="x-flex-start-start x-sub-query-config qa-sub-query-config">
          <Selector<SubQueryOperator>
            className="x-flex-grow-1 qa-sub-query-op"
            disableSorting={true}
            options={subQueryOperators}
            value={operator}
            getOptionLabel={displayComparisonOperator}
            onChange={this.onOperatorChange}
          />
          {allowUnboundQueries ? (
            <div className="x-flex-grow-1 x-margin-left-10 x-sub-query-scope">
              <Selector<SubQueryScope>
                className="qa-sub-query-scope-selector"
                disableSorting={true}
                getOptionLabel={displaySubQueryFilterScope}
                options={allSubQueryScopes}
                value={scope}
                onChange={this.onScopeChange}
              />
              <Hint
                className="qa-sub-query-scope-hint"
                message={_(
                  "Changing the scope will remove data from the Sub Query filter",
                )}
              />
            </div>
          ) : undefined}
        </div>
        <div className="x-sub-query-form qa-sub-query-form">
          <VerticalField
            label={_("Entity")}
            className="x-entity qa-entity"
            input={
              <From
                entities={entitiesForSubQueryFilter}
                value={entityName}
                onChange={this.onEntityChange}
              />
            }
          />
          {entityName ? (
            <>
              <VerticalField
                label={_("Joins")}
                className="x-joins qa-joins"
                input={
                  <Joins
                    entities={entities}
                    entityName={entityName}
                    value={queryValue?.joins || []}
                    onChange={this.onJoinChange}
                  />
                }
              />
              <VerticalField
                label={_("Filter By")}
                className="x-sub-query-filter qa-sub-query-filter"
                input={
                  <Group
                    fields={fields}
                    onRemoveGroup={onRemoveGroup}
                    value={subFilter}
                    disableSubQueries={true}
                    disableNestedGroups={disableNestedGroups}
                    renderGroupItem={renderGroupItem}
                    onChange={this.onFilterChange}
                  />
                }
              />
            </>
          ) : undefined}
        </div>
      </div>
    );
  }
}
