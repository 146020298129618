import * as React from "react";
import { Required } from "common/widgets/required";
import { VerticalField } from "./field";

interface PropTypes extends React.ComponentProps<typeof VerticalField> {
  value: any;
}

export const RequiredField = (props: PropTypes) => {
  return (
    <VerticalField
      {...props}
      isRequired={true}
      input={<Required value={props.value}>{props.input}</Required>}
    />
  );
};
RequiredField.displayName = "RequiredField";
