import { Fragment } from "react";
import { Context } from "common/types/context";
import { PurchaseOrderAdjustmentEvent } from "common/types/events";
import { Row } from "../row";
import { EmptyDetails } from "./empty";
import { ReasonDetails } from "./reason";

interface PropTypes {
  event: PurchaseOrderAdjustmentEvent;
  context: Context;
  hideLinks?: boolean;
}

export const PurchaseOrderAdjustment = ({
  event,
  context,
  hideLinks,
}: PropTypes) => {
  const purchaseOrderItems =
    event.details.adjustmentPayload.purchaseOrderItems || [];

  return (
    <div>
      <EmptyDetails event={event} uiFormat={context.uiFormat} />
      <hr />
      {purchaseOrderItems.map((event) => (
        <Fragment key={event.id}>
          <div className="x-part-description">{event.partDescription}</div>
          {event.prevOrderedQty && event.receivedQty && (
            <Row label={event.status}>
              {_("{RECEIVEDQTY} out of {ORDEREDQTY}")
                .replace("{RECEIVEDQTY}", event.receivedQty.toString())
                .replace("{ORDEREDQTY}", event.prevOrderedQty.toString())}
            </Row>
          )}
        </Fragment>
      ))}
      <ReasonDetails
        reason={event.details.reason}
        context={context}
        hideLinks={hideLinks}
      />
    </div>
  );
};
