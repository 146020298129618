import * as R from "ramda";
import type { Entity } from "common/entities/types";
import type { FilterField, FilterRule, SelectField } from "common/query/types";
import type { EntityQueries } from "x/scheduler2/types";
import { getRulesValidator } from "common/query/filter";
import type { EntityColumn } from "common/entities/entity-column/types";

export const isFilterApplied = (entityQueries: EntityQueries) =>
  R.values(entityQueries).some((v) => !!v.query.filter);

export const resetFilter = (entityQueries: EntityQueries) =>
  R.fromPairs(
    R.toPairs(entityQueries).map(([entityName, query]) => [
      entityName,
      {
        entity: query.entity,
        query: R.pick(["select"], query.query),
      },
    ]),
  );

export const isSelectValid = (
  entityQueries: EntityQueries,
  workOrderEntity: Entity,
) =>
  entityQueries?.[workOrderEntity.name]?.query?.select?.every((select) =>
    workOrderEntity.columns.some(
      (column) => column.name === (select as SelectField).name,
    ),
  );

export const isFilterValid = (columns: EntityColumn[]) =>
  getRulesValidator(
    (filter: FilterRule) =>
      !filter ||
      columns.some((column) => column.name === (filter as FilterField)?.name),
  );
