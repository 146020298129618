import { Props } from "x/types";

export const splitLocation = (path: string = "") => {
  const [site, page, id, ...extra] = path.split("/").filter((p: string) => !!p);
  return { site, page, id, extra };
};

export const getPathWithoutSite = (props: Props) =>
  props?.location?.pathname.replace(/^\/[a-z]+\//, "");

export const hasEnteredAdmin = (prevPath: string, newPath: string) => {
  const isAdminRegex = /^admin(\/|$)/;
  return !isAdminRegex.test(prevPath) && isAdminRegex.test(newPath);
};
