import { getColumn } from "common/entities";
import { isRestrictedForRole } from "common/entities/entity-column/functions";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { MappedField } from "common/form/types";
import { getFkId } from "common/functions/foreign-key";
import { getSelectWithId } from "common/query/select";
import { FilterRule, QueryForEntity } from "common/query/types";
import { Context } from "common/types/context";
import { FkValue, ForeignKey } from "common/types/foreign-key";
import { Properties } from "common/types/records";

export const INTERNAL_EMPTY = Symbol("internalEmpty");

export const getEmptyOption = () =>
  ({
    id: INTERNAL_EMPTY as any,
    title: `(${_("Not set")})`,
  }) as ForeignKey;

export const isEmptyOption = (option: ForeignKey) =>
  !option || (getFkId(option) as unknown) === INTERNAL_EMPTY;

const getFilters = (
  mappedFields: MappedField[],
  value: Properties,
  currentIndex: number,
) => {
  const defaultFilters: FilterRule[] = [{ name: "isDeleted", op: "isfalse" }];
  if (currentIndex === 0) return defaultFilters;

  const previousLevels = mappedFields.slice(0, currentIndex); // excluding current

  return previousLevels.reduce<FilterRule[]>((acc, field) => {
    const fieldValue = value[field.columnName];
    const filter: FilterRule =
      fieldValue === INTERNAL_EMPTY || fieldValue?.id === INTERNAL_EMPTY
        ? {
            name: field.targetColumnName,
            op: "isnull",
          }
        : {
            name: field.targetColumnName,
            op: "eq",
            value: fieldValue,
          };
    return acc.concat(filter);
  }, defaultFilters);
};

export const getMainFkQuery = (
  targetEntity: Entity,
  mappedFields: MappedField[],
  value: Properties,
): QueryForEntity => ({
  entity: targetEntity.name,
  query: {
    ...targetEntity.query,
    select: getSelectWithId(targetEntity.query.select),
    filter: {
      // length here is technically out of bound
      and: getFilters(mappedFields, value, mappedFields.length),
    },
  },
});

export const createOption = (value: FkValue): ForeignKey =>
  ({ id: value, title: value }) as ForeignKey;

export const unwrap = (value: FkValue, isForeignKey: boolean): ForeignKey =>
  isForeignKey ? (value as ForeignKey) : createOption(value);

const getRequiredLevelsFilters = (
  entity: Entity,
  mappedFields: MappedField[],
): FilterRule[] =>
  mappedFields.reduce<FilterRule[]>((acc, field) => {
    const column = entity.columns.find(
      (column) => column.name === field.columnName,
    );
    return column?.required
      ? acc.concat({ name: field.targetColumnName, op: "isnotnull" })
      : acc;
  }, []);

export const getLevelQuery = (
  entity: Entity,
  targetEntityName: string,
  targetColumnName: string,
  mappedFields: MappedField[],
  value: Properties,
  currentIndex: number,
): QueryForEntity => {
  const requiredFilters: FilterRule[] = getRequiredLevelsFilters(
    entity,
    mappedFields,
  );

  return {
    entity: targetEntityName,
    query: {
      select: [{ name: targetColumnName }],
      order: [{ name: targetColumnName }],
      group: [{ name: targetColumnName }],
      filter: {
        and: getFilters(mappedFields, value, currentIndex).concat(
          requiredFilters,
        ),
      },
    },
  };
};

const hasRestrictedFkColumn = (
  context: Context,
  entity: Entity,
  mainFkColumnName: string,
) => {
  const mainFkColumn = getColumn(entity, mainFkColumnName);
  return isRestrictedForRole(mainFkColumn?.roleIds, context.role);
};

const hasRestrictedLookupLevel = (
  context: Context,
  entity: Entity,
  mappedFields: MappedField[],
) =>
  mappedFields.some(({ columnName }) =>
    hasRestrictedFkColumn(context, entity, columnName),
  );

export const hasRestrictedColumn = (
  context: Context,
  entity: Entity,
  mappedFields: MappedField[],
  mainFkColumnName: string,
) =>
  hasRestrictedLookupLevel(context, entity, mappedFields) ||
  hasRestrictedFkColumn(context, entity, mainFkColumnName);

export const hasReadOnlyField = (
  entity: Entity,
  mappedFields: MappedField[],
  mainFkColumn: EntityColumn,
) =>
  mainFkColumn?.readOnly ||
  mainFkColumn?.userReadOnly ||
  mappedFields.some(({ columnName }) => {
    const column = getColumn(entity, columnName);
    return column?.readOnly || column?.userReadOnly;
  });
