import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { LabelWidget } from "common/form/widget/label-widget";
import { ColumnDefinition } from "../advanced-types";
import { hasFieldsWithTotals, getColumnTotal } from "./functions";

interface PropTypes {
  data: Properties[];
  columnDefinitions: ColumnDefinition[];
  context: Context;
  hasActions: boolean;
  hasSelectAction: boolean;
}

export const Footer = ({
  context,
  columnDefinitions = [],
  data = [],
  hasActions,
  hasSelectAction,
}: PropTypes) => {
  if (!hasFieldsWithTotals(columnDefinitions)) return null;

  const footerCols = columnDefinitions.map(({ column }) => {
    if (!column.renderWithTotals) return <td key={column.name} />;

    return (
      <td key={column.name}>
        <LabelWidget
          context={context}
          column={column}
          value={getColumnTotal(data, column)}
          currencyId={context.currency.id}
        />
      </td>
    );
  });

  return (
    <tfoot>
      <tr>
        {hasActions || hasSelectAction ? <td /> : null}
        {footerCols}
      </tr>
    </tfoot>
  );
};
