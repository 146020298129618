import * as R from "ramda";
import { Component, ReactNode } from "react";
import { formatFk } from "common/widgets/foreign-key/functions";
import { isGroupedOption } from "common/vendor-wrappers/react-select/functions";
import {
  FormatMeta,
  LabelledOption,
  LabelledOptionOrGroup,
} from "common/vendor-wrappers/react-select/types";
import { Entity } from "common/entities/types";
import { QueryForEntity, RunQuery } from "common/query/types";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { Selector } from "common/widgets/selector";
import { formatItem } from "common/widgets/record-selector/functions";
import { ValueProps } from "common/with-value-for";

interface PropTypes<T> extends ValueProps<T> {
  context: Context;
  runQuery: RunQuery;
  query: QueryForEntity;
  placeholder?: string;
  disabled?: boolean;
  defaults?: T[];
  entity?: Entity;
  formatOption?: (
    option: LabelledOptionOrGroup<T>,
    meta: FormatMeta<T>,
  ) => ReactNode;
}

interface StateType<T> {
  records?: T[];
  loading?: boolean;
}

export class QuerySelector<T extends ForeignKey> extends Component<
  PropTypes<T>,
  StateType<T>
> {
  static readonly displayName = "QuerySelector";
  state: StateType<T> = {
    records: [],
  };

  componentDidMount() {
    this.loadRecords();
  }

  componentDidUpdate(prevProps: PropTypes<T>) {
    if (R.equals(prevProps.query, this.props.query)) return;

    this.loadRecords();
  }

  loadRecords = () => {
    const { query, runQuery, disabled, value } = this.props;
    if (!query || (disabled && !value)) return;

    this.setState({ loading: true });

    const queryWithCache: QueryForEntity = {
      ...query,
      query: { ...query.query, cache: true },
    };

    runQuery(queryWithCache).then((records: T[]) => {
      this.setState({ records, loading: false });
    });
  };

  formatOption = (option: LabelledOptionOrGroup<T>, meta: FormatMeta<T>) => {
    const { query, entity, context } = this.props;

    if (!query || !option) return undefined;

    if (isGroupedOption(option)) return option.label;

    if (this.props.formatOption) {
      return this.props.formatOption(option, meta);
    }

    return context && entity
      ? formatItem(
          query.query.select,
          entity,
          context,
        )(option.value, meta?.inputValue)
      : formatItem(query.query.select)(option.value, meta?.inputValue);
  };

  formatSelection = (option: LabelledOption<T>) => {
    if (!option) return undefined;

    if (this.props.formatOption) {
      return this.props.formatOption(option, undefined);
    }

    const { entity, context } = this.props;
    return formatFk(context.entities)(option.value, true, entity, context);
  };

  render() {
    const {
      query,
      value,
      placeholder,
      disabled = false,
      onChange,
      defaults = [],
    } = this.props;
    const { records, loading } = this.state;

    const options =
      value && !records.length ? [value] : defaults.concat(records);
    const currentValue = value
      ? options.find((fk) => fk.id === value.id)
      : undefined;

    return (
      <Selector<T>
        allowClear={false}
        placeholder={loading ? `${_("loading")}...` : placeholder}
        formatOption={this.formatOption}
        formatSelection={this.formatSelection}
        className="x-selector qa-selector"
        options={options}
        disabled={!query || loading || disabled}
        value={!loading ? currentValue : undefined}
        onChange={onChange}
      />
    );
  }
}
