import { History } from "history";
import { GoFn } from "common/types/url";

export const cleanUrlHash = (url: string) => {
  if (!url) return "/";
  return "/" + url.replace(/^[/#]*\//, "");
};
export const goTo =
  (history: History): GoFn =>
  (url: string) => {
    const { location } = history;
    const urlHash = cleanUrlHash(url);
    const locationPathname = cleanUrlHash(location.pathname);
    if (urlHash !== locationPathname) {
      history.push(urlHash);
    }
  };
