import { formatDate } from "common/date-time/formatters";
import { UTCDateTime } from "common/date-time/types";
import { isDateValid } from "common/date-time/validators";
import { HighlightProps, Label } from "../label";

interface PropTypes extends HighlightProps {
  format: string;
  title?: string;
  timezone?: string;
  value: UTCDateTime;
}

export const getFormattedDate = (
  value: UTCDateTime,
  format: string,
  timezone?: string,
) => (isDateValid(value) ? formatDate(value, format, timezone) : value);

export const DateBaseLabel = ({
  format,
  title,
  textToHighlight,
  timezone,
  value,
}: PropTypes) => (
  <Label
    title={title}
    value={getFormattedDate(value, format, timezone)}
    textToHighlight={textToHighlight}
  />
);
