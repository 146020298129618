import { Component } from "react";
import { recordsApi } from "common/api/records";
import { Entity } from "common/entities/types";
import { isDelete } from "common/record/form/functions";
import { RequestOptions } from "common/types/api";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { CancellablePromise } from "common/types/promises";
import { Record } from "common/types/records";
import { LoadingButton } from "common/widgets/loading-button";

interface PropTypes {
  context: Context;
  entity: Entity;
  record: Record;
  isValid: boolean;
  save: (
    record: Record,
    confirmDelete: boolean,
    requestOptions?: RequestOptions,
  ) => CancellablePromise<any>;
  onError: (error: ApiErrorResponse) => void;
  onActionExecuting: (isExecuting: boolean) => void;
  onSubmitted: (recordId: string) => void;
}

interface StateType {
  loading: boolean;
}

export class SubmitButton extends Component<PropTypes, StateType> {
  static readonly displayName = "SubmitButton";

  state: StateType = {
    loading: false,
  };

  onSubmit = () => {
    const {
      context,
      entity,
      record,
      save,
      onSubmitted,
      onError,
      onActionExecuting,
    } = this.props;
    const { apiCall } = context;

    this.setState({ loading: true });
    onActionExecuting(true);

    save(record, isDelete(record), {
      hideNotifications: true,
      preventReloadAfterSave: true,
    })
      .then((recordId) =>
        recordsApi(apiCall)
          .bulkSubmitRequisition(entity.name, [recordId])
          .then(() => {
            onActionExecuting(false);
            onSubmitted(recordId);
          }),
      )
      .catch((error) => {
        this.setState({ loading: false });
        onActionExecuting(false);
        onError(error);
      });
  };

  render() {
    const { loading } = this.state;
    const { isValid } = this.props;

    return (
      <LoadingButton
        className="qa-submit"
        loadingText={_("Submitting")}
        idleText={_("Submit")}
        disabled={!isValid}
        loading={loading}
        onClick={this.onSubmit}
        type="modify"
        size="large"
      />
    );
  }
}
