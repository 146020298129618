import { Component, JSX } from "react";
import { getTrue } from "common";
import { queryBuilderBlackList } from "common/entities/entity-column/data-type/types";
import { Entities } from "common/entities/types";
import { FieldSelector } from "common/query-builder/field-selector";
import { handleAdd } from "common/query-builder/functions";
import { Field } from "common/query-builder/types";
import { getSelectErrors, SelectError } from "common/query-builder/validation";
import { SelectField, SelectItem } from "common/query/types";
import { Context } from "common/types/context";
import { createList } from "common/widgets/list";
import { OnChange, ValueProps } from "common/with-value-for";
import { getSelectedFullPaths, isFieldFromOneToManyJoin } from "./functions";
import { SelectListItem } from "./select-list-item";

interface PropTypes extends ValueProps<SelectItem[]> {
  context: Context;
  entities: Entities;
  fields: Field[];
  allowAggregates: boolean;
  allowLong: boolean;
  forceFkAggregates: boolean;
  maxFields?: number;
  withExpressions?: boolean;
  noDuplicates?: boolean;
  noAliases?: boolean;
  entitySelector?: JSX.Element;
  label?: string;
}

const List = createList<SelectItem>("Select");

const noErrors: SelectError[] = [];

export class Select extends Component<PropTypes> {
  static readonly displayName = "Select";

  onChangeValue = (value: SelectItem[]) => {
    this.props.onChange(value);
  };

  onAdd = (field: Field) => {
    const { entities } = this.props;
    handleAdd(this.onChangeValue, this.props.value, (v) =>
      this.props.forceFkAggregates && isFieldFromOneToManyJoin(v.path, entities)
        ? { ...v, fn: "COUNT" }
        : v,
    )(field);
  };

  getName = (item: SelectItem) => {
    return (item as SelectField).name;
  };

  renderItemWithoutErrors = (
    item: SelectItem,
    onChange: OnChange<SelectItem>,
    index: number,
  ) => {
    const {
      context,
      fields,
      allowAggregates,
      forceFkAggregates,
      noAliases,
      entities,
    } = this.props;

    return (
      <SelectListItem
        context={context}
        index={index}
        entities={entities}
        fields={fields}
        allowAggregates={allowAggregates}
        forceFkAggregates={forceFkAggregates}
        errors={noErrors}
        noAliases={noAliases}
        value={item}
        onChange={onChange}
      />
    );
  };

  renderItem = (errors: SelectError[]) => {
    return errors.length
      ? (item: SelectItem, onChange: OnChange<SelectItem>, index: number) => (
          <SelectListItem
            context={this.props.context}
            index={index}
            entities={this.props.entities}
            fields={this.props.fields}
            allowAggregates={this.props.allowAggregates}
            forceFkAggregates={this.props.forceFkAggregates}
            errors={errors}
            noAliases={this.props.noAliases}
            value={item}
            onChange={onChange}
          />
        )
      : this.renderItemWithoutErrors;
  };

  render() {
    const {
      label,
      fields,
      allowLong,
      withExpressions,
      noDuplicates,
      entitySelector,
      value = [],
      maxFields,
    } = this.props;
    const selectErrors = getSelectErrors(value);
    const disableDropdown = !!maxFields && value.length >= maxFields;

    const hasError = !value.length ? " x-has-error" : "";

    const selectedFullPaths = getSelectedFullPaths(value);

    const filteredFields = noDuplicates
      ? fields.filter((field: Field) => {
          const fieldFullPath = `${field.path}.${field.column.name}`;
          return selectedFullPaths.indexOf(fieldFullPath) === -1;
        })
      : fields;

    const cn = !entitySelector
      ? "x-query-builder-select qa-query-builder-select" + hasError
      : "x-query-builder-table-select qa-query-builder-select" + hasError;

    return (
      <div className={cn}>
        <div className="x-select-controls">
          <FieldSelector
            className="x-select-column"
            label={label}
            value={undefined}
            withExpressions={withExpressions}
            fields={
              allowLong
                ? filteredFields
                : filteredFields.filter(
                    (f) => !queryBuilderBlackList.includes(f.column.dataType),
                  )
            }
            disabled={disableDropdown}
            onChange={this.onAdd}
          />
          {entitySelector}
        </div>
        {value.length ? (
          <List
            className="x-padding-top-10 x-padding-bottom-10"
            canDelete={getTrue}
            getName={this.getName}
            onDisplay={this.renderItem(selectErrors)}
            value={value}
            onChange={this.onChangeValue}
          />
        ) : undefined}
      </div>
    );
  }
}
