import * as R from "ramda";
import { getColumn } from "common/entities";
import { Entities } from "common/entities/types";

const getPathRelatedName = (
  entities: Entities,
  entityName: string,
  path: string,
) => {
  if (path && R.includes(".", path)) {
    return path.split(".")[0];
  }
  const column = getColumn(entities[entityName], path);
  return column && column.relatedEntity;
};

const getRelatedEntityNameByPathRec = (
  entities: Entities,
  entityName: string,
  paths: string[],
): string => {
  const pathEntityName = getPathRelatedName(entities, entityName, paths[0]);

  return paths.length > 1
    ? getRelatedEntityNameByPathRec(entities, pathEntityName, paths.slice(1))
    : pathEntityName;
};

export const getRelatedEntityNameByPath = (
  entities: Entities,
  entityName: string,
  path: string,
) => {
  const paths = path.split("/").filter((p) => p);
  return getRelatedEntityNameByPathRec(entities, entityName, paths);
};
