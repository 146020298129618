import * as R from "ramda";
import { Role } from "common/types/roles";
import { KeysOf } from "common/types/records";
import { isRoleWithFullPermissions } from "common/functions/roles";

interface RoleMissingPermissions {
  roleName: string;
  missingEntities: string[];
}

export const getRolesMissingPermissions = (
  roles: Role[] = [],
  reportEntityNames: string[] = [],
) =>
  roles.reduce((acc: RoleMissingPermissions[], r) => {
    if (isRoleWithFullPermissions(r)) return acc;

    const permissionKeys = R.keys(r.permissions) as KeysOf<Permissions>;
    const missingEntities = R.difference(reportEntityNames, permissionKeys);

    return missingEntities.length
      ? acc.concat({
          roleName: r.name,
          missingEntities,
        })
      : acc;
  }, []);
