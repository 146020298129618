import * as R from "ramda";
import { Context } from "common/types/context";
import { withValue, WithValue } from "common/with-value";
import { defaultValue } from "common/widgets/map";
import { MapValue, Pin } from "common/vendor-wrappers/leaflet/types";
import { EntityMap } from "./entity-map";
import { Data } from "./types";

interface PropTypes {
  context: Context;
  data: Data;
  image: string;
  entity: string;
  defaultPin: string;
  selectedPin: string;
  showAll: boolean;
}

const QueryMapComp = ({
  context,
  image,
  entity,
  defaultPin = "blue.svg",
  selectedPin = "red.svg",
  showAll,
  data = [],
  value = defaultValue,
  onChangeSetValue,
}: PropTypes & WithValue<MapValue>) => {
  const { pins = [] } = value;

  const ids: string[] = data.map((d) => d.id);
  const isSelected = (p: Pin) => p.layer === entity && R.includes(p.id, ids);

  const queryPins = showAll
    ? pins.map((p) =>
        R.mergeRight(p, {
          icon: isSelected(p) ? selectedPin : defaultPin,
        }),
      )
    : pins.filter(isSelected);

  const queryValue = R.mergeRight(value, { pins: queryPins });

  return (
    <EntityMap
      context={context}
      image={image}
      isReadOnly={true}
      value={queryValue}
      onChange={onChangeSetValue}
    />
  );
};

export const QueryMap = withValue<MapValue, PropTypes>(
  QueryMapComp,
  "QueryMap",
);
