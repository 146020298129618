import { ApiCall } from "common/types/api";
import { defaultFor } from "common";
import { recordsApi } from "common/api/records";
import { Email } from "common/types/records";
import { Action } from "common/record/actions/action";
import { PropTypes, DismissAction } from "common/record/actions/types";
import { hasProtectedColumns } from "common/record/utils";
import { EmailAction, isValid } from "common/widgets/email-action";
import { withValue, WithValue } from "common/with-value";

export interface SendEmailValue {
  emailForm: Email;
}

const SendEmailModalComp = ({
  context,
  entity,
  records = [],
  dismiss,
  value = defaultFor<SendEmailValue>(),
  onChangeMergeValue,
}: PropTypes & WithValue<SendEmailValue>) => {
  const onOk = (apiCall: ApiCall, dismiss: DismissAction) =>
    recordsApi(apiCall)
      .email(entity?.name, records[0]?.properties?.id, value.emailForm)
      .then(dismiss);

  return (
    <Action
      requiresAuthentication={hasProtectedColumns(entity)}
      context={context}
      dismiss={dismiss}
      entity={entity}
      records={records}
      title={_("E-mail")}
      btnLabel={_("Send")}
      onOk={isValid(value.emailForm) && onOk}
      hideReason={true}
    >
      <EmailAction
        context={context}
        entity={entity}
        isBulkAction={false}
        value={value.emailForm}
        onChange={onChangeMergeValue("emailForm")}
      />
    </Action>
  );
};

export const SendEmailModal = withValue<SendEmailValue, PropTypes>(
  SendEmailModalComp,
  "SendEmailModal",
);
