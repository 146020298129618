import * as R from "ramda";
import { Component } from "react";
import { Entities } from "common/entities/types";
import { RecentlyViewed } from "common/types/recently-viewed";
import { Site } from "common/types/sites";
import { ValueProps } from "common/with-value-for";
import { RecentRecord } from "./recent-record";

interface PropTypes extends ValueProps<RecentlyViewed[]> {
  entities: Entities;
  sites: Site[];
}

export class RecentlyViewedList extends Component<PropTypes> {
  onDelete = (index: number) => {
    return () => {
      this.props.onChange(R.remove(index, 1, this.props.value));
    };
  };

  render() {
    const { entities, sites, value = [] } = this.props;
    return (
      <div className="x-recently-viewed">
        {value.map((record: RecentlyViewed, index: number) => (
          <RecentRecord
            key={index}
            record={record}
            entities={entities}
            sites={sites}
            onDelete={this.onDelete(index)}
          />
        ))}
      </div>
    );
  }
}
