import { isReferenceEntity } from "common/api/entities";
import { Entity } from "common/entities/types";
import {
  FormValidationProps,
  Group,
  WidgetsOverwriteMap,
} from "common/form/types";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { ValueProps } from "common/with-value-for";
// eslint-disable-next-line import/no-cycle
import { SectionGroup } from "./group";
// eslint-disable-next-line import/no-cycle
import { ReferenceGroup } from "./reference-group";

interface PropTypes extends FormValidationProps, ValueProps<Properties> {
  context: Context;
  entity: Entity;
  groups: Group[];
  withLinks: boolean;
  allowDynamicValues?: boolean;
  widgetsMap?: WidgetsOverwriteMap;
}

export const Groups = ({
  groups = [],
  context,
  entity,
  withLinks,
  widgetsMap,
  allowDynamicValues,
  formValidation,
  onFormValidationChange,
  value,
  onChange,
}: PropTypes) => (
  <>
    {isReferenceEntity(entity)
      ? groups.map((group) => (
          <ReferenceGroup
            key={group.name}
            context={context}
            entity={entity}
            groups={groups}
            group={group}
            withLinks={withLinks}
            allowDynamicValues={allowDynamicValues}
            formValidation={formValidation}
            onFormValidationChange={onFormValidationChange}
            value={value}
            onChange={onChange}
          />
        ))
      : groups.map((group) => (
          <SectionGroup
            key={group.name}
            context={context}
            entity={entity}
            groups={groups}
            group={group}
            widgetsMap={widgetsMap}
            withLinks={withLinks}
            hideLegend={groups.length <= 1}
            allowDynamicValues={allowDynamicValues}
            formValidation={formValidation}
            onFormValidationChange={onFormValidationChange}
            value={value}
            onChange={onChange}
          />
        ))}
  </>
);
