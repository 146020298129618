import {
  add,
  areIntervalsOverlapping,
  getDate,
  getMonth,
  getYear,
  sub,
} from "date-fns";
import { getEndOf, getStartOf } from "common/date-time/calculators";
import {
  FORMAT_DAY_ORDINAL,
  FORMAT_FULL_DAY,
  FORMAT_FULL_MONTH,
  FORMAT_FULL_NAMED_DAY,
  FORMAT_NAMED_MONTH,
} from "common/date-time/formats";
import { formatDate } from "common/date-time/formatters";
import { parseToSited, sitedToUtcISO } from "common/date-time/internal";
import {
  DurationUnit,
  TimeRange,
  TimeUnit,
  UTCDateTime,
} from "common/date-time/types";
import { isIntervalValid, isRangeValid } from "common/date-time/validators";
import { ViewType } from "common/types/scheduler";

const unitOfTimeGetters = [getYear, getMonth, getDate];
const formatSettings = {
  customMonth: [FORMAT_FULL_MONTH, FORMAT_NAMED_MONTH],
  day: [FORMAT_FULL_NAMED_DAY, FORMAT_DAY_ORDINAL],
  week: [FORMAT_FULL_DAY, FORMAT_DAY_ORDINAL],
};

export const getDurationFromView = (view: ViewType): DurationUnit => {
  switch (view) {
    case "day":
      return "days";
    case "week":
      return "weeks";
    case "customMonth":
      return "months";
    default:
      return undefined;
  }
};

export const getTimeUnitFromView = (view: ViewType): TimeUnit =>
  view === "customMonth" ? "month" : view;

export const getStartOfForDateWithViewType = (
  date: UTCDateTime,
  view: ViewType,
): UTCDateTime => getStartOf(date, getTimeUnitFromView(view));

export const getEndOfForDateWithViewType = (
  date: UTCDateTime,
  view: ViewType,
): UTCDateTime => getEndOf(date, getTimeUnitFromView(view));

export const getPreviousDate = (
  date: UTCDateTime,
  view: ViewType,
): UTCDateTime => {
  const sitedDate = parseToSited(date);
  const duration = getDurationFromView(view);

  return duration && sitedDate
    ? sitedToUtcISO(sub(sitedDate, { [duration]: 1 }))
    : undefined;
};

export const getNextDate = (date: UTCDateTime, view: ViewType): UTCDateTime => {
  const sitedDate = parseToSited(date);
  const duration = getDurationFromView(view);

  return duration && sitedDate
    ? sitedToUtcISO(add(sitedDate, { [duration]: 1 }))
    : undefined;
};

export const areTimeRangesOverlapping = (a: TimeRange, b: TimeRange) => {
  const range1 = {
    start: parseToSited(a.rangeFrom),
    end: parseToSited(a.rangeTo),
  };
  const range2 = {
    start: parseToSited(b.rangeFrom),
    end: parseToSited(b.rangeTo),
  };

  if (!isIntervalValid(range1) || !isIntervalValid(range2)) return false;

  return areIntervalsOverlapping(range1, range2);
};

export const getBeginningOfDay = (date: UTCDateTime): UTCDateTime =>
  getStartOf(date, "day");

export const formatDateToFullNamedDay = (date: UTCDateTime) =>
  formatDate(date, FORMAT_FULL_NAMED_DAY);

const removeLastUnits = (format: string, numberOfTokens: number) =>
  format.replace(new RegExp(`(\\W\\w+){${numberOfTokens}}$`), "");

const formatRangeCompactly = (
  from: UTCDateTime,
  to: UTCDateTime,
  format: string,
  maxRemovableUnits: number,
) => {
  const sitedFrom = parseToSited(from);
  const sitedTo = parseToSited(to);

  for (let i = 0; i <= maxRemovableUnits; ++i) {
    const unitGetter = unitOfTimeGetters[i];
    if (unitGetter(sitedFrom) !== unitGetter(sitedTo)) {
      const shortFormat = removeLastUnits(format, i);
      return `${formatDate(from, shortFormat)} - ${formatDate(to, format)}`;
    }
  }
  return formatDate(to, format);
};

export const formatRange = (
  from: UTCDateTime,
  to: UTCDateTime,
  viewType: ViewType,
) => {
  if (!isRangeValid(from, to)) return "";

  const [format, baseUnit] = formatSettings[viewType] || formatSettings.week;
  const formatSplit = format.split(new RegExp(`(?:^|\\W)${baseUnit}(?:\\W|$)`));
  const maxRemovableUnits = (formatSplit[1] || "").split(/\W/).length;

  return formatRangeCompactly(from, to, format, maxRemovableUnits);
};
