import { ComponentType } from "react";
import { ValueComponent, ValueProps, OnChange } from "common/with-value-for";
import { Modal } from ".";

interface ModalProps {
  title: string;
  isValid: boolean;
  className?: string;
  okLabel?: string;
  size?: "small" | "large";
}

interface WithModal<Value> {
  value: Value;
  onChange: OnChange<Value>;
}

export interface ModalValue<Value> {
  value: Value;
  modalValue?: Value;
}

// The props that get passed to withModal(YourComponent)
type ModalInput<Value, PropTypes> = PropTypes &
  ModalProps &
  ValueProps<ModalValue<Value>>;

// The props that YourComponent expects
type ModalOutput<Value, PropTypes> = PropTypes & WithModal<Value>;

export function showModal<Value>(value: Value): ModalValue<Value> {
  return { modalValue: value, value };
}

export function getValue<Value>(value: ModalValue<Value>): Value {
  return value && value.value;
}

export function withModal<Value, PropTypes>(
  YourComponent: ComponentType<ModalOutput<Value, PropTypes>>,
) {
  type ModalVal = ModalValue<Value>;

  type ModalProps = ModalInput<Value, PropTypes>;

  return class WithModal extends ValueComponent<ModalVal, ModalProps> {
    onSave = () => {
      const { modalValue } = this.props.value;
      this.mergeValue({
        modalValue: undefined,
        value: modalValue,
      });
    };

    onShow = () => {
      const { value } = this.props.value;
      this.mergeValue({ modalValue: value });
    };

    onCancel = () => {
      this.mergeValue({ modalValue: undefined });
    };

    render() {
      const { title, className, okLabel, size, isValid, value } = this.props;
      return value && value.modalValue ? (
        <Modal
          content={
            <YourComponent
              {...this.props}
              value={value.modalValue}
              onChange={this.onChangeMergeValue("modalValue")}
            />
          }
          size={size}
          className={className}
          title={title}
          okLabel={okLabel || _("Apply")}
          onOk={isValid ? this.onSave : undefined}
          onCancel={this.onCancel}
        />
      ) : null;
    }
  };
}
