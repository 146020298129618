export interface RGBColor {
  r: number;
  g: number;
  b: number;
  a?: number;
}

export type HexColorString = string;
export type RGBColorString = string;
type ColorString = string | HexColorString | RGBColorString;

export const BLACK: HexColorString = "#000000";
export const BLUE: HexColorString = "#367BE4";
// eslint-disable-next-line import/no-unused-modules
export const DARK_BLUE: HexColorString = "#1666A9";
export const DARK_GREY: HexColorString = "#333333";
export const FLUKE_YELLOW: HexColorString = "#FFC20E";
// eslint-disable-next-line import/no-unused-modules
export const GREEN: HexColorString = "#7ACC5A";
// eslint-disable-next-line import/no-unused-modules
export const GREY: HexColorString = "#777777";
export const LIGHT_RED: HexColorString = "#E84446";
// eslint-disable-next-line import/no-unused-modules
export const PURPLE: HexColorString = "#A64DB8";
export const RED: HexColorString = "#C00C00";
// eslint-disable-next-line import/no-unused-modules
export const WHITE: HexColorString = "#ffffff";

export const COLOR_WHITE: RGBColor = { r: 255, g: 255, b: 255, a: 1 };
export const COLOR_BLACK: RGBColor = { r: 0, g: 0, b: 0, a: 1 };

// eslint-disable-next-line import/no-unused-modules
export const TRANSPARENT: ColorString = "transparent";
export const BORDER_COLOR_FACTOR: number = -0.4;
