import * as R from "ramda";
import { Component } from "react";
import { Context } from "common/types/context";
import { defaultFor } from "common";
import { FkEventValues, Properties } from "common/types/records";
import { Entity } from "common/entities/types";
import { AlertLoading, AlertWarning } from "common/widgets/alert";
import { AvailabilityResult, EventProperties } from "../types";
import { EventConflicts } from "./conflicts-details";
import {
  checkAssignmentAvailability,
  eventPropertiesChanged,
  isEventAlreadyAdded,
} from "./functions";
import { QueryResult } from "./types";

interface PropTypes {
  context: Context;
  entity: Entity;
  eventProps: EventProperties;
  uiFormProperties?: Properties[];
  relatedCalendarArgValue?: FkEventValues;
}

interface StateType {
  result?: AvailabilityResult;
  error?: any;
  loading?: boolean;
}

const defaultEventProps = defaultFor<EventProperties>();

export class CheckAvailability extends Component<PropTypes, StateType> {
  static readonly displayName = "CheckAvailability";
  state: StateType = {
    result: undefined,
    error: undefined,
    loading: false,
  };

  componentDidMount() {
    this.check();
  }

  componentDidUpdate(prevProps: PropTypes) {
    if (eventPropertiesChanged(prevProps.eventProps, this.props.eventProps)) {
      this.check();
    }
  }

  check = () => {
    const {
      eventProps = defaultEventProps,
      entity,
      context,
      relatedCalendarArgValue,
    } = this.props;

    const { rangeFrom, rangeTo } = eventProps;
    if (rangeFrom && rangeTo) this.setState({ loading: true });

    checkAssignmentAvailability(
      eventProps,
      entity,
      context,
      relatedCalendarArgValue,
    )
      .then((result: QueryResult) => {
        if (this.haveEventPropsChanged(eventProps)) return;
        this.setState({ result, error: undefined, loading: false });
      })
      .catch((error: any) => {
        if (this.haveEventPropsChanged(eventProps)) return;
        if (error) {
          const errorMsg = error.message ? error.message : error;
          this.setState({ result: undefined, error: errorMsg, loading: false });
        }
      });
  };

  haveEventPropsChanged(eventProps: EventProperties) {
    return !R.equals(this.props.eventProps, eventProps);
  }

  render() {
    const { result, error, loading } = this.state;
    const { context, uiFormProperties, eventProps } = this.props;
    const { entities, site, uiFormat } = context;

    if (loading) {
      return (
        <AlertLoading
          className="x-check-availability"
          message={`${_("Verifying availability")}...`}
        />
      );
    }

    if (error) {
      return <AlertWarning className="x-check-availability" message={error} />;
    }

    if (uiFormProperties && isEventAlreadyAdded(eventProps, uiFormProperties)) {
      return (
        <AlertWarning
          className="x-check-availability"
          message={_("The requested assignment is already present")}
        />
      );
    }

    if (!result) return null;

    const { message = "", details = [] } = result;

    if (details.length) {
      return (
        <AlertWarning className="x-check-availability" message={message}>
          <EventConflicts
            entities={entities}
            site={site.name}
            events={details}
            uiFormat={uiFormat}
          />
        </AlertWarning>
      );
    }

    return <AlertWarning className="x-check-availability" message={message} />;
  }
}
