import { isType } from "common/index";
import {
  SystemStringFk,
  SystemStringFkValue,
} from "common/types/system-strings";

export const isSystemStringFk = isType<SystemStringFkValue, SystemStringFk>([
  "id",
  "title",
]);
