import { Component } from "react";
import { Culture } from "common/culture/supported-cultures";
import { getStartOfToday } from "common/date-time/calculators";
import { getUtcNow } from "common/date-time/common";
import { getDateTimeFormatter } from "common/date-time/helpers/date-time-picker";
import { resolveDateDynamicValue } from "common/date-time/helpers/dynamic-values";
import { DateFormat, TimeFormat, UTCDateTime } from "common/date-time/types";
import { arrayToString } from "common/utils/array";
import { LazyReactDateTime } from "common/vendor-wrappers/react-datetime";
import {
  hasDateChanged,
  isAfterMaxDate,
  isBeforeMinDate,
} from "common/widgets/date/functions";
import { ButtonModal } from "common/widgets/modal/button-modal";
import { ValueProps } from "common/with-value-for";
import { TimePicker } from "../time-picker";

interface PropTypes extends ValueProps<UTCDateTime> {
  culture: Culture;
  dateFormat: DateFormat;
  timeFormat?: TimeFormat;
  minDate?: UTCDateTime;
  maxDate?: UTCDateTime;
  disabled?: boolean;
  allowClear?: boolean;
}

interface StateType {
  modalValue?: UTCDateTime;
}

export class DateBase extends Component<PropTypes, StateType> {
  static readonly displayName = "DateBase";
  state: StateType = { modalValue: undefined };

  componentDidUpdate(prevProps: PropTypes) {
    const { value, onChange, minDate, maxDate } = this.props;

    if (
      hasDateChanged(prevProps.minDate, minDate) &&
      isBeforeMinDate(minDate, value)
    ) {
      onChange(minDate);
      this.setState({ modalValue: undefined });
    } else if (
      hasDateChanged(prevProps.maxDate, maxDate) &&
      isAfterMaxDate(maxDate, value)
    ) {
      onChange(maxDate);
      this.setState({ modalValue: undefined });
    }
  }

  onTimeChange = (dateTime: UTCDateTime) => {
    this.setState({ modalValue: dateTime });
  };

  modalContent = () => {
    const { culture, dateFormat, timeFormat, value, minDate, maxDate } =
      this.props;
    const {
      modalValue = resolveDateDynamicValue(value, getUtcNow()) || value,
    } = this.state;
    const formatDateTime = getDateTimeFormatter(dateFormat, timeFormat);

    const datePicker = (
      <LazyReactDateTime
        key="datePicker"
        value={modalValue}
        culture={culture}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        onChange={this.onTimeChange}
      />
    );

    return (
      <>
        <div className="x-date-modal-title x-align-center x-padding-bottom-20">
          {formatDateTime(modalValue) || "-"}
        </div>
        {timeFormat ? (
          <div className="x-date-time-picker">
            {datePicker}
            <TimePicker
              value={modalValue || getStartOfToday()}
              timeFormat={timeFormat}
              onChange={this.onTimeChange}
            />
          </div>
        ) : (
          datePicker
        )}
      </>
    );
  };

  onOk = () => {
    this.props.onChange(this.state.modalValue);
    this.setState({ modalValue: undefined });
  };

  onCancel = () => this.setState({ modalValue: undefined });

  onReset = () => this.props.onChange(undefined);

  render() {
    const {
      dateFormat,
      timeFormat,
      disabled,
      value,
      allowClear = true,
    } = this.props;
    const { modalValue = value } = this.state;
    const showClearButton = value && allowClear;
    const formatDateTime = getDateTimeFormatter(dateFormat, timeFormat);

    const dateTimeButtonClasses = arrayToString([
      "x-input-addon-button",
      timeFormat ? "x-date-time-icon" : "x-date-icon",
    ]);
    const dateTimeButton = disabled ? undefined : (
      <div className={dateTimeButtonClasses}>
        <i className="fa fa-calendar-o" />
        {timeFormat && <i className="fa fa-clock-o" />}
      </div>
    );

    const input = (
      <input
        className="x-no-read-only-style"
        type="text"
        value={formatDateTime(value)}
        disabled={disabled}
        readOnly={true}
      />
    );

    const inputWithClear = showClearButton ? (
      <div className="x-date-base-input">
        {input}
        <div className="x-clear-button" onClick={this.onReset}>
          <i className="fa fa-close" />
        </div>
      </div>
    ) : undefined;

    const buttons = (
      <div className="x-date-base-button">
        {!showClearButton && input}
        {dateTimeButton}
      </div>
    );

    const baseClassName = arrayToString([
      "x-date-base",
      showClearButton ? "x-with-clear" : undefined,
    ]);

    const isValid = hasDateChanged(value, modalValue);

    return (
      <ButtonModal
        button={buttons}
        content={this.modalContent()}
        inputWithClear={inputWithClear}
        className={baseClassName}
        disabled={disabled}
        size={timeFormat ? undefined : "small"}
        title={timeFormat ? _("Choose Date and Time") : _("Choose Date")}
        allowFullscreen={false}
        onOk={isValid ? this.onOk : undefined}
        onCancel={this.onCancel}
      />
    );
  }
}
