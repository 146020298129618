import { Context } from "common/types/context";
import { Output } from "common/types/preferences";
import { defaultFor } from "common";
import { Entity } from "common/entities/types";
import { Query } from "common/query/types";
import { ValueProps } from "common/with-value-for";
import { FunctionalLocation } from "x/records/list/functional-location";

interface PropTypes extends ValueProps<Query> {
  context: Context;
  output: Output;
  entity: Entity;
}

export const BodyFilter = ({
  context,
  entity,
  output,
  value = defaultFor(),
  onChange,
}: PropTypes) => {
  switch (output) {
    case "FunctionalLocation":
      return (
        <FunctionalLocation
          context={context}
          entity={entity}
          onChange={onChange}
          value={value}
        />
      );
    default:
      return null;
  }
};
