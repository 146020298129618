import { ApiCall } from "common/types/api";
import { AddressApiResults } from "common/types/address-book";

export const addressBookApi = (apiCall: ApiCall) => ({
  get: (siteName: string) => (search: string, limit?: number) => {
    const params = [
      `searchWord=${search}`,
      limit ? `limit=${limit}` : undefined,
    ]
      .filter((p) => !!p)
      .join("&");
    return apiCall<AddressApiResults>(
      "get",
      `api/addressBook/${siteName}/search?${params}`,
    );
  },
});
