import { Component } from "react";
import { getByBehaviorArgument } from "common/entities";
import { Entity } from "common/entities/types";
import {
  FormValue,
  PoItemValue,
} from "common/record/actions/ui/purchase-order/return-items/types";
import { Context } from "common/types/context";
import { PoDetails } from "common/types/purchase-orders";
import { VerticalField } from "common/ui/field";
import { StringInput } from "common/widgets/input-with-submit/string";
import { ValueProps } from "common/with-value-for";
import { Header } from "../header";
import { PoList } from "./list";

export interface PropTypes extends ValueProps<FormValue> {
  context: Context;
  entity: Entity;
  details: PoDetails;
}

export class ReturnItemsForm extends Component<PropTypes> {
  onItemsChange = (purchaseOrderItems: PoItemValue[]) =>
    this.props.onChange({ ...this.props.value, purchaseOrderItems });

  onReasonChange = (reason: string) =>
    this.props.onChange({ ...this.props.value, reason });

  render() {
    const { context, value, entity, details } = this.props;
    const { entities } = context;
    const { purchaseOrderItems, reason } = value;

    return (
      <>
        <Header context={context} entity={entity} details={details} />
        <PoList
          context={context}
          entity={getByBehaviorArgument(
            entities,
            "PurchaseOrderItem",
            "purchaseOrderEntity",
            entity.name,
          )} // po items entity
          value={purchaseOrderItems}
          onChange={this.onItemsChange}
        />
        <VerticalField
          label={_("Reason")}
          input={
            <StringInput
              maxLength={256}
              value={reason}
              onChange={this.onReasonChange}
            />
          }
        />
      </>
    );
  }
}
