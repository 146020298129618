import { Context } from "common/types/context";
import { WorkOrderOpenedEvent } from "common/types/events";
import { DateTimeLabel } from "common/widgets/date/date-time-label";
import { getLocalizedColumnName } from "common/entities/entity-column/functions";
import { LabelWidget } from "common/form/widget/label-widget";
import { Entity } from "common/entities/types";
import { getColumn } from "common/entities";
import { Row } from "../row";
import { ReasonDetails } from "./reason";

interface PropTypes {
  event: WorkOrderOpenedEvent;
  context: Context;
  entity: Entity;
  hideLinks?: boolean;
}

export const WorkOrderOpened = ({
  event,
  context,
  entity,
  hideLinks,
}: PropTypes) => {
  const { uiFormat } = context;
  return (
    <div>
      <legend>{_("Details")}</legend>
      <Row label={_("Scheduled Start Date")}>
        <DateTimeLabel
          uiFormat={uiFormat}
          value={event.details.scheduledStartDate}
        />
      </Row>
      {!!event.details.startDate && (
        <Row label={_("Start Date")}>
          <DateTimeLabel uiFormat={uiFormat} value={event.details.startDate} />
        </Row>
      )}
      {event.details.assetId ? (
        <Row label={getLocalizedColumnName(entity, "assetId")}>
          <LabelWidget
            context={context}
            entityName={entity?.name}
            column={getColumn(entity, "assetId")}
            withLinks={true}
            value={event.details.assetId}
          />
        </Row>
      ) : undefined}
      <Row label={_("Username")}>{event.userName}</Row>
      <ReasonDetails
        reason={event.details.reason}
        context={context}
        hideLinks={hideLinks}
      />
    </div>
  );
};

WorkOrderOpened.displayName = "WorkOrderOpened";
