import { subtractDateDuration } from "common/date-time/calculators";
import { getUtcNow } from "common/date-time/common";
import { DateTimeFormat, TimeRange } from "common/date-time/types";
import { defaultFor } from "common";
import { TimeRangeName } from "common/record/form/content/measurements/time-range/types";
import { Context } from "common/types/context";
import { UiFormat } from "common/types/ui-format";

export const getTimeRangeFormat = (
  context: Context = defaultFor<Context>(),
  timeRange: TimeRangeName,
): DateTimeFormat => {
  const { uiFormat = defaultFor<UiFormat>() } = context;
  const { dateFormat, timeFormat } = uiFormat;
  switch (timeRange) {
    case "ONE_HOUR":
    case "TWELVE_HOUR":
    case "ONE_DAY":
      return timeFormat ? timeFormat : "";
    case "ONE_WEEK":
    case "ONE_MONTH":
    case "ALL":
    default:
      return dateFormat && timeFormat ? `${dateFormat} ${timeFormat}` : "";
  }
};

export const getTimeRangeTranslation = (timeRange: TimeRangeName) => {
  switch (timeRange) {
    case "ONE_HOUR":
      return _("1 Hour");
    case "TWELVE_HOUR":
      return _("12 Hours");
    case "ONE_DAY":
      return _("1 Day");
    case "ONE_WEEK":
      return _("1 Week");
    case "ONE_MONTH":
      return _("1 Month");
    case "ALL":
      return _("All");
    default:
      return "";
  }
};

export const getTimeRange = (timeRange: TimeRangeName): TimeRange => {
  const utcNow = getUtcNow();
  switch (timeRange) {
    case "ONE_HOUR":
      return {
        rangeFrom: subtractDateDuration(utcNow, 1, "hours"),
        rangeTo: utcNow,
      };
    case "TWELVE_HOUR":
      return {
        rangeFrom: subtractDateDuration(utcNow, 12, "hours"),
        rangeTo: utcNow,
      };
    case "ONE_DAY":
      return {
        rangeFrom: subtractDateDuration(utcNow, 1, "days"),
        rangeTo: utcNow,
      };
    case "ONE_WEEK":
      return {
        rangeFrom: subtractDateDuration(utcNow, 7, "days"),
        rangeTo: utcNow,
      };
    case "ONE_MONTH":
      return {
        rangeFrom: subtractDateDuration(utcNow, 1, "months"),
        rangeTo: utcNow,
      };
    case "ALL":
    default:
      return { rangeFrom: undefined, rangeTo: undefined };
  }
};
