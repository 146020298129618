import { Component } from "react";
import { getMediaUrl, mediaApi } from "common/api/media";
import { Context } from "common/types/context";
import { FileType } from "common/types/media";
import { Uploader } from "common/widgets/file-selector/uploader";
import { AcceptType } from "./types";

type OnUpload = (file: FileType) => any;

interface PropTypes {
  context: Context;
  entityName: string;
  recordId: string;
  onUpload: OnUpload;
  acceptedFileTypes?: AcceptType;
}

export class MediaUploadController extends Component<PropTypes> {
  upload = (file: FileType) => {
    const { context, onUpload, entityName, recordId } = this.props;
    const { apiCall, site } = context;

    return mediaApi(apiCall)
      .upload(entityName, recordId, file)
      .then((response: FileType) => {
        const { name } = response;
        onUpload({
          name,
          type: file.type,
          url: getMediaUrl(site.name, entityName, recordId, name),
        });
      });
  };

  render() {
    const { recordId, acceptedFileTypes = "all" } = this.props;
    return (
      <Uploader
        uploadFile={this.upload}
        isNewFile={!recordId}
        buttonAsIcon={false}
        acceptType={acceptedFileTypes}
      />
    );
  }
}
