import * as React from "react";
import { arrayToString } from "common/utils/array";
import { defaultFor } from "common";
import { Site } from "common/types/sites";
import { ValueComponent } from "common/with-value-for";
import { Tree } from "../tree";

export interface SiteSelectorValue {
  site: Site;
  open?: boolean;
}

interface PropTypes {
  sites: Site[];
  placeholder?: string;
  autoClose?: boolean;
  icon?: string;
}

const defaultValue = defaultFor<SiteSelectorValue>();

export class SiteTreeSelector extends ValueComponent<
  SiteSelectorValue,
  PropTypes
> {
  onSelectSite = (site: Site) => {
    const { autoClose, value } = this.props;
    this.setValue({
      site,
      open: value.open && !autoClose,
    });
  };

  onToggleOpen = () => {
    const { value = defaultValue } = this.props;
    this.mergeValue({ open: !value.open });
  };

  onClose = (e: React.FormEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
    this.mergeValue({ open: false });
  };

  render() {
    const {
      sites,
      placeholder,
      autoClose,
      children,
      icon = "fa fa-caret-down",
      value = defaultValue,
    } = this.props;
    const { site, open } = value;

    const className = arrayToString([
      "x-site-selector qa-site-selector",
      open ? "x-active" : undefined,
    ]);

    return (
      <div className={className}>
        <span onClick={this.onToggleOpen} className="btn">
          {site ? (
            <span className="x-site-selector-value">{site.label}</span>
          ) : (
            <span className="x-site-selector-placeholder">{placeholder}</span>
          )}
          <span className="x-site-selector-icon">
            <i className={icon} />
          </span>
        </span>
        {open ? (
          <div>
            {autoClose ? (
              <div className="modal-backdrop fade" onClick={this.onClose} />
            ) : undefined}
            <div className="x-site-selector-tree qa-site-selector-tree">
              {children}
              <Tree
                onClick={this.onSelectSite}
                nodes={sites}
                selected={site ? [site] : undefined}
              />
            </div>
          </div>
        ) : undefined}
      </div>
    );
  }
}
