import * as R from "ramda";
import { getSelectWithId } from "common/query/select";
import { Entity } from "common/entities/types";
import { Group, GroupColumn, Layout } from "common/form/types";
import { getIntFkId } from "common/functions/system-int";
import {
  MappedSystemIntFkOptions,
  translateProcedureStatus,
} from "common/functions/system-string-options";
import { mergeChain } from "common/merge";
import { QueryForEntity } from "common/query/types";
import { RelatedForm } from "common/record/types";
import { ForeignKey } from "common/types/foreign-key";
import { ProcedureTypes } from "common/types/procedure-type";
import { Properties, Record } from "common/types/records";
import { SystemIntFk } from "common/types/system-int";
import {
  COMPLETED_PROCEDURE_STATUS_ID,
  FAIL_PROCEDURE_STATUS_ID,
  METER_READING_PROVIDED_PROCEDURE_STATUS_ID,
  NOT_APPLICABLE_PROCEDURE_STATUS_ID,
  NO_PROCEDURE_STATUS_ID,
  PASS_PROCEDURE_STATUS_ID,
  URGENT_REPAIR_PROCEDURE_STATUS_ID,
  YES_PROCEDURE_STATUS_ID,
} from "common/types/system-strings";
import { RelatedValue } from "../types";

export const getQuery = (
  assetEntity: Entity,
  options: ForeignKey[],
  op: string,
): QueryForEntity => {
  const filteredOptions = options.filter((a) => a);
  return {
    entity: assetEntity.name,
    query: {
      ...assetEntity.query,
      select: getSelectWithId(assetEntity.query.select),
      filter:
        filteredOptions.length > 0
          ? {
              name: "id",
              op: op,
              value: filteredOptions.map((a) => a.id || a).join(","),
            }
          : undefined,
    },
  };
};

export const getProcedureTypeHint = (procedureTypeId: number) => {
  switch (procedureTypeId) {
    case ProcedureTypes.Completable:
      return _(
        "A technician will have the option to mark a procedure as Completed or leave the procedure Not Completed",
      );
    case ProcedureTypes.PassFailUrgentRepair:
      return _(
        "A technician will have the option to mark a procedure as Pass, Fail or Urgent Repair which will generate a follow-up Work Order",
      );
    case ProcedureTypes.YesNoNotApplicable:
      return _(
        "A technician will have the option to mark a procedure as Yes, No or Not Applicable which will not generate any follow-up Work Order",
      );
    case ProcedureTypes.MeterReading:
      return _(
        "A technician will be prompted to select an asset's meter and enter it's reading after saving Work Order record",
      );
    default:
      return _("Select a procedure type to proceed");
  }
};

export const getDependencies = (properties: Properties): Properties => ({
  procedureTypeId: properties?.procedureTypeId,
});

export const getRecordWithUrgentRepairDescription = (
  record: Record,
  entityName: string,
  procedureId: string,
) => {
  const recordDescription = record.properties?.description;
  const procedureProps = record.related[entityName].find(
    (procedure) => procedure.properties.id === procedureId,
  )?.properties;

  if (!procedureProps) return record;

  const { step: procedureStep, assetId: procedureAssetId } = procedureProps;
  const urgentRepairWorkOrderDescription = _(
    "[Urgent Repair] {PROCEDURE_STEP}",
  ).replace("{PROCEDURE_STEP}", procedureStep ?? recordDescription);

  return mergeChain(record)
    .prop("properties")
    .set("description", urgentRepairWorkOrderDescription)
    .set("assetId", procedureAssetId ?? record.properties.assetId)
    .output();
};

export const isProcedureValid = (relatedForm: RelatedForm) => {
  const procedureTypeId = getIntFkId(relatedForm.procedureTypeId);
  return (
    procedureTypeId !== ProcedureTypes.MeterReading || !!relatedForm.meterId
  );
};

export const PROCEDURE_TYPE_COLUMN_NAME = "procedureTypeId";
export const METER_TYPE_COLUMN_NAME = "meterId";

const meterIdColumn: GroupColumn = {
  columnName: METER_TYPE_COLUMN_NAME,
  highlighted: false,
  readOnly: false,
  required: true,
  disabled: false,
};

export const injectMeterTypeIntoLayout = (layout: Layout): Layout => {
  const newGroups: Group[] = layout.groups.reduce((acc: Group[], group) => {
    const { columns } = group;

    const includesMeterId = columns.some(
      (c) => c.columnName === meterIdColumn.columnName,
    );

    const procedureTypeIndex = columns.findIndex(
      (c) => c.columnName === PROCEDURE_TYPE_COLUMN_NAME,
    );

    const newGroup =
      procedureTypeIndex === -1 || includesMeterId
        ? group
        : {
            ...group,
            columns: R.insert(
              procedureTypeIndex + 1,
              meterIdColumn,
              group.columns,
            ),
          };

    return [...acc, newGroup];
  }, []);

  return {
    ...layout,
    groups: newGroups,
  };
};

export const isProcedureMeterReadingType = (
  value: RelatedValue,
  entityName: string,
) => {
  const form = value?.related?.partialForm?.[entityName];
  const procedureTypeId = form && getIntFkId(form?.procedureTypeId);
  return procedureTypeId === ProcedureTypes.MeterReading;
};

export const isUrgentRepairOption = (item: SystemIntFk) =>
  item.id === URGENT_REPAIR_PROCEDURE_STATUS_ID;

const getStatusesByProcedureType = (
  columnOptions: SystemIntFk[],
  properties: Properties,
): SystemIntFk[] => {
  const procedureTypeId = getIntFkId(properties?.procedureTypeId);

  switch (procedureTypeId) {
    case ProcedureTypes.Completable:
      return columnOptions.filter(
        (option) => option.id === COMPLETED_PROCEDURE_STATUS_ID,
      );

    case ProcedureTypes.PassFailUrgentRepair:
      return columnOptions.filter(
        (option) =>
          option.id === PASS_PROCEDURE_STATUS_ID ||
          option.id === FAIL_PROCEDURE_STATUS_ID ||
          option.id === URGENT_REPAIR_PROCEDURE_STATUS_ID,
      );

    case ProcedureTypes.YesNoNotApplicable:
      return columnOptions.filter(
        (option) =>
          option.id === YES_PROCEDURE_STATUS_ID ||
          option.id === NO_PROCEDURE_STATUS_ID ||
          option.id === NOT_APPLICABLE_PROCEDURE_STATUS_ID,
      );

    case ProcedureTypes.MeterReading:
      return columnOptions.filter(
        (option) => option.id === METER_READING_PROVIDED_PROCEDURE_STATUS_ID,
      );

    default:
      return columnOptions;
  }
};

export const getProcedureStatus = (
  columnOptions: SystemIntFk[],
  dependencies: Properties,
): MappedSystemIntFkOptions => ({
  options: getStatusesByProcedureType(columnOptions, dependencies),
  translate: (value?: SystemIntFk) => translateProcedureStatus(value?.title),
});
