import * as R from "ramda";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entities, Entity } from "common/entities/types";
import { isRestrictedColumn } from "common/form/functions/entity";
import { ActionSignature, GroupColumn } from "common/form/types";
import { findEntityByArgs } from "common/functions/entity";
import { AlertWarning } from "common/widgets/alert";

export const getUnselectedColumns = (
  entity: Entity,
  selectedNames: string[] = [],
) => {
  return entity
    ? entity.columns.filter(
        (c) =>
          !c.isSystem &&
          !isRestrictedColumn(entity, c.name) &&
          !R.includes(c.name, selectedNames),
      )
    : [];
};

export const getAssetMeterReadingEntity = (
  woEntity: Entity,
  entities: Entities,
) => {
  const assetEntityName = woEntity?.arguments?.assetEntity;
  const assetMeterEntity = findEntityByArgs(
    entities,
    "AssetMeter",
    "assetEntity",
    assetEntityName,
  );
  const assetMeteReadingEntity = findEntityByArgs(
    entities,
    "AssetMeterReading",
    "assetMeterEntity",
    assetMeterEntity?.name,
  );

  return assetMeteReadingEntity;
};

export const getProceduresEntity = (entity: Entity, entities: Entities) => {
  const proceduresEntity = findEntityByArgs(
    entities,
    "Procedure",
    "ownerEntity",
    entity?.name,
  );

  return proceduresEntity;
};

export const hasSystemColumnName = (
  columnNames: string[] = [],
  entityColumns: EntityColumn[] = [],
) =>
  R.any((ec) => R.includes(ec.name, columnNames) && ec.isSystem, entityColumns);

export const getWarning = (
  groupColumns: GroupColumn[] = [],
  entityColumns: EntityColumn[] = [],
) => {
  const warningMessage = _(
    "System fields are not allowed in this action form, please remove them.",
  );
  const groupColumnNames = groupColumns.map((gc) => gc.columnName);

  return hasSystemColumnName(groupColumnNames, entityColumns) ? (
    <AlertWarning message={warningMessage} />
  ) : undefined;
};

export const displaySignatureOption = (signature: ActionSignature) => {
  switch (signature) {
    case "required":
      return _("Signature is required");
    case "optional":
      return _("Include signature");
    case "disabled":
      return _("No signature");
    default:
      return signature;
  }
};
