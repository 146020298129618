import { FileType } from "common/types/media";
import { ErrorMatch } from "common/types/error";

const warningList: ErrorMatch[] = [
  {
    regex:
      /^The document is linked to the record with (.*) (\d*). To delete this document, remove the link from the (.*) fields.$/,
    translate: (error: string, match: RegExp) => {
      const [, columnName, recordNumber, fields] = match.exec(error);
      const linkMessage = _(
        "The document is linked to the record with {COLUMN_NAME} {RECORD_NUMBER}.",
      );

      const deleteMessage =
        fields.split(", ").length > 1
          ? _(
              "To delete this document, remove the link from the {FIELDS} fields.",
            )
          : _(
              "To delete this document, remove the link from the {FIELDS} field.",
            );

      return `${linkMessage} ${deleteMessage}`
        .replace("{COLUMN_NAME}", columnName)
        .replace("{RECORD_NUMBER}", recordNumber.toString())
        .replace("{FIELDS}", fields);
    },
  },
  {
    regex: /^The document is linked to one of the comments$/,
    translate: () => _("The document is linked to one of the comments"),
  },
  {
    regex: /^Unable to delete documents attached to closed (.*)$/,
    translate: (error: string, match: RegExp) => {
      const [, entityName] = match.exec(error);
      const message = _(
        "Unable to delete documents attached to closed {ENTITY}",
      ).replace("{ENTITY}", entityName);

      return message;
    },
  },
];

export const translateWarning = (warning: string, isUsed: boolean) => {
  if (!isUsed) return _("The document will be permanently deleted");

  const match = warningList.find((w) => warning.match(w.regex));
  return match ? match.translate(warning, match.regex) : undefined;
};

export const imageExtensions = [".bmp", ".gif", ".jpeg", ".jpg", ".png"];
export const inlineAudioExtensions = [".mp3", ".wav"];
export const inlineVideoExtensions = [
  ".mov",
  ".mp4",
  ".f4v",
  ".mkv",
  ".webm",
  ".m4v",
];
const otherVideoExtensions = [
  ".mpeg4",
  ".3gpp",
  ".avi",
  ".wmv",
  ".mpegps",
  ".flv",
];
const pdfExtensions = [".pdf"];
const powerpointExtensions = [
  ".ppt",
  ".pptx",
  ".pptm",
  ".pot",
  ".potx",
  ".potm",
];
const wordExtensions = [".doc", ".docx", ".docm", ".dotx", ".dotm", ".docb"];
const excelExtensions = [".xls", ".xltx", ".xltm", ".xlsm", ".xlsx"];
const compressedExtensions = [".zip", ".rar"];

export const hasMatchingExtension = (
  fileName: string,
  extensions: string[],
) => {
  const [extension] = fileName.toLowerCase().split(".").slice(-1);
  return extensions.includes(`.${extension}`);
};

export const getFileType = (file: FileType) => {
  const { type, name } = file;

  if (hasMatchingExtension(name, imageExtensions)) return "image";
  if (hasMatchingExtension(name, inlineVideoExtensions)) return "inlineVideo";
  if (hasMatchingExtension(name, inlineAudioExtensions)) return "inlineAudio";
  if (
    type.indexOf("video") === 0 ||
    hasMatchingExtension(name, otherVideoExtensions)
  )
    return "video";
  if (type.indexOf("audio") === 0) return "audio";
  if (type.indexOf("pdf") > -1 || hasMatchingExtension(name, pdfExtensions))
    return "pdf";
  if (hasMatchingExtension(name, powerpointExtensions)) return "powerpoint";
  if (hasMatchingExtension(name, wordExtensions)) return "word";
  if (hasMatchingExtension(name, excelExtensions)) return "excel";
  if (hasMatchingExtension(name, compressedExtensions)) return "compressed";

  return "file";
};

export const getSizeString = (size: number) => {
  if (size < 1024) return size + " B";
  const sizeInKb = size / 1024;
  if (sizeInKb < 1000) return sizeInKb.toFixed() + " KB";
  if (sizeInKb < 1000000) return (sizeInKb / 1000).toFixed(1) + " MB";
  return (sizeInKb / 1000000000).toFixed(1) + " GB";
};

export const fileIcons: { [index: string]: string } = {
  image: "fa-file-image-o",
  video: "fa-file-video-o",
  audio: "fa-file-audio-o",
  pdf: "fa-file-pdf-o",
  word: "fa-file-word-o",
  excel: "fa-file-excel-o",
  powerpoint: "fa-file-powerpoint-o",
  compressed: "fa-file-zip-o",
  file: "fa-file-o",
};

export const isViewableAudioFileType = (fileType: string): boolean =>
  fileType === "inlineAudio";

export const isViewableVideoFileType = (fileType: string): boolean =>
  fileType === "inlineVideo";

export const isImageFileType = (fileType: string): boolean =>
  fileType === "image";

export const isViewableFileType = (fileType: string): boolean =>
  isViewableVideoFileType(fileType) ||
  isViewableAudioFileType(fileType) ||
  fileType === "image";
