import * as R from "ramda";
import {
  RelatedFormDisplayType,
  RelatedFormDisplayTypes,
} from "common/types/related-form-display";

export const hasActions = R.includes<RelatedFormDisplayType>("actions");
export const hasClearable = R.includes<RelatedFormDisplayType>("clearable");
export const hasForm = R.includes<RelatedFormDisplayType>("form");
export const hasTab = R.includes<RelatedFormDisplayType>("tab");
export const hasTable = R.includes<RelatedFormDisplayType>("table");
export const hasTableOnly = R.equals<RelatedFormDisplayTypes>(["table"]);

export const shouldDisplayForm = (displayTypes: RelatedFormDisplayTypes) =>
  !displayTypes || hasForm(displayTypes);
