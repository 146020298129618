import { UTCDateTime } from "common/date-time/types";
import { HighlightProps, Label } from "../label";
import { displayDateOffset } from "./functions";

interface PropTypes extends HighlightProps {
  value: UTCDateTime;
}

export const DateOffsetLabel = ({ value, textToHighlight }: PropTypes) => (
  <Label value={displayDateOffset(value)} textToHighlight={textToHighlight} />
);
