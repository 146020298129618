import { getLocalizedName } from "common";
import { getColumn } from "common/entities";
import { getSubFKTitle } from "common/functions/foreign-key";
import { Section } from "common/record/form/content/detail/view/section-group";
import type { Context } from "common/types/context";
import type { ForeignKey } from "common/types/foreign-key";
import { AlertWarning } from "common/widgets/alert";
import { LinkFk } from "common/widgets/foreign-key/link-fk";
import { ImageSmall } from "common/widgets/image";

export interface PropTypes {
  context: Context;
  entityName: string;
  assetFk: ForeignKey;
}

export const AssetSection = (props: PropTypes) => {
  const { assetFk, context, entityName } = props;
  const { entities } = context;

  const assetColumn = getColumn(entities[entityName], "assetId");
  const assetSubtitle = getSubFKTitle(assetFk?.subtitle);
  const assetSubsubtitle = getSubFKTitle(assetFk?.subsubtitle);
  const message = _("The {ASSET} is not configured for this record").replace(
    "{ASSET}",
    getLocalizedName(assetColumn),
  );

  return (
    <Section className="x-scheduler-asset" title={_("Asset")}>
      {assetFk ? (
        <>
          <div className="x-scheduler-asset-left">
            <ImageSmall value={assetFk.image} allowZoom={true} />
          </div>

          <div className="x-scheduler-asset-right">
            <div className="x-margin-bottom-2 x-asset-title">
              <LinkFk context={context} column={assetColumn} value={assetFk} />
            </div>
            {assetSubtitle && (
              <div className="x-margin-bottom-2 x-asset-subtitle">
                {assetSubtitle}
              </div>
            )}
            {assetSubsubtitle && (
              <div className="x-asset-subsubtitle">{assetSubsubtitle}</div>
            )}
          </div>
        </>
      ) : (
        <AlertWarning message={message} />
      )}
    </Section>
  );
};
