import { Context } from "common/types/context";
import { defaultFor, getLocalizedName } from "common";
import { Properties } from "common/types/records";
import { getColumnLabel } from "common/entities";
import { Entity } from "common/entities/types";
import { RequiredField } from "common/form/ui";
import {
  RUNNING_METER_TYPE_ID,
  TRENDING_METER_TYPE_ID,
} from "common/types/system-strings";
import { AlertErrorTip } from "common/widgets/alert";
import { Float, Ufloat } from "common/widgets/number";
import { withValue, WithValue } from "common/with-value";
import { VerticalField } from "common/ui/field";
import { arrayToString } from "common/utils/array";

interface PropTypes {
  context: Context;
  entity: Entity;
  previousReading: number;
  meterTypeId: number;
  isValid: boolean;
  maxReadingPerDay: number;
  isRequired?: boolean;
}

const defaultValue = defaultFor<Properties>();

const ReadingComp = ({
  context,
  entity,
  previousReading,
  meterTypeId,
  isValid,
  maxReadingPerDay,
  value = defaultValue,
  isRequired = false,
  onChangeMergeValue,
}: PropTypes & WithValue<Properties>) => {
  const { entities } = context;

  const assetMeterEntity = entities[entity.arguments.assetMeterEntity];
  const entityLocalizedName = getLocalizedName(assetMeterEntity);

  const valid = !value.value || !value.assetMeterId || isValid;
  const isRunningMeter = meterTypeId === RUNNING_METER_TYPE_ID;
  const maxValue = maxReadingPerDay + previousReading;
  const runningMessage =
    isRunningMeter && maxReadingPerDay
      ? ` ${_("and lower than maximum daily reading:")} ${maxValue}`
      : "";

  const message = `${_(
    "The reading has to be higher than the current value in ENTITY:",
  ).replace("ENTITY", entityLocalizedName)} ${
    previousReading || 0
  }${runningMessage}`;

  const label = getColumnLabel(entity, "value");
  const classNames = arrayToString([
    "qa-asset-meter-reading-value",
    valid ? "" : "x-has-error",
  ]);

  const content = (
    <>
      {meterTypeId === TRENDING_METER_TYPE_ID ? (
        <Float value={value.value} onChange={onChangeMergeValue("value")} />
      ) : (
        <Ufloat value={value.value} onChange={onChangeMergeValue("value")} />
      )}
      {!valid && <AlertErrorTip message={message} />}
    </>
  );

  return isRequired ? (
    <RequiredField label={label} className={classNames} value={value.value}>
      {content}
    </RequiredField>
  ) : (
    <VerticalField label={label} className={classNames} input={content} />
  );
};

export const Reading = withValue<Properties, PropTypes>(ReadingComp, "Reading");
