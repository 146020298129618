import { Component } from "common/component";
import { ApiErrorResponse } from "common/types/error";
import { CancellablePromise } from "common/types/promises";
import { ApiError } from "common/ui/api-error";
import { ActionButton, CancelButton } from "common/ui/buttons";
import { LoadingIcon } from "common/widgets/loading-icon";

interface PropTypes {
  message: string;
  recordId: string;
  onCancel: () => void;
  onSet: (id: string) => CancellablePromise<void>;
  onComplete: () => void;
}

interface StateType {
  loading: boolean;
  error: ApiErrorResponse;
}

export class SetRecordAs extends Component<PropTypes, StateType> {
  static readonly displayName = "SetRecordAs";
  state: StateType = {
    loading: false,
    error: undefined,
  };

  onOk = () => {
    const { recordId, onSet, onComplete } = this.props;
    this.setState({ loading: true });
    onSet(recordId)
      .then(onComplete)
      .catch((error) => this.setState({ loading: false, error }));
  };

  render() {
    const { message, onCancel } = this.props;
    const { loading, error } = this.state;
    return (
      <div className="x-padding-15 qa-set-record-as">
        {loading && <LoadingIcon />}
        {error && <ApiError error={error} className="x-margin-bottom-20-i" />}
        <div className="x-flex">
          <div className="x-flex-grow-1">{message}</div>
          <CancelButton className="x-margin-left-10" onClick={onCancel} />
          <ActionButton className="x-margin-left-10" onClick={this.onOk} />
        </div>
      </div>
    );
  }
}
