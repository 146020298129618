import * as R from "ramda";
import { eventsApi } from "common/api/scheduled-event";
import { ApiCall } from "common/types/api";
import { Component } from "common/component";
import { Entities, Entity } from "common/entities/types";
import { getSubFKTitle } from "common/functions/foreign-key";
import {
  frequencyInRange,
  getEventAssets,
} from "common/scheduled-event/functions";
import {
  canPreviewProjections,
  validateProjectionLength,
} from "common/scheduled-event/validations";
import { Projection, ScheduledEventRecord } from "common/types/scheduled-event";
import { ApiError } from "common/ui/api-error";
import { ModifyButtonLarge } from "common/ui/buttons";
import { FullPageModal } from "common/widgets/full-page-modal";
import { Grid } from "./grid";
import { ProjectionWarning } from "./projection-warning";

interface PropTypes {
  apiCall: ApiCall;
  entities: Entities;
  entity: Entity;
  scheduledEvent: ScheduledEventRecord;
}

interface StateType {
  error?: any;
  loading?: boolean;
  projections?: Projection[];
}

export class DataHandler extends Component<PropTypes, StateType> {
  state: StateType = {
    error: undefined,
    loading: undefined,
    projections: undefined,
  };

  getProjectionsData = () => {
    const { apiCall, entity, scheduledEvent } = this.props;
    this.setState({ projections: undefined, error: undefined, loading: true });
    eventsApi(apiCall)
      .getProjections(entity.name, scheduledEvent)
      .then((projections) => this.setState({ projections, loading: false }))
      .catch((error) => this.setState({ error, loading: false }));
  };

  getContent = () => {
    const { entities, entity, scheduledEvent } = this.props;
    const { projections } = this.state;

    if (!projections) {
      return undefined;
    }

    return (
      <Grid
        projections={projections}
        relatedRecords={R.fromPairs(
          getEventAssets(entities, entity, scheduledEvent).map(
            (record): [string, string] => [
              record.properties.assetId.id,
              getSubFKTitle(record.properties.assetId.title),
            ],
          ),
        )}
      />
    );
  };

  onCloseButtonClick = () => {
    this.setState({ projections: undefined });
  };

  render() {
    const { projections, loading, error } = this.state;
    const { entities, entity, scheduledEvent } = this.props;
    const valid = canPreviewProjections(entities, entity, scheduledEvent);
    const isFrequencyOk = frequencyInRange(scheduledEvent);
    const isProjectionLengthOk = validateProjectionLength(
      scheduledEvent.properties.projectionLength,
    );

    return (
      <div className={`x-pm-projections ${projections ? "x-active" : ""}`}>
        <div className="x-pm-projections-actions">
          {isFrequencyOk ? (
            !valid ? (
              <ProjectionWarning
                message={_(
                  "Please fix the issues before generating the projections",
                )}
              />
            ) : undefined
          ) : (
            <ProjectionWarning
              message={_(
                "Preview is only available for Calendar Frequency Scheduled Work Orders",
              )}
              isInfoWarning={true}
            />
          )}
          <ModifyButtonLarge
            className="x-pm-projections-preview qa-generate-preview"
            title={_("Projections preview")}
            disabled={!valid || !isFrequencyOk || !isProjectionLengthOk}
            onClick={this.getProjectionsData}
          >
            {loading ? <i className="fa fa-spinner fa-spin" /> : undefined}
            {_("Projections preview")}
          </ModifyButtonLarge>
        </div>

        {projections ? (
          <FullPageModal
            title={_("Projections preview")}
            message={_("Preview of up to 100 projections or 24 months.")}
            closeButtonClick={this.onCloseButtonClick}
            closeButtonTitle={_("Close preview")}
          >
            {this.getContent()}
            <ApiError error={error} />
          </FullPageModal>
        ) : undefined}
      </div>
    );
  }
}
