import { Component } from "react";
import * as R from "ramda";
import { Properties } from "common/types/records";
import { recordsApi } from "common/api/records";
import { canDo, getByBehaviorArgument } from "common/entities";
import { Entity } from "common/entities/types";
import { Query } from "common/query/types";
import { getGroupForSelect } from "common/query/select";
import { Reload } from "common/record/types";
import { AlertWarning } from "common/widgets/alert";
import { ValueProps } from "common/with-value-for";
import { ActionWithContent } from "common/query/table/types";
import { StandardRelated } from "../standard-related";
import { PropTypes as TablePropTypes } from "../table-with-form/types";
import { RelatedValue } from "../types";
import { SetRecordAs } from "../common/set-record-as";
import { getRelatedRecordIds } from "../common/functions";
import { unwrapWithDefault } from "../functions";
import { StockController } from "./controller";
import { TransferStock } from "./transfer-controller";
import { updateEntity, updateDefaultForm } from "./functions";

interface PropTypes extends TablePropTypes {
  reload: Reload;
}
type Props = PropTypes & ValueProps<RelatedValue>;

const addOnHand = (batchEntity: Entity, query: Query): Query => {
  const group = getGroupForSelect(query.select);

  const select = query.select.concat([
    {
      name: "quantity",
      alias: "onHand",
      path: `/${batchEntity.name}.stockId`,
      fn: "COUNT",
      label: _("On Hand"),
    },
  ]);

  const joins = (query.joins || []).concat([
    {
      entity: batchEntity.name,
      column: "stockId",
    },
  ]);

  return R.mergeRight(query, {
    select,
    joins,
    group,
  });
};

export class RecordStock extends Component<Props> {
  static readonly displayName = "RecordStock";

  displayAdjust = (stock: Properties, close: () => void) => {
    const { context, entity, value, reload } = this.props;
    return (
      <StockController
        context={context}
        onAdjusted={reload}
        onCancel={close}
        entity={entity}
        onHand={stock.onHand}
        recordId={stock.id}
        hasBatchSelection={value.record.properties.manualBatchSelection}
      />
    );
  };

  displayTransfer = (
    stock: Properties,
    close: () => void,
    stocks: Properties[],
  ) => {
    const { context, entity, value, reload } = this.props;
    return (
      <TransferStock
        context={context}
        onTransferred={reload}
        onCancel={close}
        entity={entity}
        fromStock={stock}
        destinations={R.reject((s) => s.id === stock.id, stocks)}
        hasBatchSelection={value.record.properties.manualBatchSelection}
      />
    );
  };

  displaySetDefaultLocation = (properties: Properties, close: () => void) => {
    const { reload } = this.props;
    return (
      <SetRecordAs
        message={_("Set as the default location?")}
        recordId={properties.id}
        onCancel={close}
        onSet={this.onSetDefaultLocation}
        onComplete={reload}
      />
    );
  };

  isDefaultLocation = (properties: Properties) => !!properties.isDefault;

  isPartAdjustmentDisabled = () => {
    return !!this.props.value.record.properties.disablePartAdjustment;
  };

  onSetDefaultLocation = (id: string) => {
    const { context, entity } = this.props;
    return recordsApi(context.apiCall).setDefaultLocation(entity.name, id);
  };

  onChangeSetValue = (newValue: RelatedValue) => {
    const { onChange, entity } = this.props;
    const fixedValue = unwrapWithDefault(newValue, entity.name);
    onChange(fixedValue);
  };

  render() {
    const { entity, query, actions, context, value, defaultForm } = this.props;
    const canSetDefault = canDo(entity, "SetDefault");

    const actionsWithContent: ActionWithContent[] = [
      ...(canSetDefault
        ? [
            {
              name: "SetDefault",
              fn: this.displaySetDefaultLocation,
              isDisabled: this.isDefaultLocation,
            },
          ]
        : []),
      {
        name: "Adjust",
        fn: this.displayAdjust,
        isDisabled: this.isPartAdjustmentDisabled,
      },
      {
        name: "Transfer",
        fn: this.displayTransfer,
        isDisabled: this.isPartAdjustmentDisabled,
      },
    ];

    const batchBehaviorEntity = getByBehaviorArgument(
      context.entities,
      "Batch",
      "stockEntity",
      entity.name,
    );

    if (!batchBehaviorEntity) {
      return (
        <AlertWarning message={_("Missing permission for Batch Entity.")} />
      );
    }

    const ids = getRelatedRecordIds(entity.name, "locationId", value);

    return (
      <StandardRelated
        {...this.props}
        entity={updateEntity(entity)}
        defaultForm={updateDefaultForm(defaultForm, !ids.length)}
        query={addOnHand(batchBehaviorEntity, query)}
        actions={actions}
        actionsWithContent={actionsWithContent}
        onChange={this.onChangeSetValue}
      />
    );
  }
}
