import { Culture } from "common/culture/supported-cultures";
import { DateFormat, TimeFormat } from "common/date-time/types";

interface BaseUiFormat {
  culture: Culture;
  dateFormat: DateFormat;
  timeFormat: TimeFormat;
  decimalSeparator: string;
  displayTimeZoneOnSingleSiteRecords: boolean;
}

export interface UiFormat extends BaseUiFormat {
  displayTimezone?: boolean;
}

export const defaultUiFormat: UiFormat = {
  culture: "en-US",
  dateFormat: "MM-dd-yyyy",
  timeFormat: "HH:mm:ss",
  decimalSeparator: ".",
  displayTimeZoneOnSingleSiteRecords: false,
};
