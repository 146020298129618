import { ApiCall, Crud } from "common/types/api";
import { CurrencyRate } from "common/types/currencies";
import { CancellablePromise } from "common/types/promises";
import {
  messages,
  returnAndNotify,
  withNotifications,
} from "common/api/with-notifications";

const getMessages = messages(() => ({
  created: _("The currency was successfully created"),
  updated: _("The currency was successfully updated"),
  removed: _("The currency was successfully deleted"),
}));

interface Extra {
  createMany: (xs: CurrencyRate[]) => CancellablePromise<any>;
  listLatest: (name: string) => CancellablePromise<CurrencyRate[]>;
}

export const currenciesApi = withNotifications(
  getMessages,
  (apiCall: ApiCall): Crud<string, CurrencyRate> & Extra => ({
    get: (name: string) =>
      apiCall("get", `api/admin/currencies/${name}/rate-history`),
    list: () => apiCall("get", "api/admin/currencies/current-rates"),
    listLatest: (name: string) =>
      apiCall("get", `api/admin/currencies/${name}/latest`),
    create(currencyRate: CurrencyRate) {
      return this.createMany([currencyRate]);
    },
    createMany: (currencyRates: CurrencyRate[]) =>
      apiCall("post", `api/admin/currencies/current-rates`, currencyRates).then(
        returnAndNotify(_("Currencies successfully saved")),
      ),
    update: () => CancellablePromise.reject("Cannot update a currency rate"),
    remove: () => CancellablePromise.reject("Cannot remove a currency rate"),
  }),
);
