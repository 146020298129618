import { PaginationValue } from "common/query/types";
import { ApiCall } from "common/types/api";
import {
  SearchSummary,
  SearchRecord,
} from "x/layout/header/global-search/types";

const RESULTS_PER_PAGE = 15;

export const globalSearchApi = (apiCall: ApiCall) => ({
  searchSummary: (searchWord: string) =>
    apiCall<SearchSummary[]>("post", "api/global-search/:site/summary", {
      searchWord: searchWord,
    }),
  searchListByEntity: (
    searchWord: string,
    entityName: string,
    page: number,
    pageSize: number = RESULTS_PER_PAGE,
  ) =>
    apiCall<PaginationValue<SearchRecord>>(
      "post",
      `api/global-search/:site/${entityName}`,
      { searchWord, page, pageSize },
    ),
});
