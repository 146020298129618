import * as R from "ramda";
import { Context } from "common/types/context";
import { WorkOrderCompletedEvent } from "common/types/events";
import { Entity } from "common/entities/types";
import { collapseReferenceFks } from "common/functions/foreign-key";
import { EmptyDetails } from "./empty";
import { ReasonDetails } from "./reason";
import { PropertiesPayload } from "./payload/properties";

interface PropTypes {
  event: WorkOrderCompletedEvent;
  entity: Entity;
  context: Context;
  hideLinks?: boolean;
}

export const WorkOrderCompleted = ({
  event,
  entity,
  context,
  hideLinks,
}: PropTypes) => (
  <div>
    <PropertiesPayload
      context={context}
      entity={entity}
      showEmpty={true}
      title={_("Updated record fields")}
      properties={event?.details?.record?.properties}
    />
    {!R.isNil(event?.details?.record?.properties) &&
    !R.isEmpty(event?.details?.record?.properties) ? (
      <hr />
    ) : undefined}
    <PropertiesPayload
      context={context}
      entity={entity}
      showEmpty={true}
      title={_("Previous record fields")}
      properties={collapseReferenceFks(
        event?.details?.oldProperties,
        context.entities,
      )}
    />
    {!R.isNil(event?.details?.oldProperties) &&
    !R.isEmpty(event?.details?.oldProperties) ? (
      <hr />
    ) : undefined}
    <EmptyDetails event={event} uiFormat={context.uiFormat} />
    <ReasonDetails
      reason={event.details.reason}
      context={context}
      hideLinks={hideLinks}
    />
  </div>
);

WorkOrderCompleted.displayName = "WorkOrderCompleted";
