import { withDebounce } from "common/with-debounce";
import { withValue, WithValue } from "common/with-value";
import { float, int, ufloat, uint, isDecimal } from "common/validate";
import { decimalPlaceholder } from "common/utils/decimal";
import { InputWithSubmit, OnSubmit } from "../input-with-submit";
import { NumberValue as Value, ValidationFn } from "./types";
import {
  replaceSeparator,
  fromDisplay,
  getValidationWarning,
  getDecimalScaleWarning,
  getRangeWarning,
  isShorter,
  checkRange,
} from "./functions";

interface PropTypes {
  minValue?: number;
  maxValue?: number;
  onSubmit?: OnSubmit;
  submitOnBlur?: boolean;
  disabled?: boolean;
  placeholder?: string;
  decimalSeparator?: string;
  decimalScale?: number;
  className?: string;
  inputId?: string;
  propagateInvalidValue?: boolean;
  label?: string;
}

type Props = PropTypes & WithValue<Value>;

const Input = (fn: ValidationFn, name: string) => {
  const comp = ({
    onSubmit,
    submitOnBlur,
    disabled,
    minValue,
    maxValue,
    placeholder,
    decimalSeparator,
    decimalScale,
    className = "",
    value,
    inputId,
    label,
    setValue,
    propagateInvalidValue,
  }: Props) => {
    const isValid = (v: Value) =>
      fn(v) &&
      (!decimalScale || isDecimal(decimalScale, v)) &&
      checkRange(minValue, maxValue, v);

    const getWarningMessage = (value: Value) =>
      getValidationWarning(value, fn) ||
      getDecimalScaleWarning(value, decimalScale) ||
      getRangeWarning(value, minValue, maxValue);

    const onChange = (displayValue: Value) => {
      const newValue = fromDisplay(displayValue, decimalSeparator);

      if (propagateInvalidValue) return setValue(newValue);

      const canUpdate =
        isValid(newValue) || (!isValid(value) && isShorter(value, newValue));

      return canUpdate && setValue(newValue);
    };

    const inputPlaceholder =
      placeholder || decimalPlaceholder(decimalSeparator, decimalScale);

    return (
      <InputWithSubmit
        type="text"
        className={className}
        value={replaceSeparator(value, decimalSeparator)}
        inputId={inputId}
        submitOnBlur={submitOnBlur ?? true}
        placeholder={inputPlaceholder}
        label={label}
        hasError={!isValid(value)}
        disabled={disabled}
        onChange={onChange}
        onSubmit={onSubmit}
        autoComplete="off"
        errorMessage={getWarningMessage(value)}
      />
    );
  };

  return withValue<Value, PropTypes>(comp, name);
};

const BaseFloat = Input(float, "Float");
const BaseInt = Input(int, "Int");
const BaseUfloat = Input(ufloat, "Ufloat");
const BaseUint = Input(uint, "Uint");

export const Float = withDebounce(BaseFloat);
export const Int = withDebounce(BaseInt);
export const Ufloat = withDebounce(BaseUfloat);
export const Uint = withDebounce(BaseUint);
