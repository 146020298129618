interface PropTypes {
  message: string;
}

export const InventoryUpdated = ({ message }: PropTypes) => {
  return (
    <div className="x-inventory-updated qa-inventory-updated">
      <legend>{_("Details")}</legend>
      <div className="x-inventory-message">{message}</div>
    </div>
  );
};

InventoryUpdated.displayName = "InventoryUpdated";
