import { schedulerSettingsApi } from "common/api/scheduler-settings";
import { searchApi } from "common/api/search";
import type { Entity } from "common/entities/types";
import type { Context } from "common/types/context";
import type { SchedulerSettingsType } from "common/types/scheduler-settings";
import type { SchedulerApiData, UserContact } from "x/scheduler2/types";
import {
  calendarToOption,
  fkToOption,
} from "common/widgets/list-with-select/functions";
import { getExpandFkQueryWithoutDeleted } from "common/widgets/foreign-key/functions";
import { ForeignKey } from "common/types/foreign-key";
import { getColumn } from "common/entities";
import { CancellablePromise } from "common/types/promises";
import { ListWithSelectItem } from "common/widgets/list-with-select/types";

const contactQuery = (entity: Entity, userName: string) => ({
  entity: entity.name,
  query: {
    ...entity.query,
    select: [{ name: "id" }, { name: "email" }],
    filter: {
      and: [
        { name: "isDeleted", op: "isfalse" },
        { name: "email", op: "eq", value: userName },
      ],
    },
  },
});

const mapItemOrderWithLabels = (
  itemOrder: ListWithSelectItem[] = [],
  options: ListWithSelectItem[] = [],
): ListWithSelectItem[] => {
  const optionsLookup: { [key: string]: string } = {};
  options.forEach((option) => {
    optionsLookup[option.id] = option.label || "";
  });

  return itemOrder.map((item) => {
    const label = optionsLookup[item.id];
    if (label) item.label = label;
    return item;
  });
};

const fetchGroupByOptions = (
  context: Context,
  schedulerSettings: SchedulerSettingsType,
) => {
  const { apiCall, entities, calendars } = context;
  const { entityName, groupBy } = schedulerSettings ?? {};

  const column = getColumn(entities[entityName], groupBy);
  const groupByEntity = entities[column?.relatedEntity];
  const query = getExpandFkQueryWithoutDeleted(groupByEntity);

  if (groupBy === "calendarId") {
    return CancellablePromise.resolve(calendars.map(calendarToOption));
  }

  return searchApi(apiCall)
    .runQuery(query)
    .then((result: ForeignKey[]) => result.map(fkToOption));
};

export const getSchedulerApiData = (context: Context) => {
  const { apiCall, entities, userName } = context;

  return schedulerSettingsApi(apiCall)
    .get()
    .then((schedulerSettings: SchedulerSettingsType) => {
      const { entityName, itemOrder, groupBy } = schedulerSettings ?? {};

      if (!entityName) return { schedulerSettings, userContact: undefined };

      const userContactFetch = searchApi(apiCall).runQuery(
        contactQuery(entities[entityName], userName),
      ) as CancellablePromise<UserContact[]>;

      // Adds label for itemOrder as label is dynamic and are not stored
      const groupByOptionsFetch: CancellablePromise<ListWithSelectItem[]> =
        groupBy
          ? fetchGroupByOptions(context, schedulerSettings)
          : CancellablePromise.resolve([]);

      return CancellablePromise.all([
        userContactFetch,
        groupByOptionsFetch,
      ]).then(([userContact, options]) => ({
        schedulerSettings: groupBy
          ? {
              ...schedulerSettings,
              itemOrder: mapItemOrderWithLabels(itemOrder, options),
            }
          : schedulerSettings,
        userContact: userContact[0],
      }));
    });
};

export const dependenciesInjected: SchedulerApiData = {
  schedulerApiData: {
    schedulerSettings: {
      entityName: undefined,
      groupBy: undefined,
    },
    userContact: undefined,
  },
};
