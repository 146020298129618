import * as React from "react";
import { Provider } from "react-redux";
import { Route, RouteComponentProps, withRouter } from "react-router";
import { HashRouter } from "react-router-dom";
import { store } from "common/app/redux";
import { render } from "./render";

export interface RouteProps extends RouteComponentProps<void> {
  route: Route;
}

export const splitPath = (path: string) =>
  (path || "")
    .replace(/^\//, "")
    .split("/")
    .filter((s) => !!s);

export const splitLocation = (path: string) => {
  const [page, id, ...extra] = path.split("/").filter((p: string) => !!p);
  return { page, id, extra };
};

export const getIdProps = (id: string) => {
  const isNew = id === "new";
  const stringId = isNew ? undefined : id;
  const numberId = parseInt(id, 10) || undefined;
  return { isNew, stringId, numberId };
};

export function renderWithRouter(ctrl: React.ComponentClass<RouteProps>) {
  return render(
    <Provider store={store}>
      <HashRouter hashType="slash">
        <Route path="*" component={withRouter(ctrl)} />
      </HashRouter>
    </Provider>,
  );
}
