import { Properties } from "common/types/records";
import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";
import { shouldDisplayForm } from "common/functions/related-form-display";
import { RelatedFormDisplayTypes } from "common/types/related-form-display";

interface DisabledItemPayload {
  reason: string;
  className?: string;
}

export const areChargesReadOnly = (
  mainEntity: Entity,
  related: Entity,
  properties: Properties,
  displayTypes?: RelatedFormDisplayTypes,
) =>
  behaveAs("WorkOrder", mainEntity) &&
  (behaveAs("LaborCharge", related) || behaveAs("PartCharge", related)) &&
  (!shouldDisplayForm(displayTypes) ||
    (properties.status !== "O" && properties.status !== "C"));

export const shouldDisablePOItems = (
  mainEntity: Entity,
  related: Entity,
  properties: Properties,
): DisabledItemPayload =>
  behaveAs("PurchaseOrder", mainEntity) &&
  behaveAs("PurchaseOrderItem", related) &&
  !properties.supplierId
    ? {
        reason: _("{LABEL} are disabled for PO without chosen supplier"),
        className: "x-item-disabled",
      }
    : undefined;

export const shouldDisableRelatedEntity = (
  mainEntity: Entity,
  related: Entity,
  properties: Properties,
) => shouldDisablePOItems(mainEntity, related, properties);

export const isRelatedEntityReadonly = (
  mainEntity: Entity,
  related: Entity,
  properties: Properties,
) =>
  areChargesReadOnly(mainEntity, related, properties) ||
  !!shouldDisablePOItems(mainEntity, related, properties);
