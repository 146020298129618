import { TableConfigWithMerge } from "common/data/types";
import { FancyCheckboxLarge } from "common/widgets/fancy-checkbox";
import { withValue, WithValue } from "common/with-value";

interface PropTypes {
  showMergeOption: boolean;
}

const OutputConfigTableComp = ({
  showMergeOption,
  value,
  onChangeMergeValue,
}: PropTypes & WithValue<TableConfigWithMerge>) => (
  <div className="x-table-output table-output">
    {showMergeOption ? (
      <FancyCheckboxLarge
        label={_("Group sites")}
        onChange={onChangeMergeValue("mergeSites")}
        value={value.mergeSites}
      />
    ) : undefined}
    <FancyCheckboxLarge
      label={_("Allow filter")}
      onChange={onChangeMergeValue("allowFilter")}
      value={value.allowFilter}
    />
  </div>
);

export const OutputConfigTable = withValue<TableConfigWithMerge, PropTypes>(
  OutputConfigTableComp,
  "OutputConfigTable",
);
