import * as R from "ramda";
import { Properties } from "common/types/records";
import { getFkId } from "common/functions/foreign-key";
import { keysToNotParse, QueryString, queryStringKeys } from "common/types/url";
import { arrayToString } from "common/utils/array";

export interface StringObject {
  [key: string]: string;
}

export const makeUrlString = (obj: StringObject) => {
  const stringArray = R.keys(obj).reduce((acc: string[], key: string) => {
    return obj[key] ? acc.concat(`${key}=${obj[key]}`) : acc;
  }, []);
  const qs = arrayToString(stringArray, "&");
  return qs.length ? `?${qs}` : "";
};

const flattenProperties = (properties: Properties) =>
  R.mapObjIndexed((value) => getFkId(value), properties);

export const createQueryString = (queryString: QueryString) => {
  const qsProps = R.mapObjIndexed(
    (value: QueryString[keyof QueryString], key: keyof QueryString) => {
      if (R.isNil(value) || R.isEmpty(value)) return undefined;
      const flattenedValue =
        key === "defaultProperties"
          ? flattenProperties(value as Properties)
          : value;
      const stringValue =
        typeof flattenedValue !== "string"
          ? JSON.stringify(flattenedValue)
          : flattenedValue;
      return encodeURIComponent(stringValue);
    },
    queryString,
  );

  return makeUrlString(qsProps);
};

const isQueryStringKey = (key: string): key is keyof QueryString =>
  R.includes(key, queryStringKeys);

const tryParseJSON = (value: string) => {
  try {
    return JSON.parse(value);
  } catch {
    // Swallows error. Strings will return as strings.
    // boolean and int will be casted properly.
    return value;
  }
};

export const parseQueryString = (search: string): QueryString => {
  const searchParams = new URLSearchParams(search);

  return Array.from(searchParams.keys()).reduce<QueryString>((acc, key) => {
    if (!isQueryStringKey(key)) return acc;

    const rawValue = searchParams.get(key);
    const value = keysToNotParse.includes(key)
      ? rawValue
      : tryParseJSON(rawValue);

    return { ...acc, [key]: value };
  }, {});
};

export const getQueryString = (location: Location): QueryString =>
  parseQueryString(location?.search);
