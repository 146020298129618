import * as R from "ramda";
import { getLocalizedName } from "common";
import { hasBehavior } from "common/api/behavior";
import { returnAndNotify } from "common/api/with-notifications";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { Crud } from "common/types/api";
import { Context } from "common/types/context";
import { CancellablePromise } from "common/types/promises";

interface Behavior {
  name: string;
  arguments: string[];
}

type EntitiesApiType = Crud<string, Entity> & {
  behaviors: () => CancellablePromise<Behavior[]>;
};

export const isReferenceEntity = (entity: Entity): boolean =>
  !!(entity?.behaviors && hasBehavior(entity.behaviors, "Reference"));

export const displayEntity = (e: Entity) =>
  getLocalizedName(e) || (e && e.name) || "";

export const displayEntityColumn = (c: EntityColumn) =>
  getLocalizedName(c) || (c && c.name) || "";

export const omitIsSystemFromColumns = (columns: EntityColumn[] = []) =>
  columns.map((c) => R.omit(["isSystem"], c));

export const getEntityWithoutIsSystemInColumns = (entity: Entity): Entity => ({
  ...entity,
  columns: omitIsSystemFromColumns(entity.columns),
});

export const entitiesApi = (context: Context): EntitiesApiType => ({
  get: (name: string) => context.apiCall("get", `api/admin/entities/${name}`),
  list: () => context.apiCall("get", `api/admin/entities`),
  create: (entity: Entity) =>
    context
      .apiCall("post", "api/admin/entities", entity)
      .then(() => ({ id: entity.name }))
      .then(returnAndNotify(_("The entity was successfully created"))),
  update: (entity: Entity) =>
    context
      .apiCall(
        "put",
        `api/admin/entities`,
        getEntityWithoutIsSystemInColumns(entity),
      )
      .then(returnAndNotify(_("The entity was successfully updated"))),
  remove: (name: string) =>
    context
      .apiCall("delete", `api/admin/entities/${name}`)
      .then(returnAndNotify(_("The entity was successfully deleted"))),
  behaviors: () => context.apiCall("get", `api/admin/behaviors`),
});
