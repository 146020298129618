import * as R from "ramda";
import * as React from "react";
import { getCached, getCachedStringPrimitive } from "common/cache";
import { Primitive } from "common/types/primitives";

// TODO: Review this implementation. This approach vs shallow equal or some
//       other pattern for preventing needless component updates.
//       e.g React.PureComponent uses a shallow comparison but produces false
//       negatives with complex object structures.
export const deepEqual = (a: any, b: any, except: string[] = []) => {
  if (a === b) return true;
  const newA = R.is(Object, a) ? R.omit(except, a) : a;
  const newB = R.is(Object, b) ? R.omit(except, b) : b;
  return R.equals(newA, newB);
};

type CachedMergeState = (e?: any) => void;
type MergeState<S> = <K extends keyof S>(k: K) => (v: S[K]) => void;

type CachedMergeDefaultState = () => void;
type MergeDefaultState<S> = <K extends keyof S>(
  k: K,
  p: S[K] & Primitive,
) => () => void;

/**
 * @deprecated Don't use me. If you really need me, check `DeepCheckComponent`.
 */
export class Component<PropTypes, StateType = unknown> extends React.Component<
  PropTypes,
  StateType
> {
  onChangeMergeState: MergeState<StateType>;

  onChangeMergeDefaultState: MergeDefaultState<StateType>;

  constructor(props: PropTypes) {
    super(props);

    this.onChangeMergeState = getCached<
      CachedMergeState,
      keyof CachedMergeState
    >((key) => (value) => {
      this.setState({ ...this.state, [key]: value });
    });

    this.onChangeMergeDefaultState = getCachedStringPrimitive<
      CachedMergeDefaultState,
      keyof CachedMergeDefaultState
    >((key, newValue) => () => {
      this.setState({ ...this.state, [key]: newValue });
    });
  }

  shouldComponentUpdate(newProps: Readonly<PropTypes>, newState: StateType) {
    return (
      !deepEqual(newProps, this.props) ||
      !deepEqual(newState || {}, this.state || {})
    );
  }
}
