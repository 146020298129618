import { getFkId } from "common/functions/foreign-key";
import type { Context } from "common/types/context";
import type { FkValue, ForeignKey } from "common/types/foreign-key";
import { ReactHighlightWords } from "common/vendor-wrappers/react-highlight-words";
import type {
  FormatMeta,
  LabelledOptionOrGroup,
} from "common/vendor-wrappers/react-select/types";
import { formatFk } from "common/widgets/foreign-key/functions";

export const formatReferenceOption = (
  item: LabelledOptionOrGroup<any>,
  meta?: FormatMeta<any>,
) => <ReactHighlightWords text={item.label} search={meta?.inputValue} />;

export const getReferenceLabel = (context: Context) => (record: any) =>
  record ? formatFk(context.entities)(record) : undefined;

export const getValueFromRecords = (value: FkValue, records: ForeignKey[]) =>
  value ? records.find((r) => r.id === getFkId(value), records) : undefined;

export const getRecordsToLabelledOptionsFn =
  (context: Context) => (records: ForeignKey[]) =>
    records.map((record) => ({
      label: getReferenceLabel(context)(record),
      value: record,
    }));
