import { searchApi } from "common/api/search";
import { ApiCall } from "common/types/api";
import {
  showErrorNotification,
  showInfoNotification,
} from "common/ui/notification";
import { Report } from "common/types/reports";

type Exportable = Pick<Report, "name" | "entity" | "query">;

export const getQuery = (report: Exportable) => {
  const { entity, query } = report;
  return { entity, query };
};

export const exportCsv = (apiCall: ApiCall, report: Exportable) => () => {
  showInfoNotification(_("Your csv download will start shortly"));
  searchApi(apiCall)
    .downloadCsv(report.name, getQuery(report))
    .catch(() =>
      showErrorNotification(_("We could not complete your request")),
    );
};

export const exportPdf = (apiCall: ApiCall, report: Exportable) => () => {
  const { name } = report;
  showInfoNotification(_("Your pdf download will start shortly"));
  searchApi(apiCall)
    .downloadPdf(name, getQuery(report), name)
    .catch(() =>
      showErrorNotification(_("We could not complete your request")),
    );
};
