import { Component } from "react";
import { ViewMode } from "react-datetime";
import { Culture } from "common/culture/supported-cultures";
import { DateFormat, UTCDateTime } from "common/date-time/types";
import { CancelButton } from "common/ui/buttons";
import { LazyReactDateTime } from "common/vendor-wrappers/react-datetime";
import { Modal } from "common/widgets/modal";
import { ValueProps } from "common/with-value-for";

interface PropTypes extends ValueProps<UTCDateTime> {
  culture: Culture;
  dateFormat: DateFormat;
  viewMode?: ViewMode;
  className?: string;
}

interface StateType {
  open: boolean;
}

export class DateButtonModal extends Component<PropTypes, StateType> {
  static readonly displayName = "DateButtonModal";

  state: StateType = {
    open: false,
  };

  onChangeValue = (value: UTCDateTime) => {
    this.props.onChange(value);
    this.setState({ open: !this.state.open });
  };

  onToggleModal = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  render() {
    const {
      culture,
      dateFormat,
      viewMode,
      className = "",
      value,
      children,
    } = this.props;
    const { open } = this.state;

    const datePicker = (
      <LazyReactDateTime
        value={value}
        culture={culture}
        dateFormat={dateFormat}
        initialViewMode={viewMode}
        onChange={this.onChangeValue}
      />
    );

    return (
      <div className={`qa-date-button-modal ${className}`}>
        <CancelButton onClick={this.onToggleModal}>
          {children ? children : <i className="fa fa-calendar-o" />}
        </CancelButton>
        {open ? (
          <Modal
            content={datePicker}
            size="small"
            hideFooter={true}
            title={_("Choose Date")}
            className={className}
            allowFullscreen={false}
            onCancel={this.onToggleModal}
          />
        ) : undefined}
      </div>
    );
  }
}
