import * as R from "ramda";
import { ColorRule } from "common/types/color-rule";
import { RGBColor } from "common/types/colors";

export const getColorByRuleId = (
  colorRules: ColorRule[] = [],
  colorId: number,
): RGBColor => {
  if (!colorId || !colorRules.length) return undefined;

  const colorRule = R.find((rule) => rule.id === colorId, colorRules);
  return colorRule?.color;
};
