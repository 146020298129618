import * as R from "ramda";
import {
  FORMAT_DATE_MONTH_NAME,
  FORMAT_DAY_ORDINAL,
} from "common/date-time/formats";
import { formatDate } from "common/date-time/formatters";
import { Projection } from "common/types/scheduled-event";
import { Day } from "./day";

interface RelatedRecordMap {
  [index: string]: string;
}

interface PropTypes {
  month: string;
  projections: Projection[];
  relatedRecords: RelatedRecordMap;
}

const getProjectionsByDay = (projections: Projection[]) =>
  R.toPairs(
    R.groupBy((e) => formatDate(e.date, FORMAT_DAY_ORDINAL), projections),
  ).map(([day, dayProjections]: [string, Projection[]]) => ({
    day,
    dayProjections,
  }));

export const Month = ({ month, projections, relatedRecords }: PropTypes) => (
  <section className="row">
    <div className="col-xs-12 x-pm-projections-heading">
      <h1>{formatDate(month, FORMAT_DATE_MONTH_NAME)}</h1>
    </div>
    <div className="col-xs-12">
      <ul className="row unstyled x-projection-list">
        {getProjectionsByDay(projections).map(({ day, dayProjections }, i) => (
          <li
            key={i}
            className="col-xs-12 col-sm-6 col-md-4 col-lg-3 x-projection-day"
          >
            <Day
              day={day}
              projections={dayProjections}
              relatedRecords={relatedRecords}
            />
          </li>
        ))}
      </ul>
    </div>
  </section>
);
