import { ComponentType, JSX, MouseEvent } from "react";
import { ITooltip } from "react-tooltip";
import { getTooltipProps } from "common/vendor-wrappers/react-tooltip";
import { Link } from "common/widgets/link";

export type ButtonType =
  | "modify"
  | "action"
  | "inactive"
  | "delete"
  | "cancel"
  | "link"
  | "modify-link";

export type HtmlButtonType = "submit" | "reset" | "button";

export type ButtonSize = "small" | "default" | "large";

const saveLabel = () => _("Save");
const deleteLabel = () => _("Delete");
const cancelLabel = () => _("Cancel");
const actionLabel = () => _("Ok");

export type OnClick = (e: MouseEvent) => void;

interface ButtonPropTypes {
  onClick?: OnClick;
  children?: any;
  className?: string;
  disabled?: boolean;
  role?: string;
  title?: string;
  type?: HtmlButtonType;
  tooltip?: string | JSX.Element;
  tooltipVariant?: ITooltip["variant"];
  isFocusable?: boolean;
  dataTestId?: string;
}

interface InternalButtonPropTypes extends ButtonPropTypes {
  style?: string;
}

const Button = ({
  className,
  disabled,
  onClick,
  children,
  style,
  role,
  title,
  type = "button",
  tooltip,
  tooltipVariant,
  isFocusable = true,
  dataTestId,
}: InternalButtonPropTypes) => (
  <button
    className={`${style} ${className || ""}`}
    disabled={disabled}
    onClick={onClick}
    role={role}
    title={title}
    type={type}
    tabIndex={isFocusable ? 0 : -1}
    data-testid={dataTestId}
    {...(tooltip ? getTooltipProps(tooltip, tooltipVariant) : undefined)}
  >
    {children}
  </button>
);
Button.displayName = "Button";

const create = (
  displayName: string,
  style: string,
  defaultLabel: () => string,
) => {
  const ButtonComp = (props: InternalButtonPropTypes) => (
    <Button {...props} style={style}>
      {props.children || defaultLabel()}
    </Button>
  );
  ButtonComp.displayName = displayName;
  return ButtonComp;
};

// Modify buttons
export const ModifyButtonSmall = create(
  "ModifyButtonSmall",
  "x-button-modify-small",
  saveLabel,
);
export const ModifyButton = create(
  "ModifyButton",
  "x-button-modify",
  saveLabel,
);
export const ModifyButtonLarge = create(
  "ModifyButtonLarge",
  "x-button-modify-large",
  saveLabel,
);

// Action buttons
export const ActionButtonSmall = create(
  "ActionButtonSmall",
  "x-button-action-small",
  actionLabel,
);
export const ActionButton = create(
  "ActionButton",
  "x-button-action",
  actionLabel,
);
export const ActionButtonLarge = create(
  "ActionButtonLarge",
  "x-button-action-large",
  actionLabel,
);

// Inactive buttons
export const InactiveButtonSmall = create(
  "InactiveButtonSmall",
  "x-button-inactive-small",
  actionLabel,
);

// Delete buttons
export const DeleteButtonSmall = create(
  "DeleteButtonSmall",
  "x-button-delete-small",
  deleteLabel,
);
export const DeleteButton = create(
  "DeleteButton",
  "x-button-delete",
  deleteLabel,
);
export const DeleteButtonLarge = create(
  "DeleteButtonLarge",
  "x-button-delete-large",
  deleteLabel,
);

// Cancel buttons
export const CancelButtonSmall = create(
  "CancelButtonSmall",
  "x-button-cancel-small",
  cancelLabel,
);
export const CancelButton = create(
  "CancelButton",
  "x-button-cancel",
  cancelLabel,
);
export const CancelButtonLarge = create(
  "CancelButtonLarge",
  "x-button-cancel-large",
  cancelLabel,
);

// link buttons
type LinkTargetPropTypes = "_blank" | "_self" | "_parent" | "_top";

interface LinkPropTypes {
  href: string;
  target?: LinkTargetPropTypes;
  children?: any;
  className?: string;
  disabled?: boolean;
  title?: string;
}

type CompType = (props: InternalButtonPropTypes) => JSX.Element;

const createLink = (displayName: string, Comp: CompType) => {
  const LinkComp = ({
    children,
    href,
    target,
    className,
    disabled,
    title,
  }: LinkPropTypes) => (
    <Link href={href} target={target} className={className}>
      <Comp disabled={disabled} title={title} isFocusable={!href}>
        {children}
      </Comp>
    </Link>
  );
  LinkComp.displayName = displayName;
  return LinkComp;
};

export const LinkButtonSmall = createLink("LinkButtonSmall", ActionButtonSmall);
export const LinkButton = createLink("LinkButton", ActionButton);
export const LinkButtonLarge = createLink("LinkButtonLarge", ActionButtonLarge);

export const ModifyLinkButtonSmall = createLink(
  "ModifyLinkButtonSmall",
  ModifyButtonSmall,
);
export const ModifyLinkButton = createLink("ModifyLinkButton", ModifyButton);
export const ModifyLinkButtonLarge = createLink(
  "ModifyLinkButtonLarge",
  ModifyButtonLarge,
);

export const getButtonComp = (
  type: ButtonType,
  size: ButtonSize,
): ComponentType<ButtonPropTypes> => {
  switch (type) {
    case "modify":
      if (size === "small") return ModifyButtonSmall;
      if (size === "large") return ModifyButtonLarge;
      return ModifyButton;
    case "action":
      if (size === "small") return ActionButtonSmall;
      if (size === "large") return ActionButtonLarge;
      return ActionButton;
    case "inactive":
      if (size === "small") return InactiveButtonSmall;
    // fall through
    case "delete":
      if (size === "small") return DeleteButtonSmall;
      if (size === "large") return DeleteButtonLarge;
      return DeleteButton;
    case "cancel":
      if (size === "small") return CancelButtonSmall;
      if (size === "large") return CancelButtonLarge;
      return CancelButton;
    case "link":
      if (size === "small") return LinkButtonSmall;
      if (size === "large") return LinkButtonLarge;
      return LinkButton;
    case "modify-link":
      if (size === "small") return ModifyLinkButtonSmall;
      if (size === "large") return ModifyLinkButtonLarge;
      return ModifyLinkButton;
    default:
      return ModifyButton;
  }
};
