import { getFileTypeFromUrl } from "common/widgets/media/functions";

export type AttachmentType = "media" | "file" | "audio";

export const getAttachmentType = (url: string): AttachmentType => {
  const fileType = getFileTypeFromUrl(url);
  switch (fileType) {
    case "audio":
      return "audio";
    case "image":
    case "video":
      return "media";
    default:
      return "file";
  }
};
