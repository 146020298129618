import { useState } from "react";
import { recordsApi } from "common/api/records";
import { hasProtectedColumns } from "common/record/utils";
import { ApiCall } from "common/types/api";
import { getLocalizedName } from "common/index";
import { AlertInfo, AlertWarning } from "common/widgets/alert";
import { Action } from "common/record/actions/action";
import { PropTypes } from "common/record/actions/types";
import { RequiredField } from "common/ui/required-field";
import { YesNo } from "common/widgets/radio-button-list/yes-no";
import { mapPayload } from "./functions";

export const Trigger = (props: PropTypes) => {
  const { context, entity, records } = props;
  const [updateLastOpenDate, setUpdateLastOpenDate] = useState(false);

  const triggerRecord = (apiCall: ApiCall) =>
    recordsApi(apiCall).trigger(
      entity.name,
      records[0].properties.id,
      updateLastOpenDate,
    );

  const triggerRecordList = (apiCall: ApiCall) =>
    recordsApi(apiCall).bulkTrigger(
      entity.name,
      mapPayload(records, updateLastOpenDate),
    );

  const onChangeUpdateLastOpenDate = (value: boolean) =>
    setUpdateLastOpenDate(value);

  const onTrigger = (apiCall: ApiCall, dismiss: () => void) => {
    const triggerRequest =
      records.length > 1 ? triggerRecordList(apiCall) : triggerRecord(apiCall);

    return triggerRequest.then(dismiss);
  };

  const woEntity = context.entities[entity.arguments.workOrderEntity];
  const woEntityName = getLocalizedName(woEntity);
  const swoEntityName = getLocalizedName(entity);
  const requiresAuthentication =
    hasProtectedColumns(entity) || hasProtectedColumns(woEntity);

  return (
    <Action
      {...props}
      requiresAuthentication={requiresAuthentication}
      title={_("Trigger")}
      btnLabel={_("Trigger")}
      size="large"
      onOk={onTrigger}
    >
      <AlertInfo
        message={_(
          "Clicking trigger will generate a {WORK_ORDER_ENTITY} record for each asset linked to the {SWO_ENTITY} record. To update the 'Last Open Date' for all the assets to today's date, choose Yes in the options below.",
        )
          .replace("{WORK_ORDER_ENTITY}", woEntityName)
          .replace("{SWO_ENTITY}", swoEntityName)}
      />
      <div className="x-padding-top-10">
        <RequiredField
          label={_("Update 'Last Open Date' to today's date?")}
          value={updateLastOpenDate}
          input={
            <YesNo
              name="Update Last open Date"
              value={updateLastOpenDate}
              onChange={onChangeUpdateLastOpenDate}
            />
          }
        />
      </div>
      {updateLastOpenDate ? (
        <AlertWarning
          message={_(
            "If Generate Projections is turned on for fixed frequency Preventive Maintenance {SWO_ENTITY}, it will re-generate {WORK_ORDER_ENTITY} projections using the updated 'Last Open Date'.",
          )
            .replace("{SWO_ENTITY}", swoEntityName)
            .replace("{WORK_ORDER_ENTITY}", woEntityName)}
        />
      ) : undefined}
    </Action>
  );
};
