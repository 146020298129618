import { defaultFor } from "common";
import { EntityColumn } from "common/entities/entity-column/types";
import { formatDecimal } from "common/utils/decimal";
import { HighlightProps, Label } from "../label";
import { replaceSeparator } from "./functions";
import { NumberValue as Value } from "./types";

interface IntPropTypes extends HighlightProps {
  value: Value;
}

const createIntLabel = (name: string) => {
  const LabelComp = ({ textToHighlight, value }: IntPropTypes) => (
    <Label textToHighlight={textToHighlight} value={value} />
  );
  LabelComp.displayName = name;
  return LabelComp;
};

interface FloatPropTypes extends HighlightProps {
  value: Value;
  decimalSeparator?: string;
  column?: EntityColumn;
}

const createFloatLabel = (name: string) => {
  const comp = ({
    value,
    decimalSeparator,
    column = defaultFor<EntityColumn>(),
    textToHighlight,
  }: FloatPropTypes) => {
    const { displayValue, decimalScale } = column;

    const formattedValue = formatDecimal(value, decimalScale);

    const valueToDisplay =
      displayValue?.(formattedValue) ||
      replaceSeparator(formattedValue, decimalSeparator);

    return <Label textToHighlight={textToHighlight} value={valueToDisplay} />;
  };
  comp.displayName = name;
  return comp;
};

export const FloatLabel = createFloatLabel("FloatLabel");
export const IntLabel = createIntLabel("IntLabel");
export const UfloatLabel = createFloatLabel("UfloatLabel");
export const UintLabel = createIntLabel("UintLabel");
