import { displayEntity } from "common/api/entities";
import { getUrl } from "common/entities";
import { Entities } from "common/entities/types";
import { RecentlyViewed } from "common/types/recently-viewed";
import { Site } from "common/types/sites";
import { Link } from "common/widgets/link";
import { TimeAgo } from "common/widgets/time-ago";
import { getSiteLabelByName } from "x/account-settings/sites/functions";

interface PropTypes {
  record: RecentlyViewed;
  entities: Entities;
  sites: Site[];
  onDelete: () => void;
}

export const RecentRecord = ({
  record,
  entities,
  sites,
  onDelete,
}: PropTypes) => {
  if (!record) return null;
  const { entity, id, label, site, update } = record;

  const recordEntity = entities[entity];
  const entityLabel = displayEntity(recordEntity);
  const siteLabel = getSiteLabelByName(sites, site);

  return (
    <div className="x-recent-record qa-recent-record">
      <Link
        className="x-flex-grow-1 x-recent-record-info qa-recent-info"
        href={`${getUrl(recordEntity, site)}/${id}`}
        title={label}
      >
        <div className="x-history-panel-title qa-history-panel-title">
          {`${entityLabel}${siteLabel ? " - " + siteLabel : ""}`}
        </div>
        <div className="x-history-panel-content qa-history-panel-content">
          {label}
        </div>
        {update ? (
          <TimeAgo
            className="x-history-panel-footer qa-history-panel-footer"
            value={update}
          />
        ) : undefined}
      </Link>
      <div
        className="x-flex x-recent-record-close qa-recent-close"
        title={_("Remove from this list")}
        onClick={onDelete}
      >
        <i className="fa fa-close" />
      </div>
    </div>
  );
};
RecentRecord.displayName = "RecentRecord";
