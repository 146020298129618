import { TimeRange, UTCDateTime } from "common/date-time/types";
import { MeasurementsResponse } from "common/record/form/content/measurements/types";
import { ApiCall } from "common/types/api";
import { CancellablePromise } from "common/types/promises";
import { arrayToString } from "common/utils/array";

type AdpParam = "from" | "to" | "metric" | "sort" | "limit";
type AdpMetric = "temperature";

const getProp = <T extends string>(name: AdpParam, value: T) =>
  value ? `${name}=${value}` : undefined;

export const adpApi = (apiCall: ApiCall) => ({
  getMeasurements: (
    assetId: string,
    range?: TimeRange,
    metric: AdpMetric = "temperature",
  ): CancellablePromise<MeasurementsResponse> => {
    const props = arrayToString(
      [
        getProp<UTCDateTime>("from", range?.rangeFrom),
        getProp<UTCDateTime>("to", range?.rangeTo),
        getProp<AdpMetric>("metric", metric),
      ],
      "&",
    );
    const propsString = props ? "?" + props : "";
    return apiCall(
      "get",
      `api/adp/measurements/assets/${assetId}${propsString}`,
    );
  },

  getImage: (path: string, token: string) =>
    apiCall(
      "get",
      `api/adp/measurements/measurement-image?token=${token}&path=${path}`,
    ),

  attachImage: (
    entityName: string,
    recordId: string,
    path: string,
    token: string,
  ) =>
    apiCall(
      "post",
      `api/adp/measurements/:site/${entityName}/${recordId}/attach-image?token=${token}&path=${path}`,
    ),
});
