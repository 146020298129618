import { Context } from "common/types/context";
import {
  ESignatureSettings,
  ReasonCode,
} from "common/types/esignature-settings";
import { ESignature } from "common/types/reasons";
import { VerticalField } from "common/ui/field";
import { ConditionalRequired } from "common/widgets/conditional-required";
import { ForeignKeySelector } from "common/widgets/selector/foreign-key-selector";
import { T1000 } from "common/widgets/text";

interface PropTypes {
  context: Context;
  eSignatureSettings: ESignatureSettings;
  reason: ESignature;
  onReasonChange: (reason: ESignature) => void;
}

export const ESignatureReason = ({
  context,
  eSignatureSettings,
  reason,
  onReasonChange,
}: PropTypes) => {
  const onReasonTextChange = (text: string) => {
    onReasonChange({ ...reason, text });
  };

  const onReasonCodeChange = (code: ReasonCode) => {
    onReasonChange({ ...reason, code });
  };

  return (
    <>
      {eSignatureSettings?.displayReasonCode ? (
        <ConditionalRequired
          isRequired={eSignatureSettings.requireReasonCode}
          value={reason?.code}
        >
          <VerticalField
            className="qa-esignature-reason-code"
            label={_("Reason Code")}
            input={
              <ForeignKeySelector
                context={context}
                allowClear={true}
                disabled={!eSignatureSettings.reasonCodes?.length}
                entity={context.entities.AuditReasonCode}
                options={eSignatureSettings.reasonCodes}
                value={reason?.code}
                onChange={onReasonCodeChange}
              />
            }
          />
        </ConditionalRequired>
      ) : null}
      {eSignatureSettings?.displayReasonText ? (
        <ConditionalRequired
          isRequired={eSignatureSettings.requireReasonText}
          value={reason?.text}
        >
          <VerticalField
            className="qa-esignature-reason-text"
            label={_("Reason Text")}
            input={<T1000 value={reason?.text} onChange={onReasonTextChange} />}
          />
        </ConditionalRequired>
      ) : null}
    </>
  );
};

ESignatureReason.displayName = "ESignatureReason";
