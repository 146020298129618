import { Entity } from "common/entities/types";
import { findEntityByArgs } from "common/functions/entity";
import { getContactsQuery } from "common/functions/query";
import { Context } from "common/types/context";

export const getUserContactQuery = (context: Context, entity: Entity) => {
  const { userName, entities } = context;

  const approvalGroupName = entity?.arguments?.approvalGroupEntity;
  const requisitionerEntity = findEntityByArgs(
    entities,
    "Requisitioner",
    "approvalGroupEntity",
    approvalGroupName,
  );
  const contactEntityName = requisitionerEntity?.arguments?.contactEntity;
  const contactEntity = entities[contactEntityName];

  if (!contactEntity) return undefined;

  return getContactsQuery(contactEntity, userName);
};
