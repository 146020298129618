import { AssignmentPublishedEvent } from "common/types/events";
import { UiFormat } from "common/types/ui-format";
import { DateTimeLabel } from "common/widgets/date/date-time-label";
import { Row } from "../row";

interface PropTypes {
  event: AssignmentPublishedEvent;
  uiFormat: UiFormat;
}

export const AssignmentPublished = ({ event, uiFormat }: PropTypes) => {
  const occurredOn = event?.details?.occurredOn;
  return (
    <div>
      <legend>{_("Details")}</legend>
      <Row label={_("Published On")}>
        <DateTimeLabel uiFormat={uiFormat} value={occurredOn} />
      </Row>
    </div>
  );
};

AssignmentPublished.displayName = "AssignmentPublished";
