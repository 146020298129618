import * as R from "ramda";
import { stringToBehaviors } from "common/api/behavior";
import { Entity, EntityJoin } from "./types";

const findByName = (name: string, entities: Entity[]) =>
  R.find((e) => e.name === name, entities);

const getJoins = (rawEntities: Entity[], entityName: string): EntityJoin[] => {
  const parseInbound = (entity: Entity): EntityJoin[] =>
    entity.columns
      .filter((c) => c.relatedEntity === entityName)
      .map((c) => ({
        entityName: entity.name,
        column: c.name,
        outbound: false,
        oneToOne: !!c.relatedIsOneToOne,
        owner: entity.ownerFkColumn === c.name,
      }));

  const parseOutbound = (entity: Entity): EntityJoin[] =>
    entity.columns
      .filter((c) => c.relatedEntity != null)
      .map((c) => ({
        entityName: c.relatedEntity,
        column: c.name,
        outbound: true,
        oneToOne: !!c.relatedIsOneToOne,
        owner: entity.ownerFkColumn === c.name,
      }));

  const inbound = R.flatten(rawEntities.map(parseInbound)) ?? [];

  const entity = findByName(entityName, rawEntities);
  const outbound = parseOutbound(entity);

  return inbound.concat(outbound);
};

export const processEntities = (rawEntities: Entity[]): Entity[] =>
  rawEntities.map((entity) =>
    R.mergeRight(entity, {
      behaviors: stringToBehaviors(entity.behavior),
      joins: getJoins(rawEntities, entity.name),
    }),
  );
