import * as R from "ramda";
import { HorizontalField } from "common/form/ui";
import { Calendar } from "common/types/calendars";
import { CalendarSelector } from "common/widgets/calendar-selector";
import { EntryBoxList } from "common/widgets/entry-box/list";
import { Hint } from "common/widgets/hint";
import { ValueComponent } from "common/with-value-for";

interface PropTypes {
  calendars: Calendar[];
}

export class PmCalendars extends ValueComponent<number[], PropTypes> {
  static readonly displayName = "PmCalendars";

  onAdd = (calendar: number) => {
    const { value = [] } = this.props;
    this.setValue(value.concat(calendar));
  };

  onDisplay = (v: number) => {
    const { calendars = [] } = this.props;
    const cal = R.find((c) => v === c.id, calendars);
    return cal && cal.label;
  };

  render() {
    const { calendars = [], value = [] } = this.props;
    const availableCalendars = value.length
      ? R.difference(
          calendars,
          value.map((v) => R.find((c) => c.id === v, calendars)),
        )
      : calendars;
    const message = _(
      "Selected calendars affect PM triggering, WOs can be skipped or shifted depending on holidays and off-season periods",
    );

    return (
      <div className="x-pm-section qa-pm-section qa-pm-trigger-calendars">
        <HorizontalField label={_("Calendars")}>
          <div className="x-pm-trigger-control-dropdown">
            <CalendarSelector
              disabled={!calendars.length}
              placeholder={_("Select a calendar")}
              calendars={availableCalendars}
              value={value[0]}
              onChange={this.onAdd}
            />
          </div>
          <Hint message={message} />
          {value.length ? (
            <div className="x-pm-added-assets">
              <EntryBoxList<number>
                withDefaultValue={true}
                display={this.onDisplay}
                value={value}
                onChange={this.onChangeSetValue}
              />
            </div>
          ) : undefined}
        </HorizontalField>
      </div>
    );
  }
}
