import { Component } from "react";
import { purchaseOrdersApi } from "common/api/purchase-orders";
import { getByBehaviorArgument } from "common/entities";
import { Action } from "common/record/actions/action";
import { PropTypes } from "common/record/actions/types";
import { hasProtectedColumns } from "common/record/utils";
import { ApiCall } from "common/types/api";
import { ApiErrorResponse } from "common/types/error";
import { CancellablePromise } from "common/types/promises";
import { PoDetails } from "common/types/purchase-orders";
import { ApiError } from "common/ui/api-error";
import { AlertWarning } from "common/widgets/alert";
import { LoadingIcon } from "common/widgets/loading-icon";
import { Header } from "../header";
import { isReceivingAll } from "../receive-items/functions";
import { PoList } from "./list";

interface StateType {
  detailsLoading: boolean;
  detailsError: ApiErrorResponse;
  details: PoDetails;
}

export class ClosePo extends Component<PropTypes, StateType> {
  static readonly displayName = "ClosePo";

  state: StateType = {
    detailsLoading: false,
    detailsError: undefined,
    details: undefined,
  };

  componentDidMount() {
    this.loadDetails();
  }

  loadDetails = () => {
    const { context, records = [], entity } = this.props;
    const id = records[0]?.properties?.id;
    const entityName = entity && entity.name;

    if (entityName && id) {
      this.setState({ detailsLoading: true });

      purchaseOrdersApi(context.apiCall)
        .getDetails(entityName, id)
        .then((details) => {
          // backend returns the full qty you can receive
          // we overwrite to undefined to show the proper old -> new ordered
          const purchaseOrderItems = details.purchaseOrderItems.map((item) => ({
            ...item,
            receivingQty: undefined,
          }));

          this.setState({
            details: { ...details, purchaseOrderItems },
            detailsLoading: false,
            detailsError: undefined,
          });
        })
        .catch((error) =>
          this.setState({
            detailsLoading: false,
            detailsError: error,
          }),
        );
    }
  };

  onOk = (apiCall: ApiCall, dismiss: () => void) => {
    const { entity, records } = this.props;
    const id = records[0]?.properties?.id;

    return purchaseOrdersApi(apiCall)
      .closePo(entity?.name, id)
      .then(dismiss)
      .catch((e: Error) => {
        this.loadDetails();
        return CancellablePromise.reject(e);
      });
  };

  render() {
    const { context, dismiss, entity, records } = this.props;
    const { detailsLoading, detailsError, details } = this.state;

    return (
      <Action
        requiresAuthentication={hasProtectedColumns(entity)}
        context={context}
        dismiss={dismiss}
        entity={entity}
        records={records}
        title={_("Close")}
        btnLabel={_("Close")}
        size="large"
        hideRecordNumber={true}
        onOk={records.length === 1 && details ? this.onOk : undefined}
      >
        {detailsLoading && <LoadingIcon />}
        {detailsError && (
          <ApiError error={detailsError} className="x-margin-bottom-20-i" />
        )}
        {details && (
          <>
            <Header context={context} entity={entity} details={details} />
            <PoList
              context={context}
              entity={getByBehaviorArgument(
                context.entities,
                "PurchaseOrderItem",
                "purchaseOrderEntity",
                entity.name,
              )} // po items entity
              items={details.purchaseOrderItems}
            />
            {!isReceivingAll(details.purchaseOrderItems) && (
              <AlertWarning
                className="x-margin-top-10-i"
                message={_(
                  "The original requested quantities will be rewritten and the PO recalculated.",
                )}
              />
            )}
          </>
        )}
      </Action>
    );
  }
}
