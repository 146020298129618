import { returnAndNotify } from "common/api/with-notifications";
import { ApiCall } from "common/types/api";
import { UiFormat } from "common/types/ui-format";

interface Profile {
  name: string;
  userName: string;
  password: string;
  uiFormat: UiFormat;
}

export const profileApi = (apiCall: ApiCall) => ({
  update: (profile: Profile) =>
    apiCall("put", `/api/ui/profile`, profile).then(
      returnAndNotify(_("The profile was successfully updated")),
    ),
});
