import { Component } from "react";
import { recordsApi } from "common/api/records";
import { Properties } from "common/types/records";
import { canDo } from "common/entities";
import { Reload } from "common/record/types";
import { ValueProps } from "common/with-value-for";
import { getRelatedRecordIds } from "../common/functions";
import { SetRecordAs } from "../common/set-record-as";
import { resetDefault, unwrapWithDefault } from "../functions";
import { StandardRelated } from "../standard-related";
import { PropTypes as TablePropTypes } from "../table-with-form/types";
import { RelatedValue } from "../types";
import { updateContext, updateDefaultForm, updateEntity } from "./functions";

interface PropTypes extends ValueProps<RelatedValue>, TablePropTypes {
  reload: Reload;
}

export class PartSupplier extends Component<PropTypes> {
  static readonly displayName = "PartSupplier";

  displaySetDefaultSupplier = (properties: Properties, close: () => void) => {
    const { reload } = this.props;
    return (
      <SetRecordAs
        message={_("Set as the default supplier?")}
        recordId={properties.id}
        onCancel={close}
        onSet={this.onSetDefaultSupplier}
        onComplete={reload}
      />
    );
  };

  isDefaultSupplier = (properties: Properties) => !!properties.isDefault;

  onSetDefaultSupplier = (id: string) => {
    const { context, entity, value, onChange } = this.props;
    const newValue = resetDefault(value, entity.name, id);
    onChange(newValue);
    return recordsApi(context.apiCall).setDefaultSupplier(entity.name, id);
  };

  onChangeSetValue = (newValue: RelatedValue) => {
    const { onChange, entity } = this.props;
    const fixedValue = unwrapWithDefault(newValue, entity.name);
    onChange(fixedValue);
  };

  render() {
    const { context, entity, value, defaultForm } = this.props;

    const ids = getRelatedRecordIds(entity.name, "supplierId", value);

    return (
      <StandardRelated
        {...this.props}
        context={updateContext(context, entity, ids)}
        entity={updateEntity(entity)}
        defaultForm={updateDefaultForm(context, defaultForm, !ids.length)}
        actionsWithContent={
          canDo(entity, "SetDefault")
            ? [
                {
                  name: "SetDefault",
                  fn: this.displaySetDefaultSupplier,
                  isDisabled: this.isDefaultSupplier,
                },
              ]
            : undefined
        }
        onChange={this.onChangeSetValue}
      />
    );
  }
}
