import { Component } from "common/component";
import { ValueComponentType } from "common/with-value-for";

interface State<Value> {
  value: Value;
}

export function rememberValue<Value, PropTypes = unknown, StateType = unknown>(
  Comp: ValueComponentType<Value, PropTypes, StateType>,
  defaultValue?: Value,
) {
  return class RememberValue extends Component<PropTypes, State<Value>> {
    state: State<Value> = { value: defaultValue };

    render() {
      const { value } = this.state;

      return (
        <Comp
          {...({} as any)}
          {...this.props}
          value={value}
          onChange={this.onChangeMergeState("value")}
        />
      );
    }
  };
}
