import { ApiCall } from "common/types/api";
import { recordsApi } from "common/api/records";
import { Properties } from "common/types/records";
import { getStartOfToday } from "common/date-time/calculators";
import { getUtcNow } from "common/date-time/common";
import { hasProtectedColumns } from "common/record/utils";
import { VerticalField } from "common/ui/field";
import { DateTime } from "common/widgets/date/date-time";
import { withValue, WithValue } from "common/with-value";
import { Action } from "../action";
import { DismissAction, PropTypes } from "../types";

export interface RescheduleValue {
  date: string;
}
type Props = PropTypes & WithValue<RescheduleValue>;

const getDate = (v: RescheduleValue, properties: Properties) =>
  v?.date || properties?.startDate || getUtcNow();

const RescheduleComp = ({
  context,
  dismiss,
  entity,
  records = [],
  onChangeMergeValue,
  value,
}: Props) => {
  const properties = records[0]?.properties;
  const date = getDate(value, properties);

  const onOk = (apiCall: ApiCall, dismiss: DismissAction) =>
    recordsApi(apiCall)
      .reschedule(entity?.name, records[0]?.properties?.id, date)
      .then(dismiss);

  return (
    <Action
      requiresAuthentication={hasProtectedColumns(entity)}
      context={context}
      dismiss={dismiss}
      entity={entity}
      records={records}
      title={_("Reschedule")}
      btnLabel={_("Reschedule")}
      onOk={onOk}
    >
      <VerticalField
        label={_("New date")}
        isRequired={true}
        input={
          <DateTime
            minDate={getStartOfToday()}
            uiFormat={context.uiFormat}
            value={date}
            onChange={onChangeMergeValue("date")}
          />
        }
      />
    </Action>
  );
};

export const Reschedule = withValue<RescheduleValue, PropTypes>(
  RescheduleComp,
  "Reschedule",
);
