import { getMediaUrl } from "common/api/media";
import {
  getDocumentVersion,
  getText,
} from "common/scheduled-event/form/projections/reason";
import { RecordEvent } from "common/types/events";

export const getVersionedDocumentUrl = (site: string, details: RecordEvent) => {
  if (!details) return undefined;

  const { reason, entityName, recordId } = details;
  const text = getText(reason);
  const versionId = getDocumentVersion(reason);

  return versionId
    ? getMediaUrl(site, entityName, recordId, text, versionId)
    : undefined;
};
