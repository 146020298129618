import { getUtcNow } from "common/date-time/common";
import { resolveDateDynamicValue } from "common/date-time/helpers/dynamic-values";
import { getRulesMapper } from "common/query/filter";
import { FilterRule, QueryContext, QueryForEntity } from "common/query/types";

const getDynamicValuesFromContext = (queryContext: QueryContext) =>
  Object.keys(queryContext).reduce<QueryContext>(
    (acc, key) => ({ ...acc, [`{${key}}`]: queryContext[key] }),
    {},
  );
const getExpandFilterFn = (queryContext: QueryContext = {}) => {
  const contextDynamicValues = getDynamicValuesFromContext(queryContext);
  const utcNow = getUtcNow();

  return (filter: FilterRule): FilterRule =>
    filter
      ? {
          ...filter,
          value:
            resolveDateDynamicValue(filter.value, utcNow) ??
            contextDynamicValues?.[filter.value] ??
            filter.value,
        }
      : undefined;
};

export const getExpandedQuery = (
  { entity, query }: QueryForEntity,
  queryContext: QueryContext,
): QueryForEntity => {
  const expandFilters = getRulesMapper(getExpandFilterFn(queryContext));

  const filter = expandFilters(query.filter);
  const having = expandFilters(query.having);

  return { entity, query: { ...query, filter, having } };
};
