import { Context } from "common/types/context";
import { QueryToValue } from "common/data/query";
import { RunQuery } from "common/query/types";
import { ReportLayout } from "common/form/types";
import { Output } from "common/data/types";
import { QueryWithIdFilter } from "common/data/query-with-id-filter";
import { FetchReport, injected } from "./fetch";

interface PropTypes {
  context: Context;
  runQuery: RunQuery;
  reportLayout: ReportLayout;
  output: Output;
  reload: () => any;
  idFilter: boolean;
  entityName: string;
}

export const Report = ({
  context,
  runQuery,
  reportLayout,
  output,
  reload,
  idFilter,
  entityName,
}: PropTypes) => {
  const dataQuery = (
    <QueryToValue
      {...injected}
      context={context}
      goTo={undefined}
      runQuery={runQuery}
      notOlderThan={undefined}
      reload={reload}
      withLinks={true}
    />
  );
  return (
    <FetchReport
      context={context}
      id={reportLayout.id}
      output={output} // TODO: rts (two objects with configuration??!!)
      reportLayout={reportLayout}
    >
      {idFilter ? (
        <QueryWithIdFilter
          {...injected}
          entities={context.entities}
          entityName={entityName}
        >
          {dataQuery}
        </QueryWithIdFilter>
      ) : (
        dataQuery
      )}
    </FetchReport>
  );
};
