import * as R from "ramda";
import { returnAndNotify } from "common/api/with-notifications";
import { merge2 } from "common/merge";
import { flattenFkValues } from "common/query/filter";
import { ApiCall } from "common/types/api";
import { CancellablePromise } from "common/types/promises";
import {
  RuleForList,
  EmailNotificationAction,
  Workflow,
  WorkflowRule,
  WorkflowTemplate,
} from "common/types/workflows";
import { QueryForEntity } from "../query/types";

export const updateTemplateData = (rule: WorkflowRule) => {
  if (!rule.actions?.sendnotification) return rule;

  const action = rule.actions.sendnotification as EmailNotificationAction;
  const emailTemplateId = action.emailTemplateId || rule.emailTemplateId;
  return {
    ...rule,
    emailTemplateId,
    actions: {
      ...rule.actions,
      sendnotification: R.pick(["to", "cc", "isRecordPdfAttached"], action),
    },
  };
};

export const parseRules = (rules: WorkflowRule[]): WorkflowRule[] =>
  (rules || [])
    .map((rule) =>
      !rule.conditions?.sqlquery
        ? rule
        : merge2(
            "conditions",
            "sqlquery",
            flattenFkValues(rule.conditions.sqlquery as QueryForEntity),
            rule,
          ),
    )
    .map(updateTemplateData);

export const workflowsApi = (apiCall: ApiCall) => {
  return {
    get: (id: string): CancellablePromise<Workflow> =>
      apiCall("get", `api/rules/${id}`),
    list: (): CancellablePromise<RuleForList[]> => apiCall("get", "api/rules"),
    update: ({ name, rules }: Workflow): CancellablePromise<any> =>
      apiCall("put", `api/rules/${name}`, parseRules(rules)).then(
        returnAndNotify("The rule was successfully updated"),
      ),
    validateExpression: (expression: string) =>
      apiCall("post", "api/rules/validate-expression", { expression }),
    getValidExpressions: (): CancellablePromise<string[]> =>
      apiCall("get", "api/rules/valid-expression-placeholders"),
    allowedEntities: (workflowEntity: string) =>
      apiCall<string[]>(
        "get",
        `api/rules/create-record/allowed-entities?entityName=${workflowEntity}`,
      ),
    getTemplateList: (
      entityName: string,
    ): CancellablePromise<WorkflowTemplate[]> =>
      apiCall("get", `api/rules/notification-queries/${entityName}`),
  };
};
