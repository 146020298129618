import * as R from "ramda";
import { Component } from "react";
import { Context } from "common/types/context";
import { merge2 } from "common/merge";
import { ValueProps } from "common/with-value-for";
import { isUnaryOperator } from "common/entities/operators";
import { trackEvent, eventLabel } from "common/utils/mixpanel";
import { filterBlackList } from "common/entities/entity-column/data-type/types";
import { QueryForEntity } from "common/query/types";
import { ColumnDefinition } from "common/query/advanced-types";
import { setFilterOperator, setFilterValue, getCondition } from "./functions";
import { Operators } from "./operators";
// eslint-disable-next-line import/no-cycle
import { TableFilterWidget } from "./widget";

interface PropTypes {
  columnDefinition: ColumnDefinition;
  context: Context;
}

type Props = PropTypes & ValueProps<QueryForEntity>;

const getColumnDef = (columnDefinition: ColumnDefinition) => {
  const { column } = columnDefinition;
  const { name } = column;

  if (name === "status") {
    return merge2("column", "dataType", "systemintfk", columnDefinition);
  }
  return columnDefinition;
};

export class Filter extends Component<Props> {
  static readonly displayName = "Filter";

  onChange = (isOp?: boolean) => {
    const { value, columnDefinition, onChange } = this.props;
    const colDef = getColumnDef(columnDefinition);

    return (newValue: string) => {
      const newQuery = isOp
        ? setFilterOperator(colDef, value, newValue)
        : setFilterValue(colDef, value, newValue);

      if (newQuery) {
        onChange(newQuery);

        const label = isOp
          ? eventLabel.listFilterOperator
          : eventLabel.listFilterSearch;
        trackEvent(label, {
          entity: colDef.entity,
          column: colDef.valueKey,
          key: isOp ? newValue : undefined,
        });
      }
    };
  };

  render() {
    const { context, columnDefinition, value } = this.props;

    if (!columnDefinition || !columnDefinition.column) return <span />;

    const condition = getCondition(columnDefinition, value);
    const unaryOperator = condition && isUnaryOperator(condition.op);
    const colDef = getColumnDef(columnDefinition);
    const dataType = columnDefinition?.column?.dataType;

    return (
      <div className="x-filter">
        <Operators
          columnDefinition={colDef}
          disabled={R.includes(dataType, filterBlackList)}
          value={(condition && condition.op) || ""}
          onChange={this.onChange(true)}
        />
        <TableFilterWidget
          columnDefinition={colDef}
          context={context}
          query={value}
          disabled={unaryOperator}
          placeholder={""}
          value={condition && condition.value}
          onChange={this.onChange()}
        />
      </div>
    );
  }
}
