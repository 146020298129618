import type { Entities } from "common/entities/types";
import type { RelatedUiValue } from "common/record/types";
import { trackEvent } from "common/utils/mixpanel";
import { getAssignmentEntity } from "./functions";

export const trackAssignmentChanges = (
  entityName: string,
  entities: Entities,
  ui: RelatedUiValue,
) => {
  const assignmentsEntity = getAssignmentEntity(entityName, entities);
  const assignmentsEntityEntityName =
    assignmentsEntity.name.toLocaleLowerCase();
  const assignmentsChanged = ui.form[assignmentsEntityEntityName];

  if (assignmentsChanged && assignmentsChanged.length) {
    const assignment = ui.form[assignmentsEntityEntityName][0];

    if (assignment.properties.status === "P") {
      trackEvent("Assignment Published Changed", { origin: "Scheduler" });
    }
  }
};
