import { MeasurementsResponse } from "common/record/form/content/measurements/types";

export const hasMeasurements = (measurementsResponse: MeasurementsResponse) =>
  !!measurementsResponse?.measurements?.count;

export const getNotFoundError = () => ({
  status: 404,
  data: {
    message: _("No measurements available for the selected time range."),
  },
});
