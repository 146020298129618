import { useState } from "react";
import { CancellablePromise, pseudoCancellable } from "common/types/promises";

interface StoredPromise {
  resolve: () => void;
  reject: () => void;
}

export type OnConfirmationOpen<T> = (data?: T) => CancellablePromise<void>;

export interface ConfirmationType<T> {
  onOpen: OnConfirmationOpen<T>;
  onClose: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  data?: T;
}

export function useConfirmation<T>(): ConfirmationType<T> {
  const [storedPromise, setPromise] = useState<StoredPromise>(null);
  const [data, setData] = useState<T>();

  const onOpen = (data: T) => {
    setData(data);
    return pseudoCancellable(
      new Promise<void>((resolve, reject) => {
        setPromise({ resolve, reject });
      }),
    );
  };

  const onConfirm = () => {
    if (storedPromise.resolve) {
      storedPromise.resolve();
    }
    setPromise(null);
  };

  const onClose = () => {
    if (storedPromise.reject) {
      storedPromise.reject();
    }
    setPromise(null);
  };

  return {
    onOpen,
    onClose,
    onConfirm,
    isOpen: storedPromise !== null,
    data,
  };
}
