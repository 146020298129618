import * as R from "ramda";
import { JSX } from "react";
import { behaveAs } from "common/entities";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { LabelWidget } from "common/form/widget/label-widget";
import { getEventAssetContent } from "common/record/form/content/audit-trail/event-detail/events/payload/behaviors/event-asset";
import { getScheduledEventContent } from "common/record/form/content/audit-trail/event-detail/events/payload/behaviors/scheduled-event";
import { Context } from "common/types/context";

const getBehaviorContent = (
  context: Context,
  entity: Entity,
  column: EntityColumn,
  value: any,
): JSX.Element => {
  if (behaveAs("ScheduledEvent", entity)) {
    return getScheduledEventContent(context, entity, column, value);
  }
  if (behaveAs("EventAsset", entity)) {
    return getEventAssetContent(context, column, value);
  }
  return undefined;
};

export const getRowContent = (
  isForm: boolean,
  context: Context,
  entity: Entity,
  showEmpty: boolean,
  column: EntityColumn,
  value?: any,
  hideLinks?: boolean,
): JSX.Element => {
  if (isForm) {
    const { forms, site } = context;
    const form = R.find((f) => f.id === value, forms);

    return form ? (
      hideLinks ? (
        <span>{form.label}</span>
      ) : (
        <a
          className="x-link-form qa-link-form"
          href={`/#/${site.name}/admin/Forms/${form.id.toString()}`}
          title={form.label}
        >
          {form.label}
        </a>
      )
    ) : (
      <span className="x-empty">{_("Assigned form does not exist")}</span>
    );
  }

  if (R.isNil(value) && showEmpty)
    return <span className="x-empty">{_("empty")}</span>;

  const behaviorValue = getBehaviorContent(context, entity, column, value);
  if (behaviorValue) return behaviorValue;

  return (
    <LabelWidget
      entityName={entity.name}
      column={column}
      context={context}
      withLinks={!hideLinks}
      value={value}
    />
  );
};
