export const stripHtml = (content: string) => {
  if (!content) return "";
  const doc = new DOMParser().parseFromString(content, "text/html");
  return doc.body.textContent || "";
};

export const focusFirstInput = (container: Element) => {
  const focusableElements = container?.querySelectorAll(
    "input:not([readonly]):not([disabled]), select:not([readonly]):not([disabled]), textarea:not([readonly]):not([disabled])",
  );
  const firstFocusableElement = focusableElements?.[0] as HTMLFormElement;
  firstFocusableElement?.focus();
};
