import { Toggle } from "common/widgets/toggle/index";
import { ValueProps } from "common/with-value-for";

import "./with-label.scss";

interface PropTypes extends ValueProps<boolean> {
  label: string;
  disabled?: boolean;
  className?: string;
}

export const ToggleWithLabel = ({
  label,
  disabled,
  className,
  value,
  onChange,
}: PropTypes) => (
  <label className="x-toggle-with-label">
    <Toggle
      disabled={!!disabled}
      className={className}
      value={!!value}
      onChange={onChange}
    />
    <div className="x-toggle-label">{label}</div>
  </label>
);
