import * as R from "ramda";
import { defaultFor } from "common";
import { Entity } from "common/entities/types";
import { Layout, LookupQuery } from "common/form/types";
import { merge1 } from "common/merge";
import { addFilterToQuery } from "common/query/filter";
import { Filter } from "common/query/types";
import { getFormOrDefault } from "common/record/edit/functions";
import { getRelatedRecords, isNotDeletedRecord } from "common/record/utils";
import { Context } from "common/types/context";
import { Form } from "common/types/forms";
import { RelatedValue } from "../types";

export const replaceLookupQueries =
  (columnName: string, lookupQuery: LookupQuery) =>
  (layout: Layout): Layout => {
    if (!lookupQuery || !columnName) return layout;
    const { lookupQueries = [] } = layout;

    const newLookupQueries = lookupQueries
      .filter((lq) => lq.columnName !== columnName)
      .concat(lookupQuery);

    return { ...layout, lookupQueries: newLookupQueries };
  };

export const updateContextForms = (
  context: Context,
  entityName: string,
  fn: (f: Form) => Form,
): Context => {
  const [targetForms, otherForms] = R.partition(
    (f) => f.entityName === entityName,
    context.forms,
  );
  const validTargetForms = targetForms.length
    ? targetForms
    : [getFormOrDefault(context, entityName)];

  const newTargetForms = validTargetForms.map(fn);
  return merge1("forms", otherForms.concat(newTargetForms), context);
};

export const replaceFormsLookupQueries = (
  context: Context,
  entityName: string,
  columnName: string,
  lookupQuery: LookupQuery,
): Context => {
  if (!lookupQuery) return context;

  return updateContextForms(context, entityName, (form) =>
    merge1(
      "settings",
      replaceLookupQueries(columnName, lookupQuery)(form.settings),
      form,
    ),
  );
};

export const getRelatedRecordIds = (
  entityName: string,
  columnName: string,
  value: RelatedValue,
): string[] => {
  const { record, related } = value;
  return getRelatedRecords(entityName, record, related.form)
    .filter(isNotDeletedRecord)
    .map((r) => r?.properties?.[columnName]?.id);
};

export const createNoDuplicateIdLookUpQuery = (
  context: Context,
  entity: Entity = defaultFor<Entity>(),
  ids: string[],
  columnName: string,
  argumentsKey: string,
): LookupQuery => {
  const columnEntityName = entity.arguments[argumentsKey];
  const columnEntity = context.entities[columnEntityName];

  if (!columnEntity) return undefined;

  const filter: Filter = {
    and: ids
      .map((id): Filter => ({ name: "id", op: "neq", value: id }))
      .concat({ name: "isDeleted", op: "isfalse" }),
  };

  return {
    columnName,
    entityName: columnEntityName,
    query: addFilterToQuery(filter, columnEntity.query),
  };
};

export const noDuplicateContactId = (
  context: Context,
  entity: Entity = defaultFor<Entity>(),
  ids: string[],
) =>
  replaceFormsLookupQueries(
    context,
    entity.name,
    "contactId",
    createNoDuplicateIdLookUpQuery(
      context,
      entity,
      ids,
      "contactId",
      "contactEntity",
    ),
  );
