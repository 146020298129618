import { arrayToString } from "common/utils/array";
import { Action, ActionsGroup } from "common/record/actions/types";
import { actionGroupsWithSeparators } from "common/record/actions/functions/list-helpers";

interface PropTypes {
  displayType: "icon" | "label";
  actionGroups: ActionsGroup[];
  onActionSelect: (action: Action) => void;
  icon?: string;
  label?: string;
  onTheRight?: boolean;
}

export const ActionsSelector = ({
  displayType,
  icon = "fa fa-bars fa-lg",
  label = _("Actions"),
  onTheRight,
  actionGroups,
  onActionSelect,
}: PropTypes) => (
  <div className="btn-group x-record-actions-button" role="menu">
    {displayType === "icon" ? (
      <div
        data-bs-toggle="dropdown"
        className={arrayToString(["qa-btn-command-menu", icon])}
        title={_("More actions")}
      />
    ) : (
      <button
        data-bs-toggle="dropdown"
        className="dropdown-toggle x-button-action-small qa-btn-command-menu"
      >
        {label} <span className="caret x-caret" />
      </button>
    )}
    <ul className={`dropdown-menu ${onTheRight ? "dropdown-menu-right" : ""}`}>
      {actionGroupsWithSeparators(actionGroups, onActionSelect)}
    </ul>
  </div>
);
