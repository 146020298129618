import * as R from "ramda";
import { arrayToString } from "common/utils/array";
import { Highlight, getLabelWithHighlight } from "common/widgets/highlight";

import "./index.scss";

export interface HighlightProps {
  textToHighlight?: string;
}

interface PropTypes extends HighlightProps {
  value: any;
  title?: string;
  className?: string;
}

export const getLabel = (value: any) => {
  if (R.isNil(value)) return "";
  if (Array.isArray(value) && !value.length) return "";
  if (value.label) return value.label;
  if (value.title) return value.title;
  if (typeof value === "string" || typeof value === "number") return value;
  return "";
};

export const Label = ({
  value,
  title,
  className = "",
  textToHighlight,
}: PropTypes) => {
  const label = getLabel(value);
  const classes = arrayToString([
    "x-label-widget",
    className,
    label === "" ? "x-empty-label" : "",
  ]);

  return (
    <div className={classes} title={title} data-testid="label-widget">
      {textToHighlight ? (
        <Highlight
          text={getLabelWithHighlight(String(label), textToHighlight)}
        />
      ) : (
        label
      )}
    </div>
  );
};
Label.displayName = "Label";
