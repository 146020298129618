import { replacePlaceholders } from "common/functions/replace-placeholders";
import { Link } from "common/widgets/link";
import { AlertWarning } from "common/widgets/alert";

export const expiredPasswordMessage =
  "Your eMaint X5 password has expired and must be changed. Please go to your {PROFILE_SETTINGS} and update your password.";

export const expiredMessage = (site: string) => {
  const messageWithLink = replacePlaceholders(_(expiredPasswordMessage), {
    "{PROFILE_SETTINGS}": () => (
      <Link href={`/${site}/profile`} target="_blank">
        {_("Profile Settings")}
      </Link>
    ),
  });

  return <AlertWarning message={messageWithLink} />;
};
