import { rgbToHexString } from "common/functions/colors";
import { DEFAULT_EVENT_COLOR } from "x/scheduler2/consts";
import type {
  WorkOrder,
  WorkOrderEvent,
} from "x/scheduler2/planner/unassigned-work/types";

export const getRawEventData = (workOrder: WorkOrder): WorkOrderEvent => {
  if (!workOrder) return undefined;

  const {
    site,
    SYSTEM_id,
    SYSTEM_number,
    SYSTEM_description,
    SYSTEM_startDate,
    SYSTEM_entityName,
  } = workOrder;

  return {
    site,
    workOrderEntityName: SYSTEM_entityName,
    workOrderId: SYSTEM_id,
    number: SYSTEM_number,
    name: SYSTEM_description,
    startDate: SYSTEM_startDate,
    duration: 1,
    durationUnit: "hour",
    durationMS: 3600000,
    eventColor: rgbToHexString(DEFAULT_EVENT_COLOR),
    cls: ["x-unpublished-event", "qa-unpublished-event"],
  };
};
