import { defaultFor } from "common";
import { getColumn } from "common/entities";
import { Entity } from "common/entities/types";
import {
  FormValidationProps,
  Group,
  WidgetsOverwriteMap,
} from "common/form/types";
import { Section } from "common/form/ui";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { WithValue, withValue } from "common/with-value";
import { getLookupType } from "x/account-settings/forms/form/lookups/functions";
// eslint-disable-next-line import/no-cycle
import { GroupField } from "./field";
// eslint-disable-next-line import/no-cycle
import { LookupEditMode } from "./lookup";

export interface PropTypes extends FormValidationProps {
  context: Context;
  entity: Entity;
  groups: Group[];
  group: Group;
  withLinks: boolean;
  allowDynamicValues?: boolean;
  hideLegend?: boolean;
  widgetsMap?: WidgetsOverwriteMap;
}

type Props = PropTypes & WithValue<Properties>;

export const SectionGroup = withValue<Properties, PropTypes>(
  ({
    context,
    entity,
    groups,
    group = defaultFor<Group>(),
    withLinks,
    hideLegend,
    widgetsMap,
    allowDynamicValues,
    formValidation,
    onFormValidationChange,
    value = {},
    onChangeSetValue,
  }: Props) => {
    const { columns = [], label } = group;

    // filter to remove columns that may have been deleted from the entity
    const realColumns = columns.filter((c) => getColumn(entity, c.columnName));
    if (!realColumns.length) return null;

    return (
      <Section legend={hideLegend ? undefined : label}>
        {realColumns.map((layoutColumn) => {
          const { lookupConfiguration, columnName } = layoutColumn;

          return getLookupType(context, lookupConfiguration) ? (
            <LookupEditMode
              key={columnName}
              context={context}
              entity={entity}
              layoutColumn={layoutColumn}
              mainFkColumnName={columnName}
              formValidation={formValidation}
              onFormValidationChange={onFormValidationChange}
              value={value}
              onChange={onChangeSetValue}
            />
          ) : (
            <GroupField
              key={columnName}
              context={context}
              entity={entity}
              groups={groups}
              layoutColumn={layoutColumn}
              widgetsProps={widgetsMap?.[columnName]}
              withLinks={withLinks}
              formValidation={formValidation}
              onFormValidationChange={onFormValidationChange}
              allowDynamicValues={allowDynamicValues}
              value={value}
              onChange={onChangeSetValue}
            />
          );
        })}
      </Section>
    );
  },
  "SectionGroup",
);
