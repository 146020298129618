import * as R from "ramda";
import { recordsApi } from "common/api/records";
import { Component } from "common/component";
import { findEntityByArgs } from "common/functions/entity";
import { getIntFkId } from "common/functions/system-int";
import { merge2 } from "common/merge";
import { recordsToUpdateItems } from "common/record/form/content/related/requisitioning-item/functions";
import { ApiErrorResponse } from "common/types/error";
import { CancellablePromise } from "common/types/promises";
import { Record } from "common/types/records";
import { ApiError } from "common/ui/api-error";
import { LoadingIcon } from "common/widgets/loading-icon";
import { PropTypes } from "x/records/edit-controller/types";
import {
  APPROVER,
  AWAITING_APPROVAL,
} from "x/records/edit/behavior-form/requisitioning/consts";
import { RecordEditController } from "../edit-controller";

interface StateType {
  loading: boolean;
  error: ApiErrorResponse;
}

export class RequisitioningEditController extends Component<
  PropTypes,
  StateType
> {
  static readonly displayName = "RequisitioningEditController";
  state: StateType = {
    loading: false,
    error: undefined,
  };

  onPreSave = (record: Record, initialRecord: Record) => {
    const { context, entity } = this.props;
    const { entities, apiCall } = context;

    const status = getIntFkId(record?.properties?.statusId);
    const permissionType = record?.properties?.permissionType;
    if (status !== AWAITING_APPROVAL || permissionType !== APPROVER) {
      return CancellablePromise.resolve(record);
    }

    const { name } = findEntityByArgs(
      entities,
      "RequisitioningItem",
      "requisitioningEntity",
      entity.name,
    );

    const newReqItems = record?.related?.[name];
    const originalReqItems = initialRecord?.related?.[name];

    const reqItemsDiff = R.difference(
      recordsToUpdateItems(newReqItems),
      recordsToUpdateItems(originalReqItems),
    );
    const resetReqItems = merge2("related", name, originalReqItems, record);

    this.setState({ loading: true, error: undefined });
    return recordsApi(apiCall)
      .bulkUpdateItemsStatus(name, reqItemsDiff)
      .then(() => {
        this.setState({ loading: false });
        return resetReqItems;
      })
      .catch((error) => {
        this.setState({ error, loading: false });
        return resetReqItems;
      });
  };

  render() {
    const { loading, error } = this.state;

    return (
      <>
        {loading && <LoadingIcon />}
        {error && <ApiError error={error} className="x-margin-top-40-i" />}
        <RecordEditController {...this.props} onPreSave={this.onPreSave} />
      </>
    );
  }
}
