import { GroupField, Query, Secondaries } from "common/query/types";
import { FkValue } from "common/types/foreign-key";
import { CancellablePromise } from "common/types/promises";
import { ViewType } from "common/types/scheduler";
import { Feature } from "common/types/tenants";
import { EntityQueries, SearchProps } from "x/scheduler/unassigned-work/types";

export interface MenuEntry {
  canExplore: boolean;
  label: string;
  icon: string;
  link: string;
  lastViewedLink?: string;
  entityName?: string;
  itemClass?: string;
}

export interface StaticEntry {
  name: string;
  label: string;
  icon: string;
  requiredFeatures?: Feature[];
}

interface BaseMenuEntrySetting {
  icon: string;
  position?: number;
  hidden?: boolean;
}

export interface StaticSetting extends BaseMenuEntrySetting {
  name: string;
}

export interface EntitySetting extends BaseMenuEntrySetting {
  entity: string;
  localizedName: string;
}

export interface ReportSetting extends BaseMenuEntrySetting {
  reportId: number;
  labels: {
    [culture: string]: string; // name
  };
}

export type MenuEntrySetting = StaticSetting | EntitySetting | ReportSetting;

interface FilterPrefs {
  id: number;
  name: string;
  shared: boolean;
  owned: boolean;
  query: Query;
}

interface LastFilterPrefs {
  widths: number[];
  filter: FilterPrefs;
  secondaryQueries?: Secondaries;
}

export interface ListPrefs {
  site: string;
  entity: string;
  widths: { [filter: string]: number[] };
  preferred: number;
  lastFilter: LastFilterPrefs;
  output: Output;
  page: number;
  pageSize: number;
  reportId?: number;
}

interface ExplorerPrefs {
  entity: string;
  group: GroupField[];
}

export interface SchedulerSettings {
  defaultView: ViewType;
  defaultFilter: FkValue;
  defaultSelectedDate: string;
  defaultWeekends: boolean;
  defaultWOQueries: EntityQueries;
  defaultWOSearch: SearchProps;
}

export interface NodeTreePrefs {
  name: string;
  selectedId: string;
  expandedIds: string[];
  scrollOffset: number;
  searchTerm: string;
}

interface RelatedEntitySettings {
  entityName: string;
  site: string;
  widths: { [relatedEntity: string]: number[] };
}

export interface Preferences {
  menu: MenuEntrySetting[];
  list: ListPrefs[];
  explorer: ExplorerPrefs[];
  theme: string;
  lastSite: string;
  scheduler: SchedulerSettings;
  related: RelatedEntitySettings[];
  nodeTrees: NodeTreePrefs[];
}

export type Unsubscribe = () => void;
export type Subscription = () => any;

export interface PreferenceService {
  get: () => Preferences;
  set: (value: Preferences) => CancellablePromise<any>;
  setByKey: <T extends keyof Preferences>(
    key: T,
    value: Preferences[T],
  ) => CancellablePromise<any>;
  getByKey: <T extends keyof Preferences>(key: T) => Preferences[T];
  subscribe: (fn: Subscription) => Unsubscribe;
  notify: () => void;
}

export type Output = "Table" | "Cards" | "Tree" | "FunctionalLocation";
export const DefaultOutputs: Output[] = [
  "Table",
  "Cards",
  "Tree",
  "FunctionalLocation",
];
