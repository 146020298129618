import * as R from "ramda";
import { Culture } from "common/culture/supported-cultures";
import { Entities } from "common/entities/types";
import { ReportLayout } from "common/form/types";
import { merge1, merge2 } from "common/merge";
import { isQueryValid } from "common/query-builder/validation";
import {
  isSummaryField,
  SelectExpression,
  SelectField,
  SelectItem,
} from "common/query/types";
import { Form } from "common/types/forms";
import { Report } from "common/types/reports";
import { ReportFormType } from "x/reports/edit/types";

export interface SelectChanges {
  added: SelectItem[];
  removed: SelectItem[];
}

export const getSelectChanges = (
  initialSelect: SelectItem[],
  select: SelectItem[],
): SelectChanges => {
  return {
    added: R.difference(select, initialSelect),
    removed: R.difference(initialSelect, select),
  };
};

export const getColumnsName = (changesArr: SelectItem[]): string[] =>
  changesArr
    .filter((c) => !isSummaryField(c))
    .map(
      (c: SelectExpression | SelectField) => c.alias || (c as SelectField).name,
    );

export const updateColumns = (
  columns: string[],
  changes: SelectChanges,
): string[] => {
  if (changes.removed.length === 0 && changes.added.length === 0) {
    return [];
  }
  let output: string[] = columns;

  output = R.without(getColumnsName(changes.removed), output);
  output = R.uniq(output.concat(getColumnsName(changes.added)));

  return output;
};

const mergeNewColumns =
  (changes: SelectChanges) => (reportLayout: ReportLayout) =>
    merge1(
      "columns",
      updateColumns(reportLayout.columns, changes),
      reportLayout,
    );

const updateById = <T extends { id: number }>(
  arr: T[],
  id: number,
  f: (i: T) => T,
) => arr.map((item) => (item && item.id === id ? f(item) : item));

export const getFormsToUpdate = (id: number, forms: Form[] = []): Form[] => {
  if (!id) return [];
  return forms.filter((form) => {
    if (!(form.settings && form.settings.reports)) return false;
    const { sidebar = [], section = [] } = form.settings.reports;
    const reportLayouts = sidebar.concat(section);
    return R.any((r) => r && r.id === id, reportLayouts);
  });
};

export const getUpdatedForms = (
  id: number,
  forms: Form[],
  changes: SelectChanges,
): Form[] => {
  if (!id) return [];

  const formsToUpdate = getFormsToUpdate(id, forms);

  return formsToUpdate.map((f) => {
    const { reports } = f.settings;
    let newSidebar;
    let newSection;

    if (reports.sidebar)
      newSidebar = updateById(reports.sidebar, id, mergeNewColumns(changes));

    if (reports.section)
      newSection = updateById(reports.section, id, mergeNewColumns(changes));

    return merge2(
      "settings",
      "reports",
      {
        sidebar: newSidebar || reports.sidebar,
        section: newSection || reports.section,
      },
      f,
    );
  });
};

export const deleteReportFromForms = (id: number, forms: Form[]): Form[] => {
  if (!id) return [];

  const formsToUpdate = getFormsToUpdate(id, forms);

  return formsToUpdate.map((f) => {
    const { reports } = f.settings;
    let newSidebar;
    let newSection;

    if (reports.sidebar)
      newSidebar = R.filter((r) => r.id !== id, reports.sidebar);

    if (reports.section)
      newSection = R.filter((r) => r.id !== id, reports.section);

    return merge2(
      "settings",
      "reports",
      {
        sidebar: newSidebar || reports.sidebar,
        section: newSection || reports.section,
      },
      f,
    );
  });
};

const getReportLabelOrName = (culture: Culture, report: Report) =>
  (
    report?.labels?.[culture]?.name ||
    report.label ||
    report.name
  ).toLowerCase();

const isSameReport = (reportA: Report, reportB: Report) =>
  reportA.id && reportA.id === reportB.id;

const hasDuplicatedName = (
  culture: Culture,
  reportA: Report,
  reportB: Report,
) =>
  !isSameReport(reportA, reportB) &&
  getReportLabelOrName(culture, reportA) ===
    getReportLabelOrName(culture, reportB);

export const isUniqueReportName = (
  culture: Culture,
  report: Report,
  reports: Report[],
) =>
  reports.length === 0 ||
  !report.name ||
  reports.every((r) => !hasDuplicatedName(culture, report, r));

export const isValid = (
  entities: Entities,
  culture: Culture,
  value: ReportFormType,
  reports: Report[] = [],
): boolean => {
  if (!value || !value.item) return false;

  const { name, entity, query, isGlobal, roleIds } = value.item;

  return (
    !!name &&
    !!entity &&
    !!query &&
    isQueryValid(entities, { entity, query }) &&
    (isGlobal || roleIds.length > 0) &&
    isUniqueReportName(culture, value.item, reports)
  );
};
