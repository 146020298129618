import * as R from "ramda";
import { cloneElement } from "react";
import { defaultFor } from "common";
import { FormFooter, LoadingButtonLabels } from "common/form/footer";
import { Context } from "common/types/context";
import { ChildPropTypes } from "common/ui/controllers/edit/types";
import { LoadingIcon } from "common/widgets/loading-icon";
import { ResolvedDependencies } from "common/with-dependencies";
import { ValueComponent } from "common/with-value-for";
import { Ribbon } from "x/layout/ribbon";
import { Crumb } from "x/layout/ribbon/breadcrumb";

interface Injected<TValue, TId> {
  readOnly: boolean;
  value: TValue;
  onChange: (value: TValue) => any;
  reload: () => any;
  dependencies: any;
  id: TId;
}
export function createInjected<TValue, TId>() {
  return defaultFor<Injected<TValue, TId>>();
}

export interface FormPropTypes<TValue, TId> {
  isValid: (value: TValue, deps: ResolvedDependencies) => boolean;
  isNew: boolean;
  crumbs: Crumb[];
  context: Context;
  id: TId;
  getTitle?: (value: TValue) => string;
  cancelLabel?: string;
  deleteLabels?: LoadingButtonLabels;
  hideFooter?: boolean;
  hideRibbon?: boolean;
  noDelete?: boolean;
  deleteWarningMessage?: string;
}

interface PropTypes<TValue, TId>
  extends ChildPropTypes<TValue>,
    FormPropTypes<TValue, TId> {
  children?: any;
}

export class FormComponent<TValue, TId> extends ValueComponent<
  TValue,
  PropTypes<TValue, TId>
> {
  getInjected = () => {
    const { onSave, dependencies, children, reload, id, value } = this.props;

    const injected: Injected<TValue, TId> = {
      readOnly: !onSave,
      dependencies,
      reload,
      id,
      value,
      onChange: this.onChangeSetValue,
    };
    return cloneElement(children, injected);
  };

  render() {
    const {
      onSave,
      onDelete,
      onCancel,
      onRestore,
      loading,
      saving,
      deleting,
      restoring,
      dependencies,
      isNew,
      isValid,
      getTitle,
      crumbs,
      cancelLabel,
      deleteLabels,
      context,
      hideFooter,
      hideRibbon,
      noDelete,
      deleteWarningMessage,
      value,
    } = this.props;

    const content = loading ? (
      <LoadingIcon />
    ) : (
      <>
        {this.getInjected()}
        {hideFooter || (
          <FormFooter
            isNew={isNew}
            isValid={isValid(value, dependencies)}
            canCancel={true}
            onCancel={onCancel}
            cancelLabel={cancelLabel}
            canSave={!!onSave}
            onSave={onSave}
            isSaving={saving}
            canDelete={!!onDelete && !noDelete}
            onDelete={onDelete}
            isDeleting={deleting}
            deleteLabels={deleteLabels}
            canRestore={!!onRestore}
            onRestore={onRestore}
            isRestoring={restoring}
            deleteWarningMessage={deleteWarningMessage}
          />
        )}
      </>
    );

    const crumbsWithUrl = [
      ...crumbs.map((c) =>
        R.mergeRight(c, {
          url: c.url && c.url.replace(":site", context.site.name),
        }),
      ),
      ...(loading || isNew || getTitle
        ? [
            {
              name: loading
                ? `${_("loading")}...`
                : isNew
                  ? _("New")
                  : getTitle && getTitle(value),
            },
          ]
        : []),
    ];

    return hideRibbon ? (
      content
    ) : (
      <div className="x-container-with-ribbon">
        <Ribbon onRefresh={null} scope={context.scope} crumbs={crumbsWithUrl} />
        <div className="x-content-with-ribbon">{content}</div>
      </div>
    );
  }
}
