import { useState } from "react";
import * as R from "ramda";
// eslint-disable-next-line import/no-cycle
import { InputWidget } from "common/form/widget/input-widget";
import { searchApi } from "common/api/search";
import { behaveAs, findColumn } from "common/entities";
import { defaultFor, getLocalizedName } from "common/index";
import { getBatchQuery } from "common/record/form/content/related/part-charge/functions";
import { ForeignKey } from "common/types/foreign-key";
import { VerticalField } from "common/ui/field";
import { QuerySelector } from "common/widgets/selector/query-selector";
import { Entity } from "common/entities/types";
import { getFkId } from "common/functions/foreign-key";
import { SubmitAllActionTypes } from "common/record/actions/ui/submit-parts-count/types";
import {
  getAdjustmentEntity,
  getFormatBatchFn,
} from "common/record/form/content/related/stock/functions";
import { Context } from "common/types/context";
import { Required } from "common/widgets/required";
import { ValueProps } from "common/with-value-for";
import { LabelWidget } from "common/form/widget/label-widget";

interface PropTypes extends ValueProps<SubmitAllActionTypes> {
  context: Context;
  partsToBeCountedEntity: Entity;
  partLocationEntityName: string;
}

export const SubmitWithInput = (props: PropTypes) => {
  const {
    context,
    partLocationEntityName,
    partsToBeCountedEntity,
    value,
    onChange,
  } = props;
  const {
    finalCount,
    partId,
    manualBatchSelection,
    onHand,
    cost,
    partLocationId,
  } = value;

  const [batch, setBatch] = useState<ForeignKey>(undefined);

  const batchId = getFkId(batch);
  const onHandDelta = finalCount - onHand;
  const batchEntity =
    R.values(context.entities).find(
      (entity) =>
        behaveAs("Batch", entity) &&
        entity.arguments.stockEntity === partLocationEntityName,
    ) ?? defaultFor<Entity>();
  const batchSelectorDefaults: ForeignKey[] = [
    { id: "new", number: 0, title: _("New") },
  ];

  const onBatchChange = (batch: ForeignKey) => {
    setBatch(batch);
    onChange({ ...value, batchId: batch.id, cost: undefined });
  };
  const onCostChange = (cost: number) => {
    onChange({ ...value, cost });
  };

  const batchSelector = manualBatchSelection ? (
    <VerticalField
      label={getLocalizedName(batchEntity)}
      isRequired={true}
      input={
        <Required data-testid="batch-selector" value={batch}>
          <QuerySelector
            context={context}
            query={
              partLocationId
                ? getBatchQuery(batchEntity, partLocationId)
                : undefined
            }
            runQuery={searchApi(context.apiCall).runQuery}
            defaults={onHandDelta >= 0 ? batchSelectorDefaults : undefined}
            formatOption={getFormatBatchFn(context)}
            value={batch}
            onChange={onBatchChange}
          />
        </Required>
      }
    />
  ) : undefined;

  const fullAdjustmentEntity = getAdjustmentEntity(
    context.entities,
    context.entities[partLocationEntityName],
  );
  const unitCostColumn = R.find(
    (c) => c.name === "cost",
    fullAdjustmentEntity.columns,
  );
  const showCost =
    finalCount > onHand && (!manualBatchSelection || batchId === "new");

  const costInput = showCost ? (
    <VerticalField
      label={_("Unit cost")}
      isRequired={true}
      input={
        <Required value={cost}>
          <InputWidget
            context={context}
            column={unitCostColumn}
            disabled={false}
            validate={true}
            buffer={false}
            formValidation={undefined}
            onFormValidationChange={undefined}
            value={cost}
            onChange={onCostChange}
          />
        </Required>
      }
    />
  ) : undefined;

  const getReadOnlyField = (columnName: string, value: string) => {
    const column = findColumn(partsToBeCountedEntity?.columns, columnName);
    return (
      <VerticalField
        label={getLocalizedName(column)}
        input={
          <LabelWidget
            context={context}
            entityName={partsToBeCountedEntity?.name}
            withLinks={true}
            column={column}
            value={value}
          />
        }
      />
    );
  };

  return (
    <>
      <hr />
      <div className="row">
        <div className="col">{getReadOnlyField("partId", partId)}</div>
        <div className="col">
          {getReadOnlyField("partLocationId", partLocationId)}
        </div>
      </div>
      {batchSelector}
      {costInput}
    </>
  );
};
