import * as R from "ramda";
import { forEntity } from "common/api/records";
import { Component } from "common/component";
import { Entity } from "common/entities/types";
import { Omit } from "common/types/omit";
import { CancellablePromise } from "common/types/promises";
import { Record } from "common/types/records";
import { ExternalPropTypes } from "common/ui/controllers/edit";
import { createController } from "x/controllers/edit/with-ribbon-and-footer";
import { createInjected } from "x/controllers/edit/with-ribbon-and-footer/form";
import { VisualTemplate } from "x/account-settings/visual-templates/types";
import { visualTemplatesUIApi } from "common/api/visual-templates";
import { InteractiveMapForm } from "./form";
import { InteractiveMapRecord, InteractiveMapValue } from "./types";

interface PropTypes
  extends Omit<ExternalPropTypes<string, InteractiveMapRecord>, "onPreSave"> {
  entity: Entity;
  hidePanels: boolean;
}

interface StateTypes {
  colorTemplates: VisualTemplate[];
}

export const EditController = createController<
  InteractiveMapValue,
  string,
  Record
>();
const injected = createInjected<InteractiveMapValue, string>();

const unwrap = (form: InteractiveMapValue): InteractiveMapRecord =>
  form && form.record;

const wrap = (
  form: InteractiveMapValue,
  record: InteractiveMapRecord,
): InteractiveMapValue => R.mergeRight(form, { record });

const isValid = (value: InteractiveMapValue) =>
  !!value?.record?.properties?.description;

const noCrumbs: any[] = [];

export class MapEditController extends Component<PropTypes, StateTypes> {
  state: StateTypes = {
    colorTemplates: [],
  };

  componentDidMount() {
    const { context } = this.props;

    if (context.isEnabledFeature("visualTemplates")) {
      visualTemplatesUIApi(context.apiCall)
        .list()
        .then((colorTemplates) => this.setState({ colorTemplates }));
    }
  }

  getApi = () => {
    const { entity, context } = this.props;
    const { site, recentlyViewed } = context;

    const api = forEntity(context.apiCall, entity.name);

    return R.mergeRight(api, {
      get: (recordId: string): CancellablePromise<Record> =>
        api.get(recordId).then((record) => {
          recentlyViewed.add(site.name, entity.name, record.properties);
          return record;
        }),
    });
  };

  render() {
    const { context, id, entity, hidePanels } = this.props;
    const { colorTemplates } = this.state;

    return (
      <EditController
        {...this.props}
        api={this.getApi()}
        permissionCategory={entity.name}
        wrapRecord={wrap}
        unwrapRecord={unwrap}
        crumbs={noCrumbs}
        hideRibbon={true}
        isValid={isValid}
        isNew={!id}
      >
        <InteractiveMapForm
          {...injected}
          context={context}
          entity={entity}
          hidePanels={hidePanels}
          colorTemplates={colorTemplates}
        />
      </EditController>
    );
  }
}
