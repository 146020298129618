import {
  PermissionType,
  RequisitionStatus,
} from "x/records/edit/behavior-form/requisitioning/types";

export const APPROVAL_COST_CENTER = "approvalCostCenterId";
export const APPROVAL_GROUP = "approvalGroupId";
export const REQUESTED_BY = "requestedBy";
export const REQUESTED_ON = "requestedOn";
export const REQUISITIONING_STATUS = "statusId";
export const REQUESTED_DELIVERY_DATE = "requestedDeliveryDate";
export const COMMENTS = "comments";

export const OPEN: RequisitionStatus = 1;
export const AWAITING_APPROVAL: RequisitionStatus = 2;
export const REJECTED: RequisitionStatus = 4;
export const PO_CREATED: RequisitionStatus = 5;
export const UNDER_REVIEW: RequisitionStatus = 6;

export const REQUISITIONER: PermissionType = "requisitioner";
export const REVIEWER: PermissionType = "reviewer";
export const APPROVER: PermissionType = "approver";
