import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "common/app/redux";
import { fetchExpandedRecord } from "common/app/redux/expanded-records";
import { isForeignKey } from "common/functions/foreign-key";
import { Context } from "common/types/context";
import { FkValue } from "common/types/foreign-key";

export type ExpandRecordHookOptions = {
  context: Context;
  entityName: string;
  record: FkValue;
};

export const useExpandedRecord = ({
  context,
  entityName,
  record,
}: ExpandRecordHookOptions) => {
  const expandedRecord = useAppSelector((state) =>
    record && !isForeignKey(record)
      ? state?.expandedRecords?.[record]?.record
      : undefined,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      !context ||
      !entityName ||
      !record ||
      isForeignKey(record) ||
      record === expandedRecord?.id
    ) {
      return;
    }

    dispatch(
      fetchExpandedRecord({
        context,
        entityName,
        recordId: record,
      }),
    );
  }, [record]);

  return expandedRecord;
};
