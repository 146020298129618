import { Component } from "react";
import { Context } from "common/types/context";
import { getLocalizedName } from "common";
import { ValueProps } from "common/with-value-for";
import { RunQuery, QueryForEntity } from "common/query/types";
import { hasPermissionToUpdate } from "common/functions/roles";
import { ActionButton } from "common/ui/buttons";
import { Explorer, ExplorerValue } from "./explorer";
import { ExplorerModal } from "./explorer/modal";
import { Config } from "./explorer/types";

export interface ExplorerViewValue {
  explorer: ExplorerValue;
  explorerConfig: Config;
  explorerModalConfig: Config;
  showExplorerModal: boolean;
}

interface PropTypes extends ValueProps<ExplorerViewValue> {
  context: Context;
  runQuery: RunQuery;
  query: QueryForEntity;
}

export class View extends Component<PropTypes> {
  static readonly displayName = "ExplorerPanel";

  onShowExplorer = () => {
    const { value, onChange } = this.props;
    onChange({
      ...value,
      explorerModalConfig: value.explorerConfig,
      showExplorerModal: true,
    });
  };

  onApply = () => {
    const { value, onChange } = this.props;
    onChange({
      ...value,
      explorer: undefined,
      explorerConfig: value.explorerModalConfig,
      explorerModalConfig: undefined,
      showExplorerModal: false,
    });
  };

  onCancel = () => {
    const { value, onChange } = this.props;
    onChange({
      ...value,
      explorerModalConfig: undefined,
      showExplorerModal: false,
    });
  };

  onReset = () => {
    const { value, onChange } = this.props;
    onChange({
      ...value,
      explorerModalConfig: undefined,
    });
  };

  onChangeExplorer = (explorer: ExplorerValue) => {
    const { value, onChange } = this.props;
    onChange({ ...value, explorer });
  };

  onChangeExplorerModalConfig = (explorerModalConfig: Config) => {
    const { value, onChange } = this.props;
    onChange({ ...value, explorerModalConfig });
  };

  render() {
    const { context, runQuery, query, value } = this.props;
    const { explorer, explorerConfig, explorerModalConfig, showExplorerModal } =
      value;
    const { entities, role, userTypes } = context;
    const localEntityName = query
      ? getLocalizedName(entities[query.entity])
      : "";

    return (
      <div className="x-explorer-panel">
        <div className="x-explorer-header">
          {`${localEntityName} ${_("Explorer")}`}
        </div>

        {explorerConfig && explorerConfig.length ? (
          <Explorer
            context={context}
            runQuery={runQuery}
            query={query}
            config={explorerConfig}
            value={explorer}
            onChange={this.onChangeExplorer}
          />
        ) : (
          <div className="x-explorer-info">
            {_(
              "The explorer is not configured for this entity. Use the button below to set the grouping configuration for the explorer.",
            )}
          </div>
        )}

        {hasPermissionToUpdate(userTypes, role, "Explorer") ? (
          <ActionButton
            className="x-explorer-show-modal"
            onClick={this.onShowExplorer}
          >
            {_("Select Explorer Columns")}
          </ActionButton>
        ) : undefined}

        {showExplorerModal ? (
          <ExplorerModal
            context={context}
            entities={entities}
            entity={query.entity}
            joins={query.query.joins}
            value={explorerModalConfig}
            onChange={this.onChangeExplorerModalConfig}
            onApply={this.onApply}
            onCancel={this.onCancel}
            onReset={this.onReset}
          />
        ) : undefined}
      </div>
    );
  }
}
