import * as R from "ramda";
import { Record } from "common/types/records";
import { getColumnDefinitions } from "common/query/common";
import { QueryForEntity } from "common/query/types";
import { Context } from "common/types/context";
import { GoFn } from "common/types/url";
import { LoadingIcon } from "common/widgets/loading-icon";
import { MapWidgetPropsFn } from "common/form/types";
import { Card } from "./card";

interface PropTypes {
  context: Context;
  records: Record[];
  title: string;
  actions?: string[];
  running?: boolean;
  query: QueryForEntity;
  reload: () => any;
  goTo: GoFn;
  withLinks?: boolean;
  widgetsMapper?: MapWidgetPropsFn;
}

export const Cards = ({
  context,
  records,
  title,
  actions,
  running,
  query,
  reload,
  goTo,
  withLinks = true,
  widgetsMapper,
}: PropTypes) => {
  const allDefs = getColumnDefinitions(context.entities, query);
  const hasImage = R.any((d) => d.valueKey === "image", allDefs);
  const defs = allDefs.filter(
    (d) => d.valueKey !== title && d.valueKey !== "image",
  );

  if (running) return <LoadingIcon />;

  if (!records?.length) {
    return (
      <div className="text-center x-card-no-result">{_("No results")}</div>
    );
  }

  return (
    <section className="x-cards-group-list" data-testid="cards">
      {records.map((record, index) => {
        const recordWithAddedActions =
          actions && record.actions
            ? R.mergeRight(record, {
                actions: R.intersection(actions, record.actions),
              })
            : record;

        return (
          <div
            key={record?.properties?.id || index}
            className="x-card-wrapper col-xs-12 col-sm-6 col-md-6 col-lg-4"
          >
            <Card
              context={context}
              query={query}
              definitions={defs}
              record={recordWithAddedActions}
              title={title}
              withLinks={withLinks}
              widgetsMapper={widgetsMapper}
              hasImage={hasImage}
              reload={reload}
              goTo={goTo}
            />
          </div>
        );
      })}
    </section>
  );
};
Cards.displayName = "Cards";
