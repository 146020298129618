import * as R from "ramda";
import { withValue, WithValue } from "common/with-value";
import { Layout } from "common/form/types";
import { mapLayout } from "common/form/functions/layout";
import {
  withForm,
  withMapper,
} from "common/record/form/content/related/table-with-form/functions";
import { StandardRelated } from "./standard-related";
import { PropTypes } from "./table-with-form/types";
import { RelatedValue } from "./types";

const toDoMapper = (layout: Layout) =>
  mapLayout(
    (col) =>
      col.columnName === "status" ? R.mergeRight(col, { disabled: true }) : col,
    layout,
  );

const defaultToDo = {
  status: "P",
};

const ToDoComp = (props: PropTypes & WithValue<RelatedValue>) => (
  <div className="x-todo-form">
    <StandardRelated
      {...props}
      layoutMapper={withMapper(props.layoutMapper, toDoMapper)}
      defaultForm={withForm(props.defaultForm, defaultToDo)}
      onChange={props.onChangeSetValue}
    />
  </div>
);

export const ToDo = withValue<RelatedValue, PropTypes>(ToDoComp, "ToDo");
