import { UTCDateTime } from "common/date-time/types";
import { UiFormat } from "common/types/ui-format";
import { ValueProps } from "common/with-value-for";
import { DateBase } from "./date-base";

interface PropTypes extends ValueProps<UTCDateTime> {
  uiFormat: UiFormat;
  minDate?: string;
  maxDate?: string;
  disabled?: boolean;
  allowClear?: boolean;
}

export const DateTime = ({
  uiFormat,
  minDate,
  maxDate,
  disabled,
  allowClear,
  value,
  onChange,
}: PropTypes) => (
  <DateBase
    value={value}
    disabled={disabled}
    minDate={minDate}
    maxDate={maxDate}
    culture={uiFormat.culture}
    dateFormat={uiFormat.dateFormat}
    timeFormat={uiFormat.timeFormat}
    allowClear={allowClear}
    onChange={onChange}
  />
);
