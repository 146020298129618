import { ForeignKey } from "common/types/foreign-key";
import { ValueComponent } from "common/with-value-for";
// eslint-disable-next-line import/no-cycle
import { AdvancedRecordSelectorBase } from "./base";
import { RecordSelectorPropTypes } from "./types";
// eslint-disable-next-line import/no-cycle
import { AdvancedSearchCtrl } from "./advanced-search";

export class AdvancedSingleRecordSelector extends ValueComponent<
  ForeignKey,
  RecordSelectorPropTypes
> {
  static readonly displayName = "AdvancedSingleRecordSelector";

  onRecordSelectorChange = (records: ForeignKey[]) => {
    this.setValue(records[0]);
  };

  render() {
    return (
      <AdvancedRecordSelectorBase
        {...this.props}
        content={undefined}
        allowMultipleSelect={false}
        value={[this.props.value]}
        onChange={this.onRecordSelectorChange}
        SearchCtrlComp={AdvancedSearchCtrl}
      />
    );
  }
}
