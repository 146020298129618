import { starredApi } from "common/api/starred";
import { ApiCall } from "common/types/api";
import { Subscription } from "common/types/preferences";
import { StarredItems, StarredService } from "common/types/starred";

const toggleStar = (
  site: string,
  entity: string,
  id: string,
  starred: boolean,
  items: StarredItems[],
): StarredItems[] =>
  items
    .filter((s) => s.site !== site || s.entity !== entity || s.id !== id)
    .concat(starred ? [{ site, entity, id }] : []);

export const getStarredFor = (
  items: StarredItems[],
  site: string,
  entity: string,
): string[] =>
  items.filter((s) => s.site === site && s.entity === entity).map((s) => s.id);

const subscriptions: Subscription[] = [];
const notify = () => subscriptions.forEach((fn) => fn());

export const starredService = (
  apiCall: ApiCall,
  starred: StarredItems[],
): StarredService => {
  const api = starredApi(apiCall);

  const mutate = (s: StarredItems[]) => {
    starred.splice(0, starred.length, ...s); // TODO: fix this!
    notify();
  };

  return {
    subscribe: (fn) => {
      subscriptions.push(fn);
      return () => subscriptions.splice(subscriptions.indexOf(fn), 1);
    },
    get: () => starred,
    star: (site, entity, id) =>
      api
        .star(site, entity, id)
        .then(() => mutate(toggleStar(site, entity, id, true, starred))),
    unstar: (site, entity, id) =>
      api
        .unstar(site, entity, id)
        .then(() => mutate(toggleStar(site, entity, id, false, starred))),
  };
};
