import { VerticalField } from "common/ui/field";
import { Selector } from "common/widgets/selector";
import { getTimeRangeTranslation } from "common/record/form/content/measurements/time-range/functions";
import { TimeRangeName } from "common/record/form/content/measurements/time-range/types";
import { timeRanges } from "common/record/form/content/measurements/time-range/consts";
import { ValueProps } from "common/with-value-for";

// TODO I'm not doing anything special. consider moving it up.

export const TimeRangeSelector = ({
  value,
  onChange,
}: ValueProps<TimeRangeName>) => {
  return (
    <VerticalField
      label={_("Time Range")}
      className="x-flex-grow-1"
      input={
        <Selector<TimeRangeName>
          className="qa-select-time-range"
          getOptionLabel={getTimeRangeTranslation}
          options={timeRanges}
          disableSorting={true}
          value={value}
          onChange={onChange}
        />
      }
    />
  );
};
TimeRangeSelector.displayName = "TimeRangeSelector";
