import { Component } from "react";
import { FormValidationProps } from "common/form/types";
import { getByBehaviorArgument } from "common/entities";
import { Entity } from "common/entities/types";
import { FormValue } from "common/record/actions/ui/purchase-order/receive-items/types";
import { Context } from "common/types/context";
import { PoDetails, PoItem } from "common/types/purchase-orders";
import { hasPermissionTo } from "common/functions/roles";
import { AlertWarning } from "common/widgets/alert";
import { FancyCheckboxLarge } from "common/widgets/fancy-checkbox";
import { ValueProps } from "common/with-value-for";
import { Header } from "../header";
import { isReceivingAll } from "./functions";
import { PoList } from "./list";

interface PropTypes extends ValueProps<FormValue>, FormValidationProps {
  context: Context;
  entity: Entity;
  details: PoDetails;
}

export class ReceiveItemsForm extends Component<PropTypes> {
  onItemsChange = (purchaseOrderItems: PoItem[]) =>
    this.props.onChange({ ...this.props.value, purchaseOrderItems });

  onCheckboxChange = (closeAfterReceive: boolean) =>
    this.props.onChange({ ...this.props.value, closeAfterReceive });

  render() {
    const {
      context,
      value,
      entity,
      details,
      formValidation,
      onFormValidationChange,
    } = this.props;
    const { entities, role, userTypes } = context;
    const { purchaseOrderItems, closeAfterReceive } = value;

    return (
      <>
        <Header context={context} entity={entity} details={details} />
        <PoList
          context={context}
          entity={getByBehaviorArgument(
            entities,
            "PurchaseOrderItem",
            "purchaseOrderEntity",
            entity.name,
          )} // po items entity
          closeAfterReceive={closeAfterReceive}
          formValidation={formValidation}
          onFormValidationChange={onFormValidationChange}
          value={purchaseOrderItems}
          onChange={this.onItemsChange}
        />
        <FancyCheckboxLarge
          label={_("Close PO after receiving")}
          disabled={!hasPermissionTo(userTypes, role, entity.name, "Update")}
          value={closeAfterReceive}
          onChange={this.onCheckboxChange}
        />
        {closeAfterReceive && !isReceivingAll(purchaseOrderItems) && (
          <AlertWarning
            className="x-margin-top-10-i"
            message={_(
              "The original requested quantities will be rewritten and the PO recalculated.",
            )}
          />
        )}
      </>
    );
  }
}
