import { Component } from "common/component";
import { findChildren } from "common/utils/tree";
import { hasSomeChildNodeSelected } from "common/widgets/tree/functions";
import type { TreePropTypes, Node, NodeLinkPropTypes } from "./types";
import { NodeLink } from "./node-link";

interface PropTypes extends TreePropTypes {
  node: Node;
  expandedByDefault: boolean;
}

interface StateType {
  isExpanded: boolean;
}

export class NodeComponent extends Component<PropTypes, StateType> {
  static readonly displayName = "NodeComponent";

  constructor(props: PropTypes) {
    super(props);
    this.state = {
      isExpanded: props.expandedByDefault ?? true,
    };
  }

  onAdd = () => {
    const { node, onAdd } = this.props;
    onAdd(node);
  };

  onExpand = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  displayNode = () => {
    const {
      nodes,
      node,
      selected,
      textToHighlight,
      allowMultipleSelection,
      isDisabled,
      onClick,
      onDisplay,
    } = this.props;
    const disabled = isDisabled ? isDisabled(node) : false;

    const nodeLinkProps: NodeLinkPropTypes = {
      node,
      onClick: onClick && !disabled ? onClick.bind(null, node) : undefined,
      selected,
      textToHighlight: textToHighlight,
      onExpand: this.onExpand,
      isExpanded: this.state.isExpanded,
      isDisabled: disabled,
      allowMultipleSelection: allowMultipleSelection,
      hasSomeChildSelected: hasSomeChildNodeSelected(node, selected, nodes),
      isSelected: selected?.some((sel) => sel.name === node.name),
    };
    if (onDisplay) {
      return onDisplay(nodeLinkProps);
    }
    return <NodeLink {...nodeLinkProps} />;
  };

  displayChildren = () => {
    const {
      nodes,
      node,
      selected,
      isDisabled,
      textToHighlight,
      expandedByDefault,
      onClick,
      onDisplay,
      onAdd,
      onExpand,
      allowMultipleSelection,
      isExpandable,
    } = this.props;

    return (
      <div className={`x-tree-children ${isExpandable ? "x-expand-tree" : ""}`}>
        {findChildren(nodes, node).map((child, i) => (
          <NodeComponent
            key={i}
            nodes={nodes}
            node={child}
            selected={selected}
            isDisabled={isDisabled}
            allowMultipleSelection={allowMultipleSelection}
            isExpandable={isExpandable}
            textToHighlight={textToHighlight}
            expandedByDefault={expandedByDefault}
            onAdd={onAdd}
            onClick={onClick}
            onDisplay={onDisplay}
            onExpand={onExpand}
          />
        ))}
      </div>
    );
  };

  render() {
    const { isExpandable, node, onAdd } = this.props;
    const { isExpanded } = this.state;
    const expandedClass = `fa fa-angle-${isExpanded ? "down" : "right"}`;

    if (node?.isGroup) {
      const hasChild = findChildren(this.props.nodes, node)?.length;

      return (
        <div className="x-tree-node">
          <div className="x-node-head">
            {isExpandable && hasChild ? (
              <button onClick={this.onExpand} className="x-expansion-button">
                <i
                  data-testid="toggle-expansion-icon"
                  className={expandedClass}
                />
              </button>
            ) : undefined}
            {this.displayNode()}
            {onAdd ? (
              <button
                className={`x-tree-add qa-tree-add-${node.name}`}
                aria-label={_("Add")}
                onClick={this.onAdd}
              >
                <i className="fa fa-plus-circle" />
              </button>
            ) : undefined}
          </div>
          {this.state.isExpanded ? this.displayChildren() : undefined}
        </div>
      );
    }
    return (
      <div className="x-tree-node">
        <div className="x-node-leaf">{this.displayNode()}</div>
      </div>
    );
  }
}
