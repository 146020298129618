import { Subject, Subscription } from "rxjs";

// Should be uncommented for new events with payload
// interface EventWithPayload<T, P> {
//   type: T;
//   payload: P;
// }

interface EventWithoutPayload<T> {
  type: T;
  payload?: never;
}

type RefreshPageEvent = EventWithoutPayload<"RefreshPage">;
type ChunkLoadingErrorEvent = EventWithoutPayload<"ChunkLoadingError">;

type Event = RefreshPageEvent | ChunkLoadingErrorEvent;

class EventBus {
  private subjects: Record<string, Subject<any>> = {};

  private getSubject = <T extends Event>(event: T): Subject<T["payload"]> => {
    if (!this.subjects[event.type]) {
      this.subjects[event.type] = new Subject<T["payload"]>();
    }
    return this.subjects[event.type] as Subject<T["payload"]>;
  };

  emit = <T extends Event>(event: T): void => {
    const subject = this.getSubject(event);
    subject.next(event.payload);
  };

  on = <T extends Event>(
    eventName: T["type"],
    callback: (data: T["payload"]) => void,
  ): Subscription => {
    if (!this.subjects[eventName]) {
      this.subjects[eventName] = new Subject<T["payload"]>();
    }
    return (this.subjects[eventName] as Subject<T["payload"]>).subscribe(
      callback,
    );
  };
}

export const eventBus = new EventBus();
