import { Entity } from "common/entities/types";
import { getIntFkId } from "common/functions/system-int";
import { Context } from "common/types/context";
import { DetailUiValue } from "common/record/types";
import { ProcedureTypes } from "common/types/procedure-type";
import { Properties } from "common/types/records";
import { SystemIntFk } from "common/types/system-int";
import { ValueProps } from "common/with-value-for";
import { RelatedValue } from "../types";
import { CompletableStatusContent } from "./completable-status-content";
import { MeterReadingStatusContent } from "./meter-reading-status-content";
import { OptionStatusContent } from "./option-status-content";

interface PropTypes extends ValueProps<RelatedValue> {
  context: Context;
  entity: Entity;
  isQuickInput: boolean;
  parentEntity: Entity;
  properties: Properties;
  shouldDisableUrgentRepair: boolean;
  onChangeForm: (properties: Properties) => void;
  onUrgentRepairProcedureIdChange: (procedureId: string) => void;
  recordDetail?: DetailUiValue;
  isDisabled?: (item: SystemIntFk) => boolean;
}

export const ProcedureTypeContent = ({
  context,
  entity,
  parentEntity,
  properties,
  recordDetail,
  isQuickInput,
  shouldDisableUrgentRepair,
  onUrgentRepairProcedureIdChange,
  onChangeForm,
  isDisabled,
  value,
  onChange,
}: PropTypes) => {
  const procedureTypeId = getIntFkId(properties.procedureTypeId);
  switch (procedureTypeId) {
    case ProcedureTypes.Completable:
      return (
        <CompletableStatusContent
          context={context}
          entity={entity}
          properties={properties}
          isQuickInput={isQuickInput}
          onChangeForm={onChangeForm}
          isDisabled={isDisabled}
          value={value}
          onChange={onChange}
        />
      );
    case ProcedureTypes.YesNoNotApplicable:
    case ProcedureTypes.PassFailUrgentRepair:
      return (
        <OptionStatusContent
          context={context}
          entity={entity}
          properties={properties}
          shouldDisableUrgentRepair={shouldDisableUrgentRepair}
          isQuickInput={isQuickInput}
          onUrgentRepairProcedureIdChange={onUrgentRepairProcedureIdChange}
          onChangeForm={onChangeForm}
          isDisabled={isDisabled}
          value={value}
          onChange={onChange}
        />
      );
    case ProcedureTypes.MeterReading:
      return isQuickInput && !properties.id ? null : (
        <MeterReadingStatusContent
          context={context}
          entity={entity}
          parentEntity={parentEntity}
          properties={properties}
          recordDetail={recordDetail}
          isDisabled={isDisabled}
          value={value}
          onChange={onChange}
        />
      );
    default:
      return null;
  }
};
