import { Record } from "common/types/records";
import { BulkTriggerPayload } from "./types";

export const mapPayload = (
  records: Record[],
  updateLastOpenDate: boolean,
): BulkTriggerPayload[] =>
  records.map((r) => ({
    scheduledEventId: r.properties.id,
    updateLastOpenDate,
  }));
