import { Entity } from "common/entities/types";
import { FormValidationProps } from "common/form/types";
import { HorizontalField } from "common/form/ui";
import { Context } from "common/types/context";
import { ScheduledEvent } from "common/types/scheduled-event";
import { YesNo } from "common/widgets/radio-button-list/yes-no";
import { CustomFields } from "./custom-fields";

interface PropTypes extends FormValidationProps {
  context: Context;
  entity: Entity;
  woEntity: Entity;
  value: ScheduledEvent;
  onChange: (value: ScheduledEvent) => void;
}

export const CustomFieldsForm = ({
  context,
  entity,
  woEntity,
  formValidation,
  onFormValidationChange,
  value,
  onChange,
}: PropTypes) => {
  const onCopyCustomFieldsToWoToggleChange = (copyCustomFieldsToWo: boolean) =>
    onChange({ ...value, copyCustomFieldsToWo });

  return (
    <>
      <HorizontalField
        className="qa-copy-custom-fields"
        label={_("Copy custom fields to {ENTITY_NAME}").replace(
          "{ENTITY_NAME}",
          woEntity?.localizedName ?? woEntity?.name ?? _("Work Orders"),
        )}
        disabled={false}
      >
        <YesNo
          name="copyCustomFieldsToWo"
          value={value.copyCustomFieldsToWo}
          onChange={onCopyCustomFieldsToWoToggleChange}
        />
      </HorizontalField>
      <CustomFields
        context={context}
        entity={entity}
        formValidation={formValidation}
        onFormValidationChange={onFormValidationChange}
        value={value}
        onChange={onChange}
      />
    </>
  );
};
