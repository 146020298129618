import * as R from "ramda";
import { Component } from "common/component";
import { ValueComponent } from "common/with-value-for";
import { Item, PropTypes as ItemProps } from "./item";
import { createDraggableItem } from "./item-draggable";
import { Value, PropTypes, Props } from "./types";

export function createItems<TItem>(id?: string) {
  const ItemDraggable = id && createDraggableItem<TItem>(id);

  class ItemsBase extends ValueComponent<Value<TItem>, PropTypes<TItem>> {
    static readonly displayName = "Items";

    onDelete = (index: number) => {
      const { value, onDelete } = this.props;
      if (onDelete) {
        onDelete(value[index])
          .then(() => {
            this.setValue(R.remove(index, 1, value));
          })
          .catch(() => undefined);
      } else {
        this.setValue(R.remove(index, 1, value));
      }
    };

    onMove = (fromIndex: number, toIndex: number) => {
      const { value } = this.props;
      const source = value[fromIndex];
      const withoutSource = R.remove(fromIndex, 1, value);
      const newValue = R.insert(toIndex, source, withoutSource);
      this.setValue(newValue);
    };

    onUpdate = (item: TItem, index: number) => {
      const { value } = this.props;
      this.setValue(R.update(index, item, value));
    };

    render() {
      const {
        className,
        onDisplay,
        canDelete,
        dragTest,
        onEdit,
        getName,
        small,
        withBorder,
        refresh,
        noReorder,
        hideArrows,
        value = [],
        hideOverflow,
        getItemStyle,
        getItemClass,
      } = this.props;

      const items = value.map((item, index) => {
        const itemProps: ItemProps<TItem> = {
          onDisplay,
          canDelete,
          dragTest,
          getName,
          small,
          allowReorder: !noReorder && ItemDraggable && value.length > 1,
          item,
          index,
          refresh,
          onEdit,
          onDelete: this.onDelete,
          onMove: this.onMove,
          onUpdate: this.onUpdate,
          isLast: index + 1 === value.length,
          hideOverflow,
          hideArrows,
          getItemStyle,
          getItemClass,
        };

        return itemProps.allowReorder ? (
          <ItemDraggable key={index} itemProps={itemProps} />
        ) : (
          <Item<TItem> key={index} {...itemProps} />
        );
      });

      return (
        <div
          className={
            "x-list-widget" +
            (small ? "-small" : "") +
            (withBorder ? "-with-border" : "") +
            (className ? " " + className : "")
          }
        >
          {items}
        </div>
      );
    }
  }

  // No drag-drop allowed unless an id is specified
  if (!id) return (props: Props<TItem>) => <ItemsBase {...props} />;

  // --- Items Draggable --------------------------------------------------- //

  class ItemsDraggable extends Component<Props<TItem>> {
    render() {
      return <ItemsBase {...this.props} />;
    }
  }

  // --- Items ------------------------------------------------------------- //

  const Items = (props: Props<TItem>) =>
    ItemDraggable ? <ItemsDraggable {...props} /> : <ItemsBase {...props} />;

  return Items;
}
