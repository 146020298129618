import * as R from "ramda";
import { Component } from "react";
import { Properties } from "common/types/records";
import { ChartConfig } from "common/data/chart/types";
import { getColumn } from "common/entities";
import { labelFunction } from "common/form/widget/label-function";
import { getRelatedEntityNameByPath } from "common/functions/path";
import { TableWithReorder } from "common/query/table";
import { TableValue } from "common/query/table/types";
import {
  ActionsByRecordId,
  isSelectField,
  QueryForEntity,
} from "common/query/types";
import { Context } from "common/types/context";
import { GoFn } from "common/types/url";
import { ValueProps } from "common/with-value-for";
import { getRecordsWithActions } from "x/records/list/functions";
import { Box } from "./box";
import { Cards } from "./cards";
import { Chart } from "./chart";
import { MapController } from "./map";
import {
  BoxConfig,
  CardsConfig,
  MapConfig,
  Output,
  TableConfigWithMerge,
} from "./types";

interface PropTypes extends ValueProps<QueryForEntity> {
  context: Context;
  output: Output;
  records: Properties[];
  actionsByRecordId?: ActionsByRecordId;
  running?: boolean;
  withLinks: boolean;
  reload: () => any;
  goTo: GoFn;
}

export class Dynamic extends Component<PropTypes> {
  labelizeChartData = () => {
    const { context, records = [], value } = this.props;
    const { entities } = context;

    const entityName = value?.entity;
    const querySelects = value?.query?.select || [];
    const querySelectFields = querySelects.filter(isSelectField);

    return records.map((item) =>
      R.mapObjIndexed((value: any, key: string) => {
        const select = R.find(
          (s) => s.alias === key || s.name === key,
          querySelectFields,
        );
        const selectEntityName =
          select && select.path
            ? getRelatedEntityNameByPath(entities, entityName, select.path)
            : entityName;
        const selectEntity = entities[selectEntityName];

        const columnKey = select ? select.name : key;
        const column =
          selectEntity && columnKey
            ? getColumn(selectEntity, columnKey)
            : undefined;

        const labelValue = column
          ? labelFunction({
              context,
              column,
              value,
              withLinks: false,
              entityName: selectEntityName,
            })
          : value;

        return R.isNil(labelValue) ? _("(Not set)") : labelValue;
      }, item),
    );
  };

  onTableChanged = (tableValue: TableValue) =>
    this.props.onChange(tableValue.query);

  render() {
    const {
      context,
      output,
      records,
      actionsByRecordId,
      running,
      reload,
      goTo,
      withLinks,
      value,
    } = this.props;

    switch (output.type) {
      case "Box": {
        const cfg = output.config as BoxConfig;
        return (
          <Box
            data={records}
            label={cfg.label}
            isCurrency={cfg.isCurrency}
            context={context}
            entityName={value?.entity}
            value={cfg.value}
          />
        );
      }
      case "Cards": {
        const cfg = output.config as CardsConfig;
        const { actions, title } = cfg;

        return (
          <Cards
            goTo={goTo}
            context={context}
            records={getRecordsWithActions(records, actionsByRecordId, actions)}
            query={value}
            title={title}
            actions={actions}
            running={running}
            reload={reload}
          />
        );
      }
      case "Map": {
        const cfg = output.config as MapConfig;
        return (
          <MapController
            data={records}
            context={context}
            defaultPin={cfg.defaultPin}
            selectedPin={cfg.selectedPin}
            showAll={cfg.showAll}
            mapEntity={cfg.mapEntity}
            mapId={cfg.mapId?.id}
            entity={value.entity}
          />
        );
      }
      case "Table":
        // TODO: do not allow things that change the table value
        return (
          <TableWithReorder
            starred={[]}
            toggleStar={undefined}
            goTo={undefined}
            context={context}
            config={output.config as TableConfigWithMerge}
            withLinks={withLinks}
            value={{
              data: records,
              query: value,
              secondaryQueries: undefined,
              widths: [],
              selected: [],
            }}
            onChange={this.onTableChanged}
            reload={reload}
            isLoading={running}
          />
        );
      case "Chart":
        return (
          <Chart
            config={output.config as ChartConfig}
            data={this.labelizeChartData()}
          />
        );
      default: {
        return (
          <div className="bg-danger text-center x-padding-10">
            {_("Invalid configuration type TYPE").replace(
              "TYPE",
              `${output.type}`,
            )}
          </div>
        );
      }
    }
  }
}
