import { adpApi } from "common/api/adp";
import { Component } from "common/component";
import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";
import {
  getNotFoundError,
  hasMeasurements,
} from "common/record/form/content/measurements/functions/measurements";
import { TimeRangeSelector } from "common/record/form/content/measurements/time-range";
import {
  getTimeRange,
  getTimeRangeFormat,
} from "common/record/form/content/measurements/time-range/functions";
import { TimeRangeName } from "common/record/form/content/measurements/time-range/types";
import { MeasurementsResponse } from "common/record/form/content/measurements/types";
import { TemperatureView } from "common/record/form/content/measurements/views/temperature";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { Record } from "common/types/records";
import { ApiError } from "common/ui/api-error";
import { LoadingIcon } from "common/widgets/loading-icon";

interface PropTypes {
  context: Context;
  entity: Entity;
  record: Record;
}

interface StateTypes {
  measurements: MeasurementsResponse;
  timeRange: TimeRangeName;
  isLoading: boolean;
  error: ApiErrorResponse;
}

export class MeasurementsController extends Component<PropTypes, StateTypes> {
  static readonly displayName = "MeasurementsController";

  state: StateTypes = {
    measurements: undefined,
    timeRange: "ALL",
    isLoading: false,
    error: undefined,
  };

  componentDidMount() {
    this.loadMeasurements();
  }

  componentDidUpdate(
    prevProps: Readonly<PropTypes>,
    prevState: Readonly<StateTypes>,
  ) {
    if (
      prevProps.entity.name !== this.props.entity.name ||
      prevProps.record.properties.externalId !==
        this.props.record.properties.externalId ||
      prevState.timeRange !== this.state.timeRange
    ) {
      this.loadMeasurements();
    }
  }

  loadMeasurements = () => {
    const { context, entity, record } = this.props;
    const { timeRange } = this.state;
    const { externalId } = record.properties;

    if (!behaveAs("ExternalId", entity) && !externalId) return;

    this.setState({ isLoading: true });
    adpApi(context.apiCall)
      .getMeasurements(externalId, getTimeRange(timeRange))
      .then((measurements) => {
        this.setState({
          measurements,
          error: hasMeasurements(measurements) ? undefined : getNotFoundError(),
          isLoading: false,
        });
      })
      .catch((e) => {
        const error = e?.status === 404 ? getNotFoundError() : e;
        this.setState({ error, isLoading: false });
      });
  };

  onRangeChange = (timeRange: TimeRangeName) => {
    this.setState({ timeRange });
  };

  getView = () => {
    const { measurements } = this.state;
    const { context, entity, record } = this.props;
    const dateFormat = getTimeRangeFormat(context, "ALL");

    return (
      <TemperatureView
        context={context}
        entity={entity}
        measurements={measurements}
        dateFormat={dateFormat}
        record={record}
      />
    );
  };

  render() {
    const { isLoading, error, timeRange } = this.state;

    const content = isLoading ? (
      <LoadingIcon />
    ) : error ? (
      <ApiError error={error} />
    ) : (
      this.getView()
    );

    return (
      <div className="x-measurements">
        <TimeRangeSelector value={timeRange} onChange={this.onRangeChange} />
        {content}
      </div>
    );
  }
}
