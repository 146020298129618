import { Warning } from ".";

interface PropTypes {
  onKeepWorking: () => any;
  onDiscard: () => any;
}

export const KeepWorkingOrDiscard = ({
  onKeepWorking,
  onDiscard,
}: PropTypes) => (
  <Warning
    title={_("Changes are not saved")}
    action1={_("Keep working on this record")}
    action2={_("Discard changes and continue to another page")}
    onAction1={onKeepWorking}
    onAction2={onDiscard}
  />
);
