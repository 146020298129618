import { FlukeReason } from "common/types/reasons";
import { formatLongDate } from "common/date-time/formatters";

interface PropTypes {
  reason: FlukeReason;
}

export const FlukeReasonComp = ({ reason }: PropTypes) => {
  const { triggeredValue, um, adminDesc, low, high, createdOn } = reason;

  return (
    <div>
      <p className="x-record-reason-warning">
        {_("A reading with value")}{" "}
        <strong>
          {triggeredValue}
          {um}{" "}
        </strong>
        {_("is outside the acceptable interval for the alarm on DATE").replace(
          "DATE",
          formatLongDate(createdOn),
        )}
        .
      </p>
      <p className="x-record-reason-info">
        {_("Acceptable interval ADMINDESC: From LOWUM to HIGHUMM")
          .replace("ADMINDESC", adminDesc)
          .replace("LOW", `${low}`)
          .replace("HIGH", `${high}`)
          .replace("UM", um)
          .replace("UMM", um)}
        .
      </p>
    </div>
  );
};
