import { Context } from "common/types/context";
import { RecentlyViewed } from "common/types/recently-viewed";
import { Tab } from "common/widgets/tabs/tab";
import { Tabs } from "common/widgets/tabs/tabs";
import { ValueProps } from "common/with-value-for";
import { RecentlyViewedList } from "./recently-viewed";
import { WallController } from "./wall";

interface PropTypes extends ValueProps<RecentlyViewed[]> {
  context: Context;
}

export const HistoryPanel = ({ context, value, onChange }: PropTypes) => {
  return (
    <Tabs
      key="right-panel-tabs"
      className="x-right-panel"
      evenSpacedTabs={true}
      smallFont={true}
    >
      <Tab value="recentlyViewed" label={_("Recently Viewed")}>
        <RecentlyViewedList
          entities={context.entities}
          sites={context.sites}
          value={value}
          onChange={onChange}
        />
      </Tab>
      <Tab value="wall" label={_("Wall")}>
        <WallController context={context} />
      </Tab>
    </Tabs>
  );
};
