import { getLocalizedName } from "common";
import { displayEntity } from "common/api/entities";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
import { getSiteLabelByName } from "x/account-settings/sites/functions";
import { getUserMenuEntries } from "x/layout/navigation/functions";
import { SearchRecord } from "./types";

const DEFAULT_SCROLL_HEIGHT = 400;
const DEFAULT_ITEM_HEIGHT = 30;

const getLabelForSystemEntity = (entity: Entity) => {
  switch (entity.name) {
    case "Comments":
      return _("Comments");
    case "Documents":
      return _("Documents");
    default:
      return entity.localizedName || entity.name;
  }
};

export const getEntitySiteLabel = (record: SearchRecord, context: Context) => {
  const parentEntity = context.entities[record.parentEntity];
  const entity = context.entities[record.entity];
  const parentEntityLabel = displayEntity(parentEntity);
  const entityLabel = entity?.isSystem
    ? getLabelForSystemEntity(entity)
    : displayEntity(entity);

  const siteLabel = getSiteLabelByName(context.sites, record.site);

  return [parentEntityLabel, entityLabel, siteLabel]
    .filter(Boolean)
    .join(" - ");
};

export const getEntityIcon = (entityName: string, context: Context) => {
  const entitySummary = getUserMenuEntries(context);
  const entityLabel = getLocalizedName(context.entities[entityName]);

  return (
    entitySummary.find((es) => es.label === entityLabel)?.icon ?? "fa-file-o"
  );
};

export const getTabLabel = (context: Context, entityName: string) => {
  const entity = context.entities[entityName];

  if (entityName === "all") return _("All");
  if (entity.isSystem) return getLabelForSystemEntity(entity);

  return getLocalizedName(entity);
};

export const getScrollHeight = (totalCount: number) =>
  totalCount * DEFAULT_ITEM_HEIGHT < DEFAULT_SCROLL_HEIGHT
    ? undefined
    : DEFAULT_SCROLL_HEIGHT;
