import * as R from "ramda";
import { findColumn } from "common/entities";
import { Entities, Entity } from "common/entities/types";
import { getDefaultQuery } from "common/query/for-table";
import { getPathMap } from "common/query/joins";
import { addToSelectQuery, setSelect } from "common/query/select";
import {
  isOrderField,
  isSelectField,
  isSelectFieldWithoutPath,
  isSummaryField,
  Order,
  Query,
  SelectField,
  SelectItem,
} from "common/query/types";
import { Filter } from "common/types/filters";
import { defaultPageSize } from "common/types/pagination";
import { Report } from "common/types/reports";
import { SITE_DATA_VIEW } from "common/types/view";
import { hasEmphasis } from "./common";

export const queryWithAvailableColumns = (
  entities: Entities,
  entity: Entity,
  query: Query,
) => {
  const name = entity?.name;
  const pathMap = getPathMap(entities, { entity: name, query });
  const queryEntities = R.values(pathMap);

  const queryColumnNames = R.flatten(
    queryEntities.map((e) => e.columns.map((c) => c.name)),
  );

  const querySelect = query?.select || [];
  const select = querySelect.filter((s) =>
    isSelectField(s) ? R.includes(s.name, queryColumnNames) : true,
  );
  return setSelect(select, query);
};

export const hasColumn = (col: string, select: SelectItem[]): boolean =>
  R.any(
    (s) =>
      !isSummaryField(s) &&
      (s.alias === col || (!s.alias && (s as SelectField).name === col)),
    select,
  );
const tryGetQuery = (filter: Filter) => filter && filter.query;

export const withSite = (query: Query): Query => {
  const { select = [] } = query;

  const selectWithSite = hasColumn("site", select)
    ? R.filter((s) => {
        if (isSelectField(s)) {
          return s.name !== "site";
        }
        return true;
      }, select)
    : select;
  return hasColumn("site", select)
    ? addToSelectQuery(selectWithSite, query)
    : query;
};

export const getListQuery = (
  entities: Entities,
  entity: Entity,
  report: Report,
  filter: Filter,
): Query => {
  const query = tryGetQuery(filter) || report?.query || getDefaultQuery(entity);

  const queryWithSite = withSite({ ...query, pageSize: defaultPageSize });

  if (report?.query) return queryWithSite;

  return queryWithAvailableColumns(entities, entity, queryWithSite);
};

const isOrderInSelect = (order: Order, select: SelectField[]) =>
  order?.every(
    (o) => isOrderField(o) && select?.some((s) => s.name === o.name),
  );

const selectHasEmphasis = (entity: Entity, select: SelectField[]) =>
  select?.some((item) => {
    const column = findColumn(entity.columns, item.name);
    return !!column && hasEmphasis(column);
  });

const getSelectAndOrder = (
  queryFromPref: Query,
  query: Query,
  entity: Entity,
) => {
  const selectFromPref = queryFromPref?.select;
  const orderFromPref = queryFromPref?.order;

  if (!selectFromPref) {
    return { select: query.select, order: query.order };
  }

  const selectFieldsWithoutPath = selectFromPref.filter(
    (select: SelectField) =>
      select.name !== "id" && isSelectFieldWithoutPath(select),
  ) as SelectField[];

  const selectWithEmphasis = selectHasEmphasis(entity, selectFieldsWithoutPath)
    ? selectFieldsWithoutPath
    : [{ name: "number" }, ...selectFieldsWithoutPath];

  const validOrderFromPref = isOrderInSelect(orderFromPref, selectWithEmphasis)
    ? orderFromPref
    : undefined;

  return { select: selectWithEmphasis, order: validOrderFromPref };
};

export const getAdvancedSearchListQuery = (
  entities: Entities,
  entity: Entity,
  queryFromPref: Query,
): Query => {
  if (!entity) return undefined;

  const entityWithoutSiteColumn = {
    ...entity,
    columns: entity.columns.filter((c) => c.relatedEntity !== SITE_DATA_VIEW),
  };
  const query = getDefaultQuery(entityWithoutSiteColumn);
  const queryWithPageSize: Query = {
    ...query,
    pageSize: defaultPageSize,
    ...getSelectAndOrder(queryFromPref, query, entity),
  };

  return queryWithAvailableColumns(entities, entity, queryWithPageSize);
};
