import * as R from "ramda";
import { Culture } from "common/culture/supported-cultures";
import { Dashboard, Widget } from "common/types/dashboards";
import { cleanUp, translateName } from "./functions";
import { Captions } from "./types";

export const translateWidget =
  (captions: Captions) =>
  (widget: Widget): Widget => {
    const label = captions[widget.name];

    return R.mergeRight(widget, { label });
  };

export const translate = (culture: Culture, db: Dashboard): Dashboard => {
  if (!db) return db;

  const { id, label, labels, widgets } = db;

  const captions = labels ? labels[culture] : {};

  return R.isEmpty(captions || {})
    ? R.mergeRight(db, {
        label: label || (id && `${_("Dashboard")} ${id}`) || "",
      })
    : R.mergeRight(db, {
        label: translateName(captions),
        widgets: widgets.map(translateWidget(captions)),
      });
};

const extractWidgetCaptions = (db: Dashboard): Captions =>
  db.widgets
    ? R.fromPairs(db.widgets.map((w) => [w.name, w.label] as [string, string]))
    : {};

export const extractCaptions = (db: Dashboard): Captions =>
  db ? R.mergeRight({ name: db.label }, extractWidgetCaptions(db)) : {};

export const getKeys = (db: Dashboard): string[] => {
  if (!db) return [];
  const { widgets = [] } = db;
  return ["name", ...widgets.map((w) => w.name)];
};

const cleanUpDashboardLabels = cleanUp(getKeys);

export const extract = (culture: Culture, db: Dashboard): Dashboard =>
  db
    ? cleanUpDashboardLabels(
        R.mergeRight(db, {
          labels: R.mergeRight(db.labels, {
            [culture]: extractCaptions(db),
          }),
        }),
      )
    : undefined;

// todo: rename to getDefaultKeyLabels
export const translateKey =
  (db: Dashboard) =>
  (key: string): string => {
    if (!db) return undefined;

    return key === "name"
      ? db.label || _("Name")
      : key.replace(/^widget\.(.+)$/, `${_("Widget")} $1`);
  };
