import { ValueProps } from "common/with-value-for";
import { getRelatedRecordIds } from "../common/functions";
import { PropTypes } from "../table-with-form/types";
import { StandardRelated } from "../standard-related";
import { RelatedValue } from "../types";
import { updateContext } from "./functions";

type Props = PropTypes & ValueProps<RelatedValue>;

export const RequisitionerGroup = (props: Props) => {
  const { context, entity, value } = props;

  const ids = getRelatedRecordIds(entity.name, "groupId", value);

  return (
    <StandardRelated {...props} context={updateContext(context, entity, ids)} />
  );
};
RequisitionerGroup.displayName = "RequisitionerGroup";
