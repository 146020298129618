import { Entity } from "common/entities/types";
import { addFilter } from "common/query/filter";
import { addToSelectQuery } from "common/query/select";
import { QueryForEntity } from "common/query/types";

const selectId = [{ name: "id" }];

export const getContactsQuery = (
  entity: Entity,
  email: string,
): QueryForEntity =>
  addFilter(
    { and: [{ name: "email", op: "eq", value: email }] },
    { entity: entity.name, query: addToSelectQuery(selectId, entity.query) },
  );
