import { Component } from "react";
import { getByBehaviorArgument } from "common/entities";
import { Entity } from "common/entities/types";
import { HorizontalField } from "common/form/ui";
import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import {
  DEFAULT_PROJECTION_FREQUENCY,
  ProjectionFrequencyProps,
  ScheduledEventBase,
  PmTrigger,
} from "common/types/scheduled-event";
import { YesNo } from "common/widgets/radio-button-list/yes-no";
import { ValueProps } from "common/with-value-for";
import { PmCalendars } from "./calendars";
import { HasProjections } from "./has-projections";
import { PmOverrides } from "./overrides";
import { ProjectionFrequency } from "./projection-frequency";
import { PmThreshold } from "./threshold";

interface PropTypes extends ValueProps<ScheduledEventBase> {
  context: Context;
  entity: Entity;
  isShadow: boolean;
  assetsIds?: string[];
}

export class WithProjections extends Component<PropTypes> {
  static readonly displayName = "WithProjections";

  onChangeCalendars = (calendarIds: number[] = []) => {
    this.props.onChange({ ...this.props.value, calendarIds });
  };

  onChangeOverrides = (overriddenBy: ForeignKey[] = []) => {
    this.props.onChange({ ...this.props.value, overriddenBy });
  };

  onChangePmThreshold = (suppressionDays: number) => {
    this.props.onChange({ ...this.props.value, suppressionDays });
  };

  onChangeHasProjections = (hasProjections: boolean) => {
    const { projectionInterval, projectionLength } = this.props.value;
    const {
      projectionInterval: defaultInterval,
      projectionLength: defaultLength,
    } = DEFAULT_PROJECTION_FREQUENCY;

    const frequencyValue = hasProjections
      ? {
          projectionInterval: projectionInterval ?? defaultInterval,
          projectionLength: projectionLength ?? defaultLength,
        }
      : { projectionInterval: undefined, projectionLength: undefined };

    this.props.onChange({
      ...this.props.value,
      hasProjections,
      ...frequencyValue,
    });
  };

  onChangeProjectionFrequency = (frequencyValue: ProjectionFrequencyProps) => {
    this.props.onChange({ ...this.props.value, ...frequencyValue });
  };

  onChangePopulateChildAssets = (populateChildrenAssets: boolean) => {
    this.props.onChange({ ...this.props.value, populateChildrenAssets });
  };

  render() {
    const { context, entity, value, isShadow, assetsIds } = this.props;
    const {
      suppressionDays,
      calendarIds,
      id,
      overriddenBy,
      hasProjections,
      projectionLength,
      projectionInterval,
      trigger,
      type,
    } = value;
    const isSchedulerEnabled = context.isEnabledFeature("scheduler");

    const displayProjectionsSection =
      type === "I" || !!(type === "P" && (trigger as PmTrigger)?.frequency);

    const projectionsMessage =
      isSchedulerEnabled && type === "P" && !hasProjections
        ? _("Projections are required for Scheduler")
        : undefined;

    const workOrderAssetEntity = getByBehaviorArgument(
      context.entities,
      "WorkOrderAsset",
      "workOrderEntity",
      entity.arguments?.workOrderEntity,
    );

    return (
      <div className="x-se-with-projections">
        {context.isEnabledFeature("calendar") ? (
          <div>
            <PmThreshold
              value={suppressionDays}
              onChange={this.onChangePmThreshold}
            />
            <PmCalendars
              calendars={context.calendars}
              value={calendarIds}
              onChange={this.onChangeCalendars}
            />
          </div>
        ) : undefined}
        <PmOverrides
          context={context}
          entity={entity}
          recordId={id}
          assetsIds={assetsIds}
          value={overriddenBy}
          onChange={this.onChangeOverrides}
        />
        {displayProjectionsSection ? (
          <HasProjections
            message={projectionsMessage}
            isShadow={isShadow}
            value={hasProjections}
            onChange={this.onChangeHasProjections}
          />
        ) : undefined}
        {displayProjectionsSection && hasProjections ? (
          <ProjectionFrequency
            value={{ projectionInterval, projectionLength }}
            onChange={this.onChangeProjectionFrequency}
          />
        ) : undefined}
        <HorizontalField
          className="qa-populate-child-assets"
          label={_("Populate children Assets in {ENTITY_NAME}").replace(
            "{ENTITY_NAME}",
            workOrderAssetEntity?.localizedName ??
              workOrderAssetEntity?.name ??
              _("Work Order Assets"),
          )}
          disabled={false}
        >
          <YesNo
            name="populateChildrenAssets"
            className="x-padding-top-10"
            value={value.populateChildrenAssets}
            onChange={this.onChangePopulateChildAssets}
          />
        </HorizontalField>
      </div>
    );
  }
}
