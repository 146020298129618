export const LAST_DAY_OF_MONTH = "L";
export type ExpressionField = number | string;
export type CronTuple = [number, number, ExpressionField, number, number];

export enum CronField {
  Minute,
  Hour,
  DayOfMonth,
  Month,
  DayOfWeek,
}

export const FREQUENCIES: { [index: string]: string } = {
  everyDay: "0 0 * * *",
  everyWeek: "0 0 * * 0",
  everyMonth: "0 0 1 * *",
  everyYear: "0 0 1 1 *",
};
