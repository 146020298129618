import * as R from "ramda";
import { Output, TableConfigWithMerge } from "common/data/types";
import { ReportLayout } from "common/form/types";
import { RunQuery } from "common/query/types";
import { Reload } from "common/record/types";
import { Report as ReportWidget } from "common/reporting/report";
import { Context } from "common/types/context";
import { Tab } from "common/widgets/tabs/tab";
import { Tabs } from "common/widgets/tabs/tabs";

interface PropTypes {
  context: Context;
  reports: ReportLayout[];
  reportId: number;
  label: string;
  runQuery: RunQuery;
  reload: Reload;
  entityName: string;
}

const tableConfig: TableConfigWithMerge = {
  allowDrag: true,
  allowOrder: true,
  mergeSites: false,
  showMergeOption: false,
  ignore: ["id"],
};

const outputTable: Output = {
  type: "Table",
  config: tableConfig,
};

export const RecordReport = ({
  context,
  reports,
  reportId,
  label,
  runQuery,
  reload,
  entityName,
}: PropTypes) => (
  <Tabs key={reportId}>
    <Tab value={label} label={label}>
      <ReportWidget
        context={context}
        reportLayout={R.find((rpt) => rpt.id === reportId, reports)}
        output={outputTable}
        runQuery={runQuery}
        reload={reload}
        entityName={entityName}
        idFilter={true}
      />
    </Tab>
  </Tabs>
);
