import { behaveAs } from "common/entities";
import { Entity } from "common/entities/types";
// eslint-disable-next-line import/no-cycle
import { Groups } from "common/form";
// eslint-disable-next-line import/no-cycle
import { ConditionMonitoringForm } from "common/form/behavior-forms/condition-monitoring";
// eslint-disable-next-line import/no-cycle
import { EmailDomainRulesForm } from "common/form/behavior-forms/email-domain-rules";
// eslint-disable-next-line import/no-cycle
import { MeterForm } from "common/form/behavior-forms/meter-form";
import { FormValidationProps, Layout as FormLayout } from "common/form/types";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { ValueProps } from "common/with-value-for";

interface PropTypes extends ValueProps<Properties>, FormValidationProps {
  context: Context;
  parentEntity?: Entity;
  entity: Entity;
  layout: FormLayout;
  withLinks: boolean;
  allowDynamicValues?: boolean;
}

export const BehaviorOverviewForm = ({
  context,
  parentEntity,
  entity,
  layout,
  withLinks,
  allowDynamicValues,
  formValidation,
  onFormValidationChange,
  value = {},
  onChange,
}: PropTypes) => {
  if (behaveAs("Meter", entity)) {
    return (
      <MeterForm
        context={context}
        parentEntity={parentEntity}
        entity={entity}
        withLinks={withLinks}
        groups={layout?.groups}
        formValidation={formValidation}
        onFormValidationChange={onFormValidationChange}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (behaveAs("ConditionMonitoring", entity)) {
    return (
      <ConditionMonitoringForm
        context={context}
        entity={entity}
        withLinks={withLinks}
        formValidation={formValidation}
        onFormValidationChange={onFormValidationChange}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (behaveAs("EmailDomainRules", entity)) {
    return (
      <EmailDomainRulesForm
        context={context}
        groups={layout?.groups}
        entity={entity}
        withLinks={withLinks}
        allowDynamicValues={allowDynamicValues}
        formValidation={formValidation}
        onFormValidationChange={onFormValidationChange}
        value={value}
        onChange={onChange}
      />
    );
  }

  return (
    <Groups
      context={context}
      groups={layout?.groups}
      entity={entity}
      withLinks={withLinks}
      allowDynamicValues={allowDynamicValues}
      formValidation={formValidation}
      onFormValidationChange={onFormValidationChange}
      value={value}
      onChange={onChange}
    />
  );
};
