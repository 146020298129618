import { defaultFor } from "common";
import { getByBehaviorArgument } from "common/entities";
import type { Entities } from "common/entities/types";
import { mapLayout } from "common/form/functions/layout";
import type { Layout } from "common/form/types";
import { disableColumns } from "common/functions/group-column";
import { isRelatedEvent } from "common/functions/scheduler";
import { merge2, mergeChain } from "common/merge";
import type { FormValue, StandardUiValue } from "common/record/types";
import type { Record } from "common/types/records";
import type { RelatedEvent } from "common/types/scheduler";
import type { EventModel } from "x/scheduler2/types";

export const isMyPersonalEvent = (userName: string, event: EventModel) =>
  isRelatedEvent(event?.eventData) &&
  event?.eventData?.ownerId?.email === userName;

export const getPersonalEventEntity = (
  entities: Entities,
  ownerEntityName: string,
  event: RelatedEvent,
) =>
  entities[event?.entityName] ??
  getByBehaviorArgument(entities, "Event", "ownerEntity", ownerEntityName);

export const wrapFormValue =
  (isOwner: boolean) => (formValue: FormValue, record: Record) => {
    const defaultUi = defaultFor<StandardUiValue>();
    const uiForm = merge2("detail", "form", record.properties, defaultUi);

    return mergeChain(formValue)
      .set("record", record)
      .set("ui", isOwner ? uiForm : undefined)
      .output();
  };

export const mapPersonalEventLayout = (layout: Layout) => ({
  ...mapLayout(disableColumns(["ownerId"]), layout),
  onlyGroups: true,
});
