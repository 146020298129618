import { Component } from "react";
import { Context } from "common/types/context";
import { omitDataTypes } from "common/entities/entity-column/data-type/functions";
import { ValueProps } from "common/with-value-for";
import {
  QueryForEntity,
  Query,
  JoinItem,
  Filter,
  GroupItem,
  OrderItem,
  SelectItem,
} from "common/query/types";
import {
  filterBlackList,
  queryBuilderBlackList,
} from "common/entities/entity-column/data-type/types";
import { VerticalField } from "common/ui/field";
import { ConditionTypes } from "common/types/condition-type";
import { getPathMap } from "common/query/joins";
import { getFields, getFromEntities, sanitize } from "./functions";
import { Paging } from "./paging";
import { From } from "./from";
import { Select } from "./select";
import { Joins } from "./joins";
import { Condition } from "./condition";
import { Group } from "./group";
import { Order } from "./order";

interface PropTypes extends ValueProps<QueryForEntity> {
  context: Context;
  withPaging: boolean;
  conditionTypes?: ConditionTypes;
  allowFiltersOnUnboundQueries?: boolean;
}

const defaultQuery: Query = { select: undefined };

export class QueryBuilder extends Component<PropTypes> {
  static readonly displayName = "QueryBuilder";

  onChangeSelect = (select: SelectItem[]) => {
    const { value } = this.props;
    this.props.onChange({ ...value, query: { ...value.query, select } });
  };

  onChangeFrom = (entity: string) => {
    const { value, onChange } = this.props;
    if (value.entity === entity) return;
    onChange({ ...value, entity, query: { select: [] } });
  };

  onChangeFilter = (filter: Filter) => {
    const { value } = this.props;
    this.props.onChange({ ...value, query: { ...value.query, filter } });
  };

  onChangeJoin = (joins: JoinItem[]) => {
    const { context, value, onChange } = this.props;
    const newQuery = sanitize(context.entities, {
      ...value,
      query: { ...value.query, joins },
    });

    onChange(newQuery);
  };

  onChangeGroup = (group: GroupItem[]) => {
    const { value } = this.props;
    this.props.onChange({ ...value, query: { ...value.query, group } });
  };

  onChangeHaving = (having: Filter) => {
    const { value } = this.props;
    this.props.onChange({ ...value, query: { ...value.query, having } });
  };

  onChangeOrder = (order: OrderItem[]) => {
    const { value } = this.props;
    this.props.onChange({ ...value, query: { ...value.query, order } });
  };

  onChangeQuery = (query: Query) => {
    const { value } = this.props;
    this.props.onChange({ ...value, query });
  };

  render() {
    const {
      withPaging,
      value,
      context,
      conditionTypes,
      allowFiltersOnUnboundQueries,
    } = this.props;

    const { entities } = context;
    const fields = getFields(entities, value);
    const filteredFields = omitDataTypes(fields, filterBlackList);
    const queryBuilderFilteredFields = omitDataTypes(
      fields,
      queryBuilderBlackList,
    );
    const query = value.query || defaultQuery;
    const pagingComponent = withPaging ? (
      <VerticalField
        label={_("Paging")}
        input={<Paging value={query} onChange={this.onChangeQuery} />}
      />
    ) : undefined;
    const pathMap = getPathMap(context.entities, value);

    const fromField = (
      <From
        entities={getFromEntities(context)}
        value={value.entity}
        onChange={this.onChangeFrom}
      />
    );
    const selectField = (
      <Select
        context={context}
        allowAggregates={true}
        forceFkAggregates={false}
        allowLong={true}
        entities={context.entities}
        fields={queryBuilderFilteredFields}
        withExpressions={true}
        value={query.select}
        onChange={this.onChangeSelect}
      />
    );
    const joinsField = (
      <Joins
        entities={entities}
        entityName={value.entity}
        value={query.joins}
        onChange={this.onChangeJoin}
      />
    );
    const filterField = (
      <Condition
        pathMap={pathMap}
        allowAggregates={false}
        fields={filteredFields}
        context={context}
        entityName={value.entity}
        conditionTypes={conditionTypes}
        allowFiltersOnUnboundQueries={allowFiltersOnUnboundQueries}
        withExpressions={true}
        value={query.filter}
        onChange={this.onChangeFilter}
      />
    );
    const groupField = (
      <Group
        context={context}
        query={value}
        fields={queryBuilderFilteredFields}
        withExpressions={true}
        value={query.group}
        onChange={this.onChangeGroup}
      />
    );
    const orderField = (
      <Order
        context={context}
        fields={queryBuilderFilteredFields}
        allowAggregates={true}
        value={query.order}
        onChange={this.onChangeOrder}
      />
    );
    const havingField = (
      <Condition
        pathMap={pathMap}
        allowAggregates={true}
        context={context}
        entityName={value.entity}
        fields={filteredFields}
        conditionTypes={conditionTypes}
        disableSubQueries={true}
        value={query.having}
        onChange={this.onChangeHaving}
      />
    );

    return (
      <div className="x-query-builder qa-query-builder">
        <VerticalField
          label={_("From")}
          className={"x-from qa-from"}
          input={fromField}
        />
        {pagingComponent}
        <VerticalField
          label={_("Select")}
          className={"x-select qa-select"}
          input={selectField}
        />
        <VerticalField
          label={_("Joins")}
          className={"x-joins qa-joins"}
          input={joinsField}
        />
        <VerticalField
          label={_("Filter By")}
          className={"x-filter-by qa-filter-by"}
          input={filterField}
        />
        <VerticalField
          label={_("Group By")}
          className={"x-group-by qa-group-by"}
          input={groupField}
        />
        <VerticalField
          label={_("Having")}
          className={"x-having qa-having"}
          input={havingField}
        />
        <VerticalField
          label={_("Order By")}
          className={"x-order-by qa-order-by"}
          input={orderField}
        />
      </div>
    );
  }
}
