import { behaveAs } from "common/entities";
import { EntityColumn } from "common/entities/entity-column/types";
import {
  FormValidationProps,
  RecordSitesProps,
  WidgetOverwrite,
} from "common/form/types";
// eslint-disable-next-line import/no-cycle
import { InputWidget } from "common/form/widget/input-widget";
import { LabelWidget } from "common/form/widget/label-widget";
import { SelectField } from "common/query/types";
import { Context } from "common/types/context";
import { MaxLength } from "common/widgets/max-length";
import { Required } from "common/widgets/required";
import { SelfReference } from "common/widgets/self-reference";
import { withUniqueRunQuery } from "common/widgets/unique/query";
import { WithValue, withValue } from "common/with-value";
import { required as hasValue } from "common/validate";
import { arrayToString } from "common/utils/array";
import { getReadOnlyFieldIcon } from "./functions";

interface PropTypes
  extends WidgetOverwrite,
    FormValidationProps,
    RecordSitesProps {
  context: Context;
  column: EntityColumn;
  required: boolean;
  disabled: boolean;
  readOnly: boolean;
  withLinks: boolean;
  buffer: boolean;
  entityName: string;
  recordId: string;
  isRestricted?: boolean;
  inputId?: string;
  addToSelect?: SelectField[];
}

type Props = PropTypes & WithValue<unknown>;

const WidgetBuilder = (props: Props) => {
  const {
    context,
    required,
    isRestricted,
    readOnly,
    column,
    formValidation,
    onFormValidationChange,
    entityName,
    recordId,
    recordSites,
    value,
    onChangeSetValue,
  } = props;

  const widget = readOnly ? (
    <div
      className={arrayToString([
        "x-read-only-label-wrapper",
        !(column.isSystem && column.readOnly) && required && !hasValue(value)
          ? "x-has-error"
          : undefined,
      ])}
    >
      <div className="x-read-only-label">
        <LabelWidget {...props} />
      </div>
      <div className="x-read-only-icon">
        {getReadOnlyFieldIcon(column, isRestricted)}
      </div>
    </div>
  ) : (
    <InputWidget
      {...props}
      validate={true}
      allowClear={!required}
      formValidation={formValidation}
      recordSites={recordSites}
      onFormValidationChange={onFormValidationChange}
      onChange={onChangeSetValue}
    />
  );

  const requiredWidget =
    required && !readOnly ? (
      <Required value={value}>{widget}</Required>
    ) : (
      widget
    );

  const maxLengthWidget = column.maxLength ? (
    <MaxLength value={value} maxLength={column.maxLength}>
      {requiredWidget}
    </MaxLength>
  ) : (
    requiredWidget
  );

  const entity = context.entities[entityName];
  const isTreeEntityWithColumn =
    behaveAs("Tree", entity) && entity.arguments.fkColumn === column.name;

  return isTreeEntityWithColumn ? (
    <SelfReference
      column={column}
      entityName={entityName}
      recordId={recordId}
      value={value}
    >
      {maxLengthWidget}
    </SelfReference>
  ) : (
    maxLengthWidget
  );
};

export const Widget = withUniqueRunQuery(
  withValue<unknown, PropTypes>(WidgetBuilder, "Widget"),
);
