import * as React from "react";
import * as R from "ramda";
import "leaflet/dist/leaflet.css";
import * as Leaflet from "leaflet";
import { RGBColor } from "common/types/colors";
import { ValueProps } from "common/with-value-for";
import { stringifyRgb } from "common/functions/colors";
import { getPins, getImgUrlFor } from "./functions";
import { Pin, MapValue, GetInfo } from "./types";

export const DEFAULT_PIN_COLOR: RGBColor = {
  r: 38,
  g: 127,
  b: 202,
};

interface PropTypes extends ValueProps<MapValue> {
  imageUrl: string;
  getInfo: GetInfo;
  isReadOnly: boolean;
}

interface PopupEvent extends Leaflet.LeafletEvent {
  popup: Leaflet.Popup;
}

type Props = PropTypes & ValueProps<MapValue>;

export class LeafletWrapper extends React.Component<PropTypes> {
  static readonly displayName = "LeafletWrapper";
  map: Leaflet.Map;
  imageOverlay: Leaflet.ImageOverlay;
  pinLayer: Leaflet.LayerGroup;
  resetControl: Leaflet.Control;

  componentDidUpdate(oldProps: Props) {
    if (oldProps.imageUrl !== this.props.imageUrl) {
      this.map.removeLayer(this.imageOverlay);
      this.map.removeControl(this.resetControl);
      this.imageOverlay = undefined;
      this.resetControl = undefined;
    }

    const oldPins = getPins(oldProps.value);
    const newPins = getPins(this.props.value);
    if (!R.equals(newPins, oldPins)) {
      this.addPins(newPins, this.pinLayer);
    }
  }

  setPopupContent = (pin: Pin) => (e: PopupEvent) => {
    const { value, getInfo, onChange } = this.props;
    onChange({ ...value, selected: pin });
    getInfo(pin).then((content) => e.popup.setContent(content));
  };

  onPopupClosed = () => {
    const { value, onChange } = this.props;
    if (value.selected) onChange({ ...value, selected: undefined });
  };

  getUpdatedPins = (pin: Pin, index: number) => (e: Leaflet.LeafletEvent) => {
    const { value, onChange } = this.props;
    const { lat, lng } = e.target.getLatLng();

    const newPins: Pin[] = R.update(
      index,
      { ...pin, lat, lng },
      getPins(this.props.value),
    );

    onChange({ ...value, pins: newPins });
  };

  toMarker = (pin: Pin, index: number): Leaflet.Marker => {
    const { color = DEFAULT_PIN_COLOR, lat, lng } = pin;

    const markerIcon = Leaflet.divIcon({
      popupAnchor: [1, -34],
      html: `<div style='background-color:${stringifyRgb(
        color,
      )};' class='marker-pin'></div>`,
    });

    const marker = Leaflet.marker(Leaflet.latLng(lat, lng), {
      icon: markerIcon,
      draggable: !this.props.isReadOnly,
    });

    marker
      .on("dragend", this.getUpdatedPins(pin, index))
      .on("popupopen", this.setPopupContent(pin))
      .on("popupclose", this.onPopupClosed);

    const popup = Leaflet.popup().setContent(
      '<i class="fa fa-spinner fa-spin/">',
    );

    return marker.bindPopup(popup);
  };

  createResetControl = (bounds: Leaflet.LatLngBounds) => {
    if (this.resetControl) return null;

    const resetControl = new Leaflet.Control({ position: "topleft" });
    resetControl.onAdd = (map) => {
      const resetButton = Leaflet.DomUtil.create(
        "a",
        "fa fa-crosshairs x-leaflet-reset-button",
      );
      Leaflet.DomEvent.disableClickPropagation(resetButton).on(
        resetButton,
        "click",
        () => map.fitBounds(bounds),
      );
      return resetButton;
    };

    this.resetControl = resetControl;

    return resetControl;
  };

  addPins = (pins: Pin[], pinLayer?: Leaflet.LayerGroup) => {
    pinLayer?.clearLayers();

    pins.forEach((pin, i) => {
      const marker = this.toMarker(pin, i);
      pinLayer?.addLayer(marker);
    });
  };

  onLoadImage = (e: React.ChangeEvent<HTMLImageElement>) => {
    if (this.imageOverlay) return;

    const { value, imageUrl, onChange } = this.props;
    const { width, height } = e.target;

    onChange({ ...value, imgSize: { width, height } });

    const newMap =
      this.map ??
      Leaflet.map("mapContainer", {
        minZoom: -2,
        zoom: 0,
        zoomSnap: 0.5,
        zoomDelta: 0.5,
        crs: Leaflet.CRS.Simple,
      });

    const bounds = new Leaflet.LatLngBounds([0, 0], [height, width]);

    newMap.addControl(this.createResetControl(bounds));

    const newImageOverlay = Leaflet.imageOverlay(
      getImgUrlFor(imageUrl),
      bounds,
    ).addTo(newMap);

    newMap.fitBounds(bounds);

    const pinLayer = Leaflet.layerGroup([]).addTo(newMap);
    this.addPins(getPins(value), pinLayer);

    this.map = newMap;
    this.pinLayer = pinLayer;
    this.imageOverlay = newImageOverlay;
  };

  render() {
    return (
      <div className="x-map-wrapper">
        <img
          className="x-leaflet-img"
          src={getImgUrlFor(this.props.imageUrl)}
          onLoad={this.onLoadImage}
        />
        <div id="mapContainer" className="x-leaflet-img-overlay" />
      </div>
    );
  }
}
