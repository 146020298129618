import { ValueComponent } from "common/with-value-for";
import { truncate } from "common";
import { WorkOrderActionRecord } from "./types";

interface PropTypes {
  records: WorkOrderActionRecord[];
  showError?: (index: number) => boolean;
}

export class RecordsSidebar extends ValueComponent<number, PropTypes> {
  static readonly displayName = "RecordsSidebar";

  onClick = (index: number) => () => this.setValue(index);

  createRecordItem = (record: WorkOrderActionRecord, index: number) => {
    const { recordNumber } = record;
    const { value, showError } = this.props;
    const isSelected = index === value;
    const hasError = showError && showError(index);
    const label = truncate(recordNumber.toString(), 8);
    return (
      <li
        key={index}
        className={isSelected ? "x-selected" : ""}
        onClick={isSelected ? undefined : this.onClick(index)}
      >
        {`${label}`}
        {hasError && (
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
        )}
      </li>
    );
  };

  render() {
    const { records = [] } = this.props;

    return (
      <div className="x-wo-action-sidebar">
        <div className="x-sidebar-header">
          {`${_("Records")} (${truncate(records.length.toString(), 12)})`}
        </div>
        <ul>{records.map((r, i) => this.createRecordItem(r, i))}</ul>
      </div>
    );
  }
}
