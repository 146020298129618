import { FormValue } from "common/record/actions/ui/purchase-order/receive-items/types";
import {
  PoDetails,
  PoItem,
  ReceivePayload,
} from "common/types/purchase-orders";
import { currency } from "common/validate";

export const isValidUnitCost = (unitCost: string) =>
  parseFloat(unitCost) && currency(unitCost);

export const outstandingQty = ({ orderedQty, receivedQty = 0 }: PoItem) =>
  orderedQty - receivedQty || 0;

export const isValidReceiving = (item: PoItem, items: PoItem[]) => {
  const formIsReceivingItems = items.some(
    ({ receivingQty }) => receivingQty > 0,
  );
  return (
    item.receivingQty <= outstandingQty(item) &&
    (formIsReceivingItems
      ? item.receivingQty >= 0 // some item is > 0 so we can accept zeroes
      : item.receivingQty > 0)
  );
};

export const isValidForm = ({ purchaseOrderItems }: FormValue) => {
  return (
    purchaseOrderItems.length &&
    purchaseOrderItems.every((item) => {
      const { unitCost } = item;
      return (
        outstandingQty(item) === 0 || // fully received
        (isValidReceiving(item, purchaseOrderItems) &&
          isValidUnitCost(unitCost))
      );
    })
  );
};

export const isReceivingAll = (items: PoItem[]) =>
  items.every(
    (item) =>
      outstandingQty(item) === 0 || item.receivingQty === outstandingQty(item),
  );

export const createReceivePayload = (
  formValue: FormValue,
  { adjustmentType }: PoDetails,
): ReceivePayload => {
  const purchaseOrderItems = formValue.purchaseOrderItems
    .filter((i) => i.receivingQty > 0)
    .map(({ id, receivingQty, unitCost }) => ({
      id,
      receivingQty,
      unitCost,
    }));
  return {
    adjustmentType,
    purchaseOrderItems,
    closeAfterReceive: formValue.closeAfterReceive,
  };
};
