import { Component } from "common/component";

import "./index.scss";

interface PropTypes {
  starred: boolean;
  recordId: string;
  toggleStar: (id: string, starred: boolean) => void;
  className?: string;
}

export class Star extends Component<PropTypes> {
  static readonly displayName = "Star";

  toggle = () => {
    const { starred, recordId, toggleStar } = this.props;
    toggleStar(recordId, !starred);
  };
  render() {
    const { starred, className } = this.props;
    return (
      <div className={`x-star qa-star ${className || ""}`}>
        <i
          className={starred ? "fa fa-star" : "fa fa-star-o"}
          onClick={this.toggle}
        />
      </div>
    );
  }
}
