import { getLocalizedName } from "common";
import { getColumn } from "common/entities";
import { Row } from "../row";
import { ReadOnlyLookupPropTypes as PropTypes } from "./types";

export const ReadOnlyDrilldown = ({
  groupColumn,
  withLinks,
  entity,
  record = {},
  context,
}: PropTypes) => {
  const fkColumn = getColumn(entity, groupColumn.columnName);
  return (
    <>
      {groupColumn.lookupConfiguration.mappedFields.map((field) => {
        const entityColumn = getColumn(entity, field.columnName);
        return (
          <Row
            key={field.columnName}
            context={context}
            entityName={entity.name}
            withLinks={withLinks}
            column={entityColumn}
            label={getLocalizedName(entityColumn)}
            value={record[field.columnName]}
          />
        );
      })}
      <Row
        key={fkColumn.name}
        context={context}
        entityName={entity.name}
        withLinks={withLinks}
        column={fkColumn}
        label={getLocalizedName(fkColumn)}
        value={record[fkColumn.name]}
      />
    </>
  );
};
