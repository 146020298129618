import * as R from "ramda";
import { isType, isTypeWithoutValues } from "common";
import { isReferenceEntity } from "common/api/entities";
import { DataType } from "common/entities/entity-column/data-type/types";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entities, Entity } from "common/entities/types";
import { isSelectField } from "common/query/types";
import { Context } from "common/types/context";
import {
  FkValue,
  ForeignKey,
  SubFkValue,
  SubForeignKey,
} from "common/types/foreign-key";
import { Properties } from "common/types/records";
import { DEFAULT_CURRENCY_DECIMAL_SCALE } from "common/utils/decimal";
import { getBooleanFromString } from "common/widgets/checkbox/label";
import { getCurrencySymbol } from "common/widgets/currency/functions";
import { getCurrencyLabel } from "common/widgets/currency/label";
import { getFormattedDate } from "common/widgets/date/date-base-label";
import { getDateTimeFormat } from "common/widgets/date/date-time-label";
import { replaceSeparator } from "common/widgets/number/functions";

export const isSubForeignKey = isTypeWithoutValues<
  SubFkValue,
  SubForeignKey<string>
>(["title"]);

export const getSubFKTitle = (item: SubFkValue) =>
  isSubForeignKey(item) ? item.title : item;

export const isForeignKey = isType<FkValue, ForeignKey>(["id"]);

export const getFkId = (value: FkValue) =>
  isForeignKey(value) ? value.id : value;

// TODO unify it with labelFunction without circular dependency
export const formatFieldByType = (
  text: string,
  dataType: DataType,
  context: Context,
  column?: EntityColumn,
) => {
  const { uiFormat } = context;
  switch (dataType) {
    case "date":
      return getFormattedDate(text, uiFormat.dateFormat);
    case "datetime":
      return getFormattedDate(text, getDateTimeFormat(uiFormat));
    case "currency":
      return getCurrencyLabel(
        text,
        getCurrencySymbol(undefined, column, context),
        uiFormat.decimalSeparator,
        column?.decimalScale || DEFAULT_CURRENCY_DECIMAL_SCALE,
      );
    case "float":
    case "ufloat":
      return replaceSeparator(text, uiFormat.decimalSeparator);
    case "bool":
      return getBooleanFromString(text);
    default:
      return text;
  }
};

export const getFieldAndFormat = (
  entity: Entity,
  context: Context,
  aliasName: string,
  text: string,
) => {
  const { columns } = entity;

  /* TODO this is incorrect. there are 2 use cases missing
    - if the user selects a FK column for display (without making a join), we need to
    find out what column in the related entity is the "title" and format by dataType accordingly
    - if the user configures a column for display using a join to a related entity, you won't
    find the related column in this entity. we need to use the getFields function
  */
  const field = R.find(
    (f) => f.alias === aliasName,
    entity.query.select.filter(isSelectField),
  );
  const column = R.find((c) => c.name === field?.name, columns);
  const dataType = column?.dataType;
  return formatFieldByType(text, dataType, context, column);
};

export const getFullForeignKeyLabel = (
  context: Context,
  item: ForeignKey,
  entity?: Entity,
) => {
  if (!item) return undefined;

  const formattedNumber = item.number ? item.number : undefined;

  const title = getSubFKTitle(item.title) || item.label;
  const formattedTitle = entity
    ? getFieldAndFormat(entity, context, "title", title)
    : title;

  const subtitle = getSubFKTitle(item.subtitle);
  const formattedSubtitle = entity
    ? getFieldAndFormat(entity, context, "subtitle", subtitle)
    : subtitle;

  const subSubTitle = getSubFKTitle(item.subsubtitle);
  const formattedSubsubtitle = entity
    ? getFieldAndFormat(entity, context, "subsubtitle", subSubTitle)
    : subSubTitle;

  const formattedCustomTitle = item.customtitle
    ? getSubFKTitle(item.customtitle)
    : undefined;

  return [
    formattedNumber,
    formattedTitle,
    formattedSubtitle,
    formattedSubsubtitle,
    formattedCustomTitle,
  ]
    .filter((part) => !!part)
    .join(" - ");
};

export const collapseReferenceFks = (
  properties: Properties,
  entities: Entities,
) =>
  properties
    ? R.mapObjIndexed(
        (value) =>
          isForeignKey(value) && isReferenceEntity(entities[value?.entity])
            ? value.id
            : value,
        properties,
      )
    : undefined;
