import * as R from "ramda";
import { Context } from "common/types/context";
import { Properties } from "common/types/records";
import { Entity } from "common/entities/types";
import { omitLayoutColumn } from "common/form/functions/layout";
import { validateGroupColumns } from "common/form/functions/validation";
import { Group, WidgetsOverwriteMap } from "common/form/types";
import { Filter, Query } from "common/query/types";
import { getFormOrDefault } from "common/record/edit/functions";
import { PartSupplier } from "common/record/actions/ui/parts-reorder/purchase-order/types";

export const getFormGroups = (context: Context, entity: Entity): Group[] => {
  const layoutForm = getFormOrDefault(context, entity.name);
  const layoutWithoutPartId = omitLayoutColumn(layoutForm.settings, "partId");

  return layoutWithoutPartId.groups;
};

export const validateForm = (
  groups: Group[],
  entity: Entity,
  props: Properties,
): boolean =>
  R.all(
    (section) => validateGroupColumns(entity?.columns, props, section.columns),
    groups || [],
  );

const getSupplierQuery = (
  context: Context,
  partSuppliers: PartSupplier[],
  supplierEntity: string,
): Query => {
  const filter: Filter[] = partSuppliers.map((partSupplier) => {
    return { name: "id", op: "neq", value: partSupplier.supplierId.id };
  });

  return {
    fkExpansion: true,
    select: context.entities[supplierEntity].query?.select,
    filter: {
      and: [{ name: "isDeleted", op: "isfalse" }, ...filter],
    },
  };
};

export const partSupplierWidgetsMapper = (
  context: Context,
  partSuppliers: PartSupplier[],
  supplierEntity: string,
): WidgetsOverwriteMap => {
  return {
    ["supplierId"]: {
      query: getSupplierQuery(context, partSuppliers, supplierEntity),
    },
    ["isDefault"]: { readOnly: true },
  };
};
