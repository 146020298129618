import { Component } from "react";
import * as R from "ramda";
import { Context } from "common/types/context";
import { RecentlyViewed } from "common/types/recently-viewed";
import { HistoryPanel } from "./view";

interface PropTypes {
  context: Context;
}

interface StateType {
  unsubscribe?: () => any;
}

export class HistoryPanelController extends Component<PropTypes, StateType> {
  state: StateType = {};

  componentDidMount() {
    const { context } = this.props;
    this.setState({
      unsubscribe: context.preferenceService.subscribe(() =>
        this.forceUpdate(),
      ),
    });
  }

  componentWillUnmount() {
    this.state.unsubscribe();
  }

  onChange = (newValue: RecentlyViewed[] = []) => {
    const { context } = this.props;
    const oldValue = context.recentlyViewed.get();

    if (oldValue !== newValue) {
      const removed = R.difference(oldValue, newValue);
      R.forEach(
        ({ site, entity, id }) =>
          context.recentlyViewed.remove(site, entity, id),
        removed,
      );
    }
  };

  render() {
    const { context } = this.props;
    return (
      <HistoryPanel
        context={context}
        value={context.recentlyViewed.get()}
        onChange={this.onChange}
      />
    );
  }
}
