interface Props {
  address: string;
}

export const Address = ({ address }: Props) => (
  <div className="x-address">
    <div>
      <i className="fa fa-map-marker" />
    </div>
    <div className="x-description">{address || _("No address set")}</div>
  </div>
);
