import { useState } from "react";
import { getFalse } from "common";
import { mediaApi } from "common/api/media";
import { Entity } from "common/entities/types";
import { ApiCall } from "common/types/api";
import { FileType } from "common/types/media";
import { createList } from "common/widgets/list";
import { LoadingIcon } from "common/widgets/loading-icon";
import { Warning } from "common/widgets/warning";
import { WithConfirmation } from "common/widgets/warning/with-confirmation";
import { withValue, WithValue } from "common/with-value";
import { MediaFile, SetAsDefault } from "./file";
import { translateWarning } from "./functions";

interface PropTypes {
  apiCall: ApiCall;
  entity: Entity;
  setAsDefault: SetAsDefault;
  loading: boolean;
  defaultImage: string;
  disableEdit?: boolean;
}

export const List = createList<FileType>();

const isInternalSource = (entityName: string) => (file: FileType) =>
  !file.source || file.source === entityName;

const MediaFileListComp = ({
  apiCall,
  entity,
  defaultImage,
  loading,
  value,
  setAsDefault,
  onChangeSetValue,
  disableEdit,
}: PropTypes & WithValue<FileType[]>) => {
  const toMediaFile = (f: FileType) => (
    <MediaFile
      key={f.url}
      file={f}
      isInternalSource={isInternalSource(entity.name)(f)}
      showFileInformation={true}
      isDefault={!!defaultImage && defaultImage.indexOf(f.url) !== -1}
      setAsDefault={setAsDefault}
      disableEdit={disableEdit}
    />
  );

  const isDocumentUsed = (file: FileType) =>
    mediaApi(apiCall).isDocumentUsed(entity.name, file.sourceId, file.url);

  const loadingIcon = loading ? <LoadingIcon /> : null;

  return (
    <>
      {loadingIcon}
      {value && value.length ? (
        <WithConfirmation>
          {({ onOpen, onClose, onConfirm, isOpen }) => {
            const [isAction2Disabled, setAction2Disabled] =
              useState<boolean>(false);
            const [warningContent, setWarningContent] = useState<string>();
            const [loading, setLoading] = useState(false);

            const checkAnyLinkedRecordFields = (file: FileType) => {
              setLoading(true);
              isDocumentUsed(file)
                .then(({ isUsed, message }) => {
                  setLoading(false);
                  setAction2Disabled(isUsed);
                  setWarningContent(translateWarning(message, isUsed));
                })
                .catch(() => setLoading(false));

              return onOpen(file);
            };

            const showConfirmation =
              isOpen && !loading ? (
                <Warning
                  title={_("Delete document")}
                  content={warningContent}
                  action1={_("Cancel")}
                  action2={_("Delete Document")}
                  isAction2Disabled={isAction2Disabled}
                  onAction1={onClose}
                  onAction2={onConfirm}
                />
              ) : undefined;

            return (
              <>
                {loading ? <LoadingIcon /> : undefined}
                {showConfirmation}
                <List
                  onDisplay={toMediaFile}
                  canDelete={
                    disableEdit ? getFalse : isInternalSource(entity.name)
                  }
                  onDelete={checkAnyLinkedRecordFields}
                  className="x-media-file-list"
                  value={value}
                  onChange={onChangeSetValue}
                />
              </>
            );
          }}
        </WithConfirmation>
      ) : (
        <div>{_("No files")}</div>
      )}
      <hr />
    </>
  );
};

export const MediaFileList = withValue<FileType[], PropTypes>(
  MediaFileListComp,
  "MediaFileList",
);
