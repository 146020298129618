import { useEffect } from "react";
import { setColumnIsValid } from "common/form/functions/validation";
import { AlertErrorTip } from "common/widgets/alert";
import { getSelectorError } from "common/widgets/record-selector/errors";
import {
  formatReferenceOption,
  getRecordsToLabelledOptionsFn,
  getReferenceLabel,
  getValueFromRecords,
} from "common/widgets/record-selector/references/functions";
import type { ReferenceSelectorProps } from "common/widgets/record-selector/references/types";
import { apiSearchFull } from "common/api/search";
import { useAppDispatch, useAppSelector } from "common/app/redux";
import { fetchReferences, getReferenceKey } from "common/app/redux/references";
import { getFkId, isForeignKey } from "common/functions/foreign-key";
import {
  getRecordWithTitleFn,
  getReferenceDisplayFieldFilters,
  getReferenceQuery,
} from "common/functions/references";
import { addFilter } from "common/query/filter";
import { useExpandedRecord } from "common/record/foreign-key-expansion";
import type { FkValue } from "common/types/foreign-key";
import type { LabelledOption } from "common/vendor-wrappers/react-select/types";
import { AsyncSelector } from "common/widgets/selector/async-selector";
import { Selector } from "common/widgets/selector";

const MAX_SELECTOR_REFERENCES = 100;

export const ReferenceSelector = ({
  context,
  entity,
  query,
  className,
  allowClear = false,
  disabled = false,
  placeholder,
  filter,
  optionsLimit = MAX_SELECTOR_REFERENCES,
  recordSites,
  formValidation,
  onFormValidationChange,
  column,
  label,
  value,
  onChange,
}: ReferenceSelectorProps) => {
  const expandedRecord = useExpandedRecord({
    context,
    entityName: entity,
    record: value,
  });
  const references = useAppSelector(
    (state) => state.references[getReferenceKey(entity, recordSites, query)],
  );
  const dispatch = useAppDispatch();

  const { records = [], isLoading, totalPages } = references ?? {};

  const getRecordWithTitle = getRecordWithTitleFn(
    entity,
    context.uiFormat.culture,
    context.site.culture,
  );

  const getReferences = (searchTerm: string, limit: number) => {
    const mainQuery =
      query ?? getReferenceQuery(context, context.entities[entity]);
    const queryForEntity = { entity: entity, query: mainQuery };
    const filteredQuery = addFilter(
      {
        or: getReferenceDisplayFieldFilters(
          context,
          mainQuery.select,
          searchTerm,
        ),
      },
      queryForEntity,
    );

    return apiSearchFull(context.apiCallFull)
      .runLookupQueryWithPagination(filteredQuery, context, 0, limit)
      .then((response) => {
        const data = response?.data?.data ?? [];
        const totalPages = response?.data?.totalPages ?? 0;
        return { records: data.map(getRecordWithTitle), totalPages };
      });
  };

  useEffect(() => {
    if (!value || getValueFromRecords(value, records)) return;

    dispatch(
      fetchReferences({
        context,
        sites: recordSites,
        entityName: entity,
        query,
        limit: optionsLimit,
        force: true,
      }),
    );
  }, [value]);

  useEffect(() => {
    dispatch(
      fetchReferences({
        context,
        sites: recordSites,
        entityName: entity,
        query,
        limit: optionsLimit,
      }),
    );
  }, [entity, query, recordSites]);

  const fetch = (searchTerm: string, limit: number) =>
    getReferences(searchTerm, limit).then(({ records }) => records);

  const getOptionValue = (option: LabelledOption<FkValue>) =>
    getFkId(option.value);

  const currentValue = expandedRecord ?? value;
  const filteredRecords = filter ? records.filter(filter) : records;

  const { hasError, errorMessage } = getSelectorError(
    context,
    context.entities[entity],
    recordSites,
    isForeignKey(currentValue) ? currentValue : undefined,
  );

  useEffect(() => {
    onFormValidationChange?.(
      setColumnIsValid(formValidation, column?.name, !hasError),
    );
  }, [hasError]);

  return (
    <div className={hasError ? "x-has-error" : undefined}>
      {totalPages > 1 ? (
        <AsyncSelector
          fetch={fetch}
          mapOptions={getRecordsToLabelledOptionsFn(context)}
          isLoading={isLoading}
          defaultOptions={filteredRecords}
          formatOptionLabel={formatReferenceOption}
          className={className}
          allowClear={allowClear}
          disabled={disabled}
          getOptionValue={getOptionValue}
          placeholder={placeholder}
          optionsLimit={optionsLimit}
          label={label}
          value={currentValue}
          onChange={onChange}
        />
      ) : (
        <Selector
          className={className}
          allowClear={allowClear}
          disabled={disabled}
          options={filteredRecords}
          getOptionLabel={getReferenceLabel(context)}
          getOptionValue={getOptionValue}
          placeholder={placeholder}
          label={label}
          value={currentValue}
          onChange={onChange}
        />
      )}
      {hasError ? <AlertErrorTip message={errorMessage} /> : undefined}
    </div>
  );
};
