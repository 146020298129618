import * as R from "ramda";
import { Context } from "common/types/context";
import { queryBuilderBlackList } from "common/entities/entity-column/data-type/types";
import { Entities } from "common/entities/types";
import { getFields } from "common/query-builder/functions";
import { Group } from "common/query-builder/group";
import { JoinItem, QueryForEntity } from "common/query/types";
import { Row } from "common/widgets/row";
import { withValue, WithValue } from "common/with-value";
import { Config } from "./types";

interface PropTypes {
  context: Context;
  entities: Entities;
  entity: string;
  joins: JoinItem[];
}

const ConfigComponentComp = ({
  context,
  entities,
  entity,
  joins,
  value,
  onChangeSetValue,
}: PropTypes & WithValue<Config>) => {
  const query: QueryForEntity = {
    entity,
    query: {
      select: [],
      joins,
      group: value,
    },
  };

  const fields = getFields(entities, query).filter(
    (f) => !R.includes(f.column.dataType, queryBuilderBlackList),
  );

  return (
    <div className="x-explorer-config">
      <div className="x-query-builder">
        <Row title={_("Group By")}>
          <Group
            context={context}
            query={query}
            fields={fields}
            value={value}
            onChange={onChangeSetValue}
          />
        </Row>
      </div>
    </div>
  );
};

export const ConfigComponent = withValue<Config, PropTypes>(
  ConfigComponentComp,
  "Config",
);
