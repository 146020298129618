import { getEntityByBehavior } from "common/entities";
import { Entities, Entity } from "common/entities/types";
import {
  EntitySecuritySettings,
  Role,
  VisibilityScope,
} from "common/types/roles";
import { PresetView } from "./types";

export const presetViews = (entities: Entities): PresetView[] => {
  const workOrderEntity = getEntityByBehavior("WorkOrder", entities);
  return [
    {
      entityName: workOrderEntity.name,
      limitedAccess: false,
      visibilityScope: ["AssignedToUser"],
    },
  ];
};

export const translateVisibilityScope = (scope: VisibilityScope) => {
  switch (scope) {
    case "AssignedToUser":
      return _("Assigned to User");
    case "AssignedToTeam":
      return _("Assigned to My Team(s)");
    default:
      return "";
  }
};

export const toPresetView = (
  settings: EntitySecuritySettings,
  entityName: string,
): PresetView => {
  const entitySettings = settings?.[entityName];
  return {
    entityName,
    limitedAccess: entitySettings?.limitedAccess,
    visibilityScope: entitySettings?.visibilityScope || [],
  };
};

export const isLimitedAccessEnabled = (role: Role, entity: Entity) =>
  role.entitySecuritySettings?.[entity.name]?.limitedAccess;
