import * as R from "ramda";
import { Context } from "common/types/context";
import { Component } from "common/component";
import { getListQuery } from "common/query/list";
import { searchApi } from "common/api/search";
import { View, ExplorerViewValue } from "./view";
import { ExplorerValue } from "./explorer";
import { Config } from "./explorer/types";

interface PropTypes {
  context: Context;
  entityName: string;
}

interface StateType {
  explorer?: ExplorerValue;
  explorerModalConfig?: Config;
  showExplorerModal?: boolean;
}

export class ExplorerPanelController extends Component<PropTypes, StateType> {
  state: StateType = {
    showExplorerModal: false,
  };

  componentDidUpdate(prevProps: PropTypes) {
    if (prevProps.context.site.name !== this.props.context.site.name)
      this.setState({ explorer: undefined });
  }

  getConfig = () => {
    const { context, entityName } = this.props;
    return context.preferenceService
      .get()
      .explorer.filter((e) => e.entity === entityName)
      .map((e) => e.group)[0];
  };

  getQuery = () => {
    const { context, entityName } = this.props;
    const { entities } = context;
    return {
      entity: entityName,
      query: getListQuery(entities, entities[entityName], undefined, undefined),
    };
  };

  onChange = (newValue: ExplorerViewValue) => {
    const { context, entityName } = this.props;
    const oldExplorerConfig = this.getConfig();
    const newExplorerConfig = newValue.explorerConfig;

    if (oldExplorerConfig === newExplorerConfig) {
      return this.setState(newValue);
    }

    const oldPrefs = context.preferenceService.get();
    const newPrefs = R.mergeRight(oldPrefs, {
      explorer: oldPrefs.explorer
        .filter((e) => e.entity !== entityName)
        .concat([{ entity: entityName, group: newExplorerConfig }]),
    });
    context.preferenceService.set(newPrefs).then(() => this.setState(newValue));
  };

  render() {
    const { context } = this.props;
    const { explorer, explorerModalConfig, showExplorerModal } = this.state;

    return (
      <View
        context={context}
        runQuery={searchApi(context.apiCall).runQueryFkExpansion}
        query={this.getQuery()}
        value={{
          explorer,
          explorerConfig: this.getConfig(),
          explorerModalConfig,
          showExplorerModal,
        }}
        onChange={this.onChange}
      />
    );
  }
}
