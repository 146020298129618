import * as R from "ramda";
import { Node } from "common/widgets/tree/types";

export const findRoot = <T extends Node>(nodes: T[] = []): T =>
  R.find((n) => !n.parentName, nodes);

export const findChildren = <T extends Node>(nodes: T[] = [], node: T): T[] => {
  if (!node) return [];
  if (node.children) {
    return node.children as T[];
  }
  return R.sortBy(
    (node) => `${node.isGroup ? 0 : 1}-${node.label}`,
    nodes.filter((s) => s.parentName === node.name),
  );
};

export const getAccessibleNodes = <T extends Node>(
  knownNodes: T[] = [],
  node: T,
  nodes: T[] = [],
): T[] => {
  if (!node) return [];

  const thisNode = [node];
  const known = knownNodes.concat(thisNode);

  return node && node.isGroup
    ? thisNode.concat(
        R.flatten(
          findChildren(nodes, node)
            .filter((child) => !R.includes(child, known))
            .map((node) => getAccessibleNodes(known, node, nodes)),
        ),
      )
    : thisNode;
};

export const getSubTree = <T extends Node>(nodes: T[], node: T): T[] =>
  getAccessibleNodes([], node, nodes);

export const getSubTreeSingleNodes = <T extends Node>(
  nodes: T[],
  node: T,
): T[] => getAccessibleNodes([], node, nodes).filter((n) => !n?.isGroup);
