import { Component } from "react";
import { defaultFor } from "common";
import { Entity } from "common/entities/types";
import {
  FormValidationProps,
  Layout as FormLayout,
  RelatedEntity,
} from "common/form/types";
import { hasPermissionToRead } from "common/functions/roles";
import { mergeChain } from "common/merge";
import { applyLookupQuery } from "common/query/entities";
import { RunQuery } from "common/query/types";
import { AuditTrailController } from "common/record/form/content/audit-trail";
import {
  RecordDetail,
  RecordDetailValue,
} from "common/record/form/content/detail";
import {
  getContextWithBehaviors,
  updateRelatedEntities,
} from "common/record/form/content/functions";
import { MeasurementsController } from "common/record/form/content/measurements";
import { RelatedRecordsForm } from "common/record/form/content/related";
import { RelatedValue } from "common/record/form/content/related/types";
import {
  DetailUiValue,
  RelatedUiValue,
  Reload,
  StandardUiValue,
  StandardValue as Value,
} from "common/record/types";
import {
  AUDIT_TRAIL,
  COMMENTS,
  isOverview,
  MAP,
  MEASUREMENTS,
  MEDIA_FILES,
  SIGNATURE,
} from "common/record/utils";
import { Comment } from "common/types/comments";
import { Context } from "common/types/context";
import { Properties, Record } from "common/types/records";
import { GoFn } from "common/types/url";
import { Tab } from "common/widgets/tabs/tab";
import { Tabs } from "common/widgets/tabs/tabs";
import { ValueProps } from "common/with-value-for";
import { CommentsController } from "./comments";
import { getComment } from "./comments/functions";
import { RecordMap } from "./map";
import { RecordMedia } from "./media";
import { RecordReport } from "./report";
import { RecordSignature } from "./signature";

const defaultUi = defaultFor<StandardUiValue>();
const defaultRelated = defaultFor<RelatedUiValue>();
const defaultRecord = defaultFor<Record>();
const defaultProperties = defaultFor<Properties>();
const defaultDetail = defaultFor<DetailUiValue>();

interface PropTypes extends ValueProps<Value>, FormValidationProps {
  context: Context;
  entity: Entity;
  layout: FormLayout;
  withLinks: boolean;
  runQuery: RunQuery;
  auditTrailId: string;
  reload: Reload;
  saving: boolean;
  /**
   * if false, buffer changes and only update the record when the user presses "Save"
   */
  updateRecordOnChange: boolean;
  allowDynamicValues?: boolean;
  isTemplate?: boolean;
  disableEdit?: boolean;
  goTo?: GoFn;
  onCommentsCountChange?: (count: number) => void;
  getUrl?: (entity: Entity, site: string) => string;
}

export class Content extends Component<PropTypes> {
  static readonly displayName = "Content";

  onChangeDetail = (detailValue: RecordDetailValue) => {
    const { value, onChange, context, entity } = this.props;
    const { record, detail, related } = detailValue;

    const newValue = mergeChain(value)
      .set("record", record)
      .prop("ui")
      .set("related", related)
      .set("detail", detail)
      .output();

    onChange(updateRelatedEntities(context.entities, entity, newValue));
  };

  onChangeRelated = (relatedValue: RelatedValue) => {
    const { value, onChange, context, entity } = this.props;
    const { record, related, sidebar } = relatedValue;

    const newValue = mergeChain(value)
      .set("record", record)
      .prop("ui")
      .set("related", related)
      .set("sidebar", sidebar)
      .output();

    onChange(
      updateRelatedEntities(
        context.entities,
        entity,
        newValue,
        context.entities[sidebar.entity],
      ),
    );
  };

  onChangeComment = (comment: Comment) => {
    const { value, onChange } = this.props;
    const other = { ...value.ui.other, comment };

    const newValue = mergeChain(value).prop("ui").set("other", other).output();

    onChange(newValue);
  };

  getRelatedRecords = (relatedEntityName: string, onChange: any) => {
    const {
      context,
      disableEdit,
      entity,
      layout,
      reload,
      isTemplate,
      goTo,
      value,
      withLinks,
    } = this.props;
    const { record = defaultRecord, ui = defaultUi } = value;
    const { properties = defaultProperties } = record;
    const { detail, related = defaultRelated, sidebar } = ui;

    const newContext = getContextWithBehaviors(context, relatedEntityName);
    const entityConfig: RelatedEntity = (layout.relatedEntities || []).find(
      (ec) => ec.name === relatedEntityName,
    );

    return (
      <RelatedRecordsForm
        context={newContext}
        reload={reload}
        parentEntity={entity}
        entity={newContext.entities[relatedEntityName]}
        recordId={properties.id}
        entityConfig={entityConfig}
        isTemplate={isTemplate}
        recordDetail={detail}
        displayTypes={disableEdit ? ["table"] : undefined}
        goTo={goTo}
        value={{ record, related, sidebar }}
        onChange={onChange}
        withLinks={withLinks}
      />
    );
  };

  render() {
    const {
      context,
      layout,
      runQuery,
      auditTrailId,
      reload,
      goTo,
      saving,
      withLinks,
      updateRecordOnChange,
      isTemplate,
      disableEdit,
      allowDynamicValues,
      formValidation,
      onFormValidationChange,
      onCommentsCountChange,
      value,
      getUrl,
    } = this.props;
    const { entities } = context;
    const { record = defaultRecord, ui = defaultUi } = value;
    const {
      detail = defaultDetail,
      related = defaultRelated,
      sidebar,
      other,
    } = ui;

    const entity = applyLookupQuery(
      entities,
      this.props.entity,
      layout,
      detail.form,
    );

    const recordDetailComp = (
      <RecordDetail
        isTemplate={isTemplate}
        context={context}
        entity={entity}
        layout={layout}
        withLinks={withLinks}
        disableEdit={disableEdit}
        reload={reload}
        goTo={goTo}
        updateRecordOnChange={updateRecordOnChange}
        allowDynamicValues={allowDynamicValues}
        formValidation={formValidation}
        onFormValidationChange={onFormValidationChange}
        value={{ record, detail, related, sidebar }}
        onChange={this.onChangeDetail}
      />
    );

    if (isOverview(sidebar.label)) return recordDetailComp;

    if (sidebar.label === MEDIA_FILES) {
      return (
        <RecordMedia
          context={context}
          disableEdit={disableEdit}
          entity={entities[sidebar.entity]}
          value={{ record, detail, related: undefined, sidebar }}
          onChange={this.onChangeDetail}
        />
      );
    }

    if (sidebar.label === MAP) {
      return (
        <RecordMap
          saving={saving}
          isTemplate={isTemplate}
          value={{ record, detail, related: undefined, sidebar }}
          onChange={this.onChangeDetail}
        />
      );
    }

    if (
      sidebar.label === AUDIT_TRAIL &&
      hasPermissionToRead(context.userTypes, context.role, "AuditTrail")
    ) {
      return (
        <Tabs key="audit-trail-tabs">
          <Tab value={AUDIT_TRAIL} label={_("Audit Trail")}>
            <AuditTrailController
              context={context}
              entity={entity}
              record={record}
              auditTrailId={auditTrailId}
              hideLinks={!withLinks}
              getUrl={getUrl}
            />
          </Tab>
        </Tabs>
      );
    }

    if (sidebar.label === SIGNATURE) {
      return <RecordSignature value={record} />;
    }

    if (sidebar.label === MEASUREMENTS) {
      return (
        <MeasurementsController
          context={context}
          entity={entity}
          record={record}
        />
      );
    }

    if (sidebar.reportId) {
      return (
        <RecordReport
          context={context}
          reports={layout.reports.sidebar}
          reportId={sidebar.reportId}
          label={sidebar.label}
          runQuery={runQuery}
          reload={reload}
          entityName={entity.name}
        />
      );
    }

    if (sidebar.label === COMMENTS) {
      const recordId = record.properties.id;
      return (
        <Tabs key="comments-tabs">
          <Tab value={COMMENTS} label={_("Comments")}>
            <CommentsController
              context={context}
              entity={entity}
              recordId={recordId}
              onCountChange={onCommentsCountChange}
              value={other?.comment || getComment(entity.name, recordId)}
              onChange={this.onChangeComment}
            />
          </Tab>
        </Tabs>
      );
    }

    return this.getRelatedRecords(sidebar.entity, this.onChangeRelated);
  }
}
