import {
  add,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  getHours,
  getISODay,
  getMinutes,
  getTime,
  getYear,
  set,
  setHours,
  setMinutes as fnsSetMinutes,
} from "date-fns";
import { format } from "date-fns-tz";
import * as R from "ramda";
import { Culture } from "common/culture/supported-cultures";
import { getStartOfForDate } from "common/date-time/calculators";
import {
  FORMAT_ISO,
  FORMAT_MIDDAY_INDICATOR,
  FORMAT_TIME_SHORT,
  FORMAT_WEEKDAY_NAME,
} from "common/date-time/formats";
import {
  getSiteTimeZone,
  getUserLocale,
  setUserLocale,
} from "common/date-time/global";
import {
  getSitedNowDate,
  parseToSited,
  sitedToISO,
  sitedToUtcDate,
  sitedToUtcISO,
} from "common/date-time/internal";
import {
  GenericDateTime,
  MiddayIndicator,
  ShortUnitOfTime,
  SitedDateTime,
  SitedHour,
  SitedMinutes,
  UTCDateTime,
} from "common/date-time/types";
import { isDayOfWeekNumber } from "common/date-time/validators";
import { WeekdayNumber } from "common/date-time/weekday";
import { sumArray } from "common/utils/array";
import { getWithin } from "common/utils/number";

export const setLocale = (language: string) =>
  setUserLocale(language as Culture);

export const toUtc = (dateTime: GenericDateTime | Date): UTCDateTime =>
  sitedToUtcISO(parseToSited(dateTime));

export const toSited = (dateTime: UTCDateTime | Date): SitedDateTime =>
  dateTime
    ? format(parseToSited(dateTime), FORMAT_ISO, {
        timeZone: getSiteTimeZone(),
      })
    : undefined;

export const toSitedDate = (dateTime: UTCDateTime | Date): Date =>
  dateTime ? parseToSited(dateTime) : undefined;

export const sitedToUtc = (dateTime: UTCDateTime | Date): UTCDateTime =>
  sitedToUtcISO(dateTime);

/**
 * Returns UTC ISO of the current time
 */
export const getUtcNow = (): UTCDateTime => sitedToUtcISO(getSitedNowDate());

/**
 * Gives Sited ISO for the current time
 */
export const getNow = (): UTCDateTime => sitedToISO(getSitedNowDate());

export const getTimestamp = () => getTime(sitedToUtcDate(getSitedNowDate()));

export const getTimestampForDate = (date: UTCDateTime) =>
  date ? getTime(sitedToUtcDate(parseToSited(date))) : undefined;

export const getCurrentYear = () => getYear(getSitedNowDate());

export const numberToDayOfWeekNumber = (n: number): WeekdayNumber =>
  isDayOfWeekNumber(n) ? n : undefined;

export const setHour = (date: UTCDateTime, hour: SitedHour): UTCDateTime => {
  const sitedDate = parseToSited(date);

  return sitedDate
    ? sitedToUtcISO(
        R.isNil(hour) ? sitedDate : setHours(sitedDate, getWithin(hour, 0, 23)),
      )
    : undefined;
};

export const setMinutes = (
  date: UTCDateTime,
  minutes: SitedMinutes,
): UTCDateTime => {
  const sitedDate = parseToSited(date);

  return sitedDate
    ? sitedToUtcISO(
        R.isNil(minutes)
          ? sitedDate
          : fnsSetMinutes(sitedDate, getWithin(minutes, 0, 59)),
      )
    : undefined;
};

// GETTERS

export const isWeekend = (dateTime: UTCDateTime) =>
  getISODay(parseToSited(dateTime)) > 5;

export const getMiddayIndicator = (date: UTCDateTime): MiddayIndicator => {
  const sitedDate = parseToSited(date);

  return sitedDate
    ? (format(sitedDate, FORMAT_MIDDAY_INDICATOR, {
        locale: getUserLocale(),
      }) as MiddayIndicator)
    : undefined;
};

export const getWeekdayName = (date: UTCDateTime) => {
  const sitedDate = parseToSited(date);

  return sitedDate
    ? format(sitedDate, FORMAT_WEEKDAY_NAME, { locale: getUserLocale() })
    : undefined;
};

export const getTimeFromMins = (mins: number) =>
  format(
    set(getSitedNowDate(), { hours: mins / 60, minutes: mins % 60 }),
    FORMAT_TIME_SHORT,
    { locale: getUserLocale() },
  );

export const getFormattedDurationAmount = (
  amount: number,
  unit: ShortUnitOfTime,
) => {
  const date1 = getSitedNowDate();
  const date2 = add(date1, { [unit]: amount ?? 0 });

  const [date, ...time] = [
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInSeconds,
  ]
    .reduce((acc, fn) => [...acc, sumArray(acc) > 0 ? 0 : fn(date2, date1)], [])
    .map((d) => d.toString().padStart(2, "0"));

  return `${date}.${time.join(":")}`;
};

export const getHourFromDate = (date: UTCDateTime): SitedHour =>
  getHours(parseToSited(date) ?? getStartOfForDate(getSitedNowDate(), "day"));

export const getMinutesFromDate = (date: UTCDateTime): SitedMinutes =>
  getMinutes(parseToSited(date) ?? getStartOfForDate(getSitedNowDate(), "day"));
