import { Context } from "common/types/context";
import { DocumentLabel } from "../document/label";
import { Label } from "../label";
import { isInternalSourceMedia } from "./functions";
import { MediaWebLinkLabel } from "./web-link-label";

interface Props {
  context: Context;
  value: string;
}

export const MediaLabel = ({ context, value }: Props) => {
  if (!value) return <Label value={value} />;

  return isInternalSourceMedia(value) ? (
    <DocumentLabel value={value} />
  ) : (
    <MediaWebLinkLabel context={context} url={value} isEditMode={false} />
  );
};

MediaLabel.displayName = "MediaLabel";
