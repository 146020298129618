import { getColumn } from "common/entities";
import { Entity } from "common/entities/types";
// eslint-disable-next-line import/no-cycle
import { InputWidget } from "common/form/widget/input-widget";
import { Context } from "common/types/context";
import { Required } from "common/widgets/required";
import { withValue, WithValue } from "common/with-value";

interface PropTypes {
  context: Context;
  entity: Entity;
}

export interface Row {
  name: string;
  localizedName: string;
  value: any;
}

const RowComp = ({
  context,
  entity,
  value,
  onChangeMergeValue,
}: PropTypes & WithValue<Row>) => (
  <div className="x-flex">
    <input
      className="x-flex-grow-1 x-margin-right-10"
      readOnly={true}
      value={value.localizedName}
    />
    <Required className="x-flex-grow-2" value={value.value}>
      <InputWidget
        entityName={entity.name}
        disabled={false}
        validate={true}
        buffer={false}
        context={context}
        column={getColumn(entity, value.name)}
        formValidation={undefined}
        onFormValidationChange={undefined}
        value={value.value}
        onChange={onChangeMergeValue("value")}
      />
    </Required>
  </div>
);

export const FieldRow = withValue<Row, PropTypes>(RowComp, "FieldRow");
