import * as R from "ramda";
import { Record } from "common/types/records";
import { Entities, Entity } from "common/entities/types";
import { findEntityByArgs } from "common/functions/entity";
import {
  getPartSupplierId,
  getQuantity,
  isApproved,
} from "common/record/form/content/related/requisitioning-item/functions";
import { RequisitioningPayload } from "./types";

export const getAllItems = (
  entities: Entities,
  entity: Entity,
  record: Record,
): Record[] => {
  const reqItemEntity = findEntityByArgs(
    entities,
    "RequisitioningItem",
    "requisitioningEntity",
    entity.name,
  );
  return record?.related?.[reqItemEntity.name] || [];
};

export const getApprovedItems = (
  entities: Entities,
  entity: Entity,
  record: Record,
): Record[] => getAllItems(entities, entity, record).filter(isApproved);

export const recordToRequisitioningPayload = (
  record: Record,
): RequisitioningPayload => ({
  requisitioningItemId: record?.properties?.id,
  quantity: getQuantity(record),
  partSupplierId: getPartSupplierId(record),
});

export const isValid = (records: Record[] = []) =>
  R.all(
    (p) => !!(p.quantity && p.partSupplierId),
    records.map(recordToRequisitioningPayload),
  );
