import { Component } from "react";
import {
  decimalSeparators,
  getDecimalSeparatorLabel,
  getTimeOptionLabel,
} from "common/culture";
import { Culture } from "common/culture/supported-cultures";
import { dateFormats, timeFormats } from "common/date-time/formats";
import { DateFormat, TimeFormat } from "common/date-time/types";
import { HorizontalField } from "common/form/ui";
import { UiFormat } from "common/types/ui-format";
import { Selector } from "common/widgets/selector";
import { LanguageSelector } from "common/widgets/language-selector";
import { YesNo } from "common/widgets/radio-button-list/yes-no";
import { ValueProps } from "common/with-value-for";

interface PropTypes extends ValueProps<UiFormat> {
  cultures: Culture[];
  fieldClassName?: string;
  disabled?: boolean;
}

export class CultureSettings extends Component<PropTypes> {
  onChangeCulture = (culture: Culture) => {
    this.props.onChange({ ...this.props.value, culture });
  };

  onChangeDateFormat = (dateFormat: DateFormat) => {
    this.props.onChange({ ...this.props.value, dateFormat });
  };

  onChangeTimeFormat = (timeFormat: TimeFormat) => {
    this.props.onChange({ ...this.props.value, timeFormat });
  };

  onChangeDecimalSeparator = (decimalSeparator: string) => {
    this.props.onChange({ ...this.props.value, decimalSeparator });
  };

  onChangeTimezoneFlag = (displayTimeZoneOnSingleSiteRecords: boolean) => {
    this.props.onChange({
      ...this.props.value,
      displayTimeZoneOnSingleSiteRecords,
    });
  };

  render() {
    const { value, fieldClassName, cultures, disabled } = this.props;

    return (
      <>
        <HorizontalField
          className={`qa-language ${fieldClassName}`}
          isRequired={true}
          label={_("Language")}
          error={!value?.culture}
          disabled={disabled}
        >
          <LanguageSelector
            cultures={cultures}
            value={value?.culture}
            onChange={this.onChangeCulture}
          />
        </HorizontalField>
        <HorizontalField
          className={`qa-date-format ${fieldClassName}`}
          isRequired={true}
          label={_("Date format")}
          error={!value?.dateFormat}
          disabled={disabled}
        >
          <Selector
            options={dateFormats}
            getOptionLabel={getTimeOptionLabel}
            value={value?.dateFormat}
            onChange={this.onChangeDateFormat}
          />
        </HorizontalField>
        <HorizontalField
          className={`qa-time-format ${fieldClassName}`}
          isRequired={true}
          label={_("Time format")}
          error={!value?.timeFormat}
          disabled={disabled}
        >
          <Selector
            options={timeFormats}
            getOptionLabel={getTimeOptionLabel}
            value={value?.timeFormat}
            onChange={this.onChangeTimeFormat}
          />
        </HorizontalField>
        <HorizontalField
          className={`qa-separator ${fieldClassName}`}
          isRequired={true}
          label={_("Decimal separator")}
          error={!value?.decimalSeparator}
          disabled={disabled}
        >
          <Selector
            options={decimalSeparators}
            getOptionLabel={getDecimalSeparatorLabel}
            value={value?.decimalSeparator}
            onChange={this.onChangeDecimalSeparator}
          />
        </HorizontalField>
        <HorizontalField
          className={`qa-display-timezone ${fieldClassName}`}
          isRequired={true}
          label={_("Display timezone on single site record")}
          disabled={disabled}
        >
          <YesNo
            className="x-padding-top-10"
            name={"displayTimezones"}
            value={!!value?.displayTimeZoneOnSingleSiteRecords}
            onChange={this.onChangeTimezoneFlag}
          />
        </HorizontalField>
      </>
    );
  }
}
