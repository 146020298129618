import { Context } from "common/types/context";
import { Properties, Record } from "common/types/records";
import { Entity } from "common/entities/types";
import { AssetMeterData } from "common/record/form/content/related/asset-meter-reading/form/content";
import { AlertWarning } from "common/widgets/alert";
import { ValueProps } from "common/with-value-for";

interface PropTypes extends ValueProps<Properties> {
  context: Context;
  assetMeterReadingEntity: Entity;
  assetId: string;
  assetMeters: Record[];
  meters: Properties[];
  meterTypeId: number;
  previousReading: number;
  isValid: boolean;
  maxReadingPerDay: number;
}

export const AssetMeterReading = ({
  context,
  assetId,
  assetMeterReadingEntity,
  assetMeters = [],
  meters = [],
  meterTypeId,
  previousReading,
  isValid,
  maxReadingPerDay,
  value,
  onChange,
}: PropTypes) => {
  if (!meters.length) {
    return (
      <AlertWarning
        message={_(
          "There are no meters configured for the asset in this Work Order.",
        )}
      />
    );
  }

  return (
    <AssetMeterData
      context={context}
      entity={assetMeterReadingEntity}
      assetMeters={assetMeters}
      isValid={isValid}
      previousReading={previousReading}
      assetId={assetId}
      meterTypeId={meterTypeId}
      maxReadingPerDay={maxReadingPerDay}
      value={value}
      onChange={onChange}
    />
  );
};

AssetMeterReading.displayName = "AssetMeterReading";
