import { defaultFor } from "common";
import { Entity } from "common/entities/types";
import { RelatedValue } from "common/record/form/content/related/types";
import { ValueProps } from "common/with-value-for";
import { Context } from "common/types/context";
import {
  getSitesColumn,
  hasSharedMultipleSitesRelatedEntity,
} from "common/entities";
import { VerticalField } from "common/ui/field";
import { getLocalizedColumnName } from "common/entities/entity-column/functions";
import { WithConfirmation } from "common/widgets/warning/with-confirmation";
import { Warning } from "common/widgets/warning";
import { Required } from "common/widgets/required";
import { SitesSelectorWithChicklets } from "common/widgets/site-selector/with-chicklets";
import { merge2 } from "common/merge";
import { OnConfirmationOpen } from "common/widgets/warning/use-confirmation";

interface PropTypes extends ValueProps<RelatedValue> {
  context: Context;
  entity: Entity;
}

export const SitesSelector = ({
  context,
  entity,
  value = defaultFor<RelatedValue>(),
  onChange,
}: PropTypes) => {
  const sites = value.record?.properties?.sites;
  const sitesColumn = getSitesColumn(entity);

  const onSitesChange = (sites: string[]) =>
    onChange(
      merge2(
        "record",
        "properties",
        { ...value?.record?.properties, sites },
        value,
      ),
    );
  const onSitesChangeWithConfirmation =
    (onOpen: OnConfirmationOpen<string[]>) => (sites: string[]) =>
      onOpen().then(() => onSitesChange(sites));

  return (
    <div className="x-sites-selector-container">
      <VerticalField
        className="x-sites-selector"
        data-testid="form-selector-label"
        isRequired={true}
        label={getLocalizedColumnName(entity, sitesColumn?.name)}
        input={
          <WithConfirmation<string[]>>
            {({ onOpen, onClose, onConfirm, isOpen }) => {
              const confirmation = isOpen ? (
                <Warning
                  title={_("Are you sure?")}
                  content={_(
                    "The sites selected here will also be applied to all its multiple sites related records",
                  )}
                  action1={_("No")}
                  action2={_("Yes")}
                  onAction1={onClose}
                  onAction2={onConfirm}
                />
              ) : undefined;
              return (
                <>
                  {confirmation}
                  <Required value={sites} dataType={sitesColumn?.dataType}>
                    <SitesSelectorWithChicklets
                      context={context}
                      value={sites}
                      onChange={
                        hasSharedMultipleSitesRelatedEntity(
                          entity,
                          context.entities,
                        )
                          ? onSitesChangeWithConfirmation(onOpen)
                          : onSitesChange
                      }
                    />
                  </Required>
                </>
              );
            }}
          </WithConfirmation>
        }
      />
    </div>
  );
};

SitesSelector.displayName = "SitesSelector";
