import {
  FormValue,
  PoItemValue,
} from "common/record/actions/ui/purchase-order/return-items/types";
import { ForeignKey } from "common/types/foreign-key";
import { ReturnPayload } from "common/types/purchase-orders";

export const isValidReturning = (item: PoItemValue, items: PoItemValue[]) => {
  const formIsReturningItems = items.some(
    ({ returningQty }) => returningQty > 0,
  );
  return (
    !item.receivedQty || // item not received yet. valid
    (formIsReturningItems &&
      item.returningQty <= item.receivedQty &&
      item.returningQty >= 0)
  );
};

export const isValidForm = ({ purchaseOrderItems }: FormValue) => {
  return (
    purchaseOrderItems.length &&
    purchaseOrderItems.every((item) => {
      return isValidReturning(item, purchaseOrderItems);
    })
  );
};

export const createReturnPayload = (
  formValue: FormValue,
  adjustmentType: ForeignKey,
): ReturnPayload => {
  const purchaseOrderItems = formValue.purchaseOrderItems
    .filter((i) => i.returningQty > 0)
    .map(({ id, returningQty, unitCost }) => ({
      id,
      returningQty,
      unitCost,
    }));
  return {
    adjustmentType,
    purchaseOrderItems,
    reason: formValue.reason,
  };
};
