import * as React from "react";
import { workOrderSettingsApi } from "common/api/work-order-settings";
import { RecordContentForm } from "common/record/form/form";
import { StandardValue as Value } from "common/record/types";
import { WorkOrderSettings } from "common/types/work-order-settings";
import { ValueProps } from "common/with-value-for";
import { PropTypes } from "x/records/edit/types";
import {
  getDisableToEditWorkOrderReason,
  getEditRecordPermission,
} from "./functions";

type Props = PropTypes & ValueProps<Value>;

interface StateType {
  canEditRecord?: boolean;
}

export class WorkOrderBehaviorForm extends React.Component<Props, StateType> {
  static readonly displayName = "WorkOrderBehaviorForm";
  state: StateType = {
    canEditRecord: true,
  };

  componentDidMount() {
    const { context, entity, disableEdit, value } = this.props;
    const { apiCall, role } = context;
    const { record } = value;
    workOrderSettingsApi(apiCall)
      .get()
      .then((workOrderSettings: WorkOrderSettings) => {
        if (!workOrderSettings) return;

        this.setState({
          canEditRecord: getEditRecordPermission(
            entity,
            disableEdit,
            role,
            record,
            workOrderSettings,
          ),
        });
      });
  }

  render() {
    const { entity, disableEdit } = this.props;
    const { canEditRecord } = this.state;
    const isEditable = !canEditRecord || disableEdit;
    return (
      <RecordContentForm
        {...this.props}
        disableEdit={isEditable}
        disableReason={
          isEditable ? getDisableToEditWorkOrderReason(entity) : undefined
        }
      />
    );
  }
}
