import { find } from "ramda";
import { CancellablePromise } from "real-cancellable-promise";
import { sitedToUtc, toSited } from "common/date-time/common";
import { recordsApi } from "common/api/records";
import { isRangeValid } from "common/date-time/validators";
import type { Entity } from "common/entities/types";
import { isAssignmentEvent } from "common/functions/scheduler";
import type { Context } from "common/types/context";
import { ASSIGNED_ASSIGNMENT_STATUS_ID } from "common/types/system-strings";
import type { WorkOrderEvent } from "x/scheduler2/planner/unassigned-work/types";
import type { EventModel } from "x/scheduler2/types";

const getWOEntityNameFromArg = (assignmentEntity: Entity) =>
  assignmentEntity.arguments.workOrderEntity;

export const hasValidRange = (event: {
  startDate?: string | Date;
  endDate?: string | Date;
}) =>
  !!(
    event.startDate &&
    event.endDate &&
    isRangeValid(toSited(event.startDate), toSited(event.endDate))
  );

export const eventActions = (
  context: Context,
  assignmentsEntities: Entity[],
) => {
  const { apiCall } = context;

  return {
    create: (event: WorkOrderEvent) => {
      if (!hasValidRange(event)) {
        return CancellablePromise.reject(_("Invalid time range"));
      }

      // from a list of assignments entities, find the one that has the same
      // WO entity as the event
      const assignmentEntity = find(
        (e) => getWOEntityNameFromArg(e) === event.workOrderEntityName,
        assignmentsEntities,
      );

      if (!assignmentEntity) {
        return CancellablePromise.reject(
          `${event.workOrderEntityName} has no valid Assignment Entity`,
        );
      }
      return recordsApi(apiCall).create(
        assignmentEntity.name,
        {
          properties: {
            rangeFrom: sitedToUtc(event.startDate),
            rangeTo: sitedToUtc(event.endDate),
            targetId: event.resourceId,
            workOrderId: event.workOrderId,
            status: "N",
            assignmentStatusId: ASSIGNED_ASSIGNMENT_STATUS_ID,
          },
          isNew: true,
          actions: [],
          byPassCheckExplicitAuthentication: true,
        },
        _("The work order was successfully assigned"),
      );
    },

    update: (event: EventModel) => {
      if (!event?.id) {
        return CancellablePromise.reject(_("Missing event ID"));
      }
      if (!hasValidRange(event)) {
        return CancellablePromise.reject(_("Invalid time range"));
      }

      const eventEntityName: string = event?.eventData?.entityName;

      if (!eventEntityName) {
        return CancellablePromise.reject(_("Invalid entity"));
      }
      return recordsApi(apiCall).update(eventEntityName, {
        properties: {
          id: String(event.id),
          rangeFrom: sitedToUtc(event.startDate),
          rangeTo: sitedToUtc(event.endDate),
          targetId: event.resourceId,
        },
        actions: [],
        byPassCheckExplicitAuthentication:
          isAssignmentEvent(event.eventData) && event.eventData.status === "N",
      });
    },
  };
};
