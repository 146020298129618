import { cloneElement, Component, JSX } from "react";
import { OnChange, ValueProps } from "common/with-value-for";

interface PropTypes extends ValueProps<unknown> {
  children?: any;
}

interface StateType {
  value?: unknown;
  origValue?: unknown;
}

export class LineBufferedWidget extends Component<PropTypes, StateType> {
  static readonly displayName = "LineBufferedWidget";
  state: StateType = {
    value: this.props.value,
    origValue: this.props.value,
  };

  componentDidUpdate() {
    if (this.props.value !== this.state.origValue) {
      this.setState({
        value: this.props.value,
        origValue: this.props.value,
      });
    }
  }

  onSubmit = () => {
    if (this.props.value !== this.state.value)
      this.props.onChange(this.state.value);
  };

  onChange = (value?: unknown) => {
    if (value !== this.state.value) this.setState({ value });
  };

  render() {
    return cloneElement(this.props.children, {
      value: this.state.value,
      onSubmit: this.onSubmit,
      onChange: this.onChange,
    });
  }
}

export const withBuffer =
  (value: unknown, onChange: OnChange<unknown>) => (component: JSX.Element) => (
    <LineBufferedWidget value={value} onChange={onChange}>
      {component}
    </LineBufferedWidget>
  );
