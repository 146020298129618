import Highlighter from "react-highlight-words";

interface PropTypes {
  text: string | number;
  search: string;
}

export const ReactHighlightWords = (props: PropTypes) =>
  props.text && props.search ? (
    <Highlighter
      autoEscape={true}
      searchWords={[props.search]}
      textToHighlight={props.text.toString()}
    />
  ) : (
    <>{props.text}</>
  );
