import { getActionIcon } from "common/record/actions/functions/title-and-icon";
import { Action } from "common/record/actions/types";
import { Properties } from "common/types/records";
import { ModifyButtonSmall } from "common/ui/buttons";

type QuickUpdateProps = {
  action: Action;
  properties: Properties;
  index: number;
};

export const QuickUpdate = ({
  action,
  properties,
  index,
}: QuickUpdateProps) => {
  const onUpdate = () => action.fn(properties, index);

  return (
    <div className="x-padding-left-10" key={action.name}>
      <ModifyButtonSmall
        className="x-flex qa-related-update"
        disabled={action.isDisabled}
        onClick={onUpdate}
      >
        <i className={`fa ${getActionIcon(action.name)} x-padding-right-5`} />
        {_("Edit")}
      </ModifyButtonSmall>
    </div>
  );
};
