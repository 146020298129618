import { useCallback, useEffect, useRef, useState } from "react";
import {
  map as leafletMap,
  marker as leafletMarker,
  tileLayer,
  control,
  Map,
  Marker,
  LeafletMouseEvent,
  icon,
  LeafletEventHandlerFn,
} from "leaflet";
import "leaflet.locatecontrol";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { Geolocation } from "common/types/geolocation";
import { ValueProps } from "common/with-value-for";
import { getCoordinates } from "./utils";
import { LAYERS } from "./consts";

import "leaflet-geosearch/dist/geosearch.css";
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css";

const ICON_PATH = "/images/marker-icon.png";
const SHADOW_PATH = "/images/marker-shadow.png";

export type Props = ValueProps<Geolocation> & {
  readonly?: boolean;
};

export const GeolocationWrapper = ({ readonly, value, onChange }: Props) => {
  const mapRef = useRef();
  const [map, setMap] = useState<Map>();
  const [marker, setMarker] = useState<Marker>();

  const handleChange = useCallback(
    (value: Geolocation) => {
      if (readonly) return;
      onChange(value);
    },
    [onChange, readonly],
  );

  useEffect(() => {
    if (!mapRef.current) return () => {};

    const { lat, lng, zoom } = getCoordinates(value);
    const map = leafletMap(mapRef.current, {
      center: [lat, lng],
      zoom: zoom,
      layers: [
        tileLayer(LAYERS.OPEN_STREET_MAP, {
          maxZoom: 19,
        }),
      ],
    });

    const provider = new OpenStreetMapProvider();
    const searchControl = GeoSearchControl({
      provider,
      style: "bar",
      showMarker: false,
    });
    map.addControl(searchControl);

    control
      .locate({ drawCircle: false, drawMarker: false, cacheLocation: false })
      .addTo(map);

    const setValueFromLatLng = async (e: LeafletMouseEvent) => {
      const event =
        process.env.NODE_ENV === "test"
          ? (
              e as unknown as {
                originalEvent: { dataTransfer: LeafletMouseEvent };
              }
            ).originalEvent.dataTransfer
          : e;

      const {
        latlng: { lat, lng },
      } = event;
      const reverseRequest = provider.endpoint({
        query: { lat, lon: lng, zoom: map.getZoom() },
        type: 1,
      });
      const { display_name } = await fetch(reverseRequest).then((value) =>
        value.json(),
      );
      handleChange({
        ...value,
        lat,
        lng,
        address: display_name,
        zoom: map.getZoom(),
      });
    };
    const handleGeoSearch: LeafletEventHandlerFn = ({ location }) => {
      handleChange({
        ...value,
        lat: location.y,
        lng: location.x,
        address: location.label,
      });
    };

    map.on("click", setValueFromLatLng);
    map.on("locationfound", setValueFromLatLng);
    map.on("geosearch/showlocation", handleGeoSearch);

    setMap(map);
    return () => {
      map.off("click", setValueFromLatLng);
      map.off("locationfound", setValueFromLatLng);
      map.off("geosearch/showlocation", handleGeoSearch);
    };
  }, [mapRef]);

  useEffect(() => {
    if (!map) return;

    const { lat, lng, zoom } = getCoordinates(value);
    map.setView([lat, lng], zoom);
    marker?.remove();

    if (!value) return;

    const newMarker = leafletMarker([lat, lng], {
      icon: icon({
        iconUrl: ICON_PATH,
        shadowUrl: SHADOW_PATH,
      }),
    }).addTo(map);

    setMarker(newMarker);
  }, [map, value]);

  return (
    <div className={readonly ? "x-readonly" : ""}>
      {value?.address && (
        <label className="x-field-label qa-address-label">
          {value.address}
        </label>
      )}
      <div ref={mapRef} className="x-map" data-testid="leaflet-map" />
    </div>
  );
};
