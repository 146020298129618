import * as R from "ramda";
import { Culture } from "common/culture/supported-cultures";
import { Entities, Entity } from "common/entities/types";
import { translate as translateEntity } from "./entity-translator";
import { translate as translateQuery } from "./query-translator";

const getMap =
  (culture: Culture) =>
  (entities: Entity[]): Entities =>
    R.fromPairs(
      entities
        .map(translateEntity(culture))
        .map((e) => [e.name, e] as [string, Entity]),
    );

const translateQueries =
  (culture: Culture) =>
  (entities: Entities): Entities =>
    R.mapObjIndexed(
      (e: Entity) =>
        R.mergeRight(e, {
          query: translateQuery(entities, culture, e.name, e.query),
        }),
      entities,
    );

export const translate = (culture: Culture, entities: Entity[]): Entities =>
  R.compose(translateQueries(culture), getMap(culture))(entities);
