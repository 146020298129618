import * as R from "ramda";
import { Component } from "react";
import { showHour, showMonth, translateWeekdayNumber } from "common/culture";
import { weekdayNumbers } from "common/date-time/weekday";
import { genericSort } from "common/functions/generics";
import {
  CronField,
  ExpressionField,
  FREQUENCIES,
  LAST_DAY_OF_MONTH,
} from "common/types/cron";
import {
  FREQUENCIES_OPTIONS,
  getCron,
  getSelectedFrequency,
  showCronDay,
  translateFrequency,
} from "common/utils/cron";
import {
  getMaxDayInMonth,
  labelFor,
  optionsForSequence,
} from "common/widgets/cron/functions";
import { Selector } from "common/widgets/selector";
import { ValueProps } from "common/with-value-for";

import "./index.scss";

export class Cron extends Component<ValueProps<string>> {
  static readonly displayName = "Cron";

  onFrequencyChange = (frequency: string) => {
    this.props.onChange(FREQUENCIES[frequency]);
  };

  handleChange = (index: number) => {
    const { value } = this.props;

    return (newValue: number) => {
      const updatedValue = newValue.toString();
      const updatedCron = R.update(index, updatedValue, value.split(" "));
      this.props.onChange(updatedCron.join(" "));
    };
  };

  getCronForm = (frequency: string) => {
    const { value } = this.props;

    if (!value) return null;

    const cron = getCron(value);
    const maxDay = getMaxDayInMonth(cron[CronField.Month]);

    const hours = (
      <Selector<ExpressionField>
        key="hours"
        label="hours"
        className={`x-flex-grow-1 x-margin-left-10 qa-hours`}
        value={cron[CronField.Hour]}
        onChange={this.handleChange(CronField.Hour)}
        getOptionLabel={showHour}
        options={optionsForSequence(0, 23)}
        sortOptions={genericSort}
      />
    );

    const days = (
      <Selector<ExpressionField>
        key="days"
        label="days"
        className={`x-flex-grow-1 x-margin-left-10 qa-days`}
        value={cron[CronField.DayOfMonth]}
        onChange={this.handleChange(CronField.DayOfMonth)}
        getOptionLabel={showCronDay}
        options={[...optionsForSequence(1, maxDay), LAST_DAY_OF_MONTH]}
        sortOptions={genericSort}
      />
    );

    const months = (
      <Selector<ExpressionField>
        key="months"
        label="months"
        className={`x-flex-grow-1 x-margin-left-10 qa-months`}
        value={cron[CronField.Month]}
        onChange={this.handleChange(CronField.Month)}
        getOptionLabel={showMonth}
        options={optionsForSequence(1, 12)}
        sortOptions={genericSort}
      />
    );

    const weekDays = (
      <Selector<number>
        key="weekdays"
        label="weekdays"
        className="x-flex-grow-1 x-margin-left-10 qa-weekdays"
        value={cron[CronField.DayOfWeek]}
        onChange={this.handleChange(CronField.DayOfWeek)}
        options={weekdayNumbers}
        getOptionLabel={translateWeekdayNumber}
        sortOptions={genericSort}
      />
    );

    return (
      <div className="x-flex x-margin-left-10 x-flex-grow-1">
        {frequency === "everyWeek" && [labelFor(_("on")), weekDays]}
        {frequency === "everyMonth" && [labelFor(_("on the")), days]}
        {frequency === "everyYear" && [labelFor(_("on")), months, days]}

        {labelFor(_("at"))}
        {hours}
      </div>
    );
  };

  render() {
    const { value } = this.props;
    const frequency = getSelectedFrequency(value);

    return (
      <div className="qa-cron x-flex">
        <Selector<string>
          label="frequency"
          className="x-cron-frequencies qa-frequencies"
          value={frequency}
          onChange={this.onFrequencyChange}
          getOptionLabel={translateFrequency}
          options={FREQUENCIES_OPTIONS}
        />
        {this.getCronForm(frequency)}
      </div>
    );
  }
}
