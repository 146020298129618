import { isNumeric } from "common/utils/number";
import { displayCurrency } from "common/widgets/currency/functions";
import { formatDecimal } from "common/utils/decimal";
import { HighlightProps, Label } from "common/widgets/label";

interface PropTypes extends HighlightProps {
  decimalSeparator: string;
  currencySymbol: string;
  value: string | number;
  decimalScale: number;
  displayValue?: any;
}

export const getCurrencyLabel = (
  value: string | number,
  currencySymbol: string,
  decimalSeparator: string,
  decimalScale: number,
): string => {
  return isNumeric(value)
    ? `${currencySymbol || "$"} ${displayCurrency(
        decimalSeparator,
        formatDecimal(value, decimalScale),
      )}`
    : value || "";
};

export const CurrencyLabel = ({
  decimalSeparator,
  currencySymbol,
  value,
  decimalScale,
  displayValue,
  textToHighlight,
}: PropTypes) => {
  const valueToDisplay = getCurrencyLabel(
    displayValue?.(value) || value,
    currencySymbol,
    decimalSeparator,
    decimalScale,
  );

  return <Label value={valueToDisplay} textToHighlight={textToHighlight} />;
};
CurrencyLabel.displayName = "CurrencyLabel";
