import * as React from "react";
import { Context } from "common/types/context";
import { getLocalizedName } from "common";
import { searchApi } from "common/api/search";
import { EntityColumn } from "common/entities/entity-column/types";
import { ValueComponent } from "common/with-value-for";
import { FormValidationProps } from "common/form/types";
import { YourComponentProps } from "./types";
import { withUnique } from "./base";

interface UniqueRunQueryPropTypes {
  recordId: string;
  entityName: string;
  context: Context;
  column: EntityColumn;
}

type UniqueRunQueryProps<PropTypes> = PropTypes &
  UniqueRunQueryPropTypes &
  FormValidationProps;

export function withUniqueRunQuery<PropTypes>(
  YourComponent: React.ComponentType<YourComponentProps<PropTypes>>,
) {
  const UniqueWrapper = withUnique<PropTypes>(YourComponent);

  return class UniqueRunQuery extends ValueComponent<
    any,
    UniqueRunQueryProps<PropTypes>
  > {
    fetchIsUnique = (value: string) => {
      const { column, context, entityName, recordId } = this.props;

      const equalFilter = { op: "eq", name: column.name, value };

      const filter = recordId
        ? { and: [equalFilter, { op: "neq", name: "id", value: recordId }] }
        : equalFilter;

      return searchApi(context.apiCall)
        .runQuery({
          entity: entityName,
          query: { select: [{ name: column.name }], filter, pageSize: 1 },
        })
        .then((data: unknown[]) => !(data && data.length));
    };

    render() {
      const { column, formValidation, onFormValidationChange } = this.props;

      return (
        <UniqueWrapper
          {...this.props}
          useUnique={column.unique}
          validateOnMount={true}
          columName={column.name}
          columnLocalizedName={getLocalizedName(column)}
          formValidation={formValidation}
          onFormValidationChange={onFormValidationChange}
          fetchIsUnique={this.fetchIsUnique}
        />
      );
    }
  };
}
