import * as R from "ramda";
import {
  addDateDuration,
  getDifferenceInDays,
  getEachWeekInterval,
  getEndOf,
  getEndOfDayForDate,
  getStartOf,
  subtractDateDuration,
} from "common/date-time/calculators";
import { getUtcNow, isWeekend } from "common/date-time/common";
import { formatDateToFullNamedDay } from "common/date-time/helpers/scheduler";
import type { UTCDateRange, UTCDateTime } from "common/date-time/types";
import { formatDate } from "common/date-time/formatters";
import { isBefore } from "common/date-time/validators";
import { getByBehaviorArgument } from "common/entities";
import type { Entities, Entity } from "common/entities/types";
import { isAssignmentEvent } from "common/functions/scheduler";
import type { SelectField } from "common/query/types";
import type { Context } from "common/types/context";
import type { Sizes } from "common/widgets/sizer";
import {
  DEFAULT_SCROLL_HEIGHT,
  DEFAULT_TILE_HEIGHT,
  ENTITY_DROPDOWN_HEIGHT,
  MINIMUM_SCROLL_HEIGHT,
  SCROLL_HEIGHT_MARGIN,
  SEARCH_DROPDOWN_HEIGHT,
} from "x/scheduler2/planner/unassigned-work/consts";
import type { WorkOrder } from "x/scheduler2/planner/unassigned-work/types";
import { getCurrentDateRange } from "x/scheduler2/shared";
import type { EventModel, ViewType } from "x/scheduler2/types";

export interface UnassignedDayRange extends UTCDateRange {
  label?: string;
  additionalInfo?: string;
}

export const getCurrentDateRangeDays = (
  date: UTCDateTime,
  view: ViewType,
  hideWeekends: boolean,
): UnassignedDayRange[] => {
  const currentRange = getCurrentDateRange(date, view);

  if (isBefore(currentRange.to, getUtcNow())) return [];

  if (view === "month") {
    const startDate = currentRange.from;
    const endDate = currentRange.to;

    return getEachWeekInterval(startDate, endDate).map((weekStart) => {
      const from = weekStart;
      const endOfWeek = getEndOf(weekStart, "week");
      const to = endOfWeek > endDate ? endDate : endOfWeek;

      return {
        from,
        to,
        label: _("Week {NUMBER}").replace(
          "{NUMBER}",
          formatDate(weekStart, "w"),
        ),
        additionalInfo: _("Planned work from {FROM_DATE} to {TO_DATE}")
          .replace("{FROM_DATE}", formatDateToFullNamedDay(from))
          .replace("{TO_DATE}", formatDateToFullNamedDay(to)),
      };
    });
  }

  const durationInDays = getDifferenceInDays(
    currentRange.from,
    currentRange.to,
  );
  const days = R.range(0, durationInDays);

  return days
    .map((day) => {
      const startOfDay = getStartOf(
        addDateDuration(currentRange.from, day, "days"),
        "day",
      );
      return {
        from: startOfDay,
        to: getEndOf(startOfDay, "day"),
      };
    })
    .filter((day) => !hideWeekends || view !== "week" || !isWeekend(day.from));
};

export const defaultWOSelect: SelectField[] = [
  { name: "description" },
  { name: "number" },
  { name: "assetId" },
];

export const removeWorkOrderById = (
  workOrders: WorkOrder[],
  excludedId: string,
) =>
  excludedId
    ? (workOrders || []).filter((wo: WorkOrder) => wo.SYSTEM_id !== excludedId)
    : workOrders;

export const hasWorkOrderById = (
  workOrders: WorkOrder[],
  workOrderId: string,
) =>
  !!workOrderId &&
  (workOrders || []).some((wo: WorkOrder) => wo.SYSTEM_id === workOrderId);

export const getOverdueWOsRange = (view: ViewType): UnassignedDayRange => {
  const currentRange = getCurrentDateRange(getUtcNow(), view);

  const to = getEndOfDayForDate(
    subtractDateDuration(currentRange?.from, 1, "days"),
  );

  return {
    label: _("OVERDUE"),
    additionalInfo: _("Unassigned PMs until {OVERDUE_DATE}").replace(
      "{OVERDUE_DATE}",
      formatDateToFullNamedDay(to),
    ),
    from: undefined,
    to,
  };
};

export const isDayOpened = (
  view: ViewType,
  daysRanges: UnassignedDayRange[],
  day: UnassignedDayRange,
) => daysRanges.length === 1 || (view === "day" && !!day?.from);

export const getScrollHeight = (
  parentPanelHeight: number,
  totalCount: number,
) => {
  const calculatedSize = parentPanelHeight
    ? R.max(parentPanelHeight - SCROLL_HEIGHT_MARGIN, MINIMUM_SCROLL_HEIGHT)
    : DEFAULT_SCROLL_HEIGHT;

  return totalCount * DEFAULT_TILE_HEIGHT < calculatedSize
    ? undefined
    : calculatedSize;
};

export const getParentPanelHeight = (
  panelSizes: Sizes,
  isDropdownVisible: boolean,
) =>
  (panelSizes?.height ?? DEFAULT_SCROLL_HEIGHT) -
  (isDropdownVisible ? ENTITY_DROPDOWN_HEIGHT : 0) -
  SEARCH_DROPDOWN_HEIGHT;

export const getAssignmentsWithConflicts = (
  events: ReadonlyArray<EventModel>,
  workOrderId: string,
): string[] =>
  R.reduce(
    (acc: string[], event: EventModel) =>
      isAssignmentEvent(event.eventData) &&
      event.eventData.workOrderId?.id === workOrderId &&
      event.eventData.hasConflicts
        ? [...acc, event.eventData.id]
        : acc,
    [],
  )(events || []);

const getWOEntityName = (assignmentEntity: Entity) =>
  assignmentEntity.arguments.workOrderEntity;

export const getWorkOrderEntities = (
  entities: Entities,
  assignmentEntities: Entity[],
) =>
  assignmentEntities
    .map(getWOEntityName)
    .map((entityName) => entities[entityName]);

export const getAssignmentEntityFromWorkOrderEntity = (
  context: Context,
  entity: Entity,
) =>
  getByBehaviorArgument(
    context.entities,
    "Assignment",
    "workOrderEntity",
    entity.name,
  );
