import { Context } from "common/types/context";
import { ForeignKey } from "common/types/foreign-key";
import { withValue, WithValue } from "common/with-value";
import { AdvancedSingleRecordSelector } from "common/widgets/record-selector";
import { filterTaskEntities } from "common/entities";
import { Entity, Entities } from "common/entities/types";
import { HorizontalField } from "common/form/ui";
import { Hint } from "common/widgets/hint";

interface PropTypes {
  context: Context;
  entities: Entities;
  entity: Entity;
}

const getTaskEntity = (entity: Entity, entities: Entities) => {
  const filteredTaskEntities = filterTaskEntities(
    entity.arguments.workOrderEntity,
    entities,
  );
  return filteredTaskEntities[entity.arguments.taskEntity];
};

const FormComp = ({
  context,
  entities,
  entity,
  value,
  onChangeSetValue,
}: PropTypes & WithValue<ForeignKey>) => {
  const message = _(
    "Task description and procedures will be copied automatically to the Work Order",
  );
  return (
    <div className="x-pm-task x-pm-section qa-pm-section">
      <HorizontalField label={_("Task")}>
        <AdvancedSingleRecordSelector
          context={context}
          entity={getTaskEntity(entity, entities)}
          withLinks={true}
          allowClear={true}
          value={value}
          onChange={onChangeSetValue}
        />
        <Hint message={message} />
      </HorizontalField>
    </div>
  );
};

export const Form = withValue<ForeignKey, PropTypes>(FormComp, "Form");
