import * as R from "ramda";
import { Entity } from "common/entities/types";
import {
  CreateRecordAction,
  UpdateTableValueAction,
} from "common/types/workflows";
import { Form } from "common/types/forms";
import { getLayoutGroupColumns } from "common/form/functions/common";
import { Context } from "common/types/context";
import { getColumn } from "common/entities";
import { EntityColumn } from "common/entities/entity-column/types";
import {
  DataType,
  workflowReplaceBlacklist,
} from "common/entities/entity-column/data-type/types";

export const DEFAULT_FIELD: UpdateTableValueAction = {
  field: undefined,
  isExpression: false,
  value: undefined,
  error: undefined,
};

const workflowCreateRecordBlacklist: DataType[] = [
  ...workflowReplaceBlacklist,
  // We don't have a mechanism to move the file uploaded from Temp S3 folders.
  // Will be removed when we have this implemented (X5-10827)
  "media",
  "document",
  "image",
];

export const getFormLayoutColumns = (context: Context, formId: number) =>
  getLayoutGroupColumns(
    context.forms.find((f) => f.id === formId)?.settings.groups,
  );

const globalFormFilterFn = (form: Form, entityName: string) =>
  form.entityName === entityName && !form.sites.length;

export const hasGlobalForms = (forms: Form[], entityName: string) =>
  forms?.some((form) => globalFormFilterFn(form, entityName));

export const filterGlobalForms = (forms: Form[], entityName: string) =>
  forms?.filter((form) => globalFormFilterFn(form, entityName));

export const isConfigurableField = (column: EntityColumn) =>
  // The readOnly means, it is a system readOnly filed for eg., computed columns
  // The custom readOnly fields are userReadOnly
  !column.readOnly &&
  !R.includes(column.dataType, workflowCreateRecordBlacklist);

export const getFormData = (data: UpdateTableValueAction[]) =>
  data?.find((f) => f.field === "formId");

export const getFormId = (data: UpdateTableValueAction[]): number =>
  data?.find((f) => f.field === "formId")?.value as number;

const getField = (column: EntityColumn, data?: UpdateTableValueAction[]) => {
  const dataField = data?.find((f) => f.field === column.name);

  return {
    ...DEFAULT_FIELD,
    field: column.name,
    isExpression: !!dataField?.isExpression,
    value: dataField?.value,
  };
};

export const getRequiredFieldsFromEntity = (
  entity: Entity,
  data?: UpdateTableValueAction[],
): UpdateTableValueAction[] =>
  entity?.columns.reduce((acc, column) => {
    const required =
      isConfigurableField(column) && column.required && !column.unique;

    return required ? [...acc, getField(column, data)] : acc;
  }, []);

const getRequiredFieldsFromForm = (
  context: Context,
  entity: Entity,
  formId: number,
  data?: UpdateTableValueAction[],
): UpdateTableValueAction[] => {
  const layoutColumns = getFormLayoutColumns(context, formId);

  return layoutColumns?.reduce((acc, lc) => {
    const entityColumn = getColumn(entity, lc.columnName);
    const required =
      isConfigurableField(entityColumn) &&
      !entityColumn.unique &&
      (lc.required || entityColumn.required);

    return required ? [...acc, getField(entityColumn, data)] : acc;
  }, []);
};

const getMissingRequiredFieldsFromForm = (
  context: Context,
  entity: Entity,
  formId: number,
  data: UpdateTableValueAction[],
): UpdateTableValueAction[] =>
  getRequiredFieldsFromForm(context, entity, formId).filter(
    (f) => !data.some((i) => i.field === f.field),
  );

const getMissingRequiredFieldsFromEntity = (
  entity: Entity,
  data: UpdateTableValueAction[],
): UpdateTableValueAction[] =>
  getRequiredFieldsFromEntity(entity).filter(
    (f) => !data.some((i) => i.field === f.field),
  );

export const getMissingRequiredFields = (
  context: Context,
  entityName: string,
  data: UpdateTableValueAction[],
): UpdateTableValueAction[] => {
  if (!data) return [];

  const entity = context.entities[entityName];
  if (!entity) return [];

  const formId = getFormId(data);
  if (hasGlobalForms(context.forms, entityName) && !formId) return [];

  return hasGlobalForms(context.forms, entityName) && formId
    ? getMissingRequiredFieldsFromForm(context, entity, formId, data)
    : getMissingRequiredFieldsFromEntity(entity, data);
};

export const isCreateRecordValid = (action: CreateRecordAction) => {
  if (!action) return false;

  const { entityName, data = [] } = action;

  return !!(
    entityName &&
    data.every((data) => {
      const { error, value } = data;
      const errorType = error?.type;

      // To override validation for the unique field added and allow saving
      // the configuration with unique field and remove them on save.
      return (
        errorType === "uniqueFieldError" ||
        (value !== undefined &&
          !(errorType === "expressionError" && error.message))
      );
    })
  );
};

export const isRecordConfigValid = (
  context: Context,
  modalValue: CreateRecordAction,
) => {
  if (!modalValue) return false;

  const { entityName, data } = modalValue;
  const formId = getFormId(data);
  // If new fields are marked as required in entity or Form level, don't
  // enable the save until all the required fields are added.
  const isConfigValid =
    isCreateRecordValid(modalValue) &&
    !getMissingRequiredFields(context, entityName, data).length;

  return hasGlobalForms(context.forms, entityName)
    ? formId && isConfigValid
    : isConfigValid;
};

export const getRequiredFieldsFromEntityAndForm = (
  context: Context,
  formId: number,
  value: CreateRecordAction,
): UpdateTableValueAction[] => {
  const { entityName, data } = value;
  const entity = context.entities[entityName];
  if (!entity) return [];

  const entityRequiredField = getRequiredFieldsFromEntity(entity, data);

  return entityRequiredField.concat(
    getRequiredFieldsFromForm(context, entity, formId, data).filter(
      (f) => !R.any((i) => i.field === f.field, entityRequiredField),
    ),
  );
};

export const getFilteredFields = (
  context: Context,
  entityName: string,
  data: UpdateTableValueAction[],
): EntityColumn[] =>
  context.entities[entityName]?.columns.filter(
    (c) => isConfigurableField(c) && !R.any((i) => i.field === c.name, data),
  );

export const showConfigForm = (
  context: Context,
  modalValue: CreateRecordAction,
) => {
  const { entityName, data } = modalValue;
  if (!entityName) return false;

  // If global forms exist, form selection is required and hide
  // 'FieldInputWrapper' until the form is selected.
  // If no global forms exist, show the 'FieldInputWrapper'.
  return !hasGlobalForms(context.forms, entityName) || !!getFormId(data);
};
