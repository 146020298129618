import { Component } from "react";
import { Context } from "common/types/context";
import { RelatedSummary } from "common/types/records";
import { Highlight } from "common/widgets/highlight";
import { getHighlighter } from "common/widgets/list-with-search/search-lib";
import { Modal } from "common/widgets/modal";
import {
  getDisplayText,
  getMatchingProp,
  stringifySummaryRecords,
} from "./functions";
import { SummaryRecordComponent } from "./summary-record";

import "./index.scss";

interface PropTypes {
  context: Context;
  relatedEntityName: string;
  summary: RelatedSummary;
  filterKey: string;
}

interface StateType {
  open: boolean;
}

export class RelatedSummaryComponent extends Component<PropTypes, StateType> {
  static readonly displayName = "RelatedSummaryComponent";
  state: StateType = { open: false };

  getDisplayItem = () => {
    const { filterKey, summary } = this.props;
    const { items } = summary;

    if (!items || !items.length) return "";
    if (!filterKey) return getDisplayText(items[0].title);

    const filter = filterKey.toLowerCase();
    const stringifiedItems = stringifySummaryRecords(items);

    const matchingTitle =
      getMatchingProp(stringifiedItems, "title", filter) ||
      getMatchingProp(stringifiedItems, "subtitle", filter) ||
      getMatchingProp(stringifiedItems, "subsubtitle", filter);

    return matchingTitle
      ? getHighlighter(filter)(matchingTitle)
      : getDisplayText(items[0].title);
  };

  onToggleModal = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { context, summary, relatedEntityName, filterKey } = this.props;

    if (!summary) return null;

    const { items } = summary;
    const { open } = this.state;

    const classNames = `x-related-summary x-qa-summary-${summary.name}`;
    return (
      <div className={classNames}>
        {items?.length ? (
          <div className="x-related-summary-cell" onClick={this.onToggleModal}>
            <div className="x-ellipsis x-summary-sample">
              <Highlight text={this.getDisplayItem()} />
            </div>
            {items.length > 1 ? (
              <div className="x-summary-more">{`+${items.length - 1}`}</div>
            ) : undefined}
          </div>
        ) : undefined}
        {open && (
          <Modal
            content={items.map((r) => (
              <SummaryRecordComponent
                key={r.number}
                record={r}
                context={context}
                entityName={relatedEntityName}
                filterKey={filterKey}
              />
            ))}
            title={summary.name}
            hideFooter={true}
            onCancel={this.onToggleModal}
          />
        )}
      </div>
    );
  }
}
