import * as R from "ramda";
import { getLocalizedName } from "common";
import { Culture } from "common/culture/supported-cultures";
import { getColumn } from "common/entities";
import { Entities, Entity } from "common/entities/types";
import { getLayoutGroupColumns } from "common/form/functions/common";
import { Group, GroupColumn, Layout } from "common/form/types";
import { Form } from "common/types/forms";
import { cleanUp, keyLabel, KeyLabel, translateName } from "./functions";
import { Captions } from "./types";

const mapOptional = <T>(fn: (x: T, index: number) => T, list: T[]): T[] =>
  list && list.map(fn);

const translateColumns = (
  captions: Captions,
  columns: GroupColumn[],
): GroupColumn[] =>
  columns &&
  columns.map((col) =>
    R.mergeRight(col, {
      hint: captions[`hint.${col.columnName}`],
    }),
  );

const translateGroup =
  (captions: Captions) =>
  (group: Group): Group =>
    R.mergeRight(group, {
      label: captions[group.name],
      columns: translateColumns(captions, group.columns),
    });

const translateLayout = (captions: Captions, layout: Layout): Layout =>
  layout &&
  R.mergeRight(layout, {
    groups: mapOptional(translateGroup(captions), layout.groups),
  });

export const translateKey =
  (entity: Entity, form: Form) =>
  (key: string): string =>
    key === "name"
      ? form.label || _("Name")
      : key
          .replace(/^section\.(.+)$/, `${_("Section")} $1`)
          .replace(/^group\.(.+)$/, `${_("Group")} $1`)
          .replace(/^hint\.(.+)$/, (_, col) =>
            "Hint for COLUMN".replace(
              "COLUMN",
              (getColumn(entity, col) || { localizedName: col }).localizedName,
            ),
          )
          .replace(/^[^.]+\./, ""); // remove any 'PREFIX.' from key

export const translate = (
  culture: Culture,
  form: Form,
  entities: Entities = {},
): Form => {
  const { labels, label, id, entityName, settings } = form;
  const captions = labels ? labels[culture] : {};
  const localizedEntityName =
    getLocalizedName(entities[entityName]) || entityName;
  const withCaptions = !R.isEmpty(captions || {});

  if (withCaptions) {
    return R.mergeRight(form, {
      label: translateName(captions),
      settings: translateLayout(captions, settings),
      localizedEntityName,
    });
  } else {
    return R.mergeRight(form, {
      label: label || (id && `${_("Form")} ${id}`) || "",
      localizedEntityName,
    });
  }
};

const extractHints = (groups: Group[]): KeyLabel[] =>
  getLayoutGroupColumns(groups)
    .filter((col) => !!col.hint)
    .map((col) => keyLabel(`hint.${col.columnName}`, col.hint));

const getSectionKeys = (groups: Group[]): string[] =>
  R.flatten(
    groups
      .map((g) => [g.name])
      .concat(extractHints(groups).map((kl) => kl.key)),
  );

export const getKeys = (form: Form): string[] => [
  "name",
  ...getSectionKeys(
    (form && form.settings ? form.settings : ({} as Layout)).groups || [],
  ),
];

const cleanUpLabels = cleanUp(getKeys);

const extractGroupsLabels = (groups: Group[] = []): KeyLabel[] =>
  groups.map((g) => keyLabel(g.name, g.label));

const extractLayoutCaptions = (layout: Layout, formLabel: string): Captions =>
  layout && layout.groups
    ? R.fromPairs(
        extractGroupsLabels(layout.groups)
          .concat(extractHints(layout.groups))
          .concat([{ key: "name", label: formLabel }])
          .map(({ key, label }) => [key, label] as [string, string]),
      )
    : {};

export const updateLayoutLabels = (culture: Culture, form: Form): Form =>
  cleanUpLabels(
    R.mergeRight(form, {
      labels: R.mergeRight(form.labels, {
        [culture]: extractLayoutCaptions(form.settings, form.label),
      }),
    }),
  );
