import * as React from "react";
import { DragSource, DropTarget } from "react-dnd-cjs";
import { findDOMNode } from "react-dom";
import { Component } from "common/component";
import { Item, PropTypes as ItemProps } from "./item";

type DomTypes = Element | null | Text;

const isElement = (value: DomTypes): value is Element =>
  !!value && value instanceof Element;

const getElement = (value: DomTypes) => (isElement(value) ? value : undefined);

export function createDraggableItem<TItem>(id: string) {
  const dragId = `ItemDraggable${id}`;

  interface PropTypes {
    itemProps: ItemProps<TItem>;
    dragIndex?: number;
    connectDragSource?: (x: any) => void;
    connectDropTarget?: (x: any) => void;
    isOver?: boolean;
    isDragging?: boolean;
  }
  interface StateType {
    overInput: boolean;
  }

  class ItemDraggable extends Component<PropTypes, StateType> {
    state: StateType = {
      overInput: false,
    };
    node: Element = undefined;

    componentWillUnmount() {
      this.detachEventListeners(this.node);
      this.node = undefined;
    }

    getInputElements = (node: Element) => {
      return node
        ? Array.from(
            node.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>(
              "input, textarea",
            ),
          ).filter((e) => !e.readOnly)
        : [];
    };

    onHoverOverInput = () => {
      this.setState({ overInput: true });
    };

    onHoverOutOfInput = () => {
      this.setState({ overInput: false });
    };

    detachEventListeners = (node: Element) => {
      this.getInputElements(node).map((e) => {
        e.removeEventListener("mouseleave", this.onHoverOutOfInput);
        e.removeEventListener("mouseenter", this.onHoverOverInput);
      });
    };

    render() {
      const {
        itemProps,
        isOver,
        dragIndex,
        connectDropTarget,
        connectDragSource,
      } = this.props;
      const { overInput } = this.state;

      return (
        <Item<TItem>
          {...itemProps}
          hoverBefore={isOver && dragIndex > itemProps.index}
          hoverAfter={isOver && dragIndex < itemProps.index}
          ref={
            // TODO: double check why this doesn't work when moving int a
            // method!
            (instance: React.ReactInstance) => {
              this.detachEventListeners(this.node); // detach from old node
              // TODO ---> update the lib to use dnd in a simpler way
              // eslint-disable-next-line react/no-find-dom-node
              this.node = getElement(findDOMNode(instance));

              if (!overInput) {
                connectDragSource(this.node);
                connectDropTarget(this.node);
                this.getInputElements(this.node).map((e) => {
                  e.addEventListener("mouseenter", this.onHoverOverInput);
                });
              } else {
                this.getInputElements(this.node).map((e) => {
                  e.addEventListener("mouseleave", this.onHoverOutOfInput);
                });
              }
            }
          }
        />
      );
    }
  }

  const target = DropTarget<PropTypes>(
    dragId,
    {
      drop(props, monitor) {
        const dragIndex = (monitor.getItem() as any).dragIndex;

        const dropIndex = props.itemProps.index;
        if (dragIndex === dropIndex) return;

        props.itemProps.onMove(dragIndex, dropIndex);
        (monitor.getItem() as any).dragIndex = dropIndex;
      },
    },
    (connect, monitor) => {
      const item = (monitor.getItem() || {}) as any;
      return {
        dragIndex: item.dragIndex,
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
      };
    },
  );

  const source = DragSource<PropTypes>(
    dragId,
    {
      beginDrag(props) {
        return {
          dragIndex: props.itemProps.index,
        };
      },
    },
    (connect) => ({
      connectDragSource: connect.dragSource(),
    }),
  );

  return source(target(ItemDraggable));
}
