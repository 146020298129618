import { getLocalizedName } from "common";
import { behaveAs } from "common/entities";
import { formBlacklist } from "common/entities/entity-column/data-type/types";
import { isRestrictedForRole } from "common/entities/entity-column/functions";
import { Entity } from "common/entities/types";
import { Context } from "common/types/context";
// eslint-disable-next-line import/no-cycle
import { Row } from "./row";

const scheduledEventBlacklist: string[] = [
  "hasProjections",
  "projectionLength",
  "projectionInterval",
];

export const getAllowedMassChangeColumns = (
  context: Context,
  entity: Entity,
) => {
  const allowed = entity.columns.filter(
    (c) =>
      !c.readOnly &&
      !c.userReadOnly &&
      !isRestrictedForRole(c.roleIds, context.role) &&
      !c.unique &&
      !formBlacklist.includes(c.dataType),
  );

  if (behaveAs("ScheduledEvent", entity))
    return allowed.filter((c) => !scheduledEventBlacklist.includes(c.name));

  return allowed;
};

export const isValid = (rows: Row[]) =>
  rows.length && rows.every((f) => f.value !== undefined && f.value !== null);

export const getMessageForTheEntity = (entity: Entity) => {
  if (behaveAs("ScheduledEvent", entity))
    return _(
      "Updating {ENTITY_NAME} records will delete previously generated work order projections. If generate projections option is turned on, the previously generated work order projections will be re-generated with the updated information from the {ENTITY_NAME} records.",
    ).replace(/{ENTITY_NAME}/g, getLocalizedName(entity) ?? _("Scheduled WOs"));

  return "";
};
