import * as React from "react";
import { JSX } from "react";
import { ModifyLinkButtonLarge } from "common/ui/buttons";
import { ListWithSearch } from "common/widgets/list-with-search/list";
import { PropOrPathOf } from "common/widgets/list-with-search/types";
import { ValueProps } from "common/with-value-for";

interface DisplayPropTypes<TRecord> {
  records: TRecord[];
  newPath?: string;
  loading?: boolean;
  label?: string;
  children?: any;
  emptyListContent?: JSX.Element;
}

export interface Options {
  disableSorting?: boolean;
  showNewButton?: boolean;
  className?: string;
}

export const createList = function <
  TRecord,
  TPropTypes,
  TKey extends PropOrPathOf<TRecord> = PropOrPathOf<TRecord>,
>(
  defaultLabel: () => string,
  filterBy: TKey | TKey[],
  display: (
    props: Readonly<TPropTypes>,
    record: TRecord,
    originalRecord?: TRecord,
  ) => JSX.Element,
  options?: Options,
): React.ComponentClass<
  TPropTypes & DisplayPropTypes<TRecord> & ValueProps<string>
> {
  type Props = TPropTypes & DisplayPropTypes<TRecord> & ValueProps<string>;

  return class extends React.Component<Props> {
    onDisplay = (record: TRecord, originalRecord: TRecord) => {
      return display(
        this.props as Readonly<TPropTypes>,
        record,
        originalRecord,
      );
    };

    render() {
      const {
        records = [] as TRecord[],
        label = defaultLabel(),
        loading,
        newPath,
        children,
        emptyListContent,
        value,
        onChange,
      } = this.props;

      return (
        <div className={options?.className} data-testid="list-wrapper">
          <ListWithSearch<TRecord>
            filterBy={filterBy}
            disableSorting={options?.disableSorting}
            label={label}
            display={this.onDisplay}
            loading={loading}
            records={records}
            onChange={onChange}
            value={value}
            emptyListContent={emptyListContent}
          >
            {children}
            {newPath && options?.showNewButton ? (
              <ModifyLinkButtonLarge
                href={newPath}
                className="x-list-new-button x-float-right qa-list-new-button"
              >
                {_("New")}
              </ModifyLinkButtonLarge>
            ) : undefined}
          </ListWithSearch>
        </div>
      );
    }
  };
};
