import { Context } from "common/types/context";
import { QueryForEntity } from "common/query/types";
import { Output } from "common/data/types";
import { QueryToValue } from "common/data/query";
import { PaginationFooter } from "common/widgets/pagination/footer";
import { usePagination } from "common/widgets/pagination/use-pagination";

export interface PropTypes {
  context: Context;
  query: QueryForEntity;
  output: Output;
  withLinks: boolean;
  className?: string;
}

export const Preview = (props: PropTypes) => {
  const { context, query, output, withLinks, className = "" } = props;

  const {
    loadingRecords,
    page,
    pageSize,
    totalRecords,
    onChangePageSize,
    onChangePage,
    runQuery,
  } = usePagination(context);

  return (
    <div className={`x-report-preview ${className}`}>
      <QueryToValue
        context={context}
        goTo={undefined}
        runQuery={runQuery}
        query={query}
        notOlderThan={undefined}
        hidePaging={true}
        output={output}
        withLinks={withLinks}
        reload={undefined}
      />
      <PaginationFooter
        loadingRecords={loadingRecords}
        page={page}
        pageSize={pageSize}
        totalRecords={totalRecords}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
    </div>
  );
};
