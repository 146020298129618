import { defaultFor } from "common";
import { recordsApi } from "common/api/records";
import { Component } from "common/component";
import { filterLayout } from "common/form/functions/layout";
import type { Group } from "common/form/types";
import { getFormOrDefault } from "common/record/edit/functions";
import { ViewForm } from "common/record/form/content/detail/view";
import type { RelatedValue } from "common/record/form/content/related/types";
import type { DetailUiValue, RelatedUiValue } from "common/record/types";
import { applyChangesToRecord, getRecordChanges } from "common/record/utils";
import type { Context } from "common/types/context";
import type { ApiErrorResponse } from "common/types/error";
import { CancellablePromise } from "common/types/promises";
import type { Record } from "common/types/records";
import { ApiError } from "common/ui/api-error";
import { LoadingIcon } from "common/widgets/loading-icon";
import type { SelectedWorkOrder } from "x/scheduler2/types";
import { AssetSection } from "x/scheduler2/workorder-view/section/asset";
import { AssignmentSection } from "x/scheduler2/workorder-view/section/assignments";
import { ButtonsSection } from "x/scheduler2/workorder-view/section/buttons";
import { HeaderSection } from "x/scheduler2/workorder-view/section/header";
import { PartsSection } from "x/scheduler2/workorder-view/section/parts";
import { ProceduresSection } from "x/scheduler2/workorder-view/section/procedures";
import type { SectionProps } from "x/scheduler2/workorder-view/section/types";
import { trackAssignmentChanges } from "./track-events";

interface PropTypes {
  selectedWorkOrder: SelectedWorkOrder;
  context: Context;
  onClose: () => void;
  isPlanner: boolean;
  onLoaded?: (workOrder: Record) => void;
  reload: () => void;
}

interface StateType {
  workOrder: Record;
  hideNewForm: boolean;
  related: RelatedUiValue;
  loading: boolean;
  error: ApiErrorResponse;
}

export class WorkOrderView extends Component<PropTypes, StateType> {
  static readonly displayName = "WorkOrderView";
  state: StateType = {
    workOrder: undefined,
    hideNewForm: true,
    related: defaultFor<RelatedUiValue>(),
    loading: false,
    error: undefined,
  };
  fetchWorkOrderRequest: CancellablePromise<unknown>;

  componentDidMount() {
    this.loadEventData(this.props.selectedWorkOrder);
  }

  componentDidUpdate(prevProps: PropTypes) {
    if (prevProps.selectedWorkOrder?.id !== this.props.selectedWorkOrder?.id) {
      this.loadEventData(this.props.selectedWorkOrder);
    }
  }

  componentWillUnmount() {
    this.fetchWorkOrderRequest?.cancel();
  }

  reload = () => {
    this.loadEventData(this.props.selectedWorkOrder);
  };

  loadEventData = (selectedWorkOrder: SelectedWorkOrder) => {
    const { context, onLoaded } = this.props;
    const { apiCall } = context;

    if (!selectedWorkOrder?.entityName) return;

    this.setState({ loading: true });
    this.fetchWorkOrderRequest = recordsApi(apiCall)
      .get(selectedWorkOrder.entityName, selectedWorkOrder.id, true)
      .then((workOrder: Record) => {
        this.setState({
          workOrder,
          hideNewForm: true,
          related: defaultFor<RelatedUiValue>(),
          loading: false,
          error: undefined,
        });
        if (onLoaded) onLoaded(workOrder);
      })
      .catch((error) => this.setState({ loading: false, error }));
  };

  updateWorkOrder = (value: RelatedValue) => {
    const { context, selectedWorkOrder, reload } = this.props;
    const { entities } = context;
    const { entityName } = selectedWorkOrder;
    const { apiCall } = context;
    const { record, related } = value;

    const newWorkOrder = applyChangesToRecord(record, {
      detail: defaultFor<DetailUiValue>(),
      related,
      sidebar: undefined,
    });

    this.setState({ loading: true });
    recordsApi(apiCall)
      .createOrUpdate(entityName, getRecordChanges(record, newWorkOrder))
      .then(() => {
        trackAssignmentChanges(entityName, entities, related);
        this.loadEventData(selectedWorkOrder);
        reload();
      })
      .catch((error) => this.setState({ loading: false, error }));
  };

  onChangeRelated = (value: RelatedValue) => {
    const { record, related } = value;
    const { isDirty } = related;

    isDirty
      ? this.updateWorkOrder(value)
      : this.setState({ workOrder: record, related });
  };

  toggleNewForm = () => {
    const { hideNewForm } = this.state;
    this.setState({
      related: defaultFor<RelatedUiValue>(),
      hideNewForm: !hideNewForm,
    });
  };

  getContent = () => {
    const { workOrder, related, hideNewForm } = this.state;
    if (!workOrder) return undefined;

    const { properties = {} } = workOrder;
    const { assetId } = properties;

    const { context, selectedWorkOrder, onClose, isPlanner } = this.props;
    const { entities } = context;
    const { entityName, assignmentsWithConflicts } = selectedWorkOrder;

    const woContext = context.site.isGroup
      ? context.createContextForSiteWithFallback(workOrder.properties?.site)
      : context;

    const form = getFormOrDefault(context, entityName, properties?.formId);
    const headerLayout = form.settings.header;

    const groups = form
      ? filterLayout((c) => c.columnName !== "assetId", form.settings).groups
      : ([] as Group[]);

    const sectionProps: SectionProps = {
      isPlanner,
      context: woContext,
      workOrderEntityName: entityName,
      workOrderId: properties.id,
      relatedValue: {
        record: workOrder,
        related,
        sidebar: { label: "" },
      },
    };

    return (
      <>
        <HeaderSection
          context={woContext}
          entities={entities}
          selectedWorkOrder={selectedWorkOrder}
          layout={headerLayout}
          onClose={onClose}
        />

        <ButtonsSection
          context={woContext}
          workOrder={workOrder}
          entityName={entityName}
          isPlanner={isPlanner}
          reload={this.reload}
        />

        {isPlanner ? (
          <AssignmentSection
            {...sectionProps}
            assignmentsWithConflicts={assignmentsWithConflicts}
            onChangeRelated={this.onChangeRelated}
            toggleNewForm={this.toggleNewForm}
            hideNewForm={hideNewForm}
          />
        ) : (
          <AssignmentSection
            {...sectionProps}
            assignmentsWithConflicts={assignmentsWithConflicts}
            onChangeRelated={undefined}
            toggleNewForm={undefined}
            hideNewForm={true}
            hideNewButton={true}
          />
        )}

        <AssetSection
          context={woContext}
          entityName={entityName}
          assetFk={assetId}
        />

        <ViewForm
          className="x-section-group"
          context={woContext}
          entityName={entityName}
          withLinks={true}
          disablePositions={true}
          groups={groups}
          record={properties}
        />

        <PartsSection {...sectionProps} />

        <ProceduresSection {...sectionProps} />
      </>
    );
  };

  render() {
    const { loading, error } = this.state;

    return (
      <div className="x-scheduler-panel-flyover">
        <div className="qa-WorkOrderView-common x-scheduler-workorder-view">
          {loading && <LoadingIcon />}
          {error && <ApiError error={error} />}
          {this.getContent()}
        </div>
      </div>
    );
  }
}
