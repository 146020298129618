import { AlertErrorTip } from "./alert";
import { getValueFromUniqueValue } from "./unique/functions";
import { Hint } from "./hint";

interface PropTypes {
  maxLength: number;
  value: any;
  children?: any;
  withHint?: boolean;
  className?: string;
}

const getHintMessage = (value: string, ml: number) =>
  value?.length <= ml
    ? _("COUNT characters remaining of ML")
        .replace("COUNT", (ml - value.length).toString())
        .replace("ML", ml.toString())
    : _("The maximum length is ML characters").replace("ML", ml.toString());

export const MaxLength = ({
  maxLength,
  value,
  children,
  withHint,
  className,
}: PropTypes) => {
  const valueLength = value
    ? getValueFromUniqueValue(value).toString().length
    : 0;
  const hintMessage = withHint && getHintMessage(value, maxLength);
  const showAlert = valueLength >= maxLength;

  return (
    <div className={className}>
      {children}
      {!showAlert && hintMessage && <Hint message={hintMessage} />}
      {showAlert && (
        <AlertErrorTip
          message={_(
            "The maximum length of {ML} characters is reached",
          ).replace("{ML}", maxLength.toString())}
        />
      )}
    </div>
  );
};
