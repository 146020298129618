import { getReferenceQuery } from "common/functions/references";
import { Properties } from "common/types/records";
import { filterByBehaviorArguments } from "common/entities";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import { filterFormsByEntity } from "common/functions/forms";
import { Query } from "common/query/types";
import { Context } from "common/types/context";
import { SystemIntFk } from "common/types/system-int";
import { TRENDING_METER_TYPE_ID } from "common/types/system-strings";

const primaryColumnNames = [
  "meterId",
  "upperBound",
  "lowerBound",
  "taskId",
  "workOrderFormId",
];

export const getQueryForMeterColumn = (
  context: Context,
  entity: Entity,
  { name, relatedEntity }: EntityColumn,
  currentMeterId: string,
): Query => {
  const defaultQuery = getReferenceQuery(
    context,
    context.entities[relatedEntity],
  );

  return {
    ...defaultQuery,
    filter: {
      and: [
        defaultQuery.filter,
        {
          name: "meterTypeId",
          op: "eq",
          value: TRENDING_METER_TYPE_ID,
        },
        {
          or: [
            {
              name: name,
              path: `/${entity.name}.${name}`,
              op: "eq",
              value: currentMeterId,
            },
            {
              name: name,
              op: "isnull",
              path: `/${entity.name}.${name}`,
            },
          ],
        },
      ],
    },
    joins: [
      {
        column: name,
        entity: entity.name,
        type: "LEFT",
      },
    ],
  };
};

export const isConditionMonitoringFormValid = (value: Properties) =>
  !value?.generateOnException || (!!value?.workOrderFormId && !!value?.taskId);

export const getWoFormOptions = (
  context: Context,
  entity: Entity,
): SystemIntFk[] => {
  const { entities, forms } = context;
  const woEntities = filterByBehaviorArguments(entities, "WorkOrder", {
    taskEntity: entity.arguments.taskEntity,
  });
  const woEntityForms = woEntities.reduce(
    (acc, e) => [...acc, ...filterFormsByEntity(forms, e.name)],
    [],
  );

  return (
    woEntityForms?.map(({ id, label }) => ({
      id,
      title: label,
    })) || []
  );
};

export const getAdditionalRequiredColumns = (
  columns: EntityColumn[],
): EntityColumn[] => {
  return columns.filter(
    (column) =>
      !column.isSystem ||
      (column.required &&
        !column.readOnly &&
        !primaryColumnNames.includes(column.name)),
  );
};
