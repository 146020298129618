import debounce from "lodash-es/debounce";
import { GroupBase } from "react-select";
import SelectAsync, { AsyncProps } from "react-select/async";
import { useEffect, useRef } from "react";
import { arrayToString } from "common/utils/array";
import { getNoOptionsMessage } from "common/vendor-wrappers/react-select/functions";
import { createLimitedMenuList } from "common/vendor-wrappers/react-select/limited-menu-list";
import { genericSortByField } from "common/functions/generics";
import {
  ExtraAsyncProps,
  LabelledOptionOrGroup,
} from "common/vendor-wrappers/react-select/types";
import {
  getCommonProps,
  MAX_SELECTOR_OPTIONS,
} from "common/vendor-wrappers/react-select/consts";

type CustomAsyncProps<T> = { optionsLimit: number; label?: string } & Omit<
  AsyncProps<T, false, GroupBase<T>>,
  "loadOptions"
>;

type Props<T> = CustomAsyncProps<T> & ExtraAsyncProps<T>;

export const ReactAsyncSelect = <T extends unknown>(
  props: Props<LabelledOptionOrGroup<T>>,
) => {
  const selectorRef = useRef<HTMLDivElement>();

  const {
    loadOptions,
    menuIsOpen,
    optionsLimit = MAX_SELECTOR_OPTIONS,
    label,
    ...selectProps
  } = props;

  useEffect(() => {
    if (menuIsOpen) {
      selectorRef.current.focus();
    }
  }, [menuIsOpen]);

  const loadOptionsDebounced = debounce((term: string, callback) => {
    loadOptions(term, optionsLimit)
      .then((response) => genericSortByField("label")(response))
      .then(callback);
  }, 250);

  return (
    <SelectAsync
      ref={selectorRef as any}
      {...getCommonProps(selectProps)}
      aria-label={label}
      className={arrayToString(["x-selector", props.className])}
      loadOptions={loadOptionsDebounced}
      noOptionsMessage={getNoOptionsMessage}
      components={
        optionsLimit
          ? { MenuList: createLimitedMenuList(optionsLimit) }
          : undefined
      }
      value={props.value || null}
    />
  );
};
