import * as R from "ramda";
import { defaultFor } from "common";
import { Entity } from "common/entities/types";
import { getSubFKTitle } from "common/functions/foreign-key";
import { QueryForEntity } from "common/query/types";
import { Context } from "common/types/context";
import {
  PmTrigger,
  ScheduledEventAssetRecord,
  ScheduledEventRecord,
} from "common/types/scheduled-event";
import { EntryBoxList } from "common/widgets/entry-box/list";
import { createList } from "common/widgets/list";
import { OnChange, ValueComponent } from "common/with-value-for";
import {
  getEventAssets,
  setEventAssetDeleted,
  setEventAssets,
} from "../../functions";
import { EventAsset } from "../pm/event-asset";
import { AssetSelector } from "./selector";
import { VirtualAssetList } from "./virtual-asset-list";

export interface PropTypes {
  context: Context;
  entity: Entity;
  assetEntity: Entity;
  meterEntity?: Entity;
  query?: QueryForEntity;
}

export const List = createList<ScheduledEventAssetRecord>(undefined);

export const updateEventAssets = (
  oldEvents: ScheduledEventAssetRecord[],
  newEvents: ScheduledEventAssetRecord[],
): ScheduledEventAssetRecord[] =>
  oldEvents
    .map(
      (o) =>
        R.find(
          (n) => n.properties.assetId.id === o.properties.assetId.id,
          newEvents,
        ) || setEventAssetDeleted(true, o),
    )
    .filter((a) => a.properties.id || !a.properties.isDeleted);

export const displayBox = (r: ScheduledEventAssetRecord) =>
  getSubFKTitle(r.properties.assetId.title);

export class AssetsList extends ValueComponent<
  ScheduledEventRecord,
  PropTypes
> {
  static readonly displayName = "AssetsList";

  pmOnDisplay = (
    item: ScheduledEventAssetRecord,
    onChange: OnChange<ScheduledEventAssetRecord>,
  ) => {
    const { context, assetEntity, meterEntity, value } = this.props;

    const { trigger = defaultFor<PmTrigger>() } = value.properties;
    return (
      <EventAsset
        pmTrigger={trigger as PmTrigger}
        context={context}
        assetEntity={assetEntity}
        meterEntity={meterEntity}
        value={item}
        onChange={onChange}
      />
    );
  };

  onChangeEventAssets = (newEventAssets: ScheduledEventAssetRecord[]) => {
    const { context, entity, value } = this.props;
    const oldEventAssets = getEventAssets(context.entities, entity, value);
    const updatedEventAssets = updateEventAssets(
      oldEventAssets,
      newEventAssets || [],
    );

    const newValue = setEventAssets(
      context.entities,
      entity,
      updatedEventAssets,
      value,
    );

    this.setValue(newValue);
  };

  render() {
    const { context, entity, assetEntity, value, meterEntity, query } =
      this.props;
    const eventAssets = getEventAssets(context.entities, entity, value).filter(
      (a) => !a.properties.isDeleted,
    );
    const pmTrigger = value?.properties?.trigger;

    return (
      <>
        <div className="form-component">
          <AssetSelector
            context={context}
            entity={entity}
            assetEntity={assetEntity}
            value={value}
            query={query}
            onChange={this.onChangeSetValue}
          />
        </div>
        {eventAssets.length ? (
          <div className="x-pm-added-assets">
            {!meterEntity ? (
              <EntryBoxList<ScheduledEventAssetRecord>
                display={displayBox}
                value={eventAssets}
                onChange={this.onChangeEventAssets}
              />
            ) : eventAssets.length < 10 ? (
              <List
                key={JSON.stringify(pmTrigger)}
                onDisplay={this.pmOnDisplay}
                canDelete={R.T}
                withBorder={true}
                value={eventAssets}
                onChange={this.onChangeEventAssets}
              />
            ) : (
              <VirtualAssetList
                pmTrigger={pmTrigger as PmTrigger}
                onDisplay={this.pmOnDisplay}
                value={eventAssets}
                onChange={this.onChangeEventAssets}
              />
            )}
          </div>
        ) : undefined}
      </>
    );
  }
}
