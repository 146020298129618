import * as R from "ramda";
import { defaultFor } from "common";
import { Properties, Record } from "common/types/records";
import { getColumn } from "common/entities";
import { Entity } from "common/entities/types";
import {
  createDefaultLayout,
  filterLayout,
} from "common/form/functions/layout";
import { Layout, ReportLayout, ReportLayouts } from "common/form/types";
import { merge2 } from "common/merge";
import {
  DetailUiValue,
  FormValue,
  isStandardUiValue,
  StandardUiValue,
  StandardValue,
} from "common/record/types";
import { Context } from "common/types/context";
import { Report } from "common/types/reports";

const defaultRecord = defaultFor<Record>();
const defaultUi = defaultFor<StandardUiValue>();

export const getRecord = (value: FormValue) => {
  if (!isStandardUiValue(value)) return value.record;

  const { ui = defaultFor<StandardUiValue>() } = value;
  const { detail = defaultFor<DetailUiValue>() } = ui;
  const { form = defaultFor<Properties>() } = detail;

  const record = value.record || defaultRecord;
  const formId = form.formId || record.properties.formId;

  return record.properties.formId !== formId
    ? merge2("properties", "formId", formId, record)
    : record;
};

// TODO RTS: quick fix for the labels of the reports
// (fix in the backend and delete this)
export const getLayout = (
  context: Context,
  entity: Entity,
  value: FormValue,
): Layout => {
  const { ui = defaultUi, record = defaultRecord } =
    (value as StandardValue) ?? defaultFor<StandardValue>();
  const { properties } = record;
  const { detail, related } = ui;
  const { forms } = context;

  const formId =
    detail?.form?.formId || related?.form?.formId || properties?.formId;
  const form = formId ? R.find((f) => f.id === formId, forms) : undefined;

  return form
    ? filterLayout((c) => !!getColumn(entity, c.columnName), form.settings)
    : createDefaultLayout(entity, context, properties, false);
};

const setLayoutReportLabels = (
  reportLayouts: ReportLayout[],
  reports: Report[],
) => {
  return reportLayouts
    .filter((lr) => R.find((report) => report.id === lr.id, reports))
    .map((r) =>
      R.mergeRight(r, {
        label: R.find((report) => report.id === r.id, reports).label,
      }),
    );
};

export const getLayoutReportIds = (layout: Layout) => {
  const { reports = defaultFor<ReportLayouts>() } = layout;
  const { sidebar = [], section = [] } = reports;
  return sidebar.map((r) => r.id).concat(section.map((r) => r.id));
};

export const updateLayoutReportTitles = (layout: Layout, reports: Report[]) => {
  const layoutReports = layout.reports;
  const { sidebar = [], section = [] } = layoutReports;

  return R.mergeRight(layout, {
    reports: R.mergeRight(layout.reports, {
      sidebar: setLayoutReportLabels(sidebar, reports),
      section: setLayoutReportLabels(section, reports),
    }),
  });
};
