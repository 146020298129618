import { Component } from "react";
import { mediaApi } from "common/api/media";
import { workflowsMediaApi } from "common/api/workflows-media";
import { setColumnIsValid } from "common/form/functions/validation";
import { FormValidationProps } from "common/form/types";
import { Context } from "common/types/context";
import { FileType } from "common/types/media";
import { CancellablePromise } from "common/types/promises";
import type { UsageArea } from "common/widgets/types";
import { isWorkflowsUsageArea } from "common/widgets/functions";
import { ValueProps } from "common/with-value-for";
import { AcceptType } from "../file-selector/types";
import { DocumentWithUpload } from ".";

interface Props extends ValueProps<string>, FormValidationProps {
  context: Context;
  entityName: string;
  columnName?: string;
  recordId: string;
  allowClear: boolean;
  acceptType?: AcceptType;
  uploaderLabel?: string;
  usageArea?: UsageArea;
}

export class DocumentWithUploadController extends Component<Props> {
  static readonly displayName = "DocumentWithUploadController";

  uploadAndGetUrlRequest: CancellablePromise<unknown>;

  componentWillUnmount() {
    this.uploadAndGetUrlRequest?.cancel();
  }

  uploadDocument = (file: FileType) => {
    const {
      entityName,
      columnName,
      context,
      recordId,
      formValidation,
      usageArea,
      onFormValidationChange,
      onChange,
    } = this.props;
    const { apiCall, site } = context;

    if (columnName) {
      onFormValidationChange?.(
        setColumnIsValid(formValidation, columnName, false),
      );
    }

    // Marks the form as dirty for the time when the file is being uploaded
    onChange(undefined);

    const uploadPromise = isWorkflowsUsageArea(usageArea)
      ? workflowsMediaApi(apiCall).uploadAndGetUrl(recordId, file)
      : mediaApi(apiCall).uploadAndGetUrl(
          site.name,
          entityName,
          recordId,
          file,
        );

    this.uploadAndGetUrlRequest = uploadPromise.then((filePath) => {
      onChange(filePath);
      if (columnName) {
        onFormValidationChange?.(
          setColumnIsValid(formValidation, columnName, true),
        );
      }
    });

    return this.uploadAndGetUrlRequest;
  };

  removeDocument = () => this.props.onChange(undefined);

  render() {
    const { allowClear, uploaderLabel, acceptType, value } = this.props;

    return (
      <DocumentWithUpload
        onUpload={this.uploadDocument}
        onRemove={this.removeDocument}
        allowClear={allowClear}
        acceptType={acceptType}
        uploaderLabel={uploaderLabel}
        url={value}
      />
    );
  }
}
