import { ValueProps } from "common/with-value-for";
import { PropTypes } from "../table-with-form/types";
import { StandardRelated } from "../standard-related";
import { RelatedValue } from "../types";
import { noDuplicateContactId, getRelatedRecordIds } from "../common/functions";

type Props = PropTypes & ValueProps<RelatedValue>;

export const Requisitioner = (props: Props) => {
  const { context, entity, value } = props;

  const ids = getRelatedRecordIds(entity.name, "contactId", value);

  return (
    <StandardRelated
      {...props}
      context={noDuplicateContactId(context, entity, ids)}
    />
  );
};
Requisitioner.displayName = "Requisitioner";
