import { ReadOnlyDrilldown } from "./drilldown";
import { ReadOnlyUserDefined } from "./user-defined";
import { ReadOnlyLookupPropTypes } from "./types";

export const ReadOnlyLookupField = (props: ReadOnlyLookupPropTypes) => {
  const { type } = props.groupColumn.lookupConfiguration;

  return type === "drilldown" ? (
    <ReadOnlyDrilldown {...props} />
  ) : type === "userDefined" ? (
    <ReadOnlyUserDefined {...props} />
  ) : undefined;
};
