import { getLocalizedName } from "common";
import { getColumn } from "common/entities";
import { Row } from "../row";
import { ReadOnlyLookupPropTypes as PropTypes } from "./types";

// We don't do anything special with the read-only user-defined

export const ReadOnlyUserDefined = ({
  groupColumn,
  withLinks,
  entity,
  record = {},
  context,
}: PropTypes) => {
  const fkColumn = getColumn(entity, groupColumn.columnName);
  return (
    <Row
      key={fkColumn.name}
      context={context}
      entityName={entity.name}
      withLinks={withLinks}
      column={fkColumn}
      label={getLocalizedName(fkColumn)}
      value={record[fkColumn.name]}
    />
  );
};
