import * as R from "ramda";
import { recordsApi } from "common/api/records";
import { Component } from "common/component";
import { getEntityByBehavior } from "common/entities";
import { findEntityByArgs } from "common/functions/entity";
import { filterFormsByEntity } from "common/functions/forms";
import { Action } from "common/record/actions/action";
import { DismissAction, PropTypes } from "common/record/actions/types";
import { hasProtectedColumns } from "common/record/utils";
import { ApiCall } from "common/types/api";
import { Record } from "common/types/records";
import { LoadingIcon } from "common/widgets/loading-icon";
// eslint-disable-next-line import/no-cycle
import { RequisitioningForm } from "./form";
import {
  getApprovedItems,
  isValid,
  recordToRequisitioningPayload,
} from "./functions";
import { CreatePoParams, CreatePoPayload } from "./types";

interface StateType {
  initialItems: Record[];
  createPoParams: CreatePoParams;
  isLoading?: boolean;
}

export class CreatePurchaseOrder extends Component<PropTypes, StateType> {
  static readonly displayName = "CreatePurchaseOrder";
  state: StateType = {
    initialItems: [],
    createPoParams: { formId: undefined, items: [] },
    isLoading: false,
  };

  componentDidMount() {
    const { context, entity, records } = this.props;
    this.setState({ isLoading: true });

    recordsApi(context.apiCall)
      .get(entity.name, records[0].properties.id, true)
      .then((r) => {
        const items = getApprovedItems(context.entities, entity, r);
        this.setState({
          initialItems: items,
          createPoParams: { formId: null, items },
          isLoading: false,
        });
      });
  }

  onOk = (apiCall: ApiCall, dismiss: DismissAction) => {
    const { entity, records = [] } = this.props;
    const { initialItems, createPoParams } = this.state;

    const id = records[0]?.properties?.id;
    const formId = createPoParams.formId;
    const updatedRecords = R.difference(createPoParams.items, initialItems);
    const createPoPayload: CreatePoPayload = {
      formId,
      items: updatedRecords.map(recordToRequisitioningPayload),
    };
    return recordsApi(apiCall)
      .createPurchaseOrder(entity.name, id, createPoPayload)
      .then(dismiss);
  };

  render() {
    const { context, dismiss, entity, records = [] } = this.props;
    const { entities } = context;
    const { isLoading, createPoParams } = this.state;

    const purchaseOrderEntity = getEntityByBehavior(
      "PurchaseOrder",
      context.entities,
    );
    const purchaseOrderForms = filterFormsByEntity(
      context.forms,
      purchaseOrderEntity.name,
    );
    const isFormValid =
      purchaseOrderForms.length === 0 || createPoParams.formId;
    const isEnabled =
      !isLoading && isValid(createPoParams.items) && isFormValid;

    const reqItemEntity = findEntityByArgs(
      entities,
      "RequisitioningItem",
      "requisitioningEntity",
      entity.name,
    );

    if (isLoading) return <LoadingIcon />;

    return (
      <Action
        requiresAuthentication={hasProtectedColumns(entity)}
        context={context}
        dismiss={dismiss}
        entity={entity}
        records={records}
        title={_("Create Purchase Order")}
        btnLabel={_("Create Purchase Order")}
        onOk={isEnabled ? this.onOk : undefined}
        size="large"
      >
        <RequisitioningForm
          context={context}
          reqItemEntity={reqItemEntity}
          editableFields={["quantity", "partSupplierId"]}
          showPurchaseOrderFormSelector={true}
          value={createPoParams}
          onChange={this.onChangeMergeState("createPoParams")}
        />
      </Action>
    );
  }
}
