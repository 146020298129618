import * as R from "ramda";
import { Component } from "react";
import { SiteSelector } from "common/widgets/site-selector";
import {
  filterRolesByIds,
  getFullPermissionRoleIds,
  isRoleWithFullPermissions,
} from "common/functions/roles";
import { getSitesDictionary } from "common/functions/sites";
import { Context } from "common/types/context";
import { Role } from "common/types/roles";
import { Site } from "common/types/sites";
import { VerticalField } from "common/ui/field";
import { Chicklets } from "common/widgets/chicklets";
import { CheckAllButton } from "common/widgets/fancy-checkbox/check-all";
import { createList } from "common/widgets/fancy-checkbox/list";
import { RadioButtonList } from "common/widgets/radio-button-list";
import { getDisplaySiteFn } from "common/widgets/site-tree-selector-with-search/functions";
import { ValueProps } from "common/with-value-for";
import {
  getSitesOrDefaultToAll,
  isChildSite,
  isGroupSite,
} from "x/account-settings/sites/functions";

const sharedLabels = (t: boolean) =>
  t ? _("Shared") : _("Only visible to me");

const sharedItems = [false, true];

export interface DashboardSettingsValue {
  isShared: boolean;
  roleIds: number[];
  sites: string[];
}

interface PropTypes extends ValueProps<DashboardSettingsValue> {
  context: Context;
  roles: Role[];
}

export const defaultValue: DashboardSettingsValue = {
  isShared: false,
  roleIds: [],
  sites: undefined,
};

const List = createList<Role>();

export class SettingsForm extends Component<PropTypes> {
  onRolesChange = (roles: Role[] = []) => {
    const roleIds = roles.map((r) => r.id);
    this.props.onChange({ ...this.props.value, roleIds });
  };

  onCheckAllRolesChange = (roleIds: number[]) => {
    this.props.onChange({ ...this.props.value, roleIds });
  };

  onChangeSite = (sites: string[] = []) => {
    const { value, onChange } = this.props;

    onChange({ ...value, sites });
  };

  onChangeSitesChicklets = (sites: Site[] = []) => {
    this.props.onChange({
      ...this.props.value,
      sites: sites.map((s) => s.name),
    });
  };

  onSetShared = (isShared: boolean) => {
    this.props.onChange({
      isShared,
      roleIds: isShared ? getFullPermissionRoleIds(this.props.roles) : [],
      sites: isShared ? [] : undefined,
    });
  };

  render() {
    const { value, roles, context } = this.props;
    const { isShared, roleIds, sites } = value;

    const sitesDictionary = getSitesDictionary(context.sites);

    const formSites = getSitesOrDefaultToAll(sites, context.sites);

    const displaySite = getDisplaySiteFn(sitesDictionary);

    return (
      <>
        <VerticalField
          label={_("Dashboard privacy")}
          input={
            <RadioButtonList
              name="isShared"
              items={sharedItems}
              display={sharedLabels}
              value={isShared}
              onChange={this.onSetShared}
            />
          }
        />
        {isShared && (
          <>
            <VerticalField
              label={_("Share with the following roles")}
              input={
                <>
                  <CheckAllButton
                    allItems={roles.map((r) => r.id)}
                    defaultItems={getFullPermissionRoleIds(roles)}
                    value={roleIds}
                    onChange={this.onCheckAllRolesChange}
                  />
                  <List
                    className="qa-roles"
                    display={R.prop("name")}
                    items={roles}
                    isDisabled={isRoleWithFullPermissions}
                    value={filterRolesByIds(roles, roleIds)}
                    onChange={this.onRolesChange}
                  />
                </>
              }
            />
            {context.sites?.length > 1 && (
              <>
                <VerticalField
                  className="form-group x-flex-grow-1 qa-form-editor-site"
                  label={_("Sites")}
                  input={
                    <SiteSelector
                      context={context}
                      sitesToOmit={formSites}
                      allowMultipleSelection={true}
                      includeGroupSites={false}
                      value={sites}
                      onChange={this.onChangeSite}
                    />
                  }
                />
                <Chicklets<Site>
                  className="x-margin-bottom-5 qa-site-chicklets"
                  display={displaySite}
                  isHighlightedChicklet={isGroupSite}
                  canDeleteChicklet={isChildSite}
                  value={formSites}
                  onChange={this.onChangeSitesChicklets}
                />
              </>
            )}
          </>
        )}
      </>
    );
  }
}
